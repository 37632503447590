import { CallProductTax } from "./call-cash-tax.entity";

export class CallExchangeOutProduct {
    id: string; //UUID
    productId: string; // Product UUID
    quantity: number;
    units: number;
    price: number;
    wholesalePrice: number;
    upc: string;
    callProductTax: CallProductTax | null;
}
