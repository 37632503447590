export enum TransactionsListColumns {
    customerId = "Customer ID",
    callDate = "Call Date",
    storeInformation = "Store Information",
    createdBy = "Created By",
    receiptNumber = "Receipt #",
    salesTotal = "Sales Total",
    netTotal = "Net Total",
    gratisTotal = "Gratis Total",
    gratisPercent = "Gratis %",
    gratisRequestNumber = "Gratis Request #"
}
