import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { FilterRequestV2Dto, GenericRequestDto, GenericResponseDto, newSequentialId,
    RouteBatchParamsDto, RouteDto, RouteListColumns, RouteListFilterDto } from "shield.shared";
import { Employee } from "src/app/entity-models/employee.entity";
import { Route } from "src/app/entity-models/route.entity";
import { RouteManagementService } from "src/app/my-day/route-management/route-management.service";
import { ConverterHelper } from "../converter-services/converter.helper";
import { RouteConverterService } from "../converter-services/route-converter.service";

@Injectable()
export class RouteOnlineService {

    constructor(private http: HttpClient, private routeManagementService: RouteManagementService, private router: Router) { }

    async getById(id: string): Promise<GenericResponseDto<Route>> {
        const response = await this.http
            .get<GenericResponseDto<RouteDto>>(
                `/api/routes/${id}`
            )
            .toPromise();

        return ConverterHelper.dtoToEntity<RouteDto, Route>(response,
            (r) => RouteConverterService.routeDtoToRoute(r));
    }

    async getFutureByEmployeeId(id: string): Promise<GenericResponseDto<Route[]>> {
        const response = await this.http
            .get<GenericResponseDto<RouteDto[]>>(
                `/api/routes/employee-id/${id}/get-future-routes`
            )
            .toPromise();

        return ConverterHelper.dtoToEntityArray<RouteDto, Route>(response,
            (r) => RouteConverterService.routeDtoToRoute(r));
    }

    async editRoute(route: Route): Promise<GenericResponseDto<undefined>> {
        this.routeManagementService.route = route;
        this.router.navigate(["accounts/customers"]);

        return new GenericResponseDto<undefined>();
    }

    async getBatch(
        key: RouteBatchParamsDto
    ): Promise<GenericResponseDto<Route[]>> {

        const request = new GenericRequestDto<FilterRequestV2Dto<RouteListFilterDto, RouteListColumns>>();
        request.id = newSequentialId();
        request.values = key.filterRequestDto;

        const result = await this.http
            .post<GenericResponseDto<RouteDto[]>>(
                "/api/routes",
                request
            )
            .toPromise();

        return ConverterHelper.dtoToEntityArray(result, (r) => RouteConverterService.routeDtoToRoute(r));
    }

    async delete(routeId: string): Promise<GenericResponseDto<undefined>> {
        const result = await this.http
            .get<GenericResponseDto<undefined>>(`/api/routes/${routeId}/delete`)
            .toPromise();

        return result;
    }

    async saveRoute(route: Route): Promise<GenericResponseDto<Route>> {

        const dto = RouteConverterService.routeToRouteDto(route);
        const request = new GenericRequestDto<RouteDto>();
        request.id = route.id;
        request.values = dto;

        const result = await this.http
            .post<GenericResponseDto<RouteDto | undefined>>(`/api/routes/save`, request)
            .toPromise();

        return ConverterHelper.dtoToEntity<RouteDto, Route>(result, (r) => RouteConverterService.routeDtoToRoute(r));
    }
}
