import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { CustomerGenericTypes } from "src/app/enums/customer-generic-types";
import { Helper } from "src/app/helpers/helper";
import { CustomerStateService } from "../account-services/customer-state.service";

@Component({
    selector: "app-info-master",
    templateUrl: "./info-master.component.html",
    styleUrls: ["./info-master.component.css"]
})
export class InfoMasterComponent implements OnInit, OnDestroy {
    type: CustomerGenericTypes;
    retail = CustomerGenericTypes.retail;
    wholesale = CustomerGenericTypes.wholesale;
    chainHq = CustomerGenericTypes.chainHeadquarter;
    customerSubscription: Subscription;
    isEdit = false;
    constructor(private customerStateService: CustomerStateService,
            private router: Router) {}

    ngOnInit(): void {
        if (!this.customerSubscription || this.customerSubscription.closed) {
            this.customerSubscription = this.customerStateService.observableCustomer.subscribe(
                (customer) => {
                    if (customer) {
                        this.type = Helper.getCustomerGenericType(
                            customer
                        );
                        this.isEdit = this.router.url.includes('edit');
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (this.customerSubscription && !this.customerSubscription.closed) {
            this.customerSubscription.unsubscribe();
        }
    }
}
