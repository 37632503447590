import { CustomerContractPaymentDto, NotificationRequestDto } from "./dto";

export class SyncCommandBase<T> {
    id: number;
    queuedAt: Date;
    dequeueCount: number;
    dequeuedAt: Date;
    nextVisibleTime: Date;
    entity: SyncCommandTypes;
    payload: T;
    isDelete: boolean;
    lastError: any;
    constructor(entity: SyncCommandTypes) {
        this.entity = entity;
        this.dequeueCount = 0;
        this.dequeuedAt = null;
        this.queuedAt = new Date();
        this.nextVisibleTime = new Date();
    }
}


export class AccountOwnershipSyncPayload {
    id: string;
}

export class AccountOwnershipSyncCommand extends SyncCommandBase<AccountOwnershipSyncPayload> {
    entity: SyncCommandTypes.accountOwnership;
    constructor(id: string) {
        super(SyncCommandTypes.accountOwnership);
        this.payload = { id };
    }
}


export class AreaSyncPayload {
    id: string;
}

export class AreaSyncCommand extends SyncCommandBase<AreaSyncPayload> {
    entity: SyncCommandTypes.area;
    constructor(id: string) {
        super(SyncCommandTypes.area);
        this.payload = { id };
    }
}


export class AreaToZrtSyncPayload {
    id: string;
}

export class AreaToZrtSyncCommand extends SyncCommandBase<AreaToZrtSyncPayload> {
    entity: SyncCommandTypes.areaToZrt;
    constructor(id: string) {
        super(SyncCommandTypes.areaToZrt);
        this.payload = { id };
    }
}


export class CallSyncPayload {
    id: string;
}

export class CallSyncCommand extends SyncCommandBase<CallSyncPayload> {
    entity: SyncCommandTypes.call;
    constructor(id: string) {
        super(SyncCommandTypes.call);
        this.payload = { id };
    }
}


export class ContractTemplateSyncPayload {
    id: string;
}

export class ContractTemplateSyncCommand extends SyncCommandBase<ContractTemplateSyncPayload> {
    entity: SyncCommandTypes.contractTemplate;
    constructor(id: string) {
        super(SyncCommandTypes.contractTemplate);
        this.payload = { id };
    }
}


export class ContactSyncPayload {
    id: string;
}

export class ContactSyncCommand extends SyncCommandBase<ContactSyncPayload> {
    entity: SyncCommandTypes.contact;
    constructor(id: string) {
        super(SyncCommandTypes.contact);
        this.payload = { id };
    }
}


export class CountrySyncPayload {
    id: string;
}

export class CountrySyncCommand extends SyncCommandBase<CountrySyncPayload> {
    entity: SyncCommandTypes.country;
    constructor(id: string) {
        super(SyncCommandTypes.country);
        this.payload = { id };
    }
}


export class CountySyncPayload {
    id: string;
}

export class CountySyncCommand extends SyncCommandBase<CountySyncPayload> {
    entity: SyncCommandTypes.county;
    constructor(id: string) {
        super(SyncCommandTypes.county);
        this.payload = { id };
    }
}


export class CustomerSyncPayload {
    id: string;
}

export class CustomerSyncCommand extends SyncCommandBase<CustomerSyncPayload> {
    entity: SyncCommandTypes.customer;
    constructor(id: string) {
        super(SyncCommandTypes.customer);
        this.payload = { id };
    }
}


export class CustomerContractSyncPayload {
    id: string;
}

export class CustomerContractSyncCommand extends SyncCommandBase<CustomerContractSyncPayload> {
    entity: SyncCommandTypes.customerContract;
    constructor(id: string) {
        super(SyncCommandTypes.customerContract);
        this.payload = { id };
    }
}


export class CustomerContractPaymentSyncPayload {
    data: CustomerContractPaymentDto;
}

export class CustomerContractPaymentSyncCommand extends SyncCommandBase<CustomerContractPaymentSyncPayload> {
    entity: SyncCommandTypes.customerContractPayment;
    constructor(data: CustomerContractPaymentDto) {
        super(SyncCommandTypes.customerContractPayment);
        this.payload = { data };
    }
}


export class DayTimeEntrySyncPayload {
    id: string;
}

export class DayTimeEntrySyncCommand extends SyncCommandBase<DayTimeEntrySyncPayload> {
    entity: SyncCommandTypes.dayTimeEntry;
    constructor(id: string) {
        super(SyncCommandTypes.dayTimeEntry);
        this.payload = { id };
    }
}


export class NotificationSyncPayload {
    id: string;
    notificationRequestDto: NotificationRequestDto;
}

export class NotificationSyncCommand extends SyncCommandBase<NotificationSyncPayload> {
    entity: SyncCommandTypes.notification;
    constructor(id: string, notificationRequestDto?: NotificationRequestDto) {
        super(SyncCommandTypes.notification);
        this.payload = { id, notificationRequestDto };
    }
}


export class PictureSyncPayload {
    id: string;
    callId: string;
}

export class PictureSyncCommand extends SyncCommandBase<PictureSyncPayload> {
    entity: SyncCommandTypes.picture;
    constructor(id: string, callId: string) {
        super(SyncCommandTypes.picture);
        this.payload = { id, callId };
        this.isDelete = false;
    }
}

export class Px3SyncPayload {
    id: string;
}

export class Px3RankSyncCommand extends SyncCommandBase<Px3SyncPayload> {
    entity: SyncCommandTypes.px3Rank;
    constructor(id: string) {
        super(SyncCommandTypes.px3Rank);
        this.payload = { id };
    }
}

export class CustomerPx3RankSyncPayload {
    id: string;
}

export class CustomerPx3RankSyncCommand extends SyncCommandBase<CustomerPx3RankSyncPayload> {
    entity: SyncCommandTypes.customerPx3Rank;
    constructor(id: string) {
        super(SyncCommandTypes.customerPx3Rank);
        this.payload = { id };
    }
}

export class Px3IncentivePeriodSyncPayload {
    id: string;
}

export class Px3IncentivePeriodSyncCommand extends SyncCommandBase<Px3IncentivePeriodSyncPayload> {
    entity: SyncCommandTypes.px3IncentivePeriod;
    constructor(id: string) {
        super(SyncCommandTypes.px3IncentivePeriod);
        this.payload = { id };
    }
}

export class ReceiptSyncPayload {
    id: string;
    callId: string;
    notification?: NotificationRequestDto
}

export class ReceiptSyncCommand extends SyncCommandBase<ReceiptSyncPayload> {
    entity: SyncCommandTypes.receipt;
    constructor(id: string, callId: string) {
        super(SyncCommandTypes.receipt);
        this.payload = { id, callId };
    }
}


export class RegisteredUserSyncPayload {
    id: string;
}

export class RegisteredUserSyncCommand extends SyncCommandBase<RegisteredUserSyncPayload> {
    entity: SyncCommandTypes.registeredUser;
    constructor(id: string) {
        super(SyncCommandTypes.registeredUser);
        this.payload = { id };
    }
}


export class RouteSyncPayload {
    id: string;
}

export class RouteSyncCommand extends SyncCommandBase<RouteSyncPayload> {
    entity: SyncCommandTypes.route;
    constructor(id: string) {
        super(SyncCommandTypes.route);
        this.payload = { id };
    }
}


export class StateSyncPayload {
    id: string;
}

export class StateSyncCommand extends SyncCommandBase<StateSyncPayload> {
    entity: SyncCommandTypes.state;
    constructor(id: string) {
        super(SyncCommandTypes.state);
        this.payload = { id };
    }
}


export class StateCategorySyncPayload {
    id: string;
}

export class StateCategorySyncCommand extends SyncCommandBase<StateCategorySyncPayload> {
    entity: SyncCommandTypes.stateCategory;
    constructor(id: string) {
        super(SyncCommandTypes.stateCategory);
        this.payload = { id };
    }
}


export class StateLicenseSyncPayload {
    id: string;
}

export class StateLicenseSyncCommand extends SyncCommandBase<StateLicenseSyncPayload> {
    entity: SyncCommandTypes.stateLicense;
    constructor(id: string) {
        super(SyncCommandTypes.stateLicense);
        this.payload = { id };
    }
}


export class StoreTypeSyncPayload {
    id: string;
}

export class StoreTypeSyncCommand extends SyncCommandBase<StoreTypeSyncPayload> {
    entity: SyncCommandTypes.storeType;
    constructor(id: string) {
        super(SyncCommandTypes.storeType);
        this.payload = { id };
    }
}


export class SurveySyncPayload {
    id: string;
}

export class SurveySyncCommand extends SyncCommandBase<SurveySyncPayload> {
    entity: SyncCommandTypes.survey;
    constructor(id: string) {
        super(SyncCommandTypes.survey);
        this.payload = { id };
    }
}


export class TimeEntrySyncPayload {
    id: string;
}

export class TimeEntrySyncCommand extends SyncCommandBase<TimeEntrySyncPayload> {
    entity: SyncCommandTypes.timeEntry;
    constructor(id: string) {
        super(SyncCommandTypes.timeEntry);
        this.payload = { id };
    }
}


export class TimeEntryTypeSyncPayload {
    id: string;
}

export class TimeEntryTypeSyncCommand extends SyncCommandBase<TimeEntryTypeSyncPayload> {
    entity: SyncCommandTypes.timeEntryType;
    constructor(id: string) {
        super(SyncCommandTypes.timeEntryType);
        this.payload = { id };
    }
}


export class WholesalerCustomerMemberSyncPayload {
    id: string;
}

export class WholesalerCustomerMemberSyncCommand extends SyncCommandBase<WholesalerCustomerMemberSyncPayload> {
    entity: SyncCommandTypes.wholesalerCustomerMember;
    constructor(id: string) {
        super(SyncCommandTypes.wholesalerCustomerMember);
        this.payload = { id };
    }
}


export class WholesalerProductCatalogSyncPayload {
    id: string;
}

export class WholesalerProductCatalogSyncCommand extends SyncCommandBase<WholesalerProductCatalogSyncPayload> {
    entity: SyncCommandTypes.wholesalerProductCatalog;
    constructor(id: string) {
        super(SyncCommandTypes.wholesalerProductCatalog);
        this.payload = { id };
    }
}


export class WholesalerGroupSyncPayload {
    id: string;
}

export class WholesalerGroupSyncCommand extends SyncCommandBase<WholesalerGroupSyncPayload> {
    entity: SyncCommandTypes.wholesalerGroup;
    constructor(id: string) {
        super(SyncCommandTypes.wholesalerGroup);
        this.payload = { id };
    }
}


export class WholesalerGroupMemberSyncPayload {
    id: string;
}

export class WholesalerGroupMemberSyncCommand extends SyncCommandBase<WholesalerGroupMemberSyncPayload> {
    entity: SyncCommandTypes.wholesalerGroupMember;
    constructor(id: string) {
        super(SyncCommandTypes.wholesalerGroupMember);
        this.payload = { id };
    }
}


export class WholesalerGroupProductSyncPayload {
    id: string;
}

export class WholesalerGroupProductSyncCommand extends SyncCommandBase<WholesalerGroupProductSyncPayload> {
    entity: SyncCommandTypes.WholesalerGroupProduct;
    constructor(id: string) {
        super(SyncCommandTypes.WholesalerGroupProduct);
        this.payload = { id };
    }
}


export class WholesalerGroupProductCatalogSyncPayload {
    id: string;
}

export class WholesalerGroupProductCatalogSyncCommand extends SyncCommandBase<WholesalerGroupProductCatalogSyncPayload> {
    entity: SyncCommandTypes.wholesalerGroupProductCatalog;
    constructor(id: string) {
        super(SyncCommandTypes.wholesalerGroupProductCatalog);
        this.payload = { id };
    }
}


export class ZrtAssignmentProductCatalogSyncPayload {
    id: string;
}

export class ZrtAssignmentSyncCommand extends SyncCommandBase<ZrtAssignmentProductCatalogSyncPayload> {
    entity: SyncCommandTypes.zrtAssignment;
    constructor(id: string) {
        super(SyncCommandTypes.zrtAssignment);
        this.payload = { id };
    }
}


export type SyncCommand =
    AccountOwnershipSyncCommand
    | AreaSyncCommand
    | AreaToZrtSyncCommand
    | CallSyncCommand
    | ContractTemplateSyncCommand
    | CustomerSyncCommand
    | CustomerContractSyncCommand
    | CustomerContractPaymentSyncCommand
    | CountrySyncCommand
    | CountySyncCommand
    | ContactSyncCommand
    | RouteSyncCommand
    | DayTimeEntrySyncCommand
    | NotificationSyncCommand
    | PictureSyncCommand
    | Px3RankSyncCommand
    | Px3IncentivePeriodSyncCommand
    | CustomerPx3RankSyncCommand
    | ReceiptSyncCommand
    | RegisteredUserSyncCommand
    | StateSyncCommand
    | StateCategorySyncCommand
    | StateLicenseSyncCommand
    | StoreTypeSyncCommand
    | SurveySyncCommand
    | TimeEntrySyncCommand
    | TimeEntryTypeSyncCommand
    | WholesalerCustomerMemberSyncCommand
    | WholesalerGroupMemberSyncCommand
    | WholesalerGroupProductCatalogSyncCommand
    | WholesalerGroupProductSyncCommand
    | WholesalerGroupSyncCommand
    | WholesalerProductCatalogSyncCommand
    | ZrtAssignmentSyncCommand
;

export enum SyncCommandTypes {
    accountOwnership = "AccountOwnership",
    area = "Area",
    areaToZrt = "AreaToZrt",
    call = "Call",
    contact = "Contact",
    contractTemplate = "ContractTemplate",
    country = "Country",
    county = "County",
    customer = "Customer",
    customerContract = "CustomerContract",
    customerContractSignature = "CustomerContractSignature",
    customerContractPayment = "CustomerContractPayment",
    customerContractPaymentSignature = "CustomerContractPaymentSignature",
    dayTimeEntry = "DayTimeEntry",
    notification = "Notification",
    picture = "Picture",
    receipt = "Receipt",
    registeredUser = "RegisteredUser",
    route = "Route",
    state = "State",
    stateCategory = "StateCategory",
    stateLicense = "StateLicense",
    storeType = "StoreType",
    survey = "Survey",
    timeEntry = "TimeEntry",
    timeEntryType = "TimeEntryType",
    wholesalerCustomerMember = "WholesalerCustomerMember",
    wholesalerGroupMember = "WholesalerGroupMember",
    wholesalerGroupProductCatalog = "WholesalerGroupProductCatalog",
    WholesalerGroupProduct= "WholesalerGroupProduct",
    wholesalerGroup = "WholesalerGroup",
    wholesalerProductCatalog = "WholesalerProductCatalog",
    zrtAssignment = "ZrtAssignment",
    px3Rank = "Px3Rank",
    customerPx3Rank = "CustomerPx3Rank",
    px3IncentivePeriod = "Px3IncentivePeriod"
}
