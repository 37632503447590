<ng-container *ngIf="!viewmodel.viewMode; else viewTemplate">
    <ngb-alert *ngIf="viewmodel.isOfflineAndExistingGratis()" type="info" [dismissible]="false">
        You must be online to edit an existing gratis payment.
    </ngb-alert>
    <form [formGroup]="viewmodel?.paymentStatusFormGroup">
        <div class="row mb-3">
            <div class="col-4 h-center">
                <mat-form-field appearance="outline" color="accent" class="w-100">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="statusControl" required>
                        <mat-option *ngFor="let status of viewmodel.statuses" [value]="status" >
                            {{ status.description }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-8 h-center">
                <mat-form-field appearance="outline" color="accent" class="w-100">
                    <mat-label>Reason</mat-label>
                    <input
                        matInput
                        type="text"
                        formControlName="reasonControl"
                        maxlength="200" />
                    <mat-hint align="end" *ngIf="!viewmodel.viewMode">{{
                        viewmodel.payment.reason
                        ?.length || 0
                        }}/200</mat-hint>
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-4 h-center">
                <mat-form-field appearance="outline" color="accent" class="w-100">
                    <mat-label>Payment Amount</mat-label>
                    <input matInput type="text" #amount formControlName="amountControl" (mouseup)="viewmodel.select(amount)" currencyMask />
                </mat-form-field>
            </div>
            <div class="col-4 h-center">
                <mat-form-field appearance="outline" color="accent" class="w-100">
                    <mat-label>Payment Type</mat-label>
                    <input matInput type="text" formControlName="typeControl" readonly />
                </mat-form-field>
            </div>
            <div class="col-4 h-center">
                <mat-form-field appearance="outline" color="accent" class="w-100">
                    <mat-label *ngIf="viewmodel.status?.id === viewmodel.contractPaymentStatuses.Completed">Bonus Check</mat-label>
                    <mat-label *ngIf="viewmodel.status?.id !== viewmodel.contractPaymentStatuses.Completed">Payment Method</mat-label>
                    <mat-select formControlName="methodControl">
                        <mat-option *ngFor="let method of viewmodel.methods" [value]="method" >
                            {{ method.description }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-3 h-center">
                <div class="mb-3">
                    <mat-form-field appearance="outline" color="accent" class="w-100">
                        <mat-label>Customer Representative</mat-label>
                        <mat-select formControlName="repControl">
                            <mat-option *ngFor="let contact of viewmodel.contacts" [value]="contact" >
                                {{ contact.firstName + " " + contact.lastName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <button *ngIf="!viewmodel.viewMode"
                mat-raised-button
                class="actionBtn btn-white"
                (click)="viewmodel.onClearSignature()">Clear Signature</button>
            </div>
            <div class="col-8 h-center">
                <canvas
                    #canvas
                    id="signature-canvas"
                    class="border"
                    (disabled)="viewmodel.viewMode"
                    (click)="viewmodel.onBeginSignature()"
                    [ngClass]="{
                        'signature-required' : viewmodel.isSignatureInvalid(),
                        'signature-disabled' : viewmodel.viewMode
                    }"
                    [width]="viewmodel.canvasWidth"
                    [height]="viewmodel.canvasHeight"
                ></canvas>
            </div>
        </div>
    </form>
</ng-container>
<ng-template #viewTemplate>
    <div #agreement class="row d-flex justify-content-center mb-3">
        <div class="col-8">
            <table class="table table-bordered table-sm">
                <tbody>
                    <tr>
                        <th class="col-4 view-header text-right"><strong class="table-head">Contract Number</strong></th>
                        <td class="col-4">{{ viewmodel.contractNumber }}</td>
                    </tr>
                    <tr>
                        <th class="col-4 view-header text-right"><strong class="table-head">Status</strong></th>
                        <td class="col-4">{{ viewmodel.status.description }}</td>
                    </tr>
                    <tr>
                        <th class="col-4 view-header text-right"><strong class="table-head">Reason</strong></th>
                        <td class="col-4">{{ viewmodel.reason ? viewmodel.reason : 'N/A' }}</td>
                    </tr>
                    <tr>
                        <th class="col-4 view-header text-right"><strong class="table-head">Payment</strong></th>
                        <td class="col-4">{{ (viewmodel.amount ? viewmodel.amount : 0) | currency }}</td>
                    </tr>
                    <tr>
                        <th class="col-4 view-header text-right"><strong class="table-head">Payment Type</strong></th>
                        <td class="col-4">{{ viewmodel.type.description }}</td>
                    </tr>
                    <tr>
                        <th class="col-4 view-header text-right"><strong class="table-head">Payment Method</strong></th>
                        <td class="col-9">{{ viewmodel.method.description }}</td>
                    </tr>
                    <tr>
                        <th class="col-4 view-header text-right"><strong class="table-head">Swisher Representative</strong></th>
                        <td class="col-4">{{ viewmodel.swisherRep }}</td>
                    </tr>
                    <tr>
                        <th class="col-4 view-header text-right"><strong class="table-head">Customer Representative</strong></th>
                        <td class="col-4">{{ viewmodel.contact.firstName + " " + viewmodel.contact.lastName }}
                            <img
                                [src]="viewmodel.payment.customerContactSignature?.image ? viewmodel.payment.customerContactSignature?.image : null"
                                [width]="viewmodel.canvasWidthViewMode"
                                [height]="viewmodel.canvasHeightViewMode"
                            >
                        </td>
                    </tr>
                    <tr>
                        <th class="col-4 view-header text-right"><strong class="table-head">Payment Date</strong></th>
                        <td class="col-4">{{ viewmodel.payment.paymentDate | date: viewmodel.dateFormat}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>
