export enum TimeEntryTypeNames {
    administrationComputerWork = "Administration - Computer Work",
    businessReview = "Business Review",
    call = "Call",
    carServiceRepair = "Car Service Repair",
    driving = "Driving",
    holiday = "Holiday",
    itComputerRepair = "I.T. Computer Repair",
    makeUpTime = "Make-Up Time",
    meal = "Meal",
    otherJuryDuty = "Other - Jury Duty",
    salesMeeting = "Sales Meeting",
    sick = "Sick",
    storage = "Storage",
    tradeShow = "Trade Show",
    training = "Training",
    travel = "Travel",
    vacation = "Vacation",
    workWith = "Work With",
}
