import { GenericLookup } from "../generic";
import { ContractTemplateTypes } from "../lookup";
import { ContractTemplateDetailDto } from "./contract-template-detail.dto";

export class ContractTemplateDto{
    id: string;
    name: string;
    contractTemplateType: GenericLookup<ContractTemplateTypes>;
    version: number;
    zone: string | null;
    region: string | null;
    territory: string | null;
    createdUtcDateTime: string;
    createdUserId: string;
    createdUserZrt: string | null;
    createdUserFullName: string;
    modifiedUtcDateTime: string;
    modifiedUserId: string;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string;
    rowversion: string;
    contractTemplateDetails: ContractTemplateDetailDto[];
}
