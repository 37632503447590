import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { Area } from "../entity-models/area.entity";
import { SearchZrtDropDown } from "../entity-models/search-zrt-dropdown.entity";
import { Refiner } from "../entity-models/refiner.entity";
import { EmployeeRoleType, RefinerLocation, Subsidiary, valueSeparator } from "shield.shared";
import { RefinerServiceBase } from "../shared/refiner-service-base";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { Employee } from "../entity-models/employee.entity";
import { Helper } from "../helpers/helper";

@Injectable()
export class ZrtFilterServiceBase {

    private _areas: Area[] = [];
    private _areaZrts: SearchZrtDropDown[] = [];
    get areas(): Area[] {
        return this._areas;
    }
    set areas(value: Area[]) {
        this._areas = value;
        this.adjustAreaOptions();
        this.setZrtsByAreaOrEmployee();
    }

    private _employeeZrts: SearchZrtDropDown[] = [];
    get employeeZrts(): SearchZrtDropDown[] {
        return this._employeeZrts;
    }
    set employeeZrts(value: SearchZrtDropDown[]) {
        this._employeeZrts = value;
        this.adjustAreaOptions();
        this.setZrtsByAreaOrEmployee();
    }

    // observables
    private _zrts: SearchZrtDropDown[] = [];
    zrtsSubject: BehaviorSubject<SearchZrtDropDown[]> = new BehaviorSubject(this._zrts);
    get zrts(): SearchZrtDropDown[] {
        return this._zrts;
    }
    set zrts(value: SearchZrtDropDown[]) {
        this._zrts = value;
        this.zrtsSubject.next(this._zrts);
    }
    observableZrts: Observable<SearchZrtDropDown[]> = this.zrtsSubject.asObservable();

    private _selectedZrts: SearchZrtDropDown[] = [];
    selectedZrtsSubject: BehaviorSubject<SearchZrtDropDown[]> = new BehaviorSubject(this._selectedZrts);
    get selectedZrts(): SearchZrtDropDown[] {
        return this._selectedZrts;
    }
    set selectedZrts(value: SearchZrtDropDown[]) {
        this._selectedZrts = value.filter((v) => !!v);
        this.selectedZrtsSubject.next(this._selectedZrts);
    }
    observableSelectedZrts: Observable<SearchZrtDropDown[]> = this.selectedZrtsSubject.asObservable();
    private eqSet = <T>(a: Set<T>, b: Set<T>) => a.size === b.size && [...a].every(value => b.has(value));
    observableSelectedZrtsHandleRefiners = (refinerService: RefinerServiceBase) => combineLatest([
        this.observableSelectedZrts,
        this.observableByArea
    ]).pipe(
        debounceTime(100),
        distinctUntilChanged((a, b) => a[1] === b[1] && this.eqSet(new Set(a[0].map(z => z.zrt)), new Set(b[0].map(z => z.zrt)))),
        tap(([zrts, byArea]) => {
            const refiner = new Refiner();
            refinerService.removeRefinerByLocation(byArea ? RefinerLocation.zrtByEmployee : RefinerLocation.zrtByArea, false, false);
            refiner.location = byArea ? RefinerLocation.zrtByArea : RefinerLocation.zrtByEmployee;
            refiner.value = zrts.length > 1
                    ? `${zrts.length} Selected`
                    : zrts
                        .map((vm) => byArea ? vm.zrt : vm.displayValue)
                        .join(", ");
            refiner.dataPropertyName = byArea ? "zrt" : "employeeId";
            refiner.dataValue = zrts
                .map((vm) => vm.id)
                .join(valueSeparator);
            refinerService.checkAndUpdateRefiner(refiner, true, true);
        })
    )

    private _defaultZrtSelection: SearchZrtDropDown[] = [];
    defaultZrtSelectionSubject: BehaviorSubject<SearchZrtDropDown[]> = new BehaviorSubject(this._defaultZrtSelection);
    get defaultZrtSelection(): SearchZrtDropDown[] {
        return this._defaultZrtSelection;
    }
    set defaultZrtSelection(value: SearchZrtDropDown[]) {
        this._defaultZrtSelection = value.filter((v) => !!v);
        this.defaultZrtSelectionSubject.next(this._defaultZrtSelection);
    }
    observableDefaultZrtSelection: Observable<SearchZrtDropDown[]> = this.defaultZrtSelectionSubject.asObservable();

    private _byArea: boolean = true;
    byAreaSubject: BehaviorSubject<boolean> = new BehaviorSubject(this._byArea);
    get byArea(): boolean {
        return this._byArea;
    }
    set byArea(value: boolean) {
        this._byArea = value;
        this.byAreaSubject.next(value);
        this.setZrtsByAreaOrEmployee();
    }
    observableByArea: Observable<boolean> = this.byAreaSubject.asObservable();

    // private methods
    private adjustAreaOptions(): void {
        const areaZrts = new Array<SearchZrtDropDown>();
        for (const area of this.areas) {
            const areaEntry = new SearchZrtDropDown();
            areaEntry.id = area.id;
            areaEntry.zrt = area.name;
            areaEntry.displayValue = area.name;
            areaEntry.children = [];

            areaZrts.push(areaEntry);
        }
        let zrtOptions = _.cloneDeep(this.employeeZrts);
        zrtOptions = zrtOptions.filter((v, i, a) => a.map(x => x.zrt).indexOf(v.zrt) === i); // dedupe zones
        zrtOptions.forEach((z) => {
            z.id = z.zrt;
            z.displayValue = `Zone ${z.zrt.slice(0,2)}`;
            if (!z.children) z.children = [];
                z.children = z.children.filter((v, i, a) => a.map(x => x.zrt).indexOf(v.zrt) === i); // dedupe regions
                z.children.forEach((r) => {
                    r.id = r.zrt;
                    r.displayValue = `Region ${r.zrt.slice(0,3)}`;
                    if (!r.children) r.children = [];

                        r.children = r.children.filter((v, i, a) => a.map(x => x.zrt).indexOf(v.zrt) === i); // dedupe territories
                        r.children.forEach((t) => {
                            t.id = t.zrt;
                            t.displayValue = t.zrt;
                        });

                });

        });
        this._areaZrts = areaZrts.concat(zrtOptions);
    }

    private setZrtsByAreaOrEmployee(): void {
        if (!this._areaZrts.length) {
            this.adjustAreaOptions();
        }
        this.zrts = this._byArea ? this._areaZrts : this._employeeZrts;
        this.defaultZrtSelection = this.defaultZrtSelection.map((v) => ZrtFilterServiceBase.findNodeByZrt(this.zrts, v.zrt));
    }

    // static methods
    static addSelectedZrts(addToArray: Array<SearchZrtDropDown>, node: SearchZrtDropDown): Array<SearchZrtDropDown> {
        if (node.children) {
            for (const child of node.children) {
                addToArray = addToArray.concat(this.addSelectedZrts(addToArray, child));
            }
            addToArray = addToArray.concat(node);
        } else {
            addToArray = [node];
        }

        return [...new Set<SearchZrtDropDown>(addToArray)];
    }

    static findNodeById(zrts: SearchZrtDropDown[], id: string): SearchZrtDropDown | undefined {
        let rtn: SearchZrtDropDown;
        for (const z of zrts) {
            if (z.id === id) {
                rtn = z;
            } else {
                for (const r of z.children) {
                    if (r.id === id) {
                        rtn = r;
                    } else {
                        for (const t of r.children) {
                            if (t.id === id) {
                                rtn = t;
                            }
                            if (rtn) break;
                        }
                    }
                    if (rtn) break;
                }
            }
            if (rtn) break;
        }
        return rtn;
    }
    static findNodeByZrt(zrts: SearchZrtDropDown[], zrt: string): SearchZrtDropDown | undefined {
        let rtn: SearchZrtDropDown;
        for (const z of zrts) {
            if (z.zrt === zrt) {
                rtn = z;
            } else {
                for (const r of z.children) {
                    if (r.zrt === zrt) {
                        rtn = r;
                    } else {
                        for (const t of r.children) {
                            if (t.zrt === zrt) {
                                rtn = t;
                            }
                            if (rtn) break;
                        }
                    }
                    if (rtn) break;
                }
            }
            if (rtn) break;
        }
        return rtn;
    }

    static findNodesByZrt(zrts: SearchZrtDropDown[], searchableZrt: string): SearchZrtDropDown[] | undefined {
        let rtn = new Array<SearchZrtDropDown>();
        for (const z of zrts) {
            if (z.zrt.includes(searchableZrt)) {
                rtn.push(z);
            } else {
                for (const r of z.children) {
                    if (r.zrt.includes(searchableZrt)) {
                        rtn.push(r);
                    } else {
                        for (const t of r.children) {
                            if (t.zrt.includes(searchableZrt)){
                                rtn.push(t);
                            }
                        }
                    }
                }
            }
        }
        return rtn;
    }

    getZrtValues(): string[] {
        if (this.byArea) {
            return this.selectedZrts.map(v => v.id);
        }
        return this.selectedZrts.map(v => v.zrt);
    }

    resetFilter(): void {
        this.areas = [];
        this.employeeZrts = [];
        this.defaultZrtSelection = [];
        this.selectedZrts = [];
        this.zrts = [];
    }

    createDefaultZrtRefiner(employee: Employee, forceByArea: boolean|undefined = undefined): Refiner | undefined {
        let selected: SearchZrtDropDown = undefined;
        if (Helper.isEmployeeCustomerServiceOrAdmin(employee)) {
            this.byArea = true;

            switch (employee.subsidiaryId as Subsidiary) {
                case Subsidiary.EAS:
                    const subsidiaryEas = this.areas.filter((v) => v.subsidiaryId == Subsidiary.EAS && v.name.startsWith("All"));
                    if (subsidiaryEas && subsidiaryEas.length) {
                        selected = ZrtFilterServiceBase.findNodeById(this.zrts, subsidiaryEas[0].id);
                        this.selectedZrts = selected ? [selected] : [];
                    }
                    break;
                default:
                    const subsidiarySwisher = this.areas.filter((v) => v.subsidiaryId == Subsidiary.Swisher && v.name.startsWith("All"));
                    if (subsidiarySwisher && subsidiarySwisher.length) {
                        selected = ZrtFilterServiceBase.findNodeById(this.zrts, subsidiarySwisher[0].id);
                        this.selectedZrts = selected ? [selected] : [];
                    }
                    break;
            }
        } else {
            const shouldUseZrtData = this.byArea = forceByArea !== undefined ? forceByArea : employee.employeeRoles.some(v => v.employeeRoleType.id === EmployeeRoleType.ZM);
            const method = shouldUseZrtData ? ZrtFilterServiceBase.findNodeByZrt : ZrtFilterServiceBase.findNodeById;
            selected = method(this.zrts, shouldUseZrtData ? employee.zrt : employee.id);
            if (selected) {
                this.selectedZrts = ZrtFilterServiceBase.addSelectedZrts(new Array<SearchZrtDropDown>(), selected).filter(z => z.id);
            }
        }
        if (selected) {
            const refiner = new Refiner();
            refiner.location = this.byArea ? RefinerLocation.zrtByArea : RefinerLocation.zrtByEmployee;
            refiner.value = this.selectedZrts.map(v => this.byArea ? v.zrt : v.displayValue).join(valueSeparator);
            refiner.dataPropertyName = this.byArea ? "zrt" : "employeeId";
            refiner.dataValue = this.selectedZrts.map(v => v.id).join(valueSeparator);
            return refiner;
        }
    }

    applyRefiner(refiner: Refiner): void {
        const byArea = refiner.location === RefinerLocation.zrtByArea;
        const values = refiner.dataValue?.split(valueSeparator);
        if (values?.length) {
            const selected = values.map((v) => {
                const isZrt = v.length === 4;
                const method = isZrt ? ZrtFilterServiceBase.findNodeByZrt : ZrtFilterServiceBase.findNodeById;
                return method(this.zrts, v)
            }).filter((v) => !!v);
            this.selectedZrts = selected;
        } else {
            this.selectedZrts = [];
        }
        this.byArea = byArea;
    }
}
