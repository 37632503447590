import {
    AfterContentChecked,
    Component,
    OnInit,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { OrderDetailsDialogViewModel } from "./order-details-dialog.viewmodel";
//Icons
import { faTimes, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { OverlayService } from "src/app/services/overlay.service";
import { OrderOnlineService } from "src/app/services/online-services/order-online.service";
import { Call } from "src/app/accounts/call-master/call-services/call.service";
import { Receipt } from "src/app/entity-models/receipt";
import { CallTypes, EmailAttachment, EmailOrderParamsDto, EmailReceiptLocationOrigins, LicenseTypes, newSequentialId, NotificationRequestDto, OrderStatuses } from "shield.shared";
import { Helper } from "src/app/helpers/helper";
import { SnackbarService } from "src/app/services/snackbar.service";
import { ReceiptDelineationService } from "src/app/services/delineation-services/receipt-delineation.service";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { ShareOptionsDialogViewmodel } from "src/app/dialogs/share-options-dialog/share-options-dialog.viewmodel";
import { Employee } from "src/app/entity-models/employee.entity";
import { NotificationDelineationService } from "src/app/services/delineation-services/notification-delineation.service";
import { ShareOptionsDialogComponent } from "src/app/dialogs/share-options-dialog/share-options-dialog.component";

@Component({
    selector: "app-order-details-dialog",
    templateUrl: "./order-details-dialog.component.html",
    styleUrls: ["./order-details-dialog.component.scss"]
})
export class OrderDetailsDialogComponent
    extends OrderDetailsDialogViewModel
    implements OnInit, AfterContentChecked {
    @ViewChild("receiptImages") receiptImages: HTMLElement;

    faTimes: IconDefinition = faTimes;
    data: any;

    confirmationOverlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    receipts: Receipt[];
    call: Call;

    emailed = false;
    retailStateOptLicense: string;
    pendingOrderStatus = OrderStatuses.Pending;

    isCustomerServiceOrAdmin = false;
    receiptsEmailed = false;
    shareEmployees: Employee[];
    shareComments: string;

    private shareOverlayRef: SwisherOverlayRef<
        ShareOptionsDialogViewmodel,
        ShareOptionsDialogComponent
    >;

    constructor(
        private ref: SwisherOverlayRef<any, any>,
        private overlayService: OverlayService,
        private orderService: OrderOnlineService,
        private receiptDelineationService: ReceiptDelineationService,
        private snackbarService: SnackbarService,
        private callDelineationService: CallDelineationService,
        private customerDelineationService: CustomerDelineationService,
        private notificationDelineationService: NotificationDelineationService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.content = this.ref.content;
        this.data = this.ref.data;
        this.isCustomerServiceOrAdmin = this.data.csOrAdmin;
        const customerResponse = await this.customerDelineationService.getById(this.data.customerId);
        if (!customerResponse) { return; }
        const customer = customerResponse.values;

        if (customer?.customerLicenses?.length) {
            const customerLicense = customer.customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.RetailStateOTPLicense && cl.isActive);
            if (customerLicense) {
                this.retailStateOptLicense = customerLicense.licenseNumber;
            }
        }
        this.setDataMappings();

        if (typeof this.content === "string") {
            this.contentType = "string";
            this.contentString = this.content;
        } else if (this.content instanceof TemplateRef) {
            this.contentType = "template";
            this.contentTemplateRef = this.content;
            this.context = {
                close: this.ref.close.bind(this.ref),
                data: this.ref.data
            };
        } else {
            this.contentType = "component";
            this.contentComponent = this.content;
        }
        const callResponse = await this.callDelineationService.getOnlineCallById(
            this.data.callId
        );

        if (!callResponse) { return; }

        this.call = callResponse.values;

        const response = await this.receiptDelineationService.getCallReceiptsByCall(
            this.call
        );

        if (response) {
            this.receipts = response.values ?? new Array<Receipt>();
        } else {
            this.receipts = new Array<Receipt>();
        }
    }

    close(): void {
        this.ref.close(this.ref.data);
    }

    ngAfterContentChecked(): void {
        if (this.ref.data?.needsMappingsRefreshed) {
            this.setDataMappings();

            this.ref.data.needsMappingsRefreshed = false;
        }
    }

    cancelSingleOrderWarning(): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Cancel Single Order";
        data.message =
            "Cancelling this order cannot be undone. Do you wish to proceed?";
        data.buttonLeftText = "Cancel";
        data.buttonLeftFunction = () => {
            this.confirmationOverlayRef.close(data);
        };
        data.buttonRightText = "Proceed";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.confirmationOverlayRef.close(data);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe(async (event) => {
            if (event && event.data && event.data.isConfirmed) {
                const cancelled = await this.orderService.cancelOrder(
                    this.data.id
                );
                if (cancelled) {
                    this.ref.data.isConfirmed = true;
                    this.ref.data.affected = 1;
                    this.confirmationOverlayRef.close(data);
                    this.close();
                }
            }
        });
    }

    cancelCallOrdersWarning(): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Cancel All Orders from Call";
        data.message =
            "Cancelling all orders from this call cannot be undone. Do you wish to proceed?";
        data.buttonLeftText = "Cancel";
        data.buttonLeftFunction = () => {
            this.confirmationOverlayRef.close(data);
        };
        data.buttonRightText = "Proceed";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.confirmationOverlayRef.close(data);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe(async (event) => {
            if (event && event.data && event.data.isConfirmed) {
                const cancelled = await this.orderService.cancelCallOrders(
                    this.data.id
                );
                if (cancelled) {
                    this.ref.data.affected = cancelled;
                    this.ref.data.isConfirmed = true;
                    this.confirmationOverlayRef.close(data);
                    this.close();
                }
            }
        });
    }

    setDataMappings(): void {
        this.headerLeftText = this.ref.data?.headerLeftText;
        this.buttonLeftText = this.ref.data?.buttonLeftText ?? "Cancel";
        if (!this.ref.data?.buttonLeftFunction) {
            this.buttonLeftFunction = () => this.ref.close();
        } else {
            this.buttonLeftFunction = this.ref.data?.buttonLeftFunction;
        }
        if (!this.ref.data?.buttonLeftDisabledFunction) {
            this.buttonLeftDisabledFunction = () => {
                return false;
            };
        } else {
            this.buttonLeftDisabledFunction = this.ref.data?.buttonLeftDisabledFunction;
        }

        this.headerRightText = this.ref.data?.headerRightText;
        this.buttonRightText = this.ref.data?.buttonRightText;
        if (!this.ref.data?.buttonRightFunction) {
            this.buttonRightFunction = () => this.ref.close();
        } else {
            this.buttonRightFunction = this.ref.data?.buttonRightFunction;
        }
        if (!this.ref.data?.buttonRightDisabledFunction) {
            this.buttonRightDisabledFunction = () => {
                return false;
            };
        } else {
            this.buttonRightDisabledFunction = this.ref.data?.buttonRightDisabledFunction;
        }

        this.width = this.ref.data?.width;
        this.height = this.ref.data?.height;

        this.showFooter = !!this.buttonLeftText || !!this.buttonRightText;
    }

    async reprint(): Promise<void> {
        if(!this.receipts?.length) {
            this.snackbarService.showWarning("No receipt was found in local or the server storage location");
            return;
        }
        if (this.call.callType === CallTypes.retail) {
            const ids = this.call.callReceipts.filter(
                (cr) => cr.wholesalerId && cr.wholesalerId === this.data.wholesalerId
            )?.map((cr) => cr.id);
            if (ids?.length) {
                const found = this.receipts.filter((r) => ids.includes(r.id));
                if (found) {
                    Helper.addIFrameImage(document, found.map((r) => r.base64Image));
                }
            }
        }
    }
    async email(): Promise<void> {
        const data: ShareOptionsDialogViewmodel = new ShareOptionsDialogViewmodel();
        data.shareEmployees = this.shareEmployees;
        data.confirmButtonText = "Share Receipt";
        data.comments = this.shareComments;
        data.onClose = (closeData: ShareOptionsDialogViewmodel) => {
            this.shareComments = closeData.comments;
            this.shareEmployees = closeData.shareEmployees;
        }
        data.onSaveShare = async (
            saveData: ShareOptionsDialogViewmodel
        ): Promise<boolean> => {
            const ids = saveData.shareEmployees.map((e) => e.id);
            this.shareComments = saveData.comments;
            let isSuccessfull = true;

            if (this.receipts?.length) {

                this.receiptsEmailed = true;
                for (const employeeId of ids) {
                    const response = await this.notificationDelineationService.emailReceipt(employeeId, this.call.id, this.receipts[0].id,
                        this.data.receiptNumber, EmailReceiptLocationOrigins.order, this.shareComments);
                    if (response) {
                        isSuccessfull = isSuccessfull && !!response && !response.isError;
                    } else {
                        isSuccessfull = false;
                    }
                    return isSuccessfull;
                }
            }
            return undefined;
        }
        this.shareOverlayRef = this.overlayService.open(
            ShareOptionsDialogComponent,
            data
        );

        this.shareOverlayRef.afterClosed$.pipe().subscribe(async (ref) => {
            if (ref?.data?.promise) {
                const isSuccess = await ref.data.promise;
                if (isSuccess) {
                    this.snackbarService.showInfo("Email sent successfully!");
                }
            }
        })
    }
}
