export class CreateModifyBaseEntityDto {
    createdUserId: string;
    createdUserZrt: string;
    createdUtcDateTime: Date | null;
    createdUserFullName: string;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUtcDateTime: Date | null;
    modifiedUserFullName: string;
}
