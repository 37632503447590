import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    GenericVersionResponseDto,
    Px3RankDto,
    Px3RankSyncCommand,
    SyncCommandTypes,
    VersionResponseDto
} from "shield.shared";
import { Px3Rank } from "src/app/entity-models/px3-rank.entity";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { SyncLevel } from "../sync-enums/sync-level.enum";
import { SyncHandler } from "./sync-handler";
@Injectable()
export class Px3RankSyncHandler
    extends SyncHandler<Px3Rank, Px3RankDto>
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.Initial;
    offlineRepSyncType = SyncLevel.Initial;

    constructor(
        dbService: DatabaseService,
        http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super(
            "Px3 Ranks", 
            SyncCommandTypes.px3Rank, 
            SyncVersionKeyNames.px3Rank, 
            '/api/px3Ranks', 
            dbService.px3Ranks, 
            http
        );
    }

    protected entityToDto(entity: Px3Rank): Px3RankDto {
        return {...entity};
    }

    protected dtoToEntity(dto: Px3RankDto): Px3Rank {
        return {...dto};
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<Px3RankSyncCommand>(
            this.syncCommandType,
            async (message) => {
                let result = await this.table.get(
                    message.payload.id
                );

                let dto = this.entityToDto(result);
                if (dto) {
                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }


}
