export class CustomerLicense {
    id: string;
    customerId: string;
    licenseNumber: string;
    licenseTypeId: number;
    isActive: boolean;
    createdUtcDateTime: Date;
    createdUserId: string | null;
    createdUserZrt: string | null;
    createdUserFullName: string | null;
    modifiedUtcDateTime: Date | null;
    modifiedUserId: string | null;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string | null;
}
