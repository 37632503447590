import { TransactionLineItemType } from "shield.shared";

export class TransactionLineItem {
    id: string;
    type: TransactionLineItemType;
    typeDescription: string;
    productId: string;
    productDescription: string;
    quantity: number;
    units: number;
    price: number;
    discount: number | null;

    // Order-specific vars
    wholesalerId: string | null;
    wholesalerName: string | null;
    uin: string | null;
    upc: string | null;
    wholesalePrice: number | null;
    wholesaleTotal: number | null;

    // Gratis-specific vars
    gratisTotal: number | null;

    // Calculated vars
    salesTotal: number;
    netTotal: number;
}
