import { Component, OnInit } from "@angular/core";
import { HeaderButtonComponent } from "../header-button/header-button.component";
import {
    faPrint,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { ButtonActions } from "../../../enums/button-actions.enum";

@Component({
    selector: "app-print-button",
    templateUrl: "./print-button.component.html",
    styleUrls: ["./print-button.component.css"]
})
export class PrintButtonComponent
    implements HeaderButtonComponent {
    icon: IconDefinition = faPrint;
    title = "Print";
    buttonAction = ButtonActions.print;
    isDisabled: boolean;
}
