import { AccountOwnershipHierarchyLevel, GenericLookup } from "shield.shared";

export class AccountOwnership {
    id: string;
    businessOwnerCode: string;
	ownerCode: string;
    customerId: string | null;
	parentAccountOwnershipId: string | null;
	parentOwnerCode: string | null;
	hierarchyLevel: GenericLookup<AccountOwnershipHierarchyLevel>;
	name: string;
    assignedEmployeeId: string | null;
	commonOperatingName: string | null;
	familyCode: string | null;
	isDeactivated: boolean | null;
    storeCount: number | null;
    rowversion: string;
    createdUtcDateTime: Date | null;
    createdUserFullName: string | null;
    modifiedUtcDateTime: Date | null;
    modifiedUserFullName: string | null;
    searchKey: string | null;

    children?: AccountOwnership[];

    hasServerProcessed: number;
}
