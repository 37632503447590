export class FilterDto {
    //Usage: Used for filter accounts on the account list page
    //Possible data: account name, account GUID/Id or account number
    account?: string;

    //Usage: Not used yet
    accountIds?: string[];

    //Usage: Getting accounts/customers based on it account ownership
    //Possible data: Account ownership GUIDs
    accountOwnershipIds?: string[];

    //Usage: Get based on areas
    areas?: string[];

    //Usage: Getting accounts/customers based on contact availability days
    //Possible data: array of U, M, T, W, R, F, S
    availability?: string[];

    //Usage: Getting accounts/customers based on its callable status
    callable?: boolean;

    //Usage: Getting accounts/customers based on its type
    callType?: string[];

    //Usage: Getting contracts based on its number
    contractNumber?: string;

    //Usage: Getting contracts based on its template id
    contractTemplateIds?: string[];

    //Usage: Getting data based on the creating user full name
    createdUserFullName?: string;

    //Usage: Getting data based on the creating user ids
    createdUserIds?: string[];

    //Usage: Get data based on associated account/customer ids
    customerIds?: string[];

    //Usage: Get gratis based on days waiting
    daysWaiting?: number;

    //Usage: Get data based on employee ids
    employeeIds?: string[];

    //Usage: Only get data for the last call
    getLastCalls?: boolean;

    //Usage: Get if phone exist
    hasPhone?: boolean;

    //Usage: Get if pictures exist
    hasPictures?: boolean;

    //Usage: Get if receipts exist
    hasReceipt?: string;

    //Usage: Get data based on ids
    ids?: string[];

    //Usage: Get if active
    isActive?: boolean;

    //Usage: Get assigned (or not assigned) accounts/customers to projects
    isAssigned?: boolean;

    //Usage: Get if the associated customer is an Msa
    isMsa?: string;

    //Usage: Get data based on name
    name?: string;

    //Usage: Get based on associated order type
    orderType?: string;

    //Usage: Get based on associated order status
    orderStatus?: string;

    //Usage: Get based on associated order line item status
    orderLineItemStatus?: string;

    //Usage: Get based on associated payment method
    paymentMethod?: string;

    //Usage: get data based on the payment status id
    paymentStatusId?: string

    //Usage: Get based on associated payment type
    paymentType?: string;

    //Usage: Get based on associated phone number
    phone?: string;

    //Usage: Get base on associated picture type
    pictureType?: string;

    //Usage: Get data based on associated products ids
    productIds?: string[];

    //Usage: Get data based on associated products ids in dist
    productIdsInDist?: string[];

    //Usage: Get data based on associated products ids not in dist
    productIdsNotInDist?: string[];

    //Usage: Get based on associated contract program level
    programLevel?: string;

    //Usage: Get based on associated project ids
    projectIds?: string[];

    //Usage: Get based on associated project status
    //MV - Should be deprecated
    projectStatus?: string;

    //Usage: Get based on associated project status id
    projectStatusId?: string;

    //Usage: Get based on associated project type
    //MV - Should be deprecated (copy the incorrect spelling WOOT)
    projectType?: string;

    //Usage: Get based on associated project type id
    projectTypeId?: string;

    //Usage: Get based on associated project purpose
    purpose?: string;

    //Usage: Get based on the selected px3 rank
    px3Rank?: string[];

    //Usage: Get based on remaining gratis amount
    remainingGratis?: number;

    //Usage: Get based on associated route description
    routeDescription?: string;

    //Usage: Get based on associated route name
    routeName?: string;

    //Usage: Get based on associated route zrt
    routeZrt?: string;

    //Usage: Get based on status
    status?: string;

    //Usage: Get based on store types
    storeTypes?: string[];

    //Usage: Get based on tags
    tags?: string[];

    //Usage: Get based on associated transaction type
    transactionType?: string;

    //Usage: Get based on associated transaction type id
    transactionTypeId?: string;

    //Usage: Get based on volume amount
    volume?: string;

    //Usage: Get based on associated product UIN
    uin?: string;

    //Usage: Get data based on wholesaler Ids
    wholesalerIds?: string[];

    //Usage: Get based on Zrt
    zrts?: string[];


    /*
     * BUSINESS ADDRESS section
     */

    //Usage: Get data based on the business street/address1
    businessStreet?: string;

    //Usage: Get data based on the business city
    businessCity?: string;

    //Usage: Get data based on the business county name
    //MV - I would like to deprecate this.
    businessCounties?: string[];

    //Usage: Get data based on the business county Ids
    businessCountyIds?: string[];

    //Usage: Get data based on the business state name
    //MV - I would like to deprecate this.
    businessStates?: string[];

    //Usage: Get data based on the business state Ids
    businessStateIds?: string[];

    //Usage: Get data based on the business zip/postal code
    businessZipCode?: string;


    /*
     * DRIVING ADDRESS section
     */

    //Usage: Get data based on the business street/address1
    drivingStreet?: string;

    //Usage: Get data based on the business city
    drivingCity?: string;

    //Usage: Get data based on the business county name
    //MV - I would like to deprecate this.
    drivingCounties?: string[];

    //Usage: Get data based on the business county Ids
    drivingCountyIds?: string[];

    //Usage: Get data based on the business state name
    //MV - I would like to deprecate this.
    drivingStates?: string[];

    //Usage: Get data based on the business state Ids
    drivingStateIds?: string[];

    //Usage: Get data based on the business zip/postal code
    drivingZipCode?: string;


    /*
     * DATES section
     *     Possible data: ISO string
     */

    //Usage:
    startOnOrAfterDate?: string;
    startOnDate?: string; //Server will convert to YYYYMMDD
    startOnOrBeforeDate?: string;

    //Usage:
    endOnOrAfterDate?: string;
    endOnDate?: string; //Server will convert to YYYYMMDD
    endOnOrBeforeDate?: string;

    //Usage: Get data based on call/date
    callOnOrAfterDate?: string;
    callOnOrBeforeDate?: string;

    //Usage: Get data based on order call/date
    orderOnOrAfterDate?: string;
    orderOnOrBeforeDate?: string;

    //Usage: Get data based on payment call/date
    paymentOnOrAfterDate?: string;
    paymentOnOrBeforeDate?: string;

    //Usage: Get data based on route call/date
    routeOnOrAfterDate?: string;
    routeOnOrBeforeDate?: string;

    //Usage: Get data based on signed on call/date
    signedOnOrAfterDate?: string;
    signedOnOrBeforeDate?: string;

}
