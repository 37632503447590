export class CustomerLicenseDto {
    id: string;
    customerId: string;
    licenseNumber: string;
    licenseTypeId: number;
    isActive: boolean;
    createdUserId: string | null;
    createdUtcDateTime: string;
    createdUserZrt: string | null;
    createdUserFullName: string | null
    modifiedUtcDateTime: string | null;
    modifiedUserId: string | null;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string | null;
}
