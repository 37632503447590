import { BaseFilterMapService } from "./base-filter-map.service";
import { ProjectAssignmentCountFilterDto, ProjectAssignmentSummaryOptions } from "shield.shared"

export class ProjectAssignmentCountFilterMapService extends BaseFilterMapService {


    static mapFilterData(option: ProjectAssignmentSummaryOptions): ProjectAssignmentCountFilterDto {

        if (!option) return;

        const filterDto = new ProjectAssignmentCountFilterDto();

        switch (option) {
            case ProjectAssignmentSummaryOptions.Assigned:
                filterDto.assigned = true;
                return filterDto;
            case ProjectAssignmentSummaryOptions.City:
                filterDto.city = true;
                return filterDto;
            case ProjectAssignmentSummaryOptions.County:
                filterDto.county = true;
                return filterDto;
            case ProjectAssignmentSummaryOptions.State:
                filterDto.state = true;
                return filterDto;
            case ProjectAssignmentSummaryOptions.Zip:
                filterDto.zip = true;
                return filterDto;
            case ProjectAssignmentSummaryOptions.Chain:
                filterDto.chain = true;
                return filterDto;
        }
    }
}
