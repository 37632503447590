export class ProjectMetricsGridViewModel {
    name: string;

    // zrt/product specific
    assigned?: number;
    calledOn?: number;
    notCalled?: number;
    storesWithOrders?: number;
    cashCount?: number;
    cashAmount?: number;
    cashTotalSticks?: number;
    orderCount?: number;
    orderAmount?: number;
    orderTotalSticks?: number;
    gratisAmount?: number;
    gratisPercentage?: number;

    // wholesaler specific
    totalCalls?: number;
    callsWithWholesaler?: number;
    callsWithoutWholesaler?: number;
}
