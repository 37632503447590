import { Subscription } from "rxjs";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { Country } from "src/app/entity-models/country.entity";
import { County } from "src/app/entity-models/county.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { State } from "src/app/entity-models/state.entity";
import { Helper } from "src/app/helpers/helper";
import { FilterService } from "src/app/services/filter.service";
import { CountyViewmodel } from "../../viewmodels/county.viewmodel";
import { NamedIterables } from "../../viewmodels/named-iterables.viewmodel";
import { StateViewmodel } from "../../viewmodels/state.viewmodel";


export class ProjectAssignmentsViewmodel {
    //public vars
    chainsSubscription: Subscription;
    chains = new Array<AccountOwnership>();
    selectedChains = new Array<AccountOwnership>();
    selectedChainsHold = new Array<AccountOwnership>();
    isChainsStale: boolean;
    chainsDropdownSettings = {
        text: "Chains",
        singleSelection: false,
        autoPosition: false,
        unSelectAllText: "Deselect All",
        filterUnSelectAllText: "Deselect all filtered results",
        enableCheckAll: false,
        enableFilterSelectAll: true,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "name",
        searchBy: ["name"],
        noDataLabel: "Loading chains...",
        classes: "multi-select-container c-btn"
    }
    cityInput: string;
    counties = new Array<County>();
    countries = new Array<Country>();
    filteredStates = new Array<StateViewmodel>();
    filteredCounties = new Array<CountyViewmodel>();
    filterService: FilterService;
    groupedCountiesByState = new Array<NamedIterables<CountyViewmodel>>();
    locationZrtOptions = new Array<Refiner>();
    selectedCounties = new Array<CountyViewmodel>();
    selectedLocationZrts = new Array<Refiner>();
    selectedStates = new Array<StateViewmodel>();
    states = new Array<State>();
    storeNameCustomerId: string;
    streetInput: string;
    zipInput: string;

    //https://cuppalabs.github.io/components/multiselectDropdown/
    dropdownSettings = {
        singleSelection: false,
        showCheckbox: true,
        text: "County",
        selectAllText: "Select All",
        enableCheckAll: true,
        enableFilterSelectAll: true,
        enableSearchFilter: true,
        badgeShowLimit: 1,
        groupBy: "groupBy",
        selectGroup: true,
        labelKey: "displayValue",
        searchBy: ["displayValue"],
        classes: "multi-select-container c-btn pure-checkbox",
        unSelectAllText: 'UnSelect All',
        searchPlaceholderText: 'Search',
        disabled: !this.selectedStates?.length
    }


    constructor(filterService: FilterService) {
        this.filterService = filterService;
        this.initializeLocationDropDowns();
    }

    //public methods
    adjustCountySelectOptions(): CountyViewmodel[] {
        let rtn = new Array<CountyViewmodel>();
        const states = this.selectedStates.map((vm) => vm.state);
        states.sort((a, b) => a.name > b.name ? 1 : -1);
        const stateIds = states.map((s) => s.id);

        const counties = this.counties.filter((c) =>
            stateIds.includes(c.stateId)
        );

        const filteredCounties: CountyViewmodel[] = [];

        for (const state of states) {
            for (const county of counties.filter((c) => c.stateId === state.id).sort((a, b) => a.name > b.name ? 1 : -1)) {
                const foundState = states.find((s) => s.id === county.stateId);
                if (foundState) {
                    const vm = new CountyViewmodel(county, foundState);
                    filteredCounties.push(vm);
                }
            }
        }

        const selectedCounties = this.selectedCounties;
        this.filteredCounties = filteredCounties;

        const groupedCountiesByState: Map<
            string,
            CountyViewmodel[]
        > = Helper.groupBy(
            filteredCounties,
            (groupedCounty: CountyViewmodel) => groupedCounty.state.name
        );


        this.groupedCountiesByState = Array.from(groupedCountiesByState)
            .map((el) => new NamedIterables(el[0], el[1]))
            .sort((a, b) => (a.name >= b.name ? 1 : -1));

        this.selectedCounties = selectedCounties.filter((sc) =>
            this.groupedCountiesByState.find((gc) => gc.name === sc.state.name)?.items?.filter((countyVm) => countyVm.county.name === sc.county.name));

        rtn = selectedCounties.filter((sc) => this.selectedCounties.find((msc) => msc.state.id === sc.state.id
            && msc.county.id === sc.county.id));

        return rtn;
    }

    async initializeLocationDropDowns(): Promise<void> {
        this.countries = await this.filterService.getCountries();
        this.states = await this.filterService.getStates();
        this.counties = await this.filterService.getCounties();
        const filteredCountries = this.countries.slice();
        const selectedCountries = filteredCountries.filter(
            (c) => c.name === "United States of America"
        );

        const countryIds = selectedCountries.map((c) => c.id);
        const states = this.states
            .map((c) => { c.name.toLocaleLowerCase(); return c;})
            .slice()
            .filter((s) => countryIds.includes(s.countryId));

        const filteredStates: StateViewmodel[] = [];
        for (const state of states) {
            if (countryIds.includes(state.countryId)) {
                const vm = new StateViewmodel();
                vm.state = state;
                vm.country = selectedCountries.find(
                    (c) => c.id === vm.state.countryId
                );
                filteredStates.push(vm);
            }
        }
        this.filteredStates = filteredStates.sort((a, b) =>
            a.state.name >= b.state.name ? 1 : -1
        );
    }
}
