import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    CustomerDto, GenericResponseDto, OrderDto,
    OrderExtractionDto, StringArrayDto, newSequentialId,
    OrderBatchParamsDto, GenericRequestDto, EmailOrderParamsDto,
    OrdersByWholesalersParamsDto, ChangeOrderDateParamsDto, ChangeUinParamsDto,
    MoveOrderProductsParamsDto,
    FilterRequestV2Dto,
    OrderListFilterDto,
    OrderListColumns,
    NotificationRequestDto,
} from "shield.shared";
import { Customer } from "../../entity-models/customer.entity";
import { OrderExtraction } from "../../entity-models/order-extraction.entity";
import { Order } from "../../entity-models/order.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { CustomerConverterService } from "../converter-services/customer-converter.service";
import { OrderConverterService } from "../converter-services/order-converter.service";

@Injectable()
export class OrderOnlineService {
    constructor(private http: HttpClient) {}

    async getOrder(id: string): Promise<GenericResponseDto<Order>> {

        const result = await this.http
            .get<GenericResponseDto<OrderDto>>(`/api/orders/${id}`)
            .toPromise();

        return ConverterHelper.dtoToEntity(result, (r) => OrderConverterService.orderDtoToOrder(r));
    }

    async getBatch(
        key: OrderBatchParamsDto
    ): Promise<GenericResponseDto<Order[]>> {

        const request = new GenericRequestDto<FilterRequestV2Dto<OrderListFilterDto, OrderListColumns>>();
        request.id = newSequentialId();
        request.values = key.filterRequestDto;

        const result = await this.http.post<GenericResponseDto<OrderDto[]>>("/api/orders", request).toPromise();

        return ConverterHelper.dtoToEntityArray(result, (r) => OrderConverterService.orderDtoToOrder(r));
    }

    async changeOrderDate(params: ChangeOrderDateParamsDto): Promise<GenericResponseDto<Order>> {

        const response = await this.http
            .get<GenericResponseDto<OrderDto>>(
                `/api/orders/${params.id}/move/${params.orderDate.set("hour", 0).set("minute", 0).set("second", 0).set("milliseconds", 0).toISOString()}`,
            ).toPromise();

        return ConverterHelper.dtoToEntity(response, (r) => OrderConverterService.orderDtoToOrder(r));
    }

    async changeWholesalerUin(params: ChangeUinParamsDto): Promise<GenericResponseDto<boolean>> {

        const response = await this.http
            .get<GenericResponseDto<boolean>>(
                `/api/orders/change-uin/wholesaler-id/${params.wholesalerId}/old-uin/${params.oldUin}/new-uin/${params.newUin}`,
            ).toPromise();

        return response;
    }

    async movePendingOrderProductsToNewOrder(params: MoveOrderProductsParamsDto): Promise<GenericResponseDto<Order>> {

        const body = new StringArrayDto();
        body.values = params.lineItemIds;
        const response = await this.http
            .post<GenericResponseDto<OrderDto>>(
                `/api/orders/line-item/move/new-order/${params.orderDate.set("hour", 0).set("minute", 0).set("second", 0).set("milliseconds", 0).toISOString()}`,
                body
            ).toPromise();

        return ConverterHelper.dtoToEntity(response, (r) => OrderConverterService.orderDtoToOrder(r));
    }

    async moveRejectedProductsToNewOrder(params: MoveOrderProductsParamsDto): Promise<GenericResponseDto<Order>> {

        const body = new StringArrayDto();
        body.values = params.lineItemIds;
        const response = await this.http
            .post<GenericResponseDto<OrderDto>>(
                `/api/orders/line-item/move-rejected/new-order/${params.orderDate.set("hour", 0).set("minute", 0).set("second", 0).set("milliseconds", 0).toISOString()}`,
                body
            ).toPromise();

        return ConverterHelper.dtoToEntity(response, (r) => OrderConverterService.orderDtoToOrder(r));
    }

    async emailOrder(params: EmailOrderParamsDto): Promise<GenericResponseDto<undefined>> {
        const request = new GenericRequestDto<NotificationRequestDto>();
        request.id = newSequentialId();
        request.values = params.request;

        await this.http
            .post(
                `/api/calls/${params.callId}/receipt/${params.receiptId}/email`,
                request
            )
            .toPromise();

        return new GenericResponseDto<undefined>();
    }

    async cancelOrder(id: number): Promise<GenericResponseDto<boolean>> {

        let rtn = new GenericResponseDto<boolean>();
        rtn.values = false;

        const isCancelled = await this.http
        .get<boolean>(`/api/orders/${id}/cancel`)
        .toPromise();

        if (isCancelled) {
            rtn.values = true;
        }

        return rtn;
    }

    async cancelCallOrders(id: number): Promise<GenericResponseDto<number>> {

        let rtn = new GenericResponseDto<number>();

        const isCancelled = await this.http
        .get<number>(`/api/orders/${id}/cancelbycall`)
        .toPromise();

        if (isCancelled) {
            rtn.values = isCancelled;
        }

        return rtn;
    }

    async getWholesalersWithOrders(key: undefined): Promise<GenericResponseDto<Customer[]>> {

        const result = await this.http
        .get<GenericResponseDto<CustomerDto[]>>(`/api/orders/by-wholesaler/with-orders`)
        .toPromise();

        return ConverterHelper.dtoToEntityArray(result, (r) => CustomerConverterService.customerDtoToCustomer(r));
    }

    async getAvailableOrdersByWholesaler(params: OrdersByWholesalersParamsDto): Promise<GenericResponseDto<Order[]>> {

        const result = await this.http
            .post<GenericResponseDto<OrderDto[]>>(
                `/api/orders/by-wholesaler/${params.wholesalerId}/available-for-extraction`,
                params.filterRequestDto
            )
            .toPromise();

        return ConverterHelper.dtoToEntityArray(result, (r) => OrderConverterService.orderDtoToOrder(r));
    }

    async getOrdersByExtraction(extractionId: string): Promise<GenericResponseDto<Order[]>> {

        const result = await this.http
            .get<GenericResponseDto<OrderDto[]>>(
                `/api/orders/by-extraction/${extractionId}`
            )
            .toPromise();

        return ConverterHelper.dtoToEntityArray(result, (r) => OrderConverterService.orderDtoToOrder(r));
    }

    async getExtractionsByWholesaler(params: OrdersByWholesalersParamsDto): Promise<GenericResponseDto<OrderExtraction[]>> {

        const result = await this.http
            .post<GenericResponseDto<OrderExtractionDto[]>>(`/api/orders/extract/by-wholesaler/${params.wholesalerId}/get`, params.filterRequestDto)
            .toPromise();

        return ConverterHelper.dtoToEntityArray(result, (r)=> OrderConverterService.orderExtractionDtoToOrderExtraction(r));
    }

    extractByWholesaler(wholesalerId: string): Observable<Blob> {
        return this.http.get(`/api/orders/extract/by-wholesaler/${wholesalerId}/get-file`, { responseType: 'blob' })
    }

    extractByParams(params: OrdersByWholesalersParamsDto): Observable<Blob> {
        return this.http.post(`/api/orders/extract/by-wholesaler-and-search/${params.wholesalerId}/get-file`,
            params.filterRequestDto, { responseType: 'blob' })
    }

    getExtractionFile(extractionId: string): Observable<Blob> {
        return this.http.get(`/api/orders/extract/${extractionId}/get-file`, { responseType: 'blob' })
    }
}
