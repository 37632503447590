import { Component } from "@angular/core";

@Component({
    selector: "app-progress-spinner-dialog",
    templateUrl: "./progress-spinner-dialog.component.html",
    styleUrls: ["./progress-spinner-dialog.component.scss"]
})

export class ProgressSpinnerDialogComponent {

    enableClickClang = false;
    text = "Retrieving Data";

    clickLogo(e: { preventDefault: () => void; target: any; }) {
        e.preventDefault();

        let el = e.target;

        el.classList.remove('loader-spin');

        if (this.enableClickClang) {
            let sound = new Audio('../../../assets/sounds/metallic-strike.wav');
            sound.play();
        }

        setTimeout(() => {
            el.classList.add('loader-spin');
        }, 10)
    }
}
