import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";

export class ProjectSummaryAssignmentsGridViewmodel {

    territory: string;
    first: string;
    last: string;
    assignment: string;

    constructor(employee: Employee, assignmentCount: number){
        if (!employee) return;

        this.territory = employee.zrt;
        this.first = employee.firstName;
        this.last = employee.lastName;
        this.assignment = (assignmentCount ?? 0).toString();
    }
}
