import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { throttleTime } from "rxjs/operators";

@Directive({
    selector: "[appThrottleClick]"
})
export class ThrottledClickDirective implements OnInit, OnDestroy {
    @Input()
    throttleTime = 500;

    @Output()
    throttledClick = new EventEmitter<PointerEvent>();

    private clicks = new Subject();
    private subscription: Subscription;

    constructor() {}

    ngOnInit() {
        this.subscription = this.clicks.pipe(
            throttleTime(this.throttleTime)
            ).subscribe((e: PointerEvent) =>
                this.emitThrottledClick(e)
            );
    }

    emitThrottledClick(e: PointerEvent) {
        this.throttledClick.emit(e);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    @HostListener("click", ["$event"])
    clickEvent(event: PointerEvent) {
        this.clicks.next(event);
    }
}
