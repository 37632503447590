<ng-container *ngIf="viewmodel">
<ng-container>
    <div class="row">
        <div class="col-12">
            <app-page-header #header [name]="viewmodel.headerName"
                [entryCount]="viewmodel.total ? viewmodel.total : 0" [buttons]="viewmodel.headerButtons"
                [refinerService]="viewmodel.refinerService" [subAvailableColumns]="viewmodel.availableColumns"
                (onClickButton)="viewmodel.onButtonClickEvent($event)"
                (onButtonAction)="viewmodel.onButtonAction($event)"
                (headerExpansionChanged)="viewmodel.headerExpansionChanged()">
            </app-page-header>
        </div>
    </div>
    <div class="d-flex p-2 justify-content-center align-items-center">
        <div>
            <button class="btn btn-secondary mr-2" data-toggle="tooltip" data-placement="bottom" title="View Filters"
                (click)="viewmodel.showFilters()">
                <span class="white-space-nowrap">
                    <span class="material-icons">filter_alt</span>
                    Filters •
                    {{ viewmodel.refinerService.refiners.length }}
                </span>
            </button>
        </div>
        <div class="flex-grow-1">
            <mat-form-field appearance="outline" color="accent" [formGroup]="viewmodel.dateForm">
                <mat-label>Call on or After</mat-label>
                <input #startDateInput matInput formControlName="startDate" [max]="viewmodel.startMaxDate"
                    [matDatepicker]="afterPicker" (keyup)="viewmodel.onAddStartDateRefiner($event)"
                    (dateChange)="viewmodel.onAddStartDateRefiner()"
                    (blur)="viewmodel.onAddStartDateRefiner($event)" />
                <mat-datepicker touchUi #afterPicker></mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="afterPicker"></mat-datepicker-toggle>
            </mat-form-field>
            <mat-error *ngIf="
                    viewmodel.dateForm.invalid
                ">
                Date format invalid.
            </mat-error>
            <mat-error *ngIf="
                    viewmodel.dateForm
                        .get('startDate')
                        .hasError('dateGreaterThanEndDate')
                ">
                Start date can not be greater than End date.
            </mat-error>
        </div>
        <div class="flex-grow-1">
            <mat-form-field appearance="outline" color="accent" [formGroup]="viewmodel.dateForm">
                <mat-label>Call on or Before</mat-label>
                <input #endDateInput matInput formControlName="endDate" [min]="viewmodel.endMinDate"
                    [max]="viewmodel.endMaxDate" [matDatepicker]="beforePicker"
                    (dateChange)="viewmodel.onAddEndDateRefiner()"
                    (keyup)="viewmodel.onAddEndDateRefiner($event)" (blur)="viewmodel.onAddEndDateRefiner($event)" />
                <mat-datepicker touchUi #beforePicker></mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="beforePicker"></mat-datepicker-toggle>
            </mat-form-field>
            <mat-error *ngIf="
                    viewmodel.dateForm.invalid
                ">
                Date format invalid.
            </mat-error>
            <mat-error *ngIf="
                    viewmodel.dateForm
                        .get('endDate')
                        .hasError('dateLessThanStartDate')
                ">
                End date can not be less than Start date.
            </mat-error>
        </div>
        <div class="flex-grow-1">
            <mat-form-field appearance="outline" class="flex-grow-1 mat-input-search" color="accent">
                <mat-label id="store-customerId">Store Name</mat-label>
                <input
                    #accountSearchInput
                    id="transaction-store-customerId-input"
                    type="text"
                    (keyup)="viewmodel.onAddAccountRefiner($event)"
                    [(ngModel)]="viewmodel.accountInput"
                    matInput
                />
            </mat-form-field>
        </div>
        <div class="d-flex">
            <button appThrottleClick mat-raised-button (throttledClick)="viewmodel.search()" [disabled]="viewmodel.isSearchButtonDisabled" class="btn btn-primary mr-2">SEARCH</button>
            <button mat-raised-button (click)="viewmodel.reset()" class="btn btn-secondary">RESET</button>
        </div>
    </div>
</ng-container>
<mat-sidenav-container class="sidenav-container d-flex flex-column flex-grow-1" autosize>
    <mat-sidenav #drawer [mode]="viewmodel.drawerMode" [fixedInViewport]="true"
        [fixedTopGap]="viewmodel.topGapDistance" [fixedBottomGap]="0" class="sidenav">
        <app-filter-container [refinerService]="viewmodel.refinerService" [filters]="viewmodel.filters"
            [isSearchDisabled]="viewmodel.isSearchButtonDisabled" [filterLocation]="viewmodel.filterLocation"
            (search)="viewmodel.search()" (reset)="viewmodel.reset()">
        </app-filter-container>
    </mat-sidenav>
    <mat-sidenav-content #sideNavContent>
        <div class="sidenav-content d-flex flex-column flex-grow-1">
            <ng-container>
                <div class="table-responsive d-flex flex-column flex-grow-1">
                    <app-grid #grid [(dataSource)]="viewmodel.dataSource" [columnDefinitions]="viewmodel.columnDef"
                        [detailTemplate]="detailTemplate" [searchRefiners]="viewmodel.activeRefiners"
                        [isFixedLayout]="viewmodel.isFixedLayout" [height]="viewmodel.gridHeight"
                        [isEnabledExpandedDetails]="viewmodel.isEnabledExpandedDetails"
                        [itemsRendedInViewPort]="viewmodel.itemsRendedInViewPort"
                        [detailHeight]="viewmodel.detailHeight" [sortFunction]="viewmodel.sortFunction"
                        (getDataBatch)="viewmodel.getCallBatch($event)"
                        class="d-flex flex-column flex-grow-1">
                    </app-grid>
                </div>
            </ng-container>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #detailTemplate let-data="data">
    <table *ngIf="data.length > 0" class="table-sm table-borderless">
        <thead class="detail-table-head">
            <tr>
                <th class="detail-table-col h-center"><u>UIN #</u></th>
                <th class="detail-table-col h-center"><u>UPC</u></th>
                <th class="detail-table-col h-center"><u>Product Description</u></th>
                <th class="detail-table-col h-center"><u>Quantity</u></th>
                <th class="detail-table-col h-center"><u>Eaches</u></th>
                <th class="detail-table-col h-center"><u>Price</u></th>
                <th class="detail-table-col h-center"><u>Sales Total</u></th>
                <th class="detail-table-col h-center"><u>Discount</u></th>
                <th class="detail-table-col h-center"><u>Net Total</u></th>
                <th class="detail-table-col h-center"><u>Total Gratis</u></th>
                <th class="detail-table-col h-center"><u>Transaction</u></th>
                <th class="detail-table-col h-center"><u>Wholesaler</u></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of data">
                <td class="detail-table-col h-left">{{row.uin}}</td>
                <td class="detail-table-col h-left">{{row.upc}}</td>
                <td class="detail-table-col h-left">{{row.productDescription}}</td>
                <td class="detail-table-col h-right">{{row.quantity}}</td>
                <td class="detail-table-col h-right">{{row.units}}</td>
                <td class="detail-table-col h-right">{{(row.type === viewmodel.transactionTypes.ExchangeIn ||
                    row.type === viewmodel.transactionTypes.ExchangeOut
                    ? row.wholesalePrice
                    : row.price) | currency}}</td>
                <td class="detail-table-col h-right">{{(row.type === viewmodel.transactionTypes.ExchangeIn ||
                    row.type === viewmodel.transactionTypes.ExchangeOut
                    ? row.wholesaleTotal
                    : row.salesTotal) | currency}}</td>
                <td class="detail-table-col h-right">{{row.discount | currency}}</td>
                <td class="detail-table-col h-right">{{(row.type === viewmodel.transactionTypes.ExchangeIn ||
                    row.type === viewmodel.transactionTypes.ExchangeOut
                    ? row.wholesaleTotal
                    : row.netTotal) | currency}}</td>
                <td class="detail-table-col h-right">{{row.gratisTotal | currency}}</td>
                <td class="detail-table-col h-left">{{row.typeDescription}}</td>
                <td>{{row.wholesalerName}}</td>
            </tr>
        </tbody>
    </table>
</ng-template>
</ng-container>
