import { ProductUpcDto } from "./product-upc.dto";

export class ProductDistributionEntryDto {
    id: string;
    itemNumber: string;
    description: string;
    inDistribution: number;
    intro: number;
    oos: number;
    cos: number;
    distPercent: number;
}
