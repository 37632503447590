import { TransactionLineItemType } from "shield.shared";

export class GratisAssignedTransactionLineItem {
    id!: string;
    gratisAssignedTransactionId!: string;
    wholesalerId: string | null;
    wholesalerName: string | null;
    productId!: string;
    productDescription!: string;
    quantity!: number;
    uin: string | null;
    upc: string | null;
    units!: number;
    price: number | null;
    wholesalePrice: number | null;
    salesTotal: number | null;
    discount: number | null;
    netTotal: number | null;
    wholesaleTotal: number | null;
    gratisTotal: number | null;
    type!: TransactionLineItemType;
    typeDescription!: string;

    constructor() {
        this.wholesalerId = null;
        this.wholesalerName = null;
        this.uin = null;
        this.upc = null;
        this.price = null;
        this.wholesalePrice = null;
        this.salesTotal = null;
        this.discount = null;
        this.netTotal = null;
        this.wholesaleTotal = null;
        this.gratisTotal = null;
    }
}
