import { CallProductTaxDto } from "./call-cash-tax.dto";

export class CallGratisProductDto {
    id: string;
    productId: string;
    quantity: number;
    units: number;
    value: number;
    upc: string;
    callProductTax: CallProductTaxDto | null;
}
