export enum ProjectListColumns {
    visibleDate = "Visible Date",
    startDate = "Start Date",
    endDate = "End Date",
    projectName = "Name",
    projectType = "Type",
    mission = "Mission",
    storeCount = "Store Count",
    status = "Status"
}
