import { Component, ElementRef, Input, OnInit, Type, ViewChild } from "@angular/core";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterService } from "src/app/services/filter.service";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { RefinerLocation } from "shield.shared";
import { CallDateInformationViewmodel } from "./call-date-information.viewmodel";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";

@Component({
    selector: "app-call-date-information-filter",
    templateUrl: "./call-date-information-filter.component.html",
    styleUrls: ["./call-date-information-filter.component.scss"]
})
export class CallDateInformationFilterComponent extends FilterBaseComponent implements OnInit {
    //view childs
    @ViewChild("callStartDateInput") callStartDateInput: ElementRef;
    @ViewChild("callEndDateInput") callEndDateInput: ElementRef;

    //public vars
    contentComponent: Type<CallDateInformationFilterComponent>;
    icon = "perm_phone_msg";
    name = "Date Information";
    viewmodel = new CallDateInformationViewmodel(this.filterService, this.formBuilder);

    constructor(
        private filterService: FilterService,
        private formBuilder: FormBuilder
    ) {
        super();
    }

    ngOnInit(): void {
        this.setInitialized();
    }

    //events
    onAddEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        if (this.viewmodel.callDateForm.controls.callEndDate.errors) return;

        const value = this.callEndDateInput.nativeElement.value;
        const existing = this._refinerService.refiners?.find(v => v.location === RefinerLocation.callOnOrBeforeDate);

        if (value || existing) {
            this._refinerService.onInputChange(
                RefinerLocation.callOnOrBeforeDate,
                this.callEndDateInput.nativeElement.value
            );
            if (!event || (event as KeyboardEvent).key === "Enter") {
                setTimeout(() => {
                    const refiner = new Refiner();
                    refiner.location = RefinerLocation.callOnOrBeforeDate;
                    refiner.value = this.callEndDateInput.nativeElement.value;
                    refiner.dataPropertyName = "stopTime";

                    this._refinerService.checkAndUpdateRefiner(refiner);
                }, 0)
            }
        }
    }

    onAddStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        if (this.viewmodel.callDateForm.controls.callStartDate.errors) return;

        const value = this.callStartDateInput.nativeElement.value;
        const existing = this._refinerService.refiners?.find(v => v.location === RefinerLocation.callOnOrAfterDate);

        if (value || existing) {
            this._refinerService.onInputChange(
                RefinerLocation.callOnOrAfterDate,
                this.callStartDateInput.nativeElement.value
            );
            if (!event || (event as KeyboardEvent).key === "Enter") {
                setTimeout(() => {
                    const refiner = new Refiner();
                    refiner.location = RefinerLocation.callOnOrAfterDate;
                    refiner.value = this.callStartDateInput.nativeElement.value;
                    refiner.dataPropertyName = "stopTime";

                    this._refinerService.checkAndUpdateRefiner(refiner);
                }, 0)
            }
        }
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.callOnOrBeforeDate:
                    if (refiner.value !== this.callEndDateInput?.nativeElement.value) {
                        if(refiner.value) {
                            this.viewmodel.callDateForm.controls.callEndDate.setValue(moment(new Date(refiner.value)));
                        }
                        // else {
                        //     this.viewmodel.callDateForm.controls.callEndDate.setValue(null);
                        // }
                    }
                    break;
                case RefinerLocation.callOnOrAfterDate:
                    if (refiner.value !== this.callStartDateInput?.nativeElement.value || !refiner.value) {
                        if(refiner.value) {
                            this.viewmodel.callDateForm.controls.callStartDate.setValue(moment(new Date(refiner.value)));
                        }
                        // else {
                        //     this.viewmodel.callDateForm.controls.callStartDate.setValue(null);
                        // }
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.callOnOrBeforeDate;
        const endDateRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.callOnOrBeforeDate
        );
        if (endDateRefiner) {
            this.onInputChange(endDateRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.callOnOrAfterDate;
        const startDateRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.callOnOrAfterDate
        );
        if (startDateRefiner) {
            this.onInputChange(startDateRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }
}
