//Angular
import { Component, OnInit } from "@angular/core";

//Models
import { ContactViewModel } from "../contact/contact.viewmodel";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { CustomerStateService } from "../account-services/customer-state.service";
import { Contact } from "src/app/entity-models/contact.entity";
import { ContactDelineationService } from "src/app/services/delineation-services/contact-delineation.service";
import { ContactConverterService } from "src/app/services/converter-services/contact-converter.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";

@Component({
    selector: "app-remove-contact",
    templateUrl: "./remove-contact.component.html",
    styleUrls: ["./remove-contact.component.scss"]
})
export class RemoveContactComponent implements OnInit {
    isSaveDisabled = true;
    retailCallCustomerContactViewModel: ContactViewModel = new ContactViewModel();

    constructor(
        public overlayRef: SwisherOverlayRef<
            ContactViewModel,
            RemoveContactComponent
        >,
        private customerDelineationService: CustomerDelineationService,
        private customerStateService: CustomerStateService,
        private contactDelineationService: ContactDelineationService
    ) {}

    ngOnInit(): void {
        const data = this.overlayRef.data;

        data.buttonRightFunction = () => this.save();
        data.buttonRightDisabledFunction = () => {
            return false;
        };
        data.buttonRightText = "Yes, Remove Contact";
        data.buttonLeftFunction = () => this.close();
        data.buttonLeftDisabledFunction = () => {
            return false;
        };
        data.buttonLeftText = "Cancel";
        data.needsMappingsRefreshed = true;

        if (data?.id) {
            this.retailCallCustomerContactViewModel = data;
        }
    }

    close(): void {
        this.overlayRef.close();
    }

    async save(): Promise<void> {

        if (this.retailCallCustomerContactViewModel) {
            this.retailCallCustomerContactViewModel.isDeleted = true;
            this.retailCallCustomerContactViewModel.modifiedUtcDateTime = new Date();
            this.contactDelineationService
            const contact: Contact = ContactConverterService.buildDomainModelFromViewModel(
                this.retailCallCustomerContactViewModel
            );
            const response = await this.contactDelineationService.save(contact);
            if (!response) { return; }

            if (response) {
                await this.customerStateService.updateCustomerByContact();

                await this.customerDelineationService.upsertCustomer(
                    this.customerStateService.customer
                );
            }
        }

        this.close();
    }
}
