import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DatabaseService } from "../../services/database.service";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { TaxRate } from "src/app/entity-models/tax-rate.entity";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import {
    GenericVersionResponseDto, SharedHelper, SystemInformationKeys,
    TaxCalculationRateDto, VersionResponseDto
} from "shield.shared";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { SyncLevel } from "../sync-enums/sync-level.enum";
@Injectable()
export class TaxRateDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.Initial;
    offlineRepSyncType = SyncLevel.Initial;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.taxRate;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
    ) {
        super();
    }

    async execute(): Promise<void> {
        const lastVersion = this.lastSyncVersion;

        if (!(await this.checkVersion(lastVersion))) {
            this.log("Tax rate data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Tax rate data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.taxRatePullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;

        try {
            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;
                const response = await this.http
                    .get<GenericVersionResponseDto<TaxCalculationRateDto[]>>(`/api/tax-rate/${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                this.log(
                    `Downloaded ${thisBatchSize} tax rates, saving to IndexedDB...`
                );

                const rows = response.values.map((r) =>
                    this.taxRateDtoToTaxRate(r)
                );
                await this.dbService.taxRates.bulkPut(rows);

                this.lastSyncVersion = response.maxVersion;
            } while (thisBatchSize > 0);

            this.log("Done saving tax rate data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing tax rates", e);
        }
    }

    async pushData(): Promise<void> {
        return;
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/tax-rate/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private taxRateDtoToTaxRate(dto: TaxCalculationRateDto): TaxRate {
        return {
            ...dto,
            createdUtcDateTime: dto.createdUtcDateTime ? new Date(dto.createdUtcDateTime) : new Date(),
            modifiedUtcDateTime: dto.modifiedUtcDateTime ? new Date(dto.modifiedUtcDateTime) : new Date()
        };
    }
}
