import { OrderLineItemStatuses, OrderStatuses } from "../lookup";
import { AddressFilterDto } from "./address-filter.dto";
import { ZrtFilterDto } from "./zrt-filter.dto";

export class OrderListFilterDto {

    /*
     * My Searches
     */
    // Usage: Get only accounts with given ids
    ids?: string[];

    /*
    * Location section
    */

    //Usage: Contains ZRT properties
    zrtFilterDto?: ZrtFilterDto;

    //Usage: Used for filter accounts on the order list page
    //Possible data: account name, account GUID/Id or account number
    account?: string[];

    //Usage: Contains address properties for the business address
    businessAddressFilterDto?: AddressFilterDto;

    /*
    * Date information section
    */

    //Usage: Get data based on call date
    callOnOrAfterDate?: string;
    callOnOrBeforeDate?: string;

    /*
    * Order information section
    */

    //Usage: Get based on associated project ids
    projectIds?: string[];

    //Usage: Getting call orders based on it account ownership
    //Possible data: Account ownership GUIDs
    accountOwnershipIds?: string[];

    //Usage: Get data based on wholesaler Ids
    wholesalerIds?: string[];

    //Usage: Get data based on uins
    uins?: string[]

    //Usage: Get data based on associated products ids
    productIds?: string[];

    //Usage: Get data based on order date
    orderOnOrAfterDate?: string;
    orderOnOrBeforeDate?: string;

    //Usage: Get data based on order type ids or order type
    orderTypeIds?: number[];
    orderTypes?: string[];

    //Usage: Get data based on order status enum
    orderStatuses?: OrderStatuses[];

    //Usage: Get data based on order line item status enum
    orderLineItemStatuses?: OrderLineItemStatuses[];

    //Usage: Get based on store types
    storeTypes?: string[];

    //Usage: Get if the associated customer is an Msa
    isMsa?: boolean;

    //Usage: Get based on the selected px3 rank
    px3Rank?: string[];
}
