import { Injectable } from "@angular/core";
import { AccountOwnershipDropDownDto, GenericResponseDto } from "shield.shared";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { DexieTableNames } from "src/app/enums/dexie-table-names";
import { DatabaseService } from "../database.service";
import { AccountOwnershipOfflineService } from "../offline-services/account-ownership-offline.service";
import { AccountOwnershipOnlineService } from "../online-services/account-ownership-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class AccountOwnershipDelineationService extends DelineationContext<AccountOwnership, string> {

    constructor(private accountOwnershipOfflineService: AccountOwnershipOfflineService
        , private accountOwnershipOnlineService: AccountOwnershipOnlineService
        , snackbarService: SnackbarService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService){
            super(dbService, datasourceDelineationService, snackbarService);
        }

    async getByOwnerCode(code: string): Promise<GenericResponseDto<AccountOwnership[]>> {

        const offline = (key: string) => {
            return this.accountOwnershipOfflineService.getByOwnerCode(key);
        }
        const online = (key: string) => {
            return this.accountOwnershipOnlineService.getByOwnerCode(key);
        }
        const compareDelegate = (online: AccountOwnership[], localOffline: AccountOwnership[]) => this.compareAccountOwnershipArray(online, localOffline);
        const unprocessedDelegate = async (key: string, hasOfflineAccess: boolean) => this.getUnprocessedByOwnerCode(key);
        const response = await this.datasourceDelineationService.makeCall<string, AccountOwnership[]>(code, offline, online, compareDelegate, unprocessedDelegate);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getAllInDropDown(): Promise<GenericResponseDto<AccountOwnership[]>> {

        const offline = () => {
            return this.accountOwnershipOfflineService.getAllInDropDown();
        }
        const online = () => {
            return this.accountOwnershipOfflineService.getAllInDropDown();
        }
        const response = await this.datasourceDelineationService.makeCall<string, AccountOwnership[]>(undefined, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getAll(): Promise<GenericResponseDto<AccountOwnership[]>> {

        const offline = () => {
            return this.accountOwnershipOfflineService.getAll();
        }
        const online = () => {
            return this.accountOwnershipOfflineService.getAll();
        }
        const response = await this.datasourceDelineationService.makeCall<string, AccountOwnership[]>(undefined, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async save(ao: AccountOwnership): Promise<GenericResponseDto<undefined>> {
        this.persist(ao, DexieTableNames.accountOwnerships);

        const offline = (key: AccountOwnership) => {
            return this.accountOwnershipOfflineService.save(key);
        }
        const online = (key: AccountOwnership) => {
            return this.accountOwnershipOfflineService.save(key);
        }
        const response = await this.datasourceDelineationService.makeCall<AccountOwnership, undefined>(ao, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    private async getUnprocessedByOwnerCode(key: string): Promise<AccountOwnership[]> {
        const unprocessed = await this.accountOwnershipOfflineService.getUnprocessedByOwnerCode(key);

        return unprocessed;
    }

    private async compareAccountOwnershipArray(
        online: AccountOwnership[],
        localOffline: AccountOwnership[]
    ): Promise<AccountOwnership[]> {
        const rtn = new Array<AccountOwnership>();
        const offlineMap = new Map(localOffline?.map((entry) => [entry.id, entry]));

        for (const onlineRecord of (online ?? [])) {
            const offline = offlineMap?.get(onlineRecord.id);
            if (offline) {
                offlineMap.delete(offline.id);
                if (onlineRecord.modifiedUtcDateTime?.getTime() >= offline.modifiedUtcDateTime?.getTime()
                ) {
                    rtn.push(onlineRecord);
                } else {
                    rtn.push(offline);
                }
            } else {
                rtn.push(onlineRecord);
            }
        }

        // What is remaining in the offlineRouteMaps are entries that don't exist in the online version
        // Unprocessed entries will appear at the top
        for (const offlineMapValue of offlineMap.values()) {
            rtn.push(offlineMapValue);
        }
        return rtn;
    }
}
