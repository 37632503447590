<div #myFilter>
    <div class="mt-3 mb-3 w-100">
        <angular2-multiselect #projectSelect
            [data]="viewmodel.projects"
            [(ngModel)]="viewmodel.selectedProjects"
            [settings]="viewmodel.projectsDropdownSettings"
            (onDeSelectAll)="closedProjectSelect()"
            (onClose)="closedProjectSelect()"
            (onOpen)="openedProjectSelect()">
        </angular2-multiselect>
    </div>
    <div class="mt-3 mb-3 w-100">
        <angular2-multiselect #wholesalerSelect
            [data]="viewmodel.wholesalers"
            [(ngModel)]="viewmodel.selectedWholesalers"
            [settings]="viewmodel.wholesalersDropdownSettings"
            (onDeSelectAll)="closedWholesalerSelect()"
            (onClose)="closedWholesalerSelect()"
            (onOpen)="openedWholesalerSelect()">
        </angular2-multiselect>
    </div>
    <div class="mt-3 mb-3 w-100">
        <angular2-multiselect #chainSelect
            [data]="viewmodel.chains"
            [(ngModel)]="viewmodel.selectedChains"
            [settings]="viewmodel.chainsDropdownSettings"
            (onDeSelectAll)="closedChainSelect()"
            (onClose)="closedChainSelect()"
            (onOpen)="openedChainSelect()">
        </angular2-multiselect>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-uin">UIN
            </mat-label>
            <input #uinInput id="uin-input" type="text" (keyup)="onAddUinRefiner($event)"
                [(ngModel)]="viewmodel.uinInput" matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3 w-100">
        <angular2-multiselect
            [data]="viewmodel.products"
            [(ngModel)]="viewmodel.selectedProducts"
            [settings]="viewmodel.productsDropdownSettings"
            (onDeSelectAll)="onClosedProductsSelect()"
            (onClose)="onClosedProductsSelect()"
            (onOpen)="onOpenedProductsSelect()">
        </angular2-multiselect>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100" [formGroup]="viewmodel.dateForm">
            <mat-label>Order on or After</mat-label>
            <input #orderStartDateInput matInput
                formControlName="orderStartDate"
                [max]="viewmodel.startMaxDate"
                [matDatepicker]="afterPicker"
                (blur)="onAddOrderStartDateRefiner($event)"
                (dateChange)="onAddOrderStartDateRefiner()" />
            <mat-datepicker touchUi #afterPicker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="afterPicker"></mat-datepicker-toggle>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100" [formGroup]="viewmodel.dateForm">
            <mat-label>Order on or Before</mat-label>
            <input #orderEndDateInput matInput
                formControlName="orderEndDate"
                [min]="viewmodel.endMinDate"
                [max]="viewmodel.endMaxDate"
                [matDatepicker]="beforePicker"
                (blur)="onAddOrderEndDateRefiner($event)"
                (dateChange)="onAddOrderEndDateRefiner()" />
            <mat-datepicker touchUi #beforePicker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="beforePicker"></mat-datepicker-toggle>
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Order Type</mat-label>
            <mat-select #orderTypeSelect [(value)]="viewmodel.selectedOrderTypes" (openedChange)="onOpenedOrderTypeChange()"
                multiple>
                <mat-option *ngFor="let type of viewmodel.orderTypes" [value]="type">
                    {{ type }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Order Status</mat-label>
            <mat-select #orderStatusSelect
                [(value)]="viewmodel.selectedOrderStatuses"
                (openedChange)="onOpenedOrderStatusChange()"
                multiple>
                <mat-option *ngFor="let status of viewmodel.orderStatuses" [value]="status">
                    {{ status.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Order Line Item Status</mat-label>
            <mat-select #orderLineItemStatusSelect
                [(value)]="viewmodel.selectedOrderLineItemStatuses"
                (openedChange)="onOpenedOrderLineItemStatusChange()"
                multiple>
                <mat-option *ngFor="let status of viewmodel.orderLineItemStatuses" [value]="status">
                    {{ status.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
