export class ZoneRecapResult {
    daysWorked: number;
    retailCalls: number;
    callAverageIncludesResetDays: number;
    callAverageExcludesResetDays: number;
    top300CalledOnOnce: number;
    top300CalledOnTwiceOrMore: number;
    new: number;
    underContract: number;
    canceled: number;
    dollarsPaidOut: number;
}
