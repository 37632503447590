import { Injectable } from "@angular/core";
import { SharedHelper, WholesalerGroupIdsAndProductIdParamsDto, WholesalerGroupProductCatalogItemDto } from "shield.shared";
import { ProjectProduct } from "src/app/entity-models/project-product.entity";
import { WholesalerGroupProductCatalogItem } from "src/app/entity-models/wholesaler-group-product-catalog-item.entity";
import { DatabaseService } from "../database.service";

@Injectable()
export class WholesalerGroupProductCatalogItemOfflineService {
    constructor(private dbService: DatabaseService) { }

    getProductCatalogExport(
        _?: string,
    ): never {
        throw new Error("You must be in online mode to perform this action.");
    }

    getDivisionProductCatalogExport(_: string): never {
        throw new Error("Method not implemented.");
    }

    saveAll(_: WholesalerGroupProductCatalogItem[]): never {
        throw new Error("You must be in online mode to perform this action.");
    }

    async getByWholesalerId(
        id: string
    ): Promise<WholesalerGroupProductCatalogItem[]> {
        const wholesalerGroupMember = await this.dbService.wholesalerGroupMembers
            .where("wholesalerId")
            .equals(id)
            .first();

        if (wholesalerGroupMember) {
            const items = this.getByWholesalerGroupId(
                wholesalerGroupMember.wholesalerGroupId
            );
            return items;
        }
        return new Array<WholesalerGroupProductCatalogItem>();
    }

    async getByWholesalerGroupId(
        id: string
    ): Promise<WholesalerGroupProductCatalogItem[]> {
        const catalogItems = await this.dbService.wholesalerGroupProductCatalogItems
            .where("wholesalerGroupId")
            .equals(id)
            .and((wgpci) => !wgpci.isDeactivated && !wgpci.product.isDeactivated && !wgpci.product.isDeleted)
            .toArray();
        await this.populateWholesalerItems(catalogItems);
        return catalogItems;
    }

    async getByWholesalerGroupMemberId(
        wholesalerGroupMemberId: string
    ): Promise<WholesalerGroupProductCatalogItem[]> {
        const member = await this.dbService.wholesalerGroupMembers
            .where("id")
            .equals(wholesalerGroupMemberId)
            .toArray();

        if (member.length === 0) {
            return [];
        }

        const wpci = await this.dbService.wholesalerProductCatalogItems
            .where("wholesalerId")
            .equals(member[0].wholesalerId)
            .toArray();

        const wgpciIds = wpci.map((v) => v.wholesalerGroupProductCatalogItemId);
        const catalogItems = await this.dbService.wholesalerGroupProductCatalogItems
            .where("id")
            .anyOf(wgpciIds)
            .and((v) => !v.isDeactivated && !v.product.isDeactivated && !v.product.isDeleted && v.wholesalerGroupId === member[0].wholesalerGroupId)
            .toArray();
        await this.populateWholesalerItems(catalogItems);
        return catalogItems;
    }

    async getByWholesalerGroupIdsAndProductId(
        params: WholesalerGroupIdsAndProductIdParamsDto
    ): Promise<WholesalerGroupProductCatalogItem[]> {
        const catalogItems = await this.dbService.wholesalerGroupProductCatalogItems
            .where("wholesalerGroupId")
            .anyOf(params.wholesalerGroupIds)
            .and((wgpci) =>
                wgpci.productId === params.productId &&
                !wgpci.product.isDeactivated &&
                !wgpci.product.isDeleted &&
                !wgpci.isDeactivated
            )
            .toArray();
        await this.populateWholesalerItems(catalogItems);
        return catalogItems;
    }

    async getByProjectProduct(
        param: ProjectProduct
    ): Promise<WholesalerGroupProductCatalogItem> {
        const catalogItem = await this.dbService.wholesalerGroupProductCatalogItems
            .where("id")
            .equals(param.wholesalerGroupProductId)
            .and((wgpci) =>
                wgpci.productId === param.productId &&
                !wgpci.product.isDeactivated &&
                !wgpci.product.isDeleted &&
                !wgpci.isDeactivated
            )
            .first();
        await this.populateWholesalerItems([catalogItem]);
        return catalogItem;

    }

    /**
     * Populates the product level catalog items from the flat table in Dexie.
     * @param groupItems Group-level items to add wholesaler-level items to.
     */
    async populateWholesalerItems(groupItems: WholesalerGroupProductCatalogItem[]): Promise<void> {
        const wholesalerItems = SharedHelper.groupBy(
            await this.dbService.wholesalerProductCatalogItems
                .where("wholesalerGroupProductCatalogItemId").anyOf(groupItems.map(i => i.id))
                .and(wci => !wci.isDeactivated)
                .toArray(),
            i => i.wholesalerGroupProductCatalogItemId,
        );

        groupItems.forEach(i => {
            i.wholesalerItems = wholesalerItems.get(i.id);
            i.wholesalerCount = wholesalerItems.get(i.id)?.length ?? 0;
        });
    }
}
