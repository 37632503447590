import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import SignaturePad from 'signature_pad';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';

import { NewAgreementViewModel } from './new-agreement.viewmodel';

@Component({
    selector: 'app-new-agreement',
    templateUrl: './new-agreement.component.html',
    styleUrls: ['./new-agreement.component.scss']
})
export class NewAgreementComponent implements OnInit, OnDestroy {

    viewmodel: NewAgreementViewModel;

    constructor(public injectedData: SwisherOverlayRef<
            NewAgreementViewModel,
            NewAgreementComponent
        >) { }

    ngOnInit(): void {
        this.viewmodel = this.injectedData.data;

        const canvas: HTMLCanvasElement = document.getElementById(
            "signature-canvas"
        ) as HTMLCanvasElement;

        this.viewmodel.signaturePad = new SignaturePad(canvas, {
            dotSize: 2,
            penColor: "rgb(1,1,1)",
            onBegin: () => {
                this.viewmodel.signaturePad.penColor = "black";
            },
        });

        const date = moment(new Date()).add(1, "years");
        this.viewmodel.agreementFormGroup.controls['dateControl'].setValue(date.toDate());
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }
}
