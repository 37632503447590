import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faEye, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Subscription } from "rxjs";
import { CallTypes } from "shield.shared";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { CountDownComponent } from "src/app/dialogs/count-down/count-down.component";
import { CountDownViewmodel } from "src/app/dialogs/count-down/count-down.viewmodel";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";
import { CustomerGenericTypes } from "src/app/enums/customer-generic-types";
import { RetailStepperStep } from "src/app/enums/retail-stepper-step";
import { RmWholesaleStepperStep } from "src/app/enums/rm-wholesale-stepper-step";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { AppStateService } from "src/app/services/app-state.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { CallService } from "../call-services/call.service";
import { ClosingNotesComponent } from "../stepper-call/closing-notes/closing-notes.component";
import { ReceiptsComponent } from "../stepper-call/receipts/receipts.component";
import { StepperCallApplicationService } from "../stepper-call/stepper-call-services/stepper-call-application.service";
import { RmWholesaleCallStepperComponent } from "./rm-wholesale-call-stepper/rm-wholesale-call-stepper.component";
import { ReceiptType } from "src/app/enums/receipt-type";

@Component({
    selector: "app-rm-wholesale-call",
    templateUrl: "./rm-wholesale-call.component.html",
    styleUrls: ["./rm-wholesale-call.component.scss"]
})
export class RmWholesaleCallComponent implements OnInit, OnDestroy {
    // View Children
    @ViewChild("stepper") stepper: RmWholesaleCallStepperComponent;
    @ViewChild("receipts") receipts: ReceiptsComponent;
    @ViewChild("closingNotes") closingNotes: ClosingNotesComponent;

    // Private vars
    private observableSelectedIndexSubscription: Subscription;
    private observableEmployeeSubscription: Subscription;
    private customerSubscription: Subscription;
    private currentEmployee: Employee;
    private currentCustomer: Customer;
    private activatedRouteSubscription: Subscription;
    private modalRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    overlayRetailCall: RmWholesaleCall = new RmWholesaleCall();
    faCheck: IconDefinition = faCheck;
    faTimes: IconDefinition = faTimes;
    faEye: IconDefinition = faEye;

    private countDownModalRef: SwisherOverlayRef<
        CountDownViewmodel,
        CountDownComponent
    >;

    @Output() cancelCall = new EventEmitter<void>();
    @Output() blockCall = new EventEmitter<void>();

    public constructor(
        private stepperCallApplicationService: StepperCallApplicationService,
        public callService: CallService,
        private appStateService: AppStateService,
        private overlayService: OverlayService,
        private router: Router,
        private customerDelineationService: CustomerDelineationService,
        private customerStateService: CustomerStateService,
        private pleaseWaitService: PleaseWaitService,
        private snackbarService: SnackbarService
    ) {}

    ngOnInit(): void {
        if (!this.callService.call) {
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                this.callService.observableCall
            );
        }

        if (
            !this.observableEmployeeSubscription ||
            this.observableEmployeeSubscription.closed
        ) {
            this.observableEmployeeSubscription = this.appStateService.currentEmployee.subscribe(
                (employee) => {
                    if (this.currentEmployee?.id !== employee?.id) {
                        this.currentEmployee = employee;
                        void this.startRmWholesaleCall();
                    }
                }
            );
        }

        if (
            !this.observableSelectedIndexSubscription ||
            this.observableSelectedIndexSubscription.closed
        ) {
            this.observableSelectedIndexSubscription = this.stepperCallApplicationService.observableSelectedIndex.subscribe(
                (seletedIndex) => {
                    if (this.stepper) {
                        this.stepper.selectedIndex = seletedIndex;
                    }
                }
            );
        }

        if (!this.customerSubscription || this.customerSubscription.closed) {
            this.customerSubscription = this.customerStateService.observableCustomer.subscribe(
                (customer) => {
                    if (this.currentCustomer?.id !== customer?.id) {
                        this.currentCustomer = customer;
                        if (this.currentCustomer) {
                            void this.startRmWholesaleCall();
                        }
                    }
                }
            );
        }
    }

    async startRmWholesaleCall(): Promise<void> {
        if (this.currentEmployee && this.currentCustomer) {
            const result = await this.callService.blockCall(
                this.currentEmployee.id,
                this.countDownModalRef,
                this.overlayService
            );
            if (result) {
                this.blockCall.emit();
                void this.router.navigate([
                    "/accounts",
                    this.currentCustomer.id,
                    "profile"
                ]);
                return;
            }

            if (this.currentEmployee.user &&
                Helper.getCustomerGenericType(
                    this.currentCustomer
                ) === CustomerGenericTypes.wholesale
            ) {
                const openCall = await this.callService.getOpenCall(
                    this.currentEmployee
                );

                if (
                    openCall &&
                    openCall.customerId !==
                        this.customerStateService.customer.id
                ) {
                    // let them continue this call if its the oldest open call

                    const openCustomerResponse = await this.customerDelineationService.getById(
                        openCall.customerId
                    );
                    if (!openCustomerResponse) {
                        return;
                    }
                    const openCustomer = openCustomerResponse.values;

                    if (!openCustomer) {
                        this.snackbarService.showWarning(
                            `Can not find customer for open call: ${openCall.id} with CustomerId: ${openCall.customerId}`
                        );
                    }

                    const data = this.callService.buildBasicCurrentCallModal(
                        openCustomer?.name ?? "this customer"
                    );
                    data.buttonLeftFunction = () => {
                        this.cancelCall.emit();
                        this.pleaseWaitService.killPleaseWait();
                        this.modalRef.close();
                    };
                    data.buttonRightFunction = () => {
                        void this.router.navigate([
                            "/accounts",
                            openCustomer.id,
                            "profile"
                        ]);
                        this.pleaseWaitService.killPleaseWait();
                        this.modalRef.close();
                    };

                    this.modalRef = this.overlayService.open(
                        ConfirmationDialogComponent,
                        data
                    );
                } else {
                    if (!this.callService.call) {
                        await this.stepperCallApplicationService.startCall(
                            this.currentEmployee
                        );
                    } else {
                        if (
                            this.callService.call?.callType ===
                            CallTypes.rmWholesale
                        ) {
                            this.stepperCallApplicationService.selectedIndex =
                                (this.callService.call as RmWholesaleCall)
                                    .lastIndexVisited ??
                                RmWholesaleStepperStep.inDistribution;
                        }
                    }
                }
            }
        }
    }

    ngOnDestroy(): void {
        if (
            this.observableSelectedIndexSubscription &&
            this.observableSelectedIndexSubscription.closed
        ) {
            this.observableSelectedIndexSubscription.unsubscribe();
        }

        if (
            this.observableEmployeeSubscription &&
            !this.observableEmployeeSubscription.closed
        ) {
            this.observableEmployeeSubscription.unsubscribe();
        }

        if (
            this.activatedRouteSubscription &&
            !this.activatedRouteSubscription.closed
        ) {
            this.activatedRouteSubscription.unsubscribe();
        }

        if (this.customerSubscription && !this.customerSubscription.closed) {
            this.customerSubscription.unsubscribe();
        }
    }

    async autoPrintRetailReceipts(): Promise<void> {
        this.callService.isFinalRetailReceiptPrinted = true;
        await this.receipts.captureReceipt(ReceiptType.retail);
        await this.closingNotes.retailReceiptAutoPrintComplete();
    }

    async autoPrintWholesaleReceipts(): Promise<void> {
        await this.receipts.combinedWholesaleFunctions();
        await this.closingNotes.wholesaleReceiptAutoPrintComplete();
    }
}
