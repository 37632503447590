import { Component, OnInit } from '@angular/core';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';

import { SpecialCoverageInformationDialogViewModel } from './special-coverage-information-dialog.viewmodel';

@Component({
    selector: 'app-special-coverage-information-dialog',
    templateUrl: './special-coverage-information-dialog.component.html',
    styleUrls: ['./special-coverage-information-dialog.component.scss']
})
export class SpecialCoverageInformationDialogComponent implements OnInit {

    viewmodel: SpecialCoverageInformationDialogViewModel;

    constructor(public injectedData: SwisherOverlayRef<
                SpecialCoverageInformationDialogViewModel,
                SpecialCoverageInformationDialogComponent
            >
    ) { }

    ngOnInit(): void {
        this.viewmodel = this.injectedData.data;
    }
}
