<div *ngIf="viewmodel">
    <div class="row">
        <div class="col-12">
            <mat-form-field class="project-field" appearance="outline" color="accent">
                <mat-label>New Project Name</mat-label>
                <input matInput
                    [(ngModel)]="viewmodel.projectName"
                    placeholder="" maxlength="50">
                <mat-hint align="end">
                    {{ viewmodel.projectName?.length || 0 }}/50
                </mat-hint>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field class="project-field" appearance="outline" color="accent">
                <mat-label>Mission</mat-label>
                <textarea
                    cdkTextareaAutosize="false"
                    [(ngModel)]="viewmodel.projectMission"
                    rows="4"
                    maxLength="500"
                    type="text"
                    matInput
                >
                </textarea>
                <mat-hint align="end">
                    {{ viewmodel.projectMission?.length || 0 }}/500
                </mat-hint>
            </mat-form-field>
        </div>
    </div>
</div>
