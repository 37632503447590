import { AfterContentChecked, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { ZrtFilterServiceBase } from 'src/app/services/zrt-filter.service.base';
import { ZrtTreeViewViewModel } from './zrt-tree-view.viewmodel';

@Component({
    selector: 'app-zrt-tree-view',
    templateUrl: './zrt-tree-view.component.html',
    styleUrls: ['./zrt-tree-view.component.scss']
})
export class ZrtTreeViewComponent implements OnDestroy, AfterContentChecked {
    @ViewChild("zrtSelect") zrtSelect: MatSelect;
    @ViewChild("zrtSearch") zrtSearch: ElementRef;

    @Input("displayName") displayName = "ZRT";

    private _zrtFilterServiceBase: ZrtFilterServiceBase;
    @Input()
    get zrtFilterService() {
        return this._zrtFilterServiceBase;
    }
    set zrtFilterService(value: ZrtFilterServiceBase) {
        if (value) {
            this._zrtFilterServiceBase = value;
            this.viewmodel = new ZrtTreeViewViewModel(this.zrtFilterService);
        }
    }

    //public vars
    viewmodel: ZrtTreeViewViewModel;

    //private vars
    readyToInitZrt = false;

    ngAfterContentChecked(): void {

        if (!this.readyToInitZrt && this.zrtSearch && this.zrtSelect && this.zrtFilterService) {
            this.readyToInitZrt = true;
            this.viewmodel.selectedZrts = this.zrtFilterService.selectedZrts;

            this.viewmodel.initialize(this.zrtSearch, this.zrtSelect);
        }
    }

    ngOnDestroy(): void {
        if (this.viewmodel) {
            this.viewmodel.unsubscribe();
        }
    }
}
