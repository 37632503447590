import { CallSurveyAnswerDto, CallTypes } from "shield.shared";
import { ActivitySurveyViewModel } from "../accounts/account.viewmodels/activity-survey.viewmodel";
import { CallOrderProduct } from "./call-order-product.entity";
import { CallSurveyAnswer } from "./call-survey-answer.entity";

export class ChainHqCall {
    constructor() {
        this.callType = CallTypes.chainHq;
    }
    id: string; //UUID
    callNumber: string;
    customerId: string;
    createdUserId: string;
    createdUtcDateTime: Date;
    createdUserZrt: string;
    createdUserFullName: string
    px3RankId?: string;
    px3Rank?: string;
    modifiedUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    startTime: Date;
    stopTime: Date;
    durationMins: number;
    surveyAnswers: CallSurveyAnswer[] = [];
    surveys: ActivitySurveyViewModel[] = [];
    closingNotes: string;
    callType: CallTypes.chainHq;
    isDeleted: boolean;
    orderProducts: CallOrderProduct[] = [];
    rowversion: string;

    // Helper props - do not sync
    inDistAddedFromLastCall = true;
    productsOrder?: string[] = [];
    productsIntro?: string[] = [];
    productsOos?: string[] = [];
    productsCos?: string[] = [];
    evaluatedProducts?: string[] = [];

    callSurveyAnswers: CallSurveyAnswerDto[] = [];
    hasServerProcessed: number;
}
