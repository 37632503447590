export enum RefinerLocation {
    unknown = "Unknown ",
    account = "Account ",
    city = "City ",
    myList = "My List ",
    myFilter = "My Filter ",
    specialCoverage = "Special Coverage ",
    zrt = "ZRT ",
    street = "Street ",
    callOnOrAfterDate = "Call On or After ",
    callOnOrBeforeDate = "Call On or Before ",
    startDate = "Start on or After ",
    endDate = "Start on or Before ",
    availability = "Availability ",
    states = "States ",
    counties = "Counties ",
    zipCodes = "Zip Code ",
    storeTypes = "Store Type ",
    msa = "MSA ",
    phone = "Phone ",
    isActive = "Active ",
    volume = "Volume ",
    callable = "Callable ",
    callType = "Call Type ",
    hasPictures = "Has Pictures ",
    px3Rank = "PX3 Rank ",
    wholesalers = "Wholesalers ",
    projectWholesalers = "Project Wholesalers ",
    products = "Products ",
    receipt = "Receipt # ",
    status = "Status ",
    uin = "UIN ",
    transactionType = "Transaction Type ",
    orderOnOrAfterDate = "Order On or After ",
    orderOnOrBeforeDate = "Order On or Before ",
    orderType = "Order Type ",
    orderStatus = "Order Status ",
    orderLineItemStatus = "Order Line Item Status ",
    chains = "Chains ",
    routeName = "Route Name ",
    routeDescription = "Route Description ",
    routeZrt = "Route ZRT ",
    routeOnOrAfterDate = "Route On or After ",
    routeOnOrBeforeDate = "Route On or Before ",
    wholesaler = "Wholesaler ",
    projects = "Projects ",
    remainingGratis = "Remaining Gratis ",
    purpose = "Purpose ",
    daysWaiting = "Days Waiting ",
    signedOnOrAfterDate = "Signed On or After ",
    signedOnOrBeforeDate = "Signed On or Before ",
    contract = "Contract ",
    contractType = "Contract Type ",
    lastCallStatus = "Last Call Status ",
    paymentOnOrAfterDate = "Payment On or After ",
    paymentOnOrBeforeDate = "Payment On or Before ",
    paymentType = "Payment Type ",
    paymentMethod = "Payment Method ",
    programLevel = "Program Level ",
    name = "Name ",
    manager = "Manager ",
    assigned = "Assigned ",
    geoArea = "Geo Area ",
    assignment = "Is Assigned ",
    projectType = "Project Type ",
    projectStatus = "Project Status ",
    endOnOrBeforeDate = "End On or Before ",
    notEqual = "Not equal",
    customer = "Customer ",
    callHistory = "Call History ",
    pictureType = "Picture Type ",
    tags = "Tags ",
    zrtByArea = "ZRT (By Area) ",
    zrtByEmployee = "ZRT (By Employee) ",
    group = "Group ",
    orderMethod = "Order Method ",
    productNumber = "Product Number ",
    productDescription = "Product Description ",
    productPrice = "Product Price ",
    resetDays = "Reset Days ",
    workWithPerson = "Work With Person ",
    assignedEmployee = "Assigned Employee ",
    isAssignedToEmployee = "Is Assigned to Employee ",
    productGroups = "Product Groups ",
    lastCallOnAccount = "Last Call on Account ",
    extraction = "Extraction "
}
