<form [formGroup]="viewmodel.orderDateForm">
    <div class="center-release-dates">
    <div class="row">
        <div class="col align-self-center">
            <b>*If order should be placed on call date, leave this blank</b>
        </div>
    </div>
    <div class="row">
        <div class="col"></div>
        <div class="col align-self-center">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Order Date 1</mat-label>
                <input #orderDateOneInput matInput formControlName="orderDateOneControl"
                    [matDatepicker]="orderDate1"/>
                <mat-datepicker touchUi #orderDate1></mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="orderDate1"></mat-datepicker-toggle>
            </mat-form-field>
        </div>
        <div class="col"></div>
    </div>
    <div class="row">
        <div class="col"></div>
        <div class="col align-self-center">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Order Date 2</mat-label>
                <input #orderDateTwoInput matInput formControlName="orderDateTwoControl"
                    [matDatepicker]="orderDate2"/>
                <mat-datepicker touchUi #orderDate2></mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="orderDate2"></mat-datepicker-toggle>
            </mat-form-field>
        </div>
        <div class="col"></div>
    </div>
    <div class="row">
        <div class="col"></div>
        <div class="col align-self-center">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Order Date 3</mat-label>
                <input #orderDate3Input matInput formControlName="orderDateThreeControl"
                    [matDatepicker]="orderDate3" />
                <mat-datepicker touchUi #orderDate3></mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="orderDate3"></mat-datepicker-toggle>
            </mat-form-field>
        </div>
        <div class="col"></div>
    </div>
    <div class="row">
        <div class="col"></div>
        <div class="col align-self-center">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Order Date 4</mat-label>
                <input #orderDate4Input matInput formControlName="orderDateFourControl"
                    [matDatepicker]="orderDate4" />
                <mat-datepicker touchUi #orderDate4></mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="orderDate4"></mat-datepicker-toggle>
            </mat-form-field>
        </div>
        <div class="col"></div>
    </div>
</div>
</form>

<ng-container *ngIf="viewmodel.futureDatedProducts">
    <div class="row">
        <div class="col align-self-center">
            <br/>
            <br/>
            <div class="s-red-color">*Orders for the following products will be processed on or after the Date Available.</div>
        </div>
    </div>
    <div class="row">
        <div class="col col-lg-12 col-sm-6 align-items-center">
            <table style="width: 100%;" class="table-sm table-borderless">
                <thead class="detail-table-head">
                    <tr>
                        <th class="detail-table-col h-center"><u>UIN</u></th>
                        <th colspan="3" class="detail-table-col h-center"><u>Description</u></th>
                        <th class="detail-table-col h-center"><u>Wholesale</u></th>
                        <th class="detail-table-col h-center"><u>Date Available</u></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of viewmodel.futureDatedProducts">
                        <td class="detail-table-col h-left">{{row.uin}}</td>
                        <td colspan="3" class="detail-table-col h-left">{{row.product.longDescription}}</td>
                        <td class="detail-table-col h-left">{{row.wholesalerName}}</td>
                        <td class="detail-table-col h-right">{{row.dateAvailableFormatted}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container> 
