<ng-container *ngIf="vm">
    <div class="basic-modal" [style]="{
        height: 'auto',
        width: 'auto'
    }">
        <div cdkDragHandle>
            <div class="modal-header">
                Driving Instructions - Optimize Route
                <mat-icon class="close-icon" (click)="close()">close</mat-icon>
            </div>
        </div>
        <div class="modal-body">
            <div class="d-flex justify-content-start align-items-center mb-2">
                <fa-icon [icon]="faInfoCircle" class="text-m-gray mr-1"></fa-icon>
                <p class="text-m-gray mb-0">All fields are optional.</p>
            </div>

            <div class="mt-3">
                <div class="d-flex justify-content-start align-items-center mb-2">
                    <fa-icon [icon]="faLocationArrow" class="directions-icon text-teal mr-2"></fa-icon>
                    <h3 class="mb-0">Starting Location</h3>
                </div>
                <div class="d-flex flex-column align-items-start">
                    <mat-checkbox
                        [(ngModel)]="vm.startAtCurrentLocation"
                        (ngModelChange)="vm.startAtStorageLocation = false">
                        Use Current Location
                    </mat-checkbox>
                    <mat-checkbox *ngIf="vm.storageLocation"
                        [(ngModel)]="vm.startAtStorageLocation"
                        (ngModelChange)="vm.startAtCurrentLocation = false">
                        Use Storage Location
                    </mat-checkbox>
                </div>

                <mat-form-field appearance="outline" color="accent" class="w-100 mt-3">
                    <mat-label>Previous Start</mat-label>
                    <mat-select [(ngModel)]="vm.startLocation" (valueChange)="adjustStartNewLocation($event); validateAddressExists(vm.startLocation)"
                        [disabled]="vm.startAtCurrentLocation || vm.startAtStorageLocation">
                        <mat-option [value]="vm.startNewLocation" [hidden]="shouldHideStart()">Custom Start Location</mat-option>
                        <mat-option [value]="vm.startFirstStopLocation">
                            {{vm.startFirstStopLocation.name ? vm.startFirstStopLocation.name : "Custom Start Location"}}
                            (current first route stop)
                        </mat-option>
                        <mat-optgroup *ngIf="vm.startPrevLocations.length > 0">Previous Start Locations
                            <mat-option *ngFor="let address of vm.startPrevLocations" [value]="address">
                                <ng-container *ngIf="address.name">{{ address.name }}, </ng-container>
                                {{ address.address1 }}, {{ address.city }}, {{ address.state }} {{ address.zip }}
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>

                <mat-form-field color="accent" appearance="outline" class="w-100">
                    <mat-label>Address 1</mat-label>
                    <input matInput
                        placeholder="Address 1"
                        [(ngModel)]="vm.startLocation.address1"
                        [disabled]="vm.startAtCurrentLocation || vm.startAtStorageLocation"
                        (keyup)="validateIfStartIsNew()"
                        (blur)="validateAddressExists(vm.startLocation)">
                </mat-form-field>

                <mat-form-field color="accent" appearance="outline" class="w-100">
                    <mat-label>Address 2</mat-label>
                    <input matInput
                        placeholder="Address 2"
                        [(ngModel)]="vm.startLocation.address2"
                        [disabled]="vm.startAtCurrentLocation || vm.startAtStorageLocation"
                        (keyup)="validateIfStartIsNew()"
                        (blur)="validateAddressExists(vm.startLocation)">
                    <mat-error *ngIf="startLocationError"></mat-error>
                </mat-form-field>

                <div class="d-flex justify-content-between">
                    <mat-form-field color="accent" appearance="outline" class="mr-1">
                        <mat-label>City</mat-label>
                        <input matInput
                            placeholder="City"
                            [(ngModel)]="vm.startLocation.city"
                            [disabled]="vm.startAtCurrentLocation || vm.startAtStorageLocation"
                            (keyup)="validateIfStartIsNew()"
                            (blur)="validateAddressExists(vm.startLocation)">
                        <mat-error *ngIf="startLocationError"></mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" color="accent" class="mr-1">
                        <mat-label>State</mat-label>
                        <mat-select
                            placeholder="State"
                            [(ngModel)]="vm.startLocation.state"
                            [disabled]="vm.startAtCurrentLocation || vm.startAtStorageLocation"
                            (selectionChange)="validateIfStartIsNew(); validateAddressExists(vm.startLocation)">
                            <mat-option *ngFor="let state of states" [value]="state.shortCode">
                                {{state.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="startLocationError"></mat-error>
                    </mat-form-field>

                    <mat-form-field color="accent" appearance="outline">
                        <mat-label>Zip Code</mat-label>
                        <input matInput
                            placeholder="Zip Code"
                            [(ngModel)]="vm.startLocation.zip"
                            [disabled]="vm.startAtCurrentLocation || vm.startAtStorageLocation"
                            (keyup)="validateIfStartIsNew()"
                            (blur)="validateAddressExists(vm.startLocation)">
                        <mat-error *ngIf="startLocationError"></mat-error>
                    </mat-form-field>
                </div>

                <h3 *ngIf="startLocationError" class="mat-error">Could not resolve starting location.  Please verify address is correct and try again.</h3>

                <div class="d-flex justify-content-start align-items-center mb-2 mt-4">
                    <fa-icon [icon]="faMapMarkerAlt" class="directions-icon text-primary mr-2"></fa-icon>
                    <h3 class="mb-0">Ending Location</h3>
                </div>
                <div class="d-flex flex-column align-items-start">
                    <mat-checkbox [(ngModel)]="vm.endAtCurrentLocation" (ngModelChange)="
                        vm.endAtStorageLocation = false;
                        vm.endAtStartingLocation = false">
                        Use Current Location
                    </mat-checkbox>
                    <mat-checkbox *ngIf="vm.storageLocation" [(ngModel)]="vm.endAtStorageLocation" (ngModelChange)="
                        vm.endAtCurrentLocation = false;
                        vm.endAtStartingLocation = false">
                        Use Storage Location
                    </mat-checkbox>
                    <mat-checkbox [(ngModel)]="vm.endAtStartingLocation" (ngModelChange)="
                        vm.endAtCurrentLocation = false;
                        vm.endAtStorageLocation = false">
                        Use Starting Location
                    </mat-checkbox>
                </div>

                <mat-form-field appearance="outline" color="accent" class="w-100 mt-3">
                    <mat-label>Previous End</mat-label>
                        <mat-select [(ngModel)]="vm.endLocation" (valueChange)="adjustEndNewLocation($event); validateAddressExists(vm.endLocation, true)"
                            [disabled]="vm.endAtCurrentLocation || vm.endAtStorageLocation || vm.endAtStartingLocation">
                            <mat-option [value]="vm.endNewLocation" [hidden]="shouldHideEnd()">Custom End Location</mat-option>
                            <mat-option [value]="vm.endLastStopLocation">
                                {{vm.endLastStopLocation.name ? vm.endLastStopLocation.name : "Custom End Location"}}
                                (current last route stop)
                            </mat-option>
                        <mat-optgroup *ngIf="vm.endPrevLocations.length > 0">Previous End Locations
                            <mat-option *ngFor="let address of vm.endPrevLocations" [value]="address">
                                <ng-container *ngIf="address.name">{{ address.name }}, </ng-container>
                                {{ address.address1 }}, {{ address.city }}, {{ address.state }} {{ address.zip }}
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>

                <mat-form-field color="accent" appearance="outline" class="w-100">
                    <mat-label>Address 1</mat-label>
                    <input matInput placeholder="Address 1" [(ngModel)]="vm.endLocation.address1"
                        [disabled]="vm.endAtCurrentLocation || vm.endAtStorageLocation || vm.endAtStartingLocation"
                        (keyup)="validateIfEndIsNew()"
                        (blur)="validateAddressExists(vm.endLocation, true)">
                </mat-form-field>

                <mat-form-field color="accent" appearance="outline" class="w-100">
                    <mat-label>Address 2</mat-label>
                    <input matInput placeholder="Address 2" [(ngModel)]="vm.endLocation.address2"
                        [disabled]="vm.endAtCurrentLocation || vm.endAtStorageLocation || vm.endAtStartingLocation"
                        (keyup)="validateIfEndIsNew()"
                        (blur)="validateAddressExists(vm.endLocation, true)">
                </mat-form-field>

                <div class="d-flex justify-content-between">
                    <mat-form-field color="accent" appearance="outline" class="mr-1">
                        <mat-label>City</mat-label>
                        <input matInput placeholder="City" [(ngModel)]="vm.endLocation.city"
                            [disabled]="vm.endAtCurrentLocation || vm.endAtStorageLocation || vm.endAtStartingLocation"
                            (keyup)="validateIfEndIsNew()"
                            (blur)="validateAddressExists(vm.endLocation, true)">
                    </mat-form-field>

                    <mat-form-field appearance="outline" color="accent" class="mr-1">
                        <mat-label>State</mat-label>
                        <mat-select placeholder="State" [(ngModel)]="vm.endLocation.state"
                        [disabled]="vm.endAtCurrentLocation || vm.endAtStorageLocation || vm.endAtStartingLocation"
                            (selectionChange)="validateIfEndIsNew(); validateAddressExists(vm.endLocation, true)">
                            <mat-option *ngFor="let state of states" [value]="state.shortCode">
                                {{state.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field color="accent" appearance="outline">
                        <mat-label>Zip Code</mat-label>
                        <input matInput placeholder="Zip Code" [(ngModel)]="vm.endLocation.zip"
                            [disabled]="vm.endAtCurrentLocation || vm.endAtStorageLocation || vm.endAtStartingLocation"
                            (keyup)="validateIfEndIsNew()"
                            (blur)="validateAddressExists(vm.endLocation, true)">
                    </mat-form-field>
                </div>

                <h3 *ngIf="endLocationError" class="mat-error">Could not resolve ending location.  Please verify address is correct and try again.</h3>
            </div>
        </div>
        <div class="modal-footer">
            <button mat-raised-button class="btn-white actionBtn" (click)="close()">
                Cancel
            </button>
            <button appThrottleClick mat-raised-button class="sw-red-btn actionBtn" [disabled]="isSaveDisabled()" (throttledClick)="saveClick()">
                Save
            </button>
        </div>
    </div>
</ng-container>
