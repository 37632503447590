import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardMasterComponent } from "./dashboard-master.component";
import { SharedModule } from "src/app/shared/shared.module";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { Px3DashboardComponent } from './px3-dashboard/px3-dashboard.component';

@NgModule({
    declarations: [
        DashboardMasterComponent,
        Px3DashboardComponent,
    ],
    imports: [
        SharedModule,
        CommonModule,
        NgxChartsModule,
        FontAwesomeModule,
        MatSlideToggleModule
    ],
    exports: [
        Px3DashboardComponent,
    ],
})
export class DashboardMasterModule {}
