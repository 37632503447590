import { Injectable } from "@angular/core";
import {
    GenericResponseDto,
    SharedHelper,
    SystemInformationKeys,
    TimeEntryParamsDto,
    TimeEntryTypeNames
} from "shield.shared";
import { Call } from "src/app/accounts/call-master/call-services/call.service";
import { Customer } from "src/app/entity-models/customer.entity";
import { DayTimeEntry } from "src/app/entity-models/day-time-entry.entity";
import { TimeEntryType } from "src/app/entity-models/time-entry-type.entity";
import { TimeEntry } from "src/app/entity-models/time-entry.entity";
import { DatabaseService } from "../database.service";
import {
    CallTimeEntryParams,
    TimeEntryOfflineService
} from "../offline-services/time-entry-offline.service";
import { TimeEntryOnlineService } from "../online-services/time-entry-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";
import { SystemInformationDelineationService } from "./system-information-delineation.service";
import { TimeEntryTypeDelineationService } from "./time-entry-type-delineation.service";

@Injectable()
export class TimeEntryDelineationService extends DelineationContext<
    TimeEntry,
    string
> {
    timeEntryAgeOutDays = SharedHelper.getLastMonthsDate();

    constructor(
        private timeEntryOfflineService: TimeEntryOfflineService,
        private timeEntryOnlineService: TimeEntryOnlineService,
        private timeEntryTypeDelineationService: TimeEntryTypeDelineationService,
        snackbarService: SnackbarService,
        private systemInformationDelineationService: SystemInformationDelineationService,
        protected dbService: DatabaseService,
        protected datasourceDelineationService: DatasourceDelineationService
    ) {
        super(dbService, datasourceDelineationService, snackbarService);
        void this.init();
    }

    async init(): Promise<void> {
        const timeEntryAgeOutResponse = await this.systemInformationDelineationService.getByKey(
            SystemInformationKeys.timeEntryAgeOutDays
        );
        if (
            timeEntryAgeOutResponse?.values
            && Number.isInteger(timeEntryAgeOutResponse.values)
        ) {
            const d = new Date();
            d.setDate(d.getDate() - Number(timeEntryAgeOutResponse.values));
            this.timeEntryAgeOutDays = d;
        }
    }

    async getById(
        id: string
    ): Promise<GenericResponseDto<TimeEntry> | undefined> {
        const compareDelegate = (onlineTimeEntry: TimeEntry, notSyncedOfflineTimeEntry: TimeEntry) =>
            this.compareTimeEntry(onlineTimeEntry, notSyncedOfflineTimeEntry)
        const unProcessedDelegate = (id: string, hasOfflineAccess: boolean) => this.getUnprocessedTimeEntryById(id, hasOfflineAccess);
        const offline = (key: string) => {
            return this.timeEntryOfflineService.getById(key);
        };
        const online = (key: string) => {
            return this.timeEntryOnlineService.getById(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            string,
            TimeEntry
        >(id, offline, online, compareDelegate, unProcessedDelegate);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getByEmployeeIdAndDate(
        employeeId: string,
        month: number,
        year: number
    ): Promise<GenericResponseDto<TimeEntry[]> | undefined> {
        const params = new TimeEntryParamsDto();
        params.employeeId = employeeId;
        params.month = month;
        params.year = year;

        const offline = (key: TimeEntryParamsDto) => {
            return this.timeEntryOfflineService.getByEmployeeIdAndDate(key);
        };
        const online = (key: TimeEntryParamsDto) => {
            return this.timeEntryOnlineService.getByEmployeeIdAndDate(key);
        };
        const compareDelegate = (onlineValues: TimeEntry[], offlineValue: TimeEntry[]) => this.compareTimeEntryArray(onlineValues, offlineValue);
        const unProcessedDelegate = async (key: TimeEntryParamsDto, hasOfflineAccess: boolean) => this.getUnprocessedByEmployeeIdAndDate(key, hasOfflineAccess);

        const response = await this.datasourceDelineationService.makeCall<
            TimeEntryParamsDto,
            TimeEntry[]
        >(params, offline, online, compareDelegate, unProcessedDelegate);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getDayTimeEntriesByEmployeeIdAndDate(
        employeeId: string,
        month: number,
        year: number
    ): Promise<GenericResponseDto<DayTimeEntry[]> | undefined> {
        const params = new TimeEntryParamsDto();
        params.employeeId = employeeId;
        params.month = month;
        params.year = year;

        const offline = (key: TimeEntryParamsDto) => {
            return this.timeEntryOfflineService.getDayTimeEntriesByEmployeeIdAndDate(
                key
            );
        };
        const online = (key: TimeEntryParamsDto) => {
            return this.timeEntryOnlineService.getDayTimeEntriesByEmployeeIdAndDate(
                key
            );
        };
        const response = await this.datasourceDelineationService.makeCall<
            TimeEntryParamsDto,
            DayTimeEntry[]
        >(params, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getTimeEntryTypes(): Promise<
        GenericResponseDto<TimeEntryType[]> | undefined
    > {
        const offline = () => {
            return this.timeEntryOfflineService.getTimeEntryTypes();
        };
        const online = (key: string) => {
            return this.timeEntryOnlineService.getTimeEntryTypes();
        };
        const response = await this.datasourceDelineationService.makeCall<
            undefined,
            TimeEntryType[]
        >(undefined, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async upsert(
        entry: TimeEntry
    ): Promise<GenericResponseDto<TimeEntry> | undefined> {
        const offline = (key: TimeEntry) => {
            return this.timeEntryOfflineService.upsert(key);
        };
        const online = (key: TimeEntry) => {
            return this.timeEntryOfflineService.upsert(key);
        };

        const response = await this.datasourceDelineationService.makeCall<
            TimeEntry,
            TimeEntry | undefined
        >(entry, offline, online);

        if (response?.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async upsertArray(
        entries: TimeEntry[]
    ): Promise<GenericResponseDto<TimeEntry[]> | undefined> {
        const offline = (key: TimeEntry[]) => {
            return this.timeEntryOfflineService.upsertArray(key);
        };
        const online = (key: TimeEntry[]) => {
            return this.timeEntryOfflineService.upsertArray(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            TimeEntry[],
            TimeEntry[] | undefined
        >(entries, offline, online);

        if (response?.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async upsertDayTimeEntry(
        entry: DayTimeEntry
    ): Promise<GenericResponseDto<DayTimeEntry> | undefined> {
        const offline = (key: DayTimeEntry) => {
            return this.timeEntryOnlineService.upsertDayTimeEntry(key);
        };
        const online = (key: DayTimeEntry) => {
            return this.timeEntryOnlineService.upsertDayTimeEntry(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            DayTimeEntry,
            DayTimeEntry | undefined
        >(entry, offline, online);

        if (response?.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async saveCallTimeEntry(
        call: Call,
        customer: Customer
    ): Promise<GenericResponseDto<undefined>> {
        const params = new CallTimeEntryParams();
        params.call = call;
        params.customer = customer;
        const typeResponse = await this.timeEntryTypeDelineationService.getTimeEntryTypeByName(
            TimeEntryTypeNames.call
        );
        if (!typeResponse) {
            return;
        }

        params.type = typeResponse.values;

        const offline = (key: CallTimeEntryParams) => {
            return this.timeEntryOfflineService.saveCallTimeEntry(key);
        };
        const online = (key: CallTimeEntryParams) => {
            return this.timeEntryOfflineService.saveCallTimeEntry(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            CallTimeEntryParams,
            undefined
        >(params, offline, online);

        if (response?.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    private async getUnprocessedTimeEntryById(key: string, hasOfflineAccess: boolean): Promise<TimeEntry> {
        const unprocessed = await this.timeEntryOfflineService.getUnprocessedById(key);

        void this.cleanUpTimeEntries(hasOfflineAccess);
        return unprocessed;
    }

    private async getUnprocessedByEmployeeIdAndDate(key: TimeEntryParamsDto, hasOfflineAccess: boolean): Promise<TimeEntry[]> {
        const unprocessed = await this.timeEntryOfflineService.getUnprocessedByEmployeeIdAndDate(key);

        void this.cleanUpTimeEntries(hasOfflineAccess);
        return unprocessed;
    }

    private async cleanUpTimeEntries(hasOfflineAccess: boolean): Promise<void> {
        if (hasOfflineAccess) return;

        const processed = await this.dbService.timeEntries.where("hasServerProcessed").equals(1).toArray();

        if (processed.length) {
            this.dbService.timeEntries.bulkDelete(processed.map(v => v.id));
        }
    }

    private async compareTimeEntry(
        onlineTimeEntry: TimeEntry,
        notSyncedOfflineTimeEntry: TimeEntry
    ) : Promise<TimeEntry> {
        if (!onlineTimeEntry) {
            return notSyncedOfflineTimeEntry;
        }
        const results = await this.compareTimeEntryArray(!!onlineTimeEntry ? [onlineTimeEntry] : null
            , !!notSyncedOfflineTimeEntry ? [notSyncedOfflineTimeEntry] : null);
        return results[0];
    }

    private async compareTimeEntryArray(
        onlineTimeEntries: TimeEntry[],
        notSyncedOfflineTimeEmtries: TimeEntry[]
    ): Promise<TimeEntry[]> {
        const rtn = new Array<TimeEntry>();
        const offlineTimeEntriesIdMap = new Map(
            notSyncedOfflineTimeEmtries?.map((entry) => [entry.id, entry])
        );

        for (const onlineTimeEntry of (onlineTimeEntries ??[])) {
            const offlineTimeEntry = offlineTimeEntriesIdMap?.get(
                onlineTimeEntry.id
            );
            if (offlineTimeEntry) {
                offlineTimeEntriesIdMap.delete(offlineTimeEntry.id);
                if (
                    (onlineTimeEntry.updatedDate?.getTime() ??
                        onlineTimeEntry.createdDate?.getTime()) >=
                    (offlineTimeEntry.updatedDate?.getTime() ??
                        offlineTimeEntry.createdDate?.getTime())
                ) {
                    rtn.push(onlineTimeEntry);
                } else {
                    rtn.push(offlineTimeEntry);
                }
            } else {
                rtn.push(onlineTimeEntry);
            }
        }

        // What is remaining in the offlineTimeEntriesIdMap are entries that don't exist in the online version
        // Unprocessed entries are placed at the top
        for (const offlineTimeEntryMap of offlineTimeEntriesIdMap.entries()) {
            rtn.push(offlineTimeEntryMap[1]);
        }

        return rtn;
    }
}
