import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';

import { ManualGratisEntryViewmodel } from './manual-gratis-entry.viewmodel';

@Component({
    selector: 'app-manual-gratis-entry',
    templateUrl: './manual-gratis-entry.component.html',
    styleUrls: ['./manual-gratis-entry.component.scss']
})
export class ManualGratisEntryComponent implements OnInit, OnDestroy {

    viewmodel: ManualGratisEntryViewmodel;

    constructor(public injectedData: SwisherOverlayRef<
            ManualGratisEntryViewmodel,
            ManualGratisEntryComponent
        >) { }

    ngOnInit(): void {
        this.viewmodel = this.injectedData.data;

    }

    ngOnDestroy(): void {
        this.viewmodel.upsubscribe();
    }

}
