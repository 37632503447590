import { GenericLookup } from "../generic";
import { CustomerTypeEnum } from "../lookup";
import { CallDto } from "../type";
import { AddressDto } from "./address.dto";
import { CustomerLicenseDto } from "./customer-license.dto";
import { CustomerWholesalerDto } from "./customer-wholesaler.dto";
import { CustomerZrtDto } from "./customer-zrt.dto";
import { MsaDistributorVolumeResponseDto } from "./msa-distributor-volume-response.dto";

export class CustomerDto {
    id: string;
    msaRetailId: string | null;
    customerNumber: string;
    customerType: GenericLookup<CustomerTypeEnum>
    retailNumber: string | null;
    chainIndicator: string | null;
    ownerCode: string | null;
    callFileIndicator: string | null;
    retailIndicator: string | null;
    availability: string | null;
    email: string | null;
    fax: string | null;
    federalTaxId: string | null;
    federalTaxIdLastUpdated: string | null;
    hasPhone: boolean | null;
    inDistributionVolume: number | null;
    industryVolume: number;
    shieldVolume: number;
    shieldVolumePercentage: number;
    isActive: boolean | null;
    isCallable: boolean | null;
    isCallableOverridden: boolean | null;
    isMsa: boolean | null;
    isRouted: boolean | null;
    lastCall: string | null;
    license: string | null;
    licenseLastUpdated: string | null;
    name: string | null;
    phone: string | null;
    region: string | null;
    status: string | null;
    stateTaxId: string | null;
    stateTaxIdLastUpdated: string | null;
    territory: string | null;
    zone: string | null;
    zrt: string | null;
    comments: string | null;
    commentsLastUpdated: string | null;
    commentsLastUpdatedBy: string | null;
    manager: string | null;
    rep: string | null;
    geoArea: string | null;
    businessAddress: AddressDto | null;
    dbaAddress: AddressDto | null;
    dateCreated: string | null;
    lastEdited: string | null;
    lastEditedBy: string | null;
    deactivatedDate: string | null;
    deactivatedEmployeeId: string | null;
    deactivatedBy: string | null;
    reactivatedDate: string | null;
    reactivatedEmployeeId: string | null;
    reactivatedBy: string | null;
    isDeleted: boolean | null;
    externalTdLinx: string | null;
    lastEtlUpdated: string | null;
    lastDeletionDate: string | null;
    chain: string | null;
    nam: string | null;
    chainRep: string | null;
    distributorAccountNumber: string | null;
    groupId: number | null;
    isAddNewBusiness: boolean | null;
    program: string | null;
    orderType: string | null;
    assignedEmployeeFullName: string;
    accountOwnershipHierarchyLevelName: string;
    storeCount: number | null;
    isCashAndCarry: boolean | null;
    hasPendingOrders: boolean | null;

    px3RankId?: string|null;
    callsMade?: number|null;

    customerWholesalers: CustomerWholesalerDto[];
    customerLicenses: CustomerLicenseDto[];
    customerZrts: CustomerZrtDto[];
    calls: CallDto[];
    volumeResponse: MsaDistributorVolumeResponseDto | undefined;

    displayName: string | null;
    toRemove?: boolean;
    rowversion: string;
}
