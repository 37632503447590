export class ZoneRecapFilterDto {
    //Usage: Get based on call date on or after
    callOnOrAfter?: string;

    //Usage: Get based on call date on or after
    callOnOrBefore?: string;

    //Usage: Get based on Zrt
    zrts?: string[];

}
