import { Injectable } from "@angular/core";
import { CustomerOfflineService } from "../offline-services/customer-offline.service";
import { Customer } from "../../entity-models/customer.entity";
import { CustomerOnlineService } from "../online-services/customer-online.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { SnackbarService } from "../snackbar.service";
import {
    AccountBatchParamsDto,
    AccountsListColumns,
    CustomerTypeEnum,
    FilterRequestV2Dto,
    FilterSortDto,
    GenericResponseDto,
} from "shield.shared";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { DelineationContext } from "./delineation-context.service";
import { DatabaseService } from "../database.service";
import {
    Observable,
    throwError
} from "rxjs";
import { Product } from "src/app/entity-models/product.entity";
import { DexieTableNames } from "src/app/enums/dexie-table-names";
import { AccountListFilterMapService } from "../filter-map-services/account-list-filter-map.service";
import { CustomerMarker } from "src/app/entity-models/customer-marker.entity";
import { GoogleMapLatLng } from "src/app/accounts/accounts-list/googleMapsModels";
import { WholesalerGroupProductCatalogItemOfflineService } from "../offline-services/wholesaler-group-product-catalog-item-offline.service";

@Injectable()
export class CustomerDelineationService extends DelineationContext<Customer, string> {

    constructor(private customerOfflineService: CustomerOfflineService,
        private customerOnlineService: CustomerOnlineService,
        snackbarService: SnackbarService,
        protected datasourceDelineationService: DatasourceDelineationService,
        protected dbService: DatabaseService,
        private groupCatalogService: WholesalerGroupProductCatalogItemOfflineService,
    ) {
        super(dbService, datasourceDelineationService, snackbarService);
    }

    async getCustomersByOwnerCode(code: string): Promise<GenericResponseDto<Customer[]>> {
        const offline = (key: string) => {
            return this.customerOfflineService.getCustomersByOwnerCode(key);
        }
        const online = (key: string) => {
            return this.customerOnlineService.getCustomersByOwnerCode(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, Customer[]>(code, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getByCustomerTypes(customerTypes: CustomerTypeEnum[], forceOffline?: boolean): Promise<GenericResponseDto<Customer[]>> {
        const offline = (key: CustomerTypeEnum[]) => {
            return this.customerOfflineService.getByCustomerTypes(key);
        }
        const online = (key: CustomerTypeEnum[]) => {
            return forceOffline ? this.customerOfflineService.getByCustomerTypes(key) : this.customerOnlineService.getByCustomerTypes(key);
        }
        const response = await this.datasourceDelineationService.makeCall<CustomerTypeEnum[], Customer[]>(customerTypes, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async upsertCustomer(customer: Customer): Promise<GenericResponseDto<Customer | undefined>> {
        if (!customer) return;

        this.setLastCall(customer);
        customer.lastEdited = new Date();
        customer.hasServerProcessed = 0;
        await this.persist(customer, DexieTableNames.customers);

        const offline = (key: Customer) => {
            return this.customerOfflineService.upsertCustomer(key);
        }
        const online = async (key: Customer) => {
            return await this.customerOfflineService.upsertCustomer(key);
        }
        const response = await this.datasourceDelineationService.makeCall<Customer, Customer | undefined>(customer, offline, online);

        this.customerOfflineService.updateCustomerStore(customer);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getWholesalersForCustomer(customer: Customer): Promise<GenericResponseDto<Customer[]>> {

        const offline = (key: Customer) => {
            return this.customerOfflineService.getWholesalersForCustomer(key);
        }
        const online = (key: Customer) => {
            return this.customerOnlineService.getWholesalersForCustomer(key);
        }
        const response = await this.datasourceDelineationService.makeCall<Customer, Customer[] | undefined>(customer, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getByIds(ids: string[], forceOffline?: boolean): Promise<GenericResponseDto<Customer[]>> {

        const offline = (key: string[]) => {
            return this.customerOfflineService.getByIds(key);
        }
        const online = (key: string[]) => {
            return forceOffline ? this.customerOfflineService.getByIds(key) : this.customerOnlineService.getByIds(key);
        }
        const compareDelegate = this.compareCustomerArray;
        const unProcessedDelegate = (key: string[], hasOfflineAccess: boolean) => this.getUnprocessedCustomersByIds(key, hasOfflineAccess);
        const response = await this.datasourceDelineationService.makeCall<string[], Customer[] | undefined>(
                ids, offline, online, compareDelegate, unProcessedDelegate);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getFromServerByIds(ids: string[]): Promise<GenericResponseDto<Customer[]>> {
            const response = await this.customerOnlineService.getByIds(ids);

            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
    }

    async getById(id: string, forceOffLine?: boolean): Promise<GenericResponseDto<Customer>> {
        const offline = (key: string) => {
            return this.customerOfflineService.getById(key);
        }
        const online = (key: string) => {
            return this.customerOnlineService.getById(key);
        }
        const compareDelegate = (onlineCustomer: Customer, notSyncedOfflineCustomer: Customer) =>  this.compareCustomer(onlineCustomer, notSyncedOfflineCustomer);
        const unProcessedDelegate = async (key: string, hasOfflineAccess: boolean) => this.getUnprocessedCustomersById(key, hasOfflineAccess);
        const response = await this.datasourceDelineationService.makeCall<string, Customer | undefined>(id, offline, online, compareDelegate, unProcessedDelegate);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getNextAvailableCustomerNumber(prefix: string): Promise<GenericResponseDto<string>> {
        const offline = (key: string) => {
            return this.customerOfflineService.getNextAvailableCustomerNumber(key);
        }
        const online = (key: string) => {
            return this.customerOnlineService.getNextAvailableCustomerNumber(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, string>(prefix, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getBatch(id: string
        , refiners: Refiner[]
        , pageSize: number
        , startIndex: number
        , filterSorts: FilterSortDto<AccountsListColumns>[]
        , forceOnline?: boolean
    ): Promise<GenericResponseDto<Customer[]>> {

        const key = new AccountBatchParamsDto();
        key.filterRequestDto = new FilterRequestV2Dto();
        key.filterRequestDto.id = id;
        key.filterRequestDto.filters = AccountListFilterMapService.mapFilterData(refiners);
        key.filterRequestDto.pageSize = pageSize;
        key.filterRequestDto.startIndex = startIndex;
        key.filterRequestDto.filterSorts = filterSorts;
        key.zrts = key.filterRequestDto.filters?.zrtFilterDto?.zrts;
        key.employeeIds = key.filterRequestDto.filters?.zrtFilterDto?.employeeIds;

        const offline = (key: AccountBatchParamsDto) => {
            return this.customerOfflineService.getBatch(key);
        }
        const online = (key: AccountBatchParamsDto) => {
            return this.customerOnlineService.getBatch(key);
        }
        const compareDelegate = this.compareCustomerArray;
        const unProcessedDelegate = (key: AccountBatchParamsDto, hasOfflineAccess: boolean) => this.getUnprocessedCustomersByBatch(key, hasOfflineAccess);
        unProcessedDelegate.bind(this);
        const response = await this.datasourceDelineationService.makeCall<AccountBatchParamsDto, Customer[]>(key, offline, online, compareDelegate, unProcessedDelegate, forceOnline);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    getAccountListExport(id: string
        , refiners: Refiner[]
        , pageSize: number
        , startIndex: number
        , filterSorts: FilterSortDto<AccountsListColumns>[]
    ): Observable<Blob | never> {

        const key = new AccountBatchParamsDto();
        key.filterRequestDto = new FilterRequestV2Dto();
        key.filterRequestDto.id = id;
        key.filterRequestDto.filters = AccountListFilterMapService.mapFilterData(refiners);
        key.filterRequestDto.pageSize = pageSize;
        key.filterRequestDto.startIndex = startIndex;
        key.filterRequestDto.filterSorts = filterSorts;
        key.zrts = key.filterRequestDto.filters?.zrtFilterDto?.zrts;
        key.employeeIds = key.filterRequestDto.filters?.zrtFilterDto?.employeeIds;

        const online = (key: AccountBatchParamsDto) => {
            return this.customerOnlineService.getCustomerListExport(key);
        }
        const offline = (key: AccountBatchParamsDto) => {
            return this.customerOfflineService.getCustomerListExport(key);
        }

        try {
            return this.datasourceDelineationService.makeCallWithBlobReturn<AccountBatchParamsDto, Observable<Blob | never>>(key, offline, online);
        } catch (e) {
            this.snackbarService.showWarning(e);
            return throwError(e);
        }
    }

    async getWholesalersWithGroupProducts(): Promise<GenericResponseDto<Customer[]>> {
        const offline = () => {
            return this.customerOfflineService.getWholesalersWithGroupProducts();
        }
        const online = () => {
            return this.customerOfflineService.getWholesalersWithGroupProducts();
        }
        const response = await this.datasourceDelineationService.makeCall<undefined, Customer[]>(undefined, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getWholesalersByCustomerAndProduct(customer: Customer, product: Product): Promise<GenericResponseDto<Customer[]>> {

        if (!customer || !product) { return; }

        let rtn = new GenericResponseDto<Customer[]>();
        rtn.values = new Array<Customer>();

        const wholesalerIds = customer.customerWholesalers ? customer.customerWholesalers.map((c) => c.wholesalerId) : [];
        if (wholesalerIds.length > 0) {

            const wholesalerProductCatalogItems = await this.dbService.wholesalerProductCatalogItems
                .where("wholesalerId")
                .anyOf(wholesalerIds)
                .and((item) => item.productId === product.id && !item.isDeactivated)
                .toArray();

            const wholesalerIdsWithProduct = [...new Set(wholesalerProductCatalogItems?.map((item) => item.wholesalerId))];

            return this.getByIds(wholesalerIdsWithProduct);
        }

        return rtn;
    }

    async getWholesalersByCustomerWithProducts(customer: Customer): Promise<GenericResponseDto<Customer[]>> {

        if (!customer) { return; }

        let rtn = new GenericResponseDto<Customer[]>();
        rtn.values = new Array<Customer>();

        const wholesalerIds = customer.customerWholesalers ? customer.customerWholesalers.map((c) => c.wholesalerId) : [];
        if (wholesalerIds.length > 0) {

            const wholesalerProductCatalogItems = await this.dbService.wholesalerProductCatalogItems
                .where("wholesalerId")
                .anyOf(wholesalerIds)
                .and(item => !item.isDeactivated)
                .toArray();

            const wholesalerIdsWithProducts = [...new Set(wholesalerProductCatalogItems?.map((item) => item.wholesalerId))];

            return this.getByIds(wholesalerIdsWithProducts);
        }

        return rtn;
    }

    async getWholesalersbyProduct(product: Product): Promise<GenericResponseDto<Customer[]>> {

        const offline = (key: string) => {
            return this.customerOfflineService.getWholesalersbyProductId(key);
        }
        const online = (key: string) => {
            return this.customerOnlineService.getWholesalersbyProductId(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, Customer[]>(product.id, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getMarkersByEmployeeId(employeeId: string): Promise<GenericResponseDto<CustomerMarker[]>> {
        const offline = (key: string) => {
            return this.customerOfflineService.getMarkersByEmployeeId(key);
        }
        const online = (key: string) => {
            return this.customerOnlineService.getMarkersByEmployeeId(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, CustomerMarker[] | undefined>(employeeId, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getMarkersByFilters(
        id: string,
        refiners: Refiner[],
        lowerBound?: GoogleMapLatLng,
        upperBound?: GoogleMapLatLng
    ): Promise<GenericResponseDto<CustomerMarker[]>> {

        const key = new AccountBatchParamsDto();
        key.filterRequestDto = new FilterRequestV2Dto();
        key.filterRequestDto.id = id;
        const filters = AccountListFilterMapService.mapFilterData(refiners);
        key.filterRequestDto.filters = AccountListFilterMapService.mapLatLngFilter(filters, lowerBound, upperBound);
        key.filterRequestDto.pageSize = 15000;
        key.filterRequestDto.startIndex = 0;
        key.filterRequestDto.filterSorts = new Array<FilterSortDto<AccountsListColumns>>();

        const offline = (key: AccountBatchParamsDto) => {
            return this.customerOfflineService.getMarkersByFilters(key);
        }
        const online = (key: AccountBatchParamsDto) => {
            return this.customerOnlineService.getMarkersByFilters(key);
        }
        const response = await this.datasourceDelineationService.makeCall<AccountBatchParamsDto, CustomerMarker[]>(key, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getCustomerCallableStatus(customerId: string): Promise<GenericResponseDto<boolean>> {
        const offline = (key: string) => {
            return this.customerOfflineService.getCustomerCallableStatus(key);
        }
        const online = (key: string) => {
            return this.customerOfflineService.getCustomerCallableStatus(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, boolean>(customerId, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    //private
    private async setLastCall(customer: Customer): Promise<Customer> {

        const calls = await this.dbService.calls
            .where("customerId")
            .equals(customer.id)
            .toArray();

        const orderedCalls = calls.filter((call) => !!call.stopTime)
            .sort((a, b) => a.stopTime <= b.stopTime ? 1 : -1);

        if (orderedCalls?.length) {
            customer.lastCall = orderedCalls[0].stopTime;
        }

        return customer;
    }

    async getWholesalersInWholesalerGroups(): Promise<GenericResponseDto<Customer[]>> {
        const offline = (key: undefined) => {
            return this.customerOfflineService.getWholesalersInWholesalerGroups(key);
        }
        const online = (key: undefined) => {
            return this.customerOnlineService.getWholesalersInWholesalerGroups(key);
        }
        const response = await this.datasourceDelineationService.makeCall<undefined, Customer[]>(undefined, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    private async getUnprocessedCustomersById(key: string, hasOfflineAccess: boolean): Promise<Customer> {
        const unprocessed = await this.customerOfflineService.getUnprocessedByIds([key]);

        void this.cleanUpCustomers(hasOfflineAccess);
        return unprocessed[0];
    }

    private async getUnprocessedCustomersByIds(key: string[], hasOfflineAccess: boolean): Promise<Customer[]> {
        const unprocessed = await this.customerOfflineService.getUnprocessedByIds(key);

        void this.cleanUpCustomers(hasOfflineAccess);
        return unprocessed;
    }

    private async getUnprocessedCustomersByBatch(key: AccountBatchParamsDto, hasOfflineAccess: boolean): Promise<Customer[]> {
        const unprocessed = await this.customerOfflineService.getUnprocessedBatch(key);

        void this.cleanUpCustomers(hasOfflineAccess);
        return unprocessed;
    }

    private async cleanUpCustomers(hasOfflineAccess: boolean): Promise<void> {
        if (hasOfflineAccess) return;

        const processed = await this.dbService.customers.where("hasServerProcessed").equals(1).toArray();

        if (processed.length) {
            this.dbService.customers.bulkDelete(processed.map(v => v.id));
        }
    }

    private async compareCustomer(
        onlineCustomer: Customer,
        notSyncedOfflineCustomer: Customer
    ) : Promise<Customer> {
        if (!onlineCustomer) {
            return notSyncedOfflineCustomer;
        }
        const results = await this.compareCustomerArray(!!onlineCustomer ? [onlineCustomer]: null
            , !!notSyncedOfflineCustomer ? [notSyncedOfflineCustomer] : null);
        return results[0];
    }

    private async compareCustomerArray(
        onlineCustomers: Customer[],
        notSyncedOfflineCustomers: Customer[]
    ): Promise<Customer[]> {
        const rtn = new Array<Customer>();
        const offlineCustomerMaps = new Map(notSyncedOfflineCustomers?.map((entry) => [entry.id, entry]));

        for (const onlineCustomer of (onlineCustomers ?? [])) {
            const offlineCustomer = offlineCustomerMaps?.get(onlineCustomer.id);
            if (offlineCustomer) {
                offlineCustomerMaps.delete(offlineCustomer.id);
                if (
                    (onlineCustomer.lastEdited?.getTime() ?? onlineCustomer.dateCreated?.getTime()) >=
                    (offlineCustomer.lastEdited?.getTime() ?? offlineCustomer.dateCreated?.getTime())
                ) {
                    rtn.push(onlineCustomer);
                } else {
                    rtn.push(offlineCustomer);
                }
            } else {
                rtn.push(onlineCustomer);
            }
        }

        // What is remaining in the offlineCustomerMaps are entries that don't exist in the online version
        // Unprocessed entries will appear once synced
        return rtn;
    }
}
