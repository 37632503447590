import { HttpClient } from "@angular/common/http";
import {
    Inject,
    Injectable
} from "@angular/core";
import {
    BehaviorSubject,
    Observable
} from "rxjs";
import {
    milisecondsToSecondsScalar,
    SharedHelper
} from "shield.shared";
import { SyncVersionKeyNames } from "../enums/sync-version-key-names";
import {
    DataSyncHandlerInterface,
    dataSyncHandlerInterfaceToken
} from "../sync/data-sync-handler-interface";
import { DataSyncQueueService } from "../sync/data-sync-queue.service";
import { SyncLevel } from "../sync/sync-enums/sync-level.enum";
import { DatabaseService } from "./database.service";
import { PingService } from "./ping.service";
import { CustomerOfflineService } from "./offline-services/customer-offline.service";
import * as moment from 'moment';
import { AppStateService } from "./app-state.service";
import { CallSyncCommand, CallSyncPayload, ContactSyncCommand, CustomerSyncCommand, PictureSyncCommand, ReceiptSyncCommand } from "shield.shared";
import { CallOnlineService } from "src/app/services/online-services/call-online.service";


@Injectable()
export class SyncService {

    //private
    private _inboundSyncing = new BehaviorSubject<boolean>(false);
    private _online = false;
    private _forceSync = false;
    private _outboundSyncing = new BehaviorSubject<boolean>(false);
    private _successfulSyncCycle: undefined = undefined;
    private _syncAttempt: undefined = undefined;
    private currentRunSyncMap = new Map<SyncVersionKeyNames, boolean>();
    private static inboundSyncIntervalSeconds = 900;
    private initialSyncMap = new Map<SyncVersionKeyNames, boolean>();
    private lastSyncResult = true;
    private static outboundSyncIntervalSeconds = 30;
    private static syncErrorThreshold = 2;
    private successfulLevelOneSyncSubject = new BehaviorSubject<void>(null);
    private successfulSyncCycleSubject: BehaviorSubject<undefined> = new BehaviorSubject(
        this._successfulSyncCycle
    );
    private syncAttemptSubject: BehaviorSubject<undefined> = new BehaviorSubject(
        this._syncAttempt
    );
    private _syncVersionKeyInProgress: SyncVersionKeyNames = null;
    private syncVersionKeyInProgressSubject: BehaviorSubject<SyncVersionKeyNames> = new BehaviorSubject(
        this._syncVersionKeyInProgress
    )
    private syncErrors: string[];
    private static enableAutoSync = 0;

    // public
    public readonly inboundSyncing: Observable<boolean> = this._inboundSyncing.asObservable();
    public readonly observableSuccessfulLevelOneSync: Observable<void> = this.successfulLevelOneSyncSubject.asObservable();
    public readonly observablesuccessfulSyncCycle: Observable<undefined> = this.successfulSyncCycleSubject.asObservable();
    public readonly observableSyncAttempt: Observable<undefined> = this.syncAttemptSubject.asObservable();
    public readonly observableSyncVersionKeyInProgress: Observable<SyncVersionKeyNames> = this.syncVersionKeyInProgressSubject.asObservable();
    public readonly outboundSyncing: Observable<boolean> = this._outboundSyncing.asObservable();
    public searchableZrt: string;
    public firstSync = false;


    //get, setters
    get isInitallySynced(): boolean {
        if (!this.initialSyncMap.size) {
            try {
                JSON.parse(localStorage.getItem("initial-sync-array")).map((a: [SyncVersionKeyNames, boolean]) => {
                    this.initialSyncMap.set(a[0], a[1]);
                });
                console.log('init sync arr rebuilt')
            } catch (e) {
                console.log('failed building init sync arr')
            }
        }
        return this.initialSyncMap.size > 0 && ![...this.initialSyncMap.entries()].some((value) => !value[1]);
    }
    public get lastSync(): string {
        return localStorage.getItem('sync_last_outbound') || 'Not synced';
    }
    public set lastSync(value: string) {
        localStorage.setItem('sync_last_outbound', (moment(new Date())).format("MM/DD/YYYY hh:mma"));
    }
    public get allowSync(): boolean {
        return !this.searchableZrt || this.searchableZrt?.length <= 2 || this._forceSync || !this.isInitallySynced;
    }
    public set allowSync(value: boolean) {
        this._forceSync = value;
    }

    constructor(private dbService: DatabaseService,
        @Inject(dataSyncHandlerInterfaceToken)
        private dataSyncHandlers: DataSyncHandlerInterface[],
        private syncQueue: DataSyncQueueService,
        private pingService: PingService,
        private customerOfflineService: CustomerOfflineService,
        private callOnlineService: CallOnlineService,
        private appStateService: AppStateService,
    ) {

        this.pingService.online.subscribe((isOnline) => {
            this._online = isOnline;
            if (this._online) {
                this.onlineHandler();
            } else {
                this.offlineHandler();
            }
        });

        window.addEventListener("online", this.onlineHandler.bind(this));
        window.addEventListener("offline", this.offlineHandler.bind(this));
        this.lastSyncResult = !this._online;
    }

    //public methods
    notifySyncComplete(): void {
        this.successfulLevelOneSyncSubject.next(null);
    }

    async forceSync(noRefresh?: boolean) {
        this._forceSync = true;
        this.customerOfflineService.resetCustomerStore();
        await this.sync(noRefresh);
        this._forceSync = false;
    }

    async sync(noRefresh?: boolean): Promise<void> {
        this.syncErrors = [];
        if (!this.isInitallySynced || this._forceSync) {
            if (!this.isInitallySynced) {
                console.log('This is the initial sync v4');
            } else {
                console.log('This is a manual sync v4');
            }
            await this.callChecker();
            await this.outboundSync();
            await this.inboundSync();
            console.log(this.syncErrors);
            if (this._online && this.syncErrors.length > SyncService.syncErrorThreshold) {
                if (window.confirm(`Shield is having trouble syncing.
    Make sure you have a good, stable internet connection - WIFI is best.
    Please click OK to retry or Cancel and try again at a later time.`)) {
                    console.log('user confirmed retry')
                    this.sync();
                } else {
                    console.log('user rejected retry')
                }
            } else if (!this._online || this.syncErrors.length > SyncService.syncErrorThreshold) {
                alert(`Shield is having trouble syncing.
    Make sure you have a good, stable internet connection - WIFI is best.
    Please try again at a later time`);
            } else {
                await this.appStateService.refreshAuthToken();
                this.lastSync = 'done';

                if (!noRefresh) {
                    console.log('Reloading page because sync completed');
                    window.location.reload()
                } else {
                    console.log('Not reloading because noRefresh is set')
                }
            }
        } else {
            console.log('Trying to sync when it shouldnt v4');
            this.successfulLevelOneSyncSubject.next(null)
        }
    }

    forceOutboundSync(): void {
        this.syncOutboundHandler();
    }

    async syncInboundHandler(): Promise<void> {
        this._inboundSyncing.next(true);

        this.syncAttemptSubject.next(undefined);

        const initialSyncers = new Array<DataSyncHandlerInterface>();
        const initialSyncList = new Array<SyncVersionKeyNames>();
        const requiredSyncers = new Array<DataSyncHandlerInterface>();
        const optionalSyncers = new Array<DataSyncHandlerInterface>();
        const allSyncs = new Array<DataSyncHandlerInterface>();

        if (!this.searchableZrt || this.searchableZrt?.length <= 2) {

            initialSyncers.push(...this.dataSyncHandlers.filter((es) => es.onlineRepSyncType === SyncLevel.Initial));
            initialSyncList.push(...initialSyncers.map(v => v.syncVersionKey));
            requiredSyncers.push(...this.dataSyncHandlers.filter((es) => es.onlineRepSyncType === SyncLevel.Required));
            optionalSyncers.push(...this.dataSyncHandlers.filter((es) => es.onlineRepSyncType === SyncLevel.Optional));

        } else {

            // reorder syncs to ensure the level ones complete first.
            initialSyncers.push(...this.dataSyncHandlers.filter((es) => es.offlineRepSyncType === SyncLevel.Initial));
            initialSyncList.push(...initialSyncers.map(v => v.syncVersionKey));
            requiredSyncers.push(...this.dataSyncHandlers.filter((es) => es.offlineRepSyncType === SyncLevel.Required));
            optionalSyncers.push(...this.dataSyncHandlers.filter((es) => es.offlineRepSyncType === SyncLevel.Optional));

        }

        this.currentRunSyncMap.clear();

        allSyncs.push(...initialSyncers.concat(requiredSyncers, optionalSyncers).filter(v => !!v.syncVersionKey));
        for (const key of initialSyncers.concat(requiredSyncers).map((dsh) => dsh.syncVersionKey)) {
            this.currentRunSyncMap.set(key, false);
        }

        for (const syncer of allSyncs) {
            this.syncVersionKeyInProgressSubject.next(syncer.syncVersionKey);

            const hasInitallySynced = this.initialSyncMap.get(syncer.syncVersionKey);

            if (optionalSyncers.includes(syncer) && this.firstSync) {
                this.successfulSyncCycleSubject.next(undefined);
                this.firstSync = false;
            }

            await syncer.execute();

            if (!syncer.isRunSuccessfull) {
                this.syncErrors.push(syncer.syncVersionKey);
            }

            if (syncer.isRunSuccessfull) {
                this.currentRunSyncMap.set(syncer.syncVersionKey, true);
            }

            if (!this.isInitallySynced && syncer.isRunSuccessfull) {

                if (!hasInitallySynced) {
                    this.initialSyncMap.set(syncer.syncVersionKey, true);
                    window.localStorage.setItem("initial-sync-array", JSON.stringify(Array.from(this.initialSyncMap.entries())));
                }
            }

            if (Array.from(this.currentRunSyncMap.entries()).filter(e => initialSyncList.includes(e[0])).every(e => !!e[1])) {
                this.successfulLevelOneSyncSubject.next(null);
            }
        }
        //Save the current Px3IncentivePeriodId after each sync, so the CustomerDataSyncHandler can know if the incentive period has changed and update px3 ranks and calls made accordingly
        const currentDateTime = new Date();
        const currentIncentivePeriod = await this.dbService.px3IncentivePeriods.filter(pip => pip.startDate <= currentDateTime && pip.endDate >= currentDateTime).first();
        window.localStorage.setItem('sync_current_px3_incentive_period_id', currentIncentivePeriod.id);
        // this.lastSync = 'done';
        this._inboundSyncing.next(false);
    }

    async syncOutboundHandler(): Promise<void> {
        if (this._online && this.allowSync) {
            const now = new Date();
            const queue = (await this.syncQueue.peekQueueEntries() ?? []).filter((sc) => sc.nextVisibleTime <= now);
            if (queue.length) {
                this._outboundSyncing.next(true);

                for (const syncer of this.dataSyncHandlers) {
                    await syncer.pushData();
                }
                this._outboundSyncing.next(false);
            }
        }
    }

    private async callChecker() {
        console.log('Starting Call Checker')
        let calls = await this.dbService.calls
            .where("hasServerProcessed")
            .equals(0)
            .toArray();

        let callIds = calls.map(c => c.id);

        let pending = await this.dbService.syncQueue
            .where("entity")
            .equals('Call')
            .toArray();

        let serverCalls = await this.callOnlineService.getCallByIds(callIds);

        let rerun = calls.filter(c => {
            let server = serverCalls?.values?.find(f => f.id == c.id)
            let queue = pending?.find(f => {
                let pl: any = f.payload;
                return pl.id == c.id
            });

            if (server) {
                this.dbService.calls.update(c.id, {
                    hasServerProcessed: 1
                })
                return false;
            } else if (queue) {
                return false;
            } else {
                return true;
            }

        });

        console.log(`Found ${rerun?.length} calls to resync`);

        const CommandTypes: Record<string, any> = {
            Customer: 'customerId',
            Call: 'id',
            Receipt: 'callReceipts',
            Picture: 'callPictures',
            Contact: 'selectedContact'
        }

        let messages: Record<string, any> = [];
        rerun.map((call: Record<string, any>) => {
            let com = Object.keys(CommandTypes).map(ct => {
                switch (ct) {
                    case 'Customer':
                        this.syncQueue.enqueue(
                            new CustomerSyncCommand(call.customerId)
                        );
                        break;
                    case 'Call':
                        this.syncQueue.enqueue(
                            new CallSyncCommand(call.id)
                        );
                        break;
                    case 'Receipt':
                        call.callReceipts?.map((r: { id: string; }) => {
                            this.syncQueue.enqueue(
                                new ReceiptSyncCommand(r.id, call.id)
                            );
                        })

                        break;
                    case 'Picture':
                        call.callPictures?.map((p: { id: string; }) => {
                            this.syncQueue.enqueue(
                                new PictureSyncCommand(p.id, call.id)
                            );
                        })
                        break;
                    case 'Contact':
                        if (call.selectedContact) {
                            this.syncQueue.enqueue(
                                new ContactSyncCommand(call.selectedContact?.id)
                            );
                        }
                        break;
                }

            }, []);
        });

        console.log('Call Checker Done');
    }

    private async inboundSync(): Promise<void> {

        try {
            if (!this._online || !this.allowSync) {
                this.offlineHandler();
                this.notifySyncComplete();
                if (SyncService.enableAutoSync) {
                    setTimeout(function () {
                        this.inboundSync();
                    }.bind(this), milisecondsToSecondsScalar * PingService.onlineCheckIntervalSeconds);
                }
                return;
            }

            await this.syncInboundHandler();
            if (SyncService.enableAutoSync) {
                setTimeout(async function () {
                    await this.inboundSync();
                }.bind(this), milisecondsToSecondsScalar * SyncService.inboundSyncIntervalSeconds);
            }
        } catch {
            this._inboundSyncing.next(false);
            if (SyncService.enableAutoSync) {
                setTimeout(async function () {
                    await this.inboundSync();
                }.bind(this), milisecondsToSecondsScalar * PingService.onlineCheckIntervalSeconds);
            }
        }
    }

    private offlineHandler(): void {
        if (this.lastSyncResult != this._online) {
            this.lastSyncResult = false;
            console.log("Browser is offline, pausing data sync");
        }
    }

    private onlineHandler(): void {

        if (this.lastSyncResult != this._online) {
            this.lastSyncResult = true;
            console.log("Browser is online, resuming data sync");
        }
    }

    async outboundSync(): Promise<void> {

        try {
            if (!this._online) {
                this.offlineHandler();
                if (SyncService.enableAutoSync) {
                    setTimeout(function () {
                        this.outboundSync();
                    }.bind(this), milisecondsToSecondsScalar * PingService.onlineCheckIntervalSeconds);
                }
                return;
            }

            await this.syncOutboundHandler();

            if (SyncService.enableAutoSync) {
                setTimeout(async function () {
                    await this.outboundSync();
                }.bind(this), milisecondsToSecondsScalar * SyncService.outboundSyncIntervalSeconds);
            }

        } catch {
            this._outboundSyncing.next(false);
            if (SyncService.enableAutoSync) {
                setTimeout(async function () {
                    await this.outboundSync();
                }.bind(this), milisecondsToSecondsScalar * PingService.onlineCheckIntervalSeconds);
            }
        }
    }

    private async refreshIntervalValues(): Promise<void> {
        const inboundResponse = await this.dbService.systemInformation.where("key").equalsIgnoreCase("InboundSyncInterval").toArray();
        if (inboundResponse?.length > 0) {
            SyncService.inboundSyncIntervalSeconds = (SharedHelper.parseInt(inboundResponse[0].value) ?? 0);
        }

        const outboundResponse = await this.dbService.systemInformation.where("key").equalsIgnoreCase("OutboundSyncInterval").toArray();
        if (outboundResponse?.length > 0) {
            SyncService.outboundSyncIntervalSeconds = (SharedHelper.parseInt(outboundResponse[0].value) ?? 0);
        }

        const syncErrorThreshold = await this.dbService.systemInformation.where("key").equalsIgnoreCase("SyncErrorThreshold").toArray();
        if (syncErrorThreshold?.length > 0) {
            SyncService.syncErrorThreshold = (SharedHelper.parseInt(syncErrorThreshold[0].value) ?? 2);
        }

        const enableAutoSync = await this.dbService.systemInformation.where("key").equalsIgnoreCase("EnableAutoSync").toArray();
        if (enableAutoSync?.length > 0) {
            SyncService.enableAutoSync = (SharedHelper.parseInt(enableAutoSync[0].value) ?? 1);
        }

    }
}
