import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    GenericVersionResponseDto, SharedHelper, SyncCommandTypes,
    SystemInformationKeys, TimeEntryTypeDto, TimeEntryTypeSyncCommand,
    VersionResponseDto
} from "shield.shared";
import { TimeEntryType } from "src/app/entity-models/time-entry-type.entity";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { TimeEntryTypeConverterService } from "src/app/services/converter-services/time-entry-type-converter.service";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { SyncLevel } from "../sync-enums/sync-level.enum";

@Injectable()
export class TimeEntryTypeDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.Initial;
    offlineRepSyncType = SyncLevel.Initial;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.timeEntryType;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing TimeEntryTypes...");

        await this.pullData();

        this.log("Done syncing TimeEntryTypes...");
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/time-entry-types/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }



    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("Time entry types are up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Time entry types are out of date, syncing...");


        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.timeEntryTypePullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;

        try {
            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;

                const response = await this.http
                    .get<GenericVersionResponseDto<TimeEntryTypeDto[]>>(`/api/time-entry-types${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                this.log(
                    `Downloaded ${thisBatchSize} time entry types, saving to IndexedDB...`
                );

                const timeEntryTypes = response.values.map((te) =>
                    TimeEntryTypeConverterService.timeEntryTypeDtoToTimeEntryType(te)
                );

                await this.dbService.timeEntryTypes.bulkPut(timeEntryTypes);
                this.log(`  Saved  ${timeEntryTypes.length} time entries.`);

                this.lastSyncVersion = response.maxVersion;

            } while (thisBatchSize > 0);

            this.log("Done saving time entry data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing time entries", e);
        }
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<TimeEntryTypeSyncCommand>(
            SyncCommandTypes.timeEntryType,
            async (message) => {
                let timeEntryType = await this.dbService.timeEntryTypes.get(
                    message.payload.id
                );

                let dto = TimeEntryTypeConverterService.timeEntryTypeToTimeEntryTypeDto(timeEntryType);
                if (dto) {
                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }
}
