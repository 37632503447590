import { Injectable } from "@angular/core";
import { GenericRequestDto, GenericResponseDto, GratisBatchParamsDto, GratisCurrentBalanceDto, GratisRejectApprovalParamsDto } from "shield.shared";
import { GratisApproval } from "src/app/entity-models/gratis-approval.entity";
import { GratisAssignedTransaction } from "src/app/entity-models/gratis-assigned-transactions.entity";
import { Gratis } from "src/app/entity-models/gratis.entity";

@Injectable()
export class GratisOfflineService {

    private readonly notAvailableInOfflineModeError = "Gratis List is not available in offline mode.";

    async getBatch(
        key: GratisBatchParamsDto
    ): Promise<Gratis[]> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async completeOrder(gratis: Gratis) : Promise<GratisApproval> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async deletedGratisRequest(gratisId: string): Promise<string> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async getCurrentBalances(key: undefined): Promise<GratisCurrentBalanceDto[]> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async getGratisById(id: string): Promise<Gratis | undefined> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async getAvailableForGratis(gratisId?: string): Promise<GratisAssignedTransaction[]> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async saveGratisRequest(gratisRequest: GenericRequestDto<Gratis>): Promise<Gratis | undefined> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async submitGratisRequest(gratisRequest: GenericRequestDto<Gratis>): Promise<Gratis | undefined> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async rejectApproval(params: GratisRejectApprovalParamsDto): Promise<Gratis> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async approve(gratisId: string): Promise<Gratis> {

        throw Error(this.notAvailableInOfflineModeError);
    }
}
