export class WholesalerProductCatalogItems {
    id: string;
    wholesalerGroupProductCatalogItemId: string;
    wholesalerId: string;
    productId: string;
    wholesalePrice?: number;
    dateAvailable?: Date;
    isDeactivated: boolean;
    isDeactivatedDate?: Date;
}
