import { CreateModifyBaseDto } from "../create-modify-base.dto";

export class WholesalerGroupDto extends CreateModifyBaseDto {
    id: string;
    name: string;
    externalSyncId: string;
    customerId: string;
    orderGroupName: string | null;
    orderType: string | null;
    //orderFilePrefix: string | null; //DO NOT USE YET
    //orderPendingQuery: string | null; //DO NOT USE YET
    rowversion: string;
}
