import { Injectable } from "@angular/core";
import { Employee } from "src/app/entity-models/employee.entity";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { DatabaseService } from "src/app/services/database.service";

@Injectable()
export class EmployeeOfflineService {
    constructor(private dbService: DatabaseService) {}

    async getAll(key: null): Promise<Employee[]> {
        const employees: Employee[] = await this.dbService.employees
            .orderBy("fullName")
            .toArray();

        return employees;
    }

    async getById(id: string): Promise<Employee> {
        return await this.dbService.employees.where("id").equals(id).first();
    }

    async getByIds(ids: string[]): Promise<Employee[]> {
        return await this.dbService.employees.where("id").anyOf(ids).toArray();
    }

    async getByZrt(zrt: string): Promise<Employee[]> {
        const assignment = await this.dbService.zrtAssignments.where("zrt").equals(zrt).first();
        if (!assignment || !assignment.employeeId) return;

        return await this.dbService.employees.where("id").equals(assignment.employeeId).toArray();
    }

    async getEmployeeZrts(key: undefined | string): Promise<SearchZrtDropDown[]> {

        let zrtAssignments = await this.dbService.zrtAssignments.toArray();

        if (!zrtAssignments || !zrtAssignments.length) return;

        if (key) {
            const zone = key.slice(0,2);
            const region = key.slice(2,3);
            const territory = key.slice(3,4);

            zrtAssignments = zrtAssignments.filter(v => {
                let rtn = true;

                if (zone && zone != "00") {
                    rtn = (v.zone == zone);
                }

                if (rtn && region && region != "0") {
                    rtn = (v.region == region || v.region == "0");
                }

                if (rtn && territory && territory != "0") {
                    rtn = (v.territory == territory || v.territory == "0");
                }

                return rtn;
            });
        }

        const employees = await this.dbService.employees
            .where("id")
            .anyOf(zrtAssignments.map(v => v.employeeId).filter(v => !!v))
            .toArray();
        const employeeMap = new Map(employees.map((e) => [e.id, e]));

        const level1s = zrtAssignments.filter(v => v.region == '0' && v.territory == '0');
        const level2s = zrtAssignments.filter(v => v.region != '0' && v.territory == '0' && level1s.map(vv => vv.zone).includes(v.zone));
        const level3s = zrtAssignments.filter(v => v.region != '0' && v.territory != '0' && level2s.map(vv => vv.region).includes(v.region));

        const level3Nodes = level3s.map(v => {
            const i = new SearchZrtDropDown();
            const foundEmployee = employeeMap.get(v.employeeId);

            i.zrt = v.zrt;
            i.id = v.employeeId;
            i.name = foundEmployee?.fullName ?? "No Manager";
            i.displayValue = `${v.zrt} - ${i.name}`;

            return i;
        });

        const level2Nodes = level2s.map(v => {
            const i = new SearchZrtDropDown();
            const foundEmployee = employeeMap.get(v.employeeId);

            i.zrt = v.zrt;
            i.id = v.employeeId;
            i.name = foundEmployee?.fullName ?? "No Manager";
            i.displayValue = `Region ${v.zone}${v.region} - ${i.name}`;
            i.children = level3Nodes.filter((lv3) => lv3.zrt && lv3.zrt.startsWith(v.zrt.slice(0,3)))

            return i;
        });

        const level1Nodes = level1s.map(v => {
            const i = new SearchZrtDropDown();
            const foundEmployee = employeeMap.get(v.employeeId);

            i.zrt = v.zrt;
            i.id = v.employeeId;
            i.name = foundEmployee?.fullName ?? "No Manager";
            i.displayValue = `Zone ${v.zone} - ${i.name}`;
            i.children = level2Nodes.filter((lv2) => lv2.zrt && lv2.zrt.startsWith((v.zrt.slice(0,2))));

            return i;
        });

        return level1Nodes.sort((a, b) => a.zrt.localeCompare(b.zrt));
    }

    async getEmployeeZrtsByIds(key: string[]): Promise<SearchZrtDropDown[]> {
        if (!key || !key.length) return;
        let employees = await this.dbService.employees.where("id").anyOf(key).and((e) => !!e.zrt).sortBy("zrt");

        return employees.map(v => {
            const i = new SearchZrtDropDown();

            i.zrt = v.zrt;
            i.id = v.id;
            i.name = v.fullName;
            i.displayValue = `${v.zrt} - ${i.name}`;
            i.children = [];

            return i;
        });
    }
}
