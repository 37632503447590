<div class="row">
    <div class="col card-header banner-header">
        <div class="ml-2 s-white-fff-color">
            My Profile
        </div>
    </div>
</div>
<div *ngIf="employee?.user" class="row m-teal">
    <div class="col margin">
        <div class="row">
            <div class="col-12">Name:</div>
        </div>
        <div class="row">
            <div class="col-12">
                {{ employee.zrt ? employee.zrt + " - ": "" }}{{ employee.fullName }}
            </div>
        </div>
    </div>
    <div class="col margin">
        <div class="row">
            <div class="col-12">Storage Address:</div>
        </div>
        <div class="row">
            <div class="col-12">
                {{ getStorageAddressLineOne() }}
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                {{ getStorageAddressLineTwo() }}
            </div>
        </div>
    </div>
    <div class="col margin">
        <div class="row">
            <div class="col-12">Company Phone:</div>
        </div>
        <div class="row">
            <div class="col-12">
                {{ employee.cellPhone ? employee.cellPhone : "None found." }}
            </div>
        </div>
    </div>
    <div class="col margin">
        <div class="row">
            <div class="col-12">Company Email:</div>
        </div>
        <div class="row">
            <div class="col-12">
                {{ employee.user.username }}
            </div>
        </div>
    </div>
</div>
<div *ngIf="registeredUser" class="row m-teal">
    <div class="col margin">
        <div class="row">
            <div class="col">
                Other Phone
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline" color="accent">
                    <mat-label id="phone-number">Other Phone</mat-label>
                    <input type="text" [formControl]="phoneFormControl" [(ngModel)]="registeredUser.otherPhone"
                    placeholder="Ex. (555) 555-5555" [mask]="'(000) 000-0000'"
                        (blur)="saveRegisteredUser()" matInput />
                    <mat-error *ngIf="phoneFormControl.hasError('pattern')">
                        Please enter a valid 10 digit phone number!
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="col margin">
        <div *ngIf="canSeeZrtOverride">
            <div class="row">
                <div class="col">
                    Dev Only Override Zrt
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" color="accent">
                        <mat-label id="override-zrt">Override Zrt</mat-label>
                        <input type="text" [(ngModel)]="
                                    registeredUser.zrtOverride
                                " placeholder="Ex. 5555" maxlength="4" (blur)="saveRegisteredUser()" matInput />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="registeredUser && showAdminPanel" class="row m-teal">
    <div class="col margin">
        <div class="row">
            <div class="col">
                <h1>Shield Admin</h1> <span (click)="removeLocalStorageKey('ap'); showAdminPanel = false;">(close)</span>
            </div>
        </div>
    </div>
</div>

<div *ngIf="registeredUser && showAdminPanel" class="row m-teal">
    <div class="col margin">
        <div class="row">
            <div class="col">
                System Information
            </div>
        </div>
        <div class="row">
            <div class="col">
                    <mat-form-field *ngFor="let sys of sysInfo; index as idx" appearance="outline" color="accent">
                        <mat-label>{{ sys.key }}</mat-label>
                        <input type="text" [(ngModel)]="
                                    sysInfo[idx].value
                                " (blur)="saveSysInfo(idx)" matInput />
                    </mat-form-field>
            </div>
        </div>

    </div>

</div>