export class AvailableGratisLineItemViewmodel {
    uin: string | null = null;
    upc!: string;
    productDescription!: string;
    quantity!: number;
    eaches!: number;
    price: number | null = null;
    salesTotal: number | null = null;
    discount: number | null = null;
    netTotal: number | null = null;
    gratisTotal: number | null = null;
    type!: number;
    typeDescription!: string;
    wholesalerName: string | null = null;
    wholesalePrice: number | null = null;
    wholesaleTotal: number | null = null;
}
