import { Injectable } from "@angular/core";
import { CustomerContractSyncCommand, EmailAttachment, EmailContractParamsDto, newSequentialId, NotificationRequestDto, NotificationSyncCommand, RetailContractBatchParamsDto } from "shield.shared";
import { Signature } from "src/app/entity-models/signature.entity";
import { DataSyncQueueService } from "src/app/sync/data-sync-queue.service";
import { CustomerContract } from "../../entity-models/customer-contract.entity";
import { DatabaseService } from "../database.service";

@Injectable()
export class CustomerContractOfflineService {

    constructor(private dbService: DatabaseService,
        private dataSyncQueueService: DataSyncQueueService) { }

    async getCustomerContracts(key: RetailContractBatchParamsDto): Promise<CustomerContract[]> {
        throw Error("GetCustomerContracts method not implemented for offline mode yet.");
    }

    async getByCustomerId(id: string): Promise<CustomerContract[]> {
        return this.dbService.customerContracts
            .where("customerId").equals(id)
            .reverse()
            .sortBy("signatureDate");
    }

    async getUnprocessedByCustomerId(id: string): Promise<CustomerContract[]> {
        return this.dbService.customerContracts
            .where("hasServerProcessed")
            .equals(0)
            .filter((a) => a.customerId === id)
            .toArray();
    }

    async getContractSignature(key: string): Promise<Signature> {
        throw Error("GetContractSignature method not implemented for off line mode yet.");
    }

    async emailContract(key: EmailContractParamsDto): Promise<undefined> {
        if (key) {
            const dto = new NotificationRequestDto();
            dto.id = newSequentialId();
            dto.employeeId = key.employeeId;
            dto.recipientEmployeeId = key.employeeId;
            dto.sendEmail = true;
            dto.subject = "Swisher Shield Retail Contract"
            dto.message = key.comment ?? "";
            dto.attachment = new EmailAttachment();
            dto.attachment.type = "application/pdf"
            dto.attachment.name = "RetailContract.pdf"
            dto.attachment.content = key.image;

            await this.dbService.transaction(
                "rw",
                this.dbService.notificationRequests,
                this.dbService.syncQueue,
                async () => {
                    await this.dbService.notificationRequests.put(dto);
                    await this.dataSyncQueueService.enqueue(
                        new NotificationSyncCommand(dto.id)
                    );
                }
            );
        }
        return;
    }

    async upsertAgreement(newContract: CustomerContract): Promise<CustomerContract> {

        if (newContract) {
            newContract.hasServerProcessed = 0;
            await this.dbService.transaction(
                "rw",
                this.dbService.customerContracts,
                this.dbService.syncQueue,
                async () => {
                    await this.dbService.customerContracts.put(newContract);
                    await this.dataSyncQueueService.enqueue(
                        new CustomerContractSyncCommand(newContract.id)
                    );
                }
            );
        }
        return new CustomerContract();
    }

}
