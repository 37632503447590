<div #myFilter>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label>Wholesaler Group ID / Name
            </mat-label>
            <input type="text" (keyup)="onAddGroupRefiner($event)" (blur)="onAddGroupRefiner()"
                [(ngModel)]="viewmodel.groupInput" matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Order Method</mat-label>
            <mat-select #orderMethodSelect [(value)]="viewmodel.selectedOrderMethods" (openedChange)="onOpenedOrderMethodChange()"
                multiple>
                <mat-option *ngFor="let option of viewmodel.orderMethods" [value]="option">
                    {{ option }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label>Wholesaler ID / Name
            </mat-label>
            <input type="text" (keyup)="onAddCustomerRefiner($event)" (blur)="onAddCustomerRefiner()"
                [(ngModel)]="viewmodel.customerInput" matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>State</mat-label>
            <mat-select #stateSelect [(value)]="viewmodel.selectedStates" (openedChange)="onOpenedStateChange()"
                multiple>
                <mat-option *ngFor="let vm of viewmodel.states" [value]="vm">
                    {{ vm.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
