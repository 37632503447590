import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { WholesalerProductsDialogComponent } from './wholesaler-products-list/wholesaler-products-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SharedModule } from '../shared/shared.module';
import { AvailableToOrderDialogComponent } from './available-to-order-dialog/available-to-order-dialog.component';



@NgModule({
    declarations: [
        WholesalerProductsDialogComponent,
        AvailableToOrderDialogComponent,
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        SharedModule,
    ],
    exports: [
        WholesalerProductsDialogComponent,
        AvailableToOrderDialogComponent,
    ]
})
export class WholesaleProductsModule { }
