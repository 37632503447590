import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProjectStateService } from 'src/app/services/project-state-service';
import { ProjectPeopleViewmodel } from './project-people.viewmodel';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { PleaseWaitService } from 'src/app/services/please-wait.service';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { EmployeeDelineationService } from 'src/app/services/delineation-services/employee-delineation.service';
import { ProjectApplicationService } from '../../project-services/project-application.service';
import { ProjectStep } from 'src/app/enums/project-step';
import { CustomerDelineationService } from 'src/app/services/delineation-services/customer-delineation.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogViewmodel } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';
import { OverlayService } from 'src/app/services/overlay.service';

@Component({
    selector: 'app-project-people',
    templateUrl: './project-people.component.html',
    styleUrls: ['./project-people.component.scss']
})
export class ProjectPeopleComponent implements AfterViewChecked, OnDestroy {

    @ViewChild("assignedSearch") assignedSearch: ElementRef;
    @ViewChild("unAssignedSearch") unAssignedSearch: ElementRef;

    shouldWait$ = new BehaviorSubject<boolean>(true);
    viewmodel = new ProjectPeopleViewmodel(
        this.snackbarService,
        this.pleaseWaitService,
        this.projectStateService,
        this.employeeDelineationService,
        this.customerDelineationService);
    isViewmodelReady = false;
    assignedSearchValueSubscription: Subscription;
    unAssignedSearchValueSubscription: Subscription;

    faArrowUp: IconDefinition = faArrowUp;
    faArrowDown: IconDefinition = faArrowDown;

    projectSubscription: Subscription;
    currentStep = ProjectStep.people;

    private modalRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    constructor(private projectStateService: ProjectStateService
        , private snackbarService: SnackbarService
        , private pleaseWaitService: PleaseWaitService
        , private overlayService: OverlayService
        , public projectApplicationService: ProjectApplicationService
        , private employeeDelineationService: EmployeeDelineationService
        , private customerDelineationService: CustomerDelineationService) { }

    ngAfterViewChecked(): void {
        if (this.projectApplicationService.selectedIndex !== ProjectStep.people) return;

        if (!this.isViewmodelReady) {
            this.isViewmodelReady = true;

            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
            if(!this.projectSubscription || this.projectSubscription.closed) {
                this.projectSubscription = this.projectStateService.observableProject.subscribe((project) => {
                    if (project) {
                        this.viewmodel.initialize(project, this.shouldWait$);

                        setTimeout(() => {
                            if (this.unAssignedSearch) {
                                const searchValue = fromEvent(
                                    this.unAssignedSearch.nativeElement,
                                    "input"
                                ).pipe(
                                    map(
                                        (e: InputEvent) => (e.target as HTMLInputElement).value
                                    ),
                                    debounceTime(1000),
                                    distinctUntilChanged()
                                );

                                if (
                                    !this.unAssignedSearchValueSubscription ||
                                    this.unAssignedSearchValueSubscription.closed
                                ) {
                                    this.unAssignedSearchValueSubscription = searchValue.subscribe(() => {
                                        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
                                        this.viewmodel.selectedZone = "All"
                                        this.viewmodel.filter();
                                        this.viewmodel.setSelectAllUnAssigned();
                                        this.viewmodel.setSelectAllAssigned();
                                    });
                                }
                            }

                            if (this.assignedSearch) {
                                const searchValue = fromEvent(
                                    this.assignedSearch.nativeElement,
                                    "input"
                                ).pipe(
                                    map(
                                        (e: InputEvent) => (e.target as HTMLInputElement).value
                                    ),
                                    debounceTime(1000),
                                    distinctUntilChanged()
                                );

                                if (
                                    !this.assignedSearchValueSubscription ||
                                    this.assignedSearchValueSubscription.closed
                                ) {
                                    this.assignedSearchValueSubscription = searchValue.subscribe(() => {
                                        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
                                        this.viewmodel.filter();
                                    });
                                }
                            }
                            this.projectSubscription?.unsubscribe();
                        }, 0);
                    }
                });
            }
        }
    }

    ngOnDestroy(): void {
        if (this.projectSubscription && !this.projectSubscription.closed) {
            this.projectSubscription.unsubscribe();
        }
        if (this.unAssignedSearchValueSubscription && !this.unAssignedSearchValueSubscription.closed) {
            this.unAssignedSearchValueSubscription.unsubscribe();
        }
        if (this.assignedSearchValueSubscription && !this.assignedSearchValueSubscription.closed) {
            this.assignedSearchValueSubscription.unsubscribe();
        }
    }

    handleAssignmentBasedOnCustomers(ev: MatCheckboxChange): void {
        if (ev.checked) {
            this.viewmodel.assignPeopleBasedOnCustomers();
        } else {
            if (this.modalRef) {
                this.modalRef.close();
            }
            const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
            data.header = "Confirmation";
            data.message = "Are you sure you want to remove the automatically assigned people?";
            data.buttonLeftText = "No";
            data.buttonLeftFunction = null;
            data.buttonRightText = "Yes";
            data.buttonRightFunction = () => {
                this.modalRef.close();
                this.viewmodel.undoAssignPeopleBasedOnCustomers();
            }

            this.modalRef = this.overlayService.open(
                ConfirmationDialogComponent,
                data
            );

            this.modalRef.afterClosed$.subscribe(() => {
                this.modalRef = undefined;
            });
        }
    }
}
