import { EntityObjectNames, EntityPropertyNames, RefinerDto, RefinerLocation } from "shield.shared";


export class Refiner {
    dataPropertyName: string;
    dataValue: string;
    dtoObjectName?: EntityObjectNames;
    dtoPropertyName?: EntityPropertyNames;
    location: RefinerLocation;
    value: string;
    shouldSearchWhenCleared = true;

    static refinerToRefinerDto(entity: Refiner): RefinerDto {

        const dto = new RefinerDto();
        dto.dataValue = entity.dataValue;
        dto.dtoObjectName = entity.dtoObjectName;
        dto.dtoPropertyName = entity.dtoPropertyName;
        dto.refinerLocation = entity.location;
        dto.value = entity.value;

        return dto;
    }
}
