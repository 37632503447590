import { Injectable } from "@angular/core";
import { RefinerLocation } from "shield.shared";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { RefinerServiceBase } from "src/app/shared/refiner-service-base";

Injectable()
export class RouteManagementRefinerService extends RefinerServiceBase {
    private readonly corporateRemoveableRefinerLocations = [RefinerLocation.zrt, RefinerLocation.zrtByArea, RefinerLocation.zrtByEmployee];
    isCorporateUser = false;

    shouldPreventRemoval(refiner: Refiner): boolean {
        return this.corporateRemoveableRefinerLocations.includes(refiner.location) && !this.isCorporateUser
    }
}
