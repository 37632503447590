import { CustomerTypeEnum } from "../lookup";
import { ZrtFilterDto } from "./zrt-filter.dto";

export class ProductDistributionFilterDto {

    /*
     * My Searches
     */
    // Usage: Get only accounts with given ids
    ids?: string[];

    /*
    * Date Information section
    */

    // Usage: Contains ZRT properties
    zrtFilterDto?: ZrtFilterDto;

    // Usage: Get data based on call/date
    callOnOrAfterDate?: string;
    callOnOrBeforeDate?: string;

    /*
     * Activities Secton
     */

    // Usage: Get based on associated project ids
    projectIds?: string[];

    // Usage: Get data based on wholesaler Ids
    wholesalerIds?: string[];

    // Usage: Get data based on wholesaler Ids for projects
    projectWholesalers?: string[];

    // Usage: Get data based on account ownership
    // Possible data: Account ownership GUIDs
    accountOwnershipIds?: string[];

    // Usage: Get data based on associated products ids
    productIds?: string[];

    // Usage: Get data based on product groups (currently, division)
    productGroups?: string[];


    /*
     * Attributes Secton
     */

    // Usage: Get products carried by customers that are of the given store types
    customerTypeIds?: CustomerTypeEnum[];

    // Usage: Get products carried by customers that are Msa
    isMsa?: boolean;

    //Usage: Get based on the selected px3 rank
    px3Rank?: string[];
}
