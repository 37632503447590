import { Injectable } from "@angular/core";
import { GenericResponseDto } from "shield.shared";
import { RegisteredUser } from "src/app/entity-models/registered-user-entity";
import { DatabaseService } from "../database.service";
import { RegisteredUserOfflineService } from "../offline-services/registered-user-offline.service";
import { RegisteredUserOnlineService } from "../online-services/registered-user-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class RegisteredUserDelineationService extends DelineationContext<RegisteredUser, string> {

    constructor(private registeredUserOfflineService: RegisteredUserOfflineService
        , private registeredUserOnlineService: RegisteredUserOnlineService
        , snackbarService: SnackbarService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService
    ){
        super(dbService, datasourceDelineationService, snackbarService);
    }

    async getByEmployeeId(id: string): Promise<GenericResponseDto<RegisteredUser>> {

        const compareDelegate = this.compareRegisteredUser;
        const unProcessedDelegate = async (key: string) => {
            const result = await this.registeredUserOfflineService.getUnprocessedByEmployeeId(key);
            return result;
        }
        const offline = (key: string) => {
            return this.registeredUserOfflineService.getByEmployeeId(key);
        }
        const online = (key: string) => {
            return this.registeredUserOnlineService.getByEmployeeId(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, RegisteredUser>(id, offline, online, compareDelegate, unProcessedDelegate);

        if (response.isError) {
            //this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async saveRegisteredUser(user: RegisteredUser): Promise<GenericResponseDto<RegisteredUser> | undefined> {
        const offline = (key: RegisteredUser) => {
            return this.registeredUserOfflineService.saveRegisteredUser(key);
        }
        const online = (key: RegisteredUser) => {
            return this.registeredUserOfflineService.saveRegisteredUser(key);
        }
        const response = await this.datasourceDelineationService.makeCall<RegisteredUser, RegisteredUser>(user, offline, online);


        if (response?.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async autosaveRegisteredUser(user: RegisteredUser): Promise<GenericResponseDto<RegisteredUser> | undefined> {
        const offline = (key: RegisteredUser) => {
            return this.registeredUserOfflineService.autosaveRegisteredUser();
        }
        const online = (key: RegisteredUser) => {
            return this.registeredUserOnlineService.autosaveRegisteredUser(key);
        }
        const response = await this.datasourceDelineationService.makeCall<RegisteredUser, RegisteredUser>(user, offline, online);


        if (response?.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    private async compareRegisteredUser(
        onlineRegisteredUser: RegisteredUser,
        notSyncedOfflineRegisteredUser: RegisteredUser
    ) : Promise<RegisteredUser> {
        let rtn: RegisteredUser

        if (notSyncedOfflineRegisteredUser) {
            return notSyncedOfflineRegisteredUser;
        } else {
            return onlineRegisteredUser;
        }
    }
}
