<div
    class="basic-modal"
    cdkDrag
    cdkDragBoundary=".cdk-overlay-container"
>
    <div cdkDragHandle>
        <div class="modal-header">
            {{ headerLeftText }}
            <div class="col text-right">
                <span *ngIf="headerRightText">
                    {{ headerRightText }}
                </span>
                <div *ngIf="headerRightButtonText">
                    <button class="btn btn-secondary" (click)="headerRightButtonClick$?.emit()">
                        {{ headerRightButtonText }}
                    </button>
                </div>
            </div>
            <mat-icon *ngIf="!this.blocking" class="close-icon" appThrottleClick (click)="close()">close</mat-icon>
        </div>
    </div>
    <div class="modal-body"
        [style]="{
            height: height?.length > 0 ? height : 'auto',
            width: width?.length > 0 ? width : 'auto'
        }">
        <div>
            <ng-container [ngSwitch]="contentType">
                <ng-container *ngSwitchCase="'string'">
                    <div class="box">
                        <div [innerHTML]="contentString"></div>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'template'">
                    <ng-container
                        *ngTemplateOutlet="contentTemplateRef; context: context"
                    ></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'component'">
                    <ng-container
                        #component
                        *ngComponentOutlet="contentComponent"
                    ></ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div *ngIf="showFooter" class="modal-footer">
        <button-list [buttons]="iconButtons" (onButtonClick)="iconButtonClick$.next($event)">
        </button-list>
        <div class="w-100"></div>
        <button *ngIf="buttonLeftText"
            mat-raised-button
            class="btn-white actionBtn"
            [disabled]="buttonLeftDisabledFunction()"
            (click)="buttonLeftFunction()"
        >
            {{ buttonLeftText }}
        </button>
        <button *ngIf="buttonMiddleText"
            mat-raised-button
            class="btn-white actionBtn"
            [disabled]="buttonMiddleDisabledFunction()"
            (click)="buttonMiddleFunction()"
        >
            {{ buttonMiddleText }}
        </button>
        <button *ngIf="buttonRightText"
            mat-raised-button
            class="sw-red-btn actionBtn"
            [disabled]="buttonRightDisabledFunction()"
            (click)="buttonRightFunction()"
        >
            {{ buttonRightText }}
        </button>
    </div>
</div>
