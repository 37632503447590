<header class="global-header">
    <nav class="navbar navbar-expand-lg bg-white">
        <a class="navbar-brand mr-lg-5" (click)="reload()">
            <img src="assets/img/swisher-vert-logo.png" alt="Swisher Shield Vertical Logo" [title]="version" />
            <span class="sr-only">Swisher Shield</span>
        </a>
        <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
            <span class="material-icons">menu</span>
        </button>

        <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
            <ul class="navbar-nav">
                <li class="nav-item" routerLinkActive="active">
                    <a mat-button class="nav-link-mat" (click)="isMenuCollapsed = true"
                        [routerLink]="['accounts/customers']">
                        Accounts
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a mat-button [matMenuTriggerFor]="myDayMenu" class="nav-link-mat" (click)="isMenuCollapsed = true">
                        My Day
                    </a>
                    <mat-menu #myDayMenu="matMenu">
                        <button mat-menu-item [routerLink]="['my-day/gratis']">
                            Gratis Requests
                        </button>
                        <button mat-menu-item [routerLink]="['my-day/route-management']">
                            Routes
                        </button>
                        <button mat-menu-item [routerLink]="['my-day/time-log']">
                            Time Log
                        </button>
                        <button mat-menu-item (click)="getLastCall()">
                            Go To Last Call
                        </button>
                    </mat-menu>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a mat-button [matMenuTriggerFor]="detailsMenu" class="nav-link-mat"
                        (click)="isMenuCollapsed = true">
                        Details
                    </a>
                    <mat-menu #detailsMenu="matMenu">
                        <button mat-menu-item [routerLink]="['details/call-history']">
                            Call & Time History
                        </button>
                        <button mat-menu-item [routerLink]="['details/call-pictures']">
                            Call Pictures
                        </button>
                        <button mat-menu-item [routerLink]="['details/daily-summary']">
                            Daily Summary
                        </button>
                        <button mat-menu-item [routerLink]="['details/product-distribution']">
                            Product Distribution
                        </button>
                        <button mat-menu-item [routerLink]="['details/project-list']">
                            Projects
                        </button>
                        <button mat-menu-item [routerLink]="['details/retail-contracts']">
                            Retail Contracts
                        </button>
                    </mat-menu>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a mat-button [matMenuTriggerFor]="salesMenu" class="nav-link-mat" (click)="isMenuCollapsed = true">
                        Sales
                    </a>
                    <mat-menu #salesMenu="matMenu">
                        <button mat-menu-item [routerLink]="['sales/transactions']">
                            Invoices
                        </button>
                        <button mat-menu-item [routerLink]="['sales/orders/extraction']">
                            Order Extraction
                        </button>
                        <button mat-menu-item [routerLink]="['sales/orders']">
                            Orders
                        </button>
                    </mat-menu>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a mat-button [matMenuTriggerFor]="resourcesMenu" class="nav-link-mat"
                        (click)="isMenuCollapsed = true">
                        Resources
                    </a>
                    <mat-menu #resourcesMenu="matMenu">
                        <button mat-menu-item (click)="getReportingUrl()">
                            Reporting
                        </button>
                        <button mat-menu-item [routerLink]="['resources/wholesaler-groups']">
                            Wholesaler Groups
                        </button>
                        <button *ngIf="isZone19" mat-menu-item [routerLink]="['resources/zone-recap-report']">
                            Zone Recap
                        </button>
                        <button *ngIf="isAdmin" mat-menu-item [routerLink]="['resources/px3-import']">
                            Px3 Import
                        </button>
                    </mat-menu>
                </li>
            </ul>
            <ul class="navbar-nav">
                <li *ngIf="loggedIn" class="msa-sync d-flex align-items-center justify-content-left">
                    <em>MSA Refresh Last Week Ending: {{ lastMsaSync }}</em>
                </li>
            </ul>
            <ul class="navbar-nav d-flex justify-content-between align-items-center">
                <li class="nav-item">
                    <button class="btn btn-outline-primary" *ngIf="!loggedIn" (click)="login()">
                        Login
                    </button>
                </li>
                <li class="nav-item nav-icon user-account-profile">
                    <button (click)="triggerSync()" mat-raised-button color="accent"
                        [disabled]="offlineAlert || (inboundSyncing || outboundSyncing) || isChecked"
                        data-toggle="tooltip" data-placement="bottom" [title]="syncMessage">
                        Sync Now
                    </button>
                    <div class="bubble" *ngIf="callTotal > 0">{{callTotal}}</div>
                    <div class="nav-link-mat">
                        Last Sync: {{ lastSync }}
                    </div>

                </li>
                <li class="nav-item nav-icon user-account-profile"
                    *ngIf="deadLetterCount === 0 && !inboundSyncing && !outboundSyncing">
                    <mat-icon class="icon-padding text-teal">file_download_done</mat-icon>
                </li>
                <li class="nav-item account user-account-profile">
                    <div *ngIf="inboundSyncing || outboundSyncing" class="rotate-wrapper">
                        <mat-icon class="rotate">sync</mat-icon>
                    </div>
                </li>
                <li class="nav-item nav-icon text-teal user-account-profile">
                    <div [matMenuTriggerFor]="connectionMenu">
                        <mat-icon class="icon-padding nav-font-size"
                            *ngIf="offlineAlert || isChecked">wifi_off</mat-icon>
                        <mat-icon class="icon-padding nav-font-size" *ngIf="!offlineAlert && !isChecked">wifi</mat-icon>
                    </div>
                    <mat-menu #connectionMenu="matMenu">
                        <div class="nav-link-mat">Shield is currently in {{ offlineAlert || isChecked ? "offline" :
                            "online" }} mode.</div>
                        <!-- <button *ngIf="isChecked" (click)="setForceOffline()" mat-menu-item [disabled]="offlineAlert"
                            data-toggle="tooltip" data-placement="bottom"
                            title="Pulling data locally. Change this if connection improves.">
                            <mat-icon>wifi</mat-icon> Go Online
                        </button>
                        <button *ngIf="!isChecked" (click)="setForceOffline()" mat-menu-item [disabled]="offlineAlert"
                            data-toggle="tooltip" data-placement="bottom"
                            title="Pulling data from the server. Change this to pull data locally if connection is poor.">
                            <mat-icon>wifi_off</mat-icon> Go Offline
                        </button> -->
                    </mat-menu>
                </li>
                <li class="nav-item account user-account-profile ml-1">
                    <mat-icon class="nav-font-size profile-icon-padding text-teal"
                        [matMenuTriggerFor]="userMenu">account_circle</mat-icon>
                    <div class="nav-link-mat" [matMenuTriggerFor]="userMenu">{{ getDisplayName() }}</div>
                    <mat-menu #userMenu="matMenu">
                        <button mat-menu-item [routerLink]="['user/user-profile']">
                            View Profile
                        </button>
                        <button mat-menu-item (click)="logout()">
                            Logout
                        </button>
                    </mat-menu>
                </li>
            </ul>
        </div>
    </nav>
</header>

<ngb-alert *ngIf="syncService.firstSync" type="info" [dismissible]="false">
    Shield is still syncing your data. Some parts of the application will not function correctly until the sync
    completes.
</ngb-alert>
