<div #myFilter>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Wholesaler</mat-label>
            <mat-select #wholesalerSelect [(value)]="viewmodel.selectedWholesaler" (openedChange)="onOpenedWholesalerChange()">
                <mat-option *ngFor="let wholesaler of viewmodel.wholesalers" [value]="wholesaler">
                    {{ wholesaler.name }}
                    <span *ngIf="wholesaler.hasPendingOrders">&#42;</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" [formGroup]="viewmodel.dateForm">
            <mat-label>Order on or Before</mat-label>
            <input #endDateInput matInput
                formControlName="endDate"
                [min]="viewmodel.endMinDate"
                [matDatepicker]="beforePicker"
                (keyup)="onAddEndDateRefiner($event)"
                (blur)="onAddEndDateRefiner($event)"
                (dateChange)="onAddEndDateRefiner()" />
            <mat-datepicker touchUi #beforePicker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="beforePicker"></mat-datepicker-toggle>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-account-input">Store Name / Customer ID
            </mat-label>
            <input #accountInput id="customer-account-input" type="text" (keyup)="onAddAccountRefiner($event)"
                [(ngModel)]="viewmodel.accountInput" matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Chain</mat-label>
            <mat-select #chainSelect [(value)]="viewmodel.selectedChains" (openedChange)="onOpenedChainChange()"
                multiple>
                <mat-option *ngFor="let chain of viewmodel.chains" [value]="chain">
                    {{ chain }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
