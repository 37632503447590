import { AfterContentChecked, Component, OnInit, Type, ViewChild } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterService } from "src/app/services/filter.service";
import { CountyViewmodel } from "../../viewmodels/county.viewmodel";
import { StateViewmodel } from "../../viewmodels/state.viewmodel";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { ProjectLocationViewModel } from "./project-location.viewmodel";
import { RefinerLocation, valueSeparator } from "shield.shared";
import { EmployeeDropdownComponent } from "../../employee-dropdown/employee-dropdown.component";
import { ZrtTreeViewComponent } from "../../zrt-tree-view/zrt-tree-view.component";

@Component({
    selector: "app-project-location-filter",
    templateUrl: "./project-location-filter.component.html",
    styleUrls: ["./project-location-filter.component.scss"]
})
export class ProjectLocationFilterComponent extends FilterBaseComponent implements AfterContentChecked {
    //view childs
    @ViewChild("employeeDropdown") employeeDropdown: EmployeeDropdownComponent;
    @ViewChild("stateSelect") stateSelect: MatSelect;
    @ViewChild("countySelect") countySelect: MatSelect;
    @ViewChild("zrtTree") zrtTree: ZrtTreeViewComponent;

    //public vars
    contentComponent: Type<ProjectLocationFilterComponent>;
    icon = "location_on";
    name = "Location";
    viewmodel = new ProjectLocationViewModel(this.filterService);
    firstRun = true;

    constructor(private filterService: FilterService) {
        super();
    }

    async ngAfterContentChecked(): Promise<void> {
        if (this.firstRun && this.zrtTree && this.zrtFilterService) {
            this.firstRun = false;
            this.zrtTree.zrtFilterService = this.zrtFilterService;
            await this.viewmodel.initializeLocationDropDowns();
            this.setInitialized();
            this.refinerService.refiners.forEach((refiner) => this.onInputChange(refiner));
        }
    }

    //events
    onAddManagerRefiner(event?: KeyboardEvent) {
        this._refinerService.onInputChange(
            RefinerLocation.manager,
            this.viewmodel.managerInput,
            this.viewmodel.managerInput,
            false
        );
        if (!event || this.inputSubmitKeys.includes(event.key)) {
            if (this.viewmodel.managerInput) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.manager;
                refiner.value = this.viewmodel.managerInput;
                refiner.dataPropertyName = "createdBy";
                this._refinerService.addRefiners(refiner);
            } else {
                this.refinerService.removeRefinerByLocation(RefinerLocation.manager);
            }
        }
    }

    onAddCityRefiner(event?: KeyboardEvent): void {
        this._refinerService.onInputChange(
            RefinerLocation.city,
            this.viewmodel.cityInput,
            this.viewmodel.cityInput,
            false
        );
        if (
            this.viewmodel.cityInput &&
            (!event || this.inputSubmitKeys.includes(event.key))
        ) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.city;
            refiner.value = this.viewmodel.cityInput;
            refiner.dataPropertyName = "businessAddress.city";
            this._refinerService.addRefiners(refiner);
        }
    }

    onAddStreetRefiner(event?: KeyboardEvent) {
        this._refinerService.onInputChange(
            RefinerLocation.street,
            this.viewmodel.streetInput,
            this.viewmodel.streetInput,
            false
        );
        if (
            this.viewmodel.streetInput &&
            (!event || this.inputSubmitKeys.includes(event.key))
        ) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.street;
            refiner.value = this.viewmodel.streetInput;
            refiner.dataPropertyName = "businessAddress.address1";
            this._refinerService.addRefiners(refiner);
        }
    }

    onAddZipRefiner(event?: KeyboardEvent): void {
        this._refinerService.onInputChange(
            RefinerLocation.zipCodes,
            this.viewmodel.zipInput,
            this.viewmodel.zipInput,
            false
        );
        if (
            this.viewmodel.zipInput &&
            (!event || this.inputSubmitKeys.includes(event.key))
        ) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.zipCodes;
            refiner.value = this.viewmodel.zipInput;
            refiner.dataPropertyName = "businessAddress.zip";
            this._refinerService.addRefiners(refiner);
        }
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.manager:
                    if (refiner.value !== this.viewmodel.managerInput) {
                        this.viewmodel.managerInput = refiner.value;
                    }
                    break;
                case RefinerLocation.street:
                    if (refiner.value !== this.viewmodel.streetInput) {
                        this.viewmodel.streetInput = refiner.value;
                    }
                    break;
                case RefinerLocation.city:
                    if (refiner.value !== this.viewmodel.cityInput) {
                        this.viewmodel.cityInput = refiner.value;
                    }
                    break;
                case RefinerLocation.states: {
                    const selectedStates = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedStates = new Array<StateViewmodel>();

                    if (selectedStates) {
                        for (const state of selectedStates) {
                            const found = this.viewmodel.filteredStates.find(
                                (ss) => ss.state.id === state.trim()
                            );
                            if (found) {
                                rtnSelectedStates.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedStates = rtnSelectedStates;
                    if (this.viewmodel.selectedStates?.length) {
                        this.viewmodel.adjustCountySelectOptions();
                    }
                    break;
                }
                case RefinerLocation.zipCodes:
                    if (refiner.value !== this.viewmodel.zipInput) {
                        this.viewmodel.zipInput = refiner.value;
                    }
                    break;
                case RefinerLocation.counties: {
                    const selectedCounties = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedCounties = new Array<CountyViewmodel>();

                    if (selectedCounties
                        && !this.viewmodel.selectedCounties
                        && this.refinerService.doesRefinerLocationExist(RefinerLocation.states)) {
                        this.onInputChange(this.refinerService.getRefinerByLocation(RefinerLocation.states));
                    }

                    if (selectedCounties) {
                        for (const county of selectedCounties) {
                            const found = this.viewmodel.filteredCounties.find(
                                (sc) => sc.county.id === county.trim()
                            );
                            if (found) {
                                rtnSelectedCounties.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedCounties = rtnSelectedCounties;
                    break;
                }
                case RefinerLocation.assigned: {
                    if (!refiner.dataValue) {
                        this.zrtFilterService.selectedZrts = [];
                    }
                }
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();

        tempRefiner.location = RefinerLocation.manager;
        const managerRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.manager
        );
        if (managerRefiner) {
            this.onInputChange(managerRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.street;
        const streetRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.street
        );
        if (streetRefiner) {
            this.onInputChange(streetRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.assigned;
        const assignedRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.assigned
        );
        if (assignedRefiner) {
            this.onInputChange(assignedRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.states;
        const stateRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.states
        );
        if (stateRefiner) {
            this.onInputChange(stateRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.counties;
        const countyRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.counties
        );
        if (countyRefiner) {
            this.onInputChange(countyRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.city;
        const cityRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.city
        );
        if (cityRefiner) {
            this.onInputChange(cityRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.zipCodes;
        const zipRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.zipCodes
        );
        if (zipRefiner) {
            this.onInputChange(zipRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }

    //public methods
    compareCountyOptions(a: CountyViewmodel, b: CountyViewmodel): boolean {
        return (
            a?.county?.name === b?.county?.name
            && a?.county?.stateId === b?.county.stateId
        )
    }

    compareRefinerOptions(a: Refiner, b: Refiner): boolean {
        return (
            a?.dataPropertyName === b?.dataPropertyName &&
            a?.location === b?.location &&
            a?.value === b?.value &&
            a?.dataValue === b?.dataValue
        );
    }

    openedCountyChange(): void {
        if (this.countySelect && !this.countySelect.panelOpen) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.counties;
            refiner.value = this.viewmodel.selectedCounties
                .map((vm) => vm.county.name)
                .join(", ");
            refiner.dataPropertyName = "businessAddress.county";
            refiner.dataValue = this.viewmodel.selectedCounties
                .map((vm) => vm.county.id)
                .join(valueSeparator);

            this._refinerService.checkAndUpdateRefiner(refiner, true);
        }
    }

    openedStateChange(): void {
        if (this.stateSelect && !this.stateSelect.panelOpen) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.states;
            refiner.value = this.viewmodel.selectedStates
                .map((vm) => vm.state.shortCode)
                .join(", ");
            refiner.dataPropertyName = "businessAddress.state";
            refiner.dataValue = this.viewmodel.selectedStates
                .map((vm) => vm.state.id)
                .join(valueSeparator);

            this._refinerService.checkAndUpdateRefiner(refiner, true);

            const remainingSelectedCounties = this.viewmodel.adjustCountySelectOptions();

            if (remainingSelectedCounties?.length > 0 ) {
                const refinerUpdate = new Refiner();
                refinerUpdate.location = RefinerLocation.counties;
                refinerUpdate.value = remainingSelectedCounties.map((rsc) => rsc.county.name).join(", ");
                refinerUpdate.dataValue = remainingSelectedCounties.map((rsc) => rsc.county.id).join(valueSeparator);
                this._refinerService.updateRefinerValue(refinerUpdate);

            } else {
                this._refinerService.removeRefinerByLocation(RefinerLocation.counties);
            }
        }
    }
}
