<div>
    <div class="card border shadow m-3">
        <div class="card-header d-flex justify-content-between align-items-center">
            <div class="flex">
                Information
                <div class="actionHover" [routerLink]="['../edit']">
                    <fa-icon [icon]="faPen" class="ml-2 text-white"></fa-icon>
                </div>
            </div>
            <div *ngIf="wholesalerViewmodel?.customer?.lastEdited && wholesalerViewmodel?.customer?.lastEditedBy"
                class="text-white">
                Last Edited by: {{wholesalerViewmodel?.customer?.lastEditedBy}} on
                {{wholesalerViewmodel?.customer?.lastEdited | date: dateFormat}}
            </div>
        </div>
        <div class="card-body">
            <div class="row row-cols-3 pb-2 ml-5 mr-5">
                <div class="col-4">
                    <div *ngIf="wholesalerViewmodel?.customer" class="mb-4 pb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="text-teal m-0">WHOLESALE INFO</h2>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Customer ID</p>
                            <b>{{wholesalerViewmodel?.customer?.customerNumber}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">MSA Store</p>
                            <b>{{wholesalerViewmodel.customer.isMsa ? "Yes" : "No"}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Store Status</p>
                            <b>{{wholesalerViewmodel.customer.isActive ? 'Active' : 'Inactive'}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Geo Area</p>
                            <b>{{wholesalerViewmodel.displayZrt}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Manager</p>
                            <b>{{wholesalerViewmodel.manager}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Created</p>
                            <b>{{wholesalerViewmodel.customer.dateCreated | date: dateTimeFormat}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Store Type</p>
                            <b>{{wholesalerViewmodel.customerType?.name}}</b>
                        </div>
                        <div *ngIf="wholesalerViewmodel.isCashAndCarry"
                            class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Cash And Carry</p>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Program</p>
                            <b>{{wholesalerViewmodel.customer.program}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Group</p>
                            <b>{{wholesalerViewmodel.groupName}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Group Rep</p>
                            <b></b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Order Type</p>
                            <b>{{wholesalerViewmodel.orderType}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Store Phone</p>
                            <b>{{formattedPhone}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Cash & Carry</p>
                            <b>{{wholesalerViewmodel.isCashAndCarry ? "Yes" : "No"}}</b>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="mb-4 pb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="text-teal m-0 pb-2">LICENSES</h2>
                        </div>
                        <div class="license-info d-flex justify-content-between align-items-center py-2">
                            <div class="d-flex align-items-center">
                                <fa-icon [icon]="faReceipt" class="license-icon"></fa-icon>
                                <div>
                                    <p class="license-type">TOBACCO LICENSE NUMBER</p>
                                    <p *ngIf="wholesalerViewmodel?.retailStateOTPLicenseLastUpdated" class="mb-0">Updated On
                                        {{wholesalerViewmodel?.retailStateOTPLicenseLastUpdated | date: dateFormat}}
                                    </p>
                                </div>
                            </div>
                            <strong>{{wholesalerViewmodel?.retailStateOTPLicense}}</strong>
                        </div>
                        <div class="license-info d-flex justify-content-between align-items-center py-2">
                            <div class="d-flex align-items-center">
                                <fa-icon [icon]="faReceipt" class="license-icon"></fa-icon>
                                <div>
                                    <p class="license-type">VAPOR LICENSE NUMBER</p>
                                    <p *ngIf="wholesalerViewmodel?.retailStateVaporLicenseLastUpdated" class="mb-0">Updated On
                                        {{wholesalerViewmodel?.retailStateVaporLicenseLastUpdated | date: dateFormat}}
                                    </p>
                                </div>
                            </div>
                            <strong>{{wholesalerViewmodel?.retailStateVaporLicense}}</strong>
                        </div>
                        <div class="license-info d-flex justify-content-between align-items-center py-2">
                            <div class="d-flex align-items-center">
                                <fa-icon [icon]="faReceipt" class="license-icon"></fa-icon>
                                <div>
                                    <p class="license-type">STATE TAX ID NUMBER</p>
                                    <p *ngIf="wholesalerViewmodel?.stateTaxIdLastUpdated" class="mb-0">Updated
                                        on {{wholesalerViewmodel?.stateTaxIdLastUpdated | date: dateFormat}}
                                    </p>
                                </div>
                            </div>
                            <strong>{{wholesalerViewmodel?.stateTaxId}}</strong>
                        </div>
                        <div class="license-info d-flex justify-content-between align-items-center py-2">
                            <div class="d-flex align-items-center">
                                <fa-icon [icon]="faReceipt" class="license-icon"></fa-icon>
                                <div>
                                    <p class="license-type">FEDERAL TAX ID NUMBER</p>
                                    <p *ngIf="wholesalerViewmodel?.federalTaxIdLastUpdated" class="mb-0">
                                        Updated On {{wholesalerViewmodel?.federalTaxIdLastUpdated | date:
                                        dateFormat}}
                                    </p>
                                </div>
                            </div>
                            <strong>{{wholesalerViewmodel?.federalTaxId}}</strong>
                        </div>
                    </div>
                    <div>
                        <h2 class="text-teal">WHOLESALE COMMENTS</h2>
                        <p class="info-text">{{wholesalerViewmodel?.customer?.comments
                            ? wholesalerViewmodel.customer.comments
                            : 'There are currently no comments.'}}</p>
                    </div>
                </div>
                <div class="col-4">
                    <div class="mb-4 pb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="text-teal m-0 pb-2">BUSINESS ADDRESS</h2>
                        </div>
                        <div>
                            <b>{{wholesalerViewmodel?.customer?.businessAddress?.name}}</b>
                            <p class="mb-0">{{wholesalerViewmodel?.customer?.businessAddress?.address1}}</p>
                            <p class="mb-0">{{wholesalerViewmodel?.customer?.businessAddress?.address2}}</p>
                            <p class="mb-0">{{wholesalerViewmodel?.customer?.businessAddress?.city}}</p>
                            <p class="mb-0">{{wholesalerViewmodel?.customer?.businessAddress?.state}},
                                {{wholesalerViewmodel?.customer?.businessAddress?.zip}}</p>
                        </div>
                    </div>
                    <div class="mb-4 pb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="text-teal m-0 pb-2">DRIVING/DBA ADDRESS</h2>
                        </div>
                        <div>
                            <ng-container
                                *ngIf="!wholesalerViewmodel?.customer?.dbaAddress?.name &&
                                    !wholesalerViewmodel?.customer?.dbaAddress?.address1 &&
                                    !wholesalerViewmodel?.customer?.dbaAddress?.city &&
                                    !wholesalerViewmodel?.customer?.dbaAddress?.state &&
                                    !wholesalerViewmodel?.customer?.dbaAddress?.zip">
                                N/A
                            </ng-container>
                            <b>{{wholesalerViewmodel?.customer?.dbaAddress?.name}}</b>
                            <p class="mb-0">{{wholesalerViewmodel?.customer?.dbaAddress?.address1}}</p>
                            <p class="mb-0">{{wholesalerViewmodel?.customer?.dbaAddress?.address2}}</p>
                            <p class="mb-0">{{wholesalerViewmodel?.customer?.dbaAddress?.city}}</p>
                            <p class="mb-0" *ngIf="wholesalerViewmodel?.customer?.dbaAddress?.state
                                                && wholesalerViewmodel?.customer?.dbaAddress?.zip">
                                {{wholesalerViewmodel.customer.dbaAddress.state}},
                                {{wholesalerViewmodel.customer.dbaAddress.zip}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer bg-m-gray">
            <div class="d-flex justify-content-end align-items-center">
                <button class="btn btn-lg btn-secondary mr-2 pl-5 pr-5" routerLink="../profile">
                    Cancel
                </button>
                <button class="btn btn-lg btn-primary ml-2 pl-5 pr-5" [routerLink]="['../edit']">
                    <fa-icon [icon]="faPen" class="mr-2 text-white"></fa-icon>
                    Edit
                </button>
            </div>
        </div>
    </div>
</div>
