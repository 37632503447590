export class FilterSaveViewmodel {
    name: string;
    existingFilterNames: string[] = [];
    isConfirmed = false;
    showFooter = true;
    headerLeftText = "New Filter";
    buttonLeftText = "Cancel";
    buttonLeftDisabledFunction: () => false;
    buttonRightText = "Save";
    buttonRightFunction: () => void;
    buttonRightDisabledFunction: () => boolean;
    needsMappingsRefreshed = true;
    width: string;
}
