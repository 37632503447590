import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRequestDto, GenericResponseDto, newSequentialId, WholesalerGroupIdsAndProductIdParamsDto, WholesalerGroupProductCatalogItemDto } from "shield.shared";
import { Customer } from "src/app/entity-models/customer.entity";
import { WholesalerGroupProductCatalogItem } from "src/app/entity-models/wholesaler-group-product-catalog-item.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { WholesalerGroupProductCatalogItemConverterService } from "../converter-services/wholesaler-group-product-catalog-item-converter.service";
import { first } from "rxjs/operators";

@Injectable()
export class WholesalerGroupProductCatalogItemOnlineService {

    constructor(private http: HttpClient) { }

    async saveAll(items: WholesalerGroupProductCatalogItem[]): Promise<void> {
        const itemDtos = items.map(i =>
            WholesalerGroupProductCatalogItemConverterService.entityToDto(i)
        );

        await this.http
            .post(`/api/wholesaler-groups/product-catalogs/`, itemDtos)
            .toPromise();
    }

    async getProductCatalogExport(
        wholesalerGroupId?: string,
    ): Promise<Blob> {
        return this.http.get(
            `/api/wholesaler-groups/product-catalogs/export-products?wholesaler-group-id=${wholesalerGroupId}`,
            { responseType: 'blob' },
        ).pipe(first()).toPromise();
    }

    async getDivisionProductCatalogExport(
        wholesalerId?: string,
    ): Promise<Blob> {
        return this.http.get(
            `/api/wholesalers/product-catalogs/export-products?wholesaler-id=${wholesalerId}`,
            { responseType: 'blob' },
        ).pipe(first()).toPromise();
    }

    async getByWholesalerId(
        id: string
    ): Promise<GenericResponseDto<WholesalerGroupProductCatalogItem[]>> {

        const result = await this.http
            .get<GenericResponseDto<WholesalerGroupProductCatalogItemDto[]>>
            (`/api/wholesaler-groups/product-catalogs/get-products?wholesaler-id=${id}`)
            .toPromise();

        return ConverterHelper.dtoToEntityArray(result,
            (r) => WholesalerGroupProductCatalogItemConverterService.dtoToEntity(r))
    }

    async getByWholesalerGroupId(id: string)
        : Promise<GenericResponseDto<WholesalerGroupProductCatalogItem[]>> {

        const result = await this.http
            .get<GenericResponseDto<WholesalerGroupProductCatalogItemDto[]>>
            (`/api/wholesaler-groups/product-catalogs/get-products?wholesaler-group-id=${id}`)
            .toPromise();

        return ConverterHelper.dtoToEntityArray(result,
            (r) => WholesalerGroupProductCatalogItemConverterService.dtoToEntity(r))
    }

    async getByWholesalerGroupMemberId(id: string)
        : Promise<GenericResponseDto<WholesalerGroupProductCatalogItem[]>> {

        const result = await this.http
            .get<GenericResponseDto<WholesalerGroupProductCatalogItemDto[]>>
            (`/api/wholesaler-groups/product-catalogs/get-products?wholesaler-group-member-id=${id}`)
            .toPromise();

        return ConverterHelper.dtoToEntityArray(result,
            (r) => WholesalerGroupProductCatalogItemConverterService.dtoToEntity(r))
    }

    async getByWholesalerGroupIdsAndProductId(params: WholesalerGroupIdsAndProductIdParamsDto):
        Promise<GenericResponseDto<WholesalerGroupProductCatalogItem[]>> {

        const request = new GenericRequestDto<WholesalerGroupIdsAndProductIdParamsDto>();
        request.id = newSequentialId();
        request.values = params;

        const result = await this.http
            .post<GenericResponseDto<WholesalerGroupProductCatalogItemDto[]>>
            (`/api/wholesaler-groups/product-catalogs/get-products-by-wholesaler-groups-and-products`, request)
            .toPromise();

        return ConverterHelper.dtoToEntityArray(result,
            (r) => WholesalerGroupProductCatalogItemConverterService.dtoToEntity(r))
    }
}
