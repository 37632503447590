import { AfterContentChecked, Component, OnDestroy, Type, ViewChild } from "@angular/core";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { RefinerLocation } from "shield.shared";
import { RouteInformationViewmodel } from "./route-information.viewmodel";
import { ZrtTreeViewComponent } from "../../zrt-tree-view/zrt-tree-view.component";

@Component({
    selector: "app-route-information-filter",
    templateUrl: "./route-information-filter.component.html",
    styleUrls: ["./route-information-filter.component.scss"]
})
export class RouteInformationFilterComponent extends FilterBaseComponent implements AfterContentChecked, OnDestroy {
    //public vars
    @ViewChild("zrtTree") zrtTree: ZrtTreeViewComponent;

    contentComponent: Type<RouteInformationFilterComponent>;
    icon = "map";
    name = "Routes";
    viewmodel = new RouteInformationViewmodel();

    constructor() {
        super();
    }

    ngAfterContentChecked(): void {
        if (!this.isInitialized && this.zrtTree && this.zrtFilterService) {
            this.zrtTree.zrtFilterService = this.zrtFilterService;
            this.setInitialized();
            if (!this.viewmodel.areaSubscription || this.viewmodel.areaSubscription.closed) {
                this.viewmodel.areaSubscription = this.zrtFilterService.observableByArea.subscribe((value) => {
                    this.viewmodel.byArea = value;
                });
            }
        }
    }

    ngOnDestroy(): void {
        if (this.viewmodel.areaSubscription && !this.viewmodel.areaSubscription.closed) {
            this.viewmodel.areaSubscription.unsubscribe();
        }
    }

    //events
    onAddNameRefiner(event?: KeyboardEvent) {
        this._refinerService.onInputChange(
            RefinerLocation.routeName,
            this.viewmodel.nameInput
        );
        if (
            this.viewmodel.nameInput &&
            (!event || this.inputSubmitKeys.includes(event.key))
        ) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.routeName;
            refiner.value = this.viewmodel.nameInput;
            refiner.dataPropertyName = "name";

            this._refinerService.checkAndUpdateRefiner(refiner);
        }
    }

    onAddDescriptionRefiner(event?: KeyboardEvent) {
        this._refinerService.onInputChange(
            RefinerLocation.routeDescription,
            this.viewmodel.descriptionInput
        );
        if (
            this.viewmodel.descriptionInput &&
            (!event || this.inputSubmitKeys.includes(event.key))
        ) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.routeDescription;
            refiner.value = this.viewmodel.descriptionInput;
            refiner.dataPropertyName = "description";

            this._refinerService.checkAndUpdateRefiner(refiner);
        }
    }

    onAreaChange(value: boolean): void {
        this.zrtFilterService.byArea = this.viewmodel.byArea = value;
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.routeName:
                    if (refiner.value !== this.viewmodel.nameInput) {
                        this.viewmodel.nameInput = refiner.value;
                    }
                    break;
                case RefinerLocation.routeDescription:
                    if (refiner.value !== this.viewmodel.descriptionInput) {
                        this.viewmodel.descriptionInput = refiner.value;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();

        tempRefiner.location = RefinerLocation.routeName;
        const nameRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.routeName
        );
        if (nameRefiner) {
            this.onInputChange(nameRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.routeDescription;
        const descriptionRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.routeDescription
        );
        if (descriptionRefiner) {
            this.onInputChange(descriptionRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }
}
