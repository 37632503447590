import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';
import { ViewContractViewModel } from './view-contract.viewmodel';

@Component({
    selector: 'app-view-contract',
    templateUrl: './view-contract.component.html',
    styleUrls: ['./view-contract.component.scss']
})
export class ViewContractComponent implements OnInit {

    viewmodel: ViewContractViewModel;
    viewSet: boolean = false;

    @ViewChild("agreement") agreement: ElementRef<any>;

    constructor(public injectedData: SwisherOverlayRef<
            ViewContractViewModel,
            ViewContractComponent
        >) { }

    ngOnInit(): void {
        this.viewmodel = this.injectedData.data;
    }

    ngAfterViewChecked(): void {
        if (!this.viewSet) {
            this.viewSet = true;
            this.viewmodel.agreement = this.agreement;
        }
    }
}
