import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from "@angular/core";

@Component({
    selector: "shield-sku-breakdown-chart",
    templateUrl: "sku-breakdown-chart.component.html",
    styleUrls: ["sku-breakdown-chart.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkuBreakdownChartComponent implements OnInit, OnChanges {
    @Input() data: SkuBreakdownValue[];
    @Input() variant: "Normal" | "Tall" = "Normal";
    @Input() hasData = false;
    public viewbox: string = "0 0 100 20";
    public height: string = "24rem";

    constructor(private changeDetection: ChangeDetectorRef) { }

    ngOnChanges(_changes: SimpleChanges): void {
        this.processData();
    }

    ngOnInit(): void {
        this.processData();
    }

    processData(): void {
        this.height = this.variant === "Normal" ? "24rem" : "48rem";
        this.changeDetection.markForCheck();
    }
}

export interface SkuBreakdownValue {
    value: number;
    swisherLabel: string;
    genericLabel: string;
    chartLabel: string;
}
