import { Injectable } from "@angular/core";
import { GenericResponseDto, PictureParamDto } from "shield.shared";
import { Call } from "src/app/accounts/call-master/call-services/call.service";
import { Picture } from "src/app/entity-models/picture.entity";
import { DatabaseService } from "../database.service";
import { PictureOfflineService } from "../offline-services/picture-offline.service";
import { PictureOnlineService } from "../online-services/picture-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class PictureDelineationService extends DelineationContext<Picture, string> {
    constructor(
        private pictureOfflineService: PictureOfflineService,
        private pictureOnlineService: PictureOnlineService,
        snackbarService: SnackbarService,
        protected datasourceDelineationService: DatasourceDelineationService,
        protected dbService: DatabaseService
    ) {
        super(dbService, datasourceDelineationService, snackbarService);
    }

    async getPicturesByCall(call: Call): Promise<GenericResponseDto<Picture[]>> {

        const offline = (key: Call) => {
            return this.pictureOfflineService.getCallPicturesByCall(key);
        };
        const online = async (key: Call) => {
            let rtn = new GenericResponseDto<Picture[]>();
            const pictures =  await this.pictureOfflineService.getCallPicturesByCall(key);
            if (pictures?.length) {
                rtn.values = pictures;
            } else {
                rtn = await this.pictureOnlineService.getCallPicturesByCall(key);
            }
            return rtn;
        };
        const response = await this.datasourceDelineationService.makeCall<Call, Picture[]>(call, offline, online);

        return response;
    }

    async getLocalPicturesByIds(ids: string[]): Promise<GenericResponseDto<Picture[]>> {

        const offline = (key: string[]) => {
            return this.pictureOfflineService.getLocalPicturesByIds(key);
        };
        const online = (key: string[]) => {
            return this.pictureOfflineService.getLocalPicturesByIds(key);
        };
        const response = await this.datasourceDelineationService.makeCall<string[], Picture[]>(ids, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getPicture(params: PictureParamDto): Promise<GenericResponseDto<Picture>> {
        const offline = (key: PictureParamDto) => {
            return this.pictureOfflineService.getPicture(key);
        };
        const online = async (key: PictureParamDto) => {
            let result = await this.pictureOfflineService.getPicture(key);
            let rtn = new GenericResponseDto<Picture>();
            rtn.values = result;

            if (!result) {
                rtn = await this.pictureOnlineService.getPicture(key);
            }
            return rtn;
        };
        const response = await this.datasourceDelineationService.makeCall<PictureParamDto, Picture>(params, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }
}
