import { Component, OnInit } from "@angular/core";
import { HeaderButtonComponent } from "../header-button/header-button.component";
import {
    faRoad,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { ButtonActions } from "../../../enums/button-actions.enum";

@Component({
    selector: "app-route-button",
    templateUrl: "./route-button.component.html",
    styleUrls: ["./route-button.component.css"]
})
export class RouteButtonComponent
    implements HeaderButtonComponent {
    icon: IconDefinition = faRoad;
    title = "Go to Route Builder";
    buttonAction = ButtonActions.route;
    isDisabled: boolean;
}
