import { GenericLookup } from "../generic";

export enum TaxDivisions {
    Chew = 1,
    MoistSnuff = 2,
    DrySnuff = 3,
    LargeCigars = 4,
    MassMarket = 5,
    DrewEstate = 6,
    LargeFiltered = 7,
    LittleFiltered = 8,
    Rogue = 9,
    HempRollingPapers = 10,
    CBD = 11,
    EVapor = 12,
    HempCones = 13,
	HempSticks = 14,
    HempWraps = 15,
    LargeCigarsACID = 16,
	LargeCigarsBlackStone = 17,
	LargeCigarsCombo = 18,
	LargeCigarsGoodies = 19,
	LargeCigarsJava = 20,
	LargeCigarsKeepMovingGoodies = 21,
	LargeCigarsKingEdward = 22,
	LargeCigarsOptimo = 23,
	LargeCigarsPomPom = 24,
	LargeCigarsPOMPOMLEAF = 25,
	LargeCigarsSwisherSweets = 26,
	LargeCigarsSwisherSweetsBLK = 27,
	LargeCigarsSwisherSweetsLEAF = 28,
	LargeCigarsSwisherSweetsLegend = 29,
	LargeCigarsSwisherSweetsOutlaws = 30,
	LargeCigarsThompson = 31,
	Caffeine = 32,
	ELiquid = 33,
	Hempire = 34,
	RogueGLT = 35,
	RoguePouches = 36,
	LargeCigarsCrwnz = 37
}

export const TaxDivisionLookup: GenericLookup<TaxDivisions>[] = [
    { id: TaxDivisions.Chew, name: "Chew", description: "Chewing Tobacco" },
    { id: TaxDivisions.MoistSnuff, name: "Moist Snuff", description: "Moist Smokeless Tobacco" },
    { id: TaxDivisions.DrySnuff, name: "Dry Snuff", description: "Dry Smokeless Tobacco" },
    { id: TaxDivisions.LargeCigars, name: "Large Cigars", description: "Large Cigars" },
    { id: TaxDivisions.MassMarket, name: "Mass Market", description: "Mass Market" },
    { id: TaxDivisions.DrewEstate, name: "Drew Estate", description: "Drew Estate" },
    { id: TaxDivisions.LargeFiltered, name: "Large Filtered", description: "Large Filtered" },
    { id: TaxDivisions.LittleFiltered, name: "Little Filtered", description: "Little Filtered" },
    { id: TaxDivisions.Rogue, name: "Rogue", description: "Rogue" },
    { id: TaxDivisions.HempRollingPapers, name: "Hemp Rolling Papers", description: "Hemp Rolling Papers" },
    { id: TaxDivisions.CBD, name: "CBD", description: "Cannabidiol" },
    { id: TaxDivisions.EVapor, name: "E-Vapor", description: "Electronic Vapor" },
    { id: TaxDivisions.HempCones, name: "Hemp Cones", description: "Hemp Cones" },
	{ id: TaxDivisions.HempSticks, name: "Hemp Sticks", description: "Hemp Sticks" },
	{ id: TaxDivisions.HempWraps, name: "Hemp Wraps", description: "Hemp Wraps" },
    { id: TaxDivisions.LargeCigarsACID , name: 'Large Cigars - ACID', description: 'Large Cigars - ACID' },
	{ id: TaxDivisions.LargeCigarsBlackStone , name: 'Large Cigars - BlackStone', description: 'Large Cigars - BlackStone' },
	{ id: TaxDivisions.LargeCigarsCombo , name: 'Large Cigars - Combo', description: 'Large Cigars - Combo' },
	{ id: TaxDivisions.LargeCigarsGoodies , name: 'Large Cigars - Goodies', description: 'Large Cigars - Goodies' },
	{ id: TaxDivisions.LargeCigarsJava , name: 'Large Cigars - Java', description: 'Large Cigars - Java' },
	{ id: TaxDivisions.LargeCigarsKeepMovingGoodies , name: 'Large Cigars - Keep Moving Goodies', description: 'Large Cigars - Keep Moving Goodies' },
	{ id: TaxDivisions.LargeCigarsKingEdward , name: 'Large Cigars - King Edward', description: 'Large Cigars - King Edward' },
	{ id: TaxDivisions.LargeCigarsOptimo , name: 'Large Cigars - Optimo', description: 'Large Cigars - Optimo' },
	{ id: TaxDivisions.LargeCigarsPomPom , name: 'Large Cigars - Pom Pom', description: 'Large Cigars - Pom Pom' },
	{ id: TaxDivisions.LargeCigarsPOMPOMLEAF , name: 'Large Cigars - POM POM LEAF', description: 'Large Cigars - POM POM LEAF' },
	{ id: TaxDivisions.LargeCigarsSwisherSweets , name: 'Large Cigars - Swisher Sweets', description: 'Large Cigars - Swisher Sweets' },
	{ id: TaxDivisions.LargeCigarsSwisherSweetsBLK , name: 'Large Cigars - Swisher Sweets BLK', description: 'Large Cigars - Swisher Sweets BLK' },
	{ id: TaxDivisions.LargeCigarsSwisherSweetsLEAF , name: 'Large Cigars - Swisher Sweets LEAF', description: 'Large Cigars - Swisher Sweets LEAF' },
	{ id: TaxDivisions.LargeCigarsSwisherSweetsLegend , name: 'Large Cigars - Swisher Sweets Legend', description: 'Large Cigars - Swisher Sweets Legend' },
	{ id: TaxDivisions.LargeCigarsSwisherSweetsOutlaws , name: 'Large Cigars - Swisher Sweets Outlaws', description: 'Large Cigars - Swisher Sweets Outlaws' },
	{ id: TaxDivisions.LargeCigarsThompson , name: 'Large Cigars - Thompson', description: 'Large Cigars - Thompson' },
    { id: TaxDivisions.Caffeine, name: 'Caffeine', description: 'Caffeine' },
	{ id: TaxDivisions.ELiquid, name: 'E-Liquid', description: 'E-Liquid' },
	{ id: TaxDivisions.Hempire, name: 'Hempire', description: 'Hempire' },
	{ id: TaxDivisions.RogueGLT, name: 'Rogue - GLT', description: 'Rogue - GLT' },
	{ id: TaxDivisions.RoguePouches, name: 'Rogue - Pouches', description: 'Rogue - Pouches' },
	{ id: TaxDivisions.LargeCigarsCrwnz , name: 'Large Cigars - Crwnz', description: 'Large Cigars - Crwnz' },
];
