import { Component, OnInit } from "@angular/core";
import { Picture } from "src/app/entity-models/picture.entity";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { PictureCarouselViewModel } from "../account.viewmodels/picture-carousel.viewmodel";
import { PictureModalComponent } from "src/app/dialogs/pop-out-img-dialog/img-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "app-picture",
    templateUrl: "./picture.component.html",
    styleUrls: ["./picture.component.css"]
})
export class PictureComponent implements OnInit {
    pictureTags = "";
    pictureType = "";
    pictureString: string = "";
    slides: Picture[] = [];
    retailCall: RetailCall = null;
    proportion: number;

    constructor(
        private injectedData: SwisherOverlayRef<
            PictureCarouselViewModel,
            PictureComponent
        >,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.proportion = 75;
        const pictures = this.injectedData.data?.pictureViewModels.map((viewModel: { picture: Picture }) => viewModel.picture);
        if (pictures.some((p) => !!p)) {
            this.slides = pictures;
        }
        this.setPictureType(0);
    }

    openPictureInModal(picture: string, allData: string) {
        this.dialog.open(PictureModalComponent, {
            data: {picture: picture, allData: allData},
            autoFocus: false,
            maxHeight: "80vh",
            width: "800px"
        });
    }
    setPictureType(index: number): void {
        if (index || index === 0) {
            if (this.slides?.length > 0) {
                const picture: Picture = this.slides[index];
                this.pictureString = picture?.image ? picture.image : ''
                if (picture) {
                    const metaData = this.injectedData.data?.pictureViewModels.find(
                        (data) => data.callpicture.id === picture.id
                    )?.callpicture;

                    if (metaData) {
                        this.pictureType = metaData.type;

                        if (metaData.tags) {
                            this.pictureTags = metaData.tags.join(", ");
                        } else {
                            this.pictureTags = "";
                        }
                    }
                }
            }
        }
    }
}
