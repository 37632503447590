export class ProductDistributionEntry {
    id: string;
    itemNumber: string;
    description: string;
    inDistribution: number;
    intro: number;
    oos: number;
    cos: number;
    distPercent: number;
}
