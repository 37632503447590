export class ProjectMetricWholesalerDto {
    id: string;
    name: string;
    totalCalls: number;
    callsWithWholesaler: number;
    callsWithoutWholesaler: number;
    storesWithOrders: number;
    orderCount: number;
    orderAmount: number;
    orderTotalSticks: number;
    gratisAmount: number;
    gratisPercentage: number;
}
