import { WholesalerGroupProductCatalogItemDto } from "shield.shared";
import { WholesalerGroupProductCatalogItem } from "src/app/entity-models/wholesaler-group-product-catalog-item.entity";
import { ProductConverterService } from "./product-converter.service";
import { WholesaleProductCatalogItemConverter } from "./wholesaler-product-catalog-item-converter.service";

export class WholesalerGroupProductCatalogItemConverterService {
    static entityToDto(item: WholesalerGroupProductCatalogItem): WholesalerGroupProductCatalogItemDto {
        return {
            ...item,
            createdUtcDateTime: item.createdUtcDateTime?.toISOString(),
            dateAvailable: item.dateAvailable?.toISOString(),
            wholesalerCount: item.wholesalerCount.toString(),
            isDeactivatedDate: item.isDeactivatedDate?.toISOString(),
            modifiedUtcDateTime: item.modifiedUtcDateTime?.toISOString(),
            product: ProductConverterService.productToProductDto(item.product),
            wholesalerItems: item.wholesalerItems.map(i => WholesaleProductCatalogItemConverter.entityToDto(i))
        } as WholesalerGroupProductCatalogItemDto;
    }

    static dtoToEntity(dto: WholesalerGroupProductCatalogItemDto)
    : WholesalerGroupProductCatalogItem {
    const rtn = new WholesalerGroupProductCatalogItem();

    rtn.createdUserFullName = dto.createdUserFullName;
    rtn.createdUserId = dto.createdUserId;
    rtn.createdUserZrt = dto.createdUserZrt;
    rtn.createdUtcDateTime = dto.createdUtcDateTime ? new Date(dto.createdUtcDateTime) : new Date();
    rtn.department = dto.department;
    rtn.dateAvailable = dto.dateAvailable ? new Date(dto.dateAvailable) : undefined;
    rtn.wholesalerCount = parseInt(dto.wholesalerCount);
    rtn.id = dto.id;
    rtn.isDeactivated = dto.isDeactivated;
    rtn.isDeactivatedDate = dto.isDeactivatedDate ? new Date(dto.isDeactivatedDate) : null;
    rtn.modifiedUserFullName = dto.modifiedUserFullName;
    rtn.modifiedUserId = dto.modifiedUserId;
    rtn.modifiedUserZrt = dto.modifiedUserZrt;
    rtn.modifiedUtcDateTime = dto.modifiedUtcDateTime ? new Date(dto.modifiedUtcDateTime) : null;
    rtn.product = ProductConverterService.productDtoToProduct(dto.product);
    rtn.productId = dto.productId;
    rtn.productUIN = dto.productUIN;
    rtn.rowversion = rtn.rowversion;
    rtn.wholesalerItems = dto.wholesalerItems.map(i => WholesaleProductCatalogItemConverter.dtoToEntity(i));
    rtn.wholesalerGroupId = dto.wholesalerGroupId;
    rtn.isAvailable = dto.isDeactivated || dto.product.isDeactivated || dto.product.isDeleted ? 0 : 1;

    return rtn;
}
}
