import { CallOrderProductDto } from "./call-order-product.dto";
import { CallSurveyAnswerDto } from "./call-survey-answer.dto";
import { CallTypes } from "../enum";

export class ChainHqCallDto {

    id: string;
    callNumber: string;
    customerId: string;
    px3RankId?: string;
    px3Rank: string;

    createdUserId: string;
    createdUtcDateTime: string;
    createdUserZrt: string;
    createdUserFullName: string
    modifiedUtcDateTime: string;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    startTime: string;
    stopTime: string;

    closingNotes: string;

    orderProducts: CallOrderProductDto[];
    callSurveyAnswers: CallSurveyAnswerDto[];

    callType: CallTypes.chainHq;
    isDeleted: boolean;

    rowversion: string;
}
