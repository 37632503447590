<div #myFilter>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-name">Route Name
            </mat-label>
            <input #nameInput id="name-input" type="text" (keyup)="onAddNameRefiner($event)"
                [(ngModel)]="viewmodel.nameInput" matInput />
        </mat-form-field>
    </div><div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-description">Route Description
            </mat-label>
            <input #uinInput id="description-input" type="text" (keyup)="onAddDescriptionRefiner($event)"
                [(ngModel)]="viewmodel.descriptionInput" matInput />
        </mat-form-field>
    </div>
    <div class="d-flex justify-content-between align-items-center">
        <div class="w-100"><app-zrt-tree-view #zrtTree></app-zrt-tree-view></div>
    </div>
    <mat-checkbox class="checkbox-margin" [checked]="viewmodel.byArea" (change)="onAreaChange($event.checked)"> By Area </mat-checkbox>
</div>
