import {
    Component,
    ElementRef,
    HostBinding,
    OnInit,
    ViewChild
} from "@angular/core";
import { combineLatest } from "rxjs";
import {
    debounceTime,
    filter,
    map,
    shareReplay
} from "rxjs/operators";
import { CustomerGenericTypes } from "src/app/enums/customer-generic-types";
import { Helper } from "src/app/helpers/helper";
import { AppStateService } from "src/app/services/app-state.service";
import { CustomerStateService } from "../account-services/customer-state.service";

@Component({
    selector: "app-dashboard-master",
    templateUrl: "./dashboard-master.component.html",
    styleUrls: ["./dashboard-master.component.css"]
})
export class DashboardMasterComponent implements OnInit {
    employee$ = this.appStateService.currentEmployee.pipe(
        filter((employee) => !!employee)
    );
    customer$ = this.customerStateService.observableCustomer.pipe(
        filter((customer) => !!customer)
    );

    msaCustomer$ = this.customer$.pipe(
        filter((customer) => customer.isMsa),
        map(customer => [customer]),
        debounceTime(1000),
        shareReplay(1)
    );

    isAvailableToUser$ = combineLatest([this.employee$, this.customer$]).pipe(
        map(
            ([employee, customer]) =>
                !!customer.isZrtAssignment ||
                !!customer.isSpecialAssignment ||
                !!customer.isProjectAssignment ||
                !Helper.isEmployeeZrtAssigned(employee)
        )
    );

    isRetailMsa$ = this.customer$.pipe(
        map(
            (customer) =>
                Helper.getCustomerGenericType(customer) ==
                CustomerGenericTypes.retail && customer.isMsa
        )
    );

    constructor(
        private customerStateService: CustomerStateService,
        private appStateService: AppStateService,
    ) { }

    ngOnInit(): void {
    }


}
