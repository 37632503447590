import { WholesalerGroupMemberDto } from "shield.shared";
import { WholesalerGroupMember } from "src/app/entity-models/wholesaler-group-member.entity";

export class WholesalerGroupMemberConverterService {

    static wholesalerGroupMemberToWholesalerGroupMemberDto(
        w: WholesalerGroupMember
    ): WholesalerGroupMemberDto {
        const rtn = new WholesalerGroupMemberDto();

        rtn.id = w.id;
        rtn.wholesalerGroupId = w.wholesalerGroupId;
        rtn.wholesalerId = w.wholesalerId;
        rtn.createdUserId = w.createdUserId;
        rtn.createdUserZrt = w.createdUserZrt;
        rtn.createdUserFullName = w.createdUserFullName;
        rtn.createdUtcDateTime = w.createdUtcDateTime.toISOString();
        rtn.modifiedUserId = w.modifiedUserId;
        rtn.modifiedUserZrt = w.modifiedUserZrt;
        rtn.modifiedUserFullName = w.modifiedUserFullName;
        rtn.modifiedUtcDateTime = w.modifiedUtcDateTime.toISOString();
        rtn.rowversion = w.rowversion;

        return rtn;
    }

    static wholesalerGroupMemberDtoToWholesalerGroupMember(
        dto: WholesalerGroupMemberDto
    ): WholesalerGroupMember {

        const rtn = new WholesalerGroupMember();

        rtn.id = dto.id;
        rtn.createdUserFullName = dto.createdUserFullName;
        rtn.createdUserId = dto.createdUserId;
        rtn.createdUserZrt = dto.createdUserZrt;
        rtn.createdUtcDateTime = dto.createdUtcDateTime ? new Date(dto.createdUtcDateTime) : new Date();
        rtn.modifiedUserFullName = dto.modifiedUserFullName;
        rtn.modifiedUserId = dto.modifiedUserId;
        rtn.modifiedUserZrt = dto.modifiedUserZrt;
        rtn.modifiedUtcDateTime = dto.modifiedUtcDateTime ? new Date(dto.modifiedUtcDateTime) : null;
        rtn.rowversion = dto.rowversion;
        rtn.wholesalerGroupId = dto.wholesalerGroupId;
        rtn.wholesalerId = dto.wholesalerId;

        return rtn;
    }
}
