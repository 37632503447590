import { CallPicture } from "src/app/entity-models/call-picture.entity";
import { Helper } from "src/app/helpers/helper";
import { Call } from "../../call-master/call-services/call.service";
import { CallTypes } from "shield.shared";

export class CallHistoryAccountProfileViewModel {

    hasCallPictures = false;
    fullName: string;
    closingNotes: string;
    createdDate: Date;
    duration: string;
    beforeCallPicture: CallPicture;
    afterCallPicture: CallPicture;
    call: Call;

    buildViewModleFromModel(call: Call): void {
        if (call) {
            this.call = call;

            if (
                call.callType !== CallTypes.chainHq
            ) {
                const callPictures: CallPicture[] = call.callPictures;

                this.hasCallPictures = !!call.callPictures?.length;
                if (callPictures && callPictures.length > 0) {
                    this.beforeCallPicture = callPictures.find(
                        (callPicture) => callPicture.type === "Before"
                    );
                    this.afterCallPicture = callPictures.find(
                        (callPicture) => callPicture.type === "After"
                    );
                }
            }
            this.fullName = Helper.formatDisplayName(call);
            this.closingNotes = call.closingNotes;
            this.createdDate = call.createdUtcDateTime;

            if (call.stopTime && call.startTime) {
                this.duration = Helper.getHoursAndMinutesFromMilliseconds(
                    call.stopTime.getTime() - call.startTime.getTime()
                );
            }
        }
    }
}
