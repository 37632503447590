import { ZoneRecapDto, ZoneRecapProductInDistDto, ZoneRecapResultDto, ZoneRecapZrtDto } from "shield.shared";
import { ZoneRecapProductInDist } from "src/app/entity-models/zone-recap-product-indist.entity";
import { ZoneRecapResult } from "src/app/entity-models/zone-recap-result.entity";
import { ZoneRecapZrt } from "src/app/entity-models/zone-recap-zrt.entity";
import { ZoneRecap } from "src/app/entity-models/zone-recap.entity";

export class ZoneRecapConverterService {
    static zoneRecapProductInDistDtoToZoneRecapProductInDist(dto: ZoneRecapProductInDistDto): ZoneRecapProductInDist {
        const entity = new ZoneRecapProductInDist();

        entity.productId = dto.productId;
        entity.description = dto.description;
        entity.storeCountWithProductInDist = dto.storeCountWithProductInDist;

        return entity;
    }

    static zoneRecapResultDtoToZoneRecapResult(dto: ZoneRecapResultDto): ZoneRecapResult {
        const entity = new ZoneRecapResult();

        entity.daysWorked = dto.daysWorked;
        entity.retailCalls = dto.retailCalls;
        entity.callAverageIncludesResetDays = dto.callAverageIncludesResetDays;
        entity.callAverageExcludesResetDays = dto.callAverageExcludesResetDays;
        entity.top300CalledOnOnce = dto.top300CalledOnOnce;
        entity.top300CalledOnTwiceOrMore = dto.top300CalledOnTwiceOrMore;
        entity.new = dto.new;
        entity.underContract = dto.underContract;
        entity.canceled = dto.canceled;
        entity.dollarsPaidOut = dto.dollarsPaidOut;

        return entity;
    }

    static zoneRecapZrtDtoToZoneRecapZrt(dto: ZoneRecapZrtDto): ZoneRecapZrt {
        const entity = new ZoneRecapZrt();

        entity.zrt = dto.zrt;
        entity.results = ZoneRecapConverterService.zoneRecapResultDtoToZoneRecapResult(dto.results);
        entity.productsInDist = dto.productsInDist.map((dto) => ZoneRecapConverterService.zoneRecapProductInDistDtoToZoneRecapProductInDist(dto));

        return entity;
    }

    static zoneRecapDtoZToZoneRecap(dto: ZoneRecapDto): ZoneRecap {
        const entity = new ZoneRecap();

        entity.zrts = dto.zrts.map((zrt) => ZoneRecapConverterService.zoneRecapZrtDtoToZoneRecapZrt(zrt));

        return entity;
    }
}
