import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { CallService } from "../../call-services/call.service";

import { OverlayService } from "src/app/services/overlay.service";
import { Picture } from "src/app/entity-models/picture.entity";
import { StepCamComponent } from "../../step-cam/step-cam.component";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { StepperCallApplicationService } from "../../stepper-call/stepper-call-services/stepper-call-application.service";
import { RetailStepperStep } from "src/app/enums/retail-stepper-step";
import { PictureDelineationService } from "src/app/services/delineation-services/picture-delineation.service";

@Component({
    selector: "app-before-picture",
    templateUrl: "./before-picture.component.html",
    styleUrls: ["./before-picture.component.css"]
})
export class BeforePictureComponent implements OnInit, OnDestroy {
    @HostBinding("class") class = "worksheet-area";
    //Private vars
    private observableCallSubscription: Subscription;
    private observableSelectedIndexSubscription: Subscription;
    beforePicture: Picture = null;
    beforeImg = "";

    //Public vars
    public constructor(
        private callService: CallService,
        private stepperCallApplicationService: StepperCallApplicationService,
        private overlayService: OverlayService,
        private pictureDelineationService: PictureDelineationService
    ) {}

    public ngOnInit(): void {
        if (
            !this.observableCallSubscription ||
            this.observableCallSubscription.closed
        ) {
            this.observableCallSubscription = this.callService.observableCall.subscribe(
                () => {
                    this.setImage();
                }
            );
        }

        if (
            !this.observableSelectedIndexSubscription ||
            this.observableSelectedIndexSubscription.closed
        ) {
            this.observableSelectedIndexSubscription = this.stepperCallApplicationService.observableSelectedIndex.subscribe(
                () => {
                    this.setImage();
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (
            this.observableCallSubscription &&
            !this.observableCallSubscription.closed
        ) {
            this.observableCallSubscription.unsubscribe();
        }
        if (
            this.observableSelectedIndexSubscription &&
            !this.observableSelectedIndexSubscription.closed
        ) {
            this.observableSelectedIndexSubscription.unsubscribe();
        }
    }

    onOpenModal(): void {
        this.overlayService.open(StepCamComponent, RetailStepperStep.before);
    }

    private async setImage() {
        if (
            this.stepperCallApplicationService.selectedIndex === 0 &&
            this.callService.call
        ) {
            const callPicture = (this.callService.call as RetailCall)?.callPictures.find(
                (p) => p.type === "Before"
            );


            if (callPicture) {
                const beforeResponse = await this.pictureDelineationService.getLocalPicturesByIds([callPicture.id]);

                if (!beforeResponse) { return; }

                this.beforePicture = beforeResponse.values[0];

                if (this.beforePicture) {
                    this.beforeImg = this.beforePicture.image;
                }
            }
        }
    }
}
