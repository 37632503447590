import { AccountFilterDto, CallableDataDto, CustomerTypeLookup, RefinerLocation, valueSeparator } from "shield.shared";
import { GoogleMapLatLng } from "src/app/accounts/accounts-list/googleMapsModels";
import { Refiner } from "../../entity-models/refiner.entity";
import { AddressFilterMapService } from "./address-filter-map.service";
import { BaseFilterMapService } from "./base-filter-map.service";
import { ZrtFilterMapService } from "./zrt-filter-map.service";

export class AccountListFilterMapService extends BaseFilterMapService {

    static mapFilterData(refiners: Refiner[]): AccountFilterDto {
        const filterDto = new AccountFilterDto();
        if (!refiners) {
            return filterDto;
        }
        const zrtFilterDto = ZrtFilterMapService.mapFilterData(refiners);
        const businessAddressFilterDto = AddressFilterMapService.mapFilterData(refiners);
        const drivingAddressFilterDto = AddressFilterMapService.mapFilterData(refiners);

        if (Object.keys(zrtFilterDto).length) {
            filterDto.zrtFilterDto = zrtFilterDto;
        }
        if (Object.keys(businessAddressFilterDto).length) {
            filterDto.businessAddressFilterDto = businessAddressFilterDto;
        }
        if (Object.keys(drivingAddressFilterDto).length) {
            filterDto.drivingAddressFilterDto = drivingAddressFilterDto;
        }

        for (const refiner of refiners) {
            if (!refiner.dataValue) {
                refiner.dataValue = refiner.value;
            }
            switch (refiner.location) {
                case RefinerLocation.myList:
                    filterDto.ids = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.specialCoverage:
                    filterDto.customerNumbers = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.account:
                    filterDto.account = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.callOnOrAfterDate:
                    const callOnOrAfterDate = new Date(refiner.dataValue)
                    callOnOrAfterDate.setHours(0,0,0,0);
                    filterDto.callOnOrAfterDate = callOnOrAfterDate.toISOString();
                    break;
                case RefinerLocation.callOnOrBeforeDate:
                    const callOnOrBeforeDate = new Date(refiner.dataValue)
                    callOnOrBeforeDate.setHours(23,59,59,0);
                    filterDto.callOnOrBeforeDate = callOnOrBeforeDate.toISOString();
                    break;
                case RefinerLocation.availability:
                    filterDto.availability = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.projects:
                    filterDto.projectIds = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.wholesalers:
                    filterDto.wholesalerIds = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.projectWholesalers:
                    filterDto.projectWholesalers = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.chains:
                    filterDto.accountOwnerCodes = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.products:
                    refiner.value.includes("Not In Dist")
                        ? filterDto.productIdsNotInDist = refiner.dataValue.split(valueSeparator)
                        : filterDto.productIdsInDist = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.storeTypes:
                    filterDto.customerTypeIds = CustomerTypeLookup
                        .filter((ct) => refiner.dataValue.split(valueSeparator).includes(ct.name))
                        .map((v) => v.id);
                    break;
                case RefinerLocation.msa:
                    filterDto.isMsa = refiner.dataValue === BaseFilterMapService.yes;
                    break;
                case RefinerLocation.phone:
                    filterDto.hasPhone = refiner.dataValue === BaseFilterMapService.yes;
                    break;
                case RefinerLocation.isActive:
                    filterDto.isActive = refiner.dataValue === BaseFilterMapService.yes;
                    break;
                case RefinerLocation.volume:
                    filterDto.volume = refiner.dataValue === BaseFilterMapService.yes;
                    break;
                case RefinerLocation.px3Rank:
                    filterDto.px3Rank = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.callable:
                    const values = refiner.dataValue.split(valueSeparator);
                    filterDto.callable = new Array<CallableDataDto>();
                    for (const value of values) {
                        const callable = value.includes("Yes");
                        const callableOverridden = value.includes("Overridden");
                        const set = new CallableDataDto();
                        set.callable = callable;
                        set.callableOverridden = callableOverridden;
                        filterDto.callable.push(set);
                    }
                    break;
                case RefinerLocation.assignment:
                    filterDto.isAssigned = refiner.dataValue === BaseFilterMapService.true;
                    break;
                case RefinerLocation.isAssignedToEmployee:
                    filterDto.assignedToEmployee = refiner.dataValue === BaseFilterMapService.true;
                    break;
                case RefinerLocation.assignedEmployee:
                    filterDto.assignedEmployeeIds = refiner.dataValue.split(valueSeparator);
                    break;
                default:
                    break;
            }
        }

        return filterDto;
    }

    static mapLatLngFilter(
        filterDto: AccountFilterDto,
        lower: GoogleMapLatLng,
        upper: GoogleMapLatLng
    ): AccountFilterDto {
        if (!filterDto || !lower || !upper) return filterDto;

        filterDto.lowerBoundLat = lower.lat;
        filterDto.lowerBoundLng = lower.lng;

        filterDto.upperBoundLat = upper.lat;
        filterDto.upperBoundLng = upper.lng;

        return filterDto;
    }

    static mapCustomerIdToFilter(filterDto: AccountFilterDto, customerId: string): AccountFilterDto {
        if (!customerId || !filterDto) return;

        filterDto.ids ??= new Array<string>();
        filterDto.ids.push(customerId);

        return filterDto;
    }
}
