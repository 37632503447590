<div mat-dialog-title>
    <h1 class="float-left" style="margin-bottom: 0px; margin-top: 5px">{{ title }} Picture</h1>
    <button (click)="closeDialog()" class="btn btn-warning float-right" type="button">
        <i class="material-icons">close</i>
    </button>
    <button (click)="downloadImg()" style="margin-right: 5px" class="btn btn-secondary float-right">
        <i class="material-icons">cloud_download</i>
    </button>
</div>

<div mat-dialog-content>
    <img [src]="image" />
</div>