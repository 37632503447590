import { GenericLookup } from "../generic";

export enum LicenseTypes {

    FederalTaxId = 1,
    StateTaxId = 2,
    RetailStateVaporLicense = 3,
    RetailStateOTPLicense = 4,

}

export const LicenseTypeLookup: GenericLookup<LicenseTypes>[] = [
    { id: LicenseTypes.FederalTaxId, name: "FederalTaxId", description: "Federal Tax Id" },
    { id: LicenseTypes.StateTaxId, name: "StateTaxId", description: "State Tax Id" },
    { id: LicenseTypes.RetailStateVaporLicense, name: "RetailStateVaporLicense", description: "Retail State Vapor License" },
    { id: LicenseTypes.RetailStateOTPLicense, name: "RetailStateOTPLicense", description: "Retail State OTP License" },
];
