import { GenericLookup } from "../generic";
import { SurveyQuestionTypes } from "../lookup";
import { SurveyQuestionOptionDto } from "./survey-question-option.dto";

export class SurveyQuestionDto {
    id: string;
    surveyId: string;
    surveyQuestionType: GenericLookup<SurveyQuestionTypes>
    orderIndex: number
    text: string
    isActive: boolean
    isRequired: boolean
    surveyQuestionOptions: SurveyQuestionOptionDto[];
}
