import {
    MatDrawerMode, MatSidenav, MatSidenavContent
} from "@angular/material/sidenav";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { ColumnSelector } from "src/app/shared/viewmodels/column-selector.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import {
    faEye, faPrint, IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { OrderExtractionRefinerService } from "./order-extraction-refiner.service";
import { RefinerServiceBase } from "src/app/shared/refiner-service-base";
import { ButtonActions } from "src/app/shared/enums/button-actions.enum";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import {
    RefinerLocation, ResponseCountKey, SharedHelper,
} from "shield.shared";
import { OrderExtractionGridViewmodel } from "./order-extraction-grid.viewmodel";
import { ExcelExportButtonComponent } from "src/app/shared/page-header/buttons/excel-export-button/excel-export-button.component";
import { Customer } from "src/app/entity-models/customer.entity";
import { MatSelect } from "@angular/material/select";
import { OrderExtractionItemViewmodel } from "./order-extraction-item.viewmodel";
import { OrderExtraction } from "src/app/entity-models/order-extraction.entity";
import { Order } from "src/app/entity-models/order.entity";
import * as moment from "moment";
import { Router } from "@angular/router";
import * as XLSX from "xlsx";
import { OrderExtractionXlsxViewmodel } from "./order-extraction-xlsx.viewmodel";
import { OrderExtractionFilterComponent } from "src/app/shared/filters/order-extraction-filter/order-extraction-filter.component";
import { BehaviorSubject, fromEvent, Observable, Subscription } from "rxjs";
import { OrderExtractionWholesalerService } from "./order-extraction-wholesaler.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { OrderDelineationService } from "src/app/services/delineation-services/order-delineation.service";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { catchError, debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { saveAs } from "file-saver";
import { ElementRef } from "@angular/core";


export class OrderExtractionViewmodel {
    //private vars
    private orderDelineationService: OrderDelineationService;
    private wholesalerService: OrderExtractionWholesalerService;

    //public vars
    accountInput: string;
    activeRefiners: Refiner[] = [];
    availableColumns: ColumnSelector[];
    baseGridYOffset = 0;
    columnDef: ColumnDef[];
    columnsToDisplay: string[];
    currentRefinerMap: Map<RefinerLocation, string> = new Map();
    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    detailHeight = 48;
    drawer: MatSidenav;
    drawerMode: MatDrawerMode = "side";
    endDateInput: string;
    expandPanelsOnInit = true;
    extractionOptions = new Array<OrderExtractionItemViewmodel>();
    faEye: IconDefinition = faEye;
    faPrint: IconDefinition = faPrint;
    filters: FilterAndParams[] = [];
    grid: GridComponent;
    gridData = new Array<GridData>();
    gridHeight = "75vh";
    gridhieghtOffsetDeduction = 0;
    header: PageHeaderComponent;
    headerButtons: HeaderButtonComponent[] = new Array<HeaderButtonComponent>();
    headerName = "Order Extraction";
    isFixedLayout = false;
    isEnabledExpandedDetails = true;
    isExtractDisabled = true;
    isGapSet = false;
    isSearchDisabled = true;
    isSelectedExtractionDisabled = true;
    itemsRendedInViewPort = 13;
    itemSize = 48;
    newExtraction = "New Extraction";
    ordersToExtract: Order[] = new Array<Order>();
    pageIndex = 0;
    pageSize = 15;
    pleaseWaitService: PleaseWaitService;
    previousRefinerMap: Map<RefinerLocation, string> = new Map();
    refinerLessThans = [RefinerLocation.orderOnOrBeforeDate];
    router: Router;
    screenHeight: number = 0;
    searchCount: number = 0;
    selectedExtraction: OrderExtractionItemViewmodel;
    selectedWholesaler: Customer;
    selectedWholesalerSubscription: Subscription;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    showExpanderToggle = false;
    sideNavContent: MatSidenavContent;
    refinerService: RefinerServiceBase;
    refinerInputChangeSubscription: Subscription;
    topGapDistance = 0;
    total?: number;
    wholesalers: Customer[] = new Array<Customer>();
    wholesalersData: Customer[] = new Array<Customer>();
    wholesalerDropdown: MatSelect;
    wholesalerSearch: ElementRef;
    wholesalerSearchSubscription: Subscription;
    wholesalerSearchValue: string;
    wholesalerSelect: MatSelect;
    wholesalerSubscription: Subscription;

    constructor(
        refinerService: OrderExtractionRefinerService,
        orderDelineationService: OrderDelineationService,
        wholesalerService: OrderExtractionWholesalerService,
        pleaseWaitService: PleaseWaitService,
        router: Router
    ) {
        this.refinerService = refinerService;
        this.orderDelineationService = orderDelineationService;
        this.wholesalerService = wholesalerService;
        this.pleaseWaitService = pleaseWaitService;
        this.router = router;
    }

    //events
    onAddAccountRefiner(): void {
        if (this.accountInput) {
            this.refinerService.addRefiner(
                RefinerLocation.account,
                this.accountInput,
                "storeInformation"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.account
            )
        }
    }
    onAddOrderOnOrBeforeRefiner(): void {
        if (this.endDateInput) {
            this.refinerService.addRefiner(
                RefinerLocation.orderOnOrBeforeDate,
                this.endDateInput,
                "orderDate"
            );
        }
    }

    onButtonAction(value: any): void {
        switch (value) {
            case ButtonActions.exportToExcel:
                this.grid.onExportAsExcel();
                break;

            default:
                break;
        }
    }

    onButtonClickEvent(value: HeaderButtonComponent) {
        if (value.onClick) {
            value.onClick();
        }
    }

    async onExtractionSelectionChange() {
        this.setExtractionRefiner();
    }

    private async setExtractionSelection(): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        this.setSearchButton();
        if (this.selectedExtraction) {
            if (this.selectedExtraction.id === this.newExtraction) {
                await this.onWholesalerSelectionChange();
                this.populateGrid(this.selectedExtraction.orders);
            }
            else {
                const extractionResults = await this.orderDelineationService.getOrdersByExtraction(
                    this.selectedExtraction.id
                );

                if (extractionResults) {
                    this.ordersToExtract = extractionResults.values;
                    this.selectedExtraction.orders = this.ordersToExtract;
                    this.searchCount = extractionResults.getCount(ResponseCountKey.default);
                    this.populateGrid(this.ordersToExtract);
                }
            }
        }
    }

    onRouteToProfile(): (event: MouseEvent, index: number) => void {
        return (event: MouseEvent, index: number) => {
            const customerId = this.gridData[index].data.customerId;
            void this.router.navigate(["/accounts", customerId, "profile"]);
        }
    }

    async onWholesalerSelectionChange() {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        this.setSearchButton();
        this.isExtractDisabled = true;

        this.wholesalerService.selectedWholesaler = this.selectedWholesaler;

        this.extractionOptions = new Array<OrderExtractionItemViewmodel>();
        this.resetSelectedExtraction();
        this.onExtractionSelectionChange();

        if (this.selectedWholesaler) {
            this.setWholesalerRefiner();
            this.isSelectedExtractionDisabled = false;

            const extractionResults = await this.orderDelineationService.getExtractionsByWholesaler(this.selectedWholesaler.id);

            if (!extractionResults) {
                this.shouldWait$.next(true);
                return;
            }

            for (const extraction of extractionResults.values) {
                const extractionItem = new OrderExtractionItemViewmodel(extraction);

                const lastIndex = this.extractionOptions.length - 1;
                this.checkandFormatDuplicates(this.extractionOptions[lastIndex], extractionItem);

                this.extractionOptions.push(extractionItem);
            }

            const wholesalerResults = await this.orderDelineationService.getAvailableOrdersByWholesaler(
                this.selectedWholesaler.id,
                this.refinerService.refiners
            );

            if (wholesalerResults) {
                this.ordersToExtract = wholesalerResults.values;
                this.selectedExtraction.orders = this.ordersToExtract;
                this.searchCount = wholesalerResults.getCount(ResponseCountKey.default);
                this.populateGrid(this.ordersToExtract);
            }
            else {
                this.shouldWait$.next(true);
            }
        }
        else {
            const empty = new Array<Order>();
            this.populateGrid(empty);
        }
    }

    //public methods
    calculateGap(): void {
        if (this.sideNavContent) {
            setTimeout(() => {
                this.topGapDistance =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.top ?? 0;
                this.gridhieghtOffsetDeduction =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.bottom ??
                    0;
                if (
                    this.baseGridYOffset === 0 &&
                    this.gridhieghtOffsetDeduction !== 0
                ) {
                    this.baseGridYOffset = this.gridhieghtOffsetDeduction;
                }
                this.gridHeight =
                    75 * (this.screenHeight / 100) +
                    (this.baseGridYOffset -
                        this.gridhieghtOffsetDeduction) +
                    "px";
            }, 0);
            this.isGapSet = true;
        }
    }

    checkandFormatDuplicates(a: OrderExtractionItemViewmodel, b: OrderExtractionItemViewmodel) {
        if (a.dateExtracted && a.dateExtracted.valueOf() === b.dateExtracted.valueOf()
        ) {
            let duplicateCount = a.duplicateCount;
            a.extractionName = moment(a.dateExtracted).format("M/D/YY ha") + " - " + ++duplicateCount;
            b.duplicateCount = duplicateCount;
            b.extractionName = b.extractionName + " - " + ++duplicateCount;
        }
    }

    async reset(extractionChanged?: boolean): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        this.refinerService.resetRefiners();

        if (this.header) {
            if (this.refinerService.refiners.length === 0) {
                this.header.expanded = false;
            } else {
                this.header.expanded = true;
            }
        }
        this.accountInput = null;
        this.endDateInput = null;

        this.wholesalerSearchValue = "";
        this.selectedWholesaler = null;
        this.searchCount = 0;
        this.isSelectedExtractionDisabled = true;

        this.resetSelectedExtraction();

        const wholesalers = await this.wholesalerService.getWholesalers();
        this.wholesalers = wholesalers;
        this.buildWholesalers();

        if (!extractionChanged) {
            this.onWholesalerSelectionChange();
        }

        this.search();
        this.shouldWait$.next(true);
    }

    async extract(extraction: OrderExtractionItemViewmodel): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        this.isExtractDisabled = true;
        if (extraction.id === this.newExtraction) {
            if (this.refinerService.refiners.filter((v) => v.location !== "Wholesaler ").length > 0) {

                this.orderDelineationService.extractByParams(
                    this.selectedWholesaler.id,
                    this.refinerService.refiners
                ).pipe(
                    map(async (response: any) => {
                        if (response) {
                            const contentType: string = response["Content-Type"];
                            const blob = new Blob([response], { type: contentType });
                            saveAs(blob, `Order_Extraction_${(moment(new Date())).format("YYYY_MM_DD_hhmmss")}.xlsx`);
                            await this.refreshDropDowns();
                            this.shouldWait$.next(false);
                        }
                    })
                    , catchError((err: any) => {
                        this.shouldWait$.next(false);
                        return new Observable()
                    })
                ).subscribe();
            }
            else {
                this.orderDelineationService.extractByWholesaler(this.selectedWholesaler.id).pipe(
                    map(async (response: any) => {
                        if (response) {
                            const contentType: string = response["Content-Type"];
                            const blob = new Blob([response], { type: contentType });
                            saveAs(blob, `Order_Extraction_${(moment(new Date())).format("YYYY_MM_DD_hhmmss")}.xlsx`);
                            await this.refreshDropDowns();
                            this.shouldWait$.next(false);
                        }
                    })
                    , catchError((err: any) => {
                        this.shouldWait$.next(false);
                        return new Observable()
                    })
                ).subscribe();
            }

        }
        else {
            this.orderDelineationService.getExtractionFile(extraction.id).pipe(
                map(async (response: any) => {
                    if (response) {
                        const contentType: string = response["Content-Type"];
                        const blob = new Blob([response], { type: contentType });
                        saveAs(blob, `Order_Extraction_${(moment(new Date())).format("YYYY_MM_DD_hhmmss")}.xlsx`);
                        await this.refreshDropDowns();
                        this.shouldWait$.next(false);
                    }
                })
                , catchError((err: any) => {
                    this.shouldWait$.next(false);
                    return new Observable();
                })
            ).subscribe();
        }
    }

    headerExpansionChanged(): void {
        this.isGapSet = false;
    }

    async initialize(
        grid: GridComponent,
        header: PageHeaderComponent,
        sideNavContent: MatSidenavContent,
        drawer: MatSidenav,
        wholesalerSearch: ElementRef,
        wholesalerDropdown: MatSelect
    ): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        this.grid = grid;
        this.header = header;
        this.sideNavContent = sideNavContent;
        this.drawer = drawer;
        const wholesalers = await this.wholesalerService.getWholesalers();
        this.wholesalers = wholesalers;
        const excelExportButtonComponent = new ExcelExportButtonComponent();
        this.headerButtons.push(excelExportButtonComponent);
        this.wholesalerSearch = wholesalerSearch;
        this.wholesalerDropdown = wholesalerDropdown;

        this.resetSelectedExtraction();

        this.columnDef = [
            {
                headerName: "Call Number",
                dataPropertyName: "callNumber",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: "Call Date",
                dataPropertyName: "callDate",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: "Order Number",
                dataPropertyName: "id",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: "Order Date",
                dataPropertyName: "orderDate",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: "Chain",
                dataPropertyName: "chainName",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: "Store Information",
                dataPropertyName: "storeInformation",
                isAvailable: true,
                isSelected: true,
                clickFunction: this.onRouteToProfile()
            }
        ];
        const tempFilters: FilterAndParams[] = [];
        tempFilters.push({ filter: OrderExtractionFilterComponent });
        this.filters = tempFilters;

        const availableColumns = new Array<ColumnSelector>();

        this.columnsToDisplay = this.columnDef
            .filter((cd) => {
                if (cd.isAvailable) {
                    const column: ColumnSelector = {
                        name: cd.headerName,
                        isSelected: cd.isSelected,
                        columns: null
                    };
                    availableColumns.push(column);
                }
            })
            .map((cd) => cd.headerName);
        this.availableColumns = availableColumns;

        if (
            !this.refinerInputChangeSubscription ||
            this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription = this.refinerService.refinerInputChange$.subscribe(
                (refiner) => {
                    if (refiner) {
                        switch (refiner.location) {
                            case RefinerLocation.wholesaler:
                                if (!refiner.value) {
                                    this.wholesalerService.selectedWholesaler = null;
                                }
                                break;
                            case RefinerLocation.account:
                                this.accountInput = refiner.value;
                                break;
                            case RefinerLocation.orderOnOrBeforeDate:
                                this.endDateInput = refiner.value;
                                break;
                            case RefinerLocation.extraction:
                                if (!refiner.value) {
                                    this.resetSelectedExtraction();
                                } else {
                                    this.removeAdditionalRefiners();
                                }
                                this.setExtractionSelection();
                                break;
                            default:
                                break;
                        }
                        if (this.header) {
                            if (this.refinerService.refiners.length === 0) {
                                this.header.expanded = false;
                            } else {
                                this.header.expanded = true;
                            }
                        }
                        this.isGapSet = false;
                    }
                }
            );
        }

        if (
            !this.wholesalerSubscription ||
            this.wholesalerSubscription.closed
        ) {
            this.wholesalerSubscription = this.wholesalerService.observableWholesalers.subscribe(
                (wholesalers) => {
                    this.wholesalers = wholesalers;
                    this.buildWholesalers();
                }
            );
        }

        if (
            !this.selectedWholesalerSubscription ||
            this.selectedWholesalerSubscription.closed
        ) {
            this.selectedWholesalerSubscription = this.wholesalerService.observableSelectedWholesaler.subscribe(
                (wholesaler) => {
                    if (this.selectedWholesaler !== wholesaler) {
                        this.selectedWholesaler = wholesaler;
                        this.onWholesalerSelectionChange();
                        this.shouldWait$.next(true);
                    }
                }
            );
        }
    }

    // Override how material handles the space character
    onWholesalerKeyDown(event: any): void {
        if (event.key === ' ')
            event.stopPropagation()
    }

    onWholesalerSearchOpenChange(): void {
        if (!this.wholesalerDropdown.panelOpen) {
            if (this.wholesalerSearchSubscription && !this.wholesalerSearchSubscription.closed) {
                this.wholesalerSearchSubscription.unsubscribe();
            }
            this.wholesalerSearch.nativeElement.value = null;
            this.buildWholesalers();
            return;
        }

        this.wholesalerSearch.nativeElement.focus();
        this.wholesalerSearchValue = "";

        const searchValue = fromEvent(
            this.wholesalerSearch.nativeElement,
            "input"
        ).pipe(
            map(
                (e: InputEvent) => (e.target as HTMLInputElement).value
            ),
            debounceTime(500),
            distinctUntilChanged()
        );

        if (
            !this.wholesalerSearchSubscription ||
            this.wholesalerSearchSubscription.closed
        ) {
            this.wholesalerSearchSubscription = searchValue.subscribe(() => {
                this.buildWholesalers(this.wholesalerSearchValue);
            });
        }
    }

    populateGrid(orders: Order[]): void {
        if (!orders || orders?.length === 0) {
            orders = new Array<Order>();
            this.isExtractDisabled = true;
        }
        else {
            this.isExtractDisabled = false;
        }
        let gridData: GridData[] = [];

        for (const order of orders) {
            const gridItem: GridData = {
                data: new OrderExtractionGridViewmodel(order),
                detailArrayName: null,
                isExpanded: false,
                index: orders.indexOf(order)
            };
            gridData.push(gridItem);
        }
        this.gridData = gridData;

        this.dataSource = new TableVirtualScrollDataSource(
            this.gridData
        );

        this.shouldWait$.next(true);
    }

    resolvePageIndex(index: number): void {
        this.currentRefinerMap = new Map<RefinerLocation, string>();
        for (const refiner of this.refinerService.refiners ?? []) {
            this.currentRefinerMap.set(refiner.location, refiner.dataValue ?? refiner.value);
        }

        const rtn = SharedHelper.compareMaps(
            this.currentRefinerMap,
            this.previousRefinerMap
        );

        if (rtn && this.total != this.gridData?.length && index !== 0) {
            this.pageIndex++;
        } else {
            this.previousRefinerMap = new Map(this.currentRefinerMap);
            this.pageIndex = 0;
        }
    }

    async search(shouldAddInputs: boolean = true): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        if (shouldAddInputs) {
            this.onAddAccountRefiner();
            this.onAddOrderOnOrBeforeRefiner();
        }

        await this.onWholesalerSelectionChange();
    }

    showFilters(): void {
        this.drawer.toggle();
        this.isGapSet = false;
    }

    private setSearchButton(): void {
        if (this.selectedWholesaler && this.selectedExtraction && this.selectedExtraction.id == this.newExtraction) {
            this.isSearchDisabled = false;
            return;
        }
        this.drawer.close();
        this.isSearchDisabled = true;
    }

    private resetSelectedExtraction(): void {
        const newExtraction = new OrderExtractionItemViewmodel(new OrderExtraction());
        newExtraction.id = newExtraction.extractionName = this.newExtraction;

        this.extractionOptions.push(newExtraction);
        this.selectedExtraction = newExtraction;
    }

    private setWholesalerRefiner(): void {
        const wholesalerRefiner = new Refiner();
        wholesalerRefiner.location = RefinerLocation.wholesaler;
        wholesalerRefiner.value = this.selectedWholesaler.name;
        this.refinerService.checkAndUpdateRefiner(wholesalerRefiner);
    }

    private setExtractionRefiner(): void {
        const extractionRefiner = new Refiner();
        extractionRefiner.location = RefinerLocation.extraction;
        extractionRefiner.value = this.selectedExtraction.id == this.newExtraction
            ? null
            : this.selectedExtraction.extractionName;
        this.refinerService.checkAndUpdateRefiner(extractionRefiner);
    }

    private buildWholesalers(searchValue?: string): void {
        if (!searchValue) {
            this.wholesalersData = this.wholesalers;
        } else {
            searchValue = searchValue.trim().toLocaleLowerCase();
            const matches = new Array<Customer>();
            for(const w of this.wholesalers) {
                if (
                    (
                        SharedHelper.searchString(w.displayName, searchValue) ||
                        SharedHelper.searchString(w.customerNumber, searchValue)
                    ) && matches.filter((v) => v.id === w.id).length <= 0
                ) {
                    matches.push(w);
                }
            }
            this.wholesalersData = matches;
        }
    }

    private async refreshDropDowns(): Promise<void> {
        await this.onWholesalerSelectionChange();
        await this.onExtractionSelectionChange();
    }

    private removeAdditionalRefiners(): void {
        const staticRefinerLocations = [RefinerLocation.wholesaler, RefinerLocation.extraction];
        const currentRefinerLocations = this.refinerService.refiners.map(v => v.location);
        for (const location of currentRefinerLocations) {
            if (!staticRefinerLocations.includes(location)) {
                this.refinerService.removeRefinerByLocation(location);
            }
        }
    }
}
