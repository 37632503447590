import { AfterViewChecked, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/entity-models/project.entity';
import { EmployeeDelineationService } from 'src/app/services/delineation-services/employee-delineation.service';
import { ProjectStateService } from 'src/app/services/project-state-service';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { ProjectSummaryAsignmentsViewmodel } from './project-summary-assignments.viewmodel';

@Component({
  selector: 'app-project-summary-assignments',
  templateUrl: './project-summary-assignments.component.html',
  styleUrls: ['./project-summary-assignments.component.css']
})
export class ProjectSummaryAssignmentsComponent implements OnDestroy, AfterViewChecked {

    @ViewChild("grid") grid: GridComponent;

    readOnly: boolean;
    @Input()
    set project(value: Project) {
        this.readOnly = !!value;
        if (value) {
            this.projectStateService.project = value;
        }
    }

    viewmodel = new ProjectSummaryAsignmentsViewmodel(this.employeeDelineationService
        , this.projectStateService);
    isViewmodelReady = false;

    projectSubscription: Subscription;

    constructor(private employeeDelineationService: EmployeeDelineationService
        , public projectStateService: ProjectStateService) { }

    ngOnDestroy(): void {
        if (this.projectSubscription && !this.projectSubscription.closed) {
            this.projectSubscription.unsubscribe();
        }
    }

    async ngAfterViewChecked(): Promise<void> {
        if (!this.isViewmodelReady
            && this.grid
        ) {
            this.isViewmodelReady = true;
            await this.viewmodel.initialize(
                this.grid
            );

            if (!this.projectSubscription || this.projectSubscription.closed) {
                this.projectSubscription = this.projectStateService.observableProject.subscribe(async (project) => {
                    if (project) {
                        await this.viewmodel.buildAssignmentsFromEmployees();
                    }
                });
            }
        }
    }

}
