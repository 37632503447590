import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ProjectStep } from 'src/app/enums/project-step';
import { CustomerDelineationService } from 'src/app/services/delineation-services/customer-delineation.service';
import { ProductDelineationService } from 'src/app/services/delineation-services/product-delineation.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { PleaseWaitService } from 'src/app/services/please-wait.service';
import { ProjectStateService } from 'src/app/services/project-state-service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ProjectApplicationService } from '../../project-services/project-application.service';
import { ProjectProductsViewmodel } from './project-products.viewmodel';

@Component({
    selector: 'app-project-products',
    templateUrl: './project-products.component.html',
    styleUrls: ['./project-products.component.scss']
})
export class ProjectProductsComponent implements AfterViewChecked {

    @ViewChild("assignedSearch") assignedSearch: ElementRef;
    @ViewChild("unAssignedSearch") unAssignedSearch: ElementRef;
    @ViewChild('assignedViewport') assignedViewport: CdkVirtualScrollViewport;
    @ViewChild('unassignedViewport') unassignedViewport: CdkVirtualScrollViewport;

    faArrowUp: IconDefinition = faArrowUp;
    faArrowDown: IconDefinition = faArrowDown;
    isViewmodelReady = false;
    tabVisited = false;

    shouldWait$ = new BehaviorSubject<boolean>(true);
    viewmodel = new ProjectProductsViewmodel(this.snackbarService,
        this.pleaseWaitService,
        this.projectStateService,
        this.projectApplicationService,
        this.overlayService,
        this.formBuilder,
        this.customerDelineationService,
        this.activatedRoute,
        this.productDelineationService);
    assignedSearchValueSubscription: Subscription;
    unAssignedSearchValueSubscription: Subscription;
    currentStep = ProjectStep.products;

    constructor(private projectStateService: ProjectStateService
        , private snackbarService: SnackbarService
        , private pleaseWaitService: PleaseWaitService
        , public projectApplicationService: ProjectApplicationService
        , private overlayService: OverlayService
        , private formBuilder: FormBuilder
        , private customerDelineationService: CustomerDelineationService
        , private activatedRoute: ActivatedRoute
        , private productDelineationService: ProductDelineationService) { }

    ngAfterViewChecked(): void {
        if (this.projectApplicationService.selectedIndex !== ProjectStep.products) return;

        // products are needed to build the viewmodel, but may take a while to load
        // we need to show the spinner immediately, so we pull it up regardless of whether the products have loaded
        if (!this.tabVisited) {
            this.tabVisited = true;
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        }

        if (!this.isViewmodelReady
            && this.projectStateService.project
            && this.assignedViewport
            && this.unassignedViewport
            && this.projectApplicationService.products
        ) {
            this.isViewmodelReady = true;

            setTimeout(() => {
                void this.viewmodel.initialize(this.shouldWait$);

                setTimeout(() => {
                    if (this.unAssignedSearch) {
                        const searchValue = fromEvent(
                            this.unAssignedSearch.nativeElement,
                            "input"
                        ).pipe(
                            map(
                                (e: InputEvent) => (e.target as HTMLInputElement).value
                            ),
                            debounceTime(1000),
                            distinctUntilChanged()
                        );

                        if (
                            !this.unAssignedSearchValueSubscription ||
                            this.unAssignedSearchValueSubscription.closed
                        ) {
                            this.unAssignedSearchValueSubscription = searchValue.subscribe(() => {
                                this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
                                this.viewmodel.selectedCategory = "All"
                                this.viewmodel.filter();
                                this.viewmodel.setSelectAllUnAssigned();
                                this.viewmodel.setSelectAllAssigned();
                            });
                        }
                    }

                    if (this.assignedSearch) {
                        const searchValue = fromEvent(
                            this.assignedSearch.nativeElement,
                            "input"
                        ).pipe(
                            map(
                                (e: InputEvent) => (e.target as HTMLInputElement).value
                            ),
                            debounceTime(1000),
                            distinctUntilChanged()
                        );

                        if (
                            !this.assignedSearchValueSubscription ||
                            this.assignedSearchValueSubscription.closed
                        ) {
                            this.assignedSearchValueSubscription = searchValue.subscribe(() => {
                                this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
                                this.viewmodel.filter();
                            });
                        }
                    }
                }, 0);
            }, 0);
        }
    }

    ngOnDestroy(): void {
        if (this.unAssignedSearchValueSubscription && !this.unAssignedSearchValueSubscription.closed) {
            this.unAssignedSearchValueSubscription.unsubscribe();
        }
        if (this.assignedSearchValueSubscription && !this.assignedSearchValueSubscription.closed) {
            this.assignedSearchValueSubscription.unsubscribe();
        }

        this.viewmodel.unsubscribe();
    }

}
