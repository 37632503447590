export class StateLicenseDto {
    id: string;
    number: string;
    isActive: boolean;
    description: string | null
    subsidiaryId: number;
    subsidiaryName: string;
    stateId: string;
    stateName: string;
    stateShortCode: string;
    rowversion: string;
}
