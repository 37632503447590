<div #myFilter>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Store Types</mat-label>
            <mat-select #storeTypesSelect
                [(value)]="viewmodel.selectedCustomerTypes"
                (openedChange)="onOpenedStoreTypesChange()"
                (selectionChange)="onSelectionStoreTypesChange()"
                multiple>
                <mat-option *ngFor="let type of viewmodel.customerTypes" [value]="type">
                    {{ type.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Call Types</mat-label>
            <mat-select #callTypesSelect
                [(value)]="viewmodel.selectedCallTypes"
                (openedChange)="onOpenedCallTypesChange()"
                (selectionChange)="onSelectionCallTypesChange()"
                multiple>
                <mat-option *ngFor="let type of viewmodel.callTypes" [value]="type">
                    {{ type }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Picture Types</mat-label>
            <mat-select #pictureTypesSelect
                [(value)]="viewmodel.selectedPictureTypes"
                (openedChange)="onOpenedPictureTypesChange()"
                (selectionChange)="onSelectionPictureTypesChange()"
                multiple>
                <mat-option *ngFor="let type of viewmodel.pictureTypes" [value]="type">
                    {{ type }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label>Tags (separate by comma ",")</mat-label>
            <input #tagInput type="text"
                (keyup)="onAddTagRefiner($event)" (blur)="onAddTagRefiner()" [(ngModel)]="viewmodel.tagInput"
                matInput />
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Px3 Ranks</mat-label>
            <mat-select #px3RankSelect
                [(value)]="viewmodel.selectedPx3Ranks"
                (openedChange)="onOpenedSelectedPx3Change()"
                (selectionChange)="onSelectionPx3RanksChange()"
                multiple>
                <mat-option *ngFor="let rank of viewmodel.px3Ranks" [value]="rank">
                    {{ rank.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
