export class ProjectAssignmentCountFilterDto {
    // Usage: show employee assignment count by employee
    assigned?: boolean;

    // Usage: show customer assignment count by customer city
    city?: boolean;

    // Usage: show customer assignment count by customer county
    county?: boolean;

    // Usage: show customer assignment count by customer state
    state?: boolean;

    // Usage: show customer assignment count by customer zip
    zip?: boolean;

    // Usage: show customer assignment count by account ownership, or N/A if not a chain
    chain?: boolean;
}
