import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChangeOrderDateParamsDto, ChangeUinParamsDto, newSequentialId } from 'shield.shared';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogViewmodel } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel';
import { Customer } from 'src/app/entity-models/customer.entity';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';
import { OrderOnlineService } from 'src/app/services/online-services/order-online.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { OrderListLineItemViewModel } from '../orders-list/order-list-line-item.viewmodel';
import { WholesalerUinViewmodel } from './wholesaler-uin.viewmodel';
import { ProductDelineationService } from 'src/app/services/delineation-services/product-delineation.service';

@Component({
    selector: 'app-change-wholesaler-uin',
    templateUrl: './change-wholesaler-uin.component.html',
    styleUrls: ['./change-wholesaler-uin.component.scss']
})
export class ChangeWholesalerUinComponent {

    private _wholesaler: Customer
    @Input()
    get wholesaler(): Customer {
        return this._wholesaler;
    }
    set wholesaler(value: Customer) {
        this._wholesaler = value;
        if (value) {
            this.buildProductCatalog();
        }
    }

    private _orderLineItems: OrderListLineItemViewModel[];
    @Input()
    get orderLineItems(): OrderListLineItemViewModel[] {
        return this._orderLineItems;
    }
    set orderLineItems(value: OrderListLineItemViewModel[]) {
        this._orderLineItems = value;
        if (value) {
            this.buildOldUinViewmodels();
        }
    }

    @Output() wholesalerUinChange = new EventEmitter();

    oldUinViewmodels = new Array<WholesalerUinViewmodel>();
    selectedUin = new Array<WholesalerUinViewmodel>();
    selectedNewUin = new Array<WholesalerUinViewmodel>();
    wholesalerOrderCatalog = new Array<WholesalerUinViewmodel>();

    oldUinSettings = {
        singleSelection: true,
        showCheckbox: false,
        text: "Select Old UIN",
        enableCheckAll: false,
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "uin",
        searchBy: ["uin"],
        classes: "c-btn"
    }

    newUinSettings = {
        singleSelection: true,
        showCheckbox: false,
        text: "Select New UIN",
        enableCheckAll: false,
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "description",
        searchBy: ["description"],
        classes: "c-btn"
    }

    private modalRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    constructor(private productDelineationService: ProductDelineationService
        , private orderService: OrderOnlineService
        , private snackbarService: SnackbarService
        , private overlayService: OverlayService) { }

    buildOldUinViewmodels(): void {
        const vms = new Array<WholesalerUinViewmodel>();

        for (const item of this.orderLineItems) {

            if (!vms.find((existing) => existing.uin === item.uin)) {
                const vm = new WholesalerUinViewmodel();
                vm.id = newSequentialId();
                vm.uin = item.uin;
                vms.push(vm);
            }
        }
        this.oldUinViewmodels = vms;
    }

    async buildProductCatalog(): Promise<void> {
        const vms = new Array<WholesalerUinViewmodel>();

        const productViewmodelsResponse = await this.productDelineationService.getWholesalerProducts(this.wholesaler);
        if (!productViewmodelsResponse) { return; }
        const productViewmodels = productViewmodelsResponse.values;

        for(const item of productViewmodels) {
            if (!vms.find((existing) => existing.uin === item.uin)) {
                const vm = new WholesalerUinViewmodel();
                vm.id = newSequentialId();
                vm.uin = item.uin;
                vm.description = item.uin + " - " + item.description;
                vms.push(vm);
            }
        }
        this.wholesalerOrderCatalog = vms;
    }

    openSaveConfirmation(): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message = `Are you sure you want to update product UIN: ${this.selectedUin[0].uin} to ${this.selectedNewUin[0].uin} for all pending orders?`;
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => this.modalRef.close(data);
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.modalRef.close(data);
        }

        this.modalRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.modalRef.afterClosed$.subscribe((ref) => {
            if (ref?.data?.isConfirmed) {
                this.save();
            }
        })
    }

    async save(): Promise<void> {

        // make save call
        const params = new ChangeUinParamsDto();
        params.wholesalerId = this.wholesaler?.id;
        params.oldUin = this.selectedUin[0].uin;
        params.newUin = this.selectedNewUin[0].uin;
        const response = await this.orderService.changeWholesalerUin(params);

        if (response) {
            this.snackbarService.showInfo((response.affected ?? 0).toString() + " orders effected");
            this.selectedUin = [];
            this.selectedNewUin = [];
            // on Success, emit
            this.wholesalerUinChange.emit();
        }
    }
}
