export class MsaBrandDto {
    id: number;
    name: string | null;
    description: string | null;
    brandFamily: string | null;
    manufacturerName: string | null;
    brandCategoryName: string | null;
    segment: string | null;
    subSegment: string | null;
    isDeactivated: boolean;
    isDeleted: boolean;
}
