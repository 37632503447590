import { SurveyQuestion } from "src/app/entity-models/survey-question.entity";

export class ProjectSummaryActivitySurveyGridViewmodel {

    type: string;
    text: string;
    isRequired: string;

    constructor(question: SurveyQuestion) {
        if (question) {
            this.type = question.surveyQuestionType.description;
            this.text = question.text;
            this.isRequired = question.isRequired ? "Y" : "N";
        }
    }
}
