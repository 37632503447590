import { Injectable } from "@angular/core";
import { Employee } from "src/app/entity-models/employee.entity";
import { RegisteredUser } from "src/app/entity-models/registered-user-entity";
import { AppStateService } from "../app-state.service";
import { RegisteredUserDelineationService } from "../delineation-services/registered-user-delineation.service";
import { SyncService } from "../sync.service";

@Injectable()
export class SyncWatcherService {

    private employee: Employee;

    constructor(
        private registeredUserDelineationService: RegisteredUserDelineationService,
        private syncService: SyncService,
        private appStateService: AppStateService) {

        this.appStateService.currentEmployee.subscribe((employee) => {
            this.employee = employee;
        });

        this.syncService.observableSyncAttempt.subscribe(
            () => {
                this.syncAttempt();
            }
        );

        this.syncService.observablesuccessfulSyncCycle.subscribe(
            () => {
                this.successfullSyncCycle();
            }
        );
    }

    async syncAttempt(): Promise<void> {
        let registeredUser: RegisteredUser = null;
        if (this.employee) {
            const registeredUserResponse = await this.registeredUserDelineationService.getByEmployeeId(
                this.employee.id
            );
            if (registeredUserResponse) {
                registeredUser = registeredUserResponse.values;
                if (registeredUser){
                    registeredUser.lastSyncAttemptUtcDateTime = new Date();
                    await this.registeredUserDelineationService.autosaveRegisteredUser(
                        registeredUser
                    );
                }
            }
        }
    }

    async successfullSyncCycle(): Promise<void> {
        if (this.employee) {
            const registeredUserResponse = await this.registeredUserDelineationService.getByEmployeeId(
                this.employee.id
            );

            if (registeredUserResponse) {
                const registeredUser = registeredUserResponse.values;

                if (registeredUser) {
                    registeredUser.lastCompletedSyncCycleUtcDateTime = new Date();
                    await this.registeredUserDelineationService.autosaveRegisteredUser(
                        registeredUser
                    );
                }
            }
        }
    }
}
