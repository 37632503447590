import { AvailableGratisLineItemViewmodel } from "./available-gratis-line-item.viewmodel";

export class AvailableGratisViewModel {
    id!: string;
    customerId: string | null = null;
    callDate!: string;
    storeInformation: string | null = null;
    createdBy!: string;
    receiptNumber: string | null = null;
    salesTotal: number | null = null;
    netTotal: number | null = null;
    gratisTotal!: number;
    gratisPercent: number | null = null;
    gratisRequestNumber: string | null = null;
    callClosingNotes: string | null = null;
    availableGratisLineItems: AvailableGratisLineItemViewmodel[] = new Array<AvailableGratisLineItemViewmodel>();
}
