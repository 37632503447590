import { HttpClient } from "@angular/common/http";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncQueueService } from "../data-sync-queue.service";
import {
    CountryDto, CountrySyncCommand, GenericVersionResponseDto,
    SharedHelper, SyncCommandTypes, SystemInformationKeys,
    VersionResponseDto
} from "shield.shared";
import { Country } from "src/app/entity-models/country.entity";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { Injectable } from "@angular/core";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { SyncLevel } from "../sync-enums/sync-level.enum";


@Injectable()
export class CountryDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.Initial;
    offlineRepSyncType = SyncLevel.Initial;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.country;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing countries...");

        await this.pullData();

        this.log("Done syncing countries...");
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/countries/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private countryToCountryDto(c: Country): CountryDto {
        const rtn = new CountryDto();

        rtn.id = c.id;
        rtn.name = c.name;
        rtn.alpha2Code = c.alpha2Code;
        rtn.alpha3Code = c.alpha3Code;
        rtn.numericCode = c.numericCode;
        rtn.iSO31662Codes = c.iSO31662Codes;
        rtn.independent = c.independent;
        rtn.rowversion = c.rowversion;

        return rtn;
    }

    private countryDtoToCounty(dto: CountryDto): Country {
        return {
            ...dto
        };
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("Country data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Country data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.countryPullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;
        try {
            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;

                const response = await this.http
                    .get<GenericVersionResponseDto<CountryDto[]>>(`/api/countries${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                this.log(
                    `Downloaded ${thisBatchSize} countries, saving to IndexedDB...`
                );

                const countries = response.values.map((c) =>
                    this.countryDtoToCounty(c)
                );

                await this.dbService.countries.bulkPut(countries);
                this.log(`  Saved  ${countries.length} countries.`);

                this.lastSyncVersion = response.maxVersion;
            } while (thisBatchSize > 0);

            this.log("Done saving country data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing countries", e);
        }
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<CountrySyncCommand>(
            SyncCommandTypes.country,
            async (message) => {
                let country = await this.dbService.countries.get(
                    message.payload.id
                );

                let dto = this.countryToCountryDto(country);
                if (dto) {
                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }
}
