import { AfterViewChecked, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Project } from 'src/app/entity-models/project.entity';
import { ProjectStep } from 'src/app/enums/project-step';
import { CustomerDelineationService } from 'src/app/services/delineation-services/customer-delineation.service';
import { WholesalerGroupProductCatalogItemDelineationService } from 'src/app/services/delineation-services/wholesaler-group-product-catalog-item-delineation.service';
import { PleaseWaitService } from 'src/app/services/please-wait.service';
import { ProjectStateService } from 'src/app/services/project-state-service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { ProjectApplicationService } from '../../../project-services/project-application.service';
import { ProjectSummaryProductsViemodel } from './project-summary-products.viewmodel';

@Component({
    selector: 'app-project-summary-products',
    templateUrl: './project-summary-products.component.html',
    styleUrls: ['./project-summary-products.component.scss']
})
export class ProjectSummaryProductsComponent implements OnDestroy, AfterViewChecked {

    @ViewChild("grid") grid: GridComponent;

    viewmodel = new ProjectSummaryProductsViemodel(this.projectStateService,
        this.pleaseWaitService,
        this.projectApplicationService,
        this.wholesalerGroupProductCatalogItemDelineationService,
        this.customerDelineationService,
        this.snackbarService);

    isViewmodelReady = false;

    @Input() profileView = false;
    @Input() buildProducts = true;
    readOnly: boolean;
    @Input()
    set project(value: Project) {
        this.readOnly = !!value;
        if (value) {
            this.projectStateService.project = value;
            if (this.isViewmodelReady) {
                this.isViewmodelReady = false;
            }
        }
    }


    constructor(private projectApplicationService: ProjectApplicationService,
        private projectStateService: ProjectStateService,
        private pleaseWaitService: PleaseWaitService,
        private wholesalerGroupProductCatalogItemDelineationService: WholesalerGroupProductCatalogItemDelineationService,
        private customerDelineationService: CustomerDelineationService,
        private snackbarService: SnackbarService) { }

    async ngAfterViewChecked(): Promise<void> {
        if ((!this.isViewmodelReady)
            && this.grid
            && this.projectStateService.project
            && this.projectApplicationService.products) {
            this.isViewmodelReady = true;
            this.viewmodel.shouldWait$.next(false);
            await this.viewmodel.initialize(this.grid, this.buildProducts);
        }
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }

}
