export enum SyncVersionKeyNames {

    accountownership = "sync_last_accountownership_version",
    area = "sync_last_area_version",
    areaToZrt = "sync_last_area_to_zrt_version",
    callPicture = "sync_last_call_picture_version",
    callReceipt = "sync_last_call_receipt_version",
    contact = "sync_last_contact_version",
    contractTemplate = "sync_last_contract_template_version",
    country = "sync_last_country_version",
    county = "sync_last_county_version",
    customerContract = "sync_last_customer_contract_version",
    customerContractPayment = "sync_last_customer_contract_payment_version",
    customer = "sync_last_customer_version",
    customerByProject = "sync_last_customer_by_project_version",
    customerBySpecial = "sync_last_customer_by_special_version",
    dayTimeEntries = "sync_last_day_time_entry_version",
    employee = "sync_last_employee_version",
    notification = "sync_last_notification_version",
    product = "sync_last_product_version",
    project = "sync_last_project_version",
    registeredUser = "sync_last_registered_user_version",
    route = "sync_last_route_version",
    stateCategory = "sync_last_state_categories_version",
    state = "sync_last_state_version",
    stateLicense = "sync_last_state_license_version",
    survey = "sync_last_survey_version",
    systemInformation = "sync_last_systeminformation_version",
    taxRate = "sync_last_tax_rate_version",
    timeEntry = "sync_last_time_entry_version",
    timeEntryType = "sync_last_time_entry_type_version",
    wholesalerGroup = "sync_last_wholesaler_group_version",
    wholesalerGroupMember = "sync_last_wholesaler_group_member_version",
    wholesalerGroupProductCatalog = "sync_last_wholesaler_group_product_catalog_version",
    wholesalerProductCatalog = "sync_last_wholesaler_product_catalog_version",
    wholesaler = "sync_last_wholesaler_version",
    zrtAssignment = "sync_last_zrt_assignment_version",
    px3Rank = "sync_last_px3Rank_version",
    customerPx3Rank = "customerPx3Rank",
    px3IncentivePeriod = "px3IncentivePeriod"
}
