import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
//import { LogService } from "./log.service";
import {
    MsalGuardConfiguration,
    MsalService,
    MSAL_GUARD_CONFIG
} from "@azure/msal-angular";
import { EmployeeDto, SalespersonRole } from "shield.shared";
import { AccountInfo, RedirectRequest } from "@azure/msal-browser";
import { DatabaseService } from "./database.service";
import { Employee } from "../entity-models/employee.entity";
import { Helper } from "../helpers/helper";
import { PingService } from "./ping.service";
import { SyncService } from "./sync.service";
import { takeWhile } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class AppStateService {
    private _currentUser: BehaviorSubject<AccountInfo> = new BehaviorSubject<AccountInfo>(
        null
    );
    private _currentEmployee: BehaviorSubject<Employee> = new BehaviorSubject<Employee>(
        null
    );
    private employee: Employee;

    public readonly currentUser: Observable<AccountInfo> = this._currentUser.asObservable();
    public readonly currentEmployee: Observable<Employee> = this._currentEmployee.asObservable();

    public initialSycComplete = false;

    public appLoadError = false;
    public errorMessage = "";
    public errorCode = "";

    constructor(
        private http: HttpClient,
        @Inject(MSAL_GUARD_CONFIG)
        private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        //private log: LogService,
        private dbService: DatabaseService,
        private pingService: PingService,
    ) {
        this.pingService.online.pipe(takeWhile(() => !this.employee)).subscribe((isOnline) => {
            if (isOnline) {
                this.checkoutAccount();
            }
        });
    }

    async checkoutAccount(): Promise<boolean> {
        await this.authService.instance.handleRedirectPromise();

        Helper.verboseLog("Checking out account. Online statue: " + this.pingService.onlineCurrentStatus);
        const user = this.authService.instance.getAllAccounts()?.[0];
        this._currentUser.next(user);
        if (user) {
            await this.getEmployeeInfo(user);
            return true;
        }
        return false;
    }

    private employeeDtoToEmployee(dto: EmployeeDto): Employee {
        return {
            ...dto,
            createdUtcDateTime: dto.createdUtcDateTime
                ? new Date(dto.createdUtcDateTime)
                : null,
            modifiedUtcDateTime: dto.modifiedUtcDateTime
                ? new Date(dto.modifiedUtcDateTime)
                : null,
            user: null
        };
    }

    private async getEmployeeInfo(user: AccountInfo) {
        const email = user.username
        let rtn: Employee = null;
        try {


            const dexieEmployee = await this.dbService.employees
                .where("email")
                .equals(email)
                .toArray();

            let apiEmployee: Employee = null;
            if (this.pingService.onlineCurrentStatus) {
                const apiEmployeeDto = await this.http
                .get<EmployeeDto>(`/api/employees/search/email/${email}`)
                .toPromise<EmployeeDto>();
                apiEmployee = this.employeeDtoToEmployee(apiEmployeeDto);
            }

            rtn = apiEmployee ?? dexieEmployee[0];

            if (rtn) {
                this.setError(null);

                rtn.user = user;
                await this.setSearchableZrt(rtn);

                if (apiEmployee?.rowversion ?? "0" > (dexieEmployee[0]?.rowversion ?? "0")) {
                    await this.dbService.employees.put(rtn);
                }
            }

            this.employee = rtn;
            this._currentEmployee.next(rtn);

        } catch (e) {
            this.setError(e);
            const message = JSON.stringify(e);
            // this.log.error(
            //     `Error retrieving employee information for ${email}: ${message}`
            // );
            this._currentEmployee.next(null);
        }
    }

    private setError(e: HttpErrorResponse | null): void {
        if (!e) {
            this.appLoadError = false;
            this.errorCode = "";
            this.errorMessage = "";
            return;
        }

        this.appLoadError = true;
        this.errorCode = `${e.status ?? null}`;
        this.errorMessage = e.error?.message ?? e.message ?? "An unknown error occurred.";
    }

    private async setSearchableZrt(employee: Employee): Promise<void> {
        const registeredUser = await this.dbService.registeredUsers
            .where("employeeId")
            .equals(employee.id)
            .first();

        if (registeredUser && registeredUser.zrtOverride) {
            employee.zrt = registeredUser.zrtOverride;
        } else {
            employee.searchableZrt = employee.zrt;
        }

        const length: number = employee?.zrt?.length ?? 0;

        if (length > 2) {
            if (employee.zrt[length - 1] !== "0") {
                employee.salespersonRole = SalespersonRole.territoryManager;
                employee.searchableZrt = employee.zrt;
            } else if (employee.zrt[length - 2] !== "0") {
                employee.salespersonRole = SalespersonRole.regionalManager;
                employee.searchableZrt = employee.zrt.substring(0, length - 1);
            } else {
                employee.salespersonRole = SalespersonRole.zoneManager;
                employee.searchableZrt = employee.zrt.substring(0, length - 2);
            }
        }
    }

    async refreshAuthToken(): Promise<void> {
        console.log("Refresh starting");
        await this.authService.instance.acquireTokenSilent({
            scopes: [environment.auth.apiScope],
            account: this.authService.instance.getAccountByUsername(this.employee.email),
            forceRefresh: true,
        }).then(_ => {
            console.log("Refresh complete.");
            return Promise.resolve();
        }).catch(err => {
            console.log("Refresh failed.");
            console.log(JSON.stringify(err));
            return Promise.reject(err);
        });
    }

    async login(): Promise<void> {
        await this.authService.instance.handleRedirectPromise();

        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({
                ...this.msalGuardConfig.authRequest
            } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }

    logout(): void {
        this.authService.logout();
    }
}
