import { DailySummaryEntryDto } from "shield.shared";
import { DailySummaryEntry } from "src/app/entity-models/daily-summary-entry.entity";
import { Helper } from "src/app/helpers/helper";

export class DailySummaryEntryConverterService {
    static dailySummaryEntryDtoToDailySummaryEntry(
        dto: DailySummaryEntryDto
    ): DailySummaryEntry {
        const rtn = new DailySummaryEntry();

        rtn.dayTimeEntryId = dto.dayTimeEntryId;
        rtn.zrt = dto.zrt;
        rtn.name = dto.name;
        rtn.date = Helper.utcMidnightStringToLocalMidnightDate(dto.date);
        rtn.resetDay = dto.resetDay;
        rtn.totalCalls = dto.totalCalls;
        rtn.retailMinutes = dto.retailMinutes;
        rtn.wholesaleMinutes = dto.wholesaleMinutes;
        rtn.drivingMinutes = dto.drivingMinutes;
        rtn.otherMinutes = dto.otherMinutes;
        rtn.endOfDayComments = dto.endOfDayComments;
        rtn.workWiths = dto.workWiths;
        rtn.startDate = dto.startDate;
        rtn.endDate = dto.endDate

        return rtn;
    }
}
