import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericResponseDto, TimeEntryTypeDto, TimeEntryTypeNames } from "shield.shared";
import { TimeEntryType } from "src/app/entity-models/time-entry-type.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { TimeEntryTypeConverterService } from "../converter-services/time-entry-type-converter.service";

@Injectable()
export class TimeEntryTypeOnlineService {

    constructor(private http: HttpClient){}

    async getTimeEntryTypeByName(name: TimeEntryTypeNames): Promise<GenericResponseDto<TimeEntryType>> {


        const response = await this.http
            .get<GenericResponseDto<TimeEntryTypeDto>>(`/api/time-entry-types/get-by-name/${name.toString()}`)
            .toPromise();

        return ConverterHelper.dtoToEntity(response, (r) => TimeEntryTypeConverterService.timeEntryTypeDtoToTimeEntryType(r));
    }
}
