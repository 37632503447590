<mat-form-field *ngIf="viewmodel"  appearance="outline" color="accent" class="w-100">
    <mat-label>{{ displayName }}</mat-label>
    <mat-select #zrtSelect [(value)]="viewmodel.selectedZrtOption"
        (openedChange)="viewmodel.openedZrtChange()" multiple>
        <mat-form-field appearance="outline" color="accent" class="zrt-search-wrapper">
            <span class="zrt-search-icon" matPrefix><mat-icon>search</mat-icon></span>
            <input #zrtSearch matInput class="zrt-search"
                [(ngModel)]="viewmodel.zrtSearchValue"
                type="text"
                maxLength="50"
                (keydown)="viewmodel.onKeyDown($event)"
                placeholder="Search"/>
        </mat-form-field>
        <mat-tree [dataSource]="viewmodel.zrtData" [treeControl]="viewmodel.treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding
                matTreeNodePaddingIndent="20">
                <button mat-icon-button disabled></button>
                <mat-checkbox [checked]="viewmodel.isZrtSelected(node)"
                [disabled]="!node.enabled"
                (change)="viewmodel.updateChildZrt($event, node)">{{ node.displayValue }}</mat-checkbox>
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node; when: viewmodel.hasChild" matTreeNodePadding matTreeNodePaddingIndent="20">
                <button mat-icon-button matTreeNodeToggle>
                    <mat-icon>
                        {{ viewmodel.treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
                <mat-checkbox #checkbox class="checkbox-margin-node" [checked]="viewmodel.isZrtSelected(node)"
                    [disabled]="!node.enabled"
                    (change)="viewmodel.updateParentZrts($event, node)"
                    [indeterminate]="viewmodel.isIndeterminate(node)"></mat-checkbox>
                <mat-button matTreeNodeToggle [class]="{'checkbox-disabled': checkbox.disabled}">{{ node.displayValue }}</mat-button>
            </mat-tree-node>
        </mat-tree>
        <!-- This holds the display value... -->
        <mat-option [value]="viewmodel.selectedZrtOption[0]" *ngIf="!zrtSelect.panelOpen">{{ viewmodel.zrtDisplayValue }}</mat-option>
    </mat-select>
</mat-form-field>
