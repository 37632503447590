import { GenericLookup } from "../generic";

export enum OrderLineItemStatuses {
    Pending = 1,
    Waiting = 2,
    Accepted = 3,
    Rejected = 4,
    Processed = 5,
    Cancelled = 6,
    Removed = 7,
    Moved = 8
}

export const OrderLineItemStatusLookup: GenericLookup<OrderLineItemStatuses>[] = [
    { id: OrderLineItemStatuses.Pending, name: "Pending", description: "Pending" },
    { id: OrderLineItemStatuses.Waiting, name: "Waiting", description: "Waiting for Wholesaler" },
    { id: OrderLineItemStatuses.Accepted, name: "Accepted", description: "Accepted" },
    { id: OrderLineItemStatuses.Rejected, name: "Rejected", description: "Rejected" },
    { id: OrderLineItemStatuses.Processed, name: "Processed", description: "Processed" },
    { id: OrderLineItemStatuses.Cancelled, name: "Cancelled", description: "Cancelled" },
    { id: OrderLineItemStatuses.Removed, name: "Removed", description: "Removed" },
    { id: OrderLineItemStatuses.Moved, name: "Moved", description: "Moved" }
];
