import { KeyValue } from "@angular/common";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MsaBrand } from "src/app/entity-models/msa-brand.entity";
import { MsaDistributorVolumeResponse } from "src/app/entity-models/msa-distributor-volume-response.entity";
import { MsaDistributorVolume } from "src/app/entity-models/msa-distributor-volume.entity";
import { Helper } from "src/app/helpers/helper";
import { VolumeDelineationService } from "src/app/services/delineation-services/volume-delineation.service";

interface VolumeSummary {
    manufacturerTotals: VolumeSummaryManufacturerTotal[];
    total: number;
    swisherTotal: number;
}

interface ProductVolumeData {
    product: string;
    volume: number;
    isSwisher: boolean;
}

interface VolumeSummaryManufacturerTotal {
    name: string;
    value: number;
}

enum VolumeTimePeriod {
    volumeAt4Weeks,
    volumeAt13Weeks,
    volumeAt26Weeks,
    volumeAtCurrentYearToDate,
    volumeAtOneYearAgo
}

const timePeriodPropertyMap: Record<
    VolumeTimePeriod,
    keyof MsaDistributorVolume
> = {
    [VolumeTimePeriod.volumeAt4Weeks]: "volumeAt4Weeks",
    [VolumeTimePeriod.volumeAt13Weeks]: "volumeAt13Weeks",
    [VolumeTimePeriod.volumeAt26Weeks]: "volumeAt26Weeks",
    [VolumeTimePeriod.volumeAtCurrentYearToDate]: "volumeAtCurrentYearToDate",
    [VolumeTimePeriod.volumeAtOneYearAgo]: "volumeAtOneYearAgo"
};

enum VolumeDistance {
    oneMile = 1,
    fiveMiles = 5,
    tenMiles = 10
}

enum VolumeComparedTo {
    store = "Store",
    avgArea = "AvgArea"
}

@Injectable({
    providedIn: "root"
})
export class VolumeDataService {
    public volumes$ = new BehaviorSubject<ProductVolumeData[]>([]);
    public categories$ = new BehaviorSubject<string[]>([]);
    public summary$ = new BehaviorSubject<VolumeSummary>(null);

    public comparedToVolumes$ = new BehaviorSubject<ProductVolumeData[]>(null);
    public comparedToCategories$ = new BehaviorSubject<string[]>([]);
    public comparedToManufacturer$ = new BehaviorSubject<string[]>([]);

    public allCategory = "all";

    private response: MsaDistributorVolumeResponse;
    private responseBrandsById: {[id: number]: MsaBrand};
    public comparedToResponse: MsaDistributorVolumeResponse;

    // filter state values
    private _timePeriodFilter: VolumeTimePeriod =
        VolumeTimePeriod.volumeAt13Weeks;
    private _categoryFilter = this.allCategory;
    private _showAllFilter = true;

    // filter state public accessors
    public get timePeriodFilter() {
        return this._timePeriodFilter;
    }
    public get categoryFilter() {
        return this._categoryFilter;
    }
    public get showAllFilter() {
        return this._showAllFilter;
    }

    public comparedToDistance: VolumeDistance = VolumeDistance.oneMile;
    private _comparedToCategoryFilter = this.allCategory;
    private _comparedToManufacturerFilter = this.allCategory;
    private _comparedToTimePeriodFilter: VolumeTimePeriod =
        VolumeTimePeriod.volumeAt13Weeks;


    timePeriodOptions: Record<VolumeTimePeriod, string> = {
        [VolumeTimePeriod.volumeAt4Weeks]: "Last 4 Weeks",
        [VolumeTimePeriod.volumeAt13Weeks]: "Last 13 Weeks",
        [VolumeTimePeriod.volumeAt26Weeks]: "Last 26 Weeks",
        [VolumeTimePeriod.volumeAtCurrentYearToDate]: "Current Year To Date",
        [VolumeTimePeriod.volumeAtOneYearAgo]: "One Year Ago"
    };

    comparedToDistanceOptions: Record<number, string> = {
        [VolumeDistance.oneMile]: "1 Mile",
        [VolumeDistance.fiveMiles]: "5 Miles",
        [VolumeDistance.tenMiles]: "10 Miles"
    };

    comparedTo: VolumeComparedTo;
    store = VolumeComparedTo.store;
    avgArea = VolumeComparedTo.avgArea;

    distanceAdjustmentInProgress = true;

    private customerId: string;
    private _retail: boolean;

    constructor(private volumeDelineationService: VolumeDelineationService){}

    public async loadForCustomer(id: string, retail?: boolean): Promise<void> {
        this.unload();

        this.comparedTo = this.avgArea;
        this.customerId = id;

        const response = await this.volumeDelineationService.loadForCustomer(id);

        if (!response) {
            return;
        }

        this.response = response.values;
        this.responseBrandsById = Object.assign({}, ...this.response.brands.map(b => ({[b.id]: b})));

        this._retail = retail;
        if (retail) {
            const comparedToResponse = await this.volumeDelineationService.loadForCustomerByAvgArea(id, this.comparedToDistance as number);
            if (!comparedToResponse) { return; }
            this.comparedToResponse = comparedToResponse.values;
        }

        this.buildLookups();
        this.buildChart();
    }

    public unload(): void {
        this.volumes$.next([]);
        this.categories$.next([]);
        this.summary$.next(null);

        this.comparedToVolumes$.next(null);
        this.comparedToCategories$.next([]);
        this.comparedToManufacturer$.next([]);

        this.response = null;
        this.comparedToResponse = null;

        this._timePeriodFilter = VolumeTimePeriod.volumeAt13Weeks;
        this._categoryFilter = this.allCategory;
        this._showAllFilter = true;

        this.comparedToDistance = VolumeDistance.oneMile;
        this._comparedToCategoryFilter = this.allCategory;
        this._comparedToManufacturerFilter = this.allCategory;
        this._comparedToTimePeriodFilter = VolumeTimePeriod.volumeAt13Weeks;

        this.comparedTo = VolumeComparedTo.avgArea;
    }

    public setTimePeriodFilter(timePeriod: VolumeTimePeriod): void {
        this._timePeriodFilter = timePeriod;
        this.updateFilteredData();
    }

    public setCategoryFilter(category: string): void {
        this._categoryFilter = category;
        this.updateFilteredData();
    }

    public setShowAllFilter(showAll: boolean): void {
        this._showAllFilter = showAll;
        this.updateFilteredData();
    }

    public setComparedToTimePeriodFilter(timePeriod: VolumeTimePeriod): void {
        this._comparedToTimePeriodFilter = timePeriod;
        this.updateFilteredData();
    }

    public setComparedToCategoryFilter(category: string): void {
        this._comparedToCategoryFilter = category;
        this.updateFilteredData();
    }

    public setComparedToManufacturerFilter(manufacturer: string): void {
        this._comparedToManufacturerFilter = manufacturer;
        this.updateFilteredData();
    }

    public setComparedToRadioButton(event: VolumeComparedTo) {
        this.buildComparedTo(event === VolumeComparedTo.store);
        this.updateFilteredData(false);
    }

    public sortOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
        const aNumber = Number(a.key);
        const bNumber = Number(b.key);
        return aNumber > bNumber ? 1 : (bNumber > aNumber ? -1 : 0);
    }

    // store filters
    private brandMap: Map<number, MsaBrand>;
    private manufacturerBrands: Map<string, number[]>;
    private swisherBrands: number[];

    // compared to filters
    private comparedToBrandMap: Map<number, MsaBrand>;
    private comparedToManufacturerBrands: Map<string, number[]>;
    private comparedToSwisherBrands: number[];

    private buildLookups(): void {
        // build brands map
        this.brandMap = new Map(this.response.brands.sort((a) => a.manufacturerName.startsWith("Swisher")? -1 : 1).map((b) => [b.id, b]));

        // build categories list
        const categories = [
            ...new Set(this.response.brands.map((b) => b.brandCategoryName))
        ];
        categories.sort();

        this.categories$.next(categories);

        // build grouping of brands by manufacturer
        this.manufacturerBrands = Helper.groupByValue(
            this.response.brands,
            (b) => b.manufacturerName,
            (b) => b.id
        );

        // build list of swisher brands
        this.swisherBrands = [];
        Array.from(this.manufacturerBrands.keys())
            .filter((m) => m.startsWith("Swisher"))
            .forEach((cur) =>
                this.swisherBrands.push(...this.manufacturerBrands.get(cur))
            );
        if (this._retail) {
            this.buildComparedTo();
        }
        this.updateFilteredData();
    }

    private buildChart() {
        const timeProp = timePeriodPropertyMap[VolumeTimePeriod.volumeAt13Weeks];

        const excludedBrandCategories = new Set(['Tubes/Papers/Wraps/Cones']);
        const isExcludedBrand = (b: MsaBrand) => excludedBrandCategories.has(b?.brandCategoryName);
        const filteredVolumes = this.response.volumes.filter(
            v => !isExcludedBrand(this.responseBrandsById[v.msaBrandId])
        );

        const totalForBrands = (brandIds: number[]) =>
            filteredVolumes
                .filter((v) => brandIds.includes(v.msaBrandId))
                .reduce((acc, cur) => acc + (cur[timeProp] as number), 0);

        const summary: VolumeSummary = {
            manufacturerTotals: [],
            total: 0,
            swisherTotal: 0
        };

        const totals = new Array<VolumeSummaryManufacturerTotal>();
        this.manufacturerBrands.forEach((brandIds, manufacturer) => {
            const total = totalForBrands(brandIds);
            if (total !== 0) {
                totals.push({
                    name: manufacturer,
                    value: total
                });
                summary.total += total;
                if (manufacturer.startsWith("Swisher")) {
                    summary.swisherTotal += total;
                }
            }
        });
        const swisherTotals = totals.filter((t) => t.name.startsWith("Swisher"));
        let sortedTotals = totals
            .filter((t) => !t.name.startsWith("Swisher"))
            .sort((a, b) => a.value <= b.value ? 1 : -1);
        const aggregateTotals = new Array<VolumeSummaryManufacturerTotal>();
        for (const entry in sortedTotals) {
            if (parseInt(entry) < 4) {
                aggregateTotals.push(sortedTotals[entry]);
            } else {
                sortedTotals = sortedTotals.filter((t) => !aggregateTotals.includes(t));
                aggregateTotals.push({
                    name: "Other",
                    value: sortedTotals.map((t) => t.value).reduce((prev, next) => (prev + next)) ?? 0
                });
                break;
            }
        }
        summary.manufacturerTotals = [...swisherTotals, ...aggregateTotals];
        this.summary$.next(summary);
    }

    private buildComparedTo(store?: boolean) {
        const brands = store ? this.response.brands : this.comparedToResponse.brands;

        // build brands map
        this.comparedToBrandMap = new Map(brands.sort((a) => a.manufacturerName.startsWith("Swisher")? -1 : 1).map((b) => [b.id, b]));

        // build categories list
        const categories = [
            ...new Set(brands.map((b) => b.brandCategoryName))
        ];
        categories.sort();

        this.comparedToCategories$.next(categories);

        // build grouping of brands by manufacturer
        this.comparedToManufacturerBrands = Helper.groupByValue(
            brands,
            (b) => b.manufacturerName,
            (b) => b.id
        );

        // build list of swisher brands
        this.comparedToSwisherBrands = [];
        const manufacturerBrands = Array.from(this.comparedToManufacturerBrands.keys());
        manufacturerBrands
            .filter((m) => m.startsWith("Swisher"))
            .forEach((cur) =>
                this.comparedToSwisherBrands.push(...this.comparedToManufacturerBrands.get(cur))
        );

        manufacturerBrands.sort();
        this.comparedToManufacturer$.next(manufacturerBrands);
    }

    public async updateDistanceAverages(distance: VolumeDistance) {
        this.distanceAdjustmentInProgress = true;

        this.comparedToDistance = distance;
        this.comparedToVolumes$.next(null);

        const comparedToResponse = await this.volumeDelineationService.loadForCustomerByAvgArea(this.customerId, this.comparedToDistance as number);
        if (!comparedToResponse) { return; }
        this.comparedToResponse = comparedToResponse.values;

        this.buildComparedTo();
        this.updateFilteredData(false);
    }

    public updateFilteredData(buildStoreVolumes: boolean = true) {

        if (buildStoreVolumes) {
            const volumes = this.filterVolumes(
                this.response.volumes,
                this._timePeriodFilter,
                this._categoryFilter,
                this.allCategory,
                this.response,
                this.brandMap,
                this.swisherBrands,
                this.showAllFilter
            );
            this.volumes$.next(volumes);
        }

        if (this._retail) {
            const comparedToResponseToUse = this.comparedTo === VolumeComparedTo.store
                ? this.response
                : this.comparedToResponse;
            const comparedToVolumes = this.filterVolumes(
                comparedToResponseToUse.volumes,
                this._comparedToTimePeriodFilter,
                this._comparedToCategoryFilter,
                this._comparedToManufacturerFilter,
                comparedToResponseToUse,
                this.comparedToBrandMap,
                this.comparedToSwisherBrands,
                true
            );
            this.comparedToVolumes$.next(comparedToVolumes);
        }

        this.distanceAdjustmentInProgress = false;
    }

    private filterVolumes(
        filteredVolumes: MsaDistributorVolume[],
        timePeriod: VolumeTimePeriod,
        categoryFilter: string,
        manufacturerFilter: string,
        response: MsaDistributorVolumeResponse,
        brandMap: Map<number, MsaBrand>,
        swisherBrands: number[],
        showAll: boolean
    ): ProductVolumeData[] {
        const timeProp = timePeriodPropertyMap[timePeriod];

        if (categoryFilter !== this.allCategory) {
            const categoryBrandIds = response.brands
                .filter((b) => b.brandCategoryName === categoryFilter)
                .map((b) => b.id);
            filteredVolumes = filteredVolumes.filter((v) =>
                categoryBrandIds.includes(v.msaBrandId)
            );
        }

        if (manufacturerFilter !== this.allCategory) {
            const manufacturerBrandIds = response.brands
                .filter((b) => b.manufacturerName === manufacturerFilter)
                .map((b) => b.id);
            filteredVolumes = filteredVolumes.filter((v) =>
                manufacturerBrandIds.includes(v.msaBrandId)
            );
        }

        const volumes = filteredVolumes
            .map<ProductVolumeData>((v) => ({
                product: brandMap.get(v.msaBrandId)?.description,
                volume: v[timeProp] as number,
                isSwisher: swisherBrands.includes(v.msaBrandId)
            }))
            .filter((v) => v.volume !== 0)
            .filter((v) => v.isSwisher || showAll);

        return volumes.sort((a, b) => b.volume - a.volume);
    }
}
