<div class="row">
    <div class="col-lg-7 col-sm-12">
        <div class="card grid-item card--contract card--contract-double mb-3">
            <div class="card-header d-flex justify-content-between align-items-center">
                <div class="mr-auto">Contract</div>
                    <button appThrottleClick (throttledClick)="viewmodel.email()" class="btn sw-transparent-btn btn-md">
                        <span><fa-icon [icon]="viewmodel.faEnvelope"></fa-icon></span>
                    </button>
                    <button appThrottleClick (throttledClick)="viewmodel.print()" class="btn sw-transparent-btn btn-md">
                        <span><fa-icon [icon]="viewmodel.faPrint"></fa-icon></span>
                    </button>

            </div>
            <div #agreement class="card-body p-0">
                <div class="card-view-port">
                    <app-retail-incentive-agreement
                        [contract]="viewmodel.customerContract.contractTemplate"
                        [retailer]="viewmodel.retailer">
                    </app-retail-incentive-agreement>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-5 col-sm-12">
        <div class="card grid-item card--payment mb-3">
            <div class="card-header text-left">Payment History</div>
            <div class="card-body p-0">
                <div class="card-view-port">
                    <table class="table table-striped mb-1">
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Payment Date</th>
                                <th>Payment Amount</th>
                                <th>Customer Representative</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let entry of viewmodel.paymentVms">
                                <td>{{ entry.status }}</td>
                                <td>{{ entry.paymentDate }}</td>
                                <td>
                                    <ng-container *ngIf="entry.paymentAmount">{{ entry.paymentAmount | currency }}</ng-container>
                                    <ng-container *ngIf="!entry.paymentAmount">-</ng-container>
                                </td>
                                <td>{{ entry.rep }}</td>
                                <td>
                                    <div class="d-flex">
                                        <ng-container *ngIf="entry.status !== 'Signed'">
                                            <a class="single-icon-on-white mr-1"
                                                appThrottleClick (throttledClick)="viewmodel.openPaymentStatus(entry.contractPayment, true)">
                                                <span class="material-icons">visibility</span>
                                            </a>
                                            <ng-container *ngIf="!viewmodel.completedOrCancelled && entry.isEditable">
                                                <a class="single-icon-on-white"
                                                    appThrottleClick (throttledClick)="viewmodel.openPaymentStatus(entry.contractPayment)">
                                                    <span class="material-icons">edit</span>
                                                </a>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />
                    <div class="row mt-3">
                        <div class="col-6">
                            <p><b>Total Payment Received: {{ (viewmodel.paymentTotal ? viewmodel.paymentTotal : 0) | currency }}</b></p>
                        </div>
                        <div class="col-6 text-right">
                            <ng-container *ngIf="!viewmodel.completedOrCancelled">
                                <a class="btn btn-primary btn-sm mb-3 mr-3"
                                    appThrottleClick (throttledClick)="viewmodel.openPaymentStatus()">New Payment/Status</a>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
