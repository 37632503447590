<div class="card m-0 h-100">
    <div class="card-header">
        Volume
    </div>
    <div class="card-body" [ngClass]="{ 'd-flex flex-row': showComparison }">
        <div [ngClass]="{ 'store-container': showComparison }">
            <h2 class="text-teal border-bottom border-teal">Store</h2>
            <mat-form-field appearance="outline" color="accent" class="w-100">
                <mat-label>
                    Time Period
                </mat-label>
                <mat-select (selectionChange)="volumeData.setTimePeriodFilter($event.value)" value="1">
                    <mat-option *ngFor="let opt of volumeData.timePeriodOptions| keyvalue" [value]="opt.key">
                        {{opt.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" color="accent" class="w-100 mb-2">
                <mat-label>
                    MSA Category
                </mat-label>
                <mat-select (selectionChange)="volumeData.setCategoryFilter($event.value)" [value]="volumeData.allCategory">
                    <mat-option [value]="volumeData.allCategory">All</mat-option>
                    <mat-option *ngFor="let cat of volumeData.categories$ | async" [value]="cat">{{cat}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-slide-toggle (change)="volumeData.setShowAllFilter($event.checked)" [checked]="volumeData.showAllFilter">Show All</mat-slide-toggle>
            <div class="fixed-table-container">
                <table class="table table-striped table-hover mb-0 mt-4">
                    <thead>
                    <tr>
                        <th scope="col" class="is-being-filtered">
                            Rank
                            <fa-icon [icon]="faArrowUp"></fa-icon>
                        </th>
                        <th scope="col">Product</th>
                        <th scope="col">Volume</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let vol of volumeData.volumes$ | async; let ix=index;" [class.m-red]="vol.isSwisher">
                        <td>{{ix+1}}</td>
                        <td>{{vol.product}}</td>
                        <td>{{vol.volume | number}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div *ngIf="showComparison" class="border-right p-0 mx-3"></div>
        <div *ngIf="showComparison">
            <div
                class="margin-teal border-bottom border-teal d-flex justify-content-between align-items-center pb-0">
                <h2 class="text-teal pb-0 mb-0">Compared To</h2>
                <mat-radio-group class="pb-0" aria-label="Select an option" [(ngModel)]="volumeData.comparedTo"
                                 (change)="volumeData.setComparedToRadioButton($event.value)"
                                 [disabled]="volumeData.distanceAdjustmentInProgress">
                    <mat-radio-button class="mr-5" [value]="volumeData.store">Store</mat-radio-button>
                    <mat-radio-button [value]="volumeData.avgArea">Avg Area</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="d-flex flex-row">
                <div class="mr-2">
                    <mat-form-field appearance="outline" color="accent" class="w-100">
                        <mat-label>
                            Time Period
                        </mat-label>
                        <mat-select (selectionChange)="volumeData.setComparedToTimePeriodFilter($event.value)" value="1"
                                    [disabled]="volumeData.distanceAdjustmentInProgress">
                            <mat-option *ngFor="let opt of volumeData.timePeriodOptions| keyvalue" [value]="opt.key">
                                {{opt.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" color="accent" class="w-100 mb-2">
                        <mat-label>
                            MSA Category
                        </mat-label>
                        <mat-select (selectionChange)="volumeData.setComparedToCategoryFilter($event.value)" [value]="volumeData.allCategory"
                                    [disabled]="volumeData.distanceAdjustmentInProgress">
                            <mat-option [value]="volumeData.allCategory">All</mat-option>
                            <mat-option *ngFor="let cat of volumeData.comparedToCategories$ | async" [value]="cat">{{cat}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline" color="accent" class="w-100">
                        <mat-label>
                            Manufacturer
                        </mat-label>
                        <mat-select (selectionChange)="volumeData.setComparedToManufacturerFilter($event.value)" [value]="volumeData.allCategory"
                                    [disabled]="volumeData.distanceAdjustmentInProgress">
                            <mat-option [value]="volumeData.allCategory">All</mat-option>
                            <mat-option *ngFor="let cat of volumeData.comparedToManufacturer$ | async" [value]="cat">{{cat}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" color="accent" class="w-100 mb-2">
                        <mat-label>
                            Distance
                        </mat-label>
                        <mat-select (selectionChange)="volumeData.updateDistanceAverages($event.value)" value="1"
                                    [disabled]="volumeData.comparedTo != volumeData.avgArea || volumeData.distanceAdjustmentInProgress">
                            <mat-option *ngFor="let opt of volumeData.comparedToDistanceOptions | keyvalue: volumeData.sortOrder" [value]="opt.key">
                                {{ opt.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <p>Store Count:
                <ng-container *ngIf="volumeData.comparedToVolumes$ | async; else calculating">
                    {{ (volumeData.comparedTo == volumeData.store ? 1 : volumeData.comparedToResponse?.storeCount ? volumeData.comparedToResponse.storeCount : 0) | number }}
                </ng-container>
                <ng-template #calculating>
                    (calculating volume...)
                </ng-template>
            </p>
            <div class="fixed-table-container">
                <table class="table table-striped table-hover mb-0 mt-4">
                    <thead>
                    <tr>
                        <th scope="col" class="is-being-filtered">
                            Rank
                            <fa-icon [icon]="faArrowUp"></fa-icon>
                        </th>
                        <th scope="col">Product</th>
                        <th scope="col">Volume</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let vol of volumeData.comparedToVolumes$ | async; let ix=index;" [class.m-red]="vol.isSwisher">
                        <td>{{ix+1}}</td>
                        <td>{{vol.product}}</td>
                        <td>{{vol.volume | number}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

