import { GenericLookup } from "../generic";

export enum SurveyTypes {
    Survey = 1,
    Activity = 2
}

export const SurveyTypeLookup: GenericLookup<SurveyTypes>[] = [
    { id: SurveyTypes.Survey, name: "Survey", description: "Survey" },
    { id: SurveyTypes.Activity, name: "Activity", description: "Activity" }
];
