export class CreateModifyBaseDto {
    createdUserId: string;
    createdUserZrt: string;
    createdUtcDateTime: string | null;
    createdUserFullName: string;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUtcDateTime: string | null;
    modifiedUserFullName: string;
}
