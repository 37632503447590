import { GenericLookup } from "../generic";

export enum TransactionLineItemType {
    CashSale = 1,
    ExchangeOut,
    ExchangeIn,
    Order,
    Gratis
}

export const TransactionLineItemTypeLookup: GenericLookup<TransactionLineItemType>[] = [
    { id: TransactionLineItemType.CashSale, name: "Cash", description: "Cash" },
    { id: TransactionLineItemType.ExchangeOut, name: "Exchange Out", description: "Exchange Out" },
    { id: TransactionLineItemType.ExchangeIn, name: "Exchange In", description: "Exchange In" },
    { id: TransactionLineItemType.Order, name: "Order", description: "Order" },
    { id: TransactionLineItemType.Gratis, name: "Gratis", description: "Gratis" }
];


