import { Injectable } from "@angular/core";

@Injectable()
export class ZoneRecapOfflineService {
    readonly errorMessage = "You must be online to access Zone Recap."

    async offlineError(key: any): Promise<undefined> {
        throw Error(this.errorMessage);
    }
}
