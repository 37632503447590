import { Component, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { VolumeDataService } from "../volume-data.service";

@Component({
    selector: "app-chain-hq-volume",
    templateUrl: "./chain-hq-volume.component.html",
    styleUrls: ["./chain-hq-volume.component.scss"]
})
export class ChainHqVolumeComponent implements OnInit, OnDestroy {

    shouldWait$ = new BehaviorSubject<boolean>(false);

    constructor(
        public volumeData: VolumeDataService,
        private customerStateService: CustomerStateService,
        private pleaseWaitService: PleaseWaitService
    ) {}

    async ngOnInit(): Promise<void> {

        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        await this.volumeData.loadForCustomer(
            this.customerStateService.customer.id
        );

        this.shouldWait$.next(false);
    }

    ngOnDestroy(): void {
        if (this.volumeData) {
            this.volumeData.unload();
            this.volumeData = null;
        }
    }
}
