import { AfterContentChecked, Component, OnDestroy, Type, ViewChild } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterService } from "src/app/services/filter.service";
import { CountyViewmodel } from "../../viewmodels/county.viewmodel";
import { StateViewmodel } from "../../viewmodels/state.viewmodel";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { CustomerLocationViewModel } from "./customer-location.viewmodel";
import { RefinerLocation, valueSeparator } from "shield.shared";
import { ZrtTreeViewComponent } from "../../zrt-tree-view/zrt-tree-view.component";

@Component({
    selector: "app-customer-location-filter",
    templateUrl: "./customer-location-filter.component.html",
    styleUrls: ["./customer-location-filter.component.scss"]
})
export class CustomerLocationFilterComponent extends FilterBaseComponent implements AfterContentChecked, OnDestroy {
    //view childs
    @ViewChild("stateSelect") stateSelect: MatSelect;
    @ViewChild("zrtTree") zrtTree: ZrtTreeViewComponent;

    //public vars
    contentComponent: Type<CustomerLocationFilterComponent>;
    icon = "location_on";
    name = "Location";

    viewmodel = new CustomerLocationViewModel(this.filterService);
    firstRun = true;

    constructor(private filterService: FilterService) {
        super();
    }

    async ngAfterContentChecked(): Promise<void> {
        if (!this.isInitialized && this.zrtTree && this.zrtFilterService && this.firstRun) {
            this.firstRun = false;
            this.zrtTree.zrtFilterService = this.zrtFilterService;
            await this.viewmodel.initializeLocationDropDowns();
            this.setInitialized();
            this.refinerService.refiners.forEach((refiner) => this.onInputChange(refiner));
            if (!this.viewmodel.areaSubscription || this.viewmodel.areaSubscription.closed) {
                this.viewmodel.areaSubscription = this.zrtFilterService.observableByArea.subscribe((value) => {
                    this.viewmodel.byArea = value;
                });
            }
        }
    }

    ngOnDestroy(): void {
        if (this.viewmodel.areaSubscription && !this.viewmodel.areaSubscription.closed) {
            this.viewmodel.areaSubscription.unsubscribe();
        }
    }

    //events
    onAddAccountRefiner(event?: KeyboardEvent) {
        this._refinerService.onInputChange(
            RefinerLocation.account,
            this.viewmodel.storeNameCustomerId
        );
        if (
            this.viewmodel.storeNameCustomerId &&
            (!event || this.inputSubmitKeys.includes(event.key))
        ) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.account;
            refiner.value = this.viewmodel.storeNameCustomerId;
            refiner.dataValue = this._refinerService.buildStringDataValue(this.viewmodel.storeNameCustomerId);
            refiner.dataPropertyName = "name;externalSyncId";
            this._refinerService.addRefiners(refiner);
        }
    }

    onAddCityRefiner(event?: KeyboardEvent): void {
        this._refinerService.onInputChange(
            RefinerLocation.city,
            this.viewmodel.cityInput
        );
        if (
            this.viewmodel.cityInput &&
            (!event || this.inputSubmitKeys.includes(event.key))
        ) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.city;
            refiner.value = this.viewmodel.cityInput;
            refiner.dataValue = this._refinerService.buildStringDataValue(this.viewmodel.cityInput);
            refiner.dataPropertyName = "businessAddress.city";
            this._refinerService.addRefiners(refiner);
        }
    }

    onAreaChange(value: boolean): void {
        this.zrtFilterService.byArea = this.viewmodel.byArea = value;
    }

    onInputChange(refiner: Refiner): void {
        if (this.isInitialized) {
            if (refiner) {
                switch (refiner.location) {
                    case RefinerLocation.account:
                        if (refiner.value !== this.viewmodel.storeNameCustomerId) {
                            this.viewmodel.storeNameCustomerId = refiner.value;
                        }
                        break;
                    case RefinerLocation.street:
                        if (refiner.value !== this.viewmodel.streetInput) {
                            this.viewmodel.streetInput = refiner.value;
                        }
                        break;
                    case RefinerLocation.city:
                        if (refiner.value !== this.viewmodel.cityInput) {
                            this.viewmodel.cityInput = refiner.value;
                        }
                        break;
                    case RefinerLocation.states: {
                        const selectedStates = refiner.value?.split(", ");
                        const rtnSelectedStates = new Array<StateViewmodel>();

                        if (selectedStates?.length) {
                            for (const state of selectedStates) {
                                const found = this.viewmodel.filteredStates.find(
                                    (ss) => ss.state.shortCode === state.trim()
                                );
                                if (found) {
                                    rtnSelectedStates.push(found);
                                }
                            }
                        }
                        this.viewmodel.selectedStates = rtnSelectedStates;

                        break;
                    }
                    case RefinerLocation.zipCodes:
                        if (refiner.value !== this.viewmodel.zipInput) {
                            this.viewmodel.zipInput = refiner.value;
                        }
                        break;
                    case RefinerLocation.counties: {
                        const selectedCounties = refiner.dataValue?.split(valueSeparator);
                        const rtnSelectedCounties = new Array<CountyViewmodel>();

                        if (selectedCounties) {
                            for (const county of selectedCounties) {
                                const foundCounty = this.viewmodel.counties.find(
                                    (sc) => sc.id === county.trim()
                                );
                                const foundState = this.viewmodel.states.find(
                                    (sc) => sc.id === foundCounty?.stateId?.trim()
                                );
                                if (foundCounty && foundState) {
                                    rtnSelectedCounties.push(new CountyViewmodel(foundCounty, foundState));
                                }
                            }
                        }
                        this.viewmodel.selectedCounties = rtnSelectedCounties;
                        break;
                    }
                    default:
                        break;
                }

                if (!refiner.value)
                this._refinerService.removeRefinerByLocation(refiner.location, true, false);
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.account;
        const accountRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.account
        );
        if (accountRefiner) {
            this.onInputChange(accountRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.street;
        const streetRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.street
        );
        if (streetRefiner) {
            this.onInputChange(streetRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.states;
        const stateRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.states
        );
        if (stateRefiner) {
            this.onInputChange(stateRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.counties;
        const countyRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.counties
        );
        if (countyRefiner) {
            this.onInputChange(countyRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.city;
        const cityRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.city
        );
        if (cityRefiner) {
            this.onInputChange(cityRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.zipCodes;
        const zipRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.zipCodes
        );
        if (zipRefiner) {
            this.onInputChange(zipRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }

    onAddStreetRefiner(event?: KeyboardEvent) {
        this._refinerService.onInputChange(
            RefinerLocation.street,
            this.viewmodel.streetInput
        );
        if (
            this.viewmodel.streetInput &&
            (!event || this.inputSubmitKeys.includes(event.key))
        ) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.street;
            refiner.value = this.viewmodel.streetInput;
            refiner.dataValue = this._refinerService.buildStringDataValue(this.viewmodel.streetInput);
            refiner.dataPropertyName = "businessAddress.address1";
            this._refinerService.addRefiners(refiner);
        }
    }

    onAddZipRefiner(event?: KeyboardEvent): void {
        this._refinerService.onInputChange(
            RefinerLocation.zipCodes,
            this.viewmodel.zipInput
        );
        if (
            this.viewmodel.zipInput &&
            (!event || this.inputSubmitKeys.includes(event.key))
        ) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.zipCodes;
            refiner.value = this.viewmodel.zipInput;
            refiner.dataValue = this._refinerService.buildStringDataValue(this.viewmodel.zipInput);
            refiner.dataPropertyName = "businessAddress.zip";
            this._refinerService.addRefiners(refiner);
        }
    }

    //public methods
    compareCountyOptions(a: CountyViewmodel, b: CountyViewmodel): boolean {
        return (
            a?.county?.name === b?.county?.name
            && a?.county?.stateId === b?.county.stateId
        )
    }

    compareRefinerOptions(a: Refiner, b: Refiner): boolean {
        return (
            a?.dataPropertyName === b?.dataPropertyName &&
            a?.location === b?.location &&
            a?.value === b?.value &&
            a?.dataValue === b?.dataValue
        );
    }

    countyChange(): void {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.counties;

        if (this.viewmodel.selectedCounties?.length > 1) {
            const counties = this.viewmodel.selectedCounties;
            refiner.value = counties[0].county.name.concat(", +", (counties.length - 1).toString(), " more" );

        } else {
            refiner.value = this.viewmodel.selectedCounties
            .map((vm) => vm.county.name)
            .join(", ");
        }

        refiner.dataPropertyName = "county";
        refiner.dataValue = this.viewmodel.selectedCounties
        .map((vm) => vm.county.id)
        .join(valueSeparator);

        this._refinerService.checkAndUpdateRefiner(refiner, true);
    }

    openedStateChange(): void {
        if (this.stateSelect && !this.stateSelect.panelOpen) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.states;
            refiner.value = this.viewmodel.selectedStates
                .map((vm) => vm.state.shortCode)
                .join(", ");
            refiner.dataPropertyName = "state";
            refiner.dataValue = this.viewmodel.selectedStates
                .map((vm) => vm.state.id)
                .join(valueSeparator);

            this._refinerService.checkAndUpdateRefiner(refiner, true);

            const remainingSelectedCounties = this.viewmodel.adjustCountySelectOptions();

            if (remainingSelectedCounties?.length > 0 ) {
                const refinerUpdate = new Refiner();
                refinerUpdate.location = RefinerLocation.counties;
                refinerUpdate.value = remainingSelectedCounties.map((rsc) => rsc.county.name).join(", ");
                refinerUpdate.dataValue = remainingSelectedCounties.map((rsc) => rsc.county.id).join(", ");
                this._refinerService.updateRefinerValue(refinerUpdate)

            } else {
                this._refinerService.removeRefinerByLocation(RefinerLocation.counties);
            }
        }
    }
}
