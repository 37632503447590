import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ContactDto, GenericResponseDto, newSequentialId, SharedHelper } from "shield.shared";
import { Contact } from "../../entity-models/contact.entity";
import { ContactConverterService } from "../converter-services/contact-converter.service";
import { ConverterHelper } from "../converter-services/converter.helper";

@Injectable()
export class ContactOnlineService {

    constructor(private http: HttpClient) {}

    async getById(id: string): Promise<GenericResponseDto<Contact>> {

        const result = await this.http
            .get<GenericResponseDto<ContactDto>>(`/api/contacts/${id}`).toPromise();

        return ConverterHelper.dtoToEntity<ContactDto, Contact>(result, (r) => ContactConverterService.contactDtoToContact(r));
    }

    async getByCustomerId(id: string): Promise<GenericResponseDto<Contact[]>> {
        const rtn = new GenericResponseDto<Contact[]>();

        const result = await this.http
            .get<GenericResponseDto<ContactDto[]>>(`/api/contacts/customer-id/${id}`).toPromise();

        return ConverterHelper.dtoToEntityArray<ContactDto, Contact>(result, (r) => ContactConverterService.contactDtoToContact(r));
    }

    async save(contact: Contact): Promise<GenericResponseDto<Contact>> {
        const request = new GenericResponseDto<ContactDto>();
        request.id = contact.id;
        request.values = ContactConverterService.contactToContactDto(contact);

        const result = await this.http
            .post<GenericResponseDto<ContactDto>>(`/api/contacts/save`, request).toPromise();

        return ConverterHelper.dtoToEntity<ContactDto, Contact>(result, (r) => ContactConverterService.contactDtoToContact(r));
    }
}
