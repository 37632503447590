import { GenericLookup } from "../generic";

export enum AccountOwnershipHierarchyLevel {
    Owner = 1,
    Immediate = 2,
    Ultimate = 3
}

export const AccountOwnershipHierarchyLevelLookup: GenericLookup<AccountOwnershipHierarchyLevel>[] = [
    { id: AccountOwnershipHierarchyLevel.Owner, name: "Owner", description: "Owner" },
    { id: AccountOwnershipHierarchyLevel.Immediate, name: "Immediate", description: "Immediate" },
    { id: AccountOwnershipHierarchyLevel.Ultimate, name: "Ultimate", description: "Ultimate" }
  ];
