import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    DayTimeEntryDto,
    GenericRequestDto,
    GenericResponseDto,
    GenericVersionResponseDto,
    newSequentialId,
    TimeEntryDto,
    TimeEntryParamsDto,
    TimeEntryTypeDto,
} from "shield.shared";
import { DayTimeEntry } from "src/app/entity-models/day-time-entry.entity";
import { TimeEntryType } from "src/app/entity-models/time-entry-type.entity";
import { TimeEntry } from "src/app/entity-models/time-entry.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { DayTimeEntryConverterService } from "../converter-services/day-time-entry.converter.service";
import { TimeEntryConverterService } from "../converter-services/time-entry-converter.service";
import { TimeEntryTypeConverterService } from "../converter-services/time-entry-type-converter.service";
import { DatabaseService } from "../database.service";

@Injectable()
export class TimeEntryOnlineService {
    constructor(private http: HttpClient, private dbService: DatabaseService) {}

    async getById(id: string): Promise<GenericResponseDto<TimeEntry>> {
        const response = await this.http
            .get<GenericResponseDto<TimeEntryDto>>(`/api/time-entries/${id}`)
            .toPromise();

        return ConverterHelper.dtoToEntity<TimeEntryDto, TimeEntry>(
            response,
            (r) => TimeEntryConverterService.timeEntryDtoToTimeEntry(r)
        );
    }

    async getByEmployeeIdAndDate(
        params: TimeEntryParamsDto
    ): Promise<GenericResponseDto<TimeEntry[]>> {
        const response = await this.http
            .get<GenericResponseDto<TimeEntryDto[]>>(
                `/api/time-entries/employee-id/${params.employeeId}?month=${params.month}&year=${params.year}`
            )
            .toPromise();

        return ConverterHelper.dtoToEntityArray<TimeEntryDto, TimeEntry>(
            response,
            (r) => TimeEntryConverterService.timeEntryDtoToTimeEntry(r)
        );
    }

    async getDayTimeEntriesByEmployeeIdAndDate(
        params: TimeEntryParamsDto
    ): Promise<GenericResponseDto<DayTimeEntry[]>> {
        const response = await this.http
            .get<GenericResponseDto<DayTimeEntryDto[]>>(
                `/api/day-time-entries/employee-id/${params.employeeId}?month=${params.month}&year=${params.year}`
            )
            .toPromise();

        return ConverterHelper.dtoToEntityArray<DayTimeEntryDto, DayTimeEntry>(
            response,
            (r) => DayTimeEntryConverterService.dayTimeEntryDtoToDayTimeEntry(r)
        );
    }

    async getTimeEntryTypes(): Promise<GenericResponseDto<TimeEntryType[]>> {
        const response = await this.http
            .get<GenericVersionResponseDto<TimeEntryTypeDto[]>>(
                `/api/time-entry-types`
            )
            .toPromise();

        const result = ConverterHelper.genericVersionResponseDtoToGenericResponseDto(
            response
        );
        return ConverterHelper.dtoToEntityArray<
            TimeEntryTypeDto,
            TimeEntryType
        >(result, (r) =>
            TimeEntryTypeConverterService.timeEntryTypeDtoToTimeEntryType(r)
        );
    }

    async upsertDayTimeEntry(
        entry: DayTimeEntry
    ): Promise<GenericResponseDto<DayTimeEntry | undefined>> {
        const request = new GenericRequestDto<DayTimeEntryDto>();
        request.id = newSequentialId();
        request.values = DayTimeEntryConverterService.dayTimeEntryToDayTimeEntryDto(
            entry
        );

        await this.dbService.dayTimeEntries.put(entry);
        const response = await this.http
            .post<GenericResponseDto<DayTimeEntryDto>>(
                `/api/day-time-entries/save`,
                request
            )
            .toPromise();

        return ConverterHelper.dtoToEntity(response, (r) =>
        DayTimeEntryConverterService.dayTimeEntryDtoToDayTimeEntry(r)
        );
    }
}
