import { DayTimeEntryWorkWithDto } from "./day-time-entry-work-with.dto";

export class DayTimeEntryDto {
    id: string;
    key: string;
    date: string | null;
    employeeId: string;
    isCompleted: boolean;
    mileage: number;
    resetDay: boolean;
    endOfDayComment: string;
    createdUtcDateTime: string | null;
    createdUserId: string | null;
    createdUserZrt: string | null;
    createdUserFullName: string | null;
    modifiedUtcDateTime: string | null;
    modifiedUserId: string | null;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string | null;
    dayTimeEntryWorkWithDtos : DayTimeEntryWorkWithDto [];
    rowversion: string;
}
