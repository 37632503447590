import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRequestDto, GenericResponseDto, MsaDistributorVolumeResponseDto, newSequentialId, VolumeParamsDto } from "shield.shared";
import { MsaDistributorVolumeResponse } from "src/app/entity-models/msa-distributor-volume-response.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { VolumeConverterService } from "../converter-services/volume-converter-service";

@Injectable()
export class VolumeOnlineService {

    constructor(private http: HttpClient) { }

    async getByCustomerId(id: string): Promise<GenericResponseDto<MsaDistributorVolumeResponse>> {

        const response = await this.http
            .get<GenericResponseDto<MsaDistributorVolumeResponseDto>>(
                `/api/distributor-volumes/${id}`
            )
            .toPromise();

        return ConverterHelper.dtoToEntity<MsaDistributorVolumeResponseDto, MsaDistributorVolumeResponse>(response,
            (r) => VolumeConverterService.msaDistributorVolumeResponseDtoToMsaDistributorVolumeResponse(r));
    }

    async getAvgByCustomerAndDistance(params: VolumeParamsDto): Promise<GenericResponseDto<MsaDistributorVolumeResponse>> {
        const request = new GenericRequestDto<VolumeParamsDto>();
        request.id = newSequentialId();
        request.values = params;

        const response = await this.http
            .post<GenericResponseDto<MsaDistributorVolumeResponseDto>>(
                `/api/distributor-volumes/avg-area`, request
            )
            .toPromise();

        return ConverterHelper.dtoToEntity<MsaDistributorVolumeResponseDto, MsaDistributorVolumeResponse>(response,
            (r) => VolumeConverterService.msaDistributorVolumeResponseDtoToMsaDistributorVolumeResponse(r));
    }
}
