import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Moment } from 'moment';
import { BehaviorSubject, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerPx3RankImportDto, Px3IncentivePeriodDto } from 'shield.shared';
import { Px3IncentivePeriod } from 'src/app/entity-models/px3-incentive-period.entity';
import { Px3DelineationService } from 'src/app/services/delineation-services/px3-delineation.service';
import { PleaseWaitService } from 'src/app/services/please-wait.service';

@Component({
    selector: 'px3-import',
    templateUrl: './px3-import.component.html',
    styleUrls: ['./px3-import.component.scss']
})
export class Px3ImportComponent implements OnInit {

    readonly Object = Object;
    importForm = this.builder.group({
        dates: this.builder.group({
            startDate: [null, [(c: AbstractControl) => this.validateStartDate(c), Validators.required]],
            endDate: [null, [(c: AbstractControl) => this.validateEndDate(c), Validators.required]],
        }),
        incentivePeriodId: [null],
        fileInput: [null, Validators.required],
    });

    incentivePeriods = new BehaviorSubject<Px3IncentivePeriod[]>([]);
    errors?: any;
    @ViewChild('fileInput') fileInput: ElementRef;
    @ViewChild('downloadElement') downloadElement: ElementRef;

    constructor(
        private px3Service: Px3DelineationService,
        private builder: FormBuilder,
        private http: HttpClient,
        private pleaseWait: PleaseWaitService,
    ) { }

    ngOnInit(): void {
        from(this.px3Service.getCurrentAndUpcomingPeriods())
            .pipe(
                map(periods => [null].concat(periods)),
            )
            .subscribe(periods => this.incentivePeriods.next(periods));
    }

    hasIncentivePeriod(): boolean {
        return this.importForm.get("incentivePeriodId").value != null;
    }

    incentivePeriodChange(evt: MatSelectChange) {
        if (evt.value == null) {
            this.importForm.get("dates").enable();
        } else {
            this.importForm.get("dates").disable();
        }
    }

    downloadSampleFile() {
        this.http.get("/assets/files/Px3Upload.xlsx", { responseType: "blob" }).subscribe(b => {
            var reader = new FileReader();
            //Setting this as the href tag and clicking it will allow us to assign the file a name.
            reader.onload = e => {
                this.downloadElement.nativeElement.href = e.target.result as string;
                this.downloadElement.nativeElement.click();
            };
            reader.readAsDataURL(b);
        })
    }

    async save() {
        this.importForm.markAllAsTouched();
        if (this.importForm.valid) {
            const loaded = new BehaviorSubject(false);
            this.pleaseWait.showProgressSpinnerUntilLoaded(loaded);
            const input = this.importForm.value;
            const request = new CustomerPx3RankImportDto();
            request.incentivePeriod = new Px3IncentivePeriodDto();
            if (input.incentivePeriodId) {
                request.incentivePeriod.id = input.incentivePeriodId;
            } else {
                request.incentivePeriod.startDate = input.dates.startDate;
                request.incentivePeriod.endDate = input.dates.endDate;
            }

            const reader = new FileReader();
            request.fileBase64 = await new Promise((resolve, reject) => {
                reader.onload = (e) => resolve(btoa(e.target.result as string));
                reader.onerror = (e) => reject(e);

                reader.readAsBinaryString(this.fileInput.nativeElement.files[0]);
            });


            try {
                const result = await this.px3Service.bulkImport(request);
                if (result instanceof HttpErrorResponse) {
                    this.errors = result.error.exception.data;
                } else {
                    this.errors = null;
                    alert(`Successfully saved ${result} entries.`);
                    this.importForm.reset();
                }
            } finally {
                loaded.next(true);
            }
        }
    }

    validateStartDate(control: AbstractControl): ValidationErrors | null {
        if (!this.importForm) return null;
        const endDate: Moment | null = this.importForm.value.dates?.endDate;
        if (endDate && (control.value as Moment)?.isAfter(endDate)) {
            return { afterEnd: true };
        }
        return null;
    }

    validateEndDate(control: AbstractControl): ValidationErrors | null {
        if (!this.importForm) return null;
        const startDate: Moment | null = this.importForm.value.dates?.startDate;
        if (startDate && (control.value as Moment)?.isBefore(startDate)) {
            return { beforeStart: true };
        }
        return null;
    }
}
