import { ElementRef } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidatorFn
} from "@angular/forms";
import {
    MatDrawerMode,
    MatSidenav,
    MatSidenavContent
} from "@angular/material/sidenav";
import * as moment from "moment";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { ColumnSelector } from "src/app/shared/viewmodels/column-selector.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import {
    DailySummaryColumns,
    FilterSortDto,
    newSequentialId,
    RefinerLocation,
    SharedHelper,
    SortDirection} from "shield.shared";
import { Moment } from "moment";
import { BehaviorSubject, Subscription } from "rxjs";
import { map, skip, skipWhile } from "rxjs/operators";
import { SnackbarService } from "src/app/services/snackbar.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { AppStateService } from "src/app/services/app-state.service";
import { DailySummaryReportRefinerService } from "./daily-summary-report-refiner.service";
import { Employee } from "src/app/entity-models/employee.entity";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { SummaryDateInformationFilterComponent } from "src/app/shared/filters/summary-date-information-filter/summary-date-information-filter.component";
import { MatSortHeader } from "@angular/material/sort";
import { Helper } from "src/app/helpers/helper";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { MySearchesFilterComponent } from "src/app/shared/filters/my-searches/my-searches-filter.component";
import { ColumnVisabilityButtonComponent } from "src/app/shared/page-header/buttons/column-visability-button/column-visability-button.component";
import { PrintButtonComponent } from "src/app/shared/page-header/buttons/print-button/print-button.component";
import { ExcelExportButtonComponent } from "src/app/shared/page-header/buttons/excel-export-button/excel-export-button.component";
import { ButtonActions } from "src/app/shared/enums/button-actions.enum";
import { DailySummaryReportGridItemViewModel } from "./daily-summary-report-grid-item.viewmodel";
import { FilterLocation } from "src/app/enums/filter-location";
import { DailySummaryReportZrtFilterService } from "./daily-summary-report-zrt-filter.service";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { ZrtTreeViewComponent } from "src/app/shared/zrt-tree-view/zrt-tree-view.component";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { OverlayService } from "src/app/services/overlay.service";
import { MyListCommands } from "src/app/enums/my-list-commands";
import { DailySummaryAttributesFilterComponent } from "src/app/shared/filters/daily-summary-attributes-filter/daily-summary-attributes-filter.component";
import { DailySummaryEntryDelineationService } from "src/app/services/delineation-services/daily-summary-entry-delineation.service";

export class DailySummaryReportViewmodel {
    //private vars
    private formBuilder: FormBuilder;

    //public vars
    activeRefiners: Refiner[] = [];
    activeSorts: FilterSortDto<DailySummaryColumns>[];
    availableColumns: ColumnSelector[];
    baseGridYOffset = 0;
    columnDef: ColumnDef[];
    columnsToDisplay: string[];
    confirmationOverlayRef: SwisherOverlayRef<ConfirmationDialogViewmodel, ConfirmationDialogComponent>;
    currentRefinerMap: Map<RefinerLocation, string> = new Map();
    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    dateForm: FormGroup;
    dateFormat: string = MY_DATE_FORMATS.display.dateInput;
    detailHeight = 48;
    displayedColumns: string[] = [];
    drawer: MatSidenav;
    drawerMode: MatDrawerMode = "side";
    endDateDefault: moment.Moment = moment();
    endDateInput: ElementRef;
    endMaxDate: moment.Moment = moment().add(3, "years");
    endMinDate: moment.Moment = moment().subtract(3, "years");
    employee: Employee;
    employeeDelineationService: EmployeeDelineationService;
    employeeSubscription: Subscription;
    filterDataLoaded = false;
    filterLocation = FilterLocation.dailySummary;
    filters: FilterAndParams[] = [];
    filterService: FilterService;
    grid: GridComponent;
    gridData = new Array<GridData>();
    gridDataChangeSubscription: Subscription;
    gridHeight = "75vh";
    gridHeightOffsetDeduction = 0;
    header: PageHeaderComponent;
    headerButtons: HeaderButtonComponent[] = new Array<HeaderButtonComponent>();
    headerName = "Daily Summary Report";
    isEnabledExpandedDetails = false;
    isFixedLayout = false;
    isGapSet = false;
    isInitialBatchCompleted = false;
    isSearchButtonDisabled = false;
    itemsRendedInViewPort = 13;
    itemSize = 48;
    myListSubscription: Subscription;
    overlayService: OverlayService;
    pageIndex = 0;
    pageSize = 50; // buffer size of the grid. We probally need to make the min and output buffers of the grid virtual scroll an input.
    pleaseWaitService: PleaseWaitService;
    previousRefinerMap: Map<RefinerLocation, string> = new Map();
    refinerInputChangeSubscription: Subscription;
    refinerService: DailySummaryReportRefinerService;
    refinerServiceSubscription: Subscription;
    screenHeight: number = 0;
    selectedColumnVisibilityCommunicator: string[];
    shouldResetGrid = false;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    sideNavContent: MatSidenavContent;
    snackbar: SnackbarService;
    startDateDefault: moment.Moment = moment().subtract(7, "days");
    startDateInput: ElementRef;
    startMaxDate: moment.Moment = moment().add(3, "years");
    startMinDate: moment.Moment = moment().subtract(3, "years");
    dailySummaryEntryDelineationService: DailySummaryEntryDelineationService;
    topGapDistance = 0;
    total?: number;
    zrtFilterService: DailySummaryReportZrtFilterService;
    zrtSelectionSubscription: Subscription;

    readonly sortFunction = (columnDef: ColumnDef) => {
        if (this.grid) {
            const filterSorts = new Array<FilterSortDto<DailySummaryColumns>>();
            this.grid.sort.sortables.forEach((item) => {
                let sortDirection: SortDirection;
                switch ((item as MatSortHeader)._sort.direction) {
                    case "asc":
                        sortDirection = SortDirection.ascending;
                        break;
                    case "desc":
                        sortDirection = SortDirection.descending;
                        break;
                    default:
                        sortDirection = SortDirection.none;
                        break;
                }
                const sortDto = new FilterSortDto<DailySummaryColumns>();
                sortDto.direction = sortDirection;
                const headerName = this.columnDef.find(
                    (cd) => cd.dataPropertyName === item.id
                ).headerName;
                if (
                    headerName === columnDef.headerName &&
                    sortDirection != SortDirection.none
                ) {
                    sortDto.column = headerName as DailySummaryColumns;
                    filterSorts.push(sortDto);
                }
            });
            this.activeSorts = filterSorts;

            void this.getBatch(0, true);
        }
    }

    constructor(
        refinerService: DailySummaryReportRefinerService,
        formBuilder: FormBuilder,
        snackbar: SnackbarService,
        pleaseWaitService: PleaseWaitService,
        appStateService: AppStateService,
        employeeDelineationService: EmployeeDelineationService,
        filterService: FilterService,
        zrtFilterService: DailySummaryReportZrtFilterService,
        dailySummaryEntryDelineationService: DailySummaryEntryDelineationService,
        overlayService: OverlayService
    ) {
        this.refinerService = refinerService;
        this.snackbar = snackbar;
        this.pleaseWaitService = pleaseWaitService;
        this.employeeDelineationService = employeeDelineationService;
        this.filterService = filterService;
        this.zrtFilterService = zrtFilterService;
        this.dailySummaryEntryDelineationService = dailySummaryEntryDelineationService;
        this.overlayService = overlayService;

        this.formBuilder = formBuilder;
        this.dateForm = this.formBuilder.group({
            endDate: ["", [this.lessThanStartDate()]],
            startDate: ["", [this.greaterThanEndDate()]]
        });

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.employeeSubscription = appStateService.currentEmployee.subscribe(async (employee) => {
                this.employee = employee;
                await this.setFilterData();
                this.getBatch(0);
            });
        }
    }

    //events
    onButtonAction(value: any): void {
        switch (value) {
            case ButtonActions.columnVisibility:
                this.selectedColumnVisibilityCommunicator = value;
                break;
            case ButtonActions.print:
                this.grid.onPrint();
                break;
            case ButtonActions.exportToExcel:
                this.grid.onExportAsExcel();
                break;
            default:
                break;
        }
    }

    onButtonClickEvent(value: HeaderButtonComponent) {
        if (value.onClick) {
            value.onClick();
        }
    }

    onAddEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.endDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, this.dateFormat) &&
            !this.dateForm.controls.endDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.endDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.endDate,
                this.endDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.endDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.endDate,
                    this.dateForm.controls.endDate.value?.format(
                        this.dateFormat
                    ),
                    "endDate"
                );
            }
        } else {
            if (!date && event?.type === "blur") {
                this.refinerService.removeRefinerByLocation(
                    RefinerLocation.endDate,
                    true,
                    false
                );
                this.dateForm.controls.endDate.setValue(null);
            }
        }
    }

    onAddStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.startDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, this.dateFormat) &&
            !this.dateForm.controls.startDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.startDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.startDate,
                this.startDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.startDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.startDate,
                    this.dateForm.controls.startDate.value?.format(
                        this.dateFormat
                    ),
                    "startDate"
                );
            }
        } else {
            if (!date && event?.type === "blur") {
                this.refinerService.removeRefinerByLocation(
                    RefinerLocation.startDate,
                    true,
                    false
                );
                this.dateForm.controls.startDate.setValue(null);
            }
        }
    }

    onRefinersChange() {
        this.setRefinerInput();

        if (this.header) {
            if (this.refinerService.refiners.length === 0) {
                this.header.expanded = false;
            } else {
                this.header.expanded = true;
            }
        }

        // Search on removal
        const shouldAddInputs = false;
        this.search(shouldAddInputs);
    }

    onSetColumnVisibilityCommunicator(event: string[]) {
        this.selectedColumnVisibilityCommunicator = event;
    }

    //public methods
    calculateGap(): void {
        if (this.sideNavContent) {
            setTimeout(() => {
                this.topGapDistance =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.top ?? 0;
                this.gridHeightOffsetDeduction =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.bottom ??
                    0;
                if (
                    this.baseGridYOffset === 0 &&
                    this.gridHeightOffsetDeduction !== 0
                ) {
                    this.baseGridYOffset = this.gridHeightOffsetDeduction;
                }
                this.gridHeight =
                    75 * (this.screenHeight / 100) +
                    (this.baseGridYOffset - this.gridHeightOffsetDeduction) +
                    "px";
            }, 0);
            this.isGapSet = true;
        }
    }

    reset(): void {
        this.refinerService.resetRefiners();
    }

    async getBatch(
        index: number,
        force?: boolean
    ): Promise<void> {
        if (!this.refinerService.areDefaultsSet) {
            return;
        }

        const areRefinersTheSame = this.resolvePageIndex(index);

        if (
            this.total != this.gridData?.length ||
            !areRefinersTheSame ||
            this.shouldResetGrid ||
            force
        ) {
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

            setTimeout(async () => { // wait one angular cycle to ensure myFilters have reset.
                const id = newSequentialId();

                const results = await this.dailySummaryEntryDelineationService.getDailySummaryBatch(
                    id,
                    this.refinerService.refiners,
                    force ? 0 : this.pageSize,
                    this.pageIndex * this.pageSize,
                    this.activeSorts
                );

                if (!results) {
                    this.shouldResetGrid = true;
                    this.shouldWait$.next(false);
                    return;
                }

                this.total = results.count;
                this.isGapSet = false;

                let gridData: GridData[] = this.gridData.slice();
                if (this.pageIndex === 0) {
                    gridData = new Array<GridData>();
                }
                const largestIndex = gridData.length;

                for (const item of results?.values ?? []) {
                    const gridItem: GridData = {
                        data: new DailySummaryReportGridItemViewModel(item),
                        detailArrayName: "",
                        isExpanded: false,
                        index: largestIndex + results.values.indexOf(item)
                    };
                    gridData.push(gridItem);
                }
                this.gridData = gridData;

                this.dataSource = new TableVirtualScrollDataSource(
                    this.gridData
                );
                this.shouldResetGrid = false;
                this.isInitialBatchCompleted = true;
            }, 0);
        }
    }

    headerExpansionChanged(): void {
        this.isGapSet = false;
    }

    async initialize(
        grid: GridComponent,
        header: PageHeaderComponent,
        sideNavContent: MatSidenavContent,
        drawer: MatSidenav,
        startDateInput: ElementRef,
        endDateInput: ElementRef,
        zrtTree: ZrtTreeViewComponent
    ): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(
            this.shouldWait$
        );

        this.grid = grid;
        this.header = header;
        this.sideNavContent = sideNavContent;
        this.drawer = drawer;
        this.startDateInput = startDateInput;
        this.endDateInput = endDateInput;

        if (zrtTree) {
            zrtTree.zrtFilterService = this.zrtFilterService;
        }

        this.headerButtons = [];
        this.headerButtons.push(new ColumnVisabilityButtonComponent());
        this.headerButtons.push(new PrintButtonComponent());
        this.headerButtons.push(new ExcelExportButtonComponent());


        this.columnDef = [
            {
                headerName: DailySummaryColumns.zrt,
                dataPropertyName: "zrt",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: DailySummaryColumns.name,
                dataPropertyName: "name",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: DailySummaryColumns.date,
                dataPropertyName: "date",
                isAvailable: true,
                isSelected: true,
                isTemplate: true
            },
            {
                headerName: DailySummaryColumns.callBreakdown,
                dataPropertyName: "callBreakdown",
                isAvailable: true,
                isSelected: true,
                isTemplate: true
            },
            {
                headerName: DailySummaryColumns.endOfDayComments,
                dataPropertyName: "endOfDayComments",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: DailySummaryColumns.workWithAndDuration,
                dataPropertyName: "workWithAndDuration",
                isAvailable: true,
                isSelected: false,
                isTemplate: true
            }
        ];

        this.filters = [];

        this.filters.push({ filter: MySearchesFilterComponent });
        this.filters.push({ filter: SummaryDateInformationFilterComponent, zrtFilterService: this.zrtFilterService });
        this.filters.push({ filter: DailySummaryAttributesFilterComponent });

        const availableColumns = new Array<ColumnSelector>();

        this.columnsToDisplay = this.columnDef
            .filter((cd) => {
                if (cd.isAvailable) {
                    const column: ColumnSelector = {
                        name: cd.headerName,
                        isSelected: cd.isSelected,
                        columns: null
                    };
                    availableColumns.push(column);
                }
            })
            .map((cd) => cd.headerName);
        this.availableColumns = availableColumns;

        if (this.refinerService.areDefaultsSet) {
            this.populateInputsFromRefiners();
        }

        this.setSubscriptions();
    }

    search(shouldAddInputs: boolean = true) {
        if (shouldAddInputs) {
            this.onAddStartDateRefiner();
            this.onAddEndDateRefiner();
        }
        this.activeRefiners = this.refinerService.refiners.slice();

        this.getBatch(this.pageIndex);
    }

    setDefaultFilter(): void {
        if (!this.employee) {
            return;
        }

        if (!this.refinerService.areDefaultsSet) {
            this.dateForm.controls.startDate.setValue(this.startDateDefault);
            this.onAddStartDateRefiner();

            this.dateForm.controls.endDate.setValue(this.endDateDefault);
            this.onAddEndDateRefiner();

            this.zrtFilterService.byArea = false;
            if (Helper.isEmployeeCustomerServiceOrAdmin(this.employee)) {
                this.refinerService.isCorporateUser = true;
                this.zrtFilterService.selectedZrts = [];
            } else {
                const found = DailySummaryReportZrtFilterService.findNodeByZrt(this.zrtFilterService.zrts, this.employee.zrt);
                if (!found) return;
                const refiner = new Refiner();
                refiner.location = RefinerLocation.zrtByEmployee;
                refiner.value = found.displayValue;
                refiner.dataPropertyName = "employeeId";
                refiner.dataValue = found.id;
                this.refinerService.checkAndUpdateRefiner(refiner, true);

                this.zrtFilterService.selectedZrts = DailySummaryReportZrtFilterService.addSelectedZrts(new Array<SearchZrtDropDown>(), found);
            }

            this.refinerService.areDefaultsSet = true;
        }
        this.filterDataLoaded = true;
    }

    async setFilterData(): Promise<void> {
        if (Helper.isEmployeeCustomerServiceOrAdmin(this.employee)) {
            const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts();
            if (zrtsResponse && zrtsResponse?.values) {
                this.zrtFilterService.employeeZrts = zrtsResponse.values;
            }
        } else {
            const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts(this.employee.searchableZrt ?? undefined);
            if (zrtsResponse && zrtsResponse?.values) {
                this.zrtFilterService.employeeZrts = zrtsResponse.values;
            }
        }

        this.setDefaultFilter();
    }

    showFilters(): void {
        this.drawer.toggle();
        this.isGapSet = false;
    }


    unsubscribe(): void {
        if (
            this.refinerServiceSubscription &&
            !this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription.unsubscribe();
        }
        if (
            this.refinerInputChangeSubscription &&
            !this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription.unsubscribe();
        }
        if (
            this.gridDataChangeSubscription &&
            !this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription.unsubscribe();
        }
        if (
            this.employeeSubscription &&
            !this.employeeSubscription.closed
        ) {
            this.employeeSubscription.unsubscribe();
        }
        if (
            this.zrtSelectionSubscription &&
            !this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription.unsubscribe();
        }
        if (
            this.myListSubscription &&
            !this.myListSubscription.closed
        ) {
            this.myListSubscription.unsubscribe();
        }
    }

    // private methods
    private checkSearchError(): void {
        if (this.dateForm) {
            if (
                this.dateForm
                    .get("endDate")
                    .hasError("dateLessThanStartDate") ||
                this.dateForm
                    .get("startDate")
                    .hasError("dateGreaterThanEndDate")
            ) {
                this.isSearchButtonDisabled = true;
            } else {
                this.isSearchButtonDisabled = false;
            }
        }
    }

    private getMyListIds(): void {
        setTimeout(async () => {
            if (this.total > 10000) {
                this.snackbar.showWarning("Total entry count is too high. Cannot push more than 10,000 records at once.");
                this.filterService.myListEntries$.next(undefined);
            } else {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                    this.shouldWait$
                );
                const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                data.header = "Confirmation";
                data.message =
                    "Would you like to push all entries to My List?";
                data.buttonLeftText = "Cancel";
                data.buttonLeftFunction = () => {
                    this.confirmationOverlayRef.close(data);
                };
                data.buttonRightText = "Yes";
                data.buttonRightFunction = () => {
                    data.isConfirmed = true;
                    this.confirmationOverlayRef.close(data);
                };

                this.confirmationOverlayRef = this.overlayService.open(
                    ConfirmationDialogComponent,
                    data
                );

                this.confirmationOverlayRef.afterClosed$
                    .pipe(
                        map(async (event) => {
                            if (event && event.data && event.data.isConfirmed) {
                                this.filterService.myListEntries$.next(await this.getIdsForMyList());
                                this.shouldWait$.next(true);
                            } else {
                                this.filterService.myListEntries$.next(undefined);
                                this.shouldWait$.next(true);
                            }
                        })
                    )
                    .subscribe();
            }
        }, 0);
    }

    private async getIdsForMyList(): Promise<string[]> {
        let rtn = this.dataSource.filteredData.map((gd) => gd.data.dayTimeEntryId);

        if (this.employeeDelineationService.getOnlineState() && rtn.length < this.total) {
            const gridIdResponse = await this.dailySummaryEntryDelineationService.getDailySummaryBatch(
                newSequentialId(),
                this.activeRefiners,
                10000,
                this.pageIndex * this.pageSize,
                this.activeSorts

            );
            if (gridIdResponse?.values) {
                const gridIds = gridIdResponse.values.map(v => v.dayTimeEntryId);
                rtn = rtn.concat(gridIds.filter((v) => !rtn.includes(v)));
            }
        }

        return rtn;
    }

    private greaterThanEndDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            const momentStart: Moment = control.value as Moment;

            if (momentStart) {
                const startDate = momentStart.startOf("day").valueOf();
                this.endMinDate = momentStart;

                if (this.dateForm.controls.endDate.value) {
                    const endDate = (this.dateForm.controls.endDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (startDate > endDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.endMinDate = null;
            }

            this.checkSearchError();

            return forbidden ? { dateGreaterThanEndDate: true } : null;
        };
    }

    private lessThanStartDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            if (control.value) {
                const endDate = (control.value as Moment)
                    .startOf("day")
                    .valueOf();
                this.startMaxDate = control.value;

                if (this.dateForm.controls.startDate.value) {
                    const startDate = (this.dateForm.controls.startDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (endDate < startDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.startMaxDate = moment();
            }

            this.checkSearchError();

            return forbidden ? { dateLessThanStartDate: true } : null;
        };
    }

    private populateInputsFromRefiners(): void {
        for (const refiner of this.refinerService.refiners) {
            this.setInputFromRefiner(refiner);
        }
    }

    private resolvePageIndex(index: number): boolean {
        this.currentRefinerMap = new Map<RefinerLocation, string>();
        for (const refiner of this.refinerService.refiners ?? []) {
            this.currentRefinerMap.set(refiner.location, refiner.dataValue ?? refiner.value);
        }

        const rtn = SharedHelper.compareMaps(
            this.currentRefinerMap,
            this.previousRefinerMap
        );

        if (rtn && this.total != this.gridData?.length && index !== 0) {
            this.pageIndex++;
        } else {
            this.previousRefinerMap = new Map(this.currentRefinerMap);
            this.pageIndex = 0;
        }
        return rtn;
    }

    private setInputFromRefiner(refiner: Refiner): void {
        switch (refiner.location) {
            case RefinerLocation.zrtByArea:
            case RefinerLocation.zrtByEmployee:
                this.zrtFilterService.applyRefiner(refiner);
                break;
            case RefinerLocation.startDate:
                const startDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.startDate.setValue(
                    startDate
                );
                break;
            case RefinerLocation.endDate:
                const endDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.endDate.setValue(
                    endDate
                );
                break;
            default:
                break;
        }
    }

    private setRefinerInput(): void {
        if (!this.filterDataLoaded) return;

        this.setDefaultFilter();

        if (!this.refinerService.refiners.filter(v =>
            v.location == RefinerLocation.zrtByEmployee
        ).length
        ) {
            this.zrtFilterService.selectedZrts = [];
        }

        this.dateForm.controls.startDate.setValue(null);
        this.dateForm.controls.endDate.setValue(null);

        this.populateInputsFromRefiners();
    }

    private setSubscriptions(): void {
        if (
            !this.refinerServiceSubscription ||
            this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription = this.refinerService.refiners$.pipe(
                skipWhile(() => !this.filterDataLoaded && !this.refinerService.areDefaultsSet)
            ).subscribe(
                () => {
                    this.onRefinersChange();
                }
            );
        }

        if (
            !this.refinerInputChangeSubscription ||
            this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription = this.refinerService.refinerInputChange$.subscribe(
                (refiner) => {
                    if (refiner) {
                        this.setInputFromRefiner(refiner);

                        if (refiner.shouldSearchWhenCleared && !refiner.value) {
                            this.getBatch(0);
                        }
                    }

                    if (this.header) {
                        if (this.refinerService.refiners.length === 0) {
                            this.header.expanded = false;
                        } else {
                            this.header.expanded = true;
                        }
                    }
                    this.isGapSet = false;
                }
            );
        }

        if (
            !this.zrtSelectionSubscription ||
            this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription = this.zrtFilterService.observableSelectedZrtsHandleRefiners(this.refinerService).subscribe();
        }

        if (
            !this.gridDataChangeSubscription ||
            this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription = this.grid.dataSourceChange.subscribe(
                (dc) => {
                    if (this.isInitialBatchCompleted || dc) {
                        this.shouldWait$.next(true)
                    }
                }
            );
        }

        if (!this.myListSubscription ||
            this.myListSubscription.closed
        ) {
            this.myListSubscription = this.filterService.myListCommandObservable.pipe(skip(1)).subscribe(async (command) => {
                switch (command) {
                    case MyListCommands.toggleOn:
                    case MyListCommands.toggleOff:
                        this.getBatch(0, true);
                        break;
                    case MyListCommands.push:
                        this.getMyListIds();
                        break;
                    default:
                        break;

                }
            });
        }
    }
}
