import { DailyAvailability } from "./daily-availability.entity";

export class Availability {
    sunday: DailyAvailability = new DailyAvailability();
    monday: DailyAvailability = new DailyAvailability();
    tuesday: DailyAvailability = new DailyAvailability();
    wednesday: DailyAvailability = new DailyAvailability();
    thursday: DailyAvailability = new DailyAvailability();
    friday: DailyAvailability = new DailyAvailability();
    saturday: DailyAvailability = new DailyAvailability();
}
