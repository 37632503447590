import { CallTypes } from "../enum";
import { RetailCallDto } from "./retail-call.dto";

export class RmWholesaleCallDto extends RetailCallDto {

    constructor(){
        super();
        this.callType = CallTypes.rmWholesale;
    }
}
