import { Injectable } from "@angular/core";
import { VolumeParamsDto } from "shield.shared";
import { Customer } from "src/app/entity-models/customer.entity";
import { MsaBrand } from "src/app/entity-models/msa-brand.entity";
import { MsaDistributorVolumeResponse } from "src/app/entity-models/msa-distributor-volume-response.entity";
import { MsaDistributorVolume } from "src/app/entity-models/msa-distributor-volume.entity";
import { Helper } from "src/app/helpers/helper";
import { DatabaseService } from "../database.service";

@Injectable()
export class VolumeOfflineService {

    constructor(
        private dbService: DatabaseService
    ) { }

    async getByCustomerId(id: string): Promise<MsaDistributorVolumeResponse> {
        let rtn = new MsaDistributorVolumeResponse();

        const customer = await this.dbService.customers
            .where("id")
            .equals(id)
            .first();
        if (customer?.volumeResponse) {
            rtn = customer.volumeResponse;
        } else {
            throw new Error('BYPASS')
        }

        if (!rtn.brands) rtn.brands = new Array<MsaBrand>();
        if (!rtn.volumes) rtn.volumes = new Array<MsaDistributorVolume>();

        return rtn;
    }

    async getAvgByCustomerAndDistance(key: VolumeParamsDto): Promise<MsaDistributorVolumeResponse> {
        const rtn = new MsaDistributorVolumeResponse;
        rtn.brands = new Array<MsaBrand>();
        rtn.volumes = new Array<MsaDistributorVolume>();

        let responses = new Array<MsaDistributorVolumeResponse>();
        const customer = await this.dbService.customers
            .where("id")
            .equals(key.customerId)
            .first();
        const nearbyStores = new Array<Customer>();
        if (customer && customer.zrt && customer.businessAddress.latitude && customer.businessAddress.longitude) {
            const allStoresInZrt = await this.dbService.customers
                .where("zrt")
                .equals(customer.zrt)
                .toArray();
            for (const store of allStoresInZrt) {
                if (
                    (store.businessAddress?.latitude || store.dbaAddress?.latitude) &&
                    (store.businessAddress?.longitude || store.dbaAddress?.longitude)
                ) {
                    const src = { lat: customer.businessAddress.latitude, lng: customer.businessAddress.longitude };
                    const dst = {
                        lat: store.dbaAddress?.latitude ?? store.businessAddress?.latitude,
                        lng: store.dbaAddress?.longitude ?? store.businessAddress?.longitude
                    };
                    const distanceToStore = Helper.getDistanceinMiles(src, dst);
                    if (distanceToStore < key.distance) {
                        nearbyStores.push(store);
                    }
                }
            }
            responses = nearbyStores.map((s) => s.volumeResponse).filter((r) => !!r);
            for (const volumeResponse of responses) {
                for (const brand of volumeResponse.brands) {
                    if (!rtn.brands.map(b => b.id).includes(brand.id)) {
                        rtn.brands.push(brand);
                    }
                }

                for (const volume of volumeResponse.volumes) {
                    const foundVolume = rtn.volumes.find(v => v.msaBrandId === volume.msaBrandId);
                    if (foundVolume) {
                        foundVolume.volumeAt4Weeks += volume.volumeAt4Weeks;
                        foundVolume.volumeAt13Weeks += volume.volumeAt13Weeks;
                        foundVolume.volumeAt26Weeks += volume.volumeAt26Weeks;
                        foundVolume.volumeAtCurrentYearToDate += volume.volumeAtCurrentYearToDate;
                        foundVolume.volumeAtOneYearAgo += volume.volumeAtOneYearAgo;
                    } else {
                        rtn.volumes.push(volume);
                    }
                }
            }
        }
        rtn.storeCount = nearbyStores.length ?? 0;
        return rtn;
    }
}
