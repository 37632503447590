import { CustomerTypeEnum } from "../lookup";
import { OnlineReferenceDto } from "./online-reference.dto";

export class CallHistoryEntryDto {
    id: string;
    customerId: string;
    startTime: string;
    stopTime: string;
    createdById: string;
    createdByName: string;
    durationMins: number;
    closingNotes: string | null;
    customerName: string | null;
    customerNumber: string;
    address: string | null | undefined;
    city: string | null | undefined;
    state: string | null | undefined;
    county: string | null | undefined;
    zip: string | null | undefined;
    zrt: string | null;
    customerType: CustomerTypeEnum;
    callType: string;
    hasPictures: boolean;
    px3RankId: string | null | undefined;
    pictures: OnlineReferenceDto[] | null | undefined;
    hasBeforePicture: boolean;
    hasAfterPicture: boolean;
}
