import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EmployeeDto, GenericRequestDto, GenericResponseDto, newSequentialId, ResponseCountKey, SearchZrtDropDownDto } from "shield.shared";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { Employee } from "../../entity-models/employee.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { EmployeeConverterService } from "../converter-services/employee-converter.service";

@Injectable()
export class EmployeeOnlineService {
    constructor(private http: HttpClient) { }

    async getAll(key: null): Promise<GenericResponseDto<Employee[]>> {

        const result = await this.http
            .get<GenericResponseDto<EmployeeDto[]>>(`/api/employees/get-all`)
            .toPromise();

        return ConverterHelper.dtoToEntityArray<EmployeeDto, Employee>(result, (r) => EmployeeConverterService.employeeDtoToEmployee(r));
    }

    async getById(id: string): Promise<GenericResponseDto<Employee>> {
        if (!id) {
            return;
        }

        const result = await this.http
            .get<GenericResponseDto<EmployeeDto>>(`/api/employees/${id}`)
            .toPromise();

        return ConverterHelper.dtoToEntity<EmployeeDto, Employee>(result, (r) => EmployeeConverterService.employeeDtoToEmployee(r));
    }

    async getByIds(ids: string[]): Promise<GenericResponseDto<Employee[]>> {

        if (ids?.length) {
            const request = new GenericRequestDto<string[]>();
            request.id = newSequentialId();
            request.values = ids;

            const result = await this.http
                .post<GenericResponseDto<EmployeeDto[]>>(`/api/employees/getbyids`,
                    request)
                .toPromise();

            return ConverterHelper.dtoToEntityArray<EmployeeDto, Employee>(result, (r) => EmployeeConverterService.employeeDtoToEmployee(r));
        } else {

            const rtn = new GenericResponseDto<Employee[]>();
            rtn.id = newSequentialId();
            rtn.values = new Array<Employee>();

            return rtn
        }
    }

    async getByZrt(id: string): Promise<GenericResponseDto<Employee[]>> {

        if (!id) {
            return;
        }

        const result = await this.http
            .get<GenericResponseDto<EmployeeDto[]>>(`/api/employees/get-by-zrt/${id}`)
            .toPromise();

        return ConverterHelper.dtoToEntityArray<EmployeeDto, Employee>(result, (r) => EmployeeConverterService.employeeDtoToEmployee(r));
    }

    async getEmployeeZrts(key: undefined): Promise<GenericResponseDto<SearchZrtDropDown[]>> {

        const response = await this.http
            .get<GenericResponseDto<SearchZrtDropDownDto[]>>(`/api/employees/for-zrt-search`)
            .toPromise();

        return ConverterHelper.dtoToEntityArray<SearchZrtDropDownDto, SearchZrtDropDown>(response,
            (r) => EmployeeConverterService.searchZrtDropDownDtoToEntity(r));
    }
}
