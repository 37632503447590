import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';
import { OverlayService } from 'src/app/services/overlay.service';
import { ProjectProductOrderDatesViewmodel as ProjectProductOrderDatesViewmodel } from './project-product-order-dates.viewmodel';

@Component({
    selector: 'app-project-product-order-dates',
    templateUrl: './project-product-order-dates.component.html',
    styleUrls: ['./project-product-order-dates.component.css']
})
export class ProjectProductOrderDatesComponent implements OnInit, OnDestroy {

    viewmodel: ProjectProductOrderDatesViewmodel;
    constructor(public injectedData: SwisherOverlayRef<
        ProjectProductOrderDatesViewmodel,
        ProjectProductOrderDatesComponent
    >) {
        }

    ngOnInit(): void {
        this.viewmodel = this.injectedData.data;
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }

}
