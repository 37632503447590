import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { filter, map, take, takeUntil } from "rxjs/operators";
import { CustomerGenericTypes } from "src/app/enums/customer-generic-types";
import { Helper } from "src/app/helpers/helper";
import { WholesalerGroupProductsListViewModel } from "src/app/wholesale-products/wholesaler-products-list/wholesaler-group-products-list.viewmodel";
import { AppStateService } from "src/app/services/app-state.service";
import { WholesalerGroupDelineationService } from "src/app/services/delineation-services/wholesaler-group-delineation.service";
import { WholesalerGroupProductCatalogItemDelineationService } from "src/app/services/delineation-services/wholesaler-group-product-catalog-item-delineation.service";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { ExcelExportButtonComponent } from "src/app/shared/page-header/buttons/excel-export-button/excel-export-button.component";
import { CustomerStateService } from "../account-services/customer-state.service";
import { WholesalerProductsDialogComponent } from "src/app/wholesale-products/wholesaler-products-list/wholesaler-products-dialog.component";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { WholesalerDivisionProductsListViewModel } from "src/app/wholesale-products/wholesaler-products-list/wholesaler-division-products-list.viewmodel";
import { WholesalerProductCatalogItems } from "src/app/entity-models/wholesaler-product-catalog-items.entity";

@Component({
    selector: "app-products-master",
    templateUrl: "./products-master.component.html",
    styleUrls: ["./products-master.component.css"]
})
export class ProductsMasterComponent implements OnInit, OnDestroy {
    @HostBinding("class") class = "d-flex flex-column flex-grow-1";
    overlayRef: SwisherOverlayRef<WholesalerDivisionProductsListViewModel, WholesalerProductsDialogComponent>;
    viewmodel: WholesalerDivisionProductsListViewModel;
    private destroy$ = new Subject();
    constructor(
        private customerStateService: CustomerStateService,
        private appStateService: AppStateService,
        private pleaseWaitService: PleaseWaitService,
        private catalogItemService: WholesalerGroupProductCatalogItemDelineationService,
        private groupService: WholesalerGroupDelineationService,
    ) { }

    afterSave() {
        window.location.reload();
    }

    async ngOnInit(): Promise<void> {
        const loaded$ = new BehaviorSubject(null);
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(loaded$);
        const customer = await this.customerStateService.observableCustomer
            .pipe(
                filter((c) => c != null),
                take(1),
            )
            .toPromise();
        const type = Helper.getCustomerGenericType(customer);

        if (type !== CustomerGenericTypes.wholesale) {
            return;
        }

        const isAdmin = await this.appStateService.currentEmployee.pipe(take(1), map(employee =>
            Helper.isEmployeeCustomerServiceOrAdmin(employee)
        )).toPromise();

        const response = await this.catalogItemService
            .getByWholesalerId(customer.id);
        loaded$.next(null);
        if (!response) {
            return;
        }

        const wholesalerGroupEntry = await this.groupService.getEntryByWholesalerId(customer.id);
        const groupMember = wholesalerGroupEntry.groupMembers.find(
            gm => gm.wholesalerId === customer.id
        );
        const wholesalerItems = response.values.reduce((arr, gi) => {
            const wholesalerItem = gi?.wholesalerItems?.find(wi => wi.wholesalerId === customer.id);
            if (wholesalerItem) {
                arr.push(wholesalerItem);
            }
            return arr;
        }, [] as WholesalerProductCatalogItems[]);
        this.viewmodel = new WholesalerDivisionProductsListViewModel(
            isAdmin,
            wholesalerGroupEntry,
            wholesalerItems,
            groupMember,
            response.values,
        );
        this.viewmodel.showFooter = true;
        this.viewmodel.height = '70vh';
        this.viewmodel.buttonLeftDisabledFunction = () => false;
        this.viewmodel.buttonRightDisabledFunction = () => false;
        this.viewmodel.buttonLeftText = undefined;
        this.viewmodel.afterSave$.pipe(takeUntil(this.destroy$)).subscribe(() => this.afterSave());
        this.viewmodel.iconButtons = [
            new ExcelExportButtonComponent(),
        ];
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
