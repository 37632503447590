import { GenericLookup } from "../generic";
import { CustomerTypeEnum } from "../lookup";
import { CallableDataDto } from "../models";
import { AddressFilterDto } from "./address-filter.dto";
import { ZrtFilterDto } from "./zrt-filter.dto";

export class AccountFilterDto {
    /*
     * My Searches
     */
    // Usage: Get only accounts with given ids
    ids?: string[];

    // Usage: Get only accounts with given customer numbers
    customerNumbers?: string[];

    /*
     * Location Secton
     */

    //Usage: Contains ZRT properties
    zrtFilterDto?: ZrtFilterDto;

    //Usage: Used for filter accounts on the account list page
    //Possible data: account name, account GUID/Id or account number
    account?: string[];

    //Usage: Contains address properties for the business address
    businessAddressFilterDto?: AddressFilterDto;

    //Usage: Contains address properties for the business address
    drivingAddressFilterDto?: AddressFilterDto;


    /*
     * Date Information Sections
     */

    //Usage: Get data based on call/date
    callOnOrAfterDate?: string;
    callOnOrBeforeDate?: string;

    //Usage: Getting accounts/customers based on contact availability days
    //Possible data: array of U, M, T, W, R, F, S
    availability?: string[];


    /*
     * Activities Secton
     */

    //Usage: Get based on associated project ids
    projectIds?: string[];

    //Usage: Get data based on wholesaler Ids
    wholesalerIds?: string[];

    //Usage: Get data based on wholesaler Ids for projects
    projectWholesalers?: string[];

    //Usage: Getting accounts/customers based on it account ownership
    //Possible data: Account ownership GUIDs
    accountOwnershipIds?: string[];

    // Usage: Getting accounts/customers based on the owner code
    // Possible data: owner codes
    accountOwnerCodes?: string[];

    //Usage: Get data based on associated products ids
    productIds?: string[];

    //Usage: Get data based on associated products ids in dist
    productIdsInDist?: string[];

    //Usage: Get data based on associated products ids not in dist
    productIdsNotInDist?: string[];


    /*
     * Attributes Secton
     */

    //Usage: Get based on store types
    customerTypeIds?: CustomerTypeEnum[];

    //Usage: Get if the associated customer is an Msa
    isMsa?: boolean;

    //Usage: Get if phone exist
    hasPhone?: boolean;

    //Usage: Get if active
    isActive?: boolean;

    //Usage: Get based on whether any volume exists
    volume?: boolean;

    //Usage: Get based on the selected callable state
    callable?: Array<CallableDataDto>;

    //Usage: Get based on the selected px3 rank
    px3Rank?: string[];

    /*
    * Project Customers
    */

    //Usage: Is the customer assigned to the project
    isAssigned?: boolean;

    // Usage: Has the assigned customer been selected for employee assignment?
    isSelecting?: boolean;

    // Usage: Get project customers based on employee assignment status
    assignedToEmployee?: boolean;

    // Usage: Get project customers assigned to these employees
    assignedEmployeeIds?: string[];

    /*
     * Routing
     */

    // Usage: maps to the lower bound of the visible map
    lowerBoundLat: number;
    lowerBoundLng: number;

    // Usage: maps to the upper bound of the visible map
    upperBoundLat: number;
    upperBoundLng: number;

}
