import { ValidationStopLevel } from "./validation-stop-level";
import { ValidationTargets } from "./validation-targets";

export abstract class ValidationCheck<T> {
    name: ValidationTargets;
    message: string;
    stopLevel: ValidationStopLevel;
    input: T[];

    abstract validate() : this;
}
