export class ContractTemplateDetailDto{
    id: string;
    contractTemplateId: string;
    key: string;
    value: string;
    createdUtcDateTime: string;
    createdUserId: string;
    createdUserZrt: string | null;
    createdUserFullName: string;
}
