import { SalespersonRole } from "../enum";
import { EmployeeRoleDto } from "./employee-role.dto";

export class EmployeeDto {
    id: string;
    employeeNumber: string;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    hireDate: string | null;
    deptCode: string | null;
    jobTitle: string | null;
    zone: string | null;
    region: string | null;
    territory: string | null;
    zrt: string;
    subsidiaryId: number | null;
    searchableZrt: string | null;
    salespersonRole: SalespersonRole;
    area: string | null;
    nickName: string | null;
    homePhone: string | null;
    cellPhone: string | null;
    mailAddress1: string | null;
    mailAddress2: string | null;
    mailCity: string | null;
    mailState: string | null;
    mailZipCode: string | null;
    shipAddress1: string | null;
    shipAddress2: string | null;
    shipAddress3: string | null;
    shipCity: string | null;
    shipState: string | null;
    shipZipCode: string | null;
    manager: string | null;
    managerEmployeeNumber: string | null;
    managerEmployeeId: string | null;
    isDeleted: boolean;
    createdUtcDateTime: string;
    createdUserId: string;
    createdUserZrt: string;
    createdUserFullName: string;
    modifiedUtcDateTime: string;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    rowversion: string;
    employeeRoles: EmployeeRoleDto[];
}
