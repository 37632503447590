import { Component, HostListener, OnInit } from "@angular/core";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { CountDownViewmodel } from "./count-down.viewmodel";

@Component({
    selector: "app-count-down",
    templateUrl: "./count-down.component.html",
    styleUrls: ["./count-down.component.scss"]
})
export class CountDownComponent implements OnInit {
    viewmodel = new CountDownViewmodel();
    constructor(
        private ref: SwisherOverlayRef<CountDownViewmodel, CountDownComponent>
    ) {}

    ngOnInit(): void {
        this.viewmodel = this.ref.data;
        const downloadTimer = setInterval(() => {
            if (this.viewmodel.seconds <= 0) {
                clearInterval(downloadTimer);
                this.close();
            }
            this.viewmodel.seconds = this.viewmodel.seconds -= 1;
        }, 1000);
    }

    @HostListener('window:keyup', ['$event'])
        onKeyUp(event: KeyboardEvent): void {
            if (event.key === 'Enter' && this.ref) {
                this.close()
            }
        }

    close(): void {
        this.ref.close();
    }
}
