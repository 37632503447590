import { GenericLookup } from "../generic";

export enum TaxSubmittedTos {
    State = 1,
    NonState = 2,
    RDS = 3,
    County = 4
}

export const TaxSubmittedToLookup: GenericLookup<TaxSubmittedTos>[] = [
    { id: TaxSubmittedTos.State, name: "State", description: "State" },
    { id: TaxSubmittedTos.NonState, name: "Non-State", description: "Non-State" },
    { id: TaxSubmittedTos.RDS, name: "RDS", description: "RDS" },
    { id: TaxSubmittedTos.County, name: "County", description: "County" },
];
