import { GenericLookup } from "../generic";

export enum Subsidiary {
    Swisher = 1,
    EAS = 2,
    Rogue = 3
}

export const SubsidiaryLookup: GenericLookup<Subsidiary>[] = [
    { id: Subsidiary.Swisher, name: "Swisher", description: "Swisher" },
    { id: Subsidiary.EAS, name: "EAS", description: "EAS" },
    { id: Subsidiary.Rogue, name: "Rogue", description: "Rogue" }
];
