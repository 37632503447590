<div #myFilter>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-contract">Contract Number</mat-label>
            <input #contractInput id="contract-input" type="text"
                (keyup)="onAddContractRefiner($event)" [(ngModel)]="viewmodel.contractInput" matInput />
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Contract Types</mat-label>
            <mat-select #contractTypesSelect
                [(value)]="viewmodel.selectedContractTypes"
                (openedChange)="onOpenedContractTypesChange()"
                (selectionChange)="onSelectionContractTypesChange()"
                multiple>
                <mat-option *ngFor="let type of viewmodel.contractTypes" [value]="type">
                    {{ type.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Last Call Status</mat-label>
            <mat-select #lastCallStatusSelect
                [(value)]="viewmodel.selectedLastCallStatuses"
                (openedChange)="onOpenedLastCallStatusChange()"
                (selectionChange)="onSelectionLastCallStatusChange()"
                multiple>
                <mat-option *ngFor="let status of viewmodel.lastCallStatuses" [value]="status">
                    {{ status.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100" [formGroup]="viewmodel.dateForm">
            <mat-label>Signed on or After</mat-label>
            <input #signedStartDateInput matInput
                formControlName="signedStartDate"
                [max]="viewmodel.signedStartMaxDate"
                [matDatepicker]="signedAfterPicker"
                (blur)="onAddSignedStartDateRefiner($event)"
                (dateChange)="onAddSignedStartDateRefiner()" />
            <mat-datepicker touchUi #signedAfterPicker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="signedAfterPicker"></mat-datepicker-toggle>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100" [formGroup]="viewmodel.dateForm">
            <mat-label>Signed on or Before</mat-label>
            <input #signedEndDateInput matInput
                formControlName="signedEndDate"
                [max]="viewmodel.signedEndMaxDate"
                [matDatepicker]="signedBeforePicker"
                (blur)="onAddSignedEndDateRefiner($event)"
                (dateChange)="onAddSignedEndDateRefiner()" />
            <mat-datepicker touchUi #signedBeforePicker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="signedBeforePicker"></mat-datepicker-toggle>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100" [formGroup]="viewmodel.dateForm">
            <mat-label>Payment on or After</mat-label>
            <input #paymentStartDateInput matInput
                formControlName="paymentStartDate"
                [max]="viewmodel.paymentStartMaxDate"
                [matDatepicker]="paymentAfterPicker"
                (blur)="onAddPaymentStartDateRefiner($event)"
                (dateChange)="onAddPaymentStartDateRefiner()" />
            <mat-datepicker touchUi #paymentAfterPicker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="paymentAfterPicker"></mat-datepicker-toggle>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100" [formGroup]="viewmodel.dateForm">
            <mat-label>Payment on or Before</mat-label>
            <input #paymentEndDateInput matInput
                formControlName="paymentEndDate"
                [max]="viewmodel.paymentEndMaxDate"
                [matDatepicker]="paymentBeforePicker"
                (blur)="onAddPaymentEndDateRefiner($event)"
                (dateChange)="onAddPaymentEndDateRefiner()" />
            <mat-datepicker touchUi #paymentBeforePicker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="paymentBeforePicker"></mat-datepicker-toggle>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Payment Types</mat-label>
            <mat-select #paymentTypesSelect
                [(value)]="viewmodel.selectedPaymentTypes"
                (openedChange)="onOpenedPaymentTypesChange()"
                (selectionChange)="onSelectionPaymentTypesChange()"
                multiple>
                <mat-option *ngFor="let type of viewmodel.paymentTypes" [value]="type">
                    {{ type.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Payment Methods</mat-label>
            <mat-select #paymentMethodsSelect
                [(value)]="viewmodel.selectedPaymentMethods"
                (openedChange)="onOpenedPaymentMethodsChange()"
                (selectionChange)="onSelectionPaymentMethodsChange()"
                multiple>
                <mat-option *ngFor="let pmt of viewmodel.paymentMethods" [value]="pmt">
                    {{ pmt.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Program Levels</mat-label>
            <mat-select #programLevelsSelect
                [(value)]="viewmodel.selectedProgramLevels"
                (openedChange)="onOpenedProgramLevelsChange()"
                (selectionChange)="onSelectionProgramLevelsChange()"
                multiple>
                <mat-option *ngFor="let lvl of viewmodel.programLevels" [value]="lvl">
                    {{ lvl == viewmodel.allProgramLevel ? lvl : "Level " + lvl }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
