<mat-form-field appearance="outline" color="accent" class="w-100">
    <input matInput maxlength="50" placeholder="Search" [(ngModel)]="viewmodel.searchText"
        (keyup)="viewmodel.filter()" (blur)="viewmodel.filter()">
</mat-form-field>
<div class="inner-card">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th scope="col">
                    <mat-checkbox [indeterminate]="false" color="warn"
                        [ngModel]="viewmodel.isAllSelected()"
                        (ngModelChange)="viewmodel.markAllSelected($event)">
                    </mat-checkbox>
                </th>
                <th scope="col">Activites/Survey Group Description</th>
                <th scope="col">Activities in Group</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="!viewmodel.filteredActivities?.length">
                <td colspan="3">
                    <em>No additional activities/surveys found for this project.</em>
                </td>
            </tr>
            <tr *ngFor="let activity of viewmodel.filteredActivities">
                <td>
                    <mat-checkbox [indeterminate]="false" color="warn"
                        [ngModel]="activity.isSelected"
                        (ngModelChange)="viewmodel.markSelected($event, activity)">
                    </mat-checkbox>
                </td>
                <td>
                    {{ activity.description }}
                </td>
                <td>
                    {{ activity.count | number }}
                </td>
            </tr>
        </tbody>
    </table>
</div>
