export class ContactDto {
    id: string;
    customerId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    alternatePhone: string;
    note: string;
    isAvailableSundayAm: boolean;
    isAvailableSundayPm: boolean;
    isAvailableMondayAm: boolean;
    isAvailableMondayPm: boolean;
    isAvailableTuesdayAm: boolean;
    isAvailableTuesdayPm: boolean;
    isAvailableWednesdayAm: boolean;
    isAvailableWednesdayPm: boolean;
    isAvailableThursdayAm: boolean;
    isAvailableThursdayPm: boolean;
    isAvailableFridayAm: boolean;
    isAvailableFridayPm: boolean;
    isAvailableSaturdayAm: boolean;
    isAvailableSaturdayPm: boolean;
    isDeleted: boolean;
    createdUtcDateTime: string;
    createdUserId: string;
    createdUserZrt: string;
    createdUserFullName: string;
    modifiedUtcDateTime: string | null;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    clientLastTouchedUtcDateTime: string;
    rowversion: string;
}
