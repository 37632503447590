import { GenericLookup } from "../generic";

export enum TaxCalculationMethods {
    DollarPerEach = 1,
    DollarPerPack = 2,
    DollarPerOunce = 3,
    PercentOfPrice = 4,
    DollarPerOunceAndAHalfRounded = 5
}

export const TaxCalculationMethodLookup: GenericLookup<TaxCalculationMethods>[] = [
    { id: TaxCalculationMethods.DollarPerEach, name: "$ per each", description: "Dollar per each" },
    { id: TaxCalculationMethods.DollarPerPack, name: "$ per pack", description: "Dollar per pack" },
    { id: TaxCalculationMethods.DollarPerOunce, name: "$ per oz", description: "Dollar per ounce" },
    { id: TaxCalculationMethods.PercentOfPrice, name: "% of price", description: "Percent of price" },
    { id: TaxCalculationMethods.DollarPerOunceAndAHalfRounded, name: "$ per container < 1.5oz", description: "$ per container < 1.5oz. or portion thereof by weight sold" }
];
