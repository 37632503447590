import { CallProductTax } from "./call-cash-tax.entity";

export class CallGratisProduct {
    id: string; //UUID
    productId: string; //UUID
    quantity: number;
    units: number;
    value: number;
    upc: string;
    callProductTax: CallProductTax | null;
}
