import { ContractTemplate } from "./contract-template.entity";
import { CustomerContractPayment } from "./customer-contract-payment.entity";
import { Signature } from "./signature.entity";

export class CustomerContract {
    id: string;
    customerId: string;
    number: string;
    level: number;
    isNew: boolean;
    signatureDate: Date;
    endDate: Date;
    customerNumber: string;
    customerName: string;
    retailIdentificationNumber: string;
    address1: string;
    address2: string | null;
    city: string;
    state: string;
    zipCode: string;
    px3RankId?: string | null;
    lastPaymentStatusId: number;
    totalPayment: number;
    customerContactId: string;
    customerContactName: string | null;
    createdUtcDateTime: Date;
    createdUserId: string;
    createdUserZrt: string | null;
    createdUserFullName: string;
    modifiedUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string;
    customerSignature?: Signature | null;
    customerContractPayments: CustomerContractPayment[];
    hasServerProcessed: number;
    contractTemplate: ContractTemplate;
}
