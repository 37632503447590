import {
    EmployeeLevel,
    GenericLookup,
    GratisApprovalStatuses
} from "shield.shared";

export class GratisApproval {
    id!: string;
    gratisId!: string;
    employeeLevel!: GenericLookup<EmployeeLevel>;
    employeeId!: string;
    approvalStatus!: GenericLookup<GratisApprovalStatuses>;
    date: Date | null;
    reason!: string;

    constructor() {
        this.date = null;
    }
}
