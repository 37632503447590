import { Injectable } from "@angular/core";
import { GenericResponseDto } from "shield.shared";
import { State } from "src/app/entity-models/state.entity";
import { DatabaseService } from "../database.service";
import { StateOfflineService } from "../offline-services/state-offline.service";
import { StateOnlineService } from "../online-services/state-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class StateDelineationService extends DelineationContext<State, string> {

    constructor(private stateOfflineService: StateOfflineService
        , snackbarService: SnackbarService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService){
            super(dbService, datasourceDelineationService, snackbarService);
        }

    async getByShortCode(shortCode: string): Promise<GenericResponseDto<State>> {

        const offline = (key: string) => {
            return this.stateOfflineService.getByShortCode(key);
        }
        const online = (key: string) => {
            return this.stateOfflineService.getByShortCode(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, State>(shortCode, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getById(id: string): Promise<GenericResponseDto<State>> {

        const offline = (key: string) => {
            return this.stateOfflineService.getById(key);
        }
        const online = (key: string) => {
            return this.stateOfflineService.getById(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, State>(id, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getAll(): Promise<Array<State>> {
        return this.dbService.states.toArray();
    }
}
