import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    GenericVersionResponseDto, SharedHelper, SyncCommandTypes,
    SystemInformationKeys, VersionResponseDto, WholesalerProductCatalogItemDto,
    WholesalerProductCatalogSyncCommand
} from "shield.shared";
import { WholesalerProductCatalogItems } from "src/app/entity-models/wholesaler-product-catalog-items.entity";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { SyncLevel } from "../sync-enums/sync-level.enum";
import { WholesaleProductCatalogItemConverter } from "src/app/services/converter-services/wholesaler-product-catalog-item-converter.service";

@Injectable()
export class WholesalerProductCatalogDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.Required;
    offlineRepSyncType = SyncLevel.Required;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.wholesalerProductCatalog;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing wholesaler product catalogs...");

        await this.pullData();

        this.log("Done syncing wholesaler product catalogs...");
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>(
                    "/api/wholesalers/product-catalogs/version"
                )
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private wholesalerProductCatalogToWholesalerProductCatalogItemDto(
        w: WholesalerProductCatalogItems
    ): WholesalerProductCatalogItemDto {
        const rtn = new WholesalerProductCatalogItemDto();

        rtn.id = w.id;
        rtn.wholesalerId = w.wholesalerId;
        rtn.wholesalerGroupProductCatalogItemId = w.wholesalerGroupProductCatalogItemId;
        rtn.productId = w.productId

        return rtn;
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("Wholesaler products catalogs data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Wholesaler product catalogs data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.wholesalerProductCatalogPullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 2000;

        try {
            let thisBatchSize: number;
            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;
                const response = await this.http
                    .get<GenericVersionResponseDto<WholesalerProductCatalogItemDto[]>>(
                        `/api/wholesalers/product-catalogs${query}`
                    ).toPromise();

                thisBatchSize = response.values?.length;

                this.log(
                    `Downloaded ${thisBatchSize} wholesaler product catalogs, saving to IndexedDB...`
                );

                const wholesalerProducts = response.values.map((wgpci) =>
                    WholesaleProductCatalogItemConverter.dtoToEntity(
                        wgpci
                    )
                );

                await this.dbService.wholesalerProductCatalogItems.bulkPut(
                    wholesalerProducts
                );
                this.log(
                    `  Saved  ${wholesalerProducts.length} wholesaler product catalogs data.`
                );

                this.lastSyncVersion = response.maxVersion;
            } while (thisBatchSize > 0);

            this.log("Done saving wholesaler group product catalogs data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing wholesaler group product catalogs", e);
        }
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<WholesalerProductCatalogSyncCommand>(
            SyncCommandTypes.wholesalerGroupProductCatalog,
            async (message) => {
                let wholesalerProductCatalog = await this.dbService.wholesalerProductCatalogItems.get(
                    message.payload.id
                );

                let dto = this.wholesalerProductCatalogToWholesalerProductCatalogItemDto(
                    wholesalerProductCatalog
                );

                if (dto) {
                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }
}
