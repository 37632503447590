<div *ngFor="let vm of swisherViewmodels; let i = index">
    <div>
        <div>
            <div class="center">
                ******Draft******Draft******Draft******Draft******Draft******Draft******
                ******Draft******Draft******Draft******Draft******Draft******Draft******
            </div>
            <div class="row prow">
                <div class="col pcol center">
                    {{ customerReceiptAddress?.addressName }} - Receipt #{{vm.receiptNumber}}
                </div>
            </div>
            <div *ngIf="vm.customerCode" class="row prow">
                <div class="col pcol center">
                    Distributor Acct # for Retailer {{vm.customerCode}}
                </div>
            </div>
            <div *ngIf="vm.retailOptLicense?.licenseNumber" class="row prow">
                <div class="col pcol center">
                    Retail License # {{vm.retailOptLicense?.licenseNumber}}
                </div>
            </div>
            <div class="row prow">
                <div class="col pcol center">
                    {{ customerReceiptAddress?.addressFormatted }}
                </div>
            </div>
            <div *ngIf="customer?.businessAddress?.county" class="row prow">
                <div class="col pcol center">{{ customer.businessAddress?.county + ' County' }}</div>
            </div>
            <hr />
            <div *ngIf="vm.wholesaler" class="row prow">
                <div class="col pcol center">{{vm.wholesaler.name}}</div>
            </div>
            <div *ngIf="vm.wholesaler" class="row prow">
                <div class="col pcol center">State OTP License ID {{vm.wholesalerRetailOptLicense?.licenseNumber}}</div>
            </div>
            <div *ngIf="vm.orders?.length > 0">
                <div *ngFor="let order of vm.orders">
                    <br />
                    <div class="row prow">
                        <div class="col pcol center">
                            <b>ITEMS TO BE DELIVERED</b>
                        </div>
                    </div>
                    <div class="row prow">
                        <div class="col pcol">
                            <b>UIN</b>
                        </div>
                        <div class="col-8 pcol-8 center">
                            <b>Order Date {{order.orderDate | date: dateFormat}}</b>
                        </div>
                        <div class="col pcol">
                            <b class="pull-right">Qty</b>
                        </div>
                    </div>
                    <div *ngFor="let orderProduct of order.callOrderProductViewmodels" class="row prow">
                        <div class="col pcol">
                            {{ orderProduct.uin }}
                        </div>
                        <div class="col-8 pcol-8 center">
                            {{ orderProduct.product?.description }}
                        </div>
                        <div class="col pcol align-right">
                            {{ orderProduct.quantity }}
                        </div>
                    </div>
                    <hr />
                </div>
            </div>
            <div class="center">
                ******Draft******Draft******Draft******Draft******Draft******Draft******
                ******Draft******Draft******Draft******Draft******Draft******Draft******
            </div>
        </div>
        <div *ngIf="i < swisherViewmodels?.length -1 || hasEasReceipts" class="page-break"></div>
    </div>
</div>
