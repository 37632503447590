import { Injectable } from "@angular/core";
import { GenericResponseDto, WholesalerGroupIdsAndProductIdParamsDto, WholesalerGroupProductCatalogItemDto } from "shield.shared";
import { ProjectProduct } from "src/app/entity-models/project-product.entity";
import { WholesalerGroupProductCatalogItem } from "src/app/entity-models/wholesaler-group-product-catalog-item.entity";
import { DatabaseService } from "../database.service";
import { WholesalerGroupProductCatalogItemOfflineService } from "../offline-services/wholesaler-group-product-catalog-item-offline.service";
import { WholesalerGroupProductCatalogItemOnlineService } from "../online-services/wholesaler-group-product-catalog-item-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class WholesalerGroupProductCatalogItemDelineationService extends DelineationContext<WholesalerGroupProductCatalogItem, string>{

    constructor(
        private wholesalerGroupProductCatalogItemOfflineService: WholesalerGroupProductCatalogItemOfflineService,
        private wholesalerGroupProductCatalogItemOnlineService: WholesalerGroupProductCatalogItemOnlineService,
        snackbarService: SnackbarService,
        protected datasourceDelineationService: DatasourceDelineationService,
        protected dbService: DatabaseService
    ) {
        super(dbService, datasourceDelineationService, snackbarService);
    }

    async saveAll(items: WholesalerGroupProductCatalogItem[]): Promise<void> {
        await this.datasourceDelineationService.makeCall(items,
            items => this.wholesalerGroupProductCatalogItemOfflineService.saveAll(items),
            items => this.wholesalerGroupProductCatalogItemOnlineService.saveAll(items)
        );
    }

    async getProductCatalogExport(
        wholesalerGroupId?: string,
    ): Promise<Blob> {
        return this.datasourceDelineationService.makeCallWithBlobReturn(
            null,
            _ => this.wholesalerGroupProductCatalogItemOfflineService.getProductCatalogExport(
                wholesalerGroupId,
            ),
            _ => this.wholesalerGroupProductCatalogItemOnlineService.getProductCatalogExport(
                wholesalerGroupId,
            ),
        );
    }

    async getDivisionProductCatalogExport(wholesalerId: string) {
        return this.datasourceDelineationService.makeCallWithBlobReturn(
            null,
            _ => this.wholesalerGroupProductCatalogItemOfflineService.getDivisionProductCatalogExport(
                wholesalerId,
            ),
            _ => this.wholesalerGroupProductCatalogItemOnlineService.getDivisionProductCatalogExport(
                wholesalerId,
            ),
        );
    }

    async getByWholesalerId(
        id: string
    ): Promise<GenericResponseDto<WholesalerGroupProductCatalogItem[]>> {
        const offline = (key: string) => {
            return this.wholesalerGroupProductCatalogItemOfflineService.getByWholesalerId(
                id
            );
        };
        const online = (key: string) => {
            return this.wholesalerGroupProductCatalogItemOnlineService.getByWholesalerId(
                id
            );
        };
        const response = await this.datasourceDelineationService.makeCall<
            string,
            WholesalerGroupProductCatalogItem[]
        >(id, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getByWholesalerGroupId(
        id: string
    ): Promise<GenericResponseDto<WholesalerGroupProductCatalogItem[]>> {
        const offline = (key: string) => {
            return this.wholesalerGroupProductCatalogItemOfflineService.getByWholesalerGroupId(
                id
            );
        };
        const online = (key: string) => {
            return this.wholesalerGroupProductCatalogItemOnlineService.getByWholesalerGroupId(
                id
            );
        };
        const response = await this.datasourceDelineationService.makeCall<
            string,
            WholesalerGroupProductCatalogItem[]
        >(id, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getByWholesalerGroupMemberId(
        key: string
    ): Promise<GenericResponseDto<WholesalerGroupProductCatalogItem[]>> {
        const offline = (key: string) => {
            return this.wholesalerGroupProductCatalogItemOfflineService.getByWholesalerGroupMemberId(
                key
            );
        };
        const online = (key: string) => {
            return this.wholesalerGroupProductCatalogItemOnlineService.getByWholesalerGroupMemberId(
                key
            );
        };
        const response = await this.datasourceDelineationService.makeCall<
            string,
            WholesalerGroupProductCatalogItem[]
        >(key, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getByWholesalerGroupIdsAndProductId(
        ids: string[],
        productId: string
    ): Promise<GenericResponseDto<WholesalerGroupProductCatalogItem[]>> {
        const params = new WholesalerGroupIdsAndProductIdParamsDto();
        params.productId = productId;
        params.wholesalerGroupIds = ids;

        const offline = (key: WholesalerGroupIdsAndProductIdParamsDto) => {
            return this.wholesalerGroupProductCatalogItemOfflineService.getByWholesalerGroupIdsAndProductId(
                key
            );
        };
        const online = (key: WholesalerGroupIdsAndProductIdParamsDto) => {
            return this.wholesalerGroupProductCatalogItemOnlineService.getByWholesalerGroupIdsAndProductId(
                key
            );
        };
        const response = await this.datasourceDelineationService.makeCall<
            WholesalerGroupIdsAndProductIdParamsDto,
            WholesalerGroupProductCatalogItem[]
        >(params, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getByProjectProduct(
        projectProduct: ProjectProduct
    ): Promise<GenericResponseDto<WholesalerGroupProductCatalogItem>> {
        const offline = (key: ProjectProduct) => {
            return this.wholesalerGroupProductCatalogItemOfflineService.getByProjectProduct(
                key
            );
        };
        const online = (key: ProjectProduct) => {
            return this.wholesalerGroupProductCatalogItemOfflineService.getByProjectProduct(
                key
            );
        };
        const response = await this.datasourceDelineationService.makeCall<
            ProjectProduct,
            WholesalerGroupProductCatalogItem
        >(projectProduct, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }
}
