import { Component, HostListener, OnInit } from "@angular/core";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ConfirmationDialogViewmodel } from "./confirmation-dialog.viewmodel";

@Component({
    selector: "app-confirmation-dialog",
    templateUrl: "./confirmation-dialog.component.html",
    styleUrls: ["./confirmation-dialog.component.css"]
})
export class ConfirmationDialogComponent implements OnInit {
    header: string;
    message: string;
    buttonLeftFunction: () => void;
    buttonLeftText: string;
    buttonMiddleFunction: () => void;
    buttonMiddleText: string;
    buttonRightFunction: () => void;
    buttonRightDisableFunction: () => boolean;
    buttonRightText: string;
    blocking = false;

    constructor(
        private ref: SwisherOverlayRef<
            ConfirmationDialogViewmodel,
            ConfirmationDialogComponent
        >
    ) {}

    ngOnInit(): void {
        this.header = this.ref.data?.header;
        this.message = this.ref.data?.message;
        this.buttonLeftText = this.ref.data?.buttonLeftText;
        this.blocking = this.ref.data?.blocking;

        if (!this.ref.data?.buttonLeftFunction) {
            this.buttonLeftFunction = () => this.ref.close();
        } else {
            this.buttonLeftFunction = this.ref.data.buttonLeftFunction;
        }

        this.buttonMiddleText = this.ref.data.buttonMiddleText;
        if (!this.ref.data?.buttonMiddleFunction) {
            this.buttonMiddleFunction = () => this.ref.close();
        } else {
            this.buttonMiddleFunction = this.ref.data.buttonMiddleFunction;
        }

        this.buttonRightText = this.ref.data.buttonRightText;

        if (!this.ref.data.buttonRightText) {
            this.buttonRightFunction = () => this.ref.close();
        } else {
            this.buttonRightFunction = this.ref.data.buttonRightFunction;
        }
        if (this.ref.data.buttonRightDisableFunction) {
            this.buttonRightDisableFunction = this.ref.data.buttonRightDisableFunction;
        }
    }

    @HostListener('window:keyup', ['$event'])
        onKeyUp(event: KeyboardEvent): void {
            if (event.key === 'Enter' && this.ref) {
                this.ref.data.buttonRightFunction()
            }
        }

    close(): void {
        this.ref.close();
    }
}
