import { Component, ElementRef, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { faFileExcel, faSave, faTimes, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { MySearchesViewmodel } from "./my-searches.viewmodel";
import {
    newSequentialId,
    RefinerLocation,
    SharedHelper,
    valueSeparator
} from "shield.shared";
import { OverlayService } from "src/app/services/overlay.service";
import { FilterService } from "src/app/services/filter.service";
import { Subscription } from "rxjs";
import { AppStateService } from "src/app/services/app-state.service";
import { FilterSaveViewmodel } from "src/app/dialogs/filter-save-dialog/filter-save.viewmodel";
import { FilterSaveDialogComponent } from "src/app/dialogs/filter-save-dialog/filter-save-dialog.component";
import { Helper } from "src/app/helpers/helper";
import { Filter } from "src/app/entity-models/filter.entity";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { map } from "rxjs/operators";
import { SnackbarService } from "src/app/services/snackbar.service";
import { MyListCommands } from "src/app/enums/my-list-commands";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import * as XLSX from "xlsx";
import { FilterLocation } from "src/app/enums/filter-location";
import { MySearchesParityService } from "./my-searches-parity.service";
import { SpecialCoverageInformationDialogViewModel } from "./special-coverage-information-dialog/special-coverage-information-dialog.viewmodel";
import { SpecialCoverageInformationDialogComponent } from "./special-coverage-information-dialog/special-coverage-information-dialog.component";

@UntilDestroy()
@Component({
    selector: "app-my-searches",
    templateUrl: "./my-searches-filter.component.html",
    styleUrls: ["./my-searches-filter.component.scss"],
    providers: [MySearchesParityService]
})
export class MySearchesFilterComponent
    extends FilterBaseComponent
    implements OnInit {
    employeeSubscription: Subscription;

    filtersLoaded = false;

    faFileExcel = faFileExcel;
    faSave = faSave;
    faTimes = faTimes;
    faTrash = faTrash;
    faUpload = faUpload;
    showSpecialCoverage: boolean;

    icon = "search";
    name = "My Searches";
    viewmodel = new MySearchesViewmodel();

    @ViewChild("coverageInput") coverageInput: ElementRef;

    contentComponent: Type<MySearchesFilterComponent>;
    constructor(
        private overlayService: OverlayService,
        private filterService: FilterService,
        private appStateService: AppStateService,
        private snackbarService: SnackbarService,
        private parityService: MySearchesParityService
    ) {
        super();
    }

    ngOnInit(): void {
        this.showSpecialCoverage = this._filterLocation === FilterLocation.customerList;

        this.setInitialized();

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.employeeSubscription = this.appStateService.currentEmployee
                .pipe(untilDestroyed(this))
                .subscribe(async (employee) => {
                    this.viewmodel.employee = employee;
                    if (this.viewmodel.employee?.user) {
                        this.viewmodel.userFilter = await this.filterService.getUserFilters(
                            this.viewmodel.employee.user.localAccountId,
                            this._filterLocation
                        );
                        if (
                            this.viewmodel.userFilter &&
                            !this.viewmodel.userFilter.myList
                        ) {
                            this.viewmodel.userFilter.myList = new Array<string>();
                        }
                        if (
                            this.viewmodel.userFilter &&
                            !this.viewmodel.userFilter.specialCoverage
                        ) {
                            this.viewmodel.userFilter.specialCoverage = new Array<string>();
                        }
                        this.filtersLoaded = true;
                        this.refinerService.refiners.forEach((refiner) => this.onInputChange(refiner));
                    }
                });
        }
    }

    onMyListEmpty(): void {
        const hasItems = this.viewmodel.userFilter?.myList?.length > 0;
        if (!hasItems) {
            this.snackbarService.showInfo(
                "Your 'My List' does not contain any items."
            );
            return;
        }

        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "Are you sure? Once deleted, your current list will not be recoverable.";
        data.buttonLeftText = "Cancel";
        data.buttonLeftFunction = () => {
            this.viewmodel.confirmationOverlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.viewmodel.confirmationOverlayRef.close(data);
        };

        this.viewmodel.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.viewmodel.confirmationOverlayRef.afterClosed$
            .pipe(
                untilDestroyed(this),
                map(async (event) => {
                    if (event && event.data && event.data.isConfirmed) {
                        this.viewmodel.myListSelected = false;
                        this.viewmodel.userFilter.myList = new Array<string>();
                        await this.filterService.saveUserSearch(
                            this.viewmodel.userFilter
                        );
                        this.refinerService.removeRefinerByLocation(
                            RefinerLocation.myList
                        );
                        this.onMyListToggle();
                    }
                })
            )
            .subscribe();
    }

    onMyListPush(): void {
        this.viewmodel.myListLoading = true;
        this.filterService.myListCommand$.next(MyListCommands.push);

        if (
            !this.viewmodel.newEntriesSubscription ||
            this.viewmodel.newEntriesSubscription.closed
        ) {
            this.viewmodel.newEntriesSubscription = this.filterService.myListEntriesObservable.pipe(untilDestroyed(this)).subscribe(
                async (data) => {
                    if (data?.length) {
                        const newEntries = data.filter(
                            (v) => !this.viewmodel.userFilter.myList.includes(v)
                        );
                        this.viewmodel.userFilter.myList = this.viewmodel.userFilter.myList.concat(
                            newEntries
                        );

                        await this.filterService.saveUserSearch(
                            this.viewmodel.userFilter
                        );
                    }
                    this.viewmodel.myListLoading = false;
                }
            );
        }
    }

    onMyListToggle(): void {
        this.filterService.myListEntries$.next(
            this.viewmodel.userFilter.myList
        );

        if (this.viewmodel.myListSelected) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.myList;
            refiner.value = this.viewmodel.userFilter.myList.length.toString() + " Selected";
            refiner.dataValue = this.viewmodel.userFilter.myList.join(valueSeparator);
            this.refinerService.addRefiners(refiner);
            this.parityService.checkParityOnRefinerAdd(refiner);
        } else {
            this.refinerService.removeRefinerByLocation(RefinerLocation.myList);
        }

        this.filterService.myListCommand$.next(
            this.viewmodel.myListSelected
                ? MyListCommands.toggleOn
                : MyListCommands.toggleOff
        );
    }

    onOpenCreateFilter(): void {
        const data = new FilterSaveViewmodel();
        data.name = this.viewmodel.selectedFilter;
        data.existingFilterNames = this.viewmodel.userFilter.myFilters.map(
            (filter) => filter.name
        );
        data.buttonLeftDisabledFunction = () => {
            return false;
        };
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.viewmodel.filterOverLayRef.close(data);
        };
        data.buttonRightDisabledFunction = () => {
            return !data.name;
        };
        data.width = "23vw";

        this.viewmodel.filterOverLayRef = this.overlayService.open(
            FilterSaveDialogComponent,
            data,
            true
        );

        this.viewmodel.filterOverLayRef.afterClosed$.pipe(untilDestroyed(this)).subscribe((filterData) => {
            if (filterData && filterData.data?.isConfirmed) {
                if (filterData.data.name) {
                    const index = this.viewmodel.userFilter.myFilters.findIndex(
                        (filter) =>
                            Helper.equalsIgnoreCase(
                                filter.name,
                                filterData.data.name
                            )
                    );

                    const myFilter = new Filter();

                    if (index !== -1) {
                        myFilter.id = this.viewmodel.userFilter.myFilters[
                            index
                        ].id;
                        myFilter.name = filterData.data.name;
                        myFilter.refiners = this._refinerService.refiners;
                        this.viewmodel.userFilter.myFilters.splice(
                            index,
                            1,
                            myFilter
                        );
                    } else {
                        const myFilterRefiner = new Refiner();
                        myFilterRefiner.location = RefinerLocation.myFilter;
                        this._refinerService.removeRefinerByLocation(
                            myFilterRefiner.location
                        );
                        myFilter.id = newSequentialId();
                        myFilter.name = filterData.data.name;
                        myFilter.refiners = this._refinerService.refiners.slice();
                        this.viewmodel.userFilter.myFilters.push(myFilter);
                    }

                    void this.filterService.saveUserSearch(
                        this.viewmodel.userFilter
                    );

                    this.viewmodel.selectedFilter = myFilter.name;
                    this.filterSelectionChange();
                }
            }
        });
    }

    onOpenSpecialCoverageInfoModal(): void {
        const data = new SpecialCoverageInformationDialogViewModel();

        data.buttonRightText = "Import";
        data.buttonRightDisabledFunction = () => false;
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.viewmodel.specialCoverageInfoOverlayRef.close(data);
        }

        this.viewmodel.specialCoverageInfoOverlayRef = this.overlayService.open(
            SpecialCoverageInformationDialogComponent,
            data,
            true
        );

        this.viewmodel.specialCoverageInfoOverlayRef.afterClosed$
            .pipe(
                untilDestroyed(this),
                map(async (event) => {
                    if (event && event.data && event.data.isConfirmed) {
                        if (!this.viewmodel.specialCoverageSelected) {
                            this.viewmodel.specialCoverageSelected = true;
                            this.onSpecialCoverageToggle();
                        }
                        setTimeout(() => this.coverageInput.nativeElement.click(), 0);
                    }
                })
            )
            .subscribe();
    }

    onInputChange?(refiner: Refiner): void {

        switch (refiner.location) {
            case RefinerLocation.myFilter:
                this.viewmodel.selectedFilter = refiner.value;
                if (!this.viewmodel.selectedFilter) {
                    this.filterSelectionChange();
                }
                break;
            case RefinerLocation.myList:
                if (!refiner.value) {
                    this.refinerService.removeRefinerByLocation(RefinerLocation.myList);
                    this.viewmodel.myListSelected = false;
                } else {
                    this.viewmodel.myListSelected = true;
                }
                break;
            case RefinerLocation.specialCoverage:
                if (!refiner.value) {
                    this.refinerService.removeRefinerByLocation(refiner.location);
                    this.viewmodel.specialCoverageSelected = false;
                } else {
                    this.viewmodel.specialCoverageSelected = true;
                }
                break;
            default:
                break;
        }
    }

    onRefinersChange(): void {
        let found = this.refinerService.refiners?.find(
            (refiner) => refiner.location === RefinerLocation.myFilter
        );
        this.viewmodel.selectedFilter = found ? found.value : "";

        found = this.refinerService.refiners?.find(
            (refiner) => refiner.location === RefinerLocation.myList
        );
        this.viewmodel.myListSelected = found ? true : false;

        found = this.refinerService.refiners?.find(
            (refiner) => refiner.location === RefinerLocation.specialCoverage
        );
        this.viewmodel.specialCoverageSelected = !!found;
    }

    onSpecialCoverageChange(event: Event): void {
        const uploadedFiles = (event.target as any).files as File[];
        if (!uploadedFiles || !uploadedFiles.length) return;

        for (const file of uploadedFiles) {
            if (!file.name?.endsWith("xlsx") && !file.name?.endsWith("xls") && !file.name?.endsWith("csv")) {
                this.snackbarService.showWarning("Only Excel (.xls, .xlsx) or CSV (.csv) files can be added to Special Coverage.");
                return;
            }
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
                const workbook = XLSX.read(event.target.result, {type: "binary"});
                const jsonFromSheet = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: "A" }) as any[];

                const firstRow = jsonFromSheet[0];
                if (!firstRow) {
                    this.snackbarService.showWarning(
                        `${file.name}: The header row is empty. Please check your file and try again.`
                    );
                    return;
                }

                let retailNumberColumnHeader: string;

                const longestRow = jsonFromSheet.map(v => Object.keys(v).length).sort((a, b) => a > b ? -1 : 1)[0];
                if (longestRow > 1) {
                    const headers = Object.keys(firstRow);
                    for (const header of headers) {
                        if (SharedHelper.searchStringArray(firstRow[header], this.viewmodel.headerNames)) {
                            retailNumberColumnHeader = header;
                            break;
                        }
                    }
                    if (!retailNumberColumnHeader) {
                        this.snackbarService.showWarning(
                            `${file.name}: Could not find a column with "Retail Number" as its header. Please check your file and try again.`
                        );
                        return;
                    }
                } else { // sheet only has one column, so no need to check the header
                    retailNumberColumnHeader = "A";
                }

                const entries = jsonFromSheet.map(v => String(v[retailNumberColumnHeader]));
                this.setSpecialCoverage(entries);
            };
            fileReader.readAsBinaryString(file);
        }
    }

    onSpecialCoverageEmpty(): void {
        const hasItems = this.viewmodel.userFilter?.specialCoverage?.length > 0;
        if (!hasItems) {
            this.snackbarService.showInfo(
                "Your Special Coverage list does not contain any items."
            );
            return;
        }

        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "Are you sure you wish to clear your Special Coverage list?";
        data.buttonLeftText = "Cancel";
        data.buttonLeftFunction = () => {
            this.viewmodel.confirmationOverlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.viewmodel.confirmationOverlayRef.close(data);
        };

        this.viewmodel.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.viewmodel.confirmationOverlayRef.afterClosed$
            .pipe(
                untilDestroyed(this),
                map(async (event) => {
                    if (event && event.data && event.data.isConfirmed) {
                        this.viewmodel.userFilter.specialCoverage = new Array<string>();
                        await this.filterService.saveUserSearch(
                            this.viewmodel.userFilter
                        );
                        this.onSpecialCoverageToggle();
                    }
                })
            )
            .subscribe();
    }

    onSpecialCoverageToggle(): void {
        if (this.viewmodel.specialCoverageSelected) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.specialCoverage;
            refiner.value = this.viewmodel.userFilter.specialCoverage.length.toString() + " Selected";
            refiner.dataValue = this.viewmodel.userFilter.specialCoverage.join(valueSeparator);
            this.refinerService.addRefiners(refiner);
            this.parityService.checkParityOnRefinerAdd(refiner);
        } else {
            this.refinerService.removeRefinerByLocation(RefinerLocation.specialCoverage);
        }

        this.filterService.specialCoverage$.next(true);
    }

    filterSelectionChange(): void {
        if (!this.viewmodel.selectedFilter) {
            this.viewmodel.selectedFilter = "";
            if (!this.viewmodel.isDefaultFilterSelection) {
                this._refinerService.replaceRefiners(
                    this.viewmodel.originalRefinerList
                );
            }
            this.viewmodel.isDefaultFilterSelection = true;
        } else {
            if (this.viewmodel.isDefaultFilterSelection) {
                this.saveOffOriginalFilters();
                this.viewmodel.isDefaultFilterSelection = false;
            }

            const filter = this.viewmodel.userFilter.myFilters.find(
                (myfilter) => myfilter.name === this.viewmodel.selectedFilter
            );

            const refinersToLoad = filter.refiners;
            const myFilterRefiner = new Refiner();
            myFilterRefiner.location = RefinerLocation.myFilter;
            myFilterRefiner.value = this.viewmodel.selectedFilter;
            const existingMyFilter = refinersToLoad.find(
                (r) => r.location === myFilterRefiner.location
            );
            if (existingMyFilter) {
                refinersToLoad.splice(
                    refinersToLoad.indexOf(existingMyFilter),
                    1
                );
            }
            refinersToLoad.push(myFilterRefiner);

            this._refinerService.replaceRefiners(refinersToLoad);
        }
    }

    saveOffOriginalFilters(): void {
        this.viewmodel.originalRefinerList = [];
        for (const refiner of this._refinerService.refiners) {
            const myRefiner = new Refiner();
            myRefiner.location = refiner.location;
            myRefiner.value = refiner.value;
            myRefiner.dataValue = refiner.dataValue;
            this.viewmodel.originalRefinerList.push(myRefiner);
        }
    }

    confirmFilterDeletion(): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "Are you sure? Once deleted, Filter: " +
            this.viewmodel.selectedFilter +
            "  will not be recoverable.";

        data.buttonLeftText = "Cancel";
        data.buttonLeftFunction = () => {
            this.viewmodel.confirmationOverlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.viewmodel.confirmationOverlayRef.close(data);
        };

        this.viewmodel.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.viewmodel.confirmationOverlayRef.afterClosed$.pipe(untilDestroyed(this)).subscribe(
            async (event) => {
                if (event && event.data && event.data.isConfirmed) {
                    const index = this.viewmodel.userFilter.myFilters.findIndex(
                        (filter) =>
                            filter.name === this.viewmodel.selectedFilter
                    );

                    if (index !== -1) {
                        this.viewmodel.selectedFilter = "";
                        this.viewmodel.userFilter.myFilters.splice(index);
                        this.filterSelectionChange();
                        await void this.filterService.saveUserSearch(
                            this.viewmodel.userFilter
                        );
                    }
                }
            }
        );
    }

    private setSpecialCoverage(customerNumbers: string[]): void {
        if (!customerNumbers || !customerNumbers.length) return;

        // sanitize the header names
        customerNumbers = customerNumbers.filter(entry => !SharedHelper.searchStringArray(entry, this.viewmodel.headerNames));

        const newSpecialCoverage = (this.viewmodel.userFilter.specialCoverage ?? [])
            .filter(v => !customerNumbers.includes(v))
            .concat(customerNumbers);
        this.viewmodel.userFilter.specialCoverage = newSpecialCoverage;
        void this.filterService.saveUserSearch(
            this.viewmodel.userFilter
        );
        this.onSpecialCoverageToggle();
    }
}
