import { CallPicture } from "src/app/entity-models/call-picture.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { WholesaleCall } from "src/app/entity-models/wholesale-call.entity";
import { ActivitySurveyViewModel } from "../../account.viewmodels/activity-survey.viewmodel";
export class WholsaleCallViewmodel {
    wholesaleCall: WholesaleCall;
    surveys: ActivitySurveyViewModel[] = [];
    pictures: CallPicture[] = [];
    isReturns: boolean;
    isPurchase: boolean;
    isSwisherDay: boolean;
    isTradeShow: boolean;
    purchaseInvoice: string;
    creditMemo: string;
    billThrough: string;
    purchaseAmount: number;
    creditAmount: number;
    closingNotes: string;
    callComments: string;
    shareEmployees: Employee[] = [];

    //modal props
    headerLeftText: string;
    headerRightText: string;
    width = "95vw";
    height = "95vh";

    buildViewModelFromDomainModel(wc: WholesaleCall): void {
        if (wc) {
            this.wholesaleCall = wc;
            this.surveys = wc.surveys?.slice();
            this.pictures = wc.callPictures?.slice();
            this.isReturns = wc.isReturns;
            this.isPurchase = wc.isPurchase;
            this.isSwisherDay = wc.isSwisherDay;
            this.isTradeShow = wc.isTradeShow;
            this.purchaseInvoice = wc.purchaseInvoice;
            this.creditMemo = wc.creditMemo;
            this.billThrough = wc.billThrough;
            this.purchaseAmount = wc.purchaseAmount;
            this.creditAmount = wc.creditAmount;
            this.closingNotes = wc.closingNotes;
        }
    }

    buildDomainModelFromViewModel(): WholesaleCall {
        const rtn = this.wholesaleCall;
        rtn.surveys = this.surveys;
        rtn.isReturns = this.isReturns;
        rtn.isPurchase = this.isPurchase;
        rtn.isSwisherDay = this.isSwisherDay;
        rtn.isTradeShow = this.isTradeShow;
        rtn.purchaseInvoice = this.purchaseInvoice;
        rtn.creditMemo = this.creditMemo;
        rtn.billThrough = this.billThrough;
        rtn.purchaseAmount = this.purchaseAmount;
        rtn.creditAmount = this.creditAmount;
        rtn.closingNotes = this.closingNotes;

        return rtn;
    }
}
