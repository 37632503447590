import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';

import { EditWholesalersViewModel } from './edit-wholesalers.viewmodel';

@Component({
    selector: 'app-edit-wholesalers',
    templateUrl: './edit-wholesalers.component.html',
    styleUrls: ['./edit-wholesalers.component.scss']
})
export class EditWholesalersComponent implements OnInit, OnDestroy {

    viewmodel: EditWholesalersViewModel;

    constructor(public injectedData: SwisherOverlayRef<
            EditWholesalersViewModel,
            EditWholesalersComponent
        >) { }

    ngOnInit(): void {
        this.viewmodel = this.injectedData.data;
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }
}
