import { GenericLookup } from "../generic";

export enum EmployeeLevel {
    UN = 0,
    TM = 1,
    RM = 2,
    ZM = 3,
    CS = 4,
}

export const EmployeeLevelLookup: GenericLookup<EmployeeLevel>[] = [
    { id: EmployeeLevel.UN, name: "UN", description: "Unknown" },
    { id: EmployeeLevel.TM, name: "TM", description: "Territory Manager" },
    { id: EmployeeLevel.RM, name: "RM", description: "Regional Manager" },
    { id: EmployeeLevel.ZM, name: "ZM", description: "Zone Manager" },
    { id: EmployeeLevel.CS, name: "CS", description: "Customer Service" }
];
