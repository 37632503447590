import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';

import { ViewAgreementViewModel } from './view-agreement.viewmodel';

@Component({
    selector: 'app-view-agreement',
    templateUrl: './view-agreement.component.html',
    styleUrls: ['./view-agreement.component.scss']
})
export class ViewAgreementComponent implements OnInit, AfterViewChecked {

    viewmodel: ViewAgreementViewModel;
    viewSet: boolean = false;

    @ViewChild("agreement") agreement: ElementRef<any>;

    constructor(public injectedData: SwisherOverlayRef<
            ViewAgreementViewModel,
            ViewAgreementComponent
        >) { }

    ngOnInit(): void {
        this.viewmodel = this.injectedData.data;
    }

    ngAfterViewChecked(): void {
        if (!this.viewSet) {
            this.viewSet = true;
            this.viewmodel.agreement = this.agreement;
        }
    }
}
