export enum GratisListColumns {
    gratisRequestNumber = "Gratis Request #",
    name = "Name",
    startDate = "Start Date",
    endDate = "End Date",
    purpose = "Purpose",
    status = "Status",
    daysWaiting = "Days Waiting",
    available = "Available",
    requested = "Requested",
    reimbursed = "Reimbursed",
    carryForward = "Carried Forward",
    manualGratis = "Manual Gratis"
}
