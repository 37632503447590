import { Injectable } from "@angular/core";
import { SystemInformationKeys } from "shield.shared";
import { SystemInformation } from "src/app/entity-models/system-information.entity";
import { DatabaseService } from "../database.service";

@Injectable()
export class SystemInformationOfflineService {

    constructor(private dbService: DatabaseService){}

    async getByKey(key: SystemInformationKeys): Promise<SystemInformation> {
        const result = await this.dbService.systemInformation.where("key").equals(key).first();
        return result;
    }
}
