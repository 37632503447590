import { GridButton } from "../grid/grid.button";

export class ColumnDef {
    headerName: string;
    dataPropertyName: string; // this is the rowDef.column name. EX: If nested ? parentProperty.childproperty : parentProperty
    isDataNested?: boolean = false;
    nestedDataParentPropertyName?: string; // EX: The data property at the row level EX: for a nested data property like businessAddress.city => "businessAddress"
    nestedDataPropertyName?: string // EX: The data property at the row level EX: for a nested data property like businessAddress.city => "city"
    isAvailable: boolean = true;
    isSelected?: boolean = false;
    columnClass?: string;
    ngColumnClass?: string;
    ngColumnClassCriterion?: (index: number) => boolean;
    isSelectable?: boolean = false;
    selectDisableCriterion?: (index: number) => boolean;
    isTemplate?: boolean = false;
    dataType?: number;
    clickFunction?: (event: MouseEvent, index: number) => void;
    isVisible?: (index: number) => void;
    showIconMarker? = false;
    iconName? = 'edit';
    buttons?: GridButton[];
}
