import { GratisApprovalDto } from "shield.shared";

import { GratisApproval } from "src/app/entity-models/gratis-approval.entity";

export class GratisApprovalConverterService {
    static dtoToEntity(
        dto: GratisApprovalDto,
        gratisId: string
    ): GratisApproval {
        const entity = new GratisApproval();

        entity.id = dto.id;
        entity.gratisId = gratisId;
        entity.employeeLevel = dto.employeeLevel;
        entity.employeeId = dto.employeeId;
        entity.approvalStatus = dto.approvalStatus;
        entity.date = dto.date ? new Date(dto.date) : null;
        entity.reason = dto.reason;
        return entity;
    }

    static entityToDto(entity: GratisApproval): GratisApprovalDto {
        const dto = new GratisApprovalDto();

        dto.id = entity.id;
        dto.employeeLevel = entity.employeeLevel;
        dto.employeeId = entity.employeeId;
        dto.approvalStatus = entity.approvalStatus;
        dto.date = entity.date ? entity.date.toISOString() : null;
        dto.reason = entity.reason;

        return dto;
    }
}
