<div class="card card--call-history m-0">
    <div class="card-header">
        {{ callHistoryTitle }}
    </div>
        <ng-container *ngIf="!callHistoryAccountProfileViewModel?.length">
            <div class="d-flex justify-content-center align-items-center card-body p-0">
                <div class="customer-overview-tiles-empty-state">
                    <div class="empty-state-header">
                        <div class="icon">
                            <span class="material-icons"
                            >phone_in_talk</span
                            >
                        </div>
                        <h6>No calls have been placed for this customer.</h6>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="card-body p-0" *ngIf="callHistoryAccountProfileViewModel?.length">
            <div class="card-view-port">
                <div class="row mobile-col">
                    <div
                        *ngIf="hasBeforePicture"
                        class="col mobile-justify-content-start"
                    >
                        <img
                            *ngIf="beforeImage"
                            (click)="openPictureInModal(beforeImage, 'Before')"
                            [src]="beforeImage"
                            alt="Before Picture"
                            [ngClass]="afterImage && beforeImage ? 'before-profile-img mobile-photo' : 'single-profile-img mobile-photo'"
                            id="BeforeProfileImg"
                            [width]="200"
                            [height]="150"
                            type=""
                        />
                    </div>
                    <div
                        *ngIf="hasAfterPicture"
                        class="col mobile-justify-content-start"
                    >
                        <img
                            *ngIf="afterImage"
                            (click)="openPictureInModal(afterImage, 'After')"
                            [src]="afterImage"
                            alt="After Picture"
                            [ngClass]="afterImage && beforeImage ? 'after-profile-img mobile-photo' : 'single-profile-img mobile-photo'"
                            id="AfterProfileImg"
                            [width]="200"
                            [height]="150"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <table
                            class="table table-striped table-hover mb-0 hide-mobile table-header-sm"
                        >
                            <thead>
                            <tr>
                                <th class="column-width-sm" scope="col" id="photos">
                                    Photos
                                </th>
                                <th class="column-width-sm" scope="col" id="fullName">
                                    Full Name
                                </th>
                                <th class="column-width-sm" scope="col" id="closingNotes">
                                    Comments
                                </th>
                                <th class="column-width-sm" scope="col" id="createdDate">
                                    Created
                                </th>
                                <th class="column-width-sm" scope="col" id="duration">
                                    Duration
                                </th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let vm of callHistoryAccountProfileViewModel">
                                <td>
                                    <div *ngIf="vm.hasCallPictures">
                                        <span (click)="processPictures(vm.call)"
                                            class="flaticon-photo-camera single-icon-on-white"
                                        ></span>
                                    </div>
                                </td>
                                <td>{{ vm.fullName }}</td>
                                <td>
                                    {{ vm.closingNotes }}
                                </td>
                                <td>
                                    {{
                                    vm.createdDate
                                        | date: dateFormat
                                    }}
                                </td>
                                <td>{{ vm.duration }}</td>
                                <td class="text-right">
                                    <a appThrottleClick (throttledClick)="openClosingNotes(vm)"
                                        class="single-icon-on-white">
                                        <span class="flaticon-eye-variant-with-enlarged-pupil">
                                        </span>
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div
                            class="show-mobile w-100 mobile-card-container"
                        >
                            <div
                                *ngFor="
                                    let vm of callHistoryAccountProfileViewModel
                                "
                                class="mobile-card p-3 d-flex flex-column border w-100"
                            >
                                <table>
                                    <tr class="border-bottom">
                                        <td><b>Full Name</b></td>
                                        <td>{{ vm.fullName }}</td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td><b>Comments</b></td>
                                        <td>
                                            {{ vm.closingNotes }}
                                        </td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td><b>Created</b></td>
                                        <td>
                                            {{
                                            vm.createdDate
                                                | date: dateFormat
                                            }}
                                        </td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td><b>Duration</b></td>
                                        <td>{{ vm.duration }}</td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td>
                                            <b>Actions</b>
                                        </td>
                                        <td>
                                            <span
                                                *ngIf="
                                                    vm.beforeCallPicture ||
                                                    vm.afterCallPicture
                                                "
                                                class="single-icon-on-white d-inline-block mr-1"
                                            >
                                                <span
                                                    class="flaticon-photo-camera"
                                                ></span>
                                            </span>
                                            <a
                                                appThrottleClick (throttledClick)="openClosingNotes(vm)"
                                                class="single-icon-on-white d-inline-block"
                                            >
                                                <span
                                                    class="flaticon-eye-variant-with-enlarged-pupil"
                                                ></span>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>
