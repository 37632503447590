import {
    GratisAssignedTransactionLineItemDto,
    TransactionLineItemTypeLookup
} from "shield.shared";

import { GratisAssignedTransactionLineItem } from "src/app/entity-models/gratis-assigned-transaction-line-item.entity";

export class GratisAssignedTransactionLineitemConverterService {
    static dtoToEntity(
        dto: GratisAssignedTransactionLineItemDto,
        gratisAssignedTransactionId: string
    ): GratisAssignedTransactionLineItem {
        const entity = new GratisAssignedTransactionLineItem();

        entity.id = dto.id;
        entity.gratisAssignedTransactionId = gratisAssignedTransactionId;
        entity.wholesalerId = dto.wholesalerId;
        entity.wholesalerName = dto.wholesalerName;
        entity.productId = dto.productId;
        entity.productDescription = dto.productDescription;
        entity.type = dto.transactionType.id;
        entity.typeDescription = dto.transactionType.description;
        entity.quantity = dto.quantity;
        entity.uin = dto.uin;
        entity.upc = dto.upc;
        entity.units = dto.units;
        entity.price = dto.price;
        entity.wholesalePrice = dto.wholesalePrice;
        entity.salesTotal = dto.salesTotal;
        entity.wholesaleTotal = dto.wholesaleTotal;
        entity.discount = dto.discount;
        entity.netTotal = dto.netTotal;
        entity.gratisTotal = dto.gratisTotal;

        return entity;
    }

    static entityToDto(
        entity: GratisAssignedTransactionLineItem
    ): GratisAssignedTransactionLineItemDto {
        const dto = new GratisAssignedTransactionLineItemDto();

        dto.id = entity.id;
        dto.wholesalerId = entity.wholesalerId;
        dto.wholesalerName = entity.wholesalerName;
        dto.productId = entity.productId;
        dto.productDescription = entity.productDescription;
        const transactionType = TransactionLineItemTypeLookup.find(
            (lu) => lu.id === entity.type
        );
        if (transactionType) {
            dto.transactionType = transactionType;
        }
        dto.quantity = entity.quantity;
        dto.uin = entity.uin;
        dto.upc = entity.upc;
        dto.units = entity.units;
        dto.price = entity.price;
        dto.wholesalePrice = entity.wholesalePrice;
        dto.salesTotal = entity.salesTotal;
        dto.wholesaleTotal = entity.wholesaleTotal;
        dto.discount = entity.discount;
        dto.netTotal = entity.netTotal;
        dto.gratisTotal = entity.gratisTotal;

        return dto;
    }
}
