<div class="card card--contacts m-0">
    <div
        class="card-header"
    >
        Contacts
    </div>
        <ng-container *ngIf="!contactOptions?.length">
            <div class="d-flex justify-content-center align-items-center card-body p-0">
                <div class="customer-overview-tiles-empty-state">
                    <div class="empty-state-header">
                        <div class="icon">
                            <span class="material-icons"
                            >contacts</span
                            >
                        </div>
                        <h6>There are currently no associated contacts.</h6>
                        <button appThrottleClick class="btn btn-white btn-sm" (throttledClick)="onAddContact.emit()">
                            Add Contact
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="card-body p-0" *ngIf="contactOptions?.length">
            <div class="card-view-port">
                <table class="table table-striped table-hover mb-0 table-header-sm">
                    <thead>
                    <tr>
                        <th scope="col">
                            Name
                        </th>
                        <th scope="col">Phone</th>
                        <th scope="col">Available</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let option of contactOptions">
                        <td>
                            <div
                                class="overflow-text"
                                title="{{ option.firstName }} {{
                                        option.lastName
                                    }}"
                            >
                                {{ option.firstName }}
                                {{ option.lastName }}
                            </div>
                        </td>
                        <td>
                            {{ option.phoneNumber }}
                        </td>
                        <td>{{ option.availableContactDays }}</td>
                        <td>
                            <div class="d-flex">
                                <a class="single-icon-on-white mr-1"
                                    appThrottleClick (throttledClick)="onEditContact.emit(option)">
                                    <span class="material-icons">edit</span>
                                </a>
                                <a class="single-icon-on-white"
                                    appThrottleClick (throttledClick)="onRemoveContact.emit(option)">
                                    <span class="material-icons">delete</span>
                                </a>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    <ng-container *ngIf="contactOptions?.length">
        <hr/>
        <div>
            <button appThrottleClick
                class="btn sw-red-btn btn-sm bottom-margin card-bottom"
                (throttledClick)="onAddContact.emit()"
            >
                Add New
            </button>
        </div>
    </ng-container>
</div>
