<div *ngIf="customer" class="profile-grid">
        <div class="profile-grid-item col-1--row-1">
            <div class="card m-0 h-100">
                <div
                    class="card-header d-flex justify-content-between align-items-center"
                >
                    Information
                    <div class="card-header-btn">
                        <button class="btn sw-transparent-btn btn-sm" [routerLink]="['../info']">
                            <span><fa-icon [icon]="faEye"></fa-icon></span>
                        </button>
                    </div>
                </div>
                <div class="card-body p-0 small-font">
                    <div class="card-view-port">
                        <div class="row bottom-padding">
                            <div class="col">
                                <div class="row bottom-padding">
                                    <div class="col">
                                        <b>Driving Address</b>
                                    </div>
                                    <div class="col">
                                        <b>Rep:</b> {{ customer.rep }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        {{ customer.name }}
                                    </div>
                                    <div *ngIf="customer.customerType?.name" class="col">
                                        {{ customer.customerType.name }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        {{ customer.businessAddress?.address1 }}
                                    </div>
                                    <div class="col"></div>
                                </div>
                                <div class="row bottom-padding">
                                    <div class="col">
                                        {{
                                        customer.businessAddress?.city
                                            ? customer.businessAddress?.city + ", "
                                            : ""
                                        }}
                                        {{
                                        customer.businessAddress?.state ? customer.businessAddress?.state : ""
                                        }}
                                        {{ customer.businessAddress?.zip ? customer.businessAddress?.zip : "" }}
                                    </div>
                                </div>
                                <div class="row" style="height: 25px">
                                    <div class="col"></div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <b>Status:</b>
                                        {{
                                        customer.isActive
                                            ? "Active"
                                            : "Not Active"
                                        }}
                                    </div>
                                    <div class="col shade">
                                        <b>State Tobacco License:</b>
                                        {{
                                            retailProfileViewmodel?.retailStateOTPLicense
                                            ? retailProfileViewmodel.retailStateOTPLicense
                                            : "N/A"
                                        }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <b>Px3 Rank:</b>
                                        {{ px3Rank !== undefined ? px3Rank : 'Not Ranked' }}
                                    </div>
                                    <div class="col shade">
                                        <b>State Vapor License:</b>
                                        {{
                                            retailProfileViewmodel?.retailStateVaporLicense
                                            ? retailProfileViewmodel.retailStateVaporLicense
                                            : "N/A"
                                        }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <b>MSA:</b>
                                        {{ customer.isMsa ? "Yes" : "No" }}
                                    </div>
                                    <div class="col shade">
                                        <b>State Tax ID:</b>
                                        {{
                                            retailProfileViewmodel?.stateTaxId
                                            ? retailProfileViewmodel.stateTaxId
                                            : "N/A"
                                        }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col"></div>
                                    <div class="col shade">
                                        <b>Federal Tax ID:</b>
                                        {{
                                            retailProfileViewmodel?.federalTaxId
                                            ? retailProfileViewmodel.federalTaxId
                                            : "N/A"
                                        }}
                                    </div>
                                </div>
                                <div class="row" style="height: 25px">
                                    <div class="col"></div>
                                </div>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                Store Comments
                                            </th>
                                            <th>
                                                Entered By
                                            </th>
                                            <th>
                                                Date
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {{customer.comments}}
                                            </td>
                                            <td>
                                                {{customer.commentsLastUpdatedBy}}
                                            </td>
                                            <td>
                                                {{customer.commentsLastUpdated | date: dateFormat}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="profile-grid-item col-2-3--row-1">
            <app-customer-detailed-call-history #history
            [customer]="customer"
            callHistoryTitle="Call History"
            [openClosingNotes]="openClosingNotes">
            </app-customer-detailed-call-history>
        </div>
        <div class="profile-grid-item col-4--row-1">
            <app-last-order [lastOrderLineItems]="lastOrderLineItems">
            </app-last-order>
        </div>
        <div class="profile-grid-item col-1--row-2">
            <app-related-contacts
                [customer]="customer"
                [contactOptions]="contactOptions"
                [currentEmployee]="currentEmployee"
                (onAddContact)="onAddContact()"
                (onEditContact)="onEditContact($event)"
                (onRemoveContact)="onRemoveContact($event)">
            </app-related-contacts>
        </div>
        <div class="profile-grid-item col-2--row-2">
            <app-retail-agreements
                [retailAgreements]="agreementOptions"
                [customer]="customer"
                [contactOptions]="contactOptions"
                [currentEmployee]="currentEmployee"
                (openNewAgreement)="openNewAgreement()"
                (openViewAgreement)="openViewAgreement($event)">
            </app-retail-agreements>
        </div>
        <div class="profile-grid-item col-3--row-2">
            <app-related-wholesalers
                [customerMembers]="customerMembers"
                (onOpenWholesaler)="onOpenWholesalerDialog($event)">
            </app-related-wholesalers>
        </div>
        <div class="profile-grid-item col-4--row-2">
            <app-related-projects
                [projects]="relatedProjects"
                (onOpenNewProject)="openNewProject()"
                (onOpenViewProject)="openViewProject($event)"
                [isRetail]="true"
                [isNotTm]="isNotTm">
            </app-related-projects>
        </div>
</div>
