<div class="card card--agreements m-0">
    <div
        class="card-header d-flex justify-content-between align-items-center"
    >
        Agreements
    </div>
        <ng-container *ngIf="!retailAgreements?.length">
            <div class="d-flex justify-content-center align-items-center card-body p-0">
                <div class="customer-overview-tiles-empty-state">
                    <div class="empty-state-header">
                        <div class="icon">
                            <span class="material-icons"
                            >task</span
                            >
                        </div>
                        <h6>There are currently no associated agreements.</h6>
                        <button appThrottleClick class="btn btn-white btn-sm"
                            (throttledClick)="shouldAddNew() ? openNewAgreement.emit() : showIfActive()"
                        >
                            Add Agreement
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="card-body p-0" *ngIf="retailAgreements?.length">
            <div class="card-view-port">
                <table class="table table-striped table-hover mb-0">
                    <thead>
                    <tr>
                        <th scope="col">Contract #</th>
                        <th scope="col">Level</th>
                        <th scope="col">Date Signed</th>
                        <th scope="col">Status</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let agreement of retailAgreements">
                        <td>{{ agreement.contractNumber }}</td>
                        <td>{{ agreement.level }}</td>
                        <td>{{ agreement.dateSigned }}</td>
                        <td>{{ agreement.status }}</td>
                        <td>
                            <a appThrottleClick (throttledClick)="openViewAgreement.emit(agreement)"
                            class="single-icon-on-white">
                                <span class="flaticon-eye-variant-with-enlarged-pupil"></span>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    <ng-container *ngIf="retailAgreements?.length">
        <hr/>
        <div>
            <button appThrottleClick
                class="btn sw-red-btn btn-sm bottom-margin card-bottom"
                (throttledClick)="shouldAddNew() ? openNewAgreement.emit() : showIfActive()"
            >
                Add New
            </button>
        </div>
    </ng-container>
</div>
