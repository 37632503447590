import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';

import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Subject, from } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { WholesalerGroupProductCatalogItem } from 'src/app/entity-models/wholesaler-group-product-catalog-item.entity';
import { WholesalerProductCatalogItems } from 'src/app/entity-models/wholesaler-product-catalog-items.entity';
import { AddProductViewModel } from 'src/app/products/add-products/add-product.viewmodel';
import { AddProductsComponent } from 'src/app/products/add-products/add-products.component';
import { AddProductsViewmodel } from 'src/app/products/add-products/add-products.viewmodel';
import { ProductDelineationService } from 'src/app/services/delineation-services/product-delineation.service';
import { WholesalerGroupProductCatalogItemDelineationService } from 'src/app/services/delineation-services/wholesaler-group-product-catalog-item-delineation.service';
import { DialogService } from 'src/app/services/dialog.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { PleaseWaitService } from 'src/app/services/please-wait.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ButtonActions } from 'src/app/shared/enums/button-actions.enum';
import { DeleteButtonComponent } from 'src/app/shared/page-header/buttons/delete-button/delete-button.component';
import { HeaderButtonComponent } from 'src/app/shared/page-header/buttons/header-button/header-button.component';
import { AvailableToOrderDialogComponent } from '../available-to-order-dialog/available-to-order-dialog.component';
import { AvailableToOrderDialogViewModel } from '../available-to-order-dialog/available-to-order-dialog.viewmodel';
import { WholesalerGroupProductsListViewModel } from './wholesaler-group-products-list.viewmodel';
import { WholesalerProductsListViewModel } from './wholesaler-products-list.viewmodel';

@Component({
    selector: 'wholesaler-products-dialog',
    templateUrl: './wholesaler-products-dialog.component.html',
    styleUrls: ['./wholesaler-products-dialog.component.scss']
})
export class WholesalerProductsDialogComponent implements OnInit, OnDestroy {

    @Input() viewmodel: WholesalerProductsListViewModel<WholesalerGroupProductCatalogItem | WholesalerProductCatalogItems>;
    form?: FormGroup;
    orderableDialog?: SwisherOverlayRef<AvailableToOrderDialogViewModel, AvailableToOrderDialogComponent>;
    private addProductDialog?: SwisherOverlayRef<AddProductsViewmodel, AddProductsComponent>;
    private destroy$ = new Subject<void>();
    deleteButtonList = [
        new DeleteButtonComponent(),
    ];


    constructor(
        private formBuilder: FormBuilder,
        private waitService: PleaseWaitService,
        private catalogItemService: WholesalerGroupProductCatalogItemDelineationService,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private dialogService: DialogService,
        private productService: ProductDelineationService,
        public productsRef?: SwisherOverlayRef<
            WholesalerProductsListViewModel<WholesalerGroupProductCatalogItem | WholesalerProductCatalogItems>,
            WholesalerProductsDialogComponent
        >,
    ) { }



    get isGroupLevel() {
        return this.viewmodel instanceof WholesalerGroupProductsListViewModel;
    }

    groupItem(item: WholesalerGroupProductCatalogItem | WholesalerProductCatalogItems)
        : WholesalerGroupProductCatalogItem {
        return this.viewmodel.getGroupItem(item);
    }

    ngOnInit(): void {
        this.viewmodel ??= this.productsRef!.data;
        this.form = this.formBuilder.group({});
        this.form.addControl("items", this.viewmodel.createFormRows(this.form, this.formBuilder));

        this.viewmodel.save$.pipe(takeUntil(this.destroy$)).subscribe(() => this.onSave());
        this.viewmodel.shouldClose = () => this.shouldClose();
        this.viewmodel.headerRightButtonClick$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.openAddProductDialog());
        this.viewmodel.iconButtonClicked.pipe(takeUntil(this.destroy$)).subscribe(icon =>
            this.iconButtonClicked(icon)
        );
    }

    onDeleteButtonListClick(index: number, button: HeaderButtonComponent) {
        if (button.buttonAction === ButtonActions.delete) {
            this.dialogService.showConfirmDialog(
                "Are you sure? If removed this product will no longer be orderable for this wholesaler",
                "Confirmation",
                "No, cancel",
                "Yes, remove",
            ).pipe(take(1)).subscribe(confirmed => {
                if (confirmed) {
                    const itemGroup = (this.form.get('items') as FormArray).controls[index] as FormGroup;
                    itemGroup.get('deactivate').setValue(true);
                    itemGroup.markAllAsTouched();
                    itemGroup.markAsDirty();
                }
            });
        }
    }

    iconButtonClicked(icon: HeaderButtonComponent<any>): void {
        if (icon.buttonAction === ButtonActions.exportToExcel) {
            this.exportToXlsx();
        }
    }

    async openAddProductDialog(): Promise<void> {
        const done$ = new BehaviorSubject<boolean>(false);
        this.waitService.showProgressSpinnerUntilLoaded(done$);
        const activeProduct = await this.productService.observableActiveProducts
            .pipe(filter(p => p.size > 0), take(1))
            .toPromise();
        done$.next(true);
        done$.complete();
        const data = new AddProductsViewmodel(true);
        data.buttonLeftText = "OK";
        data.buttonLeftFunction = () => this.addProducts(data.result);
        data.buildViewmodelProductsFromProductDomainModel(
            activeProduct,
            this.viewmodel.items.map(p => p.productId)
        );

        this.addProductDialog = this.overlayService.open(AddProductsComponent, data, true);
    }

    addProducts(products: AddProductViewModel[]): void {
        const selected = products.filter(p => p.selected);
        this.viewmodel.addProducts(selected, this.form, this.formBuilder);

        //Once the products have been added, close the add product dialog. The new products
        //should be visible in the products dialog now.
        this.addProductDialog.close(null, true);
    }

    openOrderableDialog(itemNumber: string) {
        if (this.viewmodel instanceof WholesalerGroupProductsListViewModel) {
            const item = this.viewmodel.items.find(i => i.product.itemNumber === itemNumber);
            if (!item) {
                throw "Item does not exist in data set.";
            }

            if (this.viewmodel.groupMembers === undefined) {
                throw "No group member list was provided.";
            }

            const viewModel = new AvailableToOrderDialogViewModel(
                item,
                this.viewmodel.groupMembers,
                this.viewmodel.canEdit,
                (remove) => this.wholesalersDialogComplete(remove),
            );

            this.orderableDialog = this.overlayService.open(
                AvailableToOrderDialogComponent,
                viewModel,
                true,
            );
        }
    }
    wholesalersDialogComplete(deactivate: boolean): void {
        this.orderableDialog.close(null, true);
        const editedItem = this.orderableDialog.data.catalogItem;
        const formIndex = (this.form.value['items'] as any[])
            .map(control => control.itemNumber)
            .indexOf(editedItem.product.itemNumber);
        const editedGroup = (this.form.get('items') as FormArray).controls[formIndex] as FormGroup;

        if (deactivate) {
            editedGroup.get('deactivate').setValue(true);
        } else {
            editedItem.wholesalerCount =
                editedItem.wholesalerItems.filter(wi => !wi.isDeactivated).length;
            editedGroup.get('wholesalerCount').setValue(editedItem.wholesalerCount);
        }

        editedGroup.markAllAsTouched();
        this.form.markAsDirty();
        editedGroup.get('wholesalerCount').updateValueAndValidity();
    }

    shouldClose(): boolean {
        if (this.form.pristine) {
            return true;
        } else {
            this.dialogService.showConfirmDialog(
                "Would you like to leave the products screen? Any unsaved changes will be lost."
            ).pipe(take(1)).subscribe(confirmed => {
                if (confirmed) {
                    this.productsRef!.close(this.viewmodel, true);
                }
            });
            return false;
        }
    }



    async exportToXlsx() {
        const loaded = new BehaviorSubject<boolean>(false);
        this.waitService.showProgressSpinnerUntilLoaded(loaded);
        let file = await this.viewmodel.exportToXlsx(this.catalogItemService);
        loaded.next(true);
        loaded.complete();
        saveAs(file, 'export.xlsx');
    }


    async onSave() {
        (this.form.controls['items'] as FormArray).controls.forEach((g: FormGroup) => {
            g.controls['productUIN'].updateValueAndValidity();
        });
        if (this.form.valid) {
            //Update the model items with form values.
            const toSave = this.viewmodel.updateItems(this.form);

            //Check for any products that no longer have assigned wholesalers, and deactivate as
            //necessary.
            toSave.forEach(gci => {
                if (gci.wholesalerItems.filter(wci => wci.isDeactivated === false).length === 0) {
                    gci.isDeactivated = true;
                }
            });

            const loaded$ = new BehaviorSubject(null);

            //Show spinner and save.
            from(this.catalogItemService.saveAll(toSave))
                .subscribe(() => {
                    loaded$.next(null);
                    this.viewmodel.changed = true;

                    //Set all isNew flags to false to lock in UINs
                    (this.form.get('items') as FormArray).controls.forEach(control => {
                        control.get('isNew').setValue(false);
                    });

                    if (this.productsRef) {
                        this.productsRef.close(this.viewmodel, true);
                    }
                    this.viewmodel.afterSave$.emit();
                });

            this.waitService.showProgressSpinnerUntilLoaded(loaded$);
        } else {
            this.snackbarService.showError(this.viewmodel.errorMessage);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
