import { CallPicture } from "./call-picture.entity";
import { CallTypes } from "shield.shared";
import { CallSurveyAnswer } from "./call-survey-answer.entity";
import { ActivitySurveyViewModel } from "../accounts/account.viewmodels/activity-survey.viewmodel";

export class WholesaleCall {
    constructor() {
        this.callType = CallTypes.wholesale;
    }
    id: string; //UUID
    callNumber: string;
    customerId: string;
    createdUserId: string;
    createdUtcDateTime: Date;
    createdUserZrt: string;
    createdUserFullName: string
    modifiedUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    px3RankId?: string;
    px3Rank?: string;
    startTime: Date;
    stopTime: Date;
    durationMins: number;
    surveys: ActivitySurveyViewModel[] = [];
    surveyAnswers: CallSurveyAnswer[] = [];
    callPictures: CallPicture[] = [];
    isReturns: boolean;
    isPurchase: boolean;
    isSwisherDay: boolean;
    isTradeShow: boolean;
    purchaseInvoice: string;
    creditMemo: string;
    billThrough: string;
    purchaseAmount: number;
    creditAmount: number;
    closingNotes: string;
    callType: CallTypes.wholesale;
    isDeleted: boolean;
    hasServerProcessed: number;
    rowversion: string;
}
