import { Injectable } from "@angular/core";
import { CustomerPx3RankImportDto, CustomerPx3RankSyncCommand, newSequentialId } from "shield.shared";
import { CustomerPx3Rank } from "src/app/entity-models/customer-px3-rank.entity";
import { Px3IncentivePeriod } from "src/app/entity-models/px3-incentive-period.entity";
import { Px3Rank } from "src/app/entity-models/px3-rank.entity";
import { DataSyncQueueService } from "src/app/sync/data-sync-queue.service";
import { DatabaseService } from "../database.service";

@Injectable()
export class Px3RankOfflineService {

    constructor(private dbService: DatabaseService, private dataSyncQueueService: DataSyncQueueService) { }

    async getCurrentAndUpcomingPeriods(): Promise<Px3IncentivePeriod[]> {
        throw new Error("This function is not available in offline mode.");
    }

    async getAll(): Promise<Px3Rank[]> {
        return await this.dbService.px3Ranks.toArray();
    }

    async getById(id: string): Promise<Px3Rank> {
        return await this.dbService.px3Ranks.get(id);
    }

    async getCurrentIncentivePeriod(): Promise<Px3IncentivePeriod | undefined> {
        return await this.dbService.px3IncentivePeriods
            .filter(i => i.startDate < new Date() && i.endDate > new Date())
            .first();
    }

    async savePx3RankForCustomer(customerId: string, px3RankId?: string) {
        let curIncentivePeriod = await this.getCurrentIncentivePeriod();
        if (!curIncentivePeriod) {
            return;
        }
        await this.dbService.transaction(
            "rw",
            this.dbService.customers,
            this.dbService.syncQueue,
            async () => {
                const customer = await this.dbService.customers.where("id").equals(customerId).first();
                customer.px3RankId = px3RankId;
                await this.dbService.customers.put(customer);
                await this.dataSyncQueueService.enqueue(new CustomerPx3RankSyncCommand(customerId));
            }
        );

    }

    async bulkImport(_: CustomerPx3RankImportDto): Promise<never> {
        throw new Error("This function is not available in offline mode.");
    }
}
