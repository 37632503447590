import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
    faArrowDown,
    faArrowUp,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { SortDirection } from "shield.shared";
import { SortBy } from "../../account.viewmodels/sort-by.viewmodel";
import { VolumeDistributionService } from "../volume-distribution.service";

@Component({
    selector: "app-volume-distribution-products-card",
    templateUrl: "./volume-distribution-products-card.component.html",
    styleUrls: ["./volume-distribution-products-card.component.scss"]
})
export class VolumeDistributionProductsCardComponent implements OnInit {
    faArrowDown: IconDefinition = faArrowDown;
    faArrowUp: IconDefinition = faArrowUp;

    sortBy: SortBy;
    @ViewChild(CdkVirtualScrollViewport, { static: false })
    public viewport: CdkVirtualScrollViewport;

    constructor(public volumeDistribution: VolumeDistributionService) {}

    ngOnInit(): void {}

    onSortBy(event: MouseEvent, desc?: boolean): void {
        if (event) {
            const currentTarget = event.currentTarget as HTMLElement;
            if (currentTarget && currentTarget.innerHTML) {
                const target: string = currentTarget.id;

                if (
                    this.sortBy &&
                    this.sortBy.name === target
                ) {
                    if (
                        this.sortBy.direction ===
                            SortDirection.ascending
                    ) {
                        this.sortBy.direction =
                            SortDirection.descending;
                        this.sortBy.icon = "south";
                    } else {
                        this.sortBy.direction =
                            SortDirection.ascending;
                        this.sortBy.icon = "north";
                    }
                } else {
                    this.sortBy = new SortBy();
                    this.sortBy.name = target;
                    if (desc) {
                        this.sortBy.direction =
                        SortDirection.descending;
                        this.sortBy.icon = "south";
                    }
                    else {
                        this.sortBy.direction =
                        SortDirection.ascending;
                        this.sortBy.icon = "north";
                    }
                }

                this.sort();
            }
        }
    }
    sort(): void {
        if (
            this.sortBy?.name &&
            this.sortBy.direction !== SortDirection.none
        ) {
            const order =
                this.sortBy.direction === SortDirection.descending ? -1 : 1;

            switch (this.sortBy.name) {
                case "productName":
                    this.volumeDistribution.inDistProducts.sort(
                        (a, b) =>
                            (a.productName?.trim() ?? "").localeCompare(b.productName?.trim() ?? "", "en", {
                                sensitivity: "base"
                            }) * order
                    );
                    break;
                case "intro":
                    this.volumeDistribution.inDistProducts.sort(
                        (a, b) =>
                            ((a.intro === b.intro) ? 0 : a.intro ? -1 : 1) * order
                    );
                    break;
                case "oos":
                    this.volumeDistribution.inDistProducts.sort(
                        (a, b) =>
                            ((a.oos === b.oos) ? 0 : a.oos ? -1 : 1) * order
                    );
                    break;
                case "cos":
                    this.volumeDistribution.inDistProducts.sort(
                        (a, b) =>
                            ((a.cos === b.cos) ? 0 : a.cos ? -1 : 1) * order
                    );
                    break;
                default:
                    break;
            }
        }
    }

    //this function handles the known issue of implementing a sticky header in a cdk virtual scroll component (https://github.com/angular/components/issues/14833). It implements one of the solutions found on GitHub (https://stackblitz.com/edit/components-issue-brsnj4?file=app%2Fapp.component.html)
    public get inverseOfTranslation(): string {
        if (!this.viewport || !this.viewport["_renderedContentOffset"]) {
            return "-0px";
        }
        let offset = this.viewport["_renderedContentOffset"];
        return `-${offset}px`;
    }
}
