import { Component, OnDestroy, OnInit } from "@angular/core";
//Icons
import {
    faPen,
    faSave,
    faSignOutAlt,
    faReceipt,
    faCheckSquare,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { SharedHelper } from "shield.shared";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { AppStateService } from "src/app/services/app-state.service";
import { WholesalerGroupDelineationService } from "src/app/services/delineation-services/wholesaler-group-delineation.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { WholesaleProfileViewmodel } from "../../profile-master/wholesale-profile/wholesale-account-profile.viewmodel";

@Component({
    selector: "app-wholesale-info",
    templateUrl: "./wholesale-account-info.component.html",
    styleUrls: ["./wholesale-account-info.component.scss"]
})
export class WholesaleAccountInfoComponent implements OnInit, OnDestroy {
    faPen: IconDefinition = faPen;
    faSave: IconDefinition = faSave;
    faSignOutAlt: IconDefinition = faSignOutAlt;
    faReceipt: IconDefinition = faReceipt;
    faCheckSquare: IconDefinition = faCheckSquare;

    wholesalerCustomerSubscription: Subscription;
    wholesalerViewmodel: WholesaleProfileViewmodel = new WholesaleProfileViewmodel(this.wholesalerGroupDelineation);

    employeeSubscription: Subscription;
    employee: Employee;
    customer: Customer;

    dateFormat = MY_DATE_FORMATS.display.customDateInput;
    dateTimeFormat = MY_DATE_FORMATS.display.customDateTimeInput;
    formattedPhone: string;

    constructor(
        private customerStateService: CustomerStateService,
        private appStateService: AppStateService,
        private wholesalerGroupDelineation: WholesalerGroupDelineationService
    ) {}

    ngOnInit(): void {

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.employeeSubscription = this.appStateService.currentEmployee.subscribe((employee) => {
                this.employee = employee;
                if (this.employee && this.customer) {
                    this.wholesalerViewmodel.buildViewmodelFromDomainModel(
                        this.customer,
                        this.employee
                    );
                }
            })
        }

        if (
            !this.wholesalerCustomerSubscription ||
            this.wholesalerCustomerSubscription.closed
        ) {
            this.wholesalerCustomerSubscription = this.customerStateService.observableCustomer
                .pipe(
                    map((customer) => {
                        this.customer = customer;
                        if (customer && this.employee) {
                            this.wholesalerViewmodel.buildViewmodelFromDomainModel(
                                this.customer,
                                this.employee
                            );
                            this.formattedPhone = SharedHelper.formatPhoneNumber(
                                customer.phone
                            );
                        }
                    })
                )
                .subscribe();
        }
    }

    ngOnDestroy(): void {
        if (
            this.wholesalerCustomerSubscription &&
            !this.wholesalerCustomerSubscription.closed
        ) {
            this.wholesalerCustomerSubscription.unsubscribe();
        }
    }
}
