import { RefinerLocation, WholesalerGroupsListFilterDto, valueSeparator } from "shield.shared";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { BaseFilterMapService } from "./base-filter-map.service";

export class WholesalerGroupsListFilterMapService extends BaseFilterMapService {
    static mapFilterData(refiners: Refiner[]): WholesalerGroupsListFilterDto {

        if (!refiners) return;
        const filterDto = new WholesalerGroupsListFilterDto();

        for (const refiner of refiners) {
            if (!refiner.dataValue) {
                refiner.dataValue = refiner.value;
            }

            switch (refiner.location) {
                case RefinerLocation.group:
                    filterDto.group = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.orderMethod:
                    filterDto.orderMethod = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.wholesaler:
                    filterDto.wholesalerCustomer = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.states:
                    filterDto.state = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.productNumber:
                    filterDto.productItemNumber = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.uin:
                    filterDto.productUin = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.productDescription:
                    filterDto.productDescription = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.productPrice:
                    filterDto.productPrice = refiner.dataValue.split(valueSeparator).map(v => Number(v));
                    break;
                default:
                    break;
            }
        }
        return filterDto;
    }
}
