<div class="add-products-dialog mt-3">
        <h1 class="margin-bottom-unset m-teal">
            {{selectedCount}}
            {{'Product' | plural : selectedCount }} Ready to be Added
        </h1>
        <div class="w-100 mt-1 mb-2">
            <mat-form-field appearance="outline" color="accent" class="w-100">
                <mat-label>Search for a Product</mat-label>
                <input #search type="text" id="search-text" [(ngModel)]="searchText" matInput />
            </mat-form-field>
        </div>
        <div class="d-flex">
            <div class="d-flex flex-column sidebar">
                <div class="facet">
                    <h2 class="margin-bottom-unset m-teal">Filter Products By:</h2>
                    <mat-radio-group [value]="selectedFilteredProduct"
                    name="filteredProduct"
                    (change)="selectedFilteredProduct = $event.value; filter();">
                        <div class="col-12 d-flex flex-column">
                            <mat-radio-button color="accent" [value]="'All'">
                                All
                            </mat-radio-button>
                            <mat-radio-button color="accent" [value]="'Selected'">
                                Selected
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
                <div class="facet categories">
                    <h2 class="margin-bottom-unset m-teal">Categories:</h2>
                    <mat-radio-group [value]="selectedFilteredCategory" [name]="'categoryProduct'"
                    (change)="selectedFilteredCategory = $event.value; filter();"
                    *ngFor="let category of categories; let i = index">
                    <div class="col-12">
                        <mat-radio-button color="accent" [value]="category.value">
                            {{ category.name }}
                        </mat-radio-button>
                    </div>
                </mat-radio-group>
                </div>
            </div>
            <div class="ml-4 d-flex flex-column flex-grow-1">
                <cdk-virtual-scroll-viewport #viewport itemSize="50" minBufferPx="600" maxBufferPx="800" class="viewport d-flex flex-grow-1">
                    <table class="table table-bordered table-striped table-hover">
                        <thead>
                            <tr>
                                <th [style.top]="inverseOfTranslation" scope="col" id="select" class="align-middle">
                                    <mat-checkbox class="mt-2" [(ngModel)]="isAllSelected" (change)="selectAll()"> Select All</mat-checkbox>
                                </th>
                                <th [style.top]="inverseOfTranslation" scope="col" id="name" class="align-middle">
                                    Name
                                </th>
                                <th *ngIf="!overlayRef.data.hideItemNumber" [style.top]="inverseOfTranslation" scope="col" id="display" class="align-middle">
                                    Item Number
                                </th>
                                <th *ngIf="!overlayRef.data.hidePacksize" [style.top]="inverseOfTranslation" scope="col" id="packSize" class="align-middle">
                                    Pack Size
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="viewport-item" *cdkVirtualFor="let row of filterViewmodels" minBufferPx="600"
                                maxBufferPx="800" class="viewport-item">
                                <td>
                                    <span *ngIf="overlayRef.data.addOnly && row.inGroup">
                                        In Group
                                    </span>
                                    <mat-checkbox
                                    *ngIf="!overlayRef.data.addOnly || !row.inGroup"
                                    (change)="changeSelected(row); setSelectedCount();"
                                    [(ngModel)]="row.selected"></mat-checkbox>
                                </td>
                                <td>
                                    {{row.product.description}}
                                </td>
                                <td *ngIf="!overlayRef.data.hideItemNumber">
                                    {{row.product.itemNumber}}
                                </td>
                                <td *ngIf="!overlayRef.data.hidePacksize">
                                    {{row.product.returnableUpc?.noOfEaches}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>
</div>
