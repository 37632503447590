import { Component, OnInit } from "@angular/core";
import { StepsInRouteViewModel } from "./steps-in-route.viewmodel";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";

@Component({
    selector: "app-steps-in-route-dialog",
    templateUrl: "./steps-in-route-dialog.component.html",
    styleUrls: ["./steps-in-route-dialog.component.css"]
})
export class StepsInRouteDialogComponent implements OnInit {
    dateFormat = MY_DATE_FORMATS.display.customDateInput;

    vm: StepsInRouteViewModel;

    constructor(
        private ref: SwisherOverlayRef<
            StepsInRouteViewModel,
            StepsInRouteDialogComponent
        >
    ) {}

    ngOnInit(): void {
        this.vm = this.ref.data;
    }

    close(): void {
        this.ref.close(this.ref.data);
    }
}
