import {
    Component,
    HostBinding,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import { MatCalendar } from "@angular/material/datepicker";
import * as moment from "moment";
import { Moment } from "moment";
const myMoment = extendMoment(moment);
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { TimeEntry } from "src/app/entity-models/time-entry.entity";
import { Helper } from "src/app/helpers/helper";
import { AppStateService } from "src/app/services/app-state.service";
import {
    faPlus,
    faPen,
    faSave,
    faTrash,
    faTimes,
    faArrowRight,
    faCaretDown,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { OverlayService } from "src/app/services/overlay.service";
import { AddTimeEntryViewmodel } from "../add-time-entry/add-time-entry.viewmodel";
import { AddTimeEntryComponent } from "../add-time-entry/add-time-entry.component";
import { TimeEntryViewModel } from "./time-entry.viewmodel";
import { DailyTimeEntriesViewmodel } from "./daily-time-entries.viewmodel";
import { TimeEntryType } from "src/app/entity-models/time-entry-type.entity";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ValidatorFn
} from "@angular/forms";
import { map } from "rxjs/operators";
import { extendMoment } from "moment-range";
import { DayTimeEntryWorkWithViewmodel } from "./day-time-entry-work-with.viewmodel";
import { SnackbarService } from "src/app/services/snackbar.service";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { Employee } from "src/app/entity-models/employee.entity";
import { EmployeeDropdownComponent } from "src/app/shared/employee-dropdown/employee-dropdown.component";
import { newSequentialId } from "shield.shared";
import { TimeEntryDelineationService } from "src/app/services/delineation-services/time-entry-delineation.service";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { DayTimeEntry } from "src/app/entity-models/day-time-entry.entity";
import { Call } from "src/app/accounts/call-master/call-services/call.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { TimeLogCalendarHeaderComponent } from "./time-log-calendar-header/time-log-calendar-header.component";
import { SyncService } from "src/app/services/sync.service";
import { LogService } from "src/app/services/log.service";

@Component({
    selector: "app-time-log",
    templateUrl: "./time-log.component.html",
    styleUrls: ["./time-log.component.scss"]
})
export class TimeLogComponent implements OnInit, OnDestroy {
    @HostBinding("class") class = "worksheet-static-scrollable";
    @ViewChild("calendar", { static: false })
    calendar: MatCalendar<Moment>;
    @ViewChild("employeeDropdown") employeeDropdown: EmployeeDropdownComponent;
    selectedDate: Moment;
    startAt: Moment;
    maxDate: Moment;
    timeEntrySubscription: Subscription;
    dayTimeEntrySubscription: Subscription;
    employee: Employee;
    employeeSubscription: Subscription;
    groupedTimeEntries: Map<string, DailyTimeEntriesViewmodel> = new Map<
        string,
        DailyTimeEntriesViewmodel
    >();
    timeEntryViewmodels: TimeEntryViewModel[] = [];
    dayTimeTotal: string;
    dailyHours = 0;
    canCompletDay = false;
    completeDayTitle = "";
    isCompletingDay = false;
    dailyTimeEntry: DailyTimeEntriesViewmodel;
    hasTimeErrors = false;
    hasWorkWithTimeErrors = false;

    faPlus: IconDefinition = faPlus;
    faTrash: IconDefinition = faTrash;
    faPen: IconDefinition = faPen;
    faSave: IconDefinition = faSave;
    faArrowRight: IconDefinition = faArrowRight;
    faCaretDown: IconDefinition = faCaretDown;
    faTimes: IconDefinition = faTimes;
    editId: string;
    timeEntryTypeCall = "Call";

    addTimeEntrynOverlayRef: SwisherOverlayRef<
        AddTimeEntryViewmodel,
        AddTimeEntryComponent
    >;

    confirmationRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    timeEntryTypes: TimeEntryType[] = [];
    selectedTimeEntryType: TimeEntryType;

    recordForm: FormGroup = this.formBuilder.group({});
    workWithForm: FormGroup = this.formBuilder.group({});

    timeEditInProgress = false;
    dayCanBeFinalized = true;

    allDayTimeEntries: DayTimeEntry[] = [];
    allTimeTypes: TimeEntryType[] = [];

    defaultTimeEntryType: TimeEntryType;
    emptyTimeEntryType: TimeEntryType;

    confirmationOverlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    // We need a custom header component because firing an event on active month change is apparently beyond the scope of material calendar
    calendarHeader = TimeLogCalendarHeaderComponent;
    private shouldWait$ = new BehaviorSubject<boolean>(true);

    constructor(
        private pleaseWaitService: PleaseWaitService,
        private appStateService: AppStateService,
        public syncService: SyncService,
        private overlayService: OverlayService,
        private formBuilder: FormBuilder,
        private snackbar: SnackbarService,
        public timeEntryDelineationService: TimeEntryDelineationService,
        private callDelineationService: CallDelineationService,
        private logService: LogService
    ) {}

    async canDeactivate(): Promise<Observable<boolean> | boolean> {


        if (this.timeEntryViewmodels.some((te) => !te.type)) {

            const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
            data.header = "Confirmation";
            data.message = "Please resolve the highlighted missing time entry types.";
            data.buttonRightText = "Close";
            data.buttonRightFunction = () => {
                this.confirmationOverlayRef.close(data);
            };
            this.confirmationOverlayRef = this.overlayService.open(
                ConfirmationDialogComponent,
                data
            );

            return false;
        }
        return true;
    }

    async ngOnInit(): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        this.startAt = moment();
        this.maxDate = this.startAt;
        this.selectedDate = this.startAt;
        this.allTimeTypes = (await this.timeEntryDelineationService.getTimeEntryTypes())?.values
            ?? new Array<TimeEntryType>();
        this.defaultTimeEntryType = this.allTimeTypes?.find((tt) => tt.name === "Driving");
        this.emptyTimeEntryType = this.allTimeTypes.find((tt) => tt.name === "");
        this.restrictTimeTypes();

        let index = 0;
        while (index !== -1) {
            index = this.timeEntryTypes.findIndex(
                (tet) => tet.isOnlyApplicationControlled
            );
            if (index !== -1) {
                this.timeEntryTypes.splice(index, 1);
            }
        }

        this.selectedTimeEntryType = this.timeEntryTypes.find(
            (tet) => tet.name === "Driving"
        );

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.employeeSubscription = this.appStateService.currentEmployee
                .pipe(
                    map( async (employee: Employee) => {
                        if (employee) {
                            this.employee = employee;
                            await this.buildViewModels(employee);
                            this.shouldWait$.next(false);
                        }
                    })
                )
                .subscribe();
        }
    }

    ngOnDestroy(): void {
        if (this.employeeSubscription && !this.employeeSubscription.closed) {
            this.employeeSubscription.unsubscribe();
        }

        this.cleanTimeEntries();

        if (this.dailyTimeEntry) {
            for (const workWith of this.dailyTimeEntry.dayTimeEntryWorkWiths) {
                this.unsubscribeWorkWith(workWith);
            }
        }
    }

    unsubscribeWorkWith(ww: DayTimeEntryWorkWithViewmodel): void {
        if (
            ww.worksWithStartTimeSubscription &&
            !ww.worksWithStartTimeSubscription.closed
        ) {
            ww.worksWithStartTimeSubscription.unsubscribe();
        }

        if (
            ww.worksWithEndTimeSubscription &&
            !ww.worksWithEndTimeSubscription.closed
        ) {
            ww.worksWithEndTimeSubscription.unsubscribe();
        }
    }

    onCompleteDayClick(): void {
        if (!this.timeEntryDelineationService.getOnlineState()) {
            this.snackbar.showWarning("You must be online to complete your day.");
            return;
        }
        this.isCompletingDay = true;
    }

    async onSelect(date: Moment): Promise<void> {
        if (!Helper.compareMomentMonthAndYear(this.selectedDate, date)) {
            this.selectedDate = date;
            await this.buildViewModels(this.employee);
            this.restrictTimeTypes();
            return;
        }

        this.selectedDate = date;
        this.restrictTimeTypes();
        void this.buildOutModel();
    }

    addWorkWithValidators(i: string): void {
        this.workWithForm.addControl(
            "workWithStartTime" + i,
            new FormControl()
        );
        this.workWithForm.controls["workWithStartTime" + i].setValidators([
            this.isWorksWithEmpty("workWithStartTime", i),
            this.isWorksWithStartDateGreaterThenEnd(i),
            this.isWorksWithStartLaterThenNow(i)
        ]);
        this.workWithForm.controls[
            "workWithStartTime" + i
        ].updateValueAndValidity();

        this.workWithForm.addControl("workWithEndTime" + i, new FormControl());
        this.workWithForm.controls["workWithEndTime" + i].setValidators([
            this.isWorksWithEmpty("workWithEndTime", i),
            this.isWorksWithStartDateGreaterThenEnd(i),
            this.isWorksWithEndLaterThenNow(i)
        ]);
        this.workWithForm.controls[
            "workWithEndTime" + i
        ].updateValueAndValidity();
    }

    addValidators(i: string): void {
        this.recordForm.addControl("startTime" + i, new FormControl());
        this.recordForm.controls["startTime" + i].setValidators([
            this.isEmpty("startTime", i),
            this.isOverLapping(i),
            this.isStartDateGreaterThenEnd(i),
            this.isStartLaterThenNow(i)
        ]);
        this.recordForm.controls["startTime" + i].updateValueAndValidity();

        this.recordForm.addControl("endTime" + i, new FormControl());
        this.recordForm.controls["endTime" + i].setValidators([
            this.isEmpty("endTime", i),
            this.isOverLapping(i),
            this.isStartDateGreaterThenEnd(i),
            this.isEndLaterThenNow(i)
        ]);
        this.recordForm.controls["endTime" + i].updateValueAndValidity();

        this.recordForm.addControl("type" + i, new FormControl());
        this.recordForm.controls["type" + i].setValidators([
            this.isStartLaterThenNow(i),
            this.isEndLaterThenNow(i)
        ]);
        this.recordForm.controls["type" + i].updateValueAndValidity();
    }

    evaluateCompleteDay(): void {
        let lastEvaluatedDate: Date;
        let completeDayTitle = "";
        let canCompletDay = true;
        for (const entry of this.timeEntryViewmodels) {
            if (!lastEvaluatedDate) {
                lastEvaluatedDate = entry.end; // first entry
                continue;
            }

            if (
                lastEvaluatedDate.getHours() !== entry.start.getHours() ||
                lastEvaluatedDate.getMinutes() !== entry.start.getMinutes()
            ) {
                canCompletDay = false;
                completeDayTitle =
                    "Cannot complete day with time gaps or overlap.";
                break;
            } else if (this.dailyTimeEntry?.entries?.some((entry) => !entry.type?.name))  {
                canCompletDay = false;
                completeDayTitle =
                    "All time entries must contain a type to complete the day.";
                break;
            } else {
                lastEvaluatedDate = entry.end;
            }
        }

        if (this.timeEditInProgress) {
            canCompletDay = false;
            const message =
                "Cannot complete day while time entries are being edited.";
            completeDayTitle = completeDayTitle
                ? completeDayTitle + " " + message
                : message;
        }

        if (this.dailyHours < 8) {
            canCompletDay = false;
            const message = "Cannot complete day with less than 8 hours logged.";
            completeDayTitle = completeDayTitle
                ? completeDayTitle + " " + message
                : message;
        }
        this.canCompletDay = canCompletDay;
        this.completeDayTitle = completeDayTitle;
    }

    isStartDateGreaterThenEnd(i: string): ValidatorFn {
        return (): { [key: string]: boolean } | null => {
            let rtn = false;
            let start = null;
            let end = null;
            const selectedDate: string = this.selectedDate?.toString();

            const myStartTime = this.recordForm?.controls?.["startTime" + i]
                ?.value as Date | null;
            if (myStartTime) {
                start = new Date(selectedDate);
                start.setHours(
                    myStartTime.getHours(),
                    myStartTime.getMinutes(),
                    0,
                    0
                );
            }

            const myEndTime = this.recordForm?.controls?.["endTime" + i]
                ?.value as Date | null;
            if (myEndTime) {
                end = new Date(selectedDate);
                end.setHours(
                    myEndTime.getHours(),
                    myEndTime.getMinutes(),
                    0,
                    0
                );
            }

            if (start && end) {
                if (start.getTime() > end.getTime()) {
                    rtn = true;
                }
            }
            return rtn ? { startGreaterThenEnd: true } : null;
        };
    }

    isWorksWithStartDateGreaterThenEnd(i: string): ValidatorFn {
        return (): { [key: string]: boolean } | null => {
            let rtn = false;
            let start = null;
            let end = null;
            const selectedDate: string = this.selectedDate?.toString();

            const myStartTime = this.workWithForm?.controls?.[
                "workWithStartTime" + i
            ]?.value as Date | null;
            if (myStartTime) {
                start = new Date(selectedDate);
                start.setHours(
                    myStartTime.getHours(),
                    myStartTime.getMinutes(),
                    myStartTime.getSeconds(),
                    0
                );
            }

            const myEndTime = this.workWithForm?.controls?.[
                "workWithEndTime" + i
            ]?.value as Date | null;
            if (myEndTime) {
                end = new Date(selectedDate);
                end.setHours(
                    myEndTime.getHours(),
                    myEndTime.getMinutes(),
                    myEndTime.getSeconds(),
                    0
                );
            }

            if (start && end) {
                if (start.getTime() > end.getTime()) {
                    rtn = true;
                }
            }
            return rtn ? { startGreaterThenEnd: true } : null;
        };
    }

    isStartLaterThenNow(i: string): ValidatorFn {
        return (): { [key: string]: boolean } | null => {
            let rtn = false;
            let start = null;
            let now = null;
            let type = this.recordForm?.controls?.["type" + i]?.value;

            if(!type?.isOnlyApplicationControlled && !type?.isFutureCompliant) {
                const selectedDate: string = this.selectedDate?.toString();

                const myStartTime = this.recordForm?.controls?.["startTime" + i]
                    ?.value as Date | null;
                if (myStartTime) {
                    start = new Date(selectedDate);
                    start.setHours(
                        myStartTime.getHours(),
                        myStartTime.getMinutes(),
                        0,
                        0
                    );
                }

                now = new Date();

                if (start) {
                    if (start.getTime() > now.getTime()) {
                        rtn = true;
                    }
                }
            }
            return rtn ? { startLaterThenNow: true } : null;
        };
    }

    isEmpty(name: string, i: string): ValidatorFn {
        return (): { [key: string]: boolean } | null => {
            let rtn = false;
            const myTime = this.recordForm?.controls?.[
                name + i
            ]?.value as Date | null;
            if (!myTime) {
                rtn = true
            }
            return rtn ? { empty: true } : null;
        };
    }

    isEndLaterThenNow(i: string): ValidatorFn {
        return (): { [key: string]: boolean } | null => {
            let rtn = false;
            let end = null;
            let now = null;
            let type = this.recordForm?.controls?.["type" + i]?.value;

            if(!type?.isOnlyApplicationControlled && !type?.isFutureCompliant) {
                const selectedDate: string = this.selectedDate?.toString();

                const myEndTime = this.recordForm?.controls?.["endTime" + i]
                    ?.value as Date | null;
                if (myEndTime) {
                    end = new Date(selectedDate);
                    end.setHours(
                        myEndTime.getHours(),
                        myEndTime.getMinutes(),
                        0,
                        0
                    );
                }

                now = new Date();

                if (end) {
                    if (end.getTime() > now.getTime()) {
                        rtn = true;
                    }
                }
            }
            return rtn ? { endLaterThenNow: true } : null;
        };
    }

    isWorksWithStartLaterThenNow(i: string): ValidatorFn {
        return (): { [key: string]: boolean } | null => {
            let rtn = false;
            let start = null;
            let now = null;

            const selectedDate: string = this.selectedDate?.toString();

            const myStartTime = this.workWithForm?.controls?.[
                "workWithStartTime" + i
            ]?.value as Date | null;
            if (myStartTime) {
                start = new Date(selectedDate);
                start.setHours(
                    myStartTime.getHours(),
                    myStartTime.getMinutes(),
                    myStartTime.getSeconds(),
                    0
                );
            }

            now = new Date();

            if (start) {
                if (start.getTime() > now.getTime()) {
                    rtn = true;
                }
            }
            return rtn ? { startLaterThenNow: true } : null;
        };
    }

    isWorksWithEndLaterThenNow(i: string): ValidatorFn {
        return (): { [key: string]: boolean } | null => {
            let rtn = false;
            let end = null;
            let now = null;

            const selectedDate: string = this.selectedDate?.toString();

            const myEndTime = this.workWithForm?.controls?.[
                "workWithEndTime" + i
            ]?.value as Date | null;
            if (myEndTime) {
                end = new Date(selectedDate);
                end.setHours(
                    myEndTime.getHours(),
                    myEndTime.getMinutes(),
                    0,
                    0
                );
            }

            now = new Date();

            if (end) {
                if (end.getTime() > now.getTime()) {
                    rtn = true;
                }
            }
            return rtn ? { endLaterThenNow: true } : null;
        };
    }

    isOverLapping(i: string): ValidatorFn {
        return (): { [key: string]: boolean } | null => {
            let rtn = false;
            let start = null;
            let end = null;
            const selectedDate: string = this.selectedDate.toString();

            const myStartTime = this.recordForm?.controls?.["startTime" + i]
                ?.value as Date | null;

            if (myStartTime) {
                start = new Date(selectedDate);
                start.setHours(
                    myStartTime.getHours(),
                    myStartTime.getMinutes(),
                    0,
                    0
                );
            }

            const myEndTime = this.recordForm?.controls?.["endTime" + i]
                ?.value as Date | null;
            if (myEndTime) {
                end = new Date(selectedDate);
                end.setHours(
                    myEndTime.getHours(),
                    myEndTime.getMinutes(),
                    0,
                    0
                );
            }

            const currrentEntry = this.timeEntryViewmodels[+i];

            if (start) {
                for (const entry of this.timeEntryViewmodels) {
                    if (entry.id !== currrentEntry.id) {
                        if (start >= entry.start && start < entry.end) {
                            rtn = true;
                            if (rtn) {
                                break;
                            }
                        }
                    }
                }
            }

            if (!rtn) {
                if (end) {
                    for (const entry of this.timeEntryViewmodels) {
                        if (entry.id !== currrentEntry.id) {
                            if (end > entry.start && end <= entry.end) {
                                rtn = true;
                                if (rtn) {
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            if (!rtn) {
                for (const entry of this.timeEntryViewmodels) {
                    if (entry.id !== currrentEntry.id) {
                        const range1 = myMoment.range(
                            moment(start),
                            moment(end)
                        );
                        const range2 = myMoment.range(
                            moment(entry.start),
                            moment(entry.end)
                        );
                        rtn = range1.overlaps(range2);
                        if (rtn) {
                            break;
                        }
                    }
                }
            }

            return rtn ? { overlap: true } : null;
        };
    }

    isWorksWithEmpty(name: string, i: string): ValidatorFn {
        return (): { [key: string]: boolean } | null => {
            let rtn = false;
            const myTime = this.workWithForm?.controls?.[
                name + i
            ]?.value as Date | null;
            if (!myTime) {
                rtn = true
            }
            return rtn ? { empty: true } : null;
        };
    }

    dateClass() {
        return (cellDate: Moment, view: string): string => {
            // Only highligh dates inside the month view.
            if (Helper.equalsIgnoreCase(view, "month")) {
                let result = "";
                const key: string =
                    cellDate.year().toString() +
                    "_" +
                    (cellDate.month() + 1).toString() +
                    "_" +
                    cellDate.date().toString();
                const now = new Date();
                let hireDate = new Date("1-1-1970");
                if (this.employee && this.employee.hireDate) {
                    hireDate = new Date(this.employee.hireDate);
                }
                now.setHours(23, 59, 59, 999);
                const present = now.getTime();
                const selectedDate = cellDate.toDate().getTime();
                const isWeekend = cellDate.isoWeekday() > 5; // Mon == 1, Tue == 2, etc.
                const hiredDate = hireDate.getTime();
                if (selectedDate <= present && selectedDate >= hiredDate && !isWeekend) {
                    if (this.groupedTimeEntries.has(key)) {
                        const day = this.groupedTimeEntries.get(key);
                        if (day && day.isCompleted) {
                            result = "work-complete";
                        } else {
                            result = "work-incomplete";
                        }
                    } else {
                        result = "work-incomplete";
                    }
                } else {
                    result = null;
                }

                return result;
            }
        };
    }

    async buildOutModel(): Promise<void> {
        this.timeEntryViewmodels = [];
        const key: string =
            this.selectedDate.year().toString() +
            "_" +
            (this.selectedDate.month() + 1).toString() +
            "_" +
            this.selectedDate.date().toString();
        let timeEntries: TimeEntry[] = [];
        let dayTimeTotalMs = 0;
        let newEntries: TimeEntry[] = [];
        if (this.groupedTimeEntries.has(key)) {
            const oldTimeEntry = this.dailyTimeEntry;
            // fill out any missing drive times
            newEntries = await this.setDailyTimeEntries(
                this.groupedTimeEntries.get(key).entries,
                key
            ) ?? [];
            this.dailyTimeEntry = this.groupedTimeEntries.get(key);
            timeEntries = this.dailyTimeEntry.entries;
            dayTimeTotalMs = this.dailyTimeEntry.dayTimeTotalMs;
            this.dailyHours = this.dailyTimeEntry.dailyHours;
            //Maintain state of complete day information if we are changing time entry log after already filling some of it out.
            if (oldTimeEntry && oldTimeEntry.key === this.dailyTimeEntry.key) {
                this.dailyTimeEntry.endOfDayComment = oldTimeEntry.endOfDayComment;
                this.dailyTimeEntry.resetDay = oldTimeEntry.resetDay;
                this.dailyTimeEntry.mileage = oldTimeEntry.mileage;
                this.dailyTimeEntry.dayTimeEntryWorkWiths = oldTimeEntry.dayTimeEntryWorkWiths;
            }
        } else {
            this.dailyTimeEntry = null;
            this.dailyHours = 0;
        }

        this.sort(timeEntries); // Oldest records first

        const viewModels: TimeEntryViewModel[] = [];

        timeEntries.forEach((entry) => {
            viewModels.push(
                new TimeEntryViewModel(this.defaultTimeEntryType, entry)
            );
        });

        for (const entry of newEntries) {
            const idx = viewModels.findIndex(vm => vm.id === entry.id);
            const newVM = viewModels[idx];
            this.addValidators(idx.toString());
            this.setValidations(newVM, false, idx);
        }

        this.dayTimeTotal = Helper.getHoursAndMinutesFromMilliseconds(
            dayTimeTotalMs
        );

        this.timeEntryViewmodels = viewModels;
        this.calendar.updateTodaysDate();
        this.timeEditInProgress = false;
        this.isCompletingDay = false;
        this.evaluateCompleteDay();
    }

    private restrictTimeTypes() {
        const today = new Date();
        today.setHours(23, 59, 59, 999);
        const selectedDate = this.selectedDate.toDate();
        if (selectedDate.getTime() > today.getTime()) {
            this.timeEntryTypes = this.allTimeTypes.filter(
                (tt) => tt.isFutureCompliant && !tt.isOnlyApplicationControlled
            );
            this.selectedTimeEntryType = this.timeEntryTypes.find(
                (tt) => tt.isDefault && tt.isFutureCompliant
            );
        } else {
            this.timeEntryTypes = [...this.allTimeTypes].filter(
                (tt) => !tt.isOnlyApplicationControlled
            );
            this.selectedTimeEntryType = this.timeEntryTypes.find(
                (tt) => tt.isDefault && !tt.isFutureCompliant
            );
        }
    }

    async getAllTimeEntries(employee: Employee): Promise<void> {
        if (employee) {
            const month = new Date(this.selectedDate.toDate()).getMonth();
            const year = new Date(this.selectedDate.toDate()).getFullYear();

            const timeEntryResponse = await this.timeEntryDelineationService.getByEmployeeIdAndDate(employee.id, month, year);
            if (!timeEntryResponse) {
                return;
            }
            timeEntryResponse.values ??= new Array<TimeEntry>();

            const dayTimeEntriesResponse = await this.timeEntryDelineationService.getDayTimeEntriesByEmployeeIdAndDate(employee.id, month, year);
            if (!dayTimeEntriesResponse) { return; }

            this.allDayTimeEntries = dayTimeEntriesResponse.values
                ?? new Array<DayTimeEntry>();

            const activeEntries = timeEntryResponse.values?.filter(
                // need to be defensive in case any more time travellers try and break the time log
                (ate) => !ate.isDeleted && Helper.compareDayMonthYear(ate.start, ate.end)
            );

            const myTimeGroupings = Helper.groupBy(
                activeEntries,
                (entry: TimeEntry) =>
                    new Date(entry.start).getFullYear().toString() +
                    "_" +
                    (new Date(entry.start).getMonth() + 1).toString() +
                    "_" +
                    new Date(entry.start).getDate().toString()
            );

            this.groupedTimeEntries.clear();

            for (const group of myTimeGroupings) {
                const myGroup = [...group][1].valueOf() as TimeEntry[];

                const key = [...group][0].valueOf() as string;

                const day = this.allDayTimeEntries.find((dte) => dte.key === key);

                this.groupedTimeEntries.set(
                    key,
                    new DailyTimeEntriesViewmodel(
                        myGroup,
                        day,
                        newSequentialId(),
                        key,
                        this.employee.id
                    )
                );
            }
        }
    }

    async buildViewModels(employee: Employee): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        await this.getAllTimeEntries(employee);
        await this.buildOutModel();
        this.setDayCanBeFinalized();
        this.evaluateCompleteDay();

        this.shouldWait$.next(false);
    }

    sort(entries: TimeEntry[]): void {
        entries.sort((a, b) =>
            a.start.getTime() === b.start.getTime()
                ? a.end.getTime() - b.end.getTime()
                : a.start.getTime() - b.start.getTime()
        ); // Oldest records first
    }
    async setDailyTimeEntries(
        entries: TimeEntry[],
        key: string
    ): Promise<TimeEntry[]> {
        if (!entries || entries.length === 0) return;
        const newEntries: TimeEntry[] = [];
        this.sort(entries);
        // Create the initial drive time entry if needed
        let date = new Date(entries[0].start);
        if (date.getHours() > 8 || (date.getHours() === 8 && date.getMinutes() > 0)) { //If the first entry is after 8am, we need to add a drive time entry
            const entry = entries[0];
            const newStartDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                8,
                0,
                0,
                0
            );
            const newTimeEntry = this.createTimeEntry(
                newStartDate,
                entry.start,
                entry.type?.name === this.timeEntryTypeCall
                    ? this.defaultTimeEntryType 
                    : this.emptyTimeEntryType,
                entry.name,
                entry.createdBy
            );
            if (newTimeEntry) {
                newEntries.push(newTimeEntry);
            }
        }
        for (let [i, entry] of entries.entries()) {
            const nextEntry = entries[i + 1];
            if (!nextEntry || entry.end.getTime() >= nextEntry.start.getTime()) continue;
            const newTimeEntry = this.createTimeEntry(
                entry.end,
                nextEntry.start,
                entry.type?.name === this.timeEntryTypeCall
                    ? this.defaultTimeEntryType
                    : this.emptyTimeEntryType,
                nextEntry.name,
                entry.createdBy
            );
            if (newTimeEntry) {
                newEntries.push(newTimeEntry);
            }
        }

        if (newEntries.length > 0) {
            const failedEntries = newEntries.filter((te) => !isDateValid(te.start) || !isDateValid(te.end));
            if (failedEntries.length > 0) {
                this.snackbar.showError("Error creating time entries. Please contact support.");
                console.error("Invalid date found in new time entries.", newEntries);
                console.log("Entries with bad dates: ", failedEntries);
                this.logService.logEvent("TimeLogInvalidDates", {
                    employeeId: this.employee.id,
                    entries: newEntries,
                    invalidDateEntries: failedEntries
                });
                return;
            }
            await this.timeEntryDelineationService.upsertArray(newEntries);
            const dayEntry = this.allDayTimeEntries.find((dte) => dte.key === key);

            this.groupedTimeEntries.set(
                key,
                new DailyTimeEntriesViewmodel(
                    this.groupedTimeEntries
                        .get(key)
                        .entries.concat(newEntries),
                    dayEntry,
                    dayEntry
                        ? dayEntry.id
                        : newSequentialId(),
                    key,
                    this.employee.id
                )
            ); //Add to our existing grouped colection
        }

        return newEntries;
    }

    createTimeEntry(
        start: Date,
        end: Date,
        type: TimeEntryType,
        name: string,
        createdBy: string
    ): TimeEntry {
        let rtn: TimeEntry = null;

        rtn = new TimeEntry();
        rtn.id = newSequentialId();
        rtn.start = start;
        rtn.end = end;
        rtn.type = type;
        rtn.comments = type.name ? "Driving to " + name : null;
        rtn.createdBy = this.employee.id;
        rtn.createdDate = new Date();
        rtn.updatedBy = rtn.createdBy;
        rtn.updatedDate = rtn.createdDate;
        rtn.employeeId = this.employee.id;

        return rtn;
    }

    //Link to time-picker's implementation guide: https://daniel-projects.firebaseapp.com/owlng/date-time-picker#calendar-timer
    addNewTimeEntry(): void {
        const data: AddTimeEntryViewmodel = new AddTimeEntryViewmodel();
        data.existingDailyTimeLogs = this.timeEntryViewmodels;
        data.timeEntryTypes = this.timeEntryTypes;
        data.selectedTimeEntryType = this.selectedTimeEntryType;
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.addTimeEntrynOverlayRef.close(data);
        };
        data.buttonRightDisabledFunction = () => {
            if (
                data.selectedTimeEntryType &&
                data.start &&
                data.end &&
                !data.hasErrors
            ) {
                return false;
            } else {
                return true;
            }
        };
        data.buttonRightText = "Save";
        data.buttonLeftDisabledFunction = () => {
            return false;
        };
        data.buttonLeftFunction = () => {
            this.addTimeEntrynOverlayRef.close();
        };
        data.buttonLeftText = "Cancel";

        data.headerLeftText = "Adding a New Time Log Entry";
        data.showFooter = true;
        data.selectedDate = this.selectedDate.toDate();

        this.addTimeEntrynOverlayRef = this.overlayService.open(
            AddTimeEntryComponent,
            data,
            true
        );

        this.addTimeEntrynOverlayRef.afterClosed$.subscribe((ref) => {
            if (ref?.data?.isConfirmed) {
                const myTimeEntry = new TimeEntry();
                myTimeEntry.id = newSequentialId();
                myTimeEntry.start = ref.data.start;
                myTimeEntry.end = ref.data.end;
                myTimeEntry.comments = ref.data.comments;
                myTimeEntry.type = ref.data.selectedTimeEntryType;
                myTimeEntry.employeeId = this.employee.id;
                myTimeEntry.createdDate = new Date();
                myTimeEntry.createdBy = this.employee.id;
                myTimeEntry.updatedDate = myTimeEntry.createdDate;
                myTimeEntry.updatedBy = myTimeEntry.createdBy;

                void this.saveTimeEntry(myTimeEntry);
            }
        });
    }

    onFinalizeDayClick(): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "Are you sure? Once a day is completed, it cannot be undone. This will also sync your data so please ensure you have a good, stable internet connection - WIFI is best.";
        data.buttonLeftText = "Cancel";
        data.buttonLeftFunction = () => {
            this.confirmationRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.confirmationRef.close(data);
        };

        this.confirmationRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationRef.afterClosed$
            .pipe(
                map(async (ref) => {
                    if (ref?.data?.isConfirmed) {
                        this.isCompletingDay = false;
                        await this.timeEntryDelineationService.upsertDayTimeEntry(
                            this.dailyTimeEntry.buildDomainModelFromViewModel()
                        );
                        this.buildViewModels(this.employee);
                        this.dailyTimeEntry.isCompleted = true;
                        console.log('Sync Triggered by complete day');
                        await this.syncService.forceSync();

                    }
                })
            )
            .subscribe();
    }

    onCancelClick(): void {
        this.isCompletingDay = false;
    }

    async saveTimeEntry(myTimeEntry: TimeEntry, shouldRebuild: boolean = true, i?: number): Promise<void> {
        myTimeEntry.updatedDate = new Date();
        myTimeEntry.updatedBy = this.employee.id;

        if (myTimeEntry.type?.name === "Call") {
            const call = (await this.callDelineationService.getCallById(myTimeEntry.sourceId))?.values;
            if (!call) {
                if (!!i || i === 0) {
                    const vm = new TimeEntryViewModel(
                        this.defaultTimeEntryType,
                        myTimeEntry
                    );
                    this.cancelEdit(vm, i);
                }
                return;
            }

            await this.updateRetailCall(myTimeEntry, call);
        }

        await this.timeEntryDelineationService.upsert(myTimeEntry);
        if (shouldRebuild) {
            await this.buildViewModels(this.employee);
        }
    }

    async updateRetailCall(myTimeEntry: TimeEntry, call: Call): Promise<void> {

        call.closingNotes = myTimeEntry.comments;
        call.modifiedUtcDateTime = new Date();
        await this.callDelineationService.save(call);
    }

    setDailyTimeEdit(): void {
        this.timeEditInProgress =
            this.timeEntryViewmodels.filter((tevm) => tevm.isEditMode)?.length >
            0;
    }

    setDayCanBeFinalized(): void {
        this.checkForWorkWithTimeErrors();
        const response = !(
            this.dailyTimeEntry?.dayTimeEntryWorkWiths?.filter(
                (ww) => !ww.person || !ww.start || !ww.end
            )?.length > 0
            && !this.hasWorkWithTimeErrors
        );
        this.dayCanBeFinalized = response;
    }

    async deleteTimeEntry(entry: TimeEntryViewModel): Promise<void> {
        if (entry.type?.name === this.timeEntryTypeCall) {
            this.snackbar.showWarning("Can not delete Call entries.")
        } else if (!entry.type?.name) {
            this.cleanTimeEntries();
            for (const gapEntry of this.timeEntryViewmodels.filter((te) => !te.type?.name)) {
                gapEntry.isDeleted = true;
                await this.saveTimeEntry(
                    TimeEntryViewModel.buildDomainModelFromViewmodel(
                        gapEntry,
                        this.employee.id
                    ), false
                );
            }
        } else {
            this.cleanTimeEntries();
            entry.isDeleted = true;
            await this.saveTimeEntry(
                TimeEntryViewModel.buildDomainModelFromViewmodel(
                    entry,
                    this.employee.id
                )
            );
        }

        await this.buildViewModels(this.employee);
        this.evaluateCompleteDay();
    }

    cleanTimeEntries():void {
        for (let i = 0; i < this.timeEntryViewmodels.length; i++) {
            this.recordForm.removeControl("startTime" + i);
            this.timeEntryViewmodels[i].startTimeSubscription?.unsubscribe();
            this.recordForm.removeControl("endTime" + i);
            this.timeEntryViewmodels[i].endTimeSubscription?.unsubscribe();
            this.recordForm.removeControl("type" + i);
            this.timeEntryViewmodels[i].typeSubscription?.unsubscribe();
        }

    }

    async setValidations(
        entry: TimeEntryViewModel,
        isEdit: boolean,
        i: number
    ): Promise<void> {
        entry.isEditMode = isEdit;

        this.setDailyTimeEdit();
        //the order can change with gap entries being added
        // remove the form control and subscriptions
        this.cleanTimeEntries();

        if (isEdit) {
            this.addValidators(i.toString());
                this.recordForm.controls["startTime" + i.toString()].setValue(
                    this.timeEntryViewmodels[i].start
                );
                this.recordForm.controls["endTime" + i.toString()].setValue(
                    this.timeEntryViewmodels[i].end
                );
                this.recordForm.controls["type" + i.toString()].setValue(
                    this.timeEntryViewmodels[i].type
                );

                entry.startTimeSubscription = this.recordForm.controls[
                    "startTime" + i.toString()
                ].valueChanges.subscribe(() => {
                    setTimeout(() => {
                        const myDate = this.selectedDate.toDate();
                        const start = this.recordForm.controls[
                            "startTime" + i.toString()
                        ].value as Date;
                        if (myDate && start) {
                            myDate.setHours(
                                start.getHours(),
                                start.getMinutes(),
                                0,
                                0
                            );

                            this.timeEntryViewmodels[i].start = myDate;
                            entry.duration = entry.setDuration();
                        }

                        if (
                            this.recordForm.controls["endTime" + i.toString()]
                                .invalid
                        ) {
                            this.recordForm.controls[
                                "endTime" + i.toString()
                            ].updateValueAndValidity();
                        }
                        if (
                            this.recordForm.controls["type" + i.toString()]
                                .invalid
                        ) {
                            this.recordForm.controls[
                                "type" + i.toString()
                            ].updateValueAndValidity();
                        }
                        this.checkForTimeErrors();
                    }, 0);
                });

                entry.endTimeSubscription = this.recordForm.controls[
                    "endTime" + i.toString()
                ].valueChanges.subscribe(() => {
                    setTimeout(() => {
                        const myDate = this.selectedDate.toDate();
                        const end = this.recordForm.controls[
                            "endTime" + i.toString()
                        ].value as Date;
                        if (myDate && end) {
                            myDate.setHours(end.getHours(), end.getMinutes(), 0, 0);
                        }
                        this.timeEntryViewmodels[i].end = myDate;
                        if (
                            this.recordForm.controls["startTime" + i.toString()]
                                .invalid
                        ) {
                            this.recordForm.controls[
                                "startTime" + i.toString()
                            ].updateValueAndValidity();
                        }
                        if (
                            this.recordForm.controls["type" + i.toString()]
                                .invalid
                        ) {
                            this.recordForm.controls[
                                "type" + i.toString()
                            ].updateValueAndValidity();
                        }
                        this.checkForTimeErrors();
                    }, 0);
                });

                entry.typeSubscription = this.recordForm.controls[
                    "type" + i.toString()
                ].valueChanges.subscribe(() => {
                    setTimeout(() => {
                        const type = this.recordForm.controls[
                            "type" + i.toString()
                        ].value;
                        this.timeEntryViewmodels[i].type = type;
                        entry.duration = entry.setDuration();
                        if (
                            this.recordForm.controls["startTime" + i.toString()]
                                .invalid
                        ) {
                            this.recordForm.controls[
                                "startTime" + i.toString()
                            ].updateValueAndValidity();
                        }
                        if (
                            this.recordForm.controls["endTime" + i.toString()]
                                .invalid
                        ) {
                            this.recordForm.controls[
                                "endTime" + i.toString()
                            ].updateValueAndValidity();
                        }
                        this.checkForTimeErrors();
                    }, 0);
                });
            //}
        } else {

            await this.saveTimeEntry(
                TimeEntryViewModel.buildDomainModelFromViewmodel(
                    entry,
                    this.employee.id
                ), true, i
            );
        }
        this.setDailyTimeEdit();
        this.evaluateCompleteDay();
    }

    async cancelEdit(entry: TimeEntryViewModel, i: number): Promise<void> {
        entry.isEditMode = false;
        const newEntry = (await this.timeEntryDelineationService.getById(entry.id))?.values;
        if (!newEntry) return;

        const newViewModel = new TimeEntryViewModel(
            this.defaultTimeEntryType,
            newEntry
        );
        const index = this.timeEntryViewmodels.findIndex(
            (tev) => tev.id === entry.id
        );
        if (index !== -1) {
            this.timeEntryViewmodels.splice(index, 1, newViewModel);
        }
        this.recordForm.controls["startTime" + i.toString()].setValue(
            newViewModel.start
        );
        this.recordForm.controls["endTime" + i.toString()].setValue(
            newViewModel.end
        );
        this.recordForm.controls["type" + i.toString()].setValue(
            newViewModel.type
        );

        this.setDailyTimeEdit();
        this.evaluateCompleteDay();
    }

    addWorkWith(): void {
        if (this.dailyTimeEntry.dayTimeEntryWorkWiths.length < 3) {
            const ww = new DayTimeEntryWorkWithViewmodel();
            ww.id = newSequentialId();
            this.dailyTimeEntry.dayTimeEntryWorkWiths.push(ww);
            this.addWorkWithValidators(
                (
                    this.dailyTimeEntry.dayTimeEntryWorkWiths.length - 1
                ).toString()
            );
            if (
                !ww.worksWithStartTimeSubscription ||
                ww.worksWithStartTimeSubscription.closed
            ) {
                const i = this.dailyTimeEntry.dayTimeEntryWorkWiths.length - 1;
                ww.worksWithStartTimeSubscription = this.workWithForm.controls[
                    "workWithStartTime" + i.toString()
                ].valueChanges.subscribe(() => {
                    setTimeout(() => {
                        const myDate = this.selectedDate.toDate();
                        const time = this.workWithForm.controls[
                            "workWithStartTime" + i.toString()
                        ].value as Date;
                        if (myDate && time) {
                            myDate.setHours(
                                time.getHours(),
                                time.getMinutes(),
                                time.getSeconds(),
                                0
                            );
                        }

                        this.dailyTimeEntry.dayTimeEntryWorkWiths[i].start = myDate;
                        ww.setDuration();
                        this.setDayCanBeFinalized();

                        if (
                            this.workWithForm.controls[
                                "workWithEndTime" + i.toString()
                            ].invalid
                        ) {
                            this.workWithForm.controls[
                                "workWithEndTime" + i.toString()
                            ].updateValueAndValidity();
                        }
                    }, 0);
                });
            }

            if (
                !ww.worksWithEndTimeSubscription ||
                ww.worksWithEndTimeSubscription.closed
            ) {
                const i = this.dailyTimeEntry.dayTimeEntryWorkWiths.length - 1;
                ww.worksWithEndTimeSubscription = this.workWithForm.controls[
                    "workWithEndTime" + i.toString()
                ].valueChanges.subscribe(() => {
                    setTimeout(() => {
                        const myDate = this.selectedDate.toDate();
                        const time = this.workWithForm.controls[
                            "workWithEndTime" + i.toString()
                        ].value as Date;
                        if (myDate && time) {
                            myDate.setHours(
                                time.getHours(),
                                time.getMinutes(),
                                time.getSeconds(),
                                0
                            );
                        }

                        this.dailyTimeEntry.dayTimeEntryWorkWiths[i].end = myDate;
                        ww.setDuration();
                        this.setDayCanBeFinalized();

                        if (
                            this.workWithForm.controls[
                                "workWithStartTime" + i.toString()
                            ].invalid
                        ) {
                            this.workWithForm.controls[
                                "workWithStartTime" + i.toString()
                            ].updateValueAndValidity();
                        }
                    }, 0);
                });
            }
        } else {
            const message = "You may only add up to three Work Withs";
            this.snackbar.showInfo(message);
        }
        this.setDayCanBeFinalized();
    }

    checkForWorkWithTimeErrors(): void {
        let hasErrors = false;
        for (
            let i = 0;
            i < this.dailyTimeEntry?.dayTimeEntryWorkWiths?.length;
            i++
        ) {
            hasErrors =
                !this.workWithForm.controls["workWithStartTime" + i.toString()]
                    .valid ||
                !this.workWithForm.controls["workWithEndTime" + i.toString()]
                    .valid;
            if (hasErrors) {
                break;
            }
        }

        this.hasWorkWithTimeErrors = hasErrors;
    }

    checkForTimeErrors(): void {
        let hasErrors = false;
        for (let i = 0; i < this.timeEntryViewmodels.length; i++) {
            hasErrors =
                !this.recordForm.controls["startTime" + i.toString()]?.valid ||
                !this.recordForm.controls["endTime" + i.toString()]?.valid ||
                !this.recordForm.controls["type" + i.toString()]?.valid;
            if (hasErrors) {
                break;
            }
        }
        this.hasTimeErrors = hasErrors;
    }

    removeWorkWith(ww: DayTimeEntryWorkWithViewmodel): void {
        const index = this.dailyTimeEntry.dayTimeEntryWorkWiths.findIndex(
            (myWw) => myWw.id === ww.id
        );

        if (index !== -1) {
            this.dailyTimeEntry.dayTimeEntryWorkWiths.splice(index, 1);
        }

        this.unsubscribeWorkWith(ww);
        this.setDayCanBeFinalized();
    }

    compareTimeTypeOptions(a: TimeEntryType, b: TimeEntryType): boolean {
        return a?.id === b?.id;
    }

    async onViewChanged(): Promise<void> {
        this.selectedDate = this.calendar.activeDate;
        await this.buildViewModels(this.employee);
    }
}

const isDateValid = (date: Date) => !isNaN(date?.getTime());
