import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
    GenericVersionResponseDto, ProductDto, SharedHelper,
    SystemInformationKeys, VersionResponseDto
} from "shield.shared";
import { DatabaseService } from "../../services/database.service";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { ProductConverterService } from "src/app/services/converter-services/product-converter.service";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { SyncLevel } from "../sync-enums/sync-level.enum";

@Injectable()
export class ProductDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.Initial;
    offlineRepSyncType = SyncLevel.Initial;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.product;

    constructor(private dbService: DatabaseService
        , private http: HttpClient) {
        super();
    }

    async execute(): Promise<void> {
        const lastVersion = this.lastSyncVersion;

        if (!(await this.checkVersion(lastVersion))) {
            this.log("Product data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Product data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.productPullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;
        try {
            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;
                const response = await this.http
                    .get<GenericVersionResponseDto<ProductDto[]>>(`/api/products${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                this.log(
                    `Downloaded ${thisBatchSize} products, saving to IndexedDB...`
                );

                const products = response.values.map((dto) =>
                    ProductConverterService.productDtoToProduct(dto)
                );

                // remove deleted products
                const deletedProductIds = products
                    .filter((p) => p.isDeleted)
                    .map((p) => p.id);
                await this.dbService.products.bulkDelete(deletedProductIds);

                // put new/changed products
                const putProducts = products.filter((p) => !p.isDeleted);
                await this.dbService.products.bulkPut(putProducts);

                this.lastSyncVersion = response.maxVersion;

            } while (thisBatchSize > 0);

            this.log("Done saving product data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing products", e);
        }
    }

    async pushData(): Promise<void> {
        return;
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/products/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }
}
