import { GratisAssignedTransactionLineItem } from "./gratis-assigned-transaction-line-item.entity";

export class GratisAssignedTransaction {
    id!: string;
    transactionId: string | null;
    callId: string | null;
    callDate!: Date;
    callClosingNotes: string | null;
    manualGratisId: string | null;
    customerId: string | null;
    externalSyncId: string | null;
    description: string | null;
    customerName: string | null;
    customerAddress1: string | null;
    customerAddress2: string | null;
    customerCity: string | null;
    customerState: string | null;
    customerZip: string | null;
    customerCounty: string | null;
    receiptNumber: string | null;
    salesTotal: number | null;
    netTotal: number | null;
    gratisTotal!: number;
    gratisPercentage: number | null;
    gratisAssignedTransactionLineItems: GratisAssignedTransactionLineItem[];
    carryForwardGratisId: string | null;
    carryForwardGratisNumber: string | null;
    contractPaymentGratisId: string | null;
    createdUtcDateTime: Date | null;
    createdUserId!: string;
    createdUserZrt: string | null;
    createdUserFullName!: string;
    modifiedUtcDateTime: Date | null;
    modifiedUserId!: string;
    modifiedUserZrt: string | null;
    modifiedUserFullName!: string;

    constructor() {
        this.transactionId = null;
        this.callId = null;
        this.manualGratisId = null;
        this.customerId = null;
        this.externalSyncId = null;
        this.description = null;
        this.callClosingNotes = null;
        this.customerName = null;
        this.customerAddress1 = null;
        this.customerAddress2 = null;
        this.customerCity = null;
        this.customerState = null;
        this.customerZip = null;
        this.customerCounty = null;
        this.receiptNumber = null;
        this.salesTotal = null;
        this.netTotal = null;
        this.gratisPercentage = null;
        this.carryForwardGratisId = null;
        this.carryForwardGratisNumber = null;
        this.contractPaymentGratisId = null;
        this.createdUtcDateTime = null;
        this.createdUserZrt = null;
        this.modifiedUtcDateTime = null;
        this.modifiedUserZrt = null;

        this.gratisAssignedTransactionLineItems = new Array<GratisAssignedTransactionLineItem>();
    }
}
