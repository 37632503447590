import { CallProductTax } from "./call-cash-tax.entity";

export class CallCashProduct {
    id: string; //UUID
    productId: string; //Products UUID;
    quantity: number;
    units: number;
    price: number;
    discount: number;
    upc: string;
    callProductTax: CallProductTax | null;
}
