import { SystemInformationDto } from "shield.shared";
import { SystemInformation } from "src/app/entity-models/system-information.entity";

export class SystemInformationConverterService {

    static systemInformationToSystemInformationDto(entity: SystemInformation): SystemInformationDto {

        const rtn = new SystemInformationDto();

        rtn.id = entity.id;
        rtn.key = entity.key;
        rtn.value = entity.value;
        rtn.datatype = entity.datatype;
        rtn.rowversion = entity.rowversion;

        return rtn;
    }

    static systemInformationDtoToSystemInformation(dto: SystemInformationDto): SystemInformation {

        const rtn = new SystemInformationDto();

        rtn.id = dto.id;
        rtn.key = dto.key;
        rtn.value = dto.value;
        rtn.datatype = dto.datatype;
        rtn.rowversion = dto.rowversion;

        return rtn;

    }
}
