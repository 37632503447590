import {
    CallTypes,
    CustomerTypeEnum,
    GenericLookup
} from "shield.shared";
import { Px3Rank } from "src/app/entity-models/px3-rank.entity";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";
import { FilterService } from "src/app/services/filter.service";

export class CallPicturesAttributesViewmodel {
    filterService: FilterService;
    all = "All";
    yes = "Yes";
    no = "No";

    allCustomerType = new GenericLookup<CustomerTypeEnum>();
    isAllCustomerTypePresent: boolean;
    allPx3Rank = new GenericLookup<Px3Rank>();
    notRankedPx3Rank = new GenericLookup<Px3Rank>();
    isAllPx3RankPresent: boolean;
    px3Ranks = new Array<GenericLookup<Px3Rank>>();
    customerTypes = new Array<GenericLookup<CustomerTypeEnum>>();
    selectedCustomerTypes = new Array<GenericLookup<CustomerTypeEnum>>();

    allCallType = "";
    isAllCallTypePresent: boolean;
    callTypes = new Array<string>();
    selectedCallTypes = new Array<string>();

    allPictureType = "";
    isAllPictureTypePresent: boolean;
    pictureTypes = new Array<string>();
    selectedPictureTypes = new Array<string>();
    selectedPx3Ranks = new Array<GenericLookup<Px3Rank>>();

    tagInput: string;

    constructor(filterService: FilterService, private px3RankService: Px3DelineationService) {
        this.filterService = filterService;
        this.allCustomerType.name = this.all;
    }

    async initializeTypeDropDowns(): Promise<void> {
        this.allCustomerType.name = this.all;
        this.selectedCustomerTypes.push(this.allCustomerType);
        this.customerTypes.push(this.allCustomerType);
        const customerTypes = await this.filterService.getCustomerTypes();
        if(customerTypes) {
            for(let type of customerTypes) {
                this.customerTypes.push(type);
            }
        }

        this.allCallType = this.all;
        this.selectedCallTypes.push(this.allCallType);
        this.callTypes.push(this.allCallType);
        this.callTypes.push(CallTypes.chainHq);
        this.callTypes.push(CallTypes.retail);
        this.callTypes.push(CallTypes.wholesale);

        this.allPictureType = "All";
        this.selectedPictureTypes.push(this.allCallType);
        this.pictureTypes.push(this.allCallType);
        this.pictureTypes.push("Displays");
        this.pictureTypes.push("Signage");
        this.pictureTypes.push("Other");
    }

    async initializePx3RankDropDown(): Promise<void> {
        this.allPx3Rank.name = "All";
        this.notRankedPx3Rank.name = "Not Ranked";
        this.px3Ranks.push(this.allPx3Rank);
        this.px3Ranks.push(this.notRankedPx3Rank);
        const px3Ranks = (await this.px3RankService.getAll());
        for (let rank of px3Ranks){
            this.px3Ranks.push({
                id: rank,
                name: rank.rank,
                description: rank.rank,
            });
        }
        this.selectedPx3Ranks.push(this.allPx3Rank);
    }
}
