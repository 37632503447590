import { Injectable } from "@angular/core";
import { CustomerStateService } from "src/app/accounts/account-services/customer-state.service";
import { ChainHqCall } from "src/app/entity-models/chain-hq-call.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { CallService } from "../../call-services/call.service";
import { CallOrderProductViewModel } from "../../call-viewmodels/call-order-product.viewmodel";
import { WholesalerViewmodel } from "src/app/shared/viewmodels/wholesaler.viewmodel";

import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { SurveyDelineationService } from "src/app/services/delineation-services/survey-delineation.service";
import { Survey } from "src/app/entity-models/survey.entity";
import { GenericResponseDto, newSequentialId } from "shield.shared";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { WholesalerGroupProductCatalogItemDelineationService } from "src/app/services/delineation-services/wholesaler-group-product-catalog-item-delineation.service";

@Injectable()
export class ChainHqApplicationService {

    chainHqCallOrderProductViewModels: CallOrderProductViewModel[] = [];
    constructor(private callService: CallService,
        private customerStateService: CustomerStateService,
        private customerDelineationService: CustomerDelineationService,
        private productDelineationService: ProductDelineationService,
        private projectDelineationService: ProjectDelineationService,
        private surveryDelineationService: SurveyDelineationService) {}

        async buildOrderProductViewModel(call: ChainHqCall, hasInjectedData: boolean): Promise<void> {
            const callOrderProductViewModels: CallOrderProductViewModel[] = [];

                call.orderProducts ??= [];

                for (const orderProduct of call?.orderProducts) {
                    const orderProductViewModel: CallOrderProductViewModel
                        = new CallOrderProductViewModel(hasInjectedData, orderProduct.callOrderDates);
                    orderProductViewModel.id = orderProduct.id;
                    orderProductViewModel.product = this.productDelineationService.activeProducts.get(orderProduct.productId);
                    orderProductViewModel.uin = orderProduct.uin;
                    orderProductViewModel.quantity = orderProduct.quantity;
                    orderProductViewModel.units = orderProduct.units;
                    orderProductViewModel.oldUnits = orderProductViewModel.units;
                    orderProductViewModel.oldQuantity =
                        orderProductViewModel.quantity;
                    orderProductViewModel.upc = orderProduct.upc;
                    orderProductViewModel.storeCount = orderProduct.storeCount;

                    const wholesalersResponse = await this.customerDelineationService.getWholesalersbyProduct(orderProductViewModel.product);
                    if (!wholesalersResponse) { return; }
                    const wholesalers = wholesalersResponse.values;
                    orderProductViewModel.wholesalers = wholesalers.map((w) => new WholesalerViewmodel(w));

                    if (orderProduct.wholesalerCustomerId) {
                        orderProductViewModel.wholesaler = [orderProductViewModel.wholesalers.find(w => w.id === orderProduct.wholesalerCustomerId)];
                    }

                    callOrderProductViewModels.push(orderProductViewModel);
                };
            this.chainHqCallOrderProductViewModels = callOrderProductViewModels;
        }

    async startCall(
        employee: Employee
    ): Promise<void> {

        const startedProjectsResponse = await this.projectDelineationService.getStartedProjectsByCustomerAndEmployeeIds(this.customerStateService.customer.id, employee.id);
        if (!startedProjectsResponse) { return; }

        let surveyIds = new Array<string>();

        for (const project of startedProjectsResponse.values) {
            surveyIds = surveyIds.concat(project.projectActivitySurveys.map((as) => as.activitySurveyId));
        }

        const surveyResponse = surveyIds?.length ? await this.surveryDelineationService.getByIds(surveyIds) : new GenericResponseDto<Survey[]>();

        if (!surveyResponse) { return; }

        let chainHqCall: ChainHqCall;
        let call: ChainHqCall;
        let callsResponse = await this.callService.getLocalCallsByCustomerId(this.customerStateService.customer.id);

        if (!callsResponse) {return; }

        if (callsResponse.values) {
            call = callsResponse.values.find(
                (myCall) =>
                    myCall.stopTime == null && myCall.createdUserId === employee.id
            ) as ChainHqCall;
        }

        if (call) {
            chainHqCall = call;
        } else {
            const now: Date = new Date();
            chainHqCall = new ChainHqCall();
            chainHqCall.createdUserFullName = employee.fullName;
            chainHqCall.createdUtcDateTime = now;
            chainHqCall.createdUserId = employee.id;
            chainHqCall.createdUserZrt = employee.zrt;
            chainHqCall.id = newSequentialId();
            chainHqCall.customerId = this.customerStateService.customer.id;
            chainHqCall.startTime = now;
            this.callService.pictures = [];
        }

        this.callService.call = chainHqCall;

        if (
            this.callService.call &&
            (!this.callService.call.surveys ||
                this.callService.call.surveys.length === 0)
        ) {
            this.callService.call.surveys = surveyResponse.values?.map((s) =>
                this.callService.buildCallActivitySurveyViewModelFromSurvey(s, this.callService.call.surveyAnswers))
                    ?? [];
        }

        await this.callService.saveCallAndNotify();
    }

    resetCall(): void {
        this.callService.call = null;
        this.callService.pictures = [];
    }
}
