import { Component, OnInit } from "@angular/core";
import { AppStateService } from "../services/app-state.service";
import { AccountInfo } from "@azure/msal-browser";
import { version } from "../../../package.json";
import { PingService } from "../services/ping.service";
import { DatasourceDelineationService } from "../services/delineation-services/datasource-delineation.service";
import { SyncService } from "../services/sync.service";
import { Router } from "@angular/router";
import { DatabaseService } from "../services/database.service";
import {
    faBookDead, IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { SystemInformationDelineationService } from "../services/delineation-services/system-information-delineation.service";
import { SystemInformationKeys } from "shield.shared";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { takeUntil, takeWhile } from "rxjs/operators";
import { Employee } from "../entity-models/employee.entity";
import { Helper } from "../helpers/helper";
import { CallService } from "../accounts/call-master/call-services/call.service";
import { CustomerDelineationService } from "../services/delineation-services/customer-delineation.service";
import { DataSyncQueueService } from "../sync/data-sync-queue.service";
import { DialogService } from "../services/dialog.service";

@UntilDestroy()
@Component({
    selector: "app-nav-menu",
    templateUrl: "./nav-menu.component.html",
    styleUrls: ["./nav-menu.component.scss"]
})
export class NavMenuComponent implements OnInit {
    currentUser: AccountInfo = null;
    deadLetterCount = 0;
    employee: Employee;
    inboundSyncing: boolean;
    isChecked = false;
    isMenuCollapsed = true;
    isZone19 = false;
    lastMsaSync = "Unknown";
    lastSync = 'Unknown';
    loggedIn = false;
    offlineAlert = false;
    onlineAlert = false;
    outboundSyncing: boolean;
    version: string;
    pingInitalized = false
    syncMessage = "";
    syncTotal = 0;
    callTotal = 0;


    constructor(
        private appStateService: AppStateService,
        private datasourceDelineationService: DatasourceDelineationService,
        private dbService: DatabaseService,
        private pingService: PingService,
        private router: Router,
        public syncService: SyncService,
        private systemInformationDelineationService: SystemInformationDelineationService,
        private callService: CallService,
        private customerDelineationService: CustomerDelineationService,
        private queueService: DataSyncQueueService,
        private dialogService: DialogService,
    ) {
        appStateService.currentUser.subscribe((user) => {
            this.currentUser = user;
            this.loggedIn = !!user;
        });
        appStateService.currentEmployee.subscribe((employee) => {
            if (employee) {
                this.employee = employee;
                this.isZone19 = employee.zone === '19' ||
                    Helper.isEmployeeCustomerServiceOrAdmin(employee);
            }
        });

        this.queueService.queueItemAdded$.pipe(untilDestroyed(this)).subscribe(async _ => {
            await this.refreshSyncMessage();
        });
        this.refreshSyncMessage();
    }

    private async refreshSyncMessage() {
        const counts = await this.queueService.getPendingSyncCounts();
        this.syncTotal = Object.values(counts).reduce((acc, count) => count + acc, 0);
        this.callTotal = counts.Call || 0;
        if (this.syncTotal === 0) {
            this.syncMessage = "All data has been sent from your local device.";
        } else {
            this.syncMessage = `You currently have ${this.syncTotal} message${this.syncTotal === 1 ? "" : "s"} to sync...`;
            const entityCounts = Object.keys(counts).map(k => `${k}: ${counts[k]}`);
            this.syncMessage += `\n${entityCounts.join("\n")}`;
        }
    }

    get isAdmin() {
        return Helper.isEmployeeShieldAdmin(this.employee);
    }

    async ngOnInit(): Promise<void> {
        this.lastSync = this.syncService.lastSync;
        this.syncService.inboundSyncing.subscribe(
            (inboundSyncing) => (this.inboundSyncing = inboundSyncing)
        );

        this.syncService.outboundSyncing.subscribe(
            (outboundSyncing) => (this.outboundSyncing = outboundSyncing)
        );

        this.pingService.online.subscribe((online) => {
            if (online && this.offlineAlert) this.onlineAlert = true;
            this.offlineAlert = !online;
        });

        this.pingService.observablePingIsInitalized.pipe(takeWhile((result) => !this.pingInitalized)).subscribe((result) => {
            if (result) {
                this.pingInitalized = true;
                void this.getLastMsaRefresh();
            }
        });


        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.version = version;

        await this.deadLetterCheck();
    }

    async triggerSync() {
        console.log('Sync Triggered by nav button');
        this.dialogService.showPromptDialog(
            `Sending calls to the server. Please do not close the browser and ensure your
                machine does not enter sleep mode until the process is complete.`,
            "Sync Started",
        );
        await this.syncService.forceSync();
    }

   async getLastCall() {
    const openCall = await this.callService.getOpenCall(this.employee)
    if (openCall && openCall.customerId) {
        const openCustomerResponse = await this.customerDelineationService.getById(openCall.customerId);
        if (!openCustomerResponse) {
            this.router.navigate(["accounts/customers"])
        } else {
            const openCustomer = openCustomerResponse.values;
            this.router.navigate([`accounts/${openCustomer.id}/profile`])
        }
   } else {
        const lastCall = await this.callService.getLastCompletedCallByEmployeeId(this.employee)
        if (lastCall && lastCall.customerId) {
            const lastCustomerResponse = await this.customerDelineationService.getById(lastCall.customerId);
            if (lastCustomerResponse) {
                const lastCustomer = lastCustomerResponse.values;
                this.router.navigate([`accounts/${lastCustomer.id}/profile`])
            } else {
                this.router.navigate(["accounts/customers"])
            }
       } else {
            this.router.navigate(["accounts/customers"])
       }
   }
}


    login(): void {
        this.appStateService.login();
    }

    logout(): void {
        this.appStateService.logout();
    }

    async getLastMsaRefresh(): Promise<void> {
        const lastMsaRefreshResponse = await this.systemInformationDelineationService.getByKey(SystemInformationKeys.lastMsaRefreshDate);
        if (lastMsaRefreshResponse?.values?.value) {
            this.lastMsaSync = lastMsaRefreshResponse?.values?.value;
        }
    }

    async getReportingUrl(): Promise<void> {
        const reportingUrlResponse = await this.systemInformationDelineationService.getByKey(SystemInformationKeys.reportingUrl);
        if (reportingUrlResponse?.values?.value) {
            window.open(reportingUrlResponse.values.value, '_blank');
        }
    }

    reload(): void {
        this.router.navigateByUrl('/user/user-profile', {skipLocationChange: true}).then(() => {
            this.router.navigate(['/']);
        });
    }

    setForceOffline(): void {
        this.isChecked = !this.isChecked;
        this.datasourceDelineationService.setForceOffline = this.isChecked;
    }

    async deadLetterCheck(): Promise<void> {

        try {
            this.deadLetterCount = await this.dbService.deadLetterQueue.count();
            setTimeout(() => {
                this.deadLetterCheck();
            }, 60000);
        } catch {
            setTimeout(() => {
                this.deadLetterCheck();
            }, 1000);
        }
    }

    getDisplayName(): string {
        return Helper.getEmployeeDisplayName(this.employee, this.currentUser.name);
    }
}
