import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    GenericVersionResponseDto, StateCategoryDto, VersionResponseDto,
    SystemInformationKeys, SharedHelper, StateCategorySyncCommand,
    SyncCommandTypes
} from "shield.shared";
import { StateCategory } from "src/app/entity-models/state-category.entity";
import { State } from "src/app/entity-models/state.entity";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { SyncLevel } from "../sync-enums/sync-level.enum";

@Injectable()
export class StateCategorySyncDataHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.Initial;
    offlineRepSyncType = SyncLevel.Initial;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.stateCategory;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing state categories...");

        await this.pullData();

        this.log("Done syncing states categories...");
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/state-categories/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private stateCategoryToStateCategoryDto(
        sc: StateCategory
    ): StateCategoryDto {
        const rtn = new State();

        rtn.id = sc.id;
        rtn.name = sc.name;
        rtn.rowversion = sc.rowversion;

        return rtn;
    }

    private stateCategoryDtoToStateCategory(
        dto: StateCategoryDto
    ): StateCategory {
        return {
            ...dto
        };
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("State Category data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("State Category data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.stateCategoryPullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;

        try {
            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;

                const response = await this.http
                    .get<GenericVersionResponseDto<StateCategoryDto[]>>(`/api/state-categories${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                this.log(
                    `Downloaded ${thisBatchSize} states, saving to IndexedDB...`
                );

                const stateCategories = response.values.map((c) =>
                    this.stateCategoryDtoToStateCategory(c)
                );

                await this.dbService.stateCategories.bulkPut(stateCategories);
                this.log(`  Saved  ${stateCategories.length} states.`);

                this.lastSyncVersion = response.maxVersion;
            } while (thisBatchSize > 0);

            this.log("Done saving state category data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing states categories", e);
        }
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<StateCategorySyncCommand>(
            SyncCommandTypes.stateCategory,
            async (message) => {
                let stateCategory = await this.dbService.stateCategories.get(
                    message.payload.id
                );

                let dto = this.stateCategoryToStateCategoryDto(stateCategory);
                if (dto) {
                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }
}
