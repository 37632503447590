import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Contact } from 'src/app/entity-models/contact.entity';
import { ReceiptSelectedFormat } from 'src/app/enums/receipt-selected-format';
import { ReceiptAddressViewModel } from '../../receipt-address.viewmodel';

@Component({
  selector: 'app-retail-format-draft',
  templateUrl: './retail-format-draft.component.html',
  styleUrls: ['./retail-format-draft.component.scss']
})
export class RetailFormatDraftComponent {

    @ViewChild("originalSwisherRetailReceipt") originalSwisherRetailReceipt: ElementRef;

    @Input() isRetailDraft = false;
    @Input() isRetailFinal = false;
    @Input() customerReceiptAddress: ReceiptAddressViewModel;
    @Input() signature: string;
    @Input() signatureRetailWidth: number;
    @Input() signatureRetailHeight: number;
    @Input() selectedContact: Contact;
    @Input() isEasEmployee: boolean;
    @Input() isTaxState: boolean;
    @Input() selectedFormat: ReceiptSelectedFormat;

    @Output() captureReady = new EventEmitter();

    isEasRetailFormatDraftBuilt = false;
    isSwisherRetailFormatDraftBuild = false;
    selectedFormats = ReceiptSelectedFormat;

    easWholesalerFormatDraftBuilt(): void {
        this.isEasRetailFormatDraftBuilt = true;
        this.isCapureReady();
    }

    swisherWholesalerFormatDraftBuilt(): void {
        this.isSwisherRetailFormatDraftBuild = true;
        this.isCapureReady();
    }

    isCapureReady(): void {
        if (this.isEasRetailFormatDraftBuilt && this.isSwisherRetailFormatDraftBuild) {
            this.captureReady.emit();
            this.isEasRetailFormatDraftBuilt = false;
            this.isSwisherRetailFormatDraftBuild = false;
        }
    }

}
