import { CallProductTaxDto } from "./call-cash-tax.dto";

export class CallCashProductDto {
    id: string;
    productId: string;
    quantity: number;
    units: number;
    price: number;
    discount: number;
    upc: string;
    callProductTax: CallProductTaxDto | null;
}
