<div class="card card-project-panel m-0 d-flex flex-column flex-grow-1">
    <div class="card-header">
        Release Dates
    </div>
    <div class="card-body p-0">
        <form [formGroup]="viewmodel.releaseDateForm">
            <div>
                <div class="row">
                    <div class="col align-self-center">
                        <mat-form-field appearance="outline" color="accent">
                            <mat-label>Visible For Field</mat-label>
                            <input #visibleForFieldDateInput matInput formControlName="visibleForFieldDate"
                                (keypress)="viewmodel.blockNonDateCharacters($event)"
                                [disabled]="viewmodel?.getReadOnlyStatus(viewmodel?.releaseDates.visibleDate)"
                                [matDatepicker]="visibleForField" [min]="viewmodel.visibleForFieldMin" required />
                            <mat-datepicker touchUi #visibleForField ></mat-datepicker>
                            <mat-datepicker-toggle matSuffix [for]="visibleForField"></mat-datepicker-toggle>
                        </mat-form-field>
                        <mat-error
                            *ngIf="viewmodel?.releaseDateForm.get('visibleForFieldDate').hasError('dateGreaterThanStartDate')">
                            Visible For Field can not be greater than the Start Date.
                        </mat-error>
                    </div>
                </div>
                <div class="row">
                    <div class="col align-self-center">
                        <mat-form-field appearance="outline" color="accent">
                            <mat-label>Start Date</mat-label>
                            <input #startDateInput matInput formControlName="startDate" [matDatepicker]="startDate"
                                (keypress)="viewmodel.blockNonDateCharacters($event)"
                                [disabled]="viewmodel?.getReadOnlyStatus(viewmodel?.releaseDates.startDate)"
                                [min]="viewmodel.startMin" required />
                            <mat-datepicker touchUi #startDate></mat-datepicker>
                            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                        </mat-form-field>
                        <mat-error
                            *ngIf="viewmodel?.releaseDateForm.get('startDate').hasError('dateGreaterThanEndDate')">
                            Start Date can not be greater than the End Date.
                        </mat-error>
                    </div>
                </div>
                <div class="row">
                    <div class="col align-self-center">
                        <mat-form-field appearance="outline" color="accent">
                            <mat-label>End Date</mat-label>
                            <input #endDateInput matInput formControlName="endDate" [matDatepicker]="endDate"
                                (keypress)="viewmodel.blockNonDateCharacters($event)"
                                [disabled]="viewmodel?.getReadOnlyStatus(viewmodel?.releaseDates.endDate)"
                                [min]="viewmodel.endMin" required />
                            <mat-datepicker touchUi #endDate></mat-datepicker>
                            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="!readOnly" class="row">
                    <div class="col-12 auto-margin">
                        <div class="h-center">
                            <button appThrottleClick mat-raised-button class="btn-white actionBtn" (throttledClick)="viewmodel.cancelProject()"
                                *ngIf="!viewmodel.isReadOnly">
                                Cancel Project
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
