<div class="signature-modal" cdkDragBoundary=".cdk-overlay-container" cdkDrag>
    <div cdkDragHandle>
        <div class="modal-header">
            Signature
            <mat-icon class="close-icon" (click)="onCancel()">close</mat-icon>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <canvas
                    #signaturePad
                    id="signature-canvas"
                    [width]="canvasWidth"
                    [height]="canvasHeight"
                ></canvas>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button mat-raised-button
            class="actionBtn btn-white"
            (click)="onClearSignature()"
        >
            Clear Signature
        </button>
        <button mat-raised-button
            class="actionBtn btn-white"
            (click)="onCancel()"
        >
            Cancel
        </button>
        <button mat-raised-button class="actionBtn sw-red-btn" (click)="save()">
            Save
        </button>
    </div>
</div>
