import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";

import { AppStateService } from "src/app/services/app-state.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { CallService } from "../../call-services/call.service";

import { Contact } from "src/app/entity-models/contact.entity";
import { Customer } from "src/app/entity-models/customer.entity";

import { RetailStepperStep } from "src/app/enums/retail-stepper-step";
import { ContactComponent } from "../../../contact/contact.component";
import { ContactViewModel } from "../../../contact/contact.viewmodel";

import jsPDF from 'jspdf';
import { CallTypes, Subsidiary, SystemInformationKeys, newSequentialId } from "shield.shared";
import { ErrorLevel } from "src/app/accounts/account-enums/error-level";
import { CallValidationViewmodel } from "src/app/accounts/account-models/call-validation.viewmodel";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { SignaturePadComponent } from "src/app/dialogs/signature-pad/signature-pad.component";
import { SignaturePadViewModal } from "src/app/dialogs/signature-pad/signature-pad.viewmodel";
import { Address } from "src/app/entity-models/address.entity";
import { CallReceipt } from "src/app/entity-models/call-receipt";
import { Employee } from "src/app/entity-models/employee.entity";
import { Receipt } from "src/app/entity-models/receipt";
import { ReceiptSettings } from "src/app/entity-models/receipt-settings.entity";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";
import { DexieTableNames } from "src/app/enums/dexie-table-names";
import { ReceiptSelectedFormat } from "src/app/enums/receipt-selected-format";
import { RmWholesaleStepperStep } from "src/app/enums/rm-wholesale-stepper-step";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ContactDelineationService } from "src/app/services/delineation-services/contact-delineation.service";
import { ReceiptDelineationService } from "src/app/services/delineation-services/receipt-delineation.service";
import { ReceiptSettingsDelineationService } from "src/app/services/delineation-services/receipt-settings-delineation.service";
import { SystemInformationDelineationService } from "src/app/services/delineation-services/system-information-delineation.service";
import { DialogService } from "src/app/services/dialog.service";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { ValidationStops } from "../../../account-enums/validation-stops";
import { CallValidationService } from "../../../account-services/call-validation.service";
import { CustomerStateService } from "../../../account-services/customer-state.service";
import { RetailReceiptService } from "../stepper-call-services/retail-receipt.service";
import { StepperCallApplicationService } from "../stepper-call-services/stepper-call-application.service";
import { ReceiptAddressViewModel } from "./receipt-address.viewmodel";
import { RetailReceiptComponent } from "./retail-receipt/retail-receipt.component";
import { WholesaleReceiptComponent } from "./wholesaler-receipt/wholesale-receipt.component";
import { ReceiptType } from "src/app/enums/receipt-type";
import { CallEventLoggerService, ReceiptRenderLocation, ReceiptRenderType } from "../../call-services/call-event-logger.service";

@Component({
    selector: "app-receipts",
    templateUrl: "./receipts.component.html",
    styleUrls: ["./receipts.component.scss"]
})
export class ReceiptsComponent implements OnInit, OnDestroy {
    @ViewChild("retailReceipt") retailReceipt: RetailReceiptComponent;
    @ViewChild("wholesaleReceipt") wholesaleReceipt: WholesaleReceiptComponent;
    @ViewChild("retailReceiptCapture") retailReceiptCapture: RetailReceiptComponent;
    @ViewChild("wholesaleReceiptCapture") wholesaleReceiptCapture: WholesaleReceiptComponent;
    @ViewChild("screen", { static: true }) screen: any;
    @ViewChild("wholesalerFinalPrintButton") wholesalerFinalPrintButton: ElementRef;
    @ViewChild("ngxPrintWholesalerDraft") ngxPrintWholesalerDraft: ElementRef;
    @ViewChild("ngxPrintWholesalerFinal") ngxPrintWholesalerFinal: ElementRef;
    @ViewChild("ngxPrintRetailFinal") ngxPrintRetailFinal: ElementRef;

    //Public vars
    contactOptions: Contact[] = [];
    //selectedContact: Contact;
    customer: Customer;
    customerSubscription: Subscription;
    selectedIndexSubscription: Subscription;
    retailCallSubscription: Subscription;
    selectedIndex: RetailStepperStep | RmWholesaleStepperStep;
    call: RetailCall | RmWholesaleCall;
    narrowRecieptFormat = "/assets/css/narrow-receipt-print.css";
    wideRecieptFormat = "/assets/css/wide-receipt-print.css";
    selectedFormat: ReceiptSelectedFormat = ReceiptSelectedFormat.narrow;
    formatOptions = ["Narrow", "Wide"];
    retailStyleSheet = this.narrowRecieptFormat;
    wholesaleStyleSheet = this.narrowRecieptFormat;
    customerReceiptAddressOptions: ReceiptAddressViewModel[];
    selectedCustomerReceiptAddress: ReceiptAddressViewModel;
    signatureWidth = 400;
    signatureHeight = 100;
    signature: string;
    contactOverlayRef: SwisherOverlayRef<ContactViewModel, ContactComponent>;
    signaturePadOverlayRef: SwisherOverlayRef<
        SignaturePadViewModal,
        SignaturePadComponent
    >;
    validationErrorMessages: string[] = [];
    isSignatureDisabled = true;
    isRetailReceiptDisabled = true;
    isWholesaleReceiptDisabled = true;
    capturedRetailReceipt: string;
    isTaxState = false;
    employee: Employee;
    employeeSubscription: Subscription;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    showPrintDraftReceipts = true; //  default to existing behavior
    paperlessDialogShown = false;
    isCapturing = false;

    private modalRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    private readonly printReceiptMessage = "Printing Receipts (This may take a few moments.)";

    get hasReceipts(): boolean {
        return (
            this.stepperCallApplicationService.hasRetailReceipts ||
            this.stepperCallApplicationService.hasWholesaleReceipts
        );
    }

    get hasRetailReceipts(): boolean {
        return this.stepperCallApplicationService.hasRetailReceipts;
    }

    get hasWholesaleReceipts(): boolean {
        return this.stepperCallApplicationService.hasWholesaleReceipts;
    }

    constructor(private contactDelineationService: ContactDelineationService,
        private overlayService: OverlayService,
        private snackBarService: SnackbarService,
        public callService: CallService,
        private stepperCallApplicationService: StepperCallApplicationService,
        private callValidationService: CallValidationService,
        public customerStateService: CustomerStateService,
        private receiptSettingsDelineationService: ReceiptSettingsDelineationService,
        private receiptDelineationService: ReceiptDelineationService,
        private appStateService: AppStateService,
        private retailReceiptService: RetailReceiptService,
        private pleaseWaitService: PleaseWaitService,
        private systemInformationDelineationService: SystemInformationDelineationService,
        private dialogService: DialogService,
        private eventLogger: CallEventLoggerService,
    ) { }

    ngOnInit(): void {
        this.systemInformationDelineationService.getByKey(SystemInformationKeys.showPrintDraftReceipts).then(response => {
            //  only update the value if the flag was present & not NaN
            if (response?.values?.value) {
                //  any non-zero int is true
                this.showPrintDraftReceipts = parseInt(response.values.value) !== 0;
            }
        });

        this.employeeSubscription = this.appStateService.currentEmployee.subscribe(
            async (employee) => {
                this.employee = employee;
                const receiptSettingsResponse = this.receiptSettingsDelineationService.getReceiptSettingsByEmployeeId(this.employee.id);
                if (!receiptSettingsResponse) {
                    this.shouldWait$.next(false);
                    return;
                }
                const receiptSettings = (await receiptSettingsResponse).values;

                if (receiptSettings) {
                    this.selectedFormat = receiptSettings.receiptSelectedFormat;
                    this.onFormatChange(this.selectedFormat);
                }
            }
        );

        if (!this.customerSubscription || this.customerSubscription.closed) {
            this.customerSubscription = this.customerStateService.observableCustomer.subscribe(
                async (customer) => {
                    await this.getContactsByCustomerId(customer);
                    await this.setSelectedContact();
                    if (customer) {
                        this.isTaxState = this.customerStateService.isTaxAvailable();
                    }
                }
            );
        }
        if (
            !this.selectedIndexSubscription ||
            this.selectedIndexSubscription.closed
        ) {
            this.selectedIndexSubscription = this.stepperCallApplicationService.observableSelectedIndex.subscribe(
                async (selectedIndex) => {
                    this.selectedIndex = selectedIndex;
                    if (
                        selectedIndex === RetailStepperStep.receipts &&
                        this.call
                    ) {
                        this.stepperCallApplicationService.shouldBuildProducts = true;

                        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

                        setTimeout(async () => {
                            await this.buildUpObjects();
                            this.shouldWait$.next(false);
                        }, 0);
                    }
                }
            );
        }

        if (
            !this.retailCallSubscription ||
            this.retailCallSubscription.closed
        ) {
            this.retailCallSubscription = this.callService.observableCall.subscribe(
                async (call) => {
                    if (call) {
                        if ((call.callType === CallTypes.retail && this.selectedIndex === RetailStepperStep.receipts)
                            || (call.callType === CallTypes.rmWholesale && this.selectedIndex === RmWholesaleStepperStep.receipts)) {
                            this.call = call;
                            await this.buildUpObjects();
                            this.shouldWait$.next(false);
                        }
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (this.customerSubscription && !this.customerSubscription.closed) {
            this.customerSubscription.unsubscribe();
        }

        if (
            this.selectedIndexSubscription &&
            !this.selectedIndexSubscription.closed
        ) {
            this.selectedIndexSubscription.unsubscribe();
        }

        if (
            this.employeeSubscription &&
            !this.employeeSubscription.closed
        ) {
            this.employeeSubscription.unsubscribe();
        }

        if (
            this.retailCallSubscription &&
            !this.retailCallSubscription.closed
        ) {
            this.retailCallSubscription.unsubscribe();
        }
    }

    //events
    async onFormatChange(event: ReceiptSelectedFormat): Promise<void> {
        this.selectedFormat = event;
        const receiptSettings = new ReceiptSettings();
        receiptSettings.employeeId = this.employee.id;
        receiptSettings.receiptSelectedFormat = event;
        await this.receiptSettingsDelineationService.persist(receiptSettings, DexieTableNames.receiptSettings);

        switch (event) {
            case ReceiptSelectedFormat.narrow:
                this.retailStyleSheet = this.narrowRecieptFormat;
                this.wholesaleStyleSheet = this.narrowRecieptFormat;
                break;
            case ReceiptSelectedFormat.wide:
                this.retailStyleSheet = this.wideRecieptFormat;
                this.wholesaleStyleSheet = this.wideRecieptFormat;

            default:
                break;
        }
    }

    openModal(retail: boolean, print: boolean): void {
        if (this.modalRef) {
            this.modalRef.close();
        }

        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message = `${print ? 'Printing' : 'Emailing'} the final receipt will lock transactions and you will not be able to make any changes, are you sure you want to proceed?`;
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => this.modalRef.close();
        data.buttonRightText = "Yes";
        data.buttonRightFunction = async () => await this.printReceipt(retail, print);


        this.modalRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.modalRef.afterClosed$.subscribe(() => {
            this.modalRef = undefined;
        });

    }

    printReceipt(retail: boolean, print: boolean) {

        //Show dialog to confirm that they want to print receipts instead of emailing them.
        if (print && !this.paperlessDialogShown) {
            this.dialogService.showConfirmDialog(
                `In efforts to go paperless, save time and resources we highly encourage you to push
                for emailing receipts rather than printing them. Would you like to try emailing
                receipt(s) now?`,
                "Paperless Receipts",
                "No",
                "Yes"
            ).subscribe(response => {
                this.paperlessDialogShown = true;
                this.performPrint(retail, !response);
            });
        } else {
            this.performPrint(retail, print);
        }
    }

    private performPrint(retail: boolean, print: boolean) {
        if (retail && print) {
            this.validationRetailReceiptPrint();
        }
        if (retail && !print) {
            this.validationRetailReceiptEmail();
        }
        if (!retail && print) {
            this.onPrintWholesaleFinal(true);
        }
        if (!retail && !print) {
            this.onPrintWholesaleFinal(false);
        }

        this.eventLogger.logReceiptRender(
            print ?
                ReceiptRenderType.Print :
                ReceiptRenderType.Email,
            ReceiptRenderLocation.CallSave,
            this.call,
            this.customer
        );
        this.modalRef.close();
    }

    async onPrintRetailDraft(): Promise<void> {
        this.retailReceipt.isRetailDraft = true;
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        setTimeout(async () => {
            const image = await this.generateReceiptPdfDataUrl(
                this.retailReceipt.swisherRetailFormat.originalSwisherRetailReceipt?.nativeElement
            );
            if (image) {
                Helper.addIFrameImage(document, [image]);
            }
            this.onCompletePrintRetailDraft();
            this.shouldWait$.next(false);
        }, 0);
    }

    onCompletePrintRetailDraft(): void {
        setTimeout(() => {
            this.retailReceipt.isRetailDraft = false;
        }, 0);
    }

    toggleWholesaleDraft(): void {
        this.wholesaleReceipt.isWholesaleDraft = true;
    }

    async onPrintWholesaleDraft(): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        setTimeout(async () => {
            if (!this.wholesaleReceipt.wholesaleDraft.wholesaleDraftReceipt) {
                this.shouldWait$.next(false);
                return;
            }

            this.wholesaleReceipt.wholesaleDraft
            const image = await this.generateReceiptPdfDataUrl(
                this.wholesaleReceipt.wholesaleDraft.wholesaleDraftReceipt.nativeElement
            );

            if (image) {
                Helper.addIFrameImage(document, [image]);
            }

            this.onCompletePrintWholesaleDraft();
            this.shouldWait$.next(false);
        }, 0);
    }

    onCompletePrintWholesaleDraft(): void {
        setTimeout(() => {
            this.wholesaleReceipt.isWholesaleDraft = false;
            this.wholesaleReceiptCapture.isWholesaleDraft = false;
            void this.wholesaleReceipt.buildViewmodels();
        }, 0);
    }

    onPrintWholesaleFinal(print = true): void {
        this.wholesaleReceipt.isWholesaleFinal = true;
        this.wholesaleReceiptCapture.isWholesaleFinal = true;
        setTimeout(() => {
            print ? this.validationWholesaleReceiptPrint() : this.validationWholesaleReceiptsEmail();
        }, 0);
    }

    async combinedWholesaleFunctions(): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        this.wholesaleReceipt.isWholesaleFinal = true;
        this.callService.isFinalWholesaleReceiptPrinted = true;
        await this.captureReceipt(ReceiptType.wholesale);
        this.shouldWait$.next(false);
    }

    onCompletePrintEmailRetailFinal(): void {
        setTimeout(() => {
            this.retailReceipt.isRetailFinal = false;
            this.retailReceiptCapture.isRetailFinal = false;
        }, 0);
    }

    onCompletePrintWholesaleFinal(): void {
        setTimeout(() => {
            this.wholesaleReceipt.isWholesaleFinal = false;
            this.wholesaleReceiptCapture.isWholesaleFinal = false;
        }, 0);
    }
    async onSelectedContact(): Promise<void> {
        await this.setSelectedContact();
        await this.callService.saveCallAndNotify();
    }

    //Public methods
    async buildUpObjects(): Promise<void> {
        if (this.callService.call
            && (
                (this.selectedIndex === RetailStepperStep.receipts
                    && this.callService.call?.callType === CallTypes.retail)
                || (this.selectedIndex === RmWholesaleStepperStep.receipts
                    && this.callService.call?.callType === CallTypes.rmWholesale)
            )
        ) {
            if (this.stepperCallApplicationService.shouldBuildProducts) {
                this.stepperCallApplicationService.shouldBuildProducts = false;
                await this.stepperCallApplicationService.buildOrderProductViewModel();
                await this.stepperCallApplicationService.buildCashProductViewModel();
                await this.stepperCallApplicationService.buildGratisProductViewModel();
                await this.stepperCallApplicationService.buildProductInViewModel();
                await this.stepperCallApplicationService.buildProductOutViewModel();
            }

            if (this.callService.call && !this.callService.call.receiptBase) {
                this.callService.call.receiptBase = Math.floor(
                    new Date().getTime() / 1000.0
                );
                await this.callService.saveCallAndNotify();
            }

            if (this.callService.call) {
                this.signature = this.callService.call.signature;
                this.runValidation();
            }
            //this.setReceiptElementReferences();
        }
    }

    setReceiptElementReferences(): void {
        if (!this.isTaxState) {
            if (this.retailReceipt &&
                !this.retailReceipt?.originalSwisherRetailReceipt &&
                this.retailReceipt?.swisherRetailFormat?.originalSwisherRetailReceipt) {
                this.retailReceipt.originalSwisherRetailReceipt = this.retailReceipt.swisherRetailFormat?.originalSwisherRetailReceipt;
            }

            if (this.retailReceipt &&
                !this.retailReceipt?.originalEasRetailReceipt &&
                this.retailReceipt.easRetailFormat?.originalEasRetailReceipt) {
                this.retailReceipt.originalEasRetailReceipt = this.retailReceipt.easRetailFormat?.originalEasRetailReceipt;
            }

            if (this.wholesaleReceipt &&
                !this.wholesaleReceipt?.swisherWholesaleFormat &&
                this.wholesaleReceipt.swisherWholesaleFormat?.originalSwisherWholesaleReceipt) {
                this.wholesaleReceipt.originalSwisherWholesaleReceipt = this.wholesaleReceipt.swisherWholesaleFormat?.originalSwisherWholesaleReceipt;
            }

            if (this.wholesaleReceipt &&
                !this.wholesaleReceipt?.easWholesaleFormat &&
                this.wholesaleReceipt.easWholesaleFormat?.originalEasWholesaleReceipt) {
                this.wholesaleReceipt.originalEasWholesaleReceipt = this.wholesaleReceipt.easWholesaleFormat?.originalEasWholesaleReceipt;
            }
        }
    }

    async setSelectedContact(): Promise<void> {
        if (
            (
                (this.selectedIndex === RetailStepperStep.receipts
                    && this.callService.call?.callType === CallTypes.retail)
                || (this.selectedIndex === RmWholesaleStepperStep.receipts
                    && this.callService.call?.callType === CallTypes.rmWholesale
                )
            )
            && this.call?.selectedContact) {
            this.call.selectedContact = this.contactOptions.find((c) => c.id === this.call.selectedContact.id);
            this.callService.call.selectedContact = this.call.selectedContact;
        }
    }

    async generateReceiptPdfDataUrl(htmlElement: HTMLElement): Promise<string> {
        let settings = {
            width: htmlElement.scrollWidth + 20 < 420 ? 460 : htmlElement.scrollWidth + 20,
            height: htmlElement.scrollHeight + 20 < 660 ? 660 : htmlElement.scrollHeight + 40,
            margin: [10, 10, 10, 10],
            scale: 1,
        }

        // console.log(`SETTINGS Width : ${settings.width} Height : ${settings.height}`)
        // console.log(`SCROLL Width : ${htmlElement.scrollWidth} HTML Height : ${htmlElement.scrollHeight}`)
        // console.log('HTML Element', htmlElement.offsetWidth, htmlElement.offsetHeight)
        // console.log(`***********************************************`)

        const pdf = new jsPDF('p', 'px', [settings.width, settings.height], true);
        let pdfConf = {
            margin: settings.margin,
            html2canvas: { logging: false, scale: settings.scale, allowTaint: true, useCORS: true }
        }
        // console.time("***************GENERATING RECEIPT********************");
        return new Promise(resolve => {
            pdf.html(htmlElement, pdfConf).then(() => {
                const output = pdf.output("dataurlstring");
                // console.timeEnd("***************GENERATING RECEIPT********************");
                resolve(output);
            });
        });
    }

    async captureReceipt(type: ReceiptType, email?: boolean): Promise<void> {
        this.isCapturing = true;
        setTimeout(async () => {
            await this.pleaseWaitService.withSpinnerShowing(async () => {
                try {
                    if (type === ReceiptType.retail) {
                        await this.captureRetailReceipt();
                    } else {
                        await this.captureWholesaleReceipt(email);
                    }
                } finally {
                    this.isCapturing = false;
                }
            }, this.printReceiptMessage);
        }, 0);
    }
    private async captureRetailReceipt(): Promise<void> {
        if (this.callService.call.callType === CallTypes.retail
            || this.callService.call.callType === CallTypes.rmWholesale
        ) {

            this.callService.call.callReceipts ??= [];
            const isOriginal = this.callService.call.callReceipts.filter((cr) => !cr.wholesalerId).length === 0;
            const images = new Array<string>();
            const receiptElements: [Subsidiary, ElementRef<any> | undefined][] = [
                [Subsidiary.Swisher, this.retailReceiptCapture.swisherRetailFormat.originalSwisherRetailReceipt],
                [Subsidiary.EAS, this.retailReceiptCapture.easRetailFormat.originalEasRetailReceipt],
            ];
            for (const [subsidiary, element] of receiptElements) {
                if (!element?.nativeElement) {
                    continue;
                }
                if (isOriginal) {
                    const receipt = new Receipt();
                    receipt.id = newSequentialId();

                    const callReceipt = this.retailReceiptService.buildRetailCallReceipt(
                        receipt.id,
                        subsidiary,
                        this.retailReceiptCapture
                    );

                    receipt.base64Image = await this.generateReceiptPdfDataUrl(
                        element.nativeElement
                    );
                    images.push(receipt.base64Image);
                    this.callService.call.callReceipts ??= [];
                    this.callService.call.callReceipts.push(callReceipt);
                    await this.receiptDelineationService.persist(receipt, DexieTableNames.receipts);
                    await this.callService.saveCallAndNotify();
                } else {
                    const image = await this.generateReceiptPdfDataUrl(
                        element.nativeElement
                    );
                    images.push(image);
                }
            }

            setTimeout(() => {
                Helper.addIFrameImage(document, images);
            }, 0);

            this.onCompletePrintEmailRetailFinal();
        }
        this.shouldWait$.next(false);
    }

    private async captureWholesaleReceipt(email?: boolean): Promise<void> {
        if (!email) {
            this.wholesalerFinalPrintButton?.nativeElement?.click();
        }

        if (this.callService.call.callType === CallTypes.retail
            || this.callService.call.callType === CallTypes.rmWholesale) {
            (this.callService.call as RetailCall).callReceipts ??= [];
            const isOriginal = (this.callService.call as RetailCall).callReceipts.filter((cr) => cr.wholesalerId).length === 0;
            const images = new Array<string>();
            if (isOriginal) {
                const elementRefs = this.wholesaleReceiptCapture.swisherWholesaleFormat.originalSwisherWholesaleReceipt.toArray()
                    .concat(this.wholesaleReceiptCapture.easWholesaleFormat.originalEasWholesaleReceipt.toArray());
                for (const elementRef of elementRefs) {

                    if (this.callService.call.callType === CallTypes.retail
                        || this.callService.call.callType === CallTypes.rmWholesale) {

                        const callReceipt = new CallReceipt();
                        const receipt = new Receipt();
                        callReceipt.id = newSequentialId();
                        receipt.id = callReceipt.id;
                        callReceipt.callId = this.callService.call.id;
                        callReceipt.receiptNumber = this.employee.zrt + this.callService.call?.receiptBase?.toString();
                        callReceipt.isOriginal = isOriginal;

                        callReceipt.saleUserId = this.employee.id;
                        callReceipt.saleUserZrt = this.employee.zrt;
                        callReceipt.saleUserFullName = this.employee.fullName;
                        if (this.customer.businessAddress) {
                            callReceipt.address1 = this.customer.businessAddress.address1;
                            callReceipt.address2 = this.customer.businessAddress.address2;
                            callReceipt.city = this.customer.businessAddress.city;
                            callReceipt.county = this.customer.businessAddress.county;
                            callReceipt.state = this.customer.businessAddress.state;
                            callReceipt.zip = this.customer.businessAddress.zip;
                            callReceipt.country = this.customer.businessAddress.country;
                        }
                        receipt.base64Image = await this.generateReceiptPdfDataUrl(elementRef?.nativeElement);
                        images.push(receipt.base64Image);
                        let nextReceiptExtention = this.getNextReceiptNumberExtention();

                        callReceipt.receiptNumberExtention = nextReceiptExtention.toString().padStart(2, "0");
                        const wholeReceiptNumber = callReceipt.receiptNumber + callReceipt.receiptNumberExtention;
                        let vm = this.wholesaleReceiptCapture.swisherWholesaleFormat.swisherViewmodels.find((svm) => svm.receiptNumber === wholeReceiptNumber);
                        if (!vm) {
                            vm = this.wholesaleReceiptCapture.easWholesaleFormat.easViewmodels.find((easVm) => easVm.receiptNumber === wholeReceiptNumber);
                        }
                        if (!vm) {
                            console.log("Can not find wholesaler to associate with the receipt.");
                            continue;

                        }
                        callReceipt.callReceiptLicenses ??= [];
                        if (vm.retailOptLicense) {
                            vm.retailOptLicense.callReceiptId = callReceipt.id;
                            callReceipt.callReceiptLicenses.push(vm.retailOptLicense);
                        }
                        if (vm.retailVaporLicense) {
                            vm.retailVaporLicense.callReceiptId = callReceipt.id;
                            callReceipt.callReceiptLicenses.push(vm.retailVaporLicense);
                        }
                        if (vm.wholesalerRetailOptLicense) {
                            vm.wholesalerRetailOptLicense.callReceiptId = callReceipt.id;
                            callReceipt.callReceiptLicenses.push(vm.wholesalerRetailOptLicense);
                        }

                        callReceipt.wholesalerId = vm.wholesaler.id;
                        this.callService.call.callReceipts.push(callReceipt);
                        await this.receiptDelineationService.persist(receipt, DexieTableNames.receipts);
                    }
                }

                if (!email && images.length > 0) {
                    Helper.addIFrameImage(document, images);
                }

                if (elementRefs) {
                    await this.callService.saveCallAndNotify();
                }
            } else {
                const elementRefs = this.wholesaleReceiptCapture.swisherWholesaleFormat.originalSwisherWholesaleReceipt.toArray()
                    .concat(this.wholesaleReceiptCapture.easWholesaleFormat.originalEasWholesaleReceipt.toArray());
                for (const elementRef of elementRefs) {
                    if (this.callService.call.callType === CallTypes.retail
                        || this.callService.call.callType === CallTypes.rmWholesale) {
                        const image = await this.generateReceiptPdfDataUrl(elementRef?.nativeElement);
                        images.push(image);
                    }
                }

                if (!email && images.length > 0) {
                    Helper.addIFrameImage(document, images);
                }
            }

            this.onCompletePrintWholesaleFinal();
        }
    }

    openImageInNewTab(image: string, email: boolean) {
        if (!email) {
            setTimeout(() => {
                Helper.addIFrameImage(document, [image]);
            }, 0);
        }
    }

    getNextReceiptNumberExtention(): number {
        let rtn: number = -1;
        if (this.callService.call.callType === CallTypes.retail
            || this.callService.call.callType === CallTypes.rmWholesale) {
            rtn = this.callService.call.callReceipts?.length > 0
                ? Math.max(
                    ...this.callService.call.callReceipts.map(
                        (rc) => {
                            const parsed = parseInt(
                                rc.receiptNumberExtention
                            );
                            return isNaN(parsed) ? 0 : parsed;
                        }
                    )
                )
                : 0;
            if (!rtn) {
                rtn = 2;
            } else {
                rtn++;
            }
        }
        return rtn
    }

    async getContactsByCustomerId(customer: Customer): Promise<void> {
        this.customer = customer;

        if (customer) {
            this.customerReceiptAddressOptions = this.getCustomerRecieptAddressOptions(
                this.customer
            );
            this.selectedCustomerReceiptAddress = this.customerReceiptAddressOptions[0];

            const response = await this.contactDelineationService.getByCustomerId(
                this.customer.id
            );

            if (!response) {
                this.shouldWait$.next(false);
                return;
            }

            await this.setContactOptions();
        }
    }

    private buildAddressOption(address: Address): ReceiptAddressViewModel {
        let rtn = new ReceiptAddressViewModel;
        let addressFormatted = "";
        if (address) {
            if (address.address1) {
                addressFormatted = addressFormatted.concat(address.address1, ", ");
            }

            if (address.address2) {
                addressFormatted = addressFormatted.concat(address.address2, ", ");
            }

            if (address.city) {
                addressFormatted = addressFormatted.concat(address.city, ", ");
            }

            if (address.state) {
                addressFormatted = addressFormatted.concat(address.state, ", ");
            }

            if (address.zip) {
                addressFormatted = addressFormatted.concat(address.zip);
            }
        }
        rtn.addressName = address.name ?? "";
        rtn.addressFormatted = addressFormatted;

        return rtn;
    }

    getCustomerRecieptAddressOptions(customer: Customer): ReceiptAddressViewModel[] {
        let rtn: ReceiptAddressViewModel[] = [];

        if (customer) {
            if (customer.businessAddress) {
                const address = this.buildAddressOption(
                    customer.businessAddress
                );
                if (address) {
                    rtn.push(address);
                }
            }
            if (customer.dbaAddress) {
                const address = this.buildAddressOption(customer.dbaAddress);
                if (address) {
                    rtn.push(address);
                }
            }
        }
        return rtn;
    }

    onAddContact(): void {
        const data: ContactViewModel = new ContactViewModel();
        data.customerId = this.customer.id;
        data.headerLeftText = "Retail Call - Add Contact";

        this.contactOverlayRef = this.overlayService.open(
            ContactComponent,
            data,
            true
        );

        this.contactOverlayRef.afterClosed$.subscribe((rtnData) => {
            if (rtnData) {
                void this.setContactOptions();
            }
        });
    }

    async setContactOptions(): Promise<void> {
        const response = await this.contactDelineationService.getByCustomerId(
            this.customer.id
        );

        if (!response) {
            this.shouldWait$.next(false);
            return;
        }

        this.contactOptions = response.values?.filter((c) => !c.isDeleted);
    }

    openSinatureModal(): void {
        if (this.call.selectedContact) {
            const data: SignaturePadViewModal = new SignaturePadViewModal();
            data.save = () => this.onSave();
            this.signaturePadOverlayRef = this.overlayService.open(
                SignaturePadComponent,
                data
            );
        } else {
            this.snackBarService.showWarning(
                "Please select a contact before adding a signature."
            );
        }
    }

    async onSave(): Promise<void> {
        const myData: SignaturePadViewModal = this.signaturePadOverlayRef.data;

        if (myData
            && (this.callService.call?.callType === CallTypes.retail
                || this.callService.call.callType === CallTypes.rmWholesale)
        ) {
            this.signature = myData.toDataUrlPng();
            this.callService.call.signature = this.signature;
            await this.callService.saveCallAndNotify();
            this.signaturePadOverlayRef.close();
        } else {
            this.snackBarService.showWarning("Not a valid signature");
        }
    }

    private runValidation() {
        const callValidation: CallValidationViewmodel = this.callValidationService.isCallValid(
            this.callService.call.id
        );

        const errorMessages = callValidation.validationViolations
            .filter((vv) => vv.errorLevel === ErrorLevel.invalid)
            ?.map((vv) => vv.message);

        this.validationErrorMessages = new Array<string>();
        for (const message of errorMessages) {
            const found = this.validationErrorMessages.find((m) => m === message);
            if (!found) {
                this.validationErrorMessages.push(message);
            }
        }

        this.isSignatureDisabled =
            callValidation.validationViolations.findIndex(
                (vv) =>
                    (vv.validationStops & ValidationStops.signatureHardStop) ===
                    ValidationStops.signatureHardStop
            ) !== -1;

        this.isRetailReceiptDisabled =
            callValidation.validationViolations.findIndex(
                (vv) =>
                    (vv.validationStops & ValidationStops.retailReceiptHardStop) ===
                    ValidationStops.retailReceiptHardStop
            ) !== -1;

        this.isWholesaleReceiptDisabled =
            callValidation.validationViolations.findIndex(
                (vv) =>
                    (vv.validationStops & ValidationStops.wholesaleReceiptHardStop) ===
                    ValidationStops.wholesaleReceiptHardStop
            ) !== -1;
    }

    validationRetailReceiptPrint(): void {
        this.callService.isFinalRetailReceiptPrinted = true;
        this.captureReceipt(ReceiptType.retail);
    }

    async validationWholesaleReceiptPrint(): Promise<void> {
        this.callService.isFinalWholesaleReceiptPrinted = true;
        this.captureReceipt(ReceiptType.wholesale);
    }

    validationRetailReceiptEmail(): void {
        if (!this.call.selectedContact.email) {
            this.snackBarService.showWarning(
                "No email found for contact!  Please add one and try again."
            );
        }
        else {
            this.callService.isEmailFinalRetailReceipt = true;
            this.captureReceipt(ReceiptType.retail);
            this.snackBarService.showInfo(
                "An email copy of this receipt will be sent to both " + this.call.selectedContact.firstName + " " + this.call.selectedContact.lastName + " and yourself."
            );
        }
    }

    async validationWholesaleReceiptsEmail(): Promise<void> {
        if (!this.call.selectedContact.email) {
            this.snackBarService.showWarning(
                "No email found for contact!  Please add one and try again."
            );
        }
        else {
            await this.captureReceipt(ReceiptType.wholesale, true);
            this.callService.isEmailFinalWholesaleReceipt = true;
            this.snackBarService.showInfo(
                "An email copy of this receipt will be sent to both " + this.call.selectedContact.firstName + " " + this.call.selectedContact.lastName + " and yourself."
            );
        }
    }

    compareContactOptions(a: Contact, b: Contact): boolean {
        return a?.id === b?.id;
    }
}
