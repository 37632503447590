<ng-container *ngIf="projectStateService.project && viewmodel">
    <div class="row">
        <div class="col-12">
            <ng-container>
                <div class="row">
                    <div class="col-3 heading">
                        <h1 class="m-teal">
                            Activities & Surveys
                        </h1>
                    </div>
                    <div class="col-9 text-right">
                        <button class="btn sw-red-btn"
                            appThrottleClick
                            (throttledClick)="viewmodel.onOpenAddActivityDialog()">
                            Add Activity/Survey
                        </button>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!viewmodel.selectedActivities?.length">
                <h3>You do not have any activities/surveys added to this project yet.</h3>
            </ng-container>
            <mat-accordion class="headers-align" multi>
                <ng-container *ngFor="let activity of viewmodel.selectedActivities">
                    <mat-expansion-panel [expanded]="true" [disabled]="!viewmodel.shouldExpand" (click)="viewmodel.shouldExpand = true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Activity Group Name: {{ activity.title }}</mat-panel-title>
                            <mat-panel-description>
                                Date Added: {{ projectStateService.project.startDate | date: viewmodel.dateFormat }}
                                <button class="btn btn-outline-danger" (click)="viewmodel.onRemoveActivity(activity)">
                                    <mat-icon>delete</mat-icon> Remove
                                </button>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th class="col-8">Questions</th>
                                    <th class="col-2">Response Type</th>
                                    <th class="col-2">Required Response</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let question of activity.surveyQuestions">
                                    <td>{{ question.text }}</td>
                                    <td>
                                        {{ question.surveyQuestionType.description }}
                                        <ng-container *ngIf="question.surveyQuestionType.id === viewmodel.questionTypes.DropDown">
                                            <span appThrottleClick (throttledClick)="viewmodel.onViewDropDownOptions(question)">
                                                <a class="single-icon-on-white btn-sm">
                                                    <fa-icon [icon]="viewmodel.faEye"></fa-icon>
                                                </a>
                                            </span>
                                        </ng-container>
                                    </td>
                                    <td>{{ question.isRequired ? 'Yes' : 'No'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>
        </div>
    </div>
</ng-container>
