import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { NgModule } from "@angular/core";
import { AccountsComponent } from "./accounts/accounts.component";
import { CallHistoryComponent } from "./details/call-history/call-history.component";
import { UserProfileComponent } from "./user/user-profile/user-profile.component";
import { CanDeactivateGuard } from "./guards/can-deactivate.guard";
import {SplashComponent} from "./splash/splash.component";
import { CanActivateProjectCreationGuard } from "./guards/can-activate-project-creation.guard";
import { AccountsListComponent } from "./accounts/accounts-list/accounts-list.component";

export const routes: Routes = [
    {
        path: "",
        children: [
            {
                path: "details/call-history",
                component: CallHistoryComponent,
                canActivate: [MsalGuard],
            },
            {
                path: "",
                redirectTo: "/accounts/customers",
                pathMatch: "full",
            },
            {
                path: "accounts/customers",
                component: AccountsListComponent,
                canActivate: [MsalGuard],
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: "accounts",
                component: AccountsComponent,
                canActivate: [MsalGuard],
            },
            {
                path: "user/user-profile",
                component: UserProfileComponent,
                canActivate: [MsalGuard],
            },
            {
                path: "splash",
                component: SplashComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [CanDeactivateGuard, CanActivateProjectCreationGuard]
})
export class AppRoutingModule {}
