import { GenericLookup } from "../generic";
import { ContractPaymentMethods, ContractPaymentStatuses, ContractPaymentTypes } from "../lookup";

export class CustomerContractPaymentDto{
    id: string;
    customerContractId: string;
    customerId: string;
    customerContactId: string;
    customerContactName: string | null;
    contractPaymentStatus: GenericLookup<ContractPaymentStatuses>;
    contractPaymentType: GenericLookup<ContractPaymentTypes>;
    contractPaymentMethod: GenericLookup<ContractPaymentMethods>;
    paymentAmount: number | null;
    paymentDate: string;
    reason: string | null;
    gratisId: string | null;
    gratisNumber: string | null;
    createdUtcDateTime: string;
    createdUserId: string;
    createdUserZrt: string | null;
    createdUserFullName: string;
    modifiedUtcDateTime: string;
    modifiedUserId: string;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string;
    signatureBlob: string;
}
