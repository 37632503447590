import { GenericLookup } from "../generic";
import { CustomerTypeEnum } from "../lookup";

export class ProfileProjectParamsDto {
    projectStatusIds: number[];
    customerType: GenericLookup<CustomerTypeEnum>;
    employeeId: string;
    customerId: string;
    customerOwnerCode: string;
    chainIds: string[];
}
