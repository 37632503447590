import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
    apiVersion, ContractTemplateDto, ContractTemplateSyncCommand,
    GenericVersionResponseDto, SharedHelper, SyncCommandTypes,
    SystemInformationKeys, VersionResponseDto
} from "shield.shared";
import { DatabaseService } from "../../services/database.service";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { ContractTemplateConverterService } from "src/app/services/converter-services/contract-template-converter.service";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { SyncLevel } from "../sync-enums/sync-level.enum";
@Injectable()
export class ContractTemplateDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    apiVersion = apiVersion;
    onlineRepSyncType = SyncLevel.Initial;
    offlineRepSyncType = SyncLevel.Initial;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.contractTemplate;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing contract templates...");

        await this.pullData();

        this.log("Done syncing contract templates...");
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/contract-templates/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("contract template data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Contract template data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.contractPullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;

        try {
            let thisBatchSize: number;
            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;

                this.log(`Get contract templates [` + query + `]`);
                const response = await this.http
                    .get<GenericVersionResponseDto<ContractTemplateDto[]>>(`/api/contract-templates${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                this.log(`  Downloaded ${thisBatchSize}, saving to IndexedDB...`);

                const responseTemplates = response.values.map((template) =>
                    ContractTemplateConverterService.contractTemplateDtoToContractTemplate(template)
                );

                const toDelete: string[] = [];

                for (const template of responseTemplates) {
                    const existingTemplate = await this.dbService.contractTemplates
                        .where("[zone+region+territory]")
                        .equals([template.zone, template.region, template.territory])
                        .and((localTemplate) => 
                            localTemplate.contractTemplateType.id === 
                                template.contractTemplateType.id
                            && localTemplate.id != template.id)
                        .first();
                    if (existingTemplate) {
                        toDelete.push(existingTemplate.id);
                    }
                }

                await this.dbService.contractTemplates.bulkDelete(toDelete);
                this.log(`  Deleted ${toDelete.length} contract-templates.`);

                await this.dbService.contractTemplates.bulkPut(responseTemplates);
                this.log(`  Saved ${responseTemplates.length} contract-templates.`);

                this.lastSyncVersion = response.maxVersion;
            } while (thisBatchSize > 0);

            this.log("Done saving customer contract data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing customer contracts", e);
        }
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<ContractTemplateSyncCommand>(
            SyncCommandTypes.contractTemplate,
            async (message) => {
                let ct = await this.dbService.contractTemplates.get(
                    message.payload.id
                );

                let dto = ContractTemplateConverterService.contractTemplateToContractTemplateDto(ct);

                if (dto) {
                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }
}
