<ng-container *ngIf="projectStateService.project" class="container">
    <form [formGroup]="viewmodel.projectDefinitionFormGroup">
        <div class="row">
            <div class="col heading">
                <h1 class="m-teal">
                    Definition
                </h1>
            </div>
        </div>
        <div *ngIf="projectStateService?.project?.projectStatusId !== viewmodel.projectStatuses.InActive
            && projectStateService?.project?.projectStatusId !== viewmodel.projectStatuses.Editable"
            class="row justify-content-center">
            <div class="col-3 align-right">
                <h2>Project Type:</h2>
            </div>
            <div class="col-3">
                <h2>{{viewmodel.projectType}}</h2>
            </div>
        </div>
        <div *ngIf="projectStateService?.project?.projectStatusId === viewmodel.projectStatuses.InActive
            || projectStateService?.project?.projectStatusId === viewmodel.projectStatuses.Editable"
            class="row justify-content-center">
            <div class="col-3 align-right">
                <h2 class=" m-revert">Project Type:</h2>
            </div>
            <div class="col-3">
                <mat-form-field class="project-type-dropdown" appearance="outline" color="accent">
                    <mat-select [value]="viewmodel.selectedProjectType" formControlName="projectTypeControl" required>
                        <mat-option *ngFor="let option of viewmodel.projectTypes" [value]="option.id">
                            {{ option.description }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-3 align-right">
                <h2 class=" m-revert">Project Name:</h2>
            </div>
            <div class="col-3">
                <mat-form-field appearance="outline" color="accent" class="w-100">
                    <mat-label>Project Name</mat-label>
                    <input matInput placeholder="Project Name" maxlength="50" formControlName="projectNameControl"
                        required>
                    <mat-hint align="end">{{
                        viewmodel.projectName
                        ?.length || 0
                        }}/50</mat-hint>
                </mat-form-field>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-3 align-right">
                <h2 class=" m-revert">Mission:</h2>
            </div>
            <div class="col-3">
                <mat-form-field appearance="outline" color="accent" class="w-100">
                    <mat-label>Mission</mat-label>
                    <textarea matInput type="text" formControlName="missionControl" placeholder="Mission."
                        maxlength="500" required></textarea>
                    <mat-hint align="end">{{
                        viewmodel.mission
                        ?.length || 0
                        }}/500</mat-hint>
                </mat-form-field>
            </div>
        </div>
        <div class="row justify-content-center m-top">
            <div class="col-3 align-right">
                <h2 class=" m-revert">Additional Resources:</h2>
            </div>
            <div class="col-3">
                <mat-form-field appearance="outline" color="accent" class="w-100">
                    <mat-label>Additional Resources</mat-label>
                    <input matInput placeholder="Additional Resources" maxlength="1000"
                        formControlName="additionalResourcesControl">
                    <mat-hint align="end">{{
                        viewmodel.additionalResources
                        ?.length || 0
                        }}/1000</mat-hint>
                </mat-form-field>
            </div>
        </div>
    </form>
</ng-container>
