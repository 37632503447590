import { GenericLookup, ProjectTypeLookup, ProjectTypes } from "shield.shared";
import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";
import { Project } from "src/app/entity-models/project.entity";

export class CopyProjectViewmodel extends BasicDialogViewModel {
    project: Project;
    projectName: string;
    projectMission: string;

    constructor(project: Project) {
        super();
        this.project = project;
        this.projectName = "";
        this.projectMission = project.mission ?? "";

        this.buttonRightText = "Create Project";
        this.buttonRightDisabledFunction = () => false;

        this.buttonLeftText = "Cancel";
        this.buttonLeftDisabledFunction = () => false;

        this.headerLeftText = `Copy Project: ${project.name}`;
        this.isConfirmed = false;
        this.showFooter = true;


    }
}
