import { MsaBrandDto } from "./msa-brand.dto";
import { MsaDistributorVolumeDto } from "./msa-distributor-volume.dto";

export class MsaDistributorVolumeResponseDto {
    volumes: MsaDistributorVolumeDto[];
    brands: MsaBrandDto[];
    storeCount?: number;

    constructor() {
        this.volumes = [];
        this.brands = [];
        this.storeCount = 0;
    }

}
