import { Component, ElementRef, Input, OnInit, Type, ViewChild } from "@angular/core";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterService } from "src/app/services/filter.service";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { RefinerLocation } from "shield.shared";
import { DateInformationViewmodel } from "./date-information.viewmodel";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";

@Component({
    selector: "app-date-information-filter",
    templateUrl: "./date-information-filter.component.html",
    styleUrls: ["./date-information-filter.component.css"]
})
export class DateInformationFilterComponent extends FilterBaseComponent implements OnInit {
    //view childs
    @ViewChild("startDateInput") startDateInput: ElementRef;
    @ViewChild("endDateInput") endDateInput: ElementRef;

    //public vars
    contentComponent: Type<DateInformationFilterComponent>;
    icon = "perm_phone_msg";
    name = "Date Information";
    viewmodel = new DateInformationViewmodel(this.filterService, this.formBuilder);

    constructor(
        private filterService: FilterService,
        private formBuilder: FormBuilder
    ) {
        super();
    }

    ngOnInit(): void {
        this.setInitialized();
    }

    //events
    onAddEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        if (this.viewmodel.dateForm.controls.endDate.errors) return;

        const value = this.endDateInput.nativeElement.value;
        const existing = this._refinerService.refiners.find(v => v.location === RefinerLocation.endOnOrBeforeDate);

        if (value || existing) {
            this._refinerService.onInputChange(
                RefinerLocation.endOnOrBeforeDate,
                this.endDateInput.nativeElement.value
            );

            if (!event || (event as KeyboardEvent).key === "Enter") {
                setTimeout(() => {
                    const refiner = new Refiner();
                    refiner.location = RefinerLocation.endOnOrBeforeDate;
                    refiner.value = this.endDateInput.nativeElement.value;
                    refiner.dataPropertyName = "stopTime";

                    this._refinerService.checkAndUpdateRefiner(refiner);
                }, 0)
            }
        }
    }

    onAddStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        if (this.viewmodel.dateForm.controls.startDate.errors) return;

        const value = this.startDateInput.nativeElement.value;
        const existing = this._refinerService.refiners.find(v => v.location === RefinerLocation.startDate);

        if (value || existing) {
            this._refinerService.onInputChange(
                RefinerLocation.startDate,
                this.startDateInput.nativeElement.value
            );
            if (!event || (event as KeyboardEvent).key === "Enter") {
                setTimeout(() => {
                    const refiner = new Refiner();
                    refiner.location = RefinerLocation.startDate;
                    refiner.value = this.startDateInput.nativeElement.value;
                    refiner.dataPropertyName = "stopTime";

                    this._refinerService.checkAndUpdateRefiner(refiner);
                }, 0)
            }
        }
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.endOnOrBeforeDate:
                    if (refiner.value !== this.endDateInput?.nativeElement.value) {
                        if(refiner.value) {
                            this.viewmodel.dateForm.controls.endDate.setValue(moment(new Date(refiner.value)));
                        }
                        else {
                            this.viewmodel.dateForm.controls.endDate.setValue("");
                        }
                    }
                    break;
                case RefinerLocation.startDate:
                    if (refiner.value !== this.startDateInput?.nativeElement.value) {
                        if(refiner.value) {
                            this.viewmodel.dateForm.controls.startDate.setValue(moment(new Date(refiner.value)));
                        }
                        else {
                            this.viewmodel.dateForm.controls.startDate.setValue("");
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.endOnOrBeforeDate;
        const endDateRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.endOnOrBeforeDate
        );
        if (endDateRefiner) {
            this.onInputChange(endDateRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.startDate;
        const startDateRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.startDate
        );
        if (startDateRefiner) {
            this.onInputChange(startDateRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }
}
