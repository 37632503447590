import { AvailabilityViewModel } from "../account.viewmodels/availability.viewmodel";
import { DayAvailabilityViewModel } from "../account.viewmodels/day-availability.viewmodel";

export class ContactViewModel {
    headerLeftText: string;
    headerRightText: string;
    buttonLeftText: string;
    buttonLeftFunction: () => void;
    buttonLeftDisabledFunction: () => boolean;
    buttonRightText: string;
    buttonRightFunction: () => void;
    buttonRightDisabledFunction: () => boolean;
    width: string;
    height: string;
    needsMappingsRefreshed: boolean;

    id: string; //uuid
    customerId: string; // customer UUID
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    alternatePhoneNumber: string;
    note: string;
    availability: AvailabilityViewModel;
    availableContactDays: string;
    dailyAvailability: DayAvailabilityViewModel[];
    isDeleted: boolean;
    createdUtcDateTime: Date;
    createdUserId: string;
    createdUserZrt: string;
    createdUserFullName: string;
    modifiedUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    rowversion: string;
}
