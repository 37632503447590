<ng-container *ngIf="inStepper; else summaryView">
    <div *ngIf="projectApplicationService.selectedIndex == currentStep" class="project-summary-grid">
        <app-project-summary-release-dates
            class="project-summary-grid-item col-1--row-1 d-flex flex-column flex-grow-1"></app-project-summary-release-dates>
        <app-project-summary-order-dates #summaryOrderDates
            class="project-summary-grid-item col-1--row-2 d-flex flex-column flex-grow-1"></app-project-summary-order-dates>
        <app-project-summary-stores #summaryStores
            class="project-summary-grid-item col-2-3--row-1 d-flex flex-column flex-grow-1"></app-project-summary-stores>
        <app-project-summary-assignments #summaryAssignments
            class="project-summary-grid-item col-2-3--row-2 d-flex flex-column flex-grow-1"></app-project-summary-assignments>
        <app-project-summary-products #summaryProducts
            class="project-summary-grid-item col-4--row-1 d-flex flex-column flex-grow-1"></app-project-summary-products>
        <app-project-summary-activities-surveys #summaryActivitySurveys
            class="project-summary-grid-item col-4--row-2 d-flex flex-column flex-grow-1"></app-project-summary-activities-surveys>
    </div>
</ng-container>
<ng-template #summaryView>
    <div class="wrapper d-flex flex-grow-1">
        <mat-card class="w-100 d-flex flex-grow-1">
            <div class="row d-flex flex-grow-1">
                <div class="col-lg-3 col-sm-12 d-flex flex-column flex-grow-1">
                    <app-project-summary-release-dates class="project-summary-grid-item d-flex flex-column flex-grow-1"
                        [project]="project"></app-project-summary-release-dates>
                    <app-project-summary-order-dates class="project-summary-grid-item d-flex flex-column flex-grow-1"
                        [project]="project"></app-project-summary-order-dates>
                </div>
                <div class="col-lg-5 col-sm-12 d-flex flex-column flex-grow-1">
                    <app-project-summary-stores class="project-summary-grid-item d-flex flex-column flex-grow-1"
                        [project]="project"></app-project-summary-stores>
                    <app-project-summary-assignments class="project-summary-grid-item d-flex flex-column flex-grow-1"
                        [project]="project"></app-project-summary-assignments>
                </div>
                <div class="col-lg-4 col-sm-12 d-flex flex-column flex-grow-1">
                    <app-project-summary-products
                        class="project-summary-grid-item d-flex flex-column flex-grow-1"
                        [buildProducts]="!inStepper"
                        [project]="project"></app-project-summary-products>
                    <app-project-summary-activities-surveys
                        class="project-summary-grid-item d-flex flex-column flex-grow-1"
                        [project]="project"></app-project-summary-activities-surveys>
                </div>
            </div>
        </mat-card>
    </div>
</ng-template>
