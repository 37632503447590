import { CustomerTypeLookup, ProductDistributionFilterDto, RefinerLocation, valueSeparator } from "shield.shared";
import { Refiner } from "../../entity-models/refiner.entity";
import { BaseFilterMapService } from "./base-filter-map.service";
import { ZrtFilterMapService } from "./zrt-filter-map.service";

export class ProductDistributionFilterMapService extends BaseFilterMapService {

    static mapFilterData(refiners: Refiner[]): ProductDistributionFilterDto {
        if (!refiners) return;
        const filterDto = new ProductDistributionFilterDto();
        const zrtFilterDto = ZrtFilterMapService.mapFilterData(refiners);

        if (Object.keys(zrtFilterDto).length) {
            filterDto.zrtFilterDto = zrtFilterDto;
        }

        for (const refiner of refiners) {
            if (!refiner.dataValue) {
                refiner.dataValue = refiner.value;
            }
            switch (refiner.location) {
                case RefinerLocation.myList:
                    filterDto.ids = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.callOnOrAfterDate:
                    const callOnOrAfterDate = new Date(refiner.dataValue)
                    callOnOrAfterDate.setHours(0,0,0,0);
                    filterDto.callOnOrAfterDate = callOnOrAfterDate.toISOString();
                    break;
                case RefinerLocation.callOnOrBeforeDate:
                    const callOnOrBeforeDate = new Date(refiner.dataValue)
                    callOnOrBeforeDate.setHours(23,59,59,0);
                    filterDto.callOnOrBeforeDate = callOnOrBeforeDate.toISOString();
                    break;
                case RefinerLocation.projects:
                    filterDto.projectIds = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.wholesalers:
                    filterDto.wholesalerIds = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.projectWholesalers:
                    filterDto.projectWholesalers = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.chains:
                    filterDto.accountOwnershipIds = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.products:
                    filterDto.productIds = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.productGroups:
                    filterDto.productGroups = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.storeTypes:
                    filterDto.customerTypeIds = CustomerTypeLookup
                        .filter((ct) => refiner.dataValue.split(valueSeparator).includes(ct.name))
                        .map((v) => v.id);
                    break;
                case RefinerLocation.msa:
                    filterDto.isMsa = refiner.dataValue === BaseFilterMapService.yes;
                    break;
                case RefinerLocation.px3Rank:
                    filterDto.px3Rank = refiner.dataValue.split(valueSeparator);
                    break;
                default:
                    break;
            }
        }

        return filterDto;
    }
}
