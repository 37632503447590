import { EmailReceiptLocationOrigins } from "../enum";

export class EmailReceiptParams {
    employeeId: string;
    callId: string;
    receiptId: string;
    receiptNumber: string;
    origin: EmailReceiptLocationOrigins;
    comment?: string;
}
