import { GenericLookup } from "../generic";

export enum GratisApprovalStatuses {
    NotSubmitted = 0,
    Submitted = 1,
    Approved = 2,
    Rejected = 3,
    Ordered = 4,
    Deleted = 5,
}

export const GratisApprovalStatusLookup: GenericLookup<GratisApprovalStatuses>[] = [
    { id: GratisApprovalStatuses.NotSubmitted, name: "NotSubmitted", description: "Not Submitted" },
    { id: GratisApprovalStatuses.Submitted, name: "Submitted", description: "Submitted" },
    { id: GratisApprovalStatuses.Approved, name: "Approved", description: "Approved" },
    { id: GratisApprovalStatuses.Rejected, name: "Rejected", description: "Rejected" },
    { id: GratisApprovalStatuses.Ordered, name: "Ordered", description: "Ordered" },
    { id: GratisApprovalStatuses.Deleted, name: "Deleted", description: "Deleted" }
];
