import { Subscription } from "rxjs";
import { GenericDropDownDto } from "shield.shared";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { ActivitiesFilterService } from "../../../services/activities-filter.service";

export class ProductActivitiesViewmodel {
    activitiesFilterService: ActivitiesFilterService;

    projectsSubscription: Subscription;
    projects = new Array<GenericDropDownDto>();
    selectedProjects = new Array<GenericDropDownDto>();
    selectedProjectsHold = new Array<GenericDropDownDto>();
    isProjectsStale: boolean;
    projectsDropdownSettings = {
        text: "Projects",
        singleSelection: false,
        unSelectAllText: "Deselect All",
        filterUnSelectAllText: "Deselect all filtered results",
        enableCheckAll: false,
        enableFilterSelectAll: true,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "displayValue",
        searchBy: ["displayValue"],
        noDataLabel: "Loading projects...",
        classes: "multi-select-container c-btn"
    }

    wholesalersSubscription: Subscription;
    wholesalers = new Array<Customer>();
    selectedWholesalers = new Array<Customer>();
    selectedWholesalersHold = new Array<Customer>();
    isWholesalersStale: boolean;
    wholesalersDropdownSettings = {
        text: "Wholesalers",
        singleSelection: false,
        unSelectAllText: "Deselect All",
        filterUnSelectAllText: "Deselect all filtered results",
        enableCheckAll: false,
        enableFilterSelectAll: true,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "displayName",
        searchBy: ["displayName"],
        noDataLabel: "Loading wholesalers...",
        classes: "multi-select-container c-btn"
    }

    chainsSubscription: Subscription;
    chains = new Array<AccountOwnership>();
    selectedChains = new Array<AccountOwnership>();
    selectedChainsHold = new Array<AccountOwnership>();
    isChainsStale: boolean;
    chainsDropdownSettings = {
        text: "Chains",
        singleSelection: false,
        unSelectAllText: "Deselect All",
        filterUnSelectAllText: "Deselect all filtered results",
        enableCheckAll: false,
        enableFilterSelectAll: true,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "name",
        searchBy: ["name"],
        noDataLabel: "Loading chains...",
        classes: "multi-select-container c-btn"
    }

    productsSubscription: Subscription;
    products = new Array<GenericDropDownDto>();
    selectedProducts = new Array<GenericDropDownDto>();
    selectedProductsHold = new Array<GenericDropDownDto>();
    isProductsStale: boolean;
    productsDropdownSettings = {
        text: "Products",
        singleSelection: false,
        unSelectAllText: "Deselect All",
        filterUnSelectAllText: "Deselect all filtered results",
        enableCheckAll: false,
        enableFilterSelectAll: true,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "displayValue",
        searchBy: ["displayValue"],
        noDataLabel: "Loading products...",
        classes: "multi-select-container c-btn"
    }

    productGroupsSubscription: Subscription;
    productGroups = new Array<GenericDropDownDto>();
    selectedProductGroups = new Array<GenericDropDownDto>();
    selectedProductGroupsHold = new Array<GenericDropDownDto>();
    isProductGroupsStale: boolean;
    productGroupsDropdownSettings = {
        text: "Product Groups",
        singleSelection: false,
        unSelectAllText: "Deselect All",
        filterUnSelectAllText: "Deselect all filtered results",
        enableCheckAll: false,
        enableFilterSelectAll: true,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "displayValue",
        searchBy: ["displayValue"],
        noDataLabel: "Loading product groups...",
        classes: "multi-select-container c-btn"
    }

    constructor(activitiesFilterService: ActivitiesFilterService) {
        this.activitiesFilterService = activitiesFilterService;
    }
}
