import * as moment from "moment";
import { AddressDto, CustomerDto, CustomerLicenseDto,
        CustomerMarkerDto,
        CustomerTypeLookup,
        CustomerWholesalerDto, CustomerZrtDto, GenericLookup, RefinerDto,
        RefinerGroupDto, RefinerLocation, WholesalerCustomerDto } from "shield.shared";
import { CustomerMapService } from "src/app/accounts/account-services/customer-map.service";
import { Address } from "src/app/entity-models/address.entity";
import { Availability } from "src/app/entity-models/availability.entity";
import { Contact } from "src/app/entity-models/contact.entity";
import { CustomerLicense } from "src/app/entity-models/customer-license.entity";
import { CustomerMarker } from "src/app/entity-models/customer-marker.entity";
import { CustomerWholesaler } from "src/app/entity-models/customer-wholesaler.entity";
import { CustomerZrt } from "src/app/entity-models/customer-zrt.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { WholesalerCustomer } from "src/app/entity-models/wholesaler-customer.entity";
import { Helper } from "src/app/helpers/helper";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { CustomerAssignmentType } from "src/app/sync/sync-enums/customer-assignment-type.enum";
import { ContactConverterService } from "./contact-converter.service";
import { RefinerConverterService } from "./refiner-converter.service";
import { VolumeConverterService } from "./volume-converter-service";

export class CustomerConverterService {

    static addressToAddressDto(entity: Address | null): AddressDto | null{

        if (entity == null) {
            return null;
        }

        const dto = new AddressDto();

        dto.address1 = entity.address1;
        dto.address2 = entity.address2;
        dto.city = entity.city;
        dto.country = entity.country;
        dto.county = entity.county;
        dto.countyFipsCode = entity.countyFipsCode;
        dto.dateCreated = entity.dateCreated
            ? entity.dateCreated.toISOString()
            : new Date().toISOString();
        dto.id = entity.id;
        dto.lastUpdated = entity.lastUpdated ? entity.lastUpdated.toISOString() : null;
        dto.latitude = entity.latitude;
        dto.longitude = entity.longitude;
        dto.name = entity.name;
        dto.state = entity.state;
        dto.zip = entity.zip;

        return dto;
    }

    static addressDtoToAddress(dto: AddressDto | null): Address | null {

        if (dto == null) {
            return null;
        }

        const entity = new Address();

        entity.address1 = dto.address1;
        entity.address2 = dto.address2;
        entity.city = dto.city;
        entity.country = dto.country;
        entity.county = dto.county;
        entity.countyFipsCode = dto.countyFipsCode;
        entity.dateCreated = dto.dateCreated ? new Date(dto.dateCreated) : null;
        entity.id = dto.id;
        entity.lastUpdated = dto.lastUpdated ? new Date(dto.lastUpdated) : null;
        entity.latitude = dto.latitude;
        entity.longitude = dto.longitude;
        entity.name = dto.name;
        entity.state = dto.state;
        entity.zip = dto.zip;

        return entity;
    }

    static compareAddresses(a: Address, b: Address): boolean {
        return (a.name === b.name &&
            a.address1 === b.address1 &&
            a.address2 === b.address2 &&
            a.city === b.city &&
            a.state === b.state &&
            a.zip === b.zip);
    }

    static customerWholesalerToCustomerWholesalerDto(entity: CustomerWholesaler | null): CustomerWholesalerDto | null{
        if (entity == null) {
            return null;
        }

       const dto = new CustomerWholesalerDto();

       dto.createdUserId = entity.createdUserId;
       dto.createdUserZrt = entity.createdUserZrt;
       dto.createdUserFullName = entity.createdUserFullName;
       dto.createdUtcDateTime = entity.createdUtcDateTime
        ? entity.createdUtcDateTime.toISOString()
        : new Date().toISOString();
        dto.customerCode = entity.customerCode;
        dto.modifiedUserId = dto.modifiedUserId;
        dto.modifiedUserZrt = dto.modifiedUserZrt;
        dto.modifiedUserFullName = dto.modifiedUserFullName;
        dto.modifiedUtcDateTime = dto.modifiedUtcDateTime
            ? new Date(dto.modifiedUtcDateTime).toISOString()
            : new Date().toISOString();
        dto.wholesalerId = entity.wholesalerId;

        return dto;
    }

    static customerWholesalerDtoToCustomerWholesaler(dto: CustomerWholesalerDto | null): CustomerWholesaler | null {
        if (dto == null) {
            return null;
        }

        const entity = new CustomerWholesaler();

        entity.createdUserId = dto.createdUserId;
        entity.createdUserZrt = dto.createdUserZrt;
        entity.createdUserFullName = dto.createdUserFullName;
        entity.createdUtcDateTime = dto.createdUtcDateTime
            ? new Date(dto.createdUtcDateTime)
            : new Date();
        entity.customerCode = dto.customerCode;
        entity.modifiedUserId = dto.modifiedUserId;
        entity.modifiedUserZrt = dto.modifiedUserZrt;
        entity.modifiedUserFullName = dto.modifiedUserFullName;
        entity.modifiedUtcDateTime = dto.modifiedUtcDateTime
            ? new Date(dto.modifiedUtcDateTime)
            : new Date();
        entity.wholesalerId = dto.wholesalerId;

        return entity;
    }

    static wholesalerCustomerToWholesalerCustomerDto(entity: WholesalerCustomer | null): WholesalerCustomerDto | null{

        if (entity == null) {
            return null;
        }

        const dto = new WholesalerCustomerDto();

        dto.createdUserFullName = entity.createdUserFullName;
        dto.createdUserId = entity.createdUserId;
        dto.createdUserZrt = entity.createdUserZrt;
        dto.createdUtcDateTime = entity.createdUtcDateTime
            ? entity.createdUtcDateTime.toISOString()
            : new Date().toISOString();
        dto.customerCode = entity.customerCode;
        dto.customerId = entity.customerId;
        dto.modifiedUserFullName = entity.modifiedUserFullName;
        dto.modifiedUserId = entity.modifiedUserId;
        dto.modifiedUserZrt = entity.modifiedUserZrt;
        dto.modifiedUtcDateTime = entity.modifiedUtcDateTime
            ? entity.modifiedUtcDateTime.toISOString()
            : null;

        return dto;
    }

    static wholesalerCustomerDtoToWholesalerCustomer(dto: WholesalerCustomerDto | null): WholesalerCustomer | null {

        if (dto == null) {
            return null;
        }

        const entity = new WholesalerCustomer();

        entity.createdUserFullName = dto.createdUserFullName;
        entity.createdUserId = dto.createdUserId;
        entity.createdUserZrt = dto.createdUserZrt;
        entity.createdUtcDateTime = dto.createdUtcDateTime
            ? new Date(dto.createdUtcDateTime)
            : new Date();
        entity.customerCode = dto.customerCode;
        entity.customerId = dto.customerId;
        entity.modifiedUserFullName = dto.modifiedUserFullName;
        entity.modifiedUserId = dto.modifiedUserId;
        entity.modifiedUserZrt = dto.modifiedUserZrt;
        entity.modifiedUtcDateTime = dto.modifiedUtcDateTime
            ? new Date(dto.modifiedUtcDateTime)
            : null;

        return entity;
    }

    static customerLicenseToCustomerLicenseDto(entity: CustomerLicense): CustomerLicenseDto {

        if (!entity) { return; }

        const dto = new CustomerLicenseDto();

        dto.createdUserFullName = entity.createdUserFullName;
        dto.createdUserId = entity.createdUserId;
        dto.createdUserZrt = entity.createdUserZrt;
        dto.createdUtcDateTime = entity.createdUtcDateTime
            ? entity.createdUtcDateTime.toISOString()
            : new Date().toISOString();
        dto.customerId = entity.customerId;
        dto.id = entity.id;
        dto.isActive = entity.isActive;
        dto.licenseNumber = entity.licenseNumber;
        dto.licenseTypeId = entity.licenseTypeId;
        dto.modifiedUserFullName = entity.modifiedUserFullName;
        dto.modifiedUserId = entity.modifiedUserId;
        dto.modifiedUserZrt = entity.modifiedUserZrt;
        dto.modifiedUtcDateTime = entity.modifiedUtcDateTime
            ? entity.modifiedUtcDateTime.toISOString()
            : null;

        return dto;
    }

    static customerLicenseDtoToCustomerLicense(dto: CustomerLicenseDto): CustomerLicense {

        const entity = new CustomerLicense();

        entity.createdUserFullName = dto.createdUserFullName;
        entity.createdUserId = dto.createdUserId;
        entity.createdUserZrt = dto.createdUserZrt;
        entity.createdUtcDateTime = dto.createdUtcDateTime
            ? new Date(dto.createdUtcDateTime)
            : new Date();
        entity.customerId = dto.customerId;
        entity.id = dto.id;
        entity.isActive = dto.isActive;
        entity.licenseNumber = dto.licenseNumber;
        entity.licenseTypeId = dto.licenseTypeId;
        entity.modifiedUserFullName = dto.modifiedUserFullName;
        entity.modifiedUserId = dto.modifiedUserId;
        entity.modifiedUserZrt = dto.modifiedUserZrt;
        entity.modifiedUtcDateTime = dto.modifiedUtcDateTime
            ? new Date(dto.modifiedUtcDateTime)
            : null;

        return entity;
    }

    static customerToCustomerDto(entity: Customer): CustomerDto {

        const dto = new CustomerDto();

        dto.availability = entity.availability;
        dto.businessAddress = CustomerConverterService.addressToAddressDto(entity.businessAddress);
        dto.callFileIndicator = entity.callFileIndicator;
        dto.chainIndicator = entity.chainIndicator;
        dto.chainRep = entity.chainRep;
        dto.comments = entity.comments;
        dto.commentsLastUpdated = entity.commentsLastUpdated
            ? entity.commentsLastUpdated.toISOString()
            : null;
        dto.commentsLastUpdatedBy = entity.commentsLastUpdatedBy;
        dto.customerLicenses = (entity.customerLicenses ?? [])
            .map((cl) =>CustomerConverterService.customerLicenseToCustomerLicenseDto(cl));
        dto.customerNumber = entity.customerNumber;
        dto.customerWholesalers = (entity.customerWholesalers ?? [])
            .map((cw) => CustomerConverterService.customerWholesalerToCustomerWholesalerDto(cw));
        dto.customerZrts = (entity.customerZrts ?? [])
            .map((cz) => CustomerConverterService.customerZrtToCustomerZrtDto(cz));
        dto.dateCreated = entity.dateCreated
            ? entity.dateCreated.toISOString()
            : new Date().toISOString();
        dto.dbaAddress = CustomerConverterService.addressToAddressDto(entity.dbaAddress);
        dto.deactivatedBy = entity.deactivatedBy;
        dto.deactivatedDate = entity.deactivatedDate
            ? entity.deactivatedDate.toISOString()
            : null;
        dto.deactivatedEmployeeId = entity.deactivatedEmployeeId;
        dto.distributorAccountNumber = entity.distributorAccountNumber;
        dto.email = entity.email;
        dto.externalTdLinx = entity.externalTdLinx;
        dto.fax = entity.fax;
        dto.federalTaxId = entity.federalTaxId;
        dto.federalTaxIdLastUpdated = entity.federalTaxIdLastUpdated
            ? entity.federalTaxIdLastUpdated.toISOString()
            : null;
        dto.geoArea = entity.geoArea;
        dto.groupId = entity.groupId;
        dto.hasPendingOrders = entity.hasPendingOrders;
        dto.hasPhone = entity.hasPhone;
        dto.id = entity.id;
        dto.inDistributionVolume = entity.inDistributionVolume;
        dto.industryVolume = entity.industryVolume;
        dto.isActive = entity.isActive;
        dto.isAddNewBusiness = entity.isAddNewBusiness;
        dto.isCallable = entity.isCallable;
        dto.isCallableOverridden = entity.isCallableOverridden;
        dto.isCashAndCarry = entity.isCashAndCarry;
        dto.isDeleted = entity.isDeleted;
        dto.isMsa = entity.isMsa;
        dto.isRouted = entity.isRouted;
        dto.lastCall = entity.lastCall ? entity.lastCall.toISOString() : null;
        dto.lastDeletionDate = entity.lastDeletionDate
            ? entity.lastDeletionDate.toISOString()
            : null;
        dto.lastEdited = entity.lastEdited ? entity.lastEdited.toISOString() : null;
        dto.lastEditedBy = entity.lastEditedBy;
        dto.lastEtlUpdated = entity.lastEtlUpdated
            ? entity.lastEtlUpdated.toISOString()
            : null;
        dto.license = entity.license;
        dto.licenseLastUpdated = entity.licenseLastUpdated
            ? entity.licenseLastUpdated.toISOString()
            : null;
        dto.manager = entity.manager;
        dto.msaRetailId = entity.msaRetailId;
        dto.nam = entity.nam;
        dto.name = entity.name;
        dto.orderType = entity.orderType;
        dto.ownerCode = entity.ownerCode;
        dto.phone = entity.phone;
        dto.program = entity.program;
        dto.reactivatedBy = entity.reactivatedBy;
        dto.reactivatedDate = entity.reactivatedDate
            ? entity.reactivatedDate.toISOString()
            : null;
        dto.reactivatedEmployeeId = entity.reactivatedEmployeeId;
        dto.retailIndicator = entity.retailIndicator;
        dto.retailNumber = entity.retailNumber;
        dto.rowversion = entity.rowversion;
        dto.shieldVolume = entity.shieldVolume;
        dto.stateTaxId = entity.stateTaxId;
        dto.stateTaxIdLastUpdated = entity.stateTaxIdLastUpdated
            ? entity.stateTaxIdLastUpdated.toISOString()
            : null;
        dto.status = entity.status;
        dto.storeCount = entity.storeCount;
        dto.territory = entity.territory;
        dto.customerType = GenericLookup.getFromLookup(CustomerTypeLookup, entity.customerType?.id);
        dto.zone = entity.zone;
        dto.zrt = entity.zrt;

        return dto
    }

    static customerZrtDtoToCustomerZrt(dto: CustomerZrtDto): CustomerZrt {

        const entity = new CustomerZrt();

        entity.id = dto.id;
        entity.subsidiaryId = dto.subsidiaryId;
        entity.zrt = dto.zrt;

        return entity;
    }

    static customerZrtToCustomerZrtDto(entity: CustomerZrt): CustomerZrtDto {

        const dto = new CustomerZrtDto();

        dto.id = entity.id;
        dto.subsidiaryId = entity.subsidiaryId;
        dto.zrt = entity.zrt;

        return entity;
    }

    static customerDtoToCustomer(dto: CustomerDto, assignmentType?: CustomerAssignmentType): Customer {

        const entity = new Customer();

        entity.accountOwnershipHierarchyLevelName = dto.accountOwnershipHierarchyLevelName;
        entity.assignedEmployeeFullName = dto.assignedEmployeeFullName;
        entity.availability = dto.availability;
        entity.businessAddress = CustomerConverterService.addressDtoToAddress(dto.businessAddress);
        entity.callFileIndicator = dto.callFileIndicator;
        entity.chain = dto.chain;
        entity.chainIndicator = dto.chainIndicator;
        entity.chainRep = dto.chainRep;
        entity.comments = dto.comments;
        entity.commentsLastUpdated = dto.commentsLastUpdated
            ? new Date(dto.commentsLastUpdated)
            : null;
        entity.commentsLastUpdatedBy = dto.commentsLastUpdatedBy;
        entity.customerLicenses = (dto.customerLicenses ?? [])
            .map((customerLicense) =>
                CustomerConverterService.customerLicenseDtoToCustomerLicense(customerLicense));
        entity.customerNumber = dto.customerNumber;
        entity.customerWholesalers = (dto.customerWholesalers ?? [])
            .map((cw) =>
            CustomerConverterService.customerWholesalerDtoToCustomerWholesaler(cw));
        entity.customerZrts = (dto.customerZrts ?? [])
            .map((cz) => CustomerConverterService.customerZrtDtoToCustomerZrt(cz));
        entity.dateCreated = dto.dateCreated ? new Date(dto.dateCreated) : null;
        entity.dbaAddress = CustomerConverterService.addressDtoToAddress(dto.dbaAddress);
        entity.deactivatedBy = dto.deactivatedBy;
        entity.deactivatedDate = dto.deactivatedDate ? new Date(dto.deactivatedDate) : null;
        entity.deactivatedEmployeeId = dto.deactivatedEmployeeId;
        entity.displayName = dto.displayName;
        entity.distributorAccountNumber = dto.distributorAccountNumber;
        entity.volumeResponse = dto.volumeResponse
            ? VolumeConverterService.msaDistributorVolumeResponseDtoToMsaDistributorVolumeResponse(dto.volumeResponse)
            : null;
        entity.email = dto.email;
        entity.externalTdLinx = dto.externalTdLinx;
        entity.fax = dto.fax;
        entity.federalTaxId = entity.federalTaxId;
        entity.federalTaxIdLastUpdated = entity.federalTaxIdLastUpdated
            ? new Date(entity.federalTaxIdLastUpdated)
            : null;
        entity.geoArea = dto.geoArea;
        entity.groupId = entity.groupId;
        entity.hasPendingOrders = dto.hasPendingOrders;
        entity.hasPhone = dto.hasPhone;
        entity.id = dto.id;
        entity.inDistributionVolume = dto.inDistributionVolume;
        entity.industryVolume = dto.industryVolume;
        entity.isActive = dto.isActive;
        entity.isAddNewBusiness = dto.isAddNewBusiness;
        entity.isCallable = dto.isCallable;
        entity.isCallableOverridden = dto.isCallableOverridden;
        entity.isCashAndCarry = dto.isCashAndCarry;
        entity.isDeleted = entity.isDeleted;
        entity.isMsa = dto.isMsa;
        entity.isRouted = dto.isRouted;
        entity.lastCall = dto.lastCall ? new Date(dto.lastCall) : null;
        entity.lastDeletionDate = dto.lastDeletionDate ? new Date(dto.lastDeletionDate) : null;
        entity.lastEdited = dto.lastEdited ? new Date(dto.lastEdited) : null;
        entity.lastEditedBy = dto.lastEditedBy;
        entity.lastEtlUpdated = dto.lastEtlUpdated ? new Date(dto.lastEtlUpdated) : null;
        entity.license = dto.license;
        entity.licenseLastUpdated = dto.licenseLastUpdated
            ? new Date(dto.licenseLastUpdated)
            : null;
        entity.manager = entity.manager;
        entity.msaRetailId = entity.msaRetailId;
        entity.nam = dto.nam;
        entity.name = dto.name;
        entity.orderType = dto.orderType;
        entity.ownerCode = dto.ownerCode;
        entity.phone = dto.phone;
        entity.program = dto.program;
        entity.reactivatedBy = entity.reactivatedBy;
        entity.reactivatedDate = entity.reactivatedDate
            ? new Date(entity.reactivatedDate)
            : null;
        entity.reactivatedEmployeeId = dto.reactivatedEmployeeId;
        entity.region = dto.region;
        entity.rep = dto.rep;
        entity.retailIndicator = dto.retailIndicator;
        entity.retailNumber = dto.retailNumber;
        entity.rowversion = dto.rowversion;
        entity.shieldVolume = dto.shieldVolume;
        entity.shieldVolumePercentage = dto.shieldVolumePercentage;
        entity.stateTaxId = dto.stateTaxId;
        entity.stateTaxIdLastUpdated = dto.stateTaxIdLastUpdated
            ? new Date(dto.stateTaxIdLastUpdated)
            : null;
        entity.status = dto.status;
        entity.storeCount = dto.storeCount;
        entity.territory = dto.territory;
        entity.customerType = GenericLookup.getFromLookup(CustomerTypeLookup, dto.customerType.id);
        entity.zone = dto.zone;
        entity.zrt = dto.zrt;
        entity.px3RankId = dto.px3RankId;
        entity.callsMade = dto.callsMade;


        switch (assignmentType) {
            case CustomerAssignmentType.Project:
                entity.isProjectAssignment = 1;
                break;
            case CustomerAssignmentType.Special:
                entity.isSpecialAssignment = 1;
                break;
            case CustomerAssignmentType.Zrt:
                entity.isZrtAssignment = 1;
                break;
            default:
                break;
        }

        return entity;
    }

    static customerMarkerDtoToCustomerMarker(dto: CustomerMarkerDto): CustomerMarker {
        const rtn = new CustomerMarker();
        const address = CustomerConverterService.addressDtoToAddress(dto.address);
        const addressParts = CustomerMapService.getMarkerAddressParts(address);

        rtn.customerId = dto.customerId;
        rtn.customerNumber = dto.customerNumber;
        rtn.title = dto.title;
        rtn.customerType = GenericLookup.getFromLookup(CustomerTypeLookup, dto.customerTypeId);
        rtn.address = address;
        rtn.addressText = addressParts.addressText;
        rtn.cityStateZip = addressParts.cityStateZip;
        rtn.availability = dto.availability;
        rtn.px3Rank = dto.px3Rank ?? undefined;
        rtn.active = dto.active;
        rtn.lastCall = dto.lastCall ? moment(dto.lastCall).format(MY_DATE_FORMATS.display.dateInput) : "None";
        rtn.icon = CustomerMapService.getIconFromCustomerMarker(rtn);

        return rtn;
    }

    static getCustomerAvailability(contacts: Contact[]): string {
        const customerAvailability = new Availability();
        for (const contact of contacts) {
            customerAvailability.sunday.am =
                customerAvailability.sunday.am ||
                contact.availability.sunday.am;

            customerAvailability.monday.am =
                customerAvailability.monday.am ||
                contact.availability.monday.am;

            customerAvailability.tuesday.am =
                customerAvailability.tuesday.am ||
                contact.availability.tuesday.am;

            customerAvailability.wednesday.am =
                customerAvailability.wednesday.am ||
                contact.availability.wednesday.am;

            customerAvailability.thursday.am =
                customerAvailability.thursday.am ||
                contact.availability.thursday.pm;

            customerAvailability.friday.am =
                customerAvailability.friday.am ||
                contact.availability.friday.am;

            customerAvailability.saturday.am =
                customerAvailability.saturday.am ||
                contact.availability.saturday.am;

            customerAvailability.sunday.pm =
                customerAvailability.sunday.pm ||
                contact.availability.sunday.pm;

            customerAvailability.monday.pm =
                customerAvailability.monday.pm ||
                contact.availability.monday.pm;

            customerAvailability.tuesday.pm =
                customerAvailability.tuesday.pm ||
                contact.availability.tuesday.pm;

            customerAvailability.wednesday.pm =
                customerAvailability.wednesday.pm ||
                contact.availability.wednesday.pm;

            customerAvailability.thursday.pm =
                customerAvailability.thursday.pm ||
                contact.availability.thursday.pm;

            customerAvailability.friday.pm =
                customerAvailability.friday.pm ||
                contact.availability.friday.pm;

            customerAvailability.saturday.pm =
                customerAvailability.saturday.pm ||
                contact.availability.saturday.pm;
        }

        return ContactConverterService.getAvaiability(customerAvailability);
    };

    static getCustomerFlatBusinessAddress(customer: Customer): string {
        let rtn = "";

        if (customer) {
            if (customer.businessAddress?.address1) {
                rtn = rtn.concat(customer.businessAddress?.address1, ", ");
            }

            if (customer.businessAddress?.address2) {
                rtn = rtn.concat(customer.businessAddress?.address2, ", ");
            }

            if (customer.businessAddress?.city) {
                rtn = rtn.concat(customer.businessAddress?.city, ", ");
            }

            if (customer.businessAddress?.state) {
                rtn = rtn.concat(customer.businessAddress?.state, ", ");
            }

            if (customer.businessAddress?.zip) {
                rtn = rtn.concat(customer.businessAddress?.zip);
            }

            return rtn;
        }
    }

    static mapDtoData(refiner: RefinerDto): RefinerGroupDto {
        const refinerDtos = new Array<RefinerDto>();

        switch (refiner.refinerLocation) {
            case RefinerLocation.zrt:
            case RefinerLocation.zrtByEmployee:
            case RefinerLocation.zrtByArea:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "Zrt",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.account:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "Name",
                        refiner.value,
                        refiner.dataValue
                    ),
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "CustomerNumber",
                        refiner.value,
                        refiner.dataValue
                    ),
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "Id",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.street:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerBusinessAddress",
                        "Address1",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.city:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerBusinessAddress",
                        "City",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.states:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerBusinessAddress",
                        "State",
                        refiner.value
                    )
                );
                break;
            case RefinerLocation.counties:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerBusinessAddress",
                        "County",
                        refiner.value
                    )
                );
                break;
            case RefinerLocation.zipCodes:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerBusinessAddress",
                        "Zip",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.callOnOrAfterDate:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "LastCall",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.callOnOrBeforeDate:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "LastCall",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.availability:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "Availability",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.projects:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "ProjectCustomers",
                        "ProjectId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.wholesalers:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "WholesalerCustomerMembers",
                        "WholesalerId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.chains:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "TdOwnerCode",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.products:
                if (refiner.value.includes("Not In Dist")) {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            RefinerLocation.notEqual,
                            "CallInDistProducts",
                            "ProductId",
                            refiner.value,
                            refiner.dataValue
                        )
                    );
                } else {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "CallInDistProducts",
                            "ProductId",
                            refiner.value,
                            refiner.dataValue
                        ),
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "Customers",
                            "Id",
                            refiner.value,
                            refiner.dataValue
                        )
                    );
                }
                break;
            case RefinerLocation.storeTypes:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "Type",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.msa:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "IsMsa",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                if (refiner.dataValue === "0") {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "Customers",
                            "IsMsa",
                            refiner.value,
                            "IsNull"
                        )
                    );
                }
                break;
            case RefinerLocation.phone:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "HasPhone",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                if (refiner.dataValue === "0") {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "Customers",
                            "HasPhone",
                            refiner.value,
                            "IsNull"
                        )
                    );
                }
                break;
            case RefinerLocation.isActive:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "IsActive",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                if (refiner.dataValue === "0") {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "Customers",
                            "IsActive",
                            refiner.value,
                            "IsNull"
                        )
                    );
                }
                break;
            case RefinerLocation.volume:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "IndustryVolume",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.px3Rank:
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "CustomerPx3Ranks",
                            "Px3RankId",
                            refiner.value,
                            refiner.dataValue
                        )
                    );
                    break;
            case RefinerLocation.callable:
                const overridden = refiner.value?.includes("Overridden");
                const yes = refiner.value?.includes("Yes");
                if ((!yes && !overridden) || yes) {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "Customers",
                            "IsCallable",
                            refiner.value,
                            refiner.dataValue
                        )
                    );
                    if (refiner.dataValue === "0") {
                        refinerDtos.push(
                            RefinerConverterService.createRefinerDto(
                                refiner.refinerLocation,
                                "Customers",
                                "IsCallable",
                                refiner.value,
                                "IsNull"
                            )
                        );
                    }
                }
                if (overridden) {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            RefinerLocation.unknown,
                            "Customers",
                            "IsCallableOverridden",
                            "1"
                        )
                    );
                }
                break;
            default:
                break;
        }
        return RefinerConverterService.splitRefinerDtoValues(refinerDtos);
    }
}
