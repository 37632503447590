import { GratisApprovalDto } from "./gratis-approval.dto";
import { GratisAssignedTransactionDto } from "./gratis-assigned-transaction.dto";
import { GratisProductRequestedDetailDto } from "./gratis-product-requested-detail.dto";
import { GratisProductShippedDetailDto } from "./gratis-product-shipped-detail.dto";

export class GratisDto {
    id: string;
    employeeId: string;
    gratisNumber: string;
    gratisStatusId: number;
    purpose: string;
    daysWaiting: number | null;
    neededByDate: string;
    startDate: string | null;
    endDate: string | null;
    availableAmount: number | null;
    requestedAmount: number | null;
    reimbursedAmount: number | null;
    manualGratisAmount: number | null;
    carryForwardAmount: number | null;
    employeeApprovalStatusId: number | null;
    employeeApprovalStatusDate: string | null;
    regionalEmployeeApprovalEmployeeId: string | null;
    regionalEmployeeApprovalStatusId: number | null;
    regionalEmployeeApprovalStatusDate: string | null;
    regionalEmployeeApprovalComment: string | null;
    zoneEmployeeApprovalEmployeeId: string | null;
    zoneEmployeeApprovalStatusId: number | null;
    zoneEmployeeApprovalStatusDate: string | null;
    zoneEmployeeApprovalComment: string | null;
    customerServiceEmployeeApprovalEmployeeId: string | null;
    customerServiceEmployeeApprovalStatusId: number | null;
    customerServiceEmployeeApprovalStatusDate: string | null;
    customerServiceEmployeeApprovalComment: string | null;
    orderDate: string | null;
    orderBy: string | null;
    orderReferenceNumber: string | null;
    shipToAddress1: string;
    shipToAddress2: string | null;
    shipToCity: string;
    shipToState: string;
    shipToZip: string;
    createdUtcDateTime: string;
    createdUserId: string;
    createdUserZrt: string | null;
    createdUserFullName: string;
    modifiedUtcDateTime: string | null;
    modifiedUserId: string;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string;
    gratisApprovals: GratisApprovalDto[];
    gratisProductRequestedDetails: GratisProductRequestedDetailDto[];
    gratisProductShippedDetails: GratisProductShippedDetailDto[];
    gratisAssignedTransactions: GratisAssignedTransactionDto[];
}
