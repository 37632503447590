import { Component, OnDestroy, OnInit } from "@angular/core";
//Icons
import {
    faPen,
    faSave,
    faSignOutAlt,
    faReceipt,
    faCheckSquare,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { BehaviorSubject, Subscription } from "rxjs";
import { ChainHeadquarterProfileViewmodel } from "../../profile-master/chain-hq-profile/chain-hq-profile.viewmodel";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { map } from "rxjs/operators";
import { OverlayService } from "src/app/services/overlay.service";
import { Router } from "@angular/router";
import { FormControl, Validators } from "@angular/forms";
import { State } from "src/app/entity-models/state.entity";
import { FilterService } from "src/app/services/filter.service";
import { Customer } from "src/app/entity-models/customer.entity";
import { AppStateService } from "src/app/services/app-state.service";
import { ChainStoreTypes } from "src/app/enums/chain-store-types";
import { newSequentialId, SharedHelper } from "shield.shared";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { Employee } from "src/app/entity-models/employee.entity";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { SyncService } from "src/app/services/sync.service";

@Component({
    selector: "app-chain-hq-info",
    templateUrl: "./chain-hq-info.component.html",
    styleUrls: ["./chain-hq-info.component.scss"]
})
export class ChainHqInfoComponent implements OnInit, OnDestroy {
    faPen: IconDefinition = faPen;
    faSave: IconDefinition = faSave;
    faSignOutAlt: IconDefinition = faSignOutAlt;
    faReceipt: IconDefinition = faReceipt;
    faCheckSquare: IconDefinition = faCheckSquare;
    viewMode = true;

    overlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    chainHqCustomerSubscription: Subscription;
    chainHeadquarterViewmodel: ChainHeadquarterProfileViewmodel = new ChainHeadquarterProfileViewmodel(
        this.employeeDelineationService,
        this.customerDelineationService,
        this.accountOwnershipDelineationService
    );

    dateFormat = MY_DATE_FORMATS.display.customDateInput;
    dateTimeFormat = MY_DATE_FORMATS.display.customDateTimeInput;
    formattedPhone: string;

    usPhoneNumberRegEx = new RegExp(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    );

    phoneFormControl = new FormControl(
        this.chainHeadquarterViewmodel?.customer?.phone,
        {
            validators: [Validators.pattern(this.usPhoneNumberRegEx)],
            updateOn: "blur"
        }
    );

    phoneNumberSubscription: Subscription;
    private employeeSubscription: Subscription;
    private employeeName: string;
    private customer: Customer;

    states: State[] = [];
    private shouldWait$ = new BehaviorSubject<void>(null);

    constructor(
        private pleaseWaitService: PleaseWaitService,
        private customerStateService: CustomerStateService,
        private overlayService: OverlayService,
        private router: Router,
        private filterService: FilterService,
        private appStateService: AppStateService,
        private syncService: SyncService,
        private employeeDelineationService: EmployeeDelineationService,
        private customerDelineationService: CustomerDelineationService,
        private accountOwnershipDelineationService: AccountOwnershipDelineationService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.states = await this.filterService.getStates();

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.appStateService.currentEmployee
                .pipe(
                    map(async (employee) => {
                        this.employeeName = employee.fullName;
                        await this.setupViewmodel();
                    })
                )
                .subscribe();
        }

        if (
            !this.chainHqCustomerSubscription ||
            this.chainHqCustomerSubscription.closed
        ) {
            this.chainHqCustomerSubscription = this.customerStateService.observableCustomer
                .pipe(
                    map(async (customer) => {
                        this.customer = customer;
                        await this.setupViewmodel();
                    })
                )
                .subscribe();
        }

        if (
            !this.phoneNumberSubscription ||
            this.phoneNumberSubscription.closed
        ) {
            this.phoneNumberSubscription = this.phoneFormControl.valueChanges.subscribe(
                () => {
                    if (
                        this.phoneFormControl.valid &&
                        this.chainHeadquarterViewmodel.customer
                    ) {
                        this.chainHeadquarterViewmodel.customer.phone = this
                            .phoneFormControl.value as string;
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (
            this.chainHqCustomerSubscription &&
            !this.chainHqCustomerSubscription.closed
        ) {
            this.chainHqCustomerSubscription.unsubscribe();
        }

        if (
            this.phoneNumberSubscription &&
            !this.phoneNumberSubscription.closed
        ) {
            this.phoneNumberSubscription.unsubscribe();
        }
    }

    onUltimateCustomerChange(c: Customer): void {
        this.chainHeadquarterViewmodel.dirtyUltimateCustomer = true;
        if (c && c.id === this.chainHeadquarterViewmodel.customer.id) {
            this.chainHeadquarterViewmodel.type = ChainStoreTypes.ultimate;
            if (this.chainHeadquarterViewmodel.immediateCustomer?.id === c.id) {
                this.chainHeadquarterViewmodel.immediateCustomer = null;
            }
            if (this.chainHeadquarterViewmodel.ownerCustomer?.id === c.id) {
                this.chainHeadquarterViewmodel.ownerCustomer = null;
            }
        }
    }

    onImmediateCustomerChange(c: Customer): void {
        this.chainHeadquarterViewmodel.dirtyImmediateCustomer = true;
        if (c && c.id === this.chainHeadquarterViewmodel.customer.id) {
            this.chainHeadquarterViewmodel.type = ChainStoreTypes.immediate;
            if (this.chainHeadquarterViewmodel.ultimateCustomer?.id === c.id) {
                this.chainHeadquarterViewmodel.ultimateCustomer = null;
            }
            if (this.chainHeadquarterViewmodel.ownerCustomer?.id === c.id) {
                this.chainHeadquarterViewmodel.ownerCustomer = null;
            }
        }
    }

    onOwnerCustomerChange(c: Customer): void {
        this.chainHeadquarterViewmodel.dirtyOwnerCustomer = true;
        if (c && c.id === this.chainHeadquarterViewmodel.customer.id) {
            this.chainHeadquarterViewmodel.type = ChainStoreTypes.owner;
            if (this.chainHeadquarterViewmodel.ultimateCustomer?.id === c.id) {
                this.chainHeadquarterViewmodel.ultimateCustomer = null;
            }
            if (this.chainHeadquarterViewmodel.immediateCustomer?.id === c.id) {
                this.chainHeadquarterViewmodel.immediateCustomer = null;
            }
        }
    }

    async onUltimateRepChange(e: Employee): Promise<void> {
        const employeeResponse = await this.employeeDelineationService.getById(e.id);

        if (!employeeResponse || !employeeResponse.values) return;

        this.chainHeadquarterViewmodel.ultimateOwnership.assignedEmployeeId = employeeResponse.values.id;
        this.chainHeadquarterViewmodel.ultimateCustomerRep = employeeResponse.values.fullName;
    }

    async onImmediateRepChange(e: Employee): Promise<void> {
        const employeeResponse = await this.employeeDelineationService.getById(e.id);

        if (!employeeResponse || !employeeResponse.values) return;

        this.chainHeadquarterViewmodel.immediateOwnership.assignedEmployeeId = employeeResponse.values.id;
        this.chainHeadquarterViewmodel.immediateCustomerRep = employeeResponse.values.fullName;
    }

    async onOwnerRepChange(e: Employee): Promise<void> {
        const employeeResponse = await this.employeeDelineationService.getById(e.id);

        if (!employeeResponse || !employeeResponse.values) return;

        this.chainHeadquarterViewmodel.ownerOwnership.assignedEmployeeId = employeeResponse.values.id;
        this.chainHeadquarterViewmodel.ownerCustomerRep = employeeResponse.values.fullName;
    }

    compareCustomerOptions(a: Customer, b: Customer): boolean {
        return a?.id === b?.id;
    }

    setMode = (): void => {
        this.viewMode = !this.viewMode;
        this.formattedPhone = SharedHelper.formatPhoneNumber(
            this.chainHeadquarterViewmodel.customer.phone
        );
    };

    openDialog = async (): Promise<void> => {
        const result = await this.chainHeadquarterViewmodel.buildDomainModelsFromViewModel();
        if (!result.isEqual) {
            if (this.overlayRef) {
                this.overlayRef.close();
            }
            const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
            data.header = "Confirmation";
            data.message =
                "Your changes have not been saved. Are you sure you want to cancel?";
            data.buttonLeftText = "No";
            data.buttonLeftFunction = () => {
                this.overlayRef.close();
            };
            data.buttonRightText = "Yes";
            data.buttonRightFunction = () => {
                this.overlayRef.close();
                void this.router.navigate([
                    "/accounts",
                    this.customerStateService.customer.id,
                    "profile"
                ]);
            };
            this.overlayRef = this.overlayService.open(
                ConfirmationDialogComponent,
                data
            );

            this.overlayRef.afterClosed$.subscribe(() => {
                this.overlayRef = undefined;
            });
        } else {
            this.setMode();
            void this.router.navigate([
                "/accounts",
                this.customerStateService.customer.id,
                "profile"
            ]);
        }
    };

    async save(): Promise<void> {
        const result = await this.chainHeadquarterViewmodel.buildDomainModelsFromViewModel();
        if (!result.isEqual) {
            result.customer.lastEdited = new Date();
            result.customer.lastEditedBy = this.employeeName;
            if (this.customerStateService.customer.isActive !== result.customer.isActive) {
                result.customer.isActive
                    ? result.customer.reactivatedDate = new Date()
                    : result.customer.deactivatedDate = new Date();
            }

            const customerResponse = await this.customerDelineationService.upsertCustomer(result.customer);
            if (!customerResponse) { return; }
        }

        this.syncService.forceOutboundSync();

        void this.router.navigate([
            "/accounts",
            this.customerStateService.customer.id,
            "profile"
        ]);
    }

    private async setupViewmodel(): Promise<void> {
        if (this.customer && this.employeeName) {
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

            this.phoneFormControl.setValue(this.customer.phone);
            let chainHeadquarterResponse = await this.customerDelineationService.getById(this.customer.id);

            if (!chainHeadquarterResponse) { return; }
            let chainHeadquarter = chainHeadquarterResponse.values;

            if (!chainHeadquarter) {
                chainHeadquarter ??= new Customer();
                chainHeadquarter.id = newSequentialId();
            }
            await this.chainHeadquarterViewmodel.buildViewmodelFromDomainModel(
                chainHeadquarter,
                this.customer,
            );
            this.formattedPhone = SharedHelper.formatPhoneNumber(this.customer.phone);

            this.shouldWait$.next();
        }
    }
}
