import { Component, OnInit } from "@angular/core";
import { HeaderButtonComponent } from "../header-button/header-button.component";
import {
    faList,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { ButtonActions } from "../../../enums/button-actions.enum";

@Component({
    selector: "app-list-button",
    templateUrl: "./list-button.component.html",
    styleUrls: ["./list-button.component.scss"]
})
export class ListButtonComponent
    implements HeaderButtonComponent {
    icon: IconDefinition = faList;
    title = "Return to List";
    buttonAction = ButtonActions.list;
    isDisabled: boolean;
}
