export class SpecialCustomerEmployeeAssignmentDto {
    id: string;
    customerId: string;
    employeeId: string;
    createdUtcDateTime: string | null;
    createdUserId: string | null;
    createdUserZrt: string | null;
    createdUserFullName: string | null;
    modifiedUtcDateTime: string | null;
    modifiedUserId: string | null;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string | null;
    rowversion: string;
}
