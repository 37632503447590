import { ContractTemplateDetailDto, ContractTemplateDto } from "shield.shared";
import { ContractTemplateDetail } from "src/app/entity-models/contract-template-detail.entity";
import { ContractTemplate } from "src/app/entity-models/contract-template.entity";

export class ContractTemplateConverterService {
    static contractTemplateToContractTemplateDto(d: ContractTemplate | null): ContractTemplateDto | null {
        if (d == null) {
            return null;
        }

        return {
            ...d,
            createdUtcDateTime: d?.createdUtcDateTime?.toISOString(),
            modifiedUtcDateTime: d?.modifiedUtcDateTime?.toISOString(),
            contractTemplateDetails: d.contractTemplateDetails?.map((v) => ContractTemplateConverterService.contractTemplateDetailToContractTemplateDetailDto(v))
        };
    }

    static contractTemplateDtoToContractTemplate(dto: ContractTemplateDto | null): ContractTemplate | null {
        if (dto == null) {
            return null;
        }

        return {
            ...dto,
            createdUtcDateTime: dto?.createdUtcDateTime ? new Date(dto.createdUtcDateTime) : null,
            modifiedUtcDateTime: dto?.modifiedUtcDateTime ? new Date(dto.modifiedUtcDateTime) : null,
            contractTemplateDetails: dto.contractTemplateDetails?.map((v) => ContractTemplateConverterService.contractTemplateDetailDtoToContractTemplateDetail(v))
        };
    }

    static contractTemplateDetailToContractTemplateDetailDto(d: ContractTemplateDetail | null): ContractTemplateDetailDto | null {
        if (d == null) {
            return null;
        }

        return {
            ...d,
            createdUtcDateTime: d.createdUtcDateTime.toISOString(),
        };
    }

    static contractTemplateDetailDtoToContractTemplateDetail(dto: ContractTemplateDetailDto | null): ContractTemplateDetail | null {
        if (dto == null) {
            return null;
        }

        return {
            ...dto,
            createdUtcDateTime: dto.createdUtcDateTime ? new Date(dto.createdUtcDateTime) : new Date(),
        };
    }
}
