import { TimeEntryType } from "./time-entry-type.entity";

export class TimeEntry {
    id: string;
    sourceId: string;
    start: Date;
    end: Date;
    type: TimeEntryType;
    name: string;
    comments: string;
    employeeId: string;
    createdDate: Date;
    createdBy: string;
    updatedDate: Date;
    updatedBy: string;
    isDeleted: boolean;
    hasServerProcessed: number;
    rowversion: string;
}
