import { ContactDto, SharedHelper } from "shield.shared";
import { AvailabilityViewModel } from "src/app/accounts/account.viewmodels/availability.viewmodel";
import { DayAvailabilityViewModel } from "src/app/accounts/account.viewmodels/day-availability.viewmodel";
import { ContactViewModel } from "src/app/accounts/contact/contact.viewmodel";
import { Availability } from "src/app/entity-models/availability.entity";
import { Contact } from "src/app/entity-models/contact.entity";
import { DailyAvailability } from "src/app/entity-models/daily-availability.entity";
import { Day } from "src/app/enums/day";
import { DayAbbreviation } from "src/app/enums/day-abbreviation";

export class ContactConverterService {

    static contactToContactDto(c: Contact): ContactDto {
        if (c) {
            return {
                ...c,
                isAvailableSundayAm: c.availability?.sunday?.am,
                isAvailableSundayPm: c.availability?.sunday?.pm,
                isAvailableMondayAm: c.availability?.monday?.am,
                isAvailableMondayPm: c.availability?.monday?.pm,
                isAvailableTuesdayAm: c.availability?.tuesday?.am,
                isAvailableTuesdayPm: c.availability?.tuesday?.pm,
                isAvailableWednesdayAm: c.availability?.wednesday?.am,
                isAvailableWednesdayPm: c.availability?.wednesday?.pm,
                isAvailableThursdayAm: c.availability?.thursday?.am,
                isAvailableThursdayPm: c.availability?.thursday?.pm,
                isAvailableFridayAm: c.availability?.friday?.am,
                isAvailableFridayPm: c.availability?.friday?.pm,
                isAvailableSaturdayAm: c.availability?.saturday?.am,
                isAvailableSaturdayPm: c.availability?.saturday?.pm,
                createdUserId: c.createdUserId,
                createdUserZrt: c.createdUserZrt,
                createdUserFullName: c.createdUserFullName,
                createdUtcDateTime: c.createdUtcDateTime
                    ? c.createdUtcDateTime.toISOString()
                    : new Date().toISOString(),
                modifiedUserId: c.modifiedUserId,
                modifiedUserZrt: c.modifiedUserZrt,
                modifiedUserFullName: c.modifiedUserFullName,
                modifiedUtcDateTime: c.modifiedUtcDateTime ? c.modifiedUtcDateTime.toISOString()
                    : null,
                clientLastTouchedUtcDateTime: c.clientLastTouchedUtcDateTime
                    ? c.clientLastTouchedUtcDateTime.toISOString()
                    : null
            };
        } else {
            return null;
        }
    }

    static contactDtoToContact(dto: ContactDto): Contact {
        const availability = new Availability();
        availability.sunday.am = dto.isAvailableSundayAm;
        availability.sunday.pm = dto.isAvailableSundayPm;
        availability.monday.am = dto.isAvailableMondayAm;
        availability.monday.pm = dto.isAvailableMondayPm;
        availability.tuesday.am = dto.isAvailableTuesdayAm;
        availability.tuesday.pm = dto.isAvailableTuesdayPm;
        availability.wednesday.am = dto.isAvailableWednesdayAm;
        availability.wednesday.pm = dto.isAvailableWednesdayPm;
        availability.thursday.am = dto.isAvailableThursdayAm;
        availability.thursday.pm = dto.isAvailableThursdayPm;
        availability.friday.am = dto.isAvailableFridayAm;
        availability.friday.pm = dto.isAvailableFridayPm;
        availability.saturday.am = dto.isAvailableSaturdayAm;
        availability.saturday.pm = dto.isAvailableSaturdayPm;
        return {
            ...dto,
            availability,
            createdUtcDateTime: dto.createdUtcDateTime
                ? new Date(dto.createdUtcDateTime)
                : new Date(),
            modifiedUtcDateTime: dto.modifiedUtcDateTime
                ? new Date(dto.modifiedUtcDateTime)
                : null,
            clientLastTouchedUtcDateTime: dto.clientLastTouchedUtcDateTime
                ? new Date(dto.clientLastTouchedUtcDateTime)
                : null,
            hasServerProcessed: 0
        };
    }

    static getAvaiability(
        availability: Availability | AvailabilityViewModel
    ): string {
        let rtn = "";

        if (availability.sunday.am || availability.sunday.pm) {
            rtn += DayAbbreviation.sunday;
        }
        if (availability.monday.am || availability.monday.pm) {
            rtn += DayAbbreviation.monday;
        }
        if (availability.tuesday.am || availability.tuesday.pm) {
            rtn += DayAbbreviation.tuesday;
        }
        if (availability.wednesday.am || availability.wednesday.pm) {
            rtn += DayAbbreviation.wednesday;
        }
        if (availability.thursday.am || availability.thursday.pm) {
            rtn += DayAbbreviation.thursday;
        }
        if (availability.friday.am || availability.friday.pm) {
            rtn += DayAbbreviation.friday;
        }
        if (availability.saturday.am || availability.saturday.pm) {
            rtn += DayAbbreviation.saturday;
        }

        return rtn;
    }

    static mapDayToDayAbbreviation(days: string[]): string {
        let rtn = "";
        days ??= [];
        for (const day of days) {
            switch (day) {
                case Day.sunday:
                    rtn = rtn + DayAbbreviation.sunday;
                    break;
                case Day.monday:
                    rtn = rtn + DayAbbreviation.monday;
                    break;
                case Day.tuesday:
                    rtn = rtn + DayAbbreviation.tuesday;
                    break;
                case Day.wednesday:
                    rtn = rtn + DayAbbreviation.wednesday;
                    break;
                case Day.thursday:
                    rtn = rtn + DayAbbreviation.thursday;
                    break;
                case Day.friday:
                    rtn = rtn + DayAbbreviation.friday;
                    break;
                case Day.saturday:
                    rtn = rtn + DayAbbreviation.saturday;
                    break;
                default:
                    break;
            }
        }
        return rtn;
    }

    static buildDomainModelFromViewModel(
        retailCallCustomerContactViewModel: ContactViewModel
    ): Contact {
        const contact: Contact = new Contact();

        contact.id = retailCallCustomerContactViewModel.id;
        contact.customerId = retailCallCustomerContactViewModel.customerId;

        contact.alternatePhone = SharedHelper.unFormatPhoneNumber(
            retailCallCustomerContactViewModel.alternatePhoneNumber
        );
        contact.email = retailCallCustomerContactViewModel.email;
        contact.firstName = retailCallCustomerContactViewModel.firstName;
        contact.lastName = retailCallCustomerContactViewModel.lastName;
        contact.note = retailCallCustomerContactViewModel.note;
        contact.phone = SharedHelper.unFormatPhoneNumber(
            retailCallCustomerContactViewModel.phoneNumber
        );
        contact.availability = new Availability();

        const sunday = retailCallCustomerContactViewModel.availability.sunday;
        const monday = retailCallCustomerContactViewModel.availability.monday;
        const tuesday = retailCallCustomerContactViewModel.availability.tuesday;
        const wednesday =
            retailCallCustomerContactViewModel.availability.wednesday;
        const thursday =
            retailCallCustomerContactViewModel.availability.thursday;
        const friday = retailCallCustomerContactViewModel.availability.friday;
        const saturday =
            retailCallCustomerContactViewModel.availability.saturday;

        contact.availability.sunday = new DailyAvailability();
        contact.availability.sunday.am = sunday.am;
        contact.availability.sunday.pm = sunday.pm;

        contact.availability.monday = new DailyAvailability();
        contact.availability.monday.am = monday.am;
        contact.availability.monday.pm = monday.pm;

        contact.availability.tuesday = new DailyAvailability();
        contact.availability.tuesday.am = tuesday.am;
        contact.availability.tuesday.pm = tuesday.pm;

        contact.availability.wednesday = new DailyAvailability();
        contact.availability.wednesday.am = wednesday.am;
        contact.availability.wednesday.pm = wednesday.pm;

        contact.availability.thursday = new DailyAvailability();
        contact.availability.thursday.am = thursday.am;
        contact.availability.thursday.pm = thursday.pm;

        contact.availability.friday = new DailyAvailability();
        contact.availability.friday.am = friday.am;
        contact.availability.friday.pm = friday.pm;

        contact.availability.saturday = new DailyAvailability();
        contact.availability.saturday.am = saturday.am;
        contact.availability.saturday.pm = saturday.pm;

        contact.createdUtcDateTime = retailCallCustomerContactViewModel.createdUtcDateTime;
        contact.createdUserZrt = retailCallCustomerContactViewModel.createdUserZrt;
        contact.createdUserId = retailCallCustomerContactViewModel.createdUserId;
        contact.createdUserFullName = retailCallCustomerContactViewModel.createdUserFullName;

        contact.modifiedUtcDateTime = retailCallCustomerContactViewModel.modifiedUtcDateTime;
        contact.modifiedUserZrt = retailCallCustomerContactViewModel.modifiedUserZrt;
        contact.modifiedUserId = retailCallCustomerContactViewModel.modifiedUserId;
        contact.modifiedUserFullName = retailCallCustomerContactViewModel.modifiedUserFullName;

        contact.isDeleted = retailCallCustomerContactViewModel.isDeleted;
        contact.rowversion = retailCallCustomerContactViewModel.rowversion;

        return contact;
    }

    static buildViewModelFromDomainModel(
        contact: Contact,
        customerId: string
    ): ContactViewModel {
        const retailCallCustomerContactViewModel: ContactViewModel = new ContactViewModel();
        retailCallCustomerContactViewModel.id = contact.id;
        retailCallCustomerContactViewModel.customerId = customerId;
        retailCallCustomerContactViewModel.alternatePhoneNumber = SharedHelper.formatPhoneNumber(
            contact.alternatePhone
        );
        retailCallCustomerContactViewModel.email = contact.email;
        retailCallCustomerContactViewModel.firstName = contact.firstName;
        retailCallCustomerContactViewModel.lastName = contact.lastName;
        retailCallCustomerContactViewModel.note = contact.note;
        retailCallCustomerContactViewModel.phoneNumber = SharedHelper.formatPhoneNumber(
            contact.phone
        );
        retailCallCustomerContactViewModel.availability = new AvailabilityViewModel();
        retailCallCustomerContactViewModel.availableContactDays = "";

        retailCallCustomerContactViewModel.dailyAvailability ??= [];
        let dailyAvailability: DayAvailabilityViewModel = new DayAvailabilityViewModel();
        dailyAvailability.name = Day.sunday;
        dailyAvailability.am = contact.availability?.sunday?.am;
        dailyAvailability.pm = contact.availability?.sunday?.pm;
        dailyAvailability.all = dailyAvailability.am && dailyAvailability.pm;
        retailCallCustomerContactViewModel.availability.sunday = dailyAvailability;
        retailCallCustomerContactViewModel.dailyAvailability.push(
            retailCallCustomerContactViewModel.availability.sunday
        );

        dailyAvailability = new DayAvailabilityViewModel();
        dailyAvailability.name = Day.monday;
        dailyAvailability.am = contact.availability?.monday?.am;
        dailyAvailability.pm = contact.availability?.monday?.pm;
        dailyAvailability.all = dailyAvailability.am && dailyAvailability.pm;
        retailCallCustomerContactViewModel.availability.monday = dailyAvailability;
        retailCallCustomerContactViewModel.dailyAvailability.push(
            retailCallCustomerContactViewModel.availability.monday
        );

        dailyAvailability = new DayAvailabilityViewModel();
        dailyAvailability.name = Day.tuesday;
        dailyAvailability.am = contact.availability?.tuesday?.am;
        dailyAvailability.pm = contact.availability?.tuesday?.pm;
        dailyAvailability.all = dailyAvailability.am && dailyAvailability.pm;
        retailCallCustomerContactViewModel.availability.tuesday = dailyAvailability;
        retailCallCustomerContactViewModel.dailyAvailability.push(
            retailCallCustomerContactViewModel.availability.tuesday
        );

        dailyAvailability = new DayAvailabilityViewModel();
        dailyAvailability.name = Day.wednesday;
        dailyAvailability.am = contact.availability?.wednesday?.am;
        dailyAvailability.pm = contact.availability?.wednesday?.pm;
        dailyAvailability.all = dailyAvailability.am && dailyAvailability.pm;
        retailCallCustomerContactViewModel.availability.wednesday = dailyAvailability;
        retailCallCustomerContactViewModel.dailyAvailability.push(
            retailCallCustomerContactViewModel.availability.wednesday
        );

        dailyAvailability = new DayAvailabilityViewModel();
        dailyAvailability.name = Day.thursday;
        dailyAvailability.am = contact.availability?.thursday?.am;
        dailyAvailability.pm = contact.availability?.thursday?.pm;
        dailyAvailability.all = dailyAvailability.am && dailyAvailability.pm;
        retailCallCustomerContactViewModel.availability.thursday = dailyAvailability;
        retailCallCustomerContactViewModel.dailyAvailability.push(
            retailCallCustomerContactViewModel.availability.thursday
        );

        dailyAvailability = new DayAvailabilityViewModel();
        dailyAvailability.name = Day.friday;
        dailyAvailability.am = contact.availability?.friday?.am;
        dailyAvailability.pm = contact.availability?.friday?.pm;
        dailyAvailability.all = dailyAvailability.am && dailyAvailability.pm;
        retailCallCustomerContactViewModel.availability.friday = dailyAvailability;
        retailCallCustomerContactViewModel.dailyAvailability.push(
            retailCallCustomerContactViewModel.availability.friday
        );

        dailyAvailability = new DayAvailabilityViewModel();
        dailyAvailability.name = Day.saturday;
        dailyAvailability.am = contact.availability?.saturday?.am;
        dailyAvailability.pm = contact.availability?.saturday?.pm;
        dailyAvailability.all = dailyAvailability.am && dailyAvailability.pm;
        retailCallCustomerContactViewModel.availability.saturday = dailyAvailability;
        retailCallCustomerContactViewModel.dailyAvailability.push(
            retailCallCustomerContactViewModel.availability.saturday
        );

        retailCallCustomerContactViewModel.availableContactDays = ContactConverterService.getAvaiability(
            retailCallCustomerContactViewModel.availability
        );

        retailCallCustomerContactViewModel.isDeleted = contact.isDeleted;

        retailCallCustomerContactViewModel.createdUtcDateTime = contact.createdUtcDateTime;
        retailCallCustomerContactViewModel.createdUserZrt = contact.createdUserZrt;
        retailCallCustomerContactViewModel.createdUserId = contact.createdUserId;
        retailCallCustomerContactViewModel.createdUserFullName = contact.createdUserFullName;

        retailCallCustomerContactViewModel.modifiedUtcDateTime = contact.modifiedUtcDateTime;
        retailCallCustomerContactViewModel.modifiedUserZrt = contact.modifiedUserZrt;
        retailCallCustomerContactViewModel.modifiedUserId = contact.modifiedUserId;
        retailCallCustomerContactViewModel.modifiedUserFullName = contact.modifiedUserFullName;

        retailCallCustomerContactViewModel.rowversion = contact.rowversion;

        return retailCallCustomerContactViewModel;
    }
}
