import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
    faArrowDown,
    faArrowUp,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { BehaviorSubject } from "rxjs";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { VolumeDataService } from "../volume-data.service";
import { VolumeDistributionService } from "../volume-distribution.service";
import { AppStateService } from "src/app/services/app-state.service";
import { take } from "rxjs/operators";

@Component({
    selector: "app-retail-volume",
    templateUrl: "./retail-volume.component.html",
    styleUrls: ["./retail-volume.component.scss"]
})
export class RetailVolumeComponent implements OnInit, OnDestroy {
    faArrowDown: IconDefinition = faArrowDown;
    faArrowUp: IconDefinition = faArrowUp;
    shouldWait$ = new BehaviorSubject<boolean>(false);

    constructor(
        public volumeData: VolumeDataService,
        public volumeDistribution: VolumeDistributionService,
        private customerStateService: CustomerStateService,
        private pleaseWaitService: PleaseWaitService,
        private appStateService: AppStateService
    ) {}

    async ngOnInit(): Promise<void> {

        const volumeDataPromise = this.volumeData.loadForCustomer(
            this.customerStateService.customer.id,
            true // load retail comparison
        );
        const employee = await this.appStateService.currentEmployee.pipe(take(1)).toPromise();
        const volumeDistributionPromise = this.volumeDistribution.loadForCustomer(
            this.customerStateService.customer,
            employee
        );

        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        await Promise.all([volumeDataPromise, volumeDistributionPromise]);

        this.shouldWait$.next(false);

    }

    ngOnDestroy(): void {
        if (this.volumeData) {
            this.volumeData.unload();
            this.volumeData = null;
        }
        if (this.volumeDistribution) {
            this.volumeDistribution = null;
        }
    }
}
