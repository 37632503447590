import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterRequestDto, RefinerDto, RefinerGroupDto, RefinerLocation, SharedHelper, SystemInformationKeys } from 'shield.shared';
import { Customer } from 'src/app/entity-models/customer.entity';
import { CallDelineationService } from 'src/app/services/delineation-services/call-delineation.service';
import { SystemInformationDelineationService } from 'src/app/services/delineation-services/system-information-delineation.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { PingService } from 'src/app/services/ping.service';
import { Call } from '../../call-master/call-services/call.service';

@Component({
    selector: 'app-customer-call-history-base',
    templateUrl: './customer-call-history-base.component.html',
    styleUrls: ['./customer-call-history-base.component.scss']
})
export class CustomerCallHistoryBaseComponent implements OnInit, OnDestroy {

    isOnline = false;
    isOnlineSubscription: Subscription;

    protected _customer: Customer;
    pageSize = 15;

    constructor(
        protected overlayService: OverlayService,
        protected systemInformationDelineationService: SystemInformationDelineationService,
        protected callDelineationService: CallDelineationService,
        protected pingService: PingService
    ) { }

    async ngOnInit(): Promise<void> {

        if (!this.isOnlineSubscription || this.isOnlineSubscription.closed) {
            this.isOnlineSubscription = this.pingService.online.subscribe((isOnline) => {
                this.isOnline = isOnline;
            });
        }

        const response = await this.systemInformationDelineationService
            .getByKey(SystemInformationKeys.profileCallHistoryPageSize);

        if (!response) { return; }

        const pageSizeSystemInformation = response.values;
        if (pageSizeSystemInformation?.key) {
            const pageSize = SharedHelper.parseInt(pageSizeSystemInformation.key);
            if (pageSize) {
                this.pageSize = pageSize;
            }
        }
    }

    ngOnDestroy(): void {
        if (this.isOnlineSubscription && !this.isOnlineSubscription.closed) {
            this.isOnlineSubscription.unsubscribe();
        }
    }

    async callHistoryBaseSearch(): Promise<Call[]> {

        const filterRequestDto = new FilterRequestDto();
        filterRequestDto.id = this._customer.id;
        filterRequestDto.pageSize = this.pageSize;
        filterRequestDto.startIndex = 0;
        const refiner = new RefinerDto();
        refiner.dtoObjectName = "Customers";
        refiner.dtoPropertyName = "Id";
        refiner.refinerLocation = RefinerLocation.callHistory;
        refiner.value = refiner.dataValue = this._customer.id;
        const refinerGroup = new RefinerGroupDto();
        refinerGroup.refiners = [refiner];
        filterRequestDto.refinerGroups = [refinerGroup];

        const response = await this.callDelineationService.getCallsByCustomerId(this._customer.id, filterRequestDto);

        if (!response) { return; }
        const result = response.values;

        return result?.length ? result.sort((a, b) => a.createdUtcDateTime < b.createdUtcDateTime ? 1 : -1) : new Array<Call>();
    }
}
