import { Injectable } from "@angular/core";
import { CustomerContractPaymentDto, CustomerContractSignatureDataDto, GenericResponseDto} from "shield.shared";
import { CustomerContractPayment } from "src/app/entity-models/customer-contract-payment.entity";
import { CustomerContract } from "src/app/entity-models/customer-contract.entity";
import { Signature } from "src/app/entity-models/signature.entity";
import { DatabaseService } from "../database.service";
import { ContractPaymentOfflineService } from "../offline-services/contract-payment-offline.service";
import { ContractPaymentOnlineService } from "../online-services/contract-payment-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class ContractPaymentDelineationService extends DelineationContext<CustomerContractPayment, string> {

    constructor(private contractPaymentOfflineService: ContractPaymentOfflineService,
        private contractPaymentOnlineService: ContractPaymentOnlineService,
        protected datasourceDelineationService: DatasourceDelineationService,
        protected dbService: DatabaseService,
        snackbarService: SnackbarService
        ){
            super(dbService, datasourceDelineationService, snackbarService);
        }

    async getContractPaymentSignatures(customerContract: CustomerContract): Promise<GenericResponseDto<Signature[] | undefined>> {
        const offline = async (key: CustomerContract) => {
            return this.contractPaymentOfflineService.getContractPaymentSignatures(key);
        }
        const online = async (key: CustomerContract) => {
            const signatures = await this.contractPaymentOnlineService.getContractPaymentSignatures(key);
            this.contractPaymentOfflineService.updateAndSavePayments(customerContract, signatures.values);
            return signatures;

        }
        const compareDelegate = this.compareSignatureArray;
        const offlineValues = (key: CustomerContract, hasOfflineAccess: boolean) => this.getSignatures(key, hasOfflineAccess);
        const response = await this.datasourceDelineationService.makeCall<CustomerContract, Signature[]>(
            customerContract, offline, online, compareDelegate, offlineValues);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }
        return response;
    }

    async upsertCustomerContractPayment(dto: CustomerContractPaymentDto): Promise<GenericResponseDto<CustomerContractPayment | undefined>> {

        const offline = async (key: CustomerContractPaymentDto) => {
            return this.contractPaymentOfflineService.upsertCustomerContractPayment(key);
        }
        const online = async (key: CustomerContractPaymentDto) => {
            return await this.contractPaymentOfflineService.upsertCustomerContractPayment(key);
        }
        const response = await this.datasourceDelineationService.makeCall<CustomerContractPaymentDto, CustomerContractPayment>(dto, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }
        return response;
    }

    private async getSignatures(key: CustomerContract, hasOfflineAccess: boolean): Promise<Signature[]> {
        const results = await this.contractPaymentOfflineService.getContractPaymentSignatures(key);
        return results.filter((r) => !!r);
    }

    private async compareSignatureArray(
        onlineSignatures: Signature[],
        offlineSignatures: Signature[]
    ): Promise<Signature[]> {
        let rtn = new Array<Signature>();
        const offlineSignaturesMaps = new Map(offlineSignatures?.map((entry) => [entry.id, entry]));

        for (const onlineSignature of (onlineSignatures?.filter((os) => !!os) ?? [])) {
            const offlineSignature = offlineSignaturesMaps?.get(onlineSignature.id);
            if (offlineSignature) {
                offlineSignaturesMaps.delete(offlineSignature.id);
            } else {
                rtn.push(onlineSignature);
            }
        }

        // No hasServerProcessed logic here, if we find an offline one that doesn't exist online, add it
        rtn = rtn.concat(Array.from(offlineSignaturesMaps.entries()).map((entry) => entry[1]));
        return rtn;
    }
}
