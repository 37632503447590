import { ProjectActivitySurveyDto } from "./project-activity-survey.dto";
import { ProjectCustomerDto } from "./project-customer.dto";
import { ProjectEmployeeDto } from "./project-employee.dto";
import { ProjectOrderDateDto } from "./project-order-date.dto";
import { ProjectProductDto } from "./project-product.dto";

export class ProjectDto {
    id: string;
    projectId: string;
    projectTypeId: number;
    projectStatusId: number;
    name: string;
    startDate: string | null;
    visibleDate: string | null;
    endDate: string | null;
    mission: string;
    shareSiteLink: string;
    wholesalerGroupId: string;
    areStoresVisibleToAll: boolean;
    createdUserId: string | null;
    createdUtcDateTime: string | null;
    createdUserZrt: string | null;
    createdUserFullName: string | null
    modifiedUtcDateTime: string | null;
    modifiedUserId: string | null;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string | null;
    rowversion: string;

    projectActivitySurveys = new Array<ProjectActivitySurveyDto>();
    projectCustomers = new Array<ProjectCustomerDto>();
    projectEmployees = new Array<ProjectEmployeeDto>();
    projectOrderDates = new Array<ProjectOrderDateDto>();
    projectProducts = new Array<ProjectProductDto>();

    storeCount?: number | null;
}
