import { ContractPaymentMethods, ContractPaymentStatuses, ContractPaymentTypes, ContractTemplateTypes, RefinerLocation, RetailContractsFilterDto, valueSeparator } from "shield.shared";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { AddressFilterMapService } from "./address-filter-map.service";
import { BaseFilterMapService } from "./base-filter-map.service";
import { ZrtFilterMapService } from "./zrt-filter-map.service";

export class RetailContractFilterMapService extends BaseFilterMapService {
    static mapFilterData(refiners: Refiner[]): RetailContractsFilterDto {

        if (!refiners) return;
        const filterDto = new RetailContractsFilterDto();
        const zrtFilterDto = ZrtFilterMapService.mapFilterData(refiners);
        const addressFilterDto = AddressFilterMapService.mapFilterData(refiners);

        if (Object.keys(zrtFilterDto).length) {
            filterDto.zrtFilterDto = zrtFilterDto;
        }

        if (Object.keys(addressFilterDto).length) {
            filterDto.addressFilterDto = addressFilterDto;
        }

        for (const refiner of refiners) {
            if (!refiner.dataValue) {
                refiner.dataValue = refiner.value;
            }

            switch (refiner.location) {
                case RefinerLocation.myList:
                    filterDto.ids = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.account:
                    filterDto.account = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.callOnOrAfterDate:
                    const callOnOrAfterDate = new Date(refiner.dataValue)
                    callOnOrAfterDate.setHours(0,0,0,0);
                    filterDto.callOnOrAfterDate = callOnOrAfterDate.toISOString();
                    break;
                case RefinerLocation.callOnOrBeforeDate:
                    const callOnOrBeforeDate = new Date(refiner.dataValue)
                    callOnOrBeforeDate.setHours(23,59,59,0);
                    filterDto.callOnOrBeforeDate = callOnOrBeforeDate.toISOString();
                    break;
                case RefinerLocation.contract:
                    filterDto.contractNumber = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.contractType:
                    const contractTypes = new Array<ContractTemplateTypes>();
                    refiner.dataValue.split(valueSeparator).forEach((typeId) => contractTypes.push(Number(typeId) as ContractTemplateTypes));
                    filterDto.contractTypes = contractTypes;
                    break;
                case RefinerLocation.lastCallStatus:
                    const callStatuses = new Array<ContractPaymentStatuses>();
                    refiner.dataValue.split(valueSeparator).forEach((statusId) => callStatuses.push(Number(statusId) as ContractPaymentStatuses));
                    filterDto.lastCallStatus = callStatuses;
                    break;
                case RefinerLocation.signedOnOrAfterDate:
                    const signedOnOrAfterDate = new Date(refiner.dataValue)
                    signedOnOrAfterDate.setHours(0,0,0,0);
                    filterDto.signedOnOrAfterDate = signedOnOrAfterDate.toISOString();
                    break;
                case RefinerLocation.signedOnOrBeforeDate:
                    const signedOnOrBeforeDate = new Date(refiner.dataValue)
                    signedOnOrBeforeDate.setHours(23,59,59,0);
                    filterDto.signedOnOrBeforeDate = signedOnOrBeforeDate.toISOString();
                    break;
                case RefinerLocation.paymentOnOrAfterDate:
                    const paymentOnOrAfterDate = new Date(refiner.dataValue)
                    paymentOnOrAfterDate.setHours(0,0,0,0);
                    filterDto.paymentOnOrAfterDate = paymentOnOrAfterDate.toISOString();
                    break;
                case RefinerLocation.paymentOnOrBeforeDate:
                    const paymentOnOrBeforeDate = new Date(refiner.dataValue)
                    paymentOnOrBeforeDate.setHours(23,59,59,0);
                    filterDto.paymentOnOrBeforeDate = paymentOnOrBeforeDate.toISOString();
                    break;
                case RefinerLocation.paymentType:
                    const paymentTypes = new Array<ContractPaymentTypes>();
                    refiner.dataValue.split(valueSeparator).forEach((typeId) => paymentTypes.push(Number(typeId) as ContractPaymentTypes));
                    filterDto.paymentTypes = paymentTypes;
                    break;
                case RefinerLocation.paymentMethod:
                    const paymentMethods = new Array<ContractPaymentMethods>();
                    refiner.dataValue.split(valueSeparator).forEach((methodId) => paymentMethods.push(Number(methodId) as ContractPaymentMethods));
                    filterDto.paymentMethods = paymentMethods;
                    break;
                case RefinerLocation.programLevel:
                    filterDto.programLevel = Number(refiner.dataValue);
                    break;
                case RefinerLocation.storeTypes:
                    filterDto.storeTypes = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.msa:
                    filterDto.isMsa = refiner.dataValue === BaseFilterMapService.yes;
                    break;
                case RefinerLocation.px3Rank:
                    filterDto.px3Rank = refiner.dataValue.split(valueSeparator);
                    break;
                default:
                    break;
            }
        }
        return filterDto;
    }
}
