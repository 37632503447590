export class ZrtFilterDto {
    //Usage: Get based on areas
    areas?: string[];

    //Usage: Get data based on employee ids
    employeeIds?: string[];

    //Usage: Get based on Zrt
    zrts?: string[];

}
