import { AfterViewChecked, Component, HostListener, OnDestroy } from '@angular/core';
import { OverlayService } from 'src/app/services/overlay.service';
import { PleaseWaitService } from 'src/app/services/please-wait.service';
import { ProjectStateService } from 'src/app/services/project-state-service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ProjectApplicationService } from '../../project-services/project-application.service';
import { ProjectActivitiesAndSurveysViewmodel } from './project-activities-and-surveys.viewmodel';
import { SurveyDelineationService } from 'src/app/services/delineation-services/survey-delineation.service';
import { ProjectDelineationService } from 'src/app/services/delineation-services/project-delineation.service';
import { ProjectStep } from 'src/app/enums/project-step';
@Component({
  selector: 'app-project-activities-and-surveys',
  templateUrl: './project-activities-and-surveys.component.html',
  styleUrls: ['./project-activities-and-surveys.component.scss']
})
export class ProjectActivitiesAndSurveysComponent implements OnDestroy, AfterViewChecked {
    viewmodel = new ProjectActivitiesAndSurveysViewmodel(
        this.pleaseWaitService,
        this.projectDelineationService,
        this.projectApplicationService,
        this.projectStateService,
        this.snackbarService,
        this.overlayService,
        this.surveyDelineationService
    );
    isViewmodelReady = false;

    constructor(
        private pleaseWaitService: PleaseWaitService,
        private projectDelineationService: ProjectDelineationService,
        private projectApplicationService: ProjectApplicationService,
        public projectStateService: ProjectStateService,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private surveyDelineationService: SurveyDelineationService
    ) { }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }

    ngAfterViewChecked(): void {
        if (this.projectApplicationService.selectedIndex !== ProjectStep.activitiesAndSurveys) return;

        if (!this.isViewmodelReady) {
            this.isViewmodelReady = true;
            this.getScreenSize();
            setTimeout(() => {
                void this.viewmodel.initialize();
            }, 0);
        }
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(): void {
        if (this.viewmodel) {
            this.viewmodel.screenHeight = window.innerHeight;
        }
    }
}
