import {
    CustomerTypeEnum,
    GenericLookup
} from "shield.shared";
import { Px3Rank } from "src/app/entity-models/px3-rank.entity";
import { FilterService } from "src/app/services/filter.service";

export class ProjectCustomerAttributesViewmodel {
    filterService: FilterService;
    all = "All";
    yes = "Yes";
    no = "No";
    overridden = "Overridden";

    allCustomerType = new GenericLookup<CustomerTypeEnum>();
    allPx3Rank = new GenericLookup<Px3Rank>();
    notRankedPx3Rank = new GenericLookup<Px3Rank>();
    isAllCustomerTypePresent: boolean;
    isAllPx3RankPresent: boolean;
    customerTypes = new Array<GenericLookup<CustomerTypeEnum>>();
    px3Ranks = new Array<GenericLookup<Px3Rank>>();
    selectedCustomerType = new Array<GenericLookup<CustomerTypeEnum>>();
    selectedMsa = this.all;
    selectedPhone = this.all;
    selectedActive = this.yes;
    selectedVolume = this.all;
    selectedCallable = [this.all];
    selectedPx3Ranks = new Array<GenericLookup<Px3Rank>>();

    constructor(filterService: FilterService) {
        this.filterService = filterService;
        this.allCustomerType.name = this.all;
    }

    async initializeStoreTypeDropDown(): Promise<void> {
        this.allCustomerType.name = "All";
        this.customerTypes.push(this.allCustomerType);
        const customerTypes = await this.filterService.getCustomerTypes();
        if(customerTypes) {
            for(let type of customerTypes) {
                this.customerTypes.push(type);
            }
        }
        this.selectedCustomerType = [this.allCustomerType];
    }
}
