import { EventEmitter } from "@angular/core";
import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";
import { WholesalerGroupEntryMember } from "src/app/entity-models/wholesaler-group-entry-member.entity";
import { WholesalerGroupProductCatalogItem } from "src/app/entity-models/wholesaler-group-product-catalog-item.entity";

export class AvailableToOrderDialogViewModel extends BasicDialogViewModel {

    dialogButtonClick$ = new EventEmitter<"remove" | "save" | "close">()
    constructor(
        public catalogItem: WholesalerGroupProductCatalogItem,
        public groupMembers: WholesalerGroupEntryMember[],
        public canEdit: boolean,
        public dialogComplete: (remove: boolean) => void,
    ) {
        super();

        this.headerLeftText = `Available for Order — ${this.catalogItem.product.itemNumber} ${this.catalogItem.product.description}`;
        if (this.canEdit) {
            this.buttonLeftText = 'Remove Product From Group';
            this.buttonLeftFunction = () => this.dialogButtonClick$.emit("remove");
            this.buttonMiddleText = 'Cancel';
            this.buttonMiddleFunction = () => this.dialogButtonClick$.emit("close");
            this.buttonRightText = 'Save';
            this.buttonRightFunction = () => this.dialogButtonClick$.emit("save");
        } else {
            this.buttonLeftText = 'Close';
            this.buttonLeftFunction = () => this.dialogButtonClick$.emit("close");
        }
    }
}