import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { OrderListLineItemViewModel } from "../orders-list/order-list-line-item.viewmodel";
import { OrderSelectionViewmodel } from "../orders-list/order-selection.viewmodel";
import { RemoveRejectedOrderProductsViewlmodel } from "./order-edit-options.viewmodel";

@Component({
    selector: "app-order-edit-options",
    templateUrl: "./order-edit-options.component.html",
    styleUrls: ["./order-edit-options.component.css"]
})
export class OrderEditOptionsComponent {
    viewmodel = new RemoveRejectedOrderProductsViewlmodel();

    private _orderLineItems = new Array<OrderListLineItemViewModel>();
    @Input()
    get orderLineItems(): OrderListLineItemViewModel[] {
        return this._orderLineItems;
    }
    set orderLineItems(value: OrderListLineItemViewModel[]) {
        this._orderLineItems = value ?? new Array<OrderListLineItemViewModel>();
        this.viewmodel.initializeOrderLineItemData(this.orderLineItems);
    }

    private _orderSelectables = new Array<OrderSelectionViewmodel>();
    @Input()
    get orderSelectables(): OrderSelectionViewmodel[] {
        return this._orderSelectables;
    }
    set orderSelectables(value: OrderSelectionViewmodel[]) {
        this._orderSelectables = value ?? new Array<OrderSelectionViewmodel>();
        this.viewmodel.initializeOrderData(this.orderSelectables);
    }

    @Input() orderDate: moment.Moment;

    @Output()
    rowSelectionChange = new EventEmitter();

    onRowSelectionChange(): void {
        this.rowSelectionChange.emit();
    }
}
