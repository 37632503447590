import { Input } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import {
    faArrowDown,
    faArrowUp,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { VolumeDataService } from "../volume-data.service";

@Component({
    selector: "app-volume-data-card",
    templateUrl: "./volume-data-card.component.html",
    styleUrls: ["./volume-data-card.component.scss"]
})
export class VolumeDataCardComponent implements OnInit {
    faArrowDown: IconDefinition = faArrowDown;
    faArrowUp: IconDefinition = faArrowUp;

    @Input() showComparison: boolean = false;

    constructor(public volumeData: VolumeDataService) {}

    ngOnInit(): void {}
}
