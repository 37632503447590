import { Database, Collection, Table, IndexableType } from "dexie";
import * as _ from "lodash";

export class OfflineServicesHelper {

    /**
     * Performs an advanced multi part filter with sorting enabled. 
     * Each clause is a collection of primary keys based on the filter criteria.
     * The results are then intersected to find the common primary keys and then sorted by the sortIndex.
     * @param table - The table to filter on
     * @param sortIndex - The field to sort by
     * @param clauses - The list of clauses to filter by
     * @returns 
     */
    static async multiPartFilterWithSort<T>(table: Table<T, string>, sortIndex: string, clausesFn: tableCollectionsFn<T>) {
        const clauses = await Promise.resolve(clausesFn(table));

        const results = await Promise.all(clauses.map(clause => {
            if (!Array.isArray(clause)) {
                return clause.primaryKeys();
            }
            return Promise.all(clause.map((collection: Collection) => collection.primaryKeys()))
                .then((ids) => ids.reduce((arr, val) => arr.concat(val) , []));
        })).then(ids => _.intersection(...ids))

        return await table.where("id").anyOf(results).sortBy(sortIndex);
    }
}

type tableCollectionsFnResult<T> = (Collection<T, string> | Collection<T, string>[])[];
export interface tableCollectionsFn<T> {
    (table: Table<T, string>): Promise<tableCollectionsFnResult<T>> | tableCollectionsFnResult<T>;
}