<div #originalEasRetailReceipt *ngIf="easCashRetailViewmodels?.length > 0
    || easGratisRetailViewmodels?.length > 0
    || easExchangeOutRetailViewmodels?.length > 0
    || easExchangeInRetailViewmodels?.length > 0">
    <div class="center">
        ******Draft******Draft******Draft******Draft******Draft******Draft******
        ******Draft******Draft******Draft******Draft******Draft******Draft******
    </div>
    <div class="row">
        <div class="col center">
            {{ customerReceiptAddress?.addressName }}
        </div>
    </div>
    <div *ngIf="retailStateVaporLicense" class="row">
        <div class="col center">
            Retail State Vapor License # {{ retailStateVaporLicense?.licenseNumber }}
        </div>
    </div>
    <div class="row">
        <div class="col center">Retail State OTP License # {{retailStateOptLicense?.licenseNumber}}</div>
    </div>
    <div class="row">
        <div class="col center">
            {{ customerReceiptAddress?.addressFormatted }}
        </div>
    </div>
    <div *ngIf="customer.businessAddress?.county" class="row">
        <div class="col center">{{ customer.businessAddress?.county }}&nbsp;County</div>
    </div>
    <hr />
    <div *ngIf="easCashRetailViewmodels?.length > 0">
        <div class="row margin-right">
            <div class="col-5">
                <b>Cash Sales Item</b>
            </div>
            <div class="col-1">
                <b>Qty</b>
            </div>
            <div class="col-2">
                <b>Price</b>
            </div>
            <div class="col-2">
                <b>Discount</b>
            </div>
            <div class="col-2">
                <b>Total</b>
            </div>
        </div>
        <div class="row margin-right" *ngFor="let cashProduct of easCashRetailViewmodels">
            <div class="col-5">
                {{ cashProduct.product?.description }}
            </div>
            <div class="col-1">
                {{ cashProduct.quantity }}
            </div>
            <div class="col-2">
                {{ cashProduct.price | currency }}
            </div>
            <div class="col-2">
                {{ cashProduct.discount | currency }}
            </div>
            <div class="col-2">
                {{ cashProduct.total | currency }}
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col total-display">
                <b >Total: {{ stepperCallApplicationService.easCashDueNowTotal |
                    currency
                    }}</b>
            </div>
        </div>
        <div class="row">
            <div class="col larger">
                <b><u>Cash Due Now: {{
                        stepperCallApplicationService.easCashDueNowTotal |
                        currency }}</u></b>
            </div>
        </div>
        <br />
    </div>
    <div *ngIf="easGratisRetailViewmodels?.length > 0">
        <div class="row margin-right">
            <div class="col-5">
                <b>Promotional Goods</b>
            </div>
            <div class="col-2">
                <b>Qty</b>
            </div>
            <div class="col-2 ">
                <b>Price</b>
            </div>
            <div class="col-3">
                <b>Total</b>
            </div>
        </div>
        <div class="row margin-right" *ngFor="let gratisProduct of easGratisRetailViewmodels">
            <div class="col-5">
                {{ gratisProduct.product?.description }}
            </div>
            <div class="col-2">
                {{ gratisProduct.quantity }}
            </div>
            <div class="col-2">
                {{ gratisProduct.value | currency }}
            </div>
            <div class="col-3">
                {{ gratisProduct.total | currency }}
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col total-display">
                <b>Promotional Goods Total:
                    {{ stepperCallApplicationService.easGratisDueNowTotal | currency }}</b>
            </div>
        </div>
        <br />
    </div>
    <div *ngIf="easExchangeOutRetailViewmodels?.length > 0">
        <div class="row margin-right">
            <div class="col-5">
                <b>Exchange Out Item</b>
            </div>
            <div class="col-2">
                <b>Qty</b>
            </div>
            <div class="col-2">
                <b>Price</b>
            </div>
            <div class="col-3">
                <b>Total</b>
            </div>
        </div>
        <div class="row margin-right" *ngFor="let outProduct of easExchangeOutRetailViewmodels">
            <div class="col-5">
                {{ outProduct.product?.description }}
            </div>
            <div class="col-2">
                {{ outProduct.quantity }}
            </div>
            <div class="col-2">
                {{ outProduct.price | currency }}
            </div>
            <div class="col-3">
                {{ outProduct.total | currency }}
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col total-display">
                <b>Exchange Out Total:
                    {{ stepperCallApplicationService.easExchangeOutTotal | currency }}</b>
            </div>
        </div>
        <br />
    </div>
    <div *ngIf="easExchangeInRetailViewmodels?.length > 0">
        <div class="row margin-right">
            <div class="col-5">
                <b>Exchange In Item</b>
            </div>
            <div class="col-2">
                <b>Qty</b>
            </div>
            <div class="col-2">
                <b>Price</b>
            </div>
            <div class="col-3">
                <b>Total</b>
            </div>
        </div>
        <div class="row margin-right" *ngFor="let inProduct of easExchangeInRetailViewmodels">
            <div class="col-5">
                {{ inProduct.product?.description }}
            </div>
            <div class="col-2">
                {{ inProduct.quantity }}
            </div>
            <div class="col-2">
                {{ inProduct.price | currency }}
            </div>
            <div class="col-3">
                {{ inProduct.total | currency }}
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col total-display">
                <b>Exchange In Total: {{
                    stepperCallApplicationService.easExchangeInTotal
                    |
                    currency }}</b>
            </div>
        </div>
        <br />
    </div>
    <div class="center">
        ******Draft******Draft******Draft******Draft******Draft******Draft******
        ******Draft******Draft******Draft******Draft******Draft******Draft******
    </div>
</div>
