<div class="wrapper" *ngIf="viewmodel">
    <mat-card>
        <div class="card card-project-panel card-top mb-3">
            <div class="d-flex align-items-center card-header">
                <div class="mr-auto">
                    Zone {{ viewmodel.zone ? viewmodel.zone + " " : ""}}Recap
                </div>
                <div>
                    <button class="btn" (click)="viewmodel.print()">
                        <fa-icon [icon]="faPrint" class="text-white"></fa-icon>
                    </button>
                    <button class="btn" (click)="viewmodel.export()">
                        <fa-icon [icon]="faCloudDownloadAlt" class="text-white"></fa-icon>
                    </button>
                </div>
            </div>
            <div class="card-body un-scrollable p-0">
                <div class="d-flex p-3 justify-content-center align-items-center">
                    <div class="flex-grow-1">
                        <div class="mat-input-search">
                            <app-zrt-tree-view #zrtTree></app-zrt-tree-view>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <mat-form-field appearance="outline" color="accent" class="mat-input-search"
                            [formGroup]="viewmodel.dateForm">
                            <mat-label>Call On or After</mat-label>
                            <input #startDateInput matInput formControlName="startDate"
                                [matDatepicker]="afterPicker"
                                (dateChange)="viewmodel.onAddStartDateRefiner()"
                                (blur)="viewmodel.onAddStartDateRefiner($event)" />
                            <mat-datepicker touchUi #afterPicker></mat-datepicker>
                            <mat-datepicker-toggle matSuffix [for]="afterPicker">
                            </mat-datepicker-toggle>
                        </mat-form-field>
                        <mat-error *ngIf="
                                viewmodel.dateForm
                                    .get('startDate')
                                    .hasError('dateGreaterThanEndDate')
                            ">
                            Call on or after date can not be greater than the call on or before date.
                        </mat-error>
                    </div>
                    <div class="flex-grow-1">
                        <mat-form-field appearance="outline" color="accent" class="mat-input-search"
                            [formGroup]="viewmodel.dateForm">
                            <mat-label>Call On or Before</mat-label>
                            <input #endDateInput matInput formControlName="endDate"
                                [matDatepicker]="beforePicker"
                                (dateChange)="viewmodel.onAddEndDateRefiner()"
                                (blur)="viewmodel.onAddEndDateRefiner($event)" />
                            <mat-datepicker touchUi #beforePicker></mat-datepicker>
                            <mat-datepicker-toggle matSuffix [for]="beforePicker">
                            </mat-datepicker-toggle>
                        </mat-form-field>
                        <mat-error *ngIf="
                                viewmodel.dateForm
                                    .get('endDate')
                                    .hasError('dateLessThanStartDate')
                            ">
                            Call on or before date can not be less than the call on or after date.
                        </mat-error>
                    </div>
                    <div class="d-flex">
                        <button mat-raised-button (click)="viewmodel.reset()"
                            class="btn btn-secondary">RESET</button>
                    </div>
                </div>
            </div>
        </div>
        <div #recap>
            <div class="card card-project-panel mb-3">
                <div class="d-flex align-items-center card-header">
                    <div>
                        <div>
                            Results
                        </div>
                    </div>
                    <div class="h-center d-flex">
                        <div *ngIf="viewmodel.dateForm.controls.startDate.value
                            && viewmodel.dateForm.controls.endDate.value" class="mr-1">
                            Dates: {{viewmodel.dateForm.controls.startDate.value | date: viewmodel.momentDateFormat}}
                        </div>
                        <div *ngIf="viewmodel.dateForm.controls.startDate.value
                            && !viewmodel.dateForm.controls.endDate.value" class="mr-1">
                            Date: {{viewmodel.dateForm.controls.startDate.value | date: viewmodel.momentDateFormat}}
                        </div>
                        <div *ngIf="viewmodel.dateForm.controls.endDate.value">
                            thru {{viewmodel.dateForm.controls.endDate.value | date: viewmodel.momentDateFormat}}
                        </div>
                    </div>
                </div>
                <div class="card-body scrollable p-3">
                    <div class="center table-responsive">
                        <table #resultsTable class="table table-striped p-4 mb-1 results-wrapper">
                            <thead>
                                <tr>
                                    <th [ngClass]="{'pull-right': index != 0}" class="font-med-size-header" *ngFor="let header of viewmodel.resultsCallInformationHeaders let index = index">{{header}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td [ngClass]="{'pull-right': index != 0}"  *ngFor="let column of viewmodel.resultsDaysWorked let index = index">{{column}}</td>
                                </tr>
                                <tr>
                                    <td [ngClass]="{'pull-right': index != 0}"  *ngFor="let column of viewmodel.resultsRetailCalls let index = index">{{column}}</td>
                                </tr>
                                <tr>
                                    <td [ngClass]="{'pull-right': index != 0}"  *ngFor="let column of viewmodel.resultsCallAverageIncludingResetDays let index = index">{{column}}</td>
                                </tr>
                                <tr>
                                    <td [ngClass]="{'pull-right': index != 0}"  *ngFor="let column of viewmodel.resultsCallAverageExcludingResetDays let index = index">{{column}}</td>
                                </tr>
                                <tr>
                                    <td class="separator" [colSpan]="viewmodel.resultsCallInformationHeaders.length"></td>
                                </tr>
                                <tr>
                                    <td [ngClass]="{'pull-right': index != 0}"  *ngFor="let column of viewmodel.resultsTop300CalledOnOnce let index = index">{{column}}</td>
                                </tr>
                                <tr>
                                    <td [ngClass]="{'pull-right': index != 0}" *ngFor="let column of viewmodel.resultsTop300CalledOnTwiceOrMore let index = index">{{column}}</td>
                                </tr>
                                <tr>
                                    <td class="separator" [colSpan]="viewmodel.resultsCallInformationHeaders.length"></td>
                                </tr>
                                <tr>
                                    <th [ngClass]="{'pull-right': index != 0}" class="font-med-size-header" *ngFor="let column of viewmodel.resultsRetailAgreementsHeaders let index = index">{{column}}</th>
                                </tr>
                                <tr>
                                    <td [ngClass]="{'pull-right': index != 0}"  *ngFor="let column of viewmodel.resultsNew let index = index">{{column}}</td>
                                </tr>
                                <tr>
                                    <td [ngClass]="{'pull-right': index != 0}"  *ngFor="let column of viewmodel.resultsUnderContract let index = index">{{column}}</td>
                                </tr>
                                <tr>
                                    <td [ngClass]="{'pull-right': index != 0}"  *ngFor="let column of viewmodel.resultsCanceled let index = index">{{column}}</td>
                                </tr>
                                <tr>
                                    <td [ngClass]="{'pull-right': index != 0}"  *ngFor="let column of viewmodel.resultsDollarsPaidOut let index = index">{{column}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card card-project-panel mb-3">
                <div class="d-flex align-items-center card-header">
                    <div class="mr-auto">
                        <div>
                            Top Stores Called Upon with Product In Distribution (Based On Last Call)
                        </div>
                    </div>
                    <div class="d-flex">
                        <ng-container *ngIf="viewmodel.checkZmRoleOrHigher()">
                            <button class="btn" (click)="viewmodel.onOpenProductModal()">
                                <fa-icon [icon]="faPen" class="text-white"></fa-icon>
                            </button>
                        </ng-container>
                    </div>
                </div>
                <div class="card-body scrollable p-3">
                    <div class="center table-responsive">
                        <table #productsTable class="table table-striped p-4 mb-1 results-wrapper">
                            <thead>
                                <tr>
                                    <th [ngClass]="{'pull-right': index != 0}" class="font-med-size-header" *ngFor="let header of viewmodel.resultsProductsHeaders let index = index">{{header}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of viewmodel.zoneRecapProducts">
                                    <td>{{row.description}}</td>
                                    <td class="pull-right" *ngFor="let column of row.zoneRecapProductStoreCount">{{column.storeCount}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
</div>
