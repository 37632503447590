import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomerPx3RankDto, CustomerPx3RankImportDto, GenericRequestDto, GenericResponseDto, Px3IncentivePeriodDto, Px3RankDto, newSequentialId } from "shield.shared";
import { Px3IncentivePeriod } from "src/app/entity-models/px3-incentive-period.entity";
import { Px3Rank } from "src/app/entity-models/px3-rank.entity";
import { Px3ConverterService } from "../converter-services/px3-converter.service";
import { ConverterHelper } from "../converter-services/converter.helper";
import { CustomerPx3Rank } from "src/app/entity-models/customer-px3-rank.entity";
import { GenericConverter } from "../converter-services/generic-converter.service";
import { Px3IncentivePeriodConverter } from "../converter-services/px3-incentive-period-converter.service";
import { catchError } from "rxjs/operators";

@Injectable()
export class Px3OnlineService {

    constructor(
        private http: HttpClient,
        private converter: Px3ConverterService,
        private rankConverter: GenericConverter<CustomerPx3Rank, CustomerPx3RankDto>,
        private incentivePeriodConverter: Px3IncentivePeriodConverter,
    ) { }

    async getCurrentAndUpcomingPeriods(): Promise<Px3IncentivePeriod[]> {
        const response = await this.http
            .get<GenericResponseDto<Px3IncentivePeriodDto[]>>(`/api/px3-incentive-periods/current-upcoming`)
            .toPromise();

        var ranks = ConverterHelper.dtoToEntityArray(
            response,
            (r) => this.incentivePeriodConverter.dtoToEntity(r)
        );
        return ranks.values;
    }

    async getAll(): Promise<Px3Rank[]> {
        const response = await this.http
            .get<GenericResponseDto<Px3RankDto[]>>(`/api/px3Ranks/all`)
            .toPromise();

        var ranks = ConverterHelper.dtoToEntityArray(
            response,
            (r) => this.converter.px3RankDtotoPx3Rank(r)
        );
        return ranks.values;
    }

    async getById(id: string): Promise<Px3RankDto | undefined> {
        const response = await this.http
            .get<GenericResponseDto<Px3RankDto | undefined>>(`/api/px3Ranks/by-id/${id}`)
            .toPromise();

        var ranks = ConverterHelper.dtoToEntity(
            response,
            (r) => this.converter.px3RankDtotoPx3Rank(r)
        );
        return ranks.values;
    }

    async getCurrentIncentivePeriod(): Promise<Px3IncentivePeriod | undefined> {
        const response = await this.http
            .get<GenericResponseDto<Px3IncentivePeriodDto | undefined>>(`/api/px3-incentive-periods/incentive-period`)
            .toPromise();

        var ranks = ConverterHelper.dtoToEntity(
            response,
            (r) => this.incentivePeriodConverter.dtoToEntity(r)
        );
        return ranks.values;
    }

    async savePx3RankForCustomer(customerId: string, px3RankId?: string): Promise<void> {
        let url = `/api/customer-px3-ranks?customerId=${customerId}`;
        if (px3RankId) {
            url += `&px3RankId=${px3RankId}`;
        }
        await this.http
            .post<GenericResponseDto<void>>(url, {})
            .toPromise();
    }

    async bulkImport(importObj: CustomerPx3RankImportDto): Promise<number | HttpErrorResponse> {
        try {
            var result = await this.http.post<GenericResponseDto<void>>(
                '/api/customer-px3-ranks/bulk-import',
                importObj,
            ).toPromise();
            return result.count;
        } catch (e) {
            if (e instanceof HttpErrorResponse) {
                if (e.error?.message?.includes('Invalid data')) {
                    console.log(e.error.message);
                    return e;
                }
                throw e;
            }
        }
    }
}
