import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ContractTemplateDelineationService } from "./delineation-services/contract-template-delineation.service";
import { AccountOwnershipOfflineService } from "./offline-services/account-ownership-offline.service";
import { CallOfflineService } from "./offline-services/call-offline.service";
import { ContactOfflineService } from "./offline-services/contact-offline.service";
import { ContractPaymentOfflineService } from "./offline-services/contract-payment-offline.service";
import { ContractTemplateOfflineService } from "./offline-services/contract-template-offline.service";
import { CustomerContractOfflineService } from "./offline-services/customer-contract-offline.service";
import { CustomerOfflineService } from "./offline-services/customer-offline.service";
import { EmployeeOfflineService } from "./offline-services/employee-offline.service";
import { PictureOfflineService } from "./offline-services/picture-offline.service";
import { ProductOfflineService } from "./offline-services/product-offline.service";
import { ProjectOfflineService } from "./offline-services/project-offline-service";
import { ReceiptOfflineService } from "./offline-services/receipt-offline.service";
import { StateLicenseOfflineService } from "./offline-services/state-license-offline.service";
import { SurveyOfflineService } from "./offline-services/survey-offline.service";
import { SystemInformationOfflineService } from "./offline-services/system-information-offline.service";
import { TransactionOfflineService } from "./offline-services/transaction-offline.service";
import { TimeEntryOfflineService } from "./offline-services/time-entry-offline.service";
import { VolumeOfflineService } from "./offline-services/volume-offline.service";
import { WholesalerGroupMemberOfflineService } from "./offline-services/wholesaler-group-member-offline.service";
import { AccountOwnershipOnlineService } from "./online-services/account-ownership-online.service";
import { CallOnlineService } from "./online-services/call-online.service";
import { ContactOnlineService } from "./online-services/contact-online.service";
import { ContractPaymentOnlineService } from "./online-services/contract-payment-online.service";
import { ContractTemplateOnlineService } from "./online-services/contract-template-online.service";
import { CustomerContractOnlineService } from "./online-services/customer-contract-online.service";
import { CustomerOnlineService } from "./online-services/customer-online.service";
import { EmployeeOnlineService } from "./online-services/employee-online.service";
import { PictureOnlineService } from "./online-services/picture-online.service";
import { ProductOnlineService } from "./online-services/product-online.service";
import { ProjectOnlineService } from "./online-services/project-online.service";
import { ReceiptOnlineService } from "./online-services/receipt-online.service";
import { StateLicenseOnlineService } from "./online-services/state-license-onlne.service";
import { SurveyOnlineService } from "./online-services/survey-online.service";
import { SystemInformationOnlineService } from "./online-services/system-information-online.service";
import { TransactionOnlineService } from "./online-services/transaction-online.service";
import { TimeEntryOnlineService } from "./online-services/time-entry-online.service";
import { VolumeOnlineService } from "./online-services/volume-online.service";
import { WholesalerGroupMemberOnlineService } from "./online-services/wholesaler-group-member-online.service";
import { RouteOfflineService } from "./offline-services/route-offline.service";
import { RouteOnlineService } from "./online-services/route-online.service";
import { NotificationOfflineService } from "./offline-services/notification-offline.service";
import { WholesalerGroupProductCatalogItemOfflineService } from "./offline-services/wholesaler-group-product-catalog-item-offline.service";
import { WholesalerGroupProductCatalogItemOnlineService } from "./online-services/wholesaler-group-product-catalog-item-online.service";
import { StateOfflineService } from "./offline-services/state-offline.service";
import { StateOnlineService } from "./online-services/state-online.service";
import { TaxRateOfflineService } from "./offline-services/tax-rate-offline.service";
import { TaxRateOnlineSerivce } from "./online-services/tax-rate-online.service";
import { RegisteredUserOfflineService } from "./offline-services/registered-user-offline.service";
import { RegisteredUserOnlineService } from "./online-services/registered-user-online.service";
import { ReceiptSettingsOfflineService } from "./offline-services/receipt-settings-offline.service";
import { TimeEntryTypeOfflineService } from "./offline-services/time-entry-type-offline.service";
import { TimeEntryTypeOnlineService } from "./online-services/time-entry-type-online.service";
import { GratisOnlineService } from "./online-services/gratis-online.service";
import { GratisOfflineService } from "./offline-services/gratis-offline.service";
import { OrderOfflineService } from "./offline-services/order-offline.service";
import { OrderOnlineService } from "./online-services/order-online.service";
import { SyncWatcherService } from "./watcher-services/sync-watcher.service";
import { PingWatcherService } from "./watcher-services/ping-watcher.service";
import { NgswUpdateService } from "./ngsw-update-service";
import { ZoneRecapOfflineService } from "./offline-services/zone-recap-offline.service";
import { ZoneRecapOnlineService } from "./online-services/zone-recap-online.service";
import { NotificationOnlineService } from "./online-services/notification-online.service";
import { DailySummaryEntryOfflineService } from "./offline-services/daily-summary-entry-offline.service";
import { DailySummaryEntryOnlineService } from "./online-services/daily-summary-entry-online.service";
import { WholesalerGroupOnlineService } from "./online-services/wholesaler-group-online.service";
import { WholesalerGroupOfflineService } from "./offline-services/wholesaler-group-offline.service";
import { Px3RankOfflineService } from "./offline-services/px3-rank-offline.service";
import { GenericConverter } from "./converter-services/generic-converter.service";
import { Px3IncentivePeriodConverter } from "./converter-services/px3-incentive-period-converter.service";
import { Px3ConverterService } from "./converter-services/px3-converter.service";
import { Px3OnlineService } from "./online-services/px3-online.service";

@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
    ],
    providers: [
        AccountOwnershipOfflineService,
        AccountOwnershipOnlineService,
        CallOfflineService,
        CallOnlineService,
        ContactOfflineService,
        ContactOnlineService,
        ContractPaymentOfflineService,
        ContractPaymentOnlineService,
        ContractTemplateOfflineService,
        ContractTemplateOnlineService,
        ContractTemplateDelineationService,
        CustomerContractOfflineService,
        CustomerContractOnlineService,
        CustomerOfflineService,
        CustomerOnlineService,
        DailySummaryEntryOfflineService,
        DailySummaryEntryOnlineService,
        EmployeeOfflineService,
        EmployeeOnlineService,
        GratisOfflineService,
        GratisOnlineService,
        GenericConverter,
        OrderOfflineService,
        OrderOnlineService,
        NgswUpdateService,
        NotificationOfflineService,
        NotificationOnlineService,
        PictureOfflineService,
        PictureOnlineService,
        PingWatcherService,
        ProductOfflineService,
        ProductOnlineService,
        ProjectOfflineService,
        ProjectOnlineService,
        Px3ConverterService,
        Px3IncentivePeriodConverter,
        Px3OnlineService,
        Px3RankOfflineService,
        RegisteredUserOfflineService,
        RegisteredUserOnlineService,
        SyncWatcherService,
        StateLicenseOfflineService,
        StateLicenseOnlineService,
        StateOfflineService,
        StateOnlineService,
        SurveyOfflineService,
        SurveyOnlineService,
        SystemInformationOfflineService,
        SystemInformationOnlineService,
        ReceiptOfflineService,
        ReceiptOnlineService,
        ReceiptSettingsOfflineService,
        RouteOfflineService,
        RouteOnlineService,
        TaxRateOfflineService,
        TaxRateOnlineSerivce,
        TransactionOfflineService,
        TransactionOnlineService,
        TimeEntryOfflineService,
        TimeEntryOnlineService,
        TimeEntryTypeOfflineService,
        TimeEntryTypeOnlineService,
        VolumeOfflineService,
        VolumeOnlineService,
        WholesalerGroupOfflineService,
        WholesalerGroupOnlineService,
        WholesalerGroupMemberOfflineService,
        WholesalerGroupMemberOnlineService,
        WholesalerGroupProductCatalogItemOfflineService,
        WholesalerGroupProductCatalogItemOnlineService,
        ZoneRecapOfflineService,
        ZoneRecapOnlineService,
    ],
    exports: [
    ]
})
export class ServicesModule {}
