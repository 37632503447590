import { CustomerTypeEnum, GenericLookup } from "shield.shared";

export class ProjectBasicCustomer {
    customerId: string;
    name: string;
    address1: string | null;
    city: string | null;
    county: string | null;
    state: string | null;
    zip: string | null;
    customerType: GenericLookup<CustomerTypeEnum>;
    industryVolume: number | null;
    swisherVolume: number | null;
    lastCall: Date | null;
    zrt: string | null;
    phone: string | null;
    chain: string | null;
    isMsa: boolean | null;
    callsMade?: number | null;
    px3RankId?: string | null;
    isAssigned: boolean;
}
