<div [style.height]="height">
    <h2 class="text-teal border-bottom border-teal">
        Sku Share Breakdown
    </h2>
    <div class="skuContainer" [ngClass]="{ tall: variant === 'Tall' }">
        <div
            style="display: flex; flex-direction: column; width: 24rem; height: 100%"
            *ngFor="let sku of data"
        >
            <div *ngIf="!hasData" class="no-data-breakdown">No Data</div>
            <ng-container *ngIf="hasData">
                <div
                    style="display: flex; flex-direction: row; align-items: center;"
                >
                    <span 
                        style="font-weight: bold; display: inline-block; width: 8rem; padding-right: 1rem; text-align: end;"
                        >{{ sku.chartLabel }}</span
                    >
                    <svg *ngIf="hasData" viewBox="0 0 100 20">
                        <rect
                            x="0"
                            y="5"
                            width="100"
                            height="10"
                            style="fill:#bababa"
                        />
                        <rect
                            x="0"
                            y="5"
                            [attr.width]="sku.value"
                            height="10"
                            style="fill:#232323"
                        >
                            <animate
                                attributeName="width"
                                from="0"
                                [attr.to]="sku.value"
                                dur="0.5s"
                            />
                        </rect>
                        <text
                            x="5"
                            y="12"
                            style="fill:#FFFFFF"
                            font-size="6"
                            font-weight="bold"
                        >
                            {{ sku.value }}%
                        </text>
                    </svg>
                </div>
            </ng-container>
            <div class="legendContainer">
                <div class="legendItem">
                    <span
                        class="legendColor"
                        style="background-color: #232323"
                    ></span>
                    {{ sku.swisherLabel }}
                </div>
                <div class="legendItem">
                    <span
                        class="legendColor"
                        style="background-color: #bababa"
                    ></span>
                    {{ sku.genericLabel }}
                </div>
            </div>
        </div>
    </div>
</div>