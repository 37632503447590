import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CustomerMarker } from "src/app/entity-models/customer-marker.entity";
import { Route } from "src/app/entity-models/route.entity";

@Injectable()
export class RouteManagementService {
    private _route: Route = null;
    private _routeSubject: BehaviorSubject<Route> = new BehaviorSubject(
        this._route
    );

    get route(): Route {
        return this._route;
    }
    set route(value: Route) {
        this._route = value;
        this._routeSubject.next(value);
    }
    observableRoute: Observable<Route> = this._routeSubject.asObservable();

    private _routeCustomers: CustomerMarker[] = [];
    private _routeCustomersSubject: BehaviorSubject<CustomerMarker[]> = new BehaviorSubject(
        this._routeCustomers
    );

    get routeCustomers(): CustomerMarker[] {
        return this._routeCustomers;
    }
    set routeCustomers(value: CustomerMarker[]) {
        this._routeCustomers = value;
        this._routeCustomersSubject.next(value);
    }
    observableRouteCustomers: Observable<CustomerMarker[]> = this._routeCustomersSubject.asObservable();

    private _customerMarkers: CustomerMarker[] = [];
    private _customerMarkersSubject: BehaviorSubject<CustomerMarker[]> = new BehaviorSubject(
        this._customerMarkers
    );
    get customerMarkers(): CustomerMarker[] {
        return this._customerMarkers;
    }
    set customerMarkers(value: CustomerMarker[]) {
        this._customerMarkers = value;
        this._customerMarkersSubject.next(value);
    }
    observableCustomerMarkers: Observable<CustomerMarker[]> = this._customerMarkersSubject.asObservable();

    resetRoute(): void {
        this.route = null;
        this.routeCustomers = [];
    }
}
