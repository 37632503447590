import { Injectable } from "@angular/core";
import { AccountOwnershipDropDownDto, AccountOwnershipDto, AccountOwnershipHierarchyLevel, AccountOwnershipSyncCommand, SharedHelper } from "shield.shared";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { DataSyncQueueService } from "src/app/sync/data-sync-queue.service";
import { DatabaseService } from "../database.service";

@Injectable()
export class AccountOwnershipOfflineService {

    constructor(
        private dbService: DatabaseService,
        private dataSyncQueueService: DataSyncQueueService
    ){}

    async getByOwnerCode(code: string): Promise<AccountOwnership[]> {
        if (!code) return;

        const rtn = new Array<AccountOwnership>();

        do {
            const ao = await this.dbService.accountOwnerships
                .where("ownerCode")
                .equals(code.trim())
                .first();
            if (ao) {
                rtn.push(ao);
            }
            code = ao?.parentOwnerCode;
        } while (!!code);

        return rtn;
    }

    async getUnprocessedByOwnerCode(code: string): Promise<AccountOwnership[]> {
        if (!code) return;

        const rtn = new Array<AccountOwnership>();

        do {
            const ao = await this.dbService.accountOwnerships
                .where("ownerCode")
                .equals(code.trim())
                .first();
            if (ao && ao.hasServerProcessed == 0) {
                rtn.push(ao);
            }
            code = ao?.parentOwnerCode;
        } while (!!code);

        return rtn;
    }

    async getAllInDropDown(): Promise<AccountOwnership[]> {
        const rtn = new Array<AccountOwnership>();
        const accountOwnerships = await this.dbService.accountOwnerships.toArray();
        const accountOwnershipsMap = SharedHelper.groupBy(accountOwnerships.filter((ao) => !!ao.parentAccountOwnershipId), (a) => a.parentOwnerCode);
        if (accountOwnerships) {

            const parents = accountOwnerships.filter((ao) => !ao.parentAccountOwnershipId);

            for (const parent of parents) {
                parent.children = (accountOwnershipsMap.get(parent.ownerCode) ?? []).map((child) => {
                    child.children = (accountOwnershipsMap.get(child.ownerCode) ?? []).map((grandChild) => {
                        grandChild.children = [];
                        return grandChild;
                    });
                    return child;
                });
                rtn.push(parent);
            }
            return rtn;
        }
    }

    async getAll(): Promise<AccountOwnership[]> {

        return await this.dbService.accountOwnerships.toArray();
    }

    async save(ao: AccountOwnership): Promise<undefined> {
        if (ao) {
            ao.hasServerProcessed = 0;
            this.dbService.accountOwnerships.put(ao);

            await this.dataSyncQueueService.enqueue(new AccountOwnershipSyncCommand(ao.id));
        }

        return;
    }
}
