import { GratisAssignedTransactionDto, GratisAssignedTransactionLineItemDto } from "shield.shared";

import { GratisAssignedTransactionLineItem } from "src/app/entity-models/gratis-assigned-transaction-line-item.entity";
import { GratisAssignedTransaction } from "src/app/entity-models/gratis-assigned-transactions.entity";
import { GratisAssignedTransactionLineitemConverterService } from "./gratis-assigned-transaction-lineitem-converter.service";

export class GratisAssignedTransactionConverterService {
    static dtoToEntity(
        dto: GratisAssignedTransactionDto
    ): GratisAssignedTransaction {
        const entity = new GratisAssignedTransaction();

        entity.id = dto.id;
        entity.transactionId = dto.transactionId;
        entity.callId = dto.callId;
        entity.callDate = new Date(dto.callDate);
        entity.callClosingNotes = dto.callClosingNotes;
        entity.manualGratisId = dto.manualGratisId;
        entity.customerId = dto.customerId;
        entity.externalSyncId = dto.externalSyncId;
        entity.description = dto.description;
        entity.customerName = dto.customerName;
        entity.customerAddress1 = dto.customerAddress1;
        entity.customerAddress2 = dto.customerAddress2;
        entity.customerCity = dto.customerCity;
        entity.customerState = dto.customerState;
        entity.customerZip = dto.customerZip;
        entity.customerCounty = dto.customerCounty;
        entity.receiptNumber = dto.receiptNumber;
        entity.salesTotal = dto.salesTotal;
        entity.netTotal = dto.netTotal;
        entity.gratisTotal = dto.gratisTotal;
        entity.gratisPercentage = dto.gratisPercentage;
        entity.carryForwardGratisId = dto.carryForwardGratisId;
        entity.carryForwardGratisNumber = dto.carryForwardGratisNumber;
        entity.contractPaymentGratisId = dto.contractPaymentGratisId;
        entity.createdUtcDateTime = dto.createdUtcDateTime
            ? new Date(dto.createdUtcDateTime)
            : null;
        entity.createdUserId = dto.createdUserId;
        entity.createdUserZrt = dto.createdUserZrt;
        entity.createdUserFullName = dto.createdUserFullName;
        entity.modifiedUtcDateTime = dto.modifiedUtcDateTime
            ? new Date(dto.modifiedUtcDateTime)
            : null;
        entity.modifiedUserId = dto.modifiedUserId;
        entity.modifiedUserZrt = dto.modifiedUserZrt;
        entity.modifiedUserFullName = dto.modifiedUserFullName;

        entity.gratisAssignedTransactionLineItems = (
            dto.gratisAssignedTransactionLineItems ??
            new Array<GratisAssignedTransactionLineItemDto>()
        ).map((dto) =>
            GratisAssignedTransactionLineitemConverterService.dtoToEntity(
                dto,
                entity.id
            )
        );

        return entity;
    }

    static entityToDto(
        entity: GratisAssignedTransaction
    ): GratisAssignedTransactionDto {
        const dto = new GratisAssignedTransactionDto();

        dto.id = entity.id;
        dto.transactionId = entity.transactionId;
        dto.callId = entity.callId;
        dto.callDate = entity.callDate.toISOString();
        dto.manualGratisId = entity.manualGratisId;
        dto.customerId = entity.customerId;
        dto.externalSyncId = entity.externalSyncId;
        dto.description = entity.description;
        dto.customerName = entity.customerName;
        dto.customerAddress1 = entity.customerAddress1;
        dto.customerAddress2 = entity.customerAddress2;
        dto.customerCity = entity.customerCity;
        dto.customerState = entity.customerState;
        dto.customerZip = entity.customerZip;
        dto.customerCounty = entity.customerCounty;
        dto.receiptNumber = entity.receiptNumber;
        dto.salesTotal = entity.salesTotal;
        dto.netTotal = entity.netTotal;
        dto.gratisTotal = entity.gratisTotal;
        dto.gratisPercentage = entity.gratisPercentage;
        dto.carryForwardGratisId = entity.carryForwardGratisId;
        dto.carryForwardGratisNumber = entity.carryForwardGratisNumber;
        dto.contractPaymentGratisId = entity.contractPaymentGratisId;
        dto.createdUtcDateTime = entity.createdUtcDateTime
            ? entity.createdUtcDateTime.toISOString()
            : null;
        dto.createdUserId = entity.createdUserId;
        dto.createdUserZrt = entity.createdUserZrt;
        dto.createdUserFullName = entity.createdUserFullName;
        dto.modifiedUtcDateTime = entity.modifiedUtcDateTime
            ? entity.modifiedUtcDateTime.toISOString()
            : null;
        dto.modifiedUserId = entity.modifiedUserId;
        dto.modifiedUserZrt = entity.modifiedUserZrt;
        dto.modifiedUserFullName = entity.modifiedUserFullName;

        dto.gratisAssignedTransactionLineItems = (
            entity.gratisAssignedTransactionLineItems ??
            new Array<GratisAssignedTransactionLineItem>()
        ).map((entity) =>
            GratisAssignedTransactionLineitemConverterService.entityToDto(
                entity
            )
        );

        return dto;
    }
}
