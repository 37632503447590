<div #myFilter>
    <div class="mt-3 mb-3 w-100">
        <angular2-multiselect #projectSelect
            [data]="viewmodel.projects"
            [(ngModel)]="viewmodel.selectedProjects"
            [settings]="viewmodel.projectsDropdownSettings"
            (onDeSelectAll)="closedProjectSelect()"
            (onClose)="closedProjectSelect()"
            (onOpen)="openedProjectSelect()">
        </angular2-multiselect>
    </div>
    <div class="mt-3 mb-3 w-100">
        <angular2-multiselect #wholesalerSelect
            [data]="viewmodel.wholesalers"
            [(ngModel)]="viewmodel.selectedWholesalers"
            [settings]="viewmodel.wholesalersDropdownSettings"
            (onDeSelectAll)="closedWholesalerSelect()"
            (onClose)="closedWholesalerSelect()"
            (onOpen)="openedWholesalerSelect()">
        </angular2-multiselect>
    </div>
    <div class="mt-3 mb-3 w-100">
        <angular2-multiselect #chainSelect
            [data]="viewmodel.chains"
            [(ngModel)]="viewmodel.selectedChains"
            [settings]="viewmodel.chainsDropdownSettings"
            (onDeSelectAll)="closedChainSelect()"
            (onClose)="closedChainSelect()"
            (onOpen)="openedChainSelect()">
        </angular2-multiselect>
    </div>
    <div class="mt-3 mb-3 w-100">
        <angular2-multiselect #productSelect
            [data]="viewmodel.products"
            [(ngModel)]="viewmodel.selectedProducts"
            [settings]="viewmodel.productsDropdownSettings"
            (onDeSelectAll)="closedProductSelect()"
            (onClose)="closedProductSelect()"
            (onOpen)="openedProductSelect()">
        </angular2-multiselect>
    </div>
    <div class="mt-3 mb-3 w-100">
        <angular2-multiselect #productGroupSelect
            [data]="viewmodel.productGroups"
            [(ngModel)]="viewmodel.selectedProductGroups"
            [settings]="viewmodel.productGroupsDropdownSettings"
            (onDeSelectAll)="closedProductGroupSelect()"
            (onClose)="closedProductGroupSelect()"
            (onOpen)="openedProductGroupSelect()">
        </angular2-multiselect>
    </div>
</div>
