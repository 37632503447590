import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { OrderLineItem } from "src/app/entity-models/order-line-item.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { OrderListLineItemViewModel } from "../orders-list/order-list-line-item.viewmodel";
import { OrderSelectionViewmodel } from "../orders-list/order-selection.viewmodel";

export class RemoveRejectedOrderProductsViewlmodel {
    activeRefiners: Refiner[] = [];
    columnDef: ColumnDef[];
    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    gridData = new Array<GridData>();
    gridHeight = "25vh";
    gridWidth = "98%";
    itemsRendedInViewPort: number = 4;
    isEnabledExpandedDetails = false;
    isSortable = false;

    onHeaderSelectionChanged(event: boolean): void {
        for (const item of this.dataSource.data) {
            item.data.isSelected = event;
        }
    }

    initializeOrderData(orders: OrderSelectionViewmodel[]): void {
        this.columnDef = [
            {
                headerName: "",
                dataPropertyName: "isSelected",
                isAvailable: true,
                isSelected: false,
                isSelectable: true,
                columnClass: "w-25"
            },
            {
                headerName: "OrderId",
                dataPropertyName: "id",
                isAvailable: true,
                isSelected: false,
                isSelectable: false
            },
            {
                headerName: "Customer",
                dataPropertyName: "customerName",
                isAvailable: true,
                isSelected: false,
                isSelectable: false
            },
            {
                headerName: "Wholesaler",
                dataPropertyName: "wholesalerName",
                isAvailable: true,
                isSelected: false,
                isSelectable: false
            },
        ];
        this.setDataSource<OrderSelectionViewmodel>(orders);
    }

    initializeOrderLineItemData(orderLineItems: OrderListLineItemViewModel[]): void {

        this.columnDef = [
            {
                headerName: "",
                dataPropertyName: "isSelected",
                isAvailable: true,
                isSelected: false,
                isSelectable: true,
                columnClass: "w-25"
            },
            {
                headerName: "Product Description",
                dataPropertyName: "productDescription",
                isAvailable: true
            },
        ];

        this.setDataSource<OrderListLineItemViewModel>(orderLineItems);
    }

    setDataSource<T>(data: T[]): void {
        const gridData = new Array<GridData>();
        for (const dataItem of data) {
            const gridItem: GridData = new GridData();
            gridItem.data = dataItem;
            gridItem.index = data.indexOf(dataItem);
            gridData.push(gridItem);
        }

        this.gridData = gridData;
        this.dataSource = new TableVirtualScrollDataSource(
                this.gridData
            );
    }


}
