<div class="card m-0 h-100">
    <div class="card-header">
        Distribution
    </div>
    <div class="card-body">
        <div class="fixed-table-container">
            <table class="table table-striped table-hover mb-0">
                <thead>
                <tr>
                    <th [style.top]="inverseOfTranslation" scope="col" id="productName" (click)="onSortBy($event)"
                        [ngClass]="{'is-being-filtered': sortBy?.name === 'productName'}">
                        Product
                        <fa-icon *ngIf="sortBy?.name === 'productName' && sortBy?.icon === 'north'" [icon]="faArrowUp"></fa-icon>
                        <fa-icon *ngIf="sortBy?.name === 'productName' && sortBy?.icon === 'south'" [icon]="faArrowDown"></fa-icon>
                    </th>
                    <th [style.top]="inverseOfTranslation" scope="col" id="intro" (click)="onSortBy($event)"
                        [ngClass]="{'is-being-filtered': sortBy?.name === 'intro'}">
                        Intro
                        <fa-icon *ngIf="sortBy?.name === 'intro' && sortBy?.icon === 'north'" [icon]="faArrowUp"></fa-icon>
                        <fa-icon *ngIf="sortBy?.name === 'intro' && sortBy?.icon === 'south'" [icon]="faArrowDown"></fa-icon>
                    </th>
                    <th [style.top]="inverseOfTranslation" scope="col" id="oos" (click)="onSortBy($event)"
                        [ngClass]="{'is-being-filtered': sortBy?.name === 'oos'}">
                        Oos
                        <fa-icon *ngIf="sortBy?.name === 'oos' && sortBy?.icon === 'north'" [icon]="faArrowUp"></fa-icon>
                        <fa-icon *ngIf="sortBy?.name === 'oos' && sortBy?.icon === 'south'" [icon]="faArrowDown"></fa-icon>
                    </th>
                    <th [style.top]="inverseOfTranslation" scope="col" id="cos" (click)="onSortBy($event)"
                        [ngClass]="{'is-being-filtered': sortBy?.name === 'cos'}">
                        COoS
                        <fa-icon *ngIf="sortBy?.name === 'cos' && sortBy?.icon === 'north'" [icon]="faArrowUp"></fa-icon>
                        <fa-icon *ngIf="sortBy?.name === 'cos' && sortBy?.icon === 'south'" [icon]="faArrowDown"></fa-icon>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let vm of volumeDistribution.inDistProducts">
                    <td>{{ vm.productName }}</td>
                    <td>{{ vm.intro ? "Y" : "N" }}</td>
                    <td>{{ vm.oos ? "Y" : "N" }}</td>
                    <td>{{ vm.cos ? "Y" : "N" }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
