<div>
    <div class="card border shadow m-3">
        <div class="card-header d-flex justify-content-between align-items-center">
            <div class="flex">
                Information
                <div class="actionHover" (click)="setMode()">
                    <fa-icon class="actionHover" [icon]="faPen" class="ml-2 text-white" *ngIf="viewMode"></fa-icon>
                </div>
            </div>
            <div *ngIf="chainHeadquarterViewmodel?.customer?.lastEdited && chainHeadquarterViewmodel?.customer?.lastEditedBy"
                class="text-white">
                Last Edited by: {{chainHeadquarterViewmodel?.customer?.lastEditedBy}} on
                {{chainHeadquarterViewmodel?.customer?.lastEdited | date: dateFormat}}
            </div>
        </div>
        <div class="card-body">
            <div *ngIf="chainHeadquarterViewmodel.customer" class="row row-cols-3 pb-2 ml-5 mr-5">
                <div class="col-4">
                    <div class="mb-4 pb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="text-teal m-0">CHAIN HQ INFO</h2>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Customer ID</p>
                            <b>{{chainHeadquarterViewmodel.customer.customerNumber}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">MSA Store</p>
                            <b>{{chainHeadquarterViewmodel.customer.isMsa ? 'Yes' : 'No'}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Status</p>
                            <b *ngIf="viewMode">{{chainHeadquarterViewmodel.customer.isActive ? 'Active' :
                                'Inactive'}}</b>
                            <mat-slide-toggle *ngIf="!viewMode" color="accent"
                                [(ngModel)]="chainHeadquarterViewmodel.customer.isActive">
                                {{chainHeadquarterViewmodel.customer.isActive ? "Active" : "Inactive"}}
                            </mat-slide-toggle>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Geo Area</p>
                            <b>{{chainHeadquarterViewmodel.displayZrt}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Created</p>
                            <b>{{chainHeadquarterViewmodel.customer.dateCreated | date: dateTimeFormat}}</b>
                        </div>
                        <div *ngIf="chainHeadquarterViewmodel.customer.deactivatedDate"
                            class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Deactivated</p>
                            <b>{{chainHeadquarterViewmodel.customer.deactivatedDate | date: dateTimeFormat}}</b>
                        </div>
                        <div *ngIf="chainHeadquarterViewmodel.customer.reactivatedDate &&
                            chainHeadquarterViewmodel.customer.reactivatedDate > chainHeadquarterViewmodel.customer.deactivatedDate"
                            class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Reactivated</p>
                            <b>{{chainHeadquarterViewmodel.customer.reactivatedDate | date: dateTimeFormat}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Program</p>
                            <p class="info-text mb-0" *ngIf="viewMode && !chainHeadquarterViewmodel.customer.program">No
                                program selected</p>
                            <p class="info-text mb-0" *ngIf="viewMode && chainHeadquarterViewmodel.customer.program">
                                {{chainHeadquarterViewmodel.customer.program}}</p>
                            <mat-form-field *ngIf="!viewMode" appearance="outline" color="accent">
                                <mat-label>Program</mat-label>
                                <mat-select [(ngModel)]="chainHeadquarterViewmodel.customer.program">
                                    <mat-option [value]="">
                                    </mat-option>
                                    <mat-option [value]="'PIP'">
                                        PIP
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Store Count</p>
                            <b></b>
                        </div>
                        <div class="d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Phone</p>
                            <b *ngIf="viewMode">{{formattedPhone}}</b>
                            <mat-form-field *ngIf="!viewMode" appearance="outline" color="accent">
                                <mat-label id="phone-number">Phone Number</mat-label>
                                <input type="text" [formControl]="phoneFormControl" [value]="
                                chainHeadquarterViewmodel.customer.phone
                                    " placeholder="Ex. (555) 555-5555" [mask]="'(000) 000-0000'" matInput />
                                <mat-error *ngIf="phoneFormControl.hasError('pattern')">
                                    Please enter a valid 10 digit phone number!
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="mb-4 pb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="text-teal m-0">OWNERSHIP INFO</h2>
                        </div>
                        <div *ngIf="chainHeadquarterViewmodel.ultimateCustomer">
                            <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                                <p class="mb-0">Chain Ultimate</p>
                                <b>{{chainHeadquarterViewmodel.ultimateCustomer?.name}}</b>
                            </div>
                            <div *ngIf="chainHeadquarterViewmodel.ultimateCustomerRep || !viewMode"
                                class="border-bottom d-flex justify-content-between align-items-center py-2">
                                <p class="mb-0">Rep</p>
                                <div *ngIf="!viewMode; else viewUltimateRep" class="w-50">
                                    <app-employee-dropdown [singleSelection]="true" [zrtOnly]="true"
                                    [preselectedIds]="chainHeadquarterViewmodel.ultimateOwnership?.assignedEmployeeId ? [chainHeadquarterViewmodel.ultimateOwnership?.assignedEmployeeId] : null"
                                    (selectionChange)="onUltimateRepChange($event)"
                                    ></app-employee-dropdown>
                                </div>
                                <ng-template #viewUltimateRep>
                                    <b>{{chainHeadquarterViewmodel.ultimateCustomerRep}}</b>
                                </ng-template>
                            </div>
                        </div>
                        <div *ngIf="chainHeadquarterViewmodel.immediateCustomer">
                            <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                                <p class="mb-0">Chain Immediate</p>
                                <b>{{chainHeadquarterViewmodel.immediateCustomer?.name}}</b>
                            </div>
                            <div *ngIf="chainHeadquarterViewmodel.immediateCustomerRep || !viewMode"
                                class="border-bottom d-flex justify-content-between align-items-center py-2">
                                <p class="mb-0">Rep</p>
                                <div *ngIf="!viewMode; else viewImmediateRep" class="w-50">
                                    <app-employee-dropdown [singleSelection]="true" [zrtOnly]="true"
                                    [preselectedIds]="chainHeadquarterViewmodel.immediateOwnership?.assignedEmployeeId ? [chainHeadquarterViewmodel.immediateOwnership?.assignedEmployeeId] : null"
                                    (selectionChange)="onImmediateRepChange($event)"
                                    ></app-employee-dropdown>
                                </div>
                                <ng-template #viewImmediateRep>
                                    <b>{{chainHeadquarterViewmodel.immediateCustomerRep}}</b>
                                </ng-template>
                            </div>
                        </div>
                        <div *ngIf="chainHeadquarterViewmodel.ownerCustomer">
                            <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                                <p class="mb-0">Chain Owner</p>
                                <b>{{chainHeadquarterViewmodel.ownerCustomer?.name}}</b>
                            </div>
                            <div *ngIf="chainHeadquarterViewmodel?.ownerCustomerRep || !viewMode"
                                class="d-flex justify-content-between align-items-center py-2">
                                <p class="mb-0">Rep</p>
                                <div *ngIf="!viewMode; else viewOwnerRep" class="w-50">
                                    <app-employee-dropdown [singleSelection]="true" [zrtOnly]="true"
                                    [preselectedIds]="chainHeadquarterViewmodel.ownerOwnership?.assignedEmployeeId ? [chainHeadquarterViewmodel.ownerOwnership?.assignedEmployeeId] : null"
                                    (selectionChange)="onOwnerRepChange($event)"
                                    ></app-employee-dropdown>
                                </div>
                                <ng-template #viewOwnerRep>
                                    <b>{{chainHeadquarterViewmodel.ownerCustomerRep}}</b>
                                </ng-template>
                            </div>
                        </div>
                        <div class="mt-4">
                            <h2 class="text-teal">CHAIN COMMENTS</h2>
                            <p class="info-text" *ngIf="viewMode && !chainHeadquarterViewmodel?.customer?.comments">
                                There are currently no comments.</p>
                            <p class="info-text" *ngIf="viewMode  && chainHeadquarterViewmodel?.customer?.comments">
                                {{chainHeadquarterViewmodel.customer.comments}}</p>
                            <mat-form-field *ngIf="!viewMode" appearance="outline" color="accent" class="w-100">
                                <mat-label>Store Comments</mat-label>
                                <textarea matInput placeholder="Enter Chain Comments.." maxlength="4000"
                                    [(ngModel)]="chainHeadquarterViewmodel.customer.comments"></textarea>
                                <mat-hint align="end">{{
                                    chainHeadquarterViewmodel.customer.comments
                                    ?.length || 0
                                    }}/4000</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="mb-4 pb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="text-teal m-0 pb-2">BUSINESS ADDRESS</h2>
                        </div>
                        <div *ngIf="viewMode">
                            <b>{{chainHeadquarterViewmodel?.customer?.businessAddress?.name}}</b>
                            <p class="mb-0">{{chainHeadquarterViewmodel?.customer?.businessAddress?.address1}}</p>
                            <p class="mb-0">{{chainHeadquarterViewmodel?.customer?.businessAddress?.address2}}</p>
                            <p class="mb-0">{{chainHeadquarterViewmodel?.customer?.businessAddress?.city}}</p>
                            <p class="mb-0">{{chainHeadquarterViewmodel?.customer?.businessAddress?.state}},
                                {{chainHeadquarterViewmodel?.customer?.businessAddress?.zip}}</p>
                        </div>

                        <div *ngIf="!viewMode">
                            <div>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>Business/Company Name</mat-label>
                                    <input matInput
                                        [(ngModel)]="chainHeadquarterViewmodel?.customer?.businessAddress.name"
                                        placeholder="Wholesale Name" maxlength="100">
                                    <mat-hint align="end">{{
                                        chainHeadquarterViewmodel?.customer?.businessAddress?.name
                                        ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>Address 1</mat-label>
                                    <input matInput
                                        [(ngModel)]="chainHeadquarterViewmodel?.customer?.businessAddress.address1"
                                        placeholder="Address 1" maxlength="100">
                                    <mat-hint align="end">{{
                                        chainHeadquarterViewmodel?.customer?.businessAddress?.address1
                                        ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>Address 2</mat-label>
                                    <input matInput
                                        [(ngModel)]="chainHeadquarterViewmodel?.customer?.businessAddress.address2"
                                        placeholder="Address 2" maxlength="100">
                                    <mat-hint align="end">{{
                                        chainHeadquarterViewmodel?.customer?.businessAddress?.address2
                                        ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="d-flex justify-content-between">
                                <mat-form-field appearance="outline" color="accent" class="w-100 mr-1">
                                    <mat-label>City</mat-label>
                                    <input matInput
                                        [(ngModel)]="chainHeadquarterViewmodel?.customer?.businessAddress.city"
                                        placeholder="City" maxlength="100">
                                    <mat-hint align="end">{{
                                        chainHeadquarterViewmodel?.customer?.businessAddress?.city
                                        ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="accent" class="w-100 mr-1">
                                    <mat-label>State</mat-label>
                                    <mat-select [(value)]="chainHeadquarterViewmodel?.customer?.businessAddress.state">
                                        <mat-option *ngFor="let vm of states" [value]="vm.shortCode">
                                            {{vm.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="accent" class="w-25">
                                    <mat-label>Zip Code</mat-label>
                                    <input matInput
                                        [(ngModel)]="chainHeadquarterViewmodel?.customer?.businessAddress.zip"
                                        placeholder="Zip" maxlength="9" mask="999999999" digitOnly>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mb-4 pb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="text-teal m-0 pb-2">DRIVING/DBA ADDRESS</h2>
                        </div>
                        <div *ngIf="viewMode">
                            <ng-container
                                *ngIf="!chainHeadquarterViewmodel?.customer?.dbaAddress?.name &&
                                    !chainHeadquarterViewmodel?.customer?.dbaAddress?.address1 &&
                                    !chainHeadquarterViewmodel?.customer?.dbaAddress?.city &&
                                    !chainHeadquarterViewmodel?.customer?.dbaAddress?.state &&
                                    !chainHeadquarterViewmodel?.customer?.dbaAddress?.zip">
                                N/A
                            </ng-container>
                            <b>{{chainHeadquarterViewmodel?.customer?.dbaAddress?.name}}</b>
                            <p class="mb-0">{{chainHeadquarterViewmodel?.customer?.dbaAddress?.address1}}</p>
                            <p class="mb-0">{{chainHeadquarterViewmodel?.customer?.dbaAddress?.address2}}</p>
                            <p class="mb-0">{{chainHeadquarterViewmodel?.customer?.dbaAddress?.city}}</p>
                            <p class="mb-0" *ngIf="chainHeadquarterViewmodel?.customer?.dbaAddress?.state
                                                && chainHeadquarterViewmodel?.customer?.dbaAddress?.zip">
                                {{chainHeadquarterViewmodel.customer.dbaAddress.state}},
                                {{chainHeadquarterViewmodel.customer.dbaAddress.zip}}
                            </p>
                        </div>

                        <div *ngIf="!viewMode">
                            <div>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>Business/Company Name</mat-label>
                                    <input matInput [(ngModel)]="chainHeadquarterViewmodel?.customer?.dbaAddress.name"
                                        placeholder="Wholesale Name" maxlength="100">
                                    <mat-hint align="end">{{
                                        chainHeadquarterViewmodel?.customer?.dbaAddress?.name
                                        ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>Address 1</mat-label>
                                    <input matInput
                                        [(ngModel)]="chainHeadquarterViewmodel?.customer?.dbaAddress.address1"
                                        placeholder="Address 1" maxlength="100">
                                    <mat-hint align="end">{{
                                        chainHeadquarterViewmodel?.customer?.dbaAddress?.address1
                                        ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>Address 2</mat-label>
                                    <input matInput
                                        [(ngModel)]="chainHeadquarterViewmodel?.customer?.dbaAddress.address2"
                                        placeholder="Address 2" maxlength="100">
                                    <mat-hint align="end">{{
                                        chainHeadquarterViewmodel?.customer?.dbaAddress?.address2
                                        ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="d-flex justify-content-between">
                                <mat-form-field appearance="outline" color="accent" class="w-100 mr-1">
                                    <mat-label>City</mat-label>
                                    <input matInput [(ngModel)]="chainHeadquarterViewmodel?.customer?.dbaAddress.city"
                                        placeholder="City" maxlength="100">
                                    <mat-hint align="end">{{
                                        chainHeadquarterViewmodel?.customer?.dbaAddress?.city
                                        ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="accent" class="w-100 mr-1">
                                    <mat-label>State</mat-label>
                                    <mat-select [(value)]="chainHeadquarterViewmodel?.customer?.dbaAddress.state">
                                        <mat-option *ngFor="let vm of states" [value]="vm.shortCode">
                                            {{vm.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="accent" class="w-25">
                                    <mat-label>Zip Code</mat-label>
                                    <input matInput [(ngModel)]="chainHeadquarterViewmodel?.customer?.dbaAddress.zip"
                                        placeholder="Zip" maxlength="9" mask="999999999" digitOnly>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer bg-m-gray">
                <div class="d-flex justify-content-end align-items-center">
                    <button class="btn btn-lg btn-secondary mr-2 pl-5 pr-5" *ngIf="viewMode" routerLink="../profile">
                        Cancel
                    </button>
                    <button appThrottleClick class="btn btn-lg btn-secondary mr-2 pl-5 pr-5" *ngIf="!viewMode" (throttledClick)="openDialog()">
                        Cancel
                    </button>
                    <button class="btn btn-lg btn-primary ml-2 pl-5 pr-5" (click)="setMode()" *ngIf="viewMode">
                        <fa-icon [icon]="faPen" class="mr-2 text-white"></fa-icon>
                        Edit
                    </button>
                    <button appThrottleClick class="btn btn-lg btn-primary ml-2 pl-5 pr-5" (throttledClick)="save()" *ngIf="!viewMode">
                        <fa-icon [icon]="faSave" class="mr-2 text-white"></fa-icon>
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
