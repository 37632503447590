import { newSequentialId } from "shield.shared";

import { GratisApproval } from "./gratis-approval.entity";
import { GratisAssignedTransaction } from "./gratis-assigned-transactions.entity";
import { GratisProductRequestedDetail as GratisProductRequestedDetail } from "./gratis-product-requested-detail.entity";
import { GratisProductShippedDetail } from "./gratis-product-shipped-detail.entity";
export class Gratis {
    id: string;
    employeeId!: string;
    gratisNumber!: string;
    gratisStatusId!: number;
    purpose!: string;
    daysWaiting: number | null;
    neededByDate: Date | null;
    startDate: Date | null;
    endDate: Date | null;
    availableAmount: number | null;
    requestedAmount: number | null;
    reimbursedAmount: number | null;
    manualGratisAmount: number | null;
    carryForwardAmount: number | null;
    employeeApprovalStatusId: number | null;
    employeeApprovalStatusDate: Date | null;
    regionalEmployeeApprovalEmployeeId: string | null;
    regionalEmployeeApprovalStatusId: number | null;
    regionalEmployeeApprovalStatusDate: Date | null;
    regionalEmployeeApprovalComment: string | null;
    zoneEmployeeApprovalEmployeeId: string | null;
    zoneEmployeeApprovalStatusId: number | null;
    zoneEmployeeApprovalStatusDate: Date | null;
    zoneEmployeeApprovalComment: string | null;
    customerServiceEmployeeApprovalEmployeeId: string | null;
    customerServiceEmployeeApprovalStatusId: number | null;
    customerServiceEmployeeApprovalStatusDate: Date | null;
    customerServiceEmployeeApprovalComment: string | null;
    orderDate: Date | null;
    orderBy: string | null;
    orderReferenceNumber: string | null;
    shipToAddress1!: string;
    shipToAddress2: string | null;
    shipToCity!: string;
    shipToState: string | null = null;
    shipToZip!: string;
    createdUtcDateTime: Date | null;
    createdUserId!: string;
    createdUserZrt: string | null;
    createdUserFullName!: string;
    modifiedUtcDateTime: Date | null;
    modifiedUserId!: string;
    modifiedUserZrt: string | null;
    modifiedUserFullName!: string;
    gratisApprovals: GratisApproval[];
    gratisProductRequestedDetails: GratisProductRequestedDetail[];
    gratisProductShippedDetails: GratisProductShippedDetail[];
    gratisAssignedTransactions: GratisAssignedTransaction[];

    constructor() {
        this.id = newSequentialId();
        this.daysWaiting = null;
        this.neededByDate = null;
        this.startDate = null;
        this.endDate = null;
        this.availableAmount = null;
        this.requestedAmount = null;
        this.reimbursedAmount = null;
        this.manualGratisAmount = null;
        this.carryForwardAmount = null;
        this.employeeApprovalStatusId = null;
        this.employeeApprovalStatusDate = null;
        this.regionalEmployeeApprovalEmployeeId = null;
        this.regionalEmployeeApprovalStatusId = null;
        this.regionalEmployeeApprovalStatusDate = null;
        this.regionalEmployeeApprovalComment = null;
        this.zoneEmployeeApprovalEmployeeId = null;
        this.zoneEmployeeApprovalStatusId = null;
        this.zoneEmployeeApprovalStatusDate = null;
        this.zoneEmployeeApprovalComment = null;
        this.customerServiceEmployeeApprovalEmployeeId = null;
        this.customerServiceEmployeeApprovalStatusId = null;
        this.customerServiceEmployeeApprovalStatusDate = null;
        this.customerServiceEmployeeApprovalComment = null;
        this.orderDate = null;
        this.orderBy = null;
        this.orderReferenceNumber = null;
        this.shipToAddress2 = null;
        this.createdUtcDateTime = null;
        this.createdUserZrt = null;
        this.modifiedUtcDateTime = null;
        this.modifiedUserZrt = null;
        this.gratisApprovals = new Array<GratisApproval>();
        this.gratisProductRequestedDetails = new Array<GratisProductRequestedDetail>();
        this.gratisProductShippedDetails = new Array<GratisProductShippedDetail>();
        this.gratisAssignedTransactions = new Array<GratisAssignedTransaction>();
    }
}
