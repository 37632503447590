import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProjectOrderDate } from 'src/app/entity-models/project-order-date.entity';
import { Project } from 'src/app/entity-models/project.entity';
import { ProjectStateService } from 'src/app/services/project-state-service';
import { MY_DATE_FORMATS } from 'src/app/shared/constants/date-formats';

@Component({
  selector: 'app-project-summary-order-dates',
  templateUrl: './project-summary-order-dates.component.html',
  styleUrls: ['./project-summary-order-dates.component.scss']
})
export class ProjectSummaryOrderDatesComponent implements OnInit, OnDestroy {

    readOnly: boolean;
    @Input()
    set project(value: Project) {
        this.readOnly = !!value;
        if (value) {
            this.projectStateService.project = value;
        }
    }


    projectSubscription: Subscription;
    projectOrderDates: ProjectOrderDate[];
    dateOne: Date;
    dateTwo: Date;
    dateThree: Date;
    dateFour: Date;

    dateFormat = MY_DATE_FORMATS.display.customDateInput;

    constructor(public projectStateService: ProjectStateService) { }

    ngOnInit() : void {

        if (!this.projectSubscription || this.projectSubscription.closed) {
            this.projectSubscription = this.projectStateService.observableProject.subscribe((project) => {
                if (project) {
                    if (!project.projectOrderDates) {
                        this.setAllDates(true);
                    }
                    else {
                        this.setAllDates(false);
                    }
                }
            });
        }
    }


    ngOnDestroy(): void {

        if (this.projectSubscription && !this.projectSubscription.closed) {
            this.projectSubscription.unsubscribe();
        }
    }

    setAllDates(empty: boolean = true): void {
        this.dateOne   = this.setDate(empty ? null : 1);
        this.dateTwo   = this.setDate(empty ? null : 2);
        this.dateThree = this.setDate(empty ? null : 3);
        this.dateFour  = this.setDate(empty ? null : 4);
    }

    private setDate( index: number): Date | null {
        let rtn: Date | null = null;
        const found = this.projectStateService.project.projectOrderDates.find((od) => od.dateIndex === index);
        if (found) {
            rtn = found.orderDate;
        } else {
            rtn = null;
        }

        return rtn;
    }

}
