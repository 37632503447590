import {
    Component,
    OnInit,
    Type,
    ViewChild
} from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterService } from "src/app/services/filter.service";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import {
    CustomerTypeEnum,
    GenericLookup,
    RefinerLocation,
    valueSeparator
} from "shield.shared";
import { CallAttributesViewmodel } from "./call-attributes.viewmodel";
import { BaseFilterMapService } from "src/app/services/filter-map-services/base-filter-map.service";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";
import { Px3Rank } from "src/app/entity-models/px3-rank.entity";

@Component({
    selector: "app-call-attributes-filter",
    templateUrl: "./call-attributes-filter.component.html",
    styleUrls: ["./call-attributes-filter.component.css"]
})
export class CallAttributesFilterComponent extends FilterBaseComponent implements OnInit {
    //view childs
    @ViewChild("msaSelect") msaSelect: MatSelect;
    @ViewChild("storeTypesSelect") storeTypesSelect: MatSelect;
    @ViewChild("px3RankSelect") px3RankSelect: MatSelect;

    //public vars
    contentComponent: Type<CallAttributesFilterComponent>;
    icon = "bookmark";
    name = "Attributes";
    viewmodel = new CallAttributesViewmodel(this.filterService, this.px3RankService);

    constructor(private filterService: FilterService, private px3RankService: Px3DelineationService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        await this.viewmodel.initializeStoreTypeDropDown();
        await this.viewmodel.initializePx3RankDropDown();
        this.setInitialized();
    }

    //events
    onOpenedStoreTypesChange(): void {
        this.viewmodel.isAllCustomerTypePresent = this.viewmodel.selectedCustomerTypes.includes(
            this.viewmodel.allCustomerType
        );
        if (this.viewmodel.isAllCustomerTypePresent) {
            this._refinerService.removeRefinerByLocation(RefinerLocation.storeTypes, true, false);
        }
        else {
            if (!this.storeTypesSelect.panelOpen) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.storeTypes;
                refiner.value = this.viewmodel.selectedCustomerTypes
                    .map((vm) => vm.name)
                    .join(", ");
                refiner.dataPropertyName = "id";
                refiner.dataValue = this.viewmodel.selectedCustomerTypes
                    .map((vm) => vm.id)
                    .join(valueSeparator);

                this._refinerService.checkAndUpdateRefiner(refiner);
            }
        }
    }

    onSelectionStoreTypesChange(): void {
        const {isAllPresent, selected} = FilterBaseComponent.onMultiSelectSelectionChange(this.viewmodel.isAllCustomerTypePresent, this.viewmodel.selectedCustomerTypes, this.viewmodel.allCustomerType);
        this.viewmodel.isAllCustomerTypePresent = isAllPresent;
        this.viewmodel.selectedCustomerTypes = selected;
    }

    async onOpenedSelectedPx3Change(): Promise<void> {
        this.viewmodel.isAllPx3RankPresent = this.viewmodel.selectedPx3Ranks.includes(
            this.viewmodel.allPx3Rank
        );
        if (this.viewmodel.isAllPx3RankPresent) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.px3Rank,
                true,
                false
            );
        } else {
            if (!this.px3RankSelect.panelOpen) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.px3Rank;
                refiner.value = this.viewmodel.selectedPx3Ranks
                    .map((vm) => vm.name)
                    .join(", ");
                refiner.dataPropertyName = "px3RankId";
                refiner.dataValue = this.viewmodel.selectedPx3Ranks
                    .map((vm) => vm.name === 'Not Ranked' ? 'Not Ranked' : vm.id.id)
                    .join(valueSeparator);

                this.refinerService.checkAndUpdateRefiner(refiner);
            }
        }
    }

    onSelectionPx3RanksChange(): void {
        const {isAllPresent, selected} = FilterBaseComponent.onMultiSelectSelectionChange(this.viewmodel.isAllPx3RankPresent, this.viewmodel.selectedPx3Ranks, this.viewmodel.allPx3Rank);
        this.viewmodel.isAllPx3RankPresent = isAllPresent;
        this.viewmodel.selectedPx3Ranks = selected;
    }

    onOpenedSelectedMsaChange(): void {
        if (!this.msaSelect.panelOpen) {
            if (this.viewmodel.selectedMsa === "All") {
                this._refinerService.removeRefinerByLocation(RefinerLocation.msa, true, false);
            }
            else {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.msa;
                refiner.value = this.viewmodel.selectedMsa;
                refiner.dataPropertyName = "msa";
                refiner.dataValue = this.viewmodel.selectedMsa === this.viewmodel.yes ? BaseFilterMapService.yes : "0";

                this._refinerService.checkAndUpdateRefiner(refiner, true);
            }
        }
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.storeTypes:
                    const selectedCustomerTypes = refiner.value?.split(", ");
                    const rtnSelectedCustomerTypes = new Array<GenericLookup<CustomerTypeEnum>>();

                    if (selectedCustomerTypes) {
                        for (const type of selectedCustomerTypes) {
                            const found = this.viewmodel.customerTypes.find(
                                (ct) => ct.name === type.trim()
                            );
                            if (found) {
                                rtnSelectedCustomerTypes.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedCustomerTypes = rtnSelectedCustomerTypes?.length
                        ? rtnSelectedCustomerTypes
                        : [this.viewmodel.allCustomerType];
                    break;
                case RefinerLocation.msa:
                    if (refiner.value !== this.viewmodel.selectedMsa) {
                        this.viewmodel.selectedMsa = !!refiner.value ? refiner.value : this.viewmodel.all;
                    }
                break;
                case RefinerLocation.px3Rank:
                    const selectedPx3Ranks = refiner.value?.split(", ");
                    const rtnSelectedPx3Ranks = new Array<
                        GenericLookup<Px3Rank>
                    >();

                    if (selectedPx3Ranks) {
                        for (const rank of selectedPx3Ranks) {
                            const found = this.viewmodel.px3Ranks.find(
                                (rnk) => rnk.name === rank.trim()
                            );
                            if (found) {
                                rtnSelectedPx3Ranks.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedPx3Ranks = rtnSelectedPx3Ranks?.length
                        ? rtnSelectedPx3Ranks
                        : [this.viewmodel.allPx3Rank];
                break;
                default:
                    break;
            }
        }
    }

    async onRefinersChange(): Promise<void> {
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.storeTypes;
        const storeTypesRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.storeTypes
        );
        if (storeTypesRefiner) {
            this.onInputChange(storeTypesRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.msa;
        const msaRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.msa
        );
        if (msaRefiner) {
            this.onInputChange(msaRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }
}
