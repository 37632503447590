import { ElementRef } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { FilterService } from "src/app/services/filter.service";
export class RouteDateInformationViewmodel {
    filterService: FilterService;
    formBuilder: FormBuilder;

    routeDateForm: FormGroup;

    endDateDefault: moment.Moment = moment();
    endDateInput: ElementRef;
    endMaxDate: moment.Moment = moment().add(3, "years");
    endMinDate: moment.Moment = moment().subtract(3, "years");

    startDateDefault: moment.Moment = moment().subtract(7, "days");
    startDateInput: ElementRef;
    startMaxDate: moment.Moment = moment().add(3, "years");

    constructor(
        filterService: FilterService,
        formBuilder: FormBuilder
    ) {
        this.filterService = filterService;
        this.formBuilder = formBuilder;
        this.routeDateForm = this.formBuilder.group({
            routeEndDate: ["", null],
            routeStartDate: ["", null]
        });

    }
}
