import { Injectable } from "@angular/core";
import { NotificationSyncCommand, SyncCommandTypes } from "shield.shared";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { DatabaseService } from "../../services/database.service";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { SyncLevel } from "../sync-enums/sync-level.enum";

@Injectable()
export class NotificationSyncHandler extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.None;
    offlineRepSyncType = SyncLevel.Required;
    isRunSuccessfull = true;
    syncVersionKey = SyncVersionKeyNames.notification;

    constructor(
        private dbService: DatabaseService,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        return;
    }


    async pushData(): Promise<void> {
        this.log("Pushing notifications...");

        await this.pushNotificationData();

        this.log("Done pushing notifications...");
    }

    private async pushNotificationData(): Promise<void> {
        return this.syncQueue.process<NotificationSyncCommand>(
            SyncCommandTypes.notification,
            async (message) => {
                let notificationRequest = await this.dbService.notificationRequests.get(message.payload.id);
                if (notificationRequest
                    && notificationRequest.employeeId
                    && (notificationRequest.sendEmail || notificationRequest.sendSms)) {

                    this.syncQueue.pushCommand(notificationRequest, message);
                }
            }
        );
    }
}
