import { ReceiptDto } from "shield.shared";
import { Receipt } from "src/app/entity-models/receipt";

export class ReceiptConverterService {

    static receiptDtoToReceipt(dto: ReceiptDto): Receipt {
        if (dto) {
            const rtn = new Receipt();
            rtn.id = dto.id;
            rtn.base64Image = dto.base64Image;
            rtn.contentType = dto.contentType;
            return rtn;
        }
    }
}
