<div #myFilter>
    <div class="d-flex justify-content-between align-items-center">
        <div class="w-100"><app-zrt-tree-view #zrtTree></app-zrt-tree-view></div>
    </div>
    <mat-checkbox class="checkbox-margin" [checked]="viewmodel.byArea" (change)="onAreaChange($event.checked)"> By Area </mat-checkbox>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-store-customerId">Store Name / Customer ID
            </mat-label>
            <input #accountSearchInput id="customer-store-customerId-input" type="text" (keyup)="onAddAccountRefiner($event)"
                [(ngModel)]="viewmodel.storeNameCustomerId" matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-street">Street</mat-label>
            <input #streetInput id="street-input" type="text"
                (keyup)="onAddStreetRefiner($event)" [(ngModel)]="viewmodel.streetInput" matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-city">City</mat-label>
            <input #filterCityInput id="filter-city-input" type="text"
                (keyup)="onAddCityRefiner($event)" [(ngModel)]="viewmodel.cityInput"
                matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>State</mat-label>
            <mat-select #stateSelect [(value)]="viewmodel.selectedStates" (openedChange)="openedStateChange()"
                multiple>
                <mat-option *ngFor="let vm of viewmodel.filteredStates" [value]="vm">
                    {{ vm.state.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <angular2-multiselect class="fill-width" [data]="viewmodel.filteredCounties" [(ngModel)]="viewmodel.selectedCounties"
            [settings]="viewmodel.dropdownSettings" (onClose)="countyChange()" (onDeSelectAll)="countyChange()">
        </angular2-multiselect>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="mat-input-search w-100" color="accent">
            <mat-label id="zip">Zip</mat-label>
            <input #zipInput id="Zip-input" type="text"
                (keyup)="onAddZipRefiner($event)" [(ngModel)]="viewmodel.zipInput"
                mask="999999999" matInput digitOnly />
        </mat-form-field>
    </div>
</div>
