import { Customer } from "src/app/entity-models/customer.entity";
import { Product } from "src/app/entity-models/product.entity";
import { CallCashProduct } from "src/app/entity-models/call-cash-product.entity";
import { TaxCalculatorRecordViewModel } from "./tax-calculator-record.viewmodel";
import { CallService } from "../../call-services/call.service";
import { CallTypes, newSequentialId, SharedHelper, TaxCalculationMethodLookup, TaxCalculationMethods, TaxDivisionLookup, TaxTypes } from "shield.shared";
import { TaxRate } from "src/app/entity-models/tax-rate.entity";
import { CustomerStateService } from "src/app/accounts/account-services/customer-state.service";
import { CallProductTax } from "src/app/entity-models/call-cash-tax.entity";
import { Helper } from "src/app/helpers/helper";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { CallGratisProduct } from "src/app/entity-models/call-gratis-product.entity";
import { CallExchangeInProduct } from "src/app/entity-models/call-exchange-in-product.entity";
import { CallExchangeOutProduct } from "src/app/entity-models/call-exchange-out-product.entity";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { StateDelineationService } from "src/app/services/delineation-services/state-delineation.service";
import { TaxRateDelineationService } from "src/app/services/delineation-services/tax-rate-delineation.service";
export class TaxCalculatorViewModel {
    width = "83vw";
    customerCounty: string;
    headerLeftText: string;
    needsMappingsRefreshed: boolean;
    buttonLeftText = "Close";
    records: TaxCalculatorRecordViewModel[] = [];
    private callService: CallService;
    private productDelineationService: ProductDelineationService;
    private customer: Customer;
    private stateDelineationService: StateDelineationService;
    private taxRateDelineationService: TaxRateDelineationService;
    stateName: string;
    private stateId: string;
    city: string;
    county: string;
    private rates = new Array<TaxRate>();
    hasState = false;
    hasCounty = false;
    hasCity = false;
    stateTotal = 0;
    countyTotal = 0;
    cityTotal = 0;
    dollarsPerPack = TaxCalculationMethodLookup.find((tc) => tc.id === TaxCalculationMethods.DollarPerPack).name;
    dollarsPerEach = TaxCalculationMethodLookup.find((tc) => tc.id === TaxCalculationMethods.DollarPerEach).name;
    dollarsPerOz = TaxCalculationMethodLookup.find((tc) => tc.id === TaxCalculationMethods.DollarPerOunce).name;
    dollarsPerOzAndAHalf = TaxCalculationMethodLookup.find((tc) => tc.id === TaxCalculationMethods.DollarPerOunceAndAHalfRounded).name;
    percentOfPrice = TaxCalculationMethodLookup.find((tc) => tc.id === TaxCalculationMethods.PercentOfPrice).name;
    paymentOptions: string[] = ["Swisher", "Stamps", "Wholesaler"];
    selectedCountyPaymentType: string;
    selectedCityPaymentType: string;

    buttonLeftDisabledFunction: () => boolean = () => {
        return false;
    };
    buttonLeftFunction: () => void = () => {
        return;
    };

    constructor(
        callService: CallService,
        productDelineationService: ProductDelineationService,
        customerStateService: CustomerStateService,
        stateDelineationService: StateDelineationService,
        taxRateDelineationService: TaxRateDelineationService,
    ) {
        this.callService = callService;
        this.productDelineationService = productDelineationService;
        this.customer = customerStateService.customer;
        this.stateDelineationService = stateDelineationService;
        this.taxRateDelineationService = taxRateDelineationService;
    }

    onSelectedCityPaymentTypeChange(): void {
        for (const cashProduct of ((this.callService.call as RetailCall).cashProducts ?? [])) {
            cashProduct.callProductTax.selectedCityPaymentType = this.selectedCityPaymentType;
        }
    }

    onSelectedCountyPaymentTypeChange(): void {
        for (const cashProduct of ((this.callService.call as RetailCall).cashProducts ?? [])) {
            cashProduct.callProductTax.selectedCountyPaymentType = this.selectedCountyPaymentType;
        }
    }

    createCallCashTax(cashProduct: CallCashProduct, product: Product): CallProductTax {
        const rtn = new CallProductTax();
        rtn.id = newSequentialId();
        rtn.callCashProductId = cashProduct.id;
        const division = TaxDivisionLookup.find((d) => d.name === product.division);
        if (!division?.id) {
            return;
        }
        const stateTaxRate = this.getTaxRate(TaxTypes.State, division.id);
        const cityTaxRate = this.getTaxRate(TaxTypes.City, division.id);
        const countyTaxRate = this.getTaxRate(TaxTypes.County, division.id);

        rtn.stateTaxRate = stateTaxRate?.taxRate;
        rtn.stateTaxSubmittedToId = stateTaxRate?.taxSubmittedToId;
        rtn.stateTaxCalculationMethodId = stateTaxRate?.taxCalculationMethodId;
        rtn.statePrepaid = stateTaxRate?.isPrepaid;
        if (rtn.stateTaxRate && rtn.stateTaxSubmittedToId && rtn.stateTaxCalculationMethodId) {
            rtn.stateTaxAmount = this.getTaxAmount(rtn.stateTaxCalculationMethodId,
                rtn.stateTaxRate,
                cashProduct.price,
                product.returnableUpc?.weight ? product.returnableUpc?.weight : product.lowestSellableUpc?.weight / product.lowestSellableUpc?.noOfEaches,
                cashProduct.quantity,
                cashProduct.units,
                product.lowestSellableUpc?.noOfEaches,
                product.lowestSellableUpc?.noOfPacks);
        } else {
            rtn.stateTaxAmount = 0;
        }


        rtn.cityTaxRate = cityTaxRate?.taxRate;
        rtn.cityTaxCalculationMethodId = cityTaxRate?.taxCalculationMethodId;
        rtn.cityTaxSubmittedToId = cityTaxRate?.taxSubmittedToId;
        rtn.cityPrepaid = cityTaxRate?.isPrepaid;
        if (rtn.cityTaxRate && rtn.cityTaxCalculationMethodId && rtn.cityTaxSubmittedToId) {
            rtn.cityTaxAmount = this.getTaxAmount(rtn.cityTaxCalculationMethodId,
                rtn.cityTaxRate,
                cashProduct.price,
                product.returnableUpc?.weight ? product.returnableUpc?.weight : product.lowestSellableUpc?.weight / product.lowestSellableUpc?.noOfEaches,
                cashProduct.quantity,
                cashProduct.units,
                product.lowestSellableUpc?.noOfEaches,
                product.lowestSellableUpc?.noOfPacks);
        } else {
            rtn.cityTaxAmount = 0;
        }

        rtn.countyTaxRate = countyTaxRate?.taxRate;
        rtn.countyTaxSubmittedToId = countyTaxRate?.taxSubmittedToId;
        rtn.countyTaxCalculationMethodId = countyTaxRate?.taxCalculationMethodId;
        rtn.countyPrepaid = countyTaxRate?.isPrepaid;
        if (rtn.countyTaxRate && rtn.countyTaxSubmittedToId && rtn.countyTaxCalculationMethodId) {
            rtn.countyTaxAmount = this.getTaxAmount(rtn.countyTaxCalculationMethodId,
                rtn.countyTaxRate,
                cashProduct.price,
                product.returnableUpc?.weight ? product.returnableUpc?.weight : product.lowestSellableUpc?.weight / product.lowestSellableUpc?.noOfEaches,
                cashProduct.quantity,
                cashProduct.units,
                product.lowestSellableUpc?.noOfEaches,
                product.lowestSellableUpc?.noOfPacks);
        } else {
            rtn.countyTaxAmount = 0;
        }

        return rtn;

    }

    createCallGratisTax(gratisProduct: CallGratisProduct, product: Product): CallProductTax {
        const rtn = new CallProductTax();
        rtn.id = newSequentialId();
        rtn.callGratisProductId = gratisProduct.id;
        const division = TaxDivisionLookup.find((d) => d.name === product.division);
        if (!division?.id) {
            return;
        }
        const stateTaxRate = this.getTaxRate(TaxTypes.State, division.id);
        const cityTaxRate = this.getTaxRate(TaxTypes.City, division.id);
        const countyTaxRate = this.getTaxRate(TaxTypes.County, division.id);

        rtn.stateTaxRate = stateTaxRate?.taxRate;
        rtn.stateTaxSubmittedToId = stateTaxRate?.taxSubmittedToId;
        rtn.stateTaxCalculationMethodId = stateTaxRate?.taxCalculationMethodId;
        rtn.statePrepaid = stateTaxRate?.isPrepaid;
        if (rtn.stateTaxRate && rtn.stateTaxSubmittedToId && rtn.stateTaxCalculationMethodId) {
            rtn.stateTaxAmount = this.getTaxAmount(rtn.stateTaxCalculationMethodId,
                rtn.stateTaxRate,
                gratisProduct.value,
                product.returnableUpc?.weight ? product.returnableUpc?.weight : product.lowestSellableUpc?.weight / product.lowestSellableUpc?.noOfEaches,
                gratisProduct.quantity,
                gratisProduct.units,
                product.lowestSellableUpc?.noOfEaches,
                product.lowestSellableUpc?.noOfPacks);
        }


        rtn.cityTaxRate = cityTaxRate?.taxRate;
        rtn.cityTaxCalculationMethodId = cityTaxRate?.taxCalculationMethodId;
        rtn.cityTaxSubmittedToId = cityTaxRate?.taxSubmittedToId;
        rtn.cityPrepaid = cityTaxRate?.isPrepaid;
        if (rtn.cityTaxRate && rtn.cityTaxCalculationMethodId && rtn.cityTaxSubmittedToId) {
            rtn.cityTaxAmount = this.getTaxAmount(rtn.cityTaxCalculationMethodId,
                rtn.cityTaxRate,
                gratisProduct.value,
                product.returnableUpc?.weight ? product.returnableUpc?.weight : product.lowestSellableUpc?.weight / product.lowestSellableUpc?.noOfEaches,
                gratisProduct.quantity,
                gratisProduct.units,
                product.lowestSellableUpc?.noOfEaches,
                product.lowestSellableUpc?.noOfPacks);
        }

        rtn.countyTaxRate = countyTaxRate?.taxRate;
        rtn.countyTaxSubmittedToId = countyTaxRate?.taxSubmittedToId;
        rtn.countyTaxCalculationMethodId = countyTaxRate?.taxCalculationMethodId;
        rtn.countyPrepaid = countyTaxRate?.isPrepaid;
        if (rtn.countyTaxRate && rtn.countyTaxSubmittedToId && rtn.countyTaxCalculationMethodId) {
            rtn.countyTaxAmount = this.getTaxAmount(rtn.countyTaxCalculationMethodId,
                rtn.countyTaxRate,
                gratisProduct.value,
                product.returnableUpc?.weight ? product.returnableUpc?.weight : product.lowestSellableUpc?.weight / product.lowestSellableUpc?.noOfEaches,
                gratisProduct.quantity,
                gratisProduct.units,
                product.lowestSellableUpc?.noOfEaches,
                product.lowestSellableUpc?.noOfPacks);
        }

        return rtn;

    }

    createCallExchangeInTax(exchangeInProduct: CallExchangeInProduct, product: Product): CallProductTax {
        const rtn = new CallProductTax();
        rtn.id = newSequentialId();
        rtn.callExchangeInProductId = exchangeInProduct.id;
        const division = TaxDivisionLookup.find((d) => d.name === product.division);
        if (!division?.id) {
            return;
        }
        const stateTaxRate = this.getTaxRate(TaxTypes.State, division.id);
        const cityTaxRate = this.getTaxRate(TaxTypes.City, division.id);
        const countyTaxRate = this.getTaxRate(TaxTypes.County, division.id);

        rtn.stateTaxRate = stateTaxRate?.taxRate;
        rtn.stateTaxSubmittedToId = stateTaxRate?.taxSubmittedToId;
        rtn.stateTaxCalculationMethodId = stateTaxRate?.taxCalculationMethodId;
        rtn.statePrepaid = stateTaxRate?.isPrepaid;
        if (rtn.stateTaxRate && rtn.stateTaxSubmittedToId && rtn.stateTaxCalculationMethodId) {
            rtn.stateTaxAmount = this.getTaxAmount(rtn.stateTaxCalculationMethodId,
                rtn.stateTaxRate,
                exchangeInProduct.wholesalePrice,
                product.returnableUpc?.weight ? product.returnableUpc?.weight : product.lowestSellableUpc?.weight / product.lowestSellableUpc?.noOfEaches,
                exchangeInProduct.quantity,
                exchangeInProduct.units,
                product.lowestSellableUpc?.noOfEaches,
                product.lowestSellableUpc?.noOfPacks);
        }


        rtn.cityTaxRate = cityTaxRate?.taxRate;
        rtn.cityTaxCalculationMethodId = cityTaxRate?.taxCalculationMethodId;
        rtn.cityTaxSubmittedToId = cityTaxRate?.taxSubmittedToId;
        rtn.cityPrepaid = cityTaxRate?.isPrepaid;
        if (rtn.cityTaxRate && rtn.cityTaxCalculationMethodId && rtn.cityTaxSubmittedToId) {
            rtn.cityTaxAmount = this.getTaxAmount(rtn.cityTaxCalculationMethodId,
                rtn.cityTaxRate,
                exchangeInProduct.wholesalePrice,
                product.returnableUpc?.weight ? product.returnableUpc?.weight : product.lowestSellableUpc?.weight / product.lowestSellableUpc?.noOfEaches,
                exchangeInProduct.quantity,
                exchangeInProduct.units,
                product.lowestSellableUpc?.noOfEaches,
                product.lowestSellableUpc?.noOfPacks);
        }

        rtn.countyTaxRate = countyTaxRate?.taxRate;
        rtn.countyTaxSubmittedToId = countyTaxRate?.taxSubmittedToId;
        rtn.countyTaxCalculationMethodId = countyTaxRate?.taxCalculationMethodId;
        rtn.countyPrepaid = countyTaxRate?.isPrepaid;
        if (rtn.countyTaxRate && rtn.countyTaxSubmittedToId && rtn.countyTaxCalculationMethodId) {
            rtn.countyTaxAmount = this.getTaxAmount(rtn.countyTaxCalculationMethodId,
                rtn.countyTaxRate,
                exchangeInProduct.wholesalePrice,
                product.returnableUpc?.weight ? product.returnableUpc?.weight : product.lowestSellableUpc?.weight / product.lowestSellableUpc?.noOfEaches,
                exchangeInProduct.quantity,
                exchangeInProduct.units,
                product.lowestSellableUpc?.noOfEaches,
                product.lowestSellableUpc?.noOfPacks);
        }

        return rtn;
    }

    createCallExchangeOutTax(exchangeOutProduct: CallExchangeOutProduct, product: Product): CallProductTax {
        const rtn = new CallProductTax();
        rtn.id = newSequentialId();
        rtn.callExchangeOutProductId = exchangeOutProduct.id;
        const division = TaxDivisionLookup.find((d) => d.name === product.division);
        if (!division?.id) {
            return;
        }
        const stateTaxRate = this.getTaxRate(TaxTypes.State, division.id);
        const cityTaxRate = this.getTaxRate(TaxTypes.City, division.id);
        const countyTaxRate = this.getTaxRate(TaxTypes.County, division.id);

        rtn.stateTaxRate = stateTaxRate?.taxRate;
        rtn.stateTaxSubmittedToId = stateTaxRate?.taxSubmittedToId;
        rtn.stateTaxCalculationMethodId = stateTaxRate?.taxCalculationMethodId;
        rtn.statePrepaid = stateTaxRate?.isPrepaid;
        if (rtn.stateTaxRate && rtn.stateTaxSubmittedToId && rtn.stateTaxCalculationMethodId) {
            rtn.stateTaxAmount = this.getTaxAmount(rtn.stateTaxCalculationMethodId,
                rtn.stateTaxRate,
                exchangeOutProduct.wholesalePrice * -1,
                product.returnableUpc?.weight ? product.returnableUpc?.weight : product.lowestSellableUpc?.weight / product.lowestSellableUpc?.noOfEaches,
                exchangeOutProduct.quantity * -1,
                exchangeOutProduct.units,
                product.lowestSellableUpc?.noOfEaches,
                product.lowestSellableUpc?.noOfPacks);
        }


        rtn.cityTaxRate = cityTaxRate?.taxRate;
        rtn.cityTaxCalculationMethodId = cityTaxRate?.taxCalculationMethodId;
        rtn.cityTaxSubmittedToId = cityTaxRate?.taxSubmittedToId;
        rtn.cityPrepaid = cityTaxRate?.isPrepaid;
        if (rtn.cityTaxRate && rtn.cityTaxCalculationMethodId && rtn.cityTaxSubmittedToId) {
            rtn.cityTaxAmount = this.getTaxAmount(rtn.cityTaxCalculationMethodId,
                rtn.cityTaxRate,
                exchangeOutProduct.wholesalePrice * -1,
                product.returnableUpc?.weight ? product.returnableUpc?.weight : product.lowestSellableUpc?.weight / product.lowestSellableUpc?.noOfEaches,
                exchangeOutProduct.quantity * -1,
                exchangeOutProduct.units,
                product.lowestSellableUpc?.noOfEaches,
                product.lowestSellableUpc?.noOfPacks);
        }

        rtn.countyTaxRate = countyTaxRate?.taxRate;
        rtn.countyTaxSubmittedToId = countyTaxRate?.taxSubmittedToId;
        rtn.countyTaxCalculationMethodId = countyTaxRate?.taxCalculationMethodId;
        rtn.countyPrepaid = countyTaxRate?.isPrepaid;
        if (rtn.countyTaxRate && rtn.countyTaxSubmittedToId && rtn.countyTaxCalculationMethodId) {
            rtn.countyTaxAmount = this.getTaxAmount(rtn.countyTaxCalculationMethodId,
                rtn.countyTaxRate,
                exchangeOutProduct.wholesalePrice * -1,
                product.returnableUpc?.weight ? product.returnableUpc?.weight : product.lowestSellableUpc?.weight / product.lowestSellableUpc?.noOfEaches,
                exchangeOutProduct.quantity * -1,
                exchangeOutProduct.units,
                product.lowestSellableUpc?.noOfEaches,
                product.lowestSellableUpc?.noOfPacks);
        }

        return rtn;
    }

    getTaxAmount(calculationmethodId: number, rate: number, price: number, weight = 0, quantity: number, units = 0, lowestSellableEaches = 0, lowestSellablePacks = 0)
        : number {
        let rtn = 0;
        switch (calculationmethodId) {
            case TaxCalculationMethods.DollarPerEach:
                rtn = rate * quantity * units;
                break;
            case TaxCalculationMethods.DollarPerPack:
                const numberOfPacksSold = Math.ceil(units/(lowestSellableEaches/lowestSellablePacks));
                rtn = rate * quantity * numberOfPacksSold;
                break;
            case TaxCalculationMethods.DollarPerOunce:
                rtn = rate * quantity * (weight * units);
                break;
            case TaxCalculationMethods.PercentOfPrice:
                rtn = rate * quantity * Math.abs(price) / 100;
                break;
            case TaxCalculationMethods.DollarPerOunceAndAHalfRounded:
                rtn = rate * quantity * (Math.ceil(weight / units / 1.5) * units);
                break;
            default:
                break;
        }
        return SharedHelper.roundToTwo(rtn);
    }

    getTaxRate(taxTypeId: number, divisionId: number): TaxRate {
        let rtn: TaxRate = null;
        let rates = new Array<TaxRate>();
        let locality: string = null;

        switch (taxTypeId) {
            case TaxTypes.State:
                  locality = "State";
                break;
            case TaxTypes.City:
                locality = this.city;
                break;
            case TaxTypes.County:
                locality = this.county
                break;
            default:
                locality = "unknown";
                break;
        }

        rates = this.rates.filter((str) => str.taxTypeId === taxTypeId
            && Helper.equalsIgnoreCase(str.locality, locality)
            && str.isActive === true
            && str.taxDivisionId === divisionId
            && str.stateId === this.stateId);

        if (rates?.length === 1) {
            rtn = rates[0];
        }

        return rtn;
    }

    setColumnVisibility(): void {
        this.hasState = this.records.findIndex((r) => r.stateRate > 0) !== -1;
        this.hasCounty = this.records.findIndex((r) => r.countyRate > 0) !== -1;
        this.hasCity = this.records.findIndex((r) => r.cityRate > 0) !== -1;
    }

    setTotals(): void {
        this.stateTotal = this.records.reduce((acc, record) =>  {
            if (!record.statePrepaid) {
                acc = (acc ?? 0) + (record.stateTax ?? 0);
            }
            return acc;
        }, 0);
        this.cityTotal = this.records.reduce((acc, record) =>  {
            if (!record.cityPrepaid) {
                acc = (acc ?? 0) + (record.cityTax ?? 0);
            }
            return acc;
        }, 0);
        this.countyTotal = this.records.reduce((acc, record) =>  {
            if (!record.countyPrepaid) {
                acc = (acc ?? 0) + (record.countyTax ?? 0);
            }
            return acc;
        }, 0);
    }

    async buildTaxCalculatorRecordViewModels(): Promise<void> {
        // set address
        const address = this.customer.businessAddress;
        this.city = address.city;
        this.county = address.county;
        const stateResponse = await this.stateDelineationService.getByShortCode(address.state);
        if (!stateResponse) { return; }
        const state = stateResponse.values;

        if (state) {
            this.stateId = state.id;
            this.stateName = state.name;
        }

        const ratesResponse = await this.taxRateDelineationService.getAll();
        if (!ratesResponse) { return; }

        this.rates = ratesResponse.values;

        if (this.callService?.call?.callType === CallTypes.retail
            || this.callService?.call?.callType === CallTypes.rmWholesale) {
             const cashProducts: CallCashProduct[] = (this.callService.call.cashProducts ??= []);
             const gratisProducts: CallGratisProduct[] = (this.callService.call.gratisProducts ??= []);
             const exchangeInProducts: CallExchangeInProduct[] = (this.callService.call.exchangeInProducts ??= []);
             const exchangeOutProducts: CallExchangeOutProduct[] = (this.callService.call.exchangeOutProducts ??= []);

            const productIds: string[] = cashProducts.map((cp) => cp.productId)
                .concat(gratisProducts.map((gp) => gp.productId))
                .concat(exchangeInProducts.map((eip) => eip.productId))
                .concat(exchangeOutProducts.map((eop) => eop.productId));

            const distinctKeys: string[] = [...new Set(productIds)];
            const productsResponse = await this.productDelineationService.getByIds(
                distinctKeys
            );
            if (!productsResponse) { return; }
            const products = productsResponse.values;

            const productMap = new Map(products.map((p) => [p.id, p]))

            this.buildCashProductTaxRecords(cashProducts, productMap);
            this.buildGratisProductTaxRecords(gratisProducts, productMap);
            this.buildExchangeInProductTaxRecords(exchangeInProducts, productMap);
            this.buildExchangeOutProductTaxRecords(exchangeOutProducts, productMap);

            this.setColumnVisibility();
            this.setTotals();
         }
    }

    private buildGratisProductTaxRecords(gratisProducts: CallGratisProduct[], productMap: Map<string, Product>): void {

        gratisProducts.forEach((gratisProduct) => {
            const product: Product = productMap.get(gratisProduct.productId);

            gratisProduct.callProductTax = this.createCallGratisTax(gratisProduct, product);

            let record = this.records.find(
                (myRecord) => myRecord.category === product.division
            );
            if (record) {
                record.quantityExtention +=
                    gratisProduct.quantity * gratisProduct.units;
                record.quantity = gratisProduct.quantity;
                record.weight = product.lowestSellableUpc?.weight ?? 0;
                record.price += gratisProduct.quantity * gratisProduct.value;
                record.stateTax = (gratisProduct.callProductTax?.stateTaxAmount || gratisProduct.callProductTax?.stateTaxAmount === 0)
                    ? record.stateTax + gratisProduct.callProductTax?.stateTaxAmount : null;
                record.cityTax = (gratisProduct.callProductTax?.cityTaxAmount || gratisProduct.callProductTax?.cityTaxAmount === 0)
                    ? record.cityTax + gratisProduct.callProductTax?.cityTaxAmount : null;
                record.countyTax = (gratisProduct.callProductTax?.countyTaxAmount || gratisProduct.callProductTax?.countyTaxAmount === 0)
                    ? record.countyTax + gratisProduct.callProductTax?.countyTaxAmount : null;
                record.statePrepaid = gratisProduct.callProductTax?.statePrepaid;
                record.cityPrepaid = gratisProduct.callProductTax?.cityPrepaid;
                record.countyPrepaid = gratisProduct.callProductTax?.countyPrepaid;
                record.totalTax = (!record.statePrepaid ? record.stateTax ?? 0 : 0) + (!record.countyPrepaid ? record.countyTax ?? 0 : 0) + (!record.cityPrepaid ? record.cityTax ?? 0 : 0);
                record.stateCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === gratisProduct.callProductTax?.stateTaxCalculationMethodId)?.name;
                record.cityCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === gratisProduct.callProductTax?.cityTaxCalculationMethodId)?.name;
                record.countyCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === gratisProduct.callProductTax?.countyTaxCalculationMethodId)?.name;

            } else {
                record = new TaxCalculatorRecordViewModel();
                record.id = newSequentialId();
                record.category = product.division;
                record.quantityExtention =
                    gratisProduct.quantity * gratisProduct.units;
                record.quantity = gratisProduct.quantity;
                record.weight = product.lowestSellableUpc?.weight ?? 0;
                record.price = gratisProduct.quantity * gratisProduct.value;
                record.stateRate = gratisProduct.callProductTax?.stateTaxRate;
                record.stateTax = gratisProduct.callProductTax?.stateTaxAmount;
                record.cityRate = gratisProduct.callProductTax?.cityTaxRate;
                record.cityTax = gratisProduct.callProductTax?.cityTaxAmount;
                record.countyRate = gratisProduct.callProductTax?.countyTaxRate;
                record.countyTax = gratisProduct.callProductTax?.countyTaxAmount;
                record.statePrepaid = gratisProduct.callProductTax?.statePrepaid;
                record.cityPrepaid = gratisProduct.callProductTax?.cityPrepaid;
                record.countyPrepaid = gratisProduct.callProductTax?.countyPrepaid;
                record.totalTax = (!record.statePrepaid ? record.stateTax ?? 0 : 0) + (!record.countyPrepaid ? record.countyTax ?? 0 : 0) + (!record.cityPrepaid ? record.cityTax ?? 0 : 0);
                record.stateCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === gratisProduct.callProductTax?.stateTaxCalculationMethodId)?.name;
                record.cityCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === gratisProduct.callProductTax?.cityTaxCalculationMethodId)?.name;
                record.countyCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === gratisProduct.callProductTax?.countyTaxCalculationMethodId)?.name;
                record.noOfPacks = Math.ceil(gratisProduct.units / (product.lowestSellableUpc?.noOfEaches / product.lowestSellableUpc?.noOfPacks));
                this.records.push(record);
            }
        });
    }

    private buildExchangeInProductTaxRecords(exchangeInProducts: CallExchangeInProduct[], productMap: Map<string, Product>): void {
        exchangeInProducts.forEach((exchangeInProduct) => {
            const product: Product = productMap.get(exchangeInProduct.productId);

            exchangeInProduct.callProductTax = this.createCallExchangeInTax(exchangeInProduct, product);

            let record = this.records.find(
                (myRecord) => myRecord.category === product.division
            );
            if (record) {
                record.quantityExtention +=
                    exchangeInProduct.quantity * exchangeInProduct.units;
                record.quantity = exchangeInProduct.quantity;
                record.weight = product.lowestSellableUpc?.weight ?? 0;
                record.price += exchangeInProduct.quantity * exchangeInProduct.wholesalePrice;
                record.stateTax = (exchangeInProduct.callProductTax?.stateTaxAmount || exchangeInProduct.callProductTax?.stateTaxAmount)
                    ? exchangeInProduct.callProductTax?.stateTaxAmount + record.stateTax : null;
                record.cityTax = (exchangeInProduct?.callProductTax?.cityTaxAmount || exchangeInProduct.callProductTax?.cityTaxAmount === 0)
                    ? exchangeInProduct.callProductTax.cityTaxAmount + record.cityTax : null;
                record.countyTax = (exchangeInProduct.callProductTax?.countyTaxAmount || exchangeInProduct.callProductTax?.countyTaxAmount === 0)
                    ? exchangeInProduct.callProductTax?.countyTaxAmount + record.countyTax : null;
                record.statePrepaid = exchangeInProduct.callProductTax?.statePrepaid;
                record.cityPrepaid = exchangeInProduct.callProductTax?.cityPrepaid;
                record.countyPrepaid = exchangeInProduct.callProductTax?.countyPrepaid;
                record.totalTax = (!record.statePrepaid ? record.stateTax ?? 0 : 0) + (!record.countyPrepaid ? record.countyTax ?? 0 : 0) + (!record.cityPrepaid ? record.cityTax ?? 0 : 0);
                record.stateCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === exchangeInProduct.callProductTax?.stateTaxCalculationMethodId)?.name;
                record.cityCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === exchangeInProduct.callProductTax?.cityTaxCalculationMethodId)?.name;
                record.countyCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === exchangeInProduct.callProductTax?.countyTaxCalculationMethodId)?.name;

            } else {
                record = new TaxCalculatorRecordViewModel();
                record.id = newSequentialId();
                record.category = product.division;
                record.quantityExtention =
                    exchangeInProduct.quantity * exchangeInProduct.units;
                record.quantity = exchangeInProduct.quantity;
                record.weight = product.lowestSellableUpc?.weight ?? 0;
                record.price = exchangeInProduct.quantity * exchangeInProduct.wholesalePrice;
                record.stateRate = exchangeInProduct.callProductTax?.stateTaxRate;
                record.stateTax = exchangeInProduct.callProductTax?.stateTaxAmount;
                record.cityRate = exchangeInProduct.callProductTax?.cityTaxRate;
                record.cityTax = exchangeInProduct.callProductTax?.cityTaxAmount;
                record.countyRate = exchangeInProduct.callProductTax?.countyTaxRate;
                record.countyTax = exchangeInProduct.callProductTax?.countyTaxAmount;
                record.statePrepaid = exchangeInProduct.callProductTax?.statePrepaid;
                record.cityPrepaid = exchangeInProduct.callProductTax?.cityPrepaid;
                record.countyPrepaid = exchangeInProduct.callProductTax?.countyPrepaid;
                record.totalTax = (!record.statePrepaid ? record.stateTax ?? 0 : 0) + (!record.countyPrepaid ? record.countyTax ?? 0 : 0) + (!record.cityPrepaid ? record.cityTax ?? 0 : 0);
                record.stateCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === exchangeInProduct.callProductTax?.stateTaxCalculationMethodId)?.name;
                record.cityCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === exchangeInProduct.callProductTax?.cityTaxCalculationMethodId)?.name;
                record.countyCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === exchangeInProduct.callProductTax?.countyTaxCalculationMethodId)?.name;
                record.noOfPacks = Math.ceil(exchangeInProduct.units / (product.lowestSellableUpc?.noOfEaches / product.lowestSellableUpc?.noOfPacks));
                this.records.push(record);
            }
        });
    }

    private buildExchangeOutProductTaxRecords(exchangeOutProducts: CallExchangeOutProduct[], productMap: Map<string, Product>): void {
        exchangeOutProducts.forEach((exchangeOutProduct) => {
            const product: Product = productMap.get(exchangeOutProduct.productId);

            exchangeOutProduct.callProductTax = this.createCallExchangeOutTax(exchangeOutProduct, product);

            let record = this.records.find(
                (myRecord) => myRecord.category === product.division && myRecord.productId != product.id
            );
            if (record) {
                record.quantityExtention +=
                    exchangeOutProduct.quantity * exchangeOutProduct.units * -1;
                record.quantity = exchangeOutProduct.quantity;
                record.weight = product.lowestSellableUpc?.weight ?? 0;
                record.price += exchangeOutProduct.quantity * exchangeOutProduct.wholesalePrice;
                record.stateTax = (exchangeOutProduct.callProductTax?.stateTaxAmount || exchangeOutProduct.callProductTax?.stateTaxAmount === 0)
                    ? exchangeOutProduct.callProductTax?.stateTaxAmount + record.stateTax : null;
                record.cityTax = (exchangeOutProduct.callProductTax?.cityTaxAmount || exchangeOutProduct.callProductTax?.cityTaxAmount === 0)
                    ? exchangeOutProduct.callProductTax?.cityTaxAmount + record.cityTax : null;
                record.countyTax = (exchangeOutProduct.callProductTax?.countyTaxAmount || exchangeOutProduct.callProductTax?.countyTaxAmount === 0)
                    ? exchangeOutProduct.callProductTax?.countyTaxAmount + record.countyTax : null;
                record.statePrepaid = exchangeOutProduct.callProductTax?.statePrepaid;
                record.cityPrepaid = exchangeOutProduct.callProductTax?.cityPrepaid;
                record.countyPrepaid = exchangeOutProduct.callProductTax?.countyPrepaid;
                record.totalTax = (!record.statePrepaid ? record.stateTax ?? 0 : 0) + (!record.countyPrepaid ? (record.countyTax ?? 0) : 0) + (!record.cityPrepaid ? (record.cityTax ?? 0) : 0);
                record.stateCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === exchangeOutProduct.callProductTax?.stateTaxCalculationMethodId)?.name;
                record.cityCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === exchangeOutProduct.callProductTax?.cityTaxCalculationMethodId)?.name;
                record.countyCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === exchangeOutProduct.callProductTax?.countyTaxCalculationMethodId)?.name;

            } else {
                record = new TaxCalculatorRecordViewModel();
                record.id = newSequentialId();
                record.productId = product.id;
                record.category = product.division;
                record.quantityExtention =
                    exchangeOutProduct.quantity * exchangeOutProduct.units * -1;
                record.quantity = exchangeOutProduct.quantity;
                record.weight = product.lowestSellableUpc?.weight ?? 0;
                record.price = exchangeOutProduct.quantity * exchangeOutProduct.wholesalePrice;
                record.stateRate = exchangeOutProduct.callProductTax?.stateTaxRate;
                record.stateTax = exchangeOutProduct.callProductTax?.stateTaxAmount;
                record.cityRate = exchangeOutProduct.callProductTax?.cityTaxRate;
                record.cityTax = exchangeOutProduct.callProductTax?.cityTaxAmount;
                record.countyRate = exchangeOutProduct.callProductTax?.countyTaxRate;
                record.countyTax = exchangeOutProduct.callProductTax?.countyTaxAmount;
                record.statePrepaid = exchangeOutProduct.callProductTax?.statePrepaid;
                record.cityPrepaid = exchangeOutProduct.callProductTax?.cityPrepaid;
                record.countyPrepaid = exchangeOutProduct.callProductTax?.countyPrepaid;
                record.totalTax = (!record.statePrepaid ? record.stateTax ?? 0 : 0) + (!record.countyPrepaid ? (record.countyTax ?? 0) : 0) + (!record.cityPrepaid ? record.cityTax ?? 0 : 0);
                record.stateCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === exchangeOutProduct.callProductTax?.stateTaxCalculationMethodId)?.name;
                record.cityCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === exchangeOutProduct.callProductTax?.cityTaxCalculationMethodId)?.name;
                record.countyCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === exchangeOutProduct.callProductTax?.countyTaxCalculationMethodId)?.name;
                record.noOfPacks = Math.ceil(exchangeOutProduct.units / (product.lowestSellableUpc?.noOfEaches / product.lowestSellableUpc?.noOfPacks));
                this.records.push(record);
            }
        });
    }

    private buildCashProductTaxRecords(cashProducts: CallCashProduct[], productMap: Map<string, Product>): void {
        cashProducts.forEach((cashProduct) => {
            const product: Product = productMap.get(cashProduct.productId);

            cashProduct.callProductTax = this.createCallCashTax(cashProduct, product);

            let record = this.records.find(
                (myRecord) => myRecord.category === product.division
            );
            if (record) {
                record.quantityExtention +=
                    cashProduct.quantity * cashProduct.units;
                record.quantity = cashProduct.quantity;
                record.weight = product.lowestSellableUpc?.weight ?? 0;
                record.price += cashProduct.quantity * cashProduct.price;
                record.stateTax = (cashProduct.callProductTax?.stateTaxAmount || cashProduct.callProductTax?.stateTaxAmount === 0)
                    ? cashProduct.callProductTax?.stateTaxAmount + record.stateTax : null;
                record.cityTax = (cashProduct.callProductTax?.cityTaxAmount || cashProduct.callProductTax?.cityTaxAmount === 0)
                ? cashProduct.callProductTax?.cityTaxAmount + record.cityTax : null;
                record.countyTax = (cashProduct.callProductTax?.countyTaxAmount || cashProduct.callProductTax?.countyTaxAmount === 0)
                ? cashProduct.callProductTax?.countyTaxAmount + record.countyTax : null;
                record.statePrepaid = cashProduct.callProductTax?.statePrepaid;
                record.cityPrepaid = cashProduct.callProductTax?.cityPrepaid;
                record.countyPrepaid = cashProduct.callProductTax?.countyPrepaid;
                record.totalTax = (!record.statePrepaid ? record.stateTax ?? 0 : 0) + (!record.countyPrepaid ? record.countyTax ?? 0 : 0) + (!record.cityPrepaid ? record.cityTax ?? 0 : 0);
                record.stateCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === cashProduct.callProductTax?.stateTaxCalculationMethodId)?.name;
                record.cityCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === cashProduct.callProductTax?.cityTaxCalculationMethodId)?.name;
                record.countyCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === cashProduct.callProductTax?.countyTaxCalculationMethodId)?.name;

            } else {
                record = new TaxCalculatorRecordViewModel();
                record.id = newSequentialId();
                record.category = product.division;
                record.quantityExtention =
                    cashProduct.quantity * cashProduct.units;
                record.quantity = cashProduct.quantity;
                record.weight = product.lowestSellableUpc?.weight ?? 0;
                record.price = cashProduct.quantity * cashProduct.price;
                record.stateRate = cashProduct.callProductTax?.stateTaxRate;
                record.stateTax = cashProduct.callProductTax?.stateTaxAmount;
                record.cityRate = cashProduct.callProductTax?.cityTaxRate;
                record.cityTax = cashProduct.callProductTax?.cityTaxAmount;
                record.countyRate = cashProduct.callProductTax?.countyTaxRate;
                record.countyTax = cashProduct.callProductTax?.countyTaxAmount;
                record.statePrepaid = cashProduct.callProductTax?.statePrepaid;
                record.cityPrepaid = cashProduct.callProductTax?.cityPrepaid;
                record.countyPrepaid = cashProduct.callProductTax?.countyPrepaid;
                record.totalTax = (!record.statePrepaid ? record.stateTax ?? 0 : 0) + (!record.countyPrepaid ? record.countyTax ?? 0 : 0) + (!record.cityPrepaid ? record.cityTax ?? 0 : 0);
                record.stateCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === cashProduct.callProductTax?.stateTaxCalculationMethodId)?.name;
                record.cityCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === cashProduct.callProductTax?.cityTaxCalculationMethodId)?.name;
                record.countyCalculationMethod = TaxCalculationMethodLookup.find((tc) => tc.id === cashProduct.callProductTax?.countyTaxCalculationMethodId)?.name;
                record.noOfPacks = Math.ceil(cashProduct.units / (product.lowestSellableUpc?.noOfEaches / product.lowestSellableUpc?.noOfPacks));
                this.records.push(record);
            }
        });
    }
}
