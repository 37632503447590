import { EventEmitter, ViewChild } from "@angular/core";
import { Component, Input, Output } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { ButtonActions } from "../enums/button-actions.enum";
import { HeaderButtonComponent } from "./buttons/header-button/header-button.component";
import { RefinerServiceBase } from "../refiner-service-base";
import { ColumnSelector } from "../viewmodels/column-selector.viewmodel";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-page-header",
    templateUrl: "./page-header.component.html",
    styleUrls: ["./page-header.component.scss"]
})
export class PageHeaderComponent {
    //viewchilds
    @ViewChild("menuTrigger") menuTrigger: MatMenuTrigger;

    //inputs
    @Input()
    buttons: HeaderButtonComponent[] = [];

    private _subAvailableColumns: ColumnSelector[];
    @Input()
    get subAvailableColumns(): ColumnSelector[] {
        return this._subAvailableColumns;
    }
    set subAvailableColumns(value: ColumnSelector[]) {
        this._subAvailableColumns = value;
        this.availableColumns.columns = value;
        if (value) {
            this.selectedColumnVisability = this._subAvailableColumns.filter((sc) => sc.isSelected).map((sc) => sc.name);
        }
        setTimeout(() => {
            this.onColumnVisabilityMenuCloseEvent.emit(this.selectedColumnVisability);
        }, 0);
    }

    @Input() entryCount: number;

    @Input() name: string;

    @Input()
    refinerService: RefinerServiceBase;

    //outputs
    @Output() headerExpansionChanged = new EventEmitter();

    @Output()
    onButtonAction = new EventEmitter<ButtonActions>();

    @Output()
    onClickButton = new EventEmitter<HeaderButtonComponent>();

    @Output()
    onColumnVisabilityMenuCloseEvent = new EventEmitter<string[]>();

    //public vars
    allColumnsChecked = true;
    availableColumns: ColumnSelector = {
        name: "All Columns",
        isSelected: true,
        columns: []
    };
    expanded = false;
    selectedColumnVisability: string[];
    spinner: IconDefinition = faSpinner;

    constructor() {}

    //events
    onExpandChange(): void {
        this.headerExpansionChanged.emit();
    }

    onButtonClick(button: HeaderButtonComponent): void {
        switch (button?.buttonAction) {
            case ButtonActions.columnVisibility:
                this.menuTrigger.openMenu();
                return;

            default:
                break;
        }
        this.onClickButton.emit(button);
        this.onButtonAction.emit(button.buttonAction);
    }

    onColumnVisabilityMenuClose(): void {
        this.onColumnVisabilityMenuCloseEvent.emit(
            this.selectedColumnVisability
        );
    }

    someColumnsChecked(): boolean {
        if (this.availableColumns.columns == null) {
            return false;
        }
        return (
            this.availableColumns.columns.filter((c) => c.isSelected).length >
                0 && !this.allColumnsChecked
        );
    }

    setAllColumnsChecked(isSelected: boolean): void {
        this.allColumnsChecked = isSelected;
        if (this.availableColumns.columns == null) {
            return;
        }
        this.availableColumns.columns.forEach(
            (c) => (c.isSelected = isSelected)
        );
        this.updateSelectedColumns();
    }

    updateAllColumnsChecked(): void {
        this.allColumnsChecked =
            this.availableColumns.columns != null &&
            this.availableColumns.columns.every((c) => c.isSelected);
        this.updateSelectedColumns();
    }

    updateSelectedColumns() {
        this.selectedColumnVisability = this.availableColumns.columns
            .filter((col) => col.isSelected)
            .map((col) => col.name);
    }
}
