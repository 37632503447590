<div *ngFor="let vm of easViewmodels; let i = index">
    <div #originalEasWholesaleReceipt
        *ngIf="isWholesaleDraft || isWholesaleFinal || vm.wholesaler?.id === selectedWholesaler?.id">

            <div class="row prow">
                <div class="col pcol center image-margin-top">
                    <img *ngIf="printLogo" [src]="printLogo" alt="Eas Print Logo" />
                </div>
            </div>
            <div class="row prow">
                <div class="col pcol center"><b>Swisher Solutions Ship To Form</b></div>
            </div>
            <div class="row prow">
                <div class="col pcol center">
                    {{swisherAddress}}
                </div>
            </div>
            <div class="row prow">
                <div class="col pcol-5 center">{{ timeNow| date: dateTimeFormat }}</div>
                <div class="col pcol-6 center">
                    {{ salesRepDisplayName }} word
                </div>
            </div>
            <div class="row prow">
                <div class="col pcol center">
                    <div>EAS State License # {{ easlicense }}</div>
                </div>
            </div>
            <hr />
            <div>
                <div>
                    <div class="row prow">
                        <div class="col pcol center">
                            {{ customerReceiptAddress?.addressName }} - Receipt #{{vm.receiptNumber}}
                        </div>
                    </div>
                    <div *ngIf="vm.customerCode" class="row prow">
                        <div class="col pcol center">
                            Distributor Acct # for Retailer {{vm.customerCode}}
                        </div>
                    </div>
                    <div *ngIf="vm.retailVaporLicense?.licenseNumber" class="row prow">
                        <div class="col pcol center">
                            Retail State Vapor License # {{ vm.retailVaporLicense.licenseNumber }}
                        </div>
                    </div>
                    <div class="row prow center">
                        <div class="col pcol center">Retail State OTP License # {{vm.retailOptLicense?.licenseNumber ?
                            vm.retailOptLicense.licenseNumber : 'None Listed'}}</div>
                    </div>
                    <div class="row prow">
                        <div class="col pcol center">
                            {{ customerReceiptAddress?.addressFormatted }}
                        </div>
                    </div>
                    <div *ngIf="customer?.businessAddress?.county" class="row prow">
                        <div class="col pcol center">{{ customer.businessAddress.county + ' County' }}</div>
                    </div>
                    <hr />
                    <div *ngIf="vm.wholesaler" class="row prow">
                        <div class="col pcol center">{{vm.wholesaler.name}}</div>
                    </div>
                    <div *ngIf="vm.wholesaler" class="row prow">
                        <div class="col pcol center">State OTP License ID {{vm.wholesalerRetailOptLicense?.licenseNumber}}</div>
                    </div>
                    <div *ngIf="vm.orders?.length > 0">
                        <div *ngFor="let order of vm.orders">
                            <br />
                            <div class="row prow">
                                <div class="col pcol center">
                                    <b>ITEMS TO BE DELIVERED</b>
                                </div>
                            </div>
                            <div class="row prow">
                                <div class="col pcol">
                                    <b>UIN</b>
                                </div>
                                <div class="col pcol center">
                                    <b>Order Date {{order.orderDate | date: dateFormat}}</b>
                                </div>
                                <div class="col pcol center">
                                    <b>Qty</b>
                                </div>
                            </div>

                            <div *ngFor="let orderProduct of order.callOrderProductViewmodels" class="row prow">
                                <div class="col pcol">
                                    {{ orderProduct.uin }}
                                </div>
                                <div class="col pcol center">
                                    {{ orderProduct.product?.description }}
                                </div>
                                <div class="col pcol center">
                                    {{ orderProduct.quantity }}
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row prow">
                <div class="col pcol">
                    Salesrep: ({{signatureDisplayName}})
                </div>
            </div>
            <div class="row prow">
                <div class="col pcol larger">
                    <b>Customer Representative:</b>
                </div>
            </div>
            <div class="row prow">
                <div class="col pcol">
                    <img *ngIf="signature" [src]="signature" alt="Signature" [width]="signatureRetailWidth"
                        [height]="signatureRetailHeight" />
                    <hr class="no-top-margin" />
                </div>
            </div>
            <div class="row prow">
                <div class="col pcol">Signature ({{contactDisplayName}})</div>
                <br />
            </div>
            <div class="row prow">
                <div class="col pcol center">
                    Falsification of this form is grounds for immedaite dismissal
                </div>
            </div>
            <div class="row prow">
                <div class="col pcol center">Receipt #{{vm.receiptNumber}}</div>
            </div>
            <hr *ngIf="i < easViewmodels?.length -1" />
            <div *ngIf="i < easViewmodels?.length -1" class="page-break"></div>

    </div>
</div>
