import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
    IconDefinition,
    faExpandAlt,
    faCheck,
    faTimes,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import { EmployeeRoleType } from "shield.shared";
import { AppStateService } from "src/app/services/app-state.service";
import { FilterService } from "src/app/services/filter.service";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { DataSyncQueueService } from "src/app/sync/data-sync-queue.service";
import { GratisRequestFormViewmodel } from "./gratis-request-form.viewmodel";
import { GratisRequestValidationService } from "src/app/validation/gratis-request.validation";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { RegisteredUserDelineationService } from "src/app/services/delineation-services/registered-user-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { GratisDelineationService } from "src/app/services/delineation-services/gratis-delineation.service";

@Component({
    selector: "app-gratis-request-form",
    templateUrl: "./gratis-request-form.component.html",
    styleUrls: ["./gratis-request-form.component.scss"]
})
export class GratisRequestFormComponent implements OnInit {

    faExpandAlt: IconDefinition = faExpandAlt;
    faCheck: IconDefinition = faCheck;
    faTimes: IconDefinition = faTimes;
    faPlus: IconDefinition = faPlus;

    viewmodel: GratisRequestFormViewmodel;

    constructor(private router: Router,
        private appStateService: AppStateService,
        private filterService: FilterService,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private overlayService: OverlayService,
        private productDelineationService: ProductDelineationService,
        private gratisDelineationService: GratisDelineationService,
        private pleaseWaitService: PleaseWaitService,
        private snackbarService: SnackbarService,
        private dataSyncQueueService: DataSyncQueueService,
        private gratisRequestValidationService: GratisRequestValidationService,
        private registeredUserDelineationService: RegisteredUserDelineationService,
        private employeeDelineationService: EmployeeDelineationService) {}

    ngOnInit(): void {
        this.viewmodel = new GratisRequestFormViewmodel(this.router,
            this.formBuilder,
            this.activatedRoute,
            this.filterService,
            this.overlayService,
            this.productDelineationService,
            this.gratisDelineationService,
            this.pleaseWaitService,
            this.snackbarService,
            this.dataSyncQueueService,
            this.gratisRequestValidationService,
            this.registeredUserDelineationService,
            this.employeeDelineationService
            );

        if (!this.viewmodel.employeeSubscription || this.viewmodel.employeeSubscription.closed) {
            this.viewmodel.employeeSubscription = this.appStateService.currentEmployee.subscribe(async (employee) => {
                this.viewmodel.employee = employee;



                if  (employee) {
                    this.viewmodel.isTm = !!employee.employeeRoles?.map((r) => r.employeeRoleType.id)?.find((id) => id === EmployeeRoleType.TM);
                    await this.viewmodel.initialize(employee);
                }
            });
        }
    }

    ngOnDestroy(): void {
        if (this.viewmodel.employeeSubscription && !this.viewmodel.employeeSubscription.closed) {
            this.viewmodel.employeeSubscription.unsubscribe();
        }
        this.viewmodel.unsubscribe();
    }
}
