export class CallProductTaxDto {
    id: string //uuid
    callCashProductId: string //uuid
    callGratisProductId: string //uuid
    callExchangeInProductId: string //uuid
    callExchangeOutProductId: string //uuid
    stateTaxRate: number;
    stateTaxAmount: number;
    stateTaxCalculationMethodId: number;
    stateTaxSubmittedToId: number;
    cityTaxRate: number;
    cityTaxAmount: number;
    cityTaxCalculationMethodId: number;
    cityTaxSubmittedToId: number;
    countyTaxRate: number;
    countyTaxAmount: number;
    countyTaxCalculationMethodId: number;
    countyTaxSubmittedToId: number;
    selectedCountyPaymentType: string;
    selectedCityPaymentType: string;
    statePrepaid: boolean;
    countyPrepaid: boolean;
    cityPrepaid: boolean;
}
