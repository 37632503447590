import { ProductViewmodel } from "src/app/accounts/call-master/stepper-call/distribution-grid/product.viewmodel";
import { Customer } from "src/app/entity-models/customer.entity";

export class ProjectSummaryProductGridViewmodel {
    name: string;
    uin: string;
    upc: string;
    wholesalerName: string;

    constructor(product: ProductViewmodel){
        this.name = product.description;
        this.uin = product.uin;
        this.upc = product.lowestSellableUpc?.upc;
        this.wholesalerName = product.wholesaler?.name;
    }
}
