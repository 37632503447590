import { FilterService } from "src/app/services/filter.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { ElementRef, Input } from "@angular/core";
import { Customer } from "src/app/entity-models/customer.entity";
import { Subscription } from "rxjs";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";


export class OrderExtractionViewmodel {
    //public vars
    filterService: FilterService;
    customerDelineationService: CustomerDelineationService;
    formBuilder: FormBuilder;

    accountInput: string;
    chains = new Array<string>();
    chainInput: string;
    dateForm: FormGroup;
    endDateInput: ElementRef;
    endMinDate: moment.Moment = moment().subtract(1, "years");
    selectedChains = new Array<string>();
    selectedWholesaler: Customer;
    selectedWholesalerSubscription: Subscription;
    wholesalers: Customer[] = new Array<Customer>();
    wholesalerSubscription: Subscription;

    constructor(
        filterService: FilterService,
        customerDelineationService: CustomerDelineationService,
        formBuilder: FormBuilder
    ) {
        this.filterService = filterService;
        this.customerDelineationService = customerDelineationService;
        this.formBuilder = formBuilder;
        this.dateForm = this.formBuilder.group({
            endDate: ["", []]
        });
    }
}
