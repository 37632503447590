import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ContractTemplateDto, ContractTemplateTypeIdAndZoneParamDto, GenericRequestDto, GenericResponseDto, newSequentialId, SharedHelper } from "shield.shared";
import { ContractTemplate } from "../../entity-models/contract-template.entity";
import { ContractTemplateDataSyncHandler } from "../../sync/data-sync-handlers/contract-template-data-sync-handler";
import { ContractTemplateConverterService } from "../converter-services/contract-template-converter.service";
import { ConverterHelper } from "../converter-services/converter.helper";

@Injectable()
export class ContractTemplateOnlineService {

    constructor(private http: HttpClient) { }

    async getByTypeIdAndZone(params: ContractTemplateTypeIdAndZoneParamDto): Promise<GenericResponseDto<ContractTemplate[]>> {

        const request = new GenericRequestDto<ContractTemplateTypeIdAndZoneParamDto>();
        request.id = newSequentialId();
        request.values = params;

        const result = await this.http
            .post<GenericResponseDto<ContractTemplateDto[]>>(`/api/contract-templates/get-by-type-and-zone`, request).toPromise();

        return ConverterHelper.dtoToEntityArray<ContractTemplateDto, ContractTemplate>(result,
            (r) => ContractTemplateConverterService.contractTemplateDtoToContractTemplate(r));
    }
}
