import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { RegisteredUserSyncCommand } from "shield.shared";
import { Employee } from "../../entity-models/employee.entity";
import { RegisteredUser } from "../../entity-models/registered-user-entity";
import { DataSyncQueueService } from "../../sync/data-sync-queue.service";
import { DatabaseService } from "../database.service";

@Injectable()
export class RegisteredUserOfflineService {

    constructor(private dbService: DatabaseService,
        private dataSyncQueueService: DataSyncQueueService,
        private router: Router) { }

    async autosaveRegisteredUser(): Promise<RegisteredUser> {
        return;
    }

    async getByEmployeeId(id: string): Promise<RegisteredUser> {
        return this.dbService.registeredUsers.where("employeeId").equals(id).first();
    }

    async getUnprocessedByEmployeeId(id: string): Promise<RegisteredUser> {
        return this.dbService.registeredUsers.where("employeeId").equals(id).and(v => !v.hasServerProcessed).first();
    }

    async saveRegisteredUser(registeredUser: RegisteredUser): Promise<RegisteredUser> {
        if (registeredUser?.employeeId) {
            registeredUser.hasServerProcessed = 0;
            const oldRegisteredUser = await this.dbService.registeredUsers.where("employeeId").equals(registeredUser.employeeId).first();
            if (!!oldRegisteredUser) {
                registeredUser.id = oldRegisteredUser.id;
            }
            await this.dbService.registeredUsers.put(registeredUser);
            await this.dataSyncQueueService.enqueue(
                new RegisteredUserSyncCommand(registeredUser.id));
        }

        return registeredUser;
    }
}
