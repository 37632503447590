import { Injectable } from "@angular/core";
import { CustomerContract } from "../../entity-models/customer-contract.entity";
import { CustomerContractOnlineService } from "../online-services/customer-contract-online.service";
import { CustomerContractOfflineService } from "../offline-services/customer-contract-offline.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { SnackbarService } from "../snackbar.service";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { BatchParamsDto, EmailContractParamsDto, FilterRequestV2Dto, FilterSortDto, GenericResponseDto, RetailContractBatchParamsDto, RetailContractsColumns, SortDto } from "shield.shared";
import { BehaviorSubject } from "rxjs";
import { Signature } from "src/app/entity-models/signature.entity";
import { DatabaseService } from "../database.service";
import { DelineationContext } from "./delineation-context.service";
import { RetailContractFilterMapService } from "../filter-map-services/retail-contract-filter-map.service";

@Injectable()
export class CustomerContractDelineationService extends DelineationContext<CustomerContract, string> {

    constructor(private customerContractOfflineService: CustomerContractOfflineService,
        private customerContractOnlineService: CustomerContractOnlineService,
        snackbarService: SnackbarService,
        protected datasourceDelineationService: DatasourceDelineationService,
        protected dbService: DatabaseService){
            super(dbService, datasourceDelineationService, snackbarService);
        }

    async getCustomerContracts(id: string,
        refiners: Refiner[],
        pageSize: number | null,
        startIndex: number,
        filterSorts: FilterSortDto<RetailContractsColumns>[],
        shouldWait$: BehaviorSubject<Boolean>): Promise<GenericResponseDto<CustomerContract[]>> {

        const key = new RetailContractBatchParamsDto();
        key.filterRequestDto = new FilterRequestV2Dto();
        key.filterRequestDto.id = id;
        key.filterRequestDto.filters = RetailContractFilterMapService.mapFilterData(refiners);
        key.filterRequestDto.pageSize = pageSize;
        key.filterRequestDto.startIndex = startIndex;
        key.filterRequestDto.filterSorts = filterSorts;
        key.employeeId = id;

        const offline = (key: RetailContractBatchParamsDto) => {
            return this.customerContractOfflineService.getCustomerContracts(key);
        }
        const online = (key: RetailContractBatchParamsDto) => {
            return this.customerContractOnlineService.getCustomerContracts(key);
        }
        const response = await this.datasourceDelineationService.makeCall<RetailContractBatchParamsDto, CustomerContract[]>(key, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            shouldWait$.next(false);
            return;
        }

        return response;
    }

    async getByCustomerId(id: string): Promise<GenericResponseDto<CustomerContract[]>> {
        const offline = (key: string) => {
            return this.customerContractOfflineService.getByCustomerId(key);
        }
        const online = (key: string) => {
            return this.customerContractOnlineService.getByCustomerId(key);
        }

        const response = await this.datasourceDelineationService.makeCall<string, CustomerContract[]>(
            id, offline, online, this.compareCustomerContractArray,
            (key: string, hasOfflineAccess: boolean) => this.getUnprocessedCustomerContractsByCustomerId(key, hasOfflineAccess));

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getContractSignature(id: string): Promise<Signature> {
        const offline = (key: string) => {
            return this.customerContractOfflineService.getContractSignature(key);
        }
        const online = (key: string) => {
            return this.customerContractOnlineService.getContractSignature(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, Signature>(id, offline, online);

        let signature: Signature;

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            } else {
                signature = response.values;
            }
        }
        return signature;
    }

    async emailContract(employeeId: string, image: string, comment?: string): Promise<undefined> {
        const params = new EmailContractParamsDto();
        params.employeeId = employeeId;
        params.image = image;
        params.comment = comment;

        const offline = (key: EmailContractParamsDto) => {
            return this.customerContractOfflineService.emailContract(key);
        }
        const online = async (key: EmailContractParamsDto) => {
            return await this.customerContractOfflineService.emailContract(key);
        }
        const response = await this.datasourceDelineationService.makeCall<EmailContractParamsDto, undefined>(params, offline, online);

        if (response && response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }
    }

    async upsertAgreement(newCustomerContract: CustomerContract): Promise<CustomerContract> {
        const offline = (key: CustomerContract) => {
            return this.customerContractOfflineService.upsertAgreement(key);
        }
        const online = async (key: CustomerContract) => {
            return await this.customerContractOfflineService.upsertAgreement(key);
        }
        const response = await this.datasourceDelineationService.makeCall<CustomerContract, CustomerContract>(newCustomerContract, offline, online);

        if (response && response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }
    }

    private async getUnprocessedCustomerContractsByCustomerId(key: string, hasOfflineAccess: boolean): Promise<CustomerContract[]> {
        const unprocessed = await this.customerContractOfflineService.getUnprocessedByCustomerId(key);

        void this.cleanUpCustomerContracts(hasOfflineAccess);
        return unprocessed;
    }

    private async cleanUpCustomerContracts(hasOfflineAccess: boolean) {
        if (hasOfflineAccess) return;

        const processed = await this.dbService.customerContracts.where("hasServerProcessed").equals(1).toArray();

        if (processed.length) {
            this.dbService.customerContracts.bulkDelete(processed.map(v => v.id));
        }
    }
    private async compareCustomerContractArray(
        onlineCustomerContracts: CustomerContract[],
        notSyncedOfflineCustomerContracts: CustomerContract[]
    ): Promise<CustomerContract[]> {
        const rtn = new Array<CustomerContract>();
        const offlineCustomerContractsMaps = new Map(notSyncedOfflineCustomerContracts?.map((entry) => [entry.id, entry]));

        for (const onlineCustomerContract of (onlineCustomerContracts ?? [])) {
            const offlineContact = offlineCustomerContractsMaps?.get(onlineCustomerContract.id);
            if (offlineContact) {
                offlineCustomerContractsMaps.delete(offlineContact.id);
                if (
                    (onlineCustomerContract.modifiedUtcDateTime?.getTime() ?? onlineCustomerContract.createdUtcDateTime?.getTime()) >=
                    (offlineContact.modifiedUtcDateTime?.getTime() ?? offlineContact.createdUtcDateTime?.getTime())
                ) {
                    rtn.push(onlineCustomerContract);
                } else {
                    rtn.push(offlineContact);
                }
            } else {
                rtn.push(onlineCustomerContract);
            }
        }

        // What is remaining in the offlineCustomerContractsMaps are entries that don't exist in the online version
        // Unprocessed entries are placed at the top
        for (const offlineCustomerContractsMap of offlineCustomerContractsMaps.entries()) {
            rtn.push(offlineCustomerContractsMap[1]);
        }

        return rtn;
    }
}
