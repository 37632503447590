import { Injectable } from "@angular/core";
import { FilterRequestV2Dto, FilterSortDto, GenericResponseDto, WholesalerGroupEntryDto, WholesalerGroupsListBatchParamsDto, WholesalerGroupsListColumns } from "shield.shared";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { WholesalerGroupEntry } from "src/app/entity-models/wholesaler-group-entry.entity";
import { WholesalerGroup } from "src/app/entity-models/wholesaler-group.entity";
import { DatabaseService } from "../database.service";
import { WholesalerGroupsListFilterMapService } from "../filter-map-services/wholesaler-groups-list-filter-map.service";
import { WholesalerGroupOfflineService } from "../offline-services/wholesaler-group-offline.service";
import { WholesalerGroupOnlineService } from "../online-services/wholesaler-group-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class WholesalerGroupDelineationService extends DelineationContext<WholesalerGroupEntry, string> {

    constructor(private wholesalerGroupOfflineService: WholesalerGroupOfflineService
        , private wholesalerGroupOnlineService: WholesalerGroupOnlineService
        , snackbarService: SnackbarService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService
    ) {
        super(dbService, datasourceDelineationService, snackbarService);
    }

    async getBatch(
        id: string,
        refiners: Refiner[],
        pageSize: number | null,
        startIndex: number,
        filterSorts: FilterSortDto<WholesalerGroupsListColumns>[]
    ): Promise<GenericResponseDto<WholesalerGroupEntry[]>> {

        const key = new WholesalerGroupsListBatchParamsDto();
        key.filterRequestDto = new FilterRequestV2Dto();
        key.filterRequestDto.id = id;
        key.filterRequestDto.filters = WholesalerGroupsListFilterMapService.mapFilterData(refiners);
        key.filterRequestDto.pageSize = pageSize;
        key.filterRequestDto.startIndex = startIndex;
        key.filterRequestDto.filterSorts = filterSorts;
        key.employeeId = id;

        const offline = (key: WholesalerGroupsListBatchParamsDto) => {
            return this.wholesalerGroupOfflineService.getBatch(key);
        }
        const online = (key: WholesalerGroupsListBatchParamsDto) => {
            return this.wholesalerGroupOnlineService.getBatch(key);
        }
        const response = await this.datasourceDelineationService.makeCall<WholesalerGroupsListBatchParamsDto, WholesalerGroupEntry[]>(key, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getEntryByWholesalerId(wholesalerId: string): Promise<WholesalerGroupEntryDto | null> {
        return (await this.simpleCall<
            WholesalerGroupOfflineService,
            WholesalerGroupOnlineService,
            string,
            WholesalerGroupEntryDto | null
        >(
            "getEntryByWholesalerId",
            wholesalerId,
            this.wholesalerGroupOfflineService,
            this.wholesalerGroupOnlineService,
        )).values;
    }

    async getByWholesalerId(
        wholesalerId: string,
    ): Promise<WholesalerGroup | null> {

        let wholesalerGroup: WholesalerGroup = null;

        const wholesalerGroupMember = await this.dbService.wholesalerGroupMembers
            .where("wholesalerId")
            .equals(wholesalerId)
            .first();

        if (wholesalerGroupMember) {
            const wholesalerGroupId = wholesalerGroupMember.wholesalerGroupId;

            if (wholesalerGroupId) {
                wholesalerGroup = await this.dbService.wholesalerGroups
                    .where("id")
                    .equals(wholesalerGroupId)
                    .first();
            }
        }
        return wholesalerGroup;
    }
}
