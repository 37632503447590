import { ProjectStatuses, ProjectTypes } from "../lookup";
import { AddressFilterDto } from "./address-filter.dto";

export class ProjectListFilterDto {

    /*
     * My Searches
     */
    // Usage: Get only accounts with given ids
    ids?: string[];

    /*
    * Header/Location
    */

    // Usage: Get data based on name of the project
    name?: string[];

    // Usage: Get data based on manager that created the project
    createdBy?: string[];

    // Usage: Get data based on assigned employee ids
    employeeIds?: string[];

    // Usage: Contains address properties for the business address
    addressFilterDto?: AddressFilterDto;

    /*
     * Date Information
     */

    // Usage: Get data based on project start and end dates
    startOnOrAfterDate?: string;
    endOnOrBeforeDate?: string;

    /*
     * Activities
     */

    // Usage: Get data based on associated wholesalers
    wholesalerIds?: string[];

    // Usage: Get data based on account ownership
    //Possible data: Account ownership GUIDs
    accountOwnershipIds?: string[];

    /*
     * Attributes
     */

    // Usage: Get based on project types
    projectTypes?: ProjectTypes[];

    // Usage: Get based on project statuses
    projectStatuses?: ProjectStatuses[];
}
