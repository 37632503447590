// eslint-disable-next-line no-shadow
export enum FilterLocation {
    default = 0,
    customerList = 1,
    callHistory = 2,
    orderList = 3,
    transactionList = 4,
    gratisList = 5,
    retailContracts = 6,
    projectStores = 7,
    projectList = 8,
    callPictures = 9,
    productDistribution = 10,
    dailySummary = 11,
}
