import { EmailReceiptLocationOrigins } from "../enum";

export class EmailReceiptToCustomerContactParams {
    email: string;
    callId: string;
    receiptId: string;
    receiptNumber: string;
    origin: EmailReceiptLocationOrigins;
    comment?: string;
}
