import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ContractPaymentStatuses, CustomerContractPaymentDto, GenericResponseDto, SignatureDto } from "shield.shared";
import { CustomerContractPayment } from "src/app/entity-models/customer-contract-payment.entity";
import { CustomerContract } from "src/app/entity-models/customer-contract.entity";
import { Signature } from "src/app/entity-models/signature.entity";
import { CustomerContractPaymentDataSyncHandler } from "src/app/sync/data-sync-handlers/customer-contract-payment-data-sync-handler";
import { ConverterHelper } from "../converter-services/converter.helper";
import { CustomerContractPaymentConverterService } from "../converter-services/customer-contract-payment-converter.service";
import { SignatureConverterService } from "../converter-services/signature-converter.service";

@Injectable()
export class ContractPaymentOnlineService {

    apiVersion = CustomerContractPaymentDataSyncHandler.apiVersion;
    constructor(private http: HttpClient){}

    async getContractPaymentSignatures(customerContract: CustomerContract): Promise<GenericResponseDto<Signature[]>> {
        const rtn = new GenericResponseDto<Signature[]>();
        let signatures = new Array<Signature>();

        if (!customerContract?.customerContractPayments) { return null; }

        const signaturePromises: Promise<GenericResponseDto<SignatureDto>>[] = [];

        for (const payment of customerContract.customerContractPayments) {
            if (payment.contractPaymentStatus?.id !== ContractPaymentStatuses.Signed) {
                const signature = payment.customerContactSignature;
                if (signature) {
                        signatures.push(signature);
                } else {
                    signaturePromises.push(
                        this.http.get<GenericResponseDto<SignatureDto>>(
                                `/api/customer-contracts/payment/${payment.id}/get-signature`
                            ).toPromise()
                    );
                }
            }
        }


        if (signaturePromises.length) {
            const result = await Promise.all(signaturePromises);
            const serverSignatures = result.map((dto) => {
                return SignatureConverterService.signatureDtoToSignature(dto.values);
            });
            signatures = signatures.concat(serverSignatures);
        }

        if (signatures?.length) {
            rtn.values = signatures
        }

        return rtn;
    }


    async upsertCustomerContractPayment(dto: CustomerContractPaymentDto): Promise<GenericResponseDto<CustomerContractPayment>> {

        const request = new GenericResponseDto<CustomerContractPaymentDto>();
        request.id = dto.id;
        request.values = dto;

        const result = await this.http
            .post<GenericResponseDto<CustomerContractPaymentDto>>(`/api/customer-contracts/save-payment?apiVersion=${this.apiVersion}`, request)
            .toPromise();

        const rtn = ConverterHelper.dtoToEntity(result, (r) => CustomerContractPaymentConverterService.customerContractPaymentDtoToCustomerContractPayment(r));

        const formData = new FormData();
        formData.append("file", dto.signatureBlob);
        if (request.id === result.id) {
            await this.http
                .put(`/api/customer-contracts/payment-signature/${dto.id}`, formData).toPromise()
        }

        return rtn;
    }
}
