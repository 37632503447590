import { Address } from "./address.entity";

export class RouteStop {
    id: string; // UUID
    customerId: string | null; // UUID
    label: string;
    address: Address;
    sequence: number;
    px3RankId?: string;
    isCompleted?: boolean;
}
