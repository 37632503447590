import { GenericResponseDto, GenericVersionResponseDto } from "shield.shared";

export class ConverterHelper {

    static dtoToEntityArray<T, K>(dto: GenericResponseDto<T[]>, converterMethod: (input: T) => K ): GenericResponseDto<K[]> {

        const rtn = new GenericResponseDto<K[]>();

        if (dto && converterMethod) {
            rtn.count = dto.count;
            rtn.affected = dto.affected;
            rtn.counts = dto.counts;
            rtn.id = dto.id;
            rtn.isError = dto.isError;
            rtn.message = dto.message;
            if (dto.values && converterMethod && Array.isArray(dto.values)) {
                rtn.values = dto.values.map((item) => converterMethod(item));
            } else {
                rtn.values = new Array<K>();
            }
        }
        return rtn;
    }

    static dtoToEntity<T, K>(dto: GenericResponseDto<T>, converterMethod: (input: T) => K ): GenericResponseDto<K> {

        const rtn = new GenericResponseDto<K>();

        if (dto && converterMethod) {
            rtn.count = dto.count;
            rtn.affected = dto.affected;
            rtn.counts = dto.counts;
            rtn.id = dto.id;
            rtn.isError = dto.isError;
            rtn.message = dto.message;
            if (dto.values && converterMethod && !Array.isArray(dto.values)) {
                rtn.values = converterMethod(dto.values);
            } else {
                rtn.values = undefined;
            }
        }
        return rtn;
    }

    static genericVersionResponseDtoToGenericResponseDto<T>(vr: GenericVersionResponseDto<T>): GenericResponseDto<T> {

        const rtn = new GenericResponseDto<T>();

        rtn.count = vr.count;
        rtn.id = vr.id;
        rtn.isError = vr.isError;
        rtn.values = vr.values;

        return rtn;
    }

    // This method ensures that server-side responses are correctly casted as GenericResponseDto, even if no conversion is needed.
    static dtoAsDto<T>(dto: GenericResponseDto<T>): GenericResponseDto<T> {

        const rtn = new GenericResponseDto<T>();

        if (dto) {
            rtn.id = dto.id;
            rtn.count = dto.count;
            rtn.isError = dto.isError;
            rtn.message = dto.message;
            rtn.affected = dto.affected;
            rtn.counts = dto.counts;

            rtn.values = dto.values;
        }

        return rtn;
    }
}
