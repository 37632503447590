import { ElementRef } from "@angular/core";
import {
    IconDefinition,
    faEye,
    faPrint,
    faCloudDownloadAlt,
    faCaretDown,
    faCalendarAlt,
    faRoad
} from "@fortawesome/free-solid-svg-icons";
import { OverlayService } from "src/app/services/overlay.service";
import { AppStateService } from "src/app/services/app-state.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { map, skip, skipWhile } from "rxjs/operators";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { Employee } from "src/app/entity-models/employee.entity";
import { Router } from "@angular/router";
import * as moment from "moment";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { MatDrawerMode, MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import { ColumnSelector } from "src/app/shared/viewmodels/column-selector.viewmodel";
import { CallHistoryColumns, FilterSortDto, GenericDropDownDto, RefinerLocation, SharedHelper, SortDirection, SortDto, Subsidiary } from "shield.shared";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn } from "@angular/forms";
import { Moment } from "moment";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { GridDataTypes } from "src/app/shared/enums/grid-data-types.enum";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { CallHistoryRefinerService } from "./call-history-refiner.service";
import { ColumnVisabilityButtonComponent } from "src/app/shared/page-header/buttons/column-visability-button/column-visability-button.component";
import { ExcelExportButtonComponent } from "src/app/shared/page-header/buttons/excel-export-button/excel-export-button.component";
import { ButtonActions } from "src/app/shared/enums/button-actions.enum";
import { PrintButtonComponent } from "src/app/shared/page-header/buttons/print-button/print-button.component";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { CallTableRowViewModel } from "../../accounts/account.viewmodels/call-table-row.viewmodel";
import { CustomerLocationFilterComponent } from "src/app/shared/filters/customer-location-filter/customer-location-filter.component";
import { MySearchesFilterComponent as MySearchesFilterComponent } from "src/app/shared/filters/my-searches/my-searches-filter.component";
import { CallHistoryAttributesFilterComponent } from "src/app/shared/filters/call-history-attributes-filter/call-history-attributes-filter.component";
import { CallDateInformationFilterComponent } from "src/app/shared/filters/call-date-information-filter/call-date-information-filter.component";
import { FilterLocation } from "src/app/enums/filter-location";
import { PingService } from "src/app/services/ping.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { MatSortHeader } from "@angular/material/sort";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { DelineationStates } from "src/app/enums/delineation-states";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { CallHistoryZrtFilterService } from "./call-history-zrt.filter.service";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { MyListCommands } from "src/app/enums/my-list-commands";
import { BaseFilterMapService } from "src/app/services/filter-map-services/base-filter-map.service";
import { CallPicturesActivitiesFilterComponent } from "src/app/shared/filters/call-pictures-activities-filter/call-pictures-activities-filter.component";
import { ActivitiesFilterService } from "src/app/services/activities-filter.service";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";
import { XLSXService } from "src/app/services/xlsx.service";

export class CallHistoryViewmodel {
    faEye: IconDefinition = faEye;
    faPrint: IconDefinition = faPrint;
    faCloudDownloadAlt: IconDefinition = faCloudDownloadAlt;
    faCaretDown: IconDefinition = faCaretDown;
    faCalendarAlt: IconDefinition = faCalendarAlt;
    faRoad: IconDefinition = faRoad;

    private formBuilder: FormBuilder;
    pleaseWaitService: PleaseWaitService;
    overlayService: OverlayService;
    appStateService: AppStateService;
    refinerService: CallHistoryRefinerService;
    router: Router;
    callDelineationService: CallDelineationService;
    customerDelineationService: CustomerDelineationService;
    pingService: PingService;
    snackbar: SnackbarService;
    employeeDelineationService: EmployeeDelineationService;
    filterService: FilterService;
    zrtFilterService: CallHistoryZrtFilterService;
    activitiesFilterService: ActivitiesFilterService;
    projectDelineationService: ProjectDelineationService;
    accountOwnershipDelineationService: AccountOwnershipDelineationService;

    accountInput: string;
    activeRefiners: Refiner[] = [];
    activeSorts: FilterSortDto<CallHistoryColumns>[] = [];
    availableColumns: ColumnSelector[];
    baseGridYOffset = 0;
    calls: CallTableRowViewModel[];
    cityInput: string;
    columnDef: ColumnDef[];
    columnsToDisplay: string[];
    currentRefinerMap: Map<RefinerLocation, string> = new Map();
    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    dateForm: FormGroup;
    dateFormat = MY_DATE_FORMATS.display.customDateInput;
    detailHeight = 48;
    delineationState = DelineationStates.online;
    delineationStates = DelineationStates;
    drawer: MatSidenav;
    drawerMode: MatDrawerMode = "side";
    employee: Employee;
    endDateDefault: moment.Moment = moment();
    endDateInput: ElementRef;
    endMaxDate: moment.Moment = moment();
    endMinDate: moment.Moment = moment().subtract(3, "years");
    filters: FilterAndParams[] = [];
    filterDataLoaded = false;
    filterLocation = FilterLocation.callHistory;
    filterSorts: SortDto[];
    grid: GridComponent;
    gridData = new Array<GridData>();
    gridHeight = "75vh";
    gridheightOffsetDeduction = 0;
    header: PageHeaderComponent;
    headerButtons: HeaderButtonComponent[] = new Array<HeaderButtonComponent>();
    headerName = "Call History";
    isFixedLayout = false;
    isGapSet = false;
    isOnlineSubscription: Subscription;
    isSearchButtonDisabled = false;
    itemsRendedInViewPort = 13;
    lastCall = false;
    pageIndex = 0;
    pageSize = 50;
    previousRefinerMap: Map<RefinerLocation, string> = new Map();
    refinerGreaterThans = [RefinerLocation.callOnOrAfterDate];
    refinerLessThans = [RefinerLocation.callOnOrBeforeDate];
    refinerMultiselects = [
        RefinerLocation.states,
        RefinerLocation.counties,
        RefinerLocation.storeTypes,
        RefinerLocation.callType
    ];
    screenHeight: number = 0;
    selectedColumnVisibility: string[];
    selectedColumnVisibilityComunicator: string[];
    shouldResetGrid: boolean = false;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    showExpanderToggle = false;
    sideNavContent: MatSidenavContent;
    startDateDefault: moment.Moment = moment().subtract(7, "days");
    startDateInput: ElementRef;
    startMaxDate: moment.Moment = moment();
    streetInput: string;
    topGapDistance = 0;
    total?: number;
    zipInput: string;

    myListSubscription: Subscription;
    refinerServiceSubscription: Subscription;
    refinerInputChangeSubscription: Subscription;
    gridDataChangeSubscription: Subscription;
    subscriptionEmployee: Subscription;
    zrtSelectionSubscription: Subscription;

    confirmationOverlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    readonly sortFunction = (columnDef: ColumnDef) => {
        if (this.grid) {
            const filterSorts = new Array<FilterSortDto<CallHistoryColumns>>();
            this.grid.sort.sortables.forEach((item) => {
                let sortDirection: SortDirection;
                switch ((item as MatSortHeader)._sort.direction) {
                    case "asc":
                        sortDirection = SortDirection.ascending;
                        break;
                    case "desc":
                        sortDirection = SortDirection.descending;
                        break;
                    default:
                        sortDirection = SortDirection.none;
                        break;
                }
                const sortDto = new FilterSortDto<CallHistoryColumns>();
                sortDto.direction = sortDirection;
                const headerName = this.columnDef.find(
                    (cd) => cd.dataPropertyName === item.id
                ).headerName;
                if (
                    headerName === columnDef.headerName &&
                    sortDirection != SortDirection.none
                ) {
                    sortDto.column = headerName as CallHistoryColumns;
                    filterSorts.push(sortDto);
                }
            });
            this.pageIndex = 0;
            this.shouldResetGrid = true;
            this.activeSorts = filterSorts;
            void this.getCalls();
        }
    };

    constructor(
        pleaseWaitService: PleaseWaitService,
        overlayService: OverlayService,
        appStateService: AppStateService,
        refinerService: CallHistoryRefinerService,
        formBuilder: FormBuilder,
        router: Router,
        callDelineationService: CallDelineationService,
        customerDelineationService: CustomerDelineationService,
        pingService: PingService,
        snackbar: SnackbarService,
        employeeDelineationService: EmployeeDelineationService,
        filterService: FilterService,
        zrtFilterService: CallHistoryZrtFilterService,
        activitiesFilterService: ActivitiesFilterService,
        projectDelineationService: ProjectDelineationService,
        accountOwnershipDelineationService: AccountOwnershipDelineationService,
        private px3RankService: Px3DelineationService,
        private xlsxService: XLSXService,
    ) {
        this.pleaseWaitService = pleaseWaitService;
        this.overlayService = overlayService;
        this.appStateService = appStateService;
        this.refinerService = refinerService;
        this.formBuilder = formBuilder;
        this.router = router;
        this.callDelineationService = callDelineationService;
        this.customerDelineationService = customerDelineationService;
        this.pingService = pingService;
        this.snackbar = snackbar;
        this.employeeDelineationService = employeeDelineationService;
        this.filterService = filterService;
        this.zrtFilterService = zrtFilterService;
        this.activitiesFilterService = activitiesFilterService;
        this.projectDelineationService = projectDelineationService;
        this.accountOwnershipDelineationService = accountOwnershipDelineationService;

        this.dateForm = this.formBuilder.group({
            endDate: ["", [this.lessThanStartDate()]],
            startDate: ["", [this.greaterThanEndDate()]]
        });
    }

    async initialize(
        grid: GridComponent,
        header: PageHeaderComponent,
        sideNavContent: MatSidenavContent,
        drawer: MatSidenav,
        startDateInput: ElementRef,
        endDateInput: ElementRef
    ): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        this.grid = grid;
        this.header = header;
        this.sideNavContent = sideNavContent;
        this.drawer = drawer;
        this.startDateInput = startDateInput;
        this.endDateInput = endDateInput;

        const columnVisabilityComponent = new ColumnVisabilityButtonComponent();
        this.headerButtons.push(columnVisabilityComponent);

        const printButtonComponent = new PrintButtonComponent();
        this.headerButtons.push(printButtonComponent);

        const excelExportButtonComponent = new ExcelExportButtonComponent();
        this.headerButtons.push(excelExportButtonComponent);

        this.columnDef = [
            {
                headerName: "Id",
                dataPropertyName: "id",
                isAvailable: false,
                isSelected: false
            }, {
                headerName: CallHistoryColumns.createdBy,
                dataPropertyName: "createdByName",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: CallHistoryColumns.start,
                dataPropertyName: "startFormatted",
                isAvailable: true,
                isSelected: true,
            },
            {
                headerName: CallHistoryColumns.end,
                dataPropertyName: "endFormatted",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: CallHistoryColumns.duration,
                dataPropertyName: "duration",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: CallHistoryColumns.type,
                dataPropertyName: "callType",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: CallHistoryColumns.comments,
                dataPropertyName: "closingNotesFormatted",
                isAvailable: true,
                isSelected: true,
                columnClass: "text-wrap",
                dataType: GridDataTypes.text,
                clickFunction: this.onExpandComment()
            },
            {
                headerName: CallHistoryColumns.customerId,
                dataPropertyName: "customerNumber",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: CallHistoryColumns.name,
                dataPropertyName: "customerName",
                isAvailable: true,
                isSelected: true,
                clickFunction: this.onNavigateToStoreProfile()
            },
            {
                headerName: CallHistoryColumns.address,
                dataPropertyName: "address",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: CallHistoryColumns.city,
                dataPropertyName: "city",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: CallHistoryColumns.state,
                dataPropertyName: "state",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: CallHistoryColumns.zip,
                dataPropertyName: "zip",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: CallHistoryColumns.hasPictures,
                dataPropertyName: "hasPictures",
                isAvailable: true,
                isSelected: false
            },
            {
                headerName: CallHistoryColumns.px3Rank,
                dataPropertyName: "px3Rank",
                isAvailable: true,
                isSelected: true,
            },
        ];
        const tempFilters: FilterAndParams[] = [];

        tempFilters.push({ filter: MySearchesFilterComponent });
        tempFilters.push({ filter: CustomerLocationFilterComponent, zrtFilterService: this.zrtFilterService });
        tempFilters.push({ filter: CallDateInformationFilterComponent });
        tempFilters.push({ filter: CallPicturesActivitiesFilterComponent });
        tempFilters.push({ filter: CallHistoryAttributesFilterComponent });

        this.filters = tempFilters;

        const availableColumns = new Array<ColumnSelector>();

        this.columnsToDisplay = this.columnDef
            .filter((cd) => {
                if (cd.isAvailable) {
                    const column: ColumnSelector = {
                        name: cd.headerName,
                        isSelected: cd.isSelected,
                        columns: null
                    };
                    availableColumns.push(column);
                }
            })
            .map((cd) => cd.headerName);
        this.availableColumns = availableColumns;

        if (this.refinerService.areDefaultsSet) {
            this.populateInputsFromRefiners();
        }

        if (
            !this.refinerServiceSubscription ||
            this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription = this.refinerService.refiners$.pipe(
                skipWhile(() => !this.filterDataLoaded && !this.refinerService.areDefaultsSet)
            ).subscribe(
                () => {
                    this.onRefinersChange();
                }
            );
        }

        if (
            !this.refinerInputChangeSubscription ||
            this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription = this.refinerService.refinerInputChange$.subscribe(
                (refiner) => {
                    if (refiner) {
                        this.setInputFromRefiner(refiner);

                        if (refiner.shouldSearchWhenCleared && !refiner.value) {
                            this.onSearch();
                        }
                    }

                    if (this.header) {
                        if (this.refinerService.refiners.length === 0) {
                            this.header.expanded = false;
                        } else {
                            this.header.expanded = true;
                        }
                    }
                    this.isGapSet = false;
                }
            );
        }

        if (
            !this.gridDataChangeSubscription ||
            this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription = this.grid.dataSourceChange.subscribe(
                (dc) => {
                    if (dc) {
                        this.shouldWait$.next(true);
                    }
                }
            );
        }

        if (!this.isOnlineSubscription || this.isOnlineSubscription.closed) {
            this.isOnlineSubscription = this.pingService.online.subscribe(() => {
                this.delineationState = this.callDelineationService.getDelineationState();
            });
        }

        if (!this.subscriptionEmployee || this.subscriptionEmployee.closed) {
            this.subscriptionEmployee = this.appStateService.currentEmployee
                .pipe(
                    map(async (employee) => {
                        this.employee = employee;
                        if (employee) {
                            await this.setFilterData();
                            await this.getCallHistoryBatch(0);
                        } else {
                            this.calls = [];
                        }
                    })
                )
                .subscribe();
        }

        if (
            !this.zrtSelectionSubscription ||
            this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription = this.zrtFilterService.observableSelectedZrtsHandleRefiners(this.refinerService).subscribe();
        }

        if (!this.myListSubscription ||
            this.myListSubscription.closed
        ) {
            this.myListSubscription = this.filterService.myListCommandObservable.pipe(skip(1)).subscribe(async (command) => {
                switch (command) {
                    case MyListCommands.toggleOn:
                    case MyListCommands.toggleOff:
                        this.getCallHistoryBatch(0, true);
                        break;
                    case MyListCommands.push:
                        this.getMyListIds();
                        break;
                    default:
                        break;

                }
            });
        }
    }

    calculateGap(): void {
        if (this.sideNavContent) {
            setTimeout(() => {
                this.topGapDistance =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.top ?? 0;
                this.gridheightOffsetDeduction =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.bottom ??
                    0;
                if (
                    this.baseGridYOffset === 0 &&
                    this.gridheightOffsetDeduction !== 0
                ) {
                    this.baseGridYOffset = this.gridheightOffsetDeduction;
                }
                this.gridHeight =
                    75 * (this.screenHeight / 100) +
                    (this.baseGridYOffset - this.gridheightOffsetDeduction) +
                    "px";
            }, 0);
            this.isGapSet = true;
        }
    }

    checkSearchError(): void {
        if (this.dateForm) {
            if (
                this.dateForm
                    .get("endDate")
                    .hasError("dateLessThanStartDate") ||
                this.dateForm
                    .get("startDate")
                    .hasError("dateGreaterThanEndDate")
            ) {
                this.isSearchButtonDisabled = true;
            } else {
                this.isSearchButtonDisabled = false;
            }
        }
    }

    async getCallHistoryBatch(index: number, force?: boolean) {
        if (!this.refinerService.areDefaultsSet) return;

        const areRefinersTheSame = this.resolvePageIndex(index);
        if (
            this.total != this.gridData?.length ||
            !areRefinersTheSame ||
            this.shouldResetGrid ||
            force
        ) {
            await this.getCalls();
        }
    }

    greaterThanEndDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            const momentStart: Moment = control.value as Moment;

            if (momentStart) {
                const startDate = momentStart.startOf("day").valueOf();
                this.endMinDate = momentStart;

                if (this.dateForm.controls.endDate.value) {
                    const endDate = (this.dateForm.controls.endDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (startDate > endDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.endMinDate = null;
            }

            this.checkSearchError();

            return forbidden ? { dateGreaterThanEndDate: true } : null;
        };
    }

    lessThanStartDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            if (control.value) {
                const endDate = (control.value as Moment)
                    .startOf("day")
                    .valueOf();
                this.startMaxDate = control.value;

                if (this.dateForm.controls.startDate.value) {
                    const startDate = (this.dateForm.controls.startDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (endDate < startDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.startMaxDate = moment();
            }

            this.checkSearchError();

            return forbidden ? { dateLessThanStartDate: true } : null;
        };
    }

    async getCalls(): Promise<void> {
        if (!this.employee) {
            return;
        }
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(
            this.shouldWait$
        );

        const zrtsToAdd = this.zrtFilterService.selectedZrts.map(v => v.zrt);
        this.activeRefiners = this.refinerService.refiners.slice();

        const result = await this.callDelineationService.getCallHistoryBatch(
            this.employee.id,
            this.employee.searchableZrt,
            this.activeRefiners,
            this.pageSize,
            this.pageIndex * this.pageSize,
            this.activeSorts,
            zrtsToAdd,
            this.lastCall
        );
        if (!result) {
            this.shouldResetGrid = true;
            this.shouldWait$.next(true);
            return;
        }
        const px3Ranks = new Map((await this.px3RankService.getAll()).map((pr) => [pr.id, pr.rank]));
        const calls = result.values.map((v) => new CallTableRowViewModel(v, px3Ranks.get(v.px3RankId)));
        this.total = result.getCount();
        this.isGapSet = false;

        this.setDataSource(calls);

        this.shouldResetGrid = false;
        this.shouldWait$.next(true);
    }

    setDataSource(calls: CallTableRowViewModel[]): void {
        let gridData = !this.shouldResetGrid && this.pageIndex ? this.gridData.slice() : new Array<GridData>();
        const largestIndex = gridData.length;
        if (!largestIndex) {
            this.calls = [];
        }

        for (const call of calls) {
            if (this.lastCall) {
                const found = gridData.find(
                    (gi) => gi.data.customerId === call.customerId
                );
                if (found) continue;
            }
            const gridItem: GridData = {
                data: call,
                detailArrayName: "",
                isExpanded: false,
                index: largestIndex + calls.indexOf(call)
            };
            gridData.push(gridItem);
            this.calls.push(call);
        }
        this.gridData = gridData;

        this.dataSource = new TableVirtualScrollDataSource(
            this.gridData
        );
    }

    setDefaultFilter(): void {
        if (!(this.zrtFilterService.employeeZrts?.length) || !this.employee) {
            return;
        }
        if (!this.refinerService.areDefaultsSet) {
            this.dateForm.controls.startDate.setValue(this.startDateDefault);
            this.onAddStartDateRefiner();

            this.dateForm.controls.endDate.setValue(this.endDateDefault);
            this.onAddEndDateRefiner();
            const refiner = this.zrtFilterService.createDefaultZrtRefiner(this.employee);
            const isCorporateUser = Helper.isEmployeeCustomerServiceOrAdmin(this.employee);
            if (refiner) {
                this.refinerService.checkAndUpdateRefiner(refiner, true);
                if (isCorporateUser) {
                    this.zrtFilterService.defaultZrtSelection = this.zrtFilterService.selectedZrts;
                }
            }
            this.refinerService.isCorporateUser = isCorporateUser;
            this.refinerService.areDefaultsSet = true;
        }
        this.filterDataLoaded = true;
    }

    async setActivitiesFilter(): Promise<void> {
        const projectResponse = await this.projectDelineationService.getDropDown();
        if (!projectResponse) { return; }

        const projects = projectResponse.values;
        this.activitiesFilterService.projects = projects ?? new Array<GenericDropDownDto>();

        const chainsResponse = await this.accountOwnershipDelineationService.getAllInDropDown();
        if (chainsResponse?.values?.length) {
            this.activitiesFilterService.chains = chainsResponse.values.sort((a, b) => a.name.localeCompare(b.name));
        } else {
            this.activitiesFilterService.chains = new Array<AccountOwnership>();
        }
    }

    async setFilterData(): Promise<void> {
        if (Helper.isEmployeeCustomerServiceOrAdmin(this.employee)) {
            this.zrtFilterService.areas = await this.filterService.getAreas();
            const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts();
            if (zrtsResponse && zrtsResponse?.values) {
                this.zrtFilterService.employeeZrts = zrtsResponse.values;
            }
        } else {
            const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts(this.employee.searchableZrt ?? undefined);
            if (zrtsResponse && zrtsResponse?.values) {
                this.zrtFilterService.employeeZrts = zrtsResponse.values;
            }
        }

        void this.setActivitiesFilter();
        this.setDefaultFilter();
    }

    onAddAccountRefiner(): void {
        if (this.accountInput) {
            this.refinerService.addRefiner(
                RefinerLocation.account,
                this.accountInput,
                "customerName"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.account
            )
        }
    }

    onAddStreetRefiner(): void {
        if (this.streetInput) {
            this.refinerService.addRefiner(
                RefinerLocation.street,
                this.streetInput,
                "address"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.street
            )
        }
    }

    onAddCityRefiner(): void {
        if (this.cityInput) {
            this.refinerService.addRefiner(
                RefinerLocation.city,
                this.cityInput,
                "city"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.city
            )
        }
    }

    onAddZipRefiner(): void {
        if (this.zipInput) {
            this.refinerService.addRefiner(
                RefinerLocation.zipCodes,
                this.zipInput,
                "zip"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.zipCodes
            )
        }
    }

    onAddEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.endDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, MY_DATE_FORMATS.display.dateInput) &&
            !this.dateForm.controls.endDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.callOnOrBeforeDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.callOnOrBeforeDate,
                this.endDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.endDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.callOnOrBeforeDate,
                    this.endDateInput.nativeElement.value,
                    "endFormatted"
                );
            }
        } else {
            if (!date) {
                this.refinerService.removeRefinerByLocation(RefinerLocation.callOnOrBeforeDate);
            }
        }
    }

    onAddStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.startDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, MY_DATE_FORMATS.display.dateInput) &&
            !this.dateForm.controls.startDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.callOnOrAfterDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.callOnOrAfterDate,
                this.startDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.startDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.callOnOrAfterDate,
                    this.dateForm.controls.startDate.value?.format(
                        this.startDateInput.nativeElement.value
                    ),
                    "endFormatted"
                );
            }
        } else {
            if (!date) {
                this.refinerService.removeRefinerByLocation(RefinerLocation.callOnOrAfterDate);
            }
        }
    }

    onButtonAction(value: any): void {
        switch (value) {
            case ButtonActions.columnVisibility:
                this.selectedColumnVisibilityComunicator = value;
                break;
            case ButtonActions.print:
                this.grid.onPrint();
                break;
            case ButtonActions.exportToExcel:
                this.exportToExcel();
                break;
            default:
                break;
        }
    }

    async exportToExcel(): Promise<void> {
        await this.pleaseWaitService.withSpinnerShowing(async () => {
            const px3Ranks = new Map((await this.px3RankService.getAll()).map(r => [r.id, r.rank]));
            const zrtsToAdd = this.zrtFilterService.selectedZrts.map(v => v.zrt);
            const result = await this.callDelineationService.getCallHistoryBatch(
                this.employee.id,
                this.employee.searchableZrt,
                this.activeRefiners,
                10000,
                0,
                this.activeSorts,
                zrtsToAdd,
                this.lastCall
            );
            const columns = [
                CallHistoryColumns.createdBy,
                CallHistoryColumns.start,
                CallHistoryColumns.end,
                CallHistoryColumns.duration,
                CallHistoryColumns.type,
                CallHistoryColumns.comments,
                CallHistoryColumns.customerId,
                CallHistoryColumns.name,
                CallHistoryColumns.address,
                CallHistoryColumns.city,
                CallHistoryColumns.state,
                CallHistoryColumns.zip,
                CallHistoryColumns.px3Rank
            ];
            const data = result.values.map(e => {
                const duration = moment(e.stopTime).diff(e.startTime, "seconds");
                const durationHr = Math.floor(duration / 3600).toString();
                const durationMin = Math.floor((duration % 3600) / 60);
                const durationMinStr = durationMin < 10 ? '0' + durationMin : durationMin;
                return [
                    e.createdByName,
                    e.startTime,
                    e.stopTime,
                    `${durationHr}:${durationMinStr}`,
                    e.callType,
                    e.closingNotes,
                    e.customerNumber,
                    e.customerName,
                    e.address,
                    e.city,
                    e.state,
                    e.zip,
                    px3Ranks.get(e.px3RankId) ?? '',
                ];
            });
            return await this.xlsxService.saveAsXLSX(data, columns, 'CallHistory');
        });
    }

    onButtonClickEvent(value: HeaderButtonComponent) {
        if (value.onClick) {
            value.onClick();
        }
    }

    onReset(): void {
        this.refinerService.resetRefiners();
    }

    onExpandComment(): (event: MouseEvent, index: number) => void {
        return async (event: MouseEvent, index: number) => {
            const closingNotes = this.gridData[index].data.closingNotes;
            if (event && closingNotes?.length > 50) {
                if (this.gridData[index].data.closingNotesFormatted?.length < closingNotes.length) {
                    this.gridData[index].data.closingNotesFormatted = closingNotes;
                } else {
                    this.gridData[index].data.closingNotesFormatted = closingNotes.slice(0, 50) + "...";
                }
            }

        }
    }

    onHeaderExpansionChange(): void {
        this.isGapSet = false;
    }

    onLastCallCheckboxChange(): void {
        this.shouldResetGrid = true;

        if (!this.lastCall) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.lastCallOnAccount,
                true,
                false
            );
            return;
        }
        const refiner = new Refiner();
        refiner.location = RefinerLocation.lastCallOnAccount;
        refiner.dataValue = refiner.value = BaseFilterMapService.yes;

        this.refinerService.checkAndUpdateRefiner(refiner);
    }

    onNavigateToStoreProfile(): (event: MouseEvent, index: number) => void {
        return async (event: MouseEvent, index: number) => {
            this.navigateToStoreProfile(this.gridData[index].data.customerId)
        }
    }

    onRefinersChange() {
        this.setRefinerInput();
        if (this.header) {
            if (this.refinerService.refiners.length === 0) {
                this.header.expanded = false;
            } else {
                this.header.expanded = true;
            }
        }

        // Search on removal
        this.getCalls();
    }

    async onSearch(): Promise<void> {
        this.onAddAccountRefiner();
        this.onAddStreetRefiner();
        this.onAddCityRefiner();
        this.onAddZipRefiner();
        this.onAddStartDateRefiner();
        this.onAddEndDateRefiner();

        await this.getCallHistoryBatch(0);
    }

    navigateToStoreProfile(customerId: string): void {
        void this.router.navigate(["/accounts", customerId, "profile"]);
    }

    populateInputsFromRefiners(): void {
        for (const refiner of this.refinerService.refiners) {
            this.setInputFromRefiner(refiner);
        }
    }

    resolvePageIndex(index: number): boolean {
        this.currentRefinerMap = new Map<RefinerLocation, string>();
        for (const refiner of this.refinerService.refiners ?? []) {
            this.currentRefinerMap.set(refiner.location, refiner.dataValue ?? refiner.value);
        }

        const rtn = SharedHelper.compareMaps(
            this.currentRefinerMap,
            this.previousRefinerMap
        );

        if (rtn && this.total != this.gridData?.length && index !== 0) {
            this.pageIndex++;
        } else {
            this.previousRefinerMap = new Map(this.currentRefinerMap);
            this.pageIndex = 0;
        }
        return rtn;
    }

    setColumnVisibilityCommunicator(event: string[]) {
        this.selectedColumnVisibilityComunicator = event;
    }

    setRefinerInput(): void {
        if (!this.filterDataLoaded) return;

        this.setDefaultFilter();

        if (!this.refinerService.refiners.filter(v =>
            v.location == RefinerLocation.zrtByArea ||
            v.location == RefinerLocation.zrtByEmployee
        ).length
        ) {
            this.zrtFilterService.selectedZrts = [];
        }

        this.dateForm.controls.startDate.setValue(null);
        this.dateForm.controls.endDate.setValue(null);

        this.lastCall = false;

        this.accountInput = null;
        this.streetInput = null;
        this.cityInput = null;
        this.zipInput = null;

        this.populateInputsFromRefiners();
    }

    showFilters(): void {
        this.drawer.toggle();
        this.isGapSet = false;
    }

    unsubscribe(): void {
        if (
            this.refinerServiceSubscription &&
            !this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription.unsubscribe();
        }
        if (
            this.refinerInputChangeSubscription &&
            !this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription.unsubscribe();
        }
        if (
            this.gridDataChangeSubscription &&
            !this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription.unsubscribe();
        }
        if (
            this.subscriptionEmployee &&
            !this.subscriptionEmployee.closed
        ) {
            this.subscriptionEmployee.unsubscribe();
        }
        if (
            this.isOnlineSubscription &&
            !this.isOnlineSubscription.closed
        ) {
            this.isOnlineSubscription.unsubscribe();
        }
        if (this.zrtSelectionSubscription && !this.zrtSelectionSubscription.closed) {
            this.zrtSelectionSubscription.unsubscribe();
        }
        if (this.myListSubscription && !this.myListSubscription.closed) {
            this.myListSubscription.unsubscribe();
        }
    }

    private getMyListIds(): void {
        setTimeout(async () => {
            if (this.total > 10000) {
                this.snackbar.showWarning("Total entry count is too high. Cannot push more than 10,000 records at once.");
                this.filterService.myListEntries$.next(undefined);
            } else {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                    this.shouldWait$
                );
                const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                data.header = "Confirmation";
                data.message =
                    "Would you like to push all entries to My List?";
                data.buttonLeftText = "Cancel";
                data.buttonLeftFunction = () => {
                    this.confirmationOverlayRef.close(data);
                };
                data.buttonRightText = "Yes";
                data.buttonRightFunction = () => {
                    data.isConfirmed = true;
                    this.confirmationOverlayRef.close(data);
                };

                this.confirmationOverlayRef = this.overlayService.open(
                    ConfirmationDialogComponent,
                    data
                );

                this.confirmationOverlayRef.afterClosed$
                    .pipe(
                        map(async (event) => {
                            if (event && event.data && event.data.isConfirmed) {
                                this.filterService.myListEntries$.next(await this.getIdsForMyList());
                                this.shouldWait$.next(true);
                            } else {
                                this.filterService.myListEntries$.next(undefined);
                                this.shouldWait$.next(true);
                            }
                        })
                    )
                    .subscribe();
            }
        }, 0);
    }

    private async getIdsForMyList(): Promise<string[]> {
        let rtn = this.dataSource.filteredData.map((gd) => gd.data.id);

        if (this.callDelineationService.getOnlineState() && rtn.length < this.total) {
            const gridIdResponse = await this.callDelineationService.getCallHistoryBatch(
                this.employee.id,
                this.employee.zrt,
                this.activeRefiners,
                10000,
                this.pageIndex * this.pageSize,
                this.activeSorts,
                this.zrtFilterService.selectedZrts.map(v => v.zrt),
                this.lastCall
            );
            if (gridIdResponse?.values) {
                const gridIds = gridIdResponse.values.map(v => v.id);
                rtn = rtn.concat(gridIds.filter((v) => !rtn.includes(v)));
            }
        }

        return rtn;
    }

    private setInputFromRefiner(refiner: Refiner): void {
        switch (refiner.location) {
            case RefinerLocation.zrtByArea:
            case RefinerLocation.zrtByEmployee:
                this.zrtFilterService.applyRefiner(refiner);
                break;
            case RefinerLocation.account:
                this.accountInput = refiner.value;
                break;
            case RefinerLocation.street:
                this.streetInput = refiner.value;
                break;
            case RefinerLocation.city:
                this.cityInput = refiner.value;
                break;
            case RefinerLocation.zipCodes:
                this.zipInput = refiner.value;
                break;
            case RefinerLocation.callOnOrAfterDate:
                const startDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.startDate.setValue(
                    startDate
                );
                break;
            case RefinerLocation.callOnOrBeforeDate:
                const endDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.endDate.setValue(
                    endDate
                );
                break;
            case RefinerLocation.lastCallOnAccount:
                if (!refiner.value) {
                    this.lastCall = false;
                    this.onLastCallCheckboxChange();
                }
                break;
            default:
                break;
        }
    }
}
