import { RefinerGroupDto } from "./refiner-group.dto";
import { SortDto } from "./sort.dto";

export class FilterRequestDto {
    id: string;
    refinerGroups: RefinerGroupDto[];
    pageSize: number | undefined;
    startIndex: number;
    filterSorts: SortDto[];

    static validate(f: FilterRequestDto): FilterRequestDto {

        if (!f.refinerGroups) {
            f.refinerGroups = [];
        }

        if (!f.pageSize) {
            f.pageSize = undefined;
        }

        if (!f.startIndex) {
            f.startIndex = 0;
        }

        if (!f.filterSorts) {
            f.filterSorts = [];
        }

        return f;
    }
}
