export class CustomerWholesalerDto {
    wholesalerId: string;
    customerCode: string;
    createdUtcDateTime: string;
    createdUserId: string;
    createdUserZrt: string;
    createdUserFullName: string;
    modifiedUtcDateTime: string;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
}
