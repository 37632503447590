<div class="card card--last-order m-0">
    <div class="card-header">Last Order</div>
        <ng-container *ngIf="!lastOrderLineItems?.length">
            <div class="d-flex justify-content-center align-items-center card-body p-0">
                <div class="customer-overview-tiles-empty-state">
                    <div class="empty-state-header">
                        <div class="icon">
                            <span class="material-icons"
                            >library_add_check</span
                            >
                        </div>
                        <h6>No orders have been placed for this customer.</h6>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="card-body p-0" *ngIf="lastOrderLineItems?.length">
            <div class="card-view-port">
                <table class="table table-striped table-hover mb-0">
                    <thead>
                    <tr>
                        <th class="column-width-md" scope="col" id="productName" (click)="onSortBy($event)">
                            Product
                            <span *ngIf="sortBy && sortBy.name === 'productName'" class="material-icons">
                                {{ sortBy.icon }}
                            </span>
                        </th>
                        <th class="column-width-md" scope="col" id="wholesalerName" (click)="onSortBy($event)">
                            Wholesaler
                            <span *ngIf="sortBy && sortBy.name === 'wholesalerName'" class="material-icons">
                                {{ sortBy.icon }}
                            </span>
                        </th>
                        <th class="column-width-sm" scope="col" id="quantity" (click)="onSortBy($event)">
                            Qty
                            <span *ngIf="sortBy && sortBy.name === 'quantity'" class="material-icons">
                                {{ sortBy.icon }}
                            </span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of lastOrderLineItems">
                        <td>{{ item.productName }}</td>
                        <td>{{ item.wholesalerName }}</td>
                        <td>{{ item.quantity }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
</div>
