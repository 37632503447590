import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { HeaderButtonComponent } from '../page-header/buttons/header-button/header-button.component';

@Component({
  selector: 'button-list',
  templateUrl: './button-list.component.html',
  styleUrls: ['./button-list.component.scss']
})
export class ButtonListComponent implements OnInit {

  constructor() { }

  spinner: IconDefinition = faSpinner;

  @Input() buttons: HeaderButtonComponent[] = [];
  @Output() onButtonClick = new EventEmitter();

  ngOnInit(): void {

  }

}
