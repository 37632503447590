import { TaxCalculationRateDto } from "shield.shared";
import { TaxRate } from "src/app/entity-models/tax-rate.entity";

export class TaxRateConverterService {

    static taxRateToTaxCalculationRateDto(entity: TaxRate): TaxCalculationRateDto {

        const dto = new TaxCalculationRateDto();

        dto.id = entity.id;
        dto.createdUserFullName = entity.createdUserFullName;
        dto.createdUserId = entity.createdUserId;
        dto.createdUserZrt = entity.createdUserZrt;
        dto.createdUtcDateTime = entity.createdUtcDateTime ? entity.createdUtcDateTime.toISOString() : new Date().toISOString();
        dto.isActive = entity.isActive;
        dto.isPrepaid = entity.isPrepaid;
        dto.locality = entity.locality;
        dto.modifiedUserFullName = entity.modifiedUserFullName;
        dto.modifiedUserId = entity.modifiedUserId;
        dto.modifiedUserZrt = entity.modifiedUserZrt;
        dto.modifiedUtcDateTime = entity.modifiedUtcDateTime ? entity.modifiedUtcDateTime.toISOString() : null;
        dto.rowversion = entity.rowversion;
        dto.stateId = entity.stateId;
        dto.taxCalculationMethodId = entity.taxCalculationMethodId;
        dto.taxDivisionId = entity.taxDivisionId;
        dto.taxRate = entity.taxRate;
        dto.taxSubmittedToId = entity.taxSubmittedToId;
        dto.taxTypeId = entity.taxTypeId;

        return dto;
    }

    static taxCalculationRateDtoToTaxRate(dto: TaxCalculationRateDto): TaxRate {

        const entity = new TaxRate();

        entity.id = dto.id;
        entity.createdUserFullName = dto.createdUserFullName;
        entity.createdUserId = dto.createdUserId;
        entity.createdUserZrt = dto.createdUserZrt;
        entity.createdUtcDateTime = dto.createdUtcDateTime ? new Date(dto.createdUtcDateTime) : new Date();
        entity.isActive = dto.isActive;
        entity.isPrepaid = dto.isPrepaid;
        entity.locality = dto.locality;
        entity.modifiedUserFullName = dto.modifiedUserFullName;
        entity.modifiedUserId = dto.modifiedUserId;
        entity.modifiedUserZrt = dto.modifiedUserZrt;
        entity.modifiedUtcDateTime = dto.modifiedUtcDateTime ? new Date(dto.modifiedUtcDateTime) : null;
        entity.rowversion = dto.rowversion;
        entity.stateId = dto.stateId;
        entity.taxCalculationMethodId = dto.taxCalculationMethodId;
        entity.taxDivisionId = dto.taxDivisionId;
        entity.taxRate = dto.taxRate;
        entity.taxSubmittedToId = dto.taxSubmittedToId;
        entity.taxTypeId = dto.taxTypeId;

        return entity;
    }
}
