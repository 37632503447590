import { Component, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterService } from "src/app/services/filter.service";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { GenericLookup, RefinerLocation, TransactionLineItemType, valueSeparator } from "shield.shared";
import { TransactionsViewmodel } from "./transactions.viewmodel";
import { Product } from "src/app/entity-models/product.entity";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";

@Component({
    selector: "app-transactions-filter",
    templateUrl: "./transactions-filter.component.html",
    styleUrls: ["./transactions-filter.component.scss"]
})
export class TransactionsFilterComponent extends FilterBaseComponent implements OnInit, OnDestroy  {
    //view childs
    @ViewChild("statusSelect") statusSelect: MatSelect;
    @ViewChild("typeSelect") typeSelect: MatSelect;
    @ViewChild("productSelect") productSelect: MatSelect;

    //public vars
    contentComponent: Type<TransactionsFilterComponent>;
    icon = "monetization_on";
    name = "Transactions";
    viewmodel = new TransactionsViewmodel(this.filterService, this.productDelineationService);

    constructor(private filterService: FilterService, private productDelineationService: ProductDelineationService) {
        super();
    }

    ngOnInit(): void {
        if (!this.viewmodel.productSubscription || this.viewmodel.productSubscription.closed) {
            this.viewmodel.productSubscription = this.productDelineationService.observableAllProducts.subscribe(
                (allProducts) => {
                    if (allProducts?.size > 0) {
                        this.viewmodel.products = Array.from(allProducts.values());
                        this.setInitialized();
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if(this.viewmodel.productSubscription && !this.viewmodel.productSubscription.closed){
            this.viewmodel.productSubscription.unsubscribe();
        }
    }

    //events
    onAddReceiptRefiner(event?: KeyboardEvent): void {
        this._refinerService.onInputChange(
            RefinerLocation.receipt,
            this.viewmodel.receiptInput
        );
        if (
            this.viewmodel.receiptInput &&
            (!event || this.inputSubmitKeys.includes(event.key))
        ) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.receipt;
            refiner.value = this.viewmodel.receiptInput;
            refiner.dataPropertyName = "retailReceiptNumber";
            this._refinerService.addRefiners(refiner);
        }
    }

    onAddUinRefiner(event?: KeyboardEvent) {
        this._refinerService.onInputChange(
            RefinerLocation.uin,
            this.viewmodel.uinInput
        );
        if (
            this.viewmodel.uinInput &&
            (!event || this.inputSubmitKeys.includes(event.key))
        ) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.uin;
            refiner.value = this.viewmodel.uinInput;
            refiner.dataPropertyName = "uin";

            this._refinerService.checkAndUpdateRefiner(refiner);
        }
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.receipt:
                    if (refiner.value !== this.viewmodel.receiptInput) {
                        this.viewmodel.receiptInput = refiner.value;
                    }
                    break;
                case RefinerLocation.status:
                    if (refiner.value !== this.viewmodel.selectedStatus) {
                        this.viewmodel.selectedStatus = refiner.value;
                    }
                    break;
                case RefinerLocation.transactionType:
                    const selectedTypes = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedTypes = new Array<GenericLookup<TransactionLineItemType>>();

                    if (selectedTypes) {
                        for (const type of selectedTypes) {
                            const found = this.viewmodel.types.find(
                                (st) => st.id?.toString() === type
                            );
                            if (found) {
                                rtnSelectedTypes.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedTypes = rtnSelectedTypes.length > 0
                        ? rtnSelectedTypes
                        : [this.viewmodel.allType];
                    break;
                case RefinerLocation.products:
                    const selectedProductIds = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedProducts = new Array<Product>();

                    if (selectedProductIds) {
                        for (const id of selectedProductIds) {
                            const found = this.viewmodel.products.find(
                                (sp) => sp.id === id.trim()
                            );
                            if (found) {
                                rtnSelectedProducts.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedProducts = rtnSelectedProducts;
                    break;
                case RefinerLocation.uin:
                    if (refiner.value !== this.viewmodel.uinInput) {
                        this.viewmodel.uinInput = refiner.value;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onOpenedProductsSelect() {
        this.viewmodel.isProductsSearchStale = true;
        this.viewmodel.selectedProductsHold = this.viewmodel.selectedProducts;
    }

    onClosedProductsSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.products;
        refiner.value = this.viewmodel.selectedProducts.length
            + " Swisher Product(s)";
        refiner.dataPropertyName = "id";
        refiner.dataValue = this.viewmodel.selectedProducts
            .map((vm) => vm.id)
            .join(valueSeparator);

        this._refinerService.checkAndUpdateRefiner(refiner, true);

        if (this.viewmodel.selectedProductsHold === this.viewmodel.selectedProducts) {
            this.viewmodel.isProductsSearchStale = false;
        }
    }

    onOpenedStatusChange(): void {
        if (this.statusSelect && !this.statusSelect.panelOpen) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.status;
            refiner.value = this.viewmodel.selectedStatus;
            refiner.dataPropertyName = "status";
            refiner.dataValue = this.viewmodel.selectedStatus;

            this._refinerService.checkAndUpdateRefiner(refiner, true);
        }
    }

    onOpenedTypeChange(): void {
        if (this.viewmodel.selectedTypes.includes(this.viewmodel.allType)) {
            this._refinerService.removeRefinerByLocation(RefinerLocation.transactionType, true, false);
        }
        else {
            if (!this.typeSelect.panelOpen) {
                const typeValues = new Array<string>();
                const typeDataValues = new Array<TransactionLineItemType>();
                for(const type of this.viewmodel.selectedTypes) {
                    typeValues.push(type.description);
                    typeDataValues.push(type.id);
                }

                const refiner = new Refiner();
                refiner.location = RefinerLocation.transactionType;
                refiner.value = typeValues.join(", ");
                refiner.dataPropertyName = "description";
                refiner.dataValue = typeDataValues.join(valueSeparator);

                this._refinerService.checkAndUpdateRefiner(refiner, true);
            }
        }
    }

    onSelectionTypeChange(): void {
        if (
            this.viewmodel.isAllTypePresent &&
            this.viewmodel.selectedTypes.length > 1
        ) {
            const index = this.viewmodel.selectedTypes.findIndex(
                (s) => s === this.viewmodel.allType
            );
            if (index !== -1) {
                this.viewmodel.selectedTypes.splice(index, 1);
                this.viewmodel.isAllTypePresent = false;
            }
        } else if (
            !this.viewmodel.isAllTypePresent &&
            this.viewmodel.selectedTypes.includes(
                this.viewmodel.allType
            )
        ) {
            this.viewmodel.selectedTypes = [
                this.viewmodel.allType
            ];
            this.viewmodel.isAllTypePresent = true;
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.receipt;
        const receiptRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.receipt
        );
        if (receiptRefiner) {
            this.onInputChange(receiptRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.status;
        const statusRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.status
        );
        if (statusRefiner) {
            this.onInputChange(statusRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.transactionType;
        const typeRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.transactionType
        );
        if (typeRefiner) {
            this.onInputChange(typeRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.products;
        const productsRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.products
        );
        if (productsRefiner) {
            this.onInputChange(productsRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.uin;
        const uinRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.uin
        );
        if (uinRefiner) {
            this.onInputChange(uinRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }
}
