import {
    CustomerTypeEnum,
    GenericLookup
} from "shield.shared";
import { Address } from "./address.entity";

export class CustomerMarker {
    customerId?: string;
    customerNumber?: string;
    title?: string;
    label?: string;
    customerType?: GenericLookup<CustomerTypeEnum>;
    icon?: google.maps.Icon;
    address?: Address;
    addressText?: string;
    cityStateZip?: string;
    availability?: string;
    px3Rank?: string;
    active?: string;
    routed?: boolean;
    lastCall?: string;
}
