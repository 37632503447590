import { Injectable } from "@angular/core";
import { FilterRequestV2Dto, FilterSortDto, GenericRequestDto, GenericResponseDto, RefinerDto, SortDto, TransactionBatchParamsDto, TransactionsListColumns } from "shield.shared";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { TransactionLineItem } from "src/app/entity-models/transaction-line-item.entity";
import { Transaction } from "src/app/entity-models/transaction.entity";
import { TransactionOfflineService } from "../offline-services/transaction-offline.service";
import { TransactionOnlineService } from "../online-services/transaction-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { TransactionListFilterMapService } from "../filter-map-services/transaction-list-filter-map.service";
import { DelineationContext } from "./delineation-context.service";
import { DatabaseService } from "../database.service";

@Injectable()
export class TransactionDelineationService extends DelineationContext<TransactionDelineationService, string>{

    constructor(private transactionOfflineService: TransactionOfflineService
        , private transactionOnlineService: TransactionOnlineService
        , snackbarService: SnackbarService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService) {
            super(dbService, datasourceDelineationService, snackbarService);
        }

    async getById(id: string): Promise<GenericResponseDto<Transaction>> {

        const offline = (key: string) => {
            return this.transactionOfflineService.getById(key);
        }
        const online = (key: string) => {
            return this.transactionOnlineService.getById(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, Transaction>(id, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getTransactionBatch(
        id: string,
        refiners: Refiner[],
        pageSize: number | null,
        startIndex: number,
        filterSorts: FilterSortDto<TransactionsListColumns>[]
    ): Promise<GenericResponseDto<Transaction[]>> {

        const key = new TransactionBatchParamsDto();
        key.filterRequestDto = new FilterRequestV2Dto();
        key.filterRequestDto.id = id;
        key.filterRequestDto.filters = TransactionListFilterMapService.mapFilterData(refiners);
        key.filterRequestDto.pageSize = pageSize;
        key.filterRequestDto.startIndex = startIndex;
        key.filterRequestDto.filterSorts = filterSorts;
        key.employeeId = id;


        const offline = (key: TransactionBatchParamsDto) => {
            return this.transactionOfflineService.getTransactionBatch(key);
        }
        const online = (key: TransactionBatchParamsDto) => {
            return this.transactionOnlineService.getTransactionBatch(key);
        }
        const response = await this.datasourceDelineationService.makeCall<TransactionBatchParamsDto, Transaction[]>(key, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async saveInvoiceDetails(transactionLineItems: TransactionLineItem[], transactionId: string): Promise<GenericResponseDto<Transaction>> {

        if (transactionLineItems?.length) {

            const request = new GenericRequestDto<TransactionLineItem[]>();
            request.id = transactionId;
            request.values = transactionLineItems;

            const offline = (key: GenericRequestDto<TransactionLineItem[]>) => {
                return this.transactionOfflineService.saveInvoiceDetails(key);
            }
            const online = (key: GenericRequestDto<TransactionLineItem[]>) => {
                return this.transactionOnlineService.saveInvoiceDetails(key);
            }
            const response = await this.datasourceDelineationService
                .makeCall<GenericRequestDto<TransactionLineItem[]>, Transaction>(request, offline, online);

            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }
    }
}
