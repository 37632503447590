import { Component } from "@angular/core";
import { HeaderButtonComponent } from "../header-button/header-button.component";
import { faEye, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { ButtonActions } from "../../../enums/button-actions.enum";

@Component({
    selector: "app-column-visability-button",
    templateUrl: "./column-visability-button.component.html",
    styleUrls: ["./column-visability-button.component.css"]
})
export class ColumnVisabilityButtonComponent
    implements HeaderButtonComponent {
    icon: IconDefinition = faEye;
    title = "Column Visibility"
    buttonAction = ButtonActions.columnVisibility;
    isDisabled: boolean;
}
