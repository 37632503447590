export enum GridDataTypes {
    none = 0,
    currency = 1,
    percent = 2,
    date = 3,
    moment = 4,
    buttons = 5,
    text = 6,
    number = 7,
    percentPlusTwoPlaces = 8,
    checkboxNoHeader = 9,
    numberOrString
}
