import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { AccountsComponent } from "./accounts.component";
import { CallMasterComponent } from "./call-master/call-master.component";
import { ProfileMasterComponent } from "./profile-master/profile-master.component";
import { InfoMasterComponent } from "./info-master/info-master.component";
import { VolumeMasterComponent } from "./volume-master/volume-master.component";
import { DashboardMasterComponent } from "./dashboard-master/dashboard-master.component";
import { ProductsMasterComponent } from "./products-master/products-master.component";

const routes: Routes = [
    {
        path: "accounts",
        canActivate: [MsalGuard],
        component: AccountsComponent,
        children: [
            {
                path: ":CustomerId/profile",
                component: ProfileMasterComponent
            },
            {
                path: ":CustomerId/call",
                component: CallMasterComponent
            },
            {
                path: ":CustomerId/info",
                component: InfoMasterComponent
            },
            {
                path: ":CustomerId/edit",
                component: InfoMasterComponent
            },
            {
                path: ":CustomerId/volume",
                component: VolumeMasterComponent
            },
            {
                path: ":CustomerId/dashboard",
                component: DashboardMasterComponent
            },
            {
                path: ":CustomerId/products",
                component: ProductsMasterComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountsRoutingModule {}
