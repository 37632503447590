import { OrderLineItem } from "src/app/entity-models/order-line-item.entity";
import { OrderLineItemStatusLookup } from "shield.shared";
import { Helper } from "src/app/helpers/helper";

export class OrderListLineItemViewModel {
    id: string;
    isSelected = false;
    orderId: string;
    productId: string;
    productDescription: string;
    quantity: number;
    orderLineItemStatusLookupId: number;
    uin: string;
    upc: string;
    movedToNewOrderId: string | null;
    dateCreated: Date;
    createdBy: string;
    lastUpdated: Date;
    lastUpdatedBy: string;


    orderLineItemStatus: string;

    constructor(lineItem: OrderLineItem){
        this.id = lineItem.id;
        this.orderId = lineItem.orderId;
        this.productId = lineItem.productId;
        this.productDescription = lineItem.productDescription;
        this.quantity = lineItem.quantity;
        this.orderLineItemStatusLookupId = lineItem.orderLineItemStatusLookupId;
        this.uin = lineItem.uin;
        this.upc = lineItem.upc;
        this.movedToNewOrderId = lineItem.movedToNewOrderId;
        this.dateCreated = lineItem.createdUtcDateTime;
        this.lastUpdated = lineItem.modifiedUtcDateTime;
        this.lastUpdatedBy = Helper.formatDisplayName(lineItem);
        this.orderLineItemStatus = OrderLineItemStatusLookup.find((olisl) => olisl.id === lineItem.orderLineItemStatusLookupId).name;
    }
}
