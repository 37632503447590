<div class="wrapper">
    <div class="mb-3">
        <div class="sheen-container">
            <img class="logo loader-fade" src="assets/img/shield-vector-logo.svg" alt="Swisher Shield" />
            <div class="sheen-fast"></div>
        </div>
    </div>
    <h3>
        {{ statusMessage }}
    </h3>
    <ng-container *ngIf="appStateService.appLoadError">
        <div class="text-center">
            <h3 class="validation-error">An error occurred while loading the app. Please contact the help desk.</h3>
            <h3 class="validation-error">
                {{ getErrorMessage() }}
            </h3>
        </div>
    </ng-container>
    <mat-progress-spinner [diameter]="75" [value]="completionPercentage"></mat-progress-spinner>
</div>
