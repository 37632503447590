import { Component, OnInit, TemplateRef, Type } from "@angular/core";
import { SwisherOverlayRef } from "./swisher-overlay-ref";

@Component({
    selector: "app-overlay",
    templateUrl: "./overlay.component.html",
    styleUrls: ["./overlay.component.css"]
})
export class OverlayComponent implements OnInit {
    contentType: "template" | "string" | "component" = "component";
    content: string | TemplateRef<any> | Type<any>;
    contentString: string;
    contentTemplateRef: TemplateRef<any>;
    contentComponent: Type<any>;
    context: any;

    constructor(private ref: SwisherOverlayRef<any, any>) {}

    ngOnInit(): void {
        this.content = this.ref.content;

        if (typeof this.content === "string") {
            this.contentType = "string";
            this.contentString = this.content;
        } else if (this.content instanceof TemplateRef) {
            this.contentType = "template";
            this.contentTemplateRef = this.content;
            this.context = {
                close: this.ref.close.bind(this.ref),
                data: this.ref.data
            };
        } else {
            this.contentType = "component";
            this.contentComponent = this.content;
        }
    }

    close(): void {
        this.ref.close(null);
    }
}
