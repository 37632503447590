import { ZrtFilterDto } from "./zrt-filter.dto";

export class RouteListFilterDto {

    /*
     * My Searches
     */
    // Usage: Get only accounts with given ids
    ids?: string[];

    /*
    * Header and Requested information section
    */

    // Usage: Contains ZRT properties
    zrtFilterDto?: ZrtFilterDto;

    /*
     * Routes
     */

    // Usage: Get data based on route name
    routeName?: string[];

    // Usage: Get data based on route description
    routeDescription?: string[];

    /*
     * Routes
     */

    // Usage: Get data based on route date
    routeOnOrAfterDate?: string;
    routeOnOrBeforeDate?: string;
}
