<div *ngIf="retailCallCustomerContactViewModel" class="modal-margins">
    <div class="row margin-row">
        <div class="col">
            <mat-form-field appearance="outline" color="accent">
                <mat-label id="first-name">First Name</mat-label>
                <input
                    type="text"
                    [formControl]="firstNameFormControl"
                    [value]="retailCallCustomerContactViewModel.firstName"
                    maxLength="50"
                    matInput
                    required
                />
                <mat-hint align="end"
                    >{{
                        retailCallCustomerContactViewModel.firstName?.length ||
                            0
                    }}/50</mat-hint
                >
                <mat-error *ngIf="firstNameFormControl.hasError('required') && firstNameFormControl.touched">
                    Please enter contact first name.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="outline" color="accent">
                <mat-label id="last-name">Last Name</mat-label>
                <input
                    type="text"
                    [formControl]="lastNameFormControl"
                    [value]="retailCallCustomerContactViewModel.lastName"
                    maxLength="50"
                    matInput
                    required
                />
                <mat-hint align="end"
                    >{{
                        retailCallCustomerContactViewModel.lastName?.length ||
                            0
                    }}/50</mat-hint
                >
                <mat-error *ngIf="lastNameFormControl.hasError('required') && lastNameFormControl.touched">
                    Please enter contact last name.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="outline" color="accent">
                <mat-label id="email-address">Email Address</mat-label>
                <input
                    type="email"
                    [formControl]="emailFormControl"
                    maxLength="50"
                    placeholder="Ex. charles@example.com"
                    matInput
                />
                <mat-hint align="end"
                    >{{ emailFormControl.value?.length || 0 }}/50</mat-hint
                >
                <mat-error *ngIf="emailFormControl.hasError('email')">
                    Please enter a valid email address!
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row margin-row">
        <div class="col-lg-6 col-sm-12">
            <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" color="accent">
                        <mat-label id="phone-number">Phone Number</mat-label>
                        <input
                            type="text"
                            [formControl]="phoneFormControl"
                            [value]="
                                this.retailCallCustomerContactViewModel
                                    .phoneNumber
                            "
                            placeholder="Ex. (555) 555-5555"
                            [mask]="'(000) 000-0000'"
                            matInput
                        />
                        <mat-error *ngIf="phoneFormControl.hasError('pattern')">
                            Please enter a valid 10 digit phone number!
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" color="accent">
                        <mat-label id="alternate-phone-number"
                            >Alternate Phone Number</mat-label
                        >
                        <input
                            type="text"
                            [formControl]="altPhoneFormControl"
                            [value]="
                                retailCallCustomerContactViewModel.alternatePhoneNumber
                            "
                            placeholder="Ex. (555) 555-5555"
                            [mask]="'(000) 000-0000'"
                            matInput
                        />
                        <mat-error
                            *ngIf="altPhoneFormControl.hasError('pattern')"
                        >
                            Please enter a valid 10 digit phone number!
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12">
            <mat-form-field appearance="outline" color="accent">
                <mat-label id="notes">Notes</mat-label>
                <textarea
                    cdkTextareaAutosize="false"
                    [(ngModel)]="retailCallCustomerContactViewModel.note"
                    rows="6"
                    maxLength="4000"
                    type="text"
                    matInput
                >
                </textarea>
                <mat-hint align="end"
                    >{{
                        retailCallCustomerContactViewModel.note?.length || 0
                    }}/4000</mat-hint
                >
            </mat-form-field>
        </div>
    </div>
    <div class="row margin-row">
        <div class="col banner center">Availability</div>
    </div>
    <div *ngIf="retailCallCustomerContactViewModel.dailyAvailability">
        <div class="row margin-row">
            <div
                class="col day-card"
                *ngFor="
                    let day of retailCallCustomerContactViewModel.dailyAvailability
                "
            >
                <div class="row">
                    <div class="col">
                        <div class="s-teal-color center">
                            <b>{{ day.name }}</b>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="raised-grey check-box">
                        <mat-checkbox
                            [checked]="day.all"
                            [indeterminate]="someComplete(day)"
                            [color]="'warn'"
                            (change)="setAll(day, $event.checked)"
                        >
                            All Day
                        </mat-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="raised-white check-box">
                        <mat-checkbox
                            [(ngModel)]="day.am"
                            [indeterminate]="false"
                            [color]="'warn'"
                            (change)="updateAllComplete(day)"
                        >
                            AM
                        </mat-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="raised-white check-box">
                        <mat-checkbox
                            [(ngModel)]="day.pm"
                            [indeterminate]="false"
                            [color]="'warn'"
                            (change)="updateAllComplete(day)"
                        >
                            PM
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row margin-row">
        <div class="col bottom-banner">
            <button mat-raised-button class="btn-white" (click)="clearAll()">
                Clear Availability
            </button>
        </div>
    </div>
    <div class="row margin-row">
        <div class="col text-right required">
            * denotes required field.
        </div>
    </div>
</div>
