<div class="row">
    <div class="col-12">
        <div class="text-right">
            <button appThrottleClick (throttledClick)="viewmodel.email()" class="btn btn-primary mr-2">
                <span><fa-icon [icon]="viewmodel.faEnvelope"></fa-icon></span> Email
            </button>
            <button appThrottleClick (throttledClick)="viewmodel.print()" class="btn btn-primary mr-2">
                <span><fa-icon [icon]="viewmodel.faPrint"></fa-icon></span> Print
            </button>
        </div>
        <div #agreement>
                <app-retail-incentive-agreement
                    [contract]="viewmodel.customerContract.contractTemplate"
                    [retailer]="viewmodel.retailer"
                    [endDate]="viewmodel.endDate">
                </app-retail-incentive-agreement>
        </div>
    </div>
</div>
