<mat-card class="mt-3">
    <div *ngIf="validationErrorMessages && validationErrorMessages.length > 0">
        <div class="alert alert-danger" role="alert">
            <div class="row">
                <b>Validation Summary</b>
            </div>
            <div *ngFor="let message of validationErrorMessages">
                <mat-icon>warning</mat-icon>
                {{ message }}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-9 col-sm-12">
            <div class="pb-3">
                <mat-card class="inner-card m-0">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="m-teal"><b>Cash Sales</b></h2>
                        </div>
                        <div class="col-12 button-bottom-margin">
                            <button appThrottleClick
                                [disabled]="isFinalRetailReceiptPrinted"
                                class="btn sw-red-btn btn-sm"
                                type="button"
                                tabindex="0"
                                (throttledClick)="addCashProduct()">
                                ADD PRODUCTS
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col overflow-auto">
                            <table *ngIf="
                            stepperCallApplicationService.callCashProductViewModels &&
                            stepperCallApplicationService.callCashProductViewModels.length > 0
                                " class="table table-bordered table-striped mobile-w-max">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Description</th>
                                        <th scope="col"></th>
                                        <th scope="col">Unit QTY</th>
                                        <th scope="col"># Sticks/Units</th>
                                        <th scope="col">Total Sticks</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Discount</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="
                                            let cashProduct of stepperCallApplicationService.callCashProductViewModels;
                                            let i = index
                                        ">
                                        <td class="icon-width">
                                            <button [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                class="btn btn-outline-danger text-decoration-none btn-sm"
                                                (click)="removeCashProduct(
                                                        cashProduct
                                                    )"
                                                title="Remove Product">
                                                <span class="flaticon-delete"></span>
                                            </button>
                                        </td>
                                        <td>
                                            {{
                                            cashProduct?.product
                                            ?.description
                                            }}
                                        </td>
                                        <td class="icon-width">
                                            <button [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                class="btn btn-outline-info btn-sm"
                                                (click)="copyCashProduct(
                                                        cashProduct
                                                    )"
                                                title="Copy Product Metadata">
                                                <span class="material-icons">content_copy</span>
                                            </button>
                                        </td>
                                        <td class="counter-fixed-width">
                                            <div class="input-group counter-control">
                                                <div class="input-group-prepend">
                                                    <button [disabled]="isFinalRetailReceiptPrinted" tabindex="-1"
                                                    class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            incrementCashProductQuantity(
                                                                cashProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">add</span>
                                                    </button>
                                                </div>
                                                <input
                                                    [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                    type="text" #cashProductQty (mouseup)="select(cashProductQty)"
                                                    (blur)="
                                                        validationCashProductQuantity(
                                                            cashProduct
                                                        )
                                                    " class="form-control form-control-sm mw-7rem-form" mask="000000"
                                                    aria-label="Example text with button addon" [(ngModel)]="
                                                        cashProduct.quantity
                                                    " />
                                                <div class="input-group-append">
                                                    <button [disabled]="isFinalRetailReceiptPrinted" tabindex="-1"
                                                        class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            decrementCashProductQuantity(
                                                                cashProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">remove</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="counter-fixed-width">
                                            <div class="input-group counter-control">
                                                <div class="input-group-prepend">
                                                    <button [disabled]="isFinalRetailReceiptPrinted" tabindex="-1"
                                                        class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            incrementCashProductUnit(
                                                                cashProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">add</span>
                                                    </button>
                                                </div>
                                                <input
                                                    [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                    type="text" #cashProductUnit (mouseup)="select(cashProductUnit)"
                                                    (blur)="
                                                        validationCashProductUnit(
                                                            cashProduct
                                                        )
                                                    " class="form-control form-control-sm mw-7rem-form" mask="000000"
                                                    [(ngModel)]=" cashProduct.units
                                                    " />
                                                <div class="input-group-append">
                                                    <button [disabled]="isFinalRetailReceiptPrinted" tabindex="-1"
                                                        class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            decrementCashProductUnit(
                                                                cashProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">remove</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="total-sticks-fixed-width dotted-right-border">
                                            {{
                                            cashProduct.quantity *
                                            cashProduct.units

                                            }}
                                        </td>
                                        <td>
                                            <mat-form-field class="example-full-width price-input" appearance="outline"
                                                color="accent">
                                                <input
                                                    [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                    matInput #cashProductPrice (mouseup)="select(cashProductPrice)"
                                                    (blur)="
                                                    validationCashProductPrice(
                                                            cashProduct
                                                        )
                                                    " currencyMask [(ngModel)]="
                                                        cashProduct.price
                                                    " />
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            <mat-form-field class="example-full-width price-input" appearance="outline"
                                                color="accent">
                                                <input
                                                    [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                    matInput #cashProductDiscount
                                                    (mouseup)="select(cashProductDiscount)" (blur)="
                                                        saveCashProduct(
                                                            cashProduct
                                                        )
                                                    " currencyMask [(ngModel)]="
                                                        cashProduct.discount
                                                    " />
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            {{
                                            cashProduct.quantity *
                                            (cashProduct.price -
                                            cashProduct.discount)
                                            | currency
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </mat-card>
            </div>

            <!-- Orders -->

            <div class="" *ngIf="stepperCallApplicationService.hasOrderableProduct && !isGenericWholesalerStoreType">
                <mat-card class="inner-card m-0">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="m-teal"><b>Orders/ Prebook</b></h2>
                        </div>
                        <div class="col-12 button-bottom-margin">
                            <button appThrottleClick
                                [disabled]="isFinalWholesaleReceiptPrinted" tabindex="0"
                                class="btn sw-red-btn btn-sm" type="button" (throttledClick)="addOrderProduct()">
                                ADD PRODUCTS
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col overflow-auto">
                            <table *ngIf="
                            stepperCallApplicationService.callOrderProductViewModels &&
                            stepperCallApplicationService.callOrderProductViewModels.length > 0
                                " class="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Description</th>
                                        <th scope="col"></th>
                                        <!-- <th scope="col"></th> -->
                                        <th scope="col">Unit QTY</th>
                                        <th scope="col"># Sticks/Units</th>
                                        <th scope="col">Total Sticks</th>
                                        <th scope="col">Wholesaler</th>
                                        <th scope="col">UIN</th>
                                        <th scope="col">Order Dates</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="
                                            let orderProduct of stepperCallApplicationService.callOrderProductViewModels;
                                            let i = index
                                        ">
                                        <td class="icon-width">
                                            <button [disabled]="isFinalWholesaleReceiptPrinted" tabindex="0"
                                                class="btn btn-outline-danger text-decoration-none btn-sm" (click)="
                                                    removeOrderProduct(
                                                        orderProduct
                                                    )
                                                " title="Remove Product">
                                                <span class="flaticon-delete"></span>
                                            </button>
                                        </td>
                                        <td>
                                            {{
                                            orderProduct?.product
                                            ?.description
                                            }}
                                        </td>
                                        <td class="icon-width">
                                            <button [disabled]="isFinalWholesaleReceiptPrinted" tabindex="0"
                                                class="btn btn-outline-info btn-sm" (click)="
                                                    duplicateOrderProduct(
                                                        orderProduct
                                                    )
                                                " title="Add Copy of Product">
                                                <span class="flaticon-add"></span>
                                            </button>
                                        </td>
                                        <td class="counter-fixed-width">
                                            <div class="input-group counter-control">
                                                <div class="input-group-prepend">
                                                    <button [disabled]="isFinalWholesaleReceiptPrinted" tabindex="-1"
                                                        class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            incrementOrderProductQuantity(
                                                                orderProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">add</span>
                                                    </button>
                                                </div>
                                                <input
                                                    [disabled]="isFinalWholesaleReceiptPrinted" tabindex="0"
                                                    type="text" #orderProductQty (mouseup)="select(orderProductQty)"
                                                    (blur)="
                                                        validationOrderProductQuantity(
                                                            orderProduct
                                                        )
                                                    " class="form-control form-control-sm mw-7rem-form" mask="000000"
                                                    aria-label="Example text with button addon" [(ngModel)]="
                                                        orderProduct.quantity
                                                    " />
                                                <div class="input-group-append">
                                                    <button [disabled]="isFinalWholesaleReceiptPrinted" tabindex="-1"
                                                        class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            decrementOrderProductQuantity(
                                                                orderProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">remove</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="counter-fixed-width">
                                            <div class="input-group counter-control">
                                                <div class="input-group-prepend">
                                                    <button [disabled]="isFinalWholesaleReceiptPrinted" tabindex="-1"
                                                        class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            incrementOrderProductUnit(
                                                                orderProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">add</span>
                                                    </button>
                                                </div>
                                                <input
                                                    [disabled]="isFinalWholesaleReceiptPrinted" tabindex="0"
                                                    type="text" #orderProductUnit
                                                    (mouseup)="select(orderProductUnit)" (blur)="
                                                        validationOrderProductUnit(
                                                            orderProduct
                                                        )
                                                    " class="form-control form-control-sm mw-7rem-form" mask="000000"
                                                    aria-label="Example text with button addon" [(ngModel)]="
                                                        orderProduct.units
                                                    " />
                                                <div class="input-group-append">
                                                    <button [disabled]="isFinalWholesaleReceiptPrinted" tabindex="-1"
                                                        class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            decrementOrderProductUnit(
                                                                orderProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">remove</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="total-sticks-fixed-width dotted-right-border">
                                            {{
                                            orderProduct.quantity *
                                            orderProduct.units

                                            }}
                                        </td>
                                        <td>
                                            <ng-container *ngIf="!isFinalWholesaleReceiptPrinted">
                                                <angular2-multiselect [data]="orderProduct.wholesalers" [(ngModel)]="orderProduct.wholesaler"
                                                    [settings]="wholeSalerDropdownSettings" (onDeSelect)="wholesalerDeselect(orderProduct, $event)"
                                                    (onSelect)="onWholsalerSelectionChange(orderProduct)" (onDeSelectAll)="wholesalerDeselect(orderProduct, $event)">
                                                </angular2-multiselect>
                                            </ng-container>
                                            <ng-container *ngIf="isFinalWholesaleReceiptPrinted">
                                                <mat-form-field appearance="outline" color="accent">
                                                    <input
                                                        [disabled]="isFinalRetailReceiptPrinted"
                                                        tabindex="0"
                                                        matInput
                                                        [(ngModel)]="orderProduct.wholesaler[0].displayValue"
                                                    />
                                                </mat-form-field>
                                            </ng-container>
                                        </td>
                                        <td>
                                            {{orderProduct.uin}}
                                        </td>
                                        <td>
                                            <button appThrottleClick *ngIf="orderProduct.selectedProject"
                                                [disabled]="isFinalWholesaleReceiptPrinted" tabindex="0"
                                                class="btn btn-outline-info btn-sm" type="button" (throttledClick)="
                                                    setOrderOptions(
                                                        orderProduct
                                                    )
                                                ">
                                                Order Options
                                            </button>
                                            <span *ngIf="!orderProduct.selectedProject && orderProduct.dateAvailable">
                                                {{ orderProduct.dateAvailable | date : 'shortDate'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </mat-card>
            </div>

            <!-- Gratis -->

            <div class="">
                <mat-card class="inner-card">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="m-teal"><b>Gratis</b></h2>
                        </div>
                        <div class="col-12 button-bottom-margin">
                            <button appThrottleClick
                                class="btn sw-red-btn btn-sm"
                                type="button"
                                tabindex="0"
                                [disabled]="isFinalRetailReceiptPrinted"
                                (throttledClick)="addGratisProduct()">
                                ADD PRODUCTS
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col overflow-auto">
                            <table *ngIf="
                            stepperCallApplicationService.callGratisProductViewModels &&
                            stepperCallApplicationService.callGratisProductViewModels.length > 0
                                " class="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Description</th>
                                        <th scope="col"></th>
                                        <th scope="col">Unit QTY</th>
                                        <th scope="col"># Sticks/Units</th>
                                        <th scope="col">Total Sticks</th>
                                        <th scope="col">Value</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="
                                            let gratisProduct of stepperCallApplicationService.callGratisProductViewModels;
                                            let i = index
                                        ">
                                        <td class="icon-width">
                                            <button [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                class="btn btn-outline-danger btn-sm" (click)="
                                                    removeGratisProduct(
                                                        gratisProduct
                                                    )
                                                " title="Remove Product">
                                                <span class="flaticon-delete"></span>
                                            </button>
                                        </td>
                                        <td>
                                            {{
                                            gratisProduct?.product
                                            ?.description
                                            }}
                                        </td>
                                        <td class="icon-width">
                                            <button [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                class="btn btn-outline-info btn-sm" (click)="
                                                    copyGratisProduct(
                                                        gratisProduct
                                                    )
                                                " title="Copy Product Metadata">
                                                <span class="material-icons">content_copy</span>
                                            </button>
                                        </td>
                                        <td class="counter-fixed-width">
                                            <div class="input-group counter-control">
                                                <div class="input-group-prepend">
                                                    <button [disabled]="isFinalRetailReceiptPrinted" tabindex="-1"
                                                        class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            incrementGratisProductQuantity(
                                                                gratisProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">add</span>
                                                    </button>
                                                </div>
                                                <input
                                                    [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                    type="text" #gratisProductQty
                                                    (mouseup)="select(gratisProductQty)" (blur)="
                                                        validationGratisProductQuantity(
                                                            gratisProduct
                                                        )
                                                    " class="form-control form-control-sm mw-7rem-form" mask="000000"
                                                    aria-label="Example text with button addon" [(ngModel)]="
                                                        gratisProduct.quantity
                                                    " />
                                                <div class="input-group-append">
                                                    <button [disabled]="isFinalRetailReceiptPrinted" tabindex="-1"
                                                        class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            decrementGratisProductQuantity(
                                                                gratisProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">remove</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="counter-fixed-width">
                                            <div class="input-group counter-control">
                                                <div class="input-group-prepend">
                                                    <button [disabled]="isFinalRetailReceiptPrinted" tabindex="-1"
                                                        class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            incrementGratisProductUnit(
                                                                gratisProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">add</span>
                                                    </button>
                                                </div>
                                                <input
                                                    [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                    type="text" #gratisProductUnit
                                                    (mouseup)="select(gratisProductUnit)" (blur)="
                                                        validationGratisProductUnit(
                                                            gratisProduct
                                                        )
                                                    " class="form-control form-control-sm mw-7rem-form" mask="000000"
                                                    aria-label="Example text with button addon" [(ngModel)]="
                                                        gratisProduct.units
                                                    " />
                                                <div class="input-group-append">
                                                    <button [disabled]="isFinalRetailReceiptPrinted" tabindex="-1"
                                                        class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            decrementGratisProductUnit(
                                                                gratisProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">remove</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="total-sticks-fixed-width dotted-right-border">
                                            {{
                                            gratisProduct.quantity *
                                            gratisProduct.units

                                            }}
                                        </td>
                                        <td>
                                            <mat-form-field appearance="outline" color="accent" class="price-input">
                                                <input
                                                    [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                    matInput #gratisProductValue
                                                    (mouseup)="select(gratisProductValue)" (blur)="
                                                    validationGratisProductPrice(
                                                            gratisProduct
                                                        )
                                                    " currencyMask [(ngModel)]="
                                                        gratisProduct.value
                                                    " />
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            {{
                                            gratisProduct.quantity *
                                            gratisProduct.value
                                            | currency
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-3 col-sm-12">
            <mat-card class="inner-card m-0">
                <table class="table table-borderless">
                    <tr>
                        <td>
                            <small>
                                <b>Total Before Discount</b>
                            </small>
                        </td>
                        <td class="text-right">
                            {{ stepperCallApplicationService.totalCashBeforeDiscount | currency }}
                        </td>
                        <td class="text-right"></td>
                    </tr>
                    <tr class="border-bottom">
                        <td>
                            <small>
                                <b>Total Discounts/Gratis</b>
                            </small>
                        </td>
                        <td class="text-right">
                            {{ stepperCallApplicationService.totalCashAndGratisDiscount | currency }}
                        </td>
                        <td class="text-right" [ngClass]=" stepperCallApplicationService.totalDiscountPercent > 0.08 ? 'm-red' : ''">
                            {{ (stepperCallApplicationService.totalDiscountPercent ? stepperCallApplicationService.totalDiscountPercent : 0) | percent }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <small>
                                <b>Total Payment Due</b>
                            </small>
                        </td>
                        <td class="text-right">
                            {{ stepperCallApplicationService.totalPaymentDue | currency }}
                        </td>
                        <td class="text-right"></td>
                    </tr>
                </table>
                <div class="row p-3">
                    <div>
                        <button appThrottleClick *ngIf="isTaxAvailable"
                            [disabled]="isFinalWholesaleReceiptPrinted || isFinalRetailReceiptPrinted"
                            (throttledClick)="onOpenTaxCalculator()" class="btn sw-red-btn btn-sm" tabindex="0"
                            type="button">
                            Tax Calculator
                        </button>
                    </div>
                    <div></div>
                </div>
            </mat-card>
        </div>
    </div>
</mat-card>
