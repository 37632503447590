import { DayAvailabilityViewModel } from "./day-availability.viewmodel";

export class AvailabilityViewModel {
    sunday: DayAvailabilityViewModel = new DayAvailabilityViewModel();
    monday: DayAvailabilityViewModel = new DayAvailabilityViewModel();
    tuesday: DayAvailabilityViewModel = new DayAvailabilityViewModel();
    wednesday: DayAvailabilityViewModel = new DayAvailabilityViewModel();
    thursday: DayAvailabilityViewModel = new DayAvailabilityViewModel();
    friday: DayAvailabilityViewModel = new DayAvailabilityViewModel();
    saturday: DayAvailabilityViewModel = new DayAvailabilityViewModel();
}
