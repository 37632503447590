import { Component, ElementRef, OnInit, Type, ViewChild } from "@angular/core";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterService } from "src/app/services/filter.service";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { RefinerLocation, valueSeparator } from "shield.shared";
import { FormBuilder } from "@angular/forms";
import * as moment from "moment";
import { CustomerDateInformationViewmodel } from "./customer-date-information.viewmodel";
import { MatSelect } from "@angular/material/select";
import { Day } from "src/app/enums/day";
import { MatOptionSelectionChange } from "@angular/material/core";

@Component({
    selector: "app-customer-date-information-filter",
    templateUrl: "./customer-date-information-filter.component.html",
    styleUrls: ["./customer-date-information-filter.component.scss"]
})
export class CustomerDateInformationFilterComponent extends FilterBaseComponent implements OnInit {
    //view childs
    @ViewChild("callStartDateInput") callStartDateInput: ElementRef;
    @ViewChild("callEndDateInput") callEndDateInput: ElementRef;
    @ViewChild("availabilitySelect") availabilitySelect: MatSelect;

    //public vars
    contentComponent: Type<CustomerDateInformationFilterComponent>;
    icon = "perm_phone_msg";
    name = "Date Information";
    viewmodel = new CustomerDateInformationViewmodel(this.filterService, this.formBuilder);

    constructor(
        private filterService: FilterService,
        private formBuilder: FormBuilder
    ) {
        super();
    }

    ngOnInit(): void {
        this.setInitialized();
    }

    //events
    onAddEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        if (this.viewmodel.callDateForm.controls.callEndDate.errors) return;

        const value = this.callEndDateInput.nativeElement.value;
        const existing = this._refinerService.refiners?.find(v => v.location === RefinerLocation.callOnOrBeforeDate);

        if (value || existing) {
            this._refinerService.onInputChange(
                RefinerLocation.callOnOrBeforeDate,
                this.callEndDateInput.nativeElement.value
            );

            if (!event || (event as KeyboardEvent).key === "Enter") {
                setTimeout(() => {
                    const refiner = new Refiner();
                    refiner.location = RefinerLocation.callOnOrBeforeDate;
                    refiner.value = this.callEndDateInput.nativeElement.value;
                    refiner.dataPropertyName = "lastCall";

                    this._refinerService.checkAndUpdateRefiner(refiner);
                }, 0)
            }
        }
    }

    onAddStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        if (this.viewmodel.callDateForm.controls.callStartDate.errors) return;

        const value = this.callStartDateInput.nativeElement.value;
        const existing = this._refinerService.refiners?.find(v => v.location === RefinerLocation.callOnOrAfterDate);

        if (value || existing) {
            this._refinerService.onInputChange(
                RefinerLocation.callOnOrAfterDate,
                this.callStartDateInput.nativeElement.value
            );

            if (!event || (event as KeyboardEvent).key === "Enter") {
                setTimeout(() => {
                    const refiner = new Refiner();
                    refiner.location = RefinerLocation.callOnOrAfterDate;
                    refiner.value = this.callStartDateInput.nativeElement.value;
                    refiner.dataPropertyName = "lastCall";

                    this._refinerService.checkAndUpdateRefiner(refiner);
                }, 0)
            }
        }
    }

    onOpenedAvailabilityChange(): void {
        this.viewmodel.isAllAvailabilityPresent = this.viewmodel.selectedAvailability.includes("All");




            if (!this.availabilitySelect?.panelOpen) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.availability;
                refiner.value = this.viewmodel.isAllAvailabilityPresent ? 'All' : this.viewmodel.selectedAvailability
                    .join(", ");
                refiner.dataPropertyName = "availability";
                refiner.dataValue = this.viewmodel.selectedAvailability
                    .map((day) => {
                        switch (day) {
                            case Day.sunday:
                                return "U";
                            case Day.thursday:
                                return "R";
                            default:
                                return day.slice(0, 1);
                        }
                    })
                    .join(valueSeparator);

                this._refinerService.checkAndUpdateRefiner(refiner);
            }

    }



    onSelectionAvailabilityChange(event: MatOptionSelectionChange): void {

        if (event.isUserInput) {
            let value = event.source.value;
            let idx = this.viewmodel.selectedAvailability.findIndex(v => v === value);
            let allIdx = this.viewmodel.selectedAvailability.findIndex(v => v === 'All');
            let noneIdx = this.viewmodel.selectedAvailability.findIndex(v => v === 'None');
            let exists:boolean = idx !== -1;
            let allExists: boolean = allIdx !== -1;
            let noneExists: boolean = noneIdx !== -1;
            switch(event.source.value) {

                case 'All':
                    if (exists) this.viewmodel.selectedAvailability = [];
                    else this.viewmodel.selectedAvailability = [...this.viewmodel.availability.filter(v => v !== 'None')];
                    break;
                case 'None':
                    if (exists) this.viewmodel.selectedAvailability.splice(idx,1);
                    else this.viewmodel.selectedAvailability = ['None'];
                    break;
                default:
                    if (exists) {
                        this.viewmodel.selectedAvailability.splice(idx,1);
                        if (allExists) this.viewmodel.selectedAvailability.splice(allIdx, 1);

                        this.viewmodel.selectedAvailability = [...this.viewmodel.selectedAvailability];
                    }
                    else {
                        this.viewmodel.selectedAvailability.push(value);

                        if (noneExists) this.viewmodel.selectedAvailability.splice(noneIdx, 1);

                        this.viewmodel.selectedAvailability = [...this.viewmodel.selectedAvailability];

                        let selectedAvailability = this.viewmodel.selectedAvailability;
                        let availability = this.viewmodel.availability.filter((v: string) => !(v == 'None' ||  v == 'All'));
                        if (availability.every(el => selectedAvailability.includes(el))) {
                            this.viewmodel.selectedAvailability = [...this.viewmodel.availability.filter(v => v !== 'None')];
                        }
                    }
                    break;
            }
        }
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.callOnOrBeforeDate:
                    if (refiner.value !== this.callEndDateInput?.nativeElement.value) {
                        if(refiner.value) {
                            this.viewmodel.callDateForm.controls.callEndDate.setValue(moment(new Date(refiner.value)));
                        }
                        else {
                            this.viewmodel.callDateForm.controls.callEndDate.setValue("");
                        }
                    }
                    break;
                case RefinerLocation.callOnOrAfterDate:
                    if (refiner.value !== this.callStartDateInput?.nativeElement.value) {
                        if(refiner.value) {
                            this.viewmodel.callDateForm.controls.callStartDate.setValue(moment(new Date(refiner.value)));
                        }
                        else {
                            this.viewmodel.callDateForm.controls.callStartDate.setValue("");
                        }
                    }
                    break;
                case RefinerLocation.availability:
                    if (!this.viewmodel.selectedAvailability.includes("All")) {
                        const selectedAvailability = refiner.value?.split(", ");
                        const rtnSelectedAvailability = new Array<string>();

                        if (selectedAvailability) {
                            for (const option of selectedAvailability) {
                                const found = this.viewmodel.availability.find(
                                    (a) => a === option.trim()
                                );
                                if (found) {
                                    rtnSelectedAvailability.push(found);
                                }
                            }
                        }
                        this.viewmodel.selectedAvailability = rtnSelectedAvailability;
                        this.onOpenedAvailabilityChange();
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.callOnOrBeforeDate;
        const endDateRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.callOnOrBeforeDate
        );
        if (endDateRefiner) {
            this.onInputChange(endDateRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.callOnOrAfterDate;
        const startDateRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.callOnOrAfterDate
        );
        if (startDateRefiner) {
            this.onInputChange(startDateRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.availability;
        const availabilityRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.availability
        );
        if (availabilityRefiner) {
            this.onInputChange(availabilityRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }
}
