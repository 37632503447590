import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { CallOrderDate } from "src/app/entity-models/call-order-date.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { Product } from "src/app/entity-models/product.entity";
import { Project } from "src/app/entity-models/project.entity";
import { WholesalerViewmodel } from "src/app/shared/viewmodels/wholesaler.viewmodel";
export class CallOrderProductViewModel {
    id: string; //UUID
    product: Product;
    quantity: number;
    units: number;
    uin: string;
    save: () => void;
    oldQuantity: number;
    oldUnits: number;
    uinLookUp: string;
    upc: string;
    today = new Date();
    oldOrderDates: CallOrderDate[];
    orderDates: CallOrderDate[];
    storeCount: number | null; // temp, this should come from Total Stores
    formControl: FormControl;
    orderDateValueChangeSubscription: Subscription;
    wholesaler: WholesalerViewmodel[]; //the multiSelect when in single select mode still returns an array
    wholesalers: WholesalerViewmodel[];
    selectedProject: Project;
    employee: Employee;
    isConfirmed = false;
    dateAvailable?: Date;

    constructor(isFormControlDisabled: boolean, orderDates?: CallOrderDate[],) {
        this.orderDates = orderDates;
        if (!this.orderDates) {

            const defaultOrderDate = new CallOrderDate();
            defaultOrderDate.orderDate = this.today;
            defaultOrderDate.quantity = this.quantity;

            this.orderDates = [defaultOrderDate];
        }
        this.formControl = new FormControl({
            value: this.orderDates[0]?.orderDate,
            disabled: isFormControlDisabled
        });

        if (
            !this.orderDateValueChangeSubscription ||
            this.orderDateValueChangeSubscription.closed
        ) {
            this.orderDateValueChangeSubscription = this.formControl.valueChanges.subscribe(
                (value) => {
                    const date = new Date(value);
                    const orderDate = this.orderDates[0];

                    if (orderDate) {
                        orderDate.orderDate = date;
                    }
                }
            );
        }
    }

    get isPristine(): boolean {
        this.orderDates.forEach((d) => d.orderDate.setHours(0, 0, 0, 999));
        this.today.setHours(0, 0, 0, 999);
        return (
            this.quantity === 1 &&
            this.units === (this.product.lowestSellableUpc?.noOfEaches ?? 1) &&
            (!this.storeCount || this.storeCount === 1) &&
            (!this.orderDates ||
                !this.orderDates.some(
                    (dd) => dd.orderDate.getTime() === this.today.getTime()
                ))
        );
    }

    copyTo(orderProduct: CallOrderProductViewModel): CallOrderProductViewModel {
        orderProduct.oldQuantity = orderProduct.quantity;
        orderProduct.oldUnits = orderProduct.units;
        orderProduct.quantity = this.quantity;
        orderProduct.units = this.units;
        orderProduct.storeCount = this.storeCount;
        orderProduct.orderDates = this.orderDates;
        return orderProduct;
    }
}
