import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FilterRequestDto, GenericRequestDto, GenericResponseDto, SharedHelper, StringArrayDto, SurveyDto } from "shield.shared";
import { Survey } from "../../entity-models/survey.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { SurveyConverterService } from "../converter-services/survey-converter.service";

@Injectable()
export class SurveyOnlineService {

    constructor(private http: HttpClient) { };

    async getById(id: string): Promise<GenericResponseDto<Survey>> {

        const result = await this.http
            .get<GenericResponseDto<SurveyDto>>(`/api/surveys/${id}`).toPromise();

        return ConverterHelper.dtoToEntity<SurveyDto, Survey>(result, (r) => SurveyConverterService.surveyDtoToSurvey(r));
    }

    async getByIds(ids: string[]): Promise<GenericResponseDto<Survey[]>> {

        const dto = new StringArrayDto();
        dto.values = ids;

        const result = await this.http
            .post<GenericResponseDto<SurveyDto[]>>(`/api/surveys/by-ids`, dto).toPromise();

            return ConverterHelper.dtoToEntityArray<SurveyDto, Survey>(result, (r) => SurveyConverterService.surveyDtoToSurvey(r));
    }

    async getAvailableBatch(
        id: string,
    ): Promise<GenericResponseDto<Survey[]>> {

        const filterRequestDto = new FilterRequestDto();

        filterRequestDto.refinerGroups = [];
        filterRequestDto.pageSize = 0;
        filterRequestDto.startIndex = 0;
        filterRequestDto.filterSorts = null;
        filterRequestDto.id = id;

        const request = new GenericRequestDto<FilterRequestDto>();
        request.id = id;
        request.values = filterRequestDto;

        const result = await this.http
            .post<GenericResponseDto<SurveyDto[]>>(
                "/api/surveys",
                request
            )
            .toPromise();

        return ConverterHelper.dtoToEntityArray<SurveyDto, Survey>(result, (r) => SurveyConverterService.surveyDtoToSurvey(r));
    }
}
