import {
    CustomerTypeEnum,
    GenericLookup
} from "shield.shared";
import { ProjectBasicCustomer } from "src/app/entity-models/project-basic-customer.entity";

export class ProjectStoreGridViewmodel {
    isSelected: boolean;
    isAssigned: boolean;
    assigned: string;
    lastCall: Date;
    storeName: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    customerType: GenericLookup<CustomerTypeEnum>;
    customerTypeName: string;
    industryVolume: number;
    swisherVolume: number;
    zrt: string;
    msa: string;
    ssPercent: number;
    phone: string;
    chain: string;
    customerId: string;
    callsMade: string;
    px3Rank: string;

    constructor(pbc: ProjectBasicCustomer, px3Rank?: string){
        this.customerId = pbc.customerId;
        this.isAssigned = pbc.isAssigned;
        this.isSelected = this.isAssigned;
        this.assigned = pbc.isAssigned ? "Assigned" : "";
        this.lastCall = pbc.lastCall;
        this.storeName = pbc.name;
        this.address = pbc.address1;
        this.city = pbc.city;
        this.state = pbc.state;
        this.zip = pbc.zip;
        this.customerType = pbc.customerType;
        this.customerTypeName = this.customerType.name;
        this.industryVolume = pbc.industryVolume;
        this.swisherVolume = pbc.swisherVolume;
        this.zrt = pbc.zrt;
        this.msa = pbc.isMsa ? "Yes" : "No";
        this.ssPercent = this.industryVolume ? (this.swisherVolume ?? 0) /this.industryVolume : 0;
        this.phone = pbc.phone;
        this.chain = pbc.chain;
        this.callsMade = pbc?.callsMade?.toString() ?? "--";
        this.px3Rank = px3Rank;
    }
}
