export abstract class DataSyncHandlerBase {
    logEnabled: boolean;

    constructor() {
        this.logEnabled = window.localStorage.getItem("sync_log") !== "N";
    }

    log(msg: string): void {
        if (this.logEnabled) {
            console.log("SYNC: " + msg);
        }
    }
}
