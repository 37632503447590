import { CallTypes } from "shield.shared";
import { RetailCall } from "./retail-call.entity";

export class RmWholesaleCall extends RetailCall {

    constructor() {
        super();
        this.callType = CallTypes.rmWholesale;
    }
}
