import { Injectable } from "@angular/core";
import { State } from "src/app/entity-models/state.entity";
import { DatabaseService } from "../database.service";

@Injectable()
export class StateOfflineService {

    constructor(private dbService: DatabaseService){}

    async getByShortCode(code: string): Promise<State> {

        return await this.dbService.states
            .where("shortCode")
            .equals(code)
            .first();
    }

    async getById(id: string): Promise<State> {

        return await this.dbService.states
            .where("id")
            .equals(id)
            .first();
    }
}
