import { Component, Input, ViewChildren } from "@angular/core";
import { MatAccordion, MatExpansionPanel } from "@angular/material/expansion";
import { CustomerGenericTypes } from "src/app/enums/customer-generic-types";
import { Helper } from "src/app/helpers/helper";
import { CustomerStateService } from "../../../account-services/customer-state.service";
import { Call, CallService } from "../../call-services/call.service";
import { newSequentialId, SurveyQuestionTypes, SurveyTypes } from "shield.shared";
import { CallSurveyAnswer } from "src/app/entity-models/call-survey-answer.entity";
import { ActivitySurveyViewModel } from "../../../account.viewmodels/activity-survey.viewmodel";
import { ActivitySurveyResponseViewModel } from "../../../account.viewmodels/activity-survey-response.viewmodel";
@Component({
    selector: "app-activities-surveys",
    templateUrl: "./activities-surveys.component.html",
    styleUrls: ["./activities-surveys.component.scss"]
})
export class ActivitiesSurveysComponent {
    //View Children
    @ViewChildren(MatAccordion) accordions: MatAccordion[];
    @ViewChildren(MatExpansionPanel) expantionPanels: MatExpansionPanel[];

    private _isReadOnly: boolean;
    @Input()
    get isReadOnly(): boolean {
        return this._isReadOnly;
    }
    set isReadOnly(value: boolean) {
        this._isReadOnly = value;
    }

    private _isPanel: boolean;
    @Input()
    get isPanel(): boolean {
        return this._isPanel;
    }
    set isPanel(value: boolean) {
        this._isPanel = value;
    }

    private _call: Call;
    @Input()
    get call(): Call {
        return this._call;
    }
    set call(value: Call) {
        this._call = value;
        if (value) {
            this.setDisplayActivitiesSurveys();
            this.openAccordians();
        }
    }

    //Public vars
    type: CustomerGenericTypes;
    displayActivities: ActivitySurveyViewModel[] = [];
    displaySurveys: ActivitySurveyViewModel[] = [];

    surveyTypes = SurveyTypes;
    surveyQuestionTypes = SurveyQuestionTypes;

    public constructor(
        private callService: CallService,
        private customerStateService: CustomerStateService
    ) {}

    ngOnInit(): void {
        if (this.customerStateService.customer) {
            this.type = Helper.getCustomerGenericType(
                this.customerStateService.customer
            );
        }
        this.setDisplayActivitiesSurveys();
    }

    openAccordians(): void {
        setTimeout(() => {
            this.accordions.forEach((element) => {
                element.openAll();
            });
        }, 0);
    }

    //Public methods
    saveCall(): void {
        setTimeout(async () => {
            // wait on cycle to let the binding change happen
            await this.callService.saveCallAndNotify();
        }, 0);
    }

    setDisplayActivitiesSurveys(): void {
        this.displayActivities = new Array<ActivitySurveyViewModel>();
        this.displaySurveys = new Array<ActivitySurveyViewModel>();
        if (this.call?.surveys?.length && this.call?.surveyAnswers?.length) {
            for (const survey of this.call.surveys) {
                for (const response of survey.surveyResponses) {
                    response.surveyQuestionOptionDefault = response.answer?.surveyQuestionOptionId
                        ? response.surveyQuestionOptions.find((o) => o.id === response.answer.surveyQuestionOptionId)
                        : null
                    if (this.call.surveyAnswers.find((a) => a.id === response.answer.id)) {
                        if (survey.surveyType.id === SurveyTypes.Activity) {
                            this.displayActivities.push(survey);
                            break;
                        } else {
                            this.displaySurveys.push(survey);
                            break;
                        }
                    }
                }
            }
        }
    }

    shouldShowEmptyMessage(vms: ActivitySurveyViewModel[], type: SurveyTypes): boolean {
        return !vms || !vms.filter(vm => vm?.surveyType?.id === type).length
    }

    onSurveyResponse(event: any, response: ActivitySurveyResponseViewModel): void {
        if (!response?.answer || !response?.answer?.id) {
            response.answer = new CallSurveyAnswer();
            response.answer.id = newSequentialId();
            response.answer.callId = this.callService.call.id;
            response.answer.surveyId = response.surveyId;
            response.answer.surveyQuestionId = response.id;
        }
        switch (response.surveyQuestionType.id) {
            case SurveyQuestionTypes.CheckBox:
                if (event) {
                    response.answer.answer = "true";
                } else {
                    response.answer.answer = "false";
                }
                break;
            case SurveyQuestionTypes.DropDown:
                response.answer.surveyQuestionOptionId = event.id;
                response.answer.answer = event.text;
                break;
            default:
                response.answer.answer = event;
                break;
        }
        this.saveCall();
    }
}
