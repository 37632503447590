import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    GenericVersionResponseDto, ProjectDto, SharedHelper,
    SystemInformationKeys, VersionResponseDto
} from "shield.shared";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { ProjectConverterService } from "src/app/services/converter-services/project-converter.service";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { SyncLevel } from "../sync-enums/sync-level.enum";

@Injectable()
export class ProjectDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.None;
    offlineRepSyncType = SyncLevel.Required;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.project;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing projects...");

        await this.pullData();

        this.log("Done syncing projects...");
    }

    async pushData(): Promise<void> {
        return;
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/projects/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("Project data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Project data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.projectPullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;
        try {
            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;

                const response = await this.http
                    .get<GenericVersionResponseDto<ProjectDto[]>>(`/api/projects${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                this.log(
                    `Downloaded ${thisBatchSize} projects, saving to IndexedDB...`
                );

                if (thisBatchSize) {
                    const projects = response.values.map((c) =>
                        ProjectConverterService.projectDtoToProject(c)
                    );


                    await this.dbService.projects.bulkPut(projects);
                    this.log(`  Saved  ${projects.length} projects.`);

                    this.lastSyncVersion = response.maxVersion;
                }
            } while (thisBatchSize > 0);

            this.log("Done saving projects data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing projects", e);
        }
    }
}
