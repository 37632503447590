import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { ContactViewModel } from "../../contact/contact.viewmodel";

@Component({
    selector: "app-related-contacts",
    templateUrl: "./related-contacts.component.html",
    styleUrls: ["./related-contacts.component.scss"]
})
export class RelatedContactsComponent {
    private _customer: Customer;
    @Input()
    get customer(): Customer {
        return this._customer;
    }
    set customer(value: Customer) {
        this._customer = value;
    }

    private _contactOptions: ContactViewModel[];
    @Input()
    get contactOptions(): ContactViewModel[] {
        return this._contactOptions;
    }
    set contactOptions(value: ContactViewModel[]) {
        setTimeout(() => {
            this._contactOptions = value;
        }, 0);
    }

    private _currentEmployee: Employee;
    @Input()
    get currentEmployee(): Employee {
        return this._currentEmployee;
    }
    set currentEmployee(value: Employee) {
        this._currentEmployee = value;
    }

    @Output() onAddContact = new EventEmitter();
    @Output() onEditContact = new EventEmitter<ContactViewModel>();
    @Output() onRemoveContact = new EventEmitter<ContactViewModel>();
}
