import { GenericLookup } from "../generic";

export enum ContractPaymentStatuses {
    Signed = 1,
    Compliant = 2,
    NonCompliant = 3,
    Completed = 4,
    Canceled = 5
}

export const ContractPaymentStatusLookup: GenericLookup<ContractPaymentStatuses>[] = [
    { id: ContractPaymentStatuses.Signed, name: "Signed", description: "Signed" },
    { id: ContractPaymentStatuses.Compliant, name: "Compliant", description: "Compliant" },
    { id: ContractPaymentStatuses.NonCompliant, name: "NonCompliant", description: "Non Compliant" },
    { id: ContractPaymentStatuses.Completed, name: "Completed", description: "Completed" },
    { id: ContractPaymentStatuses.Canceled, name: "Canceled", description: "Canceled" }
  ];
