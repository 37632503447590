<div #myFilter>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-store-customerId">Created By
            </mat-label>
            <input #accountSearchInput id="customer-store-customerId-input" type="text" (keyup)="onAddManagerRefiner($event)"
                [(ngModel)]="viewmodel.managerInput" matInput />
        </mat-form-field>
    </div>
    <div class="d-flex justify-content-between align-items-center">
        <div class="w-100"><app-zrt-tree-view #zrtTree [displayName]="'Assigned'"></app-zrt-tree-view></div>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-street">Street</mat-label>
            <input #streetInput id="street-input" type="text"
                (keyup)="onAddStreetRefiner($event)" [(ngModel)]="viewmodel.streetInput" matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-city">City</mat-label>
            <input #filterCityInput id="filter-city-input" type="text"
                (keyup)="onAddCityRefiner($event)" [(ngModel)]="viewmodel.cityInput"
                matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>State</mat-label>
            <mat-select #stateSelect [(value)]="viewmodel.selectedStates" (openedChange)="openedStateChange()"
                multiple>
                <mat-option *ngFor="let vm of viewmodel.filteredStates" [value]="vm">
                    {{ vm.state.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>
                {{
                viewmodel.selectedStates?.length > 0
                ? "County"
                : "County, Please select a state to enable option"
                }}
            </mat-label>
            <mat-select #countySelect [(value)]="viewmodel.selectedCounties" [disabled]="
                !viewmodel.selectedStates ||
                viewmodel.selectedStates.length === 0"
                (openedChange)="openedCountyChange()"
                [compareWith]="compareCountyOptions"
                multiple>
                <mat-optgroup *ngFor="let group of viewmodel.groupedCountiesByState" [label]="group.name">
                    <mat-option class="offset-mat-option" *ngFor="let vm of group.items" [value]="vm">
                        {{ vm.county.name }}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="mat-input-search w-100" color="accent">
            <mat-label id="zip">Zip</mat-label>
            <input #zipInput id="Zip-input" type="text"
                (keyup)="onAddZipRefiner($event)" [(ngModel)]="viewmodel.zipInput"
                mask="999999999" matInput digitOnly />
        </mat-form-field>
    </div>
</div>
