import { Injectable } from "@angular/core";
import { GenericResponseDto } from "shield.shared";
import { WholesalerGroupMember } from "src/app/entity-models/wholesaler-group-member.entity";
import { DatabaseService } from "../database.service";
import { WholesalerGroupMemberOfflineService } from "../offline-services/wholesaler-group-member-offline.service";
import { WholesalerGroupMemberOnlineService } from "../online-services/wholesaler-group-member-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class WholesalerGroupMemberDelineationService extends DelineationContext<WholesalerGroupMember, string> {

    constructor(private wholesalerGroupMemberOfflineService: WholesalerGroupMemberOfflineService
        , private wholesalerGroupMemberOnlineService: WholesalerGroupMemberOnlineService
        , snackbarService: SnackbarService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService){
            super(dbService, datasourceDelineationService, snackbarService);
        }

    async getByWholesalerId(id: string): Promise<GenericResponseDto<WholesalerGroupMember>> {

        const offline = (key: string) => {
            return this.wholesalerGroupMemberOfflineService.getByWholesalerId(key);
        }
        const online = (key: string) => {
            return this.wholesalerGroupMemberOnlineService.getByWholesalerId(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, WholesalerGroupMember>(id, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getByWholesalerIds(ids: string[]): Promise<GenericResponseDto<WholesalerGroupMember[]>> {

        const offline = (key: string[]) => {
            return this.wholesalerGroupMemberOfflineService.getByWholesalerIds(key);
        }
        const online = (key: string[]) => {
            return this.wholesalerGroupMemberOnlineService.getByWholesalerIds(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string[], WholesalerGroupMember[]>(ids, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }
}
