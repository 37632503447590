<div class="inner-card d-flex justify-content-center align-items-center">
    <div class="mr-auto text-left">
        <div class="mb-3">
            Special coverage allows the user to upload a list of Mpower store IDs and view those stores in the customer list.
        </div>
        <div class="mb-3">
            The uploaded file must be saved in the .csv, .xls, or .xlsx file format with "Retail Number" as the column header name.
        </div>
        <div class="mb-3">
            *See the sample on the right for reference.
        </div>
    </div>
    <div class="ml-2">
        <img [src]="viewmodel.imageUrl"/>
    </div>
</div>
