import { StateLicenseDto } from "shield.shared"
import { StateLicense } from "src/app/entity-models/state-license.entity"

export class StateLicenseConverterService {

    static licenseToLicenseDto(l: StateLicense): StateLicenseDto {

        const dto = new StateLicenseDto();

        dto.id = l.id;
        dto.description = l.description;
        dto.isActive = l.isActive;
        dto.number = l.number;
        dto.rowversion = l.rowversion;
        dto.stateId = l.stateId;
        dto.stateName = l.stateName;
        dto.stateShortCode = l.stateShortCode;
        dto.subsidiaryId = l.subsidiaryId;
        dto.subsidiaryName = l.subsidiaryName;

        return dto;
    }

    static licenseDtoToLicense(dto: StateLicenseDto): StateLicense {

        const entity = new StateLicense();

        entity.id = dto.id;
        entity.description = dto.description;
        entity.isActive = dto.isActive;
        entity.number = dto.number;
        entity.rowversion = dto.rowversion;
        entity.stateId = dto.stateId;
        entity.stateName = dto.stateName;
        entity.stateShortCode = dto.stateShortCode;
        entity.subsidiaryId = dto.subsidiaryId;
        entity.subsidiaryName = dto.subsidiaryName;

        return entity;
    }
}
