import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { SharedHelper } from 'shield.shared';
import { WholesalerProductCatalogItems } from 'src/app/entity-models/wholesaler-product-catalog-items.entity';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';
import { DialogService } from 'src/app/services/dialog.service';
import { AvailableToOrderDialogViewModel } from './available-to-order-dialog.viewmodel';

@UntilDestroy()
@Component({
    selector: 'available-to-order-dialog',
    templateUrl: './available-to-order-dialog.component.html',
    styleUrls: ['./available-to-order-dialog.component.scss']
})
export class AvailableToOrderDialogComponent implements OnInit {

    viewModel: AvailableToOrderDialogViewModel;
    form: FormGroup;

    constructor(
        private dialogRef: SwisherOverlayRef<
            AvailableToOrderDialogViewModel,
            AvailableToOrderDialogComponent>,
        formBuilder: FormBuilder,
        private dialogService: DialogService,
    ) {
        this.viewModel = this.dialogRef.data;
        const orderableIds = this.viewModel.catalogItem.wholesalerItems
            .filter(wi => !wi.isDeactivated)
            .map(wi => wi.wholesalerId);

        this.form = formBuilder.group({
            items: formBuilder.array(this.viewModel.groupMembers.map(member =>
                formBuilder.group({
                    id: member.wholesalerId,
                    orderable: [orderableIds.indexOf(member.wholesalerId) >= 0]
                })
            ))
        });

        this.viewModel.shouldClose = () => this.shouldClose();

        this.viewModel.dialogButtonClick$.pipe(untilDestroyed(this)).subscribe(button => {
            switch (button) {
                case "save":
                    this.save();
                    break;
                case "close":
                    this.cancel();
                    break;
                case "remove":
                    this.removeProductFromGroup();
                    break;
            }
        });
    }

    removeProductFromGroup() {
        this.dialogService.showConfirmDialog(
            `This action will deactivate the product in the group and all wholesalers that are part
      of the group. The product will no longer be orderable, but will still be viewable on past orders.
      Remove this product?`
        ).pipe(take(1)).subscribe(confirm => {
            if (confirm) {
                this.viewModel.dialogComplete(true);
            }
        });
    }

    cancel() {
        this.dialogRef.close();
    }

    shouldClose() {
        if (this.form.pristine) {
            return true;
        } else {
            this.dialogService.showConfirmDialog().pipe(take(1)).subscribe(confirm => {
                if (confirm) {
                    this.dialogRef.close(null, true);
                }
            });
            return false;
        }
    }

    save() {
        const orderableStatus = SharedHelper.groupBy(
            this.form.value.items as { id: string, orderable: boolean }[],
            item => (item.orderable as boolean).toString()
        );

        const orderableIds = this.viewModel.catalogItem.wholesalerItems.map(wi => wi.wholesalerId);

        for (const item of orderableStatus.get(true.toString()) ?? []) {
            if (orderableIds.indexOf(item.id) < 0) {
                const newItem = new WholesalerProductCatalogItems();
                newItem.wholesalerGroupProductCatalogItemId = this.viewModel.catalogItem.id;
                newItem.wholesalerId = item.id;
                newItem.productId = this.viewModel.catalogItem.productId;
                this.viewModel.catalogItem.wholesalerItems.push(newItem);
            }
        }

        for (const item of orderableStatus.get(false.toString()) ?? []) {
            const orderableIndex = orderableIds.indexOf(item.id);
            if (orderableIndex >= 0) {
                const removeIndex = this.viewModel.catalogItem.wholesalerItems
                    .map(item => item.wholesalerId)
                    .indexOf(item.id);
                this.viewModel.catalogItem.wholesalerItems[removeIndex].isDeactivated = true;
                this.viewModel.catalogItem.wholesalerItems[removeIndex].isDeactivatedDate = new Date();
            }
        }

        this.viewModel.dialogComplete(false);
        this.form.markAsPristine();
    }

    ngOnInit(): void {

    }

    markAll() {
        (this.form.controls['items'] as FormArray).controls.forEach((control: FormGroup) => {
            control.controls['orderable'].setValue(true);
        })
    }

}
