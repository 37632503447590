import { CallCashProduct } from "./call-cash-product.entity";
import { CallGratisProduct } from "./call-gratis-product.entity";
import { CallOrderProduct } from "./call-order-product.entity";
import { CallExchangeOutProduct } from "./call-exchange-out-product.entity";
import { CallExchangeInProduct } from "./call-exchange-in-product.entity";
import { CallPicture } from "./call-picture.entity";
import { Contact } from "./contact.entity";
import { CallTypes } from "shield.shared";
import { CallReceipt } from "./call-receipt";
import { CallProductStatus } from "./call-product-status.entity";
import { CallSurveyAnswer } from "./call-survey-answer.entity";
import { ActivitySurveyViewModel } from "../accounts/account.viewmodels/activity-survey.viewmodel";
import { Project } from "./project.entity";
export class RetailCall {
    constructor() {
        this.callType = CallTypes.retail;
    }
    id: string; //UUID
    callNumber: string;
    customerId: string;
    createdUserId: string;
    createdUtcDateTime: Date;
    createdUserZrt: string;
    createdUserFullName: string
    modifiedUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    px3RankId?: string;
    px3Rank?: string;
    startTime: Date;
    stopTime: Date;
    durationMins: number;
    surveys: ActivitySurveyViewModel[] = [];
    surveyAnswers: CallSurveyAnswer[] = [];
    callPictures: CallPicture[] = [];
    inDistProductIds: string[] = [];
    cashProducts: CallCashProduct[] = [];
    orderProducts: CallOrderProduct[] = [];
    gratisProducts: CallGratisProduct[] = [];
    exchangeInProducts: CallExchangeInProduct[] = [];
    exchangeOutProducts: CallExchangeOutProduct[] = [];
    callProductStatus: CallProductStatus[] = [];
    unsalableProductIds: string[] = [];
    generalDiscount = false;
    closingNotes: string;
    signature: string;

    selectedContact: Contact;
    receiptBase: number;
    wholsaleReceiptNumbers: number[];
    callType: CallTypes.retail | CallTypes.rmWholesale;
    isDeleted: boolean;
    callReceipts: CallReceipt[] = [];
    rowversion: string;

    //No need to store these beyond dexie. For current call only
    evaluatedProducts?: string[] = [];
    productsIntro?: string[] = [];
    productsOos?: string[] = [];
    productsCos?: string[] = [];
    productsOrder?: string[] = [];
    productsCash?: string[] = [];
    farthestIndex?: number;
    lastIndexVisited?: number;
    shouldVisitTax = false;
    inDistAddedFromLastCall?: boolean;
    isFinalRetailReceiptPrinted: boolean;
    isFinalWholesaleReceiptPrinted: boolean;
    isEmailFinalRetailReceipt: boolean;
    isEmailFinalWholesaleReceipt: boolean;
    selectedProject: Project;
    hasServerProcessed: number;
}
