import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { WholesalerGroupsListComponent } from "./wholesaler-groups/wholesaler-groups-list.component";
import { ZoneRecapReportComponent } from "./zone-recap-report/zone-recap-report.component";
import { Px3ImportComponent } from "./px3-import/px3-import/px3-import.component";

export const routes: Routes = [
    {
        canActivate: [MsalGuard],
        path: "resources/wholesaler-groups",
        component: WholesalerGroupsListComponent
    },
    {
        canActivate: [MsalGuard],
        path: "resources/zone-recap-report",
        component: ZoneRecapReportComponent
    },
    {
        canActivate: [MsalGuard],
        path: "resources/px3-import",
        component: Px3ImportComponent
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class ResourcesRoutingModule {}
