import { Subscription } from "rxjs";
import { DayTimeEntryWorkWith } from "src/app/entity-models/day-time-entry-work-with.entity";
import { Helper } from "src/app/helpers/helper";

export class DayTimeEntryWorkWithViewmodel {
    id: string; //uuid
    dayTimeEntryId: string;
    person: string;
    start: Date;
    end: Date;
    duration: string;
    worksWithStartTimeSubscription: Subscription;
    worksWithEndTimeSubscription: Subscription;

    setDuration(): void {
        const startTime: number = this.start?.setSeconds(0,0);
        const endTime: number = this.end?.setSeconds(0,0);

        if (startTime && endTime && startTime <= endTime) {
            this.duration = Helper.getHoursAndMinutesFromMilliseconds(
                endTime - startTime
            );
        }
    }

    buildDomainModelFromViewmodel(): DayTimeEntryWorkWith {
        const ww = new DayTimeEntryWorkWith();
        ww.id = this.id;
        ww.person = this.person;
        ww.start = this.start;
        ww.end = this.end;

        return ww;
    }
}
