import {
    HttpClient,
    HttpHeaders
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    FilterRequestV2Dto,
    GenericRequestDto,
    GenericResponseDto,
    GratisApprovalDto,
    GratisAssignedTransactionDto,
    GratisBatchParamsDto,
    GratisCurrentBalanceDto,
    GratisDto,
    GratisListColumns,
    GratisListFilterDto,
    GratisRejectApprovalParamsDto, NamedStringDto, newSequentialId
} from "shield.shared";
import { GratisApproval } from "../../entity-models/gratis-approval.entity";
import { Gratis } from "../../entity-models/gratis.entity";
import { GratisAssignedTransaction } from "../../entity-models/gratis-assigned-transactions.entity";
import { GratisConverterService } from "../converter-services/gratis-converter.service";
import { StateDelineationService } from "../delineation-services/state-delineation.service";
import { ConverterHelper } from "../converter-services/converter.helper";
import { State } from "src/app/entity-models/state.entity";
import { GratisApprovalConverterService } from "../converter-services/gratis-approval-converter.service";
import { GratisAssignedTransactionConverterService } from "../converter-services/gratis-assigned-transaction-converter.service";

@Injectable()
export class GratisOnlineService {

    constructor(
        private http: HttpClient,
        private stateDelineationService: StateDelineationService
    ) {
        this.headers = new HttpHeaders().set('Cache-Control', 'no-cache');
    }

    headers: HttpHeaders;

    async completeOrder(gratis: Gratis) : Promise<GenericResponseDto<GratisApproval>> {

        const request = new GenericRequestDto<GratisDto>();
        request.id = gratis.id;
        request.values = GratisConverterService.gratisToGratisDto(gratis);

        const result = await this.http
            .post<GenericResponseDto<GratisApprovalDto | undefined>>(`/api/gratis/complete`, request).toPromise();

        return ConverterHelper.dtoToEntity(result, (r) => GratisApprovalConverterService.dtoToEntity(r, request.id));
    }

    async deletedGratisRequest(gratisId: string): Promise<GenericResponseDto<string>> {

        const result = await this.http
            .get<GenericResponseDto<string>>(`/api/gratis/${gratisId}/delete`).toPromise();

        return result;
    }

    async getCurrentBalances(key: undefined): Promise<GenericResponseDto<GratisCurrentBalanceDto[]>> {

        const result = await this.http
            .get<GenericResponseDto<GratisCurrentBalanceDto[]>>("/api/gratis/current-balance").toPromise();

        return ConverterHelper.dtoToEntityArray(result, (r) => r);
    }

    async getGratisById(id: string): Promise<GenericResponseDto<Gratis | undefined>> {

        const result = await this.http
            .get<GenericResponseDto<GratisDto | undefined>>(`/api/gratis/${id}`).toPromise();

        let shipToState: State = null;
        if (result?.values) {
            shipToState = (await this.stateDelineationService.getById(result.values.shipToState))?.values;
        }

        return ConverterHelper.dtoToEntity(result, (r) => GratisConverterService.gratisDtoToGratis(r, shipToState));
    }

    async getBatch(
        key: GratisBatchParamsDto
    ): Promise<GenericResponseDto<Gratis[]>> {

        const request = new GenericRequestDto<FilterRequestV2Dto<GratisListFilterDto, GratisListColumns>>();
        request.id = newSequentialId();
        request.values = key.filterRequestDto;

        const result = await this.http
            .post<GenericResponseDto<GratisDto[]>>(
                "/api/gratis",
                request
            )
            .toPromise();

        return ConverterHelper.dtoToEntityArray(result, (r)=> {
            let shipToState: State = null;
            if (r) {
                this.stateDelineationService.getById(r.shipToState);
            }

            return GratisConverterService.gratisDtoToGratis(r, shipToState)
        })
    }

    async getAvailableForGratis(gratisId?: string): Promise<GenericResponseDto<GratisAssignedTransaction[]>> {

        let result: GenericResponseDto<GratisAssignedTransactionDto[]>;

        if (gratisId != null) {
            result = await this.http
                .get<GenericResponseDto<GratisAssignedTransactionDto[]>>(`/api/gratis/get-available-transactions?gratisId=${gratisId}`,
                { headers: this.headers }).toPromise();
        }
        else {
            result = await this.http
                .get<GenericResponseDto<GratisAssignedTransactionDto[]>>(`/api/gratis/get-available-transactions`,
                { headers: this.headers }).toPromise();
        }

        return ConverterHelper.dtoToEntityArray(result, (r) => GratisAssignedTransactionConverterService.dtoToEntity(r));
    }

    async saveGratisRequest(gratisRequest: GenericRequestDto<Gratis>): Promise<GenericResponseDto<Gratis | undefined>> {

        const gratisDto = GratisConverterService.gratisToGratisDto(gratisRequest.values);
        const request = new GenericRequestDto<GratisDto>();
        request.id = gratisRequest.id;
        request.values = gratisDto;

        const result = await this.http
            .post<GenericResponseDto<GratisDto | undefined>>(`/api/gratis/save`, request).toPromise();

        let shipToState: State = null;
        if (result?.values) {
            shipToState = (await this.stateDelineationService.getById(result.values.shipToState))?.values;
        }
        return ConverterHelper.dtoToEntity(result, (r) => GratisConverterService.gratisDtoToGratis(r, shipToState));
    }

    async submitGratisRequest(gratisRequest: GenericRequestDto<Gratis>): Promise<GenericResponseDto<Gratis | undefined>> {

        const gratisDto = GratisConverterService.gratisToGratisDto(gratisRequest.values);
        const request = new GenericRequestDto<GratisDto>();
        request.id = gratisRequest.id;
        request.values = gratisDto;

        const result = await this.http
            .post<GenericResponseDto<GratisDto | undefined>>(`/api/gratis/submit`, request).toPromise();

        let shipToState: State = null;
        if (result?.values) {
            this.stateDelineationService.getById(result.values.shipToState);
        }
        return ConverterHelper.dtoToEntity(result, (r) => GratisConverterService.gratisDtoToGratis(r, shipToState));
    }


    async rejectApproval(params: GratisRejectApprovalParamsDto): Promise<GenericResponseDto<Gratis>> {

        const result = await this.http
            .post<GenericResponseDto<GratisDto>>(`/api/gratis/${params.gratisId}/reject`, new NamedStringDto(params.reason) ).toPromise();

        let shipToState: State = null;
        if (result?.values) {
            this.stateDelineationService.getById(result.values.shipToState);
        }
        return ConverterHelper.dtoToEntity(result, (r) => GratisConverterService.gratisDtoToGratis(r, shipToState));
    }

    async approve(gratisId: string): Promise<GenericResponseDto<Gratis>> {

        const result = await this.http
            .get<GenericResponseDto<GratisDto>>(`/api/gratis/${gratisId}/approve`).toPromise();

        let shipToState: State = null;
        if (result?.values) {
            this.stateDelineationService.getById(result.values.shipToState);
        }
        return ConverterHelper.dtoToEntity(result, (r) => GratisConverterService.gratisDtoToGratis(r, shipToState));
    }
}
