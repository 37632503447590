<div class="container">
    <div class="row top-section">
        <div class="text-left col-12" [ngClass]="{ 'col-md-7': viewModel.canEdit }">
            Available for Order in {{viewModel.catalogItem.wholesalerItems.length}}
            out of {{ viewModel.groupMembers.length }} group members
        </div>
        <div class="col-12 col-md-5" *ngIf="viewModel.canEdit">
            <button class="btn btn-secondary" (click)="markAll()">Mark Orderable for all Wholesalers</button>
        </div>
    </div>
    
    <div class="row overflow-auto" style="height: 75vh; width: 900px;">
        <form [formGroup]="form" class="w-100">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Number</th>
                        <th scope="col">Address</th>
                        <th scope="col">City</th>
                        <th scope="col">State</th>
                        <th scope="col">Zip</th>
                        <th scope="col">Orderable</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="!viewModel.groupMembers.length">
                        <td colspan="7"><em>No members found.</em></td>
                    </tr>
                    <tr *ngFor="let member of viewModel.groupMembers; let i = index" formArrayName="items">
                        <td class="text-left">
                            {{ member.name }}
                        </td>
                        <td class="text-center">
                            {{ member.customerNumber }}
                        </td>
                        <td class="text-center">
                            {{ member.address }}
                        </td>
                        <td class="text-center">
                            {{ member.city }}
                        </td>
                        <td class="text-center">
                            {{ member.state }}
                        </td>
                        <td class="text-center">
                            {{ member.zip }}
                        </td>
                        <td class="text-center" [formGroupName]="i">
                            <span *ngIf="!viewModel.canEdit">
                                {{ form.value.items[i].orderable ? 'Y' : 'N' }}
                            </span>
                            <mat-checkbox formControlName="orderable" *ngIf="viewModel.canEdit"></mat-checkbox>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</div>