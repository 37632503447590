<div
    *ngIf="!mobile && callValidation && callValidation.length === 8"
    class="d-flex flex-column flex-grow-1">
    <mat-horizontal-stepper
        [linear]="isLinear"
        [selectedIndex]="selectedIndex"
        (selectionChange)="handleSelectionChange($event)"
        class="flex-grow-1"
    >
        <mat-step
            *ngFor="let step of steps; let i = index"
            [editable]="true"
            [completed]="
                farthestIndex > i && (callValidation[i].errorLevel !== validationInvalid)
            "
            [hasError]="farthestIndex > i && callValidation[i].errorLevel === validationInvalid"
            color="accent"
            [errorMessage]="callValidation[i].message"
            state="index"
        >
            <ng-template matStepLabel>
                <div title="{{ callValidation[i].hoverMessage }}">
                    {{ step.label }}
                </div>
            </ng-template>
            <div class="d-flex justify-content-between pt-2 pb-2">
                <span>
                    <button *ngIf="i > 0" mat-button matStepperPrevious>
                        Back
                    </button>
                </span>
                <span>
                    <button *ngIf="i < steps.length - 1" mat-button matStepperNext>
                        Next
                    </button>
                </span>
            </div>
            <ng-container [ngTemplateOutlet]="step.content"></ng-container>
            <div class="d-flex justify-content-between pt-2">
                <span>
                    <button *ngIf="i > 0" mat-button matStepperPrevious>
                        Back
                    </button>
                </span>
                <span>
                    <button *ngIf="i < steps.length - 1" mat-button matStepperNext>
                        Next
                    </button>
                </span>
            </div>
        </mat-step>

        <ng-template matStepperIcon="edit">
            <mat-icon style="top: -3px">check</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="error">
            <mat-icon color="warn">warning</mat-icon>
        </ng-template>
    </mat-horizontal-stepper>
</div>

<div *ngIf="mobile">
    <mat-vertical-stepper
        [linear]="isLinear"
        [selectedIndex]="selectedIndex"
        (selectionChange)="handleSelectionChange($event)"
    >
        <mat-step
            *ngFor="let step of steps; let i = index"
            [editable]="true"
            [completed]="farthestIndex > i && callValidation[i].errorLevel !== validationInvalid"
            [hasError]="farthestIndex > i && callValidation[i].errorLevel === validationInvalid"
            color="accent"
            [errorMessage]="callValidation[i].message"
            state="index"
        >
            <ng-template matStepLabel>
                <div title="{{ callValidation[i].hoverMessage }}">
                    {{ step.label }}
                </div>
            </ng-template>
            <div class="d-flex justify-content-between pt-2">
                <span>
                    <button *ngIf="i > 0" mat-button matStepperPrevious>
                        Back
                    </button>
                </span>
                <span>
                    <button *ngIf="i < steps.length" mat-button matStepperNext>
                        Next
                    </button>
                </span>
            </div>
            <ng-container [ngTemplateOutlet]="step.content"></ng-container>
            <div class="d-flex justify-content-between pt-2">
                <span>
                    <button *ngIf="i > 0" mat-button matStepperPrevious>
                        Back
                    </button>
                </span>
                <span>
                    <button *ngIf="i < steps.length" mat-button matStepperNext>
                        Next
                    </button>
                </span>
            </div>
        </mat-step>

        <ng-template matStepperIcon="edit">
            <mat-icon style="top: -3px">check</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="error">
            <mat-icon color="warn">warning</mat-icon>
        </ng-template>
    </mat-vertical-stepper>
</div>
