import { Px3IncentivePeriod } from "src/app/entity-models/px3-incentive-period.entity";
import { GenericConverter } from "./generic-converter.service";
import { Px3IncentivePeriodDto } from "shield.shared";
import { Injectable } from "@angular/core";

@Injectable()
export class Px3IncentivePeriodConverter
    extends GenericConverter<Px3IncentivePeriod, Px3IncentivePeriodDto>
{
    entityToDto(entity: Px3IncentivePeriod): Px3IncentivePeriodDto {
        return {
            id: entity.id,
            startDate: entity.startDate.toUTCString(),
            endDate: entity.endDate.toUTCString(),
        };
    }

    dtoToEntity(dto: Px3IncentivePeriodDto): Px3IncentivePeriod {
        return new Px3IncentivePeriod(dto.id, new Date(dto.startDate), new Date(dto.endDate));
    }
}
