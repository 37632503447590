import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    ReplaySubject,
    Subject,
    Subscription
} from "rxjs";
import {
    map,
    switchMap,
    tap,
    withLatestFrom
} from "rxjs/operators";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { RouteDelineationService } from "src/app/services/delineation-services/route-delineation.service";
import { VolumeDelineationService } from "src/app/services/delineation-services/volume-delineation.service";

@Component({
    selector: "app-route-px3-snapshot",
    templateUrl: "./route-px3-snapshot.component.html",
    styleUrls: ["./route-px3-snapshot.component.scss"]
})
export class RoutePx3SnapshotComponent implements OnInit, OnDestroy {

    private routeId$ = new ReplaySubject<string>(1);
    backToDetailsClicked$ = new Subject<void>();
    private onBackToDetailsClickedSubscription: Subscription;
    routeCustomers$ = this.routeId$.pipe(
        switchMap((id) => this.routeDelineationService.getById(id)),
        map((response) => response.values.stops.map((stop) => stop.customerId)),
        switchMap((ids) => this.customerDelineationService.getByIds(ids)),
        map((response) => response.values.filter((c) => c.isMsa))
    );

    private onBackToDetailsClicked$ = this.backToDetailsClicked$.pipe(
        withLatestFrom(this.routeId$),
        tap(([_, routeId]) =>
            this.router.navigate([
                "my-day",
                "route-management",
                routeId,
                "details"
            ])
        )
    );

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private routeDelineationService: RouteDelineationService,
        private customerDelineationService: CustomerDelineationService,
    ) { }

    ngOnInit() {
        this.routeId$.next(this.route.snapshot.params["routeId"]);
        this.onBackToDetailsClickedSubscription = this.onBackToDetailsClicked$.subscribe();
    }

    ngOnDestroy(): void {
        this.onBackToDetailsClickedSubscription.unsubscribe();
    }
}
