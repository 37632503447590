<ng-container *ngIf="projectStateService.project" class="d-flex flex-column flex-grow-1">
    <div #headerContainer>
        <div class="row">
            <div class="col-12">
                <app-page-header #header [name]="viewmodel?.headerName"
                    [entryCount]="viewmodel?.total ? viewmodel.total : 0" [buttons]="viewmodel?.headerButtons"
                    [refinerService]="viewmodel?.refinerService"
                    [subAvailableColumns]="viewmodel?.availableColumns"
                    (onClickButton)="viewmodel?.onButtonClickEvent($event)"
                    (onButtonAction)="viewmodel?.onButtonAction($event)"
                    (headerExpansionChanged)="viewmodel?.headerExpansionChanged()"
                    (onColumnVisabilityMenuCloseEvent)="viewmodel?.setColumnVisibilityCommunicator($event)">
                </app-page-header>
            </div>
        </div>
        <div class="row">
            <div class="col-1 v-center">
                <button class="btn btn-secondary mr-1 actionBtn" data-toggle="tooltip" data-placement="bottom"
                    title="View Filters" (click)="viewmodel?.showFilters()">
                    <span class="white-space-nowrap">
                        <span class="material-icons">filter_alt</span>
                        Filters •
                        {{ viewmodel?.refinerService.refiners.length }}
                    </span>
                </button>
            </div>
            <div class="col-3 v-center">
                <mat-button-toggle-group [value]="viewmodel.selectedAssignmentValue"
                    (change)="viewmodel.assignmentChange($event)" name="assignment" aria-label="Assignments">
                    <mat-button-toggle [value]="viewmodel.all">Show All</mat-button-toggle>
                    <mat-button-toggle [value]="viewmodel.assigned"> Assigned - {{viewmodel.assignedCount | number}}</mat-button-toggle>
                    <mat-button-toggle [value]="viewmodel.unassigned">Unassigned - {{viewmodel.unassignedCount | number}}</mat-button-toggle>
                  </mat-button-toggle-group>
            </div>
            <div class="col">
                <mat-form-field appearance="outline" class="w-100 mat-input-search" color="accent">
                    <mat-label id="store-customerId">Store Name</mat-label>
                    <input #accountSearchInput id="transaction-store-customerId-input" type="text"
                        (keyup)="viewmodel.onAddAccountRefiner($event)" [(ngModel)]="viewmodel.accountInput"
                        matInput />
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field appearance="outline" class="w-100 mat-input-search" color="accent">
                    <mat-label id="store-city">City</mat-label>
                    <input #citySearchInput id="transaction-store-customerId-input" type="text"
                        (keyup)="viewmodel.onAddCityRefiner($event)" [(ngModel)]="viewmodel.cityInput"
                        matInput />
                </mat-form-field>
            </div>
            <div class="col-2 v-center">
                <button appThrottleClick mat-raised-button (throttledClick)="viewmodel?.search()" [disabled]="viewmodel.isSearchButtonDisabled"
                    class="btn btn-primary actionBtn pull-right">SEARCH</button>
                <button mat-raised-button (click)="viewmodel?.reset()" class="btn btn-secondary pull-right">RESET</button>
            </div>
        </div>
    </div>
    <mat-sidenav-container class="sidenav-container d-flex flex-column flex-grow-1" autosize>
        <mat-sidenav #drawer [mode]="viewmodel?.drawerMode" [fixedInViewport]="true"
            [fixedTopGap]="viewmodel?.topGapDistance" [fixedBottomGap]="0" class="sidenav">
            <app-filter-container [refinerService]="viewmodel?.refinerService" [filters]="viewmodel?.filters"
                [isSearchDisabled]="viewmodel.isSearchButtonDisabled" [filterLocation]="viewmodel.filterLocation"
                (search)="viewmodel?.search()" (reset)="viewmodel?.reset()">
            </app-filter-container>
        </mat-sidenav>
        <mat-sidenav-content class="d-flex flex-column flex-grow-1">
            <div class="sidenav-content d-flex flex-column flex-grow-1">
                <ng-container *ngIf="viewmodel?.dataSource" class="d-flex flex-column flex-grow-1">
                    <div #gridContainer class="row d-flex flex-column flex-grow-1">
                        <div class="col-12 table-responsive d-flex flex-column flex-grow-1">
                            <app-grid *ngIf="projectApplicationService.selectedIndex == currentStep"
                                #grid [(dataSource)]="viewmodel.dataSource"
                                [columnDefinitions]="viewmodel?.columnDef" [searchRefiners]="viewmodel?.activeRefiners"
                                [selectedColumnVisability]="viewmodel?.selectedColumnVisibilityComunicator"
                                [showExpanderToggle]="false" [isFixedLayout]="viewmodel?.isFixedLayout"
                                [height]="viewmodel?.gridHeight" [detailHeight]="viewmodel?.detailHeight"
                                [itemsRendedInViewPort]="viewmodel?.itemsRendedInViewPort" [itemSize]="50"
                                [sortFunction]="viewmodel.sortFunction"
                                (getDataBatch)="viewmodel?.getBatch($event)"
                                (rowSelectionChange)="viewmodel.setAssignment($event)"
                                class="d-flex flex-column flex-grow-1">
                            </app-grid>
                        </div>
                    </div>
                </ng-container>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>
