import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
    EmployeeDto, GenericVersionResponseDto, SharedHelper,
    SystemInformationKeys, VersionResponseDto
} from "shield.shared";
import { DatabaseService } from "../../services/database.service";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { EmployeeConverterService } from "src/app/services/converter-services/employee-converter.service";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { SyncLevel } from "../sync-enums/sync-level.enum";

@Injectable()
export class EmployeeDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.Initial;
    offlineRepSyncType = SyncLevel.Initial;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.employee;
    constructor(private dbService: DatabaseService, private http: HttpClient) {
        super();
    }


    async execute(): Promise<void> {
        const lastVersion = this.lastSyncVersion;

        if (!(await this.checkVersion(lastVersion))) {
            this.isRunSuccessfull = true;
            this.log("Employee data is up to date");
            return;
        }

        this.log("Employee data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.employeePullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;
        try {
            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;
                const response = await this.http
                    .get<GenericVersionResponseDto<EmployeeDto[]>>(`/api/employees${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                this.log(
                    `Downloaded ${thisBatchSize} employees, saving to IndexedDB...`
                );

                const employees = response.values.map((dto) =>
                    EmployeeConverterService.employeeDtoToEmployee(dto)
                );

                // remove deleted employees
                const deletedEmployeeIds = employees
                    .filter((e) => e.isDeleted)
                    .map((e) => e.id);
                await this.dbService.employees.bulkDelete(deletedEmployeeIds);

                // put new/changed employees
                const putEmployees = employees.filter((e) => !e.isDeleted);
                await this.dbService.employees.bulkPut(putEmployees);

                this.lastSyncVersion = response.maxVersion;
            } while (thisBatchSize > 0);

            this.log("Done saving employee data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing employees", e);
        }
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/employees/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    async pushData(): Promise<void> {
        return;
    }
}
