<div class="wholesale-grid">
    <div class="wholesale-grid-item col-1--row-1">
        <div class="card card--info m-0 h-100">
            <div class="card-header d-flex justify-content-between align-items-center">
                Information
                <div class="card-header-btn">
                    <button class="btn sw-transparent-btn btn-sm" [routerLink]="['../info']">
                        <fa-icon [icon]="faEye"></fa-icon>
                    </button>
                </div>
            </div>
            <div class="card-body p-0 small-font">
                <div *ngIf="wholesalerViewmodel?.customer && customer" class="card-view-port">
                    <div class="row bottom-padding">
                        <div class="col">
                            <div class="row bottom-padding">
                                <div class="col">
                                    <b>Store Type:</b>
                                    {{wholesalerViewmodel?.customerType?.name}}
                                </div>
                                <div class="col">
                                    <b>Store Status:</b>
                                    {{ wholesalerViewmodel.customer.isActive ? 'Active' : 'Inactive' }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <b>Store Count:</b>
                                    {{wholesalerViewmodel.numberOfStores}}
                                </div>
                                <div class="col">
                                    <b>MSA:</b>
                                    {{wholesalerViewmodel.customer.isMsa ? "Yes" : "No"}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <b>MSA Status:</b>
                                    {{wholesalerViewmodel.customer.msaStatus}}
                                </div>
                                <div class="col">
                                    <b>Order Type:</b>
                                    {{wholesalerViewmodel.orderType}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <b>Rep:</b>
                                    {{wholesalerViewmodel.rep}}
                                </div>
                                <div class="col">
                                    <b>Program:</b>
                                    {{wholesalerViewmodel.customer.program}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <b>Group:</b>
                                    {{wholesalerViewmodel.groupName}}
                                </div>
                                <div class="col">
                                    <b>Group Rep:</b>
                                    Group Rep Name
                                </div>
                            </div>
                            <div class="row padding-bottom">
                                <div class="col shade py-1">
                                    <b>Licenses:</b>
                                </div>
                            </div>
                            <div class="row padding-bottom">
                                <div class="col shade py-1">
                                    <b>State Tobacco License:</b>
                                    {{wholesalerViewmodel.retailStateOTPLicense ? wholesalerViewmodel.retailStateOTPLicense : 'N/A'}}
                                </div>
                                <div class="col shade py-1">
                                    <b>State Vapor License:</b>
                                    {{wholesalerViewmodel.retailStateVaporLicense ? wholesalerViewmodel.retailStateVaporLicense : 'N/A'}}
                                </div>
                            </div>
                            <div class="row padding-bottom">
                                <div class="col shade py-1">
                                    <b>State Tax Id:</b>
                                    {{wholesalerViewmodel.stateTaxId ? wholesalerViewmodel.stateTaxId : 'N/A'}}
                                </div>
                                <div class="col shade py-1">
                                    <b>Federal Tax Id:</b>
                                    {{wholesalerViewmodel.federalTaxId ? wholesalerViewmodel.federalTaxId : 'N/A'}}
                                </div>
                            </div>
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>
                                        Store Comments
                                    </th>
                                    <th>
                                        Entered By
                                    </th>
                                    <th>
                                        Date
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        {{customer.comments}}
                                    </td>
                                    <td>
                                        {{customer.commentsLastUpdatedBy}}
                                    </td>
                                    <td>
                                        {{customer.commentsLastUpdated | date: dateFormat}}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wholesale-grid-item col-1--row-2">
        <app-related-contacts
            [customer]="customer"
            [contactOptions]="contactOptions"
            [currentEmployee]="currentEmployee"
            (onAddContact)="onAddContact()"
            (onEditContact)="onEditContact($event)"
            (onRemoveContact)="onRemoveContact($event)">
        </app-related-contacts>
    </div>
    <div class="wholesale-grid-item col-2-3--row-1-2">
        <app-customer-detailed-call-history #history
                                            [customer]="customer"
                                            callHistoryTitle="Call History"
                                            [openClosingNotes]="openClosingNotes">
        </app-customer-detailed-call-history>
    </div>
    <div class="wholesale-grid-item col-4--row-1-2">
        <app-related-projects
            [projects]="relatedProjects"
            (onOpenNewProject)="openNewProject()"
            (onOpenViewProject)="openViewProject($event)"
            [isNotTm]="isNotTm">
        </app-related-projects>
    </div>
</div>
