import { EmailAttachment } from "./email-attachment";

export class NotificationRequestDto {
    id: string;
    sendSms: boolean;
    sendEmail: boolean;
    employeeId: string;
    recipientEmployeeId: string;
    textMessage: string;
    message: string;
    subject: string;
    attachment?: EmailAttachment;
    attachments?: EmailAttachment[];
    recipientOtherPhone: string;
    recipientOtherEmail: string;
    callId?: string;
    receiptId?: string;
    sendToSelf?: boolean;
}
