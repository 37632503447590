<div class="w-100">
    <angular2-multiselect #dropdown
        [data]="employees"
        [(ngModel)]="selectedEmployees"
        [settings]="employeeDropdownSettings"
        (onSelect)="onSelect($event, selectedEmployees)"
        (onDeSelect)="onDeselect($event)"
        (onDeSelectAll)="onDeselect()"
    >
    </angular2-multiselect>
</div>
