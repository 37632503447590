export class AddressFilterDto {
    //Usage: Get data based on the business street/address1
    street?: string[];

    //Usage: Get data based on the business city
    city?: string[];

    //Usage: Get data based on the business county name
    //MV - I would like to deprecate this.
    //JM - We use this on the client; let's leave it in.
    counties?: string[];

    //Usage: Get data based on the business county Ids
    countyIds?: string[];

    //Usage: Get data based on the business state name
    //MV - I would like to deprecate this.
    //JM - We use this on the client; let's leave it in.
    states?: string[];

    //Usage: Get data based on the business state Ids
    stateIds?: string[];

    //Usage: Get data based on the business zip/postal code
    zipCode?: string;

}
