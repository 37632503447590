<ng-container *ngIf="projectApplicationService.selectedIndex == currentStep">
    <div class="row">
        <div class="col-lg-1 col-md-12 col-sm-12">
        </div>
        <div class="col-lg col-md-12 col-sm-12">
            <h1 class="m-teal">
                People
            </h1>
            <mat-checkbox color="warn" class="checkbox-margin" (change)="handleAssignmentBasedOnCustomers($event)">
                Add reps based on territory of stores
            </mat-checkbox>
        </div>
        <div class="col-2">
        </div>
        <div class="col-lg col-md-12 col-sm-12">
            <h2 class="m-teal">
                People Selected For Project: {{viewmodel?.assignedEmployees?.length ? viewmodel.assignedEmployees.length
                : 0}}
            </h2>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-1 col-md-1 col-sm-12">
            <div class="row">
                <div class="col">
                    <b><u>Zones</u></b>
                </div>
            </div>
            <div class="row workable-view-height">
                <div class="col">
                    <mat-radio-group aria-labelledby="radio-group-label" class="radio-group"
                                     (change)="viewmodel.zoneChanged($event)" [(ngModel)]="viewmodel.selectedZone">
                        <mat-radio-button color="warn" class="radio-button" *ngFor="let zone of viewmodel.zones"
                                          [value]="zone">
                            {{zone}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <div class="col-lg col-md-11 col-sm-12">
            <div class="row header-container">
                <div class="search-text">
                    <div class="v-center">
                        Select All
                    </div>
                    <div class="w-100 mt-1 mb-2">
                        <mat-form-field appearance="outline" color="accent" class="w-100">
                            <mat-label>Search</mat-label>
                            <input #unAssignedSearch type="text" id="unassigned-search-text"
                                [(ngModel)]="viewmodel.unAssignedSearchText" matInput />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row header-container">
                <div class="col-1 padding-left">
                    <mat-checkbox color="warn" class="margin-top"
                        (change)="viewmodel.selectAllUnassignedChanged($event)"
                        [(ngModel)]="viewmodel.allUnassignedchecked"></mat-checkbox>
                </div>
                <div (click)="viewmodel.unAssignedSort('territory')" class="col-4 large-font">
                    <b>Territory</b>
                    <fa-icon *ngIf="viewmodel.unAssignedFilteredEmployeesSortDto.name === 'territory'
                        && viewmodel.unAssignedFilteredEmployeesSortDto.direction === viewmodel.directions.ascending"
                        [icon]="faArrowUp" class="m-1"></fa-icon>
                    <fa-icon *ngIf="viewmodel.unAssignedFilteredEmployeesSortDto.name === 'territory'
                        && viewmodel.unAssignedFilteredEmployeesSortDto.direction === viewmodel.directions.descending"
                        [icon]="faArrowDown" class="m-1"></fa-icon>
                </div>
                <div (click)="viewmodel.unAssignedSort('first')" class="col-3 large-font">
                    <b>First</b>
                    <fa-icon *ngIf="viewmodel.unAssignedFilteredEmployeesSortDto.name === 'first'
                        && viewmodel.unAssignedFilteredEmployeesSortDto.direction === viewmodel.directions.ascending"
                        [icon]="faArrowUp" class="m-1"></fa-icon>
                    <fa-icon *ngIf="viewmodel.unAssignedFilteredEmployeesSortDto.name === 'first'
                        && viewmodel.unAssignedFilteredEmployeesSortDto.direction === viewmodel.directions.descending"
                        [icon]="faArrowDown" class="m-1"></fa-icon>
                </div>
                <div (click)="viewmodel.unAssignedSort('last')" class="col large-font">
                    <b>Last</b>
                    <fa-icon *ngIf="viewmodel.unAssignedFilteredEmployeesSortDto.name === 'last'
                        && viewmodel.unAssignedFilteredEmployeesSortDto.direction === viewmodel.directions.ascending"
                        [icon]="faArrowUp" class="m-1"></fa-icon>
                    <fa-icon *ngIf="viewmodel.unAssignedFilteredEmployeesSortDto.name === 'last'
                        && viewmodel.unAssignedFilteredEmployeesSortDto.direction === viewmodel.directions.descending"
                        [icon]="faArrowDown" class="m-1"></fa-icon>
                </div>
            </div>
            <cdk-virtual-scroll-viewport itemSize="72" class="viewport drop-list" cdkDropList cdkDropListSortingDisabled
                #unAssignedEmployeeList="cdkDropList" id="unAssignedEmployees"
                [cdkDropListData]="viewmodel.unAssignedFilteredEmployees"
                [cdkDropListConnectedTo]="[assignedEmployeeList]" (cdkDropListDropped)="viewmodel.drop($event)">
                <div [id]="item.employeeId" class="drop-box viewport-item" [class.odd]="odd"
                    *cdkVirtualFor="let item of viewmodel.unAssignedFilteredEmployees; let odd = odd" cdkDrag>
                    <div class="row data-row">
                        <div class="col-1">
                            <mat-checkbox color="warn" class="margin-top" [(ngModel)]="item.checked"></mat-checkbox>
                        </div>
                        <div class="col-4">
                            {{item.territory}}
                        </div>
                        <div class="col-3">
                            {{item.first}}
                        </div>
                        <div class="col">
                            {{item.last}}
                        </div>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
        <div class="col-lg-2 col-md-12 col-sm-12 assignment-arrows-col">
            <div class="row v-center assignment-arrows">
                <button mat-raised-button class="btn-white mb-3" (click)="viewmodel.addClicked()">
                    <img class="center-fit" [src]="viewmodel.arrowRightimageLocation" alt="Add to project." />
                </button>
            </div>
            <div class="row v-center assignment-arrows">
                <button *ngIf="viewmodel.assignedFilteredEmployees?.length > 0" mat-raised-button
                    class="btn-white mt-3" (click)="viewmodel.removeClicked()">
                    <img class="center-fit" [src]="viewmodel.arrowLeftimageLocation" alt="Remove from project." />
                </button>
            </div>
        </div>
        <div class="col-lg col-md-12 col-sm-12">
            <div class="row header-container">
                <div class="search-text">
                    <div class="v-center">
                        Select All
                    </div>
                    <div class="w-100 mt-1 mb-2">
                        <mat-form-field appearance="outline" color="accent" class="w-100">
                            <mat-label>Search</mat-label>
                            <input #assignedSearch type="text" id="assigned-search-text"
                                [(ngModel)]="viewmodel.assignedSearchText" matInput />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row header-container">
                <div class="col-1 padding-left">
                    <mat-checkbox color="warn" class="margin-top" (change)="viewmodel.selectAllAssignedChanged($event)"
                        [(ngModel)]="viewmodel.allAssignedchecked"></mat-checkbox>
                </div>
                <div (click)="viewmodel.assignedSort('territory')" class="col-4 large-font">
                    <b>Territory</b>
                    <fa-icon *ngIf="viewmodel.assignedFilteredEmployeesSortDto.name === 'territory'
                        && viewmodel.assignedFilteredEmployeesSortDto.direction === viewmodel.directions.ascending"
                        [icon]="faArrowUp" class="m-1"></fa-icon>
                    <fa-icon *ngIf="viewmodel.assignedFilteredEmployeesSortDto.name === 'territory'
                        && viewmodel.assignedFilteredEmployeesSortDto.direction === viewmodel.directions.descending"
                        [icon]="faArrowDown" class="m-1"></fa-icon>
                </div>
                <div (click)="viewmodel.assignedSort('first')" class="col-3 large-font">
                    <b>First</b>
                    <fa-icon *ngIf="viewmodel.assignedFilteredEmployeesSortDto.name === 'first'
                        && viewmodel.assignedFilteredEmployeesSortDto.direction === viewmodel.directions.ascending"
                        [icon]="faArrowUp" class="m-1"></fa-icon>
                    <fa-icon *ngIf="viewmodel.assignedFilteredEmployeesSortDto.name === 'first'
                        && viewmodel.assignedFilteredEmployeesSortDto.direction === viewmodel.directions.descending"
                        [icon]="faArrowDown" class="m-1"></fa-icon>
                </div>
                <div (click)="viewmodel.assignedSort('last')" class="col large-font">
                    <b>Last</b>
                    <fa-icon *ngIf="viewmodel.assignedFilteredEmployeesSortDto.name === 'last'
                        && viewmodel.assignedFilteredEmployeesSortDto.direction === viewmodel.directions.ascending"
                        [icon]="faArrowUp" class="m-1"></fa-icon>
                    <fa-icon *ngIf="viewmodel.assignedFilteredEmployeesSortDto.name === 'last'
                        && viewmodel.assignedFilteredEmployeesSortDto.direction === viewmodel.directions.descending"
                        [icon]="faArrowDown" class="m-1"></fa-icon>
                </div>
            </div>
            <cdk-virtual-scroll-viewport itemSize="72" class="viewport drop-list" cdkDropList cdkDropListSortingDisabled
                #assignedEmployeeList="cdkDropList" id="assignedEmployees"
                [cdkDropListData]="viewmodel.assignedFilteredEmployees"
                [cdkDropListConnectedTo]="[unAssignedEmployeeList]" (cdkDropListDropped)="viewmodel.drop($event)">
                <div [id]="item.employeeId" class="drop-box viewport-item" [class.odd]="odd"
                    *ngFor="let item of viewmodel.assignedFilteredEmployees; let odd = odd; let i = index" cdkDrag>
                    <div class="row data-row">
                        <div class="col-1">
                            <mat-checkbox color="warn" class="margin-top" (change)="viewmodel.setSelectAllAssigned()"
                                [(ngModel)]="item.checked"></mat-checkbox>
                        </div>
                        <div class="col-4">
                            {{item.territory}}
                        </div>
                        <div class="col-3">
                            {{item.first}}
                        </div>
                        <div class="col">
                            {{item.last}}
                        </div>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</ng-container>
