import { HttpClient } from "@angular/common/http";
import {
    GenericVersionResponseDto, VersionResponseDto, TimeEntryDto,
    SystemInformationKeys, SharedHelper, TimeEntrySyncCommand,
    SyncCommandTypes
} from "shield.shared";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { TimeEntry } from "src/app/entity-models/time-entry.entity";
import { Injectable } from "@angular/core";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { TimeEntryConverterService } from "src/app/services/converter-services/time-entry-converter.service";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { SyncLevel } from "../sync-enums/sync-level.enum";
@Injectable()
export class TimeEntryDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.None;
    offlineRepSyncType = SyncLevel.Required;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.timeEntry;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing TimeEntry entries...");

        await this.pullData();

        this.log("Done syncing TimeEntry entries...");
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/time-entries/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("Time entry data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Time entry data is out of date, syncing...");


        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.timeEntryPullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;

        try {
            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;

                const response = await this.http
                    .get<GenericVersionResponseDto<TimeEntryDto[]>>(`/api/time-entries${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                this.log(
                    `Downloaded ${thisBatchSize} time entries, saving to IndexedDB...`
                );

                const timeEntries = response.values.map((te) => {
                    const timeEntry = TimeEntryConverterService.timeEntryDtoToTimeEntry(te);
                    timeEntry.hasServerProcessed = 1;
                    return timeEntry;
                });

                await this.dbService.timeEntries.bulkPut(timeEntries);
                this.log(`  Saved  ${timeEntries.length} time entries.`);

                this.lastSyncVersion = response.maxVersion;

            } while (thisBatchSize > 0);

            this.log("Done saving time entry data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing time entries", e);
        }
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<TimeEntrySyncCommand>(
            SyncCommandTypes.timeEntry,
            async (message) => {
                const timeEntry = await this.dbService.timeEntries.get(
                    message.payload.id
                );

                const dto = TimeEntryConverterService.timeEntryToTimeEntryDto(timeEntry);

                if (dto) {
                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }
}
