import { GenericLookup, ProjectStatuses, ProjectStatusLookup, ProjectValidationDto, VisibleProjectStatuses } from "shield.shared";
import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";

export class ProjectValidationSummaryViewmodel extends BasicDialogViewModel {
    projectValidationDto: ProjectValidationDto;
    oldStatusId: number;
    oldStatus: string;
    newStatus: string;
    isStatusTransition: boolean;

    constructor(){
        super();
        this.buttonLeftText = "Cancel";
        this.buttonLeftDisabledFunction = () => false;
        this.buttonRightText = "Save";
        this.buttonRightDisabledFunction = () => false;
    }

    initialize(): void {
        if (this.projectValidationDto?.projectDto) {
            const newStatusId = VisibleProjectStatuses.find((psl) => psl === this.projectValidationDto.projectDto.projectStatusId) || ProjectStatuses.Editable;
            this.newStatus = ProjectStatusLookup.find((psl) => psl.id === newStatusId).description;

            const oldStatusId = VisibleProjectStatuses.find((psl) => psl === (this.oldStatusId ?? ProjectStatuses.InActive)) || ProjectStatuses.Editable;
            this.oldStatus = ProjectStatusLookup.find((psl) => psl.id === oldStatusId).description;

            if (this.oldStatus !== this.newStatus) {
                this.isStatusTransition = true;
            } else {
                this.isStatusTransition = false;
            }
        }
    }
}
