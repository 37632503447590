import { Injectable, NgZone } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { interval } from "rxjs";

@Injectable()
export class NgswUpdateService {

    constructor(public swUpdate: SwUpdate, private ngZone: NgZone) {
      if (this.swUpdate.isEnabled) {
        this.ngZone.runOutsideAngular(() =>
        // check once a minute
            interval(1000 * 60).subscribe(() => {
                    this.swUpdate
                    .checkForUpdate()
                    .then(() => console.log('checking for ngsw updates'))
            })
        );
      }
    }

    public checkForUpdates(): void {
      this.swUpdate.available.subscribe((event) => this.promptUser());
    }

    private async promptUser(): Promise<void> {
      if (confirm("A newer version is available. Load new version?")) {
        console.log('Clearing old outdated cache');
        const names = await caches.keys()
        for (let name of names) {
            await caches.delete(name);
        }
        console.log('updating to new version');
        await this.swUpdate.activateUpdate();
        window.location.reload();
      }
    }
}
