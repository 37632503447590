export enum DexieTableNames {

        accountOwnerships = "accountOwnerships",
        customers = "customers",
        products = "products",
        calls = "calls",
        callPictures = "pictures",
        surveys = "surveys",
        contacts = "contacts",
        syncQueue = "syncQueue",
        taxRates = "taxRates",
        timeEntries = "timeEntries",
        registeredUsers = "registeredUsers",
        userFilters = "userFilters",
        countries = "Country",
        states = "states",
        stateCategories = "stateCategories",
        systemInformation = "systemInformation",
        counties = "counties",
        storeTypes = "storeTypes",
        routes = "routes",
        timeEntryTypes = "timeEntryTypes",
        dayTimeEntries = "dayTimeEntries",
        employees = "employees",
        chainHeadquarters = "chainHeadquarters",
        receipts = "receipts",
        wholesalerGroupMembers = "wholesalerGroupMembers",
        wholesalerGroupProductCatalogItems = "wholesalerGroupProductCatalogItems",
        wholesalerGroups = "wholesalerGroups",
        wholesalerProductCatalogItems = "wholesalerProductCatalogItems",
        notificationRequests = "notificationRequests",
        contractTemplates = "contractTemplates",
        customerContracts = "customerContracts",
        projects = "projects",
        stateLicenses = "stateLicenses",
        receiptSettings = "receiptSettings",
}
