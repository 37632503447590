<mat-card class="mt-3">
    <div class="row">
        <div class="col-lg-3 col-sm-12">
            <div class="col-12">
                <h2 class="margin-bottom-unset m-teal">
                    <b>Products in Distribution</b>
                </h2>
                <br />
            </div>
            <div *ngIf="projects?.length" class="mt-3 mb-3">
                <angular2-multiselect
                    #projectSelect
                    [data]="projectDropDown"
                    [(ngModel)]="selectedProject"
                    [settings]="projectsDropdownSettings"
                    (onDeSelect)="closedProjectSelect()"
                    (onDeSelectAll)="closedProjectSelect()"
                    (onClose)="closedProjectSelect()"
                    (onOpen)="openedProjectSelect()"
                >
                </angular2-multiselect>
            </div>
            <div class="facet">
                <mat-radio-group
                    [value]="selectedFilteredProduct"
                    [name]="'filteredProduct' + mode"
                    (change)="selectedFilteredProduct = $event.value; filterDisplayProducts(); evaluateIfToggleAllInDistIsDisables()"
                    *ngFor="let radioButton of filterProductRadioGroup"
                >
                    <div class="col-12">
                        <mat-radio-button color="accent" [value]="radioButton.value">
                            {{ radioButton.name }}
                        </mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>
            <div class="facet categories">
                <h2 class="margin-bottom-unset m-teal">Company:</h2>
                <mat-radio-group
                    [value]="selectedSubsidiary"
                    [name]="'selectedSubsidiary' + mode"
                    (change)="selectedSubsidiary = $event.value; filterDisplayProducts(); evaluateIfToggleAllInDistIsDisables()"
                    *ngFor="let sub of subsidiaries; let i = index"
                >
                    <div class="col-12 d-flex flex-column">
                        <mat-radio-button color="accent" [value]="sub.value">
                            {{ sub.name }}
                        </mat-radio-button>
                    </div>
                </mat-radio-group>

                <h2 class="margin-bottom-unset m-teal">Categories:</h2>
                <mat-radio-group
                    [value]="selectedCategoryProduct"
                    [name]="'categoryProduct' + mode"
                    (change)="selectedCategoryProduct = $event.value; filterDisplayProducts(); evaluateIfToggleAllInDistIsDisables()"
                    *ngFor="let category of categories; let i = index"
                >
                    <div class="col-12 d-flex flex-column">
                        <mat-radio-button color="accent" [value]="category.value">
                            {{ category.name }}
                        </mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>
        </div>
        <div class="col-lg-9 col-sm-12">
            <div class="d-flex justify-content-between align-items-center mt-1 mb-2">
                <div class="">
                    <button class="btn-gray" (click)="adjustAllInDist()" [disabled]="isToggleInDistDisabled" mat-raised-button>
                        TOGGLE ALL IN DIST
                    </button>
                    <br />
                </div>
                <div class="flex-fill ml-2 mr-2">
                    <mat-form-field appearance="outline" color="accent">
                        <mat-label>Search</mat-label>
                        <input #search type="text" id="search-text" [(ngModel)]="searchText" matInput />
                    </mat-form-field>
                    <br />
                </div>
                <div *ngIf="!isGenericWholesalerStoreType">
                    <angular2-multiselect
                        [data]="wholesalers"
                        [(ngModel)]="selectedWholesaler"
                        [settings]="wholesalerDropdownSettings"
                        (onDeSelect)="onWholesalerSelectChange()"
                        (onSelect)="onWholesalerSelectChange()"
                        (onDeSelectAll)="onWholesalerSelectChange()"
                    >
                    </angular2-multiselect>
                    <br />
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover table-fixed" style="max-width: 98.8%">
                    <thead>
                        <tr>
                            <th class="xs" *ngIf="isOrderMode && !isWholesaler" scope="col" id="cash">Cash</th>
                            <th
                                class="xs"
                                *ngIf="isOrderMode && stepperCallApplicationService.hasOrderableProduct && !isGenericWholesalerStoreType"
                                scope="col"
                                id="order"
                            >
                                Order
                            </th>
                            <th class="xs" *ngIf="!isOrderMode" scope="col" id="select">Select</th>
                            <th class="sm" scope="col">Item #</th>
                            <th class="au" scope="col" id="description">Name</th>
                            <th class="sm" *ngIf="!isWholesaler" scope="col" id="packSize">Pack Size</th>
                            <th class="sm" *ngIf="isWholesaler" scope="col" id="uin">UIN #</th>
                            <th class="xs" scope="col" id="inDist">In Dist</th>
                            <th class="xs" scope="col" id="intro">Intro</th>
                            <th class="xs" scope="col" id="oos">OOS</th>
                            <th class="xs" scope="col" id="cos">COS</th>
                            <th class="sm" scope="col" id="dateAvailable">Date Available</th>
                            <th class="xs" scope="col" id="subsidiary">Comp</th>
                        </tr>
                    </thead>
                </table>
                <cdk-virtual-scroll-viewport #viewport itemSize="50" minBufferPx="600" maxBufferPx="800" class="viewport">
                    <table class="table table-bordered table-striped table-hover table-fixed">
                        <tbody>
                            <tr
                                *cdkVirtualFor="let displayedCallProduct of displayedCallProducts"
                                minBufferPx="600"
                                maxBufferPx="800"
                                class="viewport-item"
                            >
                                <td class="xs" *ngIf="isOrderMode && !isWholesaler">
                                    <span>
                                        <mat-checkbox
                                            [indeterminate]="false"
                                            [(ngModel)]="displayedCallProduct.isCash"
                                            [color]="'warn'"
                                            (change)="adjustCash($event, displayedCallProduct)"
                                            class="example-margin"
                                            [disableRipple]="isFinalRetailReceiptPrinted"
                                            [disabled]="isFinalRetailReceiptPrinted"
                                        ></mat-checkbox>
                                    </span>
                                </td>
                                <td class="xs" *ngIf="isOrderMode && stepperCallApplicationService.hasOrderableProduct">
                                    <span>
                                        <mat-checkbox
                                            *ngIf="
                                                displayedCallProduct.hasWholesalerProductMapping ||
                                                (this.wholesalers?.length && selectedProject?.length)
                                            "
                                            [disabled]="isFinalWholesaleReceiptPrinted"
                                            [disableRipple]="isFinalWholesaleReceiptPrinted"
                                            [indeterminate]="false"
                                            [(ngModel)]="displayedCallProduct.isOrder"
                                            [color]="'warn'"
                                            (change)="adjustOrder(displayedCallProduct)"
                                            class="example-margin"
                                        ></mat-checkbox>
                                    </span>
                                </td>
                                <td class="xs" *ngIf="!isOrderMode">
                                    <span>
                                        <mat-checkbox
                                            [disabled]="isSelectDisabled()"
                                            [disableRipple]="isSelectDisabled()"
                                            [indeterminate]="false"
                                            [(ngModel)]="displayedCallProduct.isSelect"
                                            [color]="'warn'"
                                            (change)="adjustIsSelected(displayedCallProduct)"
                                            class="example-margin"
                                        >
                                        </mat-checkbox>
                                    </span>
                                </td>
                                <td class="sm">{{ displayedCallProduct.product.itemNumber }}</td>
                                <td class="au">
                                    {{ displayedCallProduct.product.description }}
                                </td>
                                <td class="sm" *ngIf="!isWholesaler">
                                    {{ displayedCallProduct.packSize }}
                                </td>
                                <td class="sm" *ngIf="isWholesaler">
                                    {{ displayedCallProduct.product.uin }}
                                </td>
                                <td class="xs">
                                    <span>
                                        <mat-checkbox
                                            [disabled]="isInDistDisabled(displayedCallProduct)"
                                            [disableRipple]="isInDistDisabled(displayedCallProduct)"
                                            [indeterminate]="false"
                                            [(ngModel)]="displayedCallProduct.isInDist"
                                            [color]="'warn'"
                                            (change)="adjustInDist(displayedCallProduct)"
                                            class="example-margin"
                                        >
                                        </mat-checkbox>
                                    </span>
                                </td>
                                <td class="xs">
                                    <span>
                                        <mat-checkbox
                                            [disabled]="isInDistDisabled(displayedCallProduct)"
                                            [disableRipple]="isInDistDisabled(displayedCallProduct)"
                                            [indeterminate]="false"
                                            [(ngModel)]="displayedCallProduct.isIntro"
                                            [color]="'warn'"
                                            (change)="adjustIntro(displayedCallProduct)"
                                            class="example-margin"
                                        ></mat-checkbox>
                                    </span>
                                </td>
                                <td class="xs">
                                    <span>
                                        <mat-checkbox
                                            [disabled]="isInDistDisabled(displayedCallProduct)"
                                            [disableRipple]="isInDistDisabled(displayedCallProduct)"
                                            [indeterminate]="false"
                                            [(ngModel)]="displayedCallProduct.isOos"
                                            [color]="'warn'"
                                            (change)="adjustOos(displayedCallProduct)"
                                            class="example-margin"
                                        ></mat-checkbox>
                                    </span>
                                </td>
                                <td class="xs">
                                    <span>
                                        <mat-checkbox
                                            [disabled]="isInDistDisabled(displayedCallProduct)"
                                            [disableRipple]="isInDistDisabled(displayedCallProduct)"
                                            [indeterminate]="false"
                                            [(ngModel)]="displayedCallProduct.isCos"
                                            [color]="'warn'"
                                            (change)="adjustCos(displayedCallProduct)"
                                            class="example-margin"
                                        ></mat-checkbox>
                                    </span>
                                </td>
                                <td class="sm">
                                    {{
                                        displayedCallProduct.dateAvailable ?
                                        (displayedCallProduct.dateAvailable | date : 'shortDate') :
                                        ""
                                    }}
                                </td>
                                <td class="xs">
                                    {{ displayProduct(displayedCallProduct.product.subsidiary) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>
    </div>
</mat-card>
