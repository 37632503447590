import { PictureDto } from "./picture.dto";
import { CallTypes } from "../enum";
import { CallPictureDto } from "./call-picture.dto";
import { CallSurveyAnswerDto } from "./call-survey-answer.dto";
import { OnlineReferenceDto } from "./online-reference.dto";

export class WholesaleCallDto {

    id: string; //UUID
    callNumber: string;
    customerId: string;
    createdUserId: string;
    createdUtcDateTime: string;
    createdUserZrt: string;
    createdUserFullName: string
    modifiedUtcDateTime: string;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    startTime: string;
    stopTime: string;
    callPictures: CallPictureDto[];
    pictures: PictureDto[];
    callSurveyAnswers: CallSurveyAnswerDto[]
    isReturns: boolean;
    isPurchase: boolean;
    isSwisherDay: boolean;
    isTradeShow: boolean;
    purchaseInvoice: string;
    creditMemo: string;
    billThrough: string;
    purchaseAmount: number;
    creditAmount: number;
    closingNotes: string;
    callType: CallTypes.wholesale;
    isDeleted: boolean;
    rowversion: string;
    px3RankId?: string;
    px3Rank?: string;
}
