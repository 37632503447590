import { TransactionLineItemType } from "../lookup";
import { AddressFilterDto } from "./address-filter.dto";
import { ZrtFilterDto } from "./zrt-filter.dto";

export class TransactionListFilterDto {

    /*
     * My Searches
     */
    // Usage: Get only accounts with given ids
    ids?: string[];

    /*
    * Header and Requested information section
    */

    //Usage: Contains ZRT properties
    zrtFilterDto?: ZrtFilterDto;

    //Usage: Get data based on account info
    //Possible data: account name, account GUID/Id or account number
    account?: string[];

    //Usage: Contains address properties for the business address
    addressFilterDto?: AddressFilterDto;

    /*
     * Date Information Sections
     */

    //Usage: Get data based on call/date
    callOnOrAfterDate?: string;
    callOnOrBeforeDate?: string;

    /*
     * Transactions Secton
     */

    //Usage: Get based on receipt number(s)
    receiptNumber?: string[];

    //Usage: Get based on whether transaction has been reimbursed
    reimbursementStatus?: boolean;

    //Usage: Get based on types of transactionLineItems
    transactionTypes?: TransactionLineItemType[];

    //Usage: Get transactions which include products based on product id
    productIds?: string[];

    //Usage: Get data based on UIN(s) of products associated with transactions
    uins?: string[];

    /*
     * Activities Secton
     */

    //Usage: Get based on associated project ids
    projectIds?: string[];

    //Usage: Get data based on wholesaler Ids
    wholesalerIds?: string[];

    //Usage: Get data based on account ownership
    //Possible data: Account ownership GUIDs
    accountOwnershipIds?: string[];


    /*
     * Attributes Secton
     */

    //Usage: Get based on store types
    storeTypes?: string[];

    //Usage: Get if the associated customer is an Msa
    isMsa?: boolean;

    //Usage: Get based on the selected px3 rank
    px3Rank?: string[];

}
