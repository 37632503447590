import {
    AfterContentChecked,
    Component,
    HostBinding,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";

import { MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import { OverlayService } from "src/app/services/overlay.service";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { WholesalerGroupsListRefinerService } from "./wholesaler-groups-list-refiner.service";
import { WholesalerGroupsListViewmodel } from "./wholesaler-groups-list.viewmodel";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { WholesalerGroupDelineationService } from "src/app/services/delineation-services/wholesaler-group-delineation.service";
import { MatSelect } from "@angular/material/select";
import { WholesalerGroupProductCatalogItemDelineationService } from "src/app/services/delineation-services/wholesaler-group-product-catalog-item-delineation.service";
import { AppStateService } from "src/app/services/app-state.service";

@Component({
    selector: "app-wholesaler-groups-list",
    templateUrl: "./wholesaler-groups-list.component.html",
    styleUrls: ["./wholesaler-groups-list.component.scss"],
    providers: [PleaseWaitService]
})
export class WholesalerGroupsListComponent
    implements OnInit, AfterContentChecked, OnDestroy {
    @HostBinding("class") class = "worksheet-static d-flex flex-column flex-grow-1";
    // ViewChilds
    @ViewChild("drawer") drawer: MatSidenav;
    @ViewChild("grid") grid: GridComponent;
    @ViewChild("sideNavContent") sideNavContent: MatSidenavContent;
    @ViewChild("header") header: PageHeaderComponent;
    @ViewChild("methodSelect") methodSelect: MatSelect;

    // Public vars
    viewmodel: WholesalerGroupsListViewmodel;
    isViewmodelReady = false;
    screenHeight: number;

    constructor(
        private overlayService: OverlayService,
        private pleaseWaitService: PleaseWaitService,
        private refinerService: WholesalerGroupsListRefinerService,
        private wholesalerGroupDelineationService: WholesalerGroupDelineationService,
        private wholesalerGroupProductCatalogItemDelineationService: WholesalerGroupProductCatalogItemDelineationService,
        private appStateService: AppStateService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.viewmodel = new WholesalerGroupsListViewmodel(
            this.overlayService,
            this.pleaseWaitService,
            this.refinerService,
            this.wholesalerGroupDelineationService,
            this.wholesalerGroupProductCatalogItemDelineationService,
            this.appStateService,
        );
    }

    ngAfterContentChecked() {
        if (this.isViewmodelReady && !this.viewmodel.isGapSet) {
            this.viewmodel.calculateGap();
        }

        if (!this.isViewmodelReady
            && this.grid
            && this.header
            && this.sideNavContent
            && this.drawer
            && this.methodSelect
        ) {
            this.isViewmodelReady = true;
            this.getScreenSize();
            void this.viewmodel.initialize(
                this.grid,
                this.header,
                this.sideNavContent,
                this.drawer,
                this.methodSelect
            );
        }
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?: any): void {
        if (this.viewmodel) {
            this.viewmodel.screenHeight = window.innerHeight;
        }
    }
}
