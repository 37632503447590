import { OrderLineItemStatuses, OrderListFilterDto, OrderStatuses, RefinerLocation, valueSeparator } from "shield.shared";
import { Refiner } from "../../entity-models/refiner.entity";
import { AddressFilterMapService } from "./address-filter-map.service";
import { BaseFilterMapService } from "./base-filter-map.service";
import { ZrtFilterMapService } from "./zrt-filter-map.service";

export class OrderListFilterMapService extends BaseFilterMapService {


    static mapFilterData(refiners: Refiner[]): OrderListFilterDto {

        if (!refiners) return;
        const filterDto = new OrderListFilterDto();
        const zrtFilterDto = ZrtFilterMapService.mapFilterData(refiners);
        const businessAddressFilterDto = AddressFilterMapService.mapFilterData(refiners);

        if (Object.keys(zrtFilterDto).length) {
            filterDto.zrtFilterDto = zrtFilterDto;
        }
        if (Object.keys(businessAddressFilterDto).length) {
            filterDto.businessAddressFilterDto = businessAddressFilterDto;
        }

        for (const refiner of refiners) {
            if (!refiner.dataValue) {
                refiner.dataValue = refiner.value;
            }

            switch (refiner.location) {
                case RefinerLocation.myList:
                    filterDto.ids = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.account:
                    filterDto.account = refiner.dataValue.split(valueSeparator);
                    break;

                case RefinerLocation.callOnOrAfterDate:
                    const callOnOrAfterDate = new Date(refiner.dataValue)
                    callOnOrAfterDate.setHours(0,0,0,0);
                    filterDto.callOnOrAfterDate = callOnOrAfterDate.toISOString();
                    break;

                case RefinerLocation.callOnOrBeforeDate:
                    const callOnOrBeforeDate = new Date(refiner.dataValue)
                    callOnOrBeforeDate.setHours(23,59,59,0);
                    filterDto.callOnOrBeforeDate = callOnOrBeforeDate.toISOString();
                    break;

                case RefinerLocation.projects:
                    filterDto.projectIds = refiner.dataValue.split(valueSeparator);
                    break;

                case RefinerLocation.chains:
                    filterDto.accountOwnershipIds = refiner.dataValue.split(valueSeparator);
                    break;

                case RefinerLocation.wholesalers:
                    filterDto.wholesalerIds = refiner.dataValue.split(valueSeparator);
                    break;

                case RefinerLocation.uin:
                    filterDto.uins = refiner.dataValue.split(valueSeparator);
                    break;

                case RefinerLocation.products:
                    filterDto.productIds = refiner.dataValue.split(valueSeparator);
                    break;

                case RefinerLocation.orderOnOrBeforeDate:
                    const orderOnOrBeforeDate = new Date(refiner.dataValue)
                    orderOnOrBeforeDate.setHours(23,59,59,0);
                    filterDto.orderOnOrBeforeDate = orderOnOrBeforeDate.toISOString();
                    break;

                case RefinerLocation.orderOnOrAfterDate:
                    const orderOnOrAfterDate = new Date(refiner.dataValue)
                    orderOnOrAfterDate.setHours(0,0,0,0);
                    filterDto.orderOnOrAfterDate = orderOnOrAfterDate.toISOString();
                    break;

                case RefinerLocation.orderType: {
                    const types = new Array<number>(); // CHange these to OrderTypes enum when available
                    filterDto.orderTypes = refiner.dataValue.split(valueSeparator);
                    break;
                }

                case RefinerLocation.orderStatus: {
                    const statuses = new Array<OrderStatuses>();
                    refiner.dataValue.split(valueSeparator).forEach((statusId) => statuses.push(Number(statusId) as OrderStatuses));
                    filterDto.orderStatuses = statuses;
                    break;
                }

                case RefinerLocation.orderLineItemStatus: {
                    const statuses = new Array<OrderLineItemStatuses>();
                    refiner.dataValue.split(valueSeparator).forEach((statusId) => statuses.push(Number(statusId) as OrderLineItemStatuses));
                    filterDto.orderLineItemStatuses = statuses;
                    break;
                }

                case RefinerLocation.storeTypes:
                    filterDto.storeTypes = refiner.dataValue.split(valueSeparator);
                    break;

                case RefinerLocation.msa:
                    filterDto.isMsa = refiner.dataValue === BaseFilterMapService.yes ? true : false;
                    break;

                case RefinerLocation.px3Rank:
                    filterDto.px3Rank = refiner.dataValue.split(valueSeparator);
                    break;

                default:
                    break;
            }
        }
        return filterDto;
    }
}
