<div *ngIf="type === retail">
    <app-retail-info></app-retail-info>
</div>
<div *ngIf="type === wholesale">
    <div *ngIf="!isEdit">
        <app-wholesale-info></app-wholesale-info>
    </div>
    <div *ngIf="isEdit">
        <app-wholesale-account-edit></app-wholesale-account-edit>
    </div>
</div>
<div *ngIf="type === chainHq">
    <app-chain-hq-info></app-chain-hq-info>
</div>
