import { Injectable } from "@angular/core";
import { GenericResponseDto } from "shield.shared";
import { Survey } from "src/app/entity-models/survey.entity";
import { DatabaseService } from "../database.service";
import { SurveyOfflineService } from "../offline-services/survey-offline.service";
import { SurveyOnlineService } from "../online-services/survey-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class SurveyDelineationService extends DelineationContext<Survey, string> {

    constructor(private surveyOfflineService: SurveyOfflineService
        , private surveyOnlineService: SurveyOnlineService
        , snackbarService: SnackbarService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService){
            super(dbService, datasourceDelineationService, snackbarService);
        }

        async getById(id: string): Promise<GenericResponseDto<Survey>> {

            const offline = (key: string) => {
                return this.surveyOfflineService.getById(key);
            }
            const online = (key: string) => {
                return this.surveyOnlineService.getById(key);
            }
            const response = await this.datasourceDelineationService.makeCall<string, Survey>(id, offline, online);

            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }

        async getByIds(ids: string[]): Promise<GenericResponseDto<Survey[]>> {

            const offline = (key: string[]) => {
                return this.surveyOfflineService.getByIds(key);
            }
            const online = (key: string[]) => {
                return this.surveyOnlineService.getByIds(key);
            }
            const response = await this.datasourceDelineationService.makeCall<string[], Survey[]>(ids, offline, online);

            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }

        async getAvailibleBatch(id: string): Promise<GenericResponseDto<Survey[]>> {

            const offline = (key: string) => {
                return this.surveyOfflineService.getByIds([key]);
            }
            const online = (key: string) => {
                return this.surveyOnlineService.getAvailableBatch(key);
            }
            const response = await this.datasourceDelineationService.makeCall<string, Survey[]>(id, offline, online);

            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }


}
