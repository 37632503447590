import { Component, OnInit, Type } from "@angular/core";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { RefinerLocation } from "shield.shared";
import { WholesalerGroupProductsViewmodel } from "./wholesaler-group-products.viewmodel";

@Component({
    selector: "app-wholesaler-group-products-filter",
    templateUrl: "./wholesaler-group-products-filter.component.html",
    styleUrls: ["./wholesaler-group-products-filter.component.scss"]
})
export class WholesalerGroupProductsFilterComponent extends FilterBaseComponent implements OnInit {
    //public vars
    contentComponent: Type<WholesalerGroupProductsFilterComponent>;
    icon = "task_alt";
    name = "Product Information";
    viewmodel = new WholesalerGroupProductsViewmodel();

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.setInitialized();
    }

    //events
    onAddDescriptionRefiner(event?: KeyboardEvent) {
        if (!event || this.inputSubmitKeys.includes(event.key)) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.productDescription;
            refiner.value = this.viewmodel.descriptionInput;
            refiner.dataPropertyName = "description";
            refiner.dataValue = this._refinerService.buildStringDataValue(this.viewmodel.descriptionInput);

            this._refinerService.checkAndUpdateRefiner(refiner, true, false);
        }
    }

    onAddItemNumberRefiner(event?: KeyboardEvent) {
        if (!event || this.inputSubmitKeys.includes(event.key)) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.productNumber;
            refiner.value = this.viewmodel.itemNumberInput;
            refiner.dataPropertyName = "number";
            refiner.dataValue = this._refinerService.buildStringDataValue(this.viewmodel.itemNumberInput);

            this._refinerService.checkAndUpdateRefiner(refiner, true, false);
        }
    }

    onAddPriceRefiner(event?: KeyboardEvent) {
        if (!event || this.inputSubmitKeys.includes(event.key)) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.productPrice;
            refiner.value = `$${this.viewmodel.priceInput?.toFixed(2)}`;
            refiner.dataPropertyName = "price";
            refiner.dataValue = this.viewmodel.priceInput?.toString();

            this._refinerService.checkAndUpdateRefiner(refiner, true, false);
        }
    }

    onAddUinRefiner(event?: KeyboardEvent) {
        if (!event || this.inputSubmitKeys.includes(event.key)) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.uin;
            refiner.value = this.viewmodel.uinInput;
            refiner.dataPropertyName = "uin";
            refiner.dataValue = this._refinerService.buildStringDataValue(this.viewmodel.uinInput);

            this._refinerService.checkAndUpdateRefiner(refiner, true, false);
        }
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.uin:
                    this.viewmodel.uinInput = refiner.value;
                    break;
                case RefinerLocation.productNumber:
                    this.viewmodel.itemNumberInput = refiner.value;
                    break;
                case RefinerLocation.productDescription:
                    this.viewmodel.descriptionInput = refiner.value;
                    break;
                case RefinerLocation.productPrice:
                    this.viewmodel.priceInput = Number(refiner.dataValue);
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const emptyRefiner = new Refiner();

        for (const location of this.viewmodel.refinerLocations) {
            emptyRefiner.location = location;

            const foundRefiner = this._refinerService.refiners.find(
                (refiner) => refiner.location === location
            );

            this.onInputChange(foundRefiner ?? emptyRefiner);
        }
    }
}
