import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    FilterRequestV2Dto,
    GenericDropDownDto,
    GenericRequestDto,
    GenericResponseDto,
    GenericVersionResponseDto,
    HasWholesalserProductMappingsParamsDto,
    newSequentialId,
    ProductDistributionBatchParamsDto,
    ProductDistributionColumns,
    ProductDistributionEntryDto,
    ProductDistributionFilterDto,
    ProductDto,
    ResponseCountKey,
    WholesalerGroupProductCatalogItemDto
} from "shield.shared";
import { ProductDistributionEntry } from "src/app/entity-models/product-distribution-entry.entity";
import { WholesalerGroupProductCatalogItem } from "src/app/entity-models/wholesaler-group-product-catalog-item.entity";
import { Product } from "../../entity-models/product.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { ProductConverterService } from "../converter-services/product-converter.service";
import { WholesalerGroupProductCatalogItemConverterService } from "../converter-services/wholesaler-group-product-catalog-item-converter.service";

@Injectable()
export class ProductOnlineService {
    constructor(private http: HttpClient){}

    async getAll(): Promise<GenericResponseDto<Product[]>> {

        const query = `?take=20000`;
        const response = await this.http
            .get<GenericVersionResponseDto<ProductDto[]>>(`/api/products${query}`)
            .toPromise();

        const result = new GenericResponseDto<ProductDto[]>();
        result.id = response.id;
        result.count = response.count;
        result.affected = 0;
        result.setCount(ResponseCountKey.default, response.count);
        result.isError = response.isError;
        result.message = response.message;
        result.values = response.values;


        return ConverterHelper.dtoToEntityArray<ProductDto, Product>(result, (r) => ProductConverterService.productDtoToProduct(r));
    }

    async getDropDown(): Promise<GenericResponseDto<GenericDropDownDto[]>> {

        const response = await this.http
            .get<GenericResponseDto<GenericDropDownDto[]>>(`/api/products/dropdown`)
            .toPromise();

            return ConverterHelper.dtoToEntityArray<GenericDropDownDto, GenericDropDownDto>(response, (r) => r);
    }

    async getByIds(ids: string[]): Promise<GenericResponseDto<Product[]>> {

        const request = new GenericRequestDto<string[]>();

        request.id = newSequentialId();
        request.values = ids;

        const response = await this.http
        .post<GenericResponseDto<ProductDto[]>>(`/api/products/get-by-ids`, request)
        .toPromise();

        return ConverterHelper.dtoToEntityArray<ProductDto, Product>(response, (r) => ProductConverterService.productDtoToProduct(r));
    }

    async getWholesalerGroupProductCatalogItemsByIds(ids: string[]): Promise<GenericResponseDto<WholesalerGroupProductCatalogItem[]>> {

        const request = new GenericRequestDto<string[]>();

        request.id = newSequentialId();
        request.values = ids;

        const response = await this.http
        .post<GenericResponseDto<WholesalerGroupProductCatalogItemDto[]>>(`/api/wholesalers/get-by-ids`, request)
        .toPromise();

        return ConverterHelper.dtoToEntityArray<WholesalerGroupProductCatalogItemDto
        , WholesalerGroupProductCatalogItem>(response,
            (r) => WholesalerGroupProductCatalogItemConverterService
                .dtoToEntity(r));
    }

    async hasProductWholesalerMappings(
        params: HasWholesalserProductMappingsParamsDto
    ): Promise<GenericResponseDto<boolean>> {

        const request = new GenericRequestDto<HasWholesalserProductMappingsParamsDto>();

        request.id = newSequentialId();
        request.values = params;

        const response = await this.http
        .post<GenericResponseDto<boolean>>(`/api/wholesalers/has-product-mappings`, request)
        .toPromise();

        return response;
    }

    async getDistributionBatch(
        key: ProductDistributionBatchParamsDto
    ): Promise<GenericResponseDto<ProductDistributionEntry[]>> {

        const request = new GenericRequestDto<FilterRequestV2Dto<ProductDistributionFilterDto, ProductDistributionColumns>>();
        request.id = newSequentialId();
        request.values = key.filterRequestDto;

        const result = await this.http
            .post<GenericResponseDto<ProductDistributionEntryDto[]>>(
                "/api/products/distribution-report",
                request
            )
            .toPromise();

        return ConverterHelper.dtoToEntityArray<ProductDistributionEntryDto, ProductDistributionEntry>(
            result, (r) => ProductConverterService.productDistributionEntryDtoToProductDistributionEntry(r)
        );
    }
}
