import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { WholesalerGroupEntryMember } from "src/app/entity-models/wholesaler-group-entry-member.entity";
import { WholesalerGroupEntry } from "src/app/entity-models/wholesaler-group-entry.entity";
import { WholesalerProductCatalogItems } from "src/app/entity-models/wholesaler-product-catalog-items.entity";
import { AddProductViewModel } from "src/app/products/add-products/add-product.viewmodel";
import { WholesalerProductsListViewModel } from "./wholesaler-products-list.viewmodel";
import { WholesalerGroupProductCatalogItem } from "src/app/entity-models/wholesaler-group-product-catalog-item.entity";
import { WholesalerGroupProductCatalogItemDelineationService } from "src/app/services/delineation-services/wholesaler-group-product-catalog-item-delineation.service";
import { newSequentialId } from "shield.shared";

export class WholesalerDivisionProductsListViewModel extends WholesalerProductsListViewModel<WholesalerProductCatalogItems> {
    readonly errorMessage = "Make sure all new products have a unique UIN# before you are able to save.";

    constructor(
        public canEdit: boolean,
        public group: WholesalerGroupEntry,
        items: WholesalerProductCatalogItems[],
        public groupMember: WholesalerGroupEntryMember,
        public groupItems: WholesalerGroupProductCatalogItem[],
    ) {
        super(canEdit, group, items);

        this.headerLeftText = `Wholesaler Products — Division Level — ${groupMember.name}`;

        if (this.canEdit) {
            this.headerRightButtonText = 'Add Product to Group/Division';
        }
    }

    getGroupItem(item: WholesalerProductCatalogItems): WholesalerGroupProductCatalogItem {
        return this.groupItems.find(gi => item.wholesalerGroupProductCatalogItemId === gi.id);
    }

    async exportToXlsx(catalogItemService: WholesalerGroupProductCatalogItemDelineationService)
    : Promise<Blob> {
        return catalogItemService.getDivisionProductCatalogExport(this.groupMember.wholesalerId);
    }

    addProducts(selected: AddProductViewModel[], form: FormGroup, builder: FormBuilder): void {
        const formItems = form.get('items') as FormArray;
        for (const product of selected) {
            let groupItem = this.groupItems.find(gi => gi.productId === product.product.id);
            if (!groupItem) {
                groupItem = new WholesalerGroupProductCatalogItem();
                groupItem.id = newSequentialId();
                groupItem.wholesalerGroupId = this.group.id;
                groupItem.productId = product.product.id;
                groupItem.product = product.product;
                groupItem.wholesalerCount = 0;
                groupItem.wholesalerItems = [];
                this.groupItems.splice(0, 0, groupItem);
            }
            const newCatalogItem = {
                ...new WholesalerProductCatalogItems(),
                wholesalerId: this.groupMember.wholesalerId,
                productId: product.product.id,
                wholesalerGroupProductCatalogItemId: groupItem.id,
            };
            groupItem.wholesalerItems.push(newCatalogItem);
            formItems.insert(0, this.createFormRow(form, builder, newCatalogItem, true));
            formItems.controls[0].markAsDirty();
            formItems.controls[0].markAllAsTouched();
        }

        this.items = this.groupItems.reduce((arr, gi) => {
            const wholesalerItem = gi.wholesalerItems.find(
                wi => wi.wholesalerId === this.groupMember.wholesalerId
            );
            if (wholesalerItem) {
                arr.push(wholesalerItem);
            }
            return arr;
        }, [] as WholesalerProductCatalogItems[]);
    }

    createFormRow(
        form: FormGroup,
        builder: FormBuilder,
        item: WholesalerProductCatalogItems,
        isNew: boolean = false,
    ): FormGroup {
        const groupItem = this.groupItems.find(gi => item.wholesalerGroupProductCatalogItemId === gi.id);
        return builder.group({
            id: [item.id],
            productUIN: [
                groupItem.productUIN, [
                    Validators.required,
                    (ctrl: AbstractControl) => this.validateUnique(form, ctrl)
                ],
            ],
            itemNumber: [groupItem.product.itemNumber],
            deactivate: [false],
            groupItem: [item],
            isNew: [isNew],
            dateAvailable: [item.dateAvailable ?? groupItem.dateAvailable],
            wholesalePrice: [
                item.wholesalePrice,
                Validators.min(0),
            ],
        })
    }

    updateItems(form: FormGroup): WholesalerGroupProductCatalogItem[] {
        const currentItemsMap = new Map(this.groupItems.map(i => [i.product.itemNumber, i]));
        const formItems = (form.controls['items'] as FormArray).controls
            .filter(g => g.touched)
            .map(g => g.value);
        const toSave: WholesalerGroupProductCatalogItem[] = [];

        for (const i of formItems) {
            const toUpdate = currentItemsMap.get(i.itemNumber);
            if (toUpdate) {
                const updated = {
                    ...toUpdate,
                    productUIN: i.productUIN,
                };


                const wholesalerItem = updated.wholesalerItems.find(
                    i => i.wholesalerId === this.groupMember.wholesalerId
                );
                if (updated.dateAvailable !== i.dateAvailable) {
                    wholesalerItem.dateAvailable = i.dateAvailable;
                }
                wholesalerItem.wholesalePrice = i.wholesalePrice;
                wholesalerItem.isDeactivated = i.deactivate;
                wholesalerItem.isDeactivatedDate = i.deactivate ? new Date() : null;

                toSave.push(updated);
            }
        }

        return toSave;
    }
}
