import { AfterContentChecked, Component, ElementRef, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { RefinerLocation, valueSeparator } from "shield.shared";
import { ProjectActivitiesViewmodel } from "./project-activities.viewmodel";
import { Customer } from "src/app/entity-models/customer.entity";
import { ProjectFilterService } from "src/app/details/project-list/project-filter.service";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { ActivitiesFilterService } from "src/app/services/activities-filter.service";

@Component({
    selector: "app-project-activities-filter",
    templateUrl: "./project-activities-filter.component.html",
    styleUrls: ["./project-activities-filter.component.scss"]
})
export class ProjectActivitiesFilterComponent extends FilterBaseComponent implements OnInit, AfterContentChecked, OnDestroy {
    //view childs
    @ViewChild("chainsSelect") chainsSelect: MatSelect;
    @ViewChild("wholesalersSelect") wholesalersSelect: MatSelect;

    //public vars
    contentComponent: Type<ProjectActivitiesFilterComponent>;
    icon = "check_circle";
    name = "Activities";
    viewmodel = new ProjectActivitiesViewmodel(this.projectFilterService);
    inputsSet = false;
    wholeslersLoaded = false;
    chainsLoaded = false;

    constructor(private projectFilterService: ProjectFilterService, private activitiesFilterService: ActivitiesFilterService) {
        super();
    }

    ngOnInit(): void {

        this.setInitialized();

        if (
            !this.viewmodel.chainsSubscription ||
            this.viewmodel.chainsSubscription.closed
        ) {
            this.viewmodel.chainsSubscription = this.projectFilterService.observableChains.subscribe(
                (chains) => {
                    if (chains) {
                        let partialChains = chains.map((chain) => {
                            chain.commonOperatingName ??= chain.name;
                            return chain;
                        });

                        partialChains = [...new Map(partialChains.map((c) => [c.ownerCode, c])).values()];
                        this.viewmodel.chains = partialChains;

                        const selected = new Array<AccountOwnership>();
                        for (const chain of this.viewmodel.selectedChains) {
                            if (partialChains.map((c) => c.ownerCode).includes(chain.ownerCode)) {
                                selected.push(chain);
                            }
                        }
                        for (const chain of this.viewmodel.selectedChains) {
                            if (chains.map((c) => c.ownerCode).includes(chain.ownerCode)) {
                                selected.push(chain);
                            }
                        }
                        this.chainsLoaded = true;
                    }
                }
            );
        }
        if (
            !this.viewmodel.wholesalersSubscription ||
            this.viewmodel.wholesalersSubscription.closed
        ) {
            this.viewmodel.wholesalersSubscription = this.projectFilterService.observableWholesalers.subscribe(
                (wholesalers) => {
                    if (wholesalers) {
                        this.viewmodel.wholesalers = wholesalers;

                        const selectedWholesalerIds = this.viewmodel.selectedWholesalerIds ?? new Array<string>();
                        const selected = new Array<Customer>();
                        for (const id of selectedWholesalerIds) {
                            if (wholesalers.map((w) => w.id).includes(id)) {
                                selected.push(wholesalers.find(w => w.id === id));
                            }
                        }
                        for (const wholesaler of this.viewmodel.selectedWholesalers) {
                            if (wholesalers.map((w) => w.id).includes(wholesaler.id)) {
                                selected.push(wholesalers.find(w => w.id === wholesaler.id));
                            }
                        }
                        this.wholeslersLoaded = true;
                    }
                }
            );
        }
    }

    ngAfterContentChecked(): void {
        if (!this.inputsSet && this.chainsLoaded && this.wholeslersLoaded) {
            this.inputsSet = true;
            this.refinerService.refiners.forEach((refiner) => {
                this.onInputChange(refiner);
            });
        }
    }

    ngOnDestroy(): void {
        if (
            this.viewmodel.chainsSubscription &&
            !this.viewmodel.chainsSubscription.closed
        ) {
            this.viewmodel.chainsSubscription.unsubscribe();
        }

        if (
            this.viewmodel.wholesalersSubscription &&
            !this.viewmodel.wholesalersSubscription.closed
        ) {
            this.viewmodel.wholesalersSubscription.unsubscribe();
        }
    }

    //events
    openedWholesalerSelect() {
        this.viewmodel.isWholesalersStale = true;
        this.viewmodel.selectedWholesalersHold = this.viewmodel.selectedWholesalers;
    }

    closedWholesalerSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.wholesalers;
        refiner.value = this.viewmodel.selectedWholesalers?.length < 2
            ? this.viewmodel.selectedWholesalers
                .map((wholesaler) => wholesaler.name)
                .join(", ")
            : this.viewmodel.selectedWholesalers?.length + " Selected";
        refiner.dataPropertyName = "name";
        refiner.dataValue = this.viewmodel.selectedWholesalers
            .map((wholesaler) => wholesaler.id)
            .join(valueSeparator);

        if (this.viewmodel.selectedWholesalersHold === this.viewmodel.selectedWholesalers) {
            this.viewmodel.isWholesalersStale = false;
        }

        this.refinerService.checkAndUpdateRefiner(refiner, false, true);
    }

    openedChainSelect() {
        this.viewmodel.isChainsStale = true;
        this.viewmodel.selectedChainsHold = this.viewmodel.selectedChains;
    }

    closedChainSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.chains;
        refiner.value = this.viewmodel.selectedChains?.length < 2
            ? this.viewmodel.selectedChains
                .map((chain) => chain.name)
                .join(", ")
            : this.viewmodel.selectedChains?.length + " Selected";
        refiner.dataPropertyName = "name";
        refiner.dataValue = this.activitiesFilterService.getChainDataValues(
            this.viewmodel.selectedChains,
            this.projectFilterService.useOwnerCode
        );

        if (this.viewmodel.selectedChainsHold === this.viewmodel.selectedChains) {
            this.viewmodel.isChainsStale = false;
        }

        this.refinerService.checkAndUpdateRefiner(refiner, false, true);
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.chains:
                    const selectedChains = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedChains = new Array<AccountOwnership>();

                    if (selectedChains) {
                        for (const chain of selectedChains) {
                            const found = this.viewmodel.chains.find(
                                (v) => this.projectFilterService.useOwnerCode
                                    ? v.ownerCode === chain.trim()
                                    : v.id === chain.trim()
                            );
                            if (found) {
                                rtnSelectedChains.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedChains = rtnSelectedChains;
                    break;
                case RefinerLocation.wholesalers:
                    const selectedWholesalers = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedWholesalers = new Array<Customer>();

                    if (selectedWholesalers) {
                        for (const type of selectedWholesalers) {
                            const found = this.viewmodel.wholesalers.find(
                                (p) => p.id === type.trim()
                            );
                            if (found) {
                                rtnSelectedWholesalers.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedWholesalerIds = selectedWholesalers;
                    this.viewmodel.selectedWholesalers = rtnSelectedWholesalers;
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.chains;
        const chainsRefiner = this.refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.chains
        );
        if (chainsRefiner) {
            this.onInputChange(chainsRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.wholesalers;
        const wholesalersRefiner = this.refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.wholesalers
        );
        if (wholesalersRefiner) {
            this.onInputChange(wholesalersRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }
}
