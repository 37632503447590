import { CallReceiptDto } from "./call-receipt.dto";
import { ReceiptDto } from "./receipt.dto";
import { TransactionLineItemDto } from "./transaction-line-item.dto";

export class TransactionDto {
    id: string;
    callId: string;
    callDate: string;
    customerId: string;
    retailNumber: string | null;
    customerName: string | null;
    customerAddress1: string | null;
    customerAddress2: string | null;
    customerCity: string | null;
    customerState: string | null;
    customerZip: string | null;
    customerCounty: string | null;
    receiptNumber: string;
    salesTotal: number;
    netTotal: number;
    gratisTotal: number;
    gratisPercentage: number;
    gratisId: string | null;
    gratisNumber: string | null;
    createdUtcDateTime: string;
    createdUserId: string;
    createdUserZrt: string | null;
    createdUserFullName: string;
    modifiedUtcDateTime: string;
    modifiedUserId: string;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string;

    transactionLineItems: TransactionLineItemDto[];
    callReceipts: CallReceiptDto[];
    receipts: ReceiptDto[];
}
