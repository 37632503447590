import { Component, OnInit } from "@angular/core";
import { LegendPosition } from "@swimlane/ngx-charts";
import { VolumeDataService } from "../volume-data.service";

@Component({
    selector: "app-volume-chart-card",
    templateUrl: "./volume-chart-card.component.html",
    styleUrls: ["./volume-chart-card.component.scss"]
})
export class VolumeChartCardComponent implements OnInit {
    //Docs to pie chart component library (ngx-charts):
    //https://swimlane.gitbook.io/ngx-charts/examples/pie-charts/pie-chart#data-format
    //https://github.com/swimlane/ngx-charts

    //Pie Chart Demo:
    //https://stackblitz.com/edit/swimlane-pie-chart?embed=1&file=app/app.component.ts

    //pie chart options:
    legendPosition = LegendPosition.Below;
    showLegend: boolean = false;
    showLabels: boolean = true;

    colorScheme = {
        domain: [
            "#881A17",
            "#CD6A27",
            "#E2DA46",
            "#3A6076",
            "#216E2F",
            "#868686"
        ]
    };

    constructor(public volumeData: VolumeDataService) {}

    ngOnInit(): void {}
}
