import { GenericLookup } from "../generic";

export enum GratisEmployeeApprovalStatuses {
    NotSubmitted = 0,
    Submitted = 1,
    Approved = 2,
    Rejected = 3,
    Ordered = 4,
    Pending = 5,
    AwaitingApproval = 6,
    AwaitingTM = 7,
    AwaitingRM = 8,
    AwaitingZM = 9,
    AwaitingAll = 10,
    NeedsReview = 11,
    Deleted = 12,
}

export const GratisEmployeeApprovalStatusLookup: GenericLookup<GratisEmployeeApprovalStatuses>[] = [
    { id: GratisEmployeeApprovalStatuses.NotSubmitted, name: "Not Submitted", description: "Not Submitted" },
    { id: GratisEmployeeApprovalStatuses.Submitted, name: "Submitted", description: "Submitted" },
    { id: GratisEmployeeApprovalStatuses.Approved, name: "Approved", description: "Approved" },
    { id: GratisEmployeeApprovalStatuses.Rejected, name: "Rejected", description: "Rejected" },
    { id: GratisEmployeeApprovalStatuses.Ordered, name: "Ordered", description: "Ordered" },
    { id: GratisEmployeeApprovalStatuses.Pending, name: "Pending", description: "Pending" },
    { id: GratisEmployeeApprovalStatuses.AwaitingApproval, name: "AwaitingApproval", description: "Awaiting Approval" },
    { id: GratisEmployeeApprovalStatuses.AwaitingTM, name: "AwaitingTM", description: "Awaiting Territory Manager" },
    { id: GratisEmployeeApprovalStatuses.AwaitingRM, name: "AwaitingRM", description: "Awaiting Regional Manager" },
    { id: GratisEmployeeApprovalStatuses.AwaitingZM, name: "AwaitingZM", description: "Awaiting Zone Manager" },
    { id: GratisEmployeeApprovalStatuses.AwaitingAll, name: "AwaitingAll", description: "Awaiting All" },
    { id: GratisEmployeeApprovalStatuses.NeedsReview, name: "NeedsReview", description: "Needs Review" },
    { id: GratisEmployeeApprovalStatuses.Deleted, name: "Deleted", description: "Deleted" }
];
