<div *ngIf="customers$ | async" class="d-flex flex-column flex-grow-1  ml-2 mr-2">
    <div #top class="card m-0 h-100">
        <div class="card-header d-flex justify-content-between align-items-center">
            OTP Category Volume & Swisher OTP Category Volume - Last 26 Weeks
        </div>
        <div class="card-body chart-card-body">
            <div class="pie-chart">
                <p class="title">Category Volume Share</p>
                <div class="chart-container">
                    <div class="chart-body" *ngIf="(otpPieChartHasValues$ | async).hasValues">
                        <ngx-charts-pie-chart [results]="otpPieChart$ | async"
                            [scheme]="otpPieChartColorScheme$|async" [legend]="false" [tooltipDisabled]="true" [labels]="true"
                            [labelFormatting]="displayPercentageAsLabel"  (select)="categoryClicked($event)">
                        </ngx-charts-pie-chart>
                    </div>
                    <div class="chart-legend" *ngIf="(otpPieChartHasValues$ | async).hasValues">
                        <div class="legend-item" *ngFor="let category of otpPieChart$|async" (click)="scrollToCategory(category.name)">
                            <div class="legend-color" [style.backgroundColor]="category.color"></div>
                            {{category.name}}
                        </div>
                    </div>
                    <div class="no-data" *ngIf="(!(otpPieChartHasValues$ | async).hasValues)">
                        No Data
                    </div>
                </div>
            </div>
            <div class="pie-chart">
                <p class="title">Swisher Category Volume Share</p>
                <div class="chart-container">
                    <div class="chart-body" *ngIf="(otpPieChartHasValues$ | async).hasValues">
                        <ngx-charts-pie-chart [results]="swisherOtpPieChart$ | async"
                            [scheme]="swisherOtpPieChartColorScheme$|async" [legend]="false" [tooltipDisabled]="true" [labels]="true"
                            [labelFormatting]="displayPercentageAsLabel" (select)="categoryClicked($event)">
                        </ngx-charts-pie-chart>
                    </div>
                    <div class="chart-legend" *ngIf="(otpPieChartHasValues$ | async).hasValues">
                        <div class="legend-item" *ngFor="let category of swisherOtpPieChart$|async" (click)="scrollToCategory(category.name)">
                            <div class="legend-color" [style.backgroundColor]="category.color"></div>
                            {{category.name}}
                        </div>
                    </div>
                    <div class="no-data" *ngIf="(!(otpPieChartHasValues$ | async).hasValues)">
                        No Data
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card m-0 h-100">
        <div class="card-header d-flex justify-content-between align-items-center">
            Configure Area Distance To Compare
        </div>
        <div class="card-body chart-card-body justify-content-start">
            <div class="d-flex">
                <mat-form-field appearance="outline" color="accent" class="w-100 mb-2">
                    <mat-label>
                        Distance
                    </mat-label>
                    <mat-select (selectionChange)="nearbyVolumeRadius$.next($event.value)" value="1">
                        <mat-option value="1">
                            1 Mile
                        </mat-option>
                        <mat-option value="5">
                            5 Miles
                        </mat-option>
                        <mat-option value="10">
                            10 Miles
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div #SSCCLarge class="card m-0 h-100">
        <div class="card-header d-flex justify-content-between align-items-center">
            Large Cigar & SSCC Large Cigars - Last 26 Weeks
            <div class="card-header-btn">
                <button class="btn sw-transparent-btn btn-sm" (click)="scroll(top)">
                    Back to Top
                </button>
            </div>
        </div>
        <div class="card-body chart-card-body ">
            <div >
                <shield-share-tashare-chart
                    [hasData]="hasLargeCigars$ | async"
                    [data]="totalLargeShare$|async"
                    chartTitle="Total Large"
                    variant="Tall"
                ></shield-share-tashare-chart>
            </div>
            <div class="d-flex flex-column">
                <div>
                    <shield-share-tashare-chart
                        [hasData]="hasLargeCigars$ | async"
                        [data]="traditionalNL$|async"
                        chartTitle="Traditional NL"
                    ></shield-share-tashare-chart>
                </div>
                <div>
                    <shield-share-tashare-chart
                        [hasData]="hasLargeCigars$ | async"
                        [data]="htlTip$|async"
                        chartTitle="HTL Tip & Non Tip"
                    ></shield-share-tashare-chart>
                </div>
            </div>
            <div class="d-flex flex-column">
                <div>
                    <shield-share-tashare-chart
                        [hasData]="hasLargeCigars$ | async"
                        [data]="roughCutNL$|async"
                        chartTitle="Rough Cut NL"
                    ></shield-share-tashare-chart>
                </div>
                <div >
                    <shield-share-tashare-chart
                        [hasData]="hasLargeCigars$ | async"
                        [data]="value$|async"
                        chartTitle="Value"
                    ></shield-share-tashare-chart>
                </div>
            </div>
            <div>
                <shield-sku-breakdown-chart
                    [hasData]="hasLargeCigars$ | async"
                    [data]="largeSkuBreakdown$|async"
                    variant="Tall"
                >
                </shield-sku-breakdown-chart>
            </div>
        </div>
    </div>
    <div #MonPouch class="card m-0 h-100">
        <div class="card-header d-flex justify-content-between align-items-center">
            Mon Pouch & Rogue Pouches - Last 26 Weeks
            <div class="card-header-btn">
                <button class="btn sw-transparent-btn btn-sm" (click)="scroll(top)">
                    Back to Top
                </button>
            </div>
        </div>
        <div class="card-body chart-card-body">
            <div >
                <shield-share-tashare-chart
                    [hasData]="hasMonPouches$ | async"
                    [data]="totalMonPouchShare$|async"
                    chartTitle="Total MON Pouch"
                ></shield-share-tashare-chart>
            </div>
            <div>
                <shield-sku-breakdown-chart
                    [hasData]="hasMonPouches$ | async"
                    [data]="monPouchSkuBreakDown$|async"
                >
                </shield-sku-breakdown-chart>
            </div>
        </div>
    </div>
    <div #BDMST class="card m-0 h-100">
        <div class="card-header d-flex justify-content-between align-items-center">
            Branded Discount MST & Fat Lip MST - Last 26 Weeks
            <div class="card-header-btn">
                <button class="btn sw-transparent-btn btn-sm" (click)="scroll(top)">
                    Back to Top
                </button>
            </div>
        </div>
        <div class="card-body chart-card-body">
            <div >
                <shield-share-tashare-chart
                    [hasData]="hasBdMst$ | async"
                    [data]="totalBdMstShare$|async"
                    chartTitle="Total BD MST"
                ></shield-share-tashare-chart>
            </div>
            <div>
                <shield-sku-breakdown-chart
                    [hasData]="hasBdMst$ | async"
                    [data]="bdMstSkuBreakDown$|async"
                >
                </shield-sku-breakdown-chart>
            </div>
        </div>
    </div>
    <div #Chew class="card m-0 h-100">
        <div class="card-header d-flex justify-content-between align-items-center">
            Chew & Fat Lip Chew - Last 26 Weeks
            <div class="card-header-btn">
                <button class="btn sw-transparent-btn btn-sm" (click)="scroll(top)">
                    Back to Top
                </button>
            </div>
        </div>
        <div class="card-body chart-card-body">
            <div >
                <shield-share-tashare-chart
                    [hasData]="hasChew$ | async"
                    [data]="totalChewShare$|async"
                    chartTitle="Total Chew"
                ></shield-share-tashare-chart>
            </div>
            <div>
                <shield-sku-breakdown-chart
                    [hasData]="hasChew$ | async"
                    [data]="chewSkuBreakDown$|async"
                >
                </shield-sku-breakdown-chart>
            </div>
        </div>
    </div>
    <div #Snuff class="card m-0 h-100">
        <div class="card-header d-flex justify-content-between align-items-center">
            Dry Snuff & Fat Lip Dry Snuff - Last 26 Weeks
            <div class="card-header-btn">
                <button class="btn sw-transparent-btn btn-sm" (click)="scroll(top)">
                    Back to Top
                </button>
            </div>
        </div>
        <div class="card-body chart-card-body">
            <div >
                <shield-share-tashare-chart
                    [hasData]="hasDrySnuff$ | async"
                    [data]="totalDrySnuffShare$|async"
                    chartTitle="Total Dry Snuff"
                ></shield-share-tashare-chart>
            </div>
            <div>
                <shield-sku-breakdown-chart
                    [hasData]="hasDrySnuff$ | async"
                    [data]="drySnuffSkuBreakDown$|async"
                >
                </shield-sku-breakdown-chart>
            </div>
        </div>
    </div>
    <div #SSCCFiltered class="card m-0 h-100">
        <div class="card-header d-flex justify-content-between align-items-center">
            Filtered Cigars & SSCC Filtered Cigars - Last 26 Weeks
            <div class="card-header-btn">
                <button class="btn sw-transparent-btn btn-sm" (click)="scroll(top)">
                    Back to Top
                </button>
            </div>
        </div>
        <div class="card-body chart-card-body">
            <div >
                <shield-share-tashare-chart
                    [hasData]="hasFilteredCigars$ | async"
                    [data]="totalFilteredShare$|async"
                    chartTitle="Total Filtered"
                ></shield-share-tashare-chart>
            </div>
            <div>
                <shield-sku-breakdown-chart
                    [hasData]="hasFilteredCigars$ | async"
                    [data]="filteredSkuBreakdown$|async"
                >
                </shield-sku-breakdown-chart>
            </div>
        </div>
    </div>
    <div #SSCCLittle class="card m-0 h-100">
        <div class="card-header d-flex justify-content-between align-items-center">
            Little Cigars & SSCC Little Cigars - Last 26 Weeks
            <div class="card-header-btn">
                <button class="btn sw-transparent-btn btn-sm" (click)="scroll(top)">
                    Back to Top
                </button>
            </div>
        </div>
        <div class="card-body chart-card-body">
            <div >
                <shield-share-tashare-chart
                    [hasData]="hasLittleCigars$ | async"
                    [data]="totalLittleShare$|async"
                    chartTitle="Total Little"
                ></shield-share-tashare-chart>
            </div>
            <div>
                <shield-sku-breakdown-chart
                    [hasData]="hasLittleCigars$ | async"
                    [data]="littleSkuBreakdown$|async"
                >
                </shield-sku-breakdown-chart>
            </div>
        </div>
    </div>
</div>