import { CustomerContractDto, EntityObjectNames, EntityPropertyNames, RefinerDto, RefinerGroupDto, RefinerLocation } from "shield.shared";
import { CustomerContract } from "src/app/entity-models/customer-contract.entity";
import { ContractTemplateConverterService } from "./contract-template-converter.service";
import { CustomerContractPaymentConverterService } from "./customer-contract-payment-converter.service";
import { RefinerConverterService } from "./refiner-converter.service";

export class CustomerContractConverterService {

    static customerContractToCustomerContractDto(d: CustomerContract | null): CustomerContractDto | null{
        if (d == null) {
            return null;
        }

        return {
            ...d,
            signatureDate: d?.signatureDate?.toISOString(),
            endDate: d?.endDate?.toISOString(),
            createdUtcDateTime: d?.createdUtcDateTime?.toISOString(),
            modifiedUtcDateTime: d?.modifiedUtcDateTime?.toISOString(),
            customerContractPayments: d.customerContractPayments?.map((v) => CustomerContractPaymentConverterService.customerContractPaymentToCustomerContractPaymentDto(v)),
            contractTemplate: ContractTemplateConverterService.contractTemplateToContractTemplateDto(d.contractTemplate),
            signatureBlob: d.customerSignature.image
        };
    }

    static customerContractDtoToCustomerContract(dto: CustomerContractDto | null): CustomerContract | null {
        if (dto == null) {
            return null;
        }

        return {
            ...dto,
            signatureDate: dto?.signatureDate ? new Date(dto.signatureDate) : null,
            endDate: dto?.endDate ? new Date(dto.endDate) : null,
            createdUtcDateTime: dto?.createdUtcDateTime ? new Date(dto.createdUtcDateTime) : null,
            modifiedUtcDateTime: dto?.modifiedUtcDateTime ? new Date(dto.modifiedUtcDateTime) : null,
            customerContractPayments: dto.customerContractPayments?.map((v) => CustomerContractPaymentConverterService.customerContractPaymentDtoToCustomerContractPayment(v)),
            contractTemplate: ContractTemplateConverterService.contractTemplateDtoToContractTemplate(dto.contractTemplate),
            hasServerProcessed: 0
        };
    }

    static mapDtoData(refiner: RefinerDto): RefinerGroupDto {
        const refinerDtos = new Array<RefinerDto>();

        switch (refiner.refinerLocation) {
            case RefinerLocation.account:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContracts",
                        "CustomerName",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContracts",
                        "CustomerId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContracts",
                        "CustomerNumber",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.zrt:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "Zrt",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.street:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContracts",
                        "Address1",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.states:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContracts",
                        "State",
                        refiner.value
                    )
                );
                break;
            case RefinerLocation.city:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContracts",
                        "City",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.counties:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContracts",
                        "CountyId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.zipCodes:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContracts",
                        "ZipCode",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.signedOnOrAfterDate:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContracts",
                        "SignatureDate",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.signedOnOrBeforeDate:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContracts",
                        "SignatureDate",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.callOnOrAfterDate:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "LastCall",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.callOnOrBeforeDate:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "LastCall",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.contract:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContracts",
                        "Number",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.contractType:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "ContractTemplates",
                        "ContractTemplateTypeId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.lastCallStatus:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContracts",
                        "LastPaymentStatusId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.paymentOnOrAfterDate:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContractPayments",
                        "PaymentDate",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.paymentOnOrBeforeDate:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContractPayments",
                        "PaymentDate",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.paymentType:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContractPayments",
                        "ContractPaymentTypeId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.paymentMethod:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContractPayments",
                        "ContractPaymentMethodId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.programLevel:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerContracts",
                        "Level",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.storeTypes:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "Type",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.msa:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "IsMsa",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                if (refiner.dataValue === "0") {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "Customers",
                            "IsMsa",
                            refiner.value,
                            "IsNull"
                        )
                    );
                }
            break;
            case RefinerLocation.px3Rank:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerPx3Ranks",
                        "Px3RankId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            default:
                break;
        }
        return RefinerConverterService.splitRefinerDtoValues(refinerDtos);
    }
}
