import { RouteStopDto } from "./route-stop.dto";

export class RouteDto {
    id: string;
    employeeId: string;
    employeeZrt: string | null;
    date: string;
    name: string;
    description: string;
    stops: RouteStopDto[];
    createdUtcDateTime: string | null;
    createdUserId: string | null;
    createdUserZrt: string | null;
    createdUserFullName: string | null;
    modifiedUtcDateTime: string | null;
    modifiedUserId: string | null;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string | null;
    rowversion: string;

    completedStops: number | null;
}
