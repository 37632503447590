import { Country } from "src/app/entity-models/country.entity";
import { County } from "src/app/entity-models/county.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { State } from "src/app/entity-models/state.entity";
import { Helper } from "src/app/helpers/helper";
import { FilterService } from "src/app/services/filter.service";
import { CountyViewmodel } from "../../viewmodels/county.viewmodel";
import { NamedIterables } from "../../viewmodels/named-iterables.viewmodel";
import { StateViewmodel } from "../../viewmodels/state.viewmodel";

export class ProjectLocationViewModel {
    //public vars
    cityInput: string;
    counties = new Array<County>();
    filteredCounties = new Array<CountyViewmodel>()
    countries = new Array<Country>();
    filteredStates = new Array<StateViewmodel>();
    filterService: FilterService;
    groupedCountiesByState = new Array<NamedIterables<CountyViewmodel>>();
    managerInput: string;
    selectedCounties = new Array<CountyViewmodel>();
    selectedEmployees = new Array<Employee>();
    selectedStates = new Array<StateViewmodel>();
    states = new Array<State>();
    streetInput: string;
    zipInput: string;

    constructor(filterService: FilterService) {
        this.filterService = filterService;
    }

    //public methods
    adjustCountySelectOptions(): CountyViewmodel[] {
        let rtn = new Array<CountyViewmodel>();
        const states = this.selectedStates.map((vm) => vm.state);
        const stateIds = states.map((s) => s.id);

        const counties = this.counties.filter((c) =>
            stateIds.includes(c.stateId)
        );

        const filteredCounties: CountyViewmodel[] = [];

        for (const county of counties) {
            const foundState = states.find((s) => s.id === county.stateId);
            if (foundState) {
                const vm = new CountyViewmodel(county, foundState);
                filteredCounties.push(vm);
            }
        }

        this.filteredCounties = filteredCounties;

        const selectedCounties = this.selectedCounties;

        const groupedCountiesByState: Map<
            string,
            CountyViewmodel[]
        > = Helper.groupBy(
            filteredCounties,
            (groupedCounty: CountyViewmodel) => groupedCounty.state.name
        );


        this.groupedCountiesByState = Array.from(groupedCountiesByState)
            .map((el) => new NamedIterables(el[0], el[1]))
            .sort((a, b) => (a.name >= b.name ? 1 : -1));

        this.selectedCounties = selectedCounties.filter((sc) =>
            this.groupedCountiesByState.find((gc) => gc.name === sc.state.name)?.items?.filter((countyVm) => countyVm.county.name === sc.county.name));

        rtn = selectedCounties.filter((sc) => this.selectedCounties.find((msc) => msc.state.id === sc.state.id
            && msc.county.id === sc.county.id));

        return rtn;
    }

    async initializeLocationDropDowns(): Promise<void> {
        this.countries = await this.filterService.getCountries();
        this.states = await this.filterService.getStates();
        this.counties = await this.filterService.getCounties();
        const filteredCountries = this.countries.slice();
        const selectedCountries = filteredCountries.filter(
            (c) => c.name === "United States of America"
        );
        const countryIds = selectedCountries.map((c) => c.id);
        const states = this.states
            .slice()
            .filter((s) => countryIds.includes(s.countryId));

        const filteredStates: StateViewmodel[] = [];
        for (const state of states) {
            if (countryIds.includes(state.countryId)) {
                const vm = new StateViewmodel();
                vm.state = state;
                vm.country = selectedCountries.find(
                    (c) => c.id === vm.state.countryId
                );
                filteredStates.push(vm);
            }
        }
        this.filteredStates = filteredStates.sort((a, b) =>
            a.state.name >= b.state.name ? 1 : -1
        );

        if (this.selectedStates?.length) {
            this.adjustCountySelectOptions();
        }
    }
}
