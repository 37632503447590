import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { TimeLogComponent } from "../my-day/time-log/time-log.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";

const routes: Routes = [
    {
        canActivate: [MsalGuard],
        path: "user/user-profile",
        component: UserProfileComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UserRoutingModule {}
