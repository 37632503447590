<div
    class="share-dialog-modal"
    cdkDrag
    cdkDragBoundary=".cdk-overlay-container"
>
    <div cdkDragHandle>
        <div class="modal-header">
            Share Options
            <mat-icon class="close-icon" (click)="close()">close</mat-icon>
        </div>
    </div>
    <div class="modal-body">
        <div class="d-flex align-items-center mb-3">
            <div class="col-2">
                Send To:
            </div>
            <div class="col-8">
                <app-employee-dropdown [offline]="isOffline" [preselected]="shareEmployees" [singleSelection]="false"
                    (selectionChange)="shareEmployees = $event ? $event : null"></app-employee-dropdown>
            </div>
            <div class="col-2">
            </div>
        </div>
        
        <div class="row empty-row"></div>
        <div class="d-flex align-items-center mb-3" *ngIf="showCustomerContact">
            <div class="col-2">
                Send To:
            </div>
            <div class="col-8">
                <mat-form-field class="inherit-width" appearance="outline" *ngIf="contactOptions">
                    <mat-label>Please Select a Contact</mat-label>
                    <mat-select [(value)]="selectedContact"
                        (selectionChange)="onSelectedContact()" [compareWith]="compareContactOptions">
                        <mat-option *ngFor="let option of contactOptions" [value]="option">
                            {{ option.firstName }}
                            {{ option.lastName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-2 small">
                <button appThrottleClick class="btn btn-sm sw-green-btn border" (throttledClick)="onAddContact()">
                    <span class="flaticon-add"></span>
                </button>
            </div>
        </div>
        
        <div class="d-flex align-items-center mb-2">
            <div class="col-12">
                <mat-form-field appearance="outline" color="accent">
                    <mat-label id="comments">Comments</mat-label>
                    <textarea cdkTextareaAutosize="true" matAutosizeMaxRows="12"
                        [(ngModel)]="comments" rows="6" type="text" matInput maxlength="10000">
                    </textarea>
                    <mat-hint align="end">{{
                        comments?.length || 0
                        }}/10,000
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-white btn-sm actionBtn" (click)="close()">
            Cancel
        </button>
        <button mat-raised-button appThrottleClick
            class="btn sw-green-btn btn-sm actionBtn"
            [disabled]="(!shareEmployees || !shareEmployees?.length) && !selectedContact"
            (throttledClick)="saveShare()"
        >
            <mat-icon style="display: inline">check_circle_outline</mat-icon
            > {{ confirmButtonText }}
        </button>
    </div>
</div>
