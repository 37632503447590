import { Customer } from "src/app/entity-models/customer.entity";
import { Project } from "src/app/entity-models/project.entity";
import { ProductViewmodel } from "../stepper-call/distribution-grid/product.viewmodel";

export class CallDistributionProductViewModel {
    isOrder = false;
    isCash = false;
    isSelect = false;
    isInDist = false;
    isIntro = false;
    isOos = false;
    isCos = false;
    isGratis = false;
    isExchangeOut = false;
    isExchangeIn = false;
    hasWholesalerProductMapping = false;
    dateAvailable?: Date;
    product: ProductViewmodel;
    productUin: string;
    packSize: number;
    wholesaler?: Customer;
    selectedProject?: Project;
    excludeUpdating: ExcludeDistributionUpdate;
}

export type ExcludeDistributionUpdate =
    | "isCash"
    | "isOrder"
    | "isInDist"
    | "isIntro"
    | "isOos"
    | "isCos"
    | "isSelect";
