import { GenericRequestDto, TransactionBatchParamsDto } from "shield.shared";
import { TransactionLineItem } from "src/app/entity-models/transaction-line-item.entity";
import { Transaction } from "src/app/entity-models/transaction.entity";

export class TransactionOfflineService {

    private readonly notAvailableInOfflineModeError = "Transactions are not available in offline mode.";

    async getById(id: string): Promise<Transaction> {

        throw Error(this.notAvailableInOfflineModeError);

        return new Transaction()
    }

    async getTransactionBatch(
        dto: TransactionBatchParamsDto
    ): Promise<Transaction[]> {

        throw Error(this.notAvailableInOfflineModeError);

        return new Array<Transaction>();
    }

    async saveInvoiceDetails(request: GenericRequestDto<TransactionLineItem[]>): Promise<Transaction> {

        throw Error(this.notAvailableInOfflineModeError);

        return new Transaction();
    }
}
