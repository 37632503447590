import { DayTimeEntryWorkWith } from "./day-time-entry-work-with.entity";

export class DayTimeEntry {
    id: string;
    employeeId: string;
    date: Date;
    key: string;
    isCompleted: boolean;
    mileage: number;
    resetDay: boolean;
    endOfDayComment: string;
    createdUtcDateTime: Date | null;
    createdUserId: string | null;
    createdUserZrt: string | null;
    createdUserFullName: string | null;
    modifiedUtcDateTime: Date | null;
    modifiedUserId: string | null;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string | null;
    dayTimeEntryWorkWiths: DayTimeEntryWorkWith[];
    rowversion: string;
}
