import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/entity-models/project.entity';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';
import { CopyProjectViewmodel } from './copy-project.viewmodel';

@Component({
    selector: 'app-copy-project.modal',
    templateUrl: './copy-project-modal.component.html',
    styleUrls: ['./copy-project-modal.component.scss']
})
export class CopyProjectModalComponent implements OnInit {
    viewmodel: CopyProjectViewmodel;

    constructor(public injectedData: SwisherOverlayRef<
        CopyProjectViewmodel,
        CopyProjectModalComponent
    >) {}

    ngOnInit(): void {
        this.viewmodel = this.injectedData.data;
    }
}
