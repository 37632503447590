import {
    AfterViewChecked,
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from "@angular/core";
import {
    faCloudDownloadAlt,
    faPen, faPrint, IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FormBuilder } from "@angular/forms";
import { AppStateService } from "src/app/services/app-state.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { ZoneRecapDelineationService } from "src/app/services/delineation-services/zone-recap-delineation.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { ZrtTreeViewComponent } from "src/app/shared/zrt-tree-view/zrt-tree-view.component";
import { ZoneRecapReportZrtFilterService } from "./zone-recap-report-filter.service";
import { ZoneRecapReportRefinerService } from "./zone-recap-report-refiner.service";
import { ZoneRecapReportViewmodel } from "./zone-recap-report.viewmodel";
import { OverlayService } from "src/app/services/overlay.service";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";

@Component({
    selector: "app-zone-recap-report",
    templateUrl: "./zone-recap-report.component.html",
    styleUrls: ["./zone-recap-report.component.scss"],
    providers: [PleaseWaitService]
})
export class ZoneRecapReportComponent implements OnInit, AfterViewChecked {
    @ViewChild("startDateInput") startDateInput: ElementRef;
    @ViewChild("endDateInput") endDateInput: ElementRef;
    @ViewChild("zrtTree") zrtTree: ZrtTreeViewComponent;
    @ViewChild("resultsTable") resultsTable: ElementRef;
    @ViewChild("productsTable") productsTable: ElementRef;
    @ViewChild("recap") recap: ElementRef;

    faPen: IconDefinition = faPen;
    faCloudDownloadAlt: IconDefinition = faCloudDownloadAlt;
    faPrint: IconDefinition = faPrint;
    viewmodel: ZoneRecapReportViewmodel;
    isVmReady = false;

    constructor(
        private formBuilder: FormBuilder,
        private zoneRecapReportRefinerService: ZoneRecapReportRefinerService,
        private zrtFilterService: ZoneRecapReportZrtFilterService,
        private employeeDelineationService: EmployeeDelineationService,
        private appStateService: AppStateService,
        private zoneRecapDelineationService: ZoneRecapDelineationService,
        private pleaseWaitService: PleaseWaitService,
        private overlayService: OverlayService,
        private productDelineationService: ProductDelineationService
    ) {}

    ngOnInit(): void {
        this.viewmodel = new ZoneRecapReportViewmodel(
            this.formBuilder,
            this.zoneRecapReportRefinerService,
            this.zrtFilterService,
            this.employeeDelineationService,
            this.appStateService,
            this.zoneRecapDelineationService,
            this.pleaseWaitService,
            this.overlayService,
            this.productDelineationService
        );
    }

    ngAfterViewChecked(): void {
        if (
            !this.isVmReady &&
            this.startDateInput &&
            this.endDateInput &&
            this.zrtTree &&
            this.resultsTable &&
            this.productsTable &&
            this.recap
        ) {
            this.isVmReady = true;
            setTimeout(() => {
                void this.viewmodel.initialize(
                    this.startDateInput,
                    this.endDateInput,
                    this.zrtTree,
                    this.resultsTable,
                    this.productsTable,
                    this.recap
                );
            }, 0);
        }
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }
}
