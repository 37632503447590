export enum ButtonActions {
  none = "Notta",
  columnVisibility = "Column Visibility",
  exportToExcel = "Export To Excel",
  print = "Print",
  add = "add",
  route = "Route",
  list = "List",
  delete = "Delete"
}
