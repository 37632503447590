<div #myFilter>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100" [formGroup]="viewmodel.callDateForm">
            <mat-label>Call on or After</mat-label>
            <input #callStartDateInput matInput
                formControlName="callStartDate"
                [max]="viewmodel.startMaxDate"
                [matDatepicker]="afterPicker"
                (keyup)="onAddStartDateRefiner($event)"
                (dateChange)="onAddStartDateRefiner()" />
            <mat-datepicker touchUi #afterPicker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="afterPicker"></mat-datepicker-toggle>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100" [formGroup]="viewmodel.callDateForm">
            <mat-label>Call on or Before</mat-label>
            <input #callEndDateInput matInput
                formControlName="callEndDate"
                [min]="viewmodel.endMinDate"
                [max]="viewmodel.endMaxDate"
                [matDatepicker]="beforePicker"
                (keyup)="onAddEndDateRefiner($event)"
                (dateChange)="onAddEndDateRefiner()" />
            <mat-datepicker touchUi #beforePicker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="beforePicker"></mat-datepicker-toggle>
        </mat-form-field>
    </div>
</div>
