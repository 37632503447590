import { MsaBrandDto, MsaDistributorVolumeDto, MsaDistributorVolumeResponseDto } from "shield.shared";
import { MsaBrand } from "src/app/entity-models/msa-brand.entity";
import { MsaDistributorVolumeResponse } from "src/app/entity-models/msa-distributor-volume-response.entity";
import { MsaDistributorVolume } from "src/app/entity-models/msa-distributor-volume.entity";

export class VolumeConverterService {

    static msaDistributorVolumeResponseDtoToMsaDistributorVolumeResponse(dto: MsaDistributorVolumeResponseDto): MsaDistributorVolumeResponse {

        const rtn = new MsaDistributorVolumeResponse();

        rtn.brands = (dto.brands ?? []).map((brand) => VolumeConverterService.masBrandDtoToMsaBrand(brand));
        rtn.volumes = (dto.volumes ?? []).map((volume) => VolumeConverterService.masDistributorVolumeDtoToMsaDistributorVolume(volume));
        rtn.storeCount = dto.storeCount;

        return rtn;
    }

    static masBrandDtoToMsaBrand(dto: MsaBrandDto): MsaBrand {

        const rtn = new MsaBrand();

        rtn.id = dto.id;
        rtn.brandCategoryName = dto.brandCategoryName;
        rtn.brandFamily = dto.brandFamily;
        rtn.description = dto.description;
        rtn.isDeactivated = dto.isDeactivated;
        rtn.isDeleted = dto.isDeleted;
        rtn.manufacturerName = dto.manufacturerName;
        rtn.name = dto.name;
        rtn.segment = dto.segment;
        rtn.subSegment = dto.subSegment;

        return rtn;
    }

    static masDistributorVolumeDtoToMsaDistributorVolume(dto: MsaDistributorVolumeDto): MsaDistributorVolume {

        const rtn = new MsaDistributorVolume();

        rtn.msaBrandId = dto.msaBrandId;
        rtn.volumeAt13Weeks = dto.volumeAt13Weeks;
        rtn.volumeAt26Weeks = dto.volumeAt26Weeks;
        rtn.volumeAt4Weeks = dto.volumeAt4Weeks;
        rtn.volumeAtCurrentYearToDate = dto.volumeAtCurrentYearToDate;
        rtn.volumeAtOneYearAgo = dto.volumeAtOneYearAgo;

        return rtn;
    }
}
