import { AfterContentChecked, Component, HostBinding, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AppStateService } from 'src/app/services/app-state.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { PleaseWaitService } from 'src/app/services/please-wait.service';
import { ProjectStateService } from 'src/app/services/project-state-service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { PageHeaderComponent } from 'src/app/shared/page-header/page-header.component';
import { ProjectFilterService } from './project-filter.service';
import { ProjectListRefinerService } from './project-list-refiner.service';
import { ProjectListViewmodel } from './project-list.viewmodel';
import { ProjectDelineationService } from 'src/app/services/delineation-services/project-delineation.service';
import { CustomerDelineationService } from 'src/app/services/delineation-services/customer-delineation.service';
import { EmployeeDelineationService } from 'src/app/services/delineation-services/employee-delineation.service';
import { AccountOwnershipDelineationService } from 'src/app/services/delineation-services/account-ownership-delineation.service';
import { ProjectListZrtFilterService } from './project-list-zrt-filter.service';
import { FilterService } from 'src/app/services/filter.service';
import { FilterAndParams } from 'src/app/entity-models/filters-and-params.entity';
import { MySearchesFilterComponent } from 'src/app/shared/filters/my-searches/my-searches-filter.component';
import { ProjectLocationFilterComponent } from 'src/app/shared/filters/project-location-filter/project-location-filter.component';
import { DateInformationFilterComponent } from 'src/app/shared/filters/date-information-filter/date-information-filter.component';
import { ProjectActivitiesFilterComponent } from 'src/app/shared/filters/project-activities-filter/project-activities-filter.component';
import { ProjectAttributesFilterComponent } from 'src/app/shared/filters/project-attributes-filter/project-attributes-filter.component';

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss'],
    providers: [PleaseWaitService]
})
export class ProjectListComponent implements OnInit, AfterContentChecked {
    @HostBinding("class") class = "worksheet-static d-flex flex-column flex-grow-1";
    @ViewChild("drawer") drawer: MatSidenav;
    @ViewChild("grid") grid: GridComponent;
    @ViewChild("header") header: PageHeaderComponent;
    @ViewChild("sideNavContent") sideNavContent: MatSidenavContent;

    viewmodel = new ProjectListViewmodel(
        this.refinerService,
        this.router,
        this.overlayService,
        this.projectStateService,
        this.projectDelineationService,
        this.pleaseWaitService,
        this.snackbar,
        this.projectFilterService,
        this.employeeDelineationService,
        this.customerDelineationService,
        this.accountOwnershipDelineationService,
        this.zrtFilterService,
        this.filterService
    );
    viewmodelSet = false;

    constructor(
        private refinerService: ProjectListRefinerService,
        private router: Router,
        private overlayService: OverlayService,
        private projectStateService: ProjectStateService,
        private projectDelineationService: ProjectDelineationService,
        private pleaseWaitService: PleaseWaitService,
        private snackbar: SnackbarService,
        private appStateService: AppStateService,
        private projectFilterService: ProjectFilterService,
        private employeeDelineationService: EmployeeDelineationService,
        private customerDelineationService: CustomerDelineationService,
        private accountOwnershipDelineationService: AccountOwnershipDelineationService,
        private zrtFilterService: ProjectListZrtFilterService,
        private filterService: FilterService
    ) { }

    ngOnInit(): void {
        const filters: FilterAndParams[] = [];

        filters.push({ filter: MySearchesFilterComponent });
        filters.push({ filter: ProjectLocationFilterComponent, zrtFilterService: this.zrtFilterService });
        filters.push({ filter: DateInformationFilterComponent });
        filters.push({ filter: ProjectActivitiesFilterComponent });
        filters.push({ filter: ProjectAttributesFilterComponent });

        this.viewmodel.filters = filters;
        if (!this.viewmodel.employeeSubscription || this.viewmodel.employeeSubscription.closed) {
            this.viewmodel.employeeSubscription = this.appStateService.currentEmployee.subscribe((employee) => {
                this.viewmodel.employee = employee;
                void this.viewmodel.getProjectEmployeeOptions(employee);
            })
        }
    }

    ngAfterContentChecked(): void {
        if (this.viewmodelSet && !this.viewmodel.isGapSet) {
            this.viewmodel.calculateGap();
        }

        if (!this.viewmodelSet &&
            this.drawer &&
            this.grid &&
            this.header &&
            this.sideNavContent &&
            this.viewmodel.employee
        ) {
            this.viewmodelSet = true;
            this.getScreenSize();
            this.viewmodel.initialize(
                this.drawer,
                this.grid,
                this.header,
                this.sideNavContent
            );
        }
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?: any): void {
        if (this.viewmodel) {
            this.viewmodel.screenHeight = window.innerHeight;
        }
    }
}
