import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as XLSX from "xlsx";

@Injectable({
    providedIn: 'root'
})
export class XLSXService {

    constructor() { }

    async saveAsXLSX(data: any[][], columns: string[], prefix: string = "Export"): Promise<void> {
        return new Promise((resolve) => {
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([columns].concat(data));
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, prefix);
            XLSX.writeFile(wb, `${prefix}_${moment().format('yyyy_MM_DD_HHmmss')}.xlsx`);
            resolve();
        });
    }
}
