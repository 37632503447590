import { GenericLookup } from "../generic";

export enum CustomerTypeEnum {
    Unknown = 0,
    IndependentRetail = 1,
    DirectWholesaler = 2,
    IndirectWholesaler = 3,
    ChainRetail = 4,
    ChainHQ = 5,
}

export const CustomerTypeLookup: GenericLookup<CustomerTypeEnum>[] = [
    { id: CustomerTypeEnum.ChainHQ, name: "Chain HQ", description: "Chain HQ" },
    { id: CustomerTypeEnum.ChainRetail, name: "Chain Retail", description: "Chain Retail" },
    { id: CustomerTypeEnum.DirectWholesaler, name: "Direct Wholesaler", description: "Direct Wholesaler" },
    { id: CustomerTypeEnum.IndependentRetail, name: "Independent Retail", description: "Independent Retail" },
    { id: CustomerTypeEnum.IndirectWholesaler, name: "Indirect Wholesaler", description: "Indirect Wholesaler" },
    { id: CustomerTypeEnum.Unknown, name: "Unknown", description: "Unknown" }
];
