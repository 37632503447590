import { GratisListFilterDto, GratisStatuses, RefinerLocation, valueSeparator } from "shield.shared";
import { Refiner } from "../../entity-models/refiner.entity";
import { BaseFilterMapService } from "./base-filter-map.service";
import { ZrtFilterMapService } from "./zrt-filter-map.service";

export class GratisFilterMapService extends BaseFilterMapService {

    static mapFilterData(refiners: Refiner[]): GratisListFilterDto {

        if (!refiners) return;
        const filterDto = new GratisListFilterDto();
        const zrtFilterDto = ZrtFilterMapService.mapFilterData(refiners);

        if (Object.keys(zrtFilterDto).length) {
            filterDto.zrtFilterDto = zrtFilterDto;
        }

        for (const refiner of refiners) {
            if (!refiner.dataValue) {
                refiner.dataValue = refiner.value;
            }

            switch (refiner.location) {
                case RefinerLocation.myList:
                    filterDto.ids = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.status:
                    const statuses = new Array<GratisStatuses>();
                    refiner.dataValue.split(valueSeparator).forEach((statusId) => statuses.push(Number(statusId) as GratisStatuses));
                    filterDto.statuses = statuses;
                    break;

                case RefinerLocation.startDate:
                    filterDto.startOnOrAfterDate = refiner.dataValue;
                    break;

                case RefinerLocation.endDate:
                    filterDto.endOnOrBeforeDate = refiner.dataValue;
                    break;

                case RefinerLocation.purpose:
                    filterDto.purpose = refiner.dataValue;
                    break;

                case RefinerLocation.daysWaiting:
                    filterDto.daysWaiting = Number(refiner.dataValue);
                    break;

                default:
                    break;
            }
        }
        return filterDto;
    }
}
