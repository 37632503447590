import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStateService } from 'src/app/services/app-state.service';
import { ProjectDelineationService } from 'src/app/services/delineation-services/project-delineation.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { PleaseWaitService } from 'src/app/services/please-wait.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SyncService } from 'src/app/services/sync.service';
import { MY_DATE_FORMATS } from 'src/app/shared/constants/date-formats';
import { ProjectStateService } from '../../services/project-state-service';
import { ProjectApplicationService } from './project-services/project-application.service';
import { ProjectViewmodel } from './project.viewmodel';

@Component({
    selector: 'app-project',
    providers: [
        ProjectStateService,
        ProjectApplicationService,
        PleaseWaitService
    ],
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy {

    @HostBinding("class") class = "d-flex flex-column flex-grow-1";

    viewmodel = new ProjectViewmodel(this.projectStateService,
        this.projectApplicationService,
        this.router,
        this.appStateService,
        this.activatedRoute,
        this.overlayService,
        this.snackbarService,
        this.pleaseWaitService,
        this.projectDelineationService,
        this.syncService);
    dateFormat: string = MY_DATE_FORMATS.display.customDateInput;

    constructor(public projectStateService: ProjectStateService,
        private projectApplicationService: ProjectApplicationService,
        private router: Router,
        private appStateService: AppStateService,
        private activatedRoute: ActivatedRoute,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private pleaseWaitService: PleaseWaitService,
        private projectDelineationService: ProjectDelineationService,
        private syncService: SyncService,
    ) { }

    ngOnInit(): void {

        this.viewmodel.initialize();
    }

    async ngOnDestroy(): Promise<void> {

        this.viewmodel.unsubscribe();
    }
}
