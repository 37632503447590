import {
    CustomerTypeEnum,
    GenericLookup
} from "shield.shared";
import { Call } from "../accounts/call-master/call-services/call.service";
import { Helper } from "../helpers/helper";
import { Customer } from "./customer.entity";
import { OnlineReference } from "./online-reference.entity";
import { RetailCall } from "./retail-call.entity";
import { RmWholesaleCall } from "./rm-wholesale-call.entity";
import { WholesaleCall } from "./wholesale-call.entity";

export class CallHistoryEntry {
    id: string;
    customerId: string;
    startTime: Date;
    stopTime: Date;
    createdById: string;
    createdByName: string;
    durationMins: number;
    closingNotes: string | null;
    customerName: string | null;
    customerNumber: string | null;
    address: string | null | undefined;
    city: string | null | undefined;
    state: string | null | undefined;
    county: string | null | undefined;
    zip: string | null | undefined;
    zrt: string | null;
    customerType : GenericLookup<CustomerTypeEnum>;
    callType: string;
    hasPictures: boolean | null;
    pictures: OnlineReference[] | null;
    hasBeforePicture: boolean;
    hasAfterPicture: boolean;
    hasServerProcessed: number;
    createdUtcDateTime: Date;
    modifiedUtcDateTime: Date;
    px3RankId: string;

    constructor(call?: Call, customer?: Customer) {
        if (call) {
            this.id = call.id;
            this.customerId = call.customerId;
            this.startTime = call.startTime;
            this.stopTime = call.stopTime;
            this.createdById = call.createdUserId;
            this.createdByName = Helper.formatDisplayName(call);
            this.durationMins = call.durationMins
                ?? (call.stopTime.setSeconds(0,0) - call.startTime.setSeconds(0,0)) / (1000 * 60);
            this.closingNotes = call.closingNotes;
            this.callType = call.callType;
            this.hasBeforePicture = (call as RetailCall | RmWholesaleCall | WholesaleCall)
                ?.callPictures?.find((pic) => pic.type === 'Before') ? true : false;
            this.hasAfterPicture = (call as RetailCall | RmWholesaleCall | WholesaleCall)
                ?.callPictures?.find((pic) => pic.type === 'After') ? true : false;
            this.hasServerProcessed = call.hasServerProcessed;
            this.modifiedUtcDateTime = call.modifiedUtcDateTime;
            this.createdUtcDateTime = call.createdUtcDateTime;
            this.px3RankId = call.px3RankId;
        }

        if (customer) {
            this.zrt = customer.zrt;
            this.customerName = customer.name;
            this.customerNumber = customer.customerNumber;
            this.address = customer.businessAddress?.address1;
            this.city = customer.businessAddress?.city;
            this.state = customer.businessAddress?.state;
            this.county = customer.businessAddress?.county;
            this.zip = customer.businessAddress?.zip;
            this.customerType  = customer.customerType;
        }
    }
}
