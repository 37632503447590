<div *ngIf="viewmodel.transaction">
    <div class="row">
        <div class="col-2 margin side-margin align-left">
            {{viewmodel.transaction.callDate | date: dateTimeFormat }}
        </div>
        <div class="col">
            <div class="pull-right">
                Reprint/Send Original Receipt:
                <button appThrottleClick *ngIf="viewmodel.receipts?.length > 0|| viewmodel.receiptsEmailed" class="btn btn-sm btn-white-wide actionBtn mr-1"
                    (throttledClick)="viewmodel.print()"
                    title="Print Receipt">
                    <span class="flaticon-printer larger green"></span>
                </button>
                <button appThrottleClick *ngIf="viewmodel.receipts?.length > 0|| viewmodel.receiptsEmailed" class="btn btn-sm btn-white-wide actionBtn"
                    (throttledClick)="viewmodel.email()"
                    title="Send Receipt">
                    <span class="flaticon-send larger green"></span>
                </button>
                <div *ngIf="!viewmodel.receipts?.length" class="red">
                    Original Receipt Not Found
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-2 side-margin align-left">
            {{ viewmodel ? formatDisplayName(viewmodel.transaction) : "" }}
        </div>
        <div class="col h-center">
            <b>{{viewmodel?.customer?.name}} - Receipt # {{ viewmodel.receiptNumber }}</b>
        </div>
        <div class="col-2"></div>
    </div>
    <div class="row">
        <div class="col side-margin"></div>
        <div class="col h-center">
            Swisher License # {{ viewmodel.swisherLicenseNumber}}
        </div>
        <div class="col"></div>
    </div>
    <div class="row">
        <div class="col side-margin"></div>
        <div *ngIf="viewmodel?.retailStateOptLicense" class="col h-center">
            Retail License # {{viewmodel.retailStateOptLicense}}
        </div>
        <div class="col"></div>
    </div>
    <div class="row">
        <div class="col side-margin"></div>
        <div class="col h-center">
            {{(viewmodel?.customer?.businessAddress?.address1 ? viewmodel.customer.businessAddress.address1 + ', ' :"")
            + (viewmodel?.customer?.businessAddress?.city ? viewmodel.customer.businessAddress.city + ', ' : "")
            + (viewmodel?.customer?.businessAddress?.state ? viewmodel.customer.businessAddress.state + ' ' : "")
            + (viewmodel?.customer?.businessAddress?.zip ? viewmodel.customer.businessAddress.zip : "")}}
        </div>
        <div class="col"></div>
    </div>
    <div class="row">
        <div class="col side-margin"></div>
        <div class="col h-center">
            {{ viewmodel?.customer?.businessAddress?.county ? viewmodel.customer.businessAddress.county + " County" : "" }}
        </div>
        <div class="col"></div>
    </div>
    <div class="p-3">
        <mat-card class="inner-card overflow-auto" *ngIf="!viewmodel.isOnlyOrderLineItems" style="height: 75vh;">
            <div class="row">
                <div class="col overflow-auto" style="height: 50vh;">
<!--Cash Sales-->
                    <form [formGroup]="viewmodel.cashSalesForm">
                        <table *ngIf="
                            viewmodel.cashSalesItems?.length > 0 && viewmodel.isInitialized"
                            class="table table-bordered table-striped mobile-w-max">
                            <thead>
                                <tr>
                                    <th scope="col">Cash Sale Items<div class="header-underline"></div></th>
                                    <th scope="col">Unit QTY<div class="header-underline"></div></th>
                                    <th scope="col"># Sticks/Units<div class="header-underline"></div></th>
                                    <th scope="col">Price<div class="header-underline"></div></th>
                                    <th scope="col">Discount<div class="header-underline"></div></th>
                                    <th scope="col">Total<div class="header-underline"></div></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="
                                    let cashSaleItem of viewmodel.cashSalesItems; let i = index">
                                    <td>
                                        {{cashSaleItem?.name}}
                                    </td>
                                    <td class="counter-fixed-width">
                                        <div class="input-group counter-control">
                                            <div class="input-group-prepend">
                                                <button [disabled]="viewmodel.isReadOnly" class="btn btn-outline-secondary btn-sm" type="button"
                                                    (click)="viewmodel.incrementItemQuantity(cashSaleItem)">
                                                    <span class="material-icons">add</span>
                                                </button>
                                            </div>
                                            <input [disabled]="viewmodel.isReadOnly" type="text" #cashProductQty
                                                (mouseup)="viewmodel.select(cashProductQty)" (keyup)="
                                                viewmodel.validationSaleQuantity(
                                                    cashSaleItem
                                                )
                                            " class="form-control form-control-sm mw-7rem-form" mask="000000"
                                                aria-label="Example text with button addon"
                                                [(ngModel)]="cashSaleItem.qty" [ngModelOptions]="{standalone: true}" />
                                            <div class="input-group-append">
                                                <button [disabled]="viewmodel.isReadOnly"
                                                    class="btn btn-outline-secondary btn-sm" type="button"
                                                    (click)="viewmodel.decrementItemQuantity(cashSaleItem)">
                                                    <span class="material-icons">remove</span>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="counter-fixed-width">
                                        <div class="input-group counter-control">
                                            <div class="input-group-prepend">
                                                <button [disabled]="viewmodel.isReadOnly" class="btn btn-outline-secondary btn-sm" type="button"
                                                    (click)="viewmodel.incrementSaleUnit(
                                                        cashSaleItem
                                                    )
                                                ">
                                                    <span class="material-icons">add</span>
                                                </button>
                                            </div>
                                            <input [disabled]="viewmodel.isReadOnly" type="text" #cashProductUnit
                                                (mouseup)="viewmodel.select(cashProductUnit)"
                                                (blur)="viewmodel.validationSaleUnit(cashSaleItem)"
                                                class="form-control form-control-sm mw-7rem-form" mask="000000"
                                                [(ngModel)]=" cashSaleItem.units"
                                                [ngModelOptions]="{standalone: true}" />
                                            <div class="input-group-append">
                                                <button [disabled]="viewmodel.isReadOnly" class="btn btn-outline-secondary btn-sm" type="button"
                                                    (click)="viewmodel.decrementSaleUnit(cashSaleItem)">
                                                    <span class="material-icons">remove</span>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <mat-form-field class="price-input"
                                        [ngClass]="{'white-background' : !viewmodel.isReadOnly, 'read-only': viewmodel.isReadOnly}"
                                            appearance="outline"
                                            color="accent">
                                            <input matInput #cashProductPrice currencyMask
                                                [readonly]="viewmodel.isReadOnly"
                                                (mouseup)="viewmodel.select(cashProductPrice)"
                                                (blur)="viewmodel.validationSalePrice(cashSaleItem)"
                                                [formControlName]="'cashPrice' + i" />
                                        </mat-form-field>
                                        <mat-error
                                            *ngIf="viewmodel.cashSalesForm?.get('cashPrice' + i)?.hasError('fail')">
                                            Item price can not be less than the discount.
                                        </mat-error>
                                    </td>
                                    <td>
                                        <mat-form-field
                                            [ngClass]="{'white-background' : !viewmodel.isReadOnly && cashSaleItem.typeLabel != 'Gratis',
                                                'read-only' : viewmodel.isReadOnly || cashSaleItem.typeLabel == 'Gratis'}"
                                            class="price-input" appearance="outline" color="accent">
                                            <input matInput #cashProductDiscount currencyMask
                                                [readonly]="viewmodel.isReadOnly || cashSaleItem.typeLabel == 'Gratis'"
                                                (mouseup)="viewmodel.select(cashProductDiscount)"
                                                (keyup)="viewmodel.calculateRetailTotal()"
                                                [formControlName]="'discountPrice' + i" />
                                        </mat-form-field>
                                        <mat-error
                                            *ngIf="viewmodel.cashSalesForm?.get('discountPrice' + i)?.hasError('fail')">
                                            Item discount can not be greater than the price.
                                        </mat-error>
                                    </td>
                                    <td>
                                        {{cashSaleItem.netTotal | currency}}
                                    </td>
                                    <td>{{cashSaleItem.typeLabel}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="row">
                            <div class="col"></div>
                            <div class="col-4 h-center">
                                <h3 *ngIf="viewmodel.cashSalesItems?.length > 0"
                                    class="mr-2" [ngClass]="'text-info'">
                                    Retail Total {{viewmodel.retailTotal | currency}}
                                </h3>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <br/>
<!--Exchange Out-->
            <div class="row"  *ngIf="
            (viewmodel.exchangeInItems?.length > 0 || viewmodel.exchangeOutItems?.length > 0) && viewmodel.isInitialized">
                <div class="col overflow-auto" style="height: 50vh;">
                    <form [formGroup]="viewmodel.exchangeOutSalesForm">
                        <table class="table table-bordered table-striped mobile-w-max">
                            <thead>
                                <tr>
                                    <th scope="col">Exchange Out Items<div class="header-underline"></div></th>
                                    <th scope="col">Unit QTY<div class="header-underline"></div></th>
                                    <th scope="col"># Sticks/Units<div class="header-underline"></div></th>
                                    <th scope="col">Retail Price<div class="header-underline"></div></th>
                                    <th scope="col">Retail Total<div class="header-underline"></div></th>
                                    <th scope="col">Wholesale Price<div class="header-underline"></div></th>
                                    <th scope="col">Wholesale Cost<div class="header-underline"></div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="
                                    let exchangeOutSaleItem of viewmodel.exchangeOutItems; let i = index">
                                    <td>
                                        {{exchangeOutSaleItem?.name}}
                                    </td>
                                    <td class="counter-fixed-width">
                                        <div class="input-group counter-control">
                                            <div class="input-group-prepend">
                                                <button [disabled]="viewmodel.isReadOnly" class="btn btn-outline-secondary btn-sm" type="button"
                                                    (click)="viewmodel.incrementItemQuantity(exchangeOutSaleItem)">
                                                    <span class="material-icons">add</span>
                                                </button>
                                            </div>
                                            <input [disabled]="viewmodel.isReadOnly" type="text" #exchangeOutProductQty
                                                (mouseup)="viewmodel.select(exchangeOutProductQty)" (blur)="
                                                viewmodel.validationSaleQuantity(
                                                    exchangeOutSaleItem
                                                )
                                            " class="form-control form-control-sm mw-7rem-form" mask="000000"
                                                aria-label="Example text with button addon"
                                                [(ngModel)]="exchangeOutSaleItem.qty"
                                                [ngModelOptions]="{standalone: true}" />
                                            <div class="input-group-append">
                                                <button [disabled]="viewmodel.isReadOnly" class="btn btn-outline-secondary btn-sm" type="button"
                                                    (click)="viewmodel.decrementItemQuantity(exchangeOutSaleItem)">
                                                    <span class="material-icons">remove</span>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="counter-fixed-width">
                                        <div class="input-group counter-control">
                                            <div class="input-group-prepend">
                                                <button [disabled]="viewmodel.isReadOnly" class="btn btn-outline-secondary btn-sm" type="button"
                                                    (click)="viewmodel.incrementSaleUnit(exchangeOutSaleItem)">
                                                    <span class="material-icons">add</span>
                                                </button>
                                            </div>
                                            <input [disabled]="viewmodel.isReadOnly" type="text" #exchangeOutProductUnit
                                                (mouseup)="viewmodel.select(exchangeOutProductUnit)"
                                                (blur)="viewmodel.validationSaleUnit(exchangeOutSaleItem)"
                                                class="form-control form-control-sm mw-7rem-form" mask="000000"
                                                [(ngModel)]=" exchangeOutSaleItem.units"
                                                [ngModelOptions]="{standalone: true}" />
                                            <div class="input-group-append">
                                                <button [disabled]="viewmodel.isReadOnly" class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="viewmodel.decrementSaleUnit(exchangeOutSaleItem)">
                                                    <span class="material-icons">remove</span>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {{exchangeOutSaleItem.retailPrice | currency}}
                                    </td>
                                    <td>
                                        {{exchangeOutSaleItem.qty * exchangeOutSaleItem.retailPrice | currency}}

                                    </td>
                                    <td>
                                        <mat-form-field class="price-input" appearance="outline" color="accent"
                                            [ngClass]="{'white-background' : !viewmodel.isReadOnly, 'read-only': viewmodel.isReadOnly}">
                                            <input matInput #wholesaleOutPrice currencyMask
                                                [readonly]="viewmodel.isReadOnly"
                                                (mouseup)="viewmodel.select(wholesaleOutPrice)"
                                                (keyup)="viewmodel.calculateWholesaleTotal()"
                                                [formControlName]="'exchangeOutWholesalePrice' + i" />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        {{exchangeOutSaleItem.qty * exchangeOutSaleItem.wholesalePrice | currency}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                    <br/>
<!--Exchange in-->
                    <form [formGroup]="viewmodel.exchangeInSalesForm" style="height: 50vh;">
                        <table class="table table-bordered table-striped mobile-w-max">
                            <thead>
                                <tr>
                                    <th scope="col">Exchange In Items<div class="header-underline"></div></th>
                                    <th scope="col">Unit QTY<div class="header-underline"></div></th>
                                    <th scope="col"># Sticks/Units<div class="header-underline"></div></th>
                                    <th scope="col">Retail Price<div class="header-underline"></div></th>
                                    <th scope="col">Retail Total<div class="header-underline"></div></th>
                                    <th scope="col">Wholesale Price<div class="header-underline"></div></th>
                                    <th scope="col">Wholesale Cost<div class="header-underline"></div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="
                                    let exchangeInSaleItem of viewmodel.exchangeInItems; let i = index">
                                    <td>
                                        {{exchangeInSaleItem?.name}}
                                    </td>
                                    <td class="counter-fixed-width">
                                        <div class="input-group counter-control">
                                            <div class="input-group-prepend">
                                                <button [disabled]="viewmodel.isReadOnly" class="btn btn-outline-secondary btn-sm" type="button"
                                                    (click)="viewmodel.incrementItemQuantity(exchangeInSaleItem)">
                                                    <span class="material-icons">add</span>
                                                </button>
                                            </div>
                                            <input [disabled]="viewmodel.isReadOnly" type="text" #exchangeInProductQty
                                                (mouseup)="viewmodel.select(exchangeInProductQty)" (blur)="
                                                viewmodel.validationSaleQuantity(
                                                    exchangeInSaleItem
                                                )
                                            " class="form-control form-control-sm mw-7rem-form" mask="000000"
                                                aria-label="Example text with button addon"
                                                [(ngModel)]="exchangeInSaleItem.qty"
                                                [ngModelOptions]="{standalone: true}" />
                                            <div class="input-group-append">
                                                <button [disabled]="viewmodel.isReadOnly" class="btn btn-outline-secondary btn-sm" type="button"
                                                    (click)="viewmodel.decrementItemQuantity(exchangeInSaleItem)">
                                                    <span class="material-icons">remove</span>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="counter-fixed-width">
                                        <div class="input-group counter-control">
                                            <div class="input-group-prepend">
                                                <button [disabled]="viewmodel.isReadOnly" class="btn btn-outline-secondary btn-sm" type="button"
                                                (click)="
                                                    viewmodel.incrementSaleUnit(
                                                        exchangeInSaleItem
                                                    )
                                                ">
                                                    <span class="material-icons">add</span>
                                                </button>
                                            </div>
                                            <input [disabled]="viewmodel.isReadOnly" type="text" #exchangeOutProductUnit
                                                (mouseup)="viewmodel.select(exchangeOutProductUnit)"
                                                (blur)="viewmodel.validationSaleUnit(exchangeInSaleItem)"
                                                [ngClass]="{'white-background' : !viewmodel.isReadOnly, 'read-only': viewmodel.isReadOnly}"
                                                class="form-control form-control-sm mw-7rem-form" mask="000000"
                                                [(ngModel)]=" exchangeInSaleItem.units"
                                                [ngModelOptions]="{standalone: true}" />
                                            <div class="input-group-append">
                                                <button [disabled]="viewmodel.isReadOnly" class="btn btn-outline-secondary btn-sm" type="button"
                                                    (click)="viewmodel.decrementSaleUnit(exchangeInSaleItem)">
                                                    <span class="material-icons">remove</span>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {{exchangeInSaleItem.retailPrice | currency}}
                                    </td>
                                    <td>
                                        {{exchangeInSaleItem.qty * exchangeInSaleItem.retailPrice | currency}}

                                    </td>
                                    <td>
                                        <mat-form-field class="price-input" appearance="outline" color="accent"
                                        [ngClass]="{'white-background' : !viewmodel.isReadOnly, 'read-only': viewmodel.isReadOnly}">
                                            <input matInput #wholesaleInPrice currencyMask
                                                [readonly]="viewmodel.isReadOnly"
                                                (mouseup)="viewmodel.select(wholesaleInPrice)"
                                                (keyup)="viewmodel.calculateWholesaleTotal()"
                                                [formControlName]="'exchangeInWholesalePrice' + i" />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        {{exchangeInSaleItem.qty * exchangeInSaleItem.wholesalePrice | currency}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="row">
                            <div class="col"></div>
                            <div class="col-4 h-center">
                                <h3 *ngIf="viewmodel.exchangeOutItems?.length > 0"
                                    class="text-info mr-2">
                                    Wholesale Diff {{viewmodel.wholesaleTotal | currency}}
                                </h3>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </mat-card>
        <mat-card class="inner-card" *ngIf="viewmodel.isOnlyOrderLineItems">
            No editable items for this invoice.
        </mat-card>
    </div>
</div>
