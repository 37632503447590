import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AreaToZrtDto, AreaToZrtSyncCommand, GenericVersionResponseDto,
    SharedHelper, SyncCommandTypes, SystemInformationKeys,
    VersionResponseDto
} from "shield.shared";
import { AreaToZrt } from "src/app/entity-models/area-to-zrt.entity";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { SyncLevel } from "../sync-enums/sync-level.enum";

@Injectable()
export class AreaToZrtSyncDataHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.Initial;
    offlineRepSyncType = SyncLevel.Initial;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.areaToZrt;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing area to zrts...");

        await this.pullData();

        this.log("Done syncing area to zrts...");
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/area-to-zrts/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private entityToDto(a: AreaToZrt): AreaToZrtDto {
        const rtn = new AreaToZrtDto();

        rtn.id = a.id;
        rtn.subsidiaryId = a.subsidiaryId;
        rtn.areaId = a.areaId;
        rtn.zrt = a.zrt;
        rtn.rowversion = a.rowversion;

        return rtn;
    }

    private dtoToEntity(dto: AreaToZrtDto): AreaToZrt {
        const rtn = new AreaToZrt();

        rtn.id = dto.id;
        rtn.subsidiaryId = dto.subsidiaryId;
        rtn.areaId = dto.areaId;
        rtn.zrt = dto.zrt;
        rtn.rowversion = dto.rowversion;

        return rtn;
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("Area To Zrt data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Area To Zrt data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.areaPullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;

        try {
            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;

                const response = await this.http
                    .get<GenericVersionResponseDto<AreaToZrtDto[]>>(`/api/area-to-zrts${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                this.log(
                    `Downloaded ${thisBatchSize} area to zrts, saving to IndexedDB...`
                );

                const areas = response.values.map((v) => this.dtoToEntity(v));

                await this.dbService.areaToZrts.bulkPut(areas);
                this.log(`  Saved  ${areas.length} area to zrts.`);

                this.lastSyncVersion = response.maxVersion;
            } while (thisBatchSize > 0);

            this.log("Done saving area to zrt data.");
            this.isRunSuccessfull = true
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing area to zrts", e);
        }
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<AreaToZrtSyncCommand>(
            SyncCommandTypes.areaToZrt,
            async (message) => {
                let area = await this.dbService.areaToZrts.get(message.payload.id);

                let dto = this.entityToDto(area);
                if (dto) {
                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }
}
