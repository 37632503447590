import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    CallTypes,
    GenericResponseDto,
    PictureDto,
    PictureParamDto
} from "shield.shared";
import { Call } from "src/app/accounts/call-master/call-services/call.service";
import { Picture } from "src/app/entity-models/picture.entity";
import { PictureConverterService } from "../converter-services/picture-conveter.service";

@Injectable()
export class PictureOnlineService {

    constructor(
        private http: HttpClient
    ) { }

    async getCallPicturesByCall(call: Call): Promise<GenericResponseDto<Picture[]>> {

        const rtn = new GenericResponseDto<Picture[]>();
        rtn.values = new Array<Picture>();

        if (call.callType === CallTypes.retail
            || call.callType === CallTypes.wholesale
            || call.callType === CallTypes.rmWholesale) {
            for (const pic of call.callPictures) {
                const result = await this.http
                    .get<GenericResponseDto<PictureDto>>(`/api/calls/${call.id}/picture/${pic.id}`).toPromise();

                if (result?.isError) {
                    rtn.isError = true;
                    rtn.message = rtn.message + " " + result.message;
                    return rtn;
                }
                if (result?.values) {
                    rtn.values.push(await PictureConverterService.PictureDtoToPicture(result.values));
                }
            }
        }

        rtn.count = rtn.values.length;
        rtn.isError = false;

        return rtn;
    }

    async getPicture(params: PictureParamDto): Promise<GenericResponseDto<Picture>> {

        const result = await this.http
            .get<GenericResponseDto<PictureDto>>(`/api/calls/${params.callId}/picture/${params.pictureId}`).toPromise();

        const rtn = new GenericResponseDto<Picture>();
        if (result) {
            rtn.count = result.count;
            rtn.affected = result.affected;
            rtn.counts = result.counts;
            rtn.id = result.id;
            rtn.isError = result.isError;
            rtn.message = result.message;
            rtn.values = await PictureConverterService.PictureDtoToPicture(result.values);
        }

        return rtn;
    }
}
