import { GratisManualTransactionDto, newSequentialId } from "shield.shared";

import { Employee } from "src/app/entity-models/employee.entity";
import { GratisAssignedTransaction } from "src/app/entity-models/gratis-assigned-transactions.entity";
import { GratisManualTransaction } from "src/app/entity-models/gratis-manaul-transaction.entity";

export class GratisManualTransactionConverterService {
    static entityToDto(
        entity: GratisManualTransaction
    ): GratisManualTransactionDto {
        const dto = new GratisManualTransactionDto();

        dto.date = entity.date ? entity.date.toISOString() : null;
        dto.reason = entity.reason;
        dto.total = entity.total;
        return dto;
    }

    static createManualGratis(
        manual: GratisManualTransaction,
        employee: Employee
    ): GratisAssignedTransaction {
        const t = new GratisAssignedTransaction();

        t.id = newSequentialId();
        t.callDate = manual.date ?? new Date();
        t.manualGratisId = newSequentialId();
        t.description = "Manual Gratis; " + manual.reason;
        t.gratisTotal = manual.total;
        t.createdUtcDateTime = new Date();
        t.createdUserId = employee.id;
        t.createdUserZrt = employee.zrt;
        t.createdUserFullName = employee.fullName;
        return t;
    }
}
