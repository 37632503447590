import { AddressFilterDto, ZrtFilterDto } from ".";
import { ContractPaymentMethods, ContractPaymentStatuses, ContractPaymentTypes, ContractTemplateTypes } from "../lookup";

export class RetailContractsFilterDto {

    /*
     * My Searches
     */
    // Usage: Get only accounts with given ids
    ids?: string[];

    /*
    * Header and Requested information section
    */

    // Usage: Contains ZRT properties
    zrtFilterDto?: ZrtFilterDto;

    // Usage: Used for filter accounts on the account list page
    // Possible data: account name, account GUID/Id or account number
    account?: string[];

    // Usage: Contains address properties for the business address
    addressFilterDto?: AddressFilterDto;

    /*
     * Date Information
     */

    // Usage: Get data based on call/date
    callOnOrAfterDate?: string;
    callOnOrBeforeDate?: string;

    /*
     * Contract Information
     */

    // Usage: Get based on contract number
    contractNumber?: string[];

    // Usage: Get based on contract types
    contractTypes?: ContractTemplateTypes[];

    // Usage: Get based on last payment status
    lastCallStatus?: ContractPaymentStatuses[];

    // Usage: Get based on contract signature date
    signedOnOrAfterDate?: string;
    signedOnOrBeforeDate?: string;

    // Usage: Get based on last payment date
    paymentOnOrAfterDate?: string;
    paymentOnOrBeforeDate?: string;

    // Usage: Get based on type of last payment
    paymentTypes?: ContractPaymentTypes[];

    // Usage: Get based on method of last payment
    paymentMethods?: ContractPaymentMethods[];

    // Usage: Get based on contract level
    programLevel?: number;

    /*
     * Attributes
     */

    // Usage: Get based on store types
    storeTypes?: string[];

    //Usage: Get based on the selected px3 rank
    px3Rank?: string[];

    // Usage: Get if the associated customer is an Msa
    isMsa?: boolean;
}
