<div class="wrapper stepper d-flex flex-column flex-grow-1">
        <app-project-stepper #stepper class="d-flex flex-column flex-grow-1">
            <cdk-step label="Definition">
                <app-project-definition></app-project-definition>
            </cdk-step>
            <cdk-step label="Stores">
                <app-project-stores class="d-flex flex-column flex-grow-1"></app-project-stores>
            </cdk-step>
            <cdk-step label="People">
                <app-project-people></app-project-people>
            </cdk-step>
            <cdk-step label="Assignments">
                <app-project-assignments class="d-flex flex-column flex-grow-1"></app-project-assignments>
            </cdk-step>
            <cdk-step label="Products">
                <app-project-products></app-project-products>
            </cdk-step>>
            <cdk-step label="Activities &amp; Surveys">
                <app-project-activities-and-surveys></app-project-activities-and-surveys>
            </cdk-step>
            <cdk-step label="Summary">
                <app-project-summary class="d-flex flex-column flex-grow-1" [project]="" [inStepper]="true"></app-project-summary>
            </cdk-step>
        </app-project-stepper>
</div>
