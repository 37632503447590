<div class="basic-modal" [style]="{
    height: 'auto',
    width: 'auto'
    }"
    cdkDrag
    cdkDragBoundary=".cdk-overlay-container"
    >
        <div cdkDragHandle>
            <div class="modal-header">
                Route Stops - {{ vm.route.name }} ({{ vm.route.date | date: dateFormat }})
                <mat-icon class="close-icon" (click)="close()">close</mat-icon>
            </div>
        </div>
        <div class="modal-body">

        <table class="table table-bordered table-striped table-hover">
            <thead>
                <tr>
                    <th class="last-call-column-width text-align-center" scope="col">
                        Last Call
                        <div class="header-underline"></div>
                    </th>
                    <th class="avail-call-column-width text-align-center" scope="col">
                        Avail
                        <div class="header-underline"></div>
                    </th>
                    <th scope="col">
                        Name
                        <div class="header-underline"></div>
                    </th>
                    <th scope="col">
                        Address
                        <div class="header-underline"></div>
                    </th>
                    <th class="city-call-column-width" scope="col">
                        City
                        <div class="header-underline"></div>
                    </th>
                    <th class="county-call-column-width" scope="col">
                        County
                        <div class="header-underline"></div>
                    </th>
                    <th class="state-column-width text-align-center" scope="col">
                        State
                        <div class="header-underline"></div>
                    </th>
                    <th class="zip-column-width text-align-center" scope="col">
                        Zip
                        <div class="header-underline"></div>
                    </th>
                    <th class="avail-call-column-width text-align-center" scope="col">
                        ZRT
                        <div class="header-underline"></div>
                    </th>
                    <th scope="col">
                        Type
                        <div class="header-underline"></div>
                    </th>
                    <th scope="col">
                        Ind Volume
                        <div class="header-underline"></div>
                    </th>
                    <th class="city-call-column-width" scope="col">
                        Share Volume
                        <div class="header-underline"></div>
                    </th>
                    <th class="county-call-column-width" scope="col">
                        Share Percentage
                        <div class="header-underline"></div>
                    </th>
                    <th class="state-column-width text-align-center" scope="col">
                        MSA Store
                        <div class="header-underline"></div>
                    </th>
                    <th class="zip-column-width text-align-center" scope="col">
                        Chain Name
                        <div class="header-underline"></div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let stop of vm.stops">
                    <td class="text-align-center">
                        {{ stop.lastCall | date: dateFormat }}
                    </td>
                    <td class="text-align-center">
                        {{ stop.avail }}
                    </td>
                    <td class="text-align-center">
                        {{ stop.name }}
                    </td>
                    <td class="text-align-center">
                        {{ stop.address }}
                    </td>
                    <td class="text-align-center">
                        {{ stop.city }}
                    </td>
                    <td class="text-align-center">
                        {{ stop.county }}
                    </td>
                    <td class="text-align-center">
                        {{ stop.state }}
                    </td>
                    <td class="text-align-center">
                        {{ stop.zip }}
                    </td>
                    <td class="text-align-center">
                        {{ stop.zrt }}
                    </td>
                    <td class="text-align-center">
                        {{ stop.customerType?.name }}
                    </td>
                    <td class="text-align-center">
                        {{ stop.indVolume | number }}
                    </td>
                    <td class="text-align-center">
                        {{ stop.shareVolume | number }}
                    </td>
                    <td class="text-align-center">
                        {{ stop.sharePercentage | percent }}
                    </td>
                    <td class="text-align-center">
                        {{ stop.msaStore }}
                    </td>
                    <td class="text-align-center">
                        {{ stop.chainName }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
