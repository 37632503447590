import { ElementRef } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidatorFn
} from "@angular/forms";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { MatDrawerMode, MatSidenav } from "@angular/material/sidenav";
import { MatSortHeader } from "@angular/material/sort";
import * as moment from "moment";
import { Moment } from "moment";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { BehaviorSubject, Subscription } from "rxjs";
import {
    AccountsListColumns,
    FilterSortDto,
    GenericResponseDto,
    newSequentialId,
    ProjectCustomerColumns,
    RefinerLocation,
    ResponseCountKey,
    SharedHelper,
    SortDirection,
    SystemInformationKeys,
    valueSeparator
} from "shield.shared";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterLocation } from "src/app/enums/filter-location";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { ProjectStateService } from "src/app/services/project-state-service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { GridDataTypes } from "src/app/shared/enums/grid-data-types.enum";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { ColumnVisabilityButtonComponent } from "src/app/shared/page-header/buttons/column-visability-button/column-visability-button.component";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { ColumnSelector } from "src/app/shared/viewmodels/column-selector.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { ProjectApplicationService } from "../../project-services/project-application.service";
import { ProjectStoreGridViewmodel } from "./project-store-grid.viewmodel";
import { ProjectCustomer } from "src/app/entity-models/project-customer.entity";
import { ActivatedRoute } from "@angular/router";
import { ProjectFilterService } from "src/app/details/project-list/project-filter.service";
import { Customer } from "src/app/entity-models/customer.entity";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { SystemInformationDelineationService } from "src/app/services/delineation-services/system-information-delineation.service";
import { Project } from "src/app/entity-models/project.entity";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { BaseFilterMapService } from "src/app/services/filter-map-services/base-filter-map.service";
import { FilterService } from "src/app/services/filter.service";
import { ProjectStoresRefinerService } from "./project-stores-refiner.service";
import { ProjectStoresZrtFilterService } from "./project-stores-zrt-filter.service";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { OverlayService } from "src/app/services/overlay.service";
import { debounceTime, map, skip } from "rxjs/operators";
import { MyListCommands } from "src/app/enums/my-list-commands";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";

export class ProjectStoresViewmodel {
    activeRefiners: Refiner[] = [];
    columnDef: ColumnDef[];
    columnsToDisplay: string[];
    detailHeight = 48;
    fieldNameAssigned = "Assigned";
    fieldNameLastCall = "Last Call";
    fieldNameStoreName = "Store Name";
    fieldNameAddress = "Address";
    fieldNameCity = "City";
    fieldNameState = "State";
    fieldNameZip = "Zip";
    fieldNameStoreType = "Store Type";
    fieldNameIndustryVolume = "Industry Volume";
    fieldNameZrt = "Zrt";
    fieldNameSSPercent = "SS %";
    fieldNameSwisherVolume = "SS Volume";
    fieldNameMsa = "MSA";
    fieldNamePhone = "Phone";
    fieldNameChain = "Chain";
    refinerService: ProjectStoresRefinerService;
    headerName = "Project Stores";
    headerButtons: HeaderButtonComponent[] = new Array<HeaderButtonComponent>();
    selectedColumnVisibilityComunicator: string[];
    total = 0;
    availableColumns: ColumnSelector[];
    drawer: MatSidenav;
    drawerMode: MatDrawerMode = "side";
    screenHeight: number = 0;
    grid: GridComponent;
    header: PageHeaderComponent;
    topGapDistance = 0;
    gridhieghtOffsetDeduction = 0;
    baseGridYOffset = 0;
    gridHeight = "60vh";
    isGapSet = false;
    initialGapOffset = 0;
    itemsRendedInViewPort = 13;
    filters: FilterAndParams[] = [];
    filterLocation = FilterLocation.customerList;  // shares MyList and Special Coverage with the default customer list
    isFixedLayout = false;
    isSearchButtonDisabled = false;
    dateForm: FormGroup;
    startMaxDate: moment.Moment = moment();
    startMinDate: moment.Moment = moment().subtract(100, "years");
    endMaxDate: moment.Moment = moment();
    endMinDate: moment.Moment = moment().subtract(100, "years");
    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    currentRefinerMap: Map<RefinerLocation, string> = new Map();
    pleaseWaitService: PleaseWaitService;
    projectDelineationService: ProjectDelineationService;
    projectStateService: ProjectStateService;
    projectApplicationService: ProjectApplicationService;
    snackbarService: SnackbarService;
    systemInformationDelineationService: SystemInformationDelineationService;
    shouldWait$ = new BehaviorSubject<boolean>(false);
    isSearching = false;
    isSearchStale = false;
    pageIndex = 0;
    pageSize = 50;
    gridData = new Array<GridData>();
    previousRefinerMap: Map<RefinerLocation, string> = new Map();
    accountInput: string;
    streetInput: string;
    cityInput: string;
    zipInput: string;
    refinerInputChangeSubscription: Subscription;
    refinerServiceSubscription: Subscription;
    gridDataChangeSubscription: Subscription;
    projectSubscription: Subscription;
    selectedChainSubscription: Subscription;
    zrtSelectionSubscription: Subscription;
    initialized = false;
    assigned = "assigned";
    unassigned = "unassigned";
    all = "all";
    selectedAssignmentValue = this.all;
    filterSorts = new Array<FilterSortDto<ProjectCustomerColumns>>();
    headerContainer: ElementRef;
    gridContainer: ElementRef;
    assignCustomersBatchSize = 10000;
    unassignCustomersBatchSize = 10000;
    totalCount = 0;
    assignedCount = 0;
    unassignedCount = 0;
    activatedRoute: ActivatedRoute;
    projectFilterService: ProjectFilterService;
    accountOwnershipDelineationService: AccountOwnershipDelineationService;
    customerDelineationService: CustomerDelineationService;
    employeeDelineationService: EmployeeDelineationService;
    zrtService: ProjectStoresZrtFilterService;
    gridInitialized = false;
    dataLoaded = false;
    filterService: FilterService;
    confirmationOverlayRef: SwisherOverlayRef<ConfirmationDialogViewmodel, ConfirmationDialogComponent>;
    myListSubscription: Subscription;
    overlayService: OverlayService;
    shouldResetGrid = false;
    specialCoverageSubscription: Subscription;

    private formBuilder: FormBuilder;

    readonly sortFunction = (columnDef: ColumnDef) => {
        if (this.grid) {
            const filterSorts = new Array<
                FilterSortDto<ProjectCustomerColumns>
            >();
            this.grid.sort.sortables.forEach((item) => {
                let sortDirection: SortDirection;
                switch ((item as MatSortHeader)._sort.direction) {
                    case "asc":
                        sortDirection = SortDirection.ascending;
                        break;
                    case "desc":
                        sortDirection = SortDirection.descending;
                        break;
                    default:
                        sortDirection = SortDirection.none;
                        break;
                }
                const sortDto = new FilterSortDto<ProjectCustomerColumns>();
                sortDto.direction = sortDirection;
                const headerName = this.columnDef.find(
                    (cd) => cd.dataPropertyName === item.id
                ).headerName;
                if (
                    headerName === columnDef.headerName &&
                    sortDirection != SortDirection.none
                ) {
                    sortDto.column = headerName as ProjectCustomerColumns;

                    filterSorts.push(sortDto);
                }
            });
            this.filterSorts = filterSorts;
            void this.getBatch(0, true);
        }
    }

    constructor(
        projectStoresRefinerService: ProjectStoresRefinerService,
        formBuilder: FormBuilder,
        pleaseWaitService: PleaseWaitService,
        projectDelineationService: ProjectDelineationService,
        snackbarService: SnackbarService,
        projectStateService: ProjectStateService,
        projectApplicationService: ProjectApplicationService,
        systemInformationDelineationService: SystemInformationDelineationService,
        activatedRoute: ActivatedRoute,
        projectFilterService: ProjectFilterService,
        customerDelineationService: CustomerDelineationService,
        employeeDelineationService: EmployeeDelineationService,
        zrtService: ProjectStoresZrtFilterService,
        accountOwnershipDelineationService: AccountOwnershipDelineationService,
        filterService: FilterService,
        overlayService: OverlayService,
        private px3RankService: Px3DelineationService,
        private callsService: CallDelineationService
    ) {
        this.refinerService = projectStoresRefinerService;
        this.pleaseWaitService = pleaseWaitService;
        this.projectDelineationService = projectDelineationService;
        this.snackbarService = snackbarService;
        this.projectStateService = projectStateService;
        this.projectApplicationService = projectApplicationService;
        this.systemInformationDelineationService = systemInformationDelineationService;
        this.activatedRoute = activatedRoute;
        this.projectFilterService = projectFilterService;
        this.customerDelineationService = customerDelineationService;
        this.employeeDelineationService = employeeDelineationService;
        this.zrtService = zrtService;
        this.accountOwnershipDelineationService = accountOwnershipDelineationService;
        this.filterService = filterService;
        this.overlayService = overlayService;

        const columnVisabilityComponent = new ColumnVisabilityButtonComponent();
        this.headerButtons.push(columnVisabilityComponent);

        this.formBuilder = formBuilder;
        this.dateForm = this.formBuilder.group({
            endDate: ["", [this.lessThanStartDate()]],
            startDate: ["", [this.greaterThanEndDate()]]
        });
    }

    //public methods
    unsubscribe(): void {
        if (
            this.refinerServiceSubscription &&
            !this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription.unsubscribe();
        }
        if (
            this.refinerInputChangeSubscription &&
            !this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription.unsubscribe();
        }
        if (
            this.gridDataChangeSubscription &&
            !this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription.unsubscribe();
        }
        if (this.projectSubscription && !this.projectSubscription.closed) {
            this.projectSubscription.unsubscribe();
        }
        if (this.myListSubscription && !this.myListSubscription.closed) {
            this.myListSubscription.unsubscribe();
        }
        if (this.specialCoverageSubscription && !this.specialCoverageSubscription.closed) {
            this.specialCoverageSubscription.unsubscribe();
        }
    }

    async initialize(
        grid: GridComponent,
        header: PageHeaderComponent,
        drawer: MatSidenav,
        headerContainer: ElementRef,
        gridContainer: ElementRef
    ): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        this.grid = grid;
        this.header = header;
        this.drawer = drawer;
        this.headerContainer = headerContainer;
        this.gridContainer = gridContainer;

        const assignedResponse = await this.systemInformationDelineationService.getByKey(
            SystemInformationKeys.projectCustomersAssignBatchSize
        );
        if (!assignedResponse) {
            this.shouldWait$.next(false);
            return;
        }

        const assigned = assignedResponse.values;
        if (assigned) {
            this.assignCustomersBatchSize = SharedHelper.parseInt(
                assigned.value
            );
        }

        const unassignedResponse = await this.systemInformationDelineationService.getByKey(
            SystemInformationKeys.projectCustomersUnassignBatchSize
        );
        if (!unassignedResponse) {
            this.shouldWait$.next(false);
            return;
        }

        const unassigned = unassignedResponse.values;
        if (unassigned) {
            this.unassignCustomersBatchSize = SharedHelper.parseInt(
                unassigned.value
            );
        }

        void this.setActivitiesFilter();

        if (
            !this.selectedChainSubscription ||
            this.selectedChainSubscription.closed
        ) {
            this.selectedChainSubscription = this.activatedRoute.params.subscribe(
                async (params) => {
                    const chainHqCode = params.chainHqCode as string;
                    if (chainHqCode) {
                        const refiner = new Refiner();
                        refiner.location = RefinerLocation.chains;
                        refiner.dtoObjectName = "Customers";
                        refiner.dtoPropertyName = "TdOwnerCode";
                        refiner.value = chainHqCode;
                        refiner.dataValue = chainHqCode;
                        this.refinerService.addRefiners(refiner);
                    }

                    const wholesalerId = params.wholesalerId as string;
                    if (wholesalerId) {
                        const refiner = new Refiner();
                        refiner.location = RefinerLocation.wholesalers;
                        refiner.dtoObjectName = "WholesalerCustomerMembers";
                        refiner.dtoPropertyName = "WholesalerId";
                        refiner.value = "From Profile";
                        refiner.dataValue = wholesalerId;
                        this.refinerService.addRefiners(refiner);
                    }

                    const retailId = params.retailId as string;

                    if (retailId) {
                        const response = await this.projectDelineationService.setProjectStoreAsAssigned(
                            this.projectStateService.project.id,
                            retailId,
                            this.refinerService.refiners
                        );
                        if (!response?.isError && response?.values) {
                            this.projectStateService.project.projectCustomers.push(
                                response.values
                            );
                            this.setCounts(response);
                        }
                    }
                }
            );
        }

        if (
            !this.refinerServiceSubscription ||
            this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription = this.refinerService.refiners$.subscribe(
                () => {
                    this.onRefinersChange();
                }
            );
        }

        if (
            !this.refinerInputChangeSubscription ||
            this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription = this.refinerService.refinerInputChange$.subscribe(
                (refiner: Refiner) => {
                    this.onInputChange(refiner);
                }
            );
        }

        if (
            !this.gridDataChangeSubscription ||
            this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription = this.grid.dataSourceChange.subscribe(
                (dc) => {
                    if (dc) {
                        this.shouldWait$.next(true);
                    }
                }
            );
        }

        if (!this.projectSubscription || this.projectSubscription.closed) {
            this.projectSubscription = this.projectStateService.observableProject.subscribe(
                (project) => {
                    if (project && !this.initialized) {
                        this.initialized = true;
                        this.getBatch(0, true);
                    }
                }
            );
        }

        if (
            !this.zrtSelectionSubscription ||
            this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription = this.zrtService.observableSelectedZrtsHandleRefiners(this.refinerService).subscribe();
        }

        if (!this.myListSubscription ||
            this.myListSubscription.closed
        ) {
            this.myListSubscription = this.filterService.myListCommandObservable.pipe(skip(1)).subscribe(async (command) => {
                switch (command) {
                    case MyListCommands.toggleOn:
                    case MyListCommands.toggleOff:
                        this.getBatch(0, true);
                        break;
                    case MyListCommands.push:
                        this.getMyListIds();
                        break;
                    default:
                        break;

                }
            });
        }

        if (!this.specialCoverageSubscription ||
            this.specialCoverageSubscription.closed
        ) {
            this.specialCoverageSubscription = this.filterService.specialCoverageObservable.pipe(
                skip(1),
                debounceTime(250)
            ).subscribe(() => {
                this.getBatch(0, true);
            });
        }

        const curIncentivePeriod = await this.px3RankService.getCurrentIncentivePeriod();

        setTimeout(() => {
            this.columnDef = [
                {
                    headerName: "",
                    dataPropertyName: "isAssigned",
                    isAvailable: true,
                    isSelected: true,
                    isSelectable: true,
                    columnClass: "w-25"
                },
                {
                    headerName: this.fieldNameAssigned,
                    dataPropertyName: "assigned",
                    isAvailable: true,
                    isSelected: true
                },
                {
                    headerName: this.fieldNameLastCall,
                    dataPropertyName: "lastCall",
                    isAvailable: true,
                    isSelected: true,
                    dataType: GridDataTypes.date
                },
                {
                    headerName: this.fieldNameStoreName,
                    dataPropertyName: "storeName",
                    isAvailable: true,
                    isSelected: true
                },
                {
                    headerName: this.fieldNameAddress,
                    dataPropertyName: "address",
                    isAvailable: true,
                    isSelected: true
                },
                {
                    headerName: this.fieldNameCity,
                    dataPropertyName: "city",
                    isAvailable: true,
                    isSelected: true
                },
                {
                    headerName: this.fieldNameState,
                    dataPropertyName: "state",
                    isAvailable: true,
                    isSelected: true
                },
                {
                    headerName: this.fieldNameZip,
                    dataPropertyName: "zip",
                    isAvailable: true,
                    isSelected: true
                },
                {
                    headerName: 'Px3 Rank',
                    dataPropertyName: "px3Rank",
                    isAvailable: true,
                    isSelected: true,
                },
                {
                    headerName: AccountsListColumns.callsMade.replace(
                        "%s",
                        `${moment(curIncentivePeriod?.startDate).format('MM/DD')} - ${moment(curIncentivePeriod?.endDate).format('MM/DD')}`,
                    ),
                    dataPropertyName: "callsMade",
                    isAvailable: curIncentivePeriod != null,
                    isSelected: curIncentivePeriod != null,
                },
                {
                    headerName: this.fieldNameStoreType,
                    dataPropertyName: "customerTypeName",
                    isAvailable: true,
                    isSelected: true
                },
                {
                    headerName: this.fieldNameIndustryVolume,
                    dataPropertyName: "industryVolume",
                    isAvailable: true,
                    isSelected: true,
                    dataType: GridDataTypes.number
                },
                {
                    headerName: this.fieldNameSwisherVolume,
                    dataPropertyName: "swisherVolume",
                    isAvailable: true,
                    isSelected: true,
                    dataType: GridDataTypes.number
                },
                {
                    headerName: this.fieldNameZrt,
                    dataPropertyName: "zrt",
                    isAvailable: true,
                    isSelected: false
                },
                {
                    headerName: this.fieldNameSSPercent,
                    dataPropertyName: "ssPercent",
                    isAvailable: true,
                    isSelected: false,
                    dataType: GridDataTypes.percent
                },
                {
                    headerName: this.fieldNameMsa,
                    dataPropertyName: "msa",
                    isAvailable: true,
                    isSelected: false
                },
                {
                    headerName: this.fieldNamePhone,
                    dataPropertyName: "phone",
                    isAvailable: true,
                    isSelected: false
                },
                {
                    headerName: this.fieldNameChain,
                    dataPropertyName: "chain",
                    isAvailable: true,
                    isSelected: false
                }
            ];

            const availableColumns = new Array<ColumnSelector>();

            this.columnsToDisplay = this.columnDef
                .filter((cd) => {
                    if (cd.isAvailable) {
                        const column: ColumnSelector = {
                            name: cd.headerName,
                            isSelected: cd.isSelected,
                            columns: null
                        };
                        availableColumns.push(column);
                    }
                })
                .map((cd) => cd.headerName);
            this.availableColumns = availableColumns;

            this.gridInitialized = true;
            if (!this.dataLoaded) {
                this.getBatch(0, true);
            }
        }, 0);

        this.shouldWait$.next(false);
    }

    onRefinersChange() {
        if (this.header) {
            if (this.refinerService.refiners.length === 0) {
                this.header.expanded = false;
            } else {
                this.header.expanded = true;
            }
        }

        this.setRefinerInput();

        // Search on removal
        if (
            !this.refinerService.refiners.length
        ) {
            this.zrtService.selectedZrts = [];
            const shouldAddInputs = false;
            this.search(shouldAddInputs);
        }
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            this.isSearchStale = true;
            switch (refiner.location) {
                case RefinerLocation.account:
                    this.accountInput = refiner.value;
                    break;
                case RefinerLocation.street:
                    this.streetInput = refiner.value;
                    break;
                case RefinerLocation.city:
                    this.cityInput = refiner.value;
                    break;
                case RefinerLocation.zipCodes:
                    this.zipInput = refiner.value;
                    break;
                case RefinerLocation.zrtByArea:
                case RefinerLocation.zrtByEmployee:
                    this.zrtService.applyRefiner(refiner);
                    break;
                default:
                    break;
            }

            if (this.header) {
                if (this.refinerService.refiners.length === 0) {
                    this.header.expanded = false;
                } else {
                    this.header.expanded = true;
                }
            }

            if (
                this.projectStateService.project &&
                ((refiner.shouldSearchWhenCleared &&
                    !refiner.value) ||
                    !this.initialized)
            ) {
                this.initialized = true;
                void this.getBatch(0);
            }
        }

        if (this.header) {
            if (this.refinerService.refiners.length === 0) {
                this.header.expanded = false;
            } else {
                this.header.expanded = true;
            }
        }
    }

    setAssignment(index: number): void {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        setTimeout(async () => {
            let result: GenericResponseDto<ProjectCustomer>;
            const refiners = this.refinerService.refiners.slice();
            this.setTabRefiners(refiners);

            if (index > -1) {
                const found = this.dataSource.data[index]?.data;
                if (found) {
                    found.isAssigned = found.isSelected;
                    found.assigned = found.isAssigned ? "Assigned" : "";

                    if (found.isAssigned) {
                        result = await this.projectDelineationService.setProjectStoreAsAssigned(
                            this.projectStateService.project.id,
                            found.customerId,
                            this.refinerService.refiners
                        );
                    } else {
                        result = await this.projectDelineationService.setProjectStoreAsUnassigned(
                            this.projectStateService.project.id,
                            found.customerId,
                            this.refinerService.refiners
                        );
                    }

                    if (result) {
                        void this.updateLocalProjectCustomers();

                        this.setCounts(result);

                        if (this.selectedAssignmentValue === this.unassigned) {
                            this.dataSource.data = this.dataSource.data
                                .filter((row) => !row.data.isAssigned)
                                .map((row) => {
                                    row.index--;
                                    return row;
                                });
                        }

                        if (this.selectedAssignmentValue === this.assigned) {
                            this.dataSource.data = this.dataSource.data
                                .filter((row) => row.data.isAssigned)
                                .map((row) => {
                                    row.index--;
                                    return row;
                                });
                        }
                    }

                    this.shouldWait$.next(false);
                }
            } else if (index === -1) {
                const isAssigned = this.grid.allColumnsChecked;

                const batchSize = isAssigned
                    ? this.assignCustomersBatchSize
                    : this.unassignCustomersBatchSize;
                if (
                    (this.selectedAssignmentValue === this.assigned &&
                        this.assignedCount > batchSize) ||
                    (this.selectedAssignmentValue === this.unassigned &&
                        this.unassignedCount > batchSize) ||
                    (this.selectedAssignmentValue === this.all &&
                        this.header.entryCount > batchSize)
                ) {
                    this.snackbarService.showWarning(
                        `Please restrict your filtered results to ${batchSize} to perform this operation.`
                    );
                    void this.getBatch(this.pageIndex, true);
                    return;
                }

                let result: GenericResponseDto<Project>;
                if (isAssigned) {
                    result = await this.projectDelineationService.setProjectStoresAsAssigned(
                        this.projectStateService.project.id,
                        this.refinerService.refiners
                    );
                    if (this.selectedAssignmentValue === this.unassigned) {
                        //clear grid
                        this.gridData = new Array<GridData>();
                    }
                } else {
                    result = await this.projectDelineationService.setProjectStoresAsUnassigned(
                        this.projectStateService.project.id,
                        this.refinerService.refiners
                    );
                    if (this.selectedAssignmentValue === this.assigned) {
                        //clear grid
                        this.gridData = new Array<GridData>();
                    }
                }

                this.gridData.forEach(v => {
                    v.data.assigned = isAssigned ? "Assigned" : "";
                });

                this.dataSource = new TableVirtualScrollDataSource(
                    this.gridData
                );

                if (result.isError) {
                    this.snackbarService.showError(result.message);
                } else {
                    this.setCounts(result);
                    void this.updateLocalProjectCustomers();
                }
                this.shouldWait$.next(false);
            }

        }, 0);
    }

    onAddAccountRefiner(event?: KeyboardEvent): void {
        if (
            this.accountInput &&
            this.refinerService.getRefinerByLocation(
                RefinerLocation.account
            )?.value !== this.accountInput
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.account,
                this.accountInput
            );
        }

        if (this.accountInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.account,
                this.accountInput,
                "name"
            );
        }

        if (!this.accountInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.account
            );
        }
    }

    onAddStreetRefiner(event?: KeyboardEvent): void {
        if (
            this.streetInput &&
            this.refinerService.getRefinerByLocation(
                RefinerLocation.street
            )?.value !== this.streetInput
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.street,
                this.streetInput
            );
        }

        if (this.streetInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.street,
                this.streetInput,
                "street"
            );
        }

        if (!this.streetInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.street
            );
        }
    }

    onAddCityRefiner(event?: KeyboardEvent): void {
        if (
            this.cityInput &&
            this.refinerService.getRefinerByLocation(
                RefinerLocation.city
            )?.value !== this.cityInput
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.city,
                this.cityInput
            );
        }

        if (this.cityInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.city,
                this.cityInput,
                "city"
            );
        }

        if (!this.cityInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.city
            );
        }
    }

    onAddZipRefiner(event?: KeyboardEvent): void {
        if (
            this.zipInput &&
            this.refinerService.getRefinerByLocation(
                RefinerLocation.zipCodes
            )?.value !== this.zipInput
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.zipCodes,
                this.zipInput
            );
        }

        if (this.zipInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.zipCodes,
                this.zipInput,
                "zipCodes"
            );
        }

        if (!this.zipInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.zipCodes
            );
        }
    }

    showFilters(): void {
        this.drawer.toggle();
        this.isGapSet = false;
    }

    calculateGap(): void {
        if (this.headerContainer && this.gridContainer) {
            this.isGapSet = true;
            setTimeout(() => {
                // Page I referenced to help visulize what is going on
                //https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
                if (!this.initialGapOffset) {
                    // Get the initial distance from the bottom of the headerContainer element to the top of the window
                    // Initail load is when the header container bottom is the closest to the top of the screen
                    // This is where we decided to set our Grid VH
                    // As the Header expandes we will take the new larger distance (From the bottom of the header to the top of the window)
                    // and subtract our initial distance to know how much our realestate has srunk
                    this.initialGapOffset = this.headerContainer?.nativeElement?.getBoundingClientRect()?.bottom;
                }

                // Calculate the distance from the bottom of the element to the top of the window. This tells the Filters in our case how low to hang
                this.topGapDistance =
                    this.headerContainer?.nativeElement?.getBoundingClientRect()
                        ?.bottom ?? 0;

                //get the distance from the top of the gridContainer element to the top of the window, this is how low the grid hangs
                this.gridhieghtOffsetDeduction =
                    window.pageYOffset +
                    this.gridContainer?.nativeElement?.getBoundingClientRect()
                        ?.top ?? 0;

                // Xvh * Ypixels / 100 = the number of pixels per vh(Vertival Height) - grid size deduction to shrink it by since it
                // can no longer fit an Xvh grid
                // this.gridHeight =
                //     65 * (this.screenHeight / 100) - (this.gridhieghtOffsetDeduction - this.initialGapOffset) +
                //     "px";
            }, 0);
        }
    }

    onButtonAction(value: any): void {
        switch (value) {
            default:
                break;
        }
    }

    onButtonClickEvent(value: HeaderButtonComponent) {
        if (value.onClick) {
            value.onClick();
        }
    }

    headerExpansionChanged(): void {
        this.isGapSet = false;
    }

    setColumnVisibilityCommunicator(event: string[]) {
        this.selectedColumnVisibilityComunicator = event;
    }

    setRefinerInput(): void {
        if (this.refinerService.refiners.length === 0) {
            this.accountInput = null;
            this.streetInput = null;
            this.cityInput = null;
            this.zipInput = null;
        }
    }

    checkSearchError(): void {
        if (this.dateForm) {
            if (
                this.dateForm
                    .get("endDate")
                    .hasError("dateLessThanStartDate") ||
                this.dateForm
                    .get("startDate")
                    .hasError("dateGreaterThanEndDate")
            ) {
                this.isSearchButtonDisabled = true;
            } else {
                this.isSearchButtonDisabled = false;
            }
        }
    }

    lessThanStartDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            if (control.value) {
                const endDate = (control.value as Moment)
                    .startOf("day")
                    .valueOf();
                this.startMaxDate = control.value;

                if (this.dateForm.controls.startDate.value) {
                    const startDate = (this.dateForm.controls.startDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (endDate < startDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.startMaxDate = moment();
            }

            this.checkSearchError();

            return forbidden ? { dateLessThanStartDate: true } : null;
        };
    }

    greaterThanEndDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            const momentStart: Moment = control.value as Moment;

            if (momentStart) {
                const startDate = momentStart.startOf("day").valueOf();
                this.endMinDate = momentStart;

                if (this.dateForm.controls.endDate.value) {
                    const endDate = (this.dateForm.controls.endDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (startDate > endDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.endMinDate = null;
            }

            this.checkSearchError();

            return forbidden ? { dateGreaterThanEndDate: true } : null;
        };
    }

    search(shouldAddInputs: boolean = true): void {
        if (shouldAddInputs) {
            this.onAddAccountRefiner();
            this.onAddStreetRefiner();
            this.onAddCityRefiner();
            this.onAddZipRefiner();
        }
        this.activeRefiners = this.refinerService.refiners.slice();

        this.getBatch(this.pageIndex, true);
    }

    reset(): void {
        this.refinerService.resetRefiners();
        this.setDefaults();
    }

    setDefaults(): void {
        let refiner = new Refiner();
        refiner.location = RefinerLocation.isActive;
        refiner.value = "Yes";
        refiner.dataPropertyName = "active";
        refiner.dataValue = BaseFilterMapService.yes;
        this.refinerService.checkAndUpdateRefiner(refiner, true);
        this.refinerService.areDefaultsSet = true;

        refiner = new Refiner();
        refiner.location = RefinerLocation.callable;
        refiner.value = [BaseFilterMapService.yes, BaseFilterMapService.overridden].join(", ");
        refiner.dataPropertyName = "active";
        refiner.dataValue = [BaseFilterMapService.yes, BaseFilterMapService.overridden].join(valueSeparator);
        this.refinerService.checkAndUpdateRefiner(refiner, true);
    }


    private setCounts<T>(response: GenericResponseDto<T>): void {
        this.total = response.getCount(ResponseCountKey.customer) ?? 0;
        this.assignedCount = response.getCount(ResponseCountKey.assigned) ?? 0;
        this.unassignedCount =
            response.getCount(ResponseCountKey.unassigned) ?? 0;
    }

    async getBatch(
        index: number,
        force?: boolean
    ): Promise<void> {
        if (this.gridInitialized) {
            const areRefinersTheSame = this.resolvePageIndex(index);
            this.isSearchStale = false;

            if (
                !areRefinersTheSame ||
                this.shouldResetGrid ||
                force
            ) {
                if (!this.isSearching) {
                    this.isSearching = true;
                    this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                        this.shouldWait$
                    );

                    setTimeout(async () => {
                        // wait one angular cycle to ensure myFilters have reset.
                        const id = this.projectStateService.project.id;
                        const refiners = this.refinerService.refiners.slice();

                        this.setTabRefiners(refiners);

                        const results = await this.projectDelineationService.getCustomersForProjectAssignmentBatch(
                            id,
                            refiners,
                            this.pageSize,
                            this.pageIndex * this.pageSize,
                            this.filterSorts
                        );
                        if (results?.id === id) {
                            this.setCounts(results);

                            let gridData: GridData[] = this.gridData.slice();
                            if (this.pageIndex === 0) {
                                gridData = new Array<GridData>();
                            }
                            const largestIndex = gridData.length;

                            const assignedCustomers =
                                this.projectStateService.project
                                    ?.projectCustomers ?? [];

                            const px3Ranks = new Map((await this.px3RankService.getAll()).map(rank => [rank.id, rank.rank]));
                            for (const result of results?.values ?? []) {
                                if (
                                    assignedCustomers.find(
                                        (ac) =>
                                            ac.customerId === result.customerId
                                    )
                                ) {
                                    result.isAssigned = true;
                                }
                                const gridItem: GridData = {
                                    data: new ProjectStoreGridViewmodel(result, px3Ranks.get(result.px3RankId)),
                                    detailArrayName: "",
                                    isExpanded: false,
                                    index: largestIndex + results.values.indexOf(result)
                                };
                                gridData.push(gridItem);
                            }
                            this.gridData = gridData;

                            this.dataSource = new TableVirtualScrollDataSource(
                                this.gridData
                            );
                            this.shouldResetGrid = false;
                            this.shouldWait$.next(false);
                            this.grid.setHeaderCheckbox(
                                !this.dataSource.data.some(
                                    (row) => !row.data.isAssigned
                                )
                            );
                        } else {
                            this.shouldResetGrid = true;
                            this.shouldWait$.next(false);
                        }
                        this.isSearching = false;
                    }, 0);
                }
            }

            this.dataLoaded = true;
        }
    }

    private setTabRefiners(refiners: Refiner[]) {
        switch (this.selectedAssignmentValue) {
            case "assigned":
                {
                    const assignmentRefiner = new Refiner();
                    assignmentRefiner.shouldSearchWhenCleared = false;
                    assignmentRefiner.dataPropertyName = "Id";
                    assignmentRefiner.dtoObjectName = "ProjectCustomers";
                    assignmentRefiner.dtoPropertyName = "Id";
                    assignmentRefiner.location = RefinerLocation.assignment;
                    assignmentRefiner.dataValue = BaseFilterMapService.true;
                    assignmentRefiner.value = "0";
                    refiners.push(assignmentRefiner);
                }
                break;
            case "unassigned": {
                const assignmentRefiner = new Refiner();
                assignmentRefiner.shouldSearchWhenCleared = false;
                assignmentRefiner.dataPropertyName = "Id";
                assignmentRefiner.dtoObjectName = "ProjectCustomers";
                assignmentRefiner.dtoPropertyName = "Id";
                assignmentRefiner.location = RefinerLocation.assignment;
                assignmentRefiner.dataValue = BaseFilterMapService.false;
                assignmentRefiner.value = "0";
                refiners.push(assignmentRefiner);
            }
            default:
                break;
        }
    }

    resolvePageIndex(index: number): boolean {
        this.currentRefinerMap = new Map<RefinerLocation, string>();
        for (const refiner of this.refinerService.refiners ?? []) {
            this.currentRefinerMap.set(refiner.location, refiner.dataValue ?? refiner.value);
        }

        const rtn = SharedHelper.compareMaps(
            this.currentRefinerMap,
            this.previousRefinerMap
        );

        if (rtn && this.total != this.gridData?.length && index !== 0) {
            this.pageIndex++;
        } else {
            this.previousRefinerMap = new Map(this.currentRefinerMap);
            this.pageIndex = 0;
        }
        return rtn;
    }

    assignmentChange(event: MatButtonToggleChange): void {
        this.selectedAssignmentValue = event.value;
        this.getBatch(0, true);
    }

    async setActivitiesFilter(): Promise<void> {
        this.zrtService.byArea = false;
        this.projectFilterService.useOwnerCode = true;

        const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts();
        if (!zrtsResponse) {
            return;
        }
        this.zrtService.employeeZrts = zrtsResponse.values;

        this.zrtService.areas = await this.filterService.getAreas();

        const wholesalersResponse = await this.customerDelineationService.getWholesalersWithGroupProducts();
        if (!wholesalersResponse) {
            this.shouldWait$.next(false);
            return;
        }

        const wholesalers = wholesalersResponse.values;
        if (wholesalers?.length) {
            wholesalers.sort((a, b) => a.name.localeCompare(b.name));
        }
        this.projectFilterService.wholesalers =
            wholesalers ?? new Array<Customer>();

        const accountOwnershipResponse = await this.accountOwnershipDelineationService.getAllInDropDown();
        if (!accountOwnershipResponse || !accountOwnershipResponse.values) {
            this.shouldWait$.next(false);
            return;
        }
        this.projectFilterService.chains = accountOwnershipResponse.values.sort((a, b) => a.name.localeCompare(b.name));
    }

    private getMyListIds(): void {
        setTimeout(async () => {
            if (this.total > 10000) {
                this.snackbarService.showWarning("Total entry count is too high. Cannot push more than 10,000 records at once.");
                this.filterService.myListEntries$.next(undefined);
            } else {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                    this.shouldWait$
                );
                const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                data.header = "Confirmation";
                data.message =
                    "Would you like to push all entries to My List?";
                data.buttonLeftText = "Cancel";
                data.buttonLeftFunction = () => {
                    this.confirmationOverlayRef.close(data);
                };
                data.buttonRightText = "Yes";
                data.buttonRightFunction = () => {
                    data.isConfirmed = true;
                    this.confirmationOverlayRef.close(data);
                };

                this.confirmationOverlayRef = this.overlayService.open(
                    ConfirmationDialogComponent,
                    data
                );

                this.confirmationOverlayRef.afterClosed$
                    .pipe(
                        map(async (event) => {
                            if (event && event.data && event.data.isConfirmed) {
                                this.filterService.myListEntries$.next(await this.getIdsForMyList());
                                this.shouldWait$.next(true);
                            } else {
                                this.filterService.myListEntries$.next(undefined);
                                this.shouldWait$.next(true);
                            }
                        })
                    )
                    .subscribe();
            }
        }, 0);
    }

    private async getIdsForMyList(): Promise<string[]> {
        let rtn = this.dataSource.filteredData.map((gd) => gd.data.customerId);

        if (this.employeeDelineationService.getOnlineState() && rtn.length < this.total) {
            const gridIdResponse = await this.projectDelineationService.getCustomersForProjectAssignmentBatch(
                newSequentialId(),
                this.activeRefiners,
                10000,
                this.pageIndex * this.pageSize,
                this.filterSorts
            );
            if (gridIdResponse?.values) {
                const gridIds = gridIdResponse.values.map(v => v.customerId);
                rtn = rtn.concat(gridIds.filter((v) => !rtn.includes(v)));
            }
        }

        return rtn;
    }

    private async updateLocalProjectCustomers(): Promise<void> {
        const serverProject = await this.projectDelineationService.upsertProject(this.projectStateService.project);

        if (serverProject?.values) {
            this.projectStateService.project.projectCustomers = serverProject.values.projectCustomers;
        }
    }
}
