import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { NgModule } from "@angular/core";
import { OrdersListComponent } from "./orders/orders-list/orders-list.component";
import { OrderExtractionComponent } from "./orders/order-extraction/order-extraction.component";
import { TransactionsListComponent } from "./transactions/transactions-list/transactions-list.component";

export const routes: Routes = [
    {
        canActivate: [MsalGuard],
        path: "sales/orders",
        component: OrdersListComponent
    },
    {
        canActivate: [MsalGuard],
        path: "sales/orders/extraction",
        component: OrderExtractionComponent
    },
    {
        canActivate: [MsalGuard],
        path: "sales/transactions",
        component: TransactionsListComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class SalesRoutingModule {}
