import {
    GenericLookup,
    ContractPaymentMethods,
    ContractPaymentStatuses,
    ContractPaymentTypes
} from "shield.shared";
import { Signature } from "./signature.entity";

export class CustomerContractPayment {
    id: string;
    customerContractId: string;
    customerId: string;
    customerContactId: string;
    customerContactName: string;
    customerContactSignature?: Signature;
    contractPaymentStatus: GenericLookup<ContractPaymentStatuses>;
    contractPaymentType: GenericLookup<ContractPaymentTypes> | null;
    contractPaymentMethod: GenericLookup<ContractPaymentMethods> | null;
    paymentAmount: number | null;
    paymentDate: Date;
    reason: string | null;
    gratisId: string | null;
    gratisNumber: string | null;
    createdUtcDateTime: Date;
    createdUserId: string;
    createdUserZrt: string | null;
    createdUserFullName: string;
    modifiedUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string;
}
