import { Customer } from "src/app/entity-models/customer.entity";

export class WholesalerViewmodel {
    id: string;
    displayValue: string;
    wholesaler: Customer;

    constructor(w: Customer) {
        this.id = w.id;
        this.displayValue = w.displayName;
        this.wholesaler = w;
    }
}
