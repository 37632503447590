import { Component, OnInit } from '@angular/core';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';

import { AddProjectActivityDialogViewModel } from './add-project-activity-dialog.viewmodel';

@Component({
    selector: 'app-add-project-activity-dialog',
    templateUrl: './add-project-activity-dialog.component.html',
    styleUrls: ['./add-project-activity-dialog.component.scss']
})
export class AddProjectActivityDialogComponent implements OnInit {

    viewmodel: AddProjectActivityDialogViewModel;

    constructor(public injectedData: SwisherOverlayRef<
            AddProjectActivityDialogViewModel,
            AddProjectActivityDialogComponent
        >) { }

    ngOnInit(): void {
        this.viewmodel = this.injectedData.data;
        this.viewmodel.filter();
    }
}
