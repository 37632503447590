import { Order } from "./order.entity";

export class OrderExtraction {
    id: string;
    wholesalerId: string;
    createdUtcDateTime: Date;
    createdUserId: string;
    createdUserZrt: string | null;
    createdUserFullName: string;
    orders: Order[];

}
