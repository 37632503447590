<div class="agreement">
    <div class="row text-center">
        <div class="col-12">
            <img [src]="details.get('HeaderImage')" alt="Swisher Logo" />
        </div>
    </div>
    <div class="row text-center">
        <div class="col-12">
            <h2>{{ details.get("Title") }}</h2>
        </div>
    </div>
    <ng-container *ngIf="retailer">
        <div class="row d-flex justify-content-center mb-3">
            <div class="col-8">
                <table class="table table-bordered table-sm">
                    <tbody>
                        <tr>
                            <th class="col-4 bonus text-right"><strong class="table-head">Contract Number</strong></th>
                            <td class="col-4">{{ retailer.number }}</td>
                        </tr>
                        <tr>
                            <th class="col-4 bonus text-right"><strong class="table-head">Program Level</strong></th>
                            <td class="col-4">{{ "Level " + retailer.level }}</td>
                        </tr>
                        <tr>
                            <th class="col-4 bonus text-right"><strong class="table-head">Signatory Date</strong></th>
                            <td class="col-4">{{ retailer.signatureDate }}</td>
                        </tr>
                        <tr>
                            <th class="col-4 bonus text-right"><strong class="table-head">Account Name</strong></th>
                            <td class="col-4">{{ retailer.customerName }}</td>
                        </tr>
                        <tr>
                            <th class="col-4 bonus text-right"><strong class="table-head">Retail ID #</strong></th>
                            <td class="col-9">{{ retailer.retailIdentificationNumber }}</td>
                        </tr>
                        <tr>
                            <th class="col-4 bonus text-right"><strong class="table-head">Address</strong></th>
                            <td class="col-4">{{ retailer.address2 ? retailer.address1 + ", " + retailer.address2 : retailer.address1 ? retailer.address1 : "" }}</td>
                        </tr>
                        <tr>
                            <th class="col-4 bonus text-right"><strong class="table-head">City</strong></th>
                            <td class="col-4">{{ retailer.city }}</td>
                        </tr>
                        <tr>
                            <th class="col-4 bonus text-right"><strong class="table-head">State</strong></th>
                            <td class="col-4">{{ retailer.state }}</td>
                        </tr>
                        <tr>
                            <th class="col-4 bonus text-right"><strong class="table-head">Zip</strong></th>
                            <td class="col-4">{{ retailer.zipCode }}</td>
                        </tr>
                        <tr>
                            <th class="col-4 bonus text-right"><strong class="table-head">Swisher Representative</strong></th>
                            <td class="col-4">{{ retailer.swisherName }}</td>
                        </tr>
                        <tr>
                            <th class="col-4 bonus text-right"><strong class="table-head">Customer Representative</strong></th>
                            <td class="col-4">
                                <div class="row">
                                    <div class="col-4">{{ retailer.customerContactName }}</div>
                                    <div class="col-8"><img class="img-fluid signature" [src]="retailer.customerContactSignature" /></div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>
    <div class="row">
        <div class="col-12">
            {{ details.get("Description") }}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h3>Terms</h3>
        </div>
    </div>
    <ng-container *ngIf="terms.length > 0">
        <div class="row">
            <div class="col-12">
                <ul>
                    <li *ngFor="let term of terms">
                        {{ terms.indexOf(term) !== terms.length - 1 ? term : term + " " + endDate + "." }}
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="levelOneLines.length > 0 && levelOneLines.length > 0">
        <div class="row mb-2">
            <div class="col-6">
                <h3 class="level">Level 1</h3>
                <div *ngFor="let level of levelOneLines">
                    {{ level }}
                </div>
            </div>
            <div class="col-6">
                <h3 class="level">Level 2</h3>
                <div *ngFor="let level of levelTwoLines">
                    {{ level }}
                </div>
            </div>
        </div>
        <div class="row" *ngIf="levelOneBonuses.length > 0 || levelTwoBonuses.length > 0">
            <div class="col-12">
                <h4 class="bonus">Bonus Payment - 12 Consecutive Months</h4>
            </div>
            <div class="col-6">
                <div *ngFor="let level of levelOneBonuses">
                    {{ level }}
                </div>
            </div>
            <div class="col-6">
                <div *ngFor="let level of levelTwoBonuses">
                    {{ level }}
                </div>
            </div>
        </div>
    </ng-container>
</div>
