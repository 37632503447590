import { DayTimeEntryDto } from "shield.shared";
import { DayTimeEntry } from "src/app/entity-models/day-time-entry.entity";
import { Helper } from "src/app/helpers/helper";
import { DayTimeEntryWorkWithConverterService } from "./day-time-entry-work-with-converter.service";

export class DayTimeEntryConverterService {
    static dayTimeEntryToDayTimeEntryDto(
        entity: DayTimeEntry
    ): DayTimeEntryDto {
        const dto = new DayTimeEntryDto();

        dto.id = entity.id;
        dto.key = entity.key;
        dto.date = Helper.localMidnightDateToUtcMidnightString(entity.date);
        dto.employeeId = entity.employeeId;
        dto.isCompleted = entity.isCompleted;
        dto.mileage = entity.mileage;
        dto.resetDay = entity.resetDay;
        dto.endOfDayComment = entity.endOfDayComment;
        dto.createdUtcDateTime = entity.createdUtcDateTime
            ? entity.createdUtcDateTime.toISOString()
            : null;
        dto.createdUserId = entity.createdUserId;
        dto.createdUserZrt = entity.createdUserZrt;
        dto.createdUserFullName = entity.createdUserFullName;
        dto.modifiedUtcDateTime = entity.modifiedUtcDateTime
            ? entity.modifiedUtcDateTime.toISOString()
            : null;
        dto.modifiedUserId = entity.modifiedUserId;
        dto.modifiedUserZrt = entity.modifiedUserZrt;
        dto.modifiedUserFullName = entity.modifiedUserFullName;
        dto.dayTimeEntryWorkWithDtos = entity.dayTimeEntryWorkWiths.map((ww) =>
            DayTimeEntryWorkWithConverterService.dayTimeEntryWorkWithToDayTimeEntryWorkWithDto(
                ww
            )
        );
        dto.rowversion = entity.rowversion;

        return dto;
    }

    static dayTimeEntryDtoToDayTimeEntry(dto: DayTimeEntryDto): DayTimeEntry {
        const entity = new DayTimeEntry();

        entity.id = dto.id;
        entity.key = dto.key;
        entity.date = Helper.utcMidnightStringToLocalMidnightDate(dto.date);
        entity.employeeId = dto.employeeId;
        entity.isCompleted = dto.isCompleted;
        entity.mileage = dto.mileage;
        entity.resetDay = dto.resetDay;
        entity.endOfDayComment = dto.endOfDayComment;
        entity.createdUtcDateTime = dto.createdUtcDateTime
            ? new Date(dto.createdUtcDateTime)
            : null;
        entity.createdUserId = dto.createdUserId;
        entity.createdUserZrt = dto.createdUserZrt;
        entity.createdUserFullName = dto.createdUserFullName;
        entity.modifiedUtcDateTime = dto.modifiedUtcDateTime
            ? new Date(dto.modifiedUtcDateTime)
            : null;
        entity.modifiedUserId = dto.modifiedUserId;
        entity.modifiedUserZrt = dto.modifiedUserZrt;
        entity.modifiedUserFullName = dto.modifiedUserFullName;
        entity.dayTimeEntryWorkWiths = dto.dayTimeEntryWorkWithDtos.map((ww) =>
            DayTimeEntryWorkWithConverterService.dayTimeEntryWorkWithDtoToDayTimeEntryWorkWith(
                ww
            )
        );
        entity.rowversion = dto.rowversion;

        return entity;
    }
}
