import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    EntityObjectNames, EntityPropertyNames, FilterRequestDto, GenericRequestDto,
    GenericResponseDto, ProjectBasicCustomerDto, ProjectDto, ProjectMetricZrtDto,
    ProjectMetricWholesalerDto, ProjectMetricProductDto, ProjectMetricsRequestDto, RefinerDto,
    RefinerGroupDto, RefinerLocation, SharedHelper, ProjectCustomerDto,
    newSequentialId,
    ProjectMetricParamsDto,
    ProjectStoreAssignmentParamsDto,
    ProjectEmployeeAssignmentParamsDto,
    ProfileProjectParamsDto,
    GenericDropDownDto,
    ProjectCustomerAndEmployeeParamsDto,
    ProjectBatchParamsDto,
    FilterRequestV2Dto,
    ProjectListColumns,
    AccountFilterDto,
    ProjectListFilterDto,
    valueSeparator,
    ProjectEmployeeDto,
    StagingProjectCheckDto,
    ProjectByIdParamsDto,
    StringArrayDto,
    ProjectAssignmentCountParamsDto,
    ProjectAssignmentCountFilterDto,
    ProjectAssignmentCountEntryDto,
    ProjectBasicCustomerEmployeeDto
} from "shield.shared";
import { ProjectCustomerColumns } from "shield.shared/dist/filter/filter-sort-columns/project-customer-columns";
import { ProjectAssignmentsSummaryViewModel } from "src/app/details/project/project-configuration/project-assignments/project-assignments-summary.viewmodel";
import { ProjectBasicCustomerEmployee } from "src/app/entity-models/project-basic-customer-employee.entity";
import { ProjectEmployee } from "src/app/entity-models/project-employee.entity";
import { ProjectBasicCustomer } from "../../entity-models/project-basic-customer.entity";
import { ProjectCustomer } from "../../entity-models/project-customer.entity";
import { Project } from "../../entity-models/project.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { ProjectConverterService } from "../converter-services/project-converter.service";

@Injectable()
export class ProjectOnlineService {

    constructor(private http: HttpClient) { };

    async deleteProject(projectId: string): Promise<GenericResponseDto<Project>> {

        const result = await this.http
            .get<GenericResponseDto<ProjectDto | undefined>>(`/api/projects/${projectId}/delete`).toPromise();

        return ConverterHelper.dtoToEntity<ProjectDto, Project>(result, (r) => ProjectConverterService.projectDtoToProject(r));
    }

    async discardProject(projectId: string): Promise<GenericResponseDto<Project>> {

        const result = await this.http
            .get<GenericResponseDto<ProjectDto | undefined>>(`/api/projects/${projectId}/discard`).toPromise();

        return ConverterHelper.dtoToEntity<ProjectDto, Project>(result, (r) => ProjectConverterService.projectDtoToProject(r));
    }

    async upsertProject(project: Project, stagingDone?: boolean): Promise<GenericResponseDto<Project>> {

        const projectDto = ProjectConverterService.projectToProjectDto(project);
        const request = new GenericRequestDto<ProjectDto>();
        request.id = project.id;
        request.values = projectDto;

        let result: GenericResponseDto<ProjectDto | undefined>;
        result = await this.http
            .post<GenericResponseDto<ProjectDto | undefined>>(stagingDone
                ? `/api/projects/save?staging-done=${stagingDone}`
                : `/api/projects/save`, request)
            .toPromise();

        return ConverterHelper.dtoToEntity<ProjectDto, Project>(result, (r) => ProjectConverterService.projectDtoToProject(r));
    }

    async getById(params: ProjectByIdParamsDto): Promise<GenericResponseDto<Project>> {

        const result = await this.http
            .get<GenericResponseDto<ProjectDto>>(params.isStaging
                ? `/api/projects/${params.id}?is-staging=${true}`
                : `/api/projects/${params.id}`)
            .toPromise();

        return ConverterHelper.dtoToEntity<ProjectDto, Project>(result, (r) => ProjectConverterService.projectDtoToProject(r));
    }

    async getProjectBatch(
        key: ProjectBatchParamsDto
    ): Promise<GenericResponseDto<Project[]>> {

        const request = new GenericRequestDto<FilterRequestV2Dto<ProjectListFilterDto, ProjectListColumns>>();
        request.id = newSequentialId();
        request.values = key.filterRequestDto;

        const result = await this.http
            .post<GenericResponseDto<ProjectDto[]>>(
                "/api/projects",
                request
            )
            .toPromise();

        return ConverterHelper.dtoToEntityArray<ProjectDto, Project>(result, (r) => ProjectConverterService.projectDtoToProject(r));
    }

    async getCustomersForProjectBatch(
        key: FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>
    ): Promise<GenericResponseDto<ProjectBasicCustomer[]>> {

        const request = new GenericRequestDto<FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>>();
        request.id = key.id;
        request.values = key;

        const result = await this.http
            .post<GenericResponseDto<ProjectBasicCustomerDto[]>>(
                `/api/project-customers/${key.id}/get-customers`,
                request
            )
            .toPromise();

        return ConverterHelper.dtoToEntityArray<ProjectBasicCustomerDto, ProjectBasicCustomer>(result,
            (r) => ProjectConverterService.projectBasicCustomerDtoToProjectBasicCustomer(r));
    }

    async getCustomersForProjectEmployeeBatch(
        key: FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>
    ): Promise<GenericResponseDto<ProjectBasicCustomerEmployee[]>> {

        const request = new GenericRequestDto<FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>>();
        request.id = key.id;
        request.values = key;

        const result = await this.http
            .post<GenericResponseDto<ProjectBasicCustomerEmployeeDto[]>>(
                `/api/project-customer-employees/${key.id}/get-customers`,
                request
            )
            .toPromise();

        return ConverterHelper.dtoToEntityArray<ProjectBasicCustomerEmployeeDto, ProjectBasicCustomerEmployee>(result,
            (r) => ProjectConverterService.projectBasicCustomerEmployeeDtoToProjectBasicCustomerEmployee(r));
    }

    async getDropDown(): Promise<GenericResponseDto<GenericDropDownDto[]>> {
        const response = await this.http
            .get<GenericResponseDto<GenericDropDownDto[]>>(`/api/projects/dropdown`)
            .toPromise();

        return ConverterHelper.dtoToEntityArray<GenericDropDownDto, GenericDropDownDto>(response,
            (r) => r);
    }

    async getProjectCustomersByProjectId(id: string): Promise<GenericResponseDto<ProjectCustomer[]>> {
        const result = await this.http
            .get<GenericResponseDto<ProjectCustomerDto[]>>(`/api/project-customers/${id}/customers`).toPromise();

        return ConverterHelper.dtoToEntityArray<ProjectCustomerDto, ProjectCustomer>(result,
            (r) => ProjectConverterService.projectCustomerDtoToProjectCustomer(r));
    }

    async getMetrics(
        params: ProjectMetricParamsDto
    ): Promise<GenericResponseDto<ProjectMetricZrtDto[] | ProjectMetricWholesalerDto[] | ProjectMetricProductDto[]>> {
        const rtn = new GenericResponseDto<ProjectMetricZrtDto[] | ProjectMetricWholesalerDto[] | ProjectMetricProductDto[]>();
        rtn.values = new Array();

        const requestDto = new ProjectMetricsRequestDto();
        requestDto.summaryOption = params.summaryOption;
        requestDto.startDate = params.refiners.find((r) => r.refinerLocation === RefinerLocation.startDate)?.value;
        requestDto.endDate = params.refiners.find((r) => r.refinerLocation === RefinerLocation.endDate)?.value;
        requestDto.employeeIds = params.refiners.find((r) => r.refinerLocation === RefinerLocation.zrtByEmployee)?.value?.split(valueSeparator);

        const request = new GenericRequestDto<ProjectMetricsRequestDto>();
        request.id = params.projectId;
        request.values = requestDto;

        const result = await this.http
            .post<GenericResponseDto<ProjectMetricZrtDto[] | ProjectMetricWholesalerDto[] | ProjectMetricProductDto[]>>(
                `/api/projects/${params.projectId}/metrics/${params.summaryOption}`,
                request
            )
            .toPromise();
        if (result?.values) {
            rtn.values = result.values;
        }
        rtn.id = result?.id;
        rtn.affected = result?.affected;
        rtn.count = result?.count;
        rtn.isError = result?.isError;
        rtn.message = result?.message;

        return rtn;
    }

    async setProjectStoreAsAssigned(params: ProjectStoreAssignmentParamsDto): Promise<GenericResponseDto<ProjectCustomer | undefined>> {
        const request = new GenericRequestDto<FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>>()
        request.values = params.filterRequestV2Dto;

        const result = await this.http
            .post<GenericResponseDto<ProjectCustomerDto | undefined>>(`/api/project-customers/${params.projectId}/customer/${params.customerId}/assign`, request)
            .toPromise();

        return ConverterHelper.dtoToEntity<ProjectCustomerDto, ProjectCustomer>(result, (r) => ProjectConverterService.projectCustomerDtoToProjectCustomer(r));
    }

    async setProjectStoreAsUnassigned(params: ProjectStoreAssignmentParamsDto): Promise<GenericResponseDto<ProjectCustomer>> {
        const request = new GenericRequestDto<FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>>()
        request.values = params.filterRequestV2Dto;

        const result = await this.http
            .post<GenericResponseDto<ProjectCustomerDto | undefined>>(`/api/project-customers/${params.projectId}/customer/${params.customerId}/unassign`, request)
            .toPromise();

        return ConverterHelper.dtoToEntity<ProjectCustomerDto, ProjectCustomer>(result, (r) => ProjectConverterService.projectCustomerDtoToProjectCustomer(r));
    }

    async setProjectStoresAsAssigned(params: ProjectStoreAssignmentParamsDto): Promise<GenericResponseDto<Project>> {

        const request = new GenericRequestDto<FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>>();
        request.id = params.projectId;
        request.values = params.filterRequestV2Dto;

        const result = await this.http
            .post<GenericResponseDto<ProjectDto>>(`/api/project-customers/${params.projectId}/assign-customers`, request).toPromise();

        return ConverterHelper.dtoToEntity<ProjectDto, Project>(result, (r) => ProjectConverterService.projectDtoToProject(r));
    }

    async setProjectStoresAsUnassigned(params: ProjectStoreAssignmentParamsDto): Promise<GenericResponseDto<Project>> {

        const request = new GenericRequestDto<FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>>();
        request.id = params.projectId;
        request.values = params.filterRequestV2Dto;

        const result = await this.http
            .post<GenericResponseDto<ProjectDto>>(`/api/project-customers/${params.projectId}/unassign-customers`, request).toPromise();

        return ConverterHelper.dtoToEntity<ProjectDto, Project>(result, (r) => ProjectConverterService.projectDtoToProject(r));
    }

    async getProfileProjects(params: ProfileProjectParamsDto): Promise<GenericResponseDto<Project[]>> {
        const request = new GenericRequestDto<ProfileProjectParamsDto>();
        request.id = newSequentialId();
        request.values = params;

        const result = await this.http
            .post<GenericResponseDto<ProjectDto[]>>(`/api/projects/get-profile-projects`, request).toPromise();

        return ConverterHelper.dtoToEntityArray<ProjectDto, Project>(result, (r) => ProjectConverterService.projectDtoToProject(r));
    }

    async setProjectStoreForAssignment(params: ProjectStoreAssignmentParamsDto): Promise<GenericResponseDto<string[]>> {
        const request = new GenericRequestDto<FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>>()
        request.id = params.projectId;
        request.values = params.filterRequestV2Dto;

        const result = await this.http
            .post<GenericResponseDto<string[]>>(`/api/project-customers/${params.projectId}/select`, request)
            .toPromise();

        return ConverterHelper.dtoAsDto(result);
    }

    async assignEmployeesToStores(params: ProjectEmployeeAssignmentParamsDto): Promise<GenericResponseDto<ProjectCustomer[]>> {
        const request = new GenericRequestDto<StringArrayDto>();
        request.id = params.projectId;
        request.values = params.employeeIdArray;

        const result = await this.http
            .post<GenericResponseDto<ProjectCustomerDto[]>>(`/api/project-customer-employees/${params.projectId}/assign`, request)
            .toPromise();

        return ConverterHelper.dtoToEntityArray<ProjectCustomerDto, ProjectCustomer>
            (result, (r) => ProjectConverterService.projectCustomerDtoToProjectCustomer(r));
    }

    async unassignEmployeesFromStores(params: ProjectEmployeeAssignmentParamsDto): Promise<GenericResponseDto<ProjectCustomer[]>> {
        const request = new GenericRequestDto<StringArrayDto>();
        request.id = params.projectId;
        request.values = params.employeeIdArray;

        const result = await this.http
            .post<GenericResponseDto<ProjectCustomerDto[]>>(`/api/project-customer-employees/${params.projectId}/unassign`, request)
            .toPromise();

        return ConverterHelper.dtoToEntityArray<ProjectCustomerDto, ProjectCustomer>
            (result, (r) => ProjectConverterService.projectCustomerDtoToProjectCustomer(r));
    }

    async getAssignmentCounts(
        key: ProjectAssignmentCountParamsDto
    ): Promise<GenericResponseDto<ProjectAssignmentsSummaryViewModel[]>> {

        const request = new GenericRequestDto<FilterRequestV2Dto<ProjectAssignmentCountFilterDto, ProjectListColumns>>();
        request.id = newSequentialId();
        request.values = key.filterRequestDto;

        const result = await this.http
            .post<GenericResponseDto<ProjectAssignmentCountEntryDto[]>>(
                `/api/project-customer-employees/${key.projectId}/get-counts`,
                request
            )
            .toPromise();

        return ConverterHelper.dtoToEntityArray<ProjectAssignmentCountEntryDto, ProjectAssignmentsSummaryViewModel>
            (result, (r) => ProjectConverterService.projectAssignmentCountEntryDtoToProjectAssignmentCountEntry(r));
    }

    async checkStagingProjectStatus(projectId?: string): Promise<GenericResponseDto<StagingProjectCheckDto>> {
        const result = await this.http
            .get<GenericResponseDto<StagingProjectCheckDto>>(projectId
                ? `/api/projects/staging-check?project-id=${projectId}`
                : "/api/projects/staging-check")
            .toPromise();

        return ConverterHelper.dtoToEntity<StagingProjectCheckDto, StagingProjectCheckDto>(result, (r) => { return r; });
    }
}
