import { AccountOwnershipDto } from "shield.shared";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";

export class AccountOwnershipConverterService {

    static accountOwnershipDtoToAccountOwnership(dto: AccountOwnershipDto): AccountOwnership {

        const rtn = new AccountOwnership();

        rtn.id = dto.id;
        rtn.businessOwnerCode = dto.businessOwnerCode;
        rtn.commonOperatingName = dto.commonOperatingName;
        rtn.createdUserFullName = dto.createdUserFullName;
        rtn.createdUtcDateTime = dto.createdUtcDateTime ? new Date(dto.createdUtcDateTime) : new Date();
        rtn.customerId = dto.customerId;
        rtn.familyCode = dto.familyCode;
        rtn.hierarchyLevel = dto.hierarchyLevel;
        rtn.isDeactivated = dto.isDeactivated;
        rtn.modifiedUserFullName = dto.modifiedUserFullName;
        rtn.modifiedUtcDateTime = dto.modifiedUtcDateTime ? new Date(dto.modifiedUtcDateTime) : null;
        rtn.name = dto.name;
        rtn.assignedEmployeeId = dto.assignedEmployeeId;
        rtn.ownerCode = dto.ownerCode;
        rtn.parentAccountOwnershipId = dto.parentAccountOwnershipId;
        rtn.parentOwnerCode = dto.parentOwnerCode;
        rtn.rowversion = dto.rowversion;

        return rtn;
    }

    static accountOwnershipToAccountOwnershipDto(dto: AccountOwnership): AccountOwnershipDto {

        const rtn = new AccountOwnershipDto();

        rtn.id = dto.id;
        rtn.businessOwnerCode = dto.businessOwnerCode;
        rtn.commonOperatingName = dto.commonOperatingName;
        rtn.createdUserFullName = dto.createdUserFullName;
        rtn.createdUtcDateTime = dto.createdUtcDateTime ? dto.createdUtcDateTime.toISOString() : new Date().toISOString();
        rtn.customerId = dto.customerId;
        rtn.familyCode = dto.familyCode;
        rtn.hierarchyLevel = dto.hierarchyLevel;
        rtn.isDeactivated = dto.isDeactivated;
        rtn.modifiedUserFullName = dto.modifiedUserFullName;
        rtn.modifiedUtcDateTime = dto.modifiedUtcDateTime ? dto.modifiedUtcDateTime.toISOString() : new Date().toISOString();
        rtn.name = dto.name;
        rtn.assignedEmployeeId = dto.assignedEmployeeId;
        rtn.ownerCode = dto.ownerCode;
        rtn.parentAccountOwnershipId = dto.parentAccountOwnershipId;
        rtn.parentOwnerCode = dto.parentOwnerCode;
        rtn.rowversion = dto.rowversion;

        return rtn;
    }

}
