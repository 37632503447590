
import { ProjectActivitySurvey } from "./project-activity-survey.entity";
import { ProjectCustomer } from "./project-customer.entity";
import { ProjectEmployee } from "./project-employee.entity";
import { ProjectOrderDate } from "./project-order-date.entity";
import { ProjectProduct } from "./project-product.entity";

export class Project {

    id: string; //uuid
    projectId: string;
    projectTypeId: number;
    projectStatusId: number;
    name: string;
    startDate: Date;
    visibleDate: Date;
    endDate: Date;
    mission: string;
    shareSiteLink: string;
    wholesalerGroupId: string; //uuid
    areStoresVisibleToAll: boolean;
    createdUserId: string;
    createdUtcDateTime: Date;
    createdUserZrt: string;
    createdUserFullName: string
    modifiedUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    rowversion: string;
    projectActivitySurveys = new Array<ProjectActivitySurvey>();
    projectCustomers = new Array<ProjectCustomer>();
    projectEmployees = new Array<ProjectEmployee>();
    projectOrderDates = new Array<ProjectOrderDate>();
    projectProducts = new Array<ProjectProduct>();

    storeCount?: number;
}
