import { RefinerDto, RefinerGroupDto, RefinerLocation, TransactionDto, TransactionLineItemDto, TransactionLineItemTypeLookup } from "shield.shared";
import { Receipt } from "src/app/entity-models/receipt";
import { TransactionLineItem } from "src/app/entity-models/transaction-line-item.entity";
import { Transaction } from "src/app/entity-models/transaction.entity";
import { CallConverterService } from "./call-converter.service";
import { ReceiptConverterService } from "./receipt-converter.service";
import { RefinerConverterService } from "./refiner-converter.service";

export class TransactionConverterService {

    static mapDtoData(refiner: RefinerDto): RefinerGroupDto {
        const refinerDtos = new Array<RefinerDto>();

        switch (refiner.refinerLocation) {
            case RefinerLocation.zrt:
            case RefinerLocation.zrtByArea:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Transactions",
                        "CreatedUserZrt",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.zrtByEmployee:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Transactions",
                        "CreatedUserId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.account:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Transactions",
                        "ExternalSyncId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Transactions",
                        "CustomerName",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.callOnOrAfterDate:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Calls",
                        "CreatedUtcDateTime",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.callOnOrBeforeDate:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Calls",
                        "CreatedUtcDateTime",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.street:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Transactions",
                        "CustomerAddress1",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.states:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Transactions",
                        "CustomerStateId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.counties:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Transactions",
                        "CustomerCountyId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.city:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Transactions",
                        "CustomerCity",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.zipCodes:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Transactions",
                        "CustomerZip",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.receipt:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Transactions",
                        "RetailReceiptNumber",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.status:
                if (refiner.dataValue === "Reimbursed") {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "Transactions",
                            "GratisId",
                            "",
                            "IsNotNull"
                        )
                    );
                }
                else if (refiner.dataValue === "Not Reimbursed") {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "Transactions",
                            "GratisId",
                            "",
                            "IsNull"
                        )
                    );
                }
                break;
            case RefinerLocation.transactionType:
                const types = refiner.dataValue?.split(",");
                for (const type of types) {
                    switch (type) {
                        case "Cash":
                            refinerDtos.push(
                                RefinerConverterService.createRefinerDto(
                                    refiner.refinerLocation,
                                    "CallCashProducts",
                                    "Id",
                                    "Cash",
                                    "IsNotNull"
                                )
                            );
                            break;
                        case "Order":
                            refinerDtos.push(
                                RefinerConverterService.createRefinerDto(
                                    refiner.refinerLocation,
                                    "CallOrderProducts",
                                    "Id",
                                    "Order",
                                    "IsNotNull"
                                )
                            );
                            break;
                        case "Exchange In":
                            refinerDtos.push(
                                RefinerConverterService.createRefinerDto(
                                    refiner.refinerLocation,
                                    "CallExchangeInProducts",
                                    "Id",
                                    "Exchange In",
                                    "IsNotNull"
                                )
                            );
                            break;
                        case "Exchange Out":
                            refinerDtos.push(
                                RefinerConverterService.createRefinerDto(
                                    refiner.refinerLocation,
                                    "CallExchangeOutProducts",
                                    "Id",
                                    "Exchange Out",
                                    "IsNotNull"
                                )
                            );
                            break;
                        case "Gratis":
                            refinerDtos.push(
                                RefinerConverterService.createRefinerDto(
                                    refiner.refinerLocation,
                                    "CallGratisProducts",
                                    "Id",
                                    "Gratis",
                                    "IsNotNull"
                                )
                            );
                            break;
                        default:
                            break;
                    }
                }
                break;
            case RefinerLocation.products:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CallCashProducts",
                        "ProductId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CallExchangeInProducts",
                        "ProductId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CallExchangeOutProducts",
                        "ProductId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CallGratisProducts",
                        "ProductId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CallOrderProducts",
                        "ProductId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.uin:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CallOrderProducts",
                        "Uin",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.wholesalers:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CallOrderProducts",
                        "WholesalerCustomerId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.storeTypes:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "Type",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.msa:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "IsMsa",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                if (refiner.dataValue === "0") {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "Customers",
                            "IsMsa",
                            refiner.value,
                            "IsNull"
                        )
                    );
                }
                break;
            case RefinerLocation.px3Rank:
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "CustomerPx3Ranks",
                            "Px3RankId",
                            refiner.value,
                            refiner.dataValue
                        )
                    );
                    break;
            default:
                break;
        }
        return RefinerConverterService.splitRefinerDtoValues(refinerDtos);
    }

    static transactionDtoToTransaction(
        dto: TransactionDto
    ): Transaction {

        const entity = new Transaction();

        entity.id = dto.id;
        entity.callDate = dto.callDate ? new Date(dto.callDate) : new Date();
        entity.callId = dto.callId;
        entity.callReceipts = (dto.callReceipts ?? []).map((cr) => CallConverterService.callReceiptDtoToEntity(cr));
        entity.createdUserFullName = dto.createdUserFullName;
        entity.createdUserId = dto.createdUserId;
        entity.createdUserZrt = dto.createdUserZrt;
        entity.createdUtcDateTime = dto.createdUtcDateTime ? new Date(dto.createdUtcDateTime) : new Date();
        entity.customerAddress1 = dto.customerAddress1;
        entity.customerAddress2 = dto.customerAddress2;
        entity.customerCity = dto.customerCity;
        entity.customerCounty = dto.customerCounty;
        entity.customerId = dto.customerId;
        entity.customerName = dto.customerName;
        entity.customerState = dto.customerState;
        entity.customerZip = dto.customerZip;
        entity.gratisId = dto.gratisId;
        entity.gratisNumber = dto.gratisNumber;
        entity.gratisPercentage = dto.gratisPercentage;
        entity.gratisTotal = dto.gratisTotal;
        entity.modifiedUserFullName = dto.modifiedUserFullName;
        entity.modifiedUserId = dto.modifiedUserId;
        entity.modifiedUserZrt = dto.modifiedUserZrt;
        entity.modifiedUtcDateTime = dto.modifiedUtcDateTime ? new Date(dto.modifiedUtcDateTime) : null;
        entity.netTotal = dto.netTotal;

        entity.retailNumber = dto.retailNumber;
        entity.receiptNumber = dto.receiptNumber;
        entity.salesTotal = dto.salesTotal;
        entity.transactionLineItems = dto.transactionLineItems?.map((tli) => TransactionConverterService.transactionLineItemDtoToTransationLineItem(tli));
        entity.receipts = dto.receipts?.map((v) => ReceiptConverterService.receiptDtoToReceipt(v));

        return entity;
    }

    static transactionLineItemDtoToTransationLineItem(
        dto: TransactionLineItemDto
    ): TransactionLineItem {
        const rtn = new TransactionLineItem();
        rtn.discount = dto.discount;
        rtn.gratisTotal = dto.gratisTotal;
        rtn.id = dto.id;
        rtn.netTotal = dto.netTotal;
        rtn.price = dto.price;
        rtn.productDescription = dto.productDescription;
        rtn.productId = dto.productId;
        rtn.quantity = dto.quantity;
        rtn.salesTotal = dto.salesTotal;
        rtn.type = dto.type.id;
        rtn.typeDescription = dto.type.description;
        rtn.uin = dto.uin;
        rtn.units = dto.units;
        rtn.upc = dto.upc;
        rtn.wholesalerId = dto.wholesalerId;
        rtn.wholesalerName = dto.wholesalerName
        rtn.wholesalePrice = dto.wholesalePrice;
        rtn.wholesaleTotal = dto.wholesaleTotal;
        return rtn;
    }

    static transactionLineItemToTransationLineItemDto(
        entity: TransactionLineItem
    ): TransactionLineItemDto {
        const rtn = new TransactionLineItemDto();
        rtn.discount = entity.discount;
        rtn.gratisTotal = entity.gratisTotal;
        rtn.id = entity.id;
        rtn.netTotal = entity.netTotal;
        rtn.price = entity.price;
        rtn.wholesalePrice = entity.wholesalePrice;
        rtn.productDescription = entity.productDescription;
        rtn.productId = entity.productId;
        rtn.quantity = entity.quantity;
        rtn.wholesaleTotal = entity.wholesaleTotal;
        rtn.salesTotal = entity.salesTotal;
        rtn.type = TransactionLineItemTypeLookup.find((t) => t.id === entity.type);
        rtn.uin = entity.uin;
        rtn.units = entity.units;
        rtn.upc = entity.upc;
        rtn.wholesalerId = entity.wholesalerId;
        rtn.wholesalerName = entity.wholesalerName;

        return rtn;
    }
}
