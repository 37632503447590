<div #myFilter>
    <div class="d-flex justify-content-between align-items-center">
        <div class="w-100"><app-zrt-tree-view #zrtTree></app-zrt-tree-view></div>
    </div>
    <mat-checkbox class="checkbox-margin" [checked]="viewmodel.zrtByArea" (change)="onAreaChange($event.checked)"> By Area </mat-checkbox>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-purpose">Purpose
            </mat-label>
            <input #uinInput id="purpose-input" type="text" (keyup)="onAddPurposeRefiner($event)"
                [(ngModel)]="viewmodel.purposeInput" matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Status</mat-label>
            <mat-select #statusSelect
                [(value)]="viewmodel.selectedStatuses"
                (openedChange)="onOpenedStatusChange()"
                (selectionChange)="onSelectionStatusChange()"
                multiple>
                <mat-option *ngFor="let status of viewmodel.statuses" [value]="status">
                    {{ status.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-days-waiting">Days Waiting
            </mat-label>
            <input #uinInput id="purpose-days-waiting" type="text" (keyup)="onAddDaysWaitingRefiner($event)"
                [(ngModel)]="viewmodel.daysWaitingInput" digitOnly matInput />
        </mat-form-field>
    </div>
</div>

