import { Injectable } from "@angular/core";

import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarRef,
    MatSnackBarVerticalPosition,
    TextOnlySnackBar
} from "@angular/material/snack-bar";
import { newSequentialId } from "shield.shared";
import { LogService } from "./log.service";

@Injectable({
    providedIn: "root"
})
export class SnackbarService {
    horizontalPosition: MatSnackBarHorizontalPosition = "center";
    verticalPosition: MatSnackBarVerticalPosition = "top";

    constructor(
        private _snackBar: MatSnackBar,
        private _logService: LogService
    ) {}

    showError(message: string): void {
        const supportCode = this.generateSupportCode();
        this._logService.logException(new Error(message), supportCode);
        console.log(message, supportCode);

        // const msg = `Please try again, and if the problem persists, contact the help desk. SUPPORT CODE: ${supportCode}`

        this._snackBar.open(`${message}`, "OK", {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
        });
        
    }

    showInfo(
        message: string,
        isSticky: boolean = false
    ): MatSnackBarRef<TextOnlySnackBar> {
        let rtn: MatSnackBarRef<TextOnlySnackBar>;

        if (isSticky) {
            rtn = this._snackBar.open(message, "Info", {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                duration: 1500
            });
        } else {
            rtn = this._snackBar.open(message, "Info", {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                duration: 1500
            });
        }
        return rtn;
    }

    showWarning(
        message: string,
        isSticky: boolean = false
    ) {
        let rtn: MatSnackBarRef<TextOnlySnackBar>;

        if (isSticky) {
            rtn = this._snackBar.open(message, "OK", {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
        } else {
            rtn = this._snackBar.open(message, "OK", {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                duration: 5000
            });
        }
        return rtn;
    }

    showDebug(message: string): MatSnackBarRef<TextOnlySnackBar> {
        return this._snackBar.open(message, "Debug", {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 1500
        });
    }

    generateSupportCode(): string {
        return newSequentialId().substring(20,31);
    }
}
