import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild
} from "@angular/core";
import { PieSeriesComponent } from "@swimlane/ngx-charts";
import {
    BehaviorSubject,
  Observable,
  combineLatest,
  forkJoin,
  from,
  of
} from "rxjs";
import {
  filter,
  map,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
  tap,
  withLatestFrom
} from "rxjs/operators";
import { Customer } from "src/app/entity-models/customer.entity";
import { MsaDistributorVolumeResponse } from "src/app/entity-models/msa-distributor-volume-response.entity";
import { VolumeDelineationService } from "src/app/services/delineation-services/volume-delineation.service";
import {
  PieChartValue,
  Px3ChartCategoryName,
  Px3ChartSwisherCategoryName,
  toOtpPieChart,
  toShareTAShare,
  toSkuBreakdown
} from "src/app/shared/charts/chart-functions";
import { ShareTAShareData } from "src/app/shared/charts/share-tashare-chart/share-tashare-chart.component";
import { SkuBreakdownValue } from "src/app/shared/charts/sku-breakdown-chart/sku-breakdown-chart.component";

@Component({
  selector: 'px3-dashboard',
  templateUrl: './px3-dashboard.component.html',
  styleUrls: ['./px3-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Px3DashboardComponent {
  @HostBinding("class") class = "d-flex flex-column flex-grow-1";
  @ViewChild("SSCCLarge") ssccLargeRef: ElementRef;
  @ViewChild("SSCCFiltered") ssccFilteredRef: ElementRef;
  @ViewChild("SSCCLittle") ssccLittleRef: ElementRef;
  @ViewChild("MonPouch") monPouchRef: ElementRef;
  @ViewChild("Chew") chewRef: ElementRef;
  @ViewChild("Snuff") snuffRef: ElementRef;
  @ViewChild("BDMST") bdmstRef: ElementRef;

  @Input() customers$: Observable<Customer[]>;

  ngOnInit() {
    this.volumeResponse$ = this.customers$.pipe(
        map((customers) => customers.map((customer) => customer.volumeResponse
            ? of(customer.volumeResponse)
            : from(
                this.volumeDelineationService.loadForCustomer(customer.id)
            ).pipe(map((resp) => resp.values)))
        ),
        mergeMap((volumeResponse) => forkJoin(volumeResponse)),
        map((volumeResponse) =>
            volumeResponse.reduce(
                (acc, cur) => {
                    acc.brands.push(...cur.brands);
                    acc.volumes.push(...cur.volumes);
                    return acc;
                },
                { brands: [], volumes: [] } as MsaDistributorVolumeResponse
            )
        ),
        startWith({ brands: [], volumes: [] } as MsaDistributorVolumeResponse),
        shareReplay(1)
    );

    this.nearbyVolume$ = combineLatest([this.customers$, this.nearbyVolumeRadius$])
    .pipe(
        switchMap(([customers, radius]) =>
          Promise.all(
            customers.map((customer) =>
              this.volumeDelineationService.loadForCustomerByAvgArea(
                customer.id,
                radius
              )
            )
          )
        ),
        map((responses) =>
          responses.reduce(
            (acc, cur) => {
              acc.brands.push(...cur.values.brands);
              acc.volumes.push(...cur.values.volumes);
              return acc;
            },
            { brands: [], volumes: [] } as MsaDistributorVolumeResponse
          )
        ),
        startWith({ brands: [], volumes: [] } as MsaDistributorVolumeResponse),
        shareReplay(1)
      );

    this.categoryNames$ = combineLatest([this.volumeResponse$, this.nearbyVolume$]).pipe(
        map(([volumeResponse, nearbyVolume]) => ({brands: [...volumeResponse.brands, ...nearbyVolume.brands], volumes: [...volumeResponse.volumes, ...nearbyVolume.volumes]})),
        map(toOtpPieChart()),
        map((values) => new Set(values.map((value) => value.name))),
        shareReplay(1)
    )

    this.otpPieChart$ = this.volumeResponse$.pipe(
      map(toOtpPieChart()),
      shareReplay(1),
    );

    this.otpPieChartHasValues$ = this.otpPieChart$.pipe(
        map((values) => ({ hasValues: values.length > 0 })),
    )

    this.swisherOtpPieChart$ = this.volumeResponse$.pipe(
      map(toOtpPieChart(true)),
      map((values) =>
        values.map((value) => {
          switch (value.name) {
            case Px3ChartCategoryName.LargeCigars:
              return { ...value, name: Px3ChartSwisherCategoryName.LargeCigars };
            case Px3ChartCategoryName.MonPouches:
              return { ...value, name: Px3ChartSwisherCategoryName.MonPouches };
            case Px3ChartCategoryName.BdMst:
              return { ...value, name: Px3ChartSwisherCategoryName.BdMst };
            case Px3ChartCategoryName.Chew:
              return { ...value, name: Px3ChartSwisherCategoryName.Chew };
            case Px3ChartCategoryName.DrySnuff:
              return { ...value, name: Px3ChartSwisherCategoryName.DrySnuff };
            case Px3ChartCategoryName.FilteredCigars:
              return { ...value, name: Px3ChartSwisherCategoryName.FilteredCigars };
            case Px3ChartCategoryName.LittleCigars:
              return { ...value, name: Px3ChartSwisherCategoryName.LittleCigars };
            default:
              return value;
          }
        })
      ),
      shareReplay(1),
    );

    this.hasLargeCigars$ = this.createHasCategoryObservable(Px3ChartCategoryName.LargeCigars);
    this.hasMonPouches$ = this.createHasCategoryObservable(Px3ChartCategoryName.MonPouches);
    this.hasBdMst$ = this.createHasCategoryObservable(Px3ChartCategoryName.BdMst);
    this.hasChew$ = this.createHasCategoryObservable(Px3ChartCategoryName.Chew);
    this.hasDrySnuff$ = this.createHasCategoryObservable(Px3ChartCategoryName.DrySnuff);
    this.hasFilteredCigars$ = this.createHasCategoryObservable(Px3ChartCategoryName.FilteredCigars);
    this.hasLittleCigars$ = this.createHasCategoryObservable(Px3ChartCategoryName.LittleCigars);

    

    this.totalLargeShare$ = combineLatest([
      this.volumeResponse$,
      this.nearbyVolume$
    ]).pipe(map(toShareTAShare("SSCC Large", "Large Cigars")));

    this.traditionalNL$ = combineLatest([
      this.volumeResponse$,
      this.nearbyVolume$
    ]).pipe(
      map(
        toShareTAShare("SSCC TNL", "NL Traditional", {
          category: "Large Cigars",
          segment: "NL Traditional"
        })
      )
    );

    this.htlTip$ = combineLatest([this.volumeResponse$, this.nearbyVolume$]).pipe(
      map(([volumeResponse, nearbyVolume]) => [
        ...toShareTAShare("SSCC HTL TIP", "HTL Tip Total", {
          category: "Large Cigars",
          segment: "HTL Tip"
        })([volumeResponse, nearbyVolume]),
        ...toShareTAShare("SSCC NON Tip", "Non Tip Total", {
          category: "Large Cigars",
          segment: "HTL Non-Tip"
        })([volumeResponse, nearbyVolume])
      ])
    );

    this.roughCutNL$ = combineLatest([
      this.volumeResponse$,
      this.nearbyVolume$
    ]).pipe(
      map(
        toShareTAShare("SS Leaf", "NL Rough Cut", {
          category: "Large Cigars",
          segment: "NL Rough Cut"
        })
      )
    );

    this.value$ = combineLatest([this.volumeResponse$, this.nearbyVolume$]).pipe(
      map(
        toShareTAShare("Pom Pom", "Value Brand Large", {
          category: "Large Cigars",
          subSegment: "Value Brand"
        })
      )
    );

    this.largeSkuBreakdown$ = this.volumeResponse$.pipe(
      map(
        toSkuBreakdown([
          {
            chartLabel: "Total Large",
            swisherLabel: "SSCC Large",
            genericLabel: "Large Cigars"
          },
          {
            chartLabel: "Traditional NL",
            swisherLabel: "SSCC TNL",
            genericLabel: "NL Traditional",
            brandMatch: {
              category: "Large Cigars",
              segment: "NL Traditional"
            }
          },
          {
            chartLabel: "Rough Cut NL",
            swisherLabel: "SS Leaf",
            genericLabel: "NL Rough Cut",
            brandMatch: {
              category: "Large Cigars",
              segment: "NL Rough Cut"
            }
          },
          {
            chartLabel: "HTL Tip",
            swisherLabel: "SSCC HTL Tip",
            genericLabel: "HTL Tip Total",
            brandMatch: { category: "Large Cigars", segment: "HTL Tip" }
          },
          {
            chartLabel: "HTL Non-Tip",
            swisherLabel: "SSCC NON Tip",
            genericLabel: "Non Tip Total",
            brandMatch: {
              category: "Large Cigars",
              segment: "HTL Non-Tip"
            }
          },
          {
            chartLabel: "Value",
            swisherLabel: "Pom Pom",
            genericLabel: "Value Brand Large",
            brandMatch: {
              category: "Large Cigars",
              subSegment: "Value Brand"
            }
          }
        ])
      )
    );

    this.totalMonPouchShare$ = combineLatest([
      this.volumeResponse$,
      this.nearbyVolume$
    ]).pipe(
      map(
        toShareTAShare("Rogue Pouch", "MON Pouches", {
          category: "Modern Oral",
          segment: "MON",
          subSegment: "Pouch"
        })
      )
    );

    this.monPouchSkuBreakDown$ = this.volumeResponse$.pipe(
      map(
        toSkuBreakdown([
          {
            chartLabel: "Share of SKU",
            swisherLabel: "Rogue Pouch",
            genericLabel: "MON Pouches",
            brandMatch: {
              category: "Modern Oral",
              segment: "MON",
              subSegment: "Pouch"
            }
          }
        ])
      )
    );

    this.totalBdMstShare$ = combineLatest([
      this.volumeResponse$,
      this.nearbyVolume$
    ]).pipe(
      map(
        toShareTAShare("Fat Lip MST", "BD MST", {
          category: "MST",
          segment: "Branded Discount"
        })
      )
    );

    this.bdMstSkuBreakDown$ = this.volumeResponse$.pipe(
      map(
        toSkuBreakdown([
          {
            chartLabel: "Share of SKU",
            swisherLabel: "Fat Lip MST",
            genericLabel: "BD MST",
            brandMatch: { category: "MST", segment: "Branded Discount" }
          }
        ])
      )
    );

    this.totalChewShare$ = combineLatest([
      this.volumeResponse$,
      this.nearbyVolume$
    ]).pipe(
      map(
        toShareTAShare("Fat Lip Chew", "Chew", {
          category: "Loose Leaf/Chew"
        })
      )
    );

    this.chewSkuBreakDown$ = this.volumeResponse$.pipe(
      map(
        toSkuBreakdown([
          {
            chartLabel: "Share of SKU",
            swisherLabel: "Fat Lip Chew",
            genericLabel: "Chew",
            brandMatch: { category: "Loose Leaf/Chew" }
          }
        ])
      )
    );

    this.totalDrySnuffShare$ = combineLatest([
      this.volumeResponse$,
      this.nearbyVolume$
    ]).pipe(map(toShareTAShare("Fat Lip Dry Snuff", "Dry Snuff")));
    this.drySnuffSkuBreakDown$ = this.volumeResponse$.pipe(
      map(
        toSkuBreakdown([
          {
            chartLabel: "Share of SKU",
            swisherLabel: "Fat Lip Dry Snuff",
            genericLabel: "Dry Snuff",
            brandMatch: { category: "Dry Snuff" }
          }
        ])
      )
    );

    this.totalFilteredShare$ = combineLatest([
      this.volumeResponse$,
      this.nearbyVolume$
    ]).pipe(map(toShareTAShare("SSCC Filtered", "Filtered Cigars")));

    this.filteredSkuBreakdown$ = this.volumeResponse$.pipe(
      map(
        toSkuBreakdown([
          {
            chartLabel: "Share of SKU",
            swisherLabel: "SSCC Filtered",
            genericLabel: "Filtered Cigars",
            brandMatch: { category: "Filtered Cigars" }
          }
        ])
      )
    );

    this.totalLittleShare$ = combineLatest([
      this.volumeResponse$,
      this.nearbyVolume$
    ]).pipe(map(toShareTAShare("SSCC Little", "Little Cigars")));

    this.littleSkuBreakdown$ = this.volumeResponse$.pipe(
      map(
        toSkuBreakdown([
          {
            chartLabel: "Share of SKU",
            swisherLabel: "SSCC Little",
            genericLabel: "Little Cigars",
            brandMatch: { category: "Little Cigars" }
          }
        ])
      )
    );

    this.otpPieChartColorScheme$ = this.otpPieChart$.pipe(
      filter(values => values.length > 0),
      map((values) => ({ domain: values.map((value) => value.color) }))
    );

    this.swisherOtpPieChartColorScheme$ = this.swisherOtpPieChart$.pipe(
      filter(values => values.length > 0),
      map((values) => ({ domain: values.map((value) => value.color) }))
    );
  }
  private volumeResponse$: Observable<MsaDistributorVolumeResponse>;
  nearbyVolumeRadius$ = new BehaviorSubject<number>(1);
  otpPieChart$: Observable<PieChartValue[]>;
  otpPieChartHasValues$: Observable<{ hasValues: boolean; }>;
  swisherOtpPieChart$: Observable<PieChartValue[]>;

  private categoryNames$: Observable<Set<string>>;
  hasLargeCigars$: Observable<boolean>;
  hasMonPouches$: Observable<boolean>;
  hasBdMst$: Observable<boolean>;
  hasChew$: Observable<boolean>;
  hasDrySnuff$: Observable<boolean>;
  hasFilteredCigars$: Observable<boolean>;
  hasLittleCigars$: Observable<boolean>;

  private nearbyVolume$: Observable<MsaDistributorVolumeResponse>;

  totalLargeShare$: Observable<ShareTAShareData[]>;
  traditionalNL$: Observable<ShareTAShareData[]>;
  htlTip$: Observable<ShareTAShareData[]>;
  roughCutNL$: Observable<ShareTAShareData[]>;
  value$: Observable<ShareTAShareData[]>;

  largeSkuBreakdown$: Observable<SkuBreakdownValue[]>;

  totalMonPouchShare$: Observable<ShareTAShareData[]>;
  monPouchSkuBreakDown$: Observable<SkuBreakdownValue[]>;
  totalBdMstShare$: Observable<ShareTAShareData[]>;
  bdMstSkuBreakDown$: Observable<SkuBreakdownValue[]>;
  totalChewShare$: Observable<ShareTAShareData[]>;
  chewSkuBreakDown$: Observable<SkuBreakdownValue[]>;
  totalDrySnuffShare$: Observable<ShareTAShareData[]>;
  drySnuffSkuBreakDown$: Observable<SkuBreakdownValue[]>;
  totalFilteredShare$: Observable<ShareTAShareData[]>;
  filteredSkuBreakdown$: Observable<SkuBreakdownValue[]>;
  totalLittleShare$: Observable<ShareTAShareData[]>;
  littleSkuBreakdown$: Observable<SkuBreakdownValue[]>;
  otpPieChartColorScheme$: Observable<{ domain: string[] }>;
  swisherOtpPieChartColorScheme$: Observable<{ domain: string[] }>;

  constructor(
    private volumeDelineationService: VolumeDelineationService,
    private changeDetector: ChangeDetectorRef
  ) { }

  createHasCategoryObservable(categoryName: string): Observable<boolean> {
    return this.categoryNames$.pipe(
      map((values) => values.has(categoryName)),
      tap((_) => this.changeDetector.detectChanges())
    );
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  scrollToCategory(category: string) {
    let element: HTMLElement;
    switch (category) {
      case Px3ChartSwisherCategoryName.LargeCigars:
      case Px3ChartCategoryName.LargeCigars:
        element = this.ssccLargeRef?.nativeElement;
        break;
      case Px3ChartSwisherCategoryName.MonPouches:
      case Px3ChartCategoryName.MonPouches:
        element = this.monPouchRef?.nativeElement;
        break;
      case Px3ChartSwisherCategoryName.BdMst:
      case Px3ChartCategoryName.BdMst:
        element = this.bdmstRef?.nativeElement;
        break;
      case Px3ChartSwisherCategoryName.Chew:
      case Px3ChartCategoryName.Chew:
        element = this.chewRef?.nativeElement;
        break;
      case Px3ChartSwisherCategoryName.DrySnuff:
      case Px3ChartCategoryName.DrySnuff:
        element = this.snuffRef?.nativeElement;
        break;
      case Px3ChartSwisherCategoryName.FilteredCigars:
      case Px3ChartCategoryName.FilteredCigars:
        element = this.ssccFilteredRef?.nativeElement;
        break;
      case Px3ChartSwisherCategoryName.LittleCigars:
      case Px3ChartCategoryName.LittleCigars:
        element = this.ssccLittleRef?.nativeElement;
        break;
    }
    if (!element) return;
    element.scrollIntoView();
  }

  categoryClicked({ name }: PieChartValue) {
    this.scrollToCategory(name);
  }

  /**
   * Formats the label for the pie chart. Not intended to be called directly.
   */
  displayPercentageAsLabel(name: string) {
    const pieSeries = (this as unknown) as PieSeriesComponent;
    let value = Math.floor(
      pieSeries.series.find((series) => series.name === name).value
    );
    return `${value}%`;
  }

}