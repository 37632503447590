import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Employee } from "src/app/entity-models/employee.entity";
import { Helper } from "src/app/helpers/helper";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";

@Component({
    selector: "app-employee-dropdown",
    templateUrl: "./employee-dropdown.component.html",
    styleUrls: ["./employee-dropdown.component.scss"]
})
export class EmployeeDropdownComponent implements OnInit {
    @Input() offline = false;
    @Input() zrtOnly = false;

    private _singleSelection: boolean = true;
    @Input()
    get singleSelection(): boolean {
        return this._singleSelection;
    }
    set singleSelection(value: boolean) {
        this._singleSelection = value;
        this.employeeDropdownSettings.singleSelection = value;
        this.employeeDropdownSettings.showCheckbox = !value;
    }

    @Input()
    get preselected(): Employee[] {
        return this.selectedEmployees;
    }
    set preselected(value: Employee[]) {
        this.selectedEmployees = value;
    }

    private ids: string[];
    @Input()
    get preselectedIds(): string[] {
        return this.ids;
    }
    set preselectedIds(value: string[] | null) {
        if (!value) return;

        this.ids = value;
        if (this.employees?.length) {
            const selected = new Array<Employee>();
            for (const id of this.ids) {
                selected.push(this.employees.find((e) => e.id === id));
            }
            this.selectedEmployees = selected;
        }
    }

    private _employeeOptions: string[];
    @Input()
    get employeeOptions(): string[] {
        return this._employeeOptions;
    }
    set employeeOptions(value: string[]) {
        this._employeeOptions = value;

        if (value) {
            this.init();
        }
        void this.getEmployeeSelection(value);
    }

    @Output()
    selectionChange = new EventEmitter();

    @Output()
    deselectionChange = new EventEmitter();


    employees: Employee[] = [];
    employeeDropdownSettings = {
        singleSelection: this.singleSelection,
        text: "Employee",
        unSelectAllText: "Deselect All",
        enableCheckAll: false,
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        lazyLoading: true,
        labelKey: "fullName",
        searchBy: ["fullName"],
        showCheckbox: !this.singleSelection,
        autoPosition: false,
        noDataLabel: "Loading employees...",
        classes: "multi-select-container c-btn",
        badgeShowLimit: 2
    };
    selectedEmployees: Employee[] = [];

    constructor(private employeeDelineationService: EmployeeDelineationService) { }

    async init(): Promise<void> {

        const employeesResponse = await this.employeeDelineationService.getAll();
        if (!employeesResponse) { return; }

        const employees = this.zrtOnly
            ? employeesResponse.values.filter(v => !!v.zrt).map(v => {
                v.fullName = `${v.zrt} - ${v.fullName}`;
                return v;
            })
            : employeesResponse.values;
        if (this.employeeOptions?.length) {
            this.employees = employees.filter((e) => this.employeeOptions.includes(e.id));

        } else {
            this.employees = employees;
        }
        if (this.ids?.length) {
            const selected = new Array<Employee>();
            for (const id of this.ids) {
                selected.push(this.employees.find((e) => e.id === id));
            }
            this.selectedEmployees = selected;
        }

        this.employees = this.zrtOnly
            ? this.employees.sort((a, b) => a.zrt > b.zrt ? 1 : -1)
            : this.employees.sort((a, b) => a.fullName > b.fullName ? 1 : -1);
        this.filterEmpties();
    }


    deselectAll(): void {
        this.selectedEmployees = new Array<Employee>();
    }

    filterEmpties(): void {
        // Temp function to remove bad data from the dropdown.
        this.employees = this.employees.filter((e) => e.fullName);
    }

    async getEmployeeSelection(ids: string[]): Promise<void> {

            const response = await this.employeeDelineationService.getByIds(ids);
            if (!response) { return; }

            this.employees = response.values;
    }

    onSelect(employee?: Employee, employees?: Employee[]): void {
        if (this.singleSelection) {
            this.selectionChange.emit(employee);
        } else {
            this.selectionChange.emit(employees);
        }
    }

    onDeselect(employee?: Employee): void {
        if (this.singleSelection) {
            this.selectionChange.emit(null);
        } else {
            this.deselectionChange.emit(employee);
        }
    }

    ngOnInit(): void {
        this.init();
    }
}
