import { Component, OnInit } from "@angular/core";
import {
    faArrowDown,
    faArrowUp,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { BehaviorSubject } from "rxjs";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { VolumeDataService } from "../volume-data.service";

@Component({
    selector: "app-wholesale-volume",
    templateUrl: "./wholesale-account-volume.component.html",
    styleUrls: ["./wholesale-account-volume.component.scss"]
})
export class WholesaleAccountVolumeComponent implements OnInit {
    faArrowDown: IconDefinition = faArrowDown;
    faArrowUp: IconDefinition = faArrowUp;
    shouldWait$ = new BehaviorSubject<boolean>(false);

    constructor(
        public volumeData: VolumeDataService,
        private customerStateService: CustomerStateService,
        private pleaseWaitService: PleaseWaitService
    ) {}

    async ngOnInit(): Promise<void> {

        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        await this.volumeData.loadForCustomer(
            this.customerStateService.customer.id
        );

        this.shouldWait$.next(true);
    }
}
