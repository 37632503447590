import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectComponent } from './project.component';
import { ProjectRoutingModule } from './project.routing.module';
import { ProjectStateService } from '../../services/project-state-service';
import { ProjectSummaryComponent } from './project-configuration/project-summary/project-summary.component';
import { ProjectMetricsComponent } from './project-metrics/project-metrics.component';
import { ProjectConfigurationComponent } from './project-configuration/project-configuration.component';
import { ProjectApplicationService } from './project-services/project-application.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectStepperComponent } from './project-stepper/project-stepper.component';
import { ProjectDefinitionComponent } from './project-configuration/project-definition/project-definition.component';
import { ProjectStoresComponent } from './project-configuration/project-stores/project-stores.component';
import { ProjectPeopleComponent } from './project-configuration/project-people/project-people.component';
import { ProjectAssignmentsComponent } from './project-configuration/project-assignments/project-assignments.component';
import { ProjectProductsComponent } from './project-configuration/project-products/project-products.component';
import { ProjectActivitiesAndSurveysComponent } from './project-configuration/project-activities-and-surveys/project-activities-and-surveys.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { ProjectSummaryStoresComponent } from './project-configuration/project-summary/project-summary-stores/project-summary-stores.component';
import { ProjectSummaryReleaseDatesComponent } from './project-configuration/project-summary/project-summary-release-dates/project-summary-release-dates.component';
import { ProjectSummaryProductsComponent } from './project-configuration/project-summary/project-summary-products/project-summary-products.component';
import { ProjectSummaryAssignmentsComponent } from './project-configuration/project-summary/project-summary-assignments/project-summary-assignments.component';
import { ProjectSummaryOrderDatesComponent } from './project-configuration/project-summary/project-summary-order-dates/project-summary-order-dates.component';
import { ProjectProductOrderDatesComponent } from './project-configuration/project-products/project-product-order-dates/project-product-order-dates.component';
import { ProjectValidationSummaryComponent } from './project-validation-summary/project-validation-summary.component';
import { AddProjectActivityDialogComponent } from './project-configuration/project-activities-and-surveys/add-project-activity-dialog/add-project-activity-dialog.component';
import { ViewProjectActivityDialogComponent } from './project-configuration/project-activities-and-surveys/view-project-activity-dialog/view-project-activity-dialog.component';
import { ProjectSummaryActivitiesSurveysComponent } from './project-configuration/project-summary/project-summary-activities-surveys/project-summary-activities-surveys.component';
import { ProjectAssignmentRefinerService } from './project-configuration/project-assignments/project-assignment-refiner.service';
import { ProjectStoresRefinerService } from './project-configuration/project-stores/project-stores-refiner.service';


@NgModule({
  declarations: [
    ProjectComponent,
    ProjectSummaryComponent,
    ProjectMetricsComponent,
    ProjectConfigurationComponent,
    ProjectStepperComponent,
    ProjectDefinitionComponent,
    ProjectStoresComponent,
    ProjectPeopleComponent,
    ProjectAssignmentsComponent,
    ProjectProductsComponent,
    ProjectActivitiesAndSurveysComponent,
    ProjectSummaryStoresComponent,
    ProjectSummaryReleaseDatesComponent,
    ProjectSummaryProductsComponent,
    ProjectSummaryAssignmentsComponent,
    ProjectSummaryOrderDatesComponent,
    ProjectProductOrderDatesComponent,
    ProjectValidationSummaryComponent,
    AddProjectActivityDialogComponent,
    ViewProjectActivityDialogComponent,
    ProjectSummaryActivitiesSurveysComponent
  ],
  imports: [
    CommonModule,
    ProjectRoutingModule,
    FontAwesomeModule,
    SharedModule,
    MatSlideToggleModule,
    MatCardModule,
    MatButtonToggleModule
  ],
  providers: [ProjectStateService, ProjectApplicationService,
    ProjectAssignmentRefinerService, ProjectStoresRefinerService],
  exports: [
      ProjectSummaryComponent,
      ProjectSummaryOrderDatesComponent,
      ProjectSummaryProductsComponent,
      ProjectSummaryActivitiesSurveysComponent
  ]
})
export class ProjectModule { }
