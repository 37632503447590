import { Injectable } from "@angular/core";
import { ApplicationInsights, IEventTelemetry, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { environment } from "../../environments/environment";
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';

@Injectable({
    providedIn: "root"
})

@Injectable()
export class LogService {
    appInsights: ApplicationInsights;

    constructor() {
        var angularPlugin = new AngularPlugin();

        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.appInsights.instrumentationKey,
                enableCorsCorrelation: true,
                extensions: [angularPlugin],
                correlationHeaderExcludedDomains: ["*.googleapis.com"],
            }
        });
        this.appInsights.loadAppInsights();
        this.appInsights.addTelemetryInitializer(this.telemetryInitializer);
    }

    telemetryInitializer = (envelope: any) => {
        const environment = this.getCookieValue('x-ms-routing-name') || 'production';
        envelope.data['slot'] = environment;
    }

    getCookieValue(key: string) {
        const cookie = document.cookie
            .split('; ')
            .find(cookie => cookie.startsWith(key));

        return cookie ? cookie.split('=')[1] : null;
    }

    logPageView(name?: string, url?: string) { // option to call manually
        this.appInsights.trackPageView({
            name: name,
            uri: url
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }

    logException(exception: Error, supportCode: string, severityLevel?: number) {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel }, { supportCode: supportCode });
    }

    logWarning(message: string, supportCode: string) {
        let event: IEventTelemetry = { name: message, properties: { type: "Custom Warning", message: message } }
        this.appInsights.trackEvent(event, { supportCode: supportCode });
    }

    logTrace(message: string, supportCode: string) {
        this.appInsights.trackTrace({ message: message }, { supportCode: supportCode });
    }


}
