import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import * as _ from "lodash";
import { newSequentialId } from "shield.shared";
import { ProductUpc } from "src/app/entity-models/product-upc.entity";
import { WholesalerGroupEntryMember } from "src/app/entity-models/wholesaler-group-entry-member.entity";
import { WholesalerGroupEntry } from "src/app/entity-models/wholesaler-group-entry.entity";
import { WholesalerGroupProductCatalogItem } from "src/app/entity-models/wholesaler-group-product-catalog-item.entity";
import { AddProductViewModel } from "src/app/products/add-products/add-product.viewmodel";
import { WholesalerProductsListViewModel } from "./wholesaler-products-list.viewmodel";
import { WholesalerGroupProductCatalogItemDelineationService } from "src/app/services/delineation-services/wholesaler-group-product-catalog-item-delineation.service";

export class WholesalerGroupProductsListViewModel extends WholesalerProductsListViewModel<WholesalerGroupProductCatalogItem> {

    readonly errorMessage = `Make sure all new or edited products have a unique UIN# &
        at least one wholesaler linked before you are able to save.`;

    constructor(
        public canEdit: boolean,
        public group: WholesalerGroupEntry,
        public groupCatalogItems: WholesalerGroupProductCatalogItem[],
        public groupMembers?: WholesalerGroupEntryMember[],
        public groupMember?: WholesalerGroupEntryMember,
    ) {
        super(canEdit, group, groupCatalogItems);

        this.headerLeftText = `Wholesaler Group Products — Group Level — ${group.groupName}`;
        this.buttonLeftText = "Close";
        this.buttonLeftDisabledFunction = () => false;

        if (this.canEdit) {
            this.headerRightButtonText = 'Add Product to Group';
            this.buttonRightText = "Save";
            this.buttonRightFunction = () => this.save$.emit();
        }
    }

    async exportToXlsx(catalogItemService: WholesalerGroupProductCatalogItemDelineationService)
    : Promise<Blob> {
        return catalogItemService.getProductCatalogExport(this.group.id);
    }

    getGroupItem(item: WholesalerGroupProductCatalogItem): WholesalerGroupProductCatalogItem {
        return item;
    }

    createFormRow(
        form: FormGroup,
        builder: FormBuilder,
        item: WholesalerGroupProductCatalogItem,
        isNew: boolean = false,
    ): FormGroup {
        return builder.group({
            id: [item.id],
            productUIN: [
                item.productUIN, [
                    Validators.required,
                    (ctrl: AbstractControl) => this.validateUnique(form, ctrl)
                ],
            ],
            itemNumber: [item.product.itemNumber],
            deactivate: [false],
            groupItem: [item],
            isNew: [isNew],
            dateAvailable: [item.dateAvailable],
            wholesalerCount: [
                item.wholesalerCount,
                (ctrl: AbstractControl) => this.validateWholesalerCount(form, ctrl),
            ],
        });
    }
    addProducts(selected: AddProductViewModel[], form: FormGroup, builder: FormBuilder): void {
        const formItems = form.get('items') as FormArray;
        const toAdd = selected.map(p => {
            const newItem = new WholesalerGroupProductCatalogItem();
            newItem.id = newSequentialId();
            newItem.wholesalerGroupId = this.group.id;
            newItem.productId = p.product.id;
            newItem.product = p.product;
            newItem.wholesalerCount = 0;
            newItem.wholesalerItems = [];
            return newItem;
        });
        this.items = [
            ...toAdd,
            ...this.items,
        ];
        toAdd.reverse().forEach(i => {
            formItems.insert(0, this.createFormRow(form, builder, i));
            formItems.controls[0].get('wholesalerCount').markAsDirty();
            formItems.controls[0].get('productUIN').markAsTouched();
        });
    }

    updateItems(form: FormGroup): WholesalerGroupProductCatalogItem[] {
        //Update the model items with form values.
        const currentItemsMap = new Map(this.items.map(i => [i.product.itemNumber, i]));
        const formItems = (form.controls['items'] as FormArray).controls
            .filter(g => g.touched)
            .map(g => g.value);
        const toSave: WholesalerGroupProductCatalogItem[] = [];
        for (const i of formItems) {
            const toUpdate = currentItemsMap.get(i.itemNumber);
            if (toUpdate) {
                const updated = {
                    ...toUpdate,
                    productUIN: i.productUIN,
                };

                updated.wholesalerCount = i.wholesalerCount;
                updated.dateAvailable = i.dateAvailable;

                if (i.deactivate) {
                    updated.isDeactivated = true;
                    updated.isDeactivatedDate = new Date();

                    //Deactivate all group-level items
                    updated.wholesalerItems.forEach(wi => {
                        wi.isDeactivated = true;
                        wi.isDeactivatedDate = new Date();
                    });
                }
                toSave.push(updated);
            }
        }

        //Check the assigned wholesalers in each item to see if any of them are the same
        //as the original loaded items
        for (const item of this.items) {
            const originalItem = this.items.find(i => i.id === item.id);
            if (!originalItem) {
                continue;
            }
            for (const wholesaler of item.wholesalerItems) {
                const originalWholesalerCatalogItem = originalItem.wholesalerItems.find(wi =>
                    wi.wholesalerGroupProductCatalogItemId === wholesaler.wholesalerGroupProductCatalogItemId &&
                    wi.wholesalerId === wholesaler.wholesalerId &&
                    wi.productId === wholesaler.productId
                );
                if (originalWholesalerCatalogItem) {
                    //Unchanged, so assign the original ID to the new object
                    wholesaler.id = originalWholesalerCatalogItem.id;
                }
            }
        }

        return toSave;
    }

    private validateWholesalerCount(form: FormGroup, ctrl: AbstractControl): ValidationErrors {
        if (!form || !ctrl.parent) {
            return null;
        }

        return ctrl.parent.value['deactivate'] ? null : Validators.min(1)(ctrl);
    }
}
