import { Customer } from "src/app/entity-models/customer.entity";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { OverlayService } from "src/app/services/overlay.service";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators
} from "@angular/forms";
import { Subscription } from "rxjs";
import { TransactionLineItemType, LicenseTypes, TransactionLineItemTypeLookup, EmailReceiptLocationOrigins } from "shield.shared";
import { InvoiceDetailsComponent } from "./invoice-details.component";
import { SnackbarService } from "src/app/services/snackbar.service";
import { Receipt } from "src/app/entity-models/receipt";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { Employee } from "src/app/entity-models/employee.entity";
import { ShareOptionsDialogViewmodel } from "src/app/dialogs/share-options-dialog/share-options-dialog.viewmodel";
import { ShareOptionsDialogComponent } from "src/app/dialogs/share-options-dialog/share-options-dialog.component";
import { TransactionLineItemViewmodel } from "./tranasaction-line-item.viewmodel";
import { TransactionLineItem } from "src/app/entity-models/transaction-line-item.entity";
import { Transaction } from "src/app/entity-models/transaction.entity";
import { TransactionDelineationService } from "src/app/services/delineation-services/transaction-delineation.service";
import { ReceiptDelineationService } from "src/app/services/delineation-services/receipt-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { NotificationDelineationService } from "src/app/services/delineation-services/notification-delineation.service";
import { CallEventLoggerService, ReceiptRenderLocation, ReceiptRenderType } from "src/app/accounts/call-master/call-services/call-event-logger.service";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";

export class InvoiceDetailsViewmodel {
    private formBuilder: FormBuilder;

    header = "Invoice";
    headerLeftText = "Invoice";
    showFooter = true;
    headerRightText: string;
    buttonLeftText = "Cancel";
    buttonLeftFunction: () => void;
    buttonRightText = "Save";
    buttonRightFunction: () => void;
    buttonRightDisabledFunction: () => boolean;
    buttonLeftDisabledFunction: () => boolean;
    width = "75vw";
    cloaseable = false

    employee: Employee;
    shareEmployees: Employee[];
    shareComments: string;
    receiptNumber: string;
    swisherLicenseNumber: string;
    cashSalesItems: TransactionLineItemViewmodel[] = new Array<TransactionLineItemViewmodel>();
    exchangeOutItems: TransactionLineItemViewmodel[] = new Array<TransactionLineItemViewmodel>();
    exchangeInItems: TransactionLineItemViewmodel[] = new Array<TransactionLineItemViewmodel>();
    transactionLineItems: TransactionLineItem[] = new Array<TransactionLineItem>();

    retailTotal: number;
    wholesaleTotal: number;
    maxQuantityNumber = 500;
    maxUnitNumber = 500;
    maxPriceNumber = 500;
    priceFormControls: FormControl[] = new Array<FormControl>();
    isInitialized = false;
    isReadOnly = true;
    receiptsEmailed = false;
    isOnlyOrderLineItems = false;

    productDelineationService: ProductDelineationService;
    overlayService: OverlayService;
    customerDelineationService: CustomerDelineationService;
    receiptDelineationService: ReceiptDelineationService;
    snackbarService: SnackbarService;
    transactionDelineationService: TransactionDelineationService;
    notificationDelineationService: NotificationDelineationService

    retailStateOptLicense: string;

    isConfirmed = false;
    receipts: Receipt[];
    transaction: Transaction;
    customer: Customer;

    private confirmationOverlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    private shareOverlayRef: SwisherOverlayRef<
        ShareOptionsDialogViewmodel,
        ShareOptionsDialogComponent
    >;

    overlayRef: SwisherOverlayRef<
        InvoiceDetailsViewmodel,
        InvoiceDetailsComponent
    >;

    cashSalesForm: FormGroup;
    exchangeOutSalesForm: FormGroup;
    exchangeInSalesForm: FormGroup;
    cashSalesFormSubscriptions = new Array<Subscription>();
    cashDiscountFormSubscriptions = new Array<Subscription>();
    exchangeOutWholesaleFormSubscriptions = new Array<Subscription>();
    exchangeInWholesaleFormSubscriptions = new Array<Subscription>();

    constructor(
        productDelineationService: ProductDelineationService,
        overlayService: OverlayService,
        formBuilder: FormBuilder,
        customerDelineationService: CustomerDelineationService,
        snackbarService: SnackbarService,
        transactionDelineationService: TransactionDelineationService,
        receiptDelineationService: ReceiptDelineationService,
        employee: Employee,
        transaction: Transaction,
        customer: Customer,
        notificationDelineationService: NotificationDelineationService,
        private eventLogger: CallEventLoggerService,
        private callService: CallDelineationService,
    ) {
        this.productDelineationService = productDelineationService;
        this.overlayService = overlayService;
        this.formBuilder = formBuilder;
        this.customerDelineationService = customerDelineationService;
        this.snackbarService = snackbarService;
        this.transactionDelineationService = transactionDelineationService;
        this.receiptDelineationService = receiptDelineationService;
        this.employee = employee;
        this.transaction = transaction;
        this.customer = customer;
        this.notificationDelineationService = notificationDelineationService;

        this.cashSalesForm = this.formBuilder.group({});
        this.exchangeOutSalesForm = this.formBuilder.group({});
        this.exchangeInSalesForm = this.formBuilder.group({});

        const customerLicense = customer.customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.RetailStateOTPLicense && cl.isActive);
        if (customerLicense) {
            this.retailStateOptLicense = customerLicense.licenseNumber;
        }

        this.transactionLineItems = this.transaction?.transactionLineItems ?? new Array<TransactionLineItem>();

        this.receipts = this.transaction?.receipts ?? new Array<Receipt>();

        const cashProducts = this.transaction?.transactionLineItems.filter((tlt) => tlt.type === TransactionLineItemType.CashSale
            || tlt.type === TransactionLineItemType.Gratis)

        for (let i = 0; i < cashProducts.length; i++) {
            this.addTransactionLineItem(cashProducts[i], i)
        }

        const exchangeOutProducts = this.transaction?.transactionLineItems.filter((tlt) => tlt.type === TransactionLineItemType.ExchangeOut);
        for (let i = 0; i < exchangeOutProducts.length; i++) {
            this.addTransactionLineItem(exchangeOutProducts[i], i);
        }

        const exchangeInProducts = this.transaction?.transactionLineItems.filter((tlt) => tlt.type === TransactionLineItemType.ExchangeIn);
        for (let i = 0; i < exchangeInProducts.length; i++) {
            this.addTransactionLineItem(exchangeInProducts[i], i);
        }

        this.isOnlyOrderLineItems = this.cashSalesItems.length == 0 &&
            this.exchangeInItems.length == 0 &&
            this.exchangeOutItems.length == 0;

        this.buttonLeftFunction = () => {

            this.setBlocking();

            if (this.overlayRef.blocking
                && !this.buttonRightDisabledFunction()) {
                if (this.confirmationOverlayRef) {
                    this.confirmationOverlayRef.close();
                }
                const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                data.header = "Confirmation - Unsaved Changes";
                data.message =
                    "Changes have not been saved, do you wish to proceed?";
                data.buttonLeftText = "Proceed";
                data.buttonLeftFunction = () => {
                    this.confirmationOverlayRef.close();
                    this.overlayRef.blocking = false;
                    this.overlayRef.close();
                };
                data.buttonRightText = "Save & Proceed";
                data.buttonRightFunction = () => {
                    this.overlayRef.blocking = false;
                    this.overlayRef.close();
                    this.confirmationOverlayRef.close();
                    this.save();
                };
                data.buttonRightDisableFunction = () => {

                    return this.buttonRightDisabledFunction();
                }

                this.confirmationOverlayRef = this.overlayService.open(
                    ConfirmationDialogComponent,
                    data
                );
                this.confirmationOverlayRef.afterClosed$.subscribe(() => {
                    this.confirmationOverlayRef = undefined;
                });
            } else {
                this.overlayRef.blocking = false;
                this.overlayRef.close(this.overlayRef.data);
            }
        };
        this.buttonRightFunction = async () => {
            await this.save();
            this.overlayRef.blocking = false;
            this.overlayRef.close(this.overlayRef.data);
        }
        this.buttonRightDisabledFunction = () => {
            let rtn = false;

            if (
                this.cashSalesForm.invalid ||
                this.isReadOnly
            ) {
                rtn = true;
            }

            return rtn;
        };
    }

    setBlocking(): void {

        let blocking = false;
        const transactionLineItems = [...this.cashSalesItems, ...this.exchangeInItems, ...this.exchangeOutItems];
        for (let i = 0; i < transactionLineItems.length; i++) {
            if (!this.isItemEqual(transactionLineItems[i])) {
                blocking = true;
                break;
            }
        }

        this.overlayRef.blocking = blocking;
    }

    async save(): Promise<void> {
        const lineItems = new Map([...this.cashSalesItems, ...this.exchangeInItems, ...this.exchangeOutItems].map((li) => [li.id, li])); // Cash sales include gratis
        this.transactionLineItems.forEach((t) => {
            let foundItem: TransactionLineItemViewmodel | undefined;
            if (lineItems.has(t.id)) {
                foundItem = lineItems.get(t.id);
            }
            if (foundItem) {
                t.quantity = foundItem.qty;
                t.units = foundItem.units;
                t.price = foundItem.retailPrice ?? null;
                t.wholesalePrice = foundItem.wholesalePrice ?? null;
                t.discount = foundItem.discount;

            }
        });

        const results = await this.transactionDelineationService.saveInvoiceDetails(
            this.transactionLineItems, this.transaction.id
        );

        if (results) {
            if (results.isError) {
                this.snackbarService.showError(results.message);
            }
        }

        this.isConfirmed = true;
        this.overlayRef.close(this);
    }

    addTransactionLineItem(lineItem: TransactionLineItem, index: number): void {
        const item = new TransactionLineItemViewmodel();
        item.id = lineItem.id;
        item.discount = lineItem.discount;
        item.oldDiscount = item.discount;
        item.name = lineItem.productDescription;
        item.qty = lineItem.quantity;
        item.oldQty = item.qty;
        item.units = lineItem.units;
        item.oldUnits = item.units;
        item.retailPrice = lineItem.price;
        item.oldRetailPrice = item.retailPrice;
        item.wholesalePrice = lineItem.wholesalePrice;
        item.oldWholesalePrice = item.wholesalePrice;
        item.netTotal = lineItem.netTotal;
        item.type = lineItem.type;
        item.typeLabel = TransactionLineItemTypeLookup.find((tlt) => tlt.id === lineItem.type).description;

        switch (lineItem.type) {
            case TransactionLineItemType.Gratis:
            case TransactionLineItemType.CashSale:
                this.cashSalesItems.push(item);
                const formControl = new FormControl("", [
                    Validators.min(item.discount ?? 0)
                ]);
                this.priceFormControls.push(formControl);
                this.cashSalesForm.addControl(
                    "cashPrice" + index,
                    new FormControl()
                );
                this.cashSalesForm.controls["cashPrice" + index].setValidators([
                    this.isPriceGreaterThanDiscount(index)
                ]);
                this.cashSalesForm.controls["cashPrice" + index].setValue(
                    item.retailPrice
                );
                this.cashSalesForm.controls[
                    "cashPrice" + index
                ].updateValueAndValidity();

                const discountFormControl = new FormControl("", [
                    Validators.max(item.discount ?? 0)
                ]);
                this.priceFormControls.push(formControl);
                this.cashSalesForm.addControl(
                    "discountPrice" + index,
                    new FormControl()
                );
                this.cashSalesForm.controls["discountPrice" + index].setValidators([
                    this.isPriceGreaterThanDiscount(index)
                ]);
                this.cashSalesForm.controls["discountPrice" + index].setValue(
                    item.discount
                );
                this.cashSalesForm.controls[
                    "discountPrice" + index
                ].updateValueAndValidity();
                break;
            case TransactionLineItemType.ExchangeIn:
                this.exchangeInItems.push(item);
                this.exchangeInSalesForm.addControl(
                    "exchangeInWholesalePrice" + index,
                    new FormControl()
                );
                this.exchangeInSalesForm.controls[
                    "exchangeInWholesalePrice" + index
                ].setValue(item.wholesalePrice);
                this.exchangeInSalesForm.controls[
                    "exchangeInWholesalePrice" + index
                ].updateValueAndValidity();
                break;
            case TransactionLineItemType.ExchangeOut:
                this.exchangeOutItems.push(item);
                this.exchangeOutSalesForm.addControl(
                    "exchangeOutWholesalePrice" + index,
                    new FormControl()
                );
                this.exchangeOutSalesForm.controls[
                    "exchangeOutWholesalePrice" + index
                ].setValue(item.wholesalePrice);
                this.exchangeOutSalesForm.controls[
                    "exchangeOutWholesalePrice" + index
                ].updateValueAndValidity();
                break;
            default:
                break;
        }
    }

    isPriceGreaterThanDiscount(i: number): ValidatorFn {
        return (): { [key: string]: boolean } | null => {
            let rtn = false;
            let discount = this.cashSalesForm.controls["discountPrice" + i]
                ?.value;
            let price = this.cashSalesForm.controls["cashPrice" + i]?.value;
            if ((price ?? 0) < (discount ?? 0)) {
                rtn = true;
            }

            return rtn ? { fail: true } : null;
        };
    }

    calculateRetailTotal(): void {
        let retailTotal = 0;
        for (const item of this.cashSalesItems) {
            retailTotal =
                retailTotal + item.qty * (item.retailPrice - (item.discount ?? 0));
        }

        this.retailTotal = retailTotal;
        this.setBlocking();
    }

    calculateWholesaleTotal(): void {
        //Out - in
        let wholesaleTotal = 0;
        let items = this.exchangeOutItems;
        for (const item of items) {
            wholesaleTotal =
                wholesaleTotal - item.qty * (item.wholesalePrice ?? 0);
        }

        items = this.exchangeInItems;
        for (const item of items) {
            wholesaleTotal =
                wholesaleTotal + item.qty * (item.wholesalePrice ?? 0);
        }
        this.wholesaleTotal = wholesaleTotal;
        this.setBlocking();
    }

    decrementItemQuantity(
        item: TransactionLineItemViewmodel
    ): void {
        if (item.qty > 1) {
            item.oldQty = item.qty;
            item.qty--;
            this.calculateRetailTotal();
            this.calculateWholesaleTotal();
        }
    }

    decrementSaleUnit(
        item: TransactionLineItemViewmodel
    ): void {
        if (item.units > 1) {
            item.oldUnits = item.units;
            item.units--;
            this.calculateRetailTotal();
            this.calculateWholesaleTotal();
        }
    }

    async print(): Promise<void> {
        if (this.receipts) {
            Helper.addIFrameImage(document, this.receipts.map((r) => r.base64Image));
            const call = (await this.callService.getCallById(this.transaction.callId)).values;
            const customer =
                (await this.customerDelineationService.getById(this.transaction.customerId)).values;
            this.eventLogger.logReceiptRender(
                ReceiptRenderType.Print,
                ReceiptRenderLocation.Invoice,
                call,
                customer,
            );
        }
    }

    async email(): Promise<void> {
        const data: ShareOptionsDialogViewmodel = new ShareOptionsDialogViewmodel();
        data.shareEmployees = this.shareEmployees;
        data.confirmButtonText = "Share Receipt";
        data.comments = this.shareComments;
        data.customerId = this.customer.id;
        data.onClose = (closeData: ShareOptionsDialogViewmodel) => {
            this.shareComments = closeData.comments;
            this.shareEmployees = closeData.shareEmployees;
        }
        data.onSaveShare = async (
            saveData: ShareOptionsDialogViewmodel
        ): Promise<boolean> => {
            this.shareComments = saveData.comments;
            let isSuccessfull = true;

            if (this.receipts?.length) {
                if (saveData.shareEmployees) {
                    const ids = saveData.shareEmployees.map((e) => e.id);

                    this.receiptsEmailed = true;
                    if (ids.length > 0) {
                        for (const employeeId of ids) {
                            const response = await this.notificationDelineationService.emailReceipt(employeeId, this.transaction.id,
                                this.receipts[0].id, this.receiptNumber, EmailReceiptLocationOrigins.invoice, this.shareComments);
                            if (response) {
                                isSuccessfull = isSuccessfull && !!response;
                            }
                        }
                    }
                }

                if (saveData.shareCustomerContact) {
                    const response = await this.notificationDelineationService.emailReceiptToCustomerContact(saveData.shareCustomerContact.email, this.transaction.id,
                        this.receipts[0].id, this.receiptNumber, EmailReceiptLocationOrigins.invoice, this.shareComments);
                    if (response) {
                        isSuccessfull = isSuccessfull && !!response;
                    }
                }

                const call = (await this.callService.getCallById(this.transaction.callId)).values;
                const customer =
                    (await this.customerDelineationService.getById(this.transaction.customerId)).values;
                this.eventLogger.logReceiptRender(
                    ReceiptRenderType.Email,
                    ReceiptRenderLocation.Invoice,
                    call,
                    customer,
                );

                return isSuccessfull;
            }
            return undefined;
        }
        this.shareOverlayRef = this.overlayService.open(
            ShareOptionsDialogComponent,
            data
        );

        this.shareOverlayRef.afterClosed$.pipe().subscribe(async (ref) => {
            if (ref?.data?.promise) {
                const isSuccess = await ref.data.promise;
                if (isSuccess) {
                    this.snackbarService.showInfo("Email sent successfully!");
                }
            }
        })
    }

    async initialize(): Promise<void> {
        this.calculateRetailTotal();
        this.calculateWholesaleTotal();

        this.isInitialized = true;
        setTimeout(() => {
            this.setValidations();
        }, 0);
    }

    incrementItemQuantity(
        item: TransactionLineItemViewmodel
    ): void {
        if (item.qty + 1 >= this.maxQuantityNumber) {
            this.openQuantityWarning(item);
        } else if (item.qty < this.maxQuantityNumber) {
            item.qty++;
            item.oldQty = item.qty;
            this.calculateRetailTotal();
            this.calculateWholesaleTotal();
        }
    }

    incrementSaleUnit(
        item: TransactionLineItemViewmodel
    ): void {
        if (item.units + 1 >= this.maxUnitNumber) {
            this.openUnitWarning(item);
        } else if (item.units < this.maxUnitNumber) {
            item.oldUnits = item.units;
            item.units++;
            this.calculateRetailTotal();
            this.calculateWholesaleTotal();
        }
        this.setBlocking();
    }

    private isItemEqual(item: TransactionLineItemViewmodel): boolean {
        const found = this.transactionLineItems.find(
            (cp) => cp.id === item.id
        );

        return (
            !!found &&
            found.discount == item.discount &&
            found.price == item.retailPrice &&
            found.units === item.units &&
            found.quantity === item.qty &&
            found.wholesalePrice == item.wholesalePrice
        );
    }

    openCashPriceWarning(product: TransactionLineItemViewmodel): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "You have set the Price higher than the typical value. Are you sure you would like to proceed?";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            product.retailPrice = product.oldRetailPrice ?? 0;
            product.discount = product.oldDiscount ?? 0;
            this.confirmationOverlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.confirmationOverlayRef.close(data);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe((event) => {
            if (event && event.data) {
                const index = this.cashSalesItems.findIndex(
                    (csi) => csi.id === product.id
                );
                if (event.data.isConfirmed) {
                    product.oldRetailPrice = product.retailPrice;
                    product.oldDiscount = product.discount;
                    this.cashSalesForm.controls["cashPrice" + index].setValue(
                        product.retailPrice
                    );
                } else {
                    this.cashSalesForm.controls["cashPrice" + index].setValue(
                        product.oldRetailPrice ?? product.retailPrice
                    );
                    this.cashSalesForm.controls["discountPrice" + index].setValue(
                        product.oldDiscount ?? product.discount
                    );
                }
                this.calculateRetailTotal();
            }
        });
    }

    openCashPriceGreaterThanDiscountWarning(
        product: TransactionLineItemViewmodel
    ): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "You have set the Price higher than the discount value. Are you sure you would like to proceed? Doing so will zero out the discount";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            product.retailPrice = product.oldRetailPrice ?? 0;
            product.discount = product.oldDiscount ?? 0;
            this.confirmationOverlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.confirmationOverlayRef.close(data);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe((event) => {
            if (event && event.data && event.data.isConfirmed) {
                product.oldRetailPrice = product.retailPrice;
                product.oldDiscount = product.discount;
                this.calculateRetailTotal();
            }
        });
    }

    openQuantityWarning(
        product: TransactionLineItemViewmodel
    ): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "You have set the quantity higher than the typical value. Are you sure you would like to proceed?";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            product.qty = product.oldQty;
            this.confirmationOverlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.confirmationOverlayRef.close(data);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe((event) => {
            if (event && event.data && event.data.isConfirmed) {
                if (product.oldQty === product.qty) {
                    product.qty++;
                }
                product.oldQty = product.qty;
                this.calculateRetailTotal();
                this.calculateWholesaleTotal();
            }
        });
    }

    openUnitWarning(
        product: TransactionLineItemViewmodel
    ): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "You have set the Sticks/Units higher than the typical value. Are you sure you would like to proceed?";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            product.units = product.oldUnits;
            this.confirmationOverlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.confirmationOverlayRef.close(data);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe((event) => {
            if (event && event.data && event.data.isConfirmed) {
                if (product.oldUnits === product.units) {
                    product.units++;
                }
                product.oldUnits = product.units;
                this.calculateRetailTotal();
                this.calculateWholesaleTotal();
            }
        });
    }

    select(element: HTMLInputElement): void {
        Helper.selectInputText(element);
    }

    setValidations(): void {
        for (let i = 0; i < this.cashSalesItems.length; i++) {
            this.cashSalesFormSubscriptions[
                i
            ] = this.cashSalesForm.controls[
                "cashPrice" + i
            ].valueChanges.subscribe((value) => {
                this.cashSalesItems[i].retailPrice = value ?? 0;
                if (!value && value !== 0) {
                    this.cashSalesForm.controls["cashPrice" + i].setValue(
                        0
                    );
                }
                if (
                    !this.cashSalesForm.controls["cashPrice" + i]?.hasError(
                        "fail"
                    ) &&
                    this.cashSalesForm.controls[
                        "discountPrice" + i
                    ]?.hasError("fail")
                ) {
                    this.cashSalesForm.controls[
                        "discountPrice" + i
                    ].updateValueAndValidity();
                }
            });
            this.cashDiscountFormSubscriptions[
                i
            ] = this.cashSalesForm.controls[
                "discountPrice" + i
            ]?.valueChanges.subscribe((value) => {
                this.cashSalesItems[i].discount = value ?? 0;
                if (!value && value !== 0) {
                    this.cashSalesForm.controls[
                        "discountPrice" + i
                    ].setValue(0);
                }
                if (
                    !this.cashSalesForm.controls[
                        "discountPrice" + i
                    ]?.hasError("fail") &&
                    this.cashSalesForm.controls["cashPrice" + i]?.hasError(
                        "fail"
                    )
                ) {
                    this.cashSalesForm.controls[
                        "cashPrice" + i
                    ].updateValueAndValidity();
                }
            });
        }

        for (let i = 0; i < this.exchangeOutItems.length; i++) {
            this.exchangeOutWholesaleFormSubscriptions[
                i
            ] = this.exchangeOutSalesForm.controls[
                "exchangeOutWholesalePrice" + i
            ]?.valueChanges.subscribe((value) => {
                this.exchangeOutItems[i].wholesalePrice = value ?? 0;
                if (!value && value !== 0) {
                    this.exchangeOutSalesForm.controls[
                        "exchangeOutWholesalePrice" + i
                    ].setValue(0);
                }
            });
        }

        for (let i = 0; i < this.exchangeInItems.length; i++) {
            this.exchangeInWholesaleFormSubscriptions[
                i
            ] = this.exchangeInSalesForm.controls[
                "exchangeInWholesalePrice" + i
            ]?.valueChanges.subscribe((value) => {
                this.exchangeInItems[i].wholesalePrice = value ?? 0;
                if (!value && value !== 0) {
                    this.exchangeInSalesForm.controls[
                        "exchangeInWholesalePrice" + i
                    ].setValue(0);
                }
            });
        }
    }

    validationSalePrice(item: TransactionLineItemViewmodel): void {
        if (item.retailPrice !== item.oldRetailPrice && !this.isReadOnly) {
            item.retailPrice = Helper.validateMin(item.retailPrice, 0, true);

            if (item.retailPrice >= this.maxPriceNumber) {
                this.openCashPriceWarning(item);
            } else {
                item.oldRetailPrice = item.retailPrice;
                this.calculateRetailTotal();
            }
        }
    }

    validationSaleQuantity(
        item: TransactionLineItemViewmodel
    ): void {
        if (item.qty !== item.oldQty) {
            item.qty = Helper.validateMin(item.qty);

            if (item.qty >= this.maxQuantityNumber) {
                this.openQuantityWarning(item);
            } else {
                item.oldQty = item.qty;
                this.calculateRetailTotal();
            }
        }
    }

    validationSaleUnit(
        item: TransactionLineItemViewmodel
    ): void {
        if (item.units !== item.oldUnits) {
            item.units = Helper.validateMin(item.units);

            if (item.units >= this.maxUnitNumber) {
                this.openUnitWarning(item);
            } else {
                item.oldUnits = item.units;
                this.calculateRetailTotal();
            }
        }
    }
}
