import { GenericLookup } from "../generic";
import { SurveyTypes } from "../lookup";
import { SurveyCustomerMemberDto } from "./survey-customer-member-dto";
import { SurveyQuestionDto } from "./survey-question-dto";

export class SurveyDto {
    id: string;
    title: string;
    description: string | null
    isActive: boolean
    surveyType: GenericLookup<SurveyTypes>
    startDate: string | null;
    endDate: string | null;
    createdUtcDateTime: string | null;
    createdUserId: string | null;
    createdUserZrt: string | null;
    createdUserFullName: string | null;
    modifiedUtcDateTime: string | null;
    modifiedUserId: string | null;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string | null;
    rowversion: string;
    surveyQuestions: SurveyQuestionDto[];
    surveyCustomerMembers: SurveyCustomerMemberDto[];
}
