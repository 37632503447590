import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DatabaseService } from "../../services/database.service";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import {
    CallTypes,
    NotificationRequestDto,
    newSequentialId,
    EmailAttachment,
    CallSyncCommand,
    PictureSyncCommand,
    ReceiptSyncCommand,
    SyncCommandTypes,
    PictureDataDto,
    ReceiptDataDto,
    NotificationSyncCommand,
    receiptForCall
} from "shield.shared";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { CallConverterService } from "src/app/services/converter-services/call-converter.service";
import { Receipt } from "src/app/entity-models/receipt";
import { Picture } from "src/app/entity-models/picture.entity";
import { SyncLevel } from "../sync-enums/sync-level.enum";

@Injectable()
export class CallDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {
    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService,
    ) {
        super();
    }

    onlineRepSyncType = SyncLevel.None;
    offlineRepSyncType = SyncLevel.Required;
    isRunSuccessfull = false;

    async execute(): Promise<void> {
        return; // No longer pulling calls just pushing
    }

    async pushData(): Promise<void> {
        await this.pushCallData();
        await this.pushCallPictures();
        await this.pushCallReceipts();
    }

    private async pushCallData(): Promise<void> {
        return this.syncQueue.process<CallSyncCommand>(
            SyncCommandTypes.call,
            async (message) => {
                let call = await this.dbService.calls.get(message.payload.id);
                const customer = await this.dbService.customers.get(call.customerId);

                if (call) {
                    let dto = CallConverterService.callToCallDto(call, customer.customerWholesalers);
                    await this.syncQueue.pushCommand(dto, message);
                }


                if (call.callType !== CallTypes.chainHq) {
                    for (const pic of call.callPictures) {
                        await this.syncQueue.enqueue(
                            new PictureSyncCommand(pic.id, message.payload.id)
                        );
                    }

                    if (call.callType === CallTypes.retail || call.callType === CallTypes.rmWholesale) {
                        const notification = new NotificationRequestDto();
                        notification.id = newSequentialId();
                        notification.employeeId = call.createdUserId;
                        notification.sendEmail = true;
                        notification.subject = receiptForCall;
                        notification.message = "";
                        notification.attachments = [];
                        notification.callId = call.id;
                        notification.sendToSelf = true;
                        notification.recipientOtherEmail = call.selectedContact?.email;
                        for (const receipt of call.callReceipts) {
                            if (call.stopTime &&
                                ((call.isEmailFinalRetailReceipt && (receipt.receiptNumberExtention === "00" || receipt.receiptNumberExtention === "01")) ||
                                    (call.isEmailFinalWholesaleReceipt && receipt.receiptNumberExtention !== "00" && receipt.receiptNumberExtention !== "01"))
                            ) {
                                const attachment = new EmailAttachment;
                                attachment.name = `Swisher Receipt ${receipt.receiptNumber}${receipt.receiptNumberExtention}.pdf`;
                                attachment.type = "application/pdf";
                                notification.attachments.push(attachment);

                                await this.syncQueue.enqueue(
                                    new ReceiptSyncCommand(
                                        receipt.id,
                                        message.payload.id,
                                    )
                                );
                            } else {
                                await this.syncQueue.enqueue(
                                    new ReceiptSyncCommand(
                                        receipt.id,
                                        message.payload.id
                                    )
                                );
                            }
                        }
                        if (notification.attachments.length > 0) {
                            await this.dbService.notificationRequests.put(notification);
                            await this.syncQueue.enqueue(
                                new NotificationSyncCommand(
                                    notification.id,
                                    notification
                                )
                            );
                        }
                    }
                }
            }
        );
    }

    public async pushCallReceipts(): Promise<void> {
        return this.syncQueue.process<ReceiptSyncCommand>(
            SyncCommandTypes.receipt,
            async (message) => {
                const receipt: Receipt = await this.dbService.receipts.get(message.payload.id);

                const data = new ReceiptDataDto();
                data.callId = message.payload.callId;
                data.receiptId = message.payload.id;
                data.notification = message.payload.notification;
                data.image = receipt?.base64Image;

                if (receipt?.base64Image) {
                    this.syncQueue.pushCommand(data, message);
                } else {
                    throw new Error(`Can not find local receipt payload for receiptId:
                        ${data.receiptId} on callId: ${data.callId}`);
                }
            }
        );
    }

    public async pushCallPictures(): Promise<void> {
        return this.syncQueue.process<PictureSyncCommand>(
            SyncCommandTypes.picture,
            async (message) => {
                const picture: Picture = await this.dbService.pictures.get(message.payload.id);

                const data = new PictureDataDto();
                data.callId = message.payload.callId;
                data.pictureId = message.payload.id;
                data.image = picture?.image;

                if (picture?.image) {
                    this.syncQueue.pushCommand(data, message);
                } else {
                    throw new Error(`Can not find local picture payload for pictureId:
                        ${data.pictureId} on callId: ${data.callId}`);
                }
            }
        );
    }
}
