import { GenericLookup } from "../generic";
import { EmployeeLevel, GratisApprovalStatuses } from "../lookup";

export class GratisApprovalDto {
    id: string;
    employeeLevel: GenericLookup<EmployeeLevel>;
    employeeId: string;
    approvalStatus: GenericLookup<GratisApprovalStatuses>;
    date: string | null;
    reason: string;
}
