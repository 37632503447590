import { ElementRef } from "@angular/core";
import {
    IconDefinition,
    faEye,
    faPrint,
    faCloudDownloadAlt,
    faCaretDown,
    faCalendarAlt,
    faRoad
} from "@fortawesome/free-solid-svg-icons";
import { StepsInRouteDialogComponent } from "../../dialogs/steps-in-route-dialog/steps-in-route-dialog.component";
import { StepsInRouteViewModel } from "../../dialogs/steps-in-route-dialog/steps-in-route.viewmodel";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { OverlayService } from "src/app/services/overlay.service";
import { AppStateService } from "src/app/services/app-state.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { map, skipWhile } from "rxjs/operators";
import { Route } from "src/app/entity-models/route.entity";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { RouteViewmodel } from "./routeViewmodel";
import { Address } from "src/app/entity-models/address.entity";
import { RouteStop } from "src/app/entity-models/route-stop.entity";
import { RouteStopViewmodel } from "./routeStopViewmodel";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { Router } from "@angular/router";
import * as moment from "moment";
import { formatDate } from "@angular/common";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { MatDrawerMode, MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import { ColumnSelector } from "src/app/shared/viewmodels/column-selector.viewmodel";
import { EmployeeRoleType, FilterSortDto, RefinerLocation, RouteListColumns, SharedHelper, SortDirection, Subsidiary } from "shield.shared";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn } from "@angular/forms";
import { Moment } from "moment";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { RouteManagementRefinerService } from "./route-management-refiner.service";
import { ColumnVisabilityButtonComponent } from "src/app/shared/page-header/buttons/column-visability-button/column-visability-button.component";
import { ExcelExportButtonComponent } from "src/app/shared/page-header/buttons/excel-export-button/excel-export-button.component";
import { ButtonActions } from "src/app/shared/enums/button-actions.enum";
import { PrintButtonComponent } from "src/app/shared/page-header/buttons/print-button/print-button.component";
import { RouteDateInformationFilterComponent } from "src/app/shared/filters/route-date-information-filter/route-date-information-filter.component";
import { RouteInformationFilterComponent } from "src/app/shared/filters/route-information-filter/route-information-filter.component";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { RouteButtonComponent } from "src/app/shared/page-header/buttons/route-button/route-button.component";
import { RouteManagementService } from "./route-management.service";
import { RouteDelineationService } from "src/app/services/delineation-services/route-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { CallHistoryEntry } from "src/app/entity-models/call-history-entry.entity";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { MatSortHeader } from "@angular/material/sort";
import { DelineationStates } from "src/app/enums/delineation-states";
import { PingService } from "src/app/services/ping.service";
import { RouteManagementZrtFilterService } from "./route-management-zrt-filter.service";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { ZrtTreeViewComponent } from "src/app/shared/zrt-tree-view/zrt-tree-view.component";
import { Helper } from "src/app/helpers/helper";

export class RouteManagementViewmodel {
    faEye: IconDefinition = faEye;
    faPrint: IconDefinition = faPrint;
    faCloudDownloadAlt: IconDefinition = faCloudDownloadAlt;
    faCaretDown: IconDefinition = faCaretDown;
    faCalendarAlt: IconDefinition = faCalendarAlt;
    faRoad: IconDefinition = faRoad;

    stepsInRouteOverlayRef: SwisherOverlayRef<
        StepsInRouteViewModel,
        StepsInRouteDialogComponent
    >;

    private formBuilder: FormBuilder;
    pleaseWaitService: PleaseWaitService;
    overlayService: OverlayService;
    appStateService: AppStateService;
    pingService: PingService;
    callDelineationService: CallDelineationService;
    customerDelineationService: CustomerDelineationService;
    refinerService: RouteManagementRefinerService;
    routeDelineationService: RouteDelineationService;
    routeManagementService: RouteManagementService;
    router: Router;
    employeeDelineationService: EmployeeDelineationService;
    filterService: FilterService;
    zrtFilterService: RouteManagementZrtFilterService;

    activeRefiners: Refiner[] = [];
    activeSorts: FilterSortDto<RouteListColumns>[] = [];
    availableColumns: ColumnSelector[];
    baseGridYOffset = 0;
    columnDef: ColumnDef[];
    columnsToDisplay: string[];
    currentRefinerMap: Map<RefinerLocation, string> = new Map();
    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    dateForm: FormGroup;
    dateFormat = MY_DATE_FORMATS.display.customDateInput;
    delineationState = DelineationStates.online;
    delineationStates = DelineationStates;
    descriptionInput: string;
    detailHeight = 48;
    drawer: MatSidenav;
    drawerMode: MatDrawerMode = "side";
    employee: Employee;
    endDateInput: ElementRef;
    endMinDate: moment.Moment = moment().subtract(3, "years");
    expandPanelsOnInit = true;
    filterDataLoaded = false;
    filters: FilterAndParams[] = [];
    grid: GridComponent;
    gridData = new Array<GridData>();
    gridHeight = "75vh";
    gridheightOffsetDeduction = 0;
    header: PageHeaderComponent;
    headerButtons: HeaderButtonComponent[] = new Array<HeaderButtonComponent>();
    headerName = "Route List";
    isFixedLayout = false;
    isGapSet = false;
    isSearchButtonDisabled = false;
    itemsRendedInViewPort = 13;
    nameInput: string;
    pageIndex = 0;
    pageSize = 50;
    previousRefinerMap: Map<RefinerLocation, string> = new Map();
    refinerGreaterThans = [RefinerLocation.routeOnOrAfterDate];
    refinerLessThans = [RefinerLocation.routeOnOrBeforeDate];
    routes: RouteViewmodel[];
    screenHeight: number = 0;
    selectedColumnVisibility: string[];
    selectedColumnVisibilityComunicator: string[];
    shouldWait$ = new BehaviorSubject<boolean>(true);
    showExpanderToggle = false;
    sideNavContent: MatSidenavContent;
    startDateDefault: moment.Moment = moment().subtract(7, "days");
    startDateInput: ElementRef;
    startMaxDate: moment.Moment = moment().add(3, "years");
    topGapDistance = 0;
    total?: number;


    isOnlineSubscription: Subscription;
    refinerServiceSubscription: Subscription;
    refinerInputChangeSubscription: Subscription;
    gridDataChangeSubscription: Subscription;
    subscriptionEmployee: Subscription;
    zrtSelectionSubscription: Subscription;

    readonly sortFunction = (columnDef: ColumnDef) => {
        if (this.grid) {
            const filterSorts = new Array<FilterSortDto<RouteListColumns>>();
            this.grid.sort.sortables.forEach((item) => {
                let sortDirection: SortDirection;
                switch ((item as MatSortHeader)._sort.direction) {
                    case "asc":
                        sortDirection = SortDirection.ascending;
                        break;
                    case "desc":
                        sortDirection = SortDirection.descending;
                        break;
                    default:
                        sortDirection = SortDirection.none;
                        break;
                }
                const sortDto = new FilterSortDto<RouteListColumns>();
                sortDto.direction = sortDirection;
                const headerName = this.columnDef.find(
                    (cd) => cd.dataPropertyName === item.id
                ).headerName;
                if (
                    headerName === columnDef.headerName &&
                    sortDirection != SortDirection.none
                ) {
                    sortDto.column = headerName as RouteListColumns;
                    filterSorts.push(sortDto);
                }
            });
            this.activeSorts = filterSorts;
            void this.loadRoutes();
        }
    };

    constructor(
        pleaseWaitService: PleaseWaitService,
        overlayService: OverlayService,
        appStateService: AppStateService,
        pingService: PingService,
        callDelineationService: CallDelineationService,
        customerDelineationService: CustomerDelineationService,
        refinerService: RouteManagementRefinerService,
        formBuilder: FormBuilder,
        routeDelineationService: RouteDelineationService,
        routeManagementService: RouteManagementService,
        router: Router,
        employeeDelineationService: EmployeeDelineationService,
        filterService: FilterService,
        zrtFilterService: RouteManagementZrtFilterService
    ) {
        this.pleaseWaitService = pleaseWaitService;
        this.overlayService = overlayService;
        this.appStateService = appStateService;
        this.pingService = pingService;
        this.callDelineationService = callDelineationService;
        this.customerDelineationService = customerDelineationService;
        this.refinerService = refinerService;
        this.formBuilder = formBuilder;
        this.routeDelineationService = routeDelineationService;
        this.routeManagementService = routeManagementService;
        this.router = router;
        this.employeeDelineationService = employeeDelineationService;
        this.filterService = filterService;
        this.zrtFilterService = zrtFilterService;

        this.dateForm = this.formBuilder.group({
            endDate: ["", [this.lessThanStartDate()]],
            startDate: ["", [this.greaterThanEndDate()]]
        });
    }

    async initialize(
        grid: GridComponent,
        header: PageHeaderComponent,
        sideNavContent: MatSidenavContent,
        drawer: MatSidenav,
        startDateInput: ElementRef,
        endDateInput: ElementRef,
        zrtTree: ZrtTreeViewComponent
    ): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        this.grid = grid;
        this.header = header;
        this.sideNavContent = sideNavContent;
        this.drawer = drawer;
        this.startDateInput = startDateInput;
        this.endDateInput = endDateInput;

        if (zrtTree) {
            zrtTree.zrtFilterService = this.zrtFilterService;
        }

        const routeButtonComponent = new RouteButtonComponent();
        this.headerButtons.push(routeButtonComponent);

        const columnVisabilityComponent = new ColumnVisabilityButtonComponent();
        this.headerButtons.push(columnVisabilityComponent);

        const printButtonComponent = new PrintButtonComponent();
        this.headerButtons.push(printButtonComponent);

        const excelExportButtonComponent = new ExcelExportButtonComponent();
        this.headerButtons.push(excelExportButtonComponent);

        this.columnDef = [
            {
                headerName: "Route Id",
                dataPropertyName: "id",
                isAvailable: false,
                isSelected: false
            }, {
                headerName: RouteListColumns.zrt,
                dataPropertyName: "zrt",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: RouteListColumns.routeName,
                dataPropertyName: "name",
                isAvailable: true,
                isSelected: true,
                clickFunction: this.onNavigateToRouteDetails()
            },
            {
                headerName: RouteListColumns.routeDate,
                dataPropertyName: "dateFormatted",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: RouteListColumns.description,
                dataPropertyName: "description",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: RouteListColumns.startAddress,
                dataPropertyName: "startAddress",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: RouteListColumns.endAddress,
                dataPropertyName: "endAddress",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: RouteListColumns.stopsInRoute,
                dataPropertyName: "totalStops",
                isAvailable: true,
                isSelected: true,
                isTemplate: true,
                clickFunction: this.onOpenStepsInRoute()
            },
            {
                headerName: RouteListColumns.completedStopsInRoute,
                dataPropertyName: "completedStops",
                isAvailable: true,
                isSelected: true
            }
        ];
        const tempFilters: FilterAndParams[] = [];

        tempFilters.push({ filter: RouteInformationFilterComponent, zrtFilterService: this.zrtFilterService });
        tempFilters.push({ filter: RouteDateInformationFilterComponent });

        this.filters = tempFilters;

        const availableColumns = new Array<ColumnSelector>();

        this.columnsToDisplay = this.columnDef
            .filter((cd) => {
                if (cd.isAvailable) {
                    const column: ColumnSelector = {
                        name: cd.headerName,
                        isSelected: cd.isSelected,
                        columns: null
                    };
                    availableColumns.push(column);
                }
            })
            .map((cd) => cd.headerName);
        this.availableColumns = availableColumns;

        if (this.refinerService.areDefaultsSet) {
            this.populateInputsFromRefiners();
        }

        if (
            !this.isOnlineSubscription ||
            this.isOnlineSubscription.closed
        ) {
            this.isOnlineSubscription = this.pingService.online.subscribe(() => {
                this.delineationState = this.callDelineationService.getDelineationState();
            });
        }

        if (
            !this.refinerServiceSubscription ||
            this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription = this.refinerService.refiners$.pipe(
                skipWhile(() => !this.filterDataLoaded && !this.refinerService.areDefaultsSet)
            ).subscribe(
                () => {
                    this.onRefinersChange();
                }
            );
        }

        if (
            !this.refinerInputChangeSubscription ||
            this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription = this.refinerService.refinerInputChange$.subscribe(
                (refiner) => {
                    if (refiner) {
                        this.setInputFromRefiner(refiner);
                        if (refiner.shouldSearchWhenCleared && !refiner.value) {
                            this.getRouteBatch(0);
                        }
                    }

                    if (this.header) {
                        if (this.refinerService.refiners.length === 0) {
                            this.header.expanded = false;
                        } else {
                            this.header.expanded = true;
                        }
                    }
                    this.isGapSet = false;
                }
            );
        }

        if (
            !this.gridDataChangeSubscription ||
            this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription = this.grid.dataSourceChange.subscribe(
                (dc) => {
                    if (dc) {
                        this.shouldWait$.next(true);
                    }
                }
            );
        }

        if (!this.subscriptionEmployee || this.subscriptionEmployee.closed) {
            this.subscriptionEmployee = this.appStateService.currentEmployee
                .pipe(
                    map(async (employee) => {
                        this.employee = employee;
                        if (employee) {
                            await this.setFilterData();
                            await this.getRouteBatch(0);
                        } else {
                            this.routes = [];
                        }
                    })
                )
                .subscribe();
        }

        if (
            !this.zrtSelectionSubscription ||
            this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription = this.zrtFilterService.observableSelectedZrtsHandleRefiners(this.refinerService).subscribe();
        }
    }

    calculateGap(): void {
        if (this.sideNavContent) {
            setTimeout(() => {
                this.topGapDistance =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.top ?? 0;
                this.gridheightOffsetDeduction =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.bottom ??
                    0;
                if (
                    this.baseGridYOffset === 0 &&
                    this.gridheightOffsetDeduction !== 0
                ) {
                    this.baseGridYOffset = this.gridheightOffsetDeduction;
                }
                this.gridHeight =
                    75 * (this.screenHeight / 100) +
                    (this.baseGridYOffset - this.gridheightOffsetDeduction) +
                    "px";
            }, 0);
            this.isGapSet = true;
        }
    }

    checkSearchError(): void {
        if (this.dateForm) {
            if (
                this.dateForm
                    .get("endDate")
                    .hasError("dateLessThanStartDate") ||
                this.dateForm
                    .get("startDate")
                    .hasError("dateGreaterThanEndDate")
            ) {
                this.isSearchButtonDisabled = true;
            } else {
                this.isSearchButtonDisabled = false;
            }
        }
    }

    async getRouteBatch(index: number, force?: boolean) {
        const areRefinersTheSame = this.resolvePageIndex(index);
        if (
            this.total != this.gridData?.length ||
            !areRefinersTheSame ||
            force
        ) {
            await this.loadRoutes();
        }
    }

    greaterThanEndDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            const momentStart: Moment = control.value as Moment;

            if (momentStart) {
                const startDate = momentStart.startOf("day").valueOf();
                this.endMinDate = momentStart;

                if (this.dateForm.controls.endDate.value) {
                    const endDate = (this.dateForm.controls.endDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (startDate > endDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.endMinDate = null;
            }

            this.checkSearchError();

            return forbidden ? { dateGreaterThanEndDate: true } : null;
        };
    }

    lessThanStartDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            if (control.value) {
                const endDate = (control.value as Moment)
                    .startOf("day")
                    .valueOf();
                this.startMaxDate = control.value;

                if (this.dateForm.controls.startDate.value) {
                    const startDate = (this.dateForm.controls.startDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (endDate < startDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.startMaxDate = moment().add(3, "years");
            }

            this.checkSearchError();

            return forbidden ? { dateLessThanStartDate: true } : null;
        };
    }

    async loadRoutes(): Promise<void> {
        if (!this.employee || !this.refinerService.areDefaultsSet) return;

        this.pleaseWaitService.showProgressSpinnerUntilLoaded(
            this.shouldWait$
        );
        const routesResponse = await this.routeDelineationService.getBatch(
            this.employee.id,
            this.employee.searchableZrt,
            this.refinerService.refiners,
            this.pageSize,
            this.pageIndex * this.pageSize,
            this.activeSorts
        );
        if (!routesResponse) {
            this.shouldWait$.next(false);
            return;
        }

        let routeVms = this.pageIndex ? this.routes.slice() : new Array<RouteViewmodel>();
        for (const route of routesResponse.values) {
            const vm = await this.routeToRouteVm(route);
            if (vm) {
                routeVms.push(vm);
            }
        }
        this.routes = routeVms;

        this.total = routesResponse.getCount();
        this.isGapSet = false;

        let gridData = new Array<GridData>();

        for (const route of this.routes) {
            const gridItem: GridData = {
                data: route,
                detailArrayName: "",
                isExpanded: false,
                index: this.routes.indexOf(route)
            };
            gridData.push(gridItem);
        }
        this.gridData = gridData;

        this.dataSource = new TableVirtualScrollDataSource(
            this.gridData
        );
    }

    formatAddress(address: Address): string {
        let cityStateZip;
        let addressText;
        if (address?.city && address?.state && address?.zip) {
            cityStateZip = `${address.city}, ${address.state} ${address.zip}`;
        }
        if (address?.address1) {
            addressText = `${address.address1} ${address.address2 ?? ""}`.trim();
        }
        if (cityStateZip && addressText) return `${addressText}, ${cityStateZip}`;
        if (cityStateZip) return `${cityStateZip}`;
        if (addressText) return `${addressText}`;
    }

    getCompletedStops(routeDate: Date, calls: CallHistoryEntry[]): number {
        const completedCalls = calls.filter(
            (call) => call.stopTime?.setHours(0, 0, 0, 0) === routeDate.setHours(0, 0, 0, 0));
        return completedCalls?.length ?? 0;
    }

    resolvePageIndex(index: number): boolean {
        this.currentRefinerMap = new Map<RefinerLocation, string>();
        for (const refiner of this.refinerService.refiners ?? []) {
            this.currentRefinerMap.set(refiner.location, refiner.dataValue ?? refiner.value);
        }

        const rtn = SharedHelper.compareMaps(
            this.currentRefinerMap,
            this.previousRefinerMap
        );

        if (rtn && this.total != this.gridData?.length && index !== 0) {
            this.pageIndex++;
        } else {
            this.previousRefinerMap = new Map(this.currentRefinerMap);
            this.pageIndex = 0;
        }
        return rtn;
    }

    async routeToRouteVm(route: Route): Promise<RouteViewmodel> {
        const stopCount = route.stops.length;
        const firstStop = route.stops.find(v => v.sequence === 0);
        const lastStop = route.stops.find(v => v.sequence === stopCount - 1);

        const vm: RouteViewmodel = {
            id: route.id,
            name: route.name,
            description: route.description,
            date: route.date,
            dateFormatted: moment(route.date).format(
                MY_DATE_FORMATS.display.dateInput
            ),
            zrt: route.employeeZrt ?? this.employee?.zrt,
            startAddress: this.formatAddress(firstStop?.address),
            endAddress: this.formatAddress(lastStop?.address),
            totalStops: `<a>${stopCount} <span class="flaticon-eye-variant-with-enlarged-pupil"></span></a>`,
            completedStops: route.completedStops
        };

        return vm;
    }

    routeStopToRouteStopVm(
        stop: RouteStop,
        customer: Customer
    ): RouteStopViewmodel {
        return {
            customerId: customer?.id,
            avail: customer?.availability,
            chainName: customer?.chain,
            indVolume: customer?.industryVolume ?? 0,
            lastCall: customer?.lastCall ? formatDate(
                customer.lastCall,
                this.dateFormat,
                "en-US"
            ) : "",
            msaStore: customer ? (customer.isMsa ? "Y" : "N") : "",
            zrt: customer?.zrt,
            customerType: customer?.customerType,
            name: stop.label ?? customer?.name,
            shareVolume: customer?.shieldVolume ?? 0,
            sharePercentage: customer?.industryVolume ?
                customer?.shieldVolume / customer?.industryVolume : 0,
            address: stop.address.address1 ? `${stop.address.address1} ${stop.address.address2 ?? ""}`.trim() : "",
            city: stop.address.city,
            state: stop.address.state,
            county: stop.address.county,
            zip: stop.address.zip
        };
    }

    onAddNameRefiner(): void {
        if (this.nameInput) {
            this.refinerService.addRefiner(
                RefinerLocation.routeName,
                this.nameInput,
                "name"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.routeName
            )
        }
    }

    onAddDescriptionRefiner(): void {
        if (this.descriptionInput) {
            this.refinerService.addRefiner(
                RefinerLocation.routeDescription,
                this.descriptionInput,
                "description"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.routeDescription
            )
        }
    }

    onAddEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.endDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, MY_DATE_FORMATS.display.dateInput) &&
            !this.dateForm.controls.endDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.routeOnOrBeforeDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.routeOnOrBeforeDate,
                this.endDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.endDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.routeOnOrBeforeDate,
                    this.endDateInput.nativeElement.value,
                    "dateFormatted"
                );
            }
        } else {
            if (!date && event?.type === "blur") {
                this.dateForm.controls.endDate.setValue(null);
                this.refinerService.removeRefinerByLocation(
                    RefinerLocation.routeOnOrBeforeDate,
                    true, false
                );
            }
        }
    }

    onAddStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.startDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, MY_DATE_FORMATS.display.dateInput) &&
            !this.dateForm.controls.startDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.routeOnOrAfterDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.routeOnOrAfterDate,
                this.startDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.startDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.routeOnOrAfterDate,
                    this.dateForm.controls.startDate.value?.format(
                        this.startDateInput.nativeElement.value
                    ),
                    "dateFormatted"
                );
            }
        } else {
            if (!date && event?.type === "blur") {
                this.dateForm.controls.startDate.setValue(null);
                this.refinerService.removeRefinerByLocation(
                    RefinerLocation.routeOnOrAfterDate,
                    true, false
                );
            }
        }
    }

    onButtonAction(value: any): void {
        switch (value) {
            case ButtonActions.route:
                this.routeDelineationService.editRoute(new Route());
                break;
            case ButtonActions.columnVisibility:
                this.selectedColumnVisibilityComunicator = value;
                break;
            case ButtonActions.print:
                this.grid.onPrint();
                break;
            case ButtonActions.exportToExcel:
                this.grid.onExportAsExcel();
                break;
            default:
                break;
        }
    }

    onButtonClickEvent(value: HeaderButtonComponent) {
        if (value.onClick) {
            value.onClick();
        }
    }

    onReset(): void {
        this.refinerService.resetRefiners();
    }

    onHeaderExpansionChange(): void {
        this.isGapSet = false;
    }

    onOpenStepsInRoute(): (event: MouseEvent, index: number) => void {
        return async (event: MouseEvent, index: number) => {
            this.openStepsInRoute(this.gridData[index].data.id)
        }
    }

    onNavigateToRouteDetails(): (event: MouseEvent, index: number) => void {
        return async (event: MouseEvent, index: number) => {
            this.navigateToRouteDetails(this.gridData[index].data.id)
        }
    }

    onRefinersChange() {
        this.setRefinerInput();

        if (this.header) {
            if (this.refinerService.refiners.length === 0) {
                this.header.expanded = false;
            } else {
                this.header.expanded = true;
            }
        }

        this.loadRoutes();
    }

    async openStepsInRoute(routeId: string): Promise<void> {
        const routeResponse = await this.routeDelineationService.getById(routeId);
        if (!routeResponse) return;

        const customerIds = routeResponse.values?.stops
            .map((s) => s.customerId)
            .filter((cid) => !!cid);

        const customersResponse = await this.customerDelineationService.getByIds(customerIds);
        if (!customersResponse) return;

        const vm = new StepsInRouteViewModel();
        vm.route = routeResponse.values;

        vm.route.stops.sort((a, b) => a.sequence - b.sequence);

        vm.stops = vm.route.stops.map((stop) =>
            this.routeStopToRouteStopVm(
                stop,
                (customersResponse.values ?? []).find((c) => c.id === stop.customerId)
            )
        );

        this.stepsInRouteOverlayRef = this.overlayService.open(
            StepsInRouteDialogComponent,
            vm
        );
    }

    navigateToRouteDetails(routeId: string): void {
        void this.router.navigate(["/my-day/route-management", routeId, "details"]);
    }

    populateInputsFromRefiners(): void {
        for (const refiner of this.refinerService.refiners) {
            this.setInputFromRefiner(refiner);
        }
    }

    search(shouldAddInputs: boolean = true) {
        if (shouldAddInputs) {
            this.onAddNameRefiner();
            this.onAddDescriptionRefiner();
            this.onAddEndDateRefiner();
        }
        this.onAddStartDateRefiner();

        this.activeRefiners = this.refinerService.refiners.slice();

        this.loadRoutes();
    }

    setColumnVisibilityCommunicator(event: string[]) {
        this.selectedColumnVisibilityComunicator = event;
    }

    setDefaultFilter(): void {
        if (!(this.zrtFilterService.employeeZrts?.length) || !this.employee) {
            return;
        }
        if (!this.refinerService.areDefaultsSet) {
            this.dateForm.controls.startDate.setValue(this.startDateDefault);
            this.onAddStartDateRefiner();

            const refiner = this.zrtFilterService.createDefaultZrtRefiner(this.employee);
            const isCorporateUser = Helper.isEmployeeCustomerServiceOrAdmin(this.employee);
            if (refiner) {
                this.refinerService.checkAndUpdateRefiner(refiner, true);
                if (isCorporateUser) {
                    this.zrtFilterService.defaultZrtSelection = this.zrtFilterService.selectedZrts;
                }
            }
            this.refinerService.isCorporateUser = isCorporateUser;

            this.refinerService.areDefaultsSet = true;
        }
    }

    async setFilterData(): Promise<void> {
        if (Helper.isEmployeeCustomerServiceOrAdmin(this.employee)) {
            this.zrtFilterService.areas = await this.filterService.getAreas();
            const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts();
            if (zrtsResponse && zrtsResponse?.values) {
                this.zrtFilterService.employeeZrts = zrtsResponse.values;
            }
        } else {
            const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts(this.employee.searchableZrt ?? undefined);
            if (zrtsResponse && zrtsResponse?.values) {
                this.zrtFilterService.employeeZrts = zrtsResponse.values;
            }
        }

        this.filterDataLoaded = true;
        this.setDefaultFilter();
    }

    setRefinerInput(): void {
        if (!this.filterDataLoaded) return;

        this.setDefaultFilter();

        if (!this.refinerService.refiners.filter(v =>
            v.location == RefinerLocation.zrtByArea ||
            v.location == RefinerLocation.zrtByEmployee
        ).length
        ) {
            this.zrtFilterService.selectedZrts = [];
        }

        this.dateForm.controls.startDate.setValue(null);
        this.dateForm.controls.endDate.setValue(null);

        this.nameInput = null;
        this.descriptionInput = null;

        this.populateInputsFromRefiners();
    }

    showFilters(): void {
        this.drawer.toggle();
        this.isGapSet = false;
    }


    unsubscribe(): void {
        if (
            this.isOnlineSubscription &&
            !this.isOnlineSubscription.closed
        ) {
            this.isOnlineSubscription.unsubscribe();
        }
        if (
            this.refinerServiceSubscription &&
            !this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription.unsubscribe();
        }
        if (
            this.refinerInputChangeSubscription &&
            !this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription.unsubscribe();
        }
        if (
            this.gridDataChangeSubscription &&
            !this.gridDataChangeSubscription.closed
        ) {
            this.subscriptionEmployee.unsubscribe();
        }
        if (
            this.subscriptionEmployee &&
            !this.subscriptionEmployee.closed
        ) {
            this.subscriptionEmployee.unsubscribe();
        }
        if (this.zrtSelectionSubscription && !this.zrtSelectionSubscription.closed) {
            this.zrtSelectionSubscription.unsubscribe();
        }
    }

    private setInputFromRefiner(refiner: Refiner): void {
        switch (refiner.location) {
            case RefinerLocation.zrtByArea:
            case RefinerLocation.zrtByEmployee:
                this.zrtFilterService.applyRefiner(refiner);
                break;
            case RefinerLocation.routeName:
                this.nameInput = refiner.value;
                break;
            case RefinerLocation.routeDescription:
                this.descriptionInput = refiner.value;
                break;
            case RefinerLocation.routeOnOrAfterDate:
                const startDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.startDate.setValue(
                    startDate
                );
                break;
            case RefinerLocation.routeOnOrBeforeDate:
                const endDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.endDate.setValue(
                    endDate
                );
                break;
            default:
                break;
        }
    }
}
