export enum SystemInformationKeys {
	accountOwnershipPullSyncBatchSize = "AccountOwnershipPullSyncBatchSize",
    areaPullSyncBatchSize = "AreaPullSyncBatchSize",
    areaToZrtPullSyncBatchSize = "AreaToZrtPullSyncBatchSize",
	callPullSyncBatchSize = "CallPullSyncBatchSize",
    callsToSyncForCallHistory = "CallsToSyncForCallHistory",
	contactPullSyncBatchSize = "ContactPullSyncBatchSize",
	contractPullSyncBatchSize = "ContractPullSyncBatchSize",
	countryPullSyncBatchSize = "CountryPullSyncBatchSize",
	countyPullSyncBatchSize = "CountyPullSyncBatchSize",
	customerContractPullSyncBatchSize = "CustomerContractPullSyncBatchSize",
    customerPullMaxBatchSize = "CustomerPullMaxBatchSize",
    customerExportBatchSizeLimit = "CustomerExportBatchSizeLimit",
	customerPullSyncBatchSize = "CustomerPullSyncBatchSize",
	dataSyncQueueMaxRetries = "DataSyncQueueMaxRetries",
    dayTimeEntryPullSyncBatchSize = "DayTimeEntryPullSyncBatchSize",
    disableAutoPrintReceipts = "DisableAutoPrintReceipts",
	employeePullSyncBatchSize = "EmployeePullSyncBatchSize",
    enableClickClang = "EnableClickClang",
    showPrintDraftReceipts = "ShowPrintDraftReceipts",
    lastCallNumber = 'LastCallNumber',
    lastMsaRefreshDate = 'LastMsaRefreshDate',
	productPullSyncBatchSize = "ProductPullSyncBatchSize",
    profileCallHistoryPageSize = "ProfileCallHistoryPageSize",
    projectCustomersAssignBatchSize = "ProjectCustomersAssignBatchSize",
    projectCustomersUnassignBatchSize = "ProjectCustomersUnassignBatchSize",
	projectPullSyncBatchSize = "ProjectPullSyncBatchSize",
	registeredUserPullSyncBatchSize = "RegisteredUserPullSyncBatchSize",
    reportingUrl = "ReportingUrl",
	routePullSyncBatchSize = "RoutePullSyncBatchSize",
	stateCategoryPullSyncBatchSize = "StateCategoryPullSyncBatchSize",
	statePullSyncBatchSize = "StatePullSyncBatchSize",
    stateLicensePullSyncBatchSize = "StateLicensePullSyncBatchSize",
	storeTypePullSyncBatchSize = "StoreTypePullSyncBatchSize",
    surveyPullSyncBatchSize = "SurveyPullSyncBatchSize",
	systemInformationPullSyncBatchSize = "SystemInformationPullSyncBatchSize",
	taxRatePullSyncBatchSize = "TaxRatePullSyncBatchSize",
    timeEntryAgeOutDays = "TimeEntryAgeOutDays",
	timeEntryHistoricalDayLimit = "TimeEntryHistoricalDayLimit",
    timeEntryPullSyncBatchSize = "TimeEntryPullSyncBatchSize",
	timeEntryTypePullSyncBatchSize = "TimeEntryTypePullSyncBatchSize",
	wholesalerGroupPullSyncBatchSize = "WholesalerGroupPullSyncBatchSize",
    wholesalerGroupMemberPullSyncBatchSize = "WholesalerGroupMemberPullSyncBatchSize",
	wholesalerGroupProductCatalogPullSyncBatchSize = "WholesalerGroupProductCatalogPullSyncBatchSize",
	wholesalerProductCatalogPullSyncBatchSize = "WholesalerProductCatalogPullSyncBatchSize",
    wholesalerPullSyncBatchSize = "WholesalerPullSyncBatchSize",
    zrtAssignmentPullSyncBatchSize = "ZrtAssignmentPullSyncBatchSize"
}
