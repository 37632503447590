<div *ngIf="injectedData">
    <div class="alert alert-danger" role="alert">
        <div *ngIf="viewmodel.isStatusTransition" class="row">
            Saving this project will result in the status transitioning from {{viewmodel.oldStatus}} to {{viewmodel.newStatus}}.
        </div>
        <div *ngIf="!viewmodel.isStatusTransition" class="row">
            Saving this project will result in the status remaining in {{viewmodel.oldStatus}}.
        </div>
        <div *ngFor="let message of viewmodel.projectValidationDto.validationErrors">
            <div class="row">
                <mat-icon>warning</mat-icon>
                {{ message }}
            </div>
        </div>
    </div>
</div>
