import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: "precisionCurrency" })
export class PrecisionCurrency implements PipeTransform {
    currencyPipe = new CurrencyPipe("en-US", 'USD');

    transform(value: number): string {
        return this.currencyPipe.transform(value, "USD", "symbol", "1.2-5", null);
    }
}
