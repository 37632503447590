import {
    AfterContentChecked,
    Component,
    OnDestroy,
    Type,
    ViewChild
} from "@angular/core";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import {
    GenericDropDownDto,
    RefinerLocation,
    valueSeparator
} from "shield.shared";
import { ActivitiesFilterService } from "../../../services/activities-filter.service";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { AngularMultiSelect } from "angular2-multiselect-dropdown";
import { CallPicturesActivitiesViewmodel } from "./call-pictures-activities.viewmodel";

@Component({
    selector: "app-call-pictures-activities-filter",
    templateUrl: "./call-pictures-activities-filter.component.html",
    styleUrls: ["./call-pictures-activities-filter.component.scss"]
})
export class CallPicturesActivitiesFilterComponent extends FilterBaseComponent implements AfterContentChecked, OnDestroy {
    //view childs
    @ViewChild("chainSelect") chainSelect: AngularMultiSelect;
    @ViewChild("projectSelect") projectSelect: AngularMultiSelect;

    //public vars
    contentComponent: Type<CallPicturesActivitiesFilterComponent>;
    icon = "check_circle";
    name = "Activities";
    viewmodel = new CallPicturesActivitiesViewmodel(this.activitiesFilterService);

    private isProjectsInitialized = false;
    private isChainsInitialized = false;

    constructor(private activitiesFilterService: ActivitiesFilterService) {
        super();
    }

    ngAfterContentChecked(): void {
        if (!this.isInitialized && this.chainSelect && this.projectSelect) {

            if (
                !this.viewmodel.chainsSubscription ||
                this.viewmodel.chainsSubscription.closed
            ) {
                this.viewmodel.chainsSubscription = this.activitiesFilterService.observableChains.subscribe(
                    (chains) => {
                        if (chains && this.activitiesFilterService.chainsLoaded) {
                            this.chainSelect.settings.noDataLabel = "No chains found.";

                            let partialChains = chains.map((chain) => {
                                chain.commonOperatingName ??= chain.name;
                                return chain;
                            });
                            this.isChainsInitialized = true;

                            partialChains = [...new Map(partialChains.map((c) => [c.ownerCode, c])).values()];
                            this.viewmodel.chains = partialChains;

                            const selected = new Array<AccountOwnership>();
                            for (const chain of this.viewmodel.selectedChains) {
                                if (chains.map((c) => c.id).includes(chain.id)) {
                                    selected.push(chain);
                                }
                            }
                            this.viewmodel.selectedChains = selected;
                            this.checkAndSetInitialized();
                        }
                    }
                );
            }
            if (
                !this.viewmodel.projectsSubscription ||
                this.viewmodel.projectsSubscription.closed
            ) {
                this.viewmodel.projectsSubscription = this.activitiesFilterService.observableProjects.subscribe(
                    (projects) => {
                        if (projects && this.activitiesFilterService.projectsLoaded) {
                            this.projectSelect.settings.noDataLabel = "No projects found.";
                            this.viewmodel.projects = projects;
                            this.isProjectsInitialized = true;

                            const selected = new Array<GenericDropDownDto>();
                            for (const project of this.viewmodel.selectedProjects) {
                                if (projects.map(p => p.id).includes(project.id)) {
                                    selected.push(project);
                                }
                            }
                            this.viewmodel.selectedProjects = selected;
                            this.checkAndSetInitialized();
                        }
                    }
                );
            }
        }
    }

    ngOnDestroy(): void {
        if (
            this.viewmodel.chainsSubscription &&
            !this.viewmodel.chainsSubscription.closed
        ) {
            this.viewmodel.chainsSubscription.unsubscribe();
        }
        if (
            this.viewmodel.projectsSubscription &&
            !this.viewmodel.projectsSubscription.closed
        ) {
            this.viewmodel.projectsSubscription.unsubscribe();
        }
    }

    //events
    openedProjectSelect() {
        this.viewmodel.isProjectsStale = true;
        this.viewmodel.selectedProjectsHold = this.viewmodel.selectedProjects;
    }

    closedProjectSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.projects;
        refiner.value = this.viewmodel.selectedProjects?.length < 2
            ? this.viewmodel.selectedProjects
                .map((project) => project.name)
                .join(", ")
            : this.viewmodel.selectedProjects?.length + " Selected";
        refiner.dataPropertyName = "name"; // TODO: Determine property for local filter.
        refiner.dataValue = this.viewmodel.selectedProjects
            .map((project) => project.id)
            .join(valueSeparator);

        if (this.viewmodel.selectedProjectsHold === this.viewmodel.selectedProjects) {
            this.viewmodel.isProjectsStale = false;
        }

        this._refinerService.checkAndUpdateRefiner(refiner);
    }

    openedChainSelect() {
        this.viewmodel.isChainsStale = true;
        this.viewmodel.selectedChainsHold = this.viewmodel.selectedChains;
    }

    closedChainSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.chains;
        refiner.value = this.viewmodel.selectedChains?.length < 2
            ? this.viewmodel.selectedChains
                .map((chain) => chain.name)
                .join(", ")
            : this.viewmodel.selectedChains?.length + " Selected";
        refiner.dataValue = this.activitiesFilterService.getChainDataValues(this.viewmodel.selectedChains);

        if (this.viewmodel.selectedChainsHold === this.viewmodel.selectedChains) {
            this.viewmodel.isChainsStale = false;
        }

        this._refinerService.checkAndUpdateRefiner(refiner);
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.chains:
                    const selectedChainIds = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedChains = new Array<AccountOwnership>();

                    if (selectedChainIds) {
                        for (const chainId of selectedChainIds) {
                            const found = this.viewmodel.chains.find(
                                (c) => c.id === chainId.trim()
                            );
                            if (found) {
                                rtnSelectedChains.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedChains = rtnSelectedChains;
                    break;
                case RefinerLocation.projects:
                    const selectedProjectIds = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedProjects = new Array<GenericDropDownDto>();

                    if (selectedProjectIds) {
                        for (const projectId of selectedProjectIds) {
                            const found = this.viewmodel.projects.find(
                                (p) => p.id === projectId.trim()
                            );
                            if (found) {
                                rtnSelectedProjects.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedProjects = rtnSelectedProjects;
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.chains;
        const chainsRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.chains
        );
        if (chainsRefiner) {
            this.onInputChange(chainsRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.projects;
        const projectsRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.projects
        );
        if (projectsRefiner) {
            this.onInputChange(projectsRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }

    private checkAndSetInitialized(): void {
        if (!this.isInitialized
            && this.isProjectsInitialized
            && this.isChainsInitialized
        ) {
            this.setInitialized();
        }
    }
}
