export class ConfirmationDialogViewmodel {
    header: string;
    message: string;
    buttonLeftText: string;
    buttonLeftFunction: () => void;
    buttonMiddleText: string;
    buttonMiddleFunction: () => void;
    buttonRightText: string;
    buttonRightFunction: () => void;
    buttonRightDisableFunction: () => boolean;
    toDataUrlPng: () => string;
    isConfirmed: boolean;
    blocking = false;
}
