export enum OrderListColumns {

    callNumber = "Call Number",
    customerId = "Customer ID",
    orderStatus = "Order Status",
    callDate = "Call Date",
    orderDate = "Order Date",
    storeInformation = "Store Information",
    wholesalerCode = "Wholesaler Code",
    wholesaler = "Wholesaler",
    wholesalerCustomerId = "Wholesaler Customer ID",
    chain = "Chain"
}
