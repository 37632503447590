import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { ShareOptionsDialogComponent } from "src/app/dialogs/share-options-dialog/share-options-dialog.component";
import { ChainHqCall } from "src/app/entity-models/chain-hq-call.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { CustomerGenericTypes } from "src/app/enums/customer-generic-types";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { AppStateService } from "src/app/services/app-state.service";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { Call, CallService } from "../call-services/call.service";
import { ChainHqCallViewmodel } from "./chain-hq-call.viewmodel";
import { ChainHqApplicationService } from "./chain-hq-services/chain-hq-call-application.service";
import { CallTypes } from "shield.shared";
import { CallOrderProductViewModel } from "../call-viewmodels/call-order-product.viewmodel";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { AddProductsViewmodel } from "../../../products/add-products/add-products.viewmodel";
import { AddProductsComponent } from "../../../products/add-products/add-products.component";
import { CallOrderProduct } from "src/app/entity-models/call-order-product.entity";
import { ShareOptionsDialogViewmodel } from "src/app/dialogs/share-options-dialog/share-options-dialog.viewmodel";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { DistributionActionItems } from "../stepper-call/stepper-call-enums/distribution-action-items";
import { CountDownViewmodel } from "src/app/dialogs/count-down/count-down.viewmodel";
import { CountDownComponent } from "src/app/dialogs/count-down/count-down.component";
import { WholesalerGroupProductCatalogItemDelineationService } from "src/app/services/delineation-services/wholesaler-group-product-catalog-item-delineation.service";

@Component({
    selector: "app-chain-hq-call",
    templateUrl: "./chain-hq-call.component.html",
    styleUrls: ["./chain-hq-call.component.scss"]
})
export class ChainHqCallComponent implements OnInit, OnDestroy {
    employeeSubscription: Subscription;
    customerSubscription: Subscription;

    currentEmployee: Employee;
    currentCustomer: Customer;

    vm: ChainHqCallViewmodel;

    private modalRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;
    confirmationOverlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    private shareOverlayRef: SwisherOverlayRef<
        ShareOptionsDialogViewmodel,
        ShareOptionsDialogComponent
    >;

    private countDownModalRef: SwisherOverlayRef<
        CountDownViewmodel,
        CountDownComponent
    >;

    addProductOverlayRef: SwisherOverlayRef<
        AddProductsViewmodel,
        AddProductsComponent
    >;

    isOrderMode = false;
    mode: DistributionActionItems;
    maxNumber = 1000000;
    maxQuantityNumber = 500;
    maxUnitNumber = 500;
    maxPriceNumber = 500;
    today = new Date();
    dateFormat: string = MY_DATE_FORMATS.display.dateInput;
    wholesalerOptions: Customer[];
    call: Call;

    @Output() cancelCall = new EventEmitter<void>();
    @Output() blockCall = new EventEmitter<void>();

    constructor(
        private pleaseWaitService: PleaseWaitService,
        private appStateService: AppStateService,
        private customerStateService: CustomerStateService,
        private customerDelineationService: CustomerDelineationService,
        private router: Router,
        private overlayService: OverlayService,
        public chainHqApplicationService: ChainHqApplicationService,
        public callService: CallService,
        public productService: ProductDelineationService,
        private wholesalerGroupProductCatalogItemDelineationService: WholesalerGroupProductCatalogItemDelineationService,
        public injectedData: SwisherOverlayRef<
            ChainHqCallViewmodel,
            ChainHqCallComponent
        >
    ) {}

    async ngOnInit(): Promise<void> {
        if (this.injectedData) {
            this.chainHqApplicationService.buildOrderProductViewModel(
                this.injectedData.data.chainHqCall,
                !!this.injectedData
            );
            this.vm = new ChainHqCallViewmodel();
            this.vm.buildViewModelFromDomainModel(
                this.injectedData.data.chainHqCall
            );
            this.call = this.vm.chainHqCall;
        } else {
            if (!this.callService.call) {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                    this.callService.observableCall
                );
            }

            if (
                !this.employeeSubscription ||
                this.employeeSubscription.closed
            ) {
                this.employeeSubscription = this.appStateService.currentEmployee.subscribe(
                    (employee) => {
                        if (this.currentEmployee?.id !== employee?.id)
                            this.currentEmployee = employee;
                        if (this.currentEmployee) {
                            void this.startChainHqCall();
                        }
                    }
                );
            }

            if (
                !this.customerSubscription ||
                this.customerSubscription.closed
            ) {
                this.customerSubscription = this.customerStateService.observableCustomer.subscribe(
                    (customer) => {
                        if (this.currentCustomer?.id !== customer?.id) {
                            this.currentCustomer = customer;
                            if (this.currentCustomer) {
                                if (!this.currentCustomer.storeCount) {
                                    this.currentCustomer.storeCount = 1; //Temp
                                }
                                void this.startChainHqCall();
                            }
                        }
                    }
                );
            }
        }
    }

    ngOnDestroy(): void {
        if (this.customerSubscription && !this.customerSubscription.closed) {
            this.customerSubscription.unsubscribe();
        }
        if (this.employeeSubscription && !this.employeeSubscription.closed) {
            this.employeeSubscription.unsubscribe();
        }

        for (const orderProduct of this.chainHqApplicationService
            .chainHqCallOrderProductViewModels) {
            if (
                orderProduct.orderDateValueChangeSubscription &&
                !orderProduct.orderDateValueChangeSubscription.closed
            ) {
                orderProduct.orderDateValueChangeSubscription.unsubscribe();
            }
        }
    }

    onOpenConfirmation(): void {
        if (this.modalRef) {
            this.modalRef.close();
        }

        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.buttonRightFunction = () => this.save();
        data.buttonRightText = "Yes";
        data.buttonLeftText = "No";
        data.header = "Confirmation";
        data.message =
            "Saving this call will complete it and make it un-editable. Are you sure you want to complete the call?";
        this.modalRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.modalRef.afterClosed$.subscribe(() => {
            this.modalRef = undefined;
        });
    }

    onOpenShare(): void {
        const data: ShareOptionsDialogViewmodel = new ShareOptionsDialogViewmodel();
        data.shareEmployees = this.vm.shareEmployees;
        data.confirmButtonText = "Save and Share";
        data.comments = this.vm.callComments;
        data.isOffline = true;
        data.onClose = (closeData: ShareOptionsDialogViewmodel) => {
            this.vm.callComments = closeData.comments;
            this.vm.shareEmployees = closeData.shareEmployees;
        };
        data.onSaveShare = async (
            saveData: ShareOptionsDialogViewmodel
        ): Promise<void> => {
            const ids = saveData.shareEmployees.map((e) => e.id);
            await this.callService.shareCall(
                this.call.createdUserId,
                `${Helper.formatDisplayName(this.call)}`,
                ids,
                saveData.comments
            );

            this.callService.call = this.vm.buildDomainModelFromViewModel(
                this.chainHqApplicationService.chainHqCallOrderProductViewModels
            );

            await this.callService.completeCall();
            this.chainHqApplicationService.resetCall();
            this.shareOverlayRef.close();

            if (this.callService.routeId) {
                await this.router.navigate([
                    "/my-day/route-management",
                    this.callService.routeId,
                    "details"
                ]);
            } else {
                await this.router.navigate(["/accounts/customers"]);
            }
        };
        this.shareOverlayRef = this.overlayService.open(
            ShareOptionsDialogComponent,
            data
        );
    }

    async onWholsalerSelectionChange(
        orderProduct: CallOrderProductViewModel
    ): Promise<void> {
        if (orderProduct.wholesaler[0]) {
            const wholesalerProductCatalogItemsResponse = await this.wholesalerGroupProductCatalogItemDelineationService
                .getByWholesalerId(orderProduct.wholesaler[0].id);
            if (wholesalerProductCatalogItemsResponse) {
                orderProduct.uin = wholesalerProductCatalogItemsResponse.values.find((p) => p.productId = orderProduct.product.id)?.productUIN;
            }
        }
        await this.saveOrderProduct(orderProduct);
    }

    addProduct(): void {
        const data: AddProductsViewmodel = new AddProductsViewmodel();
        data.buttonLeftDisabledFunction = () => false;
        data.buttonLeftFunction = () => this.addProductOverlayRef.close(data);
        data.buildViewmodelProductsFromProductDomainModel(
            this.productService.activeProducts,
            this.chainHqApplicationService.chainHqCallOrderProductViewModels.map(
                (p) => p.product.id
            )
        );
        this.addProductOverlayRef = this.overlayService.open(
            AddProductsComponent,
            data,
            true
        );

        this.addProductOverlayRef.afterClosed$.subscribe(async (ref) => {
            if (ref && ref.data && ref.data.result) {
                const products = ref.data.result
                    .filter((r) => r.selected)
                    ?.sort((a, b) =>
                        a.product.description < b.product.description ? 1 : -1
                    );

                for (const selectedProduct of products) {
                    await this.callService.addProductOrder(
                        selectedProduct.product,
                        null
                    );
                }
            }
            await this.buildOrderProductViewModel();
        });
    }

    async buildOrderProductViewModel(): Promise<void> {
        await this.chainHqApplicationService.buildOrderProductViewModel(
            this.callService.call as ChainHqCall,
            !!this.injectedData
        );
    }

    private async save(): Promise<void> {
        this.callService.call = this.vm.buildDomainModelFromViewModel(
            this.chainHqApplicationService.chainHqCallOrderProductViewModels
        );

        await this.callService.completeCall();
        this.chainHqApplicationService.resetCall();
        this.modalRef.close();

        if (this.callService.routeId) {
            await this.router.navigate([
                "/my-day/route-management",
                this.callService.routeId,
                "details"
            ]);
        } else {
            await this.router.navigate(["/accounts/customers"]);
        }
    }

    async startChainHqCall(): Promise<void> {
        if (this.currentEmployee && this.currentCustomer) {
            if (
                await this.callService.blockCall(
                    this.currentEmployee.id,
                    this.countDownModalRef,
                    this.overlayService
                )
            ) {
                this.blockCall.emit();
                void this.router.navigate(["/accounts", this.currentCustomer.id, "profile"]);
                return;
            }

            if (
                this.currentEmployee.user &&
                Helper.getCustomerGenericType(
                    this.currentCustomer
                ) === CustomerGenericTypes.chainHeadquarter
            ) {
                const openCall = await this.callService.getOpenCall(
                    this.currentEmployee
                );

                if (
                    openCall &&
                    openCall.customerId !==
                        this.customerStateService.customer.id
                ) {
                    // let them continue this call if its the oldest open call

                    const openCustomerResponse = await this.customerDelineationService.getById(
                        openCall.customerId
                    );
                    if (!openCustomerResponse) {
                        return;
                    }
                    const openCustomer = openCustomerResponse.values;

                    const data = this.callService.buildBasicCurrentCallModal(
                        openCustomer?.name ?? "this customer"
                    );
                    data.buttonLeftFunction = () => {
                        this.cancelCall.emit();
                        this.pleaseWaitService.killPleaseWait();
                        this.modalRef.close();
                    };
                    data.buttonRightFunction = () => {
                        void this.router.navigate([
                            "/accounts",
                            openCustomer.id,
                            "profile"
                        ]);
                        this.pleaseWaitService.killPleaseWait();
                        this.modalRef.close();
                    };

                    this.modalRef = this.overlayService.open(
                        ConfirmationDialogComponent,
                        data
                    );
                } else {
                    if (!this.callService.call) {
                        await this.chainHqApplicationService.startCall(
                            this.currentEmployee
                        );
                    }
                    this.vm = new ChainHqCallViewmodel();
                    this.vm.buildViewModelFromDomainModel(
                        this.callService.call as ChainHqCall
                    );
                    this.buildOrderProductViewModel();
                    this.call = this.callService.call;
                }
            }
        }
    }

    copyOrderProduct(orderProduct: CallOrderProductViewModel): void {
        const startingIndex = this.chainHqApplicationService.chainHqCallOrderProductViewModels.indexOf(
            orderProduct
        );
        let newProduct: CallOrderProductViewModel;
        if (
            startingIndex !== -1 &&
            this.chainHqApplicationService.chainHqCallOrderProductViewModels
                .length > startingIndex
        ) {
            for (
                let i = startingIndex + 1;
                i <
                    this.chainHqApplicationService
                        .chainHqCallOrderProductViewModels.length &&
                !newProduct;
                i++
            ) {
                if (
                    this.chainHqApplicationService
                        .chainHqCallOrderProductViewModels[i].isPristine
                ) {
                    newProduct = orderProduct.copyTo(
                        this.chainHqApplicationService
                            .chainHqCallOrderProductViewModels[i]
                    );
                    this.chainHqApplicationService.chainHqCallOrderProductViewModels.splice(
                        i,
                        1,
                        newProduct
                    );
                }
            }
        }
        if (!newProduct) {
            this.reportNoAvailableCopyRecord();
        } else {
            newProduct.formControl.setValue(newProduct.orderDates);
            void this.saveOrderProduct(newProduct);
        }
    }

    decrementOrderProductQuantity(
        orderProduct: CallOrderProductViewModel
    ): void {
        if (orderProduct.quantity > 1) {
            orderProduct.quantity--;
            orderProduct.oldQuantity = orderProduct.quantity;
            this.saveOrderProduct(orderProduct);
        }
    }

    decrementOrderProductUnit(orderProduct: CallOrderProductViewModel): void {
        if (orderProduct.units > 1) {
            orderProduct.units--;
            this.saveOrderProduct(orderProduct);
        }
    }

    incrementOrderProductQuantity(
        orderProduct: CallOrderProductViewModel
    ): void {
        if (orderProduct.quantity + 1 >= this.maxQuantityNumber) {
            this.openQuantityWarning(orderProduct);
        } else if (orderProduct.quantity < this.maxQuantityNumber) {
            orderProduct.quantity++;
            orderProduct.oldQuantity = orderProduct.quantity;
            this.saveOrderProduct(orderProduct);
        }
    }

    incrementOrderProductUnit(orderProduct: CallOrderProductViewModel): void {
        if (orderProduct.units + 1 >= this.maxUnitNumber) {
            this.openUnitWarning(orderProduct);
        } else if (orderProduct.units < this.maxUnitNumber) {
            orderProduct.units++;
            orderProduct.oldUnits = orderProduct.units;
            this.saveOrderProduct(orderProduct);
        }
    }

    async removeOrderProduct(op: CallOrderProductViewModel): Promise<void> {
        if (
            this.callService &&
            this.callService.call?.callType === CallTypes.chainHq &&
            this.callService.call.orderProducts &&
            this.callService.call.orderProducts.length > 0
        ) {
            const productId: string = op.product.id;

            let index: number = this.callService.call.orderProducts.findIndex(
                (orderProduct) => orderProduct.productId === productId
            );
            if (index !== -1) {
                this.callService.call.orderProducts.splice(index, 1);
                await this.callService.saveCallAndNotify();
                index = this.callService.call.orderProducts.findIndex(
                    (orderProduct) => orderProduct.productId === productId
                );

                if (index === -1) {
                    //we just removed the last productId of its kind so kill it on the model.
                    await this.callService.removeProductFromOrder(
                        productId,
                        op.wholesaler?.length
                            ? op.wholesaler[0].wholesaler?.id
                            : ""
                    );
                }

                if (
                    op.orderDateValueChangeSubscription &&
                    !op.orderDateValueChangeSubscription.closed
                ) {
                    op.orderDateValueChangeSubscription.unsubscribe();
                }

                this.buildOrderProductViewModel();
            }
        }
    }

    async saveOrderProduct(
        orderProduct: CallOrderProductViewModel
    ): Promise<void> {
        if (
            this.callService &&
            this.callService.call?.callType === CallTypes.chainHq &&
            this.callService.call.orderProducts &&
            this.callService.call.orderProducts.length > 0
        ) {
            this.callService.call.orderProducts ??= [];

            const orderIndex: number = this.callService.call.orderProducts.findIndex(
                (product) => product.id === orderProduct.id
            );
            if (orderIndex !== -1) {
                const newOrderProduct: CallOrderProduct = new CallOrderProduct();
                newOrderProduct.id = orderProduct.id;
                newOrderProduct.uin = orderProduct.uin;
                newOrderProduct.productId = orderProduct.product?.id;
                newOrderProduct.quantity = orderProduct.quantity;
                newOrderProduct.units = orderProduct.units;
                newOrderProduct.upc = orderProduct.upc;
                newOrderProduct.uin = orderProduct.uin;
                newOrderProduct.callOrderDates = orderProduct.orderDates;
                newOrderProduct.wholesalerCustomerId = orderProduct.wholesaler[0]?.id;
                newOrderProduct.storeCount = orderProduct.storeCount;

                this.callService.call.orderProducts.splice(
                    orderIndex,
                    1,
                    newOrderProduct
                );
                await this.callService.saveCallAndNotify();
            }
        }
    }

    select(element: HTMLInputElement): void {
        Helper.selectInputText(element);
    }

    validationOrderProductQuantity(
        orderProduct: CallOrderProductViewModel
    ): void {
        if (orderProduct.quantity !== orderProduct.oldQuantity) {
            orderProduct.quantity = Helper.validateMin(orderProduct.quantity);

            if (orderProduct.quantity >= this.maxQuantityNumber) {
                this.openQuantityWarning(orderProduct);
            } else {
                orderProduct.oldQuantity = orderProduct.quantity;
                this.saveOrderProduct(orderProduct);
            }
        }
    }

    validationOrderProductUnit(orderProduct: CallOrderProductViewModel): void {
        if (orderProduct.units !== orderProduct.oldUnits) {
            orderProduct.units = Helper.validateMin(orderProduct.units);

            if (orderProduct.units >= this.maxUnitNumber) {
                this.openUnitWarning(orderProduct);
            } else {
                orderProduct.oldUnits = orderProduct.units;
                this.saveOrderProduct(orderProduct);
            }
        }
    }

    validationStoreCount(orderProduct: CallOrderProductViewModel): void {
        if (!orderProduct.storeCount) {
            orderProduct.storeCount = 1;
        }
        this.saveOrderProduct(orderProduct);
    }

    //private
    private reportNoAvailableCopyRecord(): void {
        const data = new ConfirmationDialogViewmodel();
        data.header = "Alert";
        data.message = "No unmodified record found to copy to.";

        this.overlayService.open(ConfirmationDialogComponent, data);
    }

    private openQuantityWarning(product: CallOrderProductViewModel): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "You have set the quantity higher than the typical value. Are you sure you would like to proceed?";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            product.quantity = product.oldQuantity;
            this.confirmationOverlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.confirmationOverlayRef.close(data);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe((event) => {
            if (event && event.data && event.data.isConfirmed) {
                if (product.oldQuantity === product.quantity) {
                    product.quantity++;
                }
                product.oldQuantity = product.quantity;
                this.saveOrderProduct(product as CallOrderProductViewModel);
            }
        });
    }

    private openUnitWarning(product: CallOrderProductViewModel): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "You have set the Sticks/Units higher than the typical value. Are you sure you would like to proceed?";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            product.units = product.oldUnits;
            this.confirmationOverlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.confirmationOverlayRef.close(data);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe((event) => {
            if (event && event.data && event.data.isConfirmed) {
                if (product.oldUnits === product.units) {
                    product.units++;
                }
                product.oldUnits = product.units;
                this.saveOrderProduct(product as CallOrderProductViewModel);
            }
        });
    }
}
