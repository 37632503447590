import { Injectable } from "@angular/core";
import { EmailReceiptToCustomerContactParams, EmailReceiptLocationOrigins, EmailReceiptParams, GenericResponseDto, NotificationRequestDto } from "shield.shared";
import { NotificationOfflineService } from "../offline-services/notification-offline.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";
import { DatabaseService } from "../database.service";
import { DexieTableNames } from "src/app/enums/dexie-table-names";
import { NotificationOnlineService } from "../online-services/notification-online.service";

@Injectable()
export class NotificationDelineationService extends DelineationContext<NotificationRequestDto, string> {

    constructor(private notificationOfflineService: NotificationOfflineService,
        private notificationOnlineService: NotificationOnlineService,
        snackbarService: SnackbarService,
        protected datasourceDelineationService: DatasourceDelineationService,
        protected dbService: DatabaseService){
            super(dbService, datasourceDelineationService, snackbarService);
        }

        async send(dto: NotificationRequestDto): Promise<GenericResponseDto<undefined>> {

            this.persist(dto, DexieTableNames.notificationRequests);
            const offline = (key: NotificationRequestDto) => {
                return this.notificationOfflineService.send(key);
            }
            const online = (key: NotificationRequestDto) => {
                return this.notificationOfflineService.send(key);
            }
            const response = await this.datasourceDelineationService.makeCall<NotificationRequestDto, undefined>(dto, offline, online);

            if (response) {
                if (response.isError) {
                    this.snackbarService.showError(response.message);
                    return;
                }
            }
            return response;
        }

        async emailReceipt(
            employeeId: string,
            callId: string,
            receiptId: string,
            receiptNumber: string,
            origin: EmailReceiptLocationOrigins,
            comment?: string
            ): Promise<GenericResponseDto<undefined>> {

            const param = new EmailReceiptParams();
            param.employeeId = employeeId;
            param.callId = callId;
            param.receiptId = receiptId;
            param.comment = comment;
            param.receiptNumber = receiptNumber;
            param.origin = origin;

            const offline = (key: EmailReceiptParams) => {
                return this.notificationOfflineService.emailReceipt(key);
            }
            const online = (key: EmailReceiptParams) => {
                return this.notificationOnlineService.emailReceipt(key);
            }
            const response = await this.datasourceDelineationService.makeCall<EmailReceiptParams, undefined>(param, offline, online);

            if (!response) {
                return;
            } else {
                if (response.isError) {
                    this.snackbarService.showError(response.message);
                    return;
                }
                return response;
            }
        }

        async emailReceiptToCustomerContact(
            email: string,
            callId: string,
            receiptId: string,
            receiptNumber: string,
            origin: EmailReceiptLocationOrigins,
            comment?: string
            ): Promise<GenericResponseDto<undefined>> {

            const param = new EmailReceiptToCustomerContactParams();
            param.email = email;
            param.callId = callId;
            param.receiptId = receiptId;
            param.comment = comment;
            param.receiptNumber = receiptNumber;
            param.origin = origin;

            const offline = (key: EmailReceiptToCustomerContactParams) => {
                return this.notificationOfflineService.emailReceiptToCustomerContact(key);
            }
            const online = (key: EmailReceiptToCustomerContactParams) => {
                return this.notificationOnlineService.emailReceiptToCustomerContact(key);
            }
            const response = await this.datasourceDelineationService.makeCall<EmailReceiptToCustomerContactParams, undefined>(param, offline, online);

            if (!response) {
                return;
            } else {
                if (response.isError) {
                    this.snackbarService.showError(response.message);
                    return;
                }
                return response;
            }
        }
}
