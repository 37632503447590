<div #originalEasRetailReceipt *ngIf="easCashRetailViewmodels?.length > 0
    || easGratisRetailViewmodels?.length > 0
    || easExchangeOutRetailViewmodels?.length > 0
    || easExchangeInRetailViewmodels?.length > 0">
    <div class="center">
        ******Draft******Draft******Draft******Draft******Draft******Draft******
        ******Draft******Draft******Draft******Draft******Draft******Draft******
    </div>
    <div>
        <div class="row">
            <div class="col center">
                {{ customerReceiptAddress.addressName }} - Receipt #{{swisherReceiptNumber}}
            </div>
        </div>
        <div *ngIf="retailStateVaporLicense" class="row">
            <div class="col center">
                Retail State Vapor License # {{ retailStateVaporLicense?.licenseNumber }}
            </div>
        </div>
        <div class="row">
            <div class="col center">
                Retail State OTP License # {{ retailStateOptLicense?.licenseNumber }}
            </div>
        </div>
        <div class="row">
            <div class="col center">
                {{ customerReceiptAddress.addressFormatted }}
            </div>
        </div>
        <div *ngIf="customer.businessAddress?.county" class="row">
            <div class="col center">{{ customer.businessAddress?.county }}&nbsp;County</div>
        </div>
        <hr />
        <div *ngIf="swisherCashRetailViewmodels?.length > 0">
            <div class="row margin-right">
                <div class="col-3">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                    <b>Taxes</b>
                </div>
                <div class="col-1 no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
            </div>
            <div class="row margin-right">
                <div class="col-3">
                    <b>Cash Sales Item</b>
                </div>
                <div class="col-1 no-side-padding">
                    <b>Qty</b>
                </div>
                <div class="col no-side-padding">
                    <b>Price</b>
                </div>
                <div class="col no-side-padding">
                    <b>State</b>
                </div>
                <div class="col no-side-padding">
                    <b>County</b>
                </div>
                <div class="col no-side-padding">
                    <b>City</b>
                </div>
                <div class="col no-side-padding">
                    <b>Disc</b>
                </div>
                <div class="col no-side-padding">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let cashProduct of swisherCashRetailViewmodels">
                <div class="col-3">
                    {{ cashProduct.product?.description }}
                </div>
                <div class="col-1 no-side-padding">
                    {{ cashProduct.quantity }}
                </div>
                <div class="col no-side-padding">
                    {{ cashProduct.price | precisionCurrency }}
                </div>
                <div class="col no-side-padding">
                    {{ cashProduct.stateTaxAmount | precisionCurrency }}
                </div>
                <div class="col no-side-padding">
                    {{ cashProduct.countyTaxAmount | precisionCurrency }}
                </div>
                <div class="col no-side-padding">
                    {{ cashProduct.cityTaxAmount | precisionCurrency }}
                </div>
                <div class="col no-side-padding">
                    {{ cashProduct.discount | precisionCurrency }}
                </div>
                <div class="col no-side-padding">
                    {{ cashProduct.totalWithTax | precisionCurrency}}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col total-display">
                    <b>Total: {{ stepperCallApplicationService.cashDueNowTotal | currency }}</b>
                </div>
            </div>
            <div class="row">
                <div class="col larger total-display">
                    <b><u>Cash Due Now: {{ stepperCallApplicationService.cashDueNowTotal | currency }}</u></b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="swisherGratisRetailViewmodels?.length > 0">
            <div class="row margin-right">
                <div class="col-3">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                    <b>Taxes</b>
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
            </div>
            <div class="row margin-right">
                <div class="col-3">
                    <b>Promotional Goods</b>
                </div>
                <div class="col-1 no-side-padding">
                    <b>Qty</b>
                </div>
                <div class="col no-side-padding">
                    <b>Price</b>
                </div>
                <div class="col no-side-padding">
                    <b>State</b>
                </div>
                <div class="col no-side-padding">
                    <b>County</b>
                </div>
                <div class="col no-side-padding">
                    <b>City</b>
                </div>
                <div class="col no-side-padding">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let gratisProduct of swisherGratisRetailViewmodels">
                <div class="col-3">
                    {{ gratisProduct.product?.description }}
                </div>
                <div class="col-1 no-side-padding">
                    {{ gratisProduct.quantity }}
                </div>
                <div class="col no-side-padding">
                    {{ gratisProduct.value | precisionCurrency }}
                </div>
                <div class="col no-side-padding">
                    {{ gratisProduct.stateTaxAmount | precisionCurrency }}
                </div>
                <div class="col no-side-padding">
                    {{ gratisProduct.countyTaxAmount | precisionCurrency }}
                </div>
                <div class="col no-side-padding">
                    {{ gratisProduct.cityTaxAmount | precisionCurrency }}
                </div>
                <div class="col no-side-padding">
                    {{ gratisProduct.totalWithTax | precisionCurrency}}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col total-display">
                    <b>Promotional Goods Total:
                        {{ stepperCallApplicationService.gratisDueNowTotal | currency }}</b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="swisherExchangeOutRetailViewmodels?.length > 0">
            <div class="row margin-right">
                <div class="col-3">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                    <b>Taxes</b>
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
            </div>
            <div class="row margin-right">
                <div class="col-3">
                    <b>Exchange Out Item</b>
                </div>
                <div class="col-1 no-side-padding">
                    <b>Qty</b>
                </div>
                <div class="col no-side-padding">
                    <b>Price</b>
                </div>
                <div class="col no-side-padding">
                    <b>State</b>
                </div>
                <div class="col no-side-padding">
                    <b>County</b>
                </div>
                <div class="col no-side-padding">
                    <b>City</b>
                </div>
                <div class="col no-side-padding">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let outProduct of swisherExchangeOutRetailViewmodels">
                <div class="col-3">
                    {{ outProduct.product?.description }}
                </div>
                <div class="col-1 no-side-padding">
                    {{ outProduct.quantity}}
                </div>
                <div class="col no-side-padding">
                    {{ outProduct.price | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ outProduct.stateTaxAmount * -1 | precisionCurrency }}
                </div>
                <div class="col no-side-padding">
                    {{ outProduct.countyTaxAmount * -1 | precisionCurrency }}
                </div>
                <div class="col no-side-padding">
                    {{ outProduct.cityTaxAmount * -1 | precisionCurrency }}
                </div>
                <div class="col no-side-padding">
                    {{ outProduct.totalWithTax | precisionCurrency}}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col total-display">
                    <b>Exchange Out Total:
                        {{ stepperCallApplicationService.exchangeOutTotal | currency }}</b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="swisherExchangeInRetailViewmodels?.length > 0">
            <div class="row margin-right">
                <div class="col-3">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                    <b>Taxes</b>
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
            </div>
            <div class="row margin-right">
                <div class="col-3">
                    <b>Exchange In Item</b>
                </div>
                <div class="col-1 no-side-padding">
                    <b>Qty</b>
                </div>
                <div class="col no-side-padding">
                    <b>Price</b>
                </div>
                <div class="col no-side-padding">
                    <b>State</b>
                </div>
                <div class="col no-side-padding">
                    <b>County</b>
                </div>
                <div class="col no-side-padding">
                    <b>City</b>
                </div>
                <div class="col no-side-padding">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let inProduct of swisherExchangeInRetailViewmodels">
                <div class="col-3">
                    {{ inProduct.product?.description }}
                </div>
                <div class="col-1 no-side-padding">
                    {{ inProduct.quantity }}
                </div>
                <div class="col no-side-padding">
                    {{ inProduct.price | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ inProduct.stateTaxAmount | precisionCurrency }}
                </div>
                <div class="col no-side-padding">
                    {{ inProduct.countyTaxAmount | precisionCurrency }}
                </div>
                <div class="col no-side-padding">
                    {{ inProduct.cityTaxAmount | precisionCurrency }}
                </div>
                <div class="col no-side-padding">
                    {{ inProduct.totalWithTax | precisionCurrency}}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col total-display">
                    <b>Exchange In Total: {{ stepperCallApplicationService.exchangeInTotal
                        |
                        currency }}</b>
                </div>
            </div>
            <br />
        </div>
    </div>
    <div class="center">
        ******Draft******Draft******Draft******Draft******Draft******Draft******
        ******Draft******Draft******Draft******Draft******Draft******Draft******
    </div>
</div>
