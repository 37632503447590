import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EmailAttachment, EmailReceiptLocationOrigins, EmailReceiptParams, EmailReceiptToCustomerContactParams, GenericRequestDto, GenericResponseDto, newSequentialId, NotificationRequestDto } from "shield.shared";

@Injectable()
export class NotificationOnlineService {

    constructor(private http: HttpClient) {}

    async emailReceipt(params: EmailReceiptParams): Promise<GenericResponseDto<undefined>> {
        const request = new GenericRequestDto<NotificationRequestDto>();
        request.id = newSequentialId();
        const notification = new NotificationRequestDto();
        notification.id = request.id;
        notification.employeeId = params.employeeId;
        notification.recipientEmployeeId = params.employeeId;
        notification.sendEmail = true;
        notification.attachment = new EmailAttachment;
        notification.attachment.type = "application/pdf"
        notification.receiptId = params.receiptId;
        notification.callId = params.callId;
        switch (params.origin) {
            case EmailReceiptLocationOrigins.invoice:
                notification.subject = `Invoice receipt: ${params.receiptNumber}`;
                notification.attachment.name = `InvoiceReceipt${params.receiptNumber ?? ""}.pdf`
                break;
            case EmailReceiptLocationOrigins.order:
                notification.subject = `Order receipt: ${params.receiptNumber}`;
                notification.attachment.name = `OrderReceipt${params.receiptNumber ?? ""}.pdf`
            default:
                break;
        }
        notification.message = params.comment ?? "";
        request.values = notification;

        await this.http
            .post(
                `/api/calls/${params.callId}/receipt/${params.receiptId}/email`,
                request
            )
            .toPromise();

        return new GenericResponseDto<undefined>();
    }

    async emailReceiptToCustomerContact(params: EmailReceiptToCustomerContactParams): Promise<GenericResponseDto<undefined>> {
        const request = new GenericRequestDto<NotificationRequestDto>();
        request.id = newSequentialId();
        const notification = new NotificationRequestDto();
        notification.id = request.id;
        notification.employeeId = null;
        notification.recipientEmployeeId = null;
        notification.recipientOtherEmail = params.email;
        notification.sendEmail = true;
        notification.attachment = new EmailAttachment;
        notification.attachment.type = "application/pdf"
        notification.receiptId = params.receiptId;
        notification.callId = params.callId;
        switch (params.origin) {
            case EmailReceiptLocationOrigins.invoice:
                notification.subject = `Invoice receipt: ${params.receiptNumber}`;
                notification.attachment.name = `InvoiceReceipt${params.receiptNumber ?? ""}.pdf`
                break;
            case EmailReceiptLocationOrigins.order:
                notification.subject = `Order receipt: ${params.receiptNumber}`;
                notification.attachment.name = `OrderReceipt${params.receiptNumber ?? ""}.pdf`
            default:
                break;
        }
        notification.message = params.comment ?? "";
        request.values = notification;

        await this.http
            .post(
                `/api/calls/${params.callId}/receipt/${params.receiptId}/email-contact`,
                request
            )
            .toPromise();

        return new GenericResponseDto<undefined>();
    }


}
