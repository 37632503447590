import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";
import { Project } from "src/app/entity-models/project.entity";

export class ViewRetailProjectViewModel extends BasicDialogViewModel {
    project: Project;
    daysRemaining: number;
    isRetail: boolean;

    constructor(project: Project) {
        super();

        this.project = project;
        if (this.project.endDate) {
            const end = this.project.endDate?.setHours(0,0,0,0);
            this.daysRemaining = Math.round((end - Date.now()) // output in ms
                / (1000 * 60 * 60 * 24)); // ms/s * s /m * m/h * h/d
        }

        this.headerLeftText = "View Retail Project";
        this.buttonLeftText = "Close";
        this.buttonLeftDisabledFunction = () => false;
    }

    unsubscribe(): void {}
}
