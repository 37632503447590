<div _ngcontent-ng-cli-universal-c432="">
    <div _ngcontent-ng-cli-universal-c432="" class="col card-header banner-header">
        <div _ngcontent-ng-cli-universal-c432="" class="ml-2 s-white-fff-color">Px3 Import</div>
    </div>
</div>
<a #downloadElement download="Px3Import.xlsx" style="display:none"></a>
<div class="ml-3 container">
    <form [formGroup]="importForm" class="col-lg-6 col-12">
        <div class="row">
            <button class="btn btn-secondary mt-3 ml-3" (click)="downloadSampleFile()">Download Import Template File
                (XLSX)</button>
        </div>
        <p class="row mt-2"><b>Upload for Existing Incentive Period</b></p>
        <div class="row">
            <mat-form-field class="col" appearance="outline" color="accent">
                <mat-label>Existing Incentive Period</mat-label>
                <mat-select formControlName="incentivePeriodId" (selectionChange)="incentivePeriodChange($event)">
                    <mat-option *ngFor="let period of incentivePeriods | async" [value]="period?.id">
                        <span *ngIf="period">
                            {{ period.startDate | date : 'shortDate' }} - {{ period.endDate | date : 'shortDate'}}
                        </span>
                        <span *ngIf="!period">
                            Create New Period
                        </span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <p class="row mt-2"><b>Create New Incentive Period</b></p>
        <div class="container" formGroupName="dates">
            <div class="row">
                <mat-form-field appearance="outline" color="accent">
                    <mat-label>Start Date</mat-label>
                    <input matInput #startDate [matDatepicker]="startDatePicker" formControlName="startDate" />
                    <mat-datepicker touchUi #startDatePicker></mat-datepicker>
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>

                </mat-form-field>
            </div>
            <div class="row" *ngIf="importForm.get('dates.startDate')?.touched">
                <div class="error" *ngIf="importForm.get('dates.startDate')?.errors?.required">
                    This field is required.
                </div>
                <div class="error" *ngIf="importForm.get('dates.startDate')?.errors?.afterEnd">
                    Start date must not exceed end date.
                </div>
            </div>
            <div class="row">
                <mat-form-field appearance="outline" color="accent">
                    <mat-label>End Date</mat-label>
                    <input matInput #endDate [matDatepicker]="endDatePicker" formControlName="endDate" />
                    <mat-datepicker touchUi #endDatePicker></mat-datepicker>
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                </mat-form-field>
            </div>
            <div class="row" *ngIf="importForm.get('dates.endDate')?.touched">
                <div class="error" *ngIf="importForm.get('dates.endDate')?.errors?.required">
                    This field is required.
                </div>
                <div class="error" *ngIf="importForm.get('dates.endDate')?.errors?.beforeStart">
                    End date must not be before start date.
                </div>
            </div>
        </div>
        <div class="row" style="margin-left: 0px">
            <input formControlName="fileInput" #fileInput hidden="true" type="file" accept=".xlsx" />
            <button mat-flat-button class="mt-2 btn btn-secondary" (click)="fileInput.click()">Upload XLSX
                file</button>
            <div class="col-auto center" *ngIf="importForm.get('fileInput')?.touched">
                <div class="error" *ngIf="importForm.get('fileInput')?.errors?.required">
                    An XLSX file must be uploaded.
                </div>
            </div>
            <div class="col-auto center" *ngIf="fileInput.files?.length > 0">
                Uploaded: {{fileInput.files[0].name}}
            </div>
            <div class="w-100"></div>
        </div>
        <div class="row" style="margin-left: 0px">
            <button (click)="save()" mat-raised-button class="btn btn-primary mt-2 mr-2">
                SAVE
            </button>
        </div>
        <div class="error">
            <div *ngIf="errors?.duplicates?.length > 0">
                Duplicate customer numbers found:
                <ul>
                    <li *ngFor="let item of errors.duplicates">
                        {{item}}
                    </li>
                </ul>
            </div>
            <div *ngIf='errors?.invalidRanks && Object.keys(errors.invalidRanks).length > 0'>
                Invalid Px3 ranks found:
                <ul>
                    <li *ngFor="let number of Object.keys(errors.invalidRanks)">
                        Customer {{number}} has invalid rank {{errors.invalidRanks[number]}}
                    </li>
                </ul>
            </div>
            <div *ngIf='errors?.invalidCustomers?.length > 0'>
                Invalid customer numbers found:
                <ul>
                    <li *ngFor="let number of errors.invalidCustomers">
                        {{number}}
                    </li>
                </ul>
            </div>
        </div>
    </form>
</div>
