import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    OnDestroy,
    Input
} from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'app-time-log-calendar-header',
    styleUrls: ['time-log-calendar-header.component.scss'],
    templateUrl: 'time-log-calendar-header.component.html'
})

export class TimeLogCalendarHeaderComponent<D> implements OnDestroy {
    private _destroyed = new Subject<void>();

    constructor(
        private _calendar: MatCalendar<D>,
        private _dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
        cdr: ChangeDetectorRef
    ) {
        _calendar.stateChanges
            .pipe(takeUntil(this._destroyed))
            .subscribe(() => cdr.markForCheck());
    }

    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }

    get periodLabel() {
        return this._dateAdapter
            .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
            .toLocaleUpperCase();
    }

    previousClicked() {
        let activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
        activeDate = this._dateAdapter.addCalendarDays(activeDate, 1 - this._dateAdapter.getDate(activeDate));
        this._calendar.activeDate = activeDate;
        this._calendar.viewChanged.emit();
    }

    nextClicked() {
        let activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
        activeDate = this._dateAdapter.addCalendarDays(activeDate, 1 - this._dateAdapter.getDate(activeDate));
        this._calendar.activeDate = activeDate;
        this._calendar.viewChanged.emit();
    }
}
