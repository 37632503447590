import { Px3IncentivePeriodDto } from "./px3-incentive-period.dto";

export class CustomerPx3RankImportDto {
    incentivePeriod: Px3IncentivePeriodDto;
    fileBase64: string;
}

export class CustomerPx3RankImportEntryDto {
    customerNumber: string;
    rank?: string;
}