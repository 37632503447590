import { Component } from "@angular/core";
import { Customer } from "src/app/entity-models/customer.entity";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { TaxCalculatorViewModel } from "./tax-calculator.viewmodel";


@Component({
    selector: "app-tax-calculator",
    templateUrl: "./tax-calculator.component.html",
    styleUrls: ["./tax-calculator.component.css"]
})
export class TaxCalculatorComponent {
    vm: TaxCalculatorViewModel;
    customer: Customer;

    constructor(
        private overlayRef: SwisherOverlayRef<
            TaxCalculatorViewModel,
            TaxCalculatorComponent
        >,
    ) {
        this.vm = this.overlayRef.data;
    }
}
