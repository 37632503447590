import { CdkDragDrop, transferArrayItem } from "@angular/cdk/drag-drop";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatRadioChange } from "@angular/material/radio";
import { BehaviorSubject, Subscription } from "rxjs";
import { newSequentialId, SortDirection } from "shield.shared";
import { ProjectEmployee } from "src/app/entity-models/project-employee.entity";
import { Project } from "src/app/entity-models/project.entity";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { ProjectStateService } from "src/app/services/project-state-service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { ProjectPersonSelectViewmodel } from "./project-person-select.viewmodel";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";

export class ProjectPeopleSortDto {
    name: keyof ProjectPersonSelectViewmodel;
    direction: SortDirection;
}

export class ProjectPeopleViewmodel {

    snackbarService: SnackbarService;
    pleaseWaitService: PleaseWaitService;
    projectStateService: ProjectStateService;
    employeeDelineationService: EmployeeDelineationService;
    assignedEmployees = new Array<ProjectPersonSelectViewmodel>();
    assignedFilteredEmployees = new Array<ProjectPersonSelectViewmodel>();
    assignedFilteredEmployeesSortDto = new ProjectPeopleSortDto();
    unAssignedEmployees = new Array<ProjectPersonSelectViewmodel>();
    unAssignedFilteredEmployees = new Array<ProjectPersonSelectViewmodel>();
    unAssignedFilteredEmployeesSortDto = new ProjectPeopleSortDto();
    allAssignedchecked: boolean;
    allUnassignedchecked: boolean;
    unAssignedSearchText: string;
    assignedSearchText: string;
    arrowRightimageLocation = "/assets/img/addArrow.png";
    arrowLeftimageLocation = "/assets/img/remove-arrow.png";
    zones = new Array<string | number>();
    selectedZone: number | string;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    isInitialized = false;
    directions = SortDirection;

    constructor(snackbarService: SnackbarService,
        pleaseWaitService: PleaseWaitService,
        projectStateService: ProjectStateService,
        employeeDelineationService: EmployeeDelineationService,
        private customerDeliniationService: CustomerDelineationService) {

        this.snackbarService = snackbarService;
        this.pleaseWaitService = pleaseWaitService;
        this.projectStateService = projectStateService;
        this.employeeDelineationService = employeeDelineationService;

        this.assignedFilteredEmployeesSortDto.name = "territory";
        this.assignedFilteredEmployeesSortDto.direction = SortDirection.ascending;
        this.unAssignedFilteredEmployeesSortDto.name = "territory";
        this.unAssignedFilteredEmployeesSortDto.direction = SortDirection.ascending;
    }

    async initialize(project: Project, shouldWait$: BehaviorSubject<boolean>): Promise<void> {

        if (!this.isInitialized) {
            this.isInitialized = true;
        } else {
            return;
        }
        this.shouldWait$ = shouldWait$;

        const employeesResponse = await this.employeeDelineationService.getAll();

        if (!employeesResponse) {
            return;
        }
        const filteredEmployees = employeesResponse.values.filter((e) => e.region && e.zone && e.zrt && !isNaN(+e.zone) && !isNaN(+e.zrt) && e.region != "0")
            .sort()
        //Distinct the array
        const zones: (string | number)[] = [...new Set(filteredEmployees.map((fe) => +fe.zone))];
        zones.sort((a, b) => { return +a - +b });
        zones.splice(0, 0, "All");
        this.zones = zones;
        this.selectedZone = "All";

        const projectPersonLookup = new Map(project.projectEmployees.map((pe) => [pe.employeeId, pe]));

        for (const employee of filteredEmployees) {

            const vm = new ProjectPersonSelectViewmodel(employee);

            if (projectPersonLookup.has(employee.id)) {
                this.assignedEmployees.push(vm);
            } else {
                this.unAssignedEmployees.push(vm);
            }
        }

        this.filter();
    }

    filter(): void {
        if (this.assignedSearchText) {
            const split = this.assignedSearchText.toLocaleLowerCase().split(" ");
            let assignedFilteredEmployees = new Array<ProjectPersonSelectViewmodel>();
            for (const segment of split) {

                const filteredItems = this.assignedEmployees.filter((ae) => !assignedFilteredEmployees.find((afe) => afe.employeeId && afe.employeeId === ae.employeeId) &&
                    !this.unAssignedEmployees.find((uae) => uae.employeeId === ae.employeeId) && (
                        ae.territory?.toLocaleLowerCase().includes(segment)
                        || ae.first?.toLocaleLowerCase().includes(segment)
                        || ae.last?.toLocaleLowerCase().includes(segment)))

                assignedFilteredEmployees = assignedFilteredEmployees.concat(filteredItems);
            }
            this.assignedFilteredEmployees = assignedFilteredEmployees.slice();
        } else {
            this.assignedFilteredEmployees = this.assignedEmployees.slice();
        }

        if (this.unAssignedSearchText) {
            const split = this.unAssignedSearchText?.toLowerCase().split(" ");
            let unAssignedFilteredEmployees = new Array<ProjectPersonSelectViewmodel>();
            for (const segment of split) {

                const filteredItems = this.unAssignedEmployees.filter((uae) => !unAssignedFilteredEmployees.find((uafe) => uafe.employeeId && uafe.employeeId === uae.employeeId) &&
                    !this.assignedEmployees.find((ae) => ae.employeeId === uae.employeeId) && (
                        uae.territory?.toLocaleLowerCase().includes(segment)
                        || uae.first?.toLocaleLowerCase().includes(segment)
                        || uae.last?.toLocaleLowerCase().includes(segment)))

                unAssignedFilteredEmployees = unAssignedFilteredEmployees.concat(filteredItems);
            }
            this.unAssignedFilteredEmployees = unAssignedFilteredEmployees.slice();
        } else {
            if (this.selectedZone === "All") {
                this.unAssignedFilteredEmployees = this.unAssignedEmployees.slice();
            } else {
                this.unAssignedFilteredEmployees = this.unAssignedEmployees.filter((ue) => +ue.storedEmployee.zone === this.selectedZone);
            }
        }
        this.assignedSort();
        this.unAssignedSort();
        this.shouldWait$.next(false);
    }

    addClicked(): void {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        //Give the spinner a chance to load so it feels important too
        setTimeout(() => {
            const itemsToMove = this.unAssignedEmployees.filter((ue) => ue.checked);
            if (!itemsToMove?.length) {
                this.snackbarService.showWarning("No unassigned employees have been selected to add to the project.");
            }
            itemsToMove.forEach((itm) => itm.checked = false);
            this.unAssignedEmployees = this.unAssignedEmployees.filter((ue) => !itemsToMove.find((itm) => itm.employeeId === ue.employeeId))
            this.assignedEmployees = this.assignedEmployees.concat(itemsToMove);
            this.allAssignedchecked = false;
            this.allUnassignedchecked = false;

            this.filter();
            this.buildProjectCustomersFromViewmodel();

        }, 0);
    }

    buildProjectCustomersFromViewmodel(): void {
        if (this.projectStateService.project) {
            this.projectStateService.project.projectEmployees ??= new Array<ProjectEmployee>();

            // remove items no longer valid
            const itemsToRemove = new Array<string>();
            for (const item of this.projectStateService.project.projectEmployees) {
                const found = this.assignedEmployees.find((ae) => ae.employeeId === item.employeeId);
                if (!found) {
                    itemsToRemove.push(item.employeeId);
                }
            }
            this.projectStateService.project.projectEmployees = this.projectStateService.project.projectEmployees
                .filter((pe) => {
                    const found = itemsToRemove.find((itr) => itr === pe.employeeId);
                    if (found) {
                        return false;
                    }
                    return true;
                });

            // remove now-unused employee assignments
            this.projectStateService.project.projectCustomers
                = this.projectStateService.project.projectCustomers
                    .map((pc) => {
                        pc.employeeIds = pc.employeeIds.filter((id) => !itemsToRemove.includes(id));
                        return pc;
                    });

            //items to add
            for (const item of this.assignedEmployees) {
                const found = this.projectStateService.project.projectEmployees.find((pe) => pe.employeeId === item.employeeId);
                if (!found) {
                    const newItem = new ProjectEmployee();
                    newItem.id = newSequentialId();
                    newItem.projectId = this.projectStateService.project.id;
                    newItem.employeeId = item.employeeId;

                    this.projectStateService.project.projectEmployees.push(newItem);
                }
            }
            this.projectStateService.notify();
        }
    }

    removeClicked(): void {

        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        //Give the spinner a chance to load so it feels important too
        setTimeout(() => {
            const itemsToMove = this.assignedEmployees.filter((ae) => ae.checked);
            if (!itemsToMove?.length) {
                this.snackbarService.showWarning("No assigned employees have been selected to remove from the project.");
            }
            itemsToMove.forEach((itm) => itm.checked = false);
            this.assignedEmployees = this.assignedEmployees.filter((ue) => !itemsToMove.find((itm) => itm.employeeId === ue.employeeId))
            this.unAssignedEmployees = this.unAssignedEmployees.concat(itemsToMove);
            this.allUnassignedchecked = false;
            this.allAssignedchecked = false;

            const removedEmployeeIds = itemsToMove.map((itm) => itm.employeeId);
            this.projectStateService.project.projectCustomers =
                this.projectStateService.project.projectCustomers.map((pc) => {
                    pc.employeeIds = pc.employeeIds.filter((id) => !removedEmployeeIds.includes(id));
                    return pc;
                }
            );
            this.filter();
            this.buildProjectCustomersFromViewmodel();
        }, 0);
    }



    drop(event: CdkDragDrop<ProjectPersonSelectViewmodel[]>): void {

        if (event.previousContainer !== event.container) {
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
            //Give the spinner a chance to load so it feels important too
            setTimeout(() => {
                const employeeId = event?.item.element.nativeElement.id;
                event.previousIndex = event.previousContainer.data.findIndex((data) => data.employeeId === event?.item.element.nativeElement.id);

                transferArrayItem(
                    event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex,
                );

                if (event.previousContainer.id === "unAssignedEmployees") {
                    const index = this.unAssignedEmployees.findIndex((uae) => uae.employeeId === employeeId);
                    this.unAssignedEmployees.splice(index, 1);
                    event.container.data[event.currentIndex].checked = false;
                    this.allAssignedchecked = false;
                    this.allUnassignedchecked = false;
                    this.assignedEmployees.push(event.container.data[event.currentIndex]);
                } else {
                    const index = this.assignedEmployees.findIndex((ae) => ae.employeeId === employeeId);
                    this.assignedEmployees.splice(index, 1);
                    event.container.data[event.currentIndex].checked = false;
                    this.allAssignedchecked = false;
                    this.allUnassignedchecked = false;
                    this.unAssignedEmployees.push(event.container.data[event.currentIndex]);
                    const removedEmployeeId = event.container.data[event.currentIndex].employeeId;
                    this.projectStateService.project.projectCustomers =
                    this.projectStateService.project.projectCustomers.map((pc) => {
                        pc.employeeIds = pc.employeeIds.filter((id) => removedEmployeeId !== id);
                        return pc;
                    });
                }

                this.filter();
                this.buildProjectCustomersFromViewmodel();
            }, 0);
        }

    }

    assignedSort(columnNameToSort?: keyof ProjectPersonSelectViewmodel): void {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        setTimeout(() => {
            if (columnNameToSort) {
                if (columnNameToSort === this.assignedFilteredEmployeesSortDto.name) {
                    if (this.assignedFilteredEmployeesSortDto.direction === SortDirection.ascending) {
                        this.assignedFilteredEmployeesSortDto.direction = SortDirection.descending;
                    } else {
                        this.assignedFilteredEmployeesSortDto.direction = SortDirection.ascending;
                    }
                } else {
                    this.assignedFilteredEmployeesSortDto.name = columnNameToSort;
                    this.assignedFilteredEmployeesSortDto.direction = SortDirection.ascending;
                }
            }

            this.assignedFilteredEmployees.sort((a, b) => {
                return (a[this.assignedFilteredEmployeesSortDto.name] ?? "") > (b[this.assignedFilteredEmployeesSortDto.name] ?? "")
                    ? (1 * this.assignedFilteredEmployeesSortDto.direction) : (a[this.assignedFilteredEmployeesSortDto.name] ?? "")
                        < (b[this.assignedFilteredEmployeesSortDto.name] ?? "") ? (-1 * this.assignedFilteredEmployeesSortDto.direction) : 0;
            })

            this.shouldWait$.next(false);
            this.assignedFilteredEmployees = [...this.assignedFilteredEmployees];
        }, 0);;
    }

    unAssignedSort(columnNameToSort?: keyof ProjectPersonSelectViewmodel): void {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        setTimeout(() => {
            if (columnNameToSort) {
                if (columnNameToSort === this.unAssignedFilteredEmployeesSortDto.name) {
                    if (this.unAssignedFilteredEmployeesSortDto.direction === SortDirection.ascending) {
                        this.unAssignedFilteredEmployeesSortDto.direction = SortDirection.descending;
                    } else {
                        this.unAssignedFilteredEmployeesSortDto.direction = SortDirection.ascending;
                    }
                } else {
                    this.unAssignedFilteredEmployeesSortDto.name = columnNameToSort;
                    this.unAssignedFilteredEmployeesSortDto.direction = SortDirection.ascending;
                }
            }

            this.unAssignedFilteredEmployees.sort((a, b) => {
                return (a[this.unAssignedFilteredEmployeesSortDto.name] ?? "") > (b[this.unAssignedFilteredEmployeesSortDto.name] ?? "")
                    ? (1 * this.unAssignedFilteredEmployeesSortDto.direction) : (a[this.unAssignedFilteredEmployeesSortDto.name] ?? "")
                        < (b[this.unAssignedFilteredEmployeesSortDto.name] ?? "") ? (-1 * this.unAssignedFilteredEmployeesSortDto.direction) : 0;
            });

            this.shouldWait$.next(false);
            this.unAssignedFilteredEmployees = [...this.unAssignedFilteredEmployees];
        }, 0);
    }

    selectAllUnassignedChanged(event: MatCheckboxChange): void {

        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        //Give the spinner a chance to load so it feels important too
        setTimeout(() => {

            this.unAssignedFilteredEmployees.forEach((uae) => uae.checked = event.checked);
            this.shouldWait$.next(false);
        }, 0);
    }

    selectAllAssignedChanged(event: MatCheckboxChange): void {

        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        //Give the spinner a chance to load so it feels important too
        setTimeout(() => {

            this.assignedFilteredEmployees.forEach((uae) => uae.checked = event.checked);
            this.shouldWait$.next(false);
        }, 0);
    }

    setSelectAllAssigned(): void {
        if (this.assignedFilteredEmployees.length === 0) {
            this.allAssignedchecked = false;
        } else {
            this.allAssignedchecked = !(!!this.assignedFilteredEmployees.find((afe) => !afe.checked));
        }
    }

    setSelectAllUnAssigned(): void {
        if (this.unAssignedFilteredEmployees.length === 0) {
            this.allUnassignedchecked = false;
        } else {
            this.allUnassignedchecked = !(!!this.unAssignedFilteredEmployees.find((afe) => !afe.checked));
        }
    }

    zoneChanged(event: MatRadioChange): void {

        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        //Give the spinner a chance to load so it feels important too
        setTimeout(() => {

            this.unAssignedSearchText = "";
            this.filter();
            this.setSelectAllUnAssigned();
            this.setSelectAllAssigned();
        }, 0);
    }

    //Assigns all employees to the project based on the Customers ZRT
    public async assignPeopleBasedOnCustomers(): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        const projectCustomers = await this.customerDeliniationService.getFromServerByIds(this.projectStateService.project.projectCustomers.map((pc) => pc.customerId));
        const customerZrts = new Set(projectCustomers.values.map((pc) => pc.zrt));
        //Give the spinner a chance to load so it feels important too
        setTimeout(() => {
            const itemsToMove = this.unAssignedEmployees.filter((ue) => customerZrts.has(ue.storedEmployee.zrt));
            if (!itemsToMove?.length) {
                this.snackbarService.showWarning("No unassigned employees have been selected to add to the project.");
            }
            itemsToMove.forEach((itm) => itm.checked = true);
            this.unAssignedEmployees = this.unAssignedEmployees.filter((ue) => !itemsToMove.find((itm) => itm.employeeId === ue.employeeId))
            this.assignedEmployees = this.assignedEmployees.concat(itemsToMove);
            this.allAssignedchecked = false;
            this.allUnassignedchecked = false;
            this.filter();
            this.buildProjectCustomersFromViewmodel();
        }, 0);
    }

    //Unassign all employees from the project based on the Customers ZRT
    public async undoAssignPeopleBasedOnCustomers(): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        const projectCustomers = await this.customerDeliniationService.getFromServerByIds(this.projectStateService.project.projectCustomers.map((pc) => pc.customerId));
        const customerZrts = new Set(projectCustomers.values.map((pc) => pc.zrt));
        //Give the spinner a chance to load so it feels important too
        setTimeout(() => {
            const itemsToMove = this.assignedEmployees.filter((ae) => customerZrts.has(ae.storedEmployee.zrt));
            if (!itemsToMove?.length) {
                this.snackbarService.showWarning("No assigned employees have been selected to remove from the project.");
            }
            itemsToMove.forEach((itm) => itm.checked = true);
            this.unAssignedEmployees = this.unAssignedEmployees.concat(itemsToMove)
            this.assignedEmployees = this.assignedEmployees.filter((ae) => !itemsToMove.find((itm) => itm.employeeId === ae.employeeId));
            this.allAssignedchecked = false;
            this.allUnassignedchecked = false;
            this.filter();
            this.buildProjectCustomersFromViewmodel();
        }, 0);
    }
}
