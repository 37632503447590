import { Component, OnInit } from "@angular/core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ButtonActions } from "src/app/shared/enums/button-actions.enum";
import { HeaderButtonComponent } from "../header-button/header-button.component";

@Component({
    selector: "app-add-button",
    templateUrl: "./add-button.component.html",
    styleUrls: ["./add-button.component.css"]
})
export class AddButtonComponent implements HeaderButtonComponent {
    icon: IconDefinition = faPlus;
    title: string;
    buttonAction = ButtonActions.add;
    isDisabled: boolean;
}
