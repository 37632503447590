import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";

export class SpecialCoverageInformationDialogViewModel extends BasicDialogViewModel {
    isConfirmed = false;
    imageUrl = "/assets/img/special-coverage-format.png";
    width = "40vw";

    constructor() {
        super();

        this.headerLeftText = "Special Coverage Information";
        this.buttonLeftText = "Close";
        this.buttonLeftDisabledFunction = () => false;
    }
}
