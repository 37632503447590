import { Injectable } from "@angular/core";
import { TimeEntryTypeNames } from "shield.shared";
import { TimeEntryType } from "src/app/entity-models/time-entry-type.entity";
import { DatabaseService } from "../database.service";

@Injectable()
export class TimeEntryTypeOfflineService {

    constructor(private dbService: DatabaseService){}

    async getTimeEntryTypeByName(name: TimeEntryTypeNames): Promise<TimeEntryType> {
        return await this.dbService.timeEntryTypes
            .where("name")
            .equals(name)
            .first();
    }
}
