import { EmployeeDto, SearchZrtDropDownDto } from "shield.shared";
import { Employee } from "src/app/entity-models/employee.entity";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";

export class EmployeeConverterService {

    static employeeDtoToEmployee(dto: EmployeeDto): Employee {
        return {
            ...dto,
            createdUtcDateTime: dto.createdUtcDateTime
                ? new Date(dto.createdUtcDateTime)
                : null,
            modifiedUtcDateTime: dto.modifiedUtcDateTime
                ? new Date(dto.modifiedUtcDateTime)
                : null,
            user: null
        };
    }

    static searchZrtDropDownDtoToEntity(dto: SearchZrtDropDownDto): SearchZrtDropDown {
        const rtn = new SearchZrtDropDown();

        rtn.zrt = dto.zrt;
        rtn.id = dto.employeeId;
        rtn.name = dto.name;
        rtn.displayValue = dto.displayValue;
        rtn.children = dto.children?.map(v => EmployeeConverterService.searchZrtDropDownDtoToEntity(v));

        return rtn;
    }
}
