import { ValidationCheck } from "./validation-check";
import { ValidationStopLevel } from "./validation-stop-level";
import { ValidationTargets } from "./validation-targets";

export abstract class Validation<T, K> {

    protected _root: T;
    steps = new Array<ValidationCheck<K>>();
    abstract validate(targets: ValidationTargets[]): ValidationCheck<K>[];

    get allMessages(): string[] {
        return (this.steps ?? []).filter((step) => !!step.message)?.map((step) => step.message)
    }

    get hasHardStop(): boolean {
        return (this.steps ?? []).some((step) => step.stopLevel === ValidationStopLevel.hard);
    }

    get hasSoftStop(): boolean {
        return (this.steps ?? []).some((step) => step.stopLevel === ValidationStopLevel.soft);
    }

    get hardStopValidationChecks(): ValidationCheck<K>[] {
        return (this.steps ?? []).filter((step) => step.stopLevel === ValidationStopLevel.hard);
    }

    get softStopValidationChecks(): ValidationCheck<K>[] {
        return (this.steps ?? []).filter((step) => step.stopLevel === ValidationStopLevel.soft);
    }
}
