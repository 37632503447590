import { ProductViewmodel } from "src/app/accounts/call-master/stepper-call/distribution-grid/product.viewmodel";
import { Customer } from "src/app/entity-models/customer.entity";
import { Product } from "src/app/entity-models/product.entity";

export class ProjectProductSelectViewmodel {
    product: Product;
    checked: boolean;
    description: string;
    uin: string;
    upc: string;
    wholesalerId: string;
    wholesalerGroupProductId: string;
    wholesalerName: string;
    dateAvailable: Date;
    dateAvailableFormatted: string;

    constructor(product: ProductViewmodel){
        this.product = product;
        this.description = product.description;
        this.upc = product.lowestSellableUpc?.upc;
        this.uin = product.uin;
        const wholesaler = product.wholesaler;
        if (wholesaler) {
            this.wholesalerId = wholesaler.id;
            this.wholesalerGroupProductId = product.wholesalerGroupProductId;
            this.wholesalerName = wholesaler.name;
            this.dateAvailable = product.dateAvailable;
            this.dateAvailableFormatted = product.dateAvailableFormatted;
        }
    }
}
