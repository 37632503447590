import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    GenericVersionResponseDto, SharedHelper, SyncCommandTypes,
    SystemInformationKeys, VersionResponseDto, WholesalerGroupProductCatalogItemDto,
    WholesalerGroupProductSyncCommand
} from "shield.shared";
import { WholesalerGroupProductCatalogItem } from "src/app/entity-models/wholesaler-group-product-catalog-item.entity";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { ProductConverterService } from "src/app/services/converter-services/product-converter.service";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { SyncLevel } from "../sync-enums/sync-level.enum";
import { WholesaleProductCatalogItemConverter } from "src/app/services/converter-services/wholesaler-product-catalog-item-converter.service";
import { WholesalerGroupProductCatalogItemConverterService } from "src/app/services/converter-services/wholesaler-group-product-catalog-item-converter.service";

@Injectable()
export class WholesalerGroupProductCatalogDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.Required;
    offlineRepSyncType = SyncLevel.Required;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.wholesalerGroupProductCatalog;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing wholesaler group product catalogs...");

        await this.pullData();

        this.log("Done syncing wholesaler group product catalogs...");
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>(
                    "/api/wholesaler-groups/product-catalogs/version"
                )
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private wholesalerGroupProductCatalogItemToWholesalerGroupProductCatalogItemDto(
        w: WholesalerGroupProductCatalogItem
    ): WholesalerGroupProductCatalogItemDto {
        return WholesalerGroupProductCatalogItemConverterService.entityToDto(w);
    }

    private wholesalerGroupProductCatalogItemDtoToWholesalerGroupProductCatalogItem(
        dto: WholesalerGroupProductCatalogItemDto
    ): WholesalerGroupProductCatalogItem {
        return WholesalerGroupProductCatalogItemConverterService.dtoToEntity(dto); 
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("Wholesaler group products catalogs data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Wholesaler group product catalogs data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.wholesalerGroupProductCatalogPullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 2000;

        try {
            let thisBatchSize: number;
            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;
                const response = await this.http
                    .get<GenericVersionResponseDto<WholesalerGroupProductCatalogItemDto[]>>(
                        `/api/wholesaler-groups/product-catalogs${query}`
                    ).toPromise();

                thisBatchSize = response.values?.length;

                this.log(
                    `Downloaded ${thisBatchSize} wholesaler group product catalogs, saving to IndexedDB...`
                );

                const wholesalerGroupProducts = response.values.map((wgpci) =>
                    this.wholesalerGroupProductCatalogItemDtoToWholesalerGroupProductCatalogItem(
                        wgpci
                    )
                );

                await this.dbService.wholesalerGroupProductCatalogItems.bulkPut(
                    wholesalerGroupProducts
                );
                this.log(
                    `  Saved  ${wholesalerGroupProducts.length} wholesaler group product catalogs data.`
                );

                this.lastSyncVersion = response.maxVersion;
            } while (thisBatchSize > 0);

            this.log("Done saving wholesaler group product catalogs data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing wholesaler group product catalogs", e);
        }
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<WholesalerGroupProductSyncCommand>(
            SyncCommandTypes.WholesalerGroupProduct,
            async (message) => {
                let wholesalerGroupProduct = await this.dbService.wholesalerGroupProductCatalogItems.get(
                    message.payload.id
                );

                let dto = this.wholesalerGroupProductCatalogItemToWholesalerGroupProductCatalogItemDto(
                    wholesalerGroupProduct
                );

                if (dto) {
                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }
}
