import {
    AfterContentChecked,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import { FormBuilder } from "@angular/forms";

import { MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import { SnackbarService } from "src/app/services/snackbar.service";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { AppStateService } from "src/app/services/app-state.service";
import { DailySummaryReportRefinerService } from "./daily-summary-report-refiner.service";
import { DailySummaryReportViewmodel } from "./daily-summary-report.viewmodel";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { DailySummaryReportZrtFilterService } from "./daily-summary-report-zrt-filter.service";
import { ZrtTreeViewComponent } from "src/app/shared/zrt-tree-view/zrt-tree-view.component";
import { OverlayService } from "src/app/services/overlay.service";
import { DailySummaryEntryDelineationService } from "src/app/services/delineation-services/daily-summary-entry-delineation.service";

@Component({
    selector: "app-daily-summary-report",
    templateUrl: "./daily-summary-report.component.html",
    styleUrls: ["./daily-summary-report.component.scss"],
    providers: [PleaseWaitService]
})
export class DailySummaryReportComponent
    implements OnInit, AfterContentChecked, OnDestroy {
    @HostBinding("class") class = "worksheet-static d-flex flex-column flex-grow-1";
    // ViewChilds
    @ViewChild("drawer") drawer: MatSidenav;
    @ViewChild("grid") grid: GridComponent;
    @ViewChild("sideNavContent") sideNavContent: MatSidenavContent;
    @ViewChild("header") header: PageHeaderComponent;
    @ViewChild("startDateInput") startDateInput: ElementRef;
    @ViewChild("endDateInput") endDateInput: ElementRef;
    @ViewChild("zrtTree") zrtTree: ZrtTreeViewComponent;

    // Public vars
    viewmodel: DailySummaryReportViewmodel;
    isViewmodelReady = false;
    screenHeight: number;

    constructor(
        private refinerService: DailySummaryReportRefinerService,
        private formBuilder: FormBuilder,
        private snackbar: SnackbarService,
        private pleaseWaitService: PleaseWaitService,
        private appStateService: AppStateService,
        private employeeDelineationService: EmployeeDelineationService,
        private filterService: FilterService,
        private zrtFilterService: DailySummaryReportZrtFilterService,
        private dailySummaryEntryDelineationService: DailySummaryEntryDelineationService,
        private overlayService: OverlayService
    ) {}

    async ngOnInit(): Promise<void> {
        this.viewmodel = new DailySummaryReportViewmodel(
            this.refinerService,
            this.formBuilder,
            this.snackbar,
            this.pleaseWaitService,
            this.appStateService,
            this.employeeDelineationService,
            this.filterService,
            this.zrtFilterService,
            this.dailySummaryEntryDelineationService,
            this.overlayService
        );
    }

    ngAfterContentChecked(): void {
        if (this.isViewmodelReady && !this.viewmodel.isGapSet) {
            this.viewmodel.calculateGap();
        }

        if (!this.isViewmodelReady
            && this.grid
            && this.header
            && this.sideNavContent
            && this.drawer
            && this.startDateInput
            && this.endDateInput
            && this.zrtTree
            ) {
            this.isViewmodelReady = true;
            this.getScreenSize();
            void this.viewmodel.initialize(
                this.grid,
                this.header,
                this.sideNavContent,
                this.drawer,
                this.startDateInput,
                this.endDateInput,
                this.zrtTree
            );
        }
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?: any): void {
        if (this.viewmodel) {
            this.viewmodel.screenHeight = window.innerHeight;
        }
    }
}
