import { RefinerLocation } from "../enum";
import { EntityObjectNames, EntityPropertyNames } from "../type";

export class RefinerDto {
    refinerLocation: RefinerLocation
    dtoObjectName: EntityObjectNames
    dtoPropertyName: EntityPropertyNames
    value: string;
    dataValue: string;
}
