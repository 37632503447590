import { SurveyDto, SurveyQuestionDto, SurveyQuestionOptionDto } from "shield.shared";
import { SurveyQuestionOption } from "src/app/entity-models/survey-question-drop-down-detail.entity";
import { SurveyQuestion } from "src/app/entity-models/survey-question.entity";
import { Survey } from "src/app/entity-models/survey.entity";

export class SurveyConverterService {

    static surveyToSurveyDto(d: Survey | null): SurveyDto | null{
        if (d == null) {
            return null;
        }

        return {
            ...d,
            createdUtcDateTime: d?.createdUtcDateTime?.toISOString(),
            modifiedUtcDateTime: d?.modifiedUtcDateTime?.toISOString(),
            surveyQuestions: d.surveyQuestions?.map((v) => SurveyConverterService.surveyQuestionToSurveyQuestionDto(v)),
            surveyCustomerMembers: []
        };
    }

    static surveyQuestionToSurveyQuestionDto(d: SurveyQuestion | null): SurveyQuestionDto | null {
        return {
            ...d,
            surveyQuestionOptions: d.surveyQuestionOptions?.map((v) => SurveyConverterService.surveyQuestionOptionToSurveyQuestionOptionDto(v))
        }
    }

    static surveyQuestionOptionToSurveyQuestionOptionDto(d: SurveyQuestionOption | null): SurveyQuestionOptionDto | null {
        return {
            ...d
        }
    }

    static surveyDtoToSurvey(dto: SurveyDto | null): Survey | null {
        if (dto == null) {
            return null;
        }

        return {
            ...dto,
            createdUtcDateTime: dto?.createdUtcDateTime ? new Date(dto.createdUtcDateTime) : null,
            modifiedUtcDateTime: dto?.modifiedUtcDateTime ? new Date(dto.modifiedUtcDateTime) : null,
            surveyQuestions: dto?.surveyQuestions?.map((v) => SurveyConverterService.surveyQuestionDtoToSurveyQuestion(v))
        };
    }

    static surveyQuestionDtoToSurveyQuestion(dto: SurveyQuestionDto | null): SurveyQuestion | null {
        return {
            ...dto,
            surveyQuestionOptions: dto?.surveyQuestionOptions?.map((v) => SurveyConverterService.surveyQuestionOptionDtoToSurveyQuestionOption(v))
        }
    }

    static surveyQuestionOptionDtoToSurveyQuestionOption(dto: SurveyQuestionOptionDto | null): SurveyQuestionOption | null {
        return {
            ...dto
        }
    }
}
