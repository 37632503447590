import { NgModule } from "@angular/core";
import { GratisRequestValidationService } from "./gratis-request.validation";


@NgModule({
    providers: [
        GratisRequestValidationService
    ],
})
export class ValidationModule {}
