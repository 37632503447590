export class CallOrderProductDto {
    id: string;
    orderDate: string;
    productId: string;
    wholesalerCustomerId: string;
    wholesalerCustomerCode: string;
    quantity: number;
    units: number;
    upc: string;
    storeCount: number;
    uin: string;
    projectId: string | undefined;
}
