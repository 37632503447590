//Angular
import { Injectable } from "@angular/core";
import Dexie from "dexie";

//Services

//Models
import { Product } from "../entity-models/product.entity";
import { Customer } from "../entity-models/customer.entity";
import { Picture } from "../entity-models/picture.entity";
import { Survey } from "../entity-models/survey.entity";
import { Contact } from "../entity-models/contact.entity";
import { TaxRate } from "../entity-models/tax-rate.entity";
import { TimeEntry } from "../entity-models/time-entry.entity";
import { RegisteredUser } from "../entity-models/registered-user-entity";
import { UserFilter } from "../entity-models/user-filter.entity";
import { Country } from "../entity-models/country.entity";
import { State } from "../entity-models/state.entity";
import { StateCategory } from "../entity-models/state-category.entity";
import { County } from "../entity-models/county.entity";
import {
    newSequentialId,
    NotificationRequestDto,
    SyncCommand,
    SyncCommandBase
} from "shield.shared";
import { Route } from "../entity-models/route.entity";
import { TimeEntryType } from "../entity-models/time-entry-type.entity";
import { DayTimeEntry } from "../entity-models/day-time-entry.entity";
import { Employee } from "../entity-models/employee.entity";
import { Call } from "../accounts/call-master/call-services/call.service";
import { Receipt } from "../entity-models/receipt";
import { WholesalerGroupMember } from "../entity-models/wholesaler-group-member.entity";
import { WholesalerGroupProductCatalogItem } from "../entity-models/wholesaler-group-product-catalog-item.entity";
import { WholesalerGroup } from "../entity-models/wholesaler-group.entity";
import { WholesalerProductCatalogItems } from "../entity-models/wholesaler-product-catalog-items.entity";
import { AccountOwnership } from "../entity-models/account-ownership.entity";
import { SystemInformation } from "../entity-models/system-information.entity";
import { ContractTemplate } from "../entity-models/contract-template.entity";
import { CustomerContract } from "../entity-models/customer-contract.entity";
import { Project } from "../entity-models/project.entity";
import { StateLicense } from "../entity-models/state-license.entity";
import { ReceiptSettings } from "../entity-models/receipt-settings.entity";
import { Area } from "../entity-models/area.entity";
import { AreaToZrt } from "../entity-models/area-to-zrt.entity";
import { ZrtAssignment } from "../entity-models/zrt-assignment.entity";
import { Px3Rank } from "../entity-models/px3-rank.entity";
import { CustomerPx3Rank } from "../entity-models/customer-px3-rank.entity";
import { Px3IncentivePeriod } from "../entity-models/px3-incentive-period.entity";

//Helpers

//Local Components

@Injectable({
    providedIn: "root"
})
export class DatabaseService extends Dexie {
    accountOwnerships: Dexie.Table<AccountOwnership, string>;

    customers: Dexie.Table<Customer, string>;

    products: Dexie.Table<Product, number>;

    calls: Dexie.Table<Call, string>;

    pictures: Dexie.Table<Picture, string>;

    surveys: Dexie.Table<Survey, string>;

    contacts: Dexie.Table<Contact, string>;

    syncQueue: Dexie.Table<SyncCommand, number>;

    taxRates: Dexie.Table<TaxRate, string>;

    timeEntries: Dexie.Table<TimeEntry, string>;

    registeredUsers: Dexie.Table<RegisteredUser, string>;

    userFilters: Dexie.Table<UserFilter, string>;

    countries: Dexie.Table<Country, string>;

    states: Dexie.Table<State, string>;

    stateCategories: Dexie.Table<StateCategory, string>;

    systemInformation: Dexie.Table<SystemInformation, string>;

    counties: Dexie.Table<County, string>;

    routes: Dexie.Table<Route, string>;

    timeEntryTypes: Dexie.Table<TimeEntryType, string>;

    dayTimeEntries: Dexie.Table<DayTimeEntry, string>;

    employees: Dexie.Table<Employee, string>;

    receipts: Dexie.Table<Receipt, string>;

    px3Ranks: Dexie.Table<Px3Rank, string>;

    customerPx3Ranks: Dexie.Table<CustomerPx3Rank, string>;

    px3IncentivePeriods: Dexie.Table<Px3IncentivePeriod, string>;

    wholesalerGroupMembers: Dexie.Table<WholesalerGroupMember, string>;

    wholesalerGroupProductCatalogItems: Dexie.Table<WholesalerGroupProductCatalogItem, string>;

    wholesalerGroups: Dexie.Table<WholesalerGroup, string>;

    wholesalerProductCatalogItems: Dexie.Table<WholesalerProductCatalogItems, string>;

    notificationRequests: Dexie.Table<NotificationRequestDto, string>;

    contractTemplates: Dexie.Table<ContractTemplate, string>;

    customerContracts: Dexie.Table<CustomerContract, string>;

    projects: Dexie.Table<Project, string>;

    stateLicenses: Dexie.Table<StateLicense, string>;

    receiptSettings: Dexie.Table<ReceiptSettings, string>;

    areas: Dexie.Table<Area, string>;

    areaToZrts: Dexie.Table<AreaToZrt, string>;

    deadLetterQueue: Dexie.Table<SyncCommand, string>;

    zrtAssignments: Dexie.Table<ZrtAssignment, string>;

    constructor() {
        super("shield");

        /*

        DO NOT CHANGE THIS VERSION CALL OR ANY INDEXES RIGHT NOW!

        I've added a migration for version 211, any further versions should be made in a new version()
        call below that. We can merge this all back together later on once DB version 211 is deployed 
        to Prod.

        See below the call to this.version(208) if you need to make any IndexedDB changes.

        */
        this.version(208).stores({
            accountOwnerships: "id,ownerCode,businessOwnerCode,hierarchyLevel.id,name",
            products:
            "id,description,*upcNumbers,returnableUpc.upc,lowestSellableUpc.upc,isDeleted,[isDeleted+isDeactivated+shield]",
            customers:
                "id,ownerCode,customerNumber,isActive,name,city,county,zip,state,zone,zrt,externalSyncId,hasServerProcessed,isZrtAssignment,isProjectAssignment,isSpecialAssignment,customerType.id, px3RankId, callsMade",
            customerPx3Ranks: "id,customerId,px3RankId,px3IncentivePeriodId,[customerId+px3IncentivePeriodId]",
            calls:
                "id,customerId,userZrt,stopTime,createdBy,createdDate,createdUserId,createdUserZrt,type,createdUtcDateTime,hasServerProcessed,[customerId+stopTime], [customerId+callType+stopTime], [createdUserId+stopTime], [createdUserId+createdUserZrt+stopTime], zrt, county, state, callType, hasPictures, px3RankId",
            pictures: "id",
            surveys: "id,surveyType",
            contacts: "id,customerId,hasServerProcessed",
            syncQueue: "id++,[entity+nextVisibleTime],dequeuedAt",
            timeEntries: "id,sourceId,start,end,employeeId,hasServerProcessed",
            taxRates: "id,locality,stateId,isActive,taxTypeId,taxDivisionId.taxCalculationMethodId",
            registeredUsers: "id,userId,employeeId,hasServerProcessed",
            userFilters: "id,[userId+filterLocation]",
            px3Ranks: "id,rank",
            px3IncentivePeriods: "id",
            countries: "id,alpha3Code,name",
            states: "id,name,code,countryId,shortCode",
            stateCategories: "id,name",
            systemInformation: "key",
            counties: "id,name,stateId",
            routes: "id,employeeId,employeeZrt,date,rowversion,hasServerProcessed,[employeeId+hasServerProcessed], [employeeId+date]",
            timeEntryTypes: "id,name",
            dayTimeEntries: "id,[key+userId],[employeeId+date]",
            employees: "id,employeeNumber,email,fullName,zrt,isDeleted",
            chainHeadquarters: "id,customerId",
            receipts: "id",
            wholesalerGroupMembers: "id,wholesalerGroupId,wholesalerId,customerId",
            wholesalerGroupProductCatalogItems: "id,wholesalerGroupId,productId,wholesalerGroupProductCatalogItemId,isDeactivated,isDeleted,isAvailable",
            wholesalerGroups: "id,name",
            wholesalerProductCatalogItems: "id,wholesalerGroupProductCatalogItemId,wholesalerId,productId",
            notificationRequests: "id",
            contractTemplates: "id,contractTemplateType.id,rowversion,[zone+region+territory]",
            customerContracts: "id,customerId,signatureDate,endDate,hasServerProcessed",
            projects: "id,name,projectStatusId,startDate",
            stateLicenses: "id,subsidiaryId,stateName,stateShortCode,stateId",
            receiptSettings: "employeeId",
            areas: "id,subsidiaryId,name",
            areaToZrts: "id,subsidiaryId,areaId,zrt",
            deadLetterQueue: "id",
            zrtAssignments: "zrt,employeeId,subsidiaryId"
        });

        //TODO This should be removed once we are sufficiently past version 211
        this.version(211).stores({
            products:
                "id,description,*upcNumbers,returnableUpc.upc,lowestSellableUpc.upc,isDeleted,isDropDownSelectable",
        }).upgrade(
            async trans => {
                const db = trans.db as DatabaseService;
                await db.products.toCollection().modify(product => {
                    product.isDropDownSelectable = 
                        product.shield && !product.isDeactivated && !product.isDeleted ? 1 : 0;
                });
            }
        );

        /*

        Make any newer DB versions here following the below comment template.

        */

        // ** if we need to do migrations in the future, this pattern works...
        //  more info:  https://dexie.org/docs/Version/Version.upgrade()
        // this.version(0) // <- next version
        //     .stores({
        //         // list any tables with changed indexes here... example for product table:
        //         products:
        //             "id,description,*upcNumbers,returnableUpc.upc,lowestSellableUpc.upc"
        //     })
        //     .upgrade(
        //         async (trans): Promise<void> => {
        //             const db = trans.db as DatabaseService;
        //             // here you can use db to modify the data... example change to product data:
        //             await db.products.toCollection().modify((product) => {
        //                 product.upcNumbers = product.upcs?.map((u) => u.upc);
        //             });
        //         }
        //     );

        this.accountOwnerships.mapToClass(AccountOwnership);
        this.customers.mapToClass(Customer);
        this.products.mapToClass(Product);
        this.pictures.mapToClass(Picture);
        this.surveys.mapToClass(Survey);
        this.contacts.mapToClass(Contact);
        this.customerPx3Ranks.mapToClass(CustomerPx3Rank);
        this.syncQueue.mapToClass(SyncCommandBase);
        this.taxRates.mapToClass(TaxRate);
        this.timeEntries.mapToClass(TimeEntry);
        this.registeredUsers.mapToClass(RegisteredUser);
        this.userFilters.mapToClass(UserFilter);
        this.px3IncentivePeriods.mapToClass(Px3IncentivePeriod);
        this.px3Ranks.mapToClass(Px3Rank);
        this.countries.mapToClass(Country);
        this.states.mapToClass(State);
        this.stateCategories.mapToClass(StateCategory);
        this.counties.mapToClass(County);
        this.routes.mapToClass(Route);
        this.timeEntryTypes.mapToClass(TimeEntryType);
        this.dayTimeEntries.mapToClass(DayTimeEntry);
        this.employees.mapToClass(Employee);
        this.receipts.mapToClass(Receipt);
        this.calls = this.table("calls");
        this.wholesalerGroupMembers.mapToClass(WholesalerGroupMember);
        this.wholesalerGroupProductCatalogItems.mapToClass(WholesalerGroupProductCatalogItem);
        this.notificationRequests.mapToClass(NotificationRequestDto);
        this.customerContracts.mapToClass(CustomerContract);
        this.projects.mapToClass(Project);
        this.stateLicenses.mapToClass(StateLicense);
        this.receiptSettings.mapToClass(ReceiptSettings);
        this.areas.mapToClass(Area);
        this.areaToZrts.mapToClass(AreaToZrt);
        this.zrtAssignments.mapToClass(ZrtAssignment);
    }

    async checkIsOpen(): Promise<void> {
        // the database sometimes disappears, this makes sure it recreates it if needed
        if (this.isOpen()) this.close();
        await this.open();
    }

    hasItems<T>(items: T[]): boolean {
        return items && items.length > 0;
    }

    newSequentialId(): string {
        return newSequentialId();
    }
}
