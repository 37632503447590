import { Injectable } from "@angular/core";
import { FilterRequestV2Dto, FilterSortDto, GenericRequestDto, GenericResponseDto, GratisBatchParamsDto, GratisCurrentBalanceDto, GratisListColumns, GratisRejectApprovalParamsDto, RefinerLocation } from "shield.shared";
import { GratisApproval } from "src/app/entity-models/gratis-approval.entity";
import { GratisAssignedTransaction } from "src/app/entity-models/gratis-assigned-transactions.entity";
import { Gratis } from "src/app/entity-models/gratis.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { DatabaseService } from "../database.service";
import { GratisFilterMapService } from "../filter-map-services/gratis-filter-map.service";
import { GratisOfflineService } from "../offline-services/gratis-offline.service";
import { GratisOnlineService } from "../online-services/gratis-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class GratisDelineationService extends DelineationContext<Gratis, string> {

    constructor(private gratisOfflineService: GratisOfflineService,
        private gratisOnlineService: GratisOnlineService,
        protected datasourceDelineationService: DatasourceDelineationService,
        protected dbService: DatabaseService,
        snackbarService: SnackbarService){
            super(dbService, datasourceDelineationService, snackbarService);
        }

        async getBatch(id: string
            , refiners: Refiner[]
            , pageSize: number
            , startIndex: number
            , filterSorts: FilterSortDto<GratisListColumns>[]
        ): Promise<GenericResponseDto<Gratis[]>> {

            const key = new GratisBatchParamsDto();
            key.filterRequestDto = new FilterRequestV2Dto();
            key.filterRequestDto.id = id;
            key.filterRequestDto.filters = GratisFilterMapService.mapFilterData(refiners);
            key.filterRequestDto.pageSize = pageSize;
            key.filterRequestDto.startIndex = startIndex;
            key.filterRequestDto.filterSorts = filterSorts;
            key.zrt = refiners?.find((r) => r.location === RefinerLocation.zrt || r.location === RefinerLocation.zrtByEmployee)?.value;

            const offline = (key: GratisBatchParamsDto) => {
                return this.gratisOfflineService.getBatch(key);
            }
            const online = (key: GratisBatchParamsDto) => {
                return this.gratisOnlineService.getBatch(key);
            }
            const response = await this.datasourceDelineationService.makeCall<GratisBatchParamsDto, Gratis[]>(key, offline, online);


            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }

        async completeOrder(gratis: Gratis) : Promise<GenericResponseDto<GratisApproval>> {

            const offline = (key: Gratis) => {
                return this.gratisOfflineService.completeOrder(key);
            }
            const online = (key: Gratis) => {
                return this.gratisOnlineService.completeOrder(key);
            }
            const response = await this.datasourceDelineationService.makeCall<Gratis, GratisApproval>(gratis, offline, online);


            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }

        async deletedGratisRequest(gratisId: string): Promise<GenericResponseDto<string>> {

            const offline = (key: string) => {
                return this.gratisOfflineService.deletedGratisRequest(key);
            }
            const online = (key: string) => {
                return this.gratisOnlineService.deletedGratisRequest(key);
            }
            const response = await this.datasourceDelineationService.makeCall<string, string>(gratisId, offline, online);


            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }

        async getCurrentBalances(): Promise<GenericResponseDto<GratisCurrentBalanceDto[]>> {

            const offline = (key: undefined) => {
                return this.gratisOfflineService.getCurrentBalances(key);
            }
            const online = (key: undefined) => {
                return this.gratisOnlineService.getCurrentBalances(key);
            }
            const response = await this.datasourceDelineationService.makeCall<undefined, GratisCurrentBalanceDto[]>(undefined, offline, online);


            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }

        async getGratisById(id: string): Promise<GenericResponseDto<Gratis | undefined>> {

            const offline = (key: string) => {
                return this.gratisOfflineService.getGratisById(key);
            }
            const online = (key: string) => {
                return this.gratisOnlineService.getGratisById(key);
            }
            const response = await this.datasourceDelineationService.makeCall<string, Gratis | undefined>(id, offline, online);


            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }

        async getAvailableForGratis(gratisId?: string): Promise<GenericResponseDto<GratisAssignedTransaction[]>> {

            const offline = (key: string) => {
                return this.gratisOfflineService.getAvailableForGratis(key);
            }
            const online = (key: string) => {
                return this.gratisOnlineService.getAvailableForGratis(key);
            }
            const response = await this.datasourceDelineationService.makeCall<string, GratisAssignedTransaction[]>(gratisId, offline, online);


            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }

        async saveGratisRequest(gratisRequest: GenericRequestDto<Gratis>): Promise<GenericResponseDto<Gratis | undefined>> {

            const offline = (key:  GenericRequestDto<Gratis>) => {
                return this.gratisOfflineService.saveGratisRequest(key);
            }
            const online = (key:  GenericRequestDto<Gratis>) => {
                return this.gratisOnlineService.saveGratisRequest(key);
            }
            const response = await this.datasourceDelineationService.makeCall<GenericRequestDto<Gratis>, Gratis>(gratisRequest, offline, online);

            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }

        async submitGratisRequest(gratisRequest: GenericRequestDto<Gratis>): Promise<GenericResponseDto<Gratis | undefined>> {

            const offline = (key:  GenericRequestDto<Gratis>) => {
                return this.gratisOfflineService.submitGratisRequest(key);
            }
            const online = (key:  GenericRequestDto<Gratis>) => {
                return this.gratisOnlineService.submitGratisRequest(key);
            }
            const response = await this.datasourceDelineationService.makeCall<GenericRequestDto<Gratis>, Gratis>(gratisRequest, offline, online);

            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }

        async rejectApproval(gratisId: string, reason: string): Promise<GenericResponseDto<Gratis>> {

            const params = new GratisRejectApprovalParamsDto();
            params.gratisId = gratisId;
            params.reason = reason;

            const offline = (key:  GratisRejectApprovalParamsDto) => {
                return this.gratisOfflineService.rejectApproval(key);
            }
            const online = (key:  GratisRejectApprovalParamsDto) => {
                return this.gratisOnlineService.rejectApproval(key);
            }
            const response = await this.datasourceDelineationService.makeCall<GratisRejectApprovalParamsDto, Gratis>(params, offline, online);

            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }

        async approve(gratisId: string): Promise<GenericResponseDto<Gratis>> {

            const offline = (key:  string) => {
                return this.gratisOfflineService.approve(key);
            }
            const online = (key:  string) => {
                return this.gratisOnlineService.approve(key);
            }
            const response = await this.datasourceDelineationService.makeCall<string, Gratis>(gratisId, offline, online);

            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }
}
