import { CustomerContractDto, CustomerContractPaymentDto } from "shield.shared";
import { CustomerContractPayment } from "src/app/entity-models/customer-contract-payment.entity";

export class CustomerContractPaymentConverterService {

    static customerContractPaymentToCustomerContractPaymentDto(d: CustomerContractPayment | null, signature?: Blob): CustomerContractPaymentDto | null {
        if (d == null) {
            return null;
        }

        return {
            ...d,
            paymentDate: d?.paymentDate?.toISOString(),
            createdUtcDateTime: d?.createdUtcDateTime?.toISOString(),
            modifiedUtcDateTime: d?.modifiedUtcDateTime?.toISOString(),
            signatureBlob: d.customerContactSignature.image,
        };
    }

    static customerContractPaymentDtoToCustomerContractPayment(dto: CustomerContractPaymentDto): CustomerContractPayment {
        if (dto == null) {
            return null;
        }

        const entity = new CustomerContractPayment();
        entity.id = dto.id;
        entity.contractPaymentMethod = dto.contractPaymentMethod;
        entity.contractPaymentStatus = dto.contractPaymentStatus;
        entity.contractPaymentType = dto.contractPaymentType;
        entity.createdUserFullName = dto.createdUserFullName;
        entity.createdUserId = dto.createdUserId;
        entity.createdUserZrt = dto.createdUserZrt;
        entity.createdUtcDateTime = dto.createdUtcDateTime ? new Date(dto.createdUtcDateTime) : new Date();
        entity.customerContactId = dto.customerContactId;
        entity.customerContactName = dto.customerContactName;
        entity.customerContractId = dto.customerContractId;
        entity.customerId = dto.customerId;
        entity.modifiedUserFullName = dto.modifiedUserFullName;
        entity.modifiedUserId = dto.modifiedUserId;
        entity.modifiedUserZrt = dto.modifiedUserZrt;
        entity.modifiedUtcDateTime = dto.modifiedUtcDateTime ? new Date(dto.modifiedUtcDateTime): null;
        entity.paymentAmount = dto.paymentAmount;
        entity.paymentDate = dto.paymentDate ? new Date(dto.paymentDate) : null;
        entity.reason = dto.reason;
        entity.gratisId = dto.gratisId;
        entity.gratisNumber = dto.gratisNumber;

        return entity;
    }
}
