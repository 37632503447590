import { Injectable } from "@angular/core";
import { newSequentialId } from "shield.shared";
import { Employee } from "src/app/entity-models/employee.entity";
import { RegisteredUser } from "src/app/entity-models/registered-user-entity";
import { AppStateService } from "../app-state.service";
import { RegisteredUserDelineationService } from "../delineation-services/registered-user-delineation.service";
import { PingService } from "../ping.service";

@Injectable()
export class PingWatcherService {

    private employee: Employee;

    constructor(
        private registeredUserDelineationService: RegisteredUserDelineationService,
        private pingService: PingService,
        private appStateServcie: AppStateService
    ) {
        this.appStateServcie.currentEmployee.subscribe((employee) => {
            this.employee = employee;
        })
        this.pingService.observableSeeMe.subscribe(() => {
            this.seeMe();
        });
    }

    async seeMe(): Promise<void> {
        if (this.employee) {
            const registeredUserResponse = await this.registeredUserDelineationService.getByEmployeeId(
                this.employee.id
            );
            if (registeredUserResponse) {
                let registeredUser: RegisteredUser = null;
                if (registeredUserResponse.values) {
                    registeredUser = registeredUserResponse.values;

                    if (registeredUser) {
                        registeredUser.lastSeenUtcDateTime = new Date();
                        await this.registeredUserDelineationService.autosaveRegisteredUser(
                            registeredUser
                        );
                    }
                }
            }
        }
    }
}
