<mat-card class="d-flex flex-column flex-grow-1">
    <div
        *ngIf="!beforePicture"
        class="d-flex flex-column align-items-center">
        <div class="empty-state">
            <div class="empty-state-icon-container">
                        <span class="material-icons empty-state-icon">
                            insert_photo
                        </span>
            </div>
            <div class="title">No Before Picture</div>
            <div class="subtitle">
                Before Picture will display here once taken
            </div>
        </div>
        <button appThrottleClick class="btn btn-outline-danger" (throttledClick)="onOpenModal()">
            <span class="flaticon-photo-camera"></span> Take Before
            Picture
        </button>
    </div>
    <div *ngIf="beforePicture" class="d-flex flex-column flex-grow-1">
        <h2 class="margin-bottom-unset m-teal">
            <b>Before Picture</b>
        </h2>
        <div class="d-flex flex-column flex-grow-1">
            <img
                *ngIf="beforeImg"
                [src]="beforeImg"
                alt="Before Picture"
                class="before-canvas photo-responsive mobile-photo"
            />
            <img
                *ngIf="!beforeImg"
                [src]="'assets/img/missing-picture.png'"
                alt="Missing Before Picture"
                class="before-canvas photo-responsive mobile-photo"
            />
        </div>
        <div class="d-flex mt-3 justify-content-center">
            <button appThrottleClick
                    class="btn btn-outline-danger"
                    (throttledClick)="onOpenModal()"
            >
                <span class="flaticon-photo-camera"></span> Retake
                Before Picture
            </button>
        </div>
    </div>
</mat-card>
