import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
    apiVersion, CustomerContractDto, CustomerContractSyncCommand,
    GenericResponseDto,
    GenericVersionResponseDto, SharedHelper, SignatureDto,
    SyncCommandTypes, SystemInformationKeys, VersionResponseDto
} from "shield.shared";
import { DatabaseService } from "../../services/database.service";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { CustomerContractConverterService } from "src/app/services/converter-services/customer-contract-converter.service";
import { SignatureConverterService } from "src/app/services/converter-services/signature-converter.service";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { SyncLevel } from "../sync-enums/sync-level.enum";

@Injectable()
export class CustomerContractDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    apiVersion = apiVersion;
    onlineRepSyncType = SyncLevel.None;
    offlineRepSyncType = SyncLevel.Required;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.customerContract;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing customer contracts...");

        await this.pullData();

        this.log("Done syncing customer contracts...");
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/customer-contracts/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("Customer contract data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Customer contract data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.customerContractPullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;

        try {

            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;

                this.log(`Get customer contracts [` + query + `]`);
                const response = await this.http
                    .get<GenericVersionResponseDto<CustomerContractDto[]>>(`/api/customer-contracts${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length;

                this.log(`  Downloaded ${thisBatchSize}, saving to IndexedDB...`);

                const signatureDtos = new Array<SignatureDto>();
                for (const value of response.values) {

                    try {

                        const response =  await this.http.get<GenericResponseDto<SignatureDto | undefined>>(`/api/customer-contracts/signature/${value.id}`).toPromise();
                        if (!response) { continue; }

                        if (response.values) {

                            signatureDtos.push(response.values);
                        }

                    } catch (e) {

                        this.log(`Error retriving contract signatures from server for contract id: ${value.id}`);
                        this.log(e);
                    }
                }

                const ccs = response.values.map((dto) => {
                    const c = CustomerContractConverterService.customerContractDtoToCustomerContract(dto);
                    c.hasServerProcessed = 1;
                    c.customerSignature = signatureDtos ? SignatureConverterService.signatureDtoToSignature(
                        signatureDtos.find((s) => s.id === c.id)) : null;
                    return c;
                }
                );

                await this.dbService.customerContracts.bulkPut(ccs);
                this.log(`  Saved ${ccs.length} customer contracts.`);

                this.lastSyncVersion = response.maxVersion;
            } while (thisBatchSize > 0);

            this.log("Done saving customer contract data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing customer contracts", e);
        }
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<CustomerContractSyncCommand>(
            SyncCommandTypes.customerContract,
            async (message) => {
                let contract = await this.dbService.customerContracts.get(
                    message.payload.id
                );

                const dto = CustomerContractConverterService.customerContractToCustomerContractDto(contract);

                if (dto) {
                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }
}
