import { TransactionLineItemType } from "shield.shared";

export class TransactionLineItemViewmodel {
    id: string; // uuid
    name: string;
    qty: number;
    oldQty: number;
    units: number;
    retailPrice: number;
    oldRetailPrice: number;
    wholesalePrice: number;
    oldWholesalePrice: number;
    oldUnits: number;
    discount: number;
    oldDiscount: number;
    netTotal: number;
    type: TransactionLineItemType = TransactionLineItemType.CashSale | TransactionLineItemType.Gratis
        || TransactionLineItemType.ExchangeIn | TransactionLineItemType.ExchangeOut;
    typeLabel: string;
}

