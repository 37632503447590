import * as moment from "moment";
import { OrderLineItem } from "src/app/entity-models/order-line-item.entity";
import { Order } from "src/app/entity-models/order.entity";
import { OrderStatusLookup } from "shield.shared";
import { OrderListLineItemViewModel } from "./order-list-line-item.viewmodel";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { Helper } from "src/app/helpers/helper";
import { Call } from "src/app/accounts/call-master/call-services/call.service";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";
import { RetailCall } from "src/app/entity-models/retail-call.entity";

export class OrderListGridViewmodel {
    id: string;
    referenceId: string;
    orderDate: string;
    orderStatusId: number;
    callId: string;
    callDate: string;
    customerId: string;
    retailNumber: string;
    customerName: string | null;
    customerAddress1: string | null;
    customerAddress2: string | null;
    customerCity: string | null;
    customerState: string | null;
    customerZip: string | null;
    customerCounty: string | null;
    wholesalerId: string;
    wholesalerName: string | null;
    wholesalerCustomerCode: string | null;
    chainName: string | null;
    chainNumber: string | null;
    cancelledDate: Date | null;
    cancelledBy: string | null;
    orderExtractionId: string | null;
    dateCreated: Date;
    createdBy: string;
    lastUpdated: Date;
    lastUpdatedBy: string;
    orderLineItems: OrderListLineItemViewModel[];
    csOrAdmin: boolean;

    orderStatus: string;
    storeInformation: string;
    dateCreatedFormatted: string;
    dateUpdatedFormatted: string;

    dateFormat = MY_DATE_FORMATS.display.dateInput;
    isConfirmed = false;
    affected: number;
    receiptNumber: string;

    constructor(order: Order){
        this.id = order.id;
        this.referenceId = order.referenceId;
        const orderDate = moment(order.orderDate)
        this.orderDate = orderDate.format(this.dateFormat);
        this.orderStatusId = order.orderStatusId;
        this.callId = order.callId;
        const callDate = moment(order.callDate);
        this.customerId = order.customerId;
        this.callDate = callDate.format(this.dateFormat);
        this.retailNumber = order.retailNumber;
        this.customerName = order.customerName;
        this.customerAddress1 = order.customerAddress1;
        this.customerAddress2 = order.customerAddress2;
        this.customerCity = order.customerCity;
        this.customerState = order.customerState;
        this.customerZip = order.customerZip;
        this.customerCounty = order.customerCounty;
        this.wholesalerId = order.wholesalerId;
        this.wholesalerName = order.wholesalerName;
        this.wholesalerCustomerCode = order.wholesalerCustomerCode ?? "";
        this.chainName = (order.chainName ?? "") + " : " + (order.chainNumber ?? "");
        this.chainNumber = order.chainNumber;
        this.cancelledDate = order.cancelledDate;
        this.cancelledBy = order.cancelledBy;
        this.orderExtractionId = order.orderExtractionId;
        this.dateCreated = order.createdUtcDateTime;
        this.lastUpdated = order.modifiedUtcDateTime;
        this.lastUpdatedBy = Helper.formatDisplayName(order);
        this.orderLineItems = order.orderLineItems?.map((li) => new OrderListLineItemViewModel(li));
        this.orderStatus = OrderStatusLookup.find((lookup) => lookup.id === this.orderStatusId)?.name;
        this.storeInformation = (this.customerName ?? "")
            + (this.customerAddress1 ? "; " + this.customerAddress1 : "")
            + (this.customerCity ? "; " + this.customerCity : "")
            + (this.customerState ? ", " + this.customerState : "")
            + (this.customerZip ? " " + this.customerZip : "");
        this.dateCreatedFormatted = moment(this.dateCreated).format(this.dateFormat);
        this.dateUpdatedFormatted = moment(this.lastUpdated).format(this.dateFormat);
        this.receiptNumber = order.receiptNumber;
        this.createdBy = Helper.formatDisplayName(order, true);
    }
}


