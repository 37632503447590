import * as moment from "moment";
import { Transaction } from "src/app/entity-models/transaction.entity";
import { TransactionLineItem } from "src/app/entity-models/transaction-line-item.entity";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { Helper } from "src/app/helpers/helper";

export class TransactionsListGridViewmodel {
    id: string;
    callId: string;
    callDate: string;
    customerId: string;
    retailNumber: string | null;
    customerName: string | null;
    customerAddress1: string | null;
    customerAddress2: string | null;
    customerCity: string | null;
    customerState: string | null;
    customerZip: string | null;
    customerCounty: string | null;
    retailReceiptNumber: string;
    salesTotal = 0;
    netTotal = 0;
    gratisTotal = 0;
    gratisPercentage = 0;
    gratisId: string | null;
    gratisNumber: string | null;
    dateCreated: string;
    createdBy: string;
    lastUpdated: Date;
    lastUpdatedBy: string;

    transactionLineItems: TransactionLineItem[];
    storeInformation: string;


    dateFormat = MY_DATE_FORMATS.display.dateInput;

    constructor(call: Transaction) {
        this.id = call.id;
        this.callId = call.callId;
        const callDate = moment(call.callDate);
        this.callDate = callDate.format(this.dateFormat);
        this.customerId = call.customerId;
        this.retailNumber = call.retailNumber;
        this.customerName = call.customerName;
        this.customerAddress1 = call.customerAddress1;
        this.customerAddress2 = call.customerAddress2;
        this.customerCity = call.customerCity;
        this.customerState = call.customerState;
        this.customerZip = call.customerZip;
        this.customerCounty = call.customerCounty;
        this.retailReceiptNumber = call.receiptNumber;
        this.salesTotal = call.salesTotal;
        this.netTotal = call.netTotal;
        this.gratisTotal = call.gratisTotal;
        this.gratisPercentage = call.gratisPercentage
        this.gratisId = call.gratisId;
        this.gratisNumber = call.gratisNumber;
        const dateCreated = moment(call.createdUtcDateTime);
        this.dateCreated = dateCreated.format(this.dateFormat);
        this.createdBy = Helper.formatDisplayName(call, true);
        this.lastUpdated = call.modifiedUtcDateTime;
        this.lastUpdatedBy = Helper.formatDisplayName(call);

        this.transactionLineItems = call.transactionLineItems;

        this.storeInformation = (this.customerName ?? "")
            + (this.customerAddress1 ? "; " + this.customerAddress1 : "")
            + (this.customerAddress2 ? " " + this.customerAddress2 : "")
            + (this.customerCity ? "; " + this.customerCity : "")
            + (this.customerState ? " " + this.customerState : "")
            + (this.customerZip ? ", " + this.customerZip : "");
    }
}


