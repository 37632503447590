import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CustomerStateService } from "src/app/accounts/account-services/customer-state.service";
import { Employee } from "src/app/entity-models/employee.entity";
import { AppStateService } from "src/app/services/app-state.service";
import { RelatedWholesalerViewmodel } from "./related-wholesalers.viewmodel";

@Component({
    selector: "app-related-wholesalers",
    templateUrl: "./related-wholesalers.component.html",
    styleUrls: ["./related-wholesalers.component.scss"]
})
export class RelatedWholesalersComponent {
    currentEmployee: Employee;
    private _customerMembers: RelatedWholesalerViewmodel[];
    customerMemberIds: string[];

    @Input()
    get customerMembers(): RelatedWholesalerViewmodel[] {
        return this._customerMembers;
    }
    set customerMembers(value: RelatedWholesalerViewmodel[]) {
        this._customerMembers = value;
        this.customerMemberIds = value.filter((w) => !!w.wholesaler).map((w) => w.wholesaler.id);
    }

    @Output() onOpenWholesaler = new EventEmitter<RelatedWholesalerViewmodel>();

    constructor(public customerStateService: CustomerStateService, public appStateService: AppStateService)
    {
        this.appStateService.currentEmployee.subscribe(
            (employee) => this.currentEmployee = employee
        );
    }

    emitData(model: RelatedWholesalerViewmodel, excludedIds: string[]): void {

        if (!model) {
            model = new RelatedWholesalerViewmodel();
        }

        model.excludedIds = excludedIds;
        this.onOpenWholesaler.emit(model);
    }
}
