import { Subscription } from "rxjs";

export class RouteInformationViewmodel {
    areaSubscription: Subscription;
    byArea: boolean;
    descriptionInput: string;
    nameInput: string;

    constructor() {}
}
