import { MsaBrand } from "src/app/entity-models/msa-brand.entity";
import { MsaDistributorVolumeResponse } from "src/app/entity-models/msa-distributor-volume-response.entity";
import { ShareTAShareData } from "src/app/shared/charts/share-tashare-chart/share-tashare-chart.component";
import { SkuBreakdownValue } from "src/app/shared/charts/sku-breakdown-chart/sku-breakdown-chart.component";

type brandMatch = {
    category: string;
    segment?: string;
    subSegment?: string;
    manufacturerSpecific?: boolean;
};
type volumeDataParams = {
    volumeResponse: MsaDistributorVolumeResponse;
} & brandMatch;

const determineManufacturerForBrand = (brand: MsaBrand): string => {
    switch (brand.brandCategoryName) {
        case "Modern Oral": return "Rogue Holdings";
        case "MST": 
        case "Loose Leaf/Chew": 
        case "Dry Snuff": return "Fat Lip Brands";
        default: return "Swisher";
    }
}

const getVolumeData = ({
    volumeResponse,
    category,
    segment,
    subSegment,
    manufacturerSpecific
}: volumeDataParams): number[] =>
    volumeResponse.brands
        .filter((brand) => {
            const categoryMatch = brand.brandCategoryName === category;
            //If segment, subSegment, or manufacturer are not provided, pass true so they don't affect the result
            const segmentMatch = !!segment ? brand.segment === segment : true;
            const subSegmentMatch = !!subSegment
                ? brand.subSegment === subSegment
                : true;
            const manufacturerMatch = manufacturerSpecific
                ? brand.manufacturerName.startsWith(determineManufacturerForBrand(brand))
                : true;
            return (
                categoryMatch &&
                segmentMatch &&
                subSegmentMatch &&
                manufacturerMatch
            );
        })
        .map(
            (brand) => 
                Math.max(
                    volumeResponse.volumes.find((v) => v.msaBrandId === brand.id).volumeAt26Weeks,
                    0
                )
        );

const getSkuData = ({
    volumeResponse,
    category,
    segment,
    subSegment,
    manufacturerSpecific
}: volumeDataParams): number[] =>
    volumeResponse.brands
        .filter((brand) => {
            const categoryMatch = brand.brandCategoryName === category;
            //If segment, subSegment, or manufacturer are not provided, pass true so they don't affect the result
            const segmentMatch = !!segment ? brand.segment === segment : true;
            const subSegmentMatch = !!subSegment
                ? brand.subSegment === subSegment
                : true;
            const manufacturerMatch = manufacturerSpecific
                ? brand.manufacturerName.startsWith(determineManufacturerForBrand(brand))
                : true;
            return (
                categoryMatch &&
                segmentMatch &&
                subSegmentMatch &&
                manufacturerMatch
            );
        })
        .map(
            (brand) =>
                volumeResponse.volumes.find(
                    (volume) => volume.msaBrandId === brand.id
                ).volumeAt26Weeks > 0 ? 1 : 0
        );

const sum = (arr: number[]): number => arr.reduce((a, b) => a + b, 0);
//Divides value by total, then rounds it to 4 decimal places (Math.round(value * 10000)/10000) before multiplying by 100 to format
const toPercentage = (value: number, total: number) => (Math.round((value / total)*10000)/10000) * 100;


export const toOtpPieChart = (
    manufacturerSpecific: boolean = false
) => (volumeResponse: MsaDistributorVolumeResponse) => {
    const largeCigar = sum(
        getVolumeData({
            volumeResponse,
            category: "Large Cigars",
            manufacturerSpecific
        })
    );
    const monPouches = sum(
        getVolumeData({
            volumeResponse,
            category: "Modern Oral",
            segment: "MON",
            subSegment: "Pouch",
            manufacturerSpecific
        })
    );
    const bdMst = sum(
        getVolumeData({
            volumeResponse,
            category: "MST",
            segment: "Branded Discount",
            manufacturerSpecific
        })
    );
    const chew = sum(
        getVolumeData({
            volumeResponse,
            category: "Loose Leaf/Chew",
            manufacturerSpecific
        })
    );
    const drySnuff = sum(
        getVolumeData({
            volumeResponse,
            category: "Dry Snuff",
            manufacturerSpecific
        })
    );
    const filteredCigars = sum(
        getVolumeData({
            volumeResponse,
            category: "Filtered Cigars",
            manufacturerSpecific
        })
    );
    const littleCigars = sum(
        getVolumeData({
            volumeResponse,
            category: "Little Cigars",
            manufacturerSpecific
        })
    );
    const total =
        largeCigar +
        monPouches +
        bdMst +
        chew +
        drySnuff +
        filteredCigars +
        littleCigars;
    const toReturn: PieChartValue[] = [];
    if (largeCigar !== 0)
        toReturn.push({
            name: Px3ChartCategoryName.LargeCigars,
            value: toPercentage(largeCigar, total),
            color: "#C00000"
        });
    if (monPouches !== 0)
        toReturn.push({
            name: Px3ChartCategoryName.MonPouches,
            value: toPercentage(monPouches, total),
            color: "#ED7C30"
        });
    if (bdMst !== 0)
        toReturn.push({
            name: Px3ChartCategoryName.BdMst,
            value: toPercentage(bdMst, total),
            color: "#A5A5A5"
        });
    if (chew !== 0)
        toReturn.push({
            name: Px3ChartCategoryName.Chew,
            value: toPercentage(chew, total),
            color: "#FFBF00"
        });
    if (drySnuff !== 0)
        toReturn.push({
            name: Px3ChartCategoryName.DrySnuff,
            value: toPercentage(drySnuff, total),
            color: "#5B9AD5"
        });
    if (filteredCigars !== 0)
        toReturn.push({
            name: Px3ChartCategoryName.FilteredCigars,
            value: toPercentage(filteredCigars, total),
            color: "#70AD47"
        });
    if (littleCigars !== 0)
        toReturn.push({
            name: Px3ChartCategoryName.LittleCigars,
            value: toPercentage(littleCigars, total),
            color: "#264478"
        });
    return toReturn;
};

export const toShareTAShare = (
    swisherLabel: string,
    genericLabel: string,
    brandMatch?: brandMatch
) => ([volumeResponse, nearbyVolume]: [
    MsaDistributorVolumeResponse,
    MsaDistributorVolumeResponse
]): ShareTAShareData[] => {
    const swisherShare =
        Math.floor(
            (sum(
                getVolumeData({
                    volumeResponse,
                    ...(brandMatch ?? { category: genericLabel }),
                    manufacturerSpecific: true
                })
            ) /
                sum(
                    getVolumeData({
                        volumeResponse,
                        ...(brandMatch ?? { category: genericLabel })
                    })
                )) *
                100
        ) || 0;
    const nearbySwisherShare =
        Math.floor(
            (sum(
                getVolumeData({
                    volumeResponse: nearbyVolume,
                    ...(brandMatch ?? { category: genericLabel }),
                    manufacturerSpecific: true
                })
            ) /
                sum(
                    getVolumeData({
                        volumeResponse: nearbyVolume,
                        ...(brandMatch ?? { category: genericLabel })
                    })
                )) *
                100
        ) || 0;
    return [
        {
            share: swisherShare,
            swisherLabel,
            taShare: nearbySwisherShare,
            genericLabel
        }
    ];
};

export const toSkuBreakdown = (parameters: SkuBreakdownParameters[]) => (
    volumeResponse: MsaDistributorVolumeResponse
): SkuBreakdownValue[] =>
    parameters.map(
        ({ chartLabel, swisherLabel, genericLabel, brandMatch }) => ({
            value:
                Math.floor(
                    (
                        sum(getSkuData({volumeResponse, ...(brandMatch ?? { category: genericLabel }), manufacturerSpecific: true})) /
                        sum(getSkuData({volumeResponse, ...(brandMatch ?? { category: genericLabel })}))
                    ) * 100
                ) || 0,
            swisherLabel,
            genericLabel,
            chartLabel
        })
    );

type SkuBreakdownParameters = {
    chartLabel: string;
    swisherLabel: string;
    genericLabel: string;
    brandMatch?: brandMatch;
};

export interface PieChartValue {
    name: string;
    value: number;
    color: string;
}

export enum Px3ChartCategoryName {
    LargeCigars = "Large Cigars",
    MonPouches = "MON Pouches",
    BdMst = "Branded Discount MST",
    Chew = "Loose Leaf/Chew",
    DrySnuff = "Dry Snuff",
    FilteredCigars = "Filtered Cigars",
    LittleCigars = "Little Cigars"
}

export enum Px3ChartSwisherCategoryName {
    LargeCigars = "SSCC Large",
    MonPouches = "Rogue Pouch",
    BdMst = "Fat Lip MST",
    Chew = "Fat Lip Chew",
    DrySnuff = "Fat Lip Dry Snuff",
    FilteredCigars = "SSCC Filtered",
    LittleCigars = "SSCC Little"
}