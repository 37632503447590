import { GratisAssignedTransaction } from "../entity-models/gratis-assigned-transactions.entity";
import { Gratis } from "../entity-models/gratis.entity";
import { Validation } from "./validation";
import { ValidationCheck } from "./validation-check";
import { ValidationStopLevel } from "./validation-stop-level";
import * as _ from "lodash";
import { Injectable } from "@angular/core";
import { ValidationTargets } from "./validation-targets";

//T = Root value
//K = some Type | some other Type ....
@Injectable()
export class GratisRequestValidationService extends Validation<Gratis, GratisAssignedTransaction> {

    set root(value: Gratis | null) {
        if (value) {
            this._root = value;
        }
    }

    validate(validationTargets: ValidationTargets[]): ValidationCheck<GratisAssignedTransaction>[] {

        this.steps = new Array<ValidationCheck<GratisAssignedTransaction>>();

        // build array of validations
        for (const target of validationTargets)
        switch (target) {
            case ValidationTargets.gratisSave:
                this.steps.push(new OnGratisSave(this._root.gratisAssignedTransactions));

                break;

            default:
                break;
        }

        const rtn = new Array<ValidationCheck<GratisAssignedTransaction>>();
        for (const step of this.steps) {
            this.steps.forEach((step) => rtn.push(step.validate()));
        }

        return rtn;
    }
}

//validation methods
export class OnGratisSave extends ValidationCheck<GratisAssignedTransaction> {

    constructor(input: GratisAssignedTransaction[]) {
        super();
        this.input = input;
        this.name = ValidationTargets.gratisSave;
    }

    validate(): this {

        if (!this.input?.length) {
            this.message = "No transactions found for employee."
            this.stopLevel = ValidationStopLevel.hard;
        }

        return _.cloneDeep(this);
    }
}
