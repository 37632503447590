import { Injectable } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { PromiseExtended } from "dexie";
import { takeWhile } from "rxjs/operators";
import { EmployeeRoleType, GenericResponseDto, newSequentialId, ResponseCountKey, SharedHelper } from "shield.shared";
import { Employee } from "src/app/entity-models/employee.entity";
import { DelineationStates } from "src/app/enums/delineation-states";
import { AppStateService } from "../app-state.service";
import { PingService } from "../ping.service";

@UntilDestroy()
@Injectable()
export class DatasourceDelineationService {

    constructor(
        private pingService: PingService,
        private appStateService: AppStateService
    ) {
        void this.init();
    }

    isOnline = false;
    hasOfflineAccess = true;
    pingInitalized = false;

    private _forceOffline = false;
    private _employee: Employee;

    // This is used as a scoped instance of a service seperate from the gloabal instance
    isOfflineOnly = false;

    // This is used by the navigation for when a user set the app into offline mode. It effects the global instance
    set setForceOffline(value: boolean) {
        this._forceOffline = value;
        if (this._employee) {
            this.setDelineationState();
        }
    }

    get delineationState(): DelineationStates {
        return this.setDelineationState();
    }

    init(): void {

        this.pingService.observablePingIsInitalized.pipe(
            takeWhile(result => !this.pingInitalized))
            .subscribe((result) => this.pingInitalized = result);
        this.pingService.online.subscribe((isOnline) => {
            this.isOnline = isOnline;
        });

        this.appStateService.currentEmployee.subscribe((employee) => {
            this._employee = employee;
            if (employee) {
                if (employee.searchableZrt?.length > 2 || employee.employeeRoles.find((e) => e.employeeRoleType.id === EmployeeRoleType.TM
                    || e.employeeRoleType.id === EmployeeRoleType.RM)) {
                    this.hasOfflineAccess = true;
                } else {
                    this.hasOfflineAccess = false;
                }
                this.setDelineationState();
            }
        });
    }

    private setDelineationState(): DelineationStates {

        let rtn = DelineationStates.unknown;
        if (this.isOnline) {
            if (this.hasOfflineAccess) {
                if (this._forceOffline) {
                    rtn = DelineationStates.offline;
                } else {
                    rtn = DelineationStates.online;
                }
            } else {
                rtn = DelineationStates.online;
            }
        } else {
            if (this.hasOfflineAccess) {
                rtn = DelineationStates.offline;
            } else {
                rtn = DelineationStates.unknown;
            }
        }
        return rtn;
    }

    async makeCall<K, T>(arg: K,
        offlineDelegate: (key: K) => Promise<T> | Promise<GenericResponseDto<T>>,
        onlineDelegate: (key: K) => PromiseExtended<T> | Promise<T> | Promise<GenericResponseDto<T>>,
        compareDelegate?: (onlineValues: T, offlineValue?: T) => Promise<T>,
        unProcessedDelegate?: (key: K, hasOfflineAccess?: boolean,) => Promise<T>,
        onlineOnly?: boolean
    ): Promise<GenericResponseDto<T>> {

        let rtn = new GenericResponseDto<T>();
        let result: T | GenericResponseDto<T>;
        try {
            const state = this.isOfflineOnly ? DelineationStates.offline : this.setDelineationState();

            switch (state) {
                case DelineationStates.online:
                    try {
                        if (!this.hasOfflineAccess || onlineOnly) {
                            throw new Error('User does not have offline access.');
                        } else {
                            result = await offlineDelegate(arg);
                            if (!result) {
                                throw new Error('Gotta catch them all...')
                            }
                        }
                    } catch (e) {
                        if (compareDelegate) {

                            const onlineResult = await onlineDelegate(arg);

                            if (onlineResult instanceof GenericResponseDto) {
                                let offLineUnProcessedResult: T;
                                if (unProcessedDelegate) {
                                    offLineUnProcessedResult = await unProcessedDelegate(arg, this.hasOfflineAccess);
                                }
                                result = await this.compare((onlineResult as GenericResponseDto<T>), compareDelegate, offLineUnProcessedResult);
                                if (!result) {
                                    try {
                                        result = await offlineDelegate(arg);
                                    } catch (e) {}
                                }
                            } else {
                                result = onlineResult; // call has been forced offline from some higher method
                            }

                        } else {

                            result = await onlineDelegate(arg);
                        }
                    }


                    break;
                case DelineationStates.offline:
                    try {
                        result = await offlineDelegate(arg);
                    } catch (e) {
                        if (!(e.message == 'BYPASS' || e.message.indexOf('Http failure response') > -1)) {
                            throw new Error(e.message);
                        }
                    }
                    break;

                default:
                    if (this.pingInitalized) {
                        throw Error("Online connectivity required for this user!");
                    }
            }

            if (result instanceof GenericResponseDto) {

                rtn = result;
            } else if (result) {

                rtn.values = result;
                rtn.count = Array.isArray(rtn.values) ? rtn.values.length : !!rtn.values ? 1 : 0;
            }

        } catch (e) {
            console.log(e);
            rtn.isError = true;
            if (e.error) {
                rtn.message = e.error.message ?? e.message;
            } else {
                rtn.message = e.message;
            }

            if (!rtn.message) {
                rtn.message = "An error has occured. (Please try again, and if the problem persists, contact the help desk.)";
            }
        }
        rtn.setCount(ResponseCountKey.default, rtn.count);
        return rtn;
    }

    makeCallWithBlobReturn<K, T>(arg: K,
        offlineDelegate: (key: K) => T,
        onlineDelegate: (key: K) => T
    ): T {

        const state = this.isOfflineOnly ? DelineationStates.offline : this.setDelineationState();

        switch (state) {
            case DelineationStates.online:
                return onlineDelegate(arg);
            case DelineationStates.offline:
                return offlineDelegate(arg);
            default:
                break;
        }
    }

    private async compare<T>(
        onlineResult: GenericResponseDto<T>,
        compareDelegate: (onlineValues: T, offlineValue?: T) => Promise<T>,
        offLineUnProcessedResult: T
    ): Promise<GenericResponseDto<T>> {

        let rtn = new GenericResponseDto<T>();
        if (onlineResult?.isError) {
            return onlineResult;
        } else if (!offLineUnProcessedResult) {
            return onlineResult;
        }

        rtn = new GenericResponseDto<T>();
        rtn.id = onlineResult.id;

        rtn.values = await compareDelegate(onlineResult.values as T, offLineUnProcessedResult) as T;
        // I know this can be short if the offline adds some that the online doesn't have, but its the best we got
        // until the online items finish processing
        // THe only place I think this currently matters is Accountlist, and we are only providing updates in that
        if (onlineResult.values) {
            rtn.count = onlineResult.count;
        } else if (Array.isArray(offLineUnProcessedResult)) {
            rtn.count = offLineUnProcessedResult.length;
        } else if (offLineUnProcessedResult) {
            rtn.count = 1;
        } else {
            rtn.count = 0;
        }

        rtn.setCount(ResponseCountKey.default, rtn.count);
        return rtn;
    }
}
