import { DailySummaryFilterDto, RefinerLocation, valueSeparator } from "shield.shared";
import { Refiner } from "../../entity-models/refiner.entity";
import { BaseFilterMapService } from "./base-filter-map.service";
import { ZrtFilterMapService } from "./zrt-filter-map.service";

export class DailySummaryFilterMapService extends BaseFilterMapService {

    static mapFilterData(refiners: Refiner[]): DailySummaryFilterDto {
        if (!refiners) return;
        const filterDto = new DailySummaryFilterDto();
        const zrtFilterDto = ZrtFilterMapService.mapFilterData(refiners);

        if (Object.keys(zrtFilterDto).length) {
            filterDto.zrtFilterDto = zrtFilterDto;
        }

        const timeOffset = new Date().getTimezoneOffset();

        for (const refiner of refiners) {
            if (!refiner.dataValue) {
                refiner.dataValue = refiner.value;
            }
            switch (refiner.location) {
                case RefinerLocation.myList:
                    filterDto.ids = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.startDate:
                    const startOnOrAfterDate = new Date(refiner.dataValue)
                    startOnOrAfterDate.setHours(0,0 - timeOffset,0,0);
                    filterDto.startOnOrAfterDate = startOnOrAfterDate.toISOString();
                    break;
                case RefinerLocation.endDate:
                    const startOnOrBeforeDate = new Date(refiner.dataValue)
                    startOnOrBeforeDate.setHours(23,59 - timeOffset,59,0);
                    filterDto.startOnOrBeforeDate = startOnOrBeforeDate.toISOString();
                    break;
                case RefinerLocation.callOnOrAfterDate:
                    const callOnOrAfterDate = new Date(refiner.dataValue)
                    callOnOrAfterDate.setHours(0,0,0,0);
                    filterDto.callOnOrAfterDate = callOnOrAfterDate.toISOString();
                    break;
                case RefinerLocation.callOnOrBeforeDate:
                    const callOnOrBeforeDate = new Date(refiner.dataValue)
                    callOnOrBeforeDate.setHours(23,59,59,0);
                    filterDto.callOnOrBeforeDate = callOnOrBeforeDate.toISOString();
                    break;
                case RefinerLocation.resetDays:
                    filterDto.resetDay = refiner.dataValue === BaseFilterMapService.yes;
                    break;
                case RefinerLocation.workWithPerson:
                    filterDto.workWithPerson = refiner.dataValue.split(valueSeparator);
                    break;
                default:
                    break;
            }
        }

        return filterDto;
    }
}
