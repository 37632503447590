import { FilterLocation } from "../enums/filter-location";
import { Filter } from "./filter.entity";

export class UserFilter {
    id: string;
    filterLocation: FilterLocation;
    userId: string;
    myList: string[];
    myFilters: Filter[];
    specialCoverage: string[];
}
