import { Injectable } from "@angular/core";
import { ContractTemplate } from "../../entity-models/contract-template.entity";
import { Customer } from "../../entity-models/customer.entity";
import { DatabaseService } from "../database.service";

@Injectable()
export class ContractTemplateOfflineService {

    constructor(private dbService: DatabaseService){}

    async getByTypeIdAndZone(id: number, zone: string): Promise<ContractTemplate[]> {

        return this.dbService.contractTemplates
        .where("contractTemplateType.id")
        .equals(id)
        .filter((ct) =>
            ct.zone === zone ||
            (ct.zone === "00" && ct.region === "0" && ct.territory === "0")
        )
        .toArray();
    }
}
