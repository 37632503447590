import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';

import { ProjectAssignmentDialogViewModel } from './project-assignment-dialog.viewmodel';

@Component({
    selector: 'app-project-assignment-dialog',
    templateUrl: './project-assignment-dialog.component.html',
    styleUrls: ['./project-assignment-dialog.component.scss']
})
export class ProjectAssignmentDialogComponent implements OnInit {

    viewmodel: ProjectAssignmentDialogViewModel;

    constructor(public injectedData: SwisherOverlayRef<
            ProjectAssignmentDialogViewModel,
            ProjectAssignmentDialogComponent
        >) { }

    ngOnInit(): void {
        this.viewmodel = this.injectedData.data;
        this.viewmodel.filter();
    }
}
