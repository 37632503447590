<div class="col overflow-auto" style="height: 75vh;">
    <form [formGroup]="form">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">UIN</th>
                    <th scope="col">Item Number</th>
                    <th scope="col">Description</th>
                    <th scope="col">Pack Size</th>
                    <th scope="col">Case UPC</th>
                    <th scope="col">Display UPC</th>
                    <th scope="col">Pack UPC</th>
                    <th scope="col">Swisher Cost</th>
                    <th scope="col">Date Available</th>
                    <th *ngIf="isGroupLevel" scope="col">Wholesalers</th>
                    <ng-container *ngIf="!isGroupLevel">
                        <th scope="col">Wholesale Price</th>
                        <th *ngIf="viewmodel.canEdit" scope="col">Remove / Division</th>
                    </ng-container>
                    <th scope="col">Netsuite Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="!viewmodel.items.length">
                    <td colspan="100%"><em>No products found.</em></td>
                </tr>
                <tr [ngClass]="{'deleted': form.value.items[i].deactivate}"
                    *ngFor="let item of viewmodel.items; let i = index" formArrayName="items">
                    <td class="text-left" [formGroupName]="i">
                        <ng-container *ngIf="viewmodel.canEdit && (isGroupLevel || form.value.items[i].isNew); else lockUin">
                            <mat-form-field appearance="outline" color="accent">
                                <input matInput type="text" formControlName="productUIN" placeholder="UIN" />
                            </mat-form-field>
                        </ng-container>
                        <ng-template #lockUin>
                            {{ groupItem(item).productUIN }}
                        </ng-template>
                    </td>
                    <td class="text-left">
                        {{ groupItem(item).product.itemNumber }}
                    </td>
                    <td class="text-left">
                        {{ groupItem(item).product.description }}
                    </td>
                    <td class="text-center">
                        {{
                            groupItem(item).product.returnableUpc?.noOfEaches ?
                            groupItem(item).product.returnableUpc?.noOfEaches :
                            1
                        }}
                    </td>
                    <td class="text-center">
                        {{ viewmodel.getUpc(groupItem(item).product.upcs, "Case") }}
                    </td>
                    <td class="text-center">
                        {{ viewmodel.getUpc(groupItem(item).product.upcs, "Display") }}
                    </td>
                    <td class="text-center">
                        {{ viewmodel.getUpc(groupItem(item).product.upcs, "Pack") }}
                    </td>
                    <td class="text-right">
                        {{
                            (
                                groupItem(item).product.returnableUpc?.listPrice ?
                                groupItem(item).product.returnableUpc?.listPrice :
                                0
                            ) |
                            currency
                        }}
                    </td>
                    <td class="text-right" [formGroupName]="i">
                        <span *ngIf="!viewmodel.canEdit">
                            {{ form.value.items[i].dateAvailable | date : 'shortDate' }}
                        </span>
                        <mat-form-field appearance="outline" color="accent" *ngIf="viewmodel.canEdit">
                            <input matInput [matDatepicker]="picker" formControlName="dateAvailable" />
                            <mat-datepicker touchUi #picker></mat-datepicker>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        </mat-form-field>
                    </td>
                    <td *ngIf="isGroupLevel" class="text-center"
                        [ngClass]="{'cell-invalid': groupItem(item).wholesalerCount === 0}">
                        <a class="link-column-fixed-small" (click)="openOrderableDialog(groupItem(item).product.itemNumber)">
                            {{ groupItem(item).wholesalerCount }}
                            <icon-marker iconName="{{viewmodel.canEdit ? 'edit' : 'visibility'}}"></icon-marker>
                        </a>
                    </td>
                    <ng-container *ngIf="!isGroupLevel">
                        <td class="text-center" [formGroupName]="i">
                            <mat-form-field appearance="outline" color="accent" *ngIf="viewmodel.canEdit">
                                <input type="text" currencyMask matInput formControlName="wholesalePrice" />
                            </mat-form-field>
                            <span *ngIf="!viewmodel.canEdit">
                                {{ form.value.items[i].wholesalePrice | currency }}
                            </span>
                        </td>
                        <td class="text-center" *ngIf="viewmodel.canEdit">
                            <button-list [buttons]="deleteButtonList"
                                (onButtonClick)="onDeleteButtonListClick(i, $event)"></button-list>
                        </td>
                    </ng-container>
                    <td class="text-center">
                        {{ groupItem(item).product.sellable ? "Y" : "N" }}
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</div>
