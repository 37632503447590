import { Overlay, OverlayConfig } from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { Injectable, Injector, TemplateRef, Type } from "@angular/core";
import { BasicDialogComponent } from "../dialogs/basic-dialog/basic-dialog.component";
import { OverlayComponent } from "../overlay/overlay.component";
import { SwisherOverlayRef } from "../overlay/swisher-overlay-ref";

@Injectable({
    providedIn: "root"
})
export class OverlayService {
    constructor(private overlay: Overlay, private injector: Injector) {}

    open<T, R>(
        content: string | TemplateRef<T> | Type<T>,
        data: R,
        isGeneric: boolean = false
    ): SwisherOverlayRef<R, T> {
        const configs = new OverlayConfig({
            positionStrategy: this.overlay
                .position()
                .global()
                .centerHorizontally()
                .centerVertically(),
            hasBackdrop: true
        });

        const overlayRef = this.overlay.create(configs);

        const swisherOverlayRef = new SwisherOverlayRef<R, T>(
            overlayRef,
            content,
            data
        );

        const injector = this.createInjector(swisherOverlayRef, this.injector);

        if (isGeneric) {
            overlayRef.attach(
                new ComponentPortal(BasicDialogComponent, null, injector)
            );
        } else {
            overlayRef.attach(
                new ComponentPortal(OverlayComponent, null, injector)
            );
        }

        return swisherOverlayRef;
    }

    createInjector<R, T>(
        ref: SwisherOverlayRef<R, T>,
        inj: Injector
    ): PortalInjector {
        const injectorTokens = new WeakMap([[SwisherOverlayRef, ref]]);
        return new PortalInjector(inj, injectorTokens);
    }
}
