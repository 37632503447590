import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural'
})
/**
 * Conditionally transforms a word into its plural form if `num` is anything
 * but one. Otherwise, returns the singular. Supports -y and -f English word
 * endings as well.
 */
export class PluralPipe implements PipeTransform {

  transform(value: string, num: number): string {
    if (num === 1) {
      return value;
    }
    if (value.endsWith('y')) {
      return value.replace(/y$/, 'ies');
    }
    if (value.endsWith('f')) {
      return value.replace(/f$/, 'ves');
    }
    return value + 's';
  }

}
