import { Subscription } from "rxjs";
import { GenericLookup, GratisStatuses, GratisStatusLookup } from "shield.shared";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { FilterService } from "src/app/services/filter.service";

export class RequestInformationViewmodel {
    filterService: FilterService;

    all = "All";
    allStatus = new GenericLookup<GratisStatuses>();
    areaSubscription: Subscription;
    isAllStatusPresent: boolean = true;
    daysWaitingInput: string;
    daysWaitingDataInput: string;
    purposeInput: string;
    selectedStatuses = new Array<GenericLookup<GratisStatuses>>();
    statuses = new Array<GenericLookup<GratisStatuses>>();
    zrtByArea: boolean = true;

    constructor(
        filterService: FilterService,
    ) {
        this.filterService = filterService;

        this.initializeStatusDropDown();
    }

    initializeStatusDropDown(): void {
        this.allStatus.description = this.all;
        this.selectedStatuses.push(this.allStatus);
        this.statuses.push(this.allStatus);
        this.statuses.push(GratisStatusLookup.find(v => v.id === GratisStatuses.NotSubmitted));
        this.statuses.push(GratisStatusLookup.find(v => v.id === GratisStatuses.Rejected));
        this.statuses.push(GratisStatusLookup.find(v => v.id === GratisStatuses.NotComplete));
        this.statuses.push(GratisStatusLookup.find(v => v.id === GratisStatuses.AwaitingRegionalManager));
        this.statuses.push(GratisStatusLookup.find(v => v.id === GratisStatuses.AwaitingZoneManager));
        this.statuses.push(GratisStatusLookup.find(v => v.id === GratisStatuses.AwaitingCustomerService));
        this.statuses.push(GratisStatusLookup.find(v => v.id === GratisStatuses.Complete));
        this.statuses.push(GratisStatusLookup.find(v => v.id === GratisStatuses.Deleted));
    }
}
