import { TemplateRef, Type } from "@angular/core";

export class OrderDetailsDialogViewModel {
    header: string;
    contentType: "template" | "string" | "component" = "component";
    content: string | TemplateRef<any> | Type<any>;
    contentString: string;
    contentTemplateRef: TemplateRef<any>;
    contentComponent: Type<any>;
    context: any;
    showFooter: boolean;
    headerLeftText: string;
    headerRightText: string;
    buttonLeftText: string;
    buttonLeftFunction: () => void;
    buttonLeftDisabledFunction: () => boolean;
    buttonRightText: string;
    buttonRightFunction: () => void;
    buttonRightDisabledFunction: () => boolean;
    width: string;
    height: string;
    name: string;
    date: string;
}
