import { PictureDto } from "shield.shared";
import { Picture } from "src/app/entity-models/picture.entity";

export class PictureConverterService {

    static PictureDtoToPicture(dto: PictureDto): Picture {

        if (dto) {
            const rtn = new Picture();

            rtn.id = dto.id;
            rtn.image = "data:".concat(dto.contentType, ";base64,", dto.base64Image);

            return rtn;
        }
    }

}
