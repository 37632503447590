<div role="main" class="d-flex flex-column flex-grow-1">
    <div class="main-container 2-column d-flex flex-column flex-grow-1">
        <div class="d-flex flex-column flex-grow-1">
            <div class="worksheet-area d-flex flex-column flex-grow-1">
                <div class="worksheet-header pl-4 pr-4 pt-2 pb-2 d-flex flex-column flex-grow-1">
                    <div class="row d-flex">
                        <div class="col-6 d-flex flex-column">
                            <div class="row">
                                <h2>Project: {{viewmodel?.project?.name}}</h2>
                            </div>
                            <div class="row">
                                <h4>Mission: {{viewmodel?.project?.mission}}</h4>
                            </div>
                        </div>
                        <div class="col-3 d-flex flex-column">
                            <div class="row">
                                <h3 class="mb-0">Start Date: {{viewmodel?.project?.startDate |
                                    date: dateFormat}}</h3>
                            </div>
                            <div class="row">
                                <h3 class="mb-0">Visible For Field:
                                    {{viewmodel?.project?.visibleDate | date: dateFormat}}</h3>
                            </div>
                            <div class="row">
                                <h3 class="mb-0">End Date: {{viewmodel?.project?.endDate |
                                    date: dateFormat}}</h3>
                            </div>
                        </div>
                        <div class="col-3 d-flex flex-column">
                            <div class="row">
                                <h2 class="mb-4">
                                    <ng-container *ngIf="viewmodel.daysRemaining">
                                        Days Remaining:
                                        {{ viewmodel.daysRemaining > 0 ? viewmodel.daysRemaining : 0 | number }}
                                    </ng-container>
                                </h2>
                            </div>
                            <div class="row">
                                <h3 class="mb-0">Products:
                                    {{viewmodel?.project?.projectProducts?.length
                                    ? viewmodel?.project?.projectProducts.length
                                    : 0}}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="viewmodel?.isRetail">
                        <div class="retail-profile-project-grid">
                            <app-project-summary-order-dates class="retail-profile-project-grid-item col-1--row-1 d-flex" [project]="viewmodel.project"></app-project-summary-order-dates>
                            <app-project-summary-activities-surveys class="retail-profile-project-grid-item col-1--row-2" [project]="viewmodel.project"></app-project-summary-activities-surveys>
                            <app-project-summary-products class="retail-profile-project-grid-item col-2--row-1-2" [project]="viewmodel.project"></app-project-summary-products>
                        </div>
                    </ng-container><ng-container *ngIf="!viewmodel?.isRetail">
                        <app-project-summary class="d-flex flex-column flex-grow-1" [project]="viewmodel.project"></app-project-summary>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
