<div class="card card--wholesalers m-0">
    <div
        class="card-header d-flex justify-content-between align-items-center"
    >
        <span>Wholesalers</span>
    </div>
        <ng-container *ngIf="!customerMembers?.length">
            <div class="d-flex justify-content-center align-items-center card-body p-0">
                <div class="customer-overview-tiles-empty-state">
                    <div class="empty-state-header">
                        <div class="icon">
                            <span class="material-icons"
                            >supervised_user_circle</span
                            >
                        </div>
                        <h6>There are currently no associated wholesalers.</h6>
                        <button appThrottleClick class="btn btn-white btn-sm" (throttledClick)="emitData(null, customerMemberIds)">
                            Add Wholesaler
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="card-body p-0" *ngIf="customerMembers?.length">
            <div class="card-view-port">
                <table class="table table-striped table-hover mb-0">
                    <thead>
                    <tr>
                        <th scope="col">
                            Wholesaler
                        </th>
                        <th scope="col">Code</th>
                        <!-- <th scope="col">Products Assigned</th> -->
                        <th scope="col">Added By</th>
                        <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let member of customerMembers">
                        <td>
                            {{member.wholesaler?.name + '-' + member.wholesaler?.customerNumber}}
                        </td>
                        <td>{{member.customerCode}}</td>
                        <!-- <td>{{member.wholesaler.wholesalerProducts.length}}</td> -->
                        <td>{{member.addedByName}}</td>
                        <td>
                            <div class="d-flex">
                                <a class="single-icon-on-white mr-1" appThrottleClick (throttledClick)="emitData(member, customerMemberIds)">
                                    <span class="material-icons">edit</span>
                                </a>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    <ng-container *ngIf="customerMembers?.length">
        <hr/>
        <div>
            <button appThrottleClick
                class="btn sw-red-btn btn-sm bottom-margin card-bottom"
                (throttledClick)="emitData(null, customerMemberIds)"
            >
                Add New
            </button>
        </div>
    </ng-container>
</div>
