import { NgZone } from "@angular/core";
import { AccountsListRefinerService } from "./accounts-list-refiner.service";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { Router } from "@angular/router";
import {
    MatDrawerMode,
    MatSidenav,
    MatSidenavContent
} from "@angular/material/sidenav";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { FilterService } from "src/app/services/filter.service";
import { OverlayService } from "src/app/services/overlay.service";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { AddNewBusinessViewmodel } from "../add-new-business/add-new-business.viewmodel";
import { AddNewBusinessComponent } from "../add-new-business/add-new-business.component";
import { ColumnSelector } from "src/app/shared/viewmodels/column-selector.viewmodel";
import { AgmInfoWindow } from "@agm/core";
import { FormGroup } from "@angular/forms";
import { MarkerClustererOptions } from "@google/markerclustererplus";
import {
    Subscription,
    BehaviorSubject,
    Subject,
    Observable
} from "rxjs";
import {
    AccountsListColumns,
    FilterSortDto,
    RefinerLocation} from "shield.shared";
import { Employee } from "src/app/entity-models/employee.entity";
import { FilterLocation } from "src/app/enums/filter-location";
import { AddButtonComponent } from "src/app/shared/page-header/buttons/add-button/add-button.component";
import { ColumnVisabilityButtonComponent } from "src/app/shared/page-header/buttons/column-visability-button/column-visability-button.component";
import { ExcelExportButtonComponent } from "src/app/shared/page-header/buttons/excel-export-button/excel-export-button.component";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";
import { PrintButtonComponent } from "src/app/shared/page-header/buttons/print-button/print-button.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { ButtonActions } from "src/app/shared/enums/button-actions.enum";
import { AccountsListDataViewModel } from "./accounts-list-data.viewmodel";
import { RegisteredUser } from "src/app/entity-models/registered-user-entity";
import { RouteButtonComponent } from "src/app/shared/page-header/buttons/route-button/route-button.component";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { GridDataTypes } from "src/app/shared/enums/grid-data-types.enum";
import { RouteManagementService } from "src/app/my-day/route-management/route-management.service";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import {
    debounceTime,
    map,
    take,
    skip,
    skipWhile
} from "rxjs/operators";
import { ListButtonComponent } from "src/app/shared/page-header/buttons/list-button/list-button.component";
import { SortBy } from "../account.viewmodels/sort-by.viewmodel";
import { RegisteredUserDelineationService } from "src/app/services/delineation-services/registered-user-delineation.service";
import { MyListCommands } from "src/app/enums/my-list-commands";
import { CustomerMarker } from "src/app/entity-models/customer-marker.entity";
import { AccountListZrtFilterService } from "./account-list-zrt-filter.service";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { SyncService } from "src/app/services/sync.service";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";

export class AccountsListViewModel {
    dataViewmodel: AccountsListDataViewModel;

    customerDelineationService: CustomerDelineationService;
    filterService: FilterService;
    overlayService: OverlayService;
    pleaseWaitService: PleaseWaitService;
    refinerService: AccountsListRefinerService;
    router: Router;
    snackbar: SnackbarService;
    zone: NgZone;
    zrtFilterService: AccountListZrtFilterService;

    accountInput: string;
    addNewBusinessOverlayRef: SwisherOverlayRef<AddNewBusinessViewmodel, AddNewBusinessComponent>;
    addressColumn: ColumnSelector;
    availableColumns: ColumnSelector[];
    baseGridYOffset = 0;
    cityInput: string;
    columnDef: ColumnDef[];
    columnsToDisplay: string[];
    dateForm: FormGroup;
    dbaAddressColumn: ColumnSelector;
    detailHeight = 48;
    drawer: MatSidenav;
    drawerMode: MatDrawerMode = "side";
    editRouteSubscription: Subscription;
    employee: Employee;
    employeeDataLoaded = false;
    employeeSubscription: Subscription;
    filterLocation = FilterLocation.customerList;
    filters: FilterAndParams[] = [];
    grid: GridComponent;
    gridDataChangeSubscription: Subscription;
    gridHeight = "75vh";
    gridheightOffsetDeduction = 0;
    header: PageHeaderComponent;
    headerButtons: HeaderButtonComponent[] = new Array<HeaderButtonComponent>();
    headerButtonsList: HeaderButtonComponent[] = new Array<HeaderButtonComponent>();
    headerButtonsRouting: HeaderButtonComponent[] = new Array<HeaderButtonComponent>();
    headerName = "Customer List";
    infoMarker: CustomerMarker;
    inRouteColumn: ColumnSelector;
    isAllStatusPresent: boolean = true;
    isEnabledExpandedDetails = false;
    isFixedLayout = false;
    isGapSet = false;
    isSearchButtonDisabled = false;
    isSearching = false;
    itemsRendedInViewPort = 13;
    mapCenter: google.maps.LatLngLiteral = { lat: 30.35446, lng: -81.64654 };
    mapZoom = 8;
    myListSubscription: Subscription;
    previousInfoWindow: AgmInfoWindow = null;
    refinerServiceSubscription: Subscription;
    refinerInputChangeSubscription: Subscription;
    registeredUserDelineationService: RegisteredUserDelineationService;
    routeManagementService: RouteManagementService;
    routingMode: boolean;
    routingModeMapView: boolean = false;
    routingModeSearch: boolean = false;
    routeTouched: boolean = false;
    screenHeight = 0;
    selectedColumnVisibilityComunicator: string[];
    shouldAddStartDate: boolean = true;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    showMap: boolean = false;
    sideNavContent: MatSidenavContent;
    specialCoverageSubscription: Subscription;
    streetInput: string;
    topGapDistance = 0;
    zipInput: string;
    zrtSelectionSubscription: Subscription;

    public registeredUser: RegisteredUser;

    public mapMarkerClusterOptions: MarkerClustererOptions = {
        maxZoom: 12
    };
    mapStyles: google.maps.MapTypeStyle[] = [
        {
            featureType: "poi",
            stylers: [{ visibility: "off" }]
        },
        {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }]
        }
    ];

    confirmationOverlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    canDeactivateRouteBuilderSubject: Subject<boolean> = new Subject();
    observableCanDeactivateRouteBuilder: Observable<boolean> = this.canDeactivateRouteBuilderSubject.asObservable();

    searchRefinersSubject: Subject<Refiner[]> = new Subject();
    observableSearchRefiners: Observable<Refiner[]> = this.searchRefinersSubject.asObservable();
    filterSorts: FilterSortDto<AccountsListColumns>[] = [];

    readonly sortFunction = (columnDef: ColumnDef) => {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        setTimeout(async () => {
            if (this.grid) {
                if (!this.customerDelineationService.getOnlineState() && this.grid.dataSource.data?.length < this.dataViewmodel.total) {
                    await this.dataViewmodel.getAccountData(this.refinerService.refiners);
                }
                const filterSorts = this.dataViewmodel.sortColumn(this.grid, columnDef, this.columnDef);
                if (filterSorts) {
                    void this.getGridData(0, this.refinerService.refiners, filterSorts, true);
                }
            }
            this.shouldWait$.next(true);
        }, 0);
    };

    constructor(
        dataViewmodel: AccountsListDataViewModel,
        customerDelineationService: CustomerDelineationService,
        filterService: FilterService,
        overlayService: OverlayService,
        pleaseWaitService: PleaseWaitService,
        refinerService: AccountsListRefinerService,
        registeredUserDelineationService: RegisteredUserDelineationService,
        routeManagementService: RouteManagementService,
        router: Router,
        snackbar: SnackbarService,
        zone: NgZone,
        zrtFilterService: AccountListZrtFilterService,
        private syncService: SyncService,
        private px3Service: Px3DelineationService,
    ) {
        this.dataViewmodel = dataViewmodel;

        this.customerDelineationService = customerDelineationService;
        this.filterService = filterService;
        this.overlayService = overlayService;
        this.pleaseWaitService = pleaseWaitService;
        this.refinerService = refinerService;
        this.registeredUserDelineationService = registeredUserDelineationService;
        this.routeManagementService = routeManagementService;
        this.router = router;
        this.snackbar = snackbar;
        this.zone = zone;
        this.zrtFilterService = zrtFilterService;
    }

    onButtonAction(value: any): void {
        switch (value) {
            case ButtonActions.columnVisibility:
                this.selectedColumnVisibilityComunicator = value;
                break;
            case ButtonActions.print:
                this.grid?.onPrint();
                break;
            case ButtonActions.exportToExcel:
                this.onExportAsExcel(value);
                break;
            case ButtonActions.add:
                this.onAddNewBusinessClick();
                break;
            case ButtonActions.route:
            case ButtonActions.list:
                this.onToggleRoutingMode();
                break;
            default:
                break;
        }
    }

    onButtonClickEvent(value: HeaderButtonComponent) {
        if (value.onClick) {
            value.onClick();
        }
    }

    onAddAccountRefiner(event?: KeyboardEvent): void {
        if (this.accountInput
            && this.refinerService.getRefinerByLocation(RefinerLocation.account)?.value !== this.accountInput) {
            this.refinerService.onInputChange(
                RefinerLocation.account,
                this.accountInput,
                this.refinerService.buildStringDataValue(this.accountInput)
            );
        }

        if (this.accountInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.account,
                this.accountInput,
                "name",
                this.refinerService.buildStringDataValue(this.accountInput)
            );
        }

        if (!this.accountInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.account,
                true,
                false,
                true
            )
        }
    }

    onAddStreetRefiner(event?: KeyboardEvent): void {
        if (this.streetInput
            && this.refinerService.getRefinerByLocation(RefinerLocation.street)?.value !== this.streetInput) {
            this.refinerService.onInputChange(
                RefinerLocation.street,
                this.streetInput,
                this.refinerService.buildStringDataValue(this.streetInput)
            );
        }

        if (this.streetInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.street,
                this.streetInput,
                "address",
                this.refinerService.buildStringDataValue(this.streetInput)
            );
        }

        if (!this.streetInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.street,
                true,
                false
            )
        }
    }

    onAddCityRefiner(event?: KeyboardEvent): void {
        if (this.cityInput
            && this.refinerService.getRefinerByLocation(RefinerLocation.city)?.value !== this.cityInput) {
            this.refinerService.onInputChange(
                RefinerLocation.city,
                this.cityInput,
                this.refinerService.buildStringDataValue(this.cityInput)
            );
        }

        if (this.cityInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.city,
                this.cityInput,
                "city",
                this.refinerService.buildStringDataValue(this.cityInput)
            );
        }

        if (!this.cityInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.city,
                true,
                false,
                true
            )
        }
    }

    onAddZipRefiner(event?: KeyboardEvent): void {
        if (this.zipInput
            && this.refinerService.getRefinerByLocation(RefinerLocation.zipCodes)?.value !== this.zipInput) {
            this.refinerService.onInputChange(
                RefinerLocation.zipCodes,
                this.zipInput,
                this.refinerService.buildStringDataValue(this.zipInput)
            );
        }

        if (this.zipInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.zipCodes,
                this.zipInput,
                "zip",
                this.refinerService.buildStringDataValue(this.zipInput)
            );
        }

        if (!this.zipInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.zipCodes,
                true,
                false
            )
        }
    }

    onAddNewBusinessClick(): void {
        const addNewBusinessViewmodel = new AddNewBusinessViewmodel(
            this.filterService,
            this.employee,
            this.customerDelineationService
        );

        addNewBusinessViewmodel.buttonLeftFunction = () =>
            this.addNewBusinessOverlayRef.close();

        addNewBusinessViewmodel.buttonLeftDisabledFunction = () => false;

        addNewBusinessViewmodel.buttonRightFunction = async () => {
            const newCustomer = await addNewBusinessViewmodel.buildDomainModelFromViewModel();
            this.addNewBusinessOverlayRef.data.isConfirmed = true;
            const response = await this.customerDelineationService.upsertCustomer(newCustomer);
            this.syncService.forceOutboundSync();
            this.addNewBusinessOverlayRef.close();

            if (!response) {
                this.shouldWait$.next(false);
                return;
            }

            void this.router.navigate(["/accounts", newCustomer.id, "info"]);
        };

        addNewBusinessViewmodel.buttonRightDisabledFunction = () =>
            !addNewBusinessViewmodel.name ||
            !addNewBusinessViewmodel.selectedCustomerType ||
            !addNewBusinessViewmodel.address1 ||
            !addNewBusinessViewmodel.city ||
            !addNewBusinessViewmodel.selectedState ||
            !addNewBusinessViewmodel.zip ||
            addNewBusinessViewmodel.zip?.length < 5 ||
            isNaN(+addNewBusinessViewmodel.zip);

        this.addNewBusinessOverlayRef = this.overlayService.open(
            AddNewBusinessComponent,
            addNewBusinessViewmodel,
            true
        );
    }

    onExportAsExcel(value: string): void {
        const button = this.headerButtons.find((b) => b.buttonAction === value);
        if (button?.isLoading) return;

        else if (button) {
            button.isLoading = true;
            this.dataViewmodel.onExportAsExcel(false, button);
        }
    }

    onGetBatch(index: number, refiners: Refiner[]): void {
        if (this.refinerService.areDefaultsSet && (!this.dataViewmodel.total || this.dataViewmodel.total !== this.dataViewmodel.gridData?.length)) {
            void this.getGridData(index, refiners, null, true, true);
        }
    }

    onNavigateTo(): (event: MouseEvent, index: number) => void {
        return async (event: MouseEvent, index: number) => {
            this.navigateTo(this.dataViewmodel.gridData[index].data.id)
        }
    }

    onRefinersChange() {
        this.setRefinerInput();
        this.resetMapView();

        if (this.header) {
            if (this.refinerService.refiners.length === 0) {
                this.header.expanded = false;
            } else {
                this.header.expanded = true;
            }
        }

        // Search on removal
        const shouldAddInputs = false;
        this.search(shouldAddInputs);
        this.isGapSet = false;
    }

    onRouteTouch(shouldReset: boolean): void {
        this.routeTouched = !shouldReset;
    }

    onSortRoutingMode(event: SortBy): void {
        const column = this.columnDef.find((cd) => cd.headerName === event.name);
        if (column) {
            const filterSorts = this.dataViewmodel.sortOneColumn(column, this.columnDef, event.direction);
            if (filterSorts) {
                void this.getGridData(0, this.refinerService.refiners, filterSorts, true);
            }
        }
    }

    onToggleRoutingMode(warn: boolean = true): void {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        this.showMap = false;
        if (!this.routingMode) {
            if (!this.customerDelineationService.getOnlineState()) {
                this.snackbar.showWarning("You must be online to access the route builder.");
                this.shouldWait$.next(false);
                return;
            }

            this.routingModeSearch = true;
            const inRoute = this.columnDef.find(cd => cd.headerName === AccountsListColumns.inRoute);
            if (inRoute) {
                inRoute.isAvailable = true;
            }
            this.setColumnAvailability(this.columnDef.find(v => v.headerName === AccountsListColumns.address), false, true);
            this.setColumnAvailability(this.columnDef.find(v => v.headerName === AccountsListColumns.drivingAddress), true, true);
            this.availableColumns = [
                this.inRouteColumn,
                ...this.availableColumns.filter(c => c.name !== this.addressColumn.name),
                this.dbaAddressColumn
            ];
            this.headerButtons = this.headerButtonsRouting;

            const zrtRefiner = this.refinerService.refiners?.find(
                (r) => r.location === RefinerLocation.zrtByArea || r.location === RefinerLocation.zrtByEmployee
            );
            if (!zrtRefiner) {
                this.dataViewmodel.setDefaultFilter(true);
                this.getGridData(0, this.refinerService.refiners, null, true);
            }
            this.refinerService.routingModeEnabled = true;
            setTimeout(() => {
                this.routingMode = true;
                this.shouldWait$.next(true);
            }, 0);
        } else {
            setTimeout(async () => {
                if (warn) {
                    const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                    data.header = "Confirmation";
                    data.message =
                        "Would you like to exit the route builder? Any unsaved changes to your current day's route will be lost.";
                    data.buttonLeftText = "Cancel";
                    data.buttonLeftFunction = () => {
                        this.confirmationOverlayRef.close(data);
                    };
                    data.buttonRightText = "Yes";
                    data.buttonRightFunction = () => {
                        data.isConfirmed = true;
                        this.confirmationOverlayRef.close(data);
                    };

                    this.confirmationOverlayRef = this.overlayService.open(
                        ConfirmationDialogComponent,
                        data
                    );

                    this.confirmationOverlayRef.afterClosed$
                        .pipe(
                            map(async (event) => {
                                if (
                                    event &&
                                    event.data &&
                                    event.data.isConfirmed
                                ) {
                                    await this.disableRoutingMode();
                                }
                            })
                        )
                        .subscribe();
                } else {
                    await this.disableRoutingMode();
                }
                this.shouldWait$.next(true);
            }, 0);
        }
    }

    // methods
    calculateGap(): void {
        if (this.sideNavContent) {
            setTimeout(() => {
                this.topGapDistance =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.top ?? 0;
                this.gridheightOffsetDeduction =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.bottom ??
                    0;
                if (
                    this.baseGridYOffset === 0 &&
                    this.gridheightOffsetDeduction !== 0
                ) {
                    this.baseGridYOffset = this.gridheightOffsetDeduction;
                }
                if (this.header?.expanded) {
                    this.gridHeight = "70vh";
                    this.itemsRendedInViewPort = 13;
                } else {
                    this.gridHeight = "76vh";
                    this.itemsRendedInViewPort = 14;
                }
            }, 0);
            this.isGapSet = true;
        }
    }

    reset(): void {
        this.refinerService.resetRefiners();
    }

    getGridData(index: number, refiners: Refiner[], sorts?: FilterSortDto<AccountsListColumns>[], force?: boolean, hideSearch?: boolean): void {
        if (!this.isSearching && this.refinerService.areDefaultsSet && this.employeeDataLoaded) {
            if (!this.customerDelineationService.getOnlineState() && this.refinerService.shouldResetDefaults()) {
                this.refinerService.areDefaultsSet = false;
                this.dataViewmodel.setDefaultFilter();
                if (this.refinerService.shouldResetDefaults()) {
                    this.snackbar.showWarning("You are not properly configured for offline mode.");
                    return this.shouldWait$.next(true);
                }
            }
            this.isSearching = true;

            setTimeout(async () => {
                const areRefinersTheSame = this.dataViewmodel.resolvePageIndex(index, refiners);
                if ((!areRefinersTheSame || index || force || !refiners.length || this.dataViewmodel.shouldAllowSearchRetry) && !this.routingModeSearch) {
                    // only show loading indicator if an explicit search has been performed
                    if (!hideSearch) {
                        this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                            this.shouldWait$
                        );
                    }
                    this.resetMapView();
                    //this.searchRefinersSubject.next(refiners);
                    await this.dataViewmodel.getAccountData(refiners, sorts);
                    await this.dataViewmodel.filterGrid(this.grid, this.routingMode);
                    this.isGapSet = false;

                } else if (this.routingModeSearch) {
                    this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                        this.shouldWait$
                    );
                    this.searchRefinersSubject.next(refiners);
                    await this.dataViewmodel.getAccountData(this.refinerService.refiners, sorts);
                }
                this.isSearching = false;
                this.shouldWait$.next(true);
            }, 0);
        }
    }

    getMyListIds(): void {
        setTimeout(async () => {
            if (this.dataViewmodel.total > 10000) {
                this.snackbar.showWarning("Total entry count is too high. Cannot push more than 10,000 records at once.");
                this.filterService.myListEntries$.next(undefined);
            } else {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                    this.shouldWait$
                );
                const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                data.header = "Confirmation";
                data.message =
                    "Would you like to push all entries to My List?";
                data.buttonLeftText = "Cancel";
                data.buttonLeftFunction = () => {
                    this.confirmationOverlayRef.close(data);
                };
                data.buttonRightText = "Yes";
                data.buttonRightFunction = () => {
                    data.isConfirmed = true;
                    this.confirmationOverlayRef.close(data);
                };

                this.confirmationOverlayRef = this.overlayService.open(
                    ConfirmationDialogComponent,
                    data
                );

                this.confirmationOverlayRef.afterClosed$
                    .pipe(
                        map(async (event) => {
                            if (event && event.data && event.data.isConfirmed) {
                                this.filterService.myListEntries$.next(await this.dataViewmodel.getIdsForMyList());
                                this.shouldWait$.next(true);
                            } else {
                                this.filterService.myListEntries$.next(undefined);
                                this.shouldWait$.next(true);
                            }
                        })
                    )
                    .subscribe();
            }
        }, 0);
    }

    headerExpansionChanged(): void {
        this.isGapSet = false;
    }

    async initialize(
        grid: GridComponent,
        header: PageHeaderComponent,
        sideNavContent: MatSidenavContent,
        drawer: MatSidenav
    ): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(
            this.shouldWait$
        );

        this.grid = grid;
        this.header = header;
        this.sideNavContent = sideNavContent;
        this.drawer = drawer;

        const addButton = new AddButtonComponent();
        addButton.title = "Add Business";
        this.headerButtonsList.push(addButton);
        this.headerButtonsList.push(new ColumnVisabilityButtonComponent());
        this.headerButtonsList.push(new PrintButtonComponent());
        this.headerButtonsList.push(new ExcelExportButtonComponent());
        this.headerButtonsList.push(new RouteButtonComponent());
        this.headerButtonsRouting.push(new ListButtonComponent());

        this.headerButtons = this.headerButtonsList;

        const curIncentivePeriod = await this.px3Service.getCurrentIncentivePeriod();

        this.columnDef = [
            {
                headerName: AccountsListColumns.inRoute,
                dataPropertyName: "isSelected",
                isAvailable: false,
                isSelected: true,
                isSelectable: true,
                selectDisableCriterion: this.isRouteFull(),
                dataType: GridDataTypes.checkboxNoHeader
            },
            {
                headerName: AccountsListColumns.customerId,
                dataPropertyName: "customerNumber",
                isAvailable: true,
                isSelected: false
            },
            {
                headerName: AccountsListColumns.lastCall,
                dataPropertyName: "lastCall",
                isAvailable: true,
                isSelected: true,
                ngColumnClass: "red-bar",
                ngColumnClassCriterion: this.isGridItemRouted(),
                dataType: GridDataTypes.date
            },
            {
                headerName: AccountsListColumns.available,
                dataPropertyName: "availability",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: AccountsListColumns.customerName,
                dataPropertyName: "name",
                isAvailable: true,
                isSelected: true,
                clickFunction: this.onNavigateTo(),
                ngColumnClass: "green-bar",
                ngColumnClassCriterion: this.isGridItemPx3Ranked()
            },
            {
                headerName: AccountsListColumns.px3Rank,
                dataPropertyName: "px3Rank",
                isAvailable: curIncentivePeriod != null,
                isSelected: curIncentivePeriod != null,
            },
            {
                headerName: AccountsListColumns.callsMade.replace(
                    "%s", `${curIncentivePeriod?.formattedDateRange ?? ''}`
                ),
                columnClass: "wrap-header",
                dataPropertyName: "callsMade",
                dataType: GridDataTypes.numberOrString,
                isAvailable: curIncentivePeriod != null,
                isSelected: curIncentivePeriod != null,
            },
            {
                headerName: AccountsListColumns.address,
                dataPropertyName: "displayBusinessAddress",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: AccountsListColumns.drivingAddress,
                dataPropertyName: "displayDbaAddress",
                isAvailable: false,
                isSelected: false
            },
            {
                headerName: AccountsListColumns.city,
                dataPropertyName: "city",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: AccountsListColumns.county,
                dataPropertyName: "county",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: AccountsListColumns.state,
                dataPropertyName: "state",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: AccountsListColumns.zip,
                dataPropertyName: "zip",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: AccountsListColumns.zrt,
                dataPropertyName: "zrt",
                isAvailable: true,
                isSelected: false,
            },
            {
                headerName: AccountsListColumns.customerType,
                dataPropertyName: "customerTypeName",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: AccountsListColumns.indVol,
                dataPropertyName: "industryVolume",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.number
            },
            {
                headerName: AccountsListColumns.ssVol,
                dataPropertyName: "swisherVolume",
                isAvailable: true,
                isSelected: false,
                dataType: GridDataTypes.number
            },
            {
                headerName: AccountsListColumns.ssPercent,
                dataPropertyName: "sharePercent",
                isAvailable: true,
                isSelected: false,
                dataType: GridDataTypes.percentPlusTwoPlaces
            },
            {
                headerName: AccountsListColumns.msa,
                dataPropertyName: "msaStore",
                isAvailable: true,
                isSelected: false
            },
            {
                headerName: AccountsListColumns.phone,
                dataPropertyName: "phone",
                isAvailable: true,
                isSelected: false
            },
            {
                headerName: AccountsListColumns.chain,
                dataPropertyName: "chain",
                isAvailable: true,
                isSelected: false
            },
            {
                headerName: AccountsListColumns.assignedRep,
                dataPropertyName: "assignedRepName",
                isAvailable: true,
                isSelected: false
            },
            {
                headerName: AccountsListColumns.ownershipLevel,
                dataPropertyName: "accountOwnershipLevel",
                isAvailable: true,
                isSelected: false
            },
            {
                headerName: AccountsListColumns.storeCount,
                dataPropertyName: "storeCount",
                dataType: GridDataTypes.number,
                isAvailable: true,
                isSelected: false
            }
        ];

        const availableColumns = new Array<ColumnSelector>();

        this.columnsToDisplay = this.columnDef
            .filter((cd) => {
                if (cd.isAvailable) {
                    const column: ColumnSelector = {
                        name: cd.headerName,
                        isSelected: cd.isSelected,
                        columns: null
                    };
                    availableColumns.push(column);
                }
            })
            .map((cd) => cd.headerName);
        this.availableColumns = availableColumns;
        this.inRouteColumn = {
            name: AccountsListColumns.inRoute,
            isSelected: true,
            columns: null
        };
        this.addressColumn = {
            name: AccountsListColumns.address,
            isSelected: true,
            columns: null
        };
        this.dbaAddressColumn = {
            name: AccountsListColumns.drivingAddress,
            isSelected: true,
            columns: null
        };

        if (this.refinerService.areDefaultsSet) {
            this.populateInputsFromRefiners();
        }

        if (!this.editRouteSubscription || this.editRouteSubscription.closed) {
            this.editRouteSubscription = this.routeManagementService.observableRoute.pipe(take(1)).subscribe(
                async (route) => {
                    if (route) {
                        this.onToggleRoutingMode();
                    }
                }
            );
        }

        if (
            !this.zrtSelectionSubscription ||
            this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription = this.zrtFilterService.observableSelectedZrtsHandleRefiners(this.refinerService).subscribe();
        }

        if (
            !this.refinerServiceSubscription ||
            this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription = this.refinerService.refiners$.pipe(
                skipWhile(() => !this.refinerService.areDefaultsSet && !this.dataViewmodel.filtersLoaded)
            ).subscribe(
                () => {
                    this.onRefinersChange();
                }
            );
        }

        if (
            !this.refinerInputChangeSubscription ||
            this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription = this.refinerService.refinerInputChange$.subscribe(
                (refiner) => {
                    if (refiner) {
                        this.setInputFromRefiner(refiner);

                        if (refiner.shouldSearchWhenCleared && !refiner.value) {
                            this.getGridData(0, this.refinerService.refiners.slice());
                        }
                    }

                    if (this.header) {
                        if (this.refinerService.refiners.length === 0) {
                            this.header.expanded = false;
                        } else {
                            this.header.expanded = true;
                        }
                    }
                    this.isGapSet = false;
                }
            );
        }

        if (
            !this.gridDataChangeSubscription ||
            this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription = this.grid.dataSourceChange.subscribe(
                (dc) => {
                    if (dc) {
                        this.shouldWait$.next(true);
                    }
                }
            );
        }

        if (!this.myListSubscription ||
            this.myListSubscription.closed
        ) {
            this.myListSubscription = this.filterService.myListCommandObservable.pipe(skip(1)).subscribe(async (command) => {
                switch (command) {
                    case MyListCommands.toggleOn:
                    case MyListCommands.toggleOff:
                        this.getGridData(0, this.refinerService.refiners.slice(), null, true);
                        break;
                    case MyListCommands.push:
                        this.getMyListIds();
                        break;
                    default:
                        break;

                }
            });
        }

        this.grid.sort.disableClear = true;

        if (!this.specialCoverageSubscription ||
            this.specialCoverageSubscription.closed
        ) {
            this.specialCoverageSubscription = this.filterService.specialCoverageObservable.pipe(
                skip(1),
                debounceTime(250)
            ).subscribe(() => {
                this.getGridData(0, this.refinerService.refiners.slice(), null, true);
            });
        }
    }

    isGridItemRouted(): (index: number) => boolean {
        return (index: number) => {
            return this.dataViewmodel.gridData[index].data.isRouted
        }
    }

    isGridItemPx3Ranked(): (index: number) => boolean {
        return (index: number) => {
            return this.dataViewmodel.gridData[index].data.px3Rank !== undefined;
        }
    }

    isRouteFull(): (index: number) => boolean {
        return (index: number) => {
            return this.routeManagementService.routeCustomers.length > 19 &&
                !this.routeManagementService.routeCustomers
                    .map(v => v.customerId)
                    .includes(this.dataViewmodel.gridData[index].data.id);
        }
    }

    async navigateTo(customerId: string): Promise<void> {
        void this.router.navigate(["/accounts", customerId, "profile"]);
    }

    populateInputsFromRefiners(): void {
        for (const refiner of this.refinerService.refiners) {
            this.setInputFromRefiner(refiner);
        }
    }

    search(shouldAddInputs: boolean = true) {
        if (shouldAddInputs) {
            this.onAddAccountRefiner();
            this.onAddStreetRefiner();
            this.onAddCityRefiner();
            this.onAddZipRefiner();
        }
        this.getGridData(0, this.refinerService.refiners.slice());
    }

    setColumnVisibilityCommunicator(event: string[]) {
        this.selectedColumnVisibilityComunicator = event;
    }

    setCustomerOnRoute(index: number): void {
        this.routeTouched = true;
        this.dataViewmodel.setCustomerOnRoute(index);
    }

    setMapBounds(event: google.maps.LatLngBounds): void {
        if (!event) return;

        this.dataViewmodel.mapBoundsLower = {
            lat: event.getSouthWest().lat(),
            lng: event.getSouthWest().lng()
        };

        this.dataViewmodel.mapBoundsUpper = {
            lat: event.getNorthEast().lat(),
            lng: event.getNorthEast().lng()
        };
    }

    setMapOnZoom(): void {
        this.dataViewmodel.mapLoading = true;
        this.dataViewmodel.mapRebuilding = true;
        this.dataViewmodel.customerMarkersVisibleIds = [];
        this.dataViewmodel.customerMarkers = [];
    }

    setRefinerInput(): void {
        if (!this.employee || !this.dataViewmodel.filtersLoaded) return;

        this.dataViewmodel.setDefaultFilter();

        if (!this.refinerService.refiners.filter(v =>
            v.location == RefinerLocation.zrtByArea ||
            v.location == RefinerLocation.zrtByEmployee
        ).length
        ) {
            this.zrtFilterService.selectedZrts = [];
        }

        this.accountInput = null;
        this.streetInput = null;
        this.cityInput = null;
        this.zipInput = null;

        this.populateInputsFromRefiners();
    }

    setVisibleMapMarkers(): void {
        if (!this.customerDelineationService.getOnlineState()) {
            this.showMap = false;
            this.snackbar.showWarning("You must be online to view the map.");
            return;
        }
        this.dataViewmodel.buildVisibleMapMarkers();
    }

    showFilters(): void {
        this.drawer.toggle();
        this.isGapSet = false;
    }

    toggleMap(): void {
        this.isGapSet = false;

        if (this.showMap) {
            this.showMap = false;
            this.resetMapView();
            return;
        }

        this.showMap = true;
        this.setVisibleMapMarkers();
    }

    unsubscribe(): void {
        if (this.editRouteSubscription && !this.editRouteSubscription.closed) {
            this.editRouteSubscription.unsubscribe();
        }
        if (this.employeeSubscription && !this.employeeSubscription.closed) {
            this.employeeSubscription.unsubscribe();
        }
        if (this.refinerServiceSubscription && !this.refinerServiceSubscription.closed) {
            this.refinerServiceSubscription.unsubscribe();
        }
        if (this.refinerInputChangeSubscription && !this.refinerInputChangeSubscription.closed) {
            this.refinerInputChangeSubscription.unsubscribe();
        }
        if (this.gridDataChangeSubscription && !this.gridDataChangeSubscription.closed) {
            this.gridDataChangeSubscription.unsubscribe();
        }
        if (this.myListSubscription && !this.myListSubscription.closed) {
            this.myListSubscription.unsubscribe();
        }
        if (this.specialCoverageSubscription && !this.specialCoverageSubscription.closed) {
            this.specialCoverageSubscription.unsubscribe();
        }
        if (this.zrtSelectionSubscription && !this.zrtSelectionSubscription.closed) {
            this.zrtSelectionSubscription.unsubscribe();
        }
    }

    private async disableRoutingMode(): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        await this.dataViewmodel.getFutureRoutes();

        const inRoute = this.columnDef.find(cd => cd.headerName === AccountsListColumns.inRoute);
        if (inRoute) {
            inRoute.isAvailable = false;
        }
        this.setColumnAvailability(this.columnDef.find(v => v.headerName === AccountsListColumns.address), true, true);
        this.setColumnAvailability(this.columnDef.find(v => v.headerName === AccountsListColumns.drivingAddress), false, true);
        this.availableColumns = [
            ...this.availableColumns.filter(c => c.name !== this.inRouteColumn.name && c.name !== this.dbaAddressColumn.name),
            this.addressColumn
        ];
        this.headerButtons = this.headerButtonsList;

        this.refinerService.routingModeEnabled = false;
        this.routingModeSearch = false;
        this.routingModeMapView = false;
        this.routingMode = false;
        this.routeTouched = false;

        this.getGridData(0, this.refinerService.refiners.slice(), null, true);

        this.shouldWait$.next(true);
    }

    private resetMapView(): void {
        this.dataViewmodel.mapLoading = true;
        this.dataViewmodel.mapRebuilding = true;
        this.dataViewmodel.customerMarkersVisibleIds = [];
        this.dataViewmodel.customerMarkers = [];
    }

    private setColumnAvailability(column: ColumnDef, isAvailable: boolean, isSelected: boolean) {
        if (!column) return;
        column.isAvailable = isAvailable;
        column.isSelected = isSelected;
    }

    private setInputFromRefiner(refiner: Refiner): void {
        switch (refiner.location) {
            case RefinerLocation.zrtByArea:
            case RefinerLocation.zrtByEmployee:
                this.zrtFilterService.applyRefiner(refiner);
                break;
            case RefinerLocation.account:
                this.accountInput = refiner.value;
                break;
            case RefinerLocation.street:
                this.streetInput = refiner.value;
                break;
            case RefinerLocation.city:
                this.cityInput = refiner.value;
                break;
            case RefinerLocation.zipCodes:
                this.zipInput = refiner.value;
                break;
            case RefinerLocation.products:
                // toggling product distribution status should allow search to be re-run.
                this.dataViewmodel.shouldAllowSearchRetry = true;
                break;
            default:
                break;
        }
    }
}
