import { GratisProductShippedDetailDto } from "shield.shared";

import { GratisProductShippedDetail } from "src/app/entity-models/gratis-product-shipped-detail.entity";

export class GratisProductShippedDetailConverterService {
    static dtoToEntity(
        dto: GratisProductShippedDetailDto
    ): GratisProductShippedDetail {
        const entity = new GratisProductShippedDetail();

        entity.id = dto.id;
        entity.costPerUnit = dto.costPerUnit;
        entity.productId = dto.productId;
        entity.quantity = dto.quantity;
        entity.unitOfMeasure = dto.unitOfMeasure;

        return entity;
    }

    static entityToDto(
        entity: GratisProductShippedDetail
    ): GratisProductShippedDetailDto {
        const dto = new GratisProductShippedDetailDto();

        dto.id = entity.id;
        dto.costPerUnit = entity.costPerUnit;
        dto.productId = entity.productId;
        dto.quantity = entity.quantity;
        dto.unitOfMeasure = entity.unitOfMeasure;

        return dto;
    }
}
