import { Component, OnDestroy, OnInit } from "@angular/core";
//Icons
import {
    faEye,
    faCamera,
    faArrowDown,
    faPen,
    faTrash,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { OverlayService } from "src/app/services/overlay.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { AccountProfileBaseComponent } from "../account-profile-base/account-profile-base.component";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { ChainHeadquarterProfileViewmodel } from "./chain-hq-profile.viewmodel";
import { AppStateService } from "src/app/services/app-state.service";
import { ContactDelineationService } from "src/app/services/delineation-services/contact-delineation.service";
import { CustomerContractDelineationService } from "src/app/services/delineation-services/customer-contract-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { ContractTemplateDelineationService } from "src/app/services/delineation-services/contract-template-delineation.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { ContractPaymentDelineationService } from "src/app/services/delineation-services/contract-payment-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { Router } from "@angular/router";
import { ProjectStateService } from "src/app/services/project-state-service";
import { ProjectApplicationService } from "src/app/details/project/project-services/project-application.service";
import { WholesalerGroupMemberDelineationService } from "src/app/services/delineation-services/wholesaler-group-member-delineation.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { SyncService } from "src/app/services/sync.service";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";

@Component({
    selector: "app-chain-hq-profile",
    templateUrl: "./chain-hq-profile.component.html",
    styleUrls: ["./chain-hq-profile.component.scss"]
})
export class ChainHqProfileComponent
    extends AccountProfileBaseComponent
    implements OnInit, OnDestroy {
    faEye: IconDefinition = faEye;
    faCamera: IconDefinition = faCamera;
    faArrowDown: IconDefinition = faArrowDown;
    faPen: IconDefinition = faPen;
    faTrash: IconDefinition = faTrash;

    chainHeadquarterCustomerSubscription: Subscription;
    dateFormat = MY_DATE_FORMATS.display.customDateInput;

    chainHeadquarterProfileViewmodel: ChainHeadquarterProfileViewmodel = new ChainHeadquarterProfileViewmodel(
        this.employeeDelineationService
        , this.customerDelineationService
        , this.accountOwnershipDelineationService
    );


    public constructor(
        appStateService: AppStateService,
        overlayService: OverlayService,
        contactDelineationService: ContactDelineationService,
        customerContractDelineationService: CustomerContractDelineationService,
        customerDelineationSevice: CustomerDelineationService,
        customerStateService: CustomerStateService,
        contractTemplateDelineationService: ContractTemplateDelineationService,
        contractPaymentDelineationService: ContractPaymentDelineationService,
        projectDelineationService: ProjectDelineationService,
        router: Router,
        projectStateService: ProjectStateService,
        projectApplicationService: ProjectApplicationService,
        wholesalerGroupMemberDelineationService: WholesalerGroupMemberDelineationService,
        snackbarService: SnackbarService,
        px3RankService: Px3DelineationService,
        private employeeDelineationService: EmployeeDelineationService,
        private accountOwnershipDelineationService: AccountOwnershipDelineationService,
        syncService: SyncService,) {

        super(appStateService
            , overlayService
            , contactDelineationService
            , customerContractDelineationService
            , contractPaymentDelineationService
            , customerDelineationSevice
            , customerStateService
            , contractTemplateDelineationService
            , projectDelineationService
            , router
            , projectStateService
            , projectApplicationService
            , wholesalerGroupMemberDelineationService
            , snackbarService
            , px3RankService
            , syncService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (
            !this.chainHeadquarterCustomerSubscription ||
            this.chainHeadquarterCustomerSubscription.closed
        ) {
            this.chainHeadquarterCustomerSubscription = this.customerStateService.observableCustomer
                .pipe(
                    map(async (customer) => {
                        this.customer = customer;
                        if (customer) {

                            let chainHeadquarterResponse = await this.customerDelineationService.getById(customer.id);

                            if (chainHeadquarterResponse?.values) {

                                await this.chainHeadquarterProfileViewmodel.buildViewmodelFromDomainModel(
                                    chainHeadquarterResponse.values,
                                    customer,
                                );
                            }
                        }
                    })
                )
                .subscribe();
        }
    }

    ngOnDestroy(): void {
        if (
            this.chainHeadquarterCustomerSubscription &&
            !this.chainHeadquarterCustomerSubscription.closed
        ) {
            this.chainHeadquarterCustomerSubscription.unsubscribe();
        }
    }

    // TO Be Implemented
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    async callHistorySearch(): Promise<void> {}
}
