import { Injectable } from "@angular/core";
import { TaxRate } from "src/app/entity-models/tax-rate.entity";
import { DatabaseService } from "../database.service";

@Injectable()
export class TaxRateOfflineService {

    constructor(private dbService: DatabaseService){}

    async getAll(): Promise<TaxRate[]> {

        return await this.dbService.taxRates.toArray();
    }
}
