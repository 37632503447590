import SignaturePad from "signature_pad";

export class SignaturePadViewModal {
    dotSize: number | (() => number);

    minWidth: number;

    maxWidth: number;

    canvasWidth: number;

    canvasHeight: number;

    throttle: number;

    minDistance: number;

    backGroundColor: string;

    penColor: string;

    velocityFilterWeight: number;

    signaturePad: SignaturePad;

    onBegin: (event: MouseEvent | Touch) => void;

    onEnd: (event: MouseEvent | Touch) => void;

    save: () => void;

    toDataUrlPng: () => string;

    toDataUrlJpeg: () => string;

    toDataUrlJSvg: () => string;

    isEmpty: () => boolean;

    //   // Draws signature image from data URL.
    //   // NOTE: This method does not populate internal data structure that represents drawn signature. Thus, after using #fromDataURL, #toData won't work properly.
    //   fromDataUrl(value: string) : void {
    //     if(value) {
    //       this.signaturePad.fromDataURL(value);
    //     }
    //   }

    //   // Returns signature image as an array of point groups
    //   toData(): PointGroup[] {
    //     return this.signaturePad.toData();
    //   }

    //   // Draws signature image from an array of point groups
    //   fromData(value: PointGroup[]): void {
    //     if(value) {
    //       this.signaturePad.fromData(value);
    //     }
    //   }

    //   // Clears the canvas
    //   clear() : void {
    //     this.signaturePad.clear();
    //   }

    //   // Unbinds all event handlers
    //   off(): void {
    //     this.signaturePad.off();
    //   }

    //   // Rebinds all event handlers
    //   on(): void {
    //     this.signaturePad.on();
    //   }

    //   onClearSignature(): void {
    //     this.signaturePad.clear();
    // }

    // onCancel() : void {
    //     this.overlayRef.close();
    // }
}
