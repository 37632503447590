import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncQueueService } from "../data-sync-queue.service";
import {
    VersionResponseDto, StateLicenseDto, GenericVersionResponseDto,
    SystemInformationKeys, SharedHelper, StateLicenseSyncCommand,
    SyncCommandTypes
} from "shield.shared";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { StateLicense } from "src/app/entity-models/state-license.entity";
import { StateLicenseConverterService } from "src/app/services/converter-services/state-license-converter.service";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { SyncLevel } from "../sync-enums/sync-level.enum";
@Injectable()
export class StateLicenseDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.None;
    offlineRepSyncType = SyncLevel.Required;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.stateLicense;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing state licenses...");

        await this.pullData();

        this.log("Done syncing state licenses...");
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/state-licenses/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("State license data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("State license data is out of date, syncing...");


        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.stateLicensePullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;

        try {
            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;

                const response = await this.http
                    .get<GenericVersionResponseDto<StateLicenseDto[]>>(`/api/state-licenses${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                if (thisBatchSize) {
                    this.log(
                        `Downloaded ${response.values.length} state licenses, saving to IndexedDB...`
                    );

                    const stateLicenses = response.values.map((v) =>
                        StateLicenseConverterService.licenseDtoToLicense(v)
                    );

                    await this.dbService.stateLicenses.bulkPut(stateLicenses);
                    this.log(`  Saved ${stateLicenses.length} state licenses.`);
                }
                this.lastSyncVersion = response.maxVersion;
            } while (thisBatchSize > 0);

            this.log("Done saving state license data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing state licenses", e);
        }
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<StateLicenseSyncCommand>(
            SyncCommandTypes.stateLicense,
            async (message) => {
                let license = await this.dbService.stateLicenses.get(
                    message.payload.id
                );

                let dto = StateLicenseConverterService.licenseToLicenseDto(license);
                if (dto) {
                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }
}
