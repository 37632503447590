import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomerContractDto, FilterRequestV2Dto, GenericRequestDto, GenericResponseDto,
    RetailContractBatchParamsDto, RetailContractsColumns, RetailContractsFilterDto, SignatureDto } from "shield.shared";
import { CustomerContract } from "../../entity-models/customer-contract.entity";
import { Signature } from "../../entity-models/signature.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { CustomerContractConverterService } from "../converter-services/customer-contract-converter.service";
import { SignatureConverterService } from "../converter-services/signature-converter.service";

@Injectable()
export class CustomerContractOnlineService {

    constructor(private http: HttpClient){}

    async getCustomerContracts(
        key: RetailContractBatchParamsDto
    ): Promise<GenericResponseDto<CustomerContract[]>> {

        const request = new GenericRequestDto<FilterRequestV2Dto<RetailContractsFilterDto, RetailContractsColumns>>();
        request.id = key.employeeId;
        request.values = key.filterRequestDto;

        const result = await this.http
            .post<GenericResponseDto<CustomerContractDto[]>>(
                "/api/customer-contracts",
                request
            )
            .toPromise();

        return ConverterHelper.dtoToEntityArray<CustomerContractDto, CustomerContract>(result, (r) => CustomerContractConverterService.customerContractDtoToCustomerContract(r));
    }

    async getByCustomerId(key: string): Promise<GenericResponseDto<CustomerContract[]>> {

        const result = await this.http
            .get<GenericResponseDto<CustomerContractDto[]>>(`/api/customer-contracts/customer-id/${key}`).toPromise();

        return ConverterHelper.dtoToEntityArray<CustomerContractDto, CustomerContract>(result,
            (r) => CustomerContractConverterService.customerContractDtoToCustomerContract(r));
    }

    async getContractSignature(contractId: string): Promise<GenericResponseDto<Signature>> {

        const result = await this.http
            .get<GenericResponseDto<SignatureDto>>(`/api/customer-contracts/signature/${contractId}`)
            .toPromise();
        return ConverterHelper.dtoToEntity<SignatureDto, Signature>(result, (r) => SignatureConverterService.signatureDtoToSignature(r));
    }

    async upsertAgreement(newContract: CustomerContract): Promise<GenericResponseDto<CustomerContract>> {

        const request = new GenericRequestDto<CustomerContract>();
        request.id = newContract.id;
        request.values = newContract;

        const result = await this.http
            .post<GenericResponseDto<CustomerContractDto>>(`/api/customer-contracts/save`, request).toPromise();

        return ConverterHelper.dtoToEntity<CustomerContractDto, CustomerContract>(result,
            (r) => CustomerContractConverterService.customerContractDtoToCustomerContract(r));
    }
}
