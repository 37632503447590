export enum RetailContractsColumns {
    contractId = "Contract ID",
    contractNumber = "Contract Number",
    template = "Template",
    dateSigned = "Date Signed",
    level = "Level",
    type = "Type",
    currentStatus = "Current Status",
    totalPayment = "Total Payment",
    customerId = "Customer ID",
    storeInformation = "Store Information",
    zrt = "ZRT",
    px3Rank = "Px3 Rank",
}
