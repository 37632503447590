import { Injectable } from "@angular/core";
import Dexie from "dexie";
import { GenericResponseDto } from "shield.shared";
import { DelineationStates } from "src/app/enums/delineation-states";
import { DexieTableNames } from "src/app/enums/dexie-table-names";
import { DatabaseService } from "../database.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";

@Injectable()
export class DelineationContext<T, K> {

    table: Dexie.Table<T, K>;

    constructor(
        protected dbService: DatabaseService,
        protected datasourceDelineationService: DatasourceDelineationService,
        protected snackbarService?: SnackbarService,
    ) { }

    get isOfflineOnly(): boolean {
        return this.datasourceDelineationService.isOfflineOnly;
    }
    set isOfflineOnly(value: boolean) {
        this.datasourceDelineationService.isOfflineOnly = value;
    }

    protected async simpleCall<OFF, ON, K, R>(methodName: keyof ON & keyof OFF, arg: K, offlineService: OFF, onlineService: ON): Promise<GenericResponseDto<R>> {
        const result = await this.datasourceDelineationService.makeCall<K, R>(
            arg,
            async (k) => await (offlineService as any)[methodName](k),
            async (k) => await (onlineService as any)[methodName](k)
        );

        return this.handleError(result);
    }

    protected handleError<R>(response: GenericResponseDto<R>): GenericResponseDto<R> {
        //TODO Displaying an error/handling service errors should probably be handled at the component
        //level, or an app-level error handler, not here.
        if (response && response.isError) {
            this.snackbarService?.showError(response.message);
            throw new Error(`Error from ${this.constructor.name}: ${response.message}`);
        } else {
            return response;
        }
    }

    getDelineationState(): DelineationStates {
        return this.datasourceDelineationService.delineationState;
    }

    getOnlineState(): boolean {
        return this.datasourceDelineationService.delineationState === DelineationStates.online;
    }

    async persist(item: T, tableName: DexieTableNames): Promise<undefined> {

        await this.dbService.table<T, DexieTableNames>(tableName).put(item);
        return;
    }

    async persistAll(items: T[], tableName: DexieTableNames): Promise<undefined> {

        await this.dbService.table<T, DexieTableNames>(tableName).bulkPut(items);
        return;
    }

    async delete<T extends TableRow>(item: T, tableName: DexieTableNames): Promise<undefined> {

        await this.dbService.table(tableName).delete(item.id);
        return;
    }

    async bulkDelete<T extends TableRow>(items: T[], tableName: DexieTableNames): Promise<undefined> {

        await this.dbService.table(tableName).bulkDelete(items.map((i) => i.id));
        return;
    }
}

export interface TableRow {
    id: string;
}

