import { SignatureDto } from "shield.shared";
import { Signature } from "src/app/entity-models/signature.entity";

export class SignatureConverterService {

    static signatureDtoToSignature(dto: SignatureDto | null): Signature | null {
        if (!dto) return null;
        return { ...dto };
    }
}
