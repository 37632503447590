import { Component, OnInit } from '@angular/core';
import { HeaderButtonComponent } from '../header-button/header-button.component';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ButtonActions } from 'src/app/shared/enums/button-actions.enum';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss']
})
export class DeleteButtonComponent implements HeaderButtonComponent {

  icon: IconDefinition = faTrash;
  title = "Delete";
  buttonAction = ButtonActions.delete;
  isDisabled: boolean;

}
