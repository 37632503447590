import { Injectable } from "@angular/core";
import { DataSyncQueueService } from "./data-sync-queue.service";
import { SyncService } from "../services/sync.service";
@Injectable({
    providedIn: "root"
})
export class DataSyncService {
    private static _initialized = false;

    constructor(
        private syncQueue: DataSyncQueueService,
        private syncService: SyncService,
    ) { }

    async initialize(): Promise<void> {
        if (DataSyncService._initialized) return;
        DataSyncService._initialized = true;

        await this.syncQueue.requeueAllDequeuedCommandsOnStartup();
        await this.syncService.sync();
    }
}
