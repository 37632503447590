import { Injectable } from "@angular/core";
import { GenericDropDownDto, HasWholesalserProductMappingsParamsDto, ProductDistributionBatchParamsDto } from "shield.shared";
import { ProductDistributionEntry } from "src/app/entity-models/product-distribution-entry.entity";
import { Product } from "src/app/entity-models/product.entity";
import { DatabaseService } from "src/app/services/database.service";
import { ProductViewmodel } from "../../accounts/call-master/stepper-call/distribution-grid/product.viewmodel";
import { Customer } from "../../entity-models/customer.entity";
import { WholesalerGroupProductCatalogItem } from "../../entity-models/wholesaler-group-product-catalog-item.entity";

@Injectable()
export class ProductOfflineService {
    constructor(
        private dbService: DatabaseService,
    ) { }


    async getAll(): Promise<Product[]> {
        const products: Product[] = await this.dbService.products
            .orderBy("description")
            .toArray();

        return products;
    }

    async getWholesalerProducts(
        wholesaler: Customer,
        activeProducts: Map<string, Product>
    ): Promise<ProductViewmodel[]> {
        let wholesalerProducts = new Array<ProductViewmodel>();
        const wholesalerId = wholesaler.id;
        const wholesalerProductCatalogItems = await this.dbService.wholesalerProductCatalogItems
            .where("wholesalerId").equals(wholesalerId)
            .toArray();

        if (wholesalerProductCatalogItems?.length > 0) {

            const wholesalerGroupProductCatalogItemsIds = [... new Set(wholesalerProductCatalogItems.map((wpci) => wpci.wholesalerGroupProductCatalogItemId))];
            const wholesalerGroupProductCatalogItems = await this.dbService.wholesalerGroupProductCatalogItems
                .where("id")
                .anyOf(wholesalerGroupProductCatalogItemsIds)
                .and((item) => !item.isDeactivated)
                .toArray();

            const wholesalerGroupProductCatalogItemsMap = new Map(wholesalerGroupProductCatalogItems.map(wgpci => [wgpci.id, wgpci]));

            const allProducts = await this.getByIds(
                wholesalerProductCatalogItems.map((p) => p.productId)
            );
            const products = allProducts.filter((item) => !item.isDeleted && !item.isDeactivated);
            const productMap = new Map((products.map((item) => [item.id, item])));

            if (wholesalerProductCatalogItems) {

                for (const item of wholesalerProductCatalogItems) {
                    const product = productMap.get(item.productId);
                    if (product) {
                        const vm = product as ProductViewmodel;
                        const wholesalerGroupProductCatalogItem = wholesalerGroupProductCatalogItemsMap.get(item.id);
                        if (wholesalerGroupProductCatalogItem) {
                            vm.uin = wholesalerGroupProductCatalogItem.productUIN;
                            vm.wholesalerGroupProductId = wholesalerGroupProductCatalogItem.id;
                        }
                        vm.wholesalerId = wholesalerId;
                        vm.wholesaler = wholesaler;
                        wholesalerProducts.push(vm);
                    }
                }
            }
        }

        return [...new Set(wholesalerProducts)];
    }

    async getWholesalerGroupProductCatalogItemsByIds(ids: string[]): Promise<WholesalerGroupProductCatalogItem[]> {
        return await this.dbService.wholesalerGroupProductCatalogItems
            .where("id")
            .anyOf(ids)
            .and((wgpci) => !wgpci.isDeactivated && !wgpci.product.isDeactivated && !wgpci.product.isDeleted)
            .toArray();
    }

    async getByIds(ids: string[]): Promise<Product[]> {
        return await this.dbService.products
            .where("id")
            .anyOf(ids)
            .and((p) => !p.isDeactivated && !p.isDeleted)
            .toArray();
    }

    async getDropDown(): Promise<GenericDropDownDto[]> {
        const products = await this.dbService.products
        .where("isDropDownSelectable")
        .equals(1)
        .sortBy("description")
        return products.map((p) => ({
            id: p.id,
            name: p.description,
            displayValue: p.description
        }));
    }

    async hasProductWholesalerMappings(
        params: HasWholesalserProductMappingsParamsDto
    ): Promise<boolean> {

        let wholesalerProductCatalogItems = await this.dbService.wholesalerProductCatalogItems
            .where("wholesalerId")
            .anyOf(params.wholesalerIds)
            .and((item) => params.productId === item.productId)
            .toArray();

        if (wholesalerProductCatalogItems?.length > 0) {
            return true;
        }

        return false;
    }

    async getWholesalerMappedProduct(wholesalerIds: string[]): Promise<string[]> {

        let rtn = new Array<string>();

        let wholesalerProductCatalogItems = await this.dbService.wholesalerProductCatalogItems
        .where("wholesalerId")
        .anyOf(wholesalerIds)
        .toArray();

        if (wholesalerProductCatalogItems?.length > 0) {

            const productIds = wholesalerProductCatalogItems.map((wpci) => wpci.productId);
            const distinctProductIds = [...new Set(productIds)];

            const products = await this.dbService.products
                .where("id")
                .anyOf(distinctProductIds)
                .and((p) => !p.isDeactivated && !p.isDeleted)
                .toArray();

            rtn =  products.map((p) => p.id);
        }
        return rtn;
    }

    async getDistributionBatch(key: ProductDistributionBatchParamsDto): Promise<ProductDistributionEntry[]> {
        throw Error("Cannot get product distribution report offline.");
    }
}
