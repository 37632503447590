// eslint-disable-next-line no-shadow
export enum DayAbbreviation {
    sunday = "U",
    monday = "M",
    tuesday = "T",
    wednesday = "W",
    thursday = "R",
    friday = "F",
    saturday = "S"
}
