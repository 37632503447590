import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CallTypes, LicenseTypes, newSequentialId, Subsidiary } from 'shield.shared';
import { CallService } from 'src/app/accounts/call-master/call-services/call.service';
import { CallOrderProductViewModel } from 'src/app/accounts/call-master/call-viewmodels/call-order-product.viewmodel';
import { CallOrderDate } from 'src/app/entity-models/call-order-date.entity';
import { CallReceiptLicense } from 'src/app/entity-models/call-receipt-license.entity';
import { Contact } from 'src/app/entity-models/contact.entity';
import { Customer } from 'src/app/entity-models/customer.entity';
import { Employee } from 'src/app/entity-models/employee.entity';
import { RetailCall } from 'src/app/entity-models/retail-call.entity';
import { RmWholesaleCall } from 'src/app/entity-models/rm-wholesale-call.entity';
import { RetailStepperStep } from 'src/app/enums/retail-stepper-step';
import { SubsidiaryTypes } from 'src/app/enums/subsidiary-types';
import { Helper } from 'src/app/helpers/helper';
import { StateDelineationService } from 'src/app/services/delineation-services/state-delineation.service';
import { StateLicenseDelineationService } from 'src/app/services/delineation-services/state-license-delineation.service';
import { MY_DATE_FORMATS } from 'src/app/shared/constants/date-formats';
import { StepperCallApplicationService } from '../../../stepper-call-services/stepper-call-application.service';
import { ReceiptAddressViewModel } from '../../receipt-address.viewmodel';
import { OrderViewmodel } from '../order.viewmodel';
import { WholesaleReceiptViewmodel } from '../wholesale-receipt.viewmodel';

@Component({
    selector: 'app-wholesaler-format-base',
    templateUrl: './wholesaler-format-base.component.html',
    styleUrls: ['./wholesaler-format-base.component.scss']
})
export class WholesalerFormatBaseComponent implements OnInit, OnDestroy {

    swisherViewmodels: WholesaleReceiptViewmodel[] = new Array<WholesaleReceiptViewmodel>();
    easViewmodels: WholesaleReceiptViewmodel[] = new Array<WholesaleReceiptViewmodel>();
    employee: Employee;
    isEasEmployee = false;
    selectedIndexSubscription: Subscription;
    timeNow: Date;
    dateFormat = MY_DATE_FORMATS.display.customDateInput;
    dateTimeFormat = MY_DATE_FORMATS.display.customDateTimeInput;
    stateName: string;
    swisherlicense: string;
    easlicense: string;
    today = new Date();
    customer: Customer;
    hasEasReceipts = false; //used to determine if a breakpoint is needed at the end of swisher receipts
    signatureDisplayName: string;
    salesRepDisplayName: string;
    contactDisplayName: string;
    readonly swisherAddress = "P.O. Box 2230, Jacksonville, FL 32203";

    printLogo: string;

    @Input() customerReceiptAddress: ReceiptAddressViewModel;
    @Input() signature: string;
    @Input() signatureRetailWidth: number;
    @Input() signatureRetailHeight: number;

    private _selectedContact: Contact;
    @Input()
    get selectedContact(): Contact {
        return this._selectedContact;
    }
    set selectedContact(value: Contact) {
        this._selectedContact = value;
        if (value) {
            this.contactDisplayName = (value.lastName ? value.lastName + ", " : "")
                + (value.firstName ? value.firstName : "");
        }
    }

    @Input() isWholesaleDraft = false;
    @Input() isWholesaleFinal = false;

    private _selectedWholesaler: Customer;
    @Input()
    get selectedWholesaler(): Customer {
        return this._selectedWholesaler;
    }
    set selectedWholesaler(value: Customer) {
        this._selectedWholesaler = value;
    }

    constructor(public callService: CallService,
        private stepperCallApplicationService: StepperCallApplicationService,
        private stateLicenseDelineationService: StateLicenseDelineationService,
        private stateDelineationService: StateDelineationService) { }

    ngOnInit(): void {

        if (
            !this.selectedIndexSubscription ||
            this.selectedIndexSubscription.closed
        ) {
            this.selectedIndexSubscription = this.stepperCallApplicationService.observableSelectedIndex.subscribe(
                async (selectedIndex) => {
                    if (selectedIndex === RetailStepperStep.receipts) {
                        this.timeNow = new Date();
                        if (
                            (this.callService.call?.callType === CallTypes.retail
                                || this.callService.call.callType === CallTypes.rmWholesale)
                            &&
                            this.employee
                        ) {
                            if (!this.callService.call.receiptBase) {
                                this.callService.call.receiptBase = Math.floor(
                                    new Date().getTime() / 1000.0
                                );
                                await this.callService.saveCallAndNotify();
                            }
                        }
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (
            this.selectedIndexSubscription &&
            !this.selectedIndexSubscription.closed
        ) {
            this.selectedIndexSubscription.unsubscribe();
        }
    }

    async buildSwisherViewmodels(wholesaler: Customer,
        customer: Customer,
        employee: Employee,
        swisherOrderProducts: CallOrderProductViewModel[],
        receiptNumberExtention: number,
        hasEasReceipts: boolean): Promise<void> {

        this.customer = customer;
        this.employee = employee;
        this.signatureDisplayName = (employee.lastName ? employee.lastName + ", " : "")
        + (employee.firstName ? employee.firstName : "");

        this.salesRepDisplayName = (employee.zrt ? "ZRT " + employee.zrt + ", " : "")
            + this.signatureDisplayName;
        this.hasEasReceipts = hasEasReceipts;
        const vm = new WholesaleReceiptViewmodel();
        const customerWholesalers = customer.customerWholesalers.find((c) => c.wholesalerId === wholesaler.id);
        vm.customerCode = customerWholesalers?.customerCode;
        vm.wholesaler = wholesaler;
        vm.orders = this.buildOrders(swisherOrderProducts);
        vm.subsidiary = SubsidiaryTypes.swisher
        await this.setSwisherReceiptLicense(vm, customer, wholesaler, employee);
        vm.receiptNumber = (this.employee.zrt ?? "") + (this.callService.call as RetailCall | RmWholesaleCall)?.receiptBase?.toString()
            + receiptNumberExtention.toString().padStart(2, "0");

        this.swisherViewmodels.push(vm);
    }

    async buildEasViewmodels(wholesaler: Customer,
        customer: Customer,
        employee: Employee,
        easOrderProduct: CallOrderProductViewModel[],
        receiptNumberExtention: number): Promise<void> {

        this.customer = customer;
        this.employee = employee;

        this.signatureDisplayName = (employee.lastName ? employee.lastName + ", " : "")
        + (employee.firstName ? employee.firstName : "");

        this.salesRepDisplayName = (employee.zrt ? "ZRT " + employee.zrt + ", " : "")
            + this.signatureDisplayName;

        const vm = new WholesaleReceiptViewmodel();
        const customerWholesalers = customer.customerWholesalers.find((c) => c.wholesalerId === wholesaler.id);
        vm.customerCode = customerWholesalers.customerCode;
        vm.wholesaler = wholesaler;
        vm.orders = vm.orders = this.buildOrders(easOrderProduct);
        vm.subsidiary = SubsidiaryTypes.eas
        await this.setSwisherReceiptLicense(vm, customer, wholesaler, employee);
        vm.receiptNumber = (this.employee.zrt ?? "") + (this.callService.call as RetailCall | RmWholesaleCall)?.receiptBase?.toString()
            + receiptNumberExtention?.toString()?.padStart(2, "0");

        this.easViewmodels.push(vm);
    }

    private async setSwisherReceiptLicense(vm: WholesaleReceiptViewmodel, customer: Customer, wholesalerCustomer: Customer, employee: Employee): Promise<void> {
        if (customer && wholesalerCustomer && employee) {
            customer.customerLicenses ??= [];
            const retailOptLicense = customer.customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.RetailStateOTPLicense && cl.isActive);
            if (retailOptLicense) {
                vm.retailOptLicense = this.buildReceiptLicense(LicenseTypes.RetailStateOTPLicense, retailOptLicense.licenseNumber);
            }
            const retailVaporLicense = customer.customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.RetailStateVaporLicense && cl.isActive);
            if (retailVaporLicense) {
                vm.retailVaporLicense = this.buildReceiptLicense(LicenseTypes.RetailStateVaporLicense, retailVaporLicense.licenseNumber);
            }
            wholesalerCustomer.customerLicenses ??= [];
            const wholesalerRetailOptLicense = wholesalerCustomer.customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.RetailStateOTPLicense && cl.isActive);
            if (wholesalerRetailOptLicense) {
                vm.wholesalerRetailOptLicense = this.buildReceiptLicense(LicenseTypes.RetailStateOTPLicense, wholesalerRetailOptLicense.licenseNumber);
            }

            const stateLicensesResponse = await this.stateLicenseDelineationService.getByShortCode(customer.businessAddress.state);
            if (!stateLicensesResponse) { return; }
            const stateLicenses = stateLicensesResponse.values;

            this.swisherlicense = stateLicenses.find((sl) => sl.subsidiaryId === Subsidiary.Swisher)?.number ?? "";
            this.easlicense = stateLicenses.find((sl) => sl.subsidiaryId === Subsidiary.EAS)?.number ?? "";

            const stateResponse = await this.stateDelineationService.getByShortCode(customer.businessAddress.state);
            if (!stateResponse) { return; }

            this.stateName = stateResponse.values.name;
        }
    }

    private buildReceiptLicense(licenseTypeId: LicenseTypes, licenseNumber: string): CallReceiptLicense {

        const callReceiptLicense = new CallReceiptLicense();
        callReceiptLicense.id = newSequentialId();
        callReceiptLicense.licenseNumber = licenseNumber;
        callReceiptLicense.licenseTypeId = licenseTypeId;

        return callReceiptLicense;
    }

    private buildOrders(products: CallOrderProductViewModel[]): OrderViewmodel[] {

        const orders = new Array<OrderViewmodel>();
        const orderDates = new Map<number, CallOrderProductViewModel[]>();
        for (const product of  products) {

            if (!product.orderDates || product.orderDates.length === 0) {
                const od = new CallOrderDate();
                od.orderDate = new Date();
                od.orderDate.setHours(0 ,0 ,0, 999);
                od.quantity = product.quantity;
                product.orderDates = [od];
            }
            for (const orderDate of product.orderDates.filter((od) => od.quantity > 0)) {
                if (!orderDates.has(orderDate.orderDate.getTime())) {
                    const splitProduct = {...product};
                    splitProduct.quantity = orderDate.quantity;
                    orderDates.set(orderDate.orderDate.getTime(), [splitProduct as CallOrderProductViewModel]);
                } else {
                    const mapItem = orderDates.get(orderDate.orderDate.getTime());
                    const splitProduct = {...product};
                    splitProduct.quantity = orderDate.quantity;
                    mapItem.push(splitProduct as CallOrderProductViewModel);
                }
            }
        }

        for (const entry of orderDates.entries()) {
            const order = new OrderViewmodel();
            order.orderDate = new Date(entry[0]);
            order.callOrderProductViewmodels = orderDates.get(entry[0]);
            orders.push(order);
        }

        return orders;
    }

    async getPrintLogo(url: string): Promise<void> {
        const imageData = await fetch(url);
        const blob = await imageData.blob();

        const reader = new FileReader();
        reader.onloadend = () => {
            this.printLogo = reader.result as string;
            return;
        }
        reader.readAsDataURL(blob);
    }
}
