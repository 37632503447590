import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CallService } from 'src/app/accounts/call-master/call-services/call.service';
import { Customer } from 'src/app/entity-models/customer.entity';
import { Employee } from 'src/app/entity-models/employee.entity';
import { RetailCall } from 'src/app/entity-models/retail-call.entity';
import { CustomerDelineationService } from 'src/app/services/delineation-services/customer-delineation.service';
import { StateDelineationService } from 'src/app/services/delineation-services/state-delineation.service';
import { StateLicenseDelineationService } from 'src/app/services/delineation-services/state-license-delineation.service';
import { StepperCallApplicationService } from '../../../stepper-call-services/stepper-call-application.service';
import { WholesalerReceiptService } from '../../../stepper-call-services/wholsaler-receipt.service';
import { WholesalerFormatBaseComponent } from '../wholesaler-format-base/wholesaler-format-base.component';

@Component({
    selector: 'app-eas-wholesaler-swisher-rep-format-draft',
    templateUrl: './eas-wholesaler-swisher-rep-format-draft.component.html',
    styleUrls: ['./eas-wholesaler-swisher-rep-format-draft.component.scss']
})
export class EasWholesalerSwisherRepFormatDraftComponent extends WholesalerFormatBaseComponent implements OnInit {

    @Input()
    get relayedCustomer(): Customer {
        return this.customer;
    }
    set relayedCustomer(value: Customer) {
        this.customer = value
        this.buildDraftViewmodel();
    }

    private _wholesalers: Customer[];
    @Input()
    get wholesalers(): Customer[] {
        return this._wholesalers;
    }
    set wholesalers(value: Customer[]) {
        this._wholesalers = value;
        this.buildDraftViewmodel();
    }

    @Input()
    get relayedEmployee(): Employee {
        return this.employee;
    }
    set relayedEmployee(value: Employee) {
        this.employee = value;
        this.buildDraftViewmodel();
    }

    @Output() easWholesalerFormatDraftBuilt = new EventEmitter();

    isBuilding = false;

    constructor(callService: CallService,
        stepperCallApplicationService: StepperCallApplicationService,
        private customerDelineationService: CustomerDelineationService,
        private wholesalerReceiptService: WholesalerReceiptService,
        stateLicenseDelineationService: StateLicenseDelineationService,
        stateDelineationService: StateDelineationService) {
        super(callService,
            stepperCallApplicationService,
            stateLicenseDelineationService,
            stateDelineationService);
    }

    async buildDraftViewmodel(): Promise<void> {
        if (this.customer && this.employee && this.wholesalers && !this.isBuilding) {
            this.isBuilding = true;
            await this.buildViewmodels();
            this.easWholesalerFormatDraftBuilt.emit();
            this.isBuilding = false;
        }
    }

    async buildViewmodels(): Promise<void> {
        const wholesalerIds = (this.callService
            .call as RetailCall).orderProducts?.map(
                (orderProduct) => orderProduct.wholesalerCustomerId
            );

        const wholesalers = new Array<Customer>();
        for (const id of wholesalerIds) {
            const wholesalerResponse = await this.customerDelineationService.getById(
                id
            );
            if (!wholesalerResponse) { return; }
            const wholesaler = wholesalerResponse.values;

            if (wholesaler && !wholesalers.find((w) => w.id == wholesaler.id)) {
                wholesalers.push(wholesaler);
            }
        }
        wholesalers.sort((a, b) => a.name > b.name ? 1 : -1);

        await this.wholesalerReceiptService.buildViewmodels(this.customer,
            wholesalers,
            this.employee,
            this
        );
    }
}
