import { Injectable } from "@angular/core";
import { EmailReceiptParams, EmailReceiptToCustomerContactParams, NotificationRequestDto, NotificationSyncCommand } from "shield.shared";
import { DataSyncQueueService } from "src/app/sync/data-sync-queue.service";

@Injectable()
export class NotificationOfflineService {

    private readonly notAvailableInOfflineModeError = "Process not available in offline mode.";

    constructor(private dataSyncQueueService: DataSyncQueueService){}

    async send(dto: NotificationRequestDto): Promise<undefined> {

        await this.dataSyncQueueService.enqueue(
            new NotificationSyncCommand(dto.id)
        );

        return;
    }

    async emailReceipt(params: EmailReceiptParams): Promise<undefined> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async emailReceiptToCustomerContact(params: EmailReceiptToCustomerContactParams): Promise<undefined> {

        throw Error(this.notAvailableInOfflineModeError);
    }
}
