import {
    Component,
    OnDestroy,
    OnInit,
    Type,
    ViewChild
} from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import {
    GenericDropDownDto,
    RefinerLocation,
    valueSeparator } from "shield.shared";
import { CallActivitiesViewmodel } from "./call-activities.viewmodel";
import { Customer } from "src/app/entity-models/customer.entity";
import { ActivitiesFilterService } from "src/app/services/activities-filter.service";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";

@Component({
    selector: "app-call-activities-filter",
    templateUrl: "./call-activities-filter.component.html",
    styleUrls: ["./call-activities-filter.component.scss"]
})
export class CallActivitiesFilterComponent extends FilterBaseComponent implements OnInit, OnDestroy {
    //view childs
    @ViewChild("chainsSelect") chainsSelect: MatSelect;
    @ViewChild("projectsSelect") projectsSelect: MatSelect;
    @ViewChild("wholesalersSelect") wholesalersSelect: MatSelect;

    //public vars
    contentComponent: Type<CallActivitiesFilterComponent>;
    icon = "check_circle";
    name = "Activities";
    viewmodel = new CallActivitiesViewmodel(this.activitiesService);

    private isProjectsInitialized = false;
    private isChainsInitialized = false;
    private isWholesalersInitialized = false;

    constructor(private activitiesService: ActivitiesFilterService) {
        super();
    }

    ngOnInit(): void {
        if (
            !this.viewmodel.chainsSubscription ||
            this.viewmodel.chainsSubscription.closed
        ) {
            this.viewmodel.chainsSubscription = this.activitiesService.observableChains.subscribe(
                (chains) => {
                    if (chains && this.activitiesService.chainsLoaded) {
                        let partialChains = chains.map((chain) => {
                            chain.commonOperatingName ??= chain.name;
                            return chain;
                        });
                        this.isChainsInitialized = true;

                        partialChains = [...new Map(partialChains.map((c) => [c.ownerCode, c])).values()];
                        this.viewmodel.chains = partialChains;

                        const selected = new Array<AccountOwnership>();
                        for (const chain of this.viewmodel.selectedChains) {
                            if (chains.map((c) => c.id).includes(chain.id)) {
                                selected.push(chain);
                            }
                        }
                        this.viewmodel.selectedChains = selected;
                        this.checkAndSetInitialized();
                    }
                }
            );
        }
        if (
            !this.viewmodel.projectsSubscription ||
            this.viewmodel.projectsSubscription.closed
        ) {
            this.viewmodel.projectsSubscription = this.activitiesService.observableProjects.subscribe(
                (projects) => {
                    if (projects && this.activitiesService.projectsLoaded) {
                        this.viewmodel.projectsDropdownSettings.noDataLabel = "No projects found.";
                        this.viewmodel.projects = projects;
                        this.isProjectsInitialized = true;

                        const selected = new Array<GenericDropDownDto>();
                        for (const project of this.viewmodel.selectedProjects) {
                            if (projects.map(p => p.id).includes(project.id)) {
                                selected.push(project);
                            }
                        }
                        this.viewmodel.selectedProjects = selected;
                        this.checkAndSetInitialized();
                    }
                }
            );
        }
        if (
            !this.viewmodel.wholesalersSubscription ||
            this.viewmodel.wholesalersSubscription.closed
        ) {
            this.viewmodel.wholesalersSubscription = this.activitiesService.observableWholesalers.subscribe(
                (wholesalers) => {
                    if (wholesalers && this.activitiesService.wholesalersLoaded) {
                        this.viewmodel.wholesalersDropdownSettings.noDataLabel = "No wholesalers found.";
                        this.viewmodel.wholesalers = wholesalers;
                        this.isWholesalersInitialized = true;

                        const selected = new Array<Customer>();
                        for (const wholesaler of this.viewmodel.selectedWholesalers) {
                            if (wholesalers.map((w) => w.id).includes(wholesaler.id)) {
                                selected.push(wholesalers.find(w => w.id === wholesaler.id));
                            }
                        }
                        this.viewmodel.selectedWholesalers = selected;
                        this.checkAndSetInitialized();
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (
            this.viewmodel.chainsSubscription &&
            !this.viewmodel.chainsSubscription.closed
        ) {
            this.viewmodel.chainsSubscription.unsubscribe();
        }
        if (
            this.viewmodel.projectsSubscription &&
            !this.viewmodel.projectsSubscription.closed
        ) {
            this.viewmodel.projectsSubscription.unsubscribe();
        }
        if (
            this.viewmodel.wholesalersSubscription &&
            !this.viewmodel.wholesalersSubscription.closed
        ) {
            this.viewmodel.wholesalersSubscription.unsubscribe();
        }
    }

    //events
    openedProjectSelect() {
        this.viewmodel.isProjectsStale = true;
        this.viewmodel.selectedProjectsHold = this.viewmodel.selectedProjects;
    }

    closedProjectSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.projects;
        refiner.value = this.viewmodel.selectedProjects?.length < 2
            ? this.viewmodel.selectedProjects
                .map((project) => project.name)
                .join(", ")
            : this.viewmodel.selectedProjects?.length + " Selected";
        refiner.dataPropertyName = "name"; // TODO: Determine property for local filter.
        refiner.dataValue = this.viewmodel.selectedProjects
            .map((project) => project.id)
            .join(valueSeparator);

        if (this.viewmodel.selectedProjectsHold === this.viewmodel.selectedProjects) {
            this.viewmodel.isProjectsStale = false;
        }

        this._refinerService.checkAndUpdateRefiner(refiner);
    }

    openedWholesalerSelect() {
        this.viewmodel.isWholesalersStale = true;
        this.viewmodel.selectedWholesalersHold = this.viewmodel.selectedWholesalers;
    }

    closedWholesalerSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.wholesalers;
        refiner.value = this.viewmodel.selectedWholesalers?.length < 2
            ? this.viewmodel.selectedWholesalers
                .map((wholesaler) => wholesaler.name)
                .join(", ")
            : this.viewmodel.selectedWholesalers?.length + " Selected";
        refiner.dataPropertyName = "customerWholesalers";
        refiner.dataValue = this.viewmodel.selectedWholesalers
            .map((wholesaler) => wholesaler.id)
            .join(valueSeparator);

        if (this.viewmodel.selectedWholesalersHold === this.viewmodel.selectedWholesalers) {
            this.viewmodel.isWholesalersStale = false;
        }

        this._refinerService.checkAndUpdateRefiner(refiner);
    }

    openedChainSelect() {
        this.viewmodel.isChainsStale = true;
        this.viewmodel.selectedChainsHold = this.viewmodel.selectedChains;
    }

    closedChainSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.chains;
        refiner.value = this.viewmodel.selectedChains?.length < 2
            ? this.viewmodel.selectedChains
                .map((chain) => chain.name)
                .join(", ")
            : this.viewmodel.selectedChains?.length + " Selected";
        refiner.dataPropertyName = "id";
        refiner.dataValue = this.activitiesService.getChainDataValues(this.viewmodel.selectedChains);

        if (this.viewmodel.selectedChainsHold === this.viewmodel.selectedChains) {
            this.viewmodel.isChainsStale = false;
        }

        this._refinerService.checkAndUpdateRefiner(refiner);
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.chains:
                    const selectedChains = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedChains = new Array<AccountOwnership>();

                    if (selectedChains) {
                        for (const chain of selectedChains) {
                            const found = this.viewmodel.chains.find(
                                (c) => c.id === chain.trim()
                            );
                            if (found) {
                                rtnSelectedChains.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedChains = rtnSelectedChains;
                    break;
                case RefinerLocation.projects:
                    const selectedProjects = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedProjects = new Array<GenericDropDownDto>();

                    if (selectedProjects) {
                        for (const project of selectedProjects) {
                            const found = this.viewmodel.projects.find(
                                (p) => p.id === project.trim()
                            );
                            if (found) {
                                rtnSelectedProjects.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedProjects = rtnSelectedProjects;
                    break;
                case RefinerLocation.wholesalers:
                    const selectedWholesalers = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedWholesalers = new Array<Customer>();

                    if (selectedWholesalers) {
                        for (const wholesaler of selectedWholesalers) {
                            const found = this.viewmodel.wholesalers.find(
                                (w) => w.id === wholesaler.trim()
                            );
                            if (found) {
                                rtnSelectedWholesalers.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedWholesalers = rtnSelectedWholesalers;
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.chains;
        const chainsRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.chains
        );
        if (chainsRefiner) {
            this.onInputChange(chainsRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.projects;
        const projectsRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.projects
        );
        if (projectsRefiner) {
            this.onInputChange(projectsRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.wholesalers;
        const wholesalersRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.wholesalers
        );
        if (wholesalersRefiner) {
            this.onInputChange(wholesalersRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }

    private checkAndSetInitialized(): void {
        if (!this.isInitialized
            && this.isProjectsInitialized
            && this.isChainsInitialized
            && this.isWholesalersInitialized
        ) {
            this.setInitialized();
        }
    }
}
