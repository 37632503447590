<div *ngIf="viewmodel?.customerName">
    <div class="card border shadow m-3">
        <div class="card-header d-flex justify-content-between align-items-center">
            <div class="flex">
                Information
                <div class="actionHover" (click)="setMode()">
                    <fa-icon *ngIf="viewMode" [icon]="faPen" class="ml-2 text-white"></fa-icon>
                </div>
            </div>
            <div *ngIf="viewmodel.lastEdited" class="text-white">
                Last Edited by: {{viewmodel.lastEditedBy}} on {{viewmodel.lastEdited | date: dateFormat}}
            </div>
        </div>
        <div class="card-body">
            <div class="row row-cols-3 pb-2 ml-5 mr-5">
                <div class="col-4">
                    <div class="mb-4 pb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="text-teal m-0">STORE INFO</h2>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Customer ID</p>
                            <b>{{ viewmodel.customerNumber}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">MSA Store</p>
                            <b>{{ viewmodel.msaStore }}</b>
                        </div>
                        <div *ngIf="viewmodel.isMsaStore"
                            class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">MSA Status</p>
                            <b>{{viewmodel.msaStatus}}</b>
                        </div>
                        <div *ngIf="viewmodel.isMsaStore && !viewmodel.isCallable"
                            class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Override to Callable</p>
                            <b *ngIf="viewMode">{{viewmodel.isCallableOverridden ? 'Yes' : 'No'}}</b>
                            <mat-slide-toggle *ngIf="!viewMode" color="accent" [disabled]="viewmodel.isCallable"
                                [(ngModel)]="viewmodel.isCallableOverridden">
                                {{viewmodel.isCallableOverridden ? 'Yes' : 'No'}}
                            </mat-slide-toggle>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Store Status</p>
                            <b *ngIf="viewMode">{{ viewmodel.isActive ? 'Active' : 'Inactive' }}</b>
                            <mat-slide-toggle *ngIf="!viewMode" color="accent" [(ngModel)]="viewmodel.isActive">
                                {{ viewmodel.isActive ? 'Active' : 'Inactive' }}</mat-slide-toggle>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Px3 Rank</p>
                            <b *ngIf="viewMode || !viewmodel.isAdmin">{{viewmodel?.selectedPx3Rank?.name !== undefined ? viewmodel?.selectedPx3Rank?.name : 'Not Ranked'}}</b>
                            <mat-form-field *ngIf="!viewMode && viewmodel.isAdmin" appearance="outline" color="accent">
                                <mat-label>Px3 Rank</mat-label>
                                <mat-select [(value)]="viewmodel.selectedPx3Rank.id">
                                    <mat-option *ngFor="let rank of viewmodel.px3Ranks" [value]="rank.id">
                                        {{rank.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Store Type</p>
                            <b *ngIf="viewMode">{{viewmodel.selectedCustomerType?.name}}</b>
                            <mat-form-field *ngIf="!viewMode" appearance="outline" color="accent">
                                <mat-label>Store Type</mat-label>
                                <mat-select [(value)]="viewmodel.selectedCustomerType.id">
                                    <mat-option *ngFor="let type of viewmodel.customerTypes" [value]="type.id">
                                        {{type.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Geo Area</p>
                            <b>{{ viewmodel.displayZrt }}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Store Rep</p>
                            <b>{{viewmodel.rep}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Manager</p>
                            <b>{{viewmodel.manager}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Created</p>
                            <b>{{viewmodel.dateCreated | date: dateTimeFormat}}</b>
                        </div>
                        <div *ngIf="viewmodel.dateDeactivated"
                            class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Deactivated</p>
                            <b>{{viewmodel.dateDeactivated | date: dateTimeFormat}}</b>
                        </div>
                        <div *ngIf="viewmodel.dateReactivated && viewmodel.dateReactivated > viewmodel.dateDeactivated"
                            class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Reactivated</p>
                            <b>{{viewmodel.dateReactivated | date: dateTimeFormat}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Phone</p>
                            <b *ngIf="viewMode">{{viewmodel.formattedPhone}}</b>
                            <mat-form-field *ngIf="!viewMode" appearance="outline" color="accent">
                                <mat-label id="phone-number">Phone Number</mat-label>
                                <input type="text" [formControl]="phoneFormControl" [value]="
                                        viewmodel.phone
                                    " placeholder="Ex. (555) 555-5555" [mask]="'(000) 000-0000'" matInput />
                                <mat-error *ngIf="phoneFormControl.hasError('pattern')">
                                    Please enter a valid 10 digit phone number!
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div *ngIf="viewmodel.selectedCustomerType?.id === viewmodel.chainRetailCustomerTypeId"
                        class="mb-4 pb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="text-teal m-0">CHAIN INFO</h2>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Chain Rep</p>
                            <b>{{ viewmodel.chainRep }}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Chain Name</p>
                            <b>{{ viewmodel.chainName }}</b>
                        </div>
                    </div>
                    <div class="mb-4 pb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="text-teal m-0">LICENSES</h2>
                        </div>
                        <div class="license-info d-flex justify-content-between align-items-center py-2">
                            <div class="d-flex align-items-center">
                                <fa-icon [icon]="faReceipt" class="license-icon"></fa-icon>
                                <div>
                                    <p class="license-type">TOBACCO LICENSE NUMBER</p>
                                    <p *ngIf="viewmodel.tobaccoLicenseLastUpdated" class="mb-0">Updated on
                                        {{viewmodel.tobaccoLicenseLastUpdated | date: dateFormat}}
                                    </p>
                                </div>
                            </div>
                            <strong *ngIf="viewMode">{{viewmodel.tobaccoLicense}}</strong>
                            <mat-form-field *ngIf="!viewMode" appearance="outline" color="accent">
                                <mat-label>Tobacco License</mat-label>
                                <input matInput placeholder="Type your tobacco license number..."
                                    [(ngModel)]="viewmodel.tobaccoLicense" maxlength="50">
                                <mat-hint align="end">{{
                                    viewmodel.tobaccoLicense
                                    ?.length || 0
                                    }}/50</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="license-info d-flex justify-content-between align-items-center py-2">
                            <div class="d-flex align-items-center">
                                <fa-icon [icon]="faReceipt" class="license-icon"></fa-icon>
                                <div>
                                    <p class="license-type">VAPOR LICENSE NUMBER</p>
                                    <p *ngIf="viewmodel.retailStateVaporLicenseLastUpdated" class="mb-0">Updated on
                                        {{viewmodel.retailStateVaporLicenseLastUpdated | date: dateFormat}}
                                    </p>
                                </div>
                            </div>
                            <strong *ngIf="viewMode">{{viewmodel.retailStateVaporLicense}}</strong>
                            <mat-form-field *ngIf="!viewMode" appearance="outline" color="accent">
                                <mat-label>Vapor License</mat-label>
                                <input matInput placeholder="Type your vapor license number..."
                                    [(ngModel)]="viewmodel.retailStateVaporLicense" maxlength="50">
                                <mat-hint align="end">{{
                                    viewmodel.retailStateVaporLicense
                                    ?.length || 0
                                    }}/50</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="license-info d-flex justify-content-between align-items-center py-2">
                            <div class="d-flex align-items-center">
                                <fa-icon [icon]="faReceipt" class="license-icon"></fa-icon>
                                <div>
                                    <p class="license-type">STATE TAX ID NUMBER</p>
                                    <p *ngIf="viewmodel.stateTaxIdNumberLastUpdated" class="mb-0">Updated on
                                        {{viewmodel.stateTaxIdNumberLastUpdated | date: dateFormat}}</p>
                                </div>
                            </div>
                            <strong *ngIf="viewMode">{{viewmodel.stateTaxIdNumber}}</strong>
                            <mat-form-field *ngIf="!viewMode" appearance="outline" color="accent">
                                <mat-label>State Tax ID</mat-label>
                                <input matInput placeholder="Type your State tax ID license number..."
                                    [(ngModel)]="viewmodel.stateTaxIdNumber" maxlength="50">
                                <mat-hint align="end">{{
                                    viewmodel.stateTaxIdNumber
                                    ?.length || 0
                                    }}/50</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="license-info d-flex justify-content-between align-items-center py-2">
                            <div class="d-flex align-items-center">
                                <fa-icon [icon]="faReceipt" class="license-icon"></fa-icon>
                                <div>
                                    <p class="license-type">FEDERAL TAX ID NUMBER</p>
                                    <p *ngIf="viewmodel.federalTaxIdNumberLastUpdated" class="mb-0">
                                        Updated on {{viewmodel.federalTaxIdNumberLastUpdated | date: dateFormat}}
                                    </p>
                                </div>
                            </div>
                            <strong *ngIf="viewMode">{{viewmodel.federalTaxIdNumber}}</strong>
                            <mat-form-field *ngIf="!viewMode" appearance="outline" color="accent">
                                <mat-label>Federal Tax ID</mat-label>
                                <input matInput placeholder="Type your Federal Tax ID license number..."
                                    [(ngModel)]="viewmodel.federalTaxIdNumber" maxlength="50">
                                <mat-hint align="end">{{
                                    viewmodel.federalTaxIdNumber
                                    ?.length || 0
                                    }}/50</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div>
                        <h2 class="text-teal">STORE COMMENTS</h2>
                        <p *ngIf="viewMode && viewmodel.storeComments">{{viewmodel.storeComments}}</p>
                        <p class="info-text" *ngIf="viewMode && !viewmodel.storeComments">There are currently no
                            comments.</p>
                        <mat-form-field *ngIf="!viewMode" appearance="outline" color="accent" class="w-100">
                            <mat-label>Store Comments</mat-label>
                            <textarea matInput placeholder="Enter Store Comments.."
                                [(ngModel)]="viewmodel.storeComments" maxlength="4000">
                        </textarea>
                            <mat-hint align="end">{{
                                viewmodel.storeComments
                                ?.length || 0
                                }}/4000</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-4">
                    <div class="mb-4 pb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="text-teal m-0">BUSINESS ADDRESS</h2>
                        </div>
                        <div *ngIf="viewMode || viewmodel.isMsaStore">
                            <b>{{ viewmodel.businessAddressName }}</b>
                            <p class="mb-0">{{ viewmodel.address1}}</p>
                            <p class="mb-0">{{ viewmodel.address2}}</p>
                            <p class="mb-0">{{ viewmodel.city }}</p>
                            <p class="mb-0">{{ viewmodel.selectedState }} {{ viewmodel.zip }}</p>
                        </div>

                        <div *ngIf="!viewMode && !viewmodel.isMsaStore">
                            <div>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>Business/Company Name</mat-label>
                                    <input matInput placeholder="Business/Company Name"
                                        [(ngModel)]="viewmodel.businessAddressName" maxlength="100">
                                    <mat-hint align="end">{{
                                        viewmodel.businessAddressName
                                        ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>Address 1</mat-label>
                                    <input matInput placeholder="Address 1" [(ngModel)]="viewmodel.address1"
                                        maxlength="100">
                                    <mat-hint align="end">{{
                                        viewmodel.address1
                                        ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>Address 2</mat-label>
                                    <input matInput placeholder="Address 2" [(ngModel)]="viewmodel.address2"
                                        maxlength="100">
                                    <mat-hint align="end">{{
                                        viewmodel.address2
                                        ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="d-flex justify-content-between">
                                <mat-form-field appearance="outline" color="accent" class="w-100 mr-1">
                                    <mat-label>City</mat-label>
                                    <input matInput placeholder="City" [(ngModel)]="viewmodel.city" maxlength="100">
                                    <mat-hint align="end">{{
                                        viewmodel.city
                                        ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>State</mat-label>
                                    <mat-select [(value)]="viewmodel.selectedState">
                                        <mat-option *ngFor="let vm of viewmodel.states" [value]="vm.shortCode">
                                            {{vm.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="accent" class="w-25">
                                    <mat-label>Zip Code</mat-label>
                                    <input matInput placeholder="Zip" [(ngModel)]="viewmodel.zip" maxlength="9"
                                        mask="999999999" digitOnly>
                                    <mat-hint align="end">{{
                                        viewmodel.zip
                                        ?.length || 0
                                        }}/9</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mb-4 pb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="text-teal m-0">DRIVING/DBA ADDRESS</h2>
                        </div>
                        <div *ngIf="viewMode">
                            <ng-container *ngIf="!viewmodel.dbaAddressName &&
                                    !viewmodel.drivingAddress1 &&
                                    !viewmodel.drivingCity &&
                                    !viewmodel.selectedDbaState &&
                                    !viewmodel.drivingZip">
                                N/A
                            </ng-container>
                            <b>{{viewmodel.dbaAddressName}}</b>
                            <p class="mb-0">{{viewmodel.drivingAddress1}}</p>
                            <p class="mb-0">{{viewmodel.drivingAddress2}}</p>
                            <p class="mb-0">{{viewmodel.drivingCity}}</p>
                            <p class="mb-0" *ngIf="viewmodel.selectedDbaState && viewmodel.drivingZip">
                                {{viewmodel.selectedDbaState}},
                                {{viewmodel.drivingZip}}
                            </p>
                        </div>

                        <div *ngIf="!viewMode">
                            <div>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>Customer Name</mat-label>
                                    <input matInput placeholder="Store Name" [(ngModel)]="viewmodel.dbaAddressName"
                                        maxlength="100">
                                    <mat-hint align="end">{{
                                        viewmodel.dbaAddressName
                                        ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>Address 1</mat-label>
                                    <input matInput placeholder="Address 1" [(ngModel)]="viewmodel.drivingAddress1"
                                        maxlength="100">
                                    <mat-hint align="end">{{
                                        viewmodel.drivingAddress1
                                        ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>Address 2</mat-label>
                                    <input matInput placeholder="Address 2" [(ngModel)]="viewmodel.drivingAddress2"
                                        maxlength="100">
                                    <mat-hint align="end">{{
                                        viewmodel.drivingAddress2
                                        ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="d-flex justify-content-between">
                                <mat-form-field appearance="outline" color="accent" class="w-100 mr-1">
                                    <mat-label>City</mat-label>
                                    <input matInput placeholder="City" [(ngModel)]="viewmodel.drivingCity"
                                        maxlength="100">
                                    <mat-hint align="end">{{
                                        viewmodel.drivingCity
                                        ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>State</mat-label>
                                    <mat-select [(value)]="viewmodel.selectedDbaState">
                                        <mat-option *ngFor="let vm of viewmodel.dbaStates" [value]="vm.shortCode">
                                            {{vm.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="accent" class="w-25">
                                    <mat-label>Zip Code</mat-label>
                                    <input matInput placeholder="Zip" [(ngModel)]="viewmodel.drivingZip" maxlength="9"
                                        mask="999999999" digitOnly>
                                    <mat-hint align="end">{{
                                        viewmodel.drivingZip
                                        ?.length || 0
                                        }}/9</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer bg-m-gray">
            <div class="d-flex justify-content-end align-items-center">
                <button class="btn btn-lg btn-secondary mr-2 pl-5 pr-5" *ngIf="viewMode" routerLink="../profile">
                    Cancel
                </button>
                <button appThrottleClick class="btn btn-lg btn-secondary mr-2 pl-5 pr-5" *ngIf="!viewMode"
                    (throttledClick)="openDialog()">
                    Cancel
                </button>
                <button class="btn btn-lg btn-primary ml-2 pl-5 pr-5" *ngIf="viewMode" (click)="setMode()">
                    <fa-icon [icon]="faPen" class="mr-2 text-white"></fa-icon>
                    Edit
                </button>
                <button appThrottleClick *ngIf="!viewMode" class="btn btn-lg btn-primary ml-2 pl-5 pr-5"
                    (throttledClick)="saveCustomer()">
                    <fa-icon [icon]="faSave" class="mr-2 text-white"></fa-icon>
                    Save
                </button>
            </div>
        </div>

    </div>
