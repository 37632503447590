import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    GenericVersionResponseDto, SharedHelper, SyncCommandTypes,
    SystemInformationKeys, VersionResponseDto, WholesalerGroupMemberDto,
    WholesalerGroupMemberSyncCommand
} from "shield.shared";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { WholesalerGroupMemberConverterService } from "src/app/services/converter-services/wholesaler-group-member-converter.service";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { SyncLevel } from "../sync-enums/sync-level.enum";

@Injectable()
export class WholesalerGroupMemberDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.Required;
    offlineRepSyncType = SyncLevel.Required;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.wholesalerGroupMember;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing wholesaler group members...");

        await this.pullData();

        this.log("Done syncing wholesaler group members...");
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>(
                    "/api/wholesaler-groups/members/version"
                )
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("Wholesaler group member data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Wholesaler group member data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.wholesalerGroupMemberPullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 2000;

        try {
            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;

                const response = await this.http
                    .get<GenericVersionResponseDto<WholesalerGroupMemberDto[]>>(
                        `/api/wholesaler-groups/members${query}`
                    )
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                if (thisBatchSize) {
                    this.log(
                        `Downloaded ${response.values.length} wholesaler group members, saving to IndexedDB...`
                    );

                    const wholesalerGroupMembers = response.values.map((wgm) =>
                        WholesalerGroupMemberConverterService.wholesalerGroupMemberDtoToWholesalerGroupMember(
                            wgm
                        )
                    );

                    await this.dbService.wholesalerGroupMembers.bulkPut(
                        wholesalerGroupMembers
                    );
                    this.log(
                        `  Saved  ${wholesalerGroupMembers.length} wholesaler group members.`
                    );
                }
                this.lastSyncVersion = response.maxVersion;

            } while (thisBatchSize > 0);

            this.log("Done saving wholesaler group member data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing wholesaler group members", e);
        }
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<WholesalerGroupMemberSyncCommand>(
            SyncCommandTypes.wholesalerGroupMember,
            async (message) => {
                let wholesalerGroupMember = await this.dbService.wholesalerGroupMembers.get(
                    message.payload.id
                );

                let dto = WholesalerGroupMemberConverterService.wholesalerGroupMemberToWholesalerGroupMemberDto(
                    wholesalerGroupMember
                );
                if (dto) {
                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }
}
