<div class="chain-profile-grid">
        <div class="chain-profile-grid-item col-1--row-1">
            <div class="card m-0 h-100">
                <div class="card-header d-flex justify-content-between align-items-center">
                    Information
                    <button class="btn sw-transparent-btn btn-sm" [routerLink]="['../info']">
                        <span>
                            <fa-icon [icon]="faEye"></fa-icon>
                        </span>
                    </button>
                </div>
                <div *ngIf="chainHeadquarterProfileViewmodel?.customer" class="card-body p-0 small-font">
                    <div class="card-view-port">
                        <div class="d-flex flex-column">
                            <div class="d-flex justify-content-between mt-2 mb-3 px-3">
                                <div class="w-50 mr-3">
                                    <div class="d-flex justify-content-between align-items-center border-bottom mb-2">
                                        <b>Store Type</b>
                                        <p class="mb-0">{{chainHeadquarterProfileViewmodel.customer.customerType?.name}}</p>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center border-bottom mb-2">
                                        <b>Chain Type</b>
                                        <p class="mb-0">{{chainHeadquarterProfileViewmodel.type}}</p>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center border-bottom mb-2">
                                        <b>Store Count</b>
                                        <p class="mb-0">{{chainHeadquarterProfileViewmodel.numberOfStores | number}}</p>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center border-bottom mb-2">
                                        <b>Program</b>
                                        <p class="mb-0">{{chainHeadquarterProfileViewmodel.customer.program}}</p>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center border-bottom mb-2">
                                        <b>MSA</b>
                                        <p class="mb-0">{{chainHeadquarterProfileViewmodel.customer?.isMsa
                                            ? "Yes" : "No"}}</p>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <b>Status</b>
                                        <p class="mb-0">{{chainHeadquarterProfileViewmodel.customer?.isActive ? "Active"
                                            : "Inactive"}}</p>
                                    </div>
                                </div>
                                <div class="w-50 ml-3">
                                    <ng-container *ngIf="chainHeadquarterProfileViewmodel.ultimateCustomer">
                                        <div class="d-flex justify-content-between align-items-center border-bottom mb-2">
                                            <b>Ultimate</b>
                                            <p class="mb-0">{{chainHeadquarterProfileViewmodel.ultimateCustomer?.name}}</p>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center border-bottom mb-2">
                                            <b>Rep</b>
                                            <p class="mb-0">{{chainHeadquarterProfileViewmodel.ultimateCustomerRep}}</p>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="chainHeadquarterProfileViewmodel.immediateCustomer">
                                        <div class="d-flex justify-content-between align-items-center border-bottom mb-2">
                                            <b>Immediate</b>
                                            <p class="mb-0">{{chainHeadquarterProfileViewmodel.immediateCustomer?.name}}</p>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center border-bottom mb-2">
                                            <b>Rep</b>
                                            <p class="mb-0">{{chainHeadquarterProfileViewmodel.immediateCustomerRep}}</p>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="chainHeadquarterProfileViewmodel.ownerCustomer">
                                        <div class="d-flex justify-content-between align-items-center border-bottom mb-2">
                                            <b>Owner</b>
                                            <p class="mb-0">{{chainHeadquarterProfileViewmodel.ownerCustomer?.name}}</p>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <b>Rep</b>
                                            <p class="mb-0">{{chainHeadquarterProfileViewmodel.ownerCustomerRep}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>
                                            Chain Comments
                                        </th>
                                        <th>
                                            Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{chainHeadquarterProfileViewmodel.customer?.comments}}
                                        </td>
                                        <td>
                                            {{chainHeadquarterProfileViewmodel.customer?.commentsLastUpdated | date:
                                            dateFormat}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="chain-profile-grid-item col-1--row-2">
            <app-related-contacts
                [customer]="customer"
                [contactOptions]="contactOptions"
                [currentEmployee]="currentEmployee"
                (onAddContact)="onAddContact()"
                (onEditContact)="onEditContact($event)"
                (onRemoveContact)="onRemoveContact($event)">
            </app-related-contacts>
        </div>
        <div class="chain-profile-grid-item col-2--row-1-2">
            <app-customer-call-history [customer]="customer"
            callHistoryTitle="Call History"
            [openClosingNotes]="openClosingNotes"></app-customer-call-history>
        </div>
        <div class="chain-profile-grid-item col-3--row-1-2">
            <app-related-projects
                [projects]="relatedProjects"
                (onOpenNewProject)="openNewProject()"
                (onOpenViewProject)="openViewProject($event)"
                [isNotTm]="isNotTm">
            </app-related-projects>
        </div>
</div>
