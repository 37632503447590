import { RefinerLocation } from "shield.shared";
import { State } from "src/app/entity-models/state.entity";
import { FilterService } from "src/app/services/filter.service";


export class WholesalerGroupsViewmodel {
    readonly refinerLocations: RefinerLocation[] = [
        RefinerLocation.group,
        RefinerLocation.orderMethod,
        RefinerLocation.wholesaler,
        RefinerLocation.states
    ];
    readonly country = "United States of America";

    customerInput: string;
    filterService: FilterService;
    groupInput: string;
    orderMethods = new Array<string>();
    selectedOrderMethods = new Array<string>();
    selectedStates = new Array<State>();
    states = new Array<State>();
    dropdownSet = false;

    constructor(
        filterService: FilterService
    ) {
        this.filterService = filterService;

        this.initializeDropDowns();
    }

    async initializeDropDowns(): Promise<void> {
        this.orderMethods.push("EDI");
        this.orderMethods.push("EOrder");
        this.orderMethods.push("Manual");

        this.states = await this.filterService.getStates();
        this.dropdownSet = true;
    }
}
