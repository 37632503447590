import { ElementRef } from "@angular/core";
import { MatDrawerMode, MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import { MatSortHeader } from "@angular/material/sort";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { BehaviorSubject, fromEvent, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { FilterSortDto, ProjectAssignmentSummaryOptions, ProjectCustomerColumns, projectNonChainStoreFilterName, RefinerLocation, SharedHelper, SortDirection, valueSeparator } from "shield.shared";
import { Employee } from "src/app/entity-models/employee.entity";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { ProjectCustomer } from "src/app/entity-models/project-customer.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterLocation } from "src/app/enums/filter-location";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ActivitiesFilterService } from "src/app/services/activities-filter.service";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { BaseFilterMapService } from "src/app/services/filter-map-services/base-filter-map.service";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { ProjectStateService } from "src/app/services/project-state-service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { ProjectAssignmentsFilterComponent } from "src/app/shared/filters/project-assignments-filter/project-assignments-filter.component";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { ColumnSelector } from "src/app/shared/viewmodels/column-selector.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { ProjectAssignmentStatuses } from "../../project-enums/project-assignment-statuses";
import { ProjectApplicationService } from "../../project-services/project-application.service";
import { ProjectAssignmentDialogComponent } from "./project-assignment-dialog/project-assignment-dialog.component";
import { ProjectAssignmentDialogViewModel } from "./project-assignment-dialog/project-assignment-dialog.viewmodel";
import { ProjectAssignmentGridViewmodel } from "./project-assignment-grid.viewmodel";
import { ProjectAssignmentRefinerService } from "./project-assignment-refiner.service";
import { ProjectAssignmentsSummaryViewModel } from "./project-assignments-summary.viewmodel";
import { ProjectAssignmentsZrtFilterService } from "./project-assignments-zrt-filter.service";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { ProjectBasicCustomerEmployee } from "src/app/entity-models/project-basic-customer-employee.entity";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";

export class ProjectAssignmentsViewmodel {
    accountOwnershipDelineationService: AccountOwnershipDelineationService;
    activeRefiners: Refiner[] = [];
    activeSorts: FilterSortDto<ProjectCustomerColumns>[] = [];
    activitiesFilterService: ActivitiesFilterService;
    assignmentOverlayRef: SwisherOverlayRef<ProjectAssignmentDialogViewModel, ProjectAssignmentDialogComponent>;
    assignmentStatusAll = ProjectAssignmentStatuses.All;
    assignmentStatusAssigned = ProjectAssignmentStatuses.Assigned;
    assignmentStatusUnassigned = ProjectAssignmentStatuses.Unassigned;
    availableColumns: ColumnSelector[];
    columnDef: ColumnDef[];
    columnsToDisplay: string[];
    currentRefinerMap: Map<RefinerLocation, string> = new Map();
    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    detailHeight = 48;
    drawer: MatSidenav;
    drawerMode: MatDrawerMode = "side";
    employees: Employee[];
    employeesFiltered: Employee[];
    employeeDelineationService: EmployeeDelineationService;
    expandPanelsOnInit = true;
    faEye: IconDefinition = faEye;
    filters: FilterAndParams[] = [];
    filterLocation = FilterLocation.projectStores;
    grid: GridComponent;
    gridAssignedStatus: ProjectAssignmentStatuses;
    gridData = new Array<GridData>();
    gridDataChangeSubscription: Subscription;
    header: PageHeaderComponent;
    isFixedLayout = false;
    isGapSet$?: BehaviorSubject<boolean>;
    isRetrievingData = false;
    isSearchButtonDisabled = false;
    isSearchStale = false;
    itemsRendedInViewPort = 13;
    overlayService: OverlayService;
    pageIndex = 0;
    pageSize = 50;
    pleaseWaitService: PleaseWaitService;
    previousRefinerMap: Map<RefinerLocation, string> = new Map();
    projectDelineationService: ProjectDelineationService;
    projectApplicationService: ProjectApplicationService;
    projectAssignmentRefinerService: ProjectAssignmentRefinerService;
    projectStateService: ProjectStateService;
    projectSubscription: Subscription;
    refinerInputChangeSubscription: Subscription;
    refinerServiceSubscription: Subscription;
    selectedProjectCustomerIds: string[] = [];
    selectedStoreCount = 0;
    selectedStoresHaveAssignments: boolean = false;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    sideNavContent: MatSidenavContent;
    snackbarService: SnackbarService;
    summaryAssigned = ProjectAssignmentSummaryOptions.Assigned;
    summaryCity = ProjectAssignmentSummaryOptions.City;
    summaryCounty = ProjectAssignmentSummaryOptions.County;
    summaryState = ProjectAssignmentSummaryOptions.State;
    summaryZip = ProjectAssignmentSummaryOptions.Zip;
    summaryChain = ProjectAssignmentSummaryOptions.Chain;
    summarySearchElement: ElementRef;
    summarySearchSubscription: Subscription;
    summarySearchText = "";
    summarySortBy = ProjectAssignmentSummaryOptions.Assigned;
    summaryVms = new Array<ProjectAssignmentsSummaryViewModel>();
    summaryVmsFiltered = new Array<ProjectAssignmentsSummaryViewModel>();
    total = 0;
    zrtFilterService: ProjectAssignmentsZrtFilterService;
    zrtSelectionSubscription: Subscription;

    sortFunction = (columnDef: ColumnDef) => {
        if (this.grid) {
            const filterSorts = new Array<FilterSortDto<ProjectCustomerColumns>>();
            this.grid.sort.sortables.forEach((item) => {
                let sortDirection: SortDirection;
                switch ((item as MatSortHeader)._sort.direction) {
                    case "asc":
                        sortDirection = SortDirection.ascending;
                        break;
                    case "desc":
                        sortDirection = SortDirection.descending;
                        break;
                    default:
                        sortDirection = SortDirection.none;
                        break;
                }
                const sortDto = new FilterSortDto<ProjectCustomerColumns>();
                sortDto.direction = sortDirection;
                const headerName = this.columnDef.find(
                    (cd) => cd.dataPropertyName === item.id
                ).headerName;
                if (
                    headerName === columnDef.headerName &&
                    sortDirection != SortDirection.none
                ) {
                    sortDto.column = headerName as ProjectCustomerColumns;
                    filterSorts.push(sortDto);
                }
            });
            this.activeSorts = filterSorts;

            void this.getBatch(0);
        }
    }

    constructor(
        projectRefinerService: ProjectAssignmentRefinerService,
        pleaseWaitService: PleaseWaitService,
        projectDelineationService: ProjectDelineationService,
        snackbarService: SnackbarService,
        projectStateService: ProjectStateService,
        projectApplicationService: ProjectApplicationService,
        overlayService: OverlayService,
        employeeDelineationService: EmployeeDelineationService,
        accountOwnershipDelineationService: AccountOwnershipDelineationService,
        activitiesFilterService: ActivitiesFilterService,
        zrtFilterService: ProjectAssignmentsZrtFilterService,
        private customerDelineationService: CustomerDelineationService
    ) {

        this.projectAssignmentRefinerService = projectRefinerService;
        this.pleaseWaitService = pleaseWaitService;
        this.projectDelineationService = projectDelineationService;
        this.snackbarService = snackbarService;
        this.projectStateService = projectStateService;
        this.projectApplicationService = projectApplicationService;
        this.overlayService = overlayService;
        this.employeeDelineationService = employeeDelineationService;
        this.accountOwnershipDelineationService = accountOwnershipDelineationService;
        this.activitiesFilterService = activitiesFilterService;
        this.zrtFilterService = zrtFilterService;
    }

    onSetAllAssignments(): void {
        this.search();
        this.projectStateService.notify();
    }

    //public methods
    unsubscribe(): void {
        if (this.refinerServiceSubscription && !this.refinerServiceSubscription.closed) {
            this.refinerServiceSubscription.unsubscribe();
        }
        if (this.refinerInputChangeSubscription && !this.refinerInputChangeSubscription.closed) {
            this.refinerInputChangeSubscription.unsubscribe();
        }
        if (this.gridDataChangeSubscription && !this.gridDataChangeSubscription.closed) {
            this.gridDataChangeSubscription.unsubscribe();
        }
        if (this.projectSubscription && !this.projectSubscription.closed) {
            this.projectSubscription.unsubscribe();
        }
        if (this.zrtSelectionSubscription && !this.zrtSelectionSubscription.closed) {
            this.zrtSelectionSubscription.unsubscribe();
        }
        if (this.summarySearchSubscription && !this.summarySearchSubscription.closed) {
            this.summarySearchSubscription.unsubscribe();
        }
    }

    initialize(
        grid: GridComponent,
        sideNavContent: MatSidenavContent,
        drawer: MatSidenav,
        summarySearchElement: ElementRef,
        isGapSet: BehaviorSubject<boolean>
    ): void {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(
            this.shouldWait$
        );

        this.grid = grid;
        this.sideNavContent = sideNavContent;
        this.drawer = drawer;
        this.isGapSet$ = isGapSet;
        this.summarySearchElement = summarySearchElement;

        this.filters.push({ filter: ProjectAssignmentsFilterComponent, zrtFilterService: this.zrtFilterService });

        void this.setFilterData();

        if (
            !this.refinerServiceSubscription ||
            this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription = this.projectAssignmentRefinerService.refiners$.subscribe(
                () => {
                    this.onRefinersChange();
                }
            );
        }

        if (
            !this.gridDataChangeSubscription ||
            this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription = this.grid.dataSourceChange.subscribe(
                (dc) => {
                    if (dc) {
                        this.shouldWait$.next(true);
                    }
                }
            );
        }

        if (
            !this.projectSubscription ||
            this.projectSubscription.closed
        ) {
            this.projectSubscription = this.projectStateService.observableProject.subscribe(
                async (project) => {
                    if (project) {
                        this.gridAssignedStatus = project.areStoresVisibleToAll || !project.projectCustomers.some(v => !v.employeeIds?.length)
                            ? ProjectAssignmentStatuses.All
                            : ProjectAssignmentStatuses.Unassigned;
                        await this.getBatch(0, true);
                    }
                }
            );
        }

        if (
            !this.zrtSelectionSubscription ||
            this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription = this.zrtFilterService.observableSelectedZrts.subscribe((selectedZrts) => {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.zrtByArea;
                refiner.value = selectedZrts.length > 1
                    ? `${selectedZrts.length} Selected`
                    : selectedZrts
                        .map((vm) => vm.zrt)
                        .join(", ");
                refiner.dataPropertyName = "zrt";
                refiner.dataValue = selectedZrts
                    .map((vm) => vm.id)
                    .join(valueSeparator);

                this.projectAssignmentRefinerService.checkAndUpdateRefiner(refiner, true);
            });
        }

        const searchValue = fromEvent(
            this.summarySearchElement.nativeElement,
            "input"
        ).pipe(
            map(
                (e: InputEvent) => (e.target as HTMLInputElement).value
            ),
            debounceTime(500),
            distinctUntilChanged()
        );
        if (
            !this.summarySearchSubscription ||
            this.summarySearchSubscription.closed
        ) {
            this.summarySearchSubscription = searchValue.subscribe(() => {
                this.filterSummary();
            });
        }

        setTimeout(() => {
            this.columnDef = [
                {
                    headerName: "",
                    dataPropertyName: "isSelected",
                    isAvailable: true,
                    isSelected: true,
                    isSelectable: true,
                    columnClass: "w-25",
                },
                {
                    headerName: ProjectCustomerColumns.storeName,
                    dataPropertyName: "storeName",
                    isAvailable: true,
                    isSelected: true
                },
                {
                    headerName: ProjectCustomerColumns.address,
                    dataPropertyName: "address",
                    isAvailable: true,
                    isSelected: true
                },
                {
                    headerName: ProjectCustomerColumns.city,
                    dataPropertyName: "city",
                    isAvailable: true,
                    isSelected: true
                },
                {
                    headerName: ProjectCustomerColumns.county,
                    dataPropertyName: "county",
                    isAvailable: true,
                    isSelected: true
                },
                {
                    headerName: ProjectCustomerColumns.state,
                    dataPropertyName: "state",
                    isAvailable: true,
                    isSelected: true
                },
                {
                    headerName: ProjectCustomerColumns.zip,
                    dataPropertyName: "zip",
                    isAvailable: true,
                    isSelected: true
                },
                {
                    headerName: ProjectCustomerColumns.territory,
                    dataPropertyName: "zrt",
                    isAvailable: true,
                    isSelected: true
                },
                {
                    headerName: ProjectCustomerColumns.chain,
                    dataPropertyName: "chain",
                    isAvailable: true,
                    isSelected: true
                },
                {
                    headerName: ProjectCustomerColumns.assignments,
                    dataPropertyName: "displayAssignments",
                    isAvailable: true,
                    isSelected: true
                }
            ];

            const availableColumns = new Array<ColumnSelector>();

            this.columnsToDisplay = this.columnDef
                .filter((cd) => {
                    if (cd.isAvailable) {
                        const column: ColumnSelector = {
                            name: cd.headerName,
                            isSelected: cd.isSelected,
                            columns: null
                        };
                        availableColumns.push(column);
                    }
                })
                .map((cd) => cd.headerName);
            this.availableColumns = availableColumns;
        }, 0);

        this.shouldWait$.next(false);
    }

    async buildSummaryViewModels(): Promise<void> {
        this.summarySearchText = "";

        const summaryResponse = await this.projectDelineationService.getAssignmentCounts(
            this.projectStateService.project.id,
            this.summarySortBy
        );

        if (!summaryResponse || !summaryResponse.values) return;

        this.summaryVms = summaryResponse.values.sort((a, b) => {
            if (a.value === projectNonChainStoreFilterName) return 1;
            if (b.value === projectNonChainStoreFilterName) return -1;
            return a.value.localeCompare(b.value);
        });
        this.filterSummary();
    }



    reset(): void {
        this.projectAssignmentRefinerService.resetRefiners();
    }

    filterSummary(): void {
        if (this.projectStateService.project.areStoresVisibleToAll) {
            switch (this.summarySortBy) {
                case ProjectAssignmentSummaryOptions.Assigned:
                    this.summaryVms = this.summaryVms.map(v => {
                        v.assignedCount = this.projectStateService.project.projectCustomers.length;
                        return v;
                    });
                    break;
                default:
                    this.summaryVms = this.summaryVms.map(v => {
                        v.assignedCount += v.unassignedCount;
                        v.unassignedCount = 0;
                        return v;
                    });
                    break;
            }
        }
        if (this.summarySearchText) {
            this.summaryVmsFiltered = this.summaryVms.filter(
                (vm) => vm.value.toLocaleLowerCase().includes(this.summarySearchText.toLocaleLowerCase())
            )
        } else {
            this.summaryVmsFiltered = this.summaryVms;
        }
    }

    formatDisplayAssignments(projectCustomer: ProjectCustomer, visibleToAll?: boolean): string {
        if (visibleToAll) return "ALL";

        if (!projectCustomer) return;

        const employees = (this.employees ?? []).filter(v => (projectCustomer.employeeIds ?? []).includes(v.id));
        return employees.length ? employees.map(v => v.zrt).join(", ") : "";
    }

    async getBatch(
        index: number,
        getEmployees?: boolean
    ): Promise<void> {
        if (!this.grid) return;

        this.isRetrievingData = true;
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        if (!this.employees || getEmployees) {
            const employeesResponse = await this.employeeDelineationService.getByIds(this.projectStateService.project.projectEmployees
                .map((pe) => pe.employeeId));

            if (!employeesResponse) {
                this.isRetrievingData = false;
                this.shouldWait$.next(false);
                return;
            }

            this.employees = employeesResponse.values ?? new Array<Employee>();
            this.filterSummary();
        }

        this.resolvePageIndex(index);
        this.isSearchStale = false;

        const id = this.projectStateService.project.id;
        const results = await this.projectDelineationService.getCustomersForProjectEmployeeAssignmentBatch(
            id,
            this.getLocalRefiners().concat(this.activeRefiners),
            this.pageSize,
            this.pageIndex * this.pageSize,
            this.activeSorts
        );

        if (!results) {
            this.isRetrievingData = false;
            this.shouldWait$.next(false);
            return;
        }

        this.total = results.getCount();
        this.isGapSet$.next(false);

        this.setDataSource(results.values);
        await this.buildSummaryViewModels();

        this.isRetrievingData = false;
        this.shouldWait$.next(true);
    }

    getEmptyMessage(): string {
        if (this.isRetrievingData) {
            return "Retrieving...";
        }

        if (this.activeRefiners.length) {
            return "No stores match the selected filter criteria."
        }

        switch (this.gridAssignedStatus) {
            case ProjectAssignmentStatuses.Assigned:
                return "No stores have been assigned employees on this project.";
            case ProjectAssignmentStatuses.Unassigned:
                return "No unassigned stores remain on this project.";
            default:
                return "No stores have been added to this project.";
        }
    }

    getUnassignedCount(): number {
        if (this.projectStateService.project.areStoresVisibleToAll) return 0;
        return this.summaryVms.filter(v => !v.assignedCount).length;
    }

    isAssignButtonDisabled(): boolean {
        return (!this.selectedStoreCount || !this.employees.length || this.projectStateService.project?.areStoresVisibleToAll);
    }

    isUnassignButtonDisabled(): boolean {
        return (this.isAssignButtonDisabled() || !this.selectedStoresHaveAssignments);
    }

    isSummaryFilterDisabled(): boolean {
        return (this.projectStateService.project.areStoresVisibleToAll && this.summarySortBy == ProjectAssignmentSummaryOptions.Assigned);
    }

    onRefinersChange() {
        this.zrtFilterService.selectedZrts = [];
        this.search();
    }

    async openAssignDialog(): Promise<void> {
        const data = new ProjectAssignmentDialogViewModel();
        data.employees = this.employees;

        const summaryResponse = await this.projectDelineationService.getAssignmentCounts(
            this.projectStateService.project.id,
            ProjectAssignmentSummaryOptions.Assigned
        );
        data.storeCounts = summaryResponse?.values;

        data.buttonLeftFunction = () => this.assignmentOverlayRef.close(data);
        data.buttonRightDisabledFunction = () => !(data.someChecked() || data.allChecked());
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.assignmentOverlayRef.close(data);
        }
        data.width = "30vw";
        this.assignmentOverlayRef = this.overlayService.open(
            ProjectAssignmentDialogComponent,
            data,
            true
        );

        this.assignmentOverlayRef.afterClosed$.subscribe(async (ref) => {
            if (ref && ref.data && ref.data.isConfirmed) {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
                const assignResponse = await this.projectDelineationService.assignEmployeesToStores(
                    this.projectStateService.project.id,
                    ref.data.selectedEmployees.map(v => v.id)
                );

                if (!assignResponse) return;

                this.projectStateService.project.projectCustomers = assignResponse.values;
                this.projectStateService.notify();

                this.resetSelectedStores();
                this.search();
                this.shouldWait$.next(false);
            }
        });
    }

    async openUnassignDialog(): Promise<void> {
        const data = new ProjectAssignmentDialogViewModel(true);
        data.employees = this.getUnassignEmployees();
        const summaryResponse = await this.projectDelineationService.getAssignmentCounts(
            this.projectStateService.project.id,
            ProjectAssignmentSummaryOptions.Assigned
        );
        data.storeCounts = summaryResponse?.values?.filter(v => data.employees.map(e => e.id).includes(v.dataValue));

        data.buttonLeftFunction = () => this.assignmentOverlayRef.close(data);
        data.buttonRightDisabledFunction = () => !(data.someChecked() || data.allChecked());
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.assignmentOverlayRef.close(data);
        }
        data.width = "30vw";
        this.assignmentOverlayRef = this.overlayService.open(
            ProjectAssignmentDialogComponent,
            data,
            true
        );

        this.assignmentOverlayRef.afterClosed$.subscribe(async (ref) => {
            if (ref && ref.data && ref.data.isConfirmed) {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
                const unassignResponse = await this.projectDelineationService.unassignEmployeesFromStores(
                    this.projectStateService.project.id,
                    ref.data.selectedEmployees.map(v => v.id)
                );

                if (!unassignResponse) return;
                this.projectStateService.project.projectCustomers = unassignResponse.values;
                this.projectStateService.notify();

                this.resetSelectedStores();
                this.search();
                this.shouldWait$.next(false);
            }
        });
    }

    resetSelectedStores(): void {
        this.selectedProjectCustomerIds = [];
        this.selectedStoreCount = 0;
    }

    resolvePageIndex(index: number): boolean {
        this.currentRefinerMap = new Map<RefinerLocation, string>();
        for (const refiner of this.projectAssignmentRefinerService.refiners ?? []) {
            this.currentRefinerMap.set(refiner.location, refiner.dataValue ?? refiner.value);
        }

        const rtn = SharedHelper.compareMaps(
            this.currentRefinerMap,
            this.previousRefinerMap
        );

        if (rtn && this.total != this.gridData?.length && index !== 0) {
            this.pageIndex++;
        } else {
            this.previousRefinerMap = new Map(this.currentRefinerMap);
            this.pageIndex = 0;
        }
        return rtn;
    }

    search(): void {
        this.activeRefiners = this.projectAssignmentRefinerService.refiners.slice();
        this.getBatch(0);
    }

    searchBySummary(dataValue?: string): void {
        const activeRefiners = new Array<Refiner>();

        if (dataValue) {
            activeRefiners.push(this.getSummaryRefiner(dataValue));
        }

        this.activeRefiners = activeRefiners.concat(this.projectAssignmentRefinerService.refiners.slice());
        this.getBatch(0);
    }


    async setCustomerForAssignment(index: number): Promise<void> {
        this.grid.setHeaderCheckbox(this.gridData.length && !this.gridData.some((row) => !row.data.isSelected));

        let customerId: string = null;
        let checked = null;
        if (index > -1) {
            const found = this.gridData[index]?.data;
            if (found) {
                customerId = found.customerId;
                checked = found.isSelected;
            }
        } else if (index === -1) {
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
            checked = this.dataSource.data[0].data.isSelected;
        }

        const selectionResponse = await this.projectDelineationService.setProjectStoreForAssignment(
            this.getLocalRefiners().concat(this.projectAssignmentRefinerService.refiners),
            checked,
            this.projectStateService.project.id,
            customerId
        );
        if (!selectionResponse || !selectionResponse.values) {
            this.shouldWait$.next(false);
            this.getBatch(0);
            return;
        }

        this.selectedProjectCustomerIds = this.selectedProjectCustomerIds.filter(v => !selectionResponse.values.includes(v));
        if (checked) {
            this.selectedProjectCustomerIds = this.selectedProjectCustomerIds.concat(selectionResponse.values);
        }

        this.selectedStoresHaveAssignments = !!this.getUnassignEmployees().length;
        this.selectedStoreCount = this.selectedProjectCustomerIds.length; //TODO: Why doesn't this work? selectionResponse.getCount();

        this.shouldWait$.next(false);
    }

    //Assigns all employees that are associated with a customers ZRT to the store for this project..
    public async assignEmployeesBasedOnCustomerZrt(): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        const stores = await this.customerDelineationService.getFromServerByIds(this.projectStateService.project.projectCustomers.map(pc => pc.customerId));
        const employees = await this.employeeDelineationService.getByIds(this.projectStateService.project.projectEmployees.map(pe => pe.employeeId));
        for (const store of stores.values) {
            const employeesToAssign = employees.values.filter(v => v.zrt === store.zrt);
            if (employeesToAssign.length === 0) continue;
            //Send a request to the server to select the store in the project.
            const storeSelectionResponse = await this.projectDelineationService.setProjectStoreForAssignment(
                this.projectAssignmentRefinerService.refiners,
                true,
                this.projectStateService.project.id,
                store.id
            );
            if (storeSelectionResponse.values.length === 0) continue;
            //Send the request to assign the employee to the previously assigned store.
            const assignResponse = await this.projectDelineationService.assignEmployeesToStores(
                this.projectStateService.project.id,
                employeesToAssign.map(v => v.id),
            );
            if (assignResponse.values.length === 0) continue;
            this.projectStateService.project.projectCustomers = assignResponse.values;
        }
        this.projectStateService.notify();
        this.resetSelectedStores();
        this.search();
    }

    //Unassigns all employees that are associated with a customers ZRT from the store for this project.
    public async undoAssignEmployeesBasedOnCustomerZrt(): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        const stores = await this.customerDelineationService.getFromServerByIds(this.projectStateService.project.projectCustomers.map(pc => pc.customerId));
        const employees = await this.employeeDelineationService.getByIds(this.projectStateService.project.projectEmployees.map(pe => pe.employeeId));
        this.gridAssignedStatus = ProjectAssignmentStatuses.All;
        for (const store of stores.values) {
            const employeesToUnassign = employees.values.filter(v => v.zrt === store.zrt);
            if (employeesToUnassign.length === 0) continue;
            //Send a request to the server to select the store in the project.
            const storeSelectionResponse = await this.projectDelineationService.setProjectStoreForAssignment(
                this.projectAssignmentRefinerService.refiners,
                true,
                this.projectStateService.project.id,
                store.id
            );
            if (storeSelectionResponse.values.length === 0) continue;
            //Send the request to unassign the employee from the previously assigned store.
            const unassignResponse = await this.projectDelineationService.unassignEmployeesFromStores(
                this.projectStateService.project.id,
                employeesToUnassign.map(v => v.id),
            );
            if (unassignResponse.values.length === 0) continue;
            this.projectStateService.project.projectCustomers = unassignResponse.values;
        }
        this.projectStateService.notify();
        this.resetSelectedStores();
        this.search();
    }

    setDataSource(customers: ProjectBasicCustomerEmployee[]): void {
        const gridData = this.pageIndex ? this.gridData.slice() : new Array<GridData>();
        const largestIndex = gridData.length;

        for (const customer of customers) {
            const projectCustomer = this.projectStateService.project.projectCustomers.find(v => v.customerId === customer.customerId) ?? new ProjectCustomer();
            const displayAssignments = this.formatDisplayAssignments(projectCustomer, this.projectStateService.project.areStoresVisibleToAll);

            const gridItem: GridData = {
                data: new ProjectAssignmentGridViewmodel(customer, displayAssignments),
                detailArrayName: "",
                isExpanded: false,
                index: largestIndex + customers.indexOf(customer)
            };

            gridItem.data.isSelected = this.selectedProjectCustomerIds.includes(projectCustomer.id);
            gridData.push(gridItem);
        }

        this.grid.setHeaderCheckbox(gridData.length && !gridData.some((row) => !row.data.isSelected));

        this.gridData = gridData;
        this.dataSource = new TableVirtualScrollDataSource(
            this.gridData
        );
    }

    async setFilterData(): Promise<void> {
        this.zrtFilterService.byArea = true;
        const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts();
        if (!zrtsResponse) {
            return;
        }
        this.zrtFilterService.employeeZrts = zrtsResponse.values;

        const chainsResponse = await this.accountOwnershipDelineationService.getAll();
        if (chainsResponse?.values?.length) {
            this.activitiesFilterService.chains = chainsResponse.values;
            this.activitiesFilterService.chains.sort((a, b) => a.name < b.name ? -1 : 1);
        } else {
            this.activitiesFilterService.chains = new Array<AccountOwnership>();
        }
    }



    showUnassignedFromSummary(): void {
        this.gridAssignedStatus = ProjectAssignmentStatuses.Unassigned;
        this.reset();
    }


    private getLocalRefiners(): Refiner[] {
        const rtn = new Array<Refiner>();

        switch (this.gridAssignedStatus) {
            case ProjectAssignmentStatuses.Assigned:
                const assignedRefiner = new Refiner();
                assignedRefiner.location = RefinerLocation.isAssignedToEmployee;
                assignedRefiner.dataValue = assignedRefiner.value = BaseFilterMapService.true;
                rtn.push(assignedRefiner);
                break;
            case ProjectAssignmentStatuses.Unassigned:
                const unassignedRefiner = new Refiner();
                unassignedRefiner.location = RefinerLocation.isAssignedToEmployee;
                unassignedRefiner.dataValue = unassignedRefiner.value = BaseFilterMapService.false;
                rtn.push(unassignedRefiner);
                break;
            default:
                break;
        }

        return rtn;
    }

    private getSummaryRefiner(dataValue: string): Refiner {
        const rtn = new Refiner();

        switch (this.summarySortBy) {
            case ProjectAssignmentSummaryOptions.Assigned:
                rtn.location = RefinerLocation.assignedEmployee;
                rtn.value = rtn.dataValue = dataValue;
                break;
            case ProjectAssignmentSummaryOptions.City:
                rtn.location = RefinerLocation.city;
                rtn.value = rtn.dataValue = dataValue;
                break;
            case ProjectAssignmentSummaryOptions.County:
                rtn.location = RefinerLocation.counties;
                rtn.value = rtn.dataValue = dataValue;
                break;
            case ProjectAssignmentSummaryOptions.State:
                rtn.location = RefinerLocation.states;
                rtn.value = rtn.dataValue = dataValue;
                break;
            case ProjectAssignmentSummaryOptions.Zip:
                rtn.location = RefinerLocation.zipCodes;
                rtn.value = rtn.dataValue = dataValue;
                break;
            case ProjectAssignmentSummaryOptions.Chain:
                rtn.location = RefinerLocation.chains;
                rtn.value = rtn.dataValue = dataValue;
                break;
            default:
                break;
        }

        return rtn;
    }

    private getUnassignEmployees(): Employee[] {
        const employeeIdsToUnassign: string[] = [].concat(...this.projectStateService.project.projectCustomers
            .filter(v => this.selectedProjectCustomerIds.includes(v.id) && v.employeeIds)
            .map(v => v.employeeIds));

        return (this.employees ?? []).filter(v => employeeIdsToUnassign.includes(v.id));
    }
}
