import { FlatTreeControl } from "@angular/cdk/tree";
import { ElementRef } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatSelect } from "@angular/material/select";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { fromEvent, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { SharedHelper } from "shield.shared";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { ZrtFilterServiceBase } from "src/app/services/zrt-filter.service.base";
interface FlatNode {
    expandable: boolean;
    id: string;
    displayValue: string;
    level: number;
    children?: SearchZrtDropDown[];
    enabled: boolean;
}
export class ZrtTreeViewViewModel {
    readonly zrtDisplayValueDefault = "All Selected";
    private defaultZrtSelection = new Array<SearchZrtDropDown>();
    defaultZrtSelectionSubscription: Subscription;
    selectedZrts = new Array<SearchZrtDropDown>();
    selectedZrtOption = ["default"];
    selectedZrtsSubscription: Subscription;
    zrtDisplayValue = this.zrtDisplayValueDefault;
    zrtSearch: ElementRef;
    zrtSearchSubscription: Subscription;
    zrtSearchValue = "";
    zrtSelect: MatSelect;
    zrts = new Array<SearchZrtDropDown>();
    zrtSubscription: Subscription;
    hasChild = (_: number, node: FlatNode) => node.expandable;
    private zrtToFlatNode = (node: SearchZrtDropDown, level: number): FlatNode => {
        return {
            expandable: !!node.children && node.children.length > 0,
            id: node.id,
            displayValue: node.displayValue,
            level: level,
            children: node.children,
            enabled: this.isCheckboxEnabled(node),
        };
    };
    treeControl = new FlatTreeControl<FlatNode>(
        (node) => node.level,
        (node) => node.expandable
    );
    treeFlattener = new MatTreeFlattener(
        this.zrtToFlatNode,
        (node) => node.level,
        (node) => node.expandable,
        (node) => node.children
    );
    zrtData = new MatTreeFlatDataSource(
        this.treeControl,
        this.treeFlattener
    );
    constructor(private zrtFilterService: ZrtFilterServiceBase) { }

    buildZrtTree(searchValue?: string): void {
        if (!searchValue) {
            this.zrtData.data = this.zrts;
        } else {
            searchValue = searchValue.trim().toLocaleLowerCase();
            const matches = new Array<SearchZrtDropDown>();
            for (const z of this.zrts) {
                if (
                    (
                        SharedHelper.searchString(z.displayValue, searchValue) ||
                        SharedHelper.searchString(z.zrt, searchValue)
                    ) && matches.filter((v) => v.id === z.id).length <= 0
                ) {
                    matches.push(z);
                }
                for (const r of z.children) {
                    if (
                        (
                            SharedHelper.searchString(r.displayValue, searchValue) ||
                            SharedHelper.searchString(r.zrt, searchValue)
                        ) && matches.filter((v) => v.id === r.id).length <= 0
                    ) {
                        matches.push(r);
                    }
                    for (const t of r.children) {
                        if (
                            (
                                SharedHelper.searchString(t.displayValue, searchValue) ||
                                SharedHelper.searchString(t.zrt, searchValue)
                            ) && matches.filter((v) => v.id === t.id).length <= 0
                        ) {
                            matches.push(t);
                        }
                    }
                }
            }
            this.zrtData.data = matches;
        }
        if (this.zrts?.length === 1) {
            this.treeControl.expandAll();
        }
    }

    initialize(zrtSearch: ElementRef, zrtSelect: MatSelect): void {
        this.zrtSearch = zrtSearch;
        this.zrtSelect = zrtSelect;

        if (!this.zrtSubscription || this.zrtSubscription.closed) {
            this.zrtSubscription = this.zrtFilterService.observableZrts.subscribe((zrts) => {
                this.zrts = zrts;
                this.buildZrtTree();
                const selected = this.selectedZrts.map((v) => ZrtFilterServiceBase.findNodeByZrt(this.zrts, v.zrt)).filter((v) => !!v);
                if (selected.length) {
                    this.zrtFilterService.selectedZrts = selected;
                    return;
                }
                this.zrtFilterService.selectedZrts = [];
            });
        }
        if (!this.selectedZrtsSubscription || this.selectedZrtsSubscription.closed) {
            this.selectedZrtsSubscription = this.zrtFilterService.observableSelectedZrts.subscribe((selected) => {
                this.setSelected(selected);
                this.updateNodeExpansion();
            });
        }
        if (!this.defaultZrtSelectionSubscription || this.defaultZrtSelectionSubscription.closed) {
            this.defaultZrtSelectionSubscription = this.zrtFilterService.observableDefaultZrtSelection.subscribe((defaultSelection) => {
                this.defaultZrtSelection = defaultSelection;
            });
        }
        const searchValue = fromEvent(
            this.zrtSearch.nativeElement,
            "input"
        ).pipe(
            map(
                (e: InputEvent) => (e.target as HTMLInputElement).value
            ),
            debounceTime(500),
            distinctUntilChanged()
        );
        if (
            !this.zrtSearchSubscription ||
            this.zrtSearchSubscription.closed
        ) {
            this.zrtSearchSubscription = searchValue.subscribe(() => {
                this.buildZrtTree(this.zrtSearchValue);
            });
        }
    }

    // Override how material handles the space character
    onKeyDown(event: any): void {
        if (event.key === ' ')
            event.stopPropagation()
    }

    private isCheckboxEnabled(node: SearchZrtDropDown | FlatNode): boolean {
        if (this.zrtFilterService.byArea) {
            return true;
        }
        
        return (!!node.id) || (
                (node.children && node.children.length > 0) &&
                node.children.some(c => this.isCheckboxEnabled(c))
        );
    }

    isZrtSelected(event: FlatNode): boolean {
        let isSelected = !!event.id && this.selectedZrts.map((v) => v?.id).includes(event?.id);

        if (!isSelected) {
            if (event.children) {
                for (const child of event.children) {
                    isSelected = this.isZrtSelected(this.zrtToFlatNode(child, event.level + 1));
                    if (!isSelected) {
                        break;
                    }
                }
            }
        }
        return isSelected;
    }

    openedZrtChange(): void {
        if (!this.zrtSelect) return;

        if (this.zrtSelect.panelOpen && this.zrtSearch) {
            this.zrtSearch.nativeElement.focus();
        }

        this.rebuildZrtView();
    }

    setSelected(zrts: SearchZrtDropDown[]) {
        this.selectedZrts = zrts?.length ? zrts.filter((v) => !!v) : this.defaultZrtSelection;
        if (this.selectedZrts && this.selectedZrts.length) {
            let includedInTopLevel = new Array<string>();
            this.selectedZrts
                .filter(v => v.children?.length > 0)
                .forEach(v => includedInTopLevel = includedInTopLevel.concat(v.children.map(c => c.id)));
            this.zrtDisplayValue = this.selectedZrts
                .filter(v => !includedInTopLevel.includes(v.id))
                .map((vm) => vm.displayValue)
                .join(", ");
        } else {
            this.zrtDisplayValue = this.zrtDisplayValueDefault;
        }
    }

    setSelectionByIds(employeeIds: string[]): void {
        if (!employeeIds) return;
        const rtnSelectedIds = new Array<SearchZrtDropDown>();
        for (const id of employeeIds) {
            const found = ZrtFilterServiceBase.findNodeById(this.zrts, id);
            if (found) {
                rtnSelectedIds.push(found);
            }
        }
        this.setSelected(rtnSelectedIds);
    }

    setSelectionByZrts(zrts: string[]): void {
        if (!zrts) return;
        const rtnSelectedZrts = new Array<SearchZrtDropDown>();
        for (const zrt of zrts) {
            const found = ZrtFilterServiceBase.findNodeByZrt(this.zrts, zrt);
            if (found) {
                rtnSelectedZrts.push(found);
            }
        }
        this.setSelected(rtnSelectedZrts);
    }

    isIndeterminate(node: FlatNode): boolean {
        if (node.level === 2 || !node.children) {
            return false;
        }
        let rtn = node.children.some((child) => !!child.id && !this.selectedZrts.map((v) => v.zrt).includes(child.zrt))
            && node.children.some((child) => !!child.id && this.selectedZrts.map((v) => v.zrt).includes(child.zrt));
        if (rtn) return rtn;

        // for Zm we look at the RM nodes. We either have a node that is Indeterminate or we have children
        // that are all selected and unselected
        rtn = node.children.some((child) => {
            return this.isIndeterminate(this.zrtToFlatNode(child, node.level + 1));;
        })
            || (node.children.some((child) => {
                return this.areAllChildrenChecked(this.zrtToFlatNode(child, node.level + 1))
            })
                && node.children.some((child) => {
                    return !this.areAllChildrenChecked(this.zrtToFlatNode(child, node.level + 1))
                }))
        return rtn;
    }

    areAllChildrenChecked(node: FlatNode): boolean {
        if (!node.children || !node.children.length) { return false; }
        return !node.children.some((child) => !this.selectedZrts.map((v) => v.zrt).includes(child.zrt));
    }

    updateChildZrt(event: MatCheckboxChange, node: FlatNode): void {
        if (event.checked) {
            const parent = this.treeControl.dataNodes.filter(v => v.children && v.children.map(c => c.id).some(id => id == node.id))[0];
            if (parent) {
                let allChildrenSelected = true;
                for (const child of parent.children) {
                    if (!this.selectedZrts.concat(ZrtFilterServiceBase.findNodeById(this.zrts, node.id)).map(v => v.id).includes(child.id)) {
                        allChildrenSelected = false;
                        break;
                    }
                }
                if (allChildrenSelected) {
                    this.updateParentZrts(event, parent);
                    return;
                }
            }
        }
        this.updateSelectedZrts(event, node);
    }

    updateParentZrts(event: MatCheckboxChange, node: FlatNode) {

        for (const child of node.children) {

            const myEvent = this.zrtToFlatNode(child, node.level + 1);
            if (myEvent.children?.length) {
                this.updateParentZrts(event, myEvent);
                this.updateSelectedZrts(event, myEvent);
            } else {
                this.updateSelectedZrts(event, myEvent);
            }
        }
        this.updateSelectedZrts(event, node);
    }

    private rebuildZrtView(): void {
        if (this.zrtSelect && !this.zrtSelect.panelOpen) {
            this.zrtSearchValue = "";
            this.buildZrtTree();
            this.zrtFilterService.selectedZrtsSubject.next(this.selectedZrts);
            this.updateNodeExpansion();
        }
    }

    private updateSelectedZrts(event: MatCheckboxChange, node: FlatNode): void {
        let updatedZrt = ZrtFilterServiceBase.findNodeById(this.zrts, node.id);
        if (!updatedZrt) return;

        let selectedToSet = this.selectedZrts.filter((v) => v && v.id !== updatedZrt.id);
        if (event.checked) {
            selectedToSet = selectedToSet.concat(updatedZrt);
        }
        this.setSelected(selectedToSet);
        this.rebuildZrtView();
    }

    private updateNodeExpansion(): void {
        this.treeControl.collapseAll();
        const parentNodes = this.treeControl.dataNodes?.filter(v => v.children &&
            v.children
                .map(node => node.id)
                .some(id => this.selectedZrts.map(v => v.id).includes(id))
        );
        if (parentNodes && parentNodes.length) {
            parentNodes.forEach(node => this.treeControl.expand(node));
        }
    }

    unsubscribe(): void {
        if (this.defaultZrtSelectionSubscription && !this.defaultZrtSelectionSubscription.closed) {
            this.defaultZrtSelectionSubscription.unsubscribe();
        }
        if (this.selectedZrtsSubscription && !this.selectedZrtsSubscription.closed) {
            this.selectedZrtsSubscription.unsubscribe();
        }
        if (this.zrtSubscription && !this.zrtSubscription.closed) {
            this.zrtSubscription.unsubscribe();
        }
        if (this.zrtSearchSubscription && !this.zrtSearchSubscription.closed) {
            this.zrtSearchSubscription.unsubscribe();
        }
    }
}
