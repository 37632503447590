import { EmployeeRoleType, ProjectStatuses } from "./lookup";

export const apiVersion = 2;
export const invalidOwnerCodes = ["999999", "N/A", ""];
export const activeProjectStatuses = [ProjectStatuses.Editable, ProjectStatuses.Started, ProjectStatuses.Visible];
export const valueSeparator = "|";
export const milisecondsToSecondsScalar = 1000;
export const projectNonChainStoreFilterName = "Non-Chain Stores";
export const approvedProjectRoles = [
    EmployeeRoleType.CS,
    EmployeeRoleType.CorpAdmin,
    EmployeeRoleType.NAM,
    EmployeeRoleType.RM,
    EmployeeRoleType.ShieldAdmin,
    EmployeeRoleType.ZCAM,
    EmployeeRoleType.ZM,
    EmployeeRoleType.SPM,
    EmployeeRoleType.ShieldSupport
];

// if we start to track more than this subject, move it to an enum
export const receiptForCall = "Receipt for Call";
