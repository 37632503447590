import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRequestDto, GenericResponseDto, newSequentialId, WholesalerGroupMemberDto } from "shield.shared";
import { WholesalerGroupMember } from "src/app/entity-models/wholesaler-group-member.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { WholesalerGroupMemberConverterService } from "../converter-services/wholesaler-group-member-converter.service";

@Injectable()
export class WholesalerGroupMemberOnlineService {

    constructor(private http: HttpClient){}

    async getByWholesalerId(id: string): Promise<GenericResponseDto<WholesalerGroupMember>> {

        const result = await this.http
            .get<GenericResponseDto<WholesalerGroupMemberDto>>(`/api/wholesaler-groups/members/by-wholesaler/${id}`).toPromise();

        return ConverterHelper.dtoToEntity<WholesalerGroupMemberDto, WholesalerGroupMember>(result,
            (r) => WholesalerGroupMemberConverterService.wholesalerGroupMemberDtoToWholesalerGroupMember(r));
    }

    async getByWholesalerIds(ids: string[]): Promise<GenericResponseDto<WholesalerGroupMember[]>> {

        const request = new GenericRequestDto<string[]>();
        request.id = newSequentialId();
        request.values = ids;

        const result = await this.http
            .post<GenericResponseDto<WholesalerGroupMemberDto[]>>(`/api/wholesaler-groups/members/by-wholesalerids`, request).toPromise();

        return ConverterHelper.dtoToEntityArray<WholesalerGroupMemberDto, WholesalerGroupMember>(result,
            (r) => WholesalerGroupMemberConverterService.wholesalerGroupMemberDtoToWholesalerGroupMember(r));
    }
}
