import {
    Component,
    EventEmitter, HostBinding,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { Subscription, combineLatest, from } from "rxjs";
import { CustomerStateService } from "../account-services/customer-state.service";
import { CustomerGenericTypes } from "../../enums/customer-generic-types";
import { Helper } from "src/app/helpers/helper";
import { AppStateService } from "src/app/services/app-state.service";
import { RetailCallComponent } from "./retail-call/retail-call.component";
import { CallService } from "./call-services/call.service";
import { Employee } from "src/app/entity-models/employee.entity";
import { WholesaleCallComponent } from "./wholesale-call/wholesale-call.component";
import { ChainHqCallComponent } from "./chain-hq-call/chain-hq-call.component";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { DexieTableNames } from "src/app/enums/dexie-table-names";
import { RmWholesaleCallComponent } from "./rm-wholesale-call/rm-wholesale-call.component";
import { CallTypes, CustomerTypeEnum, GenericLookup } from "shield.shared";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { OverlayService } from "src/app/services/overlay.service";
import { Router } from "@angular/router";
import { filter, map, shareReplay, startWith, switchMap, tap } from "rxjs/operators";
import { startsWith } from "lodash";

@Component({
    selector: "app-call-master",
    templateUrl: "./call-master.component.html",
    styleUrls: ["./call-master.component.css"]
})
export class CallMasterComponent implements OnInit, OnDestroy {
    @HostBinding("class") class = "d-flex flex-column flex-grow-1";
    @ViewChild("retail") retailComponent: RetailCallComponent;
    @ViewChild("wholesale") wholesaleComponent: WholesaleCallComponent;
    @ViewChild("chain") chainComponent: ChainHqCallComponent;
    @ViewChild("rmWholesale") rmWholesaleComponent: RmWholesaleCallComponent;

    confirmationOverlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    parentFunction: Function;

    @Output() cancelCallEmitter = new EventEmitter<this>();
    @Output() blockCallEmitter = new EventEmitter<this>();
    callType$ = combineLatest([this.customerStateService.observableCustomer, this.appStateService.currentEmployee]).pipe(
        filter(([customer, employee]) => !!customer && !!employee),
        map(([customer, employee]) => this.callService.getCallTypeForCustomerAndEmployee(customer, employee)),
        tap((callType) => this.callType = callType)
    )
    isCallable$ = this.customerStateService.observableCustomer.pipe(
        filter((customer) => !!customer),
        switchMap((customer) => from(this.customerDelineationService.getCustomerCallableStatus(customer.id))),
        map((callableStatus) => callableStatus.values),
    )
    viewModel$ = combineLatest([this.callType$, this.isCallable$]).pipe(
        map(([callType, isCallable]) => ({ callType, isCallable }))
    );
    isCallableSubscription: Subscription;
    callType: CallTypes;
    retail = CallTypes.retail;
    wholesale = CallTypes.wholesale;
    chainHq = CallTypes.chainHq;
    rmWholesale = CallTypes.rmWholesale;

    constructor(private customerStateService: CustomerStateService
        , private appStateService: AppStateService
        , private callDelineationService: CallDelineationService
        , private customerDelineationService: CustomerDelineationService
        , private overlayService: OverlayService
        , private router: Router
        , private callService: CallService) {
            this.callDelineationService.isOfflineOnly = true;
        }

    ngOnInit(): void {
        if (!this.isCallableSubscription || this.isCallableSubscription.closed) {
            this.isCallableSubscription = combineLatest([this.isCallable$, this.customerStateService.observableCustomer]).subscribe(
                ([isCallable, customer]) => {
                    if (this.confirmationOverlayRef) {
                        this.confirmationOverlayRef.close();
                    }
                    if (!isCallable) {
                        void this.router.navigate(["/accounts", customer.id, "profile"]);
                        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                        data.header = "Warning - Not Currently Callable";
                        data.message =`You are not assigned to ${customer.name} (or the store has yet to sync).`;
                        data.buttonRightText = "Close";
                        data.buttonRightFunction = () => {
                            this.confirmationOverlayRef.close(data);
                        };

                        this.confirmationOverlayRef = this.overlayService.open(
                            ConfirmationDialogComponent,
                            data
                        );

                        this.confirmationOverlayRef.afterClosed$.subscribe(() => {
                            this.confirmationOverlayRef = undefined;
                        });
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        this.callDelineationService.isOfflineOnly = false;

        if (this.isCallableSubscription && !this.isCallableSubscription.closed) {
            this.isCallableSubscription.unsubscribe();
        }
    }

    blockCall(): void {
        this.blockCallEmitter.emit(this);
    }

    cancelCall(): void {
        this.cancelCallEmitter.emit(this);
    }

    async cancelOtherOpenCall(
        callService: CallService,
        employee: Employee,
        ): Promise<void> {

        if (!employee) { return; }

        const openCall = await callService.getOpenCall(
            employee
        );

        if (openCall) {
            await this.callDelineationService.delete(openCall, DexieTableNames.calls);
        };

        switch (this.callType) {
            case CallTypes.retail:
                this.retailComponent.startRetailCall();
                break;
            case CallTypes.wholesale:
                this.wholesaleComponent.startWholesaleCall();
                break;
            case CallTypes.rmWholesale:
                this.rmWholesaleComponent.startRmWholesaleCall();
                break;
            case CallTypes.chainHq:
                this.chainComponent.startChainHqCall();
                break;
            default:
                break;
        }
    }
}
