import { AfterContentChecked, Component, HostBinding, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AccountsListRefinerService } from "./accounts-list-refiner.service";
import { Router } from "@angular/router";
import { AppStateService } from "src/app/services/app-state.service";
import { MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { FilterService } from "src/app/services/filter.service";
import { OverlayService } from "src/app/services/overlay.service";
import { newSequentialId} from "shield.shared";
import { AccountsListDataViewModel } from "./accounts-list-data.viewmodel";
import { AccountsListViewModel } from "./accounts-list.viewmodel";
import { ActivitiesFilterService } from "src/app/services/activities-filter.service";
import { PingService } from "src/app/services/ping.service";
import { AgmInfoWindow, AgmMarker } from "@agm/core";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { RouteManagementService } from "src/app/my-day/route-management/route-management.service";
import { BehaviorSubject, Observable } from "rxjs";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { RegisteredUserDelineationService } from "src/app/services/delineation-services/registered-user-delineation.service";
import { RouteDelineationService } from "src/app/services/delineation-services/route-delineation.service";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { RegisteredUser } from "src/app/entity-models/registered-user-entity";
import { MySearchesFilterComponent } from "src/app/shared/filters/my-searches/my-searches-filter.component";
import { CustomerLocationFilterComponent } from "src/app/shared/filters/customer-location-filter/customer-location-filter.component";
import { CustomerDateInformationFilterComponent } from "src/app/shared/filters/customer-date-information-filter/customer-date-information-filter.component";
import { CustomerActivitiesFilterComponent } from "src/app/shared/filters/customer-activities-filter/customer-activities-filter.component";
import { CustomerAttributesFilterComponent } from "src/app/shared/filters/customer-attributes-filter/customer-attributes-filter.component";
import { CustomerMarker } from "src/app/entity-models/customer-marker.entity";
import { AccountListZrtFilterService } from "./account-list-zrt-filter.service";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { DatasourceDelineationService } from "src/app/services/delineation-services/datasource-delineation.service";
import { SyncService } from "src/app/services/sync.service";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";

@Component({
    selector: "app-accounts-list",
    templateUrl: "./accounts-list.component.html",
    styleUrls: ["./accounts-list.component.scss"],
    providers: [PleaseWaitService]
})
export class AccountsListComponent implements OnInit , OnDestroy, AfterContentChecked {
    @HostBinding("class") class = "worksheet-static d-flex flex-column flex-grow-1";

    @ViewChild("drawer") drawer: MatSidenav;
    @ViewChild("grid") grid: GridComponent;
    @ViewChild("sideNavContent") sideNavContent: MatSidenavContent;
    @ViewChild("header") header: PageHeaderComponent;

    viewmodel: AccountsListViewModel;
    dataViewmodel: AccountsListDataViewModel;
    isViewmodelReady = false;
    arefiltersIntialized = false;

    private shouldWait$ = new BehaviorSubject<boolean>(true);

    constructor(
        private accountOwnershipsDelineationService: AccountOwnershipDelineationService,
        private activitiesService: ActivitiesFilterService,
        private appStateService: AppStateService,
        private customerDelineationService: CustomerDelineationService,
        private datasourceDelineationService: DatasourceDelineationService,
        private employeeDelineationService: EmployeeDelineationService,
        private filterService: FilterService,
        private overlayService: OverlayService,
        private pingService: PingService,
        private pleaseWaitService: PleaseWaitService,
        private productDelineationService: ProductDelineationService,
        private projectDelineationService: ProjectDelineationService,
        private refinerService: AccountsListRefinerService,
        private registeredUserDelineationService: RegisteredUserDelineationService,
        private routeManagementService: RouteManagementService,
        private router: Router,
        private snackbar: SnackbarService,
        private zone: NgZone,
        private routeDelineationService: RouteDelineationService,
        private zrtFilterService: AccountListZrtFilterService,
        private syncService: SyncService,
        private px3RankService: Px3DelineationService,
        private callService: CallDelineationService,
    ) {}

    // events
    ngOnInit(): void {

        this.dataViewmodel = new AccountsListDataViewModel(
            this.accountOwnershipsDelineationService,
            this.activitiesService,
            this.customerDelineationService,
            this.employeeDelineationService,
            this.datasourceDelineationService,
            this.filterService,
            this.pingService,
            this.productDelineationService,
            this.projectDelineationService,
            this.refinerService,
            this.routeDelineationService,
            this.routeManagementService,
            this.zrtFilterService,
            this.px3RankService
        );
        this.viewmodel = new AccountsListViewModel(
            this.dataViewmodel,
            this.customerDelineationService,
            this.filterService,
            this.overlayService,
            this.pleaseWaitService,
            this.refinerService,
            this.registeredUserDelineationService,
            this.routeManagementService,
            this.router,
            this.snackbar,
            this.zone,
            this.zrtFilterService,
            this.syncService,
            this.px3RankService
        );

        const filters: FilterAndParams[] = [];

        filters.push({ filter: MySearchesFilterComponent });
        filters.push({ filter: CustomerLocationFilterComponent, zrtFilterService: this.zrtFilterService });
        filters.push({ filter: CustomerDateInformationFilterComponent });
        filters.push({ filter: CustomerActivitiesFilterComponent });
        filters.push({ filter: CustomerAttributesFilterComponent });

        this.viewmodel.filters = filters;

        if (
            !this.viewmodel.employeeSubscription ||
            this.viewmodel.employeeSubscription.closed
        ) {
            this.viewmodel.employeeSubscription = this.appStateService.currentEmployee.subscribe(
                async (employee) => {
                    if (employee) {
                        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

                        this.viewmodel.employee = employee;
                        this.dataViewmodel.employee = employee;

                        const registeredUserResponse = await this.registeredUserDelineationService.getByEmployeeId(employee.id);
                        if (registeredUserResponse?.values) {
                            this.viewmodel.registeredUser = registeredUserResponse?.values;
                        } else {
                            const registeredUser: RegisteredUser = new RegisteredUser();
                            registeredUser.id = newSequentialId();
                            registeredUser.employeeId = employee.id;
                            registeredUser.userId = employee.user.localAccountId;
                            registeredUser.createdUserFullName = employee.fullName;
                            registeredUser.createdUserId = employee.id;
                            registeredUser.createdUserZrt = employee.searchableZrt;
                            registeredUser.createdUtcDateTime = new Date();
                            this.viewmodel.registeredUser = registeredUser;
                            await this.registeredUserDelineationService.saveRegisteredUser(registeredUser);
                        }
                        this.dataViewmodel.activeRefiners = this.refinerService.refiners.slice();
                        await this.dataViewmodel.setFilterData();
                        await this.dataViewmodel.getFutureRoutes();

                        this.viewmodel.employeeDataLoaded = true;
                        void this.viewmodel.getGridData(0, this.refinerService.refiners);
                        this.shouldWait$.next(false);
                    }
                }
            );
        }
    }

    ngAfterContentChecked(): void {
        if (this.isViewmodelReady && !this.viewmodel.isGapSet) {
            this.viewmodel.calculateGap();
        }

        if (!this.isViewmodelReady
            && this.grid
            && this.header
            && this.sideNavContent
            && this.drawer
            && this.viewmodel.employee
            && this.refinerService.areDefaultsSet
        ) {
            this.isViewmodelReady = true;
            this.getScreenSize();
            this.shouldWait$.next(false);
            void this.viewmodel.initialize(
                this.grid,
                this.header,
                this.sideNavContent,
                this.drawer
            );
        }
    }

    ngOnDestroy(): void {
        this.dataViewmodel.unsubscribe();
        this.viewmodel.unsubscribe();
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.viewmodel.routingMode && this.viewmodel.routeTouched) {
            if (this.viewmodel.confirmationOverlayRef) {
                this.viewmodel.confirmationOverlayRef.close();
            }
            const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
            data.header = "Confirmation";
            data.message =
                "Would you like to leave the route builder? Any unsaved changes to your current day's route will be lost.";
            data.buttonLeftText = "Cancel";
            data.buttonLeftFunction = () => {
                this.viewmodel.confirmationOverlayRef.close(data);
                this.viewmodel.canDeactivateRouteBuilderSubject.next(false);
                return this.viewmodel.observableCanDeactivateRouteBuilder;
            };
            data.buttonRightText = "Yes";
            data.buttonRightFunction = () => {
                this.routeManagementService.route = null;
                this.viewmodel.confirmationOverlayRef.close(data);
                this.viewmodel.canDeactivateRouteBuilderSubject.next(true);
            };

            this.viewmodel.confirmationOverlayRef = this.overlayService.open(
                ConfirmationDialogComponent,
                data
            );

            this.viewmodel.confirmationOverlayRef.afterClosed$.subscribe(() => {
                this.viewmodel.confirmationOverlayRef = undefined;
            });
        } else {
            this.routeManagementService.route = null;
            return true;
        }
        return this.viewmodel.observableCanDeactivateRouteBuilder;
    }

    closeInfoWindow(): void {
        if (this.viewmodel.previousInfoWindow !== null) {
            try {
                this.viewmodel.zone.runOutsideAngular(() => {
                    void this.viewmodel.previousInfoWindow.close();
                    this.viewmodel.previousInfoWindow = null;
                });
            } catch (e) { return; }
        }
    }

    openInfoWindow(
        infoWindow: AgmInfoWindow,
        agmMarker: AgmMarker,
        marker: CustomerMarker
    ): void {
        try {
            this.closeInfoWindow();
            this.viewmodel.previousInfoWindow = infoWindow;
            this.viewmodel.infoMarker = marker;
            this.viewmodel.zone.runOutsideAngular(() => {
                infoWindow.hostMarker = agmMarker;
                void infoWindow.open();
            });
        } catch (e) {
            this.viewmodel.snackbar.showError(e);
        }
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(): void {
        this.viewmodel.screenHeight = window.innerHeight;
    }

    @HostListener('window:beforeunload', ['$event'])
    displayConfirmationOnUnloadIfDirty() {
        if (!this.viewmodel.routingMode || (this.viewmodel.routingMode && this.viewmodel.routeTouched)) {
            return undefined;
        };
        return false;
    }

    filtersIntialized(): void {
        this.arefiltersIntialized = true;
    }
}
