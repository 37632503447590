import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericResponseDto, SystemInformationDto, SystemInformationKeys } from "shield.shared";
import { SystemInformation } from "src/app/entity-models/system-information.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { SystemInformationConverterService } from "../converter-services/system-information-converter.service";

@Injectable()
export class SystemInformationOnlineService {

    constructor(private http: HttpClient){}

    async getByKey(key: SystemInformationKeys): Promise<GenericResponseDto<SystemInformation>> {

        const result = await this.http
            .get<GenericResponseDto<SystemInformationDto>>(`/api/system-information/by-key/${key}`).toPromise();

        return ConverterHelper.dtoToEntity<SystemInformationDto, SystemInformation>(result,
            (r) => SystemInformationConverterService.systemInformationDtoToSystemInformation(r));
    }
}
