import {
    Component, ComponentRef, HostBinding, OnDestroy,
    OnInit
} from "@angular/core";
import {
    ActivatedRoute, ChildActivationEnd, NavigationEnd,
    Router
} from "@angular/router";
import { Helper } from "../helpers/helper";
import { OverlayService } from "../services/overlay.service";
import { SwisherOverlayRef } from "../overlay/swisher-overlay-ref";
import { ConfirmationDialogViewmodel } from "../dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { ConfirmationDialogComponent } from "../dialogs/confirmation-dialog/confirmation-dialog.component";
import { Customer } from "../entity-models/customer.entity";
import { buffer, filter, map, take } from "rxjs/operators";
import { Subscription } from "rxjs";
import { PleaseWaitService } from "../services/please-wait.service";
import { AppStateService } from "../services/app-state.service";
import { AccountTabs } from "./account-enums/account-tabs";
import { CustomerStateService } from "./account-services/customer-state.service";
import { MY_DATE_FORMATS } from "../shared/constants/date-formats";
import { Employee } from "../entity-models/employee.entity";
import { CustomerGenericTypes } from "../enums/customer-generic-types";
import { WholesaleCallApplicationService } from "./call-master/wholesale-call/wholesale-services/wholesale-call-application.service";
import { StepCamComponent } from "./call-master/step-cam/step-cam.component";
import { Call, CallService } from "./call-master/call-services/call.service";
import { AccountOwnershipHierarchyLevel, CallTypes, EmployeeRoleType, invalidOwnerCodes } from "shield.shared";
import { SnackbarService } from "../services/snackbar.service";
import { CustomerDelineationService } from "../services/delineation-services/customer-delineation.service";
import { CustomerConverterService } from "../services/converter-services/customer-converter.service";
import { PingService } from "../services/ping.service";
import { CallMasterComponent } from "./call-master/call-master.component";
import { EmployeeDelineationService } from "../services/delineation-services/employee-delineation.service";
import { AccountOwnershipDelineationService } from "../services/delineation-services/account-ownership-delineation.service";
import { CallDelineationService } from "../services/delineation-services/call-delineation.service";
import { StepperCallApplicationService } from "./call-master/stepper-call/stepper-call-services/stepper-call-application.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SyncService } from "../services/sync.service";
import { Px3DelineationService } from "../services/delineation-services/px3-delineation.service";

@UntilDestroy()
@Component({
    selector: "app-accounts",
    templateUrl: "./accounts.component.html",
    styleUrls: ["./accounts.component.scss"]
})

export class AccountsComponent implements OnInit {
    @HostBinding("class") class = "worksheet-area d-flex flex-column flex-grow-1";
    //Public vars
    customerAddress = "";
    isCallInProgress = false;
    isCallVisible = false;
    tabIndexCall = AccountTabs.call;
    tabIndexProfile = AccountTabs.profile;
    tabIndexVolume = AccountTabs.volume;
    tabIndexDashboard = AccountTabs.dashboard;
    tabIndexProducts = AccountTabs.products;
    tabIndex = this.tabIndexProfile;
    subscriptionToRouterEvents: Subscription;
    subscriptionToChildRouteParams: Subscription;
    subscriptionToChildrenParams: Subscription;
    customerId: string;
    currentEmployee: Employee;
    employeeSubscription: Subscription;
    paramSubscription: Subscription;
    dateFormat = MY_DATE_FORMATS.display.customDateInput;
    type: CustomerGenericTypes = null;
    retailType = CustomerGenericTypes.retail;
    wholesaleType = CustomerGenericTypes.wholesale;
    chainHqType = CustomerGenericTypes.chainHeadquarter;
    isMsa = false;

    employeeRoleType: EmployeeRoleType;
    routeId: string;

    px3Rank: string|undefined;

    private modalRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    callMasterSubscription: Subscription;
    callMasterBlockSubscription: Subscription;

    public constructor(
        private route: ActivatedRoute,
        private router: Router,
        private overlayService: OverlayService,
        public customerDelineationService: CustomerDelineationService,
        public customerStateService: CustomerStateService,
        public stepperCallApplicationService: StepperCallApplicationService,
        public wholesaleCallApplicationService: WholesaleCallApplicationService,
        public callService: CallService,
        private appStateService: AppStateService,
        private pleaseWaitService: PleaseWaitService,
        private snackbarService: SnackbarService,
        private pingService: PingService,
        private employeeDelineationService: EmployeeDelineationService,
        private accountOwnershipDelineationService: AccountOwnershipDelineationService,
        private callDelineationService: CallDelineationService,
        private px3RankService: Px3DelineationService,
        private syncService: SyncService,
    ) { }

    ngOnInit(): void {
        this.customerId = null;
        this.customerStateService.customer = null;
        this.callService.startingCall = false;
        const routeEndEvent$ = this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd)
        );

        if (!this.paramSubscription || this.paramSubscription.closed) {
            this.paramSubscription = this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
                    if (params.routeId) {
                        this.routeId = params.routeId;
                    }
                }
            );
        }

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.employeeSubscription = this.appStateService.currentEmployee.pipe(untilDestroyed(this)).subscribe(
                (employee) => {
                    if (employee && this.currentEmployee?.id !== employee.id) {
                        this.currentEmployee = employee;
                        this.callLookupGate();
                    }
                }
            );
        }

        if (
            !this.subscriptionToRouterEvents ||
            this.subscriptionToRouterEvents.closed
        ) {
            this.subscriptionToRouterEvents = this.router.events
                .pipe(
                    filter(
                        (e) =>
                            e instanceof ChildActivationEnd &&
                            e.snapshot.component === this.route.component
                    ),
                    buffer(routeEndEvent$),
                    map(
                        ([ev]) =>
                            (ev as ChildActivationEnd).snapshot.firstChild.data
                    ),
                    untilDestroyed(this)
                )
                .subscribe(() => {
                    this.subscriptionToChildRouteParams = this.route.children[0].params.pipe(untilDestroyed(this)).subscribe(
                        (params) => {
                            this.tabIndex = this.getTabIndex();
                            this.customerId = params.CustomerId as string;
                            if (this.isCallVisible) {
                                this.callLookupGate();
                            }
                        }
                    );
                });
        }

        if (
            !this.subscriptionToChildrenParams ||
            this.subscriptionToChildrenParams.closed
        ) {
            this.subscriptionToChildrenParams = this.route.children[0].params.pipe(untilDestroyed(this)).subscribe(
                async (params) => {
                    this.tabIndex = this.getTabIndex();

                    this.customerId = params.CustomerId as string;
                    const customer = await this.customerDelineationService.getById(this.customerId);
                    this.px3Rank = (await this.px3RankService.getById(customer.values.px3RankId))?.rank
                    const callableStatusResponse = await this.customerDelineationService.getCustomerCallableStatus(this.customerId);
                    this.isCallVisible = callableStatusResponse.values;
                    this.callLookupGate();
                }
            );
        }

        if (!this.customerStateService.customer) {
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                this.customerStateService.observableCustomer
            );
        }
    }

    //Events
    openModal(): void {
        if (this.modalRef) {
            this.modalRef.close();
        }

        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message = "Are you sure you want to cancel the call?";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = null;
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => this.cancelCall();

        this.modalRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.modalRef.afterClosed$.subscribe(() => {
            this.modalRef = undefined;
        });

    }

    async onCallAction(cancelCall: boolean): Promise<void> {
        if (!this.syncService.isInitallySynced) {
            this.snackbarService.showWarning("An initial sync must be completed before placing a call.");
            return;
        }
        await this.currentCallCheck();
        if (!this.isCallInProgress) { // Start a new call
            this.callService.startingCall = true;
            this.isCallInProgress = true;
            void this.onStartCall();
        } else if (cancelCall) { // Cancel existing call
            this.openModal();
        } else { // Resume Call
            void this.router.navigate(["/accounts/" + this.customerStateService.customer.id + "/call"]);
        }
    }

    onStartCall(): void {
        const navRoute =
            "/accounts/" + this.customerStateService.customer.id + "/call";
        if (this.router.url !== navRoute) {
            this.callService.call = null;

            void this.router.navigate([navRoute]);
        }
    }

    //Public methods
    async cancelCall(preventNavigation?: boolean): Promise<void> {
        this.modalRef.close();
        await this.callService.deleteCall();
        this.isCallInProgress = false;
        switch (this.callService.call.callType) {
            case CallTypes.retail:
            case CallTypes.rmWholesale:
                this.stepperCallApplicationService.resetCall();
                break;
            case CallTypes.wholesale:
                this.wholesaleCallApplicationService.resetCall();
                break;
            default:
                break;
        }

        if (!preventNavigation) {
            void this.router.navigate([
                "/accounts",
                this.customerStateService.customer.id,
                "profile"
            ]);
        }
    }

    openCameraModal(): void {
        this.overlayService.open(StepCamComponent, null);
    }

    getCustomerAddress(): void {
        this.customerAddress = CustomerConverterService.getCustomerFlatBusinessAddress(
            this.customerStateService.customer
        );
    }

    async getChain(customer: Customer): Promise<Customer> {
        if (customer.ownerCode) {
            let chain = "";
            let chainRep = "";

            if (customer.ownerCode == null || isNaN(Number(customer.ownerCode)) || invalidOwnerCodes.includes(customer.ownerCode.trim())) {
                return customer;
            }

            const findOwnershipResponse = await this.accountOwnershipDelineationService.getByOwnerCode(customer.ownerCode);
            if (!findOwnershipResponse) { return customer; }
            if (findOwnershipResponse.values?.length) {
                const findOwnership = findOwnershipResponse.values.find(v => v.hierarchyLevel.id == AccountOwnershipHierarchyLevel.Owner);
                if (!findOwnership) { return customer };

                chain = findOwnership.name ?? "";
                if (findOwnership.assignedEmployeeId) {
                    const employeeResponse = await this.employeeDelineationService.getById(findOwnership.assignedEmployeeId);
                    if (employeeResponse && employeeResponse.values) {
                        chainRep = employeeResponse.values.fullName;
                    }
                }
            }

            customer.chain = chain;
            customer.chainRep = chainRep;
        }

        return customer;
    }

    async setCustomer(customer: Customer): Promise<void> {
        if (customer) {
            if (
                (this.customerDelineationService &&
                    !this.customerStateService.customer?.id) ||
                this.customerStateService?.customer?.id !== customer.id
            ) {
                if (!!customer.zrt) {
                    const managerZrt = customer.territory
                        ? `${customer.zone}${customer.region}0`
                        : customer.region
                            ? `${customer.zone}00`
                            : null;

                    const employeeResponse = await this.employeeDelineationService.getByZrt(customer.zrt);
                    const rep = employeeResponse?.values ? employeeResponse?.values[0] : undefined;

                    customer.rep = rep
                        ? `${rep.zrt ? rep.zrt + " - ": ""}${rep.fullName}`
                        : "";

                    const managerResponse = managerZrt
                        ? await this.employeeDelineationService.getByZrt(managerZrt)
                        : null;


                    const manager = managerResponse?.values?.length ? managerResponse?.values[0] : null;
                    customer.manager = manager
                        ? `${manager.zrt ? manager.zrt + " - " : ""}${manager.fullName}`
                        : "";
                }

                customer = await this.getChain(customer);
                this.isMsa = customer.isMsa;
                this.isCallInProgress = false;
                this.customerStateService.ignoreCustomerSubscription = true;
                this.stepperCallApplicationService.resetCall();
                this.callService.routeId = this.routeId;
                this.customerStateService.ignoreCustomerSubscription = false;
                this.customerStateService.customer = customer;
                this.getCustomerAddress();
            }
        }
    }

    getTabIndex(): number {
        let rtn = -1;

        if (this.router.url.indexOf("call") !== -1) {
            rtn = AccountTabs.call;
        } else if (this.router.url.indexOf("volume") !== -1) {
            rtn = AccountTabs.volume;
        } else if (this.router.url.indexOf("dashboard") !== -1) {
            rtn = AccountTabs.dashboard;
        } else if (this.router.url.indexOf("products") !== -1) {
            rtn = AccountTabs.products;
        } else if (this.router.url.indexOf("/profile") !== -1) {
            rtn = AccountTabs.profile;
        }

        return rtn;
    }

    //Private Methods
    async afterRoutChangeSetup(): Promise<void> {
        const offlineResponse = await this.customerDelineationService.getById(this.customerId, true);
        if (!offlineResponse) { return; }

        let customer = offlineResponse.values;

        if (!customer) {
            const onlineResponse = await this.customerDelineationService.getById(this.customerId);
            if (!onlineResponse) return;

            customer = onlineResponse.values;

            if (!customer) {
                this.pleaseWaitService.killPleaseWait();
                this.snackbarService.showWarning("Customer not found for Id: " + this.customerId);
                return;
            }
        }

        this.type = Helper.getCustomerGenericType(customer);

        await this.setCustomer(customer);
        await this.currentCallCheck();
    }

    private async currentCallCheck() {
        if (this.currentEmployee &&
            this.currentEmployee.user) {

            const localCallResponse = await this.callDelineationService.getLocalCallsByCustomerId(this.customerId);
            let currentCall: Call = undefined;
            if (localCallResponse?.values?.length) {
                currentCall = localCallResponse.values[0];
            }

            if (!!currentCall) {
                this.callService.startingCall = false;
            }

            this.isCallInProgress = (this.callService.startingCall || !!currentCall) && !this.callService.blockingCall;

            if (currentCall) {
                this.callService.call = currentCall;
            }
        }
    }

    callLookupGate(): void {
        if (
            this.currentEmployee &&
            this.currentEmployee.user &&
            this.customerId
        ) {
            void this.afterRoutChangeSetup();
        }
    }

    suscribeToEmitter(componentRef: ComponentRef<CallMasterComponent>): void {

        if (!(componentRef instanceof CallMasterComponent)) {
            return;
        }
        const child: CallMasterComponent = componentRef;
        if (!this.callMasterSubscription || this.callMasterSubscription.closed) {
            this.callMasterSubscription = child.cancelCallEmitter.pipe(untilDestroyed(this)).subscribe(async (callMaster) => {

                callMaster.cancelOtherOpenCall(this.callService, this.currentEmployee);
            });
        }
    }

    subscribeToBlockCallEmitter(componentRef: ComponentRef<CallMasterComponent>): void {
        if (!(componentRef instanceof CallMasterComponent)) {
            return;
        }
        const child: CallMasterComponent = componentRef;
        if (!this.callMasterBlockSubscription || this.callMasterBlockSubscription.closed) {
            this.callMasterBlockSubscription = child.blockCallEmitter.pipe(untilDestroyed(this)).subscribe(() => {
                    this.isCallInProgress = false;
                    this.callService.startingCall = false;
                    this.callService.blockingCall = true;
            });
        }
    }
}
