import { CallOrderProduct } from "src/app/entity-models/call-order-product.entity";
import { CallReceiptLicense } from "src/app/entity-models/call-receipt-license.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { SubsidiaryTypes } from "src/app/enums/subsidiary-types";
import { CallOrderProductViewModel } from "../../../call-viewmodels/call-order-product.viewmodel";
import { OrderViewmodel } from "./order.viewmodel";

export class WholesaleReceiptViewmodel {
    wholesaler: Customer;
    customerCode: string;
    orders: OrderViewmodel[];
    subsidiary: SubsidiaryTypes;
    receiptNumber: string;
    retailOptLicense: CallReceiptLicense;
    retailVaporLicense: CallReceiptLicense;
    wholesalerRetailOptLicense: CallReceiptLicense;
}
