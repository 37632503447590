import { Component, HostBinding, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { RetailCallStepperComponent } from 'src/app/accounts/call-master/retail-call/retail-call-stepper/retail-call-stepper.component';
import { ProjectStep } from 'src/app/enums/project-step';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';
import { ProjectDelineationService } from 'src/app/services/delineation-services/project-delineation.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { ProjectStateService } from 'src/app/services/project-state-service';
import { NewProjectModalComponent } from '../../new-project-modal/new-project-modal.component';
import { NewProjectViewmodel } from '../../new-project-modal/new-project.viewmodel';
import { ProjectApplicationService } from '../project-services/project-application.service';

@Component({
    selector: 'app-project-configuration',
    templateUrl: './project-configuration.component.html',
    styleUrls: ['./project-configuration.component.scss']
})
export class ProjectConfigurationComponent {

    @HostBinding("class") class = "d-flex flex-column flex-grow-1";
    @ViewChild("stepper") stepper: RetailCallStepperComponent;

    subscriptionSelectedIndex: Subscription;
    subscriptionGottaBail: Subscription;
    gottaBail = false;
    newProjectOverlayRef: SwisherOverlayRef<
        NewProjectViewmodel,
        NewProjectModalComponent
    >;

    constructor(private projectStateService: ProjectStateService,
        private projectApplicationService: ProjectApplicationService,
        private router: Router,
        private overlayService: OverlayService,
        private projectDelineationService: ProjectDelineationService) { }

    async canDeactivate(): Promise<Subject<boolean> | boolean> {
        if (this.projectApplicationService.isSaving || this.gottaBail) {
            return true;
        }

        const currentNavigation = this.router.getCurrentNavigation();
        await this.projectApplicationService.cancel(currentNavigation.finalUrl.toString());

        return false;
    }

    ngOnInit(): void {

        if (!this.subscriptionSelectedIndex || this.subscriptionSelectedIndex.closed) {
            this.subscriptionSelectedIndex = this.projectApplicationService.observableSelectedIndex.subscribe((index) => {
                if (this.stepper) {
                    if (index >= ProjectStep.definition && index <= ProjectStep.summary) {
                        this.stepper.selectedIndex = index;
                    }
                }
            });
        }

        if (!this.subscriptionGottaBail || this.subscriptionGottaBail.closed) {
            this.subscriptionGottaBail = this.projectApplicationService.observableCanDeactivateProject.subscribe((value) => {
                this.gottaBail = value;
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptionSelectedIndex && !this.subscriptionSelectedIndex.closed) {
            this.subscriptionSelectedIndex.unsubscribe();
        }
        if (this.subscriptionGottaBail && !this.subscriptionGottaBail.closed) {
            this.subscriptionGottaBail.unsubscribe();
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    onClose(event?: BeforeUnloadEvent): void | string {
        if(!this.projectStateService.lastSavedProject
            || this.projectApplicationService.isProjectEqual(this.projectStateService.lastSavedProject)
            || this.projectApplicationService.isCanceling
            || this.projectApplicationService.isSaving) {
            return undefined;
        } else {
            event.preventDefault();
            event.returnValue = "Detected unsaved changes.";
        }
    }
}
