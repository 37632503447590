import { Injectable } from "@angular/core";
import { WholesalerGroupMember } from "src/app/entity-models/wholesaler-group-member.entity";
import { DatabaseService } from "../database.service";

@Injectable()
export class WholesalerGroupMemberOfflineService {

    constructor(private dbService: DatabaseService) { }

    async getByWholesalerId(id: string): Promise<WholesalerGroupMember> {

        return await this.dbService.wholesalerGroupMembers
            .where("wholesalerId")
            .equals(id)
            .first();
    }


    async getByWholesalerIds(id: string[]): Promise<WholesalerGroupMember[]> {

        return [...new Set(await this.dbService.wholesalerGroupMembers
            .where("wholesalerId")
            .anyOf(id)
            .toArray())];
    }
}
