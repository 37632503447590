<div #myFilter>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100" [formGroup]="viewmodel.callDateForm">
            <mat-label>Call on or After</mat-label>
            <input #callStartDateInput matInput
                formControlName="callStartDate"
                [max]="viewmodel.startMaxDate"
                [matDatepicker]="afterPicker"
                (blur)="onAddStartDateRefiner($event)"
                (dateChange)="onAddStartDateRefiner()" />
            <mat-datepicker touchUi #afterPicker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="afterPicker"></mat-datepicker-toggle>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100" [formGroup]="viewmodel.callDateForm">
            <mat-label>Call on or Before</mat-label>
            <input #callEndDateInput matInput
                formControlName="callEndDate"
                [min]="viewmodel.endMinDate"
                [max]="viewmodel.endMaxDate"
                [matDatepicker]="beforePicker"
                (blur)="onAddEndDateRefiner($event)"
                (dateChange)="onAddEndDateRefiner()" />
            <mat-datepicker touchUi #beforePicker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="beforePicker"></mat-datepicker-toggle>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Availability</mat-label>
            <mat-select #availabilitySelect
                [value]="viewmodel.selectedAvailability"
                (openedChange)="onOpenedAvailabilityChange()"
                multiple>
                <mat-option *ngFor="let option of viewmodel.availability" [value]="option"
                    (onSelectionChange)="onSelectionAvailabilityChange($event)">

                    {{ option }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
