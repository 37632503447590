import { RefinerLocation, RouteListFilterDto, valueSeparator } from "shield.shared";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { BaseFilterMapService } from "./base-filter-map.service";
import { ZrtFilterMapService } from "./zrt-filter-map.service";

export class RouteListFilterMapService extends BaseFilterMapService {
    static mapFilterData(refiners: Refiner[]): RouteListFilterDto {

        if (!refiners) return;
        const filterDto = new RouteListFilterDto();
        const zrtFilterDto = ZrtFilterMapService.mapFilterData(refiners);

        if (Object.keys(zrtFilterDto).length) {
            filterDto.zrtFilterDto = zrtFilterDto;
        }

        const timeOffset = new Date().getTimezoneOffset();

        for (const refiner of refiners) {
            if (!refiner.dataValue) {
                refiner.dataValue = refiner.value;
            }

            switch (refiner.location) {
                case RefinerLocation.routeName:
                    filterDto.routeName = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.routeDescription:
                    filterDto.routeDescription = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.routeOnOrAfterDate:
                    const routeOnOrAfterDate = new Date(refiner.dataValue)
                    routeOnOrAfterDate.setHours(0,0 - timeOffset,0,0);
                    filterDto.routeOnOrAfterDate = routeOnOrAfterDate.toISOString();
                    break;
                case RefinerLocation.routeOnOrBeforeDate:
                    const routeOnOrBeforeDate = new Date(refiner.dataValue)
                    routeOnOrBeforeDate.setHours(23,59 - timeOffset,59,0);
                    filterDto.routeOnOrBeforeDate = routeOnOrBeforeDate.toISOString();
                    break;
                default:
                    break;
            }
        }
        return filterDto;
    }
}
