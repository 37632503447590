import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRequestDto, GenericResponseDto, newSequentialId, StateLicenseDto } from "shield.shared";
import { StateLicense } from "src/app/entity-models/state-license.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { StateLicenseConverterService } from "../converter-services/state-license-converter.service";

@Injectable()
export class StateLicenseOnlineService {

    constructor(private http: HttpClient) { }

    async getByShortCode(shortCode: string): Promise<GenericResponseDto<StateLicense[]>> {


        const response = await this.http
            .get<GenericResponseDto<StateLicenseDto[]>>(`/api/state-licenses/get-by-short-code/${shortCode}`).toPromise();

        return ConverterHelper.dtoToEntityArray<StateLicenseDto, StateLicense>(response,
            (r) => StateLicenseConverterService.licenseDtoToLicense(r));
    }
}
