// eslint-disable-next-line no-shadow
export enum RetailStepperStep {
    before = 0,
    activitySurveys = 1,
    inDistribution = 2,
    salesGratis = 3,
    exchange = 4,
    receipts = 5,
    after = 6,
    closingNotes = 7
}
