import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericResponseDto, StateDto } from "shield.shared";
import { State } from "src/app/entity-models/state.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { StateConverter } from "../converter-services/state-converter";

@Injectable()
export class StateOnlineService {

    constructor(private http: HttpClient){}

    async getByShortCode(code: string): Promise<GenericResponseDto<State>> {

        const response = await this.http
            .get<GenericResponseDto<StateDto>>(`/api/states/get-by-short-code/${code}`)
            .toPromise();

        return ConverterHelper.dtoToEntity<StateDto, State>(response,
            (r) => StateConverter.stateDtoToState(r));
    }
}
