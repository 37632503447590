import { Component, Input } from "@angular/core";
import { SortDirection } from "shield.shared";
import { SortBy } from "src/app/accounts/account.viewmodels/sort-by.viewmodel";
import { LastOrderViewModel } from "./last-order.viewmodel";

@Component({
    selector: "app-last-order",
    templateUrl: "./last-order.component.html",
    styleUrls: ["./last-order.component.scss"]
})
export class LastOrderComponent {
    sortBy: SortBy;
    private _lastOrderLineItems: LastOrderViewModel[];

    @Input()
    get lastOrderLineItems(): LastOrderViewModel[] {
        return this._lastOrderLineItems;
    }
    set lastOrderLineItems(value: LastOrderViewModel[]) {
        this._lastOrderLineItems = value;
    }

    onSortBy(event: MouseEvent, desc?: boolean): void {
        if (event) {
            const currentTarget = event.currentTarget as HTMLElement;
            if (currentTarget && currentTarget.innerHTML) {
                const target: string = currentTarget.id;

                if (
                    this.sortBy &&
                    this.sortBy.name === target
                ) {
                    if (
                        this.sortBy.direction ===
                            SortDirection.ascending
                    ) {
                        this.sortBy.direction =
                            SortDirection.descending;
                        this.sortBy.icon = "south";
                    } else {
                        this.sortBy.direction =
                            SortDirection.ascending;
                        this.sortBy.icon = "north";
                    }
                } else {
                    this.sortBy = new SortBy();
                    this.sortBy.name = target;
                    if (desc) {
                        this.sortBy.direction =
                        SortDirection.descending;
                        this.sortBy.icon = "south";
                    }
                    else {
                        this.sortBy.direction =
                        SortDirection.ascending;
                        this.sortBy.icon = "north";
                    }
                }

                this.sort();
            }
        }
    }
    sort(): void {
        if (
            this.sortBy?.name &&
            this.sortBy.direction !== SortDirection.none
        ) {
            const order =
                this.sortBy.direction === SortDirection.descending ? -1 : 1;

            switch (this.sortBy.name) {
                case "productName":
                    this.lastOrderLineItems.sort(
                        (a, b) =>
                            (a.productName ?? "").localeCompare(b.productName ?? "", "en", {
                                sensitivity: "base"
                            }) * order
                    );
                    break;
                case "type":
                    this.lastOrderLineItems.sort(
                        (a, b) =>
                            (a.type ?? "").localeCompare(b.type ?? "", "en", {
                                sensitivity: "base"
                            }) * order
                    );
                    break;
                case "wholesalerName":
                    this.lastOrderLineItems.sort((a, b) =>
                        (a.wholesalerName && b.wholesalerName)
                            ? (a.wholesalerName).localeCompare(
                                  b.wholesalerName,
                                  "en",
                                  {
                                      sensitivity: "base"
                                  }
                              ) * order
                            : a.wholesalerName
                                ? -1
                            : b.wholesalerName
                                ? 1
                            : 0
                    );
                case "quantity":
                    this.lastOrderLineItems.sort(
                        (a, b) =>
                            (a.quantity ?? "").localeCompare(b.quantity ?? "", "en", {
                                sensitivity: "base"
                            }) * order
                    );
                    break;
                default:
                    break;
            }
        }
    }
}
