import { Injectable } from "@angular/core";
import { GenericResponseDto } from "shield.shared";
import { ReceiptSettings } from "src/app/entity-models/receipt-settings.entity";
import { DatabaseService } from "../database.service";
import { ReceiptSettingsOfflineService } from "../offline-services/receipt-settings-offline.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class ReceiptSettingsDelineationService extends DelineationContext<ReceiptSettings, string> {

    constructor(private receiptSettingsOfflineService: ReceiptSettingsOfflineService
        , snackbarService: SnackbarService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService){
        super(dbService, datasourceDelineationService, snackbarService)
    }

    async getReceiptSettingsByEmployeeId(id: string): Promise<GenericResponseDto<ReceiptSettings>> {

        const offline = (key: string) => {
            return this.receiptSettingsOfflineService.getReceiptSettingsByEmployeeId(key);
        }
        const online = (key: string) => {
            return this.receiptSettingsOfflineService.getReceiptSettingsByEmployeeId(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, ReceiptSettings>(id, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }
}
