import { ProductDistributionEntryDto, ProductDto, ProductUpcDto } from "shield.shared";
import { ProductDistributionEntry } from "src/app/entity-models/product-distribution-entry.entity";
import { Product } from "src/app/entity-models/product.entity";

export class ProductConverterService {
    static productToProductDto(product?: Product): ProductDto | undefined {
        if(!product) return;

        return {
            ...product,
            upcs: (product.upcs ?? []).map((u) => ({ ...u })),
        }
    }

    static productDtoToProduct(dto: ProductDto): Product {
        const returnableUpc = dto.upcs?.find((u) => u.returnableUnit);
        const lowestSellableUpc = dto.upcs?.find((u) => u.lowestSellableUnit);
        const upcNumbers = dto.upcs?.map((u) => u.upc);

        return {
            ...dto,
            upcs: (dto.upcs ?? []).map((u) => ({ ...u })),
            upcNumbers,
            returnableUpc,
            lowestSellableUpc,
            isDropDownSelectable: dto.shield && !dto.isDeactivated && !dto.isDeleted ? 1 : 0,
        };
    }

    static productDistributionEntryDtoToProductDistributionEntry(dto: ProductDistributionEntryDto): ProductDistributionEntry {
        const rtn = new ProductDistributionEntry();

        rtn.id = dto.id;
        rtn.itemNumber = dto.itemNumber;
        rtn.description = dto.description;
        rtn.inDistribution = dto.inDistribution;
        rtn.intro = dto.intro;
        rtn.oos = dto.oos;
        rtn.cos = dto.cos;
        rtn.distPercent = dto.distPercent;

        return rtn;
    }
}
