import { Product } from "src/app/entity-models/product.entity";
import { FilterService } from "src/app/services/filter.service";
import { Subscription } from "rxjs";
import { GenericLookup, TransactionLineItemType, TransactionLineItemTypeLookup } from "shield.shared";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";


export class TransactionsViewmodel {
    //public vars
    filterService: FilterService;
    productDelineationService: ProductDelineationService;

    allType = new GenericLookup<TransactionLineItemType>();
    isAllTypePresent: boolean = true;
    isProductsSearchStale = false;
    receiptInput: string;
    products = new Array<Product>();
    productSubscription: Subscription;
    selectedProducts = new Array<Product>();
    selectedProductsHold = new Array<Product>();
    selectedStatus = "All";
    selectedTypes = new Array<GenericLookup<TransactionLineItemType>>();
    types = new Array<GenericLookup<TransactionLineItemType>>();
    uinInput: string;

    productsDropdownSettings = {
        singleSelection: false,
        text: "Products",
        unSelectAllText: "Deselect All",
        enableCheckAll: false,
        enableFilterSelectAll: true,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 2,
        autoPosition: false,
        labelKey: "description",
        searchBy: ["description"],
        classes: "multi-select-container c-btn"
    }

    constructor(filterService: FilterService, productDelineationService: ProductDelineationService) {
        this.filterService = filterService;
        this.productDelineationService = productDelineationService;

        this.initializeTypeDropDown();
    }

    initializeTypeDropDown(): void {
        this.allType.description = "All";
        this.selectedTypes.push(this.allType);
        this.types.push(this.allType);

        for(const type of TransactionLineItemTypeLookup) {
            this.types.push(type)
        }
    }
}
