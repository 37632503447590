import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";
import { Employee } from "src/app/entity-models/employee.entity";
import { ProjectAssignmentsSummaryViewModel } from "../project-assignments-summary.viewmodel";

export class ProjectAssignmentDialogViewModel extends BasicDialogViewModel {
    employees: Employee[];
    employeesFiltered: Employee[];
    selectedEmployees = new Array<Employee>();
    searchText = "";
    storeCounts: ProjectAssignmentsSummaryViewModel[];

    constructor(unassign?: boolean) {
        super();
        this.headerLeftText = unassign ? "Unassign From" : "Assign To";
        this.buttonLeftText = "Close";
        this.buttonLeftDisabledFunction = () => false;
        this.buttonRightDisabledFunction = () => false;
        this.buttonRightText = unassign ? "Unassign" : "Assign";
    }

    filter(): void {
        if (this.searchText) {
            this.employeesFiltered = this.employees.filter(
                (e) =>
                    e.fullName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()) ||
                    e.zrt.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase())
            )
        } else {
            this.employeesFiltered = this.employees;
        }
    }

    getCount(employeeId: string): number {
        return this.storeCounts.find((sc) => sc.dataValue === employeeId).assignedCount;
    }

    markSelected(employee?: Employee): void {
        if (employee) {
            const index = this.selectedEmployees.indexOf(employee);
            if (index === -1) {
                this.selectedEmployees.push(employee);
            } else if (index > -1) {
                this.selectedEmployees.splice(index, 1);
            }
        } else {
            if (this.selectedEmployees.length !== this.employeesFiltered.length) {
                this.selectedEmployees = new Array<Employee>();
                for (const employee of this.employeesFiltered) {
                    this.selectedEmployees.push(employee);
                }
            } else {
                this.selectedEmployees = new Array<Employee>();
            }
        }
    }

    someChecked(): boolean {
        return !this.allChecked() && this.employeesFiltered.some((v) => this.selectedEmployees.map(e => e.id).includes(v.id));
    }

    allChecked(): boolean {
        return !this.employeesFiltered.some((v) => !this.selectedEmployees.map(e => e.id).includes(v.id))
    }
}
