export class GenericLookup<T> {
    id: T;
    name: string;
    description: string;

    static getFromLookup<T>(lookup: GenericLookup<T>[], id: T): GenericLookup<T> {
        const rtn = new GenericLookup<T>();
        const found = lookup.find(v => v.id === id);
    
        if (found) {
            rtn.id = found.id;
            rtn.name = found.name;
            rtn.description = found.description;
        }
    
        return rtn;
    }
}