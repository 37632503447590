import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatRadioChange } from "@angular/material/radio";
import { BehaviorSubject, Subscription } from "rxjs";
import { newSequentialId, SortDirection } from "shield.shared";
import { ProjectProduct } from "src/app/entity-models/project-product.entity";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { ProjectStateService } from "src/app/services/project-state-service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { ProjectApplicationService } from "../../project-services/project-application.service";
import { ProjectProductSelectViewmodel } from "./project-product-select.viewmodel";
import { WholesalerViewmodel } from "../../../../shared/viewmodels/wholesaler.viewmodel";
import { ProductViewmodel } from "src/app/accounts/call-master/stepper-call/distribution-grid/product.viewmodel";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ProjectProductOrderDatesComponent } from "./project-product-order-dates/project-product-order-dates.component";
import { ProjectProductOrderDatesViewmodel } from "./project-product-order-dates/project-product-order-dates.viewmodel";
import { OverlayService } from "src/app/services/overlay.service";
import { FormBuilder } from "@angular/forms";
import { ProjectOrderDate } from "src/app/entity-models/project-order-date.entity";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ActivatedRoute } from "@angular/router";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";

export class ProjectProductSortDto {
    name: keyof ProjectProductSelectViewmodel;
    direction: SortDirection;
}

export class ProjectProductsViewmodel {

    snackbarService: SnackbarService;
    pleaseWaitService: PleaseWaitService;
    projectStateService: ProjectStateService;
    customerDelineationService: CustomerDelineationService;
    projectApplicationService: ProjectApplicationService;
    projectCustomerSubscription: Subscription;
    assignedProducts = new Array<ProjectProductSelectViewmodel>();
    assignedFilteredProductsSortDto = new ProjectProductSortDto();
    assignedFilteredProducts = new Array<ProjectProductSelectViewmodel>();
    unAssignedProducts = new Array<ProjectProductSelectViewmodel>();
    unAssignedFilteredProductsSortDto = new ProjectProductSortDto();
    unAssignedFilteredProducts = new Array<ProjectProductSelectViewmodel>();
    allAssignedchecked: boolean;
    allUnassignedchecked: boolean;
    unAssignedSearchText: string;
    assignedSearchText: string;
    arrowRightimageLocation = "/assets/img/addArrow.png";
    arrowLeftimageLocation = "/assets/img/remove-arrow.png";
    categories = new Array<string | number>();
    selectedCategory: number | string;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    isWholesalerbased = false;
    wholesalerBasedText = "Based on Wholesaler";
    nonWholesalerBasedText = "Not Based on Wholesaler";
    wholesalers = new Array<WholesalerViewmodel>();
    isInitialized = false;
    selectedWholesaler: WholesalerViewmodel[];
    selectedWholesalerId: string;
    selectedWholesalerParamsSubscription: Subscription;
    shouldShowOrderDates = false;
    directions = SortDirection;
    activatedRoute: ActivatedRoute;
    productDelineationService: ProductDelineationService;

    private modalRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    //https://cuppalabs.github.io/components/multiselectDropdown/
    wholeSalerDropdownSettings = {
        singleSelection: true,
        text: "Select Wholesaler",
        enableCheckAll: false,
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        showCheckbox: false,
        labelKey: "displayValue",
        searchBy: ["displayValue"],
        classes: "multi-select-container c-btn pure-checkbox"
    }

    orderDatesOverlayRef: SwisherOverlayRef<
        ProjectProductOrderDatesViewmodel,
        ProjectProductOrderDatesComponent
    >;
    overlayService: OverlayService;

    constructor(snackbarService: SnackbarService,
        pleaseWaitService: PleaseWaitService,
        projectStateService: ProjectStateService,
        projectApplicationService: ProjectApplicationService,
        overlayService: OverlayService,
        private formBuilder: FormBuilder,
        customerDelineationService: CustomerDelineationService,
        activatedRoute: ActivatedRoute,
        productDelineationService: ProductDelineationService) {

        this.snackbarService = snackbarService;
        this.pleaseWaitService = pleaseWaitService;
        this.projectStateService = projectStateService;
        this.projectApplicationService = projectApplicationService;
        this.overlayService = overlayService;
        this.activatedRoute = activatedRoute;
        this.assignedFilteredProductsSortDto.name = "description";
        this.assignedFilteredProductsSortDto.direction = SortDirection.ascending;
        this.unAssignedFilteredProductsSortDto.name = "description";
        this.unAssignedFilteredProductsSortDto.direction = SortDirection.ascending;
        this.customerDelineationService = customerDelineationService;
        this.productDelineationService = productDelineationService;
    }

    openModal(): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        const messageTo = this.isWholesalerbased ? this.wholesalerBasedText : this.nonWholesalerBasedText;
        const messageFrom = this.isWholesalerbased ? this.nonWholesalerBasedText : this.wholesalerBasedText;
        data.message = `Are you sure you want to change from ${messageFrom} to ${messageTo}? This action will reset your assigned products list`;
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            this.isWholesalerbased = !this.isWholesalerbased;
            this.modalRef.close(data);
        }
        data.buttonRightText = "Yes, Continue";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.projectStateService.project.projectProducts = new Array<ProjectProduct>();
            this.modalRef.close(data);
        }

        this.modalRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.modalRef.afterClosed$.subscribe((response) => {
            if (response.data.isConfirmed) {
                this.selectedWholesaler = null;
                this.assignedProducts = new Array<ProjectProductSelectViewmodel>();
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
                setTimeout(() => {
                    this.buildProducts();
                }, 0);
            }
        })
    }

    basedOnWholesalerChange(): void {
        const isEmpty = !this.projectStateService.project.projectProducts?.length;

        if (!isEmpty) {
            this.openModal();
        } else {
            this.projectStateService.project.projectProducts = new Array<ProjectProduct>();
            this.selectedWholesaler = null;
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
            setTimeout(() => {
                this.buildProducts();
            }, 0);
        }
    }

    onWholesalerSelectChange(): void {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        this.buildProducts();
    }

    unsubscribe(): void {
        if (this.projectCustomerSubscription && !this.projectCustomerSubscription.closed) {
            this.projectCustomerSubscription.unsubscribe();
        }
        if (this.selectedWholesalerParamsSubscription && !this.selectedWholesalerParamsSubscription.closed) {
            this.selectedWholesalerParamsSubscription.unsubscribe();
        }
    }

    async initialize(shouldWait$: BehaviorSubject<boolean>): Promise<void> {
        this.shouldWait$ = shouldWait$;

        if (!this.isInitialized) {
            this.isInitialized = true;

            if (!this.selectedWholesalerParamsSubscription || this.selectedWholesalerParamsSubscription.closed) {
                this.selectedWholesalerParamsSubscription = this.activatedRoute.params.subscribe(
                    async (params) => {
                        this.selectedWholesalerId = params.wholesalerId as string;
                    }
                );
            }

            const isEmpty = !this.projectStateService.project.projectProducts?.length;
            const hasWholesalers = !!this.projectStateService.project.projectProducts?.find((pp) => !!pp.wholesalerId);
            const hasPreselected = !!this.selectedWholesalerId;

            if ((!isEmpty && hasWholesalers) || hasPreselected) {
                this.isWholesalerbased = true;
                if (!isEmpty) {
                    this.projectStateService.project.projectProducts = this.projectStateService.project.projectProducts.filter((pp) => !!pp.wholesalerId);
                }
            } else {
                this.isWholesalerbased = false;
                this.projectStateService.project.projectProducts = this.projectStateService.project.projectProducts.filter((pp) => !pp.wholesalerId);
            }

            await this.buildProducts();
        }
    }


    async buildProducts(): Promise<void> {
        if (!this.projectStateService.project) return;

        let products = new Array<ProductViewmodel>();

        let productMap = new Map<string, ProductViewmodel>();

        if (!this.isWholesalerbased
            || !this.wholesalers.find((w) => this.selectedWholesaler && this.selectedWholesaler.length > 0 && w.wholesaler.id === this.selectedWholesaler[0]?.wholesaler?.id)) {
            this.selectedWholesaler = null;
        }

        const allProducts = this.projectApplicationService.products;

        if (!this.categories || !this.categories.length) {
            const categories: (string | number)[] = [...new Set(allProducts.map((p) => p.division))];
            categories.sort((a, b) => { return a > b ? 1 : a < b ? -1 : 0 });
            categories.splice(0, 0, "All");
            this.categories = categories;
        }

        if (!this.selectedCategory) {
            this.selectedCategory = "All";
        }

        if (this.isWholesalerbased) {

            if (!this.wholesalers?.length) {

                const wholesalersResponse = await this.customerDelineationService.getWholesalersWithGroupProducts();
                if (!wholesalersResponse) {
                    return;
                }

                const wholesalers = wholesalersResponse.values;
                const wholesalersViewmodels = wholesalers.map((w) => new WholesalerViewmodel(w));
                wholesalersViewmodels.sort((a, b) => a.displayValue.localeCompare(b.displayValue));
                this.wholesalers = wholesalersViewmodels;
                if (this.selectedWholesalerId) {
                    this.selectedWholesaler = this.wholesalers.filter((w) => w.id === this.selectedWholesalerId);
                }
            }

            const products = new Array<ProductViewmodel>();

            const projectWholesalers = this.wholesalers.filter(v => this.projectStateService.project.projectProducts.map(p => p.wholesalerId).includes(v.id));
            for (const vm of projectWholesalers) {
                const response = await this.productDelineationService.getWholesalerProducts(vm.wholesaler);
                if (response && response.values) {
                    products.push(...response.values);
                }
            }
            productMap = new Map(products.map((p) => [p.id + "|" + (p.wholesalerId ?? ""), p]));

        } else if (!this.isWholesalerbased) {

            productMap = new Map(allProducts.filter((p) => !p.isDeactivated && !p.isDeleted).map((p) => [p.id, p as ProductViewmodel]));

            this.selectedWholesalerId = null;
        }

        products = Array.from(productMap.values());

        let assigned = new Array<ProjectProductSelectViewmodel>();
        let unassigned = new Array<ProjectProductSelectViewmodel>();

        //Add all asigned
        const wholesalerProjectProducts = this.projectStateService.project.projectProducts.filter((wb) => wb.wholesalerId);

        const wholesalerBasedProductViewModels = new Array<ProductViewmodel>();
        for (const projectProduct of wholesalerProjectProducts) {
            const productId = projectProduct.productId;
            const wholesalerId = projectProduct.wholesalerId;
            if (productMap.has(productId + "|" + wholesalerId)) {
                wholesalerBasedProductViewModels.push(productMap.get(productId + "|" + wholesalerId));
            }
        }

        if (wholesalerBasedProductViewModels?.length) {
            assigned = assigned.concat(wholesalerBasedProductViewModels.map((x) => new ProjectProductSelectViewmodel(x)));
        }
        const nonWholesalerbased = this.projectStateService.project.projectProducts.filter((nwb) => !nwb.wholesalerId);

        for (const item of nonWholesalerbased) {
            const pvm = productMap.get(item.productId)
            if (pvm) {
                assigned.push(new ProjectProductSelectViewmodel(pvm));
            }
        }

        const assignedMap = new Map(assigned.map((p) => [p.product.id + "|" + (p.wholesalerId ?? ""), p]));

        if (this.isWholesalerbased) {
            if (this.selectedWholesaler && this.selectedWholesaler[0]) {
                const response = await this.productDelineationService.getWholesalerProducts(this.selectedWholesaler[0].wholesaler);
                if (response && response.values) {
                    const vms = response.values.map(p => new ProjectProductSelectViewmodel(p));
                    unassigned = vms.filter((vm) => !assignedMap.has(vm.product.id + "|" + (vm.wholesalerId ?? "")));
                }
            }
        } else {
            unassigned = [...productMap.values()].filter(v => !assignedMap.has(`${v.id}|`)).map(v => new ProjectProductSelectViewmodel(v));
        }

        this.assignedFilteredProducts.sort((a, b) => {
            return (a[this.assignedFilteredProductsSortDto.name] ?? "") > (b[this.assignedFilteredProductsSortDto.name] ?? "")
                ? (1 * this.assignedFilteredProductsSortDto.direction) : (a[this.assignedFilteredProductsSortDto.name] ?? "")
                    < (b[this.assignedFilteredProductsSortDto.name] ?? "") ? (-1 * this.assignedFilteredProductsSortDto.direction) : 0;
        })

        if (this.isWholesalerbased) {
            const assignedMap = new Map(this.assignedProducts.map((ap) => [ap.wholesalerGroupProductId, ap]));
            assigned.forEach(vm => {
                const has = assignedMap.has(vm.wholesalerGroupProductId);
                if (!has) {
                    this.assignedProducts.push(vm);
                }
            });
            this.assignedProducts.sort((a, b) => a.description > b.description ? 1 : a.description < b.description ? -1 : 0);
        } else {
            this.assignedProducts = assigned.sort((a, b) => a.description > b.description ? 1 : a.description < b.description ? -1 : 0).slice();
        }

        this.unAssignedProducts = unassigned.sort((a, b) => a.description > b.description ? 1 : a.description < b.description ? -1 : 0).slice();
        this.shouldShowOrderDates = !!this.assignedProducts.some((ap) => ap.wholesalerId);

        this.projectStateService.notify();
        this.filter();
    }

    filter(): void {
        if (this.assignedSearchText) {
            const split = this.assignedSearchText.toLocaleLowerCase().split(" ");
            let assignedFilteredProducts = new Array<ProjectProductSelectViewmodel>();
            if (this.selectedWholesaler?.length) {
                for (const segment of split) {
                    const filteredItems = this.assignedProducts.filter((ap) =>
                        !assignedFilteredProducts.find((afp) => afp.product.id && afp.wholesalerId
                            && afp.product.id + afp.wholesalerId === ap.product.id + ap.wholesalerId) &&
                        !this.unAssignedProducts.find((uap) => uap.product.id + uap.wholesalerId === ap.product.id + ap.wholesalerId) && (
                            ap.description?.toLocaleLowerCase().includes(segment)
                            || ap.upc?.toLocaleLowerCase().includes(segment)
                            || ap.uin?.toLocaleLowerCase().includes(segment)))

                    assignedFilteredProducts = assignedFilteredProducts.concat(filteredItems);
                }
            } else {
                for (const segment of split) {
                    const filteredItems = this.assignedProducts.filter((ap) => !assignedFilteredProducts.find((afp) => afp.product.id && afp.product.id === ap.product.id) &&
                        !this.unAssignedProducts.find((uap) => uap.product.id === ap.product.id) && (
                            ap.description?.toLocaleLowerCase().includes(segment)
                            || ap.upc?.toLocaleLowerCase().includes(segment)
                            || ap.uin?.toLocaleLowerCase().includes(segment)))

                    assignedFilteredProducts = assignedFilteredProducts.concat(filteredItems);
                }
            }
            this.assignedFilteredProducts = assignedFilteredProducts.slice();
        } else {
            this.assignedFilteredProducts = this.assignedProducts.slice();
        }

        if (this.unAssignedSearchText) {
            const split = this.unAssignedSearchText?.toLowerCase().split(" ");
            let unAssignedFilteredProducts = new Array<ProjectProductSelectViewmodel>();

            if (this.selectedWholesaler?.length) {

                for (const segment of split) {

                    const filteredItems = this.unAssignedProducts.filter((uap) => !unAssignedFilteredProducts
                        .find((uafp) => uafp.product.id && uafp.wholesalerId
                            && uafp.product.id + uafp.wholesalerId === uap.product.id + uap.wholesalerId) &&
                        !this.assignedProducts.find((ap) => ap.product.id + ap.wholesalerId === uap.product.id + uap.wholesalerId) && (
                            uap.description?.toLocaleLowerCase().includes(segment)
                            || uap.upc?.toLocaleLowerCase().includes(segment)
                            || uap.uin?.toLocaleLowerCase().includes(segment)))

                    unAssignedFilteredProducts = unAssignedFilteredProducts.concat(filteredItems);
                }
            } else {
                for (const segment of split) {

                    const filteredItems = this.unAssignedProducts.filter((uap) => !unAssignedFilteredProducts
                        .find((uafp) => uafp.product.id
                            && uafp.product.id === uap.product.id) &&
                        !this.assignedProducts.find((ap) => ap.product.id === uap.product.id) && (
                            uap.description?.toLocaleLowerCase().includes(segment)
                            || uap.upc?.toLocaleLowerCase().includes(segment)
                            || uap.uin?.toLocaleLowerCase().includes(segment)))

                    unAssignedFilteredProducts = unAssignedFilteredProducts.concat(filteredItems);
                }
            }
            this.unAssignedFilteredProducts = unAssignedFilteredProducts.slice();
        } else {
            if (this.selectedCategory === "All") {
                this.unAssignedFilteredProducts = this.unAssignedProducts.slice();
            } else {
                this.unAssignedFilteredProducts = this.unAssignedProducts.filter((up) => up.product.division === this.selectedCategory);
            }
        }
        this.assignedSort();
        this.unAssignedSort();
        this.shouldWait$.next(false);
    }

    addClicked(): void {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        //Give the spinner a chance to load so it feels important too
        setTimeout(async () => {
            const itemsToMove = this.unAssignedProducts.filter((ue) => ue.checked);
            if (!itemsToMove?.length) {
                this.snackbarService.showWarning("No unassigned products have been selected to add to the project.");
            }
            itemsToMove.forEach((itm) => itm.checked = false);
            this.unAssignedProducts = this.unAssignedProducts.filter((up) => !itemsToMove.find((itm) => itm.product.id === up.product.id
                && itm.wholesalerId == up.wholesalerId))
            this.assignedProducts = this.assignedProducts.concat(itemsToMove);
            this.allAssignedchecked = false;
            this.allUnassignedchecked = false;

            this.filter();
            this.shouldShowOrderDates = !!this.assignedProducts.some((ap) => ap.wholesalerId);
            this.assignedSort();
            this.unAssignedSort();
            await this.buildProjectProductsFromViewmodel();

        }, 0);
    }

    async buildProjectProductsFromViewmodel(): Promise<void> {
        if (this.projectStateService.project) {
            this.projectStateService.project.projectProducts ??= new Array<ProjectProduct>();

            // remove items no longer valid
            const itemsToRemove = new Array<string>();
            for (const item of this.projectStateService.project.projectProducts) {
                const found = this.assignedProducts.find((ap) => ap.product.id === item.productId
                    && ap.wholesalerId === item.wholesalerId);
                if (!found) {
                    itemsToRemove.push(item.id);
                }
            }
            this.projectStateService.project.projectProducts = this.projectStateService.project.projectProducts
                .filter((pe) => {
                    const found = itemsToRemove.find((itr) => itr === pe.id);
                    if (found) {
                        return false;
                    }
                    return true;
                });

            //items to add
            for (const item of this.assignedProducts) {
                const found = this.projectStateService.project.projectProducts.find((pp) => pp.productId === item.product.id
                    && pp.wholesalerId === item.wholesalerId);
                if (!found) {
                    const newItem = new ProjectProduct();
                    newItem.id = newSequentialId();
                    newItem.projectId = this.projectStateService.project.id;
                    newItem.productId = item.product.id;
                    newItem.wholesalerId = item.wholesalerId;
                    newItem.wholesalerGroupProductId = item.wholesalerGroupProductId;

                    this.projectStateService.project.projectProducts.push(newItem);
                }
            }
            this.projectStateService.notify();
        }
    }

    removeClicked(): void {

        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        //Give the spinner a chance to load so it feels important too
        setTimeout(async () => {
            const itemsToMove = this.assignedProducts.filter((ap) => ap.checked);
            if (!itemsToMove?.length) {
                this.snackbarService.showWarning("No assigned products have been selected to remove from the project.");
            }
            for (const item of itemsToMove) {
                item.checked = false;
            }

            this.assignedProducts = this.assignedProducts.filter((up) => !itemsToMove.find((itm) => itm.product.id === up.product.id
                && itm.wholesalerId == up.wholesalerId))
            this.unAssignedProducts = this.unAssignedProducts.concat(
                itemsToMove.filter((itm) => itm.wholesalerId ==
                    ((this.selectedWholesaler && this.selectedWholesaler.length > 0) ? this.selectedWholesaler[0].wholesaler.id : null)));

            this.allUnassignedchecked = false;
            this.allAssignedchecked = false;
            this.filter();
            this.shouldShowOrderDates = !!this.assignedProducts.some((ap) => ap.wholesalerId);
            this.assignedSort();
            this.unAssignedSort();
            await this.buildProjectProductsFromViewmodel();
        }, 0);
    }

    drop(event: CdkDragDrop<ProjectProductSelectViewmodel[]>): void {

        if (event.previousContainer !== event.container) {
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        }
        //Give the spinner a chance to load so it feels important too
        setTimeout(async () => {

            if (event.previousContainer !== event.container) {
                const values = event?.item.element.nativeElement.id?.split(",");
                const productId = values[0];
                const wholesalerId = values[1] !== 'undefined' ? values[1] : undefined;

                if (event.previousContainer.id === "unAssignedProducts") {

                    const index = this.unAssignedProducts.findIndex((uap) => uap.product.id === productId
                        && uap.wholesalerId === wholesalerId);
                    if (index !== -1) {
                        const itemMoving = this.unAssignedProducts.splice(index, 1);
                        if (itemMoving?.length) {
                            itemMoving[0].checked = false;
                            this.assignedProducts.push(itemMoving[0]);
                        }
                    }
                    this.allAssignedchecked = false;
                    this.allUnassignedchecked = false;

                } else {
                    const index = this.assignedProducts.findIndex((ap) => ap.product.id === productId
                        && ap.wholesalerId === wholesalerId);
                    if (index !== -1) {
                        const itemMoving = this.assignedProducts.splice(index, 1);
                        if (itemMoving.length && itemMoving[0].wholesalerId == (this.selectedWholesaler ? this.selectedWholesaler[0].wholesaler.id : null)) {
                            itemMoving[0].checked = false;
                            this.unAssignedProducts.push(itemMoving[0]);
                        }
                    }
                    this.allAssignedchecked = false;
                    this.allUnassignedchecked = false;
                }
                await this.buildProjectProductsFromViewmodel();
                this.shouldShowOrderDates = !!this.assignedProducts.some((ap) => ap.wholesalerId);
                this.filter();
            }
        }, 0);
    }

    assignedSort(columnNameToSort?: keyof ProjectProductSelectViewmodel): void {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        setTimeout(() => {
            if (columnNameToSort) {
                if (columnNameToSort === this.assignedFilteredProductsSortDto.name) {
                    if (this.assignedFilteredProductsSortDto.direction === SortDirection.ascending) {
                        this.assignedFilteredProductsSortDto.direction = SortDirection.descending;
                    } else {
                        this.assignedFilteredProductsSortDto.direction = SortDirection.ascending;
                    }
                } else {
                    this.assignedFilteredProductsSortDto.name = columnNameToSort;
                    this.assignedFilteredProductsSortDto.direction = SortDirection.ascending;
                }
            }

            this.assignedFilteredProducts.sort((a, b) => {
                return (a[this.assignedFilteredProductsSortDto.name] ?? "") > (b[this.assignedFilteredProductsSortDto.name] ?? "")
                    ? (1 * this.assignedFilteredProductsSortDto.direction) : (a[this.assignedFilteredProductsSortDto.name] ?? "")
                        < (b[this.assignedFilteredProductsSortDto.name] ?? "") ? (-1 * this.assignedFilteredProductsSortDto.direction) : 0;
            })

            this.shouldWait$.next(false);
            this.assignedFilteredProducts = [...this.assignedFilteredProducts];
        }, 0);
    }

    unAssignedSort(columnNameToSort?: keyof ProjectProductSelectViewmodel): void {

        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        setTimeout(() => {
            if (columnNameToSort) {
                if (columnNameToSort === this.unAssignedFilteredProductsSortDto.name) {
                    if (this.unAssignedFilteredProductsSortDto.direction === SortDirection.ascending) {
                        this.unAssignedFilteredProductsSortDto.direction = SortDirection.descending;
                    } else {
                        this.unAssignedFilteredProductsSortDto.direction = SortDirection.ascending;
                    }
                } else {
                    this.unAssignedFilteredProductsSortDto.name = columnNameToSort;
                    this.unAssignedFilteredProductsSortDto.direction = SortDirection.ascending;
                }
            }

            this.unAssignedFilteredProducts.sort((a, b) => {
                return (a[this.unAssignedFilteredProductsSortDto.name] ?? "Description") > (b[this.unAssignedFilteredProductsSortDto.name] ?? "Description")
                    ? (1 * this.unAssignedFilteredProductsSortDto.direction) : (a[this.unAssignedFilteredProductsSortDto.name] ?? "Description")
                        < (b[this.unAssignedFilteredProductsSortDto.name] ?? "Description") ? (-1 * this.unAssignedFilteredProductsSortDto.direction) : 0;
            });

            this.shouldWait$.next(false);
            this.unAssignedFilteredProducts = [...this.unAssignedFilteredProducts];
        }, 0);
    }

    selectAllUnassignedChanged(event: MatCheckboxChange): void {

        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        //Give the spinner a chance to load so it feels important too
        setTimeout(() => {

            this.unAssignedFilteredProducts.forEach((uap) => uap.checked = event.checked);
            this.shouldWait$.next(false);
        }, 0);
    }

    selectAllAssignedChanged(event: MatCheckboxChange): void {

        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        //Give the spinner a chance to load so it feels important too
        setTimeout(() => {

            this.assignedFilteredProducts.forEach((uap) => uap.checked = event.checked);
            this.shouldWait$.next(false);
        }, 0);
    }

    setSelectAllAssigned(): void {
        if (this.assignedFilteredProducts.length === 0) {
            this.allAssignedchecked = false;
        } else {
            this.allAssignedchecked = !(!!this.assignedFilteredProducts.find((afp) => !afp.checked));
        }
    }

    setSelectAllUnAssigned(): void {
        if (this.unAssignedFilteredProducts.length === 0) {
            this.allUnassignedchecked = false;
        } else {
            this.allUnassignedchecked = !(!!this.unAssignedFilteredProducts.find((afp) => !afp.checked));
        }
    }

    categoryChanged(event: MatRadioChange): void {

        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        //Give the spinner a chance to load so it feels important too
        setTimeout(() => {

            this.unAssignedSearchText = "";
            this.filter();
            this.setSelectAllUnAssigned();
            this.setSelectAllAssigned();
        }, 0);
    }

    openOrderDates(): void {
        const futureDatedProducts = this.assignedFilteredProducts.filter((p) => p.dateAvailable != null);
        const viewmodel = new ProjectProductOrderDatesViewmodel(this.formBuilder, futureDatedProducts);
        this.orderDatesOverlayRef = this.overlayService.open(ProjectProductOrderDatesComponent, viewmodel, true);
        viewmodel.overlayRef = this.orderDatesOverlayRef;

        // Give the modal a turn to open and render befor setting the controls and subscriptions
        setTimeout(() => {
            viewmodel.initialize(this.projectStateService.project.projectOrderDates);
        }, 0);

        this.orderDatesOverlayRef.afterClosed$.subscribe((vm) => {
            const data = vm.data;
            this.projectStateService.project.projectOrderDates ??= new Array<ProjectOrderDate>();

            if (data.isConfirmed) {
                const orderDateOne = this.projectStateService.project?.projectOrderDates?.find((od) => od.dateIndex === 1);
                const orderDateTwo = this.projectStateService.project?.projectOrderDates?.find((od) => od.dateIndex === 2);
                const orderDateThree = this.projectStateService.project?.projectOrderDates?.find((od) => od.dateIndex === 3);
                const orderDateFour = this.projectStateService.project?.projectOrderDates?.find((od) => od.dateIndex === 4);

                this.setOrderDate(orderDateOne, data.orderDateOne, 1);
                this.setOrderDate(orderDateTwo, data.orderDateTwo, 2);
                this.setOrderDate(orderDateThree, data.orderDateThree, 3);
                this.setOrderDate(orderDateFour, data.orderDateFour, 4);
            }
        });
    }

    private setOrderDate(orderDate: ProjectOrderDate, moment: moment.Moment, dateIndex: number): void {
        if (orderDate) {
            if (moment === null) {
                const index = this.projectStateService.project?.projectOrderDates.findIndex((od) => od.dateIndex === orderDate.dateIndex);
                if (index !== -1) {
                    this.projectStateService.project?.projectOrderDates.splice(index, 1);
                }
            } else {
                orderDate.orderDate = moment.toDate();
            }
        } else {
            if (moment) {
                this.projectStateService.project.projectOrderDates.push(this.createProjectOrderDate(moment, dateIndex));
            }
        }
    }

    createProjectOrderDate(value: moment.Moment, dateIndex: number): ProjectOrderDate {
        const rtn = new ProjectOrderDate();
        rtn.id = newSequentialId();
        rtn.dateIndex = dateIndex;
        rtn.orderDate = value?.toDate();
        rtn.projectId = this.projectStateService.project.id;

        return rtn;
    }
}
