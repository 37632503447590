import { OrderLineItemDto } from "./order-line-item.dto";

export class OrderDto {
    id: string;
    referenceId: string;
    orderDate: string;
    orderStatusId: number;
    callId: string;
    callNumber: string | null;
    callDate: string;
    customerId: string;
    retailNumber: string | null;
    customerName: string | null;
    customerAddress1: string | null;
    customerAddress2: string | null;
    customerCity: string | null;
    customerState: string | null;
    customerStateId: string | null;
    customerZip: string | null;
    customerCounty: string | null;
    customerCountyId: string | null;
    wholesalerId: string;
    wholesalerName: string | null;
    wholesalerAccountNumber: string | null;
    wholesalerCustomerCode: string | null;
    chainName: string | null;
    chainNumber: string | null;
    cancelledDate: Date | null;
    cancelledBy: string | null;
    orderExtractionId: string | null;
    receiptNumber: string | null;
    createdUserId: string;
    createdUtcDateTime: string;
    createdUserZrt: string;
    createdUserFullName: string
    modifiedUtcDateTime: string;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;

    orderLineItems: OrderLineItemDto[];
}
