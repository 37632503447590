import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    GenericRequestDto, GenericResponseDto, milisecondsToSecondsScalar,
    newSequentialId, SharedHelper
} from 'shield.shared';
import { DatabaseService } from './database.service';
import { DataSyncHandlerInterface, dataSyncHandlerInterfaceToken } from '../sync/data-sync-handler-interface';
import { DataSyncQueueService } from '../sync/data-sync-queue.service';

@Injectable({
    providedIn: 'root'
})
export class PingService {

    //private
    private _online = new BehaviorSubject<boolean>(false);
    private _seeMe: undefined = undefined;
    private seeMeSubject: BehaviorSubject<undefined> = new BehaviorSubject(
        this._seeMe
    );
    public onlineCurrentStatus = false;

    //public
    public observableSeeMe: Observable<undefined> = this.seeMeSubject.asObservable();
    public static onlineCheckIntervalSeconds = 10;
    public static pingTimeoutMS = 1000;
    public readonly online: Observable<boolean> = this._online.asObservable();
    private pingIsInitalized = false;
    private pingIsInitalizedSubject = new BehaviorSubject(this.pingIsInitalized);
    public observablePingIsInitalized = this.pingIsInitalizedSubject.asObservable();

    constructor(private http: HttpClient,
        private dbService: DatabaseService,
        @Inject(dataSyncHandlerInterfaceToken)
        private dataSyncHandlers: DataSyncHandlerInterface[],
        private syncQueue: DataSyncQueueService) {
        void this.onlineCheckPolling();
    }

    //public methods
    private async setOnlineCurrentStatus(count = 0): Promise<void> {
        // @ts-ignore
        if (window.navigator && window.navigator.connection && window.navigator.connection.downlink < 1) {
            // @ts-ignore
            // console.log('Slow connection detected... ', window.navigator.connection)
            this.onlineCurrentStatus = false;
            this._online.next(this.onlineCurrentStatus);
        } else {
            // @ts-ignore
            // console.log('Fast connection detected or no connection object', window.navigator.connection)
            let startTime:number = new Date().getTime();
            try {
                const request = new GenericRequestDto();
                request.id = newSequentialId();
                
                const result = await this.http
                    .post<GenericResponseDto<void>>(`/api/pings`, request).toPromise();
                if ((new Date().getTime() - startTime) > PingService.pingTimeoutMS) {
                    throw new Error(`Slow connection ${(new Date().getTime() - startTime)}ms < ${PingService.pingTimeoutMS}ms`)
                }
                // console.log(`Ping took ${(new Date().getTime() - startTime)} ms - OK`)
                const matchedRequest = result.id === request.id;
                if (!matchedRequest) {
                    // response is not for the request we last sent
                    return;
                }

                this.onlineCurrentStatus = matchedRequest;
                this._online.next(this.onlineCurrentStatus);

                if (this.onlineCurrentStatus) {
                    this.seeMeSubject.next(undefined);
                }
            } catch (e) {
                // console.log(`Ping took ${(new Date().getTime() - startTime)} ms - FAIL`)
                if (count < 2) {
                    ++count;
                    await new Promise((resolve) => setTimeout(resolve, 100));
                    await this.setOnlineCurrentStatus(count);
                } else {
                    this.onlineCurrentStatus = false;
                    this._online.next(this.onlineCurrentStatus);
                }
            } finally {
                if (!this.pingIsInitalized) {
                    this.pingIsInitalized = true;
                    this.pingIsInitalizedSubject.next(true);
                }
            }
        }
    }

    //private methods
    private async onlineCheckPolling(): Promise<void> {

        await this.setOnlineCurrentStatus();

        this.refreshIntervalValues();

        setTimeout(function () {
            void this.onlineCheckPolling();
        }.bind(this), milisecondsToSecondsScalar * PingService.onlineCheckIntervalSeconds);
    }

    private async refreshIntervalValues(): Promise<void> {

        const onlineIntervalResponse = await this.dbService.systemInformation.where("key").equalsIgnoreCase("OnlineSyncInterval").toArray();
        if (onlineIntervalResponse?.length > 0) {
            PingService.onlineCheckIntervalSeconds = (SharedHelper.parseInt(onlineIntervalResponse[0].value) ?? 0);
        }
        const pingTimeoutResponse = await this.dbService.systemInformation.where("key").equalsIgnoreCase("PingRouteTimeout").toArray();
        if (pingTimeoutResponse?.length > 0) {
            PingService.pingTimeoutMS = (SharedHelper.parseInt(pingTimeoutResponse[0].value) ?? 1000);
        }
    }
}
