import {Component, HostBinding, OnDestroy, OnInit} from "@angular/core";
import { Subscription } from "rxjs";
import { CustomerGenericTypes } from "src/app/enums/customer-generic-types";
import { Helper } from "src/app/helpers/helper";
import { CustomerStateService } from "../account-services/customer-state.service";

@Component({
    selector: "app-volume-master",
    templateUrl: "./volume-master.component.html",
    styleUrls: ["./volume-master.component.css"]
})
export class VolumeMasterComponent implements OnInit, OnDestroy {
    @HostBinding("class") class = "d-flex flex-column flex-grow-1";

    type: CustomerGenericTypes;
    retail = CustomerGenericTypes.retail;
    wholesale = CustomerGenericTypes.wholesale;
    chainHq = CustomerGenericTypes.chainHeadquarter;
    customerSubscription: Subscription;

    constructor(private customerStateService: CustomerStateService) {}

    ngOnInit(): void {
        if (!this.customerSubscription || this.customerSubscription.closed) {
            this.customerSubscription = this.customerStateService.observableCustomer.subscribe(
                (customer): void => {
                    if (customer) {
                        this.type = Helper.getCustomerGenericType(
                            customer
                        );
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (this.customerSubscription && !this.customerSubscription.closed) {
            this.customerSubscription.unsubscribe();
        }
    }
}
