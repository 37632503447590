import { AccountOwnershipHierarchyLevel, newSequentialId } from "shield.shared";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { Address } from "src/app/entity-models/address.entity";
import { ChainHeadquarterProduct } from "src/app/entity-models/chain-headquarter-product.entity";
import { ChainHeadquarter } from "src/app/entity-models/chain-headquarter.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { Helper } from "src/app/helpers/helper";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";

export class ChainHeadquarterProfileViewmodel {
    id: string;
    customer: Customer;
    numberOfStores: number;
    ultimateOwnership: AccountOwnership;
    ultimateCustomer: Customer;
    dirtyUltimateCustomer = false;
    ultimateCustomerRep: string;
    oldUltimateCustomerRep: string;
    immediateOwnership: AccountOwnership;
    immediateCustomer: Customer;
    dirtyImmediateCustomer = false;
    immediateCustomerRep: string;
    oldImmediateCustomerRep: string;
    ownerOwnership: AccountOwnership;
    ownerCustomer: Customer;
    dirtyOwnerCustomer = false;
    ownerCustomerRep: string;
    oldOwnerCustomerRep: string;
    oldUltimateCustomer: Customer;
    oldImmediateCustomer: Customer;
    oldOwnerCustomer: Customer;
    type: string;
    displayZrt: string;

    chainHeadquarterOptions: Customer[] = [];

    private oldCustomer: Customer;
    private oldChainHeadquarter: Customer;
    private employeeName: string;
    private chainHqProducts: ChainHeadquarterProduct[];

    constructor(private employeDelineationService: EmployeeDelineationService
        , private customerDelineationService: CustomerDelineationService
        , private accountOwnershipDelineationService: AccountOwnershipDelineationService){}

    async buildViewmodelFromDomainModel(
        chainHeadquarter: Customer,
        customer: Customer,
        employeeName?: string
    ): Promise<void> {
        if (chainHeadquarter && customer) {
            const chainLevels = await this.getChainLevels(customer.ownerCode.trim());

            this.oldCustomer = Object.assign({}, customer);
            this.oldChainHeadquarter = Object.assign({}, chainHeadquarter);
            this.id = chainHeadquarter?.id;
            this.numberOfStores = chainHeadquarter.storeCount;
            this.customer = customer;

            this.ultimateCustomer = chainLevels.ultimateCustomer;
            this.ultimateCustomerRep = chainLevels.ultimateAssignedEmployeeName ?? "";
            this.oldUltimateCustomer = Object.assign({}, this.ultimateCustomer);
            this.oldUltimateCustomerRep = chainLevels.ultimateAssignedEmployeeId;

            this.immediateCustomer = chainLevels.immediateCustomer;
            this.immediateCustomerRep = chainLevels.immediateAssignedEmployeeName ?? "";
            this.oldImmediateCustomer = Object.assign({}, this.immediateCustomer);
            this.oldImmediateCustomerRep = chainLevels.immediateAssignedEmployeeId;

            this.ownerCustomer = chainLevels.ownerCustomer;
            this.ownerCustomerRep = chainLevels.ownerAssignedEmployeeName ?? "";
            this.oldOwnerCustomer = Object.assign({}, this.ownerCustomer);
            this.oldOwnerCustomerRep = chainLevels.ownerAssignedEmployeeId;

            this.type = chainHeadquarter.accountOwnershipHierarchyLevelName;
            this.displayZrt = Helper.formatDisplayZrt(customer?.zrt);
            this.employeeName = employeeName;

            if (!this.customer?.businessAddress) {
                const businessAddress = new Address();
                businessAddress.id = newSequentialId();
                this.customer.businessAddress = businessAddress;
            }

            if (!this.customer?.dbaAddress) {
                const dbaAddress = new Address();
                dbaAddress.id = newSequentialId();
                this.customer.dbaAddress = dbaAddress;
            }
            this.chainHqProducts = [];
        }
    }

    async buildDomainModelsFromViewModel(): Promise<{
        customer: Customer;
        chainHeadquarter: ChainHeadquarter;
        isEqual: boolean;
    }> {
        const now = new Date();
        const customer = this.customer;
        const chainHeadquarter: ChainHeadquarter = new ChainHeadquarter();
        chainHeadquarter.id = this.id;
        chainHeadquarter.customerId = this.customer.id;
        chainHeadquarter.numberOfStores = this.numberOfStores;
        chainHeadquarter.type = this.customer.accountOwnershipHierarchyLevelName;
        if (customer.license !== this.oldCustomer.license) {
            customer.licenseLastUpdated = now;
        }
        if (customer.stateTaxId !== this.oldCustomer.stateTaxId) {
            customer.stateTaxIdLastUpdated = now;
        }
        if (customer.federalTaxId !== this.oldCustomer.federalTaxId) {
            customer.federalTaxIdLastUpdated = now;
        }
        if (customer.comments !== this.oldCustomer.comments) {
            customer.commentsLastUpdated = now;
            customer.commentsLastUpdatedBy = this.employeeName;
        }

        chainHeadquarter.ultimateCustomerId = this.ultimateCustomer?.id;
        if (
            this.ultimateOwnership &&
            this.oldUltimateCustomerRep !== this.ultimateOwnership.assignedEmployeeId
        ) {
            await this.accountOwnershipDelineationService.save(this.ultimateOwnership);
        }

        chainHeadquarter.immediateCustomerId = this.immediateCustomer?.id;
        if (
            this.immediateOwnership &&
            this.oldImmediateCustomerRep !== this.immediateOwnership.assignedEmployeeId
        ) {
            await this.accountOwnershipDelineationService.save(this.immediateOwnership);
        }

        chainHeadquarter.ownerCustomerId = this.ownerCustomer?.id;
        if (
            this.ownerOwnership &&
            this.oldOwnerCustomerRep !== this.ownerOwnership.assignedEmployeeId
        ) {
            await this.accountOwnershipDelineationService.save(this.ownerOwnership);
        }

        chainHeadquarter.chainHeadquarterProducts = this.chainHqProducts;
        const isEqual: boolean = this.isEqual(chainHeadquarter, customer);

        return { customer, chainHeadquarter, isEqual };
    }

    isEqual(chainHeadquarter: ChainHeadquarter, customer: Customer): boolean {
        return (
            JSON.stringify(chainHeadquarter) ===
                JSON.stringify(this.oldChainHeadquarter) &&
            JSON.stringify(customer) === JSON.stringify(this.oldCustomer)
        );
    }

    private async getChainLevels(ownerCode: string): Promise<ChainHeadquarter> {
        const hqLevels = new ChainHeadquarter();

        // Get all potential hierarchy levels.
        const levelsResponse = await this.accountOwnershipDelineationService.getByOwnerCode(ownerCode);
        if (!levelsResponse) { return };

        const levels = levelsResponse.values;

        const firstLevel = levels.find((l) => l.hierarchyLevel.id === AccountOwnershipHierarchyLevel.Owner);
        const secondLevel = levels.find((l) => l.hierarchyLevel.id === AccountOwnershipHierarchyLevel.Immediate);
        const thirdLevel = levels.find((l) => l.hierarchyLevel.id === AccountOwnershipHierarchyLevel.Ultimate);

        // Getting the customers.
        if (firstLevel) {
            this.ownerOwnership = firstLevel;

            const ownerCustomerResponse = await this.customerDelineationService.getById(firstLevel.customerId);
            if (!ownerCustomerResponse) { return; }
            hqLevels.ownerCustomer = ownerCustomerResponse.values;

            if (firstLevel.assignedEmployeeId) {
                const employeeResponse = await this.employeDelineationService.getById(firstLevel.assignedEmployeeId);
                if (employeeResponse.values) {
                    hqLevels.ownerAssignedEmployeeId = employeeResponse.values.id;
                    hqLevels.ownerAssignedEmployeeName = employeeResponse.values.fullName;
                }
            }
        }

        if (secondLevel) {
            this.immediateOwnership = secondLevel;

            const immediateCustomerResponse = await this.customerDelineationService.getById(secondLevel.customerId);
            if (!immediateCustomerResponse) { return; }
            hqLevels.immediateCustomer = immediateCustomerResponse.values;

            if (secondLevel.assignedEmployeeId) {
                const employeeResponse = await this.employeDelineationService.getById(secondLevel.assignedEmployeeId);
                if (employeeResponse?.values) {
                    hqLevels.immediateAssignedEmployeeId = employeeResponse.values.id;
                    hqLevels.immediateAssignedEmployeeName = employeeResponse.values.fullName;
                }
            }
        }

        if (thirdLevel) {
            this.ultimateOwnership = thirdLevel;

            const ultimateCustomerResponse = await this.customerDelineationService.getById(thirdLevel.customerId);
            if (!ultimateCustomerResponse) { return; }
            hqLevels.ultimateCustomer = ultimateCustomerResponse.values;

            if (thirdLevel.assignedEmployeeId) {
                const employeeResponse = await this.employeDelineationService.getById(thirdLevel.assignedEmployeeId);
                if (employeeResponse.values) {
                    hqLevels.ultimateAssignedEmployeeId = employeeResponse.values.id;
                    hqLevels.ultimateAssignedEmployeeName = employeeResponse.values.fullName;
                }
            }
        }

        return hqLevels;
    }
}
