import { Injectable } from "@angular/core";
import { GenericResponseDto, SystemInformationKeys } from "shield.shared";
import { SystemInformation } from "src/app/entity-models/system-information.entity";
import { DatabaseService } from "../database.service";
import { SystemInformationOfflineService } from "../offline-services/system-information-offline.service";
import { SystemInformationOnlineService } from "../online-services/system-information-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class SystemInformationDelineationService extends DelineationContext<SystemInformation, string> {

    constructor(private systemInformationOfflineService: SystemInformationOfflineService
        , private systemInformationOnlineService: SystemInformationOnlineService
        , snackbarService: SnackbarService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService){
            super(dbService, datasourceDelineationService, snackbarService);
        }

    async getByKey(key: SystemInformationKeys): Promise<GenericResponseDto<SystemInformation>> {
        const offline = (key: SystemInformationKeys) => {
            return this.systemInformationOfflineService.getByKey(key);
        }
        const  online = (key: SystemInformationKeys) => {
            return this.systemInformationOfflineService.getByKey(key);
        }
        const response = await this.datasourceDelineationService.makeCall<SystemInformationKeys, SystemInformation>(key, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }
}
