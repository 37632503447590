<div
    class="confirmation-modal"
    cdkDrag
    cdkDragBoundary=".cdk-overlay-container"
>
    <div cdkDragHandle>
        <div class="modal-header">
            {{ header }}
            <mat-icon *ngIf="!blocking" class="close-icon" (click)="close()">close</mat-icon>
        </div>
    </div>
    <div class="modal-body">
        {{ message }}
    </div>
    <div class="modal-footer">
        <button *ngIf="buttonLeftText"
            mat-raised-button
            class="btn-white actionBtn"
            (click)="buttonLeftFunction()"
        >
            {{ buttonLeftText }}
        </button>
        <button *ngIf="buttonMiddleText"
            mat-raised-button
            class="btn-white actionBtn"
            (click)="buttonMiddleFunction()"
        >
            {{ buttonMiddleText }}
        </button>
        <button [disabled]="buttonRightDisableFunction && buttonRightDisableFunction()"
            mat-raised-button
            class="sw-red-btn actionBtn"
            (click)="buttonRightFunction()"
        >
            {{ buttonRightText }}
        </button>
    </div>
</div>
