import { ZrtFilterDto } from "./zrt-filter.dto";

export class DailySummaryFilterDto {

    /*
     * My Searches
     */
    // Usage: Get only accounts with given ids
    ids?: string[];

    /*
    * Date Information section
    */

    // Usage: Contains ZRT properties
    zrtFilterDto?: ZrtFilterDto;

    // Usage: Get data based on date range
    startOnOrAfterDate?: string;
    startOnOrBeforeDate?: string;

    // Usage: Get data based on calls made in date range
    callOnOrAfterDate?: string;
    callOnOrBeforeDate?: string;

    /*
     * Attributes Secton
     */

    // Usage: Get day time entries that are marked "Reset Day"
    resetDay?: boolean;

    // Usage: Get data based on work with person
    workWithPerson?: string[];
}
