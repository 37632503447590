import { AddressFilterDto, ZrtFilterDto } from ".";
import { GenericLookup } from "../generic/generic-lookup";
import { CustomerTypeEnum } from "../lookup";

export class CallHistoryFilterDto {
    /*
     * My Searches
     */
    // Usage: Get only accounts with given ids
    ids?: string[];

    /*
    * Header and Requested information section
    */

    // Usage: Contains ZRT properties
    zrtFilterDto?: ZrtFilterDto;

    // Usage: Used for filter accounts on the account list page
    //Possible data: account name, account GUID/Id or account number
    account?: string[];

    // Usage: Contains address properties for the customer business address
    businessAddressFilterDto?: AddressFilterDto;

    // Usage: Contains address properties for the customer driving address
    drivingAddressFilterDto?: AddressFilterDto;


    /*
     * Date Information Sections
     */

    // Usage: Get data based on call/date
    callOnOrAfterDate?: string;
    callOnOrBeforeDate?: string;

    // Usage: If set, get only last call for given customers
    lastCall?: boolean;

    /*
     * Activities Section
     */

    // Usage: Get based on associated project ids
    projectIds?: string[];

    // Usage: Get based on associated account ownership ids
    accountOwnershipIds?: string[];


    /*
     * Attributes Secton
     */

    // Usage: Get based on customer types
    customerTypeIds?: CustomerTypeEnum[];

    // Usage: Get based on call types
    callTypes?: string[];

    // Usage: Get based on picture types
    pictureTypes?: string[];

    // Usage: Get if the call has pictures
    hasPictures?: boolean;

    // Usage: Get based on call picture tags
    tags?: string[];

    //Usage: Get based on the selected px3 rank
    px3Rank?: string[];

    /*
     * Other
     */

    // Usage: if set, get call pictures
    includePictures?: boolean;

    // Usage: limit calls returned to those that fall within these ZRTs
    calledZrts?: string[];

    // Usage: do not limit calls returned for these employee ids (in normal usage, just your own)
    employeeIdsFromAnyZrt?: string[];
}
