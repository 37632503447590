<div #originalSwisherRetailReceipt *ngIf="swisherCashRetailViewmodels?.length > 0
    || swisherGratisRetailViewmodels?.length > 0
    || swisherExchangeOutRetailViewmodels?.length > 0
    || swisherExchangeInRetailViewmodels?.length > 0">
    <div class="row">
        <div class="col center image-margin-top">
            <img *ngIf="printLogo" [src]="printLogo" alt="Swisher Print Logo" />
        </div>
    </div>
    <div class="row">
        <div class="col center"><b>Swisher Sales Invoice</b></div>
    </div>
    <div class="row">
        <div class="col center">
            {{swisherAddress}}
        </div>
    </div>
    <div class="row">
        <div class="col center">{{ timeNow | date: dateTimeFormat }}</div>
        <div *ngIf="employee" class="col center">
            {{salesRepDisplayName}}
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="center">Swisher License # {{ swisherlicense }}</div>
        </div>
    </div>
    <hr />
    <div>
        <div class="row">
            <div class="col center">
                {{ customerReceiptAddress.addressName }} - Receipt #{{swisherReceiptNumber}}
            </div>
        </div>
        <div class="row">
            <div class="col center">
                Retail License # {{ retailStateOptLicense?.licenseNumber }}
            </div>
        </div>
        <div class="row">
            <div class="col center">
                {{ customerReceiptAddress.addressFormatted }}
            </div>
        </div>
        <div *ngIf="customer.businessAddress?.county" class="row">
            <div class="col center">{{ customer.businessAddress?.county }}&nbsp;County</div>
        </div>
        <hr />
        <div *ngIf="swisherCashRetailViewmodels?.length > 0">
            <div class="row  margin-right">
                <div class="col-5">
                    <b>Cash Sales Item</b>
                </div>
                <div class="col-1">
                    <b>Qty</b>
                </div>
                <div class="col-2">
                    <b>Price</b>
                </div>
                <div class="col-2">
                    <b>Discount</b>
                </div>
                <div class="col-2">
                    <b>Total</b>
                </div>
            </div>
            <div class="row  margin-right" *ngFor="let cashProduct of swisherCashRetailViewmodels">
                <div class="col-5">
                    {{ cashProduct.product?.description }}
                </div>
                <div class="col-1">
                    {{ cashProduct.quantity }}
                </div>
                <div class="col-2">
                    {{ cashProduct.price | currency }}
                </div>
                <div class="col-2">
                    {{ cashProduct.discount | currency }}
                </div>
                <div class="col-2">
                    {{ cashProduct.total | currency }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col total-display">
                    <b>Total: {{ stepperCallApplicationService.cashDueNowTotal | currency }}</b>
                </div>
            </div>
            <div class="row">
                <div class="col total-display larger">
                    <b><u>Cash Due Now: {{ stepperCallApplicationService.cashDueNowTotal | currency }}</u></b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="swisherGratisRetailViewmodels?.length > 0">
            <div class="row  margin-right">
                <div class="col-5">
                    <b>Promotional Goods</b>
                </div>
                <div class="col-2">
                    <b>Qty</b>
                </div>
                <div class="col-2">
                    <b>Price</b>
                </div>
                <div class="col-3">
                    <b>Total</b>
                </div>
            </div>
            <div class="row  margin-right" *ngFor="let gratisProduct of swisherGratisRetailViewmodels">
                <div class="col-5">
                    {{ gratisProduct.product?.description }}
                </div>
                <div class="col-2">
                    {{ gratisProduct.quantity }}
                </div>
                <div class="col-2">
                    {{ gratisProduct.value | currency }}
                </div>
                <div class="col-3">
                    {{ gratisProduct.total | currency }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col total-display">
                    <b>Promotional Goods Total:
                        {{ stepperCallApplicationService.gratisDueNowTotal | currency }}</b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="swisherExchangeOutRetailViewmodels?.length > 0">
            <div class="row  margin-right">
                <div class="col-5">
                    <b>Exchange Out Item</b>
                </div>
                <div class="col-2">
                    <b>Qty</b>
                </div>
                <div class="col-2">
                    <b>Price</b>
                </div>
                <div class="col-3">
                    <b>Total</b>
                </div>
            </div>
            <div class="row  margin-right" *ngFor="let outProduct of swisherExchangeOutRetailViewmodels">
                <div class="col-5">
                    {{ outProduct.product?.description }}
                </div>
                <div class="col-2">
                    {{ outProduct.quantity }}
                </div>
                <div class="col-2">
                    {{ outProduct.price | currency }}
                </div>
                <div class="col-3">
                    {{ outProduct.total | currency }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 total-display">
                    <b>Exchange Out Total:
                        {{ stepperCallApplicationService.exchangeOutTotal | currency }}</b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="swisherExchangeInRetailViewmodels?.length > 0">
            <div class="row  margin-right">
                <div class="col-5">
                    <b>Exchange In Item</b>
                </div>
                <div class="col-2">
                    <b>Qty</b>
                </div>
                <div class="col-2">
                    <b>Price</b>
                </div>
                <div class="col-3">
                    <b>Total</b>
                </div>
            </div>
            <div class="row  margin-right" *ngFor="let inProduct of swisherExchangeInRetailViewmodels">
                <div class="col-5">
                    {{ inProduct.product?.description }}
                </div>
                <div class="col-2">
                    {{ inProduct.quantity }}
                </div>
                <div class="col-2">
                    {{ inProduct.price | currency }}
                </div>
                <div class="col-2">
                    {{ inProduct.total | currency }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col total-display">
                    <b>Exchange In Total: {{ stepperCallApplicationService.exchangeInTotal | currency }}</b>
                </div>
            </div>
            <br />
        </div>
    </div>
    <div class="row">
        <div class="col">
            Salesrep: ({{signatureDisplayName}})
        </div>
    </div>
    <div class="row">
        <div class="col larger">
            <b>Customer Representative:</b>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col">
            <img *ngIf="signature" [src]="signature" alt="Signature" [width]="signatureRetailWidth"
                [height]="signatureRetailHeight" />
            <hr class="no-top-margin" />
        </div>
    </div>
    <div class="row">
        <div class="col">Signature ({{contactDisplayName}})</div>
        <br />
    </div>
    <div class="row">
        <div class="col  center">
            All {{ stateName }} state cigarette and/or tobacco product taxes
        </div>
    </div>
    <div class="row">
        <div class="col  center">
            are included in the total amount of this invoice
        </div>
    </div>
    <div class="row">
        <div class="col  center">
            Falsification of this form is grounds for immediate dismissal
        </div>
    </div>
    <div class="row">
        <div class="col  center">Receipt #{{swisherReceiptNumber}}</div>
    </div>
    <hr />
    <div *ngIf="easCashRetailViewmodels?.length > 0
        || easGratisRetailViewmodels?.length > 0
        || easExchangeOutRetailViewmodels?.length > 0
        || easExchangeInRetailViewmodels?.length > 0" class="page-break">
    </div>
</div>
