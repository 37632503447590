import { AddressDto } from "./address.dto";

export class RouteStopDto {
    id: string; // UUID
    customerId: string | null; // UUID
    label: string;
    address: AddressDto;
    sequence: number;
    px3RankId: string | null;
    isCompleted?: boolean;
}
