<div role="main" class="d-flex flex-column flex-grow-1">
    <div class="main-container 2-column d-flex flex-column flex-grow-1">
        <div class="d-flex flex-column flex-grow-1">
            <div class="worksheet-area d-flex flex-column flex-grow-1">
                <div class="worksheet-header">
                    <div class="d-flex justify-content-between">
                        <div class="">
                            <span class="small badge sw-badge"></span>
                            <h5 class="mb-0">
                                <b>{{ customerStateService.customer?.name }}</b>
                                <mat-icon
                                    class="swisher-yellow"
                                    *ngIf="px3Rank"
                                    >star_rate
                                </mat-icon>
                            </h5>
                            <h4 class="mb-0">
                                {{ customerAddress }}
                            </h4>
                        </div>
                        <div>
                            <div>
                                <span class="small">Customer ID: </span>
                                <span class="small">{{
                                    customerStateService.customer
                                        ?.customerNumber
                                    }}</span>
                            </div>
                            <div>
                                <span class="small">Last Call: </span>
                                <span class="small">{{
                                    !customerStateService.customer?.lastCall
                                        ? "N/A"
                                        : (customerStateService.customer
                                            .lastCall
                                            | date: dateFormat)
                                    }}</span>
                            </div>
                        </div>
                        <div class="">
                            <button *ngIf="(type === retailType || type === wholesaleType || type === chainHqType) && isCallVisible"
                                    class="btn sw-red-btn"
                                    (click)="
                                                onCallAction(true)
                                            "
                            >
                                {{
                                isCallInProgress
                                    ? "Cancel Call"
                                    : "Start Call Now"
                                }}
                            </button>
                        </div>
                    </div>
                    <div class="horizontal-nav d-flex align-items-end">
                        <ul class="list-inline list-inline-flex">
                            <li class="list-inline-item-custom"
                                [ngClass]="{ active: tabIndex == tabIndexProfile }"
                                (click)="tabIndex = tabIndexProfile"
                            >
                                <a
                                    class="no-underline"
                                    [routerLink]="[
                                        customerStateService.customer?.id,
                                        'profile'
                                    ]"
                                    >Store Profile</a
                                >
                            </li>
                            <li class="list-inline-item-custom"
                                [ngClass]="{ active: tabIndex == tabIndexVolume }"
                                (click)="tabIndex = tabIndexVolume"
                            >
                                <a class="no-underline"
                                    [routerLink]="[
                                        customerStateService.customer?.id,
                                        'volume'
                                    ]">Volume</a>
                            </li>
                            <li *ngIf="type === retailType && isMsa"
                                class="list-inline-item-custom"
                                [ngClass]="{ active: tabIndex == tabIndexDashboard }"
                                (click)="tabIndex = tabIndexDashboard"
                            >
                                <a class="no-underline"
                                    [routerLink]="[
                                        customerStateService.customer?.id,
                                        'dashboard'
                                    ]">Dashboard</a>
                            </li>
                            <li *ngIf="type === chainHqType || type === wholesaleType"
                                class="list-inline-item-custom"
                                [ngClass]="{ active: tabIndex == tabIndexProducts }"
                                (click)="tabIndex = tabIndexProducts"
                                >
                                <a class="no-underline"
                                [routerLink]="[
                                    customerStateService.customer?.id,
                                    'products'
                                ]">Products</a>
                            </li>
                            <li *ngIf="(type === retailType || type === wholesaleType || type === chainHqType)"
                                class="list-inline-item-custom"
                                [ngClass]="{ active: (tabIndex == tabIndexCall)}"
                                (click)="tabIndex = tabIndexCall"
                            >
                                <a *ngIf="isCallVisible"
                                    class="no-underline"
                                    (click)="onCallAction(false)"
                                    >{{
                                        isCallInProgress
                                            ? "Resume Call"
                                            : "Start Call Now"
                                    }}
                                </a>
                                <a *ngIf="!isCallVisible"
                                    appThrottleClick
                                    class="no-underline"
                                    (click)="onCallAction(false)"
                                    >Not Currently Callable
                                </a>
                            </li>
                        </ul>
                        <div style="padding-left: 0.5em">
                            <button appThrottleClick
                                class="large-button m-0"
                                *ngIf="
                                    (type === retailType && tabIndex === tabIndexCall &&
                                    stepperCallApplicationService.selectedIndex !==
                                        6 &&
                                    stepperCallApplicationService.selectedIndex !==
                                        0) ||
                                    (type === wholesaleType && tabIndex === tabIndexCall)
                                "
                                (throttledClick)="openCameraModal()"
                            >
                                <mat-icon [inline]="true">
                                    camera_alt
                                </mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <router-outlet (activate)="suscribeToEmitter($event)" (activate)="subscribeToBlockCallEmitter($event)"></router-outlet>
            </div>
        </div>
    </div>
</div>
