import { Component, HostListener, OnInit } from "@angular/core";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { FinishRouteDialogViewModel } from "./finish-route-dialog.viewmodel";
import {
    faInfoCircle,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { Employee } from "src/app/entity-models/employee.entity";

@Component({
    selector: "app-finish-route-dialog",
    templateUrl: "./finish-route-dialog.component.html",
    styleUrls: ["./finish-route-dialog.component.css"]
})
export class FinishRouteDialogComponent
    extends FinishRouteDialogViewModel
    implements OnInit {
    faInfoCircle: IconDefinition = faInfoCircle;

    constructor(
        private ref: SwisherOverlayRef<
            FinishRouteDialogViewModel,
            FinishRouteDialogComponent
        >
    ) {
        super();
    }

    ngOnInit(): void {
        this.isEdit = this.ref.data.isEdit;
        this.routeName = this.ref.data.routeName;
        this.routeDescription = this.ref.data.routeDescription ? this.ref.data.routeDescription : "";
        this.supervisorEmployeeOptions = this.ref.data.supervisorEmployeeOptions;
    }

    setSupervisor(supervisor: Employee): void {
        if (supervisor) {
            this.supervisorEmployeeId = supervisor.id;
        } else {
            this.supervisorEmployeeId = null;
        }
    }

    close(): void {
        this.ref.close(this.ref.data);
    }

    saveClick(): void {
        this.ref.data.onSave(this);
        this.ref.close(this.ref.data);
    }

    @HostListener('window:keyup', ['$event'])
        onKeyUp(event: KeyboardEvent): void {
            if (event.key === 'Enter' && this.ref) {
                this.saveClick()
            }
        }
}
