<div>
    <div class="card border shadow m-3">
        <div class="card-header d-flex justify-content-between align-items-center">
            <div>
                Information
            </div>
            <div *ngIf="wholesalerViewmodel?.customer?.lastEdited && wholesalerViewmodel?.customer?.lastEditedBy" class="text-white">
                Last Edited by: {{wholesalerViewmodel?.customer?.lastEditedBy}} on {{wholesalerViewmodel?.customer?.lastEdited | date: dateFormat}}
            </div>
        </div>
        <div class="card-body">
            <div *ngIf="wholesalerViewmodel?.customer" class="row row-cols-3 pb-2 ml-5 mr-5">
                <div class="col-4">
                    <div class="mb-4 pb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="text-teal m-0">WHOLESALE INFO</h2>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Customer ID</p>
                            <b>{{wholesalerViewmodel.customer.customerNumber}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">MSA Store</p>
                            <b>{{wholesalerViewmodel.customer.isMsa ? "Yes" : "No"}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">MSA Status</p>
                            <b>{{wholesalerViewmodel.customer.msaStatus}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Store Status</p>
                            <mat-slide-toggle color="accent"
                            [(ngModel)]="wholesalerViewmodel.customer.isActive">
                                {{ wholesalerViewmodel.customer.isActive ? 'Active' : 'Inactive' }}</mat-slide-toggle>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Geo Area</p>
                            <b>{{wholesalerViewmodel.displayZrt}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Manager</p>
                            <b>{{wholesalerViewmodel.manager}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Created</p>
                            <b>{{wholesalerViewmodel.customer.dateCreated | date: dateTimeFormat}}</b>
                        </div>
                        <div *ngIf="wholesalerViewmodel.customer.deactivatedDate"
                            class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Deactivated</p>
                            <b>{{wholesalerViewmodel.customer.deactivatedDate | date: dateTimeFormat}}</b>
                        </div>
                        <div *ngIf="wholesalerViewmodel.customer.reactivatedDate &&
                            wholesalerViewmodel.customer.reactivatedDate > wholesalerViewmodel.customer.deactivatedDate"
                            class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Reactivated</p>
                            <b>{{wholesalerViewmodel.customer.reactivatedDate | date: dateTimeFormat}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Store Type</p>
                            <mat-form-field appearance="outline" color="accent">
                                <mat-label>Store Type</mat-label>
                                <mat-select [(value)]="wholesalerViewmodel?.customer.customerType.id">
                                    <mat-option *ngFor="let type of customerTypes"
                                    [value]="type.id">
                                        {{type.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Program</p>
                            <mat-form-field appearance="outline" color="accent">
                                <mat-label>Program</mat-label>
                                <mat-select [(ngModel)]="wholesalerViewmodel.customer.program">
                                    <mat-option
                                        [value]="">
                                    </mat-option>
                                    <mat-option
                                        [value]="'VWA'">
                                        VWA
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Group</p>
                            <b>{{wholesalerViewmodel.groupName}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Group Rep</p>
                            <b></b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Order Type</p>
                            <b>{{wholesalerViewmodel.orderType}}</b>
                        </div>
                        <div class="border-bottom d-flex justify-content-between align-items-center py-2">
                            <p class="mb-0">Store Phone</p>
                            <mat-form-field appearance="outline" color="accent">
                                <mat-label id="phone-number">Phone Number</mat-label>
                                <input type="text" [formControl]="phoneFormControl" [value]="
                                wholesalerViewmodel?.customer?.phone
                                    " placeholder="Ex. (555) 555-5555" [mask]="'(000) 000-0000'" matInput />
                                <mat-error *ngIf="phoneFormControl.hasError('pattern')">
                                    Please enter a valid 10 digit phone number!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="d-flex justify-content-start align-items-center py-2">
                            <mat-checkbox  [(ngModel)]="wholesalerViewmodel.isCashAndCarry" class="mr-1">Cash & Carry</mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="mb-4 pb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="text-teal m-0 pb-2">LICENSES</h2>
                        </div>
                        <div class="license-info d-flex justify-content-between align-items-center py-2">
                            <div class="d-flex align-items-center">
                                <fa-icon [icon]="faReceipt" class="license-icon"></fa-icon>
                                <div>
                                    <p class="license-type">TOBACCO LICENSE NUMBER</p>
                                    <p *ngIf="wholesalerViewmodel?.retailStateOTPLicenseLastUpdated" class="mb-0">
                                        Updated on {{wholesalerViewmodel?.retailStateOTPLicenseLastUpdated | date: dateFormat}}
                                    </p>
                                </div>
                            </div>
                            <mat-form-field appearance="outline" color="accent">
                                <mat-label>Tobacco License</mat-label>
                                <input matInput
                                    [(ngModel)]="wholesalerViewmodel.retailStateOTPLicense"
                                    placeholder="Type your tobacco license number..." maxlength="50">
                                <mat-hint align="end"
                                                    >{{
                                                        wholesalerViewmodel?.retailStateOTPLicense
                                                            ?.length || 0
                                                    }}/50</mat-hint
                                                >
                            </mat-form-field>
                        </div>
                        <div class="license-info d-flex justify-content-between align-items-center py-2">
                            <div class="d-flex align-items-center">
                                <fa-icon [icon]="faReceipt" class="license-icon"></fa-icon>
                                <div>
                                    <p class="license-type">VAPOR LICENSE NUMBER</p>
                                    <p *ngIf="wholesalerViewmodel?.retailStateVaporLicenseLastUpdated" class="mb-0">
                                        Updated on {{wholesalerViewmodel?.retailStateVaporLicenseLastUpdated | date: dateFormat}}
                                    </p>
                                </div>
                            </div>
                            <mat-form-field appearance="outline" color="accent">
                                <mat-label>Vapor License</mat-label>
                                <input matInput
                                    [(ngModel)]="wholesalerViewmodel.retailStateVaporLicense"
                                    placeholder="Type your vapor license number..." maxlength="50">
                                <mat-hint align="end"
                                                    >{{
                                                        wholesalerViewmodel?.retailStateVaporLicense
                                                            ?.length || 0
                                                    }}/50</mat-hint
                                                >
                            </mat-form-field>
                        </div>
                        <div class="license-info d-flex justify-content-between align-items-center py-2">
                            <div class="d-flex align-items-center">
                                <fa-icon [icon]="faReceipt" class="license-icon"></fa-icon>
                                <div>
                                    <p class="license-type">STATE TAX ID NUMBER</p>
                                    <p *ngIf="wholesalerViewmodel?.stateTaxIdLastUpdated" class="mb-0">
                                        Updated on {{wholesalerViewmodel?.stateTaxIdLastUpdated | date: dateFormat}}</p>
                                </div>
                            </div>
                            <mat-form-field appearance="outline" color="accent">
                                <mat-label>State Tax ID</mat-label>
                                <input matInput
                                [(ngModel)]="wholesalerViewmodel.stateTaxId"
                                    placeholder="Type your State tax ID license number..." maxlength="50">
                                    <mat-hint align="end"
                                                    >{{
                                                        wholesalerViewmodel?.stateTaxId
                                                            ?.length || 0
                                                    }}/50</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="license-info d-flex justify-content-between align-items-center py-2">
                            <div class="d-flex align-items-center">
                                <fa-icon [icon]="faReceipt" class="license-icon"></fa-icon>
                                <div>
                                    <p class="license-type">FEDERAL TAX ID NUMBER</p>
                                    <p *ngIf="wholesalerViewmodel?.federalTaxIdLastUpdated" class="mb-0">
                                        Updated on {{wholesalerViewmodel?.federalTaxIdLastUpdated | date: dateFormat}}
                                    </p>
                                </div>
                            </div>
                            <mat-form-field appearance="outline" color="accent">
                                <mat-label>Federal Tax ID</mat-label>
                                <input matInput
                                    [(ngModel)]="wholesalerViewmodel.federalTaxId"
                                    placeholder="Type your Federal Tax ID license number..." maxlength="50">
                                <mat-hint align="end"
                                                    >{{
                                                        wholesalerViewmodel?.federalTaxId
                                                            ?.length || 0
                                                    }}/50</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div>
                        <h2 class="text-teal">WHOLESALE COMMENTS</h2>
                        <mat-form-field appearance="outline" color="accent" class="w-100">
                            <mat-label>Wholesale Comments</mat-label>
                            <textarea matInput
                                [(ngModel)]="wholesalerViewmodel?.customer.comments"
                                placeholder="Enter Wholesale Comments.." maxlength="4000">
                        </textarea>
                        <mat-hint align="end"
                                                    >{{
                                                        wholesalerViewmodel?.customer?.comments
                                                            ?.length || 0
                                                    }}/4000</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-4">
                    <div class="mb-4 pb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="text-teal m-0 pb-2">BUSINESS ADDRESS</h2>
                        </div>
                        <div>
                            <div>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>Business/Company Name</mat-label>
                                    <input matInput
                                        [(ngModel)]="wholesalerViewmodel?.customer?.businessAddress.name"
                                        placeholder="Wholesale Name" maxlength="100">
                                        <mat-hint align="end"
                                                    >{{
                                                        wholesalerViewmodel?.customer?.businessAddress?.name
                                                            ?.length || 0
                                                    }}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>Address 1</mat-label>
                                    <input matInput
                                        [(ngModel)]="wholesalerViewmodel?.customer?.businessAddress.address1"
                                        placeholder="Address 1" maxlength="100">
                                        <mat-hint align="end"
                                        >{{
                                            wholesalerViewmodel?.customer?.businessAddress?.address1
                                                ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>Address 2</mat-label>
                                    <input matInput
                                        [(ngModel)]="wholesalerViewmodel?.customer?.businessAddress.address2"
                                        placeholder="Address 2" maxlength="100">
                                        <mat-hint align="end"
                                        >{{
                                            wholesalerViewmodel?.customer?.businessAddress?.address2
                                                ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="d-flex justify-content-between">
                                <mat-form-field appearance="outline" color="accent" class="w-100 mr-1">
                                    <mat-label>City</mat-label>
                                    <input matInput
                                        [(ngModel)]="wholesalerViewmodel?.customer?.businessAddress.city"
                                        placeholder="City" maxlength="100">
                                        <mat-hint align="end"
                                        >{{
                                            wholesalerViewmodel?.customer?.businessAddress?.city
                                                ?.length || 0
                                        }}/100</mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="accent" class="w-100">
                                    <mat-label>State</mat-label>
                                    <mat-select
                                    [(value)]="wholesalerViewmodel?.customer?.businessAddress.state">
                                        <mat-option *ngFor="let vm of states"
                                            [value]="vm.shortCode"
                                        >
                                        {{vm.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="accent" class="w-25">
                                    <mat-label>Zip Code</mat-label>
                                    <input matInput
                                        [(ngModel)]="wholesalerViewmodel?.customer?.businessAddress.zip"
                                        placeholder="Zip" maxlength="9" mask="999999999" digitOnly>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mb-4 pb-3">
                        <div *ngIf="wholesalerViewmodel?.customer?.dbaAddress">
                            <div class="d-flex align-items-center">
                                <h2 class="text-teal m-0 pb-2">DRIVING/DBA ADDRESS</h2>
                            </div>
                            <div>
                                <div>
                                    <mat-form-field appearance="outline" color="accent" class="w-100">
                                        <mat-label>Customer Name</mat-label>
                                        <input matInput
                                            [(ngModel)]="wholesalerViewmodel.customer.dbaAddress.name"
                                            placeholder="Wholesale Name" maxlength="100">
                                            <mat-hint align="end"
                                            >{{
                                                wholesalerViewmodel.customer.dbaAddress.name
                                                    ?.length || 0
                                            }}/100</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field appearance="outline" color="accent" class="w-100">
                                        <mat-label>Address 1</mat-label>
                                        <input matInput
                                            [(ngModel)]="wholesalerViewmodel.customer.dbaAddress.address1"
                                            placeholder="Address 1" maxlength="100">
                                            <mat-hint align="end"
                                            >{{
                                                wholesalerViewmodel.customer.dbaAddress.address1
                                                    ?.length || 0
                                            }}/100</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field appearance="outline" color="accent" class="w-100">
                                        <mat-label>Address 2</mat-label>
                                        <input matInput
                                            [(ngModel)]="wholesalerViewmodel.customer.dbaAddress.address2"
                                            placeholder="Address 2" maxlength="100">
                                            <mat-hint align="end"
                                            >{{
                                                wholesalerViewmodel.customer.dbaAddress.address2
                                                    ?.length || 0
                                            }}/100</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <mat-form-field appearance="outline" color="accent" class="w-100 mr-1">
                                        <mat-label>City</mat-label>
                                        <input matInput
                                            [(ngModel)]="wholesalerViewmodel.customer.dbaAddress.city"
                                            placeholder="City" maxlength="100">
                                            <mat-hint align="end"
                                            >{{
                                                wholesalerViewmodel.customer.dbaAddress.city
                                                    ?.length || 0
                                            }}/100</mat-hint>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" color="accent" class="w-100">
                                        <mat-label>State</mat-label>
                                        <mat-select
                                        [(value)]="wholesalerViewmodel.customer.dbaAddress.state">
                                            <mat-option *ngFor="let vm of states"
                                                [value]="vm.shortCode"
                                            >
                                            {{vm.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" color="accent" class="w-25">
                                        <mat-label>Zip Code</mat-label>
                                        <input matInput
                                            [(ngModel)]="wholesalerViewmodel.customer.dbaAddress.zip"
                                            placeholder="Zip" maxlength="9" mask="999999999" digitOnly>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer bg-m-gray">
            <div class="d-flex justify-content-end align-items-center">
                <button appThrottleClick class="btn btn-lg btn-secondary mr-2 pl-5 pr-5" (throttledClick)="openDialog()">
                    Cancel
                </button>
                <button appThrottleClick class="btn btn-lg btn-primary ml-2 pl-5 pr-5"
                (throttledClick)="save()">
                    <fa-icon [icon]="faSave" class="mr-2 text-white"></fa-icon>
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
