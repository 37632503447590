import { CallTypes } from "../enum";
import { CallCashProductDto } from "./call-cash-product.dto";
import { CallGratisProductDto } from "./call-gratis-product.dto";
import { CallOrderProductDto } from "./call-order-product.dto";
import { CallExchangeInProductDto } from "./call-exchange-in-product.dto";
import { CallExchangeOutProductDto } from "./call-exchange-out-product.dto";
import { CallPictureDto } from "./call-picture.dto";
import { CallReceiptDto } from "./call-receipt.dto";
import { CallSurveyAnswerDto } from "./call-survey-answer.dto";
import { CallProductStatusDto } from "./call-product-status.dto";
import { ContactDto } from "./contact.dto";
import { PictureDto } from "./picture.dto";
import { ReceiptDto } from "./receipt.dto";
import { OnlineReferenceDto } from "./online-reference.dto";
export class RetailCallDto {

    constructor(){
        this.callType = CallTypes.retail;
    }

    id: string;
    callNumber: string;
    customerId: string;

    createdUserId: string;
    createdUtcDateTime: string;
    createdUserZrt: string;
    createdUserFullName: string
    modifiedUtcDateTime: string;
    modifiedUserId: string;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string;
    startTime: string;
    stopTime: string;
    px3RankId?: string;
    px3Rank?: string;

    closingNotes: string;

    cashProducts: CallCashProductDto[];
    orderProducts: CallOrderProductDto[];
    gratisProducts: CallGratisProductDto[];
    exchangeInProducts: CallExchangeInProductDto[];
    exchangeOutProducts: CallExchangeOutProductDto[];
    callPictures: CallPictureDto[];
    pictures: PictureDto[];
    selectedContact: ContactDto;

    inDistProductIds: string[];
    unsalableProductIds: string[];
    callProductStatus: CallProductStatusDto[];
    callReceipts: CallReceiptDto[];
    receipts: ReceiptDto[];
    callSurveyAnswers: CallSurveyAnswerDto[]

    callType: CallTypes;
    isDeleted: boolean;

    rowversion: string;
}
