<form [formGroup]="viewmodel?.manualGratisFormGroup">
    <div class="row">
        <div class="col h-center">
            <mat-form-field appearance="outline" color="accent" class="w-100">
                <mat-label>Reason</mat-label>
                <textarea matInput type="text" formControlName="reasonControl" placeholder="Please enter the reason."
                    maxlength="200" required></textarea>
                <mat-hint align="end">{{
                    viewmodel.reason
                    ?.length || 0
                    }}/200</mat-hint>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-6 h-center">
            <mat-form-field appearance="outline" color="accent" class="w-100">
                <mat-label>Date</mat-label>
                <input #dateInput matInput formControlName="dateControl" [matDatepicker]="datePicker" required/>
                <mat-datepicker touchUi #datePicker></mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            </mat-form-field>
        </div>
        <div class="col-6 h-center">
            <mat-form-field appearance="outline" color="accent" class="w-100">
                <mat-label>Amount</mat-label>
                <input matInput #amountInput placeholder="Amount" maxlength="9" formControlName="amountControl" currencyMask required
                (mouseup)="viewmodel.select(amountInput)">
            </mat-form-field>
        </div>
    </div>
</form>
