import { WholesalerProductCatalogItemDto } from "shield.shared";
import { WholesalerProductCatalogItems } from "src/app/entity-models/wholesaler-product-catalog-items.entity";

export class WholesaleProductCatalogItemConverter {
    static entityToDto(entity: WholesalerProductCatalogItems): WholesalerProductCatalogItemDto {
        return {
            ...entity,
            dateAvailable: entity.dateAvailable ? entity.dateAvailable.toISOString() : undefined,
            product: null,
            isDeactivatedDate: entity.isDeactivatedDate ? entity.isDeactivatedDate.toISOString() : undefined,
        };
    }

    static dtoToEntity(dto: WholesalerProductCatalogItemDto): WholesalerProductCatalogItems {
        return {
            ...dto,
            dateAvailable: dto.dateAvailable ? new Date(dto.dateAvailable) : undefined,
            isDeactivatedDate: dto.isDeactivatedDate ? new Date(dto.isDeactivatedDate) : undefined,
        };
    }
}
