import { CreateModifyBaseDto } from "../create-modify-base.dto";
import { ProductDto } from "./product.dto";

export class WholesalerProductCatalogItemDto {
    id: string;
    wholesalerGroupProductCatalogItemId: string;
    wholesalerId: string;
    product: ProductDto | undefined;
    productId: string;
    wholesalePrice?: number;
    dateAvailable?: string;
    isDeactivated: boolean;
    isDeactivatedDate?: string;
}
