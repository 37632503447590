import { GenericLookup } from "../generic";

export enum OrderStatuses {
    Pending = 1,
    Waiting = 2,
    Accepted = 3,
    Rejected = 4,
    Processed = 5,
    Cancelled = 6
}

export const OrderStatusLookup: GenericLookup<OrderStatuses>[] = [
    { id: OrderStatuses.Pending, name: "Pending", description: "Pending" },
    { id: OrderStatuses.Waiting, name: "Waiting", description: "Waiting for Wholesaler" },
    { id: OrderStatuses.Accepted, name: "Accepted", description: "Accepted" },
    { id: OrderStatuses.Rejected, name: "Rejected", description: "Rejected" },
    { id: OrderStatuses.Processed, name: "Processed", description: "Processed" },
    { id: OrderStatuses.Cancelled, name: "Cancelled", description: "Cancelled" }
];
