<div #myFilter>
    <div class="d-flex justify-content-between align-items-center">
        <div class="w-100"><app-zrt-tree-view></app-zrt-tree-view></div>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100" [formGroup]="viewmodel.dateForm">
            <mat-label>Start on or After</mat-label>
            <input #startDateInput matInput
                formControlName="startDate"
                [max]="viewmodel.startMaxDate"
                [matDatepicker]="afterPicker"
                (blur)="onAddStartDateRefiner($event)"
                (dateChange)="onAddStartDateRefiner()" />
            <mat-datepicker touchUi #afterPicker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="afterPicker"></mat-datepicker-toggle>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100" [formGroup]="viewmodel.dateForm">
            <mat-label>Start on or Before</mat-label>
            <input #endDateInput matInput
                formControlName="endDate"
                [min]="viewmodel.endMinDate"
                [max]="viewmodel.endMaxDate"
                [matDatepicker]="beforePicker"
                (blur)="onAddEndDateRefiner($event)"
                (dateChange)="onAddEndDateRefiner()" />
            <mat-datepicker touchUi #beforePicker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="beforePicker"></mat-datepicker-toggle>
        </mat-form-field>
    </div>
</div>
