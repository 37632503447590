import { Component, OnDestroy, OnInit } from "@angular/core";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { InvoiceDetailsViewmodel } from "./invoice-details.viewmodel";
import { Helper } from "src/app/helpers/helper";
import { NotificationDelineationService } from "src/app/services/delineation-services/notification-delineation.service";

@Component({
    selector: "app-invoice-details",
    templateUrl: "./invoice-details.component.html",
    styleUrls: ["./invoice-details.component.scss"]
})
export class InvoiceDetailsComponent implements OnInit, OnDestroy {
    viewmodel: InvoiceDetailsViewmodel;
    dateTimeFormat = MY_DATE_FORMATS.display.customDateTimeInput;

    formatDisplayName = Helper.formatDisplayName;

    constructor(
        private overlayRef: SwisherOverlayRef<
            InvoiceDetailsViewmodel,
            InvoiceDetailsComponent
        >,
        private notificationDelineationService: NotificationDelineationService
    ) { }

    async ngOnInit(): Promise<void> {

        this.viewmodel = this.overlayRef.data;
        this.viewmodel.initialize();
    }

    ngOnDestroy(): void {

        for (const subscription of this.viewmodel.cashSalesFormSubscriptions) {
            if (subscription && !subscription.closed) {
                subscription.unsubscribe();
            }
        }
        for (const subscription of this.viewmodel
            .cashDiscountFormSubscriptions) {
            if (subscription && !subscription.closed) {
                subscription.unsubscribe();
            }
        }
    }

    setValidations(): void {

        for (let i = 0; i < this.viewmodel.cashSalesItems.length; i++) {
            this.viewmodel.cashSalesFormSubscriptions[
                i
            ] = this.viewmodel.cashSalesForm.controls[
                "cashPrice" + i
            ].valueChanges.subscribe((value) => {
                this.viewmodel.cashSalesItems[i].retailPrice = value ?? 0;
                if (!value && value !== 0) {
                    this.viewmodel.cashSalesForm.controls[
                        "cashPrice" + i
                    ].setValue(0);
                }
                if (
                    !this.viewmodel.cashSalesForm.controls[
                        "cashPrice" + i
                    ]?.hasError("fail") &&
                    this.viewmodel.cashSalesForm.controls[
                        "discountPrice" + i
                    ]?.hasError("fail")
                ) {
                    this.viewmodel.cashSalesForm.controls[
                        "discountPrice" + i
                    ].updateValueAndValidity();
                }
            });
            this.viewmodel.cashDiscountFormSubscriptions[
                i
            ] = this.viewmodel.cashSalesForm.controls[
                "discountPrice" + i
            ]?.valueChanges.subscribe((value) => {
                this.viewmodel.cashSalesItems[i].discount = value ?? 0;
                if (!value && value !== 0) {
                    this.viewmodel.cashSalesForm.controls[
                        "discountPrice" + i
                    ].setValue(0);
                }
                if (
                    !this.viewmodel.cashSalesForm.controls[
                        "discountPrice" + i
                    ]?.hasError("fail") &&
                    this.viewmodel.cashSalesForm.controls[
                        "cashPrice" + i
                    ]?.hasError("fail")
                ) {
                    this.viewmodel.cashSalesForm.controls[
                        "cashPrice" + i
                    ].updateValueAndValidity();
                }
            });
        }

        for (let i = 0; i < this.viewmodel.exchangeOutItems.length; i++) {
            this.viewmodel.exchangeOutWholesaleFormSubscriptions[
                i
            ] = this.viewmodel.exchangeOutSalesForm.controls[
                "exchangeOutWholesalePrice" + i
            ]?.valueChanges.subscribe((value) => {
                this.viewmodel.exchangeOutItems[i].wholesalePrice =
                    value ?? 0;
                if (!value && value !== 0) {
                    this.viewmodel.exchangeOutSalesForm.controls[
                        "exchangeOutWholesalePrice" + i
                    ].setValue(0);
                }
            });
        }

        for (let i = 0; i < this.viewmodel.exchangeInItems.length; i++) {
            this.viewmodel.exchangeInWholesaleFormSubscriptions[
                i
            ] = this.viewmodel.exchangeInSalesForm.controls[
                "exchangeInWholesalePrice" + i
            ]?.valueChanges.subscribe((value) => {
                this.viewmodel.exchangeInItems[i].wholesalePrice =
                    value ?? 0;
                if (!value && value !== 0) {
                    this.viewmodel.exchangeInSalesForm.controls[
                        "exchangeInWholesalePrice" + i
                    ].setValue(0);
                }
            });
        }

    }
}
