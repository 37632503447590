<div #myFilter>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label>UIN</mat-label>
            <input type="text" (keyup)="onAddUinRefiner($event)" (blur)="onAddUinRefiner()"
                [(ngModel)]="viewmodel.uinInput" matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label>Swisher Item Number</mat-label>
            <input type="text" (keyup)="onAddItemNumberRefiner($event)" (blur)="onAddItemNumberRefiner()"
                [(ngModel)]="viewmodel.itemNumberInput" matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label>Description</mat-label>
            <input type="text" (keyup)="onAddDescriptionRefiner($event)" (blur)="onAddDescriptionRefiner()"
                [(ngModel)]="viewmodel.descriptionInput" matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label>Price</mat-label>
            <input type="text" (keyup)="onAddPriceRefiner($event)" (blur)="onAddPriceRefiner()"
                [(ngModel)]="viewmodel.priceInput" matInput currencyMask [options]="viewmodel.priceOptions" />
        </mat-form-field>
    </div>
</div>
