<div
    class="basic-modal"
    cdkDrag
    cdkDragBoundary=".cdk-overlay-container"
    *ngIf="viewmodel"
>
    <div cdkDragHandle>
        <div class="modal-header">
            {{ viewmodel.headerLeftText }}
            <div class="col text-right">
                <span *ngIf="viewmodel.headerRightText">
                    {{ viewmodel.headerRightText }}
                </span>
                <div *ngIf="viewmodel.headerRightButtonText">
                    <button class="btn btn-secondary" (click)="viewmodel.headerRightButtonClick$?.emit()">
                        {{ viewmodel.headerRightButtonText }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body"
        [style]="{
            height: viewmodel.height?.length > 0 ? viewmodel.height : 'auto',
            width: viewmodel.width?.length > 0 ? viewmodel.width : 'auto'
        }">
        <div>
            <ng-content></ng-content>
        </div>
    </div>
    <div *ngIf="viewmodel.showFooter" class="modal-footer">
        <button-list [buttons]="viewmodel.iconButtons" (onButtonClick)="viewmodel.iconButtonClick$.next($event)">
        </button-list>
        <div class="w-100"></div>
        <button *ngIf="viewmodel.buttonLeftText"
            mat-raised-button
            class="btn-white actionBtn"
            [disabled]="viewmodel.buttonLeftDisabledFunction()"
            (click)="viewmodel.buttonLeftFunction()"
        >
            {{ viewmodel.buttonLeftText }}
        </button>
        <button *ngIf="viewmodel.buttonMiddleText"
            mat-raised-button
            class="btn-white actionBtn"
            [disabled]="viewmodel.buttonMiddleDisabledFunction()"
            (click)="viewmodel.buttonMiddleFunction()"
        >
            {{ viewmodel.buttonMiddleText }}
        </button>
        <button *ngIf="viewmodel.buttonRightText"
            mat-raised-button
            class="sw-red-btn actionBtn"
            [disabled]="viewmodel.buttonRightDisabledFunction()"
            (click)="viewmodel.buttonRightFunction()"
        >
            {{ viewmodel.buttonRightText }}
        </button>
    </div>
</div>
