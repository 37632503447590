import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { apiVersion, CustomerContractPaymentDto, CustomerContractPaymentSyncCommand,
    GenericRequestDto, GenericResponseDto, SyncCommandTypes } from "shield.shared";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { CustomerContractPayment } from "src/app/entity-models/customer-contract-payment.entity";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { DatabaseService } from "src/app/services/database.service";
import { SyncLevel } from "../sync-enums/sync-level.enum";

@Injectable()
export class CustomerContractPaymentDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    static apiVersion = apiVersion;
    onlineRepSyncType = SyncLevel.None;
    offlineRepSyncType = SyncLevel.Required;
    isRunSuccessfull = true;
    syncVersionKey = SyncVersionKeyNames.customerContractPayment;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        return;
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<CustomerContractPaymentSyncCommand>(
            SyncCommandTypes.customerContractPayment,
            async (message) => {
                let dto = message.payload.data;

                if (dto) {
                    await this.syncQueue.pushCommand(dto, message);
                }

            }
        );
    }
}
