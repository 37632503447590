import { Helper } from "src/app/helpers/helper";
import * as moment from "moment";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { Address } from "src/app/entity-models/address.entity";
import { CallHistoryEntry } from "src/app/entity-models/call-history-entry.entity";
import { OnlineReference } from "src/app/entity-models/online-reference.entity";
import { CallTypes } from "shield.shared";

export class CallTableRowViewModel {
    closingNotes: string;
    closingNotesFormatted: string;

    constructor(entry: CallHistoryEntry, px3Rank?: string) {
        const closingNotesFormatted = entry.closingNotes?.slice(0, 50);
        const beforePicture = entry.pictures?.find((p) => p.type === "Before");
        const afterPicture = entry.pictures?.find((p) => p.type === "After");

        return { ...entry,
            callType: entry.callType ? entry.callType == CallTypes.rmWholesale ? CallTypes.wholesale : entry.callType : "",
            callDate: moment(entry.stopTime).format(MY_DATE_FORMATS.display.dateInput),
            closingNotesFormatted: closingNotesFormatted
                ? closingNotesFormatted +
                    (closingNotesFormatted.length < entry.closingNotes?.length ? "..." : "")
                : "",
            duration: Helper.getHoursAndMinutesFromMinutes(
                entry.durationMins
            ),
            startFormatted: moment(entry.startTime).format(
                MY_DATE_FORMATS.display.customMomentDateTime
            ),
            endFormatted: moment(entry.stopTime).format(
                MY_DATE_FORMATS.display.customMomentDateTime
            ),
            hasPictures: entry.hasPictures ? "Yes" : "No",
            px3Rank: px3Rank,
            beforePicture: beforePicture,
            afterPicture: afterPicture,
            otherPictures: entry.pictures?.filter((p) => p.type !== "Before" && p.type !== "After"),
            beforePictureTags: beforePicture?.tags
                ? Helper.valueSeparatedStringToFormattedString(beforePicture.tags)
                : "N/A",
            otherPictureTags: afterPicture?.tags
                ? Helper.valueSeparatedStringToFormattedString(afterPicture.tags)
                : "N/A",
            otherPictureCaption: "After"
        };
    }

    createdByName: string;
    callDate: string;
    duration: string;
    customerName: string;
    customerNumber: string;
    customerId?: string;
    businessAddress?: Address;
    address: string;
    city: string;
    state: string;
    county: string;
    zip: string;
    startFormatted: string;
    endFormatted: string;
    callType: string;
    hasPictures: string;
    hasBeforePicture: boolean;
    hasAfterPicture: boolean;
    px3Rank?: string;

    beforePicture?: OnlineReference;
    afterPicture?: OnlineReference;
    otherPictures?: OnlineReference[];

    beforePictureTags?: string;
    otherPictureTags?: string;
    otherPictureCaption?: string;
}
