import { Injectable } from "@angular/core";
import { CustomerContractPaymentDto, CustomerContractPaymentSyncCommand } from "shield.shared";
import { CustomerContractPayment } from "src/app/entity-models/customer-contract-payment.entity";
import { CustomerContract } from "src/app/entity-models/customer-contract.entity";
import { Signature } from "src/app/entity-models/signature.entity";
import { DatabaseService } from "src/app/services/database.service";
import { CustomerContractPaymentDataSyncHandler } from "src/app/sync/data-sync-handlers/customer-contract-payment-data-sync-handler";
import { DataSyncQueueService } from "src/app/sync/data-sync-queue.service";
import { CustomerContractPaymentConverterService } from "../converter-services/customer-contract-payment-converter.service";

@Injectable()
export class ContractPaymentOfflineService {

    constructor(
        private dbService: DatabaseService,
        private dataSyncQueueService: DataSyncQueueService
    ) {}

    async getContractPaymentSignatures(customerContract: CustomerContract): Promise<Signature[]> {

        const rtn = new Array<Signature>();
        const dexieCustomerContract = await this.dbService.customerContracts.where("id").equals(customerContract.id).first();
        if (dexieCustomerContract) {
            customerContract = dexieCustomerContract;
        }

        for (const payment of customerContract.customerContractPayments) {
            rtn.push(payment.customerContactSignature);
        }

        return rtn;
    }

    async updateAndSavePayments(contract: CustomerContract, signatures: Signature[]): Promise<void> {
        if (contract?.customerContractPayments?.length && signatures?.length) {
            signatures = signatures.filter((sig) => !!sig);
            for (const payment of contract.customerContractPayments) {
                payment.customerContactSignature = signatures.find((sig) => sig.id === payment.id);
            }
            await this.dbService.customerContracts.put(contract);
        }
    }

    async upsertCustomerContractPayment(dto: CustomerContractPaymentDto): Promise<CustomerContractPayment> {

        if (dto) {

            await this.dbService.transaction(
                "rw",
                this.dbService.syncQueue,
                async () => {
                    await this.dataSyncQueueService.enqueue(
                        new CustomerContractPaymentSyncCommand(
                            dto
                        )
                    );
                }
            );

            return  CustomerContractPaymentConverterService.customerContractPaymentDtoToCustomerContractPayment(dto);
        }
    }
}
