import {
    SharedHelper,
    WholesalerGroupDto,
    WholesalerGroupEntryDto
} from "shield.shared";
import { WholesalerGroupEntry } from "src/app/entity-models/wholesaler-group-entry.entity";
import { WholesalerGroup } from "src/app/entity-models/wholesaler-group.entity";

export class WholesalerGroupConverterService {

    static wholesalerGroupEntryDtoToWholesalerGroupEntry(
        dto: WholesalerGroupEntryDto
    ): WholesalerGroupEntry {
        return {
            ...dto,
            groupMembers: dto.groupMembers.map(member => { return { ...member } }),
        }
    }

    static entityToDto(entity: WholesalerGroup): WholesalerGroupDto {

        const dto = new WholesalerGroupDto();

        dto.id = entity.id;
        dto.name = entity.name;
        dto.externalSyncId = entity.externalSyncId;
        dto.orderGroupName = entity.orderGroupName;
        dto.orderType = entity.orderType;
        SharedHelper.createdModifiedEntityToDto(entity, dto);
        dto.rowversion = entity.rowversion;
        return dto;
    }

    static dtoToEntity(dto: WholesalerGroupDto): WholesalerGroup {

        const entity = new WholesalerGroup();

        entity.id = dto.id;
        entity.name = dto.name;
        entity.externalSyncId = dto.externalSyncId;
        entity.orderGroupName = dto.orderGroupName;
        entity.orderType = dto.orderType;
        SharedHelper.createdModifiedDtoToEntity(dto, entity);
        entity.rowversion = entity.rowversion;
        return entity;
    }
}
