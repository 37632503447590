import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {QueryParams} from './splash.types';

@Component({
    selector: "splash",
    templateUrl: "./splash.component.html"
})

export class SplashComponent implements OnInit {
    params: QueryParams = null;
    title = "Welcome back!";
    subtitle = "Please sign in to your account";
    buttonText = "Sign in";
    buttonLink = `/`;

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.queryParams
            .subscribe((params: QueryParams) => {
                    this.params = params;

                    if (params.isTimeout) {
                        this.title = "Account Timeout";
                        this.subtitle = "You've been signed out due to inactivity.";
                        this.buttonText = "Sign back in";
                        this.buttonLink = "/";
                    } else if (params.isLogout) {
                        this.title = "See you later!";
                        this.subtitle = "Sign back in to access your account";
                        this.buttonText = "Sign back in";
                        this.buttonLink = "/";
                    } else {
                        this.title = "Welcome back!";
                        this.subtitle = "Please sign into your account";
                        this.buttonText = "Sign in";
                        this.buttonLink = "/";
                    }
                }
            );
    }
}
