import { EmployeeDto, RegisteredUserDto, SearchZrtDropDownDto } from "shield.shared";
import { Employee } from "src/app/entity-models/employee.entity";
import { RegisteredUser } from "src/app/entity-models/registered-user-entity";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";

export class RegisteredUserConverterService {

    static registeredUserDtoToRegisteredUser(dto: RegisteredUserDto): RegisteredUser {
        const rtn = new RegisteredUser();

        rtn.id = dto.id;
        rtn.userId = dto.userId;
        rtn.employeeId = dto.employeeId;
        rtn.otherPhone = dto.otherPhone;
        rtn.lastSeenUtcDateTime = dto.lastSeenUtcDateTime ? new Date(dto.lastSeenUtcDateTime) : undefined;
        rtn.lastSyncAttemptUtcDateTime = dto.lastSyncAttemptUtcDateTime ? new Date(dto.lastSyncAttemptUtcDateTime) : undefined;
        rtn.lastCompletedSyncCycleUtcDateTime = dto.lastCompletedSyncCycleUtcDateTime ? new Date(dto.lastCompletedSyncCycleUtcDateTime) : undefined;
        rtn.createdUtcDateTime = dto.createdUtcDateTime ? new Date(dto.createdUtcDateTime) : undefined;
        rtn.createdUserId = dto.createdUserId;
        rtn.createdUserZrt = dto.createdUserZrt;
        rtn.createdUserFullName = dto.createdUserFullName;
        rtn.modifiedUtcDateTime = dto.modifiedUtcDateTime ? new Date(dto.modifiedUtcDateTime) : undefined;
        rtn.modifiedUserId = dto.modifiedUserId;
        rtn.modifiedUserZrt = dto.modifiedUserZrt;
        rtn.modifiedUserFullName = dto.modifiedUserFullName;
        rtn.rowversion = dto.rowversion;

        return rtn;
    }

    static registeredUserToRegisteredUserDto(entity: RegisteredUser): RegisteredUserDto {
        const rtn = new RegisteredUserDto();

        rtn.id = entity.id;
        rtn.userId = entity.userId;
        rtn.employeeId = entity.employeeId;
        rtn.otherPhone = entity.otherPhone;
        rtn.lastSeenUtcDateTime = entity.lastSeenUtcDateTime ? entity.lastSeenUtcDateTime.toISOString() : undefined;
        rtn.lastSyncAttemptUtcDateTime = entity.lastSyncAttemptUtcDateTime ? entity.lastSyncAttemptUtcDateTime.toISOString() : undefined;
        rtn.lastCompletedSyncCycleUtcDateTime = entity.lastCompletedSyncCycleUtcDateTime ? entity.lastCompletedSyncCycleUtcDateTime.toISOString(): undefined;
        rtn.createdUtcDateTime = entity.createdUtcDateTime ? entity.createdUtcDateTime.toISOString() : new Date().toISOString();
        rtn.createdUserId = entity.createdUserId;
        rtn.createdUserZrt = entity.createdUserZrt;
        rtn.createdUserFullName = entity.createdUserFullName;
        rtn.modifiedUtcDateTime = entity.modifiedUtcDateTime ? entity.modifiedUtcDateTime.toISOString() : undefined;
        rtn.modifiedUserId = entity.modifiedUserId;
        rtn.modifiedUserZrt = entity.modifiedUserZrt;
        rtn.modifiedUserFullName = entity.modifiedUserFullName;
        rtn.rowversion = entity.rowversion;

        return rtn;
    }
}
