import { Component, Input, OnInit } from '@angular/core';
import { BasicDialogViewModel } from 'src/app/dialogs/basic-dialog/basic-dialog.viewmodel';

@Component({
    selector: 'basic-card',
    templateUrl: './basic-card.component.html',
    styleUrls: ['./basic-card.component.scss']
})
export class BasicCardComponent {

    @Input() viewmodel: BasicDialogViewModel;
    constructor() {  }

    ngOnInit(): void {

    }

}
