<div class="d-flex h-100">
    <div class="card card-project-panel m-0 d-flex flex-column flex-grow-1" [ngClass]="viewmodel.profileView ? 'profile-view' : 'summary-view'">
        <div class="card-header">
            Products
        </div>
        <div class="card-body unscrollable p-0 d-flex flex-column flex-grow-1">
            <ng-container>
                <div class="row d-flex flex-column flex-grow-1">
                    <div *ngIf="viewmodel.checkingProducts || viewmodel.projectProducts?.length"
                        class="col-12 table-responsive d-flex flex-column flex-grow-1">
                        <app-grid #grid
                            [(dataSource)]="viewmodel.dataSource"
                            [columnDefinitions]="viewmodel?.columnDef"
                            [selectedColumnVisability]="viewmodel?.selectedColumnVisibilityComunicator"
                            [isFixedLayout]="false" [height]="viewmodel.profileView ? '68vh' :'30vh'"
                            [isEnabledExpandedDetails]="false"
                            [itemsRendedInViewPort]="viewmodel.profileView ? 12 : 6"
                            [detailHeight]="48"
                            class="d-flex flex-column flex-grow-1">
                        </app-grid>
                    </div>
                    <div *ngIf="!viewmodel.checkingProducts && !viewmodel.projectProducts?.length " class="margin-auto">
                        <b class="missing-data-warning">Project products will not be visible to field until products have been added.</b>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
