import { CustomerTypeEnum } from "../lookup";
import { AddressDto } from "./address.dto";

export class CustomerMarkerDto {
    customerId: string;
    customerNumber: string | null;
    title: string | null;
    customerTypeId: CustomerTypeEnum;
    address: AddressDto | null;
    availability: string | null;
    px3Rank: string | null;
    active: string | null;
    routed: boolean | null;
    lastCall: string | null;
}
