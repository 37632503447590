import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericResponseDto, GenericVersionResponseDto, TaxCalculationRateDto } from "shield.shared";
import { TaxRate } from "src/app/entity-models/tax-rate.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { TaxRateConverterService } from "../converter-services/tax-rate-converter.service";

@Injectable()
export class TaxRateOnlineSerivce {

    constructor(private http: HttpClient) { }

    async getAll(): Promise<GenericResponseDto<TaxRate[]>> {

        const response = await this.http
            .get<GenericVersionResponseDto<TaxCalculationRateDto[]>>(`/api/tax-rate`)
            .toPromise();

        const result = ConverterHelper.genericVersionResponseDtoToGenericResponseDto(response);
        return ConverterHelper.dtoToEntityArray<TaxCalculationRateDto, TaxRate>(result,
            (r) => TaxRateConverterService.taxCalculationRateDtoToTaxRate(r));
    }
}
