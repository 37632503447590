import { Injectable } from "@angular/core";
import { DatabaseService } from "src/app/services/database.service";
import { Contact } from "src/app/entity-models/contact.entity";
import { DataSyncQueueService } from "src/app/sync/data-sync-queue.service";
import { ContactSyncCommand } from "shield.shared";

@Injectable()
export class ContactOfflineService {
    constructor(
        private dbService: DatabaseService,
        private dataSyncQueueService: DataSyncQueueService,
    ) {}

    async getById(id: string): Promise<Contact> {
        return this.dbService.contacts.get(id);
    }

    async getUnprocessedById(id: string): Promise<Contact> {
        return this.dbService.contacts.where("id").equals(id).and(v => !v.hasServerProcessed).first();
    }

    async getByCustomerId(customerId: string): Promise<Contact[]> {
        let contacts = await this.dbService.contacts
            .where("customerId")
            .equals(customerId)
            .toArray();
        return contacts;
    }

    async getUnprocessedByCustomerId(customerId: string): Promise<Contact[]> {
        let contacts = await this.dbService.contacts
            .where("customerId")
            .equals(customerId)
            .filter(v => !v.hasServerProcessed)
            .toArray();
        return contacts;
    }

    async save(contact: Contact): Promise<Contact> {

        if (contact) {
            contact.clientLastTouchedUtcDateTime = new Date();
            contact.hasServerProcessed = 0;

            await this.dbService.transaction(
                "rw",
                this.dbService.contacts,
                this.dbService.syncQueue,
                async () => {
                    await this.dbService.contacts.put(contact);
                    await this.dataSyncQueueService.enqueue(
                        new ContactSyncCommand(contact.id)
                    );
                }
            );
        }

        return contact;
    }
}
