import { GenericLookup } from "../generic";

export enum ProjectStatuses {
    InActive = 0,
    Editable = 1,
    Visible = 2,
    Started = 3,
    Completed = 4,
    Canceled = 5,
    Staging = 6,
}

export const ProjectStatusLookup: GenericLookup<ProjectStatuses>[] = [
    { id: ProjectStatuses.InActive, name: "Inactive", description: "Inactive" },
    { id: ProjectStatuses.Editable, name: "Editable", description: "Editable" },
    { id: ProjectStatuses.Visible, name: "Visible", description: "Visible" },
    { id: ProjectStatuses.Started, name: "Started", description: "Started" },
    { id: ProjectStatuses.Completed, name: "Completed", description: "Completed" },
    { id: ProjectStatuses.Canceled, name: "Canceled", description: "Canceled" },
    { id: ProjectStatuses.Staging, name: "Staging", description: "Staging" },
];

export const VisibleProjectStatuses: ProjectStatuses[] = [
    ProjectStatuses.Canceled, 
    ProjectStatuses.Completed,
    ProjectStatuses.Editable,
    ProjectStatuses.Started,
    ProjectStatuses.Visible
];