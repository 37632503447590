import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { OrderOnlineService } from "src/app/services/online-services/order-online.service";

@Injectable()
export class ProjectFilterService {
    // Employee Options (guids)
    private _employeeOptions: string[] = [];
    employeeOptionsSubject: BehaviorSubject<string[]> = new BehaviorSubject(
        this._employeeOptions
    );
    get employeeOptions(): string[] {
        return this._employeeOptions;
    }
    set employeeOptions(value: string[]) {
        this._employeeOptions = value;
        this.employeeOptionsSubject.next(value);
    }
    observableEmployeeOptions: Observable<string[]> = this.employeeOptionsSubject.asObservable();

    // Chains
    private _chains: AccountOwnership[] = [];
    chainsSubject: BehaviorSubject<AccountOwnership[]> = new BehaviorSubject(
        this._chains
    );
    get chains(): AccountOwnership[] {
        return this._chains;
    }
    set chains(value: AccountOwnership[]) {
        this._chains = value;
        this.chainsSubject.next(value);
    }
    observableChains: Observable<AccountOwnership[]> = this.chainsSubject.asObservable();
    useOwnerCode: boolean = false;

    // Wholesalers
    private _wholesalers: Customer[] = [];
    wholesalersSubject: BehaviorSubject<Customer[]> = new BehaviorSubject(
        this._wholesalers
    );
    get wholesalers(): Customer[] {
        return this._wholesalers;
    }
    set wholesalers(value: Customer[]) {
        this._wholesalers = value;
        this.wholesalersSubject.next(value);
    }
    observableWholesalers: Observable<Customer[]> = this.wholesalersSubject.asObservable();
}
