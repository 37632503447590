import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { BehaviorSubject, Subscription } from "rxjs";
import { EmployeeRoleType, GenericRequestDto, GratisEmployeeApprovalStatuses
    , GratisEmployeeApprovalStatusLookup, GratisStatuses, NamedStringDto
    , newSequentialId, SharedHelper, TransactionLineItemType } from "shield.shared";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { TransactionLineItem } from "src/app/entity-models/transaction-line-item.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { GratisAssignedTransactionLineItem } from "src/app/entity-models/gratis-assigned-transaction-line-item.entity";
import { GratisAssignedTransaction } from "src/app/entity-models/gratis-assigned-transactions.entity";
import { GratisManualTransaction } from "src/app/entity-models/gratis-manaul-transaction.entity";
import { GratisProductRequestedDetail } from "src/app/entity-models/gratis-product-requested-detail.entity";
import { GratisProductShippedDetail } from "src/app/entity-models/gratis-product-shipped-detail.entity";
import { Gratis } from "src/app/entity-models/gratis.entity";
import { ProductUpc } from "src/app/entity-models/product-upc.entity";
import { State } from "src/app/entity-models/state.entity";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { AddProductViewModel } from "src/app/products/add-products/add-product.viewmodel";
import { AddProductsComponent } from "src/app/products/add-products/add-products.component";
import { AddProductsViewmodel } from "src/app/products/add-products/add-products.viewmodel";
import { FilterService } from "src/app/services/filter.service";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { GridDataTypes } from "src/app/shared/enums/grid-data-types.enum";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { DataSyncQueueService } from "src/app/sync/data-sync-queue.service";

import { AvailableGratisLineItemViewmodel } from "../available-gratis-line-item.viewmodel";
import { AvailableGratisViewModel } from "../available-gratis.viewmodel";
import { ManualGratisEntryComponent } from "../manual-gratis-entry/manual-gratis-entry.component";
import { ManualGratisEntryViewmodel } from "../manual-gratis-entry/manual-gratis-entry.viewmodel";
import { RejectionReasonComponent } from "../rejection-reason/rejection-reason.component";
import { RejectionReasonViewmodel } from "../rejection-reason/rejection-reason.viewmodel";
import { GratisRequestDetailViewmodel } from "./gratis-request-detail.viewmodel";
import { GratisShippedDetailViewmodel } from "./gratis-shipped-detail.viewmodel";
import { MatTableDataSource } from "@angular/material/table";
import { GratisRequestValidationService } from "src/app/validation/gratis-request.validation";
import { ValidationTargets } from "src/app/validation/validation-targets";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { RegisteredUserDelineationService } from "src/app/services/delineation-services/registered-user-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { GratisDelineationService } from "src/app/services/delineation-services/gratis-delineation.service";
import { GratisConverterService } from "src/app/services/converter-services/gratis-converter.service";
import { Product } from "src/app/entity-models/product.entity";
import { CreatedModifiedUserBase } from "src/app/entity-models/created-modified-user-base";
import { GratisManualTransactionConverterService } from "src/app/services/converter-services/gratis-manual-transaction-converter.service";

export class GratisRequestFormViewmodel {
    employee!: Employee;
    filterService: FilterService;
    productDelineationService: ProductDelineationService;
    registeredUserDelineationService: RegisteredUserDelineationService;
    employeeDelineationService: EmployeeDelineationService;
    overlayService: OverlayService;
    gratisDelineationService: GratisDelineationService;
    pleaseWaitService: PleaseWaitService;
    snackbarService: SnackbarService;
    dataSyncQueueService: DataSyncQueueService;
    gratisRequestValidationService: GratisRequestValidationService;
    employeeSubscription!: Subscription;
    activatedRouteSubscription!: Subscription;
    purposeSubscription!: Subscription;
    address1Subscription!: Subscription;
    address2Subscription!: Subscription;
    citySubscription!: Subscription;
    stateSubscription!: Subscription;
    zipSubscription!: Subscription;
    neededByDateSubscription!: Subscription;
    orderNumberSubscription!: Subscription;
    orderDateSubscription!: Subscription;
    orderedByName!: string;
    address1!: string;
    address2: string | null = null;
    city!: string;
    selectedState: State | null = null;;
    states: State[] = new Array<State>();
    zip!: string;
    purpose!: string;
    needGratisBy!: moment.Moment | Date;
    gratisRequestNumber: string | null = null;
    router: Router;
    activatedRoute: ActivatedRoute;
    phone: string | null = null;
    formBuilder: FormBuilder;
    gratisRequestFormGroup: FormGroup;
    shippedInfoFormGroup: FormGroup;
    dateFormat: string = MY_DATE_FORMATS.display.dateInput;
    jsDateFormat = MY_DATE_FORMATS.display.customDateInput;
    gratisEntity: Gratis | null = null;
    isEditable = false;
    availableGratisAmount = 0;
    remainingGratisAmount = 0;
    availableGratisColumnDefs: ColumnDef[];
    availableGratisDataSource = new MatTableDataSource(new Array<GridData>());
    availableGratis = new Array<AvailableGratisViewModel>();
    shippedGratis = new Array<GratisShippedDetailViewmodel>();
    requestedAmount = 0;
    productsRequested = new Array<GratisRequestDetailViewmodel>();
    shouldWait$ = new BehaviorSubject<boolean>(true);
    availableGratisGridWidth = "90vh";
    availableGratisDetailHeight = "10vh";
    routedGratisId!: string | null;
    shippedTotal: number = 0;
    tmEmployee!: Employee;
    tmEmployeeApprovalStatus: string | null = null;
    rmEmployee!: Employee;
    rmEmployeeApprovalStatus: string | null = null;
    zmEmployee!: Employee;
    zmEmployeeApprovalStatus: string | null = null;
    csEmployee!: Employee;
    csEmployeeApprovalStatus: string | null = null;
    employeeRoleType!: EmployeeRoleType;
    isTm: boolean = false;
    smokelessDivisions = ["Chew", "Dry Snuff", "Moist Snuff"];

    gratisEmpApprovalNotSubmitted = GratisEmployeeApprovalStatusLookup.find((lu) => lu.id === GratisEmployeeApprovalStatuses.NotSubmitted)?.description;
    gratisEmployeeApprovalSubmitted = GratisEmployeeApprovalStatusLookup.find((lu) => lu.id === GratisEmployeeApprovalStatuses.Submitted)?.description;
    gratisEmployeeApprovalApproved = GratisEmployeeApprovalStatusLookup.find((lu) => lu.id === GratisEmployeeApprovalStatuses.Approved)?.description;
    gratisEmployeeApprovalRejected = GratisEmployeeApprovalStatusLookup.find((lu) => lu.id === GratisEmployeeApprovalStatuses.Rejected)?.description;
    gratisEmployeeApprovalOrdered = GratisEmployeeApprovalStatusLookup.find((lu) => lu.id === GratisEmployeeApprovalStatuses.Ordered)?.description;
    gratisEmployeeApprovalPending = GratisEmployeeApprovalStatusLookup.find((lu) => lu.id === GratisEmployeeApprovalStatuses.Pending)?.description;
    gratisEmployeeApprovalAwaitingApproval = GratisEmployeeApprovalStatusLookup.find((lu) => lu.id === GratisEmployeeApprovalStatuses.AwaitingApproval)?.description;
    gratisEmployeeApprovalAwaitingTM = GratisEmployeeApprovalStatusLookup.find((lu) => lu.id === GratisEmployeeApprovalStatuses.AwaitingTM)?.description;
    gratisEmployeeApprovalAwaitingRM = GratisEmployeeApprovalStatusLookup.find((lu) => lu.id === GratisEmployeeApprovalStatuses.AwaitingRM)?.description;
    gratisEmployeeApprovalAwaitingZM = GratisEmployeeApprovalStatusLookup.find((lu) => lu.id === GratisEmployeeApprovalStatuses.AwaitingZM)?.description;
    gratisEmployeeApprovalAwaitingAll = GratisEmployeeApprovalStatusLookup.find((lu) => lu.id === GratisEmployeeApprovalStatuses.AwaitingAll)?.description;
    gratisEmployeeApprovalNeedsReview = GratisEmployeeApprovalStatusLookup.find((lu) => lu.id === GratisEmployeeApprovalStatuses.NeedsReview)?.description;
    areControlsSet = false;
    gratisStatusNotSubmitted = GratisStatuses.NotSubmitted;
    gratisStatusComplete = GratisStatuses.Complete;
    gratisStatusRejected = GratisStatuses.Rejected;
    gratisStatusDeleted = GratisStatuses.Deleted;
    availableGratisGridData: GridData[] = new Array<GridData>();
    transactionTypes = TransactionLineItemType;


    //https://cuppalabs.github.io/components/multiselectDropdown
    uOfMDropdownSettings = {
        singleSelection: true,
        text: "Select Unit Of Measure",
        enableCheckAll: false,
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "name",
        searchBy: ["name"],
        maxHeight: 150,
        primaryKey: "name",
        classes: "multi-select-container c-btn pure-checkbox",
        autoPosition: false
    }

    addProductOverlayRef!: SwisherOverlayRef<
        AddProductsViewmodel,
        AddProductsComponent
    >;
    addShippedProductOverlayRef!: SwisherOverlayRef<
        AddProductsViewmodel,
        AddProductsComponent
    >;
    addRejectionReasonOverlayRef!: SwisherOverlayRef<
        RejectionReasonViewmodel,
        RejectionReasonComponent
    >;
    addManualGratisOverlayRef!: SwisherOverlayRef<
        ManualGratisEntryViewmodel,
        ManualGratisEntryComponent
    >;
    confirmationOverlayRef!: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    constructor(router: Router,
        formBuilder: FormBuilder,
        activatedRoute: ActivatedRoute,
        filterService: FilterService,
        overlayService: OverlayService,
        productDelineationService: ProductDelineationService,
        gratisDelineationService: GratisDelineationService,
        pleaseWaitService: PleaseWaitService,
        snackbarService: SnackbarService,
        dataSyncQueueService: DataSyncQueueService,
        gratisRequestValidationService: GratisRequestValidationService,
        registeredUserDelineationService: RegisteredUserDelineationService,
        employeeDelineationServervice: EmployeeDelineationService) {
        this.router = router;
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.filterService = filterService;
        this.overlayService = overlayService;
        this.productDelineationService = productDelineationService;
        this.gratisDelineationService = gratisDelineationService;
        this.pleaseWaitService = pleaseWaitService;
        this.snackbarService = snackbarService;
        this.dataSyncQueueService = dataSyncQueueService;
        this.gratisRequestValidationService = gratisRequestValidationService;
        this.registeredUserDelineationService = registeredUserDelineationService;
        this.employeeDelineationService = employeeDelineationServervice;

        this.gratisRequestFormGroup = this.formBuilder.group({
            purposeControl: new FormControl(),
            address1Control: new FormControl(),
            address2Control: new FormControl(),
            cityControl: new FormControl(),
            selectedStateControl: new FormControl(),
            zipControl: new FormControl(),
            neededByDateControl: new FormControl(),
        });
        this.shippedInfoFormGroup = this.formBuilder.group({
            orderDateControl: new FormControl("", Validators.required),
            orderNumberControl: new FormControl("", Validators.required)
        });

        this.availableGratisColumnDefs = [
            {
                headerName: "id",
                dataPropertyName: "id",
                isAvailable: false,
                isSelected: false,
            },
            {
                headerName: "Customer ID",
                dataPropertyName: "customerId",
                isAvailable: true,
                isSelected: true,
                isTemplate: true,
                clickFunction: this.removeManualGratis()
            },
            {
                headerName: "Call Date",
                dataPropertyName: "callDate",
                isAvailable: true,
                isSelected: true,
            },
            {
                headerName: "Store Information",
                dataPropertyName: "storeInformation",
                isAvailable: true,
                isSelected: true,
                columnClass: "text-wrap",
            },
            {
                headerName: "Created By",
                dataPropertyName: "createdBy",
                isAvailable: true,
                isSelected: true,
            },
            {
                headerName: "Receipt #",
                dataPropertyName: "receiptNumber",
                isAvailable: true,
                isSelected: true,
            },
            {
                headerName: "Sales Total",
                dataPropertyName: "salesTotal",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.currency
            },
            {
                headerName: "Net Total",
                dataPropertyName: "netTotal",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.currency
            },
            {
                headerName: "Gratis Total",
                dataPropertyName: "gratisTotal",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.currency
            },
            {
                headerName: "Gratis %",
                dataPropertyName: "gratisPercent",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.percent
            },
            {
                headerName: "Call Comments",
                dataPropertyName: "callClosingNotes",
                isAvailable: true,
                isSelected: true,
                columnClass: "text-wrap",
            },
            {
                headerName: "Gratis Request #",
                dataPropertyName: "gratisRequestNumber",
                isAvailable: true,
                isSelected: true,
            },
            {
                headerName: "What is a whole with out its parts",
                dataPropertyName: "availableGratisLineItems",
                isAvailable: false,
                isSelected: true
            }
        ]
    }

    onSelectedRequestedProductUOfMChange(vm: GratisRequestDetailViewmodel): void {
        vm.formGroup.controls["cbu"].setValue(vm.product.upcs.find((upc) => upc.uom === vm.unitOfMeasure.name)?.listPrice);
        this.calculateProductAmounts();
    }

    onSelectedShippedProductUOfMChange(vm: GratisShippedDetailViewmodel): void {
        vm.formGroup.controls["scbu"].setValue(vm.product.upcs.find((upc) => upc.uom === vm.unitOfMeasure.name)?.listPrice);
        this.calculateProductAmounts();
    }

    addShippedProduct(): void {
        if (!this.productDelineationService.activeProducts?.size) {
            setTimeout(async () => {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
                await this.productDelineationService.getAll();
                this.shouldWait$.next(true);

                this.addShippedProduct();
            }, 0);

            return;
        }
        const data: AddProductsViewmodel = new AddProductsViewmodel();
        data.buttonLeftDisabledFunction = () => false;
        data.buttonLeftFunction = () => this.addShippedProductOverlayRef.close(data);
        data.buildViewmodelProductsFromProductDomainModel(
            this.productDelineationService.activeProducts,
            this.shippedGratis.map((pr) => pr.product.id)
        );
        this.addShippedProductOverlayRef = this.overlayService.open(
            AddProductsComponent,
            data,
            true
        );

        this.addShippedProductOverlayRef.afterClosed$.subscribe((ref) => {
            if (ref?.data?.result?.length > 0) {
                const addProductViewmodels = ref.data.result.filter((r) => r.selected);
                if (addProductViewmodels.length > 300) {
                    this.snackbarService.showWarning("A maximum of 300 shipped product can be added.");
                    return;
                }
                this.upsertShippedProduct(addProductViewmodels.map((apvm) => GratisRequestFormViewmodel.addProductViewmodelToGratisProductShippedDetail(apvm)));
            }
        });
    }

    addRequestedProduct(): void {
        if (!this.productDelineationService.activeProducts?.size) {
            setTimeout(async () => {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
                await this.productDelineationService.getAll();
                this.shouldWait$.next(true);

                this.addRequestedProduct();
            }, 0);

            return;
        }
        const data: AddProductsViewmodel = new AddProductsViewmodel();
        data.buttonLeftDisabledFunction = () => false;
        data.buttonLeftFunction = () => this.addProductOverlayRef.close(data);
        data.buildViewmodelProductsFromProductDomainModel(
            this.productDelineationService.activeProducts,
            this.productsRequested.map((pr) => pr.product.id)
        );
        this.addProductOverlayRef = this.overlayService.open(
            AddProductsComponent,
            data,
            true
        );

        this.addProductOverlayRef.afterClosed$.subscribe((ref) => {
            if (ref?.data?.result?.length > 0) {
                let addProductViewmodels = ref.data.result.filter((r) => r.selected);
                if (addProductViewmodels.length > 300) {
                    this.snackbarService.showWarning("A maximum of 300 requested product can be added.");
                    return;
                }
                this.upsertRequestedProduct(addProductViewmodels.map((apvm) => this.addProductViewmodelToGratisProductRequestedDetail(apvm)));
            }
        });
    }

    compareNamedStringOptions(a: NamedStringDto, b: NamedStringDto): boolean {
        return a.name === b.name;
    }

    addProductViewmodelToGratisProductRequestedDetail(vm: AddProductViewModel): GratisProductRequestedDetail {
        const rtn = new GratisProductRequestedDetail();
        rtn.id = newSequentialId();
        rtn.productId = vm.product.id;
        rtn.quantity = 1;
        if (this.smokelessDivisions.includes(vm.product.division)) {
            if (vm.product.upcs.find((upc) => upc.uom === "Case")) {
                rtn.unitOfMeasure = "Case";
            }
        } else {
            if (vm.product.upcs.find((upc) => upc.uom === "Case")) {
                rtn.unitOfMeasure = "Case"  ;
            } else if (vm.product.upcs.find((upc) => upc.uom === "Display")) {
                rtn.unitOfMeasure = "Display";
            }
        }
        rtn.costPerUnit = vm.product.upcs.find((upc) => upc.uom === rtn.unitOfMeasure)?.listPrice ?? 0;

        return rtn;
    }

    static addProductViewmodelToGratisProductShippedDetail(vm: AddProductViewModel): GratisProductShippedDetail {
        const rtn = new GratisProductShippedDetail();
        rtn.id = newSequentialId();
        rtn.productId = vm.product.id;
        rtn.quantity = 1;
        rtn.unitOfMeasure = vm.product.lowestSellableUpc?.uom ? vm.product.lowestSellableUpc.uom : vm.product.upcs?.length > 0 ? vm.product.upcs[0].uom : "";
        rtn.costPerUnit = vm.product.upcs.find((upc) => upc.uom === rtn.unitOfMeasure)?.listPrice ?? 0;
        rtn.isDeleted = false;

        return rtn;
    }

    openRejectionReason(): void {
        const data = new RejectionReasonViewmodel(this.formBuilder);
        data.buttonLeftFunction = () => this.addRejectionReasonOverlayRef.close(data);
        data.buttonRightFunction = () => {

            data.rejectionFormGroup.markAllAsTouched();
            if (data.isSaveDisabled()) {
                return;
            }
            data.isConfirmed = true;
            this.addRejectionReasonOverlayRef.close(data);
        }
        data.buttonRightDisabledFunction = () => data.isSaveDisabled();
        this.addRejectionReasonOverlayRef = this.overlayService.open(
            RejectionReasonComponent,
            data,
            true
        );

        this.addRejectionReasonOverlayRef.afterClosed$.subscribe(async (ref) => {
            if (ref && ref.data && ref.data.rejectionReason && this.gratisEntity?.id) {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
                const expectedId = this.gratisEntity.id;
                const result = await this.gratisDelineationService.rejectApproval(this.gratisEntity?.id, ref.data.rejectionReason);
                if (result?.id === expectedId || result.isError) {
                    if (result.isError) {
                        this.shouldWait$.next(false);
                        this.snackbarService.showError(result.message);
                        return;
                    }

                    this.gratisEntity = null;
                    this.routedGratisId = result.values.id;
                    this.buildGratisRequestViewmodel();
                    this.shouldWait$.next(false);
                } else {
                    this.shouldWait$.next(false);
                    this.snackbarService.showWarning("Response received for incorrect request. Please Refresh and try again.");
                }
            }
        });
    }

    isProductDisabled(): boolean {
        return !(this.gratisEntity.gratisStatusId === GratisStatuses.NotSubmitted || this.gratisEntity.gratisStatusId === GratisStatuses.Rejected);
    }

    async getAvailableGratis(gratisId: string | null, gratisAssignedTransactions?: GratisAssignedTransaction[]): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        let availableGratis = new Array<AvailableGratisViewModel>();

        if (!this.gratisEntity?.gratisStatusId || this.gratisEntity.gratisStatusId === GratisStatuses.NotSubmitted) {
            const genericResponseDto = await this.gratisDelineationService.getAvailableForGratis(gratisId);

            if (genericResponseDto && this.gratisEntity) {
                this.gratisEntity.gratisAssignedTransactions = genericResponseDto.values;
                availableGratis = genericResponseDto.values.map((v) => this.buildAvailableGratisFromGratisAssignedTransaction(v));
            }
        } else {
            availableGratis = (gratisAssignedTransactions ?? new Array<GratisAssignedTransaction>())
                .map((gat) => this.buildAvailableGratisFromGratisAssignedTransaction(gat));

        }

        this.availableGratis = this.sortAvailableGratis(availableGratis);
        this.availableGratisAmount = this.availableGratis?.length ? this.availableGratis.map((ag) => ag.gratisTotal).reduce((prev, next) => prev + next) : 0;
        this.shouldWait$.next(true);
    }

    buildAvailableGratisFromGratisAssignedTransaction(gat: GratisAssignedTransaction): AvailableGratisViewModel {
        const rtn = new AvailableGratisViewModel();

        const isManualGratis = gat.description?.includes("Manual Gratis;");
        const isTheTmCreator = this.employee.id === this.tmEmployee.id;
        const isCarryForword = gat.description === "Carry Forward";
        const isContractPayment = gat.description?.includes("Retail Contract");

        rtn.id = gat.id;
        let customerId = gat.externalSyncId;
        if (isManualGratis && isTheTmCreator && !this.isProductDisabled()) {
            customerId = "<span class='flaticon-delete'></span>";
        } else if (isCarryForword) {
            customerId = "N/A";
        }

        rtn.customerId = customerId;
        rtn.callDate = moment(gat.callDate).format(this.dateFormat);
        rtn.storeInformation = isManualGratis || isCarryForword ? gat.description :
            isContractPayment ? gat.description + (gat.customerName ? " - " + gat.customerName : "") :
            (gat.customerName ?? "")
            + (gat.customerAddress1 ? "; " + gat.customerAddress1 : "")
            + (gat.customerAddress2 ? " " + gat.customerAddress2 : "")
            + (gat.customerCity ? "; " + gat.customerCity : "")
            + (gat.customerState ? " " + gat.customerState : "")
            + (gat.customerZip ? ", " + gat.customerZip : "");
        rtn.createdBy = `${Helper.formatDisplayName(gat as CreatedModifiedUserBase)}`;
        rtn.receiptNumber = gat.receiptNumber;
        rtn.salesTotal = gat.salesTotal;
        rtn.netTotal = gat.netTotal;
        rtn.gratisTotal = gat.gratisTotal;
        rtn.gratisPercent = gat.gratisPercentage;
        rtn.gratisRequestNumber = gat.carryForwardGratisNumber;
        rtn.callClosingNotes = gat.callClosingNotes;
        rtn.availableGratisLineItems = gat.gratisAssignedTransactionLineItems?.map((gatli) =>
            GratisRequestFormViewmodel.buildAvailableGratisLineItemFromAvailableGratisLineItems(gatli));

        return rtn;
    }

    static buildAvailableGratisLineItemFromAvailableGratisLineItems(gatli: GratisAssignedTransactionLineItem): AvailableGratisLineItemViewmodel {
        const rtn = new AvailableGratisLineItemViewmodel();
        rtn.uin = gatli.uin;
        rtn.upc = gatli.upc ?? "";
        rtn.productDescription = gatli.productDescription;
        rtn.quantity = gatli.quantity;
        rtn.eaches = gatli.units;
        rtn.price = gatli.price;
        rtn.salesTotal = gatli.salesTotal;
        rtn.discount = gatli.discount;
        rtn.netTotal = gatli.netTotal;
        rtn.gratisTotal = gatli.gratisTotal;
        rtn.type = gatli.type;
        rtn.typeDescription = gatli.typeDescription;
        rtn.wholesalerName = gatli.wholesalerName;
        rtn.wholesalePrice = gatli.wholesalePrice;
        rtn.wholesaleTotal = gatli.wholesaleTotal;
        return rtn;
    }

    static buildAvailableGratisLineItemFromTransactionLineItem(line: TransactionLineItem): AvailableGratisLineItemViewmodel {
        const rtn = new AvailableGratisLineItemViewmodel();
        rtn.uin = line.uin;
        rtn.upc = line.upc ?? "";
        rtn.productDescription = line.productDescription;
        rtn.quantity = line.quantity;
        rtn.eaches = line.units;
        rtn.price = line.price;
        rtn.salesTotal = line.salesTotal;
        rtn.discount = line.discount;
        rtn.netTotal = line.netTotal;
        rtn.gratisTotal = line.gratisTotal;
        rtn.type = line.type;
        rtn.typeDescription = line.typeDescription;
        rtn.wholesalerName = line.wholesalerName;
        rtn.wholesalePrice = line.wholesalePrice;
        rtn.wholesaleTotal = line.wholesaleTotal;
        return rtn;
    }

    buildAvailableGratisGrid(): void {
        let gridData: GridData[] = new Array<GridData>();
        for (const vm of this.availableGratis) {
            const gridItem: GridData = {
                data: vm,
                detailArrayName: "availableGratisLineItems",
                isExpanded: false,
                index: this.availableGratis.indexOf(vm),
            }
            gridData.push(gridItem);
        }
        this.availableGratisGridData = gridData;
        this.availableGratisDataSource = new MatTableDataSource(gridData);
    }

    async initialize(employee: Employee): Promise<void> {
        this.employee = employee;

        await this.initializeDropDowns();

        if (!this.activatedRouteSubscription || this.activatedRouteSubscription.closed) {
            this.activatedRouteSubscription = this.activatedRoute.url.subscribe(async (values) => {
                this.routedGratisId = values[2].path;
                this.buildGratisRequestViewmodel();
            });
        }
    }

    async buildRequestedProducts(): Promise<void> {
        let requestedViewmodels = new Array<GratisRequestDetailViewmodel>();
        for (const requested of this.productsRequested) {
            requested.unsubscribe();
        }

        const productsResponse = await this.productDelineationService.getByIds(this.gratisEntity?.gratisProductRequestedDetails
            .map(v => v.productId) ?? new Array<string>());
        if (!productsResponse || !productsResponse.values) return;

        const productsMap = new Map(productsResponse.values.map(p => [p.id, p]));

        for (const requested of (this.gratisEntity?.gratisProductRequestedDetails ?? [])) {
            const product = productsMap.get(requested.productId);
            if (!product) {
                continue;
            }
            requestedViewmodels.push(this.gratisProductRequestedDetailsToGratisRequestDetailViewmodel(requested, product));
            const index = requestedViewmodels.length - 1;

            if (!requestedViewmodels[requestedViewmodels.length - 1].cbuSubscription || requestedViewmodels[requestedViewmodels.length - 1].cbuSubscription.closed) {
                requestedViewmodels[requestedViewmodels.length - 1].cbuSubscription = requestedViewmodels[requestedViewmodels.length - 1]
                    .formGroup.controls["cbu"].valueChanges.subscribe((value) => {
                        requestedViewmodels[index].costPerUnit = value;
                        this.calculateProductAmounts();
                    });
                requestedViewmodels[requestedViewmodels.length - 1].formGroup.controls["cbu"].setValue(requested.costPerUnit);
            }
            if (this.isProductDisabled()) {
                requestedViewmodels[requestedViewmodels.length - 1].formGroup.controls["cbu"].disable();
            } else {
                requestedViewmodels[requestedViewmodels.length - 1].formGroup.controls["cbu"].enable();
            }
        }
        this.productsRequested = requestedViewmodels;
        this.calculateProductAmounts();

        this.shouldWait$.next(false);
    }

    async buildShippedProducts(): Promise<void> {
        let shippedViewmodels = new Array<GratisShippedDetailViewmodel>();
        for (const shipped of this.shippedGratis) {
            shipped.unsubscribe();
        }

        const productsResponse = await this.productDelineationService.getByIds(this.gratisEntity?.gratisProductRequestedDetails
            .map(v => v.productId) ?? new Array<string>());
        if (!productsResponse || !productsResponse.values) return;

        const productsMap = new Map(productsResponse.values.map(p => [p.id, p]));

        for (const shipped of (this.gratisEntity?.gratisProductShippedDetails ?? [])) {
            const product = productsMap.get(shipped.productId);
            if (!product) {
                continue;
            }
            shippedViewmodels.push(this.gratisProductShippedDetailsToGratisShippedDetailViewmodel(shipped, product));
            const index = shippedViewmodels.length - 1;

            if (!shippedViewmodels[shippedViewmodels.length - 1].cbuSubscription || shippedViewmodels[shippedViewmodels.length - 1].cbuSubscription.closed) {
                shippedViewmodels[shippedViewmodels.length - 1].cbuSubscription = shippedViewmodels[shippedViewmodels.length - 1]
                    .formGroup.controls["scbu"].valueChanges.subscribe((value) => {
                        //value = value > 0 ? value : 0.01;
                        shippedViewmodels[index].costPerUnit = value;
                        this.calculateProductAmounts();
                });
                shippedViewmodels[shippedViewmodels.length - 1].formGroup.controls["scbu"].setValue(shipped.costPerUnit);
            }
            if (this.isProductDisabled()) {
                shippedViewmodels[shippedViewmodels.length - 1].formGroup.controls["scbu"].disable();
            } else {
                shippedViewmodels[shippedViewmodels.length - 1].formGroup.controls["scbu"].enable();
            }
        }

        this.shippedGratis = shippedViewmodels;
        this.calculateProductAmounts();

        this.shouldWait$.next(false);
    }

    setShippedInfo(): void {
        this.shippedInfoFormGroup.addControl("orderNumberControl", new FormControl({ value: this.gratisEntity?.orderReferenceNumber, disabled: this.gratisEntity?.gratisStatusId === this.gratisStatusComplete }
            , [Validators.required]));

        this.shippedInfoFormGroup.addControl("orderDateControl", new FormControl({ value: this.gratisEntity?.orderDate, disabled: this.gratisEntity?.gratisStatusId === this.gratisStatusComplete }
            , [Validators.required]));

        if (!this.orderNumberSubscription || this.orderNumberSubscription.closed) {
            this.orderNumberSubscription = this.shippedInfoFormGroup.controls["orderNumberControl"].valueChanges.subscribe((value) => {
                if (this.gratisEntity) {
                    this.gratisEntity.orderReferenceNumber = value;
                }
            })
        }

        if (!this.orderDateSubscription || this.orderDateSubscription.closed) {
            this.orderDateSubscription = this.shippedInfoFormGroup.controls["orderDateControl"].valueChanges.subscribe((value) => {
                if (this.gratisEntity) {
                    this.gratisEntity.orderDate = value;
                }
            })
        }

        if (this.gratisEntity?.orderReferenceNumber) {
            this.shippedInfoFormGroup.controls["orderNumberControl"].setValue(this.gratisEntity.orderReferenceNumber);
        }

        const orderDate = this.gratisEntity?.orderDate ?? new Date();
        orderDate.setHours(0, 0, 0);
        this.shippedInfoFormGroup.controls["orderDateControl"].setValue(orderDate);

        if (this.gratisEntity?.gratisStatusId === this.gratisStatusComplete) {
            this.shippedInfoFormGroup.controls["orderDateControl"].disable();
            this.shippedInfoFormGroup.controls["orderNumberControl"].disable();
        }
    }

    openRequestedPriceWarning(index: number): void {
        const requestedProduct = this.productsRequested[index];
        if (((requestedProduct.costPerUnit ?? 0) * requestedProduct.quantity) <= 500) {
            requestedProduct.oldCostperUnit = requestedProduct.costPerUnit;
            return;
        }
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message = "Are you sure you want to exceed $500?";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            const requestedProduct = this.productsRequested[index];
            requestedProduct.formGroup.controls["cbu"]?.setValue(requestedProduct.oldCostperUnit ?? .01);
            this.confirmationOverlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.confirmationOverlayRef.close(data);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe((event) => {
            if (event && event.data && event.data.isConfirmed) {
                requestedProduct.oldCostperUnit = requestedProduct.costPerUnit;
                this.calculateProductAmounts();
            }
        });

        if (this.gratisEntity?.orderDate) {
            this.shippedInfoFormGroup.controls["orderDateControl"].setValue(this.gratisEntity.orderDate);
        }
    }

    gratisProductRequestedDetailsToGratisRequestDetailViewmodel(entity: GratisProductRequestedDetail, product: Product): GratisRequestDetailViewmodel {
        const rtn = new GratisRequestDetailViewmodel();
        rtn.id = entity.id;
        let productUpcs = new Array<ProductUpc>();
        if (this.smokelessDivisions.includes(product.division)) {
            productUpcs = product.upcs.filter((upc) => Helper.equalsIgnoreCase(upc.uom, "Case"));
        } else {
            productUpcs = product.upcs.filter((upc) => Helper.equalsIgnoreCase(upc.uom, "Case") || Helper.equalsIgnoreCase(upc.uom, "Display"));
            if (productUpcs.length === 1) {
                let lowestUOM:any = []
                lowestUOM = product.upcs.filter((upc) => upc.lowestSellableUnit === true);
                productUpcs.push(...lowestUOM)
            }
        }
        rtn.availableUnitOfMeasures = productUpcs
            .map((upc) => {
                return new NamedStringDto(upc.uom);
            })
            .filter((v, i, a) => a.indexOf(v) === i)
            .sort((a, b) => a.name.localeCompare(b.name));

        rtn.costPerUnit = entity.costPerUnit;
        rtn.oldCostperUnit = rtn.costPerUnit;
        rtn.quantity = entity.quantity;
        if (productUpcs?.length) {
            rtn.unitOfMeasure = new NamedStringDto(entity.unitOfMeasure);
        }
        rtn.product = product;
        rtn.formGroup.addControl("cbu", new FormControl({ disabled: this.isProductDisabled() }, [Validators.required, Validators.min(.01)]));

        return rtn;
    }

    gratisProductShippedDetailsToGratisShippedDetailViewmodel(entity: GratisProductShippedDetail, product: Product): GratisShippedDetailViewmodel {
        const rtn = new GratisShippedDetailViewmodel();
        rtn.id = entity.id;
        rtn.availableUnitOfMeasures = product.upcs.map((upc) => {
            return new NamedStringDto(upc.uom);
        }).filter((v, i, a) => a.indexOf(v) === i);
        rtn.costPerUnit = entity.costPerUnit;
        rtn.quantity = entity.quantity;
        rtn.unitOfMeasure = new NamedStringDto(entity.unitOfMeasure);
        rtn.product = product;
        rtn.formGroup.addControl("scbu", new FormControl({ disabled: this.gratisEntity?.gratisStatusId === this.gratisStatusComplete
            || this.gratisEntity?.gratisStatusId === this.gratisStatusDeleted }, [Validators.required, Validators.min(.01)]));

        return rtn;
    }

    buildGratisEntityFromViewmodel(): void {
        if (this.gratisEntity) {
            this.gratisEntity.purpose = this.purpose;
            this.gratisEntity.shipToAddress1 = this.address1;
            this.gratisEntity.shipToAddress2 = this.address2;
            this.gratisEntity.shipToCity = this.city;
            if (this.selectedState) {
                this.gratisEntity.shipToState = this.selectedState.id;
            }
            this.gratisEntity.shipToZip = this.zip;
            this.gratisEntity.neededByDate = new Date(this.needGratisBy.toISOString());
            this.gratisEntity.gratisProductRequestedDetails = (this.productsRequested ?? []).map((vm) => this.gratisRequestDetailViewModelToGratisProductRequestedDetail(vm));
            this.gratisEntity.gratisProductShippedDetails = (this.shippedGratis ?? []).map((vm) => this.gratisShippedDetailViewModelToGratisProductShippedDetail(vm));
        }
    }

    private gratisShippedDetailViewModelToGratisProductShippedDetail(vm: GratisShippedDetailViewmodel): GratisProductShippedDetail {
        const rtn = new GratisProductShippedDetail();

        rtn.id = vm.id;
        rtn.productId = vm.product.id;
        rtn.costPerUnit = vm.costPerUnit;
        rtn.quantity = vm.quantity;
        rtn.unitOfMeasure = vm.unitOfMeasure.name;
        rtn.isDeleted = vm.isDeleted;

        return rtn;
    }

    private gratisRequestDetailViewModelToGratisProductRequestedDetail(vm: GratisRequestDetailViewmodel): GratisProductRequestedDetail {
        const rtn = new GratisProductRequestedDetail();

        rtn.id = vm.id;
        rtn.productId = vm.product.id;
        rtn.costPerUnit = vm.costPerUnit;
        rtn.quantity = vm.quantity;
        rtn.unitOfMeasure = vm.unitOfMeasure.name;

        return rtn;
    }

    async buildGratisRequestViewmodel() {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        if (this.routedGratisId === "0") {
            const now = new Date();
            this.gratisRequestNumber = "G" + this.employee.zrt
                + now.getFullYear()
                + (now.getMonth() + 1)
                + now.getDate();

            this.gratisEntity = new Gratis();
            this.tmEmployee = Object.assign({}, this.employee);
            this.gratisEntity.id = newSequentialId();
            this.gratisEntity.purpose = "";
            if (this.employee?.shipAddress1) {
                this.gratisEntity.shipToAddress1 = this.employee.shipAddress1
            }
            this.gratisEntity.shipToAddress2 = this.employee.shipAddress2;
            if (this.employee?.shipCity) {
                this.gratisEntity.shipToCity = this.employee.shipCity;
            }
            if (this.employee.shipState) {
                this.gratisEntity.shipToState = this.states.find((state) => state.shortCode === this.employee.shipState)?.id ?? null;
            }
            if (this.employee.shipZipCode) {
                this.gratisEntity.shipToZip = this.employee.shipZipCode;
            }
            const defaultNeededByDate = moment().add(7, 'days');
            defaultNeededByDate.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
            this.gratisEntity.neededByDate = defaultNeededByDate.toDate();
            this.gratisEntity.gratisAssignedTransactions = [];
            this.gratisEntity.gratisProductRequestedDetails = [];
            this.gratisEntity.gratisProductShippedDetails = [];
            this.gratisEntity.gratisApprovals = [];
        } else {

            if (!this.gratisEntity && this.routedGratisId) {
                const expectedId = this.routedGratisId;
                const result = await this.gratisDelineationService.getGratisById(this.routedGratisId)

                if (result.id === expectedId || result.isError) {
                    if (result?.isError) {
                        this.snackbarService.showError(result.message);
                    } else {
                        this.gratisEntity = result.values ?? null;
                        this.gratisRequestNumber = this.gratisEntity?.gratisNumber ?? null;
                        this.needGratisBy = this.gratisEntity?.neededByDate ?? GratisConverterService.defaultNeededByDate;
                        await this.setEmployees();
                        await this.buildRequestedProducts();
                        this.setShippedInfo();
                        await this.buildShippedProducts();
                    }
                }
            }
        }

        if (this.gratisEntity) {
            await this.getAvailableGratis(this.routedGratisId === "0" ? null : this.gratisEntity.id, this.gratisEntity.gratisAssignedTransactions);

        this.buildAvailableGratisGrid();
        this.calculateProductAmounts();

        this.isEditable = this.gratisEntity.gratisStatusId == null ||
            this.gratisEntity.gratisStatusId === GratisStatuses.NotSubmitted ||
            this.gratisEntity.gratisStatusId === GratisStatuses.Rejected;

        this.phone = this.tmEmployee.cellPhone ?? this.tmEmployee.homePhone;
        if (!this.phone) {
            const registeredUserResponse = await this.registeredUserDelineationService.getByEmployeeId(this.tmEmployee.id);
            if (!registeredUserResponse) {
                this.shouldWait$.next(false);
                return;
            }
            const registeredUser = registeredUserResponse.values;

            if (registeredUser?.otherPhone) {
                this.phone = SharedHelper.formatPhoneNumber(registeredUser.otherPhone);
            }
        }
    }

        if (!this.areControlsSet) {
            this.gratisRequestFormGroup = this.formBuilder.group({
                purposeControl: new FormControl({ value: this.purpose, disabled: !this.isEditable }, Validators.required),
                address1Control: new FormControl({ value: this.address1, disabled: !this.isEditable }, Validators.required),
                address2Control: new FormControl({ value: this.address2, disabled: !this.isEditable }),
                cityControl: new FormControl({ value: this.city, disabled: !this.isEditable }, Validators.required),
                selectedStateControl: new FormControl({ value: this.selectedState, disabled: !this.isEditable }, Validators.required),
                zipControl: new FormControl({ value: this.zip, disabled: !this.isEditable }, Validators.required),
                neededByDateControl: new FormControl({ value: this.needGratisBy, disabled: !this.isEditable }),
            });

            this.setSubscriptions();
            this.areControlsSet = true;
        }
        this.setViewModelFields();
        if (this.gratisEntity && this.gratisEntity.gratisStatusId) {
            this.disableProductControls();
        }

        this.gratisRequestValidationService.root = this.gratisEntity;
        this.shouldWait$.next(false);
    }

    private async setEmployees(): Promise<void> {
        if (!this.gratisEntity) {
            return;
        }
        if (this.gratisEntity.employeeId) {
            const tmEmployeeResponse = await this.employeeDelineationService.getById(this.gratisEntity.employeeId);
            if (!tmEmployeeResponse) {
                this.shouldWait$.next(false);
                return;
            }
            this.tmEmployee = tmEmployeeResponse.values;
            this.tmEmployeeApprovalStatus = GratisEmployeeApprovalStatusLookup
                .find((gasl) => gasl.id === this.gratisEntity?.employeeApprovalStatusId)?.description ?? null;
        }

        if (this.gratisEntity.regionalEmployeeApprovalEmployeeId) {
            const rmEmployeeResponse = await this.employeeDelineationService.getById(this.gratisEntity.regionalEmployeeApprovalEmployeeId);
            if (!rmEmployeeResponse) {
                this.shouldWait$.next(false);
                return;
            }
            this.rmEmployee = rmEmployeeResponse.values;
            this.rmEmployeeApprovalStatus = GratisEmployeeApprovalStatusLookup
                .find((gasl) => gasl.id === this.gratisEntity?.regionalEmployeeApprovalStatusId)?.description ?? null;
        }

        if (this.gratisEntity.zoneEmployeeApprovalEmployeeId) {
            const zmEmployeeResponse = await this.employeeDelineationService.getById(this.gratisEntity.zoneEmployeeApprovalEmployeeId);
            if (!zmEmployeeResponse) {
                this.shouldWait$.next(false);
                return;
            }
            this.zmEmployee = zmEmployeeResponse.values;
            this.zmEmployeeApprovalStatus = GratisEmployeeApprovalStatusLookup
                .find((gasl) => gasl.id === this.gratisEntity?.zoneEmployeeApprovalStatusId)?.description ?? null;
        }

        this.csEmployeeApprovalStatus = GratisEmployeeApprovalStatusLookup
            .find((gasl) => gasl.id === this.gratisEntity?.customerServiceEmployeeApprovalStatusId)?.description ?? null;

        if (this.gratisEntity.orderBy) {
            const orderByEmployeeResponse = await this.employeeDelineationService.getById(this.gratisEntity.orderBy);
            if (!orderByEmployeeResponse) {
                this.shouldWait$.next(false);
                return;
            }
            const orderByEmployee = orderByEmployeeResponse.values;

            if (orderByEmployee) {
                this.orderedByName = orderByEmployee.fullName ?? "";
            }
        }
    }

    private disableProductControls(): void {
        for (const vm of this.productsRequested) {
            vm.formGroup.controls["cbu"].disable();
        }
        this.gratisRequestFormGroup.controls["purposeControl"].disable();
        this.gratisRequestFormGroup.controls["address1Control"].disable();
        this.gratisRequestFormGroup.controls["address2Control"].disable();
        this.gratisRequestFormGroup.controls["cityControl"].disable();
        this.gratisRequestFormGroup.controls["selectedStateControl"].disable();
        this.gratisRequestFormGroup.controls["zipControl"].disable();
        this.gratisRequestFormGroup.controls["neededByDateControl"].disable();
    }

    private setViewModelFields(): void {
        this.gratisRequestFormGroup.controls["purposeControl"].setValue(this.gratisEntity?.purpose);
        this.gratisRequestFormGroup.controls["address1Control"].setValue(this.gratisEntity?.shipToAddress1);
        this.gratisRequestFormGroup.controls["address2Control"].setValue(this.gratisEntity?.shipToAddress2);
        this.gratisRequestFormGroup.controls["cityControl"].setValue(this.gratisEntity?.shipToCity);
        const state = this.states.find((state) => state.id === this.gratisEntity?.shipToState);
        this.gratisRequestFormGroup.controls["selectedStateControl"].setValue(state);
        this.gratisRequestFormGroup.controls["zipControl"].setValue(this.gratisEntity?.shipToZip);
        this.gratisRequestFormGroup.controls["neededByDateControl"].setValue(this.gratisEntity?.neededByDate);
    }

    calculateProductAmounts(): void {
        const requestedAmounts = this.productsRequested.map((pr) => pr.costPerUnit * pr.quantity)
        this.requestedAmount = requestedAmounts.length > 0 ? requestedAmounts.reduce((prev, next) => prev + next) : 0;
        // Math.round here sanitizes rare cases of excessive precision resulting in negative values
        // (e.g. a remaining gratis of -0.0000000057 is technically negative and fails validation; this fixes that)
        // 2022-12 update: moved to SharedHelper
        this.remainingGratisAmount = SharedHelper.roundToTwo(this.availableGratisAmount - this.requestedAmount);

        const shippedAmounts = this.shippedGratis.map((pr) => pr.costPerUnit * pr.quantity)
        this.shippedTotal = shippedAmounts.length > 0 ? shippedAmounts.reduce((prev, next) => prev + next) : 0;
    }

    cancel(): void {
        void this.router.navigate(["/my-day/gratis"]);
    }

    async initializeDropDowns(): Promise<void> {
        this.states = await this.filterService.getStates();
    }

    openManualGratis(): void {
        const data = new ManualGratisEntryViewmodel(this.formBuilder);
        data.buttonLeftFunction = () => this.addManualGratisOverlayRef.close(data);
        data.buttonRightFunction = () => {
            data.manualGratisFormGroup.markAllAsTouched();
            data.manualGratisFormGroup.controls["reasonControl"].markAllAsTouched();
            if (data.isSaveDisabled()) {
                return;
            }
            data.isConfirmed = true;
            this.addManualGratisOverlayRef.close(data);
        }
        this.addManualGratisOverlayRef = this.overlayService.open(
            ManualGratisEntryComponent,
            data,
            true
        );

        this.addManualGratisOverlayRef.afterClosed$.subscribe((ref) => {
            if (ref && ref.data && ref.data.isConfirmed && this.gratisEntity) {
                const manualGratis = new GratisManualTransaction();
                manualGratis.reason = ref.data.reason;
                manualGratis.date = ref.data.date;
                manualGratis.total = ref.data.amount;
                const newTransaction = GratisManualTransactionConverterService.createManualGratis(manualGratis, this.employee);
                this.gratisEntity.gratisAssignedTransactions = [newTransaction, ...this.gratisEntity.gratisAssignedTransactions];
                this.availableGratis
                    = this.gratisEntity.gratisAssignedTransactions
                        .map((gat) => this.buildAvailableGratisFromGratisAssignedTransaction(gat));
                this.availableGratisAmount = this.availableGratis?.length
                    ? this.availableGratis.map((ag) => ag.gratisTotal).reduce((prev, next) => prev + next)
                    : 0;
                this.buildAvailableGratisGrid();
                this.calculateProductAmounts();
            }
        });
    }

    removeManualGratis(): (event: MouseEvent, index: number) => void {
        return async (event: MouseEvent, index: number) => {

            if (this.gratisEntity) {
                const gratisAssignedTransaction = this.gratisEntity.gratisAssignedTransactions[index];
                if (gratisAssignedTransaction?.description && gratisAssignedTransaction.description.includes("Manual Gratis;")) {
                    if (!this.isEditable) {
                        this.snackbarService.showWarning("Manual gratis requests can not be removed once submitted.");
                        return;
                    }
                    this.gratisEntity.gratisAssignedTransactions.splice(index, 1);
                    this.availableGratis
                        = this.gratisEntity.gratisAssignedTransactions
                            .map((gat) => this.buildAvailableGratisFromGratisAssignedTransaction(gat));
                    this.availableGratisAmount = this.availableGratis?.length
                        ? this.availableGratis.map((ag) => ag.gratisTotal).reduce((prev, next) => prev + next)
                        : 0;
                    this.buildAvailableGratisGrid();
                    this.calculateProductAmounts();
                } else {
                    this.availableGratisGridData[index].isExpanded = !this.availableGratisGridData[index].isExpanded;
                }
            }
        };
    }

    incrementQuantity(vm: GratisRequestDetailViewmodel | GratisShippedDetailViewmodel): void {
        vm.quantity++;
        this.calculateProductAmounts();
    }

    decrementQuantity(vm: GratisRequestDetailViewmodel | GratisShippedDetailViewmodel): void {
        vm.quantity = (vm.quantity && vm.quantity > 1) ? vm.quantity - 1 : 1;
        this.calculateProductAmounts();
    }

    validateQuantity(vm: GratisRequestDetailViewmodel | GratisShippedDetailViewmodel): void {
        vm.quantity = vm.quantity ? vm.quantity : 1
        this.calculateProductAmounts();
    }

    isSubmitDisabled(): boolean {
        return ((this.gratisRequestFormGroup.controls['purposeControl'].hasError("required") && this.gratisRequestFormGroup.controls['purposeControl'].touched) ||
            (this.gratisRequestFormGroup.controls['address1Control'].hasError("required") && this.gratisRequestFormGroup.controls['address1Control'].touched) ||
            (this.gratisRequestFormGroup.controls['cityControl'].hasError("required") && this.gratisRequestFormGroup.controls['cityControl'].touched) ||
            (this.gratisRequestFormGroup.controls['selectedStateControl'].hasError("required") && this.gratisRequestFormGroup.controls['selectedStateControl'].touched) ||
            (this.gratisRequestFormGroup.controls['zipControl'].hasError("required") && this.gratisRequestFormGroup.controls['zipControl'].touched) ||
            (this.gratisRequestFormGroup.controls['neededByDateControl'].status === "INVALID")) ||
            (this.gratisEntity?.gratisStatusId != null && this.gratisEntity?.gratisStatusId !== GratisStatuses.NotSubmitted
                && this.gratisEntity?.gratisStatusId !== GratisStatuses.Submitted
                && this.gratisEntity?.gratisStatusId !== GratisStatuses.Rejected)
            || (this.remainingGratisAmount < 0);
    }

    isSaveDisabled(): boolean {
        return !this.isEditable || this.isSubmitDisabled();

    }

    removeRequestedProduct(requestedProduct: GratisRequestDetailViewmodel): void {
        const remaining = this.productsRequested.filter((rp) => rp.product.id !== requestedProduct.product.id);
        this.upsertRequestedProduct(remaining.map((r) => this.gratisRequestDetailViewModelToGratisProductRequestedDetail(r)));
    }

    upsertRequestedProduct(requestedProducts: GratisProductRequestedDetail[]): void {
        if (requestedProducts && this.gratisEntity) {
            for (const detail of requestedProducts) {
                const found = this.productsRequested.find((pr) => pr.product.id === detail.productId);
                if (found) {
                    detail.costPerUnit = found.costPerUnit;
                    detail.quantity = found.quantity;
                    detail.unitOfMeasure = found.unitOfMeasure?.name;
                }
            }
            this.productsRequested.map((rp) => rp.unsubscribe());
            this.gratisEntity.gratisProductRequestedDetails = requestedProducts;
            this.buildRequestedProducts();
        }
    }

    removeShippedProduct(shippedProduct: GratisShippedDetailViewmodel): void {
        const remaining = this.shippedGratis.filter((sp) => sp.product.id !== shippedProduct.product.id);
        this.upsertShippedProduct(remaining.map((s) => this.gratisShippedDetailViewModelToGratisProductShippedDetail(s)));
    }

    upsertShippedProduct(shippedProducts: GratisProductShippedDetail[]): void {
        if (shippedProducts && this.gratisEntity) {
            for (const detail of shippedProducts) {
                const found = this.shippedGratis.find((pr) => pr.product.id === detail.productId);
                if (found) {
                    detail.costPerUnit = found.costPerUnit;
                    detail.quantity = found.quantity;
                    detail.unitOfMeasure = found.unitOfMeasure?.name;
                }
            }
            this.gratisEntity.gratisProductShippedDetails = shippedProducts;
            this.buildShippedProducts();
        }
    }

    async submit(): Promise<void> {
        this.gratisRequestFormGroup.markAllAsTouched();

        if (this.isSaveDisabled()) {
            return;
        } else if (this.gratisEntity) {
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

            this.buildGratisEntityFromViewmodel();
            const request = new GenericRequestDto<Gratis>();
            request.id = newSequentialId();
            request.values = this.gratisEntity;
            const result = await this.gratisDelineationService.submitGratisRequest(request);

            if (result && (result.isError || result.id === request.id)) {
                if (result.isError) {
                    this.snackbarService.showError(result.message);
                } else {
                    this.gratisEntity = null;
                    this.routedGratisId = result.values?.id ?? null;
                    this.buildGratisRequestViewmodel();
                }
            }

            this.shouldWait$.next(false);
        }
    }

    async save(): Promise<void> {
        this.gratisRequestFormGroup.markAllAsTouched();

        if (this.isSaveDisabled()) {
            return;
        } else if (this.gratisEntity) {
            this.gratisRequestValidationService.validate([ValidationTargets.gratisSave]);
            for (const message of this.gratisRequestValidationService.allMessages) {
                this.snackbarService.showError(message);
            }
            if (this.gratisRequestValidationService.hasHardStop) { return; }

            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

            this.buildGratisEntityFromViewmodel();
            const request = new GenericRequestDto<Gratis>();
            request.id = newSequentialId();
            request.values = this.gratisEntity;
            const result = await this.gratisDelineationService.saveGratisRequest(request);
            if (!result) {
                this.shouldWait$.next(false);
                return;
            }

            if (result.id === request.id || result.isError) {
                if (result.isError) {
                    this.snackbarService.showError(result.message);
                } else {
                    this.gratisEntity = null;
                    if (result.values?.gratisStatusId != null && this.routedGratisId === "0" && result.values.id) {
                        this.routedGratisId = result.values.id;
                        void this.router.navigate([
                            "my-day/gratis-request-form",
                            result.values.id]);
                        this.shouldWait$.next(false);
                        return;
                    } else {
                        this.routedGratisId = result.values?.id ?? null;
                        this.buildGratisRequestViewmodel();
                    }

                }
            }

            this.shouldWait$.next(false);
        }
    }

    select(element: HTMLInputElement): void {
        Helper.selectInputText(element);
    }


    setSubscriptions(): void {
        if (!this.neededByDateSubscription || this.neededByDateSubscription.closed) {
            this.neededByDateSubscription = this.gratisRequestFormGroup.controls["neededByDateControl"].valueChanges.subscribe((value) => {
                if (Helper.isValidMomentDate(value, this.dateFormat)) {
                    this.needGratisBy = value;
                }
            });
        }

        if (!this.purposeSubscription || this.purposeSubscription.closed) {
            this.purposeSubscription = this.gratisRequestFormGroup.controls["purposeControl"].valueChanges.subscribe((value) => {
                this.purpose = value;
            });
        }

        if (!this.address1Subscription || this.address1Subscription.closed) {
            this.address1Subscription = this.gratisRequestFormGroup.controls["address1Control"].valueChanges.subscribe((value) => {
                this.address1 = value;
            });
        }

        if (!this.address2Subscription || this.address2Subscription.closed) {
            this.address2Subscription = this.gratisRequestFormGroup.controls["address2Control"].valueChanges.subscribe((value) => {
                this.address2 = value;
            });
        }

        if (!this.citySubscription || this.citySubscription.closed) {
            this.citySubscription = this.gratisRequestFormGroup.controls["cityControl"].valueChanges.subscribe((value) => {
                this.city = value;
            });
        }

        if (!this.stateSubscription || this.stateSubscription.closed) {
            this.stateSubscription = this.gratisRequestFormGroup.controls["selectedStateControl"].valueChanges.subscribe((value) => {
                this.selectedState = value;
            });
        }

        if (!this.zipSubscription || this.zipSubscription.closed) {
            this.zipSubscription = this.gratisRequestFormGroup.controls["zipControl"].valueChanges.subscribe((value) => {
                this.zip = value;
            });
        }
    }

    unsubscribe(): void {
        if (this.neededByDateSubscription && !this.neededByDateSubscription.closed) {
            this.neededByDateSubscription.unsubscribe();
        }

        if (this.purposeSubscription && !this.purposeSubscription.closed) {
            this.purposeSubscription.unsubscribe();
        }

        if (this.address1Subscription && !this.address1Subscription.closed) {
            this.address1Subscription.unsubscribe();
        }

        if (this.address2Subscription && !this.address2Subscription.closed) {
            this.address2Subscription.unsubscribe();
        }

        if (this.citySubscription && !this.citySubscription.closed) {
            this.citySubscription.unsubscribe();
        }

        if (this.stateSubscription && !this.stateSubscription.closed) {
            this.stateSubscription.unsubscribe();
        }

        if (this.zipSubscription && !this.zipSubscription.closed) {
            this.zipSubscription.unsubscribe();
        }

        if (this.orderDateSubscription && !this.orderDateSubscription.closed) {
            this.orderDateSubscription.unsubscribe();
        }

        if (this.orderNumberSubscription && !this.orderNumberSubscription.closed) {
            this.orderNumberSubscription.unsubscribe();
        }
    }

    validateSubmit(): void {
        if ((this.productsRequested ?? []).length === 0) {
            this.snackbarService.showWarning("At least one requested product is needed in order to submit.");
            return
        }

        this.submit();
    }

    compareStateOptions(a: State, b: State): boolean {
        return a?.id === b?.id;
    }

    async deleteGratisRequest(): Promise<void> {

        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message = "Are you sure you want to delete this merchandise requisition?";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => this.confirmationOverlayRef.close();
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.confirmationOverlayRef.close(data);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe(async (event) => {
            if (event && event.data && event.data.isConfirmed) {

                this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
                const expectedId = this.gratisEntity?.id;
                if (expectedId) {
                    const result = await this.gratisDelineationService.deletedGratisRequest(expectedId);

                    if (result.isError || expectedId === result.id) {
                        if (result.isError) {
                            this.shouldWait$.next(false);
                            this.snackbarService.showError(result.message);
                            return;
                        }
                    }

                    this.routedGratisId = expectedId;
                    this.gratisEntity = null;
                    this.shouldWait$.next(false);
                    void this.router.navigate(["/my-day/gratis"]);
                }
            }
        });
    }

    async approve(): Promise<void> {
        if (this.gratisEntity) {
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

            const expectedId = this.gratisEntity.id;

            const result = await this.gratisDelineationService.approve(expectedId);

            if (result.isError || expectedId === result.id) {
                if (result.isError) {
                    this.snackbarService.showError(result.message);
                    this.shouldWait$.next(false);
                    return;
                }

                this.routedGratisId = expectedId;
                this.gratisEntity = null;
                this.buildGratisRequestViewmodel();
            }

            this.shouldWait$.next(false);
        }
    }

    async order(): Promise<void> {
        this.shippedInfoFormGroup.markAllAsTouched();
        if (this.isOrderDisabled() || !this.gratisEntity) {
            return;
        }

        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        const expectedId = this.gratisEntity.id
        this.gratisEntity.orderBy = this.employee.id;
        this.buildGratisEntityFromViewmodel();

        const result = await this.gratisDelineationService.completeOrder(this.gratisEntity);

        if (result.isError || expectedId === result.id) {
            if (result.isError) {
                this.snackbarService.showError(result.message);
                this.shouldWait$.next(false);
                return;
            }
            this.routedGratisId = expectedId;
            this.gratisEntity = null;
            this.buildGratisRequestViewmodel();
        }

        this.shouldWait$.next(false);
    }

    isOrderDisabled(): boolean {
        return this.gratisEntity?.gratisStatusId === this.gratisStatusComplete
            || (this.shippedInfoFormGroup.controls["orderNumberControl"].hasError("required") && this.shippedInfoFormGroup.controls["orderNumberControl"].touched)
            || (this.shippedInfoFormGroup.controls["orderDateControl"].hasError("required") && this.shippedInfoFormGroup.controls["orderDateControl"].touched);
    }

    isEmployeeRoleCustomerService(employee: Employee): boolean {
        return !!employee?.employeeRoles.find((er) => er.employeeRoleType.id === EmployeeRoleType.CS);
    }

    private sortAvailableGratis(items: AvailableGratisViewModel[]): AvailableGratisViewModel[] {
        let rtn = new Array<AvailableGratisViewModel>();

        rtn = rtn.concat(items.filter(v => v.storeInformation?.includes("Carry Forward")));
        items = items.filter(v => !rtn.includes(v));

        rtn = rtn.concat(items.filter(v => v.storeInformation?.includes("Manual Gratis")));
        items = items.filter(v => !rtn.includes(v));

        rtn = rtn.concat(items.filter(v => v.storeInformation?.includes("Retail Contract")));
        items = items.filter(v => !rtn.includes(v));

        rtn = rtn.concat(items);
        return rtn;
    }
}

