import { Component, ElementRef, ViewChild } from '@angular/core';
import { CustomerStateService } from 'src/app/accounts/account-services/customer-state.service';
import { CallService } from 'src/app/accounts/call-master/call-services/call.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { StateDelineationService } from 'src/app/services/delineation-services/state-delineation.service';
import { StateLicenseDelineationService } from 'src/app/services/delineation-services/state-license-delineation.service';
import { RetailFormatBaseComponent } from '../../../../stepper-call/receipts/retail-receipt/retail-format-base/retail-format-base.component';
import { StepperCallApplicationService } from '../../../stepper-call-services/stepper-call-application.service';

@Component({
    selector: 'app-swisher-retail-wide-tax-format',
    templateUrl: './swisher-retail-wide-tax-format.component.html',
    styleUrls: ['./swisher-retail-wide-tax-format.component.scss']
})
export class SwisherRetailWideTaxFormatComponent extends RetailFormatBaseComponent {

    @ViewChild("originalSwisherRetailReceipt") originalSwisherRetailReceipt: ElementRef;

    imageLocation = "/assets/img/SwisherPrintLogo.jpg";

    constructor(stepperCallApplicationService: StepperCallApplicationService,
        callService: CallService,
        appStateService: AppStateService,
        customerStateService: CustomerStateService,
        stateLicenseDelineationService: StateLicenseDelineationService,
        stateDelineationService: StateDelineationService) {
        super(stepperCallApplicationService,
            callService,
            appStateService,
            customerStateService,
            stateLicenseDelineationService,
            stateDelineationService);
        const basePath = window.location.origin;
        this.imageLocation = basePath + this.imageLocation;

        this.getPrintLogo(this.imageLocation);
    }
}
