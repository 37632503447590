import { AfterContentChecked, Component, ElementRef,
    HostBinding, HostListener, OnDestroy,
    OnInit, ViewChild } from "@angular/core";
import { GratisViewmodel } from "./gratis.viewmodel";
import { GratisRefinerService } from "./gratis-refiner.service";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { Router } from "@angular/router";
import { AppStateService } from "src/app/services/app-state.service";
import { FormBuilder } from "@angular/forms";
import { MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { MatSelect } from "@angular/material/select";
import { SnackbarService } from "src/app/services/snackbar.service";
import { EmployeeRoleType } from "shield.shared";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { GratisDelineationService } from "src/app/services/delineation-services/gratis-delineation.service";
import { ZrtTreeViewComponent } from "src/app/shared/zrt-tree-view/zrt-tree-view.component";
import { GratisZrtFilterService } from "./gratis-zrt-filter.service";
import { OverlayService } from "src/app/services/overlay.service";
import { Helper } from "src/app/helpers/helper";
import { StateDelineationService } from "src/app/services/delineation-services/state-delineation.service";

@Component({
    selector: "app-gratis",
    templateUrl: "./gratis.component.html",
    styleUrls: ["./gratis.component.scss"],
    providers: [PleaseWaitService]
})
export class GratisComponent implements OnInit , OnDestroy, AfterContentChecked {
    @HostBinding("class") class = "worksheet-static d-flex flex-column flex-grow-1";

    @ViewChild("drawer") drawer: MatSidenav;
    @ViewChild("grid") grid: GridComponent;
    @ViewChild("sideNavContent") sideNavContent: MatSidenavContent;
    @ViewChild("header") header: PageHeaderComponent;
    @ViewChild("statusSelect") statusSelect: MatSelect;
    @ViewChild("startDateInput") startDateInput: ElementRef;
    @ViewChild("zrtTree") zrtTree: ZrtTreeViewComponent;

    isViewmodelReady = false;
    viewmodel: GratisViewmodel;

    constructor(
        public refinerService: GratisRefinerService,
        private gratisDelineationService: GratisDelineationService,
        private pleaseWaitService: PleaseWaitService,
        private formBuilder: FormBuilder,
        private router: Router,
        private snackbar: SnackbarService,
        private appStateService: AppStateService,
        private employeeDelineationService: EmployeeDelineationService,
        private zrtFilterService: GratisZrtFilterService,
        private filterService: FilterService,
        private overlayService: OverlayService,
        private stateDelineationService: StateDelineationService
    ) {}

    async ngOnInit(): Promise<void> {
        this.viewmodel = new GratisViewmodel(
            this.refinerService,
            this.gratisDelineationService,
            this.pleaseWaitService,
            this.formBuilder,
            this.router,
            this.snackbar,
            this.employeeDelineationService,
            this.zrtFilterService,
            this.filterService,
            await this.stateDelineationService.getAll()
        );
        if (
            !this.viewmodel.employeeSubscription ||
            this.viewmodel.employeeSubscription.closed
        ) {
            this.viewmodel.employeeSubscription = this.appStateService.currentEmployee.subscribe(
                async (employee) => {
                    this.viewmodel.employee = employee;
                    if (employee && employee.employeeRoles) {
                        if (!Helper.isEmployeeCustomerServiceOrAdmin(employee) &&
                            employee.employeeRoles
                                .map((er) => er.employeeRoleType.id)
                                .find((t) => t === EmployeeRoleType.RM || t === EmployeeRoleType.TM)
                        ) {
                            this.viewmodel.isTmOrRm = true;
                        }
                        if (employee.employeeRoles
                            .map((er) => er.employeeRoleType.id)
                            .find((t) =>
                                t === EmployeeRoleType.RM ||
                                t === EmployeeRoleType.ZM ||
                                t === EmployeeRoleType.CS ||
                                t === EmployeeRoleType.CorpAdmin
                            )
                        ) {
                            this.viewmodel.shouldDisplayZrtSearch = true;
                        }

                        await this.viewmodel.setFilterData();
                        await this.viewmodel.getGratisBatch(0);
                    }
                }
            );
        }
    }

    ngAfterContentChecked(): void {
        if (this.isViewmodelReady && !this.viewmodel.isGapSet) {
            this.viewmodel.calculateGap();
        }

        let zrtTreeReady = true;

        if (this.viewmodel?.shouldDisplayZrtSearch) {
            zrtTreeReady = !!this.zrtTree;
        }

        if (!this.isViewmodelReady
            && this.viewmodel
            && this.grid
            && this.header
            && this.sideNavContent
            && this.drawer
            && this.statusSelect
            && this.startDateInput
            && this.viewmodel.employee
            && zrtTreeReady
        ) {
            this.isViewmodelReady = true;
            this.getScreenSize();
            void this.viewmodel.initialize(
                this.grid,
                this.header,
                this.sideNavContent,
                this.drawer,
                this.statusSelect,
                this.startDateInput,
                this.zrtTree
            );
        }
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(): void {
        if (this.viewmodel) {
            this.viewmodel.screenHeight = window.innerHeight;
        }
    }
}
