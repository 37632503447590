import { ProjectStatuses } from "../lookup";
import { SharedHelper } from "../shared-helper";
import { ProjectValidationDto } from "./project-validation.dto";
import { ProjectDto } from "./project.dto";

export class ProjectValidation {
    static runValidation(projectDto: ProjectDto): ProjectValidationDto {
        const rtn = new ProjectValidationDto();
        rtn.projectDto = projectDto;
        if (projectDto && projectDto.projectStatusId !== ProjectStatuses.Canceled) {

            ProjectValidation.runHardValidations(rtn);
            ProjectValidation.runSoftValidations(rtn);
            if (rtn.isInvalid) {
                rtn.projectDto.projectStatusId = ProjectStatuses.InActive;
            } else {
                rtn.projectDto.projectStatusId = null;
                ProjectValidation.runStatusTransitions(rtn.projectDto, true);
            }
        }

        return rtn;
    }


    // MOVE TO AZURE FUNCTION!!!!
    static runStatusTransitions(projectDto: ProjectDto, calledInternally = false): void {
        if (projectDto.projectStatusId === ProjectStatuses.Staging) return;
        if ((projectDto.projectStatusId !== ProjectStatuses.InActive
            && projectDto.projectStatusId !== ProjectStatuses.Canceled) || calledInternally) {

            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const visibleDate = projectDto.visibleDate ? new Date(projectDto.visibleDate) : null;
            if (visibleDate) {
                visibleDate.setHours(0, 0, 0, 0);
            }

            const startDate = projectDto.startDate ? new Date(projectDto.startDate) : null;
            if (startDate) {
                startDate.setHours(0, 0, 0, 0);
            }

            const endDate = projectDto.endDate ? new Date(projectDto.endDate) : null;
            if (endDate) {
                endDate.setHours(0, 0, 0, 0);
            }

            if (today < visibleDate) {
                projectDto.projectStatusId = ProjectStatuses.Editable;
            } else if (today >= visibleDate && today < startDate) {
                projectDto.projectStatusId = ProjectStatuses.Visible;
            } else if (today >= startDate && today < endDate) {
                projectDto.projectStatusId = ProjectStatuses.Started;
            } else {
                projectDto.projectStatusId = ProjectStatuses.Completed;
            }
        }
    }

    private static runSoftValidations(rtn: ProjectValidationDto): void {
        if (!rtn.projectDto.areStoresVisibleToAll) {
            let unassignedEmployeeCount = 0;
            for (const projectEmployee of (rtn.projectDto.projectEmployees ?? [])) {
                const found = (rtn.projectDto.projectCustomers ?? []).find((pc) => pc.employeeIds.includes(projectEmployee.employeeId));
                if (!found) {
                    unassignedEmployeeCount++;
                }
            }
            if (unassignedEmployeeCount > 0) {
                rtn.validationErrors.push(`${unassignedEmployeeCount} employee(s) have not been assigned to a store.`);
            }

            let unassignedCustomerCount = 0;
            for (const projectCustomer of (rtn.projectDto.projectCustomers ?? [])) {
                if (!(projectCustomer.employeeIds ?? []).length) {
                    unassignedCustomerCount++;
                }
            }
            if (unassignedCustomerCount > 0) {
                rtn.validationErrors.push(`${unassignedCustomerCount} store(s) have not been assigned to an employee.`);
            }
        }
    }

    private static runHardValidations(rtn: ProjectValidationDto): void {

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const visibleDate = rtn.projectDto.visibleDate ? new Date(rtn.projectDto.visibleDate) : null;
        if (visibleDate) {
            visibleDate.setHours(0, 0, 0, 0);
        }

        const startDate = rtn.projectDto.startDate ? new Date(rtn.projectDto.startDate) : null;
        if (startDate) {
            startDate.setHours(0, 0, 0, 0);
        }

        const endDate = rtn.projectDto.endDate ? new Date(rtn.projectDto.endDate) : null;
        if (endDate) {
            endDate.setHours(0, 0, 0, 0);
        }

        const createdDate = rtn.projectDto.createdUtcDateTime ? new Date(rtn.projectDto.createdUtcDateTime) : today;
        if (createdDate) {
            createdDate.setHours(0, 0, 0, 0);
        }

        //Required fields
        if (!rtn.projectDto.name) {
            rtn.isInvalid = true;
            rtn.validationErrors.push("Missing Required Field: Project Name.");
        }

        if (!rtn.projectDto.projectTypeId) {
            rtn.isInvalid = true;
            rtn.validationErrors.push("Missing Required Field: Project Type.");
        }

        if (!rtn.projectDto.mission) {
            rtn.isInvalid = true;
            rtn.validationErrors.push("Missing Required Field: Mission.");
        }

        if (!rtn.projectDto.visibleDate) {
            rtn.isInvalid = true;
            rtn.validationErrors.push("Missing Required Field: Visible Date.");
        }

        if (!rtn.projectDto.startDate) {
            rtn.isInvalid = true;
            rtn.validationErrors.push("Missing Required Field: Start Date.");
        }

        if (!rtn.projectDto.endDate) {
            rtn.isInvalid = true;
            rtn.validationErrors.push("Missing Required Field: End Date.");
        }

        if (!rtn.projectDto.projectCustomers?.length) {
            rtn.isInvalid = true;
            rtn.validationErrors.push("At least one store must be assigned to the project.");
        }

        if (!rtn.projectDto.projectEmployees?.length) {
            rtn.isInvalid = true;
            rtn.validationErrors.push("At least one employee must be assigned to the project.");
        }

        if (rtn.projectDto.visibleDate && rtn.projectDto.startDate) {
            if (visibleDate > startDate) {
                rtn.isInvalid = true;
                rtn.validationErrors.push("Project visible date can not be greater than the project start date.");
            }
        }

        if (rtn.projectDto.endDate && rtn.projectDto.startDate) {
            if (endDate < startDate) {
                rtn.isInvalid = true;
                rtn.validationErrors.push("Project start date can not be greater than the project end date.");
            }
        }

        if (rtn.projectDto.visibleDate) {
            if (visibleDate < createdDate) {
                rtn.isInvalid = true;
                rtn.validationErrors.push("Project visible date can not be less than the project creation date.");
            }
        }

        if (rtn.projectDto.startDate) {
            if (startDate < createdDate) {
                rtn.isInvalid = true;
                rtn.validationErrors.push("Project start date can not be less than the project creation date.");
            }
        }

        if (rtn.projectDto.endDate) {
            const createdDate = rtn.projectDto.createdUtcDateTime ? new Date(rtn.projectDto.createdUtcDateTime) : SharedHelper.addDays(today, 1);
            if (endDate < createdDate) {
                rtn.isInvalid = true;
                rtn.validationErrors.push("Project start date can not be less than one day after the project creation date.");
            }
        }
    }
}
