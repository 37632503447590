import { AfterContentChecked, Component, OnInit, Type, ViewChild } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterService } from "src/app/services/filter.service";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { RefinerLocation, valueSeparator } from "shield.shared";
import { WholesalerGroupsViewmodel } from "./wholesaler-groups.viewmodel";
import { State } from "src/app/entity-models/state.entity";

@Component({
    selector: "app-wholesaler-groups-filter",
    templateUrl: "./wholesaler-groups-filter.component.html",
    styleUrls: ["./wholesaler-groups-filter.component.scss"]
})
export class WholesalerGroupsFilterComponent extends FilterBaseComponent implements AfterContentChecked {
    //viewchildren
    @ViewChild("orderMethodSelect") orderMethodSelect: MatSelect;
    @ViewChild("stateSelect") stateSelect: MatSelect;

    //public vars
    contentComponent: Type<WholesalerGroupsFilterComponent>;
    icon = "layers";
    name = "Wholesaler Group Information";
    viewmodel = new WholesalerGroupsViewmodel(
        this.filterService
    );

    constructor(
        private filterService: FilterService
    ) {
        super();
    }

    ngAfterContentChecked(): void {
        if (!this.isInitialized && this.viewmodel.dropdownSet) {
            this.setInitialized();
        }
    }

    //events
    onAddCustomerRefiner(event?: KeyboardEvent) {
        if (!event || this.inputSubmitKeys.includes(event.key)) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.wholesaler;
            refiner.value = this.viewmodel.customerInput;
            refiner.dataPropertyName = "customer";
            refiner.dataValue = this._refinerService.buildStringDataValue(this.viewmodel.customerInput);

            this._refinerService.checkAndUpdateRefiner(refiner, true, false);
        }
    }

    onAddGroupRefiner(event?: KeyboardEvent) {
        if (!event || this.inputSubmitKeys.includes(event.key)) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.group;
            refiner.value = this.viewmodel.groupInput;
            refiner.dataPropertyName = "description";
            refiner.dataValue = this._refinerService.buildStringDataValue(this.viewmodel.groupInput);

            this._refinerService.checkAndUpdateRefiner(refiner, true, false);
        }
    }

    onOpenedOrderMethodChange(): void {
        if (!this.orderMethodSelect.panelOpen) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.orderMethod;
            refiner.value = this.viewmodel.selectedOrderMethods.join(", ");
            refiner.dataPropertyName = "method";
            refiner.dataValue = this.viewmodel.selectedOrderMethods.join(valueSeparator);

            this._refinerService.checkAndUpdateRefiner(refiner);
        }
    }

    onOpenedStateChange(): void {
        if (this.stateSelect && !this.stateSelect.panelOpen) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.states;
            refiner.value = this.viewmodel.selectedStates
                .map((vm) => vm.shortCode)
                .join(", ");
            refiner.dataPropertyName = "state";
            refiner.dataValue = this.viewmodel.selectedStates
                .map((vm) => vm.id)
                .join(valueSeparator);

            this._refinerService.checkAndUpdateRefiner(refiner, true);
        }
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.group:
                    this.viewmodel.groupInput = refiner.value;
                    break;
                case RefinerLocation.orderMethod:
                    const selectedOrderMethods = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedOrderMethods = new Array<string>();

                    if (selectedOrderMethods) {
                        for (const method of selectedOrderMethods) {
                            const found = this.viewmodel.orderMethods.find(
                                (m) => m === method
                            );
                            if (found) {
                                rtnSelectedOrderMethods.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedOrderMethods = rtnSelectedOrderMethods;
                    break;
                case RefinerLocation.wholesaler:
                    this.viewmodel.customerInput = refiner.value;
                    break;
                case RefinerLocation.states: {
                    const selectedStateIds = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedStates = new Array<State>();

                    if (selectedStateIds) {
                        for (const stateId of selectedStateIds) {
                            const found = this.viewmodel.states.find(
                                (s) => s.id === stateId.trim()
                            );
                            if (found) {
                                rtnSelectedStates.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedStates = rtnSelectedStates;
                    break;
                }
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const emptyRefiner = new Refiner();

        for (const location of this.viewmodel.refinerLocations) {
            emptyRefiner.location = location;

            const foundRefiner = this._refinerService.refiners.find(
                (refiner) => refiner.location === location
            );

            this.onInputChange(foundRefiner ?? emptyRefiner);
        }
    }
}
