import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VolumeMasterComponent } from "./volume-master.component";
import { RetailVolumeComponent } from "./retail-volume/retail-volume.component";
import { WholesaleAccountVolumeComponent } from "./wholesale-account-volume/wholesale-account-volume.component";
import { ChainHqVolumeComponent } from "./chain-hq-volume/chain-hq-volume.component";
import { SharedModule } from "src/app/shared/shared.module";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { VolumeDataCardComponent } from './volume-data-card/volume-data-card.component';
import { VolumeChartCardComponent } from './volume-chart-card/volume-chart-card.component';
import { VolumeDistributionProductsCardComponent } from './volume-distribution-products-card/volume-distribution-products-card.component';

@NgModule({
    declarations: [
        VolumeMasterComponent,
        RetailVolumeComponent,
        WholesaleAccountVolumeComponent,
        ChainHqVolumeComponent,
        VolumeDataCardComponent,
        VolumeChartCardComponent,
        VolumeDistributionProductsCardComponent
    ],
    imports: [
        SharedModule,
        CommonModule,
        NgxChartsModule,
        FontAwesomeModule,
        MatSlideToggleModule
    ]
})
export class VolumeMasterModule {}
