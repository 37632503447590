import { Injectable } from "@angular/core";
import {
    DailySummaryBatchParamsDto,
    DailySummaryColumns,
    FilterRequestV2Dto,
    FilterSortDto,
    GenericResponseDto
} from "shield.shared";
import { DailySummaryEntry } from "src/app/entity-models/daily-summary-entry.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { DailySummaryFilterMapService } from "../filter-map-services/daily-summary-filter-map.service";
import { DailySummaryEntryOfflineService } from "../offline-services/daily-summary-entry-offline.service";
import { DailySummaryEntryOnlineService } from "../online-services/daily-summary-entry-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";

@Injectable()
export class DailySummaryEntryDelineationService {
    constructor(
        private dailySummaryEntryOfflineService: DailySummaryEntryOfflineService,
        private dailySummaryEntryOnlineService: DailySummaryEntryOnlineService,
        private snackbarService: SnackbarService,
        protected datasourceDelineationService: DatasourceDelineationService
    ) {}

    async getDailySummaryBatch(
        id: string,
        refiners: Refiner[],
        pageSize: number,
        startIndex: number,
        filterSorts: FilterSortDto<DailySummaryColumns>[]
    ): Promise<GenericResponseDto<DailySummaryEntry[]>> {
        const key = new DailySummaryBatchParamsDto();
        key.filterRequestDto = new FilterRequestV2Dto();
        key.filterRequestDto.id = id;
        key.filterRequestDto.filters = DailySummaryFilterMapService.mapFilterData(
            refiners
        );
        key.filterRequestDto.pageSize = pageSize;
        key.filterRequestDto.startIndex = startIndex;
        key.filterRequestDto.filterSorts = filterSorts;

        const offline = (key: DailySummaryBatchParamsDto) => {
            return this.dailySummaryEntryOfflineService.getDailySummaryBatch(
                key
            );
        };
        const online = (key: DailySummaryBatchParamsDto) => {
            return this.dailySummaryEntryOnlineService.getDailySummaryBatch(
                key
            );
        };
        const response = await this.datasourceDelineationService.makeCall<
            DailySummaryBatchParamsDto,
            DailySummaryEntry[]
        >(key, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }
}
