import { EmployeeRoleType } from "shield.shared";
import { Employee } from "src/app/entity-models/employee.entity";

export class ProjectPersonSelectViewmodel {

    employeeId: string;
    checked: boolean;
    territory: string;
    first: string;
    last: string;
    storedEmployee: Employee;

    constructor(private employee: Employee){
        this.setTerritory();
            this.last = this.employee.lastName;
            this.first = this.employee.firstName;
            this.employeeId = employee.id;
            this.checked = false;
            this.storedEmployee = employee;
    }

    private setTerritory(): void {
        if (this.employee.employeeRoles.some(v => v.employeeRoleType.id == EmployeeRoleType.ZCAM)) {
            this.territory = "Zone Chain Accounts";
            return;
        }

        if (this.employee.employeeRoles.some(v => v.employeeRoleType.id == EmployeeRoleType.NAM)) {
            this.territory = "National Accounts";
            return;
        }

        if (this.employee.employeeRoles.some(v => v.employeeRoleType.id == EmployeeRoleType.SPM)) {
            this.territory = "Special Projects";
            return;
        }

        if (this.employee.employeeRoles.some(v => v.employeeRoleType.id == EmployeeRoleType.ZM)) {
            this.territory = "Zone " + this.employee.zone;
            return;
        }

        if (this.employee.employeeRoles.some(v => v.employeeRoleType.id == EmployeeRoleType.RM)) {
            this.territory = "Region " + this.employee.zone + this.employee.region;
            return;
        }

        this.territory = "Territory " + this.employee.zrt;
    }
}
