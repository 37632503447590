import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ContractPaymentStatuses, ContractPaymentStatusLookup } from "shield.shared";
import { ContactViewModel } from "src/app/accounts/contact/contact.viewmodel";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { SnackbarService } from "src/app/services/snackbar.service";
import { RetailAgreementViewModel } from "./retail-agreements.viewmodel";

@Component({
    selector: "app-retail-agreements",
    templateUrl: "./retail-agreements.component.html",
    styleUrls: ["./retail-agreements.component.scss"]
})
export class RetailAgreementsComponent implements OnInit {

    private _retailAgreements: RetailAgreementViewModel[];
    @Input()
    get retailAgreements(): RetailAgreementViewModel[] {
        return this._retailAgreements;
    }
    set retailAgreements(value: RetailAgreementViewModel[]) {
        this._retailAgreements = value;
        if (this._retailAgreements?.length) {
            this.retailAgreements.sort((a, b) => new Date(a.dateSigned).getTime() < new Date(b.dateSigned).getTime() ? 1 : -1)
        }
    }

    private _customer: Customer;
    @Input()
    get customer(): Customer {
        return this._customer;
    }
    set customer(value: Customer) {
        this._customer = value;
    }

    private _contactOptions: ContactViewModel[];
    @Input()
    get contactOptions(): ContactViewModel[] {
        return this._contactOptions;
    }
    set contactOptions(value: ContactViewModel[]) {
        this._contactOptions = value;
    }

    private _currentEmployee: Employee;
    @Input()
    get currentEmployee(): Employee {
        return this._currentEmployee;
    }
    set currentEmployee(value: Employee) {
        this._currentEmployee = value;
    }

    @Output() openNewAgreement = new EventEmitter();
    @Output() openViewAgreement = new EventEmitter<RetailAgreementViewModel>();

    shouldAddNew(): boolean {
        return (
            this.retailAgreements.length === 0
            || this.retailAgreements[0].status ===
                ContractPaymentStatusLookup.find(
                    (s) => s.id === ContractPaymentStatuses.Completed
                ).description
            || this.retailAgreements[0].status ===
                ContractPaymentStatusLookup.find(
                    (s) => s.id === ContractPaymentStatuses.Canceled
                ).description
        );
    }

    showIfActive(): void {
        this.snackbar.showWarning(
            "Customer already has an active agreement.  The existing contract must be closed before a new one can be created."
        );
    }

    constructor(
        private snackbar: SnackbarService
    ) {}

    ngOnInit(): void {}
}
