import { NotificationRequestDto, RouteDto, RouteStopDto } from "shield.shared";
import { Employee } from "src/app/entity-models/employee.entity";
import { RouteStop } from "src/app/entity-models/route-stop.entity";
import { Route } from "src/app/entity-models/route.entity";
import { Helper } from "src/app/helpers/helper";
import { CustomerConverterService } from "./customer-converter.service";

export class RouteConverterService {

    static routeToRouteDto(entity: Route): RouteDto {
        const rtn = new RouteDto();

        rtn.id = entity.id;
        rtn.employeeId = entity.employeeId;
        rtn.employeeZrt = entity.employeeZrt;
        rtn.date = Helper.localMidnightDateToUtcMidnightString(entity.date);
        rtn.name = entity.name;
        rtn.description = entity.description;
        rtn.stops = entity.stops.map(
            (s) => RouteConverterService.routeStopToRouteStopDto(s)
        );
        rtn.createdUserFullName = entity.createdUserFullName;
        rtn.createdUserId = entity.createdUserId;
        rtn.createdUserZrt = entity.createdUserZrt;
        rtn.createdUtcDateTime = entity.createdUtcDateTime ? entity.createdUtcDateTime.toISOString() : null;
        rtn.modifiedUserFullName = entity.modifiedUserFullName;
        rtn.modifiedUserId = entity.modifiedUserId;
        rtn.modifiedUserZrt = entity.modifiedUserZrt;
        rtn.modifiedUtcDateTime = entity.modifiedUtcDateTime ? entity.modifiedUtcDateTime.toISOString() : null;
        rtn.rowversion = entity.rowversion;

        return rtn;
    }

    static routeDtoToRoute(dto: RouteDto): Route {
        const rtn = new Route();

        rtn.id = dto.id;
        rtn.employeeId = dto.employeeId;
        rtn.employeeZrt = dto.employeeZrt;
        rtn.date = dto.date ? Helper.utcMidnightStringToLocalMidnightDate(dto.date) : null;
        rtn.name = dto.name;
        rtn.description = dto.description;
        rtn.stops = dto.stops.map(
            (s) => RouteConverterService.routeStopDtoToRouteStop(s)
        );
        rtn.createdUserFullName = dto.createdUserFullName;
        rtn.createdUserId = dto.createdUserId;
        rtn.createdUserZrt = dto.createdUserZrt;
        rtn.createdUtcDateTime = dto.createdUtcDateTime ? new Date(dto.createdUtcDateTime) : null;
        rtn.modifiedUserFullName = dto.modifiedUserFullName;
        rtn.modifiedUserId = dto.modifiedUserId;
        rtn.modifiedUserZrt = dto.modifiedUserZrt;
        rtn.modifiedUtcDateTime = dto.modifiedUtcDateTime ? new Date(dto.modifiedUtcDateTime) : null;
        rtn.rowversion = dto.rowversion;

        rtn.completedStops = dto.completedStops;

        return rtn;
    }

    static routeStopToRouteStopDto(entity: RouteStop): RouteStopDto {
        const rtn = new RouteStopDto();

        rtn.id = entity.id;
        rtn.customerId = entity.customerId;
        rtn.label = entity.label;
        rtn.address = CustomerConverterService.addressToAddressDto(entity.address);
        rtn.sequence = entity.sequence;
        rtn.px3RankId = entity.px3RankId;
        rtn.isCompleted = entity.isCompleted;

        return rtn;
    }

    static routeStopDtoToRouteStop(dto: RouteStopDto): RouteStop {
        const rtn = new RouteStop();

        rtn.id = dto.id;
        rtn.customerId = dto.customerId;
        rtn.label = dto.label;
        rtn.address = CustomerConverterService.addressDtoToAddress(dto.address);
        rtn.sequence = dto.sequence;
        rtn.px3RankId = dto.px3RankId;
        rtn.isCompleted = dto.isCompleted;

        return rtn;
    }

    static formatRouteMessage(
        senderEmployee: Employee, route: Route, message: string, url: string
    ): NotificationRequestDto {
        const rtn = new NotificationRequestDto();
        rtn.subject = `${senderEmployee.zrt} ${senderEmployee.fullName} - Shared Route - ${route.name}`;
        rtn.message = `
            <p>${rtn.subject}</p>
            <p>${route.description ? "Route Description: " + route.description : ""}</p>
            <p>${message ? ("Message from " + senderEmployee.firstName + ": " + message) : ""}</p>
            <a href="${url}">Directions for Route (${route.name})</a>`;
        rtn.textMessage = `${senderEmployee.zrt} ${senderEmployee.fullName} - ${route.name}${message ? " - " + message : ""}: ${url}`

        return rtn;
    }
}
