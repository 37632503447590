
<div class="mt-4 mb-4">
    <div class="d-flex justify-content-between align-items-center">
        <mat-slide-toggle [(ngModel)]="viewmodel.myListSelected" [disabled]="!viewmodel.userFilter?.myList?.length || !filtersLoaded" (change)="onMyListToggle()">
            My List
        </mat-slide-toggle>
        <div>
            Count: {{ viewmodel.myListLoading
                ? '...'
                : (viewmodel.userFilter?.myList
                    ? viewmodel.userFilter.myList.length
                    : 0
                  ) | number }}
        </div>
    </div>
    <div class="d-flex justify-content-between mt-2">
        <button appThrottleClick class="btn btn-secondary w-50 mr-1" [disabled]="!filtersLoaded" (throttledClick)="onMyListEmpty()">
            <fa-icon [icon]="faTimes" class="mr-1"></fa-icon>
            Empty
        </button>
        <button appThrottleClick class="btn btn-primary w-50"
            [disabled]="viewmodel.myListSelected || !filtersLoaded"
            (throttledClick)="onMyListPush()">
            <fa-icon [icon]="faUpload" class="mr-1"></fa-icon>
            Push
        </button>
    </div>
</div>
<div class="mt-4 mb-4">
    <mat-form-field appearance="outline" color="accent">
        <mat-label>My Filters</mat-label>
        <mat-select [(value)]="viewmodel.selectedFilter"
            [disabled]="!filtersLoaded"
            (selectionChange)="filterSelectionChange()">
            <mat-option [value]="">
            </mat-option>
            <mat-option *ngFor="let list of viewmodel?.userFilter?.myFilters"
                [value]="list.name">
                {{ list.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class="d-flex justify-content-between mt-2">
        <button appThrottleClick class="btn btn-secondary w-50 mr-1"
            (throttledClick)="confirmFilterDeletion()">
            <fa-icon [icon]="faTrash" class="mr-1"></fa-icon>Delete
        </button>
        <button appThrottleClick class="btn btn-primary w-50 ml-1" (throttledClick)="onOpenCreateFilter()">
            <fa-icon [icon]="faSave" class="mr-1">
                save</fa-icon>Save
        </button>
    </div>
</div>
<div class="mt-4 mb-4" *ngIf="showSpecialCoverage">
    <div class="d-flex justify-content-between align-items-center">
        <mat-slide-toggle
            [disabled]="!filtersLoaded"
            [(ngModel)]="viewmodel.specialCoverageSelected"
            (change)="onSpecialCoverageToggle()">
            Special Coverage
        </mat-slide-toggle>
        <div>
            <a (click)="onOpenSpecialCoverageInfoModal()"><small>(What's this?)</small></a>
        </div>
    </div>
    <div class="mt-1">
        <ng-container *ngIf="viewmodel.specialCoverageSelected">
            <div>
                Total Entries Imported: {{ (viewmodel.userFilter?.specialCoverage
                            ? viewmodel.userFilter.specialCoverage.length
                            : 0
                      ) | number }}
            </div>
        </ng-container>
    </div>
    <div *ngIf="viewmodel.specialCoverageSelected" class="d-flex justify-content-between mt-2">
        <button appThrottleClick class="btn btn-secondary w-50 mr-1" (throttledClick)="onSpecialCoverageEmpty()">
            <fa-icon [icon]="faTimes" class="mr-1"></fa-icon>
            Clear
        </button>
        <input #coverageInput hidden="true" type="file" onclick="this.value=null" (change)="onSpecialCoverageChange($event)" multiple accept=".csv,.xls,.xlsx"/>
        <button appThrottleClick class="btn btn-primary w-50 ml-1" (throttledClick)="coverageInput.click()">
            <fa-icon [icon]="faFileExcel" class="mr-1"></fa-icon>
            Import
        </button>
    </div>
</div>
