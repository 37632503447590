import {
    Component,
    OnInit
} from "@angular/core";
import { CallOrderProductViewModel } from "../../accounts/call-master/call-viewmodels/call-order-product.viewmodel";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import {
    GenericDropDownDto,
    ProjectStatuses,
    SharedHelper
} from "shield.shared";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { CustomerStateService } from "src/app/accounts/account-services/customer-state.service";
import { Project } from "src/app/entity-models/project.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { WholesalerViewmodel } from "src/app/shared/viewmodels/wholesaler.viewmodel";
import { CallOrderDate } from "src/app/entity-models/call-order-date.entity";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { ProjectOrderDate } from "src/app/entity-models/project-order-date.entity";
import { CallService } from "src/app/accounts/call-master/call-services/call.service";

@Component({
    selector: "app-order-date-options-dialog",
    templateUrl: "./order-date-options-dialog.component.html",
    styleUrls: ["./order-date-options-dialog.component.scss"]
})
export class OrderDateOptionsDialogComponent implements OnInit {

    dateFormat = MY_DATE_FORMATS.display.customDateInput;
    manager: Employee
    orderProduct: CallOrderProductViewModel;
    previousProjectId: string;
    projectDropDown = new Array<GenericDropDownDto>();
    //https://cuppalabs.github.io/components/multiselectDropdown/
    projectsDropdownSettings = {
        singleSelection: true,
        showCheckbox: false,
        text: "Select Project",
        enableCheckAll: false,
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "displayValue",
        searchBy: ["displayValue"],
        classes: "multi-select-container c-btn pure-checkbox"
    };
    projects = new Array<Project>();
    selectedProject = new Array<GenericDropDownDto>();
    selectedWholesaler = new Array<WholesalerViewmodel>();
    wholesalerDropDown = new Array<WholesalerViewmodel>();
    wholesalerDropdownSettings = {
        singleSelection: true,
        showCheckbox: false,
        text: "Select Wholesaler",
        enableCheckAll: false,
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "displayValue",
        searchBy: ["displayValue"],
        classes: "multi-select-container c-btn pure-checkbox"
    };

    constructor(
        private ref: SwisherOverlayRef<
            CallOrderProductViewModel,
            OrderDateOptionsDialogComponent
        >,
        private projectDelineationService: ProjectDelineationService,
        private customerStateService: CustomerStateService,
        private employeeDelineationService: EmployeeDelineationService,
        private customerDelineationService: CustomerDelineationService,
        private callService: CallService,
    ) {}

    async ngOnInit(): Promise<void> {

        this.orderProduct = Object.assign({}, this.ref.data);
        this.orderProduct.orderDates = this.ref?.data?.orderDates?.length ? [...this.ref.data.orderDates] : new Array<CallOrderDate>();

        const projectResponse = await this.projectDelineationService.getProfileProjects(
            [ProjectStatuses.Started],
            this.customerStateService.customer.customerType,
            this.orderProduct.employee?.id,
            this.customerStateService.customer.id
        );
        if (!projectResponse) {
            this.ref.close();
            return;
        }

        if (this.orderProduct.employee.managerEmployeeId) {
            const managerResponse = await this.employeeDelineationService.getById(this.orderProduct.employee.managerEmployeeId);
            if (!managerResponse) {
                this.ref.close();
                return;
            }

            if (managerResponse.values) {
                this.manager = managerResponse.values;
            }
        }

        if (projectResponse.values?.length) {
            this.projects = projectResponse.values.filter(
                (p) => p.projectProducts?.some((pp) => pp.productId === this.orderProduct?.product?.id)
            );
            this.projectDropDown = this.projects.map((p) => {
                const item = new GenericDropDownDto();
                item.id = p.id;
                item.name = p.name;
                item.displayValue = p.name;

                return item;
            });
        }

        const selectedProject = new GenericDropDownDto();
        selectedProject.id = this.orderProduct.selectedProject.id;
        selectedProject.name = this.orderProduct.selectedProject.name;
        selectedProject.displayValue = selectedProject.name;
        this.selectedProject = [selectedProject];

        this.updateWholesaler();
    }

    close(ok?: boolean): void {

        if (this.orderProduct.orderDates.reduce((acc, d) => acc += d.quantity, 0) !==
                this.orderProduct.quantity
        ) {
            return;
        }
        if (ok) {
            this.orderProduct.isConfirmed = true;
            this.ref.close(this.orderProduct);
        } else {
            this.ref.close();
        }

    }

    openedProjectSelect(): void {
        this.previousProjectId = this.selectedProject[0].id;
    }

    projectDeselect(event: any): void {
        // Prevent the deselect
        if (event) {
            this.selectedProject = [event];
        }
    }

    projectSelectChange(event: any): void {

        if (!event) {
            setTimeout(() => {
                if (this.previousProjectId !== this.selectedProject[0].id) {

                    this.orderProduct.orderDates = null;
                    this.orderProduct.selectedProject = this.projects.find((p) => p.id === this.selectedProject[0].id);
                    this.selectedProjectChange();
                }
            }, 0);
        }
    }

    closedWholesalerSelect(): void {
        setTimeout(() => {
            this.orderProduct.wholesalers = this.wholesalerDropDown;
            this.orderProduct.wholesaler = this.selectedWholesaler;
        }, 0);
    }

    wholesalerDeselect(event: any): void {
        // Prevent the deselect
        if (event) {
            this.selectedWholesaler = [event];
        }
    }

    isOkDisabled(): boolean {

        if (this.orderProduct?.orderDates?.reduce(function(acc, obj) { return acc + obj.quantity }, 0) !== this.orderProduct.quantity) {
            return true;
        } else {
            return false;
        }
    }

    private async selectedProjectChange(): Promise<void> {

        await this.updateWholesaler();

        this.orderProduct.orderDates = this.callService.buildOrderDates(
            this.orderProduct.dateAvailable,
            this.orderProduct.selectedProject,
        );
    }

    private async updateWholesaler() {
        const wholesalerIds = this.orderProduct.selectedProject.projectProducts.filter((pp) => pp.productId === this.orderProduct.product.id)
            .map((pp) => pp.wholesalerId);
        const wholesalerResponse = await this.customerDelineationService.getByIds(wholesalerIds);
        if (!wholesalerResponse) {
            this.close();
        }

        this.wholesalerDropDown = wholesalerResponse.values.map((w) => {
            return new WholesalerViewmodel(w);
        });
        this.wholesalerDropDown.sort((a, b) => a.displayValue <= b.displayValue ? 1 : -1);

        if (this.wholesalerDropDown?.length) {
            this.selectedWholesaler = [this.wholesalerDropDown[0]];
        }
    }
}
