import { Contact } from "src/app/entity-models/contact.entity";
import { Employee } from "src/app/entity-models/employee.entity";

export class ShareOptionsDialogViewmodel {
    comments: string;
    shareEmployees: Employee[];
    confirmButtonText: string;
    customerId: string;
    shareCustomerContact: Contact;
    onSaveShare: (data: ShareOptionsDialogViewmodel) => Promise<boolean | void>;
    onClose: (data: ShareOptionsDialogViewmodel) => void;
    isConfirmed: boolean;
    isOffline: boolean;
    blocking = true;
    promise: Promise<boolean | void>
}
