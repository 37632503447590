import { GenericLookup, SurveyQuestionTypes } from "shield.shared";
import { CallSurveyAnswer } from "src/app/entity-models/call-survey-answer.entity";
import { SurveyQuestionOption } from "src/app/entity-models/survey-question-drop-down-detail.entity";
import { SurveyQuestion } from "src/app/entity-models/survey-question.entity";

export class ActivitySurveyResponseViewModel {
    id: string;
    surveyId: string;
    surveyQuestionType: GenericLookup<SurveyQuestionTypes>;
    orderIndex: number;
    text: string;
    isActive: boolean;
    isRequired: boolean;
    surveyQuestionOptions: SurveyQuestionOption[];

    answer: CallSurveyAnswer;
    surveyQuestionOptionDefault?: SurveyQuestionOption;

    constructor(question: SurveyQuestion, answer?: CallSurveyAnswer) {
        return {
            ...question,
            answer: answer ?? new CallSurveyAnswer()
        }
    }
}
