import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { Employee } from "src/app/entity-models/employee.entity";
import { ProjectEmployee } from "src/app/entity-models/project-employee.entity";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { ProjectStateService } from "src/app/services/project-state-service";
import { GridDataTypes } from "src/app/shared/enums/grid-data-types.enum";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { ProjectSummaryAssignmentsGridViewmodel } from "./project-summary-assignments-grid.viewmodel";

export class ProjectSummaryAsignmentsViewmodel {

    checkingAssignment = true;
    isBuilding = false;

    grid: GridComponent;
    gridData = new Array<GridData>();
    isFixedLayout = true;

    columnDef: ColumnDef[] = [
        {
            headerName: "Territory",
            dataPropertyName: "territory",
            isAvailable: true,
            isSelected: true
        },
        {
            headerName: "First",
            dataPropertyName: "first",
            isAvailable: true,
            isSelected: true
        },
        {
            headerName: "Last",
            dataPropertyName: "last",
            isAvailable: true,
            isSelected: true
        },
        {
            headerName: "Assignments",
            dataPropertyName: "assignment",
            isAvailable: true,
            isSelected: true,
            dataType: GridDataTypes.number
        }
    ];

    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    selectedColumnVisibilityComunicator = this.columnDef.map((cd) => cd.isAvailable && cd.headerName);

    employeeDelineationService: EmployeeDelineationService;
    projectStateService: ProjectStateService;

    constructor(employeeDelineationService: EmployeeDelineationService
        , projectStateService: ProjectStateService){
        this.employeeDelineationService = employeeDelineationService;
        this.projectStateService = projectStateService;
    }

    initialize(grid: GridComponent): void {
        this.grid = grid;
    }

    async buildAssignmentsFromEmployees() {

        if (!this.isBuilding) {

            this.isBuilding = true;
            let gridData = new Array<GridData>();

            const response = await this.employeeDelineationService.getByIds(this.projectStateService.project.projectEmployees
                .map((projectEmployee) => projectEmployee.employeeId));

            if (!response || !response.values) {
                this.isBuilding = false;
                return;
            }

            const employees = response.values.sort((a, b) => (a.zrt ?? "").localeCompare(b.zrt ?? ""));

            for (const employee of employees) {
                const storeCount = this.projectStateService.project.areStoresVisibleToAll
                    ? this.projectStateService.project.projectCustomers.length
                    : this.projectStateService.project.projectCustomers
                        .filter(v => v.employeeIds.includes(employee.id))
                        .length;
                const gridItem: GridData = {
                    data: new ProjectSummaryAssignmentsGridViewmodel(
                        employee,
                        storeCount
                    ),
                    detailArrayName: null,
                    isExpanded: false,
                    index: null
                };
                gridData.push(gridItem);
            }
            this.gridData = gridData;

            this.dataSource = new TableVirtualScrollDataSource(
                this.gridData
            );

            this.isBuilding = false;
        }
    }
}
