export enum CallHistoryColumns {
    createdBy = "Created By",
    start = "Start",
    end = "End",
    duration = "Duration",
    type = "Type",
    comments = "Comments",
    customerId = "Customer ID",
    name = "Name",
    address = "Address",
    city = "City",
    state = "State",
    zip = "Zip",
    hasPictures = "Has Pictures?",
    px3Rank = "PX3 Rank",
    // Call Pictures specific
    pictures = "Pictures",
    storeInformation = "Store Information",
    lastCall = "Last Call"
}
