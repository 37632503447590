import { DayTimeEntry } from "src/app/entity-models/day-time-entry.entity";
import { TimeEntry } from "src/app/entity-models/time-entry.entity";
import { DayTimeEntryWorkWithViewmodel } from "./day-time-entry-work-with.viewmodel";

export class DailyTimeEntriesViewmodel {
    constructor(
        entries: TimeEntry[],
        day: DayTimeEntry,
        id: string,
        key: string,
        employeeId: string
    ) {
        this.entries = entries;
        this.dayTimeTotalMs = 0;

        entries.forEach((myEntry) => {
            const start = new Date(myEntry.start).getTime();
            const end = new Date(myEntry.end).getTime();
            this.dayTimeTotalMs += end - start;
        });

        this.dailyHours = this.dayTimeTotalMs / 1000 / 60 / 60;

        this.id = id;
        this.key = key;
        this.employeeId = employeeId;
        this.resetDay = false;

        if (day) {
            this.isCompleted = day.isCompleted;
            this.mileage = day.mileage;
            this.resetDay = day.resetDay;
            this.endOfDayComment = day.endOfDayComment;
            this.rowVersion = day.rowversion;
            this.dayTimeEntryWorkWiths = [];
            for (const ww of day.dayTimeEntryWorkWiths) {
                const wwViewmodel = new DayTimeEntryWorkWithViewmodel();
                wwViewmodel.id = ww.id;
                wwViewmodel.person = ww.person;
                wwViewmodel.start = ww.start;
                wwViewmodel.end = ww.end;
                wwViewmodel.setDuration();
                this.dayTimeEntryWorkWiths.push(wwViewmodel);
            }
        }
    }
    id: string;
    key: string;
    employeeId: string;
    entries: TimeEntry[];
    dailyHours: number;
    dayTimeTotalMs: number;
    isCompleted: boolean;
    mileage: number;
    resetDay: boolean;
    endOfDayComment: string;
    dayTimeEntryWorkWiths: DayTimeEntryWorkWithViewmodel[] = [];
    rowVersion: string;

    buildDomainModelFromViewModel(): DayTimeEntry {
        const entity = new DayTimeEntry();
        entity.id = this.id;
        entity.employeeId = this.employeeId;
        entity.endOfDayComment = this.endOfDayComment;
        entity.isCompleted = true;
        entity.date = new Date(this.key.replace(new RegExp("_", "g"), "-"));
        entity.key = this.key;
        entity.mileage = this.mileage;
        entity.resetDay = this.resetDay;
        entity.rowversion = this.rowVersion;
        entity.dayTimeEntryWorkWiths = [];
        for (const workWith of this.dayTimeEntryWorkWiths) {
            entity.dayTimeEntryWorkWiths.push(
                workWith.buildDomainModelFromViewmodel()
            );
        }

        return entity;
    }
}
