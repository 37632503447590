import { RefinerDto, RefinerGroupDto, RefinerLocation, EntityObjectNames, EntityPropertyNames } from "shield.shared";

export class RefinerConverterService {
    static splitRefinerDtoValues(
        refinerDtos: RefinerDto[]
    ): RefinerGroupDto {
        const rtn = new RefinerGroupDto();
        rtn.refiners = new Array<RefinerDto>();

        for (const dto of refinerDtos) {
            const values = dto.value.split(",").map((v) => v.trim());
            const dataValues = dto.dataValue ? dto.dataValue.split(",").map((dv) => dv.trim()) : values;
            for (let i = 0; i < dataValues.length; i++) {

                rtn.refiners.push(
                    this.createRefinerDto(
                        dto.refinerLocation,
                        dto.dtoObjectName,
                        dto.dtoPropertyName,
                        values[i],
                        dataValues[i]
                    )
                );
            }
        }

        return rtn;
    }

    static createRefinerDto(
        refinerLocation: RefinerLocation,
        dtoObjectName: EntityObjectNames,
        dtoPropertyName: EntityPropertyNames,
        value: string,
        dataValue?: string
    ): RefinerDto {
        const rtn = new RefinerDto();

        rtn.refinerLocation = refinerLocation;
        rtn.dtoObjectName = dtoObjectName;
        rtn.dtoPropertyName = dtoPropertyName;
        rtn.value = value;
        rtn.dataValue = dataValue ?? value;

        return rtn;
    }
}
