import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    GenericRequestDto,
    GenericResponseDto,
    newSequentialId,
    StringArrayDto,
    ZoneRecapDto,
    ZoneRecapParamsDto,
    ZoneRecapProductDto
} from "shield.shared";
import { ZoneRecap } from "src/app/entity-models/zone-recap.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { ZoneRecapConverterService } from "../converter-services/zone-recap-converter.service";

@Injectable()
export class ZoneRecapOnlineService {
    constructor(private http: HttpClient) {}

    async getRecap(
        key: ZoneRecapParamsDto
    ): Promise<GenericResponseDto<ZoneRecap>> {

        const request = new GenericRequestDto();
        request.id = newSequentialId();
        request.values = key.filterRequestDto;
        const result = await this.http
            .post<GenericResponseDto<ZoneRecapDto>>(`/api/zone-recap/${key.zone}`, request)
            .toPromise();

        return ConverterHelper.dtoToEntity<ZoneRecapDto, ZoneRecap>(
            result,
            (r) => ZoneRecapConverterService.zoneRecapDtoZToZoneRecap(r)
        );
    }

    async upsertProducts(
        key: ZoneRecapParamsDto
    ): Promise<GenericResponseDto<ZoneRecapProductDto>> {

        const request = new GenericRequestDto();
        request.id = newSequentialId();
        request.values = key.productIdArrayDto;
        const result = await this.http
            .post<GenericResponseDto<ZoneRecapProductDto>>(`/api/zone-recap/${key.zone}/upsert-products`, request)
            .toPromise();

        return ConverterHelper.dtoAsDto<ZoneRecapProductDto>(result);
    }
}
