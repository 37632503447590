import { NgModule } from "@angular/core";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { ReactiveFormsModule } from "@angular/forms";

import { BeforePictureComponent } from "../retail-call/before-picture/before-picture.component";
import { SalesGratisComponent } from "./sales-gratis/sales-gratis.component";

import { ExchangeComponent } from "./exchange/exchange.component";
import { AfterPicturesComponent } from "./after-pictures/after-pictures.component";

import { NgxMaskModule } from "ngx-mask";
import { WebcamModule } from "ngx-webcam";

import { ContactComponent } from "../../contact/contact.component";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatCarouselModule } from "@ngmodule/material-carousel";
import { SharedModule } from "src/app/shared/shared.module";
//Icons
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { RemoveContactComponent } from "../../remove-contact/remove-contact.component";
import { StepCamComponent } from "../step-cam/step-cam.component";
import { CallDistributionService } from "../call-services/call-distribution.service";
import { MatIconModule } from "@angular/material/icon";
import { RetailCallStepperComponent } from "../retail-call/retail-call-stepper/retail-call-stepper.component";
import { SwisherRetailFormatComponent } from './receipts/retail-receipt/swisher-retail-format/swisher-retail-format.component';
import { RetailFormatBaseComponent } from './receipts/retail-receipt/retail-format-base/retail-format-base.component';
import { EasRetailFormatComponent } from './receipts/retail-receipt/eas-retail-format/eas-retail-format.component';
import { WholesalerReceiptService } from "./stepper-call-services/wholsaler-receipt.service";
import { EasRetailFormatDraftComponent } from './receipts/retail-receipt/eas-retail-format-draft/eas-retail-format-draft.component';
import { RetailReceiptService } from "./stepper-call-services/retail-receipt.service";
import { InDistroComponent } from "./in-distro/in-distro.component";
import { TaxCalculatorComponent } from "./tax-calculator/tax-calculator.component";
import { ClosingNotesComponent } from "./closing-notes/closing-notes.component";
import { RmWholesaleCallComponent } from "../rm-wholesale-call/rm-wholesale-call.component";
import { ReceiptsComponent } from "./receipts/receipts.component";
import { RetailReceiptComponent } from "./receipts/retail-receipt/retail-receipt.component";
import { WholesalerFormatBaseComponent } from "./receipts/wholesaler-receipt/wholesaler-format-base/wholesaler-format-base.component";
import { SwisherWholesalerFormatComponent } from "./receipts/wholesaler-receipt/swisher-wholesaler-format/swisher-wholesaler-format.component";
import { EasWholesalerFormatComponent } from "./receipts/wholesaler-receipt/eas-wholesaler-format/eas-wholesaler-format.component";
import { EasWholesalerSwisherRepFormatComponent } from "./receipts/wholesaler-receipt/eas-wholesaler-swisher-rep-format/eas-wholesaler-swisher-rep-format.component";
import { EasWholesalerSwisherRepFormatDraftComponent } from "./receipts/wholesaler-receipt/eas-wholesaler-swisher-rep-format-draft/eas-wholesaler-swisher-rep-format-draft.component";
import { EasWholesalerFormatDraftComponent } from "./receipts/wholesaler-receipt/eas-wholesaler-format-draft/eas-wholesaler-format-draft.component";
import { SwisherWholesalerFormatDraftComponent } from "./receipts/wholesaler-receipt/swisher-wholesaler-format-draft/swisher-wholesaler-format-draft.component";
import { WholesalerFormatDraftComponent } from "./receipts/wholesaler-receipt/wholesaler-format-draft/wholesaler-format-draft.component";
import { EasRetailFormatSwisherRepDraftComponent } from "./receipts/retail-receipt/eas-retail-format-swisher-rep-draft/eas-retail-format-swisher-rep-draft.component";
import { EasRetailFormatSwisherRepComponent } from "./receipts/retail-receipt/eas-retail-format-swisher-rep/eas-retail-format-swisher-rep.component";
import { SwisherRetailFormatDraftComponent } from "./receipts/retail-receipt/swisher-retail-format-draft/swisher-retail-format-draft.component";
import { RetailFormatDraftComponent } from "./receipts/retail-receipt/retail-format-draft/retail-format-draft.component";
import { SwisherRetailWideTaxFormatComponent } from "./receipts/retail-receipt/swisher-retail-wide-tax-format/swisher-retail-wide-tax-format.component";
import { SwisherRetailWideTaxFormatDraftComponent } from "./receipts/retail-receipt/swisher-retail-wide-tax-format-draft/swisher-retail-wide-tax-format-draft.component";
import { EasRetailNarrowTaxFormatSwisherRepDraftComponent } from "./receipts/retail-receipt/eas-retail-narrow-tax-format-swisher-rep-draft/eas-retail-narrow-tax-format-swisher-rep-draft.component";
import { SwisherRetailNarrowTaxFormatComponent } from "./receipts/retail-receipt/swisher-retail-narrow-tax-format/swisher-retail-narrow-tax-format.component";
import { EasRetailNarrowTaxFormatSwisherRepComponent } from "./receipts/retail-receipt/eas-retail-narrow-tax-format-swisher-rep/eas-retail-narrow-tax-format-swisher-rep.component";
import { EasRetailNarrowTaxFormatDraftComponent } from "./receipts/retail-receipt/eas-retail-narrow-tax-format-draft/eas-retail-narrow-tax-format-draft.component";
import { EasRetailNarrowTaxFormatComponent } from "./receipts/retail-receipt/eas-retail-narrow-tax-format/eas-retail-narrow-tax-format.component";
import { EasRetailWideTaxFormatSwisherRepDraftComponent } from "./receipts/retail-receipt/eas-retail-wide-tax-format-swisher-rep-draft/eas-retail-wide-tax-format-swisher-rep-draft.component";
import { EasRetailWideTaxFormatComponent } from "./receipts/retail-receipt/eas-retail-wide-tax-format/eas-retail-wide-tax-format.component";
import { SwisherRetailNarrowTaxFormatDraftComponent } from "./receipts/retail-receipt/swisher-retail-narrow-tax-format-draft/swisher-retail-narrow-tax-format-draft.component";
import { EasRetailWideTaxFormatSwisherRepComponent } from "./receipts/retail-receipt/eas-retail-wide-tax-format-swisher-rep/eas-retail-wide-tax-format-swisher-rep.component";
import { EasRetailWideTaxFormatDraftComponent } from "./receipts/retail-receipt/eas-retail-wide-tax-format-draft/eas-retail-wide-tax-format-draft.component";
import { WholesaleReceiptComponent } from "./receipts/wholesaler-receipt/wholesale-receipt.component";
import { RmWholesaleCallStepperComponent } from "../rm-wholesale-call/rm-wholesale-call-stepper/rm-wholesale-call-stepper.component";
import { RetailCallComponent } from "../retail-call/retail-call.component";
import { MatSliderModule } from '@angular/material/slider'

@NgModule({
    declarations: [
        BeforePictureComponent,
        SalesGratisComponent,
        RetailCallStepperComponent,
        ExchangeComponent,
        ReceiptsComponent,
        AfterPicturesComponent,
        StepCamComponent,
        RetailReceiptComponent,
        WholesaleReceiptComponent,
        ContactComponent,
        RemoveContactComponent,
        SwisherRetailFormatComponent,
        RetailFormatBaseComponent,
        EasRetailFormatComponent,
        WholesalerFormatBaseComponent,
        SwisherWholesalerFormatComponent,
        EasWholesalerFormatComponent,
        EasWholesalerSwisherRepFormatComponent,
        EasWholesalerSwisherRepFormatDraftComponent,
        EasWholesalerFormatDraftComponent,
        SwisherWholesalerFormatDraftComponent,
        WholesalerFormatDraftComponent,
        EasRetailFormatDraftComponent,
        EasRetailFormatSwisherRepDraftComponent,
        EasRetailFormatSwisherRepComponent,
        SwisherRetailFormatDraftComponent,
        RetailFormatDraftComponent,
        SwisherRetailWideTaxFormatComponent,
        SwisherRetailWideTaxFormatDraftComponent,
        SwisherRetailNarrowTaxFormatDraftComponent,
        SwisherRetailNarrowTaxFormatComponent,
        EasRetailWideTaxFormatComponent,
        EasRetailWideTaxFormatDraftComponent,
        EasRetailWideTaxFormatSwisherRepComponent,
        EasRetailWideTaxFormatSwisherRepDraftComponent,
        EasRetailNarrowTaxFormatDraftComponent,
        EasRetailNarrowTaxFormatComponent,
        EasRetailNarrowTaxFormatSwisherRepComponent,
        EasRetailNarrowTaxFormatSwisherRepDraftComponent,
        InDistroComponent,
        TaxCalculatorComponent,
        ClosingNotesComponent,
        RmWholesaleCallComponent,
        RmWholesaleCallStepperComponent,
        RetailCallComponent,
        RmWholesaleCallComponent
    ],
    imports: [
        ReactiveFormsModule,
        CdkStepperModule,
        WebcamModule,
        MatDialogModule,
        MatCarouselModule,
        SharedModule,
        NgxMaskModule.forRoot(),
        FontAwesomeModule,
        MatIconModule,
        MatSliderModule
    ],
    exports: [
        StepCamComponent,
        ContactComponent,
        MatDialogModule,
        RemoveContactComponent,
        InDistroComponent,
        TaxCalculatorComponent,
        ClosingNotesComponent,
        RetailCallComponent,
        RmWholesaleCallComponent
    ],
    entryComponents: [ContactComponent, RemoveContactComponent],
    providers: [
        CallDistributionService,
        WholesalerReceiptService,
        RetailReceiptService,
        { provide: MatDialogRef, useValue: {} }
    ]
})
export class StepperCallModule {}
