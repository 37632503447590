<div #myFilter>
    <div class="d-flex justify-content-between align-items-center">
        <div class="w-100"><app-zrt-tree-view #zrtTree [displayName]="'Geo Area'"></app-zrt-tree-view></div>
    </div>
    <div class="mt-3 mb-3 w-100">
        <angular2-multiselect #chainSelect
            [data]="viewmodel.chains"
            [(ngModel)]="viewmodel.selectedChains"
            [settings]="viewmodel.chainsDropdownSettings"
            (onDeSelectAll)="closedChainSelect()"
            (onClose)="closedChainSelect()"
            (onOpen)="openedChainSelect()">
        </angular2-multiselect>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-store-customerId">Store Name
            </mat-label>
            <input #accountSearchInput id="customer-store-customerId-input" type="text"
                (keyup)="onAddAccountRefiner($event)"
                (blur)="onAddAccountRefiner()"
                [(ngModel)]="viewmodel.storeNameCustomerId" matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-street">Street</mat-label>
            <input #streetInput id="street-input" type="text"
                (keyup)="onAddStreetRefiner($event)"
                (blur)="onAddStreetRefiner()"
                [(ngModel)]="viewmodel.streetInput" matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-city">City</mat-label>
            <input #filterCityInput id="filter-city-input" type="text"
                (keyup)="onAddCityRefiner($event)"
                (blur)="onAddCityRefiner()"
                [(ngModel)]="viewmodel.cityInput"
                matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>State</mat-label>
            <mat-select #stateSelect [(value)]="viewmodel.selectedStates" (openedChange)="openedStateChange()"
                multiple>
                <mat-option *ngFor="let vm of viewmodel.filteredStates" [value]="vm">
                    {{ vm.state.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <angular2-multiselect #countySelect class="fill-width" [data]="viewmodel.filteredCounties" [(ngModel)]="viewmodel.selectedCounties"
            [settings]="viewmodel.dropdownSettings" (onDeSelect)="countyChange()"
            (onSelect)="countyChange()" (onDeSelectAll)="countyChange()"
            (onSelectAll)="countyChange()" (onGroupSelect)="countyChange()" (onGroupDeSelect)="countyChange()">
        </angular2-multiselect>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="mat-input-search w-100" color="accent">
            <mat-label id="zip">Zip</mat-label>
            <input #zipInput id="Zip-input" type="text"
                (keyup)="onAddZipRefiner($event)"
                (blur)="onAddZipRefiner()"
                [(ngModel)]="viewmodel.zipInput"
                mask="999999999" matInput digitOnly />
        </mat-form-field>
    </div>
</div>
