import { GenericLookup } from "../generic";

export enum ContractPaymentMethods {
    NA = 0,
    Cash = 1,
    Gratis = 2,
    MSA = 3
}

export const ContractPaymentMethodLookup: GenericLookup<ContractPaymentMethods>[] = [
    { id: ContractPaymentMethods.NA, name: "NA", description: "Not Applicable" },
    { id: ContractPaymentMethods.Cash, name: "Cash", description: "Cash" },
    { id: ContractPaymentMethods.Gratis, name: "Gratis", description: "Gratis" },
    { id: ContractPaymentMethods.MSA, name: "MSA", description: "MSA" }
  ];
