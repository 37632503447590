import { Provider } from "@angular/core";
import { dataSyncHandlerInterfaceToken } from "./data-sync-handler-interface";
import { CustomerDataSyncHandler } from "./data-sync-handlers/customer-data-sync-handler";
import { ProductDataSyncHandler } from "./data-sync-handlers/product-data-sync-handler";
import { CallDataSyncHandler } from "./data-sync-handlers/call-data-sync-handler";
import { TaxRateDataSyncHandler } from "./data-sync-handlers/tax-rate-data-sync-handler";
import { CountryDataSyncHandler } from "./data-sync-handlers/country-data-sync-handler";
import { CountyDataSyncHandler } from "./data-sync-handlers/county-data-sync-handler";
import { StateSyncDataHandler } from "./data-sync-handlers/state-data-sync-handler";
import { StateCategorySyncDataHandler } from "./data-sync-handlers/state-category-data-sync-handler";
import { ContactDataSyncHandler } from "./data-sync-handlers/contact-data-sync-handler";
import { TimeEntryDataSyncHandler } from "./data-sync-handlers/time-entry-data-sync-handler";
import { RouteDataSyncHandler } from "./data-sync-handlers/route-data-sync-handler";
import { TimeEntryTypeDataSyncHandler } from "./data-sync-handlers/time-entry-type-data-sync-handler";
import { DayTimeEntryDataSyncHandler } from "./data-sync-handlers/day-time-entry-data-sync-handler";
import { EmployeeDataSyncHandler } from "./data-sync-handlers/employee-data-sync-handler";
import { WholesalerGroupMemberDataSyncHandler } from "./data-sync-handlers/wholesaler-group-member-data-sync-handler";
import { WholesalerProductCatalogDataSyncHandler } from "./data-sync-handlers/wholesaler-product-catalog-data-sync-handler";
import { WholesalerGroupProductCatalogDataSyncHandler } from "./data-sync-handlers/wholesaler-group-product-catalog-data-sync-handler";
import { WholesalerGroupDataSyncHandler } from "./data-sync-handlers/wholesaler-group-data-sync-handler";
import { RegisteredUserDataSyncHandler } from "./data-sync-handlers/registered-user-data-sync-handler";
import { AccountOwnershipSyncDataHandler } from "./data-sync-handlers/account-ownership-data-sync-handler";
import { NotificationSyncHandler as NotificationDataSyncHandler } from "./data-sync-handlers/notification-data-sync-handler";
import { SystemInformationSyncDataHandler } from "./data-sync-handlers/system-information-data-sync-handler";
import { ContractTemplateDataSyncHandler } from "./data-sync-handlers/contract-template-data-sync-handler";
import { CustomerContractDataSyncHandler } from "./data-sync-handlers/customer-contract-data-sync-handler";
import { CustomerContractPaymentDataSyncHandler } from "./data-sync-handlers/customer-contract-payment-data-sync-handler";
import { ProjectDataSyncHandler } from "./data-sync-handlers/project-data-sync-handler";
import { StateLicenseDataSyncHandler } from "./data-sync-handlers/state-license-sync-handler";
import { SurveyDataSyncHandler } from "./data-sync-handlers/survey-data-sync-handler";
import { WholesalerDataSyncHandler } from "./data-sync-handlers/wholesaler-data-sync-handler";
import { AreaSyncDataHandler } from "./data-sync-handlers/area-data-sync-handler";
import { AreaToZrtSyncDataHandler } from "./data-sync-handlers/area-to-zrt-data-sync-handler";
import { ZrtAssignmentDataSyncHandler } from "./data-sync-handlers/zrt-assignment-data-sync-handler";
import { CustomerDataByProjectSyncHandler } from "./data-sync-handlers/customer-data-by-project-sync-handler";
import { CustomerDataBySpecialSyncHandler } from "./data-sync-handlers/customer-data-by-special-sync-handler";
import { CallPictureDataSyncHandler } from "./data-sync-handlers/call-picture-data-sync-handler";
import { CallReceiptDataSyncHandler } from "./data-sync-handlers/call-receipt-data-sync-handler";
import { Px3RankSyncHandler } from "./data-sync-handlers/px3-rank-sync-handler";
import { Px3IncentivePeriodSyncHandler } from "./data-sync-handlers/px3-incentive-periods.handler";
import { CustomerPx3RankSyncHandler } from "./data-sync-handlers/customer-px3-ranks.handler";

export const dataSyncHandlerProviders = (): Provider[] => {
    // data sync handlers list:
    const handlerClasses = [
        EmployeeDataSyncHandler,
        ZrtAssignmentDataSyncHandler,
        SystemInformationSyncDataHandler,
        ProductDataSyncHandler,
        CountryDataSyncHandler,
        CountyDataSyncHandler,
        StateSyncDataHandler,
        StateCategorySyncDataHandler,
        StateLicenseDataSyncHandler,
        AreaSyncDataHandler,
        AreaToZrtSyncDataHandler,
        DayTimeEntryDataSyncHandler,
        CustomerDataSyncHandler,
        CustomerDataByProjectSyncHandler,
        CustomerDataBySpecialSyncHandler,
        CustomerPx3RankSyncHandler,
        AccountOwnershipSyncDataHandler,
        CallDataSyncHandler,
        TaxRateDataSyncHandler,
        ContactDataSyncHandler,
        TimeEntryDataSyncHandler,
        RouteDataSyncHandler,
        TimeEntryTypeDataSyncHandler,
        WholesalerGroupDataSyncHandler,
        WholesalerGroupProductCatalogDataSyncHandler,
        WholesalerGroupMemberDataSyncHandler,
        WholesalerProductCatalogDataSyncHandler,
        RegisteredUserDataSyncHandler,
        NotificationDataSyncHandler,
        ContractTemplateDataSyncHandler,
        CustomerContractDataSyncHandler,
        CustomerContractPaymentDataSyncHandler,
        ProjectDataSyncHandler,
        Px3IncentivePeriodSyncHandler,
        Px3RankSyncHandler,
        SurveyDataSyncHandler,
        WholesalerDataSyncHandler,
        CallPictureDataSyncHandler,
        CallReceiptDataSyncHandler
    ];

    // transform them into provider entries to be registered in main app module...
    return handlerClasses.map((handler) => ({
        provide: dataSyncHandlerInterfaceToken,
        useClass: handler,
        multi: true
    }));
};
