import { OrderLineItemStatuses, OrderListFilterDto, OrderStatuses, ProjectListFilterDto, ProjectStatuses, ProjectTypes, RefinerLocation, valueSeparator } from "shield.shared";
import { Refiner } from "../../entity-models/refiner.entity";
import { AddressFilterMapService } from "./address-filter-map.service";
import { BaseFilterMapService } from "./base-filter-map.service";
import { ZrtFilterMapService } from "./zrt-filter-map.service";

export class ProjectListFilterMapService extends BaseFilterMapService {


    static mapFilterData(refiners: Refiner[]): ProjectListFilterDto {

        if (!refiners) return;
        const filterDto = new ProjectListFilterDto();
        const addressFilterDto = AddressFilterMapService.mapFilterData(refiners);

        if (Object.keys(addressFilterDto).length) {
            filterDto.addressFilterDto = addressFilterDto;
        }

        for (const refiner of refiners) {
            if (!refiner.dataValue) {
                refiner.dataValue = refiner.value;
            }

            switch (refiner.location) {
                case RefinerLocation.myList:
                    filterDto.ids = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.name:
                    filterDto.name = refiner.dataValue.split(valueSeparator);
                    break;

                case RefinerLocation.manager:
                    filterDto.createdBy = refiner.dataValue.split(valueSeparator);
                    break;

                case RefinerLocation.assigned:
                    filterDto.employeeIds = refiner.dataValue.split(valueSeparator);
                    break;

                case RefinerLocation.startDate:
                    const startOnOrAfterDate = new Date(refiner.dataValue)
                    startOnOrAfterDate.setHours(0,0,0,0);
                    filterDto.startOnOrAfterDate = startOnOrAfterDate.toISOString();
                    break;

                case RefinerLocation.endOnOrBeforeDate:
                    const endOnOrBeforeDate = new Date(refiner.dataValue)
                    endOnOrBeforeDate.setHours(0,0,0,0);
                    filterDto.endOnOrBeforeDate = endOnOrBeforeDate.toISOString();
                    break;

                case RefinerLocation.wholesalers:
                    filterDto.wholesalerIds = refiner.dataValue.split(valueSeparator);
                    break;

                case RefinerLocation.chains:
                    filterDto.accountOwnershipIds = refiner.dataValue.split(valueSeparator);
                    break;

                case RefinerLocation.projectType: {
                    const types = new Array<ProjectTypes>();
                    refiner.dataValue.split(valueSeparator).forEach((typeId) => types.push(Number(typeId) as ProjectTypes));
                    filterDto.projectTypes = types;
                    break;
                }

                case RefinerLocation.projectStatus: {
                    const statuses = new Array<ProjectStatuses>();
                    refiner.dataValue.split(valueSeparator).forEach((statusId) => statuses.push(Number(statusId) as ProjectStatuses));
                    filterDto.projectStatuses = statuses;
                    break;
                }

                default:
                    break;
            }
        }
        return filterDto;
    }
}
