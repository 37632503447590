import { CustomerTypeEnum } from "../lookup";

export class ProjectBasicCustomerDto {
    customerId: string;
    name: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    county: string | null;
    state: string | null;
    zip: string | null;
    customerType: CustomerTypeEnum;
    industryVolume: number | null;
    swisherVolume: number | null;
    swisherPercentage: number | null;
    lastCall: string | null;
    zrt: string | null;
    phone: string | null;
    chain: string | null;
    isMsa: boolean | null;
    isAssigned: boolean;
    isSelected: boolean;
    callsMade?: number|null;
    px3RankId?: string|null;
}
