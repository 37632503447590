import { ZrtFilterDto, RefinerLocation, valueSeparator } from "shield.shared";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { BaseFilterMapService } from "./base-filter-map.service";

export class ZrtFilterMapService extends BaseFilterMapService {
    static mapFilterData(refiners: Refiner[]): ZrtFilterDto {
        if (!refiners) return;
        const filterDto = new ZrtFilterDto();

        for (const refiner of refiners) {
            if (!refiner.dataValue) {
                refiner.dataValue = refiner.value;
            }
            switch (refiner.location) {
                case RefinerLocation.zrt:
                    filterDto.zrts = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.zrtByArea:
                    const areas = new Array<string>();
                    const zrts = new Array<string>();
                    refiner.dataValue.split(valueSeparator)?.forEach((v) => {
                        if (v.length <= 4) { // dataValue is a ZRT
                            zrts.push(v);
                        } else { // dataValue is an Area Guid
                            areas.push(v);
                        }
                    });
                    filterDto.areas = areas.length ? areas : undefined;
                    filterDto.zrts = zrts.length ? zrts : undefined;
                    break;
                case RefinerLocation.zrtByEmployee:
                    filterDto.employeeIds = refiner.dataValue.split(valueSeparator);
                    break;
                default:
                    break;
            }
        }

        return filterDto;
    }
}
