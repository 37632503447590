export class WholesalerCustomer {
    customerId: string;
    customerCode: string;
    createdUtcDateTime: Date;
    createdUserId: string;
    createdUserZrt: string;
    createdUserFullName: string;
    modifiedUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
}
