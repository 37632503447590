import { ProductUpcDto } from "./product-upc.dto";

export class ProductDto {
    id: string;
    itemNumber: string;
    description: string;
    longDescription: string;
    subsidiary: string;
    brand: string;
    division: string;
    shapeCut: string;
    blend: string;
    sellable: boolean;
    returnable: boolean;
    webOrderable: boolean;
    comboProduct: boolean;
    offerSupport: boolean;
    productCatalog: boolean;
    shield: boolean;
    isDeactivated: boolean;
    isDeleted: boolean;
    rowversion: string;
    upcs: ProductUpcDto[];
}
