import { CreateModifyBaseDto } from "./create-modify-base.dto";
import { CreateModifyBaseEntityDto } from "./create-modify-base-entity.dto";
import { GenericResponseDto } from "./generic";

export class SharedHelper {
    static groupBy<T>(
        array: T[],
        keyGetter: (a: T) => string
    ): Map<string, T[]> {
        const map: Map<string, T[]> = new Map<string, T[]>();
        array.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }


    static compareMaps<K, V>(a: Map<K, V>, b: Map<K, V>): boolean {
        if ((a && !b) || (!a && b)) {
            return false;
        }
        if (a && b) {
            if (a.size !== b.size) {
                return false;
            }
            for (var [key, val] of a) {
                const testVal = b.get(key);
                // in cases of an undefined value, make sure the key
                // actually exists on the object so there are no false positives
                if (testVal !== val || (testVal === undefined && !b.has(key))) {
                    return false;
                }
            }
        }
        return true;
    }

    static formatPhoneNumber(phoneNumberString: string): string {
        const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        const match = /^(\d{3})(\d{3})(\d{4})$/.exec(cleaned);
        if (match) {
            return "(" + match[1] + ") " + match[2] + "-" + match[3];
        }
        return phoneNumberString;
    }

    static unFormatPhoneNumber(formattedNumber: string): string {
        const rtn: string = ("" + formattedNumber).replace(/\D/g, "");
        return rtn;
    }

    static preparePhoneNumberForSms(phoneNumber: string): string {
        const cleaned = ("" + phoneNumber).replace(/\D/g, "");
        const match = /^(\d{3})(\d{3})(\d{4})$/.exec(cleaned);
        if (match) {
            return match[1] + "-" + match[2] + "-" + match[3];
        }
        return phoneNumber;
    }

    static JSONTryParse<T>(input: string): boolean | GenericResponseDto<T> {
        try {
            //check if the string exists
            if (input) {
                var o = JSON.parse(input);

                //validate the result too
                if (o && o.constructor === Object) {
                    return o;
                }
            }
        }
        catch (e) {
            // One of the very few times we should ever eat an error
        }

        return false;
    };

    static addDays(date: Date, days: number): Date {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }

    static roundToTwo(num: number): number {
        return num ? Math.round((num) * 100) / 100 : 0;
    }

    static parseInt(value: string) : number | undefined {
        const rtn = +value;
        if (value && !isNaN(rtn) && rtn !== Infinity) {
            return rtn;
        }
    }

    static pad(myNumber: number, length: number): string {
        let str = "" + myNumber.toString();
        while (str.length < length) {
            str = "0" + str;
        }

        return str;
    }

    static buildGoogleMapsRouteUrl(stops: string[]): string {
        // former mapping method
        // const stopToParam = (stop: RouteStop) =>
        //     `${stop.address.latitude},${stop.address.longitude}`;

        const origin = stops[0];
        const destination = stops[stops.length - 1];
        const waypoints = stops.slice(1, stops.length - 1);

        let params = "origin=" + encodeURIComponent(origin);

        if (waypoints.length > 0) {
            const waypointsParam = waypoints
                .join("|");
            params += "&waypoints=" + encodeURIComponent(waypointsParam);
        }

        params +=
            "&destination=" + encodeURIComponent(destination);

        return "https://www.google.com/maps/dir/?api=1&" + params;
    }

    static searchString(baseString: string, searchValue: string): boolean {
        if (!baseString) return false;
        return baseString.trim().toLocaleLowerCase().includes(searchValue?.trim()?.toLocaleLowerCase());
    }

    static searchStringArray(baseString: string, searchValues: string[]): boolean {
        if (!baseString?.length) return false;

        let rtn = false;
        for(const searchValue of searchValues) {
            if(baseString.trim().toLocaleLowerCase().includes(searchValue?.trim()?.toLocaleLowerCase())) {
                return true;
            }
        }
        return false;
    }

    static getLastMonthsDate(): Date {
        const d = new Date();
        const m = d.getMonth();
        d.setMonth(d.getMonth() - 1);

        // If still in same month, set date to last day of
        // previous month
        if (d.getMonth() == m) d.setDate(0);
        d.setHours(0, 0, 0, 0);

        return d;
    }

    static isDate(value: string): boolean {
        const date: any = new Date(value);
        return (date !== "Invalid Date") && !isNaN(date);
    }

    static areDatesEqual(d1: Date, d2: Date): Boolean {
        if (d1 > d2 || d1 < d2) {
            return false;
        }
        return true;
    }

    static createdModifiedEntityToDto<T extends CreateModifyBaseEntityDto, R extends CreateModifyBaseDto>(entity: T, dto: R): R {
        dto.createdUserId = entity.createdUserId;
        dto.createdUserZrt = entity.createdUserZrt;
        dto.createdUserFullName = entity.createdUserFullName;
        dto.createdUtcDateTime = entity.createdUtcDateTime ? entity.createdUtcDateTime.toISOString() : null;
        dto.modifiedUserId = entity.modifiedUserId;
        dto.modifiedUserZrt = entity.modifiedUserZrt;
        dto.modifiedUserFullName = entity.modifiedUserFullName;
        dto.modifiedUtcDateTime = entity.modifiedUtcDateTime ? entity.modifiedUtcDateTime.toISOString() : null;

        return dto;
    }

    static createdModifiedDtoToEntity<T extends CreateModifyBaseDto, R extends CreateModifyBaseEntityDto>(dto: T, entity: R): R {
        entity.createdUserId = dto.createdUserId;
        entity.createdUserZrt = dto.createdUserZrt;
        entity.createdUserFullName = dto.createdUserFullName;
        entity.createdUtcDateTime = dto.createdUtcDateTime ? new Date(entity.createdUtcDateTime) : null;
        entity.modifiedUserId = dto.modifiedUserId;
        entity.modifiedUserZrt = dto.modifiedUserZrt;
        entity.modifiedUserFullName = dto.modifiedUserFullName;
        entity.modifiedUtcDateTime = dto.modifiedUtcDateTime ? new Date(entity.modifiedUtcDateTime) : null;

        return entity;
    }

    //Converts an array to a dictionary object
    static toDictionary<K extends string | number | symbol, V, I>(
        input: I[],
        keySelector: (i: I) => K,
        valueSelector: (i: I) => V
    ): {[key in K]: V} {
        return input.reduce(
            (map, i) => {
                map[keySelector(i)] = valueSelector(i);
                return map;
            },
            {} as { [key in K]: V }
        );
    }

    /**
     * Returns an array with values that are unique (i.e. there are no duplicates). Can also return
     * objects that have a unique value for a given key.
     * @param arr The array to filter for unique values
     * @param uniqueBy If `arr` is an object, the key to use to filter unique values on.
     * @returns The array filtered for unique values.
     */
    static unique<T>(arr: T[], uniqueBy?: string & keyof T): T[] {
        if (!uniqueBy) {
            return arr.filter((v, i, a) => a.indexOf(v) === i);
        } else {
            return [...SharedHelper.groupBy(arr, _ => uniqueBy).values()].map(grp => grp[0]);
        }
    }
}
