import { ResponseCountKey } from "./enum";

export class ResponseCount {
    key: ResponseCountKey;
    count: number | undefined;

    constructor() {
        this.key = ResponseCountKey.default;
        this.count = 0;
    }
}
