import { Injectable } from "@angular/core";
import { GenericResponseDto, newSequentialId } from "shield.shared";
import { CustomerStateService } from "src/app/accounts/account-services/customer-state.service";
import { CallPicture } from "src/app/entity-models/call-picture.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { Survey } from "src/app/entity-models/survey.entity";
import { WholesaleCall } from "src/app/entity-models/wholesale-call.entity";
import { PictureDelineationService } from "src/app/services/delineation-services/picture-delineation.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { SurveyDelineationService } from "src/app/services/delineation-services/survey-delineation.service";
import { CallService } from "../../call-services/call.service";

@Injectable()
export class WholesaleCallApplicationService {
    constructor(
        private callService: CallService,
        private customerStateService: CustomerStateService,
        private pictureDelineationService: PictureDelineationService,
        private projectDelineationService: ProjectDelineationService,
        private surveryDelineationService: SurveyDelineationService
    ) {}

    async addPicture(picture: CallPicture, image: string): Promise<void> {
        picture.id = newSequentialId();

        const beforePic: CallPicture = (this.callService.call as WholesaleCall)?.callPictures?.find(
            (pic) => pic.type === "Before"
        );
        const afterPic: CallPicture = (this.callService.call  as WholesaleCall)?.callPictures?.find(
            (pic) => pic.type === "After"
        );

        if (picture) {
            if (picture.type === "Before" && beforePic) {
                this.callService.replacePicture(beforePic, picture, image);
            } else if (picture.type === "After" && afterPic) {
                this.callService.replacePicture(afterPic, picture, image);
            } else {
                this.callService.pictures.push({
                    id: picture.id,
                    image
                });
                (this.callService.call as WholesaleCall).callPictures.push(picture);
            }

            // notify subscribers
            await this.callService.savePicturesAndNotify();
        }
    }

    resetCall(): void {
        this.callService.call = null;
        this.callService.pictures = [];
    }

    async startCall(
        employee: Employee
    ): Promise<void> {

        const startedProjectsResponse = await this.projectDelineationService.getStartedProjectsByCustomerAndEmployeeIds(this.customerStateService.customer.id, employee.id);
        if (!startedProjectsResponse) { return; }

        let surveyIds = new Array<string>();

        for (const project of startedProjectsResponse.values) {
            surveyIds = surveyIds.concat(project.projectActivitySurveys.map((as) => as.activitySurveyId));
        }

        const surveyResponse = surveyIds?.length ? await this.surveryDelineationService.getByIds(surveyIds) : new GenericResponseDto<Survey[]>();

        if (!surveyResponse) { return; }

        let call: WholesaleCall;
        let callsResponse = await this.callService.getLocalCallsByCustomerId(this.customerStateService.customer.id);

        if (!callsResponse) {return; }

        if (callsResponse.values) {
            call = callsResponse.values.find(
                (myCall) =>
                    myCall.stopTime == null && myCall.createdUserId === employee.id
            ) as WholesaleCall;
        }


        if (callsResponse.values) {
            call = callsResponse.values.find(
                (myCall) =>
                    myCall.stopTime == null && myCall.createdUserId === employee.id
            ) as WholesaleCall;
        }

        if (call) {
            if (call.callPictures?.length > 0) {
                const picsResponse = await this.pictureDelineationService.getLocalPicturesByIds(
                    call.callPictures.map((p) => p.id)
                );

                if (!picsResponse) { return; }

                if (picsResponse.values) {
                    this.callService.pictures = picsResponse.values;
                }
            }
        } else {
            const now: Date = new Date();
            call = new WholesaleCall();
            call.createdUserFullName = employee.fullName;
            call.createdUtcDateTime = now;
            call.createdUserId = employee.id;
            call.createdUserZrt = employee.zrt;
            call.id = newSequentialId();
            call.customerId = this.customerStateService.customer.id;
            call.startTime = now;
            this.callService.pictures = [];
        }

        this.callService.call = call;

        if (
            this.callService.call &&
            (!this.callService.call.surveys ||
                this.callService.call.surveys.length === 0)
        ) {
            this.callService.call.surveys = surveyResponse.values?.map((s) =>
                this.callService.buildCallActivitySurveyViewModelFromSurvey(s, this.callService.call.surveyAnswers))
                    ?? [];
        }

        await this.callService.saveCallAndNotify();
    }
}
