import { Injectable } from "@angular/core";
import { GenericResponseDto } from "shield.shared";
import { Employee } from "src/app/entity-models/employee.entity";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { DatabaseService } from "../database.service";
import { EmployeeOfflineService } from "../offline-services/employee-offline.service";
import { EmployeeOnlineService } from "../online-services/employee-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class EmployeeDelineationService extends DelineationContext<Employee, string> {

    constructor(private employeeOfflineService: EmployeeOfflineService
        , private employeeOnlineService: EmployeeOnlineService
        , snackbarService: SnackbarService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService){
            super(dbService, datasourceDelineationService, snackbarService);
        }

        async getAll(): Promise<GenericResponseDto<Employee[]>> {
            const offline = (key: undefined) => {
                return this.employeeOfflineService.getAll(key);
            }
            const online = (key: undefined) => {
                return this.employeeOnlineService.getAll(key);
            }
            const response = await this.datasourceDelineationService.makeCall<null, Employee[]>(null, offline, online);

            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }

        async getById(id: string): Promise<GenericResponseDto<Employee>> {

            const offline = (key: string) => {
                return this.employeeOfflineService.getById(key);
            }
            const online = (key: string) => {
                return this.employeeOnlineService.getById(key);
            }
            const response = await this.datasourceDelineationService.makeCall<string, Employee>(id, offline, online);

            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }

        async getByIds(ids: string[]): Promise<GenericResponseDto<Employee[]>> {

            if (ids?.length) {

                const offline = (key: string[]) => {
                    return this.employeeOfflineService.getByIds(key);
                }
                const online = (key: string[]) => {
                    return this.employeeOnlineService.getByIds(key);
                }
                const response = await this.datasourceDelineationService.makeCall<string[], Employee[]>(ids, offline, online);

                if (response.isError) {
                    this.snackbarService.showError(response.message);
                    return;
                }

                return response;
            } else {
                return new GenericResponseDto<Employee[]>();
            }
        }

        async getByZrt(id: string): Promise<GenericResponseDto<Employee[]>> {

            const offline = (key: string) => {
                return this.employeeOfflineService.getByZrt(key);
            }
            const online = (key: string) => {
                return this.employeeOnlineService.getByZrt(key);
            }
            const response = await this.datasourceDelineationService.makeCall<string, Employee[]>(id, offline, online);

            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }

        async getEmployeeZrts(searchableZrt?: string): Promise<GenericResponseDto<SearchZrtDropDown[]>> {

            const offline = (key: undefined | string) => {
                return this.employeeOfflineService.getEmployeeZrts(key);
            }
            const online = (key: undefined | string) => {
                return this.employeeOfflineService.getEmployeeZrts(key);
            }
            const response = await this.datasourceDelineationService.makeCall<undefined | string, SearchZrtDropDown[]>(searchableZrt, offline, online);

            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }

        async getEmployeeZrtsByIds(ids: string[]): Promise<GenericResponseDto<SearchZrtDropDown[]>> {

            const offline = (key: string[]) => {
                return this.employeeOfflineService.getEmployeeZrtsByIds(key);
            }
            const online = (key: string[]) => {
                return this.employeeOfflineService.getEmployeeZrtsByIds(key);
            }
            const response = await this.datasourceDelineationService.makeCall<string[], SearchZrtDropDown[]>(ids, offline, online);

            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }
}
