import { Component, OnInit } from '@angular/core';
import { NewProjectViewmodel } from './new-project.viewmodel';

@Component({
    selector: 'app-new-project.modal',
    templateUrl: './new-project-modal.component.html',
    styleUrls: ['./new-project-modal.component.scss']
})
export class NewProjectModalComponent implements OnInit {

    viewmodel = new NewProjectViewmodel();

    constructor() { }

    ngOnInit(): void {
    }

}
