import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DailySummaryBatchParamsDto, DailySummaryColumns, DailySummaryEntryDto, DailySummaryFilterDto, FilterRequestV2Dto, GenericRequestDto, GenericResponseDto, newSequentialId } from "shield.shared";
import { DailySummaryEntry } from "src/app/entity-models/daily-summary-entry.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { DailySummaryEntryConverterService } from "../converter-services/daily-summary-entry-converter.service";

@Injectable()
export class DailySummaryEntryOnlineService {

    constructor(private http: HttpClient){}

    async getDailySummaryBatch(
        key: DailySummaryBatchParamsDto
    ): Promise<GenericResponseDto<DailySummaryEntry[]>> {
        const request = new GenericRequestDto<
            FilterRequestV2Dto<DailySummaryFilterDto, DailySummaryColumns>
        >();
        request.id = newSequentialId();
        request.values = key.filterRequestDto;

        const result = await this.http
            .post<GenericResponseDto<DailySummaryEntryDto[]>>(
                "/api/day-time-entries/daily-summary",
                request
            )
            .toPromise();

        return ConverterHelper.dtoToEntityArray<
            DailySummaryEntryDto,
            DailySummaryEntry
        >(result, (r) =>
            DailySummaryEntryConverterService.dailySummaryEntryDtoToDailySummaryEntry(r)
        );
    }
}
