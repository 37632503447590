import { AfterContentChecked, Component, ElementRef, Input, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterService } from "src/app/services/filter.service";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { GenericLookup, GratisStatuses, RefinerLocation, valueSeparator } from "shield.shared";
import { RequestInformationViewmodel } from "./request-information.viewmodel";
import { MatSelect } from "@angular/material/select";
import * as moment from "moment";
import { MY_DATE_FORMATS } from "../../constants/date-formats";
import { ZrtTreeViewComponent } from "../../zrt-tree-view/zrt-tree-view.component";

@Component({
    selector: "app-request-information-filter",
    templateUrl: "./request-information-filter.component.html",
    styleUrls: ["./request-information-filter.component.scss"]
})
export class RequestInformationFilterComponent extends FilterBaseComponent implements AfterContentChecked, OnDestroy {
    //viewchild
    @ViewChild("statusSelect") statusSelect: MatSelect;
    @ViewChild("zrtTree") zrtTree: ZrtTreeViewComponent;

    //public vars
    contentComponent: Type<RequestInformationFilterComponent>;
    icon = "payments";
    name = "Request Information";
    viewmodel = new RequestInformationViewmodel(this.filterService);

    constructor(
        private filterService: FilterService,
    ) {
        super();
    }

    ngAfterContentChecked(): void {
        if (!this.isInitialized && this.zrtTree && this.zrtFilterService) {
            this.zrtTree.zrtFilterService = this.zrtFilterService;
            this.setInitialized();
            if (!this.viewmodel.areaSubscription || this.viewmodel.areaSubscription.closed) {
                this.viewmodel.areaSubscription = this.zrtFilterService.observableByArea.subscribe((value) => {
                    this.viewmodel.zrtByArea = value;
                });
            }
        }
    }

    ngOnDestroy(): void {
        if (this.viewmodel.areaSubscription && !this.viewmodel.areaSubscription.closed) {
            this.viewmodel.areaSubscription.unsubscribe();
        }
    }

    //events
    onAddDaysWaitingRefiner(event?: KeyboardEvent) {
        this.viewmodel.daysWaitingDataInput = this.viewmodel.daysWaitingInput
            ? moment(
                  new Date().setHours(0, 0, 0, 0) -
                      parseInt(this.viewmodel.daysWaitingInput) *
                          1000 *
                          60 *
                          60 *
                          24
              ).format(MY_DATE_FORMATS.display.dateInput)
            : null;
        this._refinerService.onInputChange(
            RefinerLocation.daysWaiting,
            this.viewmodel.daysWaitingInput,
            this.viewmodel.daysWaitingDataInput
        );
        if (
            this.viewmodel.daysWaitingInput &&
            (!event || this.inputSubmitKeys.includes(event.key))
        ) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.daysWaiting;
            refiner.value = this.viewmodel.daysWaitingInput;
            refiner.dataPropertyName = "daysWaiting";
            refiner.dataValue = this.viewmodel.daysWaitingDataInput;

            this._refinerService.checkAndUpdateRefiner(refiner, true);
        }
    }

    onAddPurposeRefiner(event?: KeyboardEvent) {
        if (this.viewmodel.purposeInput) {
            this._refinerService.onInputChange(
                RefinerLocation.purpose,
                this.viewmodel.purposeInput
            );
            if (!event || this.inputSubmitKeys.includes(event.key)) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.purpose;
                refiner.value = this.viewmodel.purposeInput;
                refiner.dataPropertyName = "purpose";

                this._refinerService.checkAndUpdateRefiner(refiner);
            }
        }
        else {
            this._refinerService.removeRefinerByLocation(
                RefinerLocation.purpose,
                true,
                false
            )
        }
    }

    onAreaChange(value: boolean): void {
        this.zrtFilterService.byArea = this.viewmodel.zrtByArea = value;
    }

    onOpenedStatusChange(): void {
        if (this.viewmodel.selectedStatuses.includes(this.viewmodel.allStatus)) {
            this._refinerService.removeRefinerByLocation(RefinerLocation.status, true, false);
        }
        else {
            if (!this.statusSelect.panelOpen) {
                const statusValues = new Array<string>();
                const statusDataValues = new Array<string>();
                for(const status of this.viewmodel.selectedStatuses) {
                    statusValues.push(status.description);
                    statusDataValues.push(status.id?.toString());
                }

                const refiner = new Refiner();
                refiner.location = RefinerLocation.status;
                refiner.value = statusValues.join(", ");
                refiner.dataPropertyName = "id";
                refiner.dataValue = statusDataValues.join(valueSeparator);

                this._refinerService.checkAndUpdateRefiner(refiner, true);
            }
        }
    }

    onSelectionStatusChange(): void {
        if (
            this.viewmodel.isAllStatusPresent &&
            this.viewmodel.selectedStatuses.length > 1
        ) {
            const index = this.viewmodel.selectedStatuses.findIndex(
                (s) => s === this.viewmodel.allStatus
            );
            if (index !== -1) {
                this.viewmodel.selectedStatuses.splice(index, 1);
                this.viewmodel.isAllStatusPresent = false;
            }
        } else if (
            !this.viewmodel.isAllStatusPresent &&
            this.viewmodel.selectedStatuses.includes(
                this.viewmodel.allStatus
            )
        ) {
            this.viewmodel.selectedStatuses = [
                this.viewmodel.allStatus
            ];
            this.viewmodel.isAllStatusPresent = true;
        }
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.daysWaiting:
                    if (refiner.value !== this.viewmodel.daysWaitingInput) {
                        this.viewmodel.daysWaitingInput = refiner.value;
                        this.viewmodel.daysWaitingDataInput = refiner.dataValue;
                    }
                    break;
                case RefinerLocation.purpose:
                    if (refiner.value !== this.viewmodel.purposeInput) {
                        this.viewmodel.purposeInput = refiner.value;
                    }
                    break;
                case RefinerLocation.status:
                    const selectedStatuses = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedStatuses = new Array<GenericLookup<GratisStatuses>>();

                    if (selectedStatuses) {
                        for (const status of selectedStatuses) {
                            const found = this.viewmodel.statuses.find(
                                (ss) => ss.id?.toString() === status
                            );
                            if (found) {
                                rtnSelectedStatuses.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedStatuses = rtnSelectedStatuses.length > 0
                        ? rtnSelectedStatuses
                        : [this.viewmodel.allStatus];
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();

        tempRefiner.location = RefinerLocation.daysWaiting;
        const daysWaitingRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.daysWaiting
        );
        if (daysWaitingRefiner) {
            this.onInputChange(daysWaitingRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.purpose;
        const purposeRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.purpose
        );
        if (purposeRefiner) {
            this.onInputChange(purposeRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.status;
        const statusRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.status
        );
        if (statusRefiner) {
            this.onInputChange(statusRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }
}
