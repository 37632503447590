
<div class="row">
    <div class="col-12 text-left" *ngIf="viewmodel.wholesalerToEdit">
        <h3>
            {{ viewmodel.wholesalerToEdit.name }}: {{ viewmodel.wholesalerToEdit.businessAddress.city }}, {{ viewmodel.wholesalerToEdit.businessAddress.state }}
        </h3>
    </div>
    <div class="col-12 h-center" *ngIf="!viewmodel.wholesalerToEdit">
        <angular2-multiselect class="flex-grow-1 mb-3"
            [data]="viewmodel.wholesalers"
            [(ngModel)]="viewmodel.wholesaler"
            [settings]="viewmodel.wholeSalerDropdownSettings"
            (onSelect)="viewmodel.onSelect($event)"
            (onDeSelectAll)="viewmodel.wholesaler = []">
        </angular2-multiselect>
    </div>
</div>
<form [formGroup]="viewmodel?.fg">
    <div class="row">
        <div class="flex-grow-1 ml-3 mr-3">
            <mat-form-field appearance="outline" color="accent" class="w-100">
                <mat-label id="code">Customer Code</mat-label>
                <input
                    type="text"
                    formControlName="codeControl"
                    [value]="viewmodel.customerCode"
                    maxLength="25"
                    matInput
                    required
                />
                <mat-hint align="end"
                    >{{
                        viewmodel.customerCode?.length ||
                            0
                    }}/25</mat-hint
                >
            </mat-form-field>
        </div>
    </div>
</form>
