import { GenericLookup } from "../generic";
import { TransactionLineItemType } from "../lookup";

export class TransactionLineItemDto {
    id: string;
    type: GenericLookup<TransactionLineItemType>;
    wholesalerId: string | null;
    wholesalerName: string | null;
    productId: string;
    productDescription: string;
    uin: string | null;
    upc: string | null;
    quantity: number;
    units: number;
    price: number | null;
    wholesalePrice: number | null;
    salesTotal: number | null;
    discount: number | null;
    netTotal: number | null;
    wholesaleTotal: number | null;
    gratisTotal: number | null;
}
