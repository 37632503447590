import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'icon-marker',
    templateUrl: './icon-marker.component.html',
    styleUrls: ['./icon-marker.component.scss']
})
export class IconMarkerComponent implements OnInit {
    @Input() iconName: string = 'edit';

    constructor() { }

    ngOnInit(): void { }
}
