import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";
import { Survey } from "src/app/entity-models/survey.entity";
import { ProjectActivityGroupViewModel } from "../project-activity-group.viewmodel";

export class AddProjectActivityDialogViewModel extends BasicDialogViewModel {
    activityVms: ProjectActivityGroupViewModel[];
    filteredActivities: ProjectActivityGroupViewModel[];

    searchText = "";

    constructor(activities: Survey[]) {
        super();
        this.activityVms = activities.map((as) => {
            const vm = new ProjectActivityGroupViewModel();
            vm.isSelected = false;
            vm.id = as.id;
            vm.description = as.title;
            vm.count = as.surveyQuestions?.length ?? 0;
            return vm;
        });
        this.filteredActivities = this.activityVms.slice();
        this.headerLeftText = "Add Activity Group";
        this.buttonLeftText = "Cancel";
        this.buttonLeftDisabledFunction = () => false;
        this.buttonRightDisabledFunction = () => { return !this.filteredActivities.find((as) => as.isSelected) };
        this.buttonRightText = "Add";
    }

    filter(): void {
        if (this.searchText) {
            this.filteredActivities = this.activityVms.filter(
                (vm) =>
                    vm.description.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase())
            )
        } else {
            this.filteredActivities = this.activityVms;
        }
    }

    isAllSelected(): boolean {
        return !!(this.filteredActivities.length && this.filteredActivities.filter((as) => as.isSelected).length === this.filteredActivities.length);
    }

    markSelected(event: boolean, activity: ProjectActivityGroupViewModel) {
        activity.isSelected = event;
    }

    markAllSelected(event: boolean): void {
        if (event) {
            this.filteredActivities.forEach((as) => as.isSelected = true)
        } else {
            this.filteredActivities.forEach((as) => as.isSelected = false)
        }
    }
}
