import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ProjectStep } from 'src/app/enums/project-step';
import { ProjectStateService } from 'src/app/services/project-state-service';
import { ProjectApplicationService } from '../../project-services/project-application.service';
import { ProjectDefinitionViewmodel } from './project-definition.viewmodel';

@Component({
    selector: 'app-project-definition',
    templateUrl: './project-definition.component.html',
    styleUrls: ['./project-definition.component.scss']
})
export class ProjectDefinitionComponent implements OnInit, OnDestroy {

    viewmodel = new ProjectDefinitionViewmodel(this.projectStateService, this.formBuilder);
    subscriptionProject: Subscription;
    subscriptionSelectedIndex: Subscription;
    selectedIndex: ProjectStep;

    constructor(public projectStateService: ProjectStateService,
        private projectApplicationService: ProjectApplicationService,
        private formBuilder: FormBuilder) { }

    ngOnInit(): void {

        if (!this.subscriptionProject || this.subscriptionProject.closed) {
            this.subscriptionProject = this.projectStateService.observableProject.subscribe((project) => {
                if (project) {
                    if (this.selectedIndex === ProjectStep.definition) {
                        this.viewmodel.builtViewmodelFromEntity(project);
                    }
                }
            });
        }


        if (!this.subscriptionSelectedIndex || this.subscriptionSelectedIndex.closed) {
            this.subscriptionSelectedIndex = this.projectApplicationService.observableSelectedIndex.subscribe((si) => {

                // leaving view
                if (this.selectedIndex === ProjectStep.definition && si !== ProjectStep.definition) {
                    this.selectedIndex = si;
                    this.viewmodel.buildEntityFromViewmodel();
                } else if (this.selectedIndex !== ProjectStep.definition && si === ProjectStep.definition) {
                    // entering view
                    this.selectedIndex = si;
                    if (this.projectStateService.project) {
                        this.viewmodel.builtViewmodelFromEntity(this.projectStateService.project);
                    }
                }
            });
        }
    }

    ngOnDestroy(): void {

        if (this.subscriptionProject && !this.subscriptionProject.closed) {
            this.subscriptionProject.unsubscribe();
        }

        if (this.subscriptionSelectedIndex && !this.subscriptionSelectedIndex.closed) {
            this.subscriptionSelectedIndex.unsubscribe();
        }

        this.viewmodel.unsubscribe();
    }
}
