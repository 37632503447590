import { Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/entity-models/project.entity';
import { ProjectStep } from 'src/app/enums/project-step';
import { ProjectStateService } from 'src/app/services/project-state-service';
import { ProjectApplicationService } from '../../project-services/project-application.service';
import { ProjectSummaryActivitiesSurveysComponent } from './project-summary-activities-surveys/project-summary-activities-surveys.component';
import { ProjectSummaryAssignmentsComponent } from './project-summary-assignments/project-summary-assignments.component';
import { ProjectSummaryOrderDatesComponent } from './project-summary-order-dates/project-summary-order-dates.component';
import { ProjectSummaryProductsComponent } from './project-summary-products/project-summary-products.component';
import { ProjectSummaryStoresComponent } from './project-summary-stores/project-summary-stores.component';

@Component({
    selector: 'app-project-summary',
    templateUrl: './project-summary.component.html',
    styleUrls: ['./project-summary.component.scss']
})
export class ProjectSummaryComponent implements OnInit {
    @HostBinding("class") class = "d-flex flex-column flex-grow-1";

    @ViewChild("summaryOrderDates") summaryOrderDates: ProjectSummaryOrderDatesComponent;
    @ViewChild("summaryStores") summaryStores: ProjectSummaryStoresComponent;
    @ViewChild("summaryAssignments") summaryAssignments: ProjectSummaryAssignmentsComponent;
    @ViewChild("summaryProducts") summaryProducts: ProjectSummaryProductsComponent;
    @ViewChild("summaryActivitySurveys") summaryActivitySurveys: ProjectSummaryActivitiesSurveysComponent;

    isViewmodelReady = false;
    screenHeight: number;

    selectedIndexSubscription: Subscription;

    shouldCheck = true;
    currentStep = ProjectStep.summary;

    @Input()
    get project(): Project {
        return this.projectStateService.project;
    }
    set project(value: Project) {
        if (value) {
            this.projectStateService.project = value;
        }
    }
    @Input() inStepper = false;

    constructor(public projectApplicationService: ProjectApplicationService
        , private projectStateService: ProjectStateService) { }

    ngOnInit(): void {
        if (!this.selectedIndexSubscription || this.selectedIndexSubscription.closed) {
            this.selectedIndexSubscription = this.projectApplicationService.observableSelectedIndex.subscribe(
                (selectedIndex) => {
                    if (selectedIndex === ProjectStep.summary) {
                        if (this.projectStateService.project) {
                            if (!this.projectApplicationService.areProjectOrderDatesEqaul(
                                    this.projectStateService.project.projectOrderDates,
                                    this.summaryOrderDates.projectOrderDates
                            )) {
                                this.summaryOrderDates.setAllDates(false);
                            }

                            if (!this.projectApplicationService.areProjectCustomersEqual(
                                    this.projectStateService.project.projectCustomers,
                                    this.summaryStores.viewmodel.projectCustomers
                            )) {
                                this.summaryStores.viewmodel.buildProjectCustomers(
                                    this.projectStateService.project.projectCustomers
                                );
                            }

                            if (!this.projectApplicationService.areProjectProductsEqual(
                                this.projectStateService.project.projectProducts,
                                this.summaryProducts.viewmodel.projectProducts
                            )) {
                                this.summaryProducts.viewmodel.buildProjectProducts(
                                    this.projectStateService.project.projectProducts
                                );
                            }

                            if (!this.projectApplicationService.isActivitiesAndSurveysEqual(
                                this.projectStateService.project.projectActivitySurveys,
                                this.summaryActivitySurveys.viewmodel.projectActivitiesSurveys
                            )) {
                                this.summaryActivitySurveys.viewmodel.buildProjectActivitiesSurveys(
                                    this.projectStateService.project.projectActivitySurveys
                                );
                            }
                        }
                    }
                }
            );
        }
    }
}
