import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { CustomerStateService } from "../../account-services/customer-state.service";
//Icons
import {
    faPen,
    faSave,
    faSignOutAlt,
    faReceipt,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { RetailInfoViewmodel } from "./retail-info.viewmodel";
import { FormControl, Validators } from "@angular/forms";
import { OverlayService } from "src/app/services/overlay.service";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { Router } from "@angular/router";
import { Customer } from "src/app/entity-models/customer.entity";
import { map } from "rxjs/operators";
import { FilterService } from "src/app/services/filter.service";
import { AppStateService } from "src/app/services/app-state.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";

@Component({
    selector: "app-retail-info",
    templateUrl: "./retail-info.component.html",
    styleUrls: ["./retail-info.component.css"]
})
export class RetailInfoComponent implements OnInit, OnDestroy {
    customerSubscription: Subscription;
    employeeSubscription: Subscription;
    faPen: IconDefinition = faPen;
    faSave: IconDefinition = faSave;
    faSignOutAlt: IconDefinition = faSignOutAlt;
    faReceipt: IconDefinition = faReceipt;
    viewMode = true;
    viewmodel = new RetailInfoViewmodel(this.filterService, this.px3RankService);
    storeComments = this.viewmodel.storeComments;
    dateFormat = MY_DATE_FORMATS.display.customDateInput;
    dateTimeFormat = MY_DATE_FORMATS.display.customDateTimeInput;
    usPhoneNumberRegEx = new RegExp(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    );

    phoneFormControl = new FormControl(this.viewmodel.phone, {
        validators: [Validators.pattern(this.usPhoneNumberRegEx)],
        updateOn: "blur"
    });

    phoneNumberSubscription: Subscription;
    overlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    constructor(
        private customerStateService: CustomerStateService,
        private customerDelineationService: CustomerDelineationService,
        private overlayService: OverlayService,
        private router: Router,
        private filterService: FilterService,
        private appStateService: AppStateService,
        private px3RankService: Px3DelineationService,
    ) {}

    ngOnInit(): void {
        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.appStateService.currentEmployee.subscribe((employee) => {
                this.viewmodel.employee = employee;
            });
        }
        if (!this.customerSubscription || this.customerSubscription.closed) {
            this.customerSubscription = this.customerStateService.observableCustomer
                .pipe(
                    map(async (customer) => {
                        if (
                            !this.customerStateService
                                .ignoreCustomerSubscription
                        ) {
                            if (customer) {
                                this.viewmodel.buildViewmodelFromCustomer(
                                    customer
                                );
                                this.phoneFormControl.setValue(
                                    this.viewmodel.phone
                                );
                            }
                        }
                    })
                )
                .subscribe();
        }

        if (
            !this.phoneNumberSubscription ||
            this.phoneNumberSubscription.closed
        ) {
            this.phoneNumberSubscription = this.phoneFormControl.valueChanges.subscribe(
                () => {
                    if (this.phoneFormControl.valid) {
                        this.viewmodel.phone = this.phoneFormControl
                            .value as string;
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (this.customerSubscription && !this.customerSubscription.closed) {
            this.customerSubscription.unsubscribe();
        }

        if (
            this.phoneNumberSubscription &&
            !this.phoneNumberSubscription.closed
        ) {
            this.phoneNumberSubscription.unsubscribe();
        }

        if (this.employeeSubscription && !this.employeeSubscription.closed) {
            this.employeeSubscription.unsubscribe();
        }
    }

    async saveCustomer(): Promise<void> {
        const customer = await this.viewmodel.buildDomainModelFromViewmodel();
        customer.lastEditedBy = this.viewmodel.employeeName;
        customer.lastEdited = new Date();
        if (this.customerStateService.customer.isActive !== customer.isActive) {
            customer.isActive
                ? customer.reactivatedDate = new Date()
                : customer.deactivatedDate = new Date();
        }

        //Do not allow Px3 rank to be updated unless the user is a shield admin
        if (this.viewmodel.isAdmin) {
            const selectedPx3Rank = this.viewmodel.selectedPx3Rank.id == '' ? null : this.viewmodel.selectedPx3Rank.id;
            const curPx3Rank = customer.px3RankId;
            if (curPx3Rank || curPx3Rank != selectedPx3Rank) {
                await this.px3RankService.savePx3RankForCustomer(customer.id, selectedPx3Rank);
            }
        }
        this.customerStateService.customer = customer;
        await this.customerStateService.updateCustomerByContact();
        const customerResponse = await this.customerDelineationService.upsertCustomer(
            this.customerStateService.customer
        );
        if (!customerResponse) { return; }

        void this.router.navigate([
            "accounts",
            this.customerStateService.customer.id,
            "profile"
        ]);
    }

    setMode = (): void => {
        this.viewMode = !this.viewMode;
    };

    openDialog = (): void => {
        if (this.isDirty(this.viewmodel.buildDomainModelFromViewmodel())) {
            if (this.overlayRef) {
                this.overlayRef.close();
            }

            const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
            data.header = "Confirmation";
            data.message =
                "Your changes have not been saved. Are you sure you want to cancel?";
            data.buttonLeftText = "No";
            data.buttonLeftFunction = () => {
                this.overlayRef.close();
            };
            data.buttonRightText = "Yes";
            data.buttonRightFunction = () => {
                void this.router.navigate([
                    "accounts",
                    this.customerStateService.customer.id,
                    "profile"
                ]);

                this.overlayRef.close();
            };
            this.overlayRef = this.overlayService.open(
                ConfirmationDialogComponent,
                data
            );

            this.overlayRef.afterClosed$.subscribe(() => {
                this.overlayRef = undefined;
            });
        } else {
            this.setMode();
            void this.router.navigate([
                "accounts",
                this.customerStateService.customer.id,
                "profile"
            ]);
        }
    };

    isDirty(customer: Customer): boolean {
        const customer1 = Object.assign({}, customer);
        const customer2 = Object.assign({}, this.customerStateService.customer);
        delete (customer1 as any).msaStatus;
        customer1.rowversion = null;
        customer2.rowversion = null;
        return (
            JSON.stringify(customer1) !==
            JSON.stringify(customer2)
        );
    }
}
