import { Injectable } from "@angular/core";
import { CallBatchParamsDto, CallHistoryColumns, CallHistoryParamsDto, FilterRequestDto, GenericResponseDto, GenericRequestDto, FilterRequestV2Dto, FilterSortDto, newSequentialId, CallCounts, CallTypes } from "shield.shared";
import { Call } from "src/app/accounts/call-master/call-services/call.service";
import { CallHistoryEntry } from "src/app/entity-models/call-history-entry.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { DexieTableNames } from "src/app/enums/dexie-table-names";
import { DatabaseService } from "../database.service";
import { CallOfflineService } from "../offline-services/call-offline.service";
import { CallOnlineService } from "../online-services/call-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";
import { CallHistoryFilterMapService } from "../filter-map-services/call-history-filter-map.service";
import { CustomerStateService } from "src/app/accounts/account-services/customer-state.service";

@Injectable()
export class CallDelineationService extends DelineationContext<Call, string> {

    constructor(private callOfflineService: CallOfflineService
        , private callOnlineService: CallOnlineService
        , snackbarService: SnackbarService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService
        , private customerStateService: CustomerStateService){
            super(dbService, datasourceDelineationService, snackbarService);
        }

    async getCallById(id: string): Promise<GenericResponseDto<Call>> {

        const offline = (key: string) => {
            return this.callOfflineService.getCallById(key);
        }
        const online = (key: string) => {
            return this.callOnlineService.getCallById(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, Call>(id, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getCallByIds(id: string[]): Promise<GenericResponseDto<Call[]>> {

        const offline = (key: string[]) => {
            return this.callOfflineService.getCallByIds(key);
        }
        const online = (key: string[]) => {
            return this.callOnlineService.getCallByIds(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string[], Call[]>(id, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getOnlineCallById(id: string): Promise<GenericResponseDto<Call>> {

        const offline = (key: string) => {
            return this.callOnlineService.getCallById(key);
        }
        const online = (key: string) => {
            return this.callOnlineService.getCallById(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, Call>(id, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getCallsByCustomerId(customerId: string, filterRequestDto: FilterRequestDto): Promise<GenericRequestDto<Call[]>> {

        const params = new CallBatchParamsDto();
        params.customerId = customerId;
        params.filterRequestDto = filterRequestDto;

        const compareDelegate = this.compareCallArray;
        const unProcessedDelegate = (key: CallBatchParamsDto, hasOfflineAccess: boolean) => this.getUnprocessedCallsByCustomerId(
            key, hasOfflineAccess);

        const offline = (key: CallBatchParamsDto) => {
            return this.callOfflineService.getCallsByCustomerId(key);
        }
        const online = (key: CallBatchParamsDto) => {
            return this.callOnlineService.getCallsByCustomerId(key);
        }
        const response = await this.datasourceDelineationService.makeCall<CallBatchParamsDto, Call[]>(
            params, offline, online, compareDelegate, unProcessedDelegate);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getLocalCallsByCustomerId(id: string): Promise<GenericResponseDto<Call[]>> {

        const offline = (key: string) => {
            return this.callOfflineService.getLocalCallsByCustomerId(key);
        }
        const online = (key: string) => {
            return this.callOfflineService.getLocalCallsByCustomerId(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, Call[]>(id, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getOpenCallByEmployeeId(employeeId: string): Promise<GenericResponseDto<Call>> {
        const offline = (key: string) => {
            return this.callOfflineService.getOpenCallByEmployeeId(key);
        }
        const online = (key: string) => {
            return this.callOfflineService.getOpenCallByEmployeeId(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, Call | undefined>(employeeId, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getCallCountsForCustomerIds(
        customerIds: string[],
        startDate: Date,
        endDate: Date,
        forceOnline = false
    ): Promise<GenericResponseDto<Map<string, number>>> {

        if (forceOnline) {
            const values = await this.callOnlineService.getCallCountsForCustomerIds(customerIds, startDate, endDate);
            const ret = new GenericResponseDto<Map<string, number>>();
            ret.values = values;
            ret.count = values.size;
            ret.isError = false;
            return ret;
        }

        const response = await this.datasourceDelineationService.makeCall(
            customerIds,
            ids => this.callOfflineService.getCallCountsForCustomerIds(ids, startDate, endDate),
            ids => this.callOnlineService.getCallCountsForCustomerIds(ids, startDate, endDate)
        );

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getCallHistoryBatch(employeeId: string
        , zrt: string
        , refiners: Refiner[]
        , pageSize: number
        , startIndex: number
        , filterSorts: FilterSortDto<CallHistoryColumns>[]
        , employeeZrts?: string[]
        , lastCall?: boolean
        , getPictures?: boolean
    ): Promise<GenericResponseDto<CallHistoryEntry[]>> {

        const key = new CallHistoryParamsDto();
        key.filterRequestDto = new FilterRequestV2Dto();
        key.filterRequestDto.id = newSequentialId();
        key.filterRequestDto.filters = CallHistoryFilterMapService.mapFilterData(refiners, employeeId, employeeZrts, lastCall, getPictures);
        key.filterRequestDto.pageSize = pageSize;
        key.filterRequestDto.startIndex = startIndex;
        key.filterRequestDto.filterSorts = filterSorts;
        key.employeeId = employeeId;
        key.employeeZrt = zrt;
        key.lastCall = lastCall;
        key.getPictures = getPictures;

        const compareDelegate = this.compareCallHistoryArray;
        const unProcessedDelegate = (key: CallHistoryParamsDto, hasOfflineAccess: boolean) => this.getUnprocessedCallHistoryEntriesByBatch(
            key, hasOfflineAccess);
        const offline = (key: CallHistoryParamsDto) => {
            return this.callOfflineService.getCallHistory(key);
        }
        const online = (key: CallHistoryParamsDto) => {
            return this.callOnlineService.getCallHistory(key);
        }
        const response = await this.datasourceDelineationService.makeCall<CallHistoryParamsDto, CallHistoryEntry[]>(
            key, offline, online, compareDelegate, unProcessedDelegate);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async save(call: Call): Promise<GenericResponseDto<undefined>> {
        this.persist(call, DexieTableNames.calls);

        const offline = (key: Call) => {
            return this.callOfflineService.save(key);
        }
        const online = (key: Call) => {
            return this.callOfflineService.save(key);
        }
        const response = await this.datasourceDelineationService.makeCall<Call, undefined>(call, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
            const customer = await this.dbService.customers.where("id").equals(call.customerId).first();
            customer.callsMade += 1;
            await this.dbService.customers.put(customer);
            this.customerStateService.customer = customer;
        }
        return response;
    }

    async getLastCallOfTypeByCustomerId(id: string, callType: CallTypes): Promise<GenericResponseDto<Call | undefined>> {

        const offline = (key: string) => {
            return this.callOfflineService.getLastCallOfTypeByCustomerId(id, callType);
        }
        const online = (key: string) => {
            return this.callOnlineService.getLastCallOfTypeByCustomerId(id, callType);
        }
        const response = await this.datasourceDelineationService.makeCall<string, Call | undefined>(id, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getLastCallOfTypeWithOrdersByCustomerId(id: string, callType: CallTypes): Promise<GenericResponseDto<Call | undefined>> {

        const offline = (key: string) => {
            return this.callOfflineService.getLastCallWithOrdersByCustomerId(key, callType);
        }
        const online = (key: string) => {
            return this.callOnlineService.getLastCallWithOrdersByCustomerId(key, callType);
        }
        const response = await this.datasourceDelineationService.makeCall<string, Call | undefined>(id, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getLastCompletedCallByEmployeeId(id: string): Promise<GenericResponseDto<Call | undefined>> {

        const offline = (key: string) => {
            return this.callOfflineService.getLastCompletedCallByEmployeeId(key);
        }
        const online = (key: string) => {
            return this.callOfflineService.getLastCompletedCallByEmployeeId(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, Call | undefined>(id, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    private async getUnprocessedCallsByCustomerId(key: CallBatchParamsDto, hasOfflineAccess: boolean): Promise<Call[]> {
        const calls = await this.callOfflineService.getUnprocessedCallsByCustomerId(key);

        void this.cleanUpCalls(hasOfflineAccess);
        return calls;
    }

    private async getUnprocessedCallHistoryEntriesByBatch(key: CallHistoryParamsDto, hasOfflineAccess: boolean): Promise<CallHistoryEntry[]> {
        const unprocessed = await this.callOfflineService.getUnprocessedCallHistory(key);

        void this.cleanUpCalls(hasOfflineAccess);
        return unprocessed;
    }

    private async cleanUpCalls(hasOfflineAccess: boolean): Promise<void> {
        if (hasOfflineAccess) return;

        const processed = await this.dbService.calls.where("hasServerProcessed").equals(1).toArray();

        if (processed.length) {
            this.dbService.calls.bulkDelete(processed.map(v => v.id));
        }
    }

    private async compareCallArray(
        onlineCalls: Call[],
        notSyncedOfflineCalls: Call[]
    ): Promise<Call[]> {
        const rtn = new Array<Call>();
        const offlineCallMaps = new Map(notSyncedOfflineCalls?.map((entry) => [entry.id, entry]));

        for (const onlineCall of (onlineCalls ?? [])) {
            const offlineCall = offlineCallMaps?.get(onlineCall.id);
            if (offlineCall) {
                offlineCallMaps.delete(offlineCall.id);
                if ((onlineCall.modifiedUtcDateTime?.getTime() ?? onlineCall.createdUtcDateTime?.getTime()) >=
                    (offlineCall.modifiedUtcDateTime?.getTime() ?? offlineCall.createdUtcDateTime?.getTime()))
                {
                    rtn.push(onlineCall);
                } else {
                    rtn.push(offlineCall);
                }
            } else {
                rtn.push(onlineCall);
            }
        }

        // What is remaining in the offlineContactMaps are entries that don't exist in the online version
        // Unprocessed entries are placed at the top
        for (const offlineContactMap of offlineCallMaps.entries()) {
            rtn.push(offlineContactMap[1]);
        }
        return rtn;
    }

    private async compareCallHistoryArray(
        onlineCallHistoryEntries: CallHistoryEntry[],
        notSyncedOfflineCallHistoryEntries: CallHistoryEntry[]
    ): Promise<CallHistoryEntry[]> {
        const rtn = new Array<CallHistoryEntry>();
        const offlineCallHistoryMaps = new Map(notSyncedOfflineCallHistoryEntries?.map((entry) => [entry.id, entry]));

        for (const onlineCallHistoryEntry of (onlineCallHistoryEntries ?? [])) {
            const offlineCallHistory = offlineCallHistoryMaps?.get(onlineCallHistoryEntry.id);
            if (offlineCallHistory) {
                offlineCallHistoryMaps.delete(offlineCallHistory.id);
                if ((onlineCallHistoryEntry.modifiedUtcDateTime?.getTime() ?? onlineCallHistoryEntry.createdUtcDateTime?.getTime()) >=
                    (offlineCallHistory.modifiedUtcDateTime?.getTime() ?? offlineCallHistory.createdUtcDateTime?.getTime()))
                {
                    rtn.push(onlineCallHistoryEntry);
                } else {
                    rtn.push(offlineCallHistory);
                }
            } else {
                rtn.push(onlineCallHistoryEntry);
            }
        }

        // What is remaining in the offlineContactMaps are entries that don't exist in the online version
        // Unprocessed entries are placed at the top
        for (const offlineContactMap of offlineCallHistoryMaps.entries()) {
            rtn.push(offlineContactMap[1]);
        }
        return rtn;
    }
}
