import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    Type
} from "@angular/core";
import { untilDestroyed } from "@ngneat/until-destroy";
import { Subscription } from "rxjs";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterLocation } from "src/app/enums/filter-location";
import { ZrtFilterServiceBase } from "src/app/services/zrt-filter.service.base";
import { RefinerServiceBase } from "../../refiner-service-base";
import { GenericLookup } from "shield.shared";
import { Px3Rank } from "src/app/entity-models/px3-rank.entity";

@Component({
    selector: "app-filter-base",
    templateUrl: "./filter-base.component.html",
    styleUrls: ["./filter-base.component.css"]
})
export abstract class FilterBaseComponent implements OnDestroy {

    //private vars
    private refinerInputChangeSubscription: Subscription;
    private refinerSubscription: Subscription;

    //protected vars
    protected inputSubmitKeys: string[] = ["Enter"];
    protected _refinerService: RefinerServiceBase;
    protected _filterLocation: FilterLocation;
    protected _zrtFilterService?: ZrtFilterServiceBase;

    //public
    public isInitialized = false;

    //Inputs
    @Input()
    set filterLocation(value: FilterLocation) {
        this._filterLocation = value;
    }
    @Input()
    get refinerService(): RefinerServiceBase {
        return this._refinerService;
    }
    set refinerService(value: RefinerServiceBase) {
        this._refinerService = value;

        if (this.isInitialized) {
            if (!this.refinerSubscription || this.refinerSubscription.closed) {
                this.refinerSubscription = this._refinerService.refiners$.pipe(untilDestroyed(this)).subscribe(
                    () => {
                        this.onRefinersChange();
                    }
                );
            }

            if (
                !this.refinerInputChangeSubscription ||
                this.refinerInputChangeSubscription.closed
            ) {
                this.refinerInputChangeSubscription = this._refinerService.refinerInputChange$.subscribe(
                    (refiner) => {
                        this.onInputChange(refiner);
                    }
                );
            }
        }
    }

    get zrtFilterService() {
        return this._zrtFilterService;
    }
    set zrtFilterService(value: ZrtFilterServiceBase) {
        if (value) {
            this._zrtFilterService = value;
        }
    }

    //outputs
    @Output()
    inputChanged = new EventEmitter<Refiner>();

    //abstract vars
    abstract name: string;
    abstract icon: string;
    abstract viewmodel: any;
    abstract contentComponent: Type<any>;

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    //abstract methods
    abstract onInputChange?(refiner: Refiner): void;
    abstract onRefinersChange(): void;

    //protected methods
    protected setInitialized(): void {
        this.isInitialized = true;
        if (!this.refinerSubscription || this.refinerSubscription.closed) {
            this.refinerSubscription = this._refinerService.refiners$.subscribe(
                () => {
                    this.onRefinersChange();
                }
            );
        }

        if (
            !this.refinerInputChangeSubscription ||
            this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription = this._refinerService.refinerInputChange$.subscribe(
                (refiner) => {
                    this.onInputChange(refiner);
                }
            );
        }
    }

    protected static onMultiSelectSelectionChange<T>(isAllPresent: boolean, selected: GenericLookup<T>[], all: GenericLookup<T>): { isAllPresent: boolean; selected: GenericLookup<T>[]; } {
        if (isAllPresent && selected.length > 1) {
            return {
                isAllPresent: false,
                selected: selected.filter((s) => s !== all)
            };
        } else if (!isAllPresent && selected.includes(all)) {
            return {
                isAllPresent: true,
                selected: [all]
            };
        }
        return {
            isAllPresent: isAllPresent,
            selected: selected
        };
    }

    //private methods
    private unsubscribe() {
        if (this.refinerSubscription && !this.refinerSubscription.closed) {
            this.refinerSubscription.unsubscribe();
        }
        if (
            this.refinerInputChangeSubscription &&
            !this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription.unsubscribe();
        }
    }
}
