export class DailySummaryEntry {
    dayTimeEntryId: string;
    zrt: string;
    name: string;
    date: Date;
    resetDay: boolean;
    totalCalls: number;
    retailMinutes: number;
    wholesaleMinutes: number;
    drivingMinutes: number;
    otherMinutes: number;
    endOfDayComments: string;
    workWiths?: string[];
    startDate?: any;
    endDate?: any;
}
