import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

export class ApiGatewayInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (environment.production) return next.handle(req);

        // replace api URL with local debug server URL...
        const newUrl = req.url.replace(
            "/api/",
            environment.apiRootUrl + "api/"
        );
        const authReq = req.clone({ url: newUrl });
        return next.handle(authReq);
    }
}
