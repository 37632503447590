import { CallProductTaxDto } from "./call-cash-tax.dto";

export class CallExchangeOutProductDto {
    id: string;
    productId: string;
    quantity: number;
    units: number;
    price: number;
    wholesalePrice: number;
    upc: string;
    callProductTax: CallProductTaxDto | null;
}
