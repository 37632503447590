<div class="card-header banner-header d-flex flex-column pb-0">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
        <div class="mb-3">
            Time Log
        </div>
    </div>
</div>
<div class="bg-white card-body">
    <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-12">
            <div class="row">
                <div class="col">
                    <mat-card class="p-0">
                        <div class="d-flex">
                            <div class="bg-primary p-3 btl-round bbl-round">
                                <span class="text-white">Total Time</span>
                            </div>
                            <div class="flex-grow-1 p-3 text-right {{dayTimeTotal == null ? 'text-muted' : ''}}">
                                {{ dayTimeTotal || "None" }}
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="calendar-wrapper">
                        <div *ngIf="selectedDate" class="calendar-header">
                            <div class="calendar-header-year">
                                {{ selectedDate.year() }}
                            </div>
                            <div class="calendar-header-date">
                                {{ selectedDate.format("ddd") }},
                                {{ selectedDate.format("MMM") }}
                                {{ selectedDate.format("DD") }}
                            </div>
                        </div>
                        <mat-calendar #calendar [selected]="selectedDate" [startAt]="startAt"
                            [dateClass]="employee ? dateClass() : null" (selectedChange)="onSelect($event)"
                            [headerComponent]="calendarHeader" (viewChanged)="onViewChanged()">
                        </mat-calendar>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col h-center">
                    <button [disabled]="!canCompletDay || isCompletingDay || dailyTimeEntry?.isCompleted || timeEditInProgress"
                        [title]="completeDayTitle" (click)="onCompleteDayClick()" class="btn sw-green-btn">
                        Complete Day
                    </button>
                </div>
            </div>
            <div *ngIf="dailyTimeEntry?.isCompleted">
                <mat-card class="margin-top">
                    <div class="row margin-top" *ngIf="dailyTimeEntry.mileage || dailyTimeEntry.mileage===0">
                        <div class="col">
                            Mileage: {{dailyTimeEntry.mileage}}
                        </div>
                    </div>
                    <div class="row margin-top">
                        <div class="col">
                            Reset Day: {{dailyTimeEntry.resetDay ? "Yes" : "No"}}
                        </div>
                    </div>
                    <div *ngIf="dailyTimeEntry?.dayTimeEntryWorkWiths?.length > 0" class="row margin-top">
                        <div class="col">
                            Work With:
                        </div>
                    </div>
                    <div *ngIf="dailyTimeEntry?.dayTimeEntryWorkWiths">
                        <div *ngFor="let ww of dailyTimeEntry.dayTimeEntryWorkWiths index as i" class="row">
                            <div class="col">
                                {{(i + 1) + ". " + ww.person + " (" + ww.duration + ")"}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="dailyTimeEntry?.endOfDayComment" class="row margin-top">
                        <div class="col">
                            End of Day Comment:
                        </div>
                    </div>
                    <div class="border">
                        <div *ngIf="dailyTimeEntry?.endOfDayComment" class="row">
                            <div class="col">
                                {{dailyTimeEntry.endOfDayComment}}
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-9 col-md-8 col-sm-12">
            <div class="d-flex justify-content-end mb-4">
                <button appThrottleClick [disabled]="dailyTimeEntry?.isCompleted" (throttledClick)="addNewTimeEntry()" class="btn sw-red-btn">
                    <fa-icon [icon]="faPlus" class="mr-2 align-middle"></fa-icon>Add New Time Log Entry
                </button>
            </div>
            <div class="row">
                <div class="col table-wrapper">
                    <div class="empty-state" *ngIf="timeEntryViewmodels.length <= 0" style="height: 50%">
                        <div class="empty-state-icon-container">
                            <span class="material-icons empty-state-icon">
                                today
                            </span>
                        </div>
                        <div class="title">No Time Entries</div>
                        <div class="subtitle">
                            You don't have any time entries for this date.
                        </div>
                    </div>
                    <form [formGroup]="recordForm">
                        <table class="table table-bordered table-striped table-hover"
                            *ngIf="timeEntryViewmodels.length > 0">
                            <thead>
                                <tr>
                                    <th>
                                        Time
                                        <div class="header-underline"></div>
                                    </th>
                                    <th>
                                        Duration
                                        <div class="header-underline"></div>
                                    </th>
                                    <th>
                                        Type
                                        <div class="header-underline"></div>
                                    </th>
                                    <th>
                                        Name
                                        <div class="header-underline"></div>
                                    </th>
                                    <th>
                                        Comments
                                        <div class="header-underline"></div>
                                    </th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr *ngFor="let entry of timeEntryViewmodels; index as i"
                                    [ngClass]="{'row-warning': !entry.type.name}">
                                    <td *ngIf="!entry.isEditMode || entry.isEditRestricted">{{ entry.vmStart }} - {{ entry.vmEnd }}</td>
                                    <td *ngIf="entry.isEditMode && !entry.isEditRestricted">
                                        <div class="d-flex align-items-center">
                                            <div class="d-flex align-items-center">
                                                <mat-form-field appearance="outline" color="accent">
                                                    <mat-label>Start Time</mat-label>
                                                    <input matInput name="start"
                                                        [formControlName]="'startTime' + i"
                                                        [owlDateTime]="dt1"
                                                        placeholder="EX: 8:00 AM"
                                                        [value]="recordForm.value.startTime">
                                                    <button matSuffix mat-icon-button [owlDateTimeTrigger]="dt1">
                                                        <mat-icon>schedule</mat-icon>
                                                    </button>
                                                    <mat-error *ngIf="recordForm.invalid">
                                                        Time invalid.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="recordForm.get('startTime' + i)?.hasError('empty')">
                                                        Start time can not be empty.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="recordForm.get('startTime' + i)?.hasError('overlap')">
                                                        Time range overlaps an existing time entry range.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="recordForm.get('startTime' + i)?.hasError('startGreaterThenEnd')">
                                                        Start time can not be greater than the end time.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="recordForm.get('startTime' + i)?.hasError('startLaterThenNow')">
                                                        For work entries, time can not be later than the current time.
                                                    </mat-error>
                                                    <owl-date-time [pickerType]="'timer'" #dt1 [hour12Timer]="true">
                                                    </owl-date-time>
                                                </mat-form-field>
                                            </div>

                                            <fa-icon [icon]="faArrowRight" class="text-black mx-3"></fa-icon>

                                            <div class="d-flex align-items-center">
                                                <mat-form-field appearance="outline" color="accent">
                                                    <mat-label>End Time</mat-label>
                                                    <input matInput name="end"
                                                        [formControlName]="'endTime' + i"
                                                        [owlDateTime]="dt2"
                                                        placeholder="EX: 3:45 PM"
                                                        [value]="recordForm.value.endTime">
                                                    <button matSuffix mat-icon-button [owlDateTimeTrigger]="dt2">
                                                        <mat-icon>schedule</mat-icon>
                                                    </button>
                                                    <mat-error *ngIf="recordForm.invalid">
                                                        Time invalid.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="recordForm.get('endTime' + i)?.hasError('empty')">
                                                        End time can not be empty.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="recordForm.get('endTime' + i)?.hasError('overlap')">
                                                        Time range overlaps an existing time entry range.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="recordForm.get('endTime' + i)?.hasError('startGreaterThenEnd')">
                                                        Start time can not be greater than the end time.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="recordForm.get('endTime' + i)?.hasError('endLaterThenNow')">
                                                        For work entries, time can not be later than the current time.
                                                    </mat-error>
                                                    <owl-date-time [pickerType]="'timer'" #dt2 [hour12Timer]="true">
                                                    </owl-date-time>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {{ entry.duration }}
                                    </td>
                                    <td *ngIf="!entry.isEditMode || entry.isEditRestricted">
                                        {{ entry.type.name }}
                                    </td>
                                    <td *ngIf="entry.isEditMode && !entry.isEditRestricted">
                                        <mat-form-field appearance="outline" color="accent" class="w-100">
                                            <mat-label>Select an Entry Type</mat-label>
                                            <mat-select [formControlName]="'type' + i"
                                                [compareWith]="compareTimeTypeOptions"
                                                [(value)]="entry.type">
                                                <mat-option *ngFor="let type of timeEntryTypes" [value]="type">
                                                    {{type.name}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="recordForm.get('type' + i)?.hasError('startLaterThenNow')
                                                    || recordForm.get('type' + i)?.hasError('endLaterThenNow')">
                                                For work entries, time can not be later than the current time.
                                            </mat-error>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        {{ entry.name }}
                                    </td>
                                    <td *ngIf="!entry.isEditMode">
                                        {{ entry.comments }}
                                    </td>
                                    <td *ngIf="entry.isEditMode">
                                        <mat-form-field appearance="outline" color="accent" class="w-100">
                                            <mat-label>
                                                Comments
                                            </mat-label>
                                            <textarea matInput placeholder="comments" [(ngModel)]="entry.comments"
                                                [ngModelOptions]="{standalone: true}" maxlength="4000"></textarea>
                                            <mat-hint align="end">{{
                                                entry.comments?.length || 0
                                                }}/4000</mat-hint>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <div
                                            *ngIf="!dailyTimeEntry?.isCompleted">

                                            <p *ngIf="!entry.isEditMode && !timeEditInProgress" class="edit-text mb-0"
                                                appThrottleClick (throttledClick)="setValidations(entry, true, i)">
                                                <fa-icon [icon]="faPen" class="mr-1"></fa-icon>Edit
                                            </p>
                                            <p *ngIf="entry.isEditMode
                                            && !recordForm.controls['startTime' + i.toString()]?.invalid
                                            && !recordForm.controls['endTime' + i.toString()]?.invalid
                                            && !recordForm.controls['type' + i.toString()]?.invalid" class="edit-text mb-0"
                                                appThrottleClick (throttledClick)="setValidations(entry, false, i)">
                                                <fa-icon [icon]="faSave" class="mr-1"></fa-icon>Save
                                            </p>

                                        </div>
                                    </td>
                                    <td>
                                        <div
                                            *ngIf="!dailyTimeEntry?.isCompleted">

                                            <p *ngIf="!entry.isEditRestricted
                                                && !entry.isEditMode
                                                && entry.type.name != timeEntryTypeCall" class="edit-text mb-0"
                                                    (click)="deleteTimeEntry(entry)">
                                                <fa-icon [icon]="faTrash" class="mr-1"></fa-icon>Delete
                                            </p>
                                            <p *ngIf="entry.isEditMode" class="edit-text mb-0"
                                                appThrottleClick (throttledClick)="cancelEdit(entry, i)">
                                                <fa-icon [icon]="faTimes" class="mr-1"></fa-icon>Cancel
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                    <mat-card
                        *ngIf="dailyTimeEntry && !dailyTimeEntry.isCompleted && isCompletingDay && !timeEditInProgress"
                        class="m-1">
                        <mat-card class="m-1">
                            <div class="row">
                                <div class="col">
                                    <mat-form-field appearance="outline" [color]="'accent'">
                                        <mat-label>Mileage</mat-label>
                                        <input matInput type="text" digitOnly mask="999999"
                                            [(ngModel)]="dailyTimeEntry.mileage" />
                                    </mat-form-field>
                                </div>
                                <div class="col h-center v-center radio-group">
                                    <mat-radio-group [(ngModel)]="dailyTimeEntry.resetDay">
                                        <mat-label>Reset Day: </mat-label>
                                        <mat-radio-button color="accent" [value]="false">
                                            No
                                        </mat-radio-button>
                                        <mat-radio-button color="accent" [value]="true">
                                            Yes
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="col work-with">
                                    <button appThrottleClick [disabled]="dailyTimeEntry.dayTimeEntryWorkWiths.length === 3"
                                        (throttledClick)="addWorkWith()" class="pull-right btn sw-red-btn">
                                        Add Work With
                                    </button>
                                </div>
                            </div>
                        </mat-card>
                        <mat-card class="m-1">
                            <form [formGroup]="workWithForm">
                                <div *ngFor="let record of dailyTimeEntry.dayTimeEntryWorkWiths; index as i">
                                    <div class="row">
                                        <div class="col-1 h-center">
                                            <button (click)="removeWorkWith(record)" class="btn btn-sm">
                                                <fa-icon [icon]="faTrash" class="mr-2 align-middle"></fa-icon>
                                            </button>
                                        </div>
                                        <div class="col">
                                            <app-employee-dropdown #employeeDropdown [offline]="true"
                                                (selectionChange)="record.person = $event? $event.fullName : null; setDayCanBeFinalized()">
                                            </app-employee-dropdown>
                                        </div>
                                        <div class="col h-center">
                                            <mat-form-field appearance="outline" color="accent">
                                                <mat-label>Start Time</mat-label>
                                                <input matInput name="daily-start"
                                                    [formControlName]="'workWithStartTime' + i"
                                                    [owlDateTime]="dt3"
                                                    placeholder="EX: 8:00 AM"
                                                    [value]="workWithForm.value.start">
                                                <button matSuffix mat-icon-button [owlDateTimeTrigger]="dt3">
                                                    <mat-icon>schedule</mat-icon>
                                                </button>
                                                <mat-error *ngIf="workWithForm.invalid">
                                                    Time invalid.
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="recordForm.get('workWithStartTime' + i)?.hasError('empty')">
                                                    Start time can not be empty.
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="workWithForm.get('workWithStartTime' + i)?.hasError('startGreaterThenEnd')">
                                                    Start time can not be greater than the end time.
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="workWithForm.get('workWithStartTime' + i)?.hasError('startLaterThenNow')">
                                                    Time can not be later than the current time.
                                                </mat-error>
                                                <owl-date-time [pickerType]="'timer'" #dt3 [hour12Timer]="true">
                                                </owl-date-time>
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <mat-form-field appearance="outline" color="accent">
                                                <mat-label>End Time</mat-label>
                                                <input matInput name="daily-end"
                                                    [formControlName]="'workWithEndTime' + i"
                                                    [owlDateTime]="dt4"
                                                    placeholder="EX: 3:45 PM"
                                                    [value]="workWithForm.value.endTime">
                                                <button matSuffix mat-icon-button [owlDateTimeTrigger]="dt4">
                                                    <mat-icon>schedule</mat-icon>
                                                </button>
                                                <mat-error *ngIf="workWithForm.invalid">
                                                    Time invalid.
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="recordForm.get('workWithEndTime' + i)?.hasError('empty')">
                                                    Start time can not be greater than the end time.
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="workWithForm.get('workWithEndTime' + i)?.hasError('startGreaterThenEnd')">
                                                    Start time can not be greater than the end time.
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="workWithForm.get('workWithEndTime' + i)?.hasError('endLaterThenNow')">
                                                    Time can not be later than the current time.
                                                </mat-error>
                                                <owl-date-time [pickerType]="'timer'" #dt4 [hour12Timer]="true">
                                                </owl-date-time>
                                            </mat-form-field>
                                        </div>
                                        <div class="col v-center">
                                            <div *ngIf="record.duration">
                                                Duration: {{record.duration}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="row">
                                <div class="col">
                                    <mat-form-field appearance="outline" color="accent" class="w-100 mt-3">
                                        <mat-label id="time-entry-comments">End of Day Comments (Optional)</mat-label>
                                        <textarea matInput name="endOfDayomments"
                                            [(ngModel)]="dailyTimeEntry.endOfDayComment"
                                            [ngModelOptions]="{standalone: true}"
                                            [disabled]="dailyTimeEntry?.isCompleted"
                                            placeholder="" maxlength="4000">
                                        </textarea>
                                        <mat-hint align="end">{{
                                            dailyTimeEntry.endOfDayComment?.length || 0
                                            }}/4000</mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-card>
                        <div class="text-align-right">
                            <button (click)="onCancelClick()" [disabled]="dailyTimeEntry?.isCompleted"
                                class="btn btn-white actionBtn">
                                Cancel
                            </button>
                            <button appThrottleClick [disabled]="!isCompletingDay || dailyTimeEntry.isCompleted || timeEditInProgress ||
                                !dayCanBeFinalized || hasWorkWithTimeErrors || !timeEntryDelineationService.getOnlineState()"
                                [title]="!dayCanBeFinalized || hasWorkWithTimeErrors? 'Day can not be finalized with incomplete Works With.' : 'Finalize'"
                                (throttledClick)="onFinalizeDayClick()" class="btn sw-green-btn actionBtn">
                                Complete Day
                            </button>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>
