import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { fromEvent, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { FilterSaveViewmodel } from "./filter-save.viewmodel";

@Component({
    selector: "app-filter-save-dialog",
    templateUrl: "./filter-save-dialog.component.html",
    styleUrls: ["./filter-save-dialog.component.css"]
})
export class FilterSaveDialogComponent implements OnInit, OnDestroy {
    @ViewChild("input") input: ElementRef;

    inputSubscription: Subscription;
    filterSaveViewModel: FilterSaveViewmodel = new FilterSaveViewmodel();

    constructor(
        private ref: SwisherOverlayRef<
            FilterSaveViewmodel,
            FilterSaveDialogComponent
        >
    ) {}

    @HostListener('window:keyup', ['$event'])
        onKeyUp(event: KeyboardEvent): void {
            if (event.key === 'Enter' && this.ref) {
                this.ref.data.buttonRightFunction()
            }
        }

    ngOnInit(): void {
        if (this.ref && this.ref.data) {
            this.filterSaveViewModel = this.ref.data;
            if (Helper.equalsIgnoreCase(this.ref.data.name, "No Selection")) {
                this.ref.data.name = "";
            }
        }

        setTimeout(() => {
            const inputSearchValue = fromEvent(
                this.input.nativeElement,
                "input"
            ).pipe(
                map((e: InputEvent) => (e.target as HTMLInputElement).value),
                debounceTime(500),
                distinctUntilChanged()
            );

            if (!this.inputSubscription || this.inputSubscription.closed) {
                this.inputSubscription = inputSearchValue.subscribe(() => {
                    this.checkForExistingFilter();
                });
            }
            this.checkForExistingFilter();
        }, 0);
    }

    ngOnDestroy(): void {
        if (this.inputSubscription && !this.inputSubscription.closed) {
            this.inputSubscription.unsubscribe();
        }
    }

    checkForExistingFilter(): void {
        this.filterSaveViewModel.buttonRightText = "Save";
        this.filterSaveViewModel.headerLeftText = "New Filter";
        for (const name of this.filterSaveViewModel.existingFilterNames) {
            if (Helper.equalsIgnoreCase(name, this.filterSaveViewModel.name)) {
                this.filterSaveViewModel.buttonRightText = "Update";
                this.filterSaveViewModel.headerLeftText = "Update Filter";
            }
        }
        this.filterSaveViewModel.needsMappingsRefreshed = true;
    }
}
