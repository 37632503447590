import { AfterContentChecked, Component, ElementRef, OnDestroy, Type, ViewChild } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterService } from "src/app/services/filter.service";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { OrderLineItemStatuses, OrderStatuses, RefinerLocation, GenericLookup, valueSeparator, GenericDropDownDto } from "shield.shared";
import { OrderInformationViewmodel } from "./order-information.viewmodel";
import { Product } from "src/app/entity-models/product.entity";
import { FormBuilder } from "@angular/forms";
import * as moment from "moment";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { ActivitiesFilterService } from "src/app/services/activities-filter.service";
import { AngularMultiSelect } from "angular2-multiselect-dropdown";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { Customer } from "src/app/entity-models/customer.entity";

@Component({
    selector: "app-order-information-filter",
    templateUrl: "./order-information-filter.component.html",
    styleUrls: ["./order-information-filter.component.css"]
})
export class OrderInformationFilterComponent extends FilterBaseComponent implements AfterContentChecked, OnDestroy  {
    //view childs (or is it view children?)
    @ViewChild("orderStatusSelect") orderStatusSelect: MatSelect;
    @ViewChild("orderLineItemStatusSelect") orderLineItemStatusSelect: MatSelect;
    @ViewChild("orderTypeSelect") orderTypeSelect: MatSelect;
    @ViewChild("productSelect") productSelect: MatSelect;
    @ViewChild("projectSelect") projectSelect: AngularMultiSelect;
    @ViewChild("wholesalerSelect") wholesalerSelect: AngularMultiSelect;
    @ViewChild("chainSelect") chainSelect: AngularMultiSelect;
    @ViewChild("orderStartDateInput") orderStartDateInput: ElementRef;
    @ViewChild("orderEndDateInput") orderEndDateInput: ElementRef;

    //public vars
    contentComponent: Type<OrderInformationFilterComponent>;
    icon = "layers";
    name = "Order Information";
    viewmodel = new OrderInformationViewmodel(
        this.filterService, this.productDelineationService, this.formBuilder
    );
    isProjectsInitialized = false;
    isChainsInitialized = false;
    isWholesalersInitialized = false;
    isProductsInitialized = false;

    constructor(
        private filterService: FilterService,
        private productDelineationService: ProductDelineationService,
        private activitiesFilterService: ActivitiesFilterService,
        private formBuilder: FormBuilder
    ) {
        super();
    }

    async ngAfterContentChecked(): Promise<void> {
        if (!this.isInitialized && this.wholesalerSelect && this.projectSelect && this.chainSelect) {
            this.isInitialized = true;

            if (!this.viewmodel.productSubscription || this.viewmodel.productSubscription.closed) {
                this.viewmodel.productSubscription = this.productDelineationService.observableAllProducts.subscribe(
                    (allProducts) => {
                        if (allProducts?.size > 0) {
                            this.viewmodel.products = Array.from(allProducts.values());
                            this.isProductsInitialized = true;
                        }
                        this.checkAndSetInitialized();
                    }
                );
            }

            if (
                !this.viewmodel.chainsSubscription ||
                this.viewmodel.chainsSubscription.closed
            ) {
                this.viewmodel.chainsSubscription = this.activitiesFilterService.observableChains.subscribe(
                    (chains) => {
                        if (chains && this.activitiesFilterService.chainsLoaded) {
                            this.chainSelect.settings.noDataLabel = "No chains found.";
                            let partialChains = chains.map((chain) => {
                                chain.commonOperatingName ??= chain.name;
                                return chain;
                            });
                            partialChains = [...new Map(partialChains.map((c) => [c.ownerCode, c])).values()];
                            this.viewmodel.chains = partialChains;

                            this.isChainsInitialized = true;

                            const selected = new Array<AccountOwnership>();
                            for (const chain of this.viewmodel.selectedChains) {
                                if (chains.map((c) => c.id).includes(chain.id)) {
                                    selected.push(chain);
                                }
                            }
                            this.viewmodel.selectedChains = selected;
                            this.checkAndSetInitialized();
                        }
                    }
                );
            }
            if (
                !this.viewmodel.projectsSubscription ||
                this.viewmodel.projectsSubscription.closed
            ) {
                this.viewmodel.projectsSubscription = this.activitiesFilterService.observableProjects.subscribe(
                    (projects) => {
                        if (projects && this.activitiesFilterService.projectsLoaded) {
                            this.projectSelect.settings.noDataLabel = "No projects found.";
                            this.viewmodel.projects = projects;
                            this.isProjectsInitialized = true;

                            const selected = new Array<GenericDropDownDto>();
                            for (const project of this.viewmodel.selectedProjects) {
                                if (projects.map(p => p.id).includes(project.id)) {
                                    selected.push(project);
                                }
                            }
                            this.viewmodel.selectedProjects = selected;
                            this.checkAndSetInitialized();
                        }
                    }
                );
            }
            if (
                !this.viewmodel.wholesalersSubscription ||
                this.viewmodel.wholesalersSubscription.closed
            ) {
                this.viewmodel.wholesalersSubscription = this.activitiesFilterService.observableWholesalers.subscribe(
                    (wholesalers) => {
                        if (wholesalers && this.activitiesFilterService.wholesalersLoaded) {
                            this.wholesalerSelect.settings.noDataLabel = "No wholesalers found.";
                            this.viewmodel.wholesalers = wholesalers;
                            this.isWholesalersInitialized = true;

                            const selected = new Array<Customer>();
                            for (const wholesaler of this.viewmodel.selectedWholesalers) {
                                if (wholesalers.map((w) => w.id).includes(wholesaler.id)) {
                                    selected.push(wholesalers.find(w => w.id === wholesaler.id));
                                }
                            }
                            this.viewmodel.selectedWholesalers = selected;
                            this.checkAndSetInitialized();
                        }
                    }
                );
            }
        }
    }

    ngOnDestroy(): void {
        if(
            this.viewmodel.productSubscription &&
            !this.viewmodel.productSubscription.closed
        ){
            this.viewmodel.productSubscription.unsubscribe();
        }
        if (
            this.viewmodel.chainsSubscription &&
            !this.viewmodel.chainsSubscription.closed
        ) {
            this.viewmodel.chainsSubscription.unsubscribe();
        }
        if (
            this.viewmodel.projectsSubscription &&
            !this.viewmodel.projectsSubscription.closed
        ) {
            this.viewmodel.projectsSubscription.unsubscribe();
        }
        if (
            this.viewmodel.wholesalersSubscription &&
            !this.viewmodel.wholesalersSubscription.closed
        ) {
            this.viewmodel.wholesalersSubscription.unsubscribe();
        }
    }

    //events
    onAddOrderEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        if (this.viewmodel.dateForm.controls.orderEndDate.errors) return;

        const value = this.orderEndDateInput.nativeElement.value;
        const existing = this._refinerService.refiners.find(v => v.location === RefinerLocation.orderOnOrBeforeDate);

        if (value || existing) {
            this._refinerService.onInputChange(
                RefinerLocation.orderOnOrBeforeDate,
                this.orderEndDateInput.nativeElement.value
            );
            if (!event || (event as KeyboardEvent).key === "Enter") {
                setTimeout(() => {
                    const refiner = new Refiner();
                    refiner.location = RefinerLocation.orderOnOrBeforeDate;
                    refiner.value = this.orderEndDateInput.nativeElement.value;
                    refiner.dataPropertyName = "endDate";

                    this._refinerService.checkAndUpdateRefiner(refiner);
                }, 0)
            }
        }
    }

    onAddOrderStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        if (this.viewmodel.dateForm.controls.orderStartDate.errors) return;

        const value = this.orderStartDateInput.nativeElement.value;
        const existing = this._refinerService.refiners.find(v => v.location === RefinerLocation.orderOnOrAfterDate);

        if (value || existing) {
            this._refinerService.onInputChange(
                RefinerLocation.orderOnOrAfterDate,
                this.orderStartDateInput.nativeElement.value
            );
            if (!event || (event as KeyboardEvent).key === "Enter") {
                setTimeout(() => {
                    const refiner = new Refiner();
                    refiner.location = RefinerLocation.orderOnOrAfterDate;
                    refiner.value = this.orderStartDateInput.nativeElement.value;
                    refiner.dataPropertyName = "startDate";

                    this._refinerService.checkAndUpdateRefiner(refiner);
                }, 0)
            }
        }
    }

    onAddUinRefiner(event?: KeyboardEvent) {
        this._refinerService.onInputChange(
            RefinerLocation.uin,
            this.viewmodel.uinInput
        );
        if (
            this.viewmodel.uinInput &&
            (!event || this.inputSubmitKeys.includes(event.key))
        ) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.uin;
            refiner.value = this.viewmodel.uinInput;
            refiner.dataPropertyName = "uin";

            this._refinerService.checkAndUpdateRefiner(refiner);
        }
    }

    onOpenedOrderStatusChange(): void {
        if (!this.orderStatusSelect.panelOpen) {
            const statusValues = new Array<string>();
            const statusDataValues = new Array<string>();
            for(const status of this.viewmodel.selectedOrderStatuses) {
                statusValues.push(status.name);
                statusDataValues.push(status.id.toString());
            }

            const refiner = new Refiner();
            refiner.location = RefinerLocation.orderStatus;
            refiner.value = statusValues.join(", ");
            refiner.dataPropertyName = "id";
            refiner.dataValue = statusDataValues.join(valueSeparator);

            this._refinerService.checkAndUpdateRefiner(refiner, true);
        }
    }

    onOpenedOrderLineItemStatusChange(): void {
        const statusValues = new Array<string>();
        const statusDataValues = new Array<string>();
        for(const status of this.viewmodel.selectedOrderLineItemStatuses) {
            statusValues.push(status.name);
            statusDataValues.push(status.id.toString());
        }

        if (!this.orderLineItemStatusSelect.panelOpen) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.orderLineItemStatus;
            refiner.value = statusValues.join(", ");
            refiner.dataPropertyName = "id";
            refiner.dataValue = statusDataValues.join(valueSeparator);

            this._refinerService.checkAndUpdateRefiner(refiner, true);
        }
    }

    onOpenedOrderTypeChange(): void {
        if (!this.orderTypeSelect.panelOpen) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.orderType;
            refiner.value = this.viewmodel.selectedOrderTypes.join(", ");
            refiner.dataPropertyName = "type";
            refiner.dataValue = this.viewmodel.selectedOrderTypes.join(valueSeparator);

            this._refinerService.checkAndUpdateRefiner(refiner);
        }
    }

    onOpenedProductsSelect() {
        this.viewmodel.isProductsSearchStale = true;
        this.viewmodel.selectedProductsHold = this.viewmodel.selectedProducts;
    }

    onClosedProductsSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.products;
        refiner.value = this.viewmodel.selectedProducts.length
            + " Swisher Product(s)";
        refiner.dataPropertyName = "id";
        refiner.dataValue = this.viewmodel.selectedProducts
            .map((vm) => vm.id)
            .join(valueSeparator);

        this._refinerService.checkAndUpdateRefiner(refiner, true);

        if (this.viewmodel.selectedProductsHold === this.viewmodel.selectedProducts) {
            this.viewmodel.isProductsSearchStale = false;
        }
    }

    openedProjectSelect() {
        this.viewmodel.isProjectsStale = true;
        this.viewmodel.selectedProjectsHold = this.viewmodel.selectedProjects;
    }

    closedProjectSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.projects;
        refiner.value = this.viewmodel.selectedProjects?.length < 2
            ? this.viewmodel.selectedProjects
                .map((project) => project.name)
                .join(", ")
            : this.viewmodel.selectedProjects?.length + " Selected";
        refiner.dataPropertyName = "name"; // TODO: Determine property for local filter.
        refiner.dataValue = this.viewmodel.selectedProjects
            .map((project) => project.id)
            .join(valueSeparator);

        if (this.viewmodel.selectedProjectsHold === this.viewmodel.selectedProjects) {
            this.viewmodel.isProjectsStale = false;
        }

        this._refinerService.checkAndUpdateRefiner(refiner);
    }

    openedWholesalerSelect() {
        this.viewmodel.isWholesalersStale = true;
        this.viewmodel.selectedWholesalersHold = this.viewmodel.selectedWholesalers;
    }

    closedWholesalerSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.wholesalers;
        refiner.value = this.viewmodel.selectedWholesalers?.length < 2
            ? this.viewmodel.selectedWholesalers
                .map((wholesaler) => wholesaler.name)
                .join(", ")
            : this.viewmodel.selectedWholesalers?.length + " Selected";
        refiner.dataPropertyName = "customerWholesalers";
        refiner.dataValue = this.viewmodel.selectedWholesalers
            .map((wholesaler) => wholesaler.id)
            .join(valueSeparator);

        if (this.viewmodel.selectedWholesalersHold === this.viewmodel.selectedWholesalers) {
            this.viewmodel.isWholesalersStale = false;
        }

        this._refinerService.checkAndUpdateRefiner(refiner);
    }

    openedChainSelect() {
        this.viewmodel.isChainsStale = true;
        this.viewmodel.selectedChainsHold = this.viewmodel.selectedChains;
    }

    closedChainSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.chains;
        refiner.value = this.viewmodel.selectedChains?.length < 2
            ? this.viewmodel.selectedChains
                .map((chain) => chain.name)
                .join(", ")
            : this.viewmodel.selectedChains?.length + " Selected";
        refiner.dataPropertyName = "ownerCode";
        refiner.dataValue = this.activitiesFilterService.getChainDataValues(this.viewmodel.selectedChains);

        if (this.viewmodel.selectedChainsHold === this.viewmodel.selectedChains) {
            this.viewmodel.isChainsStale = false;
        }

        this._refinerService.checkAndUpdateRefiner(refiner);
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.chains:
                    const selectedChains = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedChains = new Array<AccountOwnership>();

                    if (selectedChains) {
                        for (const chain of selectedChains) {
                            const found = this.viewmodel.chains.find(
                                (c) => c.id === chain.trim()
                            );
                            if (found) {
                                rtnSelectedChains.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedChains = rtnSelectedChains;
                    break;
                case RefinerLocation.projects:
                    const selectedProjects = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedProjects = new Array<GenericDropDownDto>();

                    if (selectedProjects) {
                        for (const project of selectedProjects) {
                            const found = this.viewmodel.projects.find(
                                (p) => p.id === project.trim()
                            );
                            if (found) {
                                rtnSelectedProjects.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedProjects = rtnSelectedProjects;
                    break;
                case RefinerLocation.wholesalers:
                    const selectedWholesalers = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedWholesalers = new Array<Customer>();

                    if (selectedWholesalers) {
                        for (const wholesaler of selectedWholesalers) {
                            const found = this.viewmodel.wholesalers.find(
                                (w) => w.id === wholesaler.trim()
                            );
                            if (found) {
                                rtnSelectedWholesalers.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedWholesalers = rtnSelectedWholesalers;
                    break;
                case RefinerLocation.uin:
                    if (refiner.value !== this.viewmodel.uinInput) {
                        this.viewmodel.uinInput = refiner.value;
                    }
                    break;
                case RefinerLocation.products:
                    const selectedProducts = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedProducts = new Array<Product>();

                    if (selectedProducts) {
                        for (const product of selectedProducts) {
                            const found = this.viewmodel.products.find(
                                (sp) => sp.id === product.trim()
                            );
                            if (found) {
                                rtnSelectedProducts.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedProducts = rtnSelectedProducts;
                    break;
                case RefinerLocation.orderOnOrAfterDate:
                    if (refiner.value !== this.orderStartDateInput?.nativeElement.value) {
                        this.viewmodel.dateForm.controls.orderStartDate.setValue(refiner.value
                            ? moment(new Date(refiner.value))
                            : this.viewmodel.startDateDefault)
                    }
                    break;
                case RefinerLocation.orderOnOrBeforeDate:
                    if (refiner.value !== this.orderEndDateInput?.nativeElement.value) {
                        this.viewmodel.dateForm.controls.orderEndDate.setValue(refiner.value
                            ? moment(new Date(refiner.value))
                            : this.viewmodel.endDateDefault)
                    }
                    break;
                case RefinerLocation.orderType:
                    const selectedOrderTypes = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedOrderTypes = new Array<string>();

                    if (selectedOrderTypes) {
                        for (const type of selectedOrderTypes) {
                            const found = this.viewmodel.orderTypes.find(
                                (ot) => ot === type
                            );
                            if (found) {
                                rtnSelectedOrderTypes.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedOrderTypes = rtnSelectedOrderTypes;
                    break;
                case RefinerLocation.orderStatus:
                    const selectedOrderStatuses = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedOrderStatuses = new Array<GenericLookup<OrderStatuses>>();

                    if (selectedOrderStatuses) {
                        for (const status of selectedOrderStatuses) {
                            const found = this.viewmodel.orderStatuses.find(
                                (sos) => sos.id.toString() === status
                            );
                            if (found) {
                                rtnSelectedOrderStatuses.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedOrderStatuses = rtnSelectedOrderStatuses;
                    break;
                case RefinerLocation.orderLineItemStatus:
                    const selectedOrderLineItemStatuses = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedOrderLineItemStatuses = new Array<GenericLookup<OrderLineItemStatuses>>();

                    if (selectedOrderLineItemStatuses) {
                        for (const oliStatus of selectedOrderLineItemStatuses) {
                            const found = this.viewmodel.orderLineItemStatuses.find(
                                (solis) => solis.id.toString() === oliStatus
                            );
                            if (found) {
                                rtnSelectedOrderLineItemStatuses.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedOrderLineItemStatuses = rtnSelectedOrderLineItemStatuses;
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.chains;
        const chainsRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.chains
        );
        if (chainsRefiner) {
            this.onInputChange(chainsRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.projects;
        const projectsRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.projects
        );
        if (projectsRefiner) {
            this.onInputChange(projectsRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.wholesalers;
        const wholesalersRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.wholesalers
        );
        if (wholesalersRefiner) {
            this.onInputChange(wholesalersRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.uin;
        const uinRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.uin
        );
        if (uinRefiner) {
            this.onInputChange(uinRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.products;
        const productsRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.products
        );
        if (productsRefiner) {
            this.onInputChange(productsRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.orderOnOrAfterDate;
        const startDateRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.orderOnOrAfterDate
        );
        if (startDateRefiner) {
            this.onInputChange(startDateRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.orderOnOrBeforeDate;
        const endDateRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.orderOnOrBeforeDate
        );
        if (endDateRefiner) {
            this.onInputChange(endDateRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.orderType;
        const orderTypeRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.orderType
        );
        if (orderTypeRefiner) {
            this.onInputChange(orderTypeRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.orderStatus;
        const orderStatusRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.orderStatus
        );
        if (orderStatusRefiner) {
            this.onInputChange(orderStatusRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.orderLineItemStatus;
        const orderLineItemStatusRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.orderLineItemStatus
        );
        if (orderLineItemStatusRefiner) {
            this.onInputChange(orderLineItemStatusRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }

    private checkAndSetInitialized(): void {
        if (this.isProductsInitialized
            && this.isProjectsInitialized
            && this.isChainsInitialized
            && this.isWholesalersInitialized
        ) {
            this.setInitialized();
        }
    }
}
