<div role="main" class="global-container d-flex flex-column flex-grow-1">
    <div class="main-container 2-column d-flex flex-column flex-grow-1">
        <div class="d-flex flex-column flex-grow-1">
            <div class="worksheet-area d-flex flex-column flex-grow-1">
                <div class="worksheet-header pl-4 pr-4 pt-2 pb-2">
                    <div class="row">
                        <div class="col-lg-4 col-md-12 col-sm-12">
                            <div class="row">
                                    <h2>Project: {{viewmodel?.projectStateService?.project?.name}}</h2>
                                    <h3 *ngIf="viewmodel?.projectStatus" class="project-status">Status: {{viewmodel?.projectStatus}}</h3>
                            </div>
                            <div class="row">
                                <h4>Mission: {{viewmodel?.projectStateService?.project?.mission}}</h4>
                            </div>
                        </div>
                        <div class="col-lg-4 d-flex col-md-12 col-sm-12">
                            <div class="col-lg-7 col-md-12 col-sm-12">
                                <div class="row">
                                    <div class="col-lg h-right col-md-12 col-sm-12">
                                        <h3 class="mb-0">Start Date: {{viewmodel?.projectStateService?.project?.startDate |
                                            date: dateFormat}}</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col h-right">
                                        <h3 class="mb-0">Visible For Field:
                                            {{viewmodel?.projectStateService?.project?.visibleDate | date: dateFormat}}</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col h-right">
                                        <h3 class="mb-0">End Date: {{viewmodel?.projectStateService?.project?.endDate |
                                            date: dateFormat}}</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-12 col-sm-12">
                                <div class="row">
                                    <div class="col-lg col-md-12 col-sm-12 h-right">
                                        <h3 class="mb-0">Store Count:
                                            {{(viewmodel?.projectStateService?.project?.projectCustomers?.length
                                            ? viewmodel.projectStateService.project.projectCustomers.length
                                            : 0) | number }}
                                        </h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col h-right">
                                        <h3 class="mb-0">People:
                                            {{(viewmodel?.projectStateService?.project?.projectEmployees?.length
                                            ? viewmodel.projectStateService?.project?.projectEmployees.length
                                            : 0) | number }}
                                        </h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col h-right">
                                        <h3 class="mb-0">Products:
                                            {{(viewmodel?.projectStateService?.project?.projectProducts?.length
                                            ? viewmodel?.projectStateService?.project?.projectProducts.length
                                            : 0) | number }}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 h-center auto-margin">
                            <div class="h-center">
                                <button appThrottleClick (throttledClick)="viewmodel.cancel()"
                                    mat-raised-button class="btn-white actionBtn" >
                                    Back
                                </button>
                                <ng-container *ngIf="viewmodel.projectTab === viewmodel.projectTabConfiguration">
                                    <button appThrottleClick mat-raised-button class="sw-red-btn actionBtn" (throttledClick)="viewmodel.validate()">
                                        {{ viewmodel.getSaveText() }}
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="viewmodel.projectTab !== viewmodel.projectTabConfiguration" class="horizontal-nav d-flex">
                        <ul class="list-inline list-inline-flex">
                            <li
                                class="list-inline-item-custom"
                                [ngClass]="{ active: viewmodel.projectTab === viewmodel.projectTabSummary }"
                            >
                                <a class="no-underline" [routerLink]="'summary'"
                                    (click)="viewmodel.setTab(viewmodel.projectTabSummary)"
                                >
                                    Summary
                                </a>
                            </li>
                            <li
                                *ngIf="projectStateService?.project?.startDate"
                                class="list-inline-item-custom"
                                [ngClass]="{ active: viewmodel.projectTab === viewmodel.projectTabMetrics }"
                            >
                                <a class="no-underline" [routerLink]="'metrics'"
                                    (click)="viewmodel.setTab(viewmodel.projectTabMetrics)"
                                >
                                    Metrics
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
