import { TransactionLineItemType } from "../lookup";
import { AddressFilterDto } from "./address-filter.dto";
import { ZrtFilterDto } from "./zrt-filter.dto";

export class WholesalerGroupsListFilterDto {

    /*
    * Wholesaler Group Filters
    */

    // Usage: Get data based on wholesaler group name or wholesaler group id (external sync id)
    group?: string[];

    // Usage: Get data based on order type (EDI, Other)
    orderMethod?: string[];

    // Usage: Get data based on wholesaler customer number or wholesaler name
    wholesalerCustomer?: string[];

    // Usage: Get data based on wholesaler address state
    state?: string[];

    // Usage: Get data based on wholesaler product UIN
    productUin?: string[];

    // Usage: Get data based on Swisher item number
    productItemNumber?: string[];

    // Usage: Get data based on Swisher product description
    productDescription?: string[];

    // Usage: Get data based on product price
    productPrice?: number[];
}
