import { NgModule } from "@angular/core";
import { TimeLogComponent } from "../my-day/time-log/time-log.component";
import { AddTimeEntryComponent } from "../my-day/add-time-entry/add-time-entry.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatChip } from "@angular/material/chips";
import { RouteDetailsComponent } from "../my-day/route-management/route-details/route-details.component";
import { RouteManagementComponent } from "../my-day/route-management/route-management.component";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { SharedModule } from "../shared/shared.module";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { MyDayRoutingModule } from "./my-day.routing.module";
import { GratisRefinerService } from "./gratis/gratis-refiner.service";
import { RouteManagementService } from "./route-management/route-management.service";
import { GratisModule } from "./gratis/gratis.module";
import { RouteManagementRefinerService } from "./route-management/route-management-refiner.service";
import { GratisZrtFilterService } from "./gratis/gratis-zrt-filter.service";
import { RouteManagementZrtFilterService } from "./route-management/route-management-zrt-filter.service";
import { TimeLogCalendarHeaderComponent } from "./time-log/time-log-calendar-header/time-log-calendar-header.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { RoutePx3SnapshotComponent } from "./route-management/route-px3-snapshot/route-px3-snapshot.component";
import { DashboardMasterModule } from "../accounts/dashboard-master/dashboard-master.module";

@NgModule({
    declarations: [
        TimeLogComponent,
        AddTimeEntryComponent,
        TimeLogCalendarHeaderComponent,
        RouteManagementComponent,
        RouteDetailsComponent,
        RoutePx3SnapshotComponent
    ],
    imports: [
        SharedModule,
        FontAwesomeModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        MyDayRoutingModule,
        NgxChartsModule,
        GratisModule,
        DashboardMasterModule,
    ],
    providers: [
        MatChip,
        GratisRefinerService,
        RouteManagementService,
        RouteManagementRefinerService,
        GratisZrtFilterService,
        RouteManagementZrtFilterService
    ]
})
export class MyDayModule {}
