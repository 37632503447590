export class ProjectMetricProductDto {
    id: string;
    name: string;
    storesWithOrders: number;
    cashCount: number;
    cashAmount: number;
    orderCount: number;
    orderAmount: number;
    orderTotalSticks: number;
}
