import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    GenericResponseDto,
    newSequentialId,
    GenericRequestDto,
    FilterRequestV2Dto,
    WholesalerGroupsListBatchParamsDto,
    WholesalerGroupsListColumns,
    WholesalerGroupsListFilterDto,
    WholesalerGroupEntryDto
} from "shield.shared";
import { ConverterHelper } from "../converter-services/converter.helper";
import { WholesalerGroupEntry } from "src/app/entity-models/wholesaler-group-entry.entity";
import { WholesalerGroupConverterService } from "../converter-services/wholesaler-group-converter.service";

@Injectable()
export class WholesalerGroupOnlineService {
    constructor(private http: HttpClient) {}

    async getBatch(
        params: WholesalerGroupsListBatchParamsDto
    ): Promise<GenericResponseDto<WholesalerGroupEntry[]>> {
        const request = new GenericRequestDto<
            FilterRequestV2Dto<
                WholesalerGroupsListFilterDto,
                WholesalerGroupsListColumns
            >
        >();
        request.id = newSequentialId();
        request.values = params.filterRequestDto;

        const result = await this.http
            .post<GenericResponseDto<WholesalerGroupEntryDto[]>>(
                "/api/wholesaler-groups",
                request
            )
            .toPromise();

        return ConverterHelper.dtoToEntityArray<
            WholesalerGroupEntryDto,
            WholesalerGroupEntry
        >(result, (v) =>
            WholesalerGroupConverterService.wholesalerGroupEntryDtoToWholesalerGroupEntry(
                v
            )
        );
    }

    async getEntryByWholesalerId(
        wholesalerId: string
    ): Promise<GenericResponseDto<WholesalerGroupEntryDto | null>> {
        const groupEntry = await this.http
            .get<WholesalerGroupEntryDto | null>(
                `/api/wholesaler-groups/entry?wholesaler-id=${wholesalerId}`
            )
            .toPromise();

        const returnDto = new GenericResponseDto<WholesalerGroupEntryDto | null>();
        returnDto.values = groupEntry;
        return returnDto;
    }
}
