import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ChangeOrderDateParamsDto, ChangeUinParamsDto, EmailOrderParamsDto, MoveOrderProductsParamsDto, OrderBatchParamsDto, OrdersByWholesalersParamsDto } from "shield.shared";
import { Customer } from "src/app/entity-models/customer.entity";
import { OrderExtraction } from "src/app/entity-models/order-extraction.entity";
import { Order } from "src/app/entity-models/order.entity";

@Injectable()
export class OrderOfflineService {

    private readonly notAvailableInOfflineModeError = "Orders are not available in offline mode.";

    async getBatch(
        key: OrderBatchParamsDto
    ): Promise<Order[]> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async getOrder(id: string): Promise<Order> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async changeOrderDate(key: ChangeOrderDateParamsDto): Promise<Order> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async changeWholesalerUin(key: ChangeUinParamsDto): Promise<boolean> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async movePendingOrderProductsToNewOrder(params: MoveOrderProductsParamsDto): Promise<Order> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async moveRejectedProductsToNewOrder(params: MoveOrderProductsParamsDto): Promise<Order> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async emailOrder(params: EmailOrderParamsDto): Promise<undefined> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async cancelOrder(id: number): Promise<boolean> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async cancelCallOrders(id: number): Promise<number> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async getWholesalersWithOrders(key: undefined): Promise<Customer[]> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async getAvailableOrdersByWholesaler(params: OrdersByWholesalersParamsDto): Promise<Order[]> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async getExtractionsByWholesaler(params: OrdersByWholesalersParamsDto): Promise<OrderExtraction[]> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async getOrdersByExtraction(extractionId: string): Promise<Order[]> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    extractByWholesaler(wholesalerId: string): Observable<Blob | never> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    extractByParams(params: OrdersByWholesalersParamsDto): Observable<Blob | never> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    getExtractionFile(extractionId: string): Observable<Blob | never> {

        throw Error(this.notAvailableInOfflineModeError);
    }
}
