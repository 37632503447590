export enum AccountsListColumns {
    inRoute = "In Route",
    customerId = "Customer ID",
    lastCall = "Last Call",
    available = "Avail",
    customerName = "Name",
    address = "Address",
    drivingAddress = "Driving Address",
    city = "City",
    county = "County",
    state = "State",
    zip = "Zip",
    zrt = "ZRT",
    indVol = "Ind Vol",
    callsMade = "Calls Made (%s)",
    ssVol = "SS Vol",
    ssPercent = "SS %",
    msa = "MSA",
    phone = "Phone",
    px3Rank = "Px3 Rank",
    chain = "Chain",
    assignedRep = "Assigned Rep",
    ownershipLevel = "Ownership Level",
    storeCount = "Store Count",
    customerType = "Store Type",
}
