import { StateDto } from "shield.shared";
import { State } from "src/app/entity-models/state.entity";

export class StateConverter {

    static stateToStateDto(entity: State): StateDto {

        const dto = new StateDto();

        dto.id = entity.id;
        dto.code = entity.code;
        dto.countryId = entity.countryId;
        dto.name = entity.name;
        dto.rowversion = entity.rowversion;
        dto.shortCode = entity.shortCode;
        dto.stateCategoryId = entity.stateCategoryId;

        return dto;
    }

    static stateDtoToState(dto: StateDto): State {

        const entity = new State();

        entity.id = dto.id;
        entity.code = dto.code;
        entity.countryId = dto.countryId;
        entity.name = dto.name;
        entity.rowversion = dto.rowversion;
        entity.shortCode = dto.shortCode;
        entity.stateCategoryId = dto.stateCategoryId;

        return entity;
    }
}
