import { Employee } from "src/app/entity-models/employee.entity";

export class FinishRouteDialogViewModel {
    supervisorEmployeeOptions: Employee[];
    textRoute: boolean;
    emailRoute: boolean;
    supervisorTextRoute: boolean;
    supervisorEmailRoute: boolean;
    supervisorTextMessage: string;
    supervisorEmployeeId: string;
    routeName: string;
    routeDescription: string;
    onSave: (data: FinishRouteDialogViewModel) => void;
    isEdit?: boolean;
}
