import { CustomerContract } from "src/app/entity-models/customer-contract.entity";

export class RetailAgreementViewModel {
    id: string;
    contractNumber: string;
    level: string;
    dateSigned: string;
    status: string | null;
    customerContract: CustomerContract
}
