import { Injectable } from "@angular/core";
import { CallProductStatus } from "src/app/entity-models/call-product-status.entity";
import { Product } from "src/app/entity-models/product.entity";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { DatabaseService } from "src/app/services/database.service";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { VolumeDistributionProductViewModel } from "./volume-distribution-products-card/volume-distribution-product.viewmodel";
import { CallService } from "../call-master/call-services/call.service";
import { Customer } from "src/app/entity-models/customer.entity";
import { AppStateService } from "src/app/services/app-state.service";
import { take } from "rxjs/operators";
import { Employee } from "src/app/entity-models/employee.entity";

@Injectable({
    providedIn: "root"
})
export class VolumeDistributionService {

    lastCall: RetailCall;
    inDistProducts: VolumeDistributionProductViewModel[];

    constructor(private callDelineationService: CallDelineationService
        , private productDelineationService: ProductDelineationService
        , private callService: CallService) {}

    public async loadForCustomer(customer: Customer, employee: Employee): Promise<void> {
        const callType = this.callService.getCallTypeForCustomerAndEmployee(customer, employee);
        const lastCallResponse = await this.callDelineationService.getLastCallOfTypeByCustomerId(customer.id, callType);
        if (!lastCallResponse) { return; }
        this.lastCall = lastCallResponse.values as RetailCall;

        if (this.lastCall) {
            const inDistProductsResponse = await this.productDelineationService.getByIds(this.lastCall.inDistProductIds);
            if (!inDistProductsResponse) { return; }
            const inDistProducts = inDistProductsResponse.values;

            this.buildInDistProducts(inDistProducts, this.lastCall.callProductStatus);
        }
    }

    buildInDistProducts(products: Product[], statuses: CallProductStatus[]): void {
        const inDistProducts = new Array<VolumeDistributionProductViewModel>();

        for (const product of products) {
            const distProduct = new VolumeDistributionProductViewModel();
            distProduct.productName = product.description;

            if (statuses && statuses.length) {
                const status = statuses.find(v => v.productId === product.id);
                distProduct.intro = status ? status.intro : false;
                distProduct.cos = status ? status.correctiveOffSelf : false;
            }

            inDistProducts.push(distProduct)
        }

        this.inDistProducts = inDistProducts;
    }
}
