import { GenericLookup } from "../generic";

export enum SurveyQuestionTypes {
    CheckBox = 1,
    DateTime = 2,
    Decimal = 3,
    Integer = 4,
    Text = 5,
    DropDown = 6
}

export const SurveyQuestionTypeLookup: GenericLookup<SurveyQuestionTypes>[] = [
    { id: SurveyQuestionTypes.CheckBox, name: "CheckBox", description: "Check Box" },
    { id: SurveyQuestionTypes.DateTime, name: "DateTime", description: "Date/Time" },
    { id: SurveyQuestionTypes.Decimal, name: "Decimal", description: "Decimal" },
    { id: SurveyQuestionTypes.Integer, name: "Integer", description: "Integer" },
    { id: SurveyQuestionTypes.Text, name: "Text", description: "Text" },
    { id: SurveyQuestionTypes.DropDown, name: "DropDown", description: "Drop Down" }
];
