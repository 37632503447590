import { Injectable } from "@angular/core";
import Dexie from "dexie";
import {
    FilterSortDto,
    RouteBatchParamsDto,
    RouteListColumns,
    RouteListFilterDto,
    RouteSyncCommand,
    SharedHelper,
    SortDirection
} from "shield.shared";
import { Call } from "src/app/accounts/call-master/call-services/call.service";
import { Route } from "src/app/entity-models/route.entity";
import { DataSyncQueueService } from "src/app/sync/data-sync-queue.service";
import { DatabaseService } from "../database.service";

@Injectable()
export class RouteOfflineService {
    constructor(
        private dbService: DatabaseService,
        private dataSyncQueueService: DataSyncQueueService
    ) { }

    private async applyFilter(routes: Route[], filter: RouteListFilterDto): Promise<Route[]> {
        if (!filter) return routes;

        if (filter.zrtFilterDto?.zrts) {
            routes = routes.filter((r) => filter.zrtFilterDto.zrts.includes(r.employeeZrt));
        }
        if (filter.zrtFilterDto?.employeeIds) {
            routes = routes.filter((r) => filter.zrtFilterDto.employeeIds.includes(r.employeeId));
        }
        if (filter.routeName) {
            routes = routes.filter((r) => SharedHelper.searchStringArray(r.name, filter.routeName));
        }
        if (filter.routeDescription) {
            routes = routes.filter((r) => SharedHelper.searchStringArray(r.description, filter.routeDescription));
        }
        if (filter.routeOnOrAfterDate) {
            routes = routes.filter((r) => r.date && r.date.getTime() >= new Date(filter.routeOnOrAfterDate).setHours(0, 0, 0, 0));
        }
        if (filter.routeOnOrBeforeDate) {
            routes = routes.filter((r) => r.date && r.date.getTime() <= new Date(filter.routeOnOrBeforeDate).setHours(23, 59, 59, 0));
        }

        return routes;
    }

    private applySorts(routes: Route[], sorts: FilterSortDto<RouteListColumns>[]): void {
        for (const sort of sorts) {

            if (sort.column === RouteListColumns.stopsInRoute) {
                routes.sort((a, b) => a.stops.length - b.stops.length)
            }
            const compares: { [key: string]: (a: Route, b: Route) => number } = {
                [RouteListColumns.zrt]: (a, b) => a.employeeZrt.localeCompare(b.employeeZrt),
                [RouteListColumns.routeName]: (a, b) => a.name.localeCompare(b.name),
                [RouteListColumns.routeDate]: (a, b) => a.date > b.date ? 1 : -1,
                [RouteListColumns.description]: (a, b) => a.description.localeCompare(b.description),
                [RouteListColumns.startAddress]: (a, b) =>
                    a.stops[0].address.address1.localeCompare(b.stops[0].address.address1),
                [RouteListColumns.endAddress]: (a, b) =>
                    a.stops[a.stops.length - 1].address.address1
                        .localeCompare(b.stops[b.stops.length - 1].address.address1),
                [RouteListColumns.stopsInRoute]: (a, b) => a.stops.length - b.stops.length,
                [RouteListColumns.completedStopsInRoute]: (a, b) => a.completedStops - b.completedStops,
            }

            const order = sort.direction === SortDirection.ascending ? 1 : -1;

            routes.sort((a, b) => compares[sort.column](a, b) * order);
        }
    }

    async getById(id: string): Promise<Route> {
        return await this.dbService.routes.where("id").equals(id).first();
    }

    async getUnprocessedById(id: string): Promise<Route> {
        return await this.dbService.routes.where("id").equals(id).and(v => !v.hasServerProcessed).first();
    }



    async updateRouteByCall(call: Call): Promise<void> {
        const startDate = new Date(call.stopTime);
        const endDate = new Date(call.stopTime);
        startDate.setUTCHours(0, 0, 0, 0);
        endDate.setUTCHours(23, 59, 59, 0);
        let route = await this.dbService.routes.where('date').between(startDate, endDate, true, true)
            .filter((route: Route) =>
            (
                route.employeeId == call.createdUserId
            )
            ).first();

        if (!route) return;
        let stop = route.stops.find(s => s.customerId == call.customerId);

        if (!stop) return;
        stop.isCompleted = true;

        route.modifiedUtcDateTime = new Date();
        route.hasServerProcessed = 0;
        route.completedStops = route.stops.filter(stop => stop.isCompleted == true).length;

        await this.dbService.routes.put(route);
    }

    async getFutureByEmployeeId(employeeId: string): Promise<Route[]> {
        const midnightToday = new Date();
        midnightToday.setHours(0, 0, 0, 0);
        const routes = await this.dbService.routes
            .where("[employeeId+date]")
            .between([employeeId, midnightToday], [employeeId, Dexie.maxKey])
            .toArray();
        return routes;
    }

    async getUnprocessedFutureByEmployeeId(employeeId: string): Promise<Route[]> {
        const newDate = new Date().setHours(0, 0, 0, 0);
        const routes = await this.dbService.routes
            .where({
                employeeId: employeeId,
                hasServerProcessed: 0,
            })
            .and(
                (r) =>
                    r.date.setHours(0, 0, 0, 0) >=
                    newDate
            )
            .toArray();

        routes.sort((a, b) => a.date.getTime() - b.date.getTime());
        return routes;
    }

    async editRoute(): Promise<undefined> {
        throw Error("Cannot edit a route while offline.");
    }

    async getBatch(
        params: RouteBatchParamsDto
    ): Promise<Route[]> {
        let routesQuery = await this.dbService.routes
            .where("employeeId").equals(params.employeeId);
        if (params.employeeZrt) {
            routesQuery = routesQuery.or("employeeZrt").startsWith(params.employeeZrt)
        }
        let routes = await routesQuery.toArray();

        routes = await this.applyFilter(routes, params.filterRequestDto.filters);
        this.applySorts(routes, params.filterRequestDto.filterSorts);
        return routes;
    }

    async getUnprocessedBatch(
        params: RouteBatchParamsDto
    ): Promise<Route[]> {
        let routesQuery = await this.dbService.routes
            .where("employeeId").equals(params.employeeId);
        if (params.employeeZrt) {
            routesQuery = routesQuery.or("employeeZrt").startsWith(params.employeeZrt);
        }
            routesQuery = routesQuery.filter(r => !r.hasServerProcessed);
            let routes = await routesQuery.toArray();

        routes = await this.applyFilter(routes, params.filterRequestDto.filters);
        this.applySorts(routes, params.filterRequestDto.filterSorts);
        return routes;
    }

    async delete(id: string): Promise<undefined> {
        throw Error("You must be online to delete a route.");
    }

    async saveRoute(route: Route): Promise<Route> {
        route.hasServerProcessed = 0;
        await this.dbService.routes.put(route);

        await this.dataSyncQueueService.enqueue(
            new RouteSyncCommand(route.id)
        );

        return route;
    }
}
