import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { DistributionActionItems } from "../../accounts/call-master/stepper-call/stepper-call-enums/distribution-action-items";
import { CallDistributionViewModel } from "../../accounts/call-master/call-viewmodels/call-distribution.viewmodel";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { DistributionGridComponent } from "src/app/accounts/call-master/stepper-call/distribution-grid/distribution-grid.component";
import { CallTypes } from "shield.shared";

@Component({
    selector: "app-distribution-dialog",
    templateUrl: "./distribution-dialog.component.html",
    styleUrls: ["./distribution-dialog.component.scss"]
})
export class DistributionDialogComponent implements OnInit {
    @ViewChild("gridComponent") grid: DistributionGridComponent;

    mode: DistributionActionItems;
    isOrderMode: boolean;
    callType: CallTypes;

    constructor(
        private ref: SwisherOverlayRef<
            CallDistributionViewModel,
            DistributionDialogComponent
        >
    ) {}

    ngOnInit(): void {
        this.mode = this.ref.data.mode;
        this.isOrderMode = this.ref.data.isOrderMode;
    }

    @HostListener('window:keyup', ['$event'])
      onKeyUp(event: KeyboardEvent): void {
          if (event.key === 'Enter' && this.ref) {
              this.close()
          }
      }

    close(): void {
        this.ref.close();
    }
}
