import { Injectable } from "@angular/core";
import { GenericResponseDto, TimeEntryTypeNames } from "shield.shared";
import { TimeEntryType } from "src/app/entity-models/time-entry-type.entity";
import { DatabaseService } from "../database.service";
import { TimeEntryTypeOfflineService } from "../offline-services/time-entry-type-offline.service";
import { TimeEntryTypeOnlineService } from "../online-services/time-entry-type-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class TimeEntryTypeDelineationService extends DelineationContext<TimeEntryType, string> {

    constructor(private timeEntryTypeOfflineService: TimeEntryTypeOfflineService
        , private timeEntryTypeOnlineService: TimeEntryTypeOnlineService
        , snackbarService: SnackbarService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService) {
            super(dbService, datasourceDelineationService, snackbarService);
        }

        async getTimeEntryTypeByName(name: TimeEntryTypeNames): Promise<GenericResponseDto<TimeEntryType>> {

            const offline = (key: TimeEntryTypeNames) => {
                return this.timeEntryTypeOfflineService.getTimeEntryTypeByName(key);
            }
            const online = (key: TimeEntryTypeNames) => {
                return this.timeEntryTypeOnlineService.getTimeEntryTypeByName(key);
            }
            const response = await this.datasourceDelineationService.makeCall<TimeEntryTypeNames, TimeEntryType>(name, offline, online);


            if (response?.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
        }
}
