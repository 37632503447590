<div [formGroup]="formGroup">
    <div class="row">
        <div class="col">
            <mat-form-field appearance="outline" color="accent">
                <mat-label id="name">Name*</mat-label>
                <input formControlName="nameControl" maxLength="50" matInput />
                <mat-hint align="end">{{
                    viewmodel.name?.length ||
                    0
                    }}/50</mat-hint>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="outline" color="accent">
                <mat-label id="phone-number">Phone Number</mat-label>
                <input type="text" formControlName="phoneControl"
                    placeholder="Ex. (555) 555-5555" [mask]="'(000) 000-0000'" matInput />
                <mat-error *ngIf="formGroup.controls.phoneControl?.hasError('pattern')">
                    Please enter a valid 10 digit phone number!
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Store Type*</mat-label>
                <mat-select formControlName="selectedStoreTypeControl">
                    <mat-option *ngFor="let type of viewmodel.customerTypes" [value]="type">
                        {{type.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field appearance="outline" color="accent">
                <mat-label id="Address1">Address1*</mat-label>
                <input formControlName="address1Control" maxLength="100" matInput />
                <mat-hint align="end">{{
                    viewmodel.address1?.length ||
                    0
                    }}/100</mat-hint>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="outline" color="accent">
                <mat-label id="Address2">Address2</mat-label>
                <input [(ngModel)]="viewmodel.address2" [ngModelOptions]="{standalone: true}" maxLength="100"
                    matInput />
                <mat-hint align="end">{{
                    viewmodel.address2?.length ||
                    0
                    }}/100</mat-hint>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="outline" color="accent">
                <mat-label id="City">City*</mat-label>
                <input formControlName="cityControl" maxLength="100" matInput />
                <mat-hint align="end">{{
                    viewmodel.city?.length ||
                    0
                    }}/100</mat-hint>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>State*</mat-label>
                <mat-select formControlName="selectedStateControl">
                    <mat-option *ngFor="let state of viewmodel.states" [value]="state.shortCode">
                        {{state.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="outline" color="accent">
                <mat-label id="zip">Zip*</mat-label>
                <input formControlName="zipControl" maxLength="9" digitOnly matInput />
                <mat-hint align="end">{{
                    viewmodel.zip?.length ||
                    0
                    }}/9</mat-hint>
            </mat-form-field>
        </div>
        <div class="col vertical-center">
            <button mat-raised-button class="sw-red-btn actionBtn" (click)="clear()">
                Clear Fields
            </button>
        </div>
    </div>
</div>
