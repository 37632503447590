import { GenericLookup, SurveyTypes } from "shield.shared";
import { SurveyQuestion } from "./survey-question.entity";

export class Survey {
    id: string;
    title: string;
    description: string | null
    isActive: boolean
    surveyType: GenericLookup<SurveyTypes>
    startDate: string | null;
    endDate: string | null;
    createdUtcDateTime: Date;
    createdUserId: string;
    createdUserZrt: string;
    createdUserFullName: string;
    modifiedUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    rowversion: string;
    surveyQuestions: SurveyQuestion[];
}
