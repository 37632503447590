import { ElementRef } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidatorFn
} from "@angular/forms";
import {
    MatDrawerMode,
    MatSidenav,
    MatSidenavContent
} from "@angular/material/sidenav";
import { MatSortHeader } from "@angular/material/sort";
import * as moment from "moment";
import { Moment } from "moment";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { BehaviorSubject, Subscription } from "rxjs";
import { filter, map, skip, skipWhile, switchMap, take, tap } from "rxjs/operators";
import {
    FilterSortDto,
    GenericDropDownDto,
    RefinerLocation,
    SharedHelper,
    SortDirection,
    Subsidiary,
    TransactionLineItemType,
    TransactionsListColumns,
    newSequentialId
} from "shield.shared";
import { CallEventLoggerService } from "src/app/accounts/call-master/call-services/call-event-logger.service";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { FilterLocation } from "src/app/enums/filter-location";
import { MyListCommands } from "src/app/enums/my-list-commands";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ActivitiesFilterService } from "src/app/services/activities-filter.service";
import { AppStateService } from "src/app/services/app-state.service";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { NotificationDelineationService } from "src/app/services/delineation-services/notification-delineation.service";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { ReceiptDelineationService } from "src/app/services/delineation-services/receipt-delineation.service";
import { StateLicenseDelineationService } from "src/app/services/delineation-services/state-license-delineation.service";
import { TransactionDelineationService } from "src/app/services/delineation-services/transaction-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { GridDataTypes } from "src/app/shared/enums/grid-data-types.enum";
import { CallActivitiesFilterComponent } from "src/app/shared/filters/call-activities-filter/call-activities-filter.component";
import { CallAttributesFilterComponent } from "src/app/shared/filters/call-attributes-filter/call-attributes-filter.component";
import { CallDateInformationFilterComponent } from "src/app/shared/filters/call-date-information-filter/call-date-information-filter.component";
import { CustomerLocationFilterComponent } from "src/app/shared/filters/customer-location-filter/customer-location-filter.component";
import { MySearchesFilterComponent } from "src/app/shared/filters/my-searches/my-searches-filter.component";
import { TransactionsFilterComponent } from "src/app/shared/filters/transactions-filter/transactions-filter.component";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { ColumnSelector } from "src/app/shared/viewmodels/column-selector.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { InvoiceDetailsComponent } from "../invoice-details/invoice-details.component";
import { InvoiceDetailsViewmodel } from "../invoice-details/invoice-details.viewmodel";
import { TransactionListZrtFilterService } from "./transaction-list-zrt-filter.service";
import { TransactionsListGridViewmodel } from "./transactions-list-grid.viewmodel";
import { TransactionsListRefinerService } from "./transactions-list-refiner.service";

export class TransactionsListViewmodel {
    //private vars
    private formBuilder: FormBuilder;

    //public vars
    accountInput: string;
    accountOwnershipDelineationService: AccountOwnershipDelineationService;
    activeRefiners: Refiner[] = [];
    activeSorts: FilterSortDto<TransactionsListColumns>[] = [];
    activitiesFilterService: ActivitiesFilterService;
    availableColumns: ColumnSelector[];
    baseGridYOffset = 0;
    transactionDelineationService: TransactionDelineationService;
    cityInput: string;
    columnDef: ColumnDef[];
    columnsToDisplay: string[];
    confirmationOverlayRef: SwisherOverlayRef<ConfirmationDialogViewmodel, ConfirmationDialogComponent>;
    currentRefinerMap: Map<RefinerLocation, string> = new Map();
    customerDelineationService: CustomerDelineationService;
    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    dateForm: FormGroup;
    dateFormat: string = MY_DATE_FORMATS.display.dateInput;
    detailHeight = 10;
    drawer: MatSidenav;
    drawerMode: MatDrawerMode = "side";
    endDateDefault: moment.Moment = moment();
    endDateInput: ElementRef;
    endMaxDate: moment.Moment = moment().add(3, "years");
    endMinDate: moment.Moment = moment().subtract(3, "years");
    employee: Employee;
    employeeDelineationService: EmployeeDelineationService;
    filterDataLoaded = false;
    filterLocation = FilterLocation.transactionList;
    filters: FilterAndParams[] = [];
    filterService: FilterService;
    grid: GridComponent;
    gridData = new Array<GridData>();
    gridDataChangeSubscription: Subscription;
    gridHeight = "75vh";
    gridhieghtOffsetDeduction = 0;
    header: PageHeaderComponent;
    headerButtons: HeaderButtonComponent[] = new Array<HeaderButtonComponent>();
    headerName = "Transactions List";
    invoiceDetailsOverlayRef: SwisherOverlayRef<InvoiceDetailsViewmodel, InvoiceDetailsComponent>;
    isFixedLayout = false;
    isEnabledExpandedDetails = true;
    isGapSet = false;
    isSaveDisabled = true;
    isSearchButtonDisabled = false;
    itemsRendedInViewPort = 13;
    itemSize = 48;
    myListSubscription: Subscription;
    overlayService: OverlayService;
    pageIndex = 0;
    pageSize = 50; // buffer size of the grid. We probally need to make the min and output buffers of the grid virtual scroll an input.
    pleaseWaitService: PleaseWaitService;
    previousRefinerMap: Map<RefinerLocation, string> = new Map();
    productDelineationService: ProductDelineationService;
    projectDelineationService: ProjectDelineationService;
    receiptInput: string;
    receiptDelineationService: ReceiptDelineationService;
    refinerInputChangeSubscription: Subscription;
    refinerService: TransactionsListRefinerService;
    refinerServiceSubscription: Subscription;
    screenHeight: number = 0;
    shouldResetGrid = false;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    sideNavContent: MatSidenavContent;
    snackbar: SnackbarService;
    startDateDefault: moment.Moment = moment().subtract(7, "days");
    startDateInput: ElementRef;
    startMaxDate: moment.Moment = moment().add(3, "years");
    startMinDate: moment.Moment = moment().subtract(3, "years");
    streetInput: string;
    topGapDistance = 0;
    total?: number;
    transactionTypes = TransactionLineItemType;
    uinInput: string;
    stateLicenseDelineationService: StateLicenseDelineationService
    zipInput: string;
    zrtFilterService: TransactionListZrtFilterService;
    zrtSelectionSubscription: Subscription;
    notificationDelineationService: NotificationDelineationService;

    readonly sortFunction = (columnDef: ColumnDef) => {
        if (this.grid) {
            const filterSorts = new Array<FilterSortDto<TransactionsListColumns>>();
            this.grid.sort.sortables.forEach((item) => {
                let sortDirection: SortDirection;
                switch ((item as MatSortHeader)._sort.direction) {
                    case "asc":
                        sortDirection = SortDirection.ascending;
                        break;
                    case "desc":
                        sortDirection = SortDirection.descending;
                        break;
                    default:
                        sortDirection = SortDirection.none;
                        break;
                }
                const sortDto = new FilterSortDto<TransactionsListColumns>();
                sortDto.direction = sortDirection;
                const headerName = this.columnDef.find(
                    (cd) => cd.dataPropertyName === item.id
                ).headerName;
                if (
                    headerName === columnDef.headerName &&
                    sortDirection != SortDirection.none
                ) {
                    sortDto.column = headerName as TransactionsListColumns;
                    filterSorts.push(sortDto);
                }
            });
            this.activeSorts = filterSorts;
            void this.getCallBatch(0, true);
        }
    };

    constructor(
        refinerService: TransactionsListRefinerService,
        overlayService: OverlayService,
        transactionDelineationService: TransactionDelineationService,
        formBuilder: FormBuilder,
        productDelineationService: ProductDelineationService,
        customerDelineationService: CustomerDelineationService,
        snackbar: SnackbarService,
        receiptDelineationService: ReceiptDelineationService,
        pleaseWaitService: PleaseWaitService,
        activitiesFilterService: ActivitiesFilterService,
        appStateService: AppStateService,
        employeeDelineationService: EmployeeDelineationService,
        projectDelineationService: ProjectDelineationService,
        stateLicenseDelineationService: StateLicenseDelineationService,
        zrtFilterService: TransactionListZrtFilterService,
        accountOwnsershipDelineationService: AccountOwnershipDelineationService,
        filterService: FilterService,
        notificationDelineationService: NotificationDelineationService,
        private callService: CallDelineationService,
        private eventLogger: CallEventLoggerService,
    ) {
        this.refinerService = refinerService;
        this.overlayService = overlayService;
        this.transactionDelineationService = transactionDelineationService;
        this.productDelineationService = productDelineationService;
        this.customerDelineationService = customerDelineationService;
        this.snackbar = snackbar;
        this.receiptDelineationService = receiptDelineationService;
        this.pleaseWaitService = pleaseWaitService;
        this.activitiesFilterService = activitiesFilterService;
        this.employeeDelineationService = employeeDelineationService;
        this.projectDelineationService = projectDelineationService;
        this.stateLicenseDelineationService = stateLicenseDelineationService;
        this.zrtFilterService = zrtFilterService;
        this.accountOwnershipDelineationService = accountOwnsershipDelineationService;
        this.filterService = filterService;
        this.notificationDelineationService = notificationDelineationService;

        this.formBuilder = formBuilder;
        this.dateForm = this.formBuilder.group({
            endDate: ["", [this.lessThanStartDate()]],
            startDate: ["", [this.greaterThanEndDate()]]
        });

        appStateService.currentEmployee
            .pipe(
                filter(e => !!e), 
                take(1),
                tap(employee => {
                    this.employee = employee;
                }),
                switchMap(_ => this.setFilterData()),
            )
            .subscribe(() => this.getCallBatch(0));
    }

    //events
    onButtonAction(value: any): void {
        switch (value) {
            default:
                break;
        }
    }

    onButtonClickEvent(value: HeaderButtonComponent) {
        if (value.onClick) {
            value.onClick();
        }
    }

    onAddAccountRefiner(event?: KeyboardEvent): void {
        if (this.accountInput
            && this.refinerService.getRefinerByLocation(RefinerLocation.account)?.value !== this.accountInput) {
            this.refinerService.onInputChange(
                RefinerLocation.account,
                this.accountInput
            );
        }

        if (this.accountInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.account,
                this.accountInput,
                "name"
            );
        }

        if (!this.accountInput || this.accountInput === "") {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.account
            )
        }
    }

    onAddEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.endDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, this.dateFormat) &&
            !this.dateForm.controls.endDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.callOnOrBeforeDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.callOnOrBeforeDate,
                this.endDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.endDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.callOnOrBeforeDate,
                    this.dateForm.controls.endDate.value?.format(
                        this.dateFormat
                    ),
                    "callOnOrBeforeDate"
                );
            }
        } else {
            if (!date && event?.type === "blur") {
                this.refinerService.removeRefinerByLocation(
                    RefinerLocation.callOnOrBeforeDate,
                    true,
                    false
                );
                this.dateForm.controls.endDate.setValue(null);
            }
        }
    }

    onAddStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.startDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, this.dateFormat) &&
            !this.dateForm.controls.startDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.callOnOrAfterDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.callOnOrAfterDate,
                this.startDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.startDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.callOnOrAfterDate,
                    this.dateForm.controls.startDate.value?.format(
                        this.dateFormat
                    ),
                    "lastCallStartDate"
                );
            }
        } else {
            if (!date && event?.type === "blur") {
                this.refinerService.removeRefinerByLocation(
                    RefinerLocation.callOnOrAfterDate,
                    true,
                    false
                );
                this.dateForm.controls.startDate.setValue(null);
            }
        }
    }

    onAddStreetRefiner(): void {
        if (this.streetInput) {
            this.refinerService.addRefiner(
                RefinerLocation.street,
                this.streetInput,
                "businessAddress.address1"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.street
            )
        }
    }

    onAddCityRefiner(): void {
        if (this.cityInput) {
            this.refinerService.addRefiner(
                RefinerLocation.city,
                this.cityInput,
                "businessAddress.city"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.city
            )
        }
    }

    onAddZipRefiner(): void {
        if (this.zipInput) {
            this.refinerService.addRefiner(
                RefinerLocation.zipCodes,
                this.zipInput,
                "businessAddress.zip"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.zipCodes
            )
        }
    }

    onAddReceiptRefiner(): void {
        if (this.receiptInput && this.receiptInput !== "") {
            this.refinerService.addRefiner(
                RefinerLocation.receipt,
                this.receiptInput,
                "receipt"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.receipt
            )
        }
    }

    onAddUinRefiner(): void {
        if (this.uinInput && this.uinInput !== "") {
            this.refinerService.addRefiner(
                RefinerLocation.uin,
                this.uinInput,
                "uin"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.uin
            )
        }
    }

    onOpenInvoiceDetails(): (event: MouseEvent, index: number) => void {
        return async (event: MouseEvent, index: number) => {
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

            const myData = this.gridData[index].data as TransactionsListGridViewmodel;
            const expectedId = myData.callId;
            const transactionResponse = await this.transactionDelineationService.getById(expectedId);
            if (!transactionResponse || !transactionResponse.values) {
                this.shouldWait$.next(false);
                return;
            }

            const customerResponse = await this.customerDelineationService.getById(
                myData.customerId
            );

            if (!customerResponse || !customerResponse.values) {
                this.shouldWait$.next(false);
                return;
            }

            const data = new InvoiceDetailsViewmodel(
                this.productDelineationService,
                this.overlayService,
                this.formBuilder,
                this.customerDelineationService,
                this.snackbar,
                this.transactionDelineationService,
                this.receiptDelineationService,
                this.employee,
                transactionResponse.values,
                customerResponse.values,
                this.notificationDelineationService,
                this.eventLogger,
                this.callService,
            );

            data.isReadOnly = !!transactionResponse.values.gratisId
                || !Helper.isEmployeeAboveTmRole(this.employee)
                || data.isOnlyOrderLineItems;

            if (data.isReadOnly) {
                data.buttonLeftText = "Close";
                data.buttonRightText = null;
            }

            let headerRightText: string = null;
            const transaction = transactionResponse.values;

            if (transaction.modifiedUserFullName && transaction.modifiedUtcDateTime
                && transaction.createdUserFullName && transaction.createdUtcDateTime &&
                (transaction.createdUtcDateTime.setSeconds(0, 0) !==
                    transaction.modifiedUtcDateTime.setSeconds(0, 0))
            ) {
                headerRightText =
                    `Last Updated By: ${Helper.formatDisplayName(transaction)}`;
            }
            data.headerRightText = headerRightText;
            const response = await this.stateLicenseDelineationService.getByShortCode(myData.customerState);
            if (!response) {
                this.shouldWait$.next(false);
                return;
            }

            const swisherLicenses = response.values;

            data.swisherLicenseNumber = "";
            switch (this.employee.subsidiaryId) {
                case Subsidiary.Swisher:
                case Subsidiary.Rogue:
                    data.swisherLicenseNumber = (swisherLicenses.find((sl) => sl.subsidiaryId = Subsidiary.Swisher))?.number ?? "";
                    break;
                case Subsidiary.Swisher:
                    data.swisherLicenseNumber = (swisherLicenses.find((sl) => sl.subsidiaryId = Subsidiary.EAS))?.number ?? "";
                default:
                    break;
            }


            data.receiptNumber = myData.retailReceiptNumber;

            this.invoiceDetailsOverlayRef = this.overlayService.open(
                InvoiceDetailsComponent,
                data,
                true
            );

            data.overlayRef = this.invoiceDetailsOverlayRef;

            this.invoiceDetailsOverlayRef.afterClosed$.subscribe((response) => {
                if (response?.data?.isConfirmed) {
                    this.getCallBatch(0, true);
                }
            });

            this.shouldWait$.next(false);
        };
    }

    onRefinersChange() {
        this.setRefinerInput();

        if (this.header) {
            if (this.refinerService.refiners.length === 0) {
                this.header.expanded = false;
            } else {
                this.header.expanded = true;
            }
        }

        // Search on removal
        const shouldAddInputs = false;
        this.search(shouldAddInputs);
    }

    //public methods
    calculateGap(): void {
        if (this.sideNavContent) {
            setTimeout(() => {
                this.topGapDistance =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.top ?? 0;
                this.gridhieghtOffsetDeduction =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.bottom ??
                    0;
                if (
                    this.baseGridYOffset === 0 &&
                    this.gridhieghtOffsetDeduction !== 0
                ) {
                    this.baseGridYOffset = this.gridhieghtOffsetDeduction;
                }
                this.gridHeight =
                    75 * (this.screenHeight / 100) +
                    (this.baseGridYOffset - this.gridhieghtOffsetDeduction) +
                    "px";
            }, 0);
            this.isGapSet = true;
        }
    }

    checkSearchError(): void {
        if (this.dateForm) {
            if (
                this.dateForm
                    .get("endDate")
                    .hasError("dateLessThanStartDate") ||
                this.dateForm
                    .get("startDate")
                    .hasError("dateGreaterThanEndDate")
            ) {
                this.isSearchButtonDisabled = true;
            } else {
                this.isSearchButtonDisabled = false;
            }
        }
    }

    reset(): void {
        this.refinerService.resetRefiners();
    }

    async getCallBatch(
        index: number,
        force?: boolean
    ): Promise<void> {
        if (!this.employee || !this.refinerService.areDefaultsSet) return;

        const areRefinersTheSame = this.resolvePageIndex(index);

        if (
            this.total != this.gridData?.length ||
            !areRefinersTheSame ||
            this.shouldResetGrid ||
            force
        ) {
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

            setTimeout(async () => { // wait one angular cycle to ensure myFilters have reset.
                const id = newSequentialId();

                const results = await this.transactionDelineationService.getTransactionBatch(
                    id,
                    this.refinerService.refiners,
                    force ? 0 : this.pageSize,
                    this.pageIndex * this.pageSize,
                    this.activeSorts
                );

                if (results) {

                    this.total = results.count;
                    this.isGapSet = false;

                    let gridData: GridData[] = this.gridData.slice();
                    if (this.pageIndex === 0) {
                        gridData = new Array<GridData>();
                    }
                    const largestIndex = gridData.length;

                    for (const call of results?.values ?? []) {
                        const gridItem: GridData = {
                            data: new TransactionsListGridViewmodel(call),
                            detailArrayName: "transactionLineItems",
                            isExpanded: this.grid?.areAllRowsExpanded ?? false,
                            index: largestIndex + results.values.indexOf(call)
                        };
                        gridData.push(gridItem);
                    }
                    this.gridData = gridData;

                    this.dataSource = new TableVirtualScrollDataSource(
                        this.gridData
                    );
                    this.shouldResetGrid = false;
                }
                else {
                    this.shouldResetGrid = true;
                    this.shouldWait$.next(true);
                }
            }, 0);
        }
    }

    greaterThanEndDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            const momentStart: Moment = control.value as Moment;

            if (momentStart) {
                const startDate = momentStart.startOf("day").valueOf();
                this.endMinDate = momentStart;

                if (this.dateForm.controls.endDate.value) {
                    const endDate = (this.dateForm.controls.endDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (startDate > endDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.endMinDate = null;
            }

            this.checkSearchError();

            return forbidden ? { dateGreaterThanEndDate: true } : null;
        };
    }

    headerExpansionChanged(): void {
        this.isGapSet = false;
    }

    async initialize(
        grid: GridComponent,
        header: PageHeaderComponent,
        sideNavContent: MatSidenavContent,
        drawer: MatSidenav,
        startDateInput: ElementRef,
        endDateInput: ElementRef
    ): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(
            this.shouldWait$
        );

        this.grid = grid;
        this.header = header;
        this.sideNavContent = sideNavContent;
        this.drawer = drawer;
        this.startDateInput = startDateInput;
        this.endDateInput = endDateInput;

        this.columnDef = [
            {
                headerName: "Call Id",
                dataPropertyName: "callId",
                isAvailable: false,
                isSelected: false
            },
            {
                headerName: TransactionsListColumns.customerId,
                dataPropertyName: "retailNumber",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: TransactionsListColumns.callDate,
                dataPropertyName: "callDate",
                isAvailable: true,
                isSelected: true,
                clickFunction: this.onOpenInvoiceDetails()
            },
            {
                headerName: TransactionsListColumns.storeInformation,
                dataPropertyName: "storeInformation",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: TransactionsListColumns.createdBy,
                dataPropertyName: "createdBy",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: TransactionsListColumns.receiptNumber,
                dataPropertyName: "retailReceiptNumber",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: TransactionsListColumns.salesTotal,
                dataPropertyName: "salesTotal",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.currency
            },
            {
                headerName: TransactionsListColumns.netTotal,
                dataPropertyName: "netTotal",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.currency
            },
            {
                headerName: TransactionsListColumns.gratisTotal,
                dataPropertyName: "gratisTotal",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.currency
            },
            {
                headerName: TransactionsListColumns.gratisPercent,
                dataPropertyName: "gratisPercentage",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.percent
            },
            {
                headerName: TransactionsListColumns.gratisRequestNumber,
                dataPropertyName: "gratisNumber",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: "Transactionlineitems",
                dataPropertyName: "transactionLineItems",
                isAvailable: false,
                isSelected: true
            }
        ];
        const tempFilters: FilterAndParams[] = [];

        tempFilters.push({ filter: MySearchesFilterComponent });
        tempFilters.push({ filter: CustomerLocationFilterComponent, zrtFilterService: this.zrtFilterService });
        tempFilters.push({ filter: CallDateInformationFilterComponent });
        tempFilters.push({ filter: TransactionsFilterComponent });
        tempFilters.push({ filter: CallActivitiesFilterComponent });
        tempFilters.push({ filter: CallAttributesFilterComponent });

        this.filters = tempFilters;

        const availableColumns = new Array<ColumnSelector>();

        this.columnsToDisplay = this.columnDef
            .filter((cd) => {
                if (cd.isAvailable) {
                    const column: ColumnSelector = {
                        name: cd.headerName,
                        isSelected: cd.isSelected,
                        columns: null
                    };
                    availableColumns.push(column);
                }
            })
            .map((cd) => cd.headerName);
        this.availableColumns = availableColumns;

        if (this.refinerService.areDefaultsSet) {
            this.populateInputsFromRefiners();
        }

        if (
            !this.refinerServiceSubscription ||
            this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription = this.refinerService.refiners$.pipe(
                skipWhile(() => !this.refinerService.areDefaultsSet && !this.filterDataLoaded)
            ).subscribe(
                () => {
                    this.onRefinersChange();
                }
            );
        }

        if (
            !this.refinerInputChangeSubscription ||
            this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription = this.refinerService.refinerInputChange$.subscribe(
                (refiner) => {
                    if (refiner) {
                        this.setInputFromRefiner(refiner);

                        if (refiner.shouldSearchWhenCleared && !refiner.value) {
                            this.getCallBatch(0);
                        }
                    }

                    if (this.header) {
                        if (this.refinerService.refiners.length === 0) {
                            this.header.expanded = false;
                        } else {
                            this.header.expanded = true;
                        }
                    }
                    this.isGapSet = false;
                }
            );
        }

        if (
            !this.gridDataChangeSubscription ||
            this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription = this.grid.dataSourceChange.subscribe(() => this.shouldWait$.next(true));
        }

        if (
            !this.zrtSelectionSubscription ||
            this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription = this.zrtFilterService.observableSelectedZrtsHandleRefiners(this.refinerService).subscribe();
        }

        if (!this.myListSubscription ||
            this.myListSubscription.closed
        ) {
            this.myListSubscription = this.filterService.myListCommandObservable.pipe(skip(1)).subscribe(async (command) => {
                switch (command) {
                    case MyListCommands.toggleOn:
                    case MyListCommands.toggleOff:
                        this.getCallBatch(0, true);
                        break;
                    case MyListCommands.push:
                        this.getMyListIds();
                        break;
                    default:
                        break;

                }
            });
        }
    }

    lessThanStartDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            if (control.value) {
                const endDate = (control.value as Moment)
                    .startOf("day")
                    .valueOf();
                this.startMaxDate = control.value;

                if (this.dateForm.controls.startDate.value) {
                    const startDate = (this.dateForm.controls.startDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (endDate < startDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.startMaxDate = moment();
            }

            this.checkSearchError();

            return forbidden ? { dateLessThanStartDate: true } : null;
        };
    }

    populateInputsFromRefiners(): void {
        for (const refiner of this.refinerService.refiners) {
            this.setInputFromRefiner(refiner);
        }
    }

    resolvePageIndex(index: number): boolean {
        this.currentRefinerMap = new Map<RefinerLocation, string>();
        for (const refiner of this.refinerService.refiners ?? []) {
            this.currentRefinerMap.set(refiner.location, refiner.dataValue ?? refiner.value);
        }

        const rtn = SharedHelper.compareMaps(
            this.currentRefinerMap,
            this.previousRefinerMap
        );

        if (rtn && this.total != this.gridData?.length && index !== 0) {
            this.pageIndex++;
        } else {
            this.previousRefinerMap = new Map(this.currentRefinerMap);
            this.pageIndex = 0;
        }
        return rtn;
    }

    search(shouldAddInputs: boolean = true) {
        if (shouldAddInputs) {
            this.onAddAccountRefiner();
            this.onAddStreetRefiner();
            this.onAddCityRefiner();
            this.onAddZipRefiner();
            this.onAddStartDateRefiner();
            this.onAddEndDateRefiner();
            this.onAddReceiptRefiner();
            this.onAddUinRefiner();
        }
        this.activeRefiners = this.refinerService.refiners.slice();

        this.getCallBatch(this.pageIndex);
    }

    async setActivitiesFilter(): Promise<void> {
        const projectResponse = await this.projectDelineationService.getDropDown();
        if (!projectResponse) {
            this.shouldWait$.next(false);
            return;
        }

        const projects = projectResponse.values;
        if (projects?.length) {
            projects.sort((a, b) => a.name.localeCompare(b.name));
        }
        this.activitiesFilterService.projects = projects ?? new Array<GenericDropDownDto>();

        const wholesalersResponse = await this.customerDelineationService.getWholesalersWithGroupProducts();
        if (!wholesalersResponse) {
            this.shouldWait$.next(false);
            return;
        }

        const wholesalers = wholesalersResponse.values;
        if (wholesalers?.length) {
            wholesalers.sort((a, b) => a.name.localeCompare(b.name));
        }

        this.activitiesFilterService.wholesalers = wholesalers ?? new Array<Customer>();

        const response = await this.accountOwnershipDelineationService.getAllInDropDown();

        if (response && response.values) {
            this.activitiesFilterService.chains = response.values.sort((a, b) => a.name.localeCompare(b.name));
        }
    }

    setDefaultFilter(): void {
        if (!(this.zrtFilterService.employeeZrts?.length) || !this.employee) {
            return;
        }

        if (!this.refinerService.areDefaultsSet) {
            this.dateForm.controls.startDate.setValue(this.startDateDefault);
            this.onAddStartDateRefiner();

            this.dateForm.controls.endDate.setValue(this.endDateDefault);
            this.onAddEndDateRefiner();

            const refiner = this.zrtFilterService.createDefaultZrtRefiner(this.employee, false);
            const isCorporateUser = Helper.isEmployeeCustomerServiceOrAdmin(this.employee);
            if (refiner) {
                this.refinerService.checkAndUpdateRefiner(refiner, true);
                if (isCorporateUser) {
                    this.zrtFilterService.defaultZrtSelection = this.zrtFilterService.selectedZrts;
                }
            }
            this.refinerService.isCorporateUser = isCorporateUser;
            this.refinerService.areDefaultsSet = true;
        }
        this.filterDataLoaded = true;
    }

    async setFilterData(): Promise<void> {
        if (Helper.isEmployeeCustomerServiceOrAdmin(this.employee)) {
            this.refinerService.isCorporateUser = true;
            this.zrtFilterService.areas = await this.filterService.getAreas();
            const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts();
            this.zrtFilterService.employeeZrts = zrtsResponse?.values ?? new Array<SearchZrtDropDown>();
        } else {
            const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts(this.employee.searchableZrt);
            this.zrtFilterService.employeeZrts = zrtsResponse?.values ?? new Array<SearchZrtDropDown>();
        }

        void this.setActivitiesFilter();
        this.setDefaultFilter();
    }

    setRefinerInput(): void {
        if (!this.filterDataLoaded) return;

        this.setDefaultFilter();

        if (!this.refinerService.refiners.filter(v =>
            v.location == RefinerLocation.zrtByArea ||
            v.location == RefinerLocation.zrtByEmployee
        ).length
        ) {
            this.zrtFilterService.selectedZrts = [];
        }

        this.dateForm.controls.startDate.setValue(null);
        this.dateForm.controls.endDate.setValue(null);

        this.accountInput = null;
        this.streetInput = null;
        this.cityInput = null;
        this.zipInput = null;
        this.receiptInput = null;
        this.uinInput = null;

        this.populateInputsFromRefiners();
    }

    showFilters(): void {
        this.drawer.toggle();
        this.isGapSet = false;
    }

    unsubscribe(): void {
        if (
            this.refinerServiceSubscription &&
            !this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription.unsubscribe();
        }
        if (
            this.refinerInputChangeSubscription &&
            !this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription.unsubscribe();
        }
        if (
            this.gridDataChangeSubscription &&
            !this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription.unsubscribe();
        }
        if (
            this.zrtSelectionSubscription &&
            !this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription.unsubscribe();
        }
        if (
            this.myListSubscription &&
            !this.myListSubscription.closed
        ) {
            this.myListSubscription.unsubscribe();
        }
    }

    private getMyListIds(): void {
        setTimeout(async () => {
            if (this.total > 10000) {
                this.snackbar.showWarning("Total entry count is too high. Cannot push more than 10,000 records at once.");
                this.filterService.myListEntries$.next(undefined);
            } else {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                    this.shouldWait$
                );
                const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                data.header = "Confirmation";
                data.message =
                    "Would you like to push all entries to My List?";
                data.buttonLeftText = "Cancel";
                data.buttonLeftFunction = () => {
                    this.confirmationOverlayRef.close(data);
                };
                data.buttonRightText = "Yes";
                data.buttonRightFunction = () => {
                    data.isConfirmed = true;
                    this.confirmationOverlayRef.close(data);
                };

                this.confirmationOverlayRef = this.overlayService.open(
                    ConfirmationDialogComponent,
                    data
                );

                this.confirmationOverlayRef.afterClosed$
                    .pipe(
                        map(async (event) => {
                            if (event && event.data && event.data.isConfirmed) {
                                this.filterService.myListEntries$.next(await this.getIdsForMyList());
                                this.shouldWait$.next(true);
                            } else {
                                this.filterService.myListEntries$.next(undefined);
                                this.shouldWait$.next(true);
                            }
                        })
                    )
                    .subscribe();
            }
        }, 0);
    }

    private async getIdsForMyList(): Promise<string[]> {
        let rtn = this.dataSource.filteredData.map((gd) => gd.data.id);

        if (this.employeeDelineationService.getOnlineState() && rtn.length < this.total) {
            const gridIdResponse = await this.transactionDelineationService.getTransactionBatch(
                newSequentialId(),
                this.activeRefiners,
                10000,
                this.pageIndex * this.pageSize,
                this.activeSorts

            );
            if (gridIdResponse?.values) {
                const gridIds = gridIdResponse.values.map(v => v.id);
                rtn = rtn.concat(gridIds.filter((v) => !rtn.includes(v)));
            }
        }

        return rtn;
    }

    private setInputFromRefiner(refiner: Refiner): void {
        switch (refiner.location) {
            case RefinerLocation.zrtByArea:
            case RefinerLocation.zrtByEmployee:
                this.zrtFilterService.applyRefiner(refiner);
                break;
            case RefinerLocation.account:
                this.accountInput = refiner.value;
                break;
            case RefinerLocation.street:
                this.streetInput = refiner.value;
                break;
            case RefinerLocation.city:
                this.cityInput = refiner.value;
                break;
            case RefinerLocation.zipCodes:
                this.zipInput = refiner.value;
                break;
            case RefinerLocation.callOnOrAfterDate:
                const startDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.startDate.setValue(
                    startDate
                );
                break;
            case RefinerLocation.callOnOrBeforeDate:
                const endDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.endDate.setValue(
                    endDate
                );
                break;
            case RefinerLocation.receipt:
                this.receiptInput = refiner.value;
                break;
            case RefinerLocation.uin:
                this.uinInput = refiner.value;
                break;
            default:
                break;
        }
    }
}
