import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';
import { MY_DATE_FORMATS } from 'src/app/shared/constants/date-formats';
import { ViewRetailProjectViewModel } from './view-retail-project.viewmodel';

@Component({
    selector: 'app-view-retail-project',
    templateUrl: './view-retail-project.component.html',
    styleUrls: ['./view-retail-project.component.scss']
})
export class ViewRetailProjectComponent implements OnInit, OnDestroy {
    @HostBinding("class") class = "d-flex flex-column flex-grow-1";

    viewmodel: ViewRetailProjectViewModel;
    dateFormat: string = MY_DATE_FORMATS.display.customDateInput;

    constructor(public injectedData: SwisherOverlayRef<
            ViewRetailProjectViewModel,
            ViewRetailProjectComponent
        >) { }

    ngOnInit(): void {
        this.viewmodel = this.injectedData.data;
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }
}
