<div *ngIf="customer && hasReceipts && stepperCallApplicationService.callOrderProductViewModels">
    <mat-card class="inner-card">
        <div class="row">
            <div class="col-12 header">
                <div class="col-2 s-teal-color">
                    <b>Wholesale</b>
                </div>
                <div class="col-10">
                    <mat-form-field class="address-dropdown" appearance="outline" color="accent">
                        <mat-label>Wholesale Address</mat-label>
                        <mat-select [(value)]="selectedWholesaler">
                            <mat-option *ngFor="let option of addressOptions" [value]="option">
                                {{ option.name }} - {{ option.businessAddress?.city ? option.businessAddress?.city :
                                option.dbaAddress?.city }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div id="wholesale-receipt">
            <app-swisher-wholesaler-format *ngIf="!isWholesaleDraft" #swisherWholesaleFormat
                [isWholesaleDraft]="isWholesaleDraft"
                [isWholesaleFinal]="isWholesaleFinal"
                [selectedWholesaler]="selectedWholesaler"
                [signature]="signature"
                [signatureRetailWidth]="signatureRetailWidth" [signatureRetailHeight]="signatureRetailHeight"
                [selectedContact]="selectedContact"
                [customerReceiptAddress]="customerReceiptAddress"></app-swisher-wholesaler-format>
            <app-eas-wholesaler-format *ngIf="!isWholesaleDraft && isEasEmployee" #easWholesaleFormat
                [isWholesaleDraft]="isWholesaleDraft"
                [isWholesaleFinal]="isWholesaleFinal"
                [selectedWholesaler]="selectedWholesaler"
                [signature]="signature"
                [signatureRetailWidth]="signatureRetailWidth" [signatureRetailHeight]="signatureRetailHeight"
                [selectedContact]="selectedContact"
                [customerReceiptAddress]="customerReceiptAddress">
            </app-eas-wholesaler-format>
            <app-eas-wholesaler-swisher-rep-format *ngIf="!isWholesaleDraft && !isEasEmployee" #easWholesaleFormat
                [isWholesaleDraft]="isWholesaleDraft"
                [isWholesaleFinal]="isWholesaleFinal"
                [selectedWholesaler]="selectedWholesaler"
                [signature]="signature"
                [signatureRetailWidth]="signatureRetailWidth" [signatureRetailHeight]="signatureRetailHeight"
                [selectedContact]="selectedContact"
                [customerReceiptAddress]="customerReceiptAddress">
            </app-eas-wholesaler-swisher-rep-format>
            <app-wholesaler-format-draft *ngIf="isWholesaleDraft" #wholesaleDraft
                [isWholesaleDraft]="true"
                [isWholesaleFinal]="false"
                [selectedWholesaler]="selectedWholesaler"
                [signature]="signature"
                [signatureRetailWidth]="signatureRetailWidth" [signatureRetailHeight]="signatureRetailHeight"
                [selectedContact]="selectedContact"
                [customerReceiptAddress]="customerReceiptAddress"
                [isEasEmployee]="isEasEmployee"
                [wholesalers]="wholesalers"
                [customer]="customer"
                [employee]="employee"
                (captureReady)="captureWholesaleDraft()">
        </app-wholesaler-format-draft>
        </div>
    </mat-card>
</div>
