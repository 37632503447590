import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { BehaviorSubject, Subscription } from "rxjs";
import { SharedHelper } from "shield.shared";
import { ProductViewmodel } from "src/app/accounts/call-master/stepper-call/distribution-grid/product.viewmodel";
import { ProjectProduct } from "src/app/entity-models/project-product.entity";
import { ProjectStep } from "src/app/enums/project-step";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { ProjectStateService } from "src/app/services/project-state-service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { ProjectApplicationService } from "../../../project-services/project-application.service";
import { ProjectSummaryProductGridViewmodel } from "./project-summary-product-grid.viewmodel";
import { Product } from "src/app/entity-models/product.entity";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { WholesalerGroupProductCatalogItemDelineationService } from "src/app/services/delineation-services/wholesaler-group-product-catalog-item-delineation.service";

export class ProjectSummaryProductsViemodel {
    allProducts = new Map<string, Product>();
    checkingProducts = true;
    projectProducts = new Array<ProjectProduct>();

    grid: GridComponent;
    gridData = new Array<GridData>();
    isFixedLayout = true;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    projectSubscription: Subscription;

    profileView = false;

    columnDef: ColumnDef[] = [
        {
            headerName: "Name",
            dataPropertyName: "name",
            isAvailable: true,
            isSelected: false
        },
        {
            headerName: "UIN",
            dataPropertyName: "uin",
            isAvailable: true,
            isSelected: false
        },
        {
            headerName: "Wholesaler",
            dataPropertyName: "wholesalerName",
            isAvailable: true,
            isSelected: false
        }
    ];

    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    selectedColumnVisibilityComunicator = this.columnDef.map((cd) => cd.isAvailable && cd.headerName);

    projectStateService: ProjectStateService;
    pleaseWaitService: PleaseWaitService;
    projectApplicationService: ProjectApplicationService
    wholesalerGroupProductCatalogItemDelineationService: WholesalerGroupProductCatalogItemDelineationService;
    customerDelineationService: CustomerDelineationService;
    snackbarService: SnackbarService;

    constructor(projectStateService: ProjectStateService,
        pleaseWaitService: PleaseWaitService,
        projectApplicationService: ProjectApplicationService,
        wholesalerGroupProductCatalogItemDelineationService: WholesalerGroupProductCatalogItemDelineationService,
        customerDelineationService: CustomerDelineationService,
        snackbarService: SnackbarService
    ) {
        this.projectStateService = projectStateService;
        this.pleaseWaitService = pleaseWaitService;
        this.projectApplicationService = projectApplicationService;
        this.wholesalerGroupProductCatalogItemDelineationService = wholesalerGroupProductCatalogItemDelineationService;
        this.customerDelineationService = customerDelineationService;
        this.snackbarService = snackbarService;
    }

    async initialize(grid: GridComponent, buildProducts: boolean): Promise<void> {
        this.grid = grid;
        await this.buildProjectProducts();

        if (!this.projectSubscription || this.projectSubscription.closed) {
            this.projectSubscription = this.projectStateService.observableProject.subscribe((project) => {

                this.profileView = this.projectApplicationService.isInProfile;

                if (this.projectApplicationService.selectedIndex === ProjectStep.summary || this.profileView || buildProducts) {
                    if (project && project.projectProducts?.length && !this.projectProducts.length) {
                        this.buildProjectProducts(project.projectProducts);
                    }
                    if (project && project.projectProducts && !this.isSame(project.projectProducts)) {
                        this.buildProjectProducts();
                    }
                }
            })
        }
    }

    isSame(pp: ProjectProduct[]): boolean {
        let rtn = true;

        if (this.projectProducts.length !== pp.length) {
            rtn = false;
        } else {

            this.projectProducts.sort((a, b) => a.id > b.id ? 1 : a.id < b.productId ? -1 : 0);
            pp.sort((a, b) => a.id > b.id ? 1 : a.id < b.productId ? -1 : 0);

            for (let i = 0; i < pp.length; i++) {
                if (pp[i].id !== this.projectProducts[i].id) {
                    rtn = false;
                    break;
                }
            }
        }
        return rtn;
    }

    async buildProjectProducts(projectProducts?: ProjectProduct[]): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        this.checkingProducts = true;
        setTimeout(async () => {
            if (!this.projectStateService.project) {
                // Project was saved right before routing await, if no project, don't try to build Products
                this.shouldWait$.next(false);
                this.checkingProducts = false;
                return;
            }
            if (projectProducts) {
                this.projectProducts = projectProducts;
            }
            if (!this.allProducts?.size) {
                this.allProducts = this.projectApplicationService.productsMap;
            }
            let productViewmodels = new Array<ProductViewmodel>();

            //Add all wholesaler
            const wholesalerbased = this.projectStateService.project?.projectProducts.filter((wb) => wb.wholesalerId);

            const wholesalerGroupings = SharedHelper.groupBy<ProjectProduct>(wholesalerbased, (pp) => pp.wholesalerId);
            for (const [key, values] of wholesalerGroupings) {
                const wholesalerId = key;
                const wholesalerResponse = await this.customerDelineationService.getById(wholesalerId);
                if (!wholesalerResponse) {
                    this.shouldWait$.next(false);
                    return;
                }
                const wholesaler = wholesalerResponse.values

                const productsResponse = await this.wholesalerGroupProductCatalogItemDelineationService.getByWholesalerId(wholesaler.id);
                if (!productsResponse) {
                    this.shouldWait$.next(false);
                    return;
                }
                const products = productsResponse.values.map((p) => {
                    const vm = ProductViewmodel.WholesalerGroupProductCatalogItemToProductViewmodel(p);
                    vm.wholesaler = wholesaler;
                    return vm;
                });
                const projectProductIds = values.map((v) => v.productId);
                productViewmodels.push(...products.filter((pp) => projectProductIds.includes(pp.id)))
            };

            //Add non-wholesalers
            const nonWholesalerbased = this.projectStateService.project?.projectProducts.filter((wb) => !wb.wholesalerId);
            for (const item of nonWholesalerbased) {
                productViewmodels.push(this.allProducts.get(item.productId) as ProductViewmodel);
            }

            productViewmodels.sort((a, b) => a.description < b.description ? 1 : a.description > b.description ? -1 : 0);

            let gridData = new Array<GridData>();

            if (productViewmodels.some((vm) => !!vm.wholesaler)) {
                this.columnDef = [
                    {
                        headerName: "Name",
                        dataPropertyName: "name",
                        isAvailable: true,
                        isSelected: false
                    },
                    {
                        headerName: "UIN",
                        dataPropertyName: "uin",
                        isAvailable: true,
                        isSelected: false
                    },
                    {
                        headerName: "Wholesaler",
                        dataPropertyName: "wholesalerName",
                        isAvailable: true,
                        isSelected: false
                    }
                ];
            } else {
                this.columnDef = [
                    {
                        headerName: "Name",
                        dataPropertyName: "name",
                        isAvailable: true,
                        isSelected: false
                    },
                    {
                        headerName: "Display UPC",
                        dataPropertyName: "upc",
                        isAvailable: true,
                        isSelected: false
                    }
                ];
            }

            for (const product of productViewmodels) {
                const gridItem: GridData = {
                    data: new ProjectSummaryProductGridViewmodel(product),
                    detailArrayName: null,
                    isExpanded: false,
                    index: null
                };
                gridData.push(gridItem);
            }
            this.gridData = gridData;

            this.dataSource = new TableVirtualScrollDataSource(
                this.gridData
            );

            this.shouldWait$.next(false);
                this.checkingProducts = false;
        }, 0);
    }

    unsubscribe(): void {
        if (this.projectSubscription && !this.projectSubscription.closed) {
            this.projectSubscription.unsubscribe();
        }
    }
}
