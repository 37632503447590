<div *ngIf="customer && hasReceipts">
    <mat-card class="inner-card">
        <div id="retail-receipt">
            <app-swisher-retail-format *ngIf="!isTaxState && !isRetailDraft" #swisherRetailFormat [isRetailDraft]="isRetailDraft" [isRetailFinal]="isRetailFinal"
                [customerReceiptAddress]="customerReceiptAddress" [signature]="signature"
                [signatureRetailWidth]="signatureRetailWidth" [signatureRetailHeight]="signatureRetailHeight"
                [selectedContact]="selectedContact">
            </app-swisher-retail-format>
            <app-swisher-retail-narrow-tax-format *ngIf="isTaxState && !isRetailDraft && selectedFormat===selectedFormats.narrow" #swisherRetailFormat [isRetailDraft]="isRetailDraft" [isRetailFinal]="isRetailFinal"
                [customerReceiptAddress]="customerReceiptAddress" [signature]="signature"
                [signatureRetailWidth]="signatureRetailWidth" [signatureRetailHeight]="signatureRetailHeight"
                [selectedContact]="selectedContact">
            </app-swisher-retail-narrow-tax-format>
            <app-swisher-retail-wide-tax-format *ngIf="isTaxState && !isRetailDraft && selectedFormat===selectedFormats.wide" #swisherRetailFormat [isRetailDraft]="isRetailDraft" [isRetailFinal]="isRetailFinal"
                [customerReceiptAddress]="customerReceiptAddress" [signature]="signature"
                [signatureRetailWidth]="signatureRetailWidth" [signatureRetailHeight]="signatureRetailHeight"
                [selectedContact]="selectedContact">
            </app-swisher-retail-wide-tax-format>
            <app-retail-format-draft *ngIf="isRetailDraft" #swisherRetailFormat
                [customerReceiptAddress]="customerReceiptAddress" [signature]="signature"
                [signatureRetailWidth]="signatureRetailWidth" [signatureRetailHeight]="signatureRetailHeight"
                [selectedContact]="selectedContact" [isEasEmployee]="isEasEmployee"
                [isTaxState]="isTaxState" [selectedFormat]="selectedFormat">
            </app-retail-format-draft>
            <app-eas-retail-format *ngIf="!isTaxState && isEasEmployee && !isRetailDraft" #easRetailFormat [isRetailDraft]="isRetailDraft" [isRetailFinal]="isRetailFinal"
                [customerReceiptAddress]="customerReceiptAddress" [signature]="signature"
                [signatureRetailWidth]="signatureRetailWidth" [signatureRetailHeight]="signatureRetailHeight"
                [selectedContact]="selectedContact">
            </app-eas-retail-format>
            <app-eas-retail-narrow-tax-format #easRetailFormat *ngIf="isTaxState && !isRetailDraft && selectedFormat===selectedFormats.narrow && isEasEmployee" [isRetailDraft]="isRetailDraft" [isRetailFinal]="isRetailFinal"
                [customerReceiptAddress]="customerReceiptAddress" [signature]="signature"
                [signatureRetailWidth]="signatureRetailWidth" [signatureRetailHeight]="signatureRetailHeight"
                [selectedContact]="selectedContact">
            </app-eas-retail-narrow-tax-format>
            <app-eas-retail-wide-tax-format #easRetailFormat *ngIf="isTaxState && !isRetailDraft && selectedFormat===selectedFormats.wide && isEasEmployee" [isRetailDraft]="isRetailDraft" [isRetailFinal]="isRetailFinal"
                [customerReceiptAddress]="customerReceiptAddress" [signature]="signature"
                [signatureRetailWidth]="signatureRetailWidth" [signatureRetailHeight]="signatureRetailHeight"
                [selectedContact]="selectedContact">
            </app-eas-retail-wide-tax-format >
            <app-eas-retail-wide-tax-format-swisher-rep #easRetailFormat *ngIf="isTaxState && selectedFormat===selectedFormats.wide && !isEasEmployee" [isRetailDraft]="isRetailDraft" [isRetailFinal]="isRetailFinal"
                [customerReceiptAddress]="customerReceiptAddress" [signature]="signature"
                [signatureRetailWidth]="signatureRetailWidth" [signatureRetailHeight]="signatureRetailHeight"
                [selectedContact]="selectedContact">
            </app-eas-retail-wide-tax-format-swisher-rep>
            <app-eas-retail-format-swisher-rep *ngIf="!isTaxState && !isEasEmployee && !isRetailDraft" #easRetailFormat [isRetailFinal]="isRetailFinal"
                [customerReceiptAddress]="customerReceiptAddress" [signature]="signature"
                [signatureRetailWidth]="signatureRetailWidth" [signatureRetailHeight]="signatureRetailHeight"
                [selectedContact]="selectedContact">
            </app-eas-retail-format-swisher-rep>
            <app-eas-retail-narrow-tax-format-swisher-rep #easRetailFormat *ngIf="isTaxState && !isRetailDraft && selectedFormat===selectedFormats.narrow && !isEasEmployee" [isRetailDraft]="isRetailDraft" [isRetailFinal]="isRetailFinal"
                [customerReceiptAddress]="customerReceiptAddress" [signature]="signature"
                [signatureRetailWidth]="signatureRetailWidth" [signatureRetailHeight]="signatureRetailHeight"
                [selectedContact]="selectedContact">
            </app-eas-retail-narrow-tax-format-swisher-rep>
        </div>
    </mat-card>
</div>
