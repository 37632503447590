import { ReferenceProperty } from "../type";
import { CallPictureDtoPictureType } from "./call-picture.dto";

export class OnlineReferenceDto {
    id: string;
    property: ReferenceProperty;
    type: CallPictureDtoPictureType | undefined;
    url: string;
    tags?: string | null;
}
