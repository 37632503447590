import { Injectable } from "@angular/core";
import { Subsidiary } from "shield.shared";
import { StateLicense } from "src/app/entity-models/state-license.entity";
import { DatabaseService } from "../database.service";

@Injectable()
export class StateLicenseOfflineService {

    constructor(private dbService: DatabaseService){}

    async getByShortCode(shortCode: string): Promise<StateLicense[]> {

        return await this.dbService.stateLicenses
                .where("stateShortCode")
                .equals(shortCode)
                .filter((sl) => sl.subsidiaryId === Subsidiary.Swisher)
                .toArray();
    }
}
