export class SharedGenericConverter<E extends Object, D extends Object> {
    dtoToEntity(
        dto: D
    ): E {
        var entity = {} as E;
        Object.assign(entity, dto);
        return entity;
    }

    entityToDto(entity: E): D {
        var dto = {} as D;
        Object.assign(dto, entity);
        return dto;
    }
}
