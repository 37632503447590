import {
    CustomerTypeLookup,
    GenericLookup,
    ProjectActivitySurveyDto,
    ProjectAssignmentCountEntryDto,
    ProjectBasicCustomerDto,
    ProjectBasicCustomerEmployeeDto,
    ProjectCustomerDto,
    ProjectDto,
    ProjectEmployeeDto,
    ProjectOrderDateDto,
    ProjectProductDto,
    RefinerDto,
    RefinerGroupDto,
    RefinerLocation
} from "shield.shared";
import { ProjectAssignmentsSummaryViewModel } from "src/app/details/project/project-configuration/project-assignments/project-assignments-summary.viewmodel";
import { ProjectActivitySurvey } from "src/app/entity-models/project-activity-survey.entity";
import { ProjectBasicCustomerEmployee } from "src/app/entity-models/project-basic-customer-employee.entity";
import { ProjectBasicCustomer } from "src/app/entity-models/project-basic-customer.entity";
import { ProjectCustomer } from "src/app/entity-models/project-customer.entity";
import { ProjectEmployee } from "src/app/entity-models/project-employee.entity";
import { ProjectOrderDate } from "src/app/entity-models/project-order-date.entity";
import { ProjectProduct } from "src/app/entity-models/project-product.entity";
import { Project } from "src/app/entity-models/project.entity";
import { Helper } from "src/app/helpers/helper";
import { RefinerConverterService } from "./refiner-converter.service";

export class ProjectConverterService {

    static projectDtoToProject(dto: ProjectDto): Project {
        const entity = new Project();
        entity.id = dto.id;
        entity.projectId = dto.projectId;
        entity.projectTypeId = dto.projectTypeId;
        entity.projectStatusId = dto.projectStatusId;
        entity.name = dto.name;
        entity.startDate = dto.startDate ? new Date(dto.startDate) : null;
        entity.visibleDate = dto.visibleDate ? new Date(dto.visibleDate) : null;
        entity.endDate = dto.endDate ? new Date(dto.endDate) : null;
        entity.mission = dto.mission;
        entity.shareSiteLink = dto.shareSiteLink;
        entity.areStoresVisibleToAll = dto.areStoresVisibleToAll;
        entity.createdUserId = dto.createdUserId;
        entity.createdUtcDateTime = dto.createdUtcDateTime ? new Date(dto.createdUtcDateTime) : null;
        entity.createdUserZrt = dto.createdUserZrt;
        entity.createdUserFullName = dto.createdUserFullName;
        entity.modifiedUserId = dto.modifiedUserId;
        entity.modifiedUtcDateTime = dto.modifiedUtcDateTime ? new Date(dto.modifiedUtcDateTime) : null;
        entity.modifiedUserZrt = dto.modifiedUserZrt;
        entity.modifiedUserFullName = dto.modifiedUserFullName;
        entity.rowversion = dto.rowversion;

        entity.projectActivitySurveys = dto.projectActivitySurveys.map((pas) => ProjectConverterService.projectActivitySurveysDtoToProjectActivitySurveys(pas));
        entity.projectCustomers = dto.projectCustomers.map((pc) => ProjectConverterService.projectCustomerDtoToProjectCustomer(pc));
        entity.projectEmployees = dto.projectEmployees.map((pe) => ProjectConverterService.projectEmployeeDtoToProjectEmployee(pe));
        entity.projectOrderDates = dto.projectOrderDates.map((pod) => ProjectConverterService.projectOrderDateDtoToProjectOrderDate(pod));
        entity.projectProducts = dto.projectProducts.map((pp) => ProjectConverterService.projectProductDtoToProjectProduct(pp));

        entity.storeCount = dto.storeCount;

        return entity;
    }

    static projectToProjectDto(entity: Project): ProjectDto {

        const dto = new ProjectDto();

        dto.id = entity.id;
        dto.projectId = entity.projectId;
        dto.projectTypeId = entity.projectTypeId;
        dto.projectStatusId = entity.projectStatusId;
        dto.name = entity.name;
        dto.startDate = entity.startDate ? entity.startDate.toISOString() : null;
        dto.visibleDate = entity.visibleDate ? entity.visibleDate.toISOString() : null;
        dto.endDate = entity.endDate ? entity.endDate.toISOString() : null;
        dto.mission = entity.mission;
        dto.shareSiteLink = entity.shareSiteLink;
        dto.wholesalerGroupId = entity.wholesalerGroupId;
        dto.areStoresVisibleToAll = entity.areStoresVisibleToAll;
        dto.createdUserFullName = entity.createdUserFullName;
        dto.createdUserId = entity.createdUserId;
        dto.createdUserZrt = entity.createdUserZrt;
        dto.createdUtcDateTime = entity.createdUtcDateTime ? entity.createdUtcDateTime.toISOString() : null;
        dto.modifiedUserFullName = entity.modifiedUserFullName;
        dto.modifiedUserId = entity.modifiedUserId;
        dto.modifiedUserZrt = entity.modifiedUserZrt;
        dto.modifiedUtcDateTime = entity.modifiedUtcDateTime ? entity.modifiedUtcDateTime.toISOString() : null;

        dto.projectActivitySurveys = entity.projectActivitySurveys.map((pas) =>
            ProjectConverterService.projectActivitiyAndSurvaeyToProjectActivityAndSurvayDto(pas));
        dto.projectCustomers = entity.projectCustomers.map((pc) => ProjectConverterService.projectCustomerToProjectCustomerDto(pc));
        dto.projectEmployees = entity.projectEmployees.map((pe) => ProjectConverterService.projectEmployeeToProjectEmployeeDto(pe));
        dto.projectOrderDates = entity.projectOrderDates.map((pod) => ProjectConverterService.projectOrderTateToProjectOrderDateDto(pod));
        dto.projectProducts = entity.projectProducts.map((pp) => ProjectConverterService.projectProductsToProjectProductDto(pp));


        return dto;
    }

    static projectProductsToProjectProductDto(entity: ProjectProduct): ProjectProductDto {

        const rtn = new ProjectProductDto();

        rtn.id = entity.id;
        rtn.productId = entity.productId;
        rtn.projectId = entity.projectId;
        rtn.wholesalerGroupProductId = entity.wholesalerGroupProductId;
        rtn.wholesalerId = entity.wholesalerId;

        return rtn;
    }

    static projectOrderTateToProjectOrderDateDto(entity: ProjectOrderDate): ProjectOrderDateDto {

        const rtn = new ProjectOrderDateDto();

        rtn.id = entity.id;
        rtn.dateIndex = entity.dateIndex;
        rtn.orderDate = entity.orderDate ? entity.orderDate.toISOString() : new Date().toISOString();
        rtn.projectId = entity.projectId;

        return rtn;
    }

    static projectEmployeeToProjectEmployeeDto(entity: ProjectEmployee): ProjectEmployeeDto {

        const rtn = new ProjectEmployeeDto();

        rtn.id = entity.id;
        rtn.employeeId = entity.employeeId;
        rtn.projectId = entity.projectId;

        return rtn;
    }

    static projectCustomerToProjectCustomerDto(entity: ProjectCustomer): ProjectCustomerDto {

        const rtn = new ProjectCustomerDto();

        rtn.id = entity.id;
        rtn.customerId = entity.customerId;
        rtn.projectId = entity.projectId;
        rtn.employeeIds = entity.employeeIds;

        return rtn;
    }

    static projectActivitiyAndSurvaeyToProjectActivityAndSurvayDto(entity: ProjectActivitySurvey): ProjectActivitySurveyDto {

        const rtn = new ProjectActivitySurveyDto();

        rtn.id = entity.id;
        rtn.activitySurveyId = entity.activitySurveyId;
        rtn.projectId = entity.projectId;

        return rtn;
    }

    static projectProductDtoToProjectProduct(dto: ProjectProductDto): ProjectProduct {

        const rtn = new ProjectProduct();

        rtn.id = dto.id;
        rtn.productId = dto.productId;
        rtn.projectId = dto.projectId;
        rtn.wholesalerId = dto.wholesalerId;
        rtn.wholesalerGroupProductId = dto.wholesalerGroupProductId;

        return rtn;
    }

    static projectOrderDateDtoToProjectOrderDate(dto: ProjectOrderDateDto): ProjectOrderDate {

        const rtn = new ProjectOrderDate();

        rtn.id = dto.id;
        rtn.dateIndex = dto.dateIndex;
        rtn.orderDate = dto.orderDate ? new Date(dto.orderDate) : new Date();
        rtn.projectId = dto.projectId;

        return rtn;
    }

    static projectEmployeeDtoToProjectEmployee(dto: ProjectEmployeeDto): ProjectEmployee {

        const rtn = new ProjectEmployee();

        rtn.id = dto.id;
        rtn.employeeId = dto.employeeId;
        rtn.projectId = dto.projectId;

        return rtn;
    }

    static projectCustomerDtoToProjectCustomer(dto: ProjectCustomerDto): ProjectCustomer {

        const rtn = new ProjectCustomer();

        rtn.id = dto.id;
        rtn.customerId = dto.customerId;
        rtn.projectId = dto.projectId;
        rtn.employeeIds = dto.employeeIds;

        return rtn;
    }

    static projectActivitySurveysDtoToProjectActivitySurveys(dto: ProjectActivitySurveyDto): ProjectActivitySurvey {

        const rtn = new ProjectActivitySurveyDto();
        rtn.id = dto.id;
        rtn.projectId = dto.projectId;
        rtn.activitySurveyId = dto.activitySurveyId;

        return rtn;
    }

    static projectBasicCustomerDtoToProjectBasicCustomer(dto: ProjectBasicCustomerDto): ProjectBasicCustomer {
        const entity = new ProjectBasicCustomer();

        entity.customerId = dto.customerId;
        entity.name = dto.name;
        entity.address1 = dto.address1;
        entity.city = dto.city;
        entity.county = dto.county;
        entity.state = dto.state;
        entity.zip = dto.zip;
        entity.customerType = GenericLookup.getFromLookup(CustomerTypeLookup, dto.customerType);
        entity.industryVolume = dto.industryVolume;
        entity.swisherVolume = dto.swisherVolume;
        entity.lastCall = dto.lastCall ? new Date(dto.lastCall) : null;
        entity.zrt = dto.zrt;
        entity.phone = dto.phone;
        entity.chain = dto.chain;
        entity.isMsa = dto.isMsa;
        entity.isAssigned = dto.isAssigned;
        entity.px3RankId = dto.px3RankId;
        entity.callsMade = dto.callsMade;

        return entity;
    }

    static projectBasicCustomerEmployeeDtoToProjectBasicCustomerEmployee(dto: ProjectBasicCustomerEmployeeDto): ProjectBasicCustomerEmployee {
        const entity = ProjectConverterService.projectBasicCustomerDtoToProjectBasicCustomer(dto) as ProjectBasicCustomerEmployee;

        entity.isSelected = dto.isSelected;
        entity.isAssigned = dto.isAssigned;

        return entity;
    }

    static projectAssignmentCountEntryDtoToProjectAssignmentCountEntry(dto: ProjectAssignmentCountEntryDto): ProjectAssignmentsSummaryViewModel {
        const rtn = new ProjectAssignmentsSummaryViewModel();

        rtn.value = dto.value;
        rtn.assignedCount = dto.assignedCount;
        rtn.unassignedCount = dto.unassignedCount ?? 0;
        rtn.dataValue = dto.dataValue;

        return rtn;
    }

    static mapDtoData(refiner: RefinerDto, isCustomerBatch?: boolean): RefinerGroupDto {
        const refinerDtos = new Array<RefinerDto>();

        switch (refiner.refinerLocation) {
            case RefinerLocation.name:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Projects",
                        "Name",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.manager:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Projects",
                        "CreatedUserFullName",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.zrt:
            case RefinerLocation.zrtByEmployee:
            case RefinerLocation.zrtByArea:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "Zrt",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.street:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerBusinessAddress",
                        "Address1",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.city:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerBusinessAddress",
                        "City",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.states:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerBusinessAddress",
                        "State",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.counties:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerBusinessAddress",
                        "County",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.zipCodes:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerBusinessAddress",
                        "Zip",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.account:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "Name,Id,CustomerNumber",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.assignment:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        refiner.dtoObjectName,
                        refiner.dtoPropertyName,
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.assigned:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "ProjectEmployees",
                        "EmployeeId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.callOnOrAfterDate:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "LastCall",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.callOnOrBeforeDate:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "LastCall",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.startDate:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Projects",
                        "StartDate",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.endDate:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Projects",
                        "EndDate",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.wholesalers:
                if (isCustomerBatch) {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "CustomerWholesalers",
                            "WholesalerId",
                            refiner.value,
                            refiner.dataValue
                        )
                    );
                } else {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "ProjectCustomers",
                            "CustomerId",
                            refiner.value,
                            refiner.dataValue
                        )
                    );
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "ProjectProducts",
                            "WholesalerId",
                            refiner.value,
                            refiner.dataValue
                        )
                    );
                }
                break;
            case RefinerLocation.chains:
                if (isCustomerBatch) {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            refiner.dtoObjectName ?? "AccountOwnerships",
                            refiner.dtoPropertyName ?? "CustomerId",
                            refiner.value,
                            refiner.dataValue
                        )
                    );
                } else {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "Customers",
                            "TdOwnerCode",
                            refiner.value,
                            refiner.dataValue
                        )
                    );
                }
                break;
            case RefinerLocation.projectType:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Projects",
                        "ProjectTypeId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.projectStatus:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Projects",
                        "ProjectStatusId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.customer:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "ProjectCustomers",
                        "CustomerId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.storeTypes:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "Type",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.msa:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "IsMsa",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                if (refiner.dataValue === "0") {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "Customers",
                            "IsMsa",
                            refiner.value,
                            "IsNull"
                        )
                    );
                }
                break;
            case RefinerLocation.phone:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "HasPhone",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                if (refiner.dataValue === "0") {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "Customers",
                            "HasPhone",
                            refiner.value,
                            "IsNull"
                        )
                    );
                }
                break;
            case RefinerLocation.isActive:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "IsActive",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                if (refiner.dataValue === "0") {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "Customers",
                            "IsActive",
                            refiner.value,
                            "IsNull"
                        )
                    );
                }
                break;
            case RefinerLocation.volume:
                if (refiner.dataValue === "0") {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            RefinerLocation.status,
                            "Customers",
                            "IndustryVolume",
                            refiner.value,
                            refiner.dataValue
                        )
                    );
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "Customers",
                            "IndustryVolume",
                            refiner.value,
                            "IsNull"
                        )
                    );
                } else {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "Customers",
                            "IndustryVolume",
                            "0"
                        )
                    );
                }
                break;
            case RefinerLocation.px3Rank:
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "CustomerPx3Ranks",
                            "Px3RankId",
                            refiner.value,
                            refiner.dataValue
                        )
                    );
                    break;
            case RefinerLocation.callable:
                const overridden = refiner.value?.includes("Overridden");
                const yes = refiner.value?.includes("Yes");
                if ((!yes && !overridden) || yes) {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            refiner.refinerLocation,
                            "Customers",
                            "IsCallable",
                            refiner.value,
                            refiner.dataValue
                        )
                    );
                    if (refiner.dataValue === "0") {
                        refinerDtos.push(
                            RefinerConverterService.createRefinerDto(
                                refiner.refinerLocation,
                                "Customers",
                                "IsCallable",
                                refiner.value,
                                "IsNull"
                            )
                        );
                    }
                }
                if (overridden) {
                    refinerDtos.push(
                        RefinerConverterService.createRefinerDto(
                            RefinerLocation.unknown,
                            "Customers",
                            "IsCallableOverridden",
                            "1"
                        )
                    );
                }
                break;
            default:
                break;
        }

        return RefinerConverterService.splitRefinerDtoValues(refinerDtos);
    }
}
