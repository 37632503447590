import { AfterViewChecked, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/entity-models/project.entity';
import { SurveyDelineationService } from 'src/app/services/delineation-services/survey-delineation.service';
import { ProjectStateService } from 'src/app/services/project-state-service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { ProjectSummaryActivitiesSurveysViewmodel } from './project-summary-activities-surveys.viewmodel';

@Component({
  selector: 'app-project-summary-activities-surveys',
  templateUrl: './project-summary-activities-surveys.component.html',
  styleUrls: ['./project-summary-activities-surveys.component.scss']
})
export class ProjectSummaryActivitiesSurveysComponent implements OnDestroy, AfterViewChecked {

    @ViewChild("grid") grid: GridComponent;

    @Input()
    set project(value: Project) {
        if (value) {
            this.projectStateService.project = value;
        }
    }

    viewmodel = new ProjectSummaryActivitiesSurveysViewmodel(this.surveyDelineationService, this.snackbarService);
    isViewmodelReady = false;

    projectSubscription: Subscription;

    constructor(private surveyDelineationService: SurveyDelineationService
        , private projectStateService: ProjectStateService
        , private snackbarService: SnackbarService) { }

    ngOnDestroy(): void {
        if (this.projectSubscription && !this.projectSubscription.closed) {
            this.projectSubscription.unsubscribe();
        }
    }

    async ngAfterViewChecked(): Promise<void> {
        if (!this.isViewmodelReady
            && this.grid
        ) {
            this.isViewmodelReady = true;
            await this.viewmodel.initialize(
                this.grid
            );

            if (!this.projectSubscription || this.projectSubscription.closed) {
                this.projectSubscription = this.projectStateService.observableProject.subscribe(async (project) => {
                    if (project) {
                        this.viewmodel.checkingProjectProperties = true;

                        if (this.viewmodel.projectActivitiesSurveys?.length !== project.projectActivitySurveys?.length) {
                            await this.viewmodel.buildProjectActivitiesSurveys(project.projectActivitySurveys);
                        } else {
                            let isSame = true;
                            for (let i = 0; i < project.projectActivitySurveys.length; i++) {
                                if (project.projectActivitySurveys[i].id !== this.viewmodel.projectActivitiesSurveys[i].id) {
                                    isSame = false;
                                    break;
                                }
                            }
                            if (!isSame) {
                                await this.viewmodel.buildProjectActivitiesSurveys(project.projectActivitySurveys);
                            }
                        }
                        this.viewmodel.checkingProjectProperties = false;
                    }
                });
            }
        }
    }

}
