import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatChip } from "@angular/material/chips";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { SharedModule } from "../shared/shared.module";
import { OrdersListComponent } from "./orders/orders-list/orders-list.component";
import { TransactionsListComponent } from "./transactions/transactions-list/transactions-list.component";
import { SalesRoutingModule } from "./sales.routing.module";
import { InvoiceDetailsComponent } from "./transactions/invoice-details/invoice-details.component";
import { OrderExtractionComponent } from "./orders/order-extraction/order-extraction.component";
import { OrderEditOptionsComponent } from "./orders/order-edit-options/order-edit-options.component";
import { ChangeOrderDateComponent } from "./orders/change-order-date/change-order-date.component";
import { ChangeWholesalerUinComponent } from "./orders/change-wholesaler-uin/change-wholesaler-uin.component";
import { MoveOrderProductsComponent } from "./orders/move-order-products/move-order-products.component";
import { OrderListZrtFilterService } from "./orders/orders-list/order-list-zrt-filter.service";
import { TransactionListZrtFilterService } from "./transactions/transactions-list/transaction-list-zrt-filter.service";

@NgModule({
    declarations: [
        OrdersListComponent,
        TransactionsListComponent,
        InvoiceDetailsComponent,
        OrderExtractionComponent,
        OrderEditOptionsComponent,
        MoveOrderProductsComponent,
        ChangeWholesalerUinComponent,
        ChangeOrderDateComponent
    ],
    imports: [
        FontAwesomeModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        SharedModule,
        SalesRoutingModule
    ],
    providers: [
        MatChip,
        OrderListZrtFilterService,
        TransactionListZrtFilterService
    ]
})
export class SalesModule {}
