import { AfterViewChecked, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Project } from 'src/app/entity-models/project.entity';
import { AppStateService } from 'src/app/services/app-state.service';
import { ProjectStateService } from 'src/app/services/project-state-service';
import { ProjectApplicationService } from '../../../project-services/project-application.service';
import { ProjectSummaryReleaseDatesViewmodel } from './project-summary-release-dates.viewmodel';

@Component({
    selector: 'app-project-summary-release-dates',
    templateUrl: './project-summary-release-dates.component.html',
    styleUrls: ['./project-summary-release-dates.component.scss']
})
export class ProjectSummaryReleaseDatesComponent implements OnDestroy, AfterViewChecked {

    viewmodel = new ProjectSummaryReleaseDatesViewmodel(this.formbuilder
        , this.projectApplicationService
        , this.projectStateService
        , this.appStateService);
    isViewmodelReady = false;

    readOnly: boolean;
    @Input()
    set project(value: Project) {
        this.readOnly = !!value;
        if (value) {
            this.projectStateService.project = value;
        }
    }

    constructor(private formbuilder: FormBuilder,
        private projectApplicationService: ProjectApplicationService,
        private projectStateService: ProjectStateService,
        private appStateService: AppStateService) { }

        ngAfterViewChecked(): void {
            if (!this.isViewmodelReady) {
                setTimeout(() => {
                    this.isViewmodelReady = true;
                    this.viewmodel.isReadOnly = this.readOnly;
                    this.viewmodel.initialize();
                }, 0);
            }
        }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }
}
