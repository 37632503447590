import { RouteStop } from "./route-stop.entity";

export class Route {
    id: string;
    employeeId: string;
    employeeZrt: string;
    date: Date;
    name: string;
    description: string;
    stops: RouteStop[] = [];
    createdUserId: string;
    createdUserZrt: string;
    createdUserFullName: string;
    createdUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    modifiedUtcDateTime: Date;
    hasServerProcessed: number;
    rowversion: string;
    completedStops?: number;
}
