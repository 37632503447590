import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AccountOwnershipDropDownDto, AccountOwnershipDto, GenericResponseDto } from "shield.shared";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { AccountOwnershipConverterService } from "../converter-services/account-ownership-converter.service";
import { ConverterHelper } from "../converter-services/converter.helper";

@Injectable()
export class AccountOwnershipOnlineService {

    constructor(private http: HttpClient) { }

    async getByOwnerCode(code: string): Promise<GenericResponseDto<AccountOwnership[]>> {

        const result = await this.http
            .get<GenericResponseDto<AccountOwnershipDto[]>>(`/api/account-ownership/owner-code/${code}`)
            .toPromise();

        return ConverterHelper.dtoToEntityArray<AccountOwnershipDto, AccountOwnership>(result,
            (r) => AccountOwnershipConverterService.accountOwnershipDtoToAccountOwnership(r));
    }
}
