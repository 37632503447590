import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";
import { SurveyQuestionOption } from "src/app/entity-models/survey-question-drop-down-detail.entity";

export class ViewProjectActivityDialogViewModel extends BasicDialogViewModel {
    responses: SurveyQuestionOption[];
    text: string;

    constructor() {
        super();

        this.headerLeftText = "Multi Choice Responses";
        this.buttonLeftText = "Close";
        this.buttonLeftDisabledFunction = () => false;
    }
}
