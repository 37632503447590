import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ContractTemplate } from 'src/app/entity-models/contract-template.entity';
import { CustomerContract } from 'src/app/entity-models/customer-contract.entity';
import { MY_DATE_FORMATS } from 'src/app/shared/constants/date-formats';
import { RetailIncentiveRetailerViewModel } from './retail-incentive-retailer.viewmodel'

@Component({
    selector: 'app-retail-incentive-agreement',
    templateUrl: './retail-incentive-agreement.component.html',
    styleUrls: ['./retail-incentive-agreement.component.css']
})
export class RetailIncentiveAgreementComponent {
    details: Map<string, string> = new Map();
    retailerInfo: Map<string, string> = new Map();

    lines = new Array<string>();
    terms = new Array<string>();
    levelOneLines = new Array<string>();
    levelTwoLines = new Array<string>();
    levelOneBonuses = new Array<string>();
    levelTwoBonuses = new Array<string>();

    private _contract: ContractTemplate;
    @Input()
    get contract(): ContractTemplate {
        return this._contract;
    }
    set contract(value: ContractTemplate) {
        this._contract = value;
        this._contract.contractTemplateDetails.forEach((element) =>
            this.details.set(element.key, element.value)
        );
        this.setContractElements();
    }

    private _endDate: string;
    @Input()
    get endDate(): string {
        return this._endDate;
    }
    set endDate(value: string) {
        this._endDate = value;
    }

    private _retailer: RetailIncentiveRetailerViewModel;
    @Input()
    get retailer(): RetailIncentiveRetailerViewModel {
        return this._retailer;
    }
    set retailer(value: RetailIncentiveRetailerViewModel) {
        this._retailer = value;

    }

    setContractElements(): void {
        this.details.forEach((value, key) => {
            if (key && value) {
                if (key.includes("Line")) this.lines.push(value);
                if (key.includes("Term")) this.terms.push(value);
                if (key.includes("LevelOneL")) this.levelOneLines.push(value);
                if (key.includes("LevelTwoL")) this.levelTwoLines.push(value);
                if (key.includes("LevelOneB")) this.levelOneBonuses.push(value);
                if (key.includes("LevelTwoB")) this.levelTwoBonuses.push(value);
            }
        });
    }
}
