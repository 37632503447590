import { MatSortHeader } from "@angular/material/sort";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { Observable, Subscription } from "rxjs";
import { saveAs } from "file-saver";
import * as moment from "moment";
import { AccountsListColumns, EmployeeRoleType, FilterSortDto, GenericDropDownDto, newSequentialId, RefinerLocation, SharedHelper, SortDirection, Subsidiary, valueSeparator } from "shield.shared";
import { Customer } from "src/app/entity-models/customer.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { Route } from "src/app/entity-models/route.entity";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { PingService } from "src/app/services/ping.service";
import { ActivitiesFilterService } from "src/app/services/activities-filter.service";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { AccountViewModel } from "./account.viewmodel";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { GridDataTypes } from "src/app/shared/enums/grid-data-types.enum";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";
import { AccountsListRefinerService } from "./accounts-list-refiner.service";
import { Employee } from "src/app/entity-models/employee.entity";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { catchError, map } from "rxjs/operators";
import { FilterService } from "src/app/services/filter.service";
import { CustomerMarker } from "src/app/entity-models/customer-marker.entity";
import { RouteDelineationService } from "src/app/services/delineation-services/route-delineation.service";
import { RouteManagementService } from "src/app/my-day/route-management/route-management.service";
import { RouteStop } from "src/app/entity-models/route-stop.entity";
import { BaseFilterMapService } from "src/app/services/filter-map-services/base-filter-map.service";
import { AccountListZrtFilterService } from "./account-list-zrt-filter.service";
import { CustomerMapService } from "../account-services/customer-map.service";
import { Helper } from "src/app/helpers/helper";
import { GoogleMapLatLng } from "./googleMapsModels";
import { DatasourceDelineationService } from "src/app/services/delineation-services/datasource-delineation.service";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";
import { Px3Rank } from "src/app/entity-models/px3-rank.entity";

export class AccountsListDataViewModel {

    accountOwnershipsDelineationService: AccountOwnershipDelineationService;
    activitiesService: ActivitiesFilterService;
    customerDelineationService: CustomerDelineationService;
    datasourceDelineationService: DatasourceDelineationService;
    employeeDelineationService: EmployeeDelineationService;
    filterService: FilterService;
    pingService: PingService;
    productDelineationService: ProductDelineationService;
    projectDelineationService: ProjectDelineationService;
    refinerService: AccountsListRefinerService;
    routeDelineationService: RouteDelineationService;
    routeManagementService: RouteManagementService;
    zrtFilterService: AccountListZrtFilterService;
    activeRefiners: Refiner[] = [];
    activeSorts: FilterSortDto<AccountsListColumns>[] = [];
    currentRefinerMap: Map<RefinerLocation, string> = new Map();
    customerMarkerDataLoaded = false;
    customerMarkers = new Array<CustomerMarker>();
    customerMarkersVisibleIds = new Array<string>();
    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    employee: Employee;
    filtersLoaded: boolean = false;
    futureRoutes: Route[] = [];
    gridData = new Array<GridData>();
    mapBoundsLower: GoogleMapLatLng;
    mapBoundsUpper: GoogleMapLatLng;
    mapLoading = true;
    mapRebuilding = false;
    pageIndex = 0;
    pageSize = 500;
    pageSizeMax = 5000;
    previousRefinerMap: Map<RefinerLocation, string> = new Map();
    refinerGreaterThans = [RefinerLocation.callOnOrAfterDate];
    refinerLessThans = [RefinerLocation.callOnOrBeforeDate];
    refinerMultiselects = [
        RefinerLocation.states,
        RefinerLocation.counties,
        RefinerLocation.availability,
        RefinerLocation.projects,
        RefinerLocation.wholesalers,
        RefinerLocation.chains,
        RefinerLocation.products,
        RefinerLocation.storeTypes,
        RefinerLocation.callType
    ];
    shouldAllowSearchRetry = false;
    subscriptionRouteCustomerIds: Subscription;
    subscriptionCustomerMarkers: Subscription;
    total: number;

    constructor(
        accountOwnershipsDelineationService: AccountOwnershipDelineationService,
        activitiesService: ActivitiesFilterService,
        customerDelineationService: CustomerDelineationService,
        employeeDelineationService: EmployeeDelineationService,
        datasourceDelineationService: DatasourceDelineationService,
        filterService: FilterService,
        pingService: PingService,
        productDelineationService: ProductDelineationService,
        projectDelineationService: ProjectDelineationService,
        refinerService: AccountsListRefinerService,
        routeDelineationService: RouteDelineationService,
        routeManagementService: RouteManagementService,
        zrtFilterService: AccountListZrtFilterService,
        private px3RankService: Px3DelineationService,
    ) {
        this.accountOwnershipsDelineationService = accountOwnershipsDelineationService;
        this.activitiesService = activitiesService;
        this.customerDelineationService = customerDelineationService;
        this.employeeDelineationService = employeeDelineationService;
        this.datasourceDelineationService = datasourceDelineationService;
        this.filterService = filterService;
        this.pingService = pingService;
        this.productDelineationService = productDelineationService;
        this.projectDelineationService = projectDelineationService;
        this.refinerService = refinerService;
        this.routeDelineationService = routeDelineationService;
        this.routeManagementService = routeManagementService;
        this.zrtFilterService = zrtFilterService;

        if (!this.subscriptionRouteCustomerIds || this.subscriptionRouteCustomerIds.closed) {
            this.subscriptionRouteCustomerIds = this.routeManagementService.observableRouteCustomers.subscribe(
                (customers) => {
                    let stops = new Array<RouteStop>();
                    const futureRouteStops = (this.futureRoutes ?? [])
                        .filter(v => v.id != this.routeManagementService.route?.id)
                        .map(r => r.stops);
                    for (const stopArray of futureRouteStops) {
                        stops = stops.concat(stopArray);
                    }
                    this.gridData.forEach(v => {
                        v.data.isSelected = customers.map(v => v.customerId).includes(v.data.id);
                        v.data.isRouted = stops.map(v => v.customerId).includes(v.data.id);
                    });
                }
            );
        }

        if (!this.subscriptionCustomerMarkers || this.subscriptionCustomerMarkers.closed) {
            this.subscriptionCustomerMarkers = this.routeManagementService.observableCustomerMarkers.subscribe(
                (markers) => {
                    if (markers && markers.length) {
                        this.customerMarkerDataLoaded = true;
                        this.buildVisibleMapMarkers();
                    }
                }
            );
        }
    }

    public get forceOnline(): boolean {
        let empIdRef = this.activeRefiners.find(r => r.dataPropertyName == 'employeeId');
        let zrtRef = this.activeRefiners.find(r => r.dataPropertyName == 'zrt');

        return !!zrtRef || empIdRef?.dataValue !== this.employee.id;
    }

    buildGrid(customers: Customer[], px3Ranks: Map<string, string>): void {
        customers = this.setCustomersOnFutureRoutesAsRouted(customers);
        const gridData = this.pageIndex ? this.gridData.slice() : new Array<GridData>();
        const largestIndex = gridData.length;

        for (const customer of customers ?? []) {
            const isInRoute = this.routeManagementService.routeCustomers.map(v => v.customerId).includes(customer.id);
            const gridItem: GridData = {
                data: new AccountViewModel(customer, px3Ranks.get(customer.px3RankId)),
                detailArrayName: "",
                isExpanded: false,
                index: largestIndex + customers.indexOf(customer)
            };
            gridItem.data.isSelected = isInRoute;
            gridData.push(gridItem);
        }

        this.gridData = gridData;
        this.dataSource = new TableVirtualScrollDataSource(
            this.gridData
        );
    }

    buildVisibleMapMarkers(): void {
        if (!this.customerMarkerDataLoaded) return;
        this.mapLoading = true;
        setTimeout(() => {
            const newMarkers = this.routeManagementService.customerMarkers
                .filter((m) => !this.customerMarkers.map(v => v.customerId).includes(m.customerId) &&
                    CustomerMapService.isCustomerMarkerVisible(m, this.mapBoundsLower, this.mapBoundsUpper));

            for (const marker of newMarkers) {
                this.customerMarkers.push(marker);
            }
            this.customerMarkersVisibleIds = this.customerMarkers.map(v => v.customerId);

            this.mapLoading = false;
            this.mapRebuilding = false;
        }, 0);
    }

    async filterGrid(grid: GridComponent, force?: boolean): Promise<void> {
        if (force) {
            setTimeout(() => {
                grid.onApplyFilter();
                this.total = grid.dataSource?.filteredData?.length ?? 0;
            }, 0);
        }
    }

    async getAccountData(refiners: Refiner[], sorts?: FilterSortDto<AccountsListColumns>[]): Promise<boolean | void> {
        const zrtRefiner = refiners?.find(
            (r) => r.location === RefinerLocation.zrtByArea || r.location === RefinerLocation.zrtByEmployee
        );
        if (!zrtRefiner && !this.customerDelineationService.getOnlineState()) {
            const shouldUseZrtData = !(this.employee.zrt && this.employee.zrt.slice(2, 4) !== '00');
            const method = shouldUseZrtData ? AccountListZrtFilterService.findNodeByZrt : AccountListZrtFilterService.findNodeById;
            const selected = method(this.zrtFilterService.zrts, shouldUseZrtData ? this.employee.zrt : this.employee.id);
            if (selected) {
                this.zrtFilterService.selectedZrts = AccountListZrtFilterService.addSelectedZrts(new Array<SearchZrtDropDown>(), selected);
                const refiner = this.zrtFilterService.createDefaultZrtRefiner(this.employee);
                this.refinerService.checkAndUpdateRefiner(refiner, true);
            }
        }

        this.activeRefiners = this.refinerService.refiners.slice();

        if (sorts) {
            this.activeSorts = sorts;
        }

        const data = await this.getAccountBatch() as boolean;

        return data;
    }



    async getAccountBatch(): Promise<boolean | void> {
        const id = newSequentialId();

        const results = await this.customerDelineationService.getBatch(
            id,
            this.activeRefiners,
            this.pageSize,
            this.pageIndex * this.pageSize,
            this.activeSorts,
            this.forceOnline
        );
        // logic to sort customers added to your current route if in route builder
        let routedCustomers = new Array<Customer>();
        const routeSort = this.activeSorts.filter(v => v.column == AccountsListColumns.inRoute);
        if (routeSort.length) {
            const routedResponse = await this.customerDelineationService.getByIds(
                this.routeManagementService.routeCustomers.map(v => v.customerId)
            );
            if (routedResponse) {
                routedCustomers = routedResponse.values;
            }
        }

        if (results && !results?.isError) {
            const customers = routeSort[0]?.direction == SortDirection.ascending
                ? routedCustomers.concat(results.values.filter(v => !routedCustomers.map(c => c.id).includes(v.id)))
                : results.values.filter(v => !routedCustomers.map(c => c.id).includes(v.id)).concat(routedCustomers);
            this.total = results.count;
            await this.getFutureRoutes();
            this.buildGrid(customers, new Map((await this.px3RankService.getAll()).map(rank => [rank.id, rank.rank])));
            this.shouldAllowSearchRetry = false;
            return true;
        }
        this.shouldAllowSearchRetry = true;
        return false;
    }

    async getIdsForMyList(): Promise<string[]> {
        let rtn = this.dataSource.filteredData.map((gd) => gd.data.id);

        if (this.customerDelineationService.getOnlineState() && rtn.length < this.total) {
            const gridIdResponse = await this.customerDelineationService.getBatch(
                newSequentialId(),
                this.activeRefiners,
                10000,
                this.pageIndex * this.pageSize,
                this.activeSorts
            );
            if (gridIdResponse?.values) {
                const gridIds = gridIdResponse.values.map(v => v.id);
                rtn = rtn.concat(gridIds.filter((v) => !rtn.includes(v)));
            }
        }

        return rtn;
    }

    onExportAsExcel(max?: boolean, button?: HeaderButtonComponent): void {
        const id = newSequentialId();
        const pageSize = max
            ? this.pageSizeMax
            : this.pageSize
        this.customerDelineationService.getAccountListExport(
            id,
            this.activeRefiners,
            pageSize,
            this.pageIndex * this.pageSize,
            this.activeSorts
        ).pipe(
            map((response: any) => {
                if (response) {
                    const contentType: string = response["Content-Type"];
                    const blob = new Blob([response], { type: contentType });
                    saveAs(blob, `Customers_${(moment(new Date())).format("YYYY_MM_DD_hhmmss")}.xlsx`);
                    if (button) {
                        button.isLoading = false;
                    }
                }
            })
            , catchError((err: any) => {
                button.isLoading = false;
                return new Observable()
            })
        ).subscribe();
    }

    resolvePageIndex(index: number, refiners: Refiner[]): boolean {
        this.currentRefinerMap = new Map<RefinerLocation, string>();
        for (const refiner of refiners ?? []) {
            this.currentRefinerMap.set(refiner.location, refiner.dataValue ?? refiner.value);
        }

        const rtn = SharedHelper.compareMaps(
            this.currentRefinerMap,
            this.previousRefinerMap
        );

        if (rtn && this.total != this.gridData?.length && index !== 0) {
            this.pageIndex++;
        } else {
            this.previousRefinerMap = new Map(this.currentRefinerMap);
            this.pageIndex = 0;
        }
        return rtn;
    }

    async setActivitiesFilter(): Promise<void> {
        const projectResponse = await this.projectDelineationService.getDropDown();
        if (!projectResponse) { return; }

        const projects = projectResponse.values;
        this.activitiesService.projects = projects ?? new Array<GenericDropDownDto>();

        const response = await this.customerDelineationService.getWholesalersWithGroupProducts();
        if (!response) { return; }

        const wholesalers = response.values;
        if (wholesalers?.length) {
            wholesalers.sort((a, b) => a.name.localeCompare(b.name));
        }
        this.activitiesService.wholesalers = wholesalers ? wholesalers : new Array<Customer>();

        const chainsResponse = await this.accountOwnershipsDelineationService.getAllInDropDown();
        if (!chainsResponse || !chainsResponse.values) { return; }

        const chains = (chainsResponse.values ?? []).sort((a, b) => a.name < b.name ? -1 : 1);
        this.activitiesService.chains = chains;

        const productResponse = await this.productDelineationService.getDropDown();
        if (!productResponse) { return; }

        const products = productResponse.values;
        this.activitiesService.products = products ?? new Array<GenericDropDownDto>();
    }

    setCustomerOnRoute(index: number): void {
        const gridItem = this.gridData[index]?.data;
        if (!gridItem) return;

        if (gridItem.isSelected && this.routeManagementService.routeCustomers.length < 20) {
            this.routeManagementService.routeCustomers = [
                ...this.routeManagementService.routeCustomers,
                CustomerMapService.getCustomerMapMarker(gridItem as AccountViewModel, undefined, undefined, gridItem.px3RankId)
            ];
            return;
        }
        this.routeManagementService.routeCustomers
            = this.routeManagementService.routeCustomers.filter(v => v.customerId != gridItem.id);
    }

    setDefaultFilter(force?: boolean): void {
        if (!(this.zrtFilterService.employeeZrts?.length) || !this.employee) {
            return;
        }

        if (!this.refinerService.areDefaultsSet || force) {

            let refiner = new Refiner();
            refiner.location = RefinerLocation.isActive;
            refiner.value = BaseFilterMapService.yes;
            refiner.dataPropertyName = "active";
            refiner.dataValue = BaseFilterMapService.yes;
            this.refinerService.checkAndUpdateRefiner(refiner, true);

            refiner = new Refiner();
            refiner.location = RefinerLocation.callable;
            refiner.value = [BaseFilterMapService.yes, BaseFilterMapService.overridden].join(", ");
            refiner.dataPropertyName = "active";
            refiner.dataValue = [BaseFilterMapService.yes, BaseFilterMapService.overridden].join(valueSeparator);
            this.refinerService.checkAndUpdateRefiner(refiner, true);
            refiner = this.zrtFilterService.createDefaultZrtRefiner(this.employee);
            if (refiner) {
                this.refinerService.checkAndUpdateRefiner(refiner, true);
            }
            this.refinerService.areDefaultsSet = true;
        }
        this.filtersLoaded = true;
    }

    async setFilterData(): Promise<void> {
        this.zrtFilterService.areas = await this.filterService.getAreas();
        const zrts = await this.employeeDelineationService.getEmployeeZrts();
        this.zrtFilterService.employeeZrts = zrts?.values ?? new Array<SearchZrtDropDown>();
        this.setDefaultFilter();

        void this.setActivitiesFilter();
    }

    async getFutureRoutes(): Promise<void> {
        const futureRoutesResponse = await this.routeDelineationService.getFutureByEmployee(this.employee);
        this.futureRoutes = futureRoutesResponse?.values ?? [];
    }

    setCustomersOnFutureRoutesAsRouted(customers: Customer[], deletedIds?: string[]): Customer[] {
        const futureRoutes = (this.futureRoutes ?? []).filter(v => v.id != this.routeManagementService.route?.id);
        for (const route of futureRoutes) {
            const customerIds = route.stops.map((stop) => stop.customerId);
            customers
                .filter((customer) =>
                    customerIds.includes(customer.id) &&
                    !deletedIds?.includes(customer.id)
                )
                .forEach((customer) => (customer.isRouted = true));
        }
        return customers;
    }

    getFilterSorts(grid: GridComponent, columnDef: ColumnDef, allColumns: ColumnDef[]): FilterSortDto<AccountsListColumns>[] | undefined {
        const filterSorts = new Array<FilterSortDto<AccountsListColumns>>();
        grid.sort.sortables.forEach((item) => {
            let sortDirection: SortDirection;
            switch ((item as MatSortHeader)._sort.direction) {
                case "asc":
                    sortDirection = SortDirection.ascending;
                    break;
                case "desc":
                    sortDirection = SortDirection.descending;
                    break;
                default:
                    sortDirection = SortDirection.none;
                    break;
            }
            const sortDto = new FilterSortDto<AccountsListColumns>();
            sortDto.direction = sortDirection;
            const headerName = allColumns.find(
                (cd) => cd.dataPropertyName === item.id
            ).headerName;
            if (
                headerName === columnDef.headerName &&
                sortDirection != SortDirection.none
            ) {
                sortDto.column = headerName as AccountsListColumns;
                filterSorts.push(sortDto);
            }
        });
        return filterSorts;
    }

    sortColumn(grid: GridComponent, columnDef: ColumnDef, allColumns: ColumnDef[]): FilterSortDto<AccountsListColumns>[] | undefined {
        if ((this.forceOnline || !this.datasourceDelineationService.hasOfflineAccess) && this.customerDelineationService.getOnlineState()) {
            console.log('sql sort')
            return this.getFilterSorts(grid, columnDef, allColumns);
        } else {
            console.log('manual sort')
            if (grid.sort.direction) {
                let multiplier = 1;

                if (grid.sort.direction === "desc") {
                    multiplier *= -1;
                }
                this.dataSource.data.sort((a, b) => {
                    const itemAData = a.data[columnDef.dataPropertyName];
                    const itemBData = b.data[columnDef.dataPropertyName];
                    if (columnDef.dataType === GridDataTypes.date) {
                        return (itemAData ? new Date(itemAData) : null) <=
                            (itemBData ? new Date(itemBData) : null)
                            ? -1 * multiplier
                            : 1 * multiplier;
                    } else if (columnDef.dataType === GridDataTypes.numberOrString) {
                        //In this case, we need to determine the types of both grid items, and sort
                        //them according to their required sort. Numbers occur higher in the order
                        //than text.
                        const itemAKey: Number | string =
                            Number.isNaN(parseInt(itemAData)) ?
                                itemAData.toString() :
                                new Number(parseInt(itemAData));
                        const itemBKey: Number | string =
                            Number.isNaN(parseInt(itemBData)) ?
                                itemBData.toString() :
                                new Number(parseInt(itemBData));

                        if (itemAKey instanceof Number) {
                            if (itemBKey instanceof Number) {
                                return (itemAKey.valueOf() - itemBKey.valueOf()) * multiplier;
                            } else {
                                return -1;
                            }
                        } else {
                            if (itemBKey instanceof Number) {
                                return 1;
                            } else {
                                return itemAKey.toUpperCase().trim().localeCompare(
                                    itemBKey.toUpperCase().trim()
                                ) * multiplier;
                            }
                        }
                    } else {
                        return (itemAData ?? "").toUpperCase().trim().localeCompare(
                            (itemBData ?? "").toUpperCase().trim()
                        ) * multiplier;
                    }
                });
            } else {
                grid.dataSource.data = this.gridData.slice();
            }
            for (let row = 0; row < this.dataSource.data.length; row++) {
                grid.dataSource.data[row].index = row;
            }
            if (grid) {
                grid.dataSource.sort = grid.sort;
                grid.grid.renderRows();
                grid.dataSourceChange.emit(this.dataSource);
            }
            return undefined;
        }
    }

    sortOneColumn(columnDef: ColumnDef, allColumns: ColumnDef[], direction: SortDirection): FilterSortDto<AccountsListColumns>[] {
        const filterSorts = new Array<FilterSortDto<AccountsListColumns>>();

        const sortDto = new FilterSortDto<AccountsListColumns>();
        sortDto.direction = direction;
        if (
            direction != SortDirection.none
        ) {
            sortDto.column = columnDef.headerName as AccountsListColumns;
            filterSorts.push(sortDto);
        }
        return filterSorts;
    }

    unsubscribe(): void {
        if (this.subscriptionRouteCustomerIds && !this.subscriptionRouteCustomerIds.closed) {
            this.subscriptionRouteCustomerIds.unsubscribe();
        }
    }
}
