<div class="card card-project-panel m-0 d-flex flex-column flex-grow-1">
    <div class="card-header">
        Order Dates
    </div>
    <div class="card-body p-0 d-flex flex-column flex-grow-1">
            <div class="d-flex flex-column flex-grow-1">
                <div class="row">
                <div *ngIf="projectStateService.project?.shareSiteLink" class="col site-link">
                    <a [href]="projectStateService.project.shareSiteLink" target="_blank" rel="noopener noreferrer">
                        {{projectStateService.project.shareSiteLink}}
                    </a>
                </div>
            </div>
            <div class="row date-row">
                <div class="col-3 h-center"><b>Order Date 1:</b></div>
                <div class="col-9 h-center date-data">
                    {{dateOne | date: dateFormat}}
                </div>
            </div>
            <div class="row date-row">
                <div class="col-3 h-center"><b>Order Date 2:</b></div>
                <div class="col-9 h-center date-data">
                    {{dateTwo | date: dateFormat}}
                </div>
            </div>
            <div class="row date-row">
                <div class="col-3 h-center"><b>Order Date 3:</b></div>
                <div class="col-9 h-center date-data">
                    {{dateThree | date: dateFormat}}
                </div>
            </div>
            <div class="row date-row">
                <div class="col-3 h-center"><b>Order Date 4:</b></div>
                <div class="col-9 h-center date-data">
                    {{dateFour | date: dateFormat}}
                </div>
            </div>
        </div>
    </div>
</div>
