import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    ContactDto, ContactSyncCommand, GenericVersionResponseDto,
    SharedHelper, SyncCommandTypes, SystemInformationKeys,
    VersionResponseDto
} from "shield.shared";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { ContactConverterService } from "src/app/services/converter-services/contact-converter.service";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { SyncLevel } from "../sync-enums/sync-level.enum";

@Injectable()
export class ContactDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.None;
    offlineRepSyncType = SyncLevel.Required;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.contact;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing contacts...");

        await this.pullData();

        this.log("Done syncing contacts...");
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/contacts/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("Contact data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Contact data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.contactPullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;
        try {
            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;

                const response = await this.http
                    .get<GenericVersionResponseDto<ContactDto[]>>(`/api/contacts${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                this.log(
                    `Downloaded ${thisBatchSize} contacts, saving to IndexedDB...`
                );

                const contacts = response.values.map((c) => {
                    const contact = ContactConverterService.contactDtoToContact(c);
                    contact.hasServerProcessed = 1;
                    return contact;
                });

                await this.dbService.contacts.bulkPut(contacts);
                this.log(`  Saved  ${contacts.length} contacts.`);

                this.lastSyncVersion = response.maxVersion;
            } while (thisBatchSize > 0);

            this.log("Done saving contact data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing contacts", e);
        }
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<ContactSyncCommand>(
            SyncCommandTypes.contact,
            async (message) => {
                let contact = await this.dbService.contacts.get(
                    message.payload.id
                );

                if (contact) {
                    let dto = ContactConverterService.contactToContactDto(contact);
                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }
}
