import { CallReceiptDto, ReceiptDto } from "shield.shared";
import { Receipt } from "./receipt";
import { TransactionLineItem } from "./transaction-line-item.entity";

export class Transaction {
    id: string;
    callId: string;
    callDate: Date;
    customerId: string;
    retailNumber: string | null;
    customerName: string | null;
    customerAddress1: string | null;
    customerAddress2: string | null;
    customerCity: string | null;
    customerState: string | null;
    customerZip: string | null;
    customerCounty: string | null;
    receiptNumber: string;
    salesTotal: number;
    netTotal: number;
    gratisTotal: number;
    gratisPercentage: number;
    gratisId: string | null;
    gratisNumber: string | null;
    createdUtcDateTime: Date;
    createdUserId: string;
    createdUserZrt: string | null;
    createdUserFullName: string;
    modifiedUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string;

    transactionLineItems: TransactionLineItem[];
    callReceipts: CallReceiptDto[];
    receipts: Receipt[];
}
