import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { GenericResponseDto } from "shield.shared";
import { Project } from "src/app/entity-models/project.entity";
import { ProjectDelineationService } from "./delineation-services/project-delineation.service";
import { SnackbarService } from "./snackbar.service";

@Injectable()
export class ProjectStateService {
    isReadOnly = true;

    lastSavedProject: Project;

    private _project: Project = null;
    private _projectSubject: BehaviorSubject<Project> = new BehaviorSubject(
        this._project
    );

    ignoreProjectSubscription = false;

    get project(): Project {
        return this._project;
    }
    set project(value: Project) {

        this._project = value;
        this._projectSubject.next(value);
    }
    observableProject: Observable<Project> = this._projectSubject.asObservable();

    constructor(private projectDelineationService: ProjectDelineationService,
        private snackbarService: SnackbarService) { };

        async deleteProject(projectId: string): Promise<GenericResponseDto<Project>> {
            return this.projectDelineationService.discardProject(projectId);
        }

    async saveProjectAndNotify(): Promise<GenericResponseDto<Project>> {
        let rtn: GenericResponseDto<Project> = null;
        if (!this.project.createdUtcDateTime) {
            this.project.createdUtcDateTime = new Date();
        }
        rtn = await this.projectDelineationService.upsertProject(this.project, true);
        if (rtn && !rtn.isError) {
            this.lastSavedProject = Object.assign({}, rtn.values);
        }

        this._projectSubject.next(null);
        return rtn;
    }

    notify(): void {
        this._projectSubject.next(this._project);
    }

    async loadAndNotify(id: string, isStaging?: boolean): Promise<void> {
        const savedResponse = await this.projectDelineationService.getProjectById(id);
        if (!savedResponse) return;

        if (isStaging) {
            const stagingResponse = await this.projectDelineationService.getProjectById(id, isStaging);
            if (stagingResponse?.id === id && !!stagingResponse.values) {
                this.project = stagingResponse.values;
                this.isReadOnly = false;
            }

        } else {
            this.project = savedResponse.values;
            this.isReadOnly = true;
        }

        this.lastSavedProject = savedResponse.values ? Object.assign({}, savedResponse.values) : undefined;
        this._projectSubject.next(this.project);
    }
}
