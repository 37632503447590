import { AfterContentChecked, Component, Type, ViewChild } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterService } from "src/app/services/filter.service";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { RefinerLocation, valueSeparator } from "shield.shared";
import { ProjectAssignmentsViewmodel } from "./project-assignments.viewmodel";
import { StateViewmodel } from "../../viewmodels/state.viewmodel";
import { CountyViewmodel } from "../../viewmodels/county.viewmodel";
import { ZrtTreeViewComponent } from "../../zrt-tree-view/zrt-tree-view.component";
import { AngularMultiSelect } from "angular2-multiselect-dropdown";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { ActivitiesFilterService } from "src/app/services/activities-filter.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: "app-project-assignments-filter",
    templateUrl: "./project-assignments-filter.component.html",
    styleUrls: ["./project-assignments-filter.component.scss"]
})
export class ProjectAssignmentsFilterComponent extends FilterBaseComponent implements AfterContentChecked {
    //view childs
    @ViewChild("stateSelect") stateSelect: MatSelect;
    @ViewChild("countySelect") countySelect: MatSelect;
    @ViewChild("zrtTree") zrtTree: ZrtTreeViewComponent;
    @ViewChild("chainSelect") chainSelect: AngularMultiSelect;

    //public vars
    contentComponent: Type<ProjectAssignmentsFilterComponent>;
    icon = "layers";
    name = "Project Assignments";
    viewmodel = new ProjectAssignmentsViewmodel(
        this.filterService
    );

    zrtTreeInitialized: boolean = false;

    constructor(
        private filterService: FilterService,
        private activitiesFilterService: ActivitiesFilterService
    ) {
        super();
    }

    ngAfterContentChecked(): void {
        if (!this.isInitialized && this.chainSelect && this.zrtTree && this.zrtFilterService) {
            if (!this.zrtTreeInitialized) {
                this.zrtTreeInitialized = true;
                this.zrtTree.zrtFilterService = this.zrtFilterService;
            }

            if (
                !this.viewmodel.chainsSubscription ||
                this.viewmodel.chainsSubscription.closed
            ) {
                this.viewmodel.chainsSubscription = this.activitiesFilterService.observableChains.pipe(untilDestroyed(this)).subscribe(
                    (chains) => {
                        if (chains && this.activitiesFilterService.chainsLoaded) {
                            this.chainSelect.settings.noDataLabel = "No chains found.";
                            this.viewmodel.chains = chains;

                            const selected = new Array<AccountOwnership>();
                            for (const chain of this.viewmodel.selectedChains) {
                                if (chains.map((c) => c.ownerCode).includes(chain.ownerCode)) {
                                    selected.push(chain);
                                }
                            }
                            this.viewmodel.selectedChains = selected;
                            if (!this.isInitialized) {
                                this.setInitialized();
                            }
                        }
                    }
                );
            }
        }
    }

    //events
    onAddAccountRefiner(event?: KeyboardEvent) {
        this.addStringValueRefiner(
            event,
            RefinerLocation.account,
            this.viewmodel.storeNameCustomerId
        );
    }

    onAddCityRefiner(event?: KeyboardEvent): void {
        this.addStringValueRefiner(
            event,
            RefinerLocation.city,
            this.viewmodel.cityInput
        );
    }

    onAddStreetRefiner(event?: KeyboardEvent) {
        this.addStringValueRefiner(
            event,
            RefinerLocation.street,
            this.viewmodel.streetInput
        );
    }

    onAddZipRefiner(event?: KeyboardEvent): void {
        this.addStringValueRefiner(
            event,
            RefinerLocation.zipCodes,
            this.viewmodel.zipInput
        );
    }

    openedChainSelect() {
        this.viewmodel.isChainsStale = true;
        this.viewmodel.selectedChainsHold = this.viewmodel.selectedChains;
    }

    closedChainSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.chains;
        refiner.value = this.viewmodel.selectedChains?.length < 2
            ? this.viewmodel.selectedChains
                .map((chain) => chain.name)
                .join(", ")
            : this.viewmodel.selectedChains?.length + " Selected";
        refiner.dataPropertyName = "ownerCode";
        refiner.dataValue = this.activitiesFilterService.getChainDataValues(this.viewmodel.selectedChains, true);

        if (this.viewmodel.selectedChainsHold === this.viewmodel.selectedChains) {
            this.viewmodel.isChainsStale = false;
        }

        this._refinerService.checkAndUpdateRefiner(refiner);
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.zrtByArea:
                    this.zrtFilterService.applyRefiner(refiner);
                    break;
                case RefinerLocation.account:
                    if (refiner.value !== this.viewmodel.storeNameCustomerId) {
                        this.viewmodel.storeNameCustomerId = refiner.value;
                    }
                    break;
                case RefinerLocation.street:
                    if (refiner.value !== this.viewmodel.streetInput) {
                        this.viewmodel.streetInput = refiner.value;
                    }
                    break;
                case RefinerLocation.city:
                    if (refiner.value !== this.viewmodel.cityInput) {
                        this.viewmodel.cityInput = refiner.value;
                    }
                    break;
                case RefinerLocation.states: {
                    const selectedStates = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedStates = new Array<StateViewmodel>();

                    if (selectedStates) {
                        for (const state of selectedStates) {
                            const found = this.viewmodel.selectedStates.find(
                                (ss) => ss.state.id === state.trim()
                            );
                            if (found) {
                                rtnSelectedStates.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedStates = rtnSelectedStates;
                    break;
                }
                case RefinerLocation.zipCodes:
                    if (refiner.value !== this.viewmodel.zipInput) {
                        this.viewmodel.zipInput = refiner.value;
                    }
                    break;
                case RefinerLocation.counties: {
                    const selectedCounties = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedCounties = new Array<CountyViewmodel>();

                    if (selectedCounties) {
                        for (const county of selectedCounties) {
                            const found = this.viewmodel.selectedCounties.find(
                                (sc) => sc.county.id === county.trim()
                            );
                            if (found) {
                                rtnSelectedCounties.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedCounties = rtnSelectedCounties;
                    break;
                }
                case RefinerLocation.chains:
                    const selectedChainCodes = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedChains = new Array<AccountOwnership>();

                    if (selectedChainCodes) {
                        for (const code of selectedChainCodes) {
                            const found = this.viewmodel.chains.find(
                                (c) => c.ownerCode === code.trim()
                            );
                            if (found) {
                                rtnSelectedChains.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedChains = rtnSelectedChains;
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        //TODO: temp until we implement zrt refiners
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.account;
        const accountRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.account
        );
        if (accountRefiner) {
            this.onInputChange(accountRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.street;
        const streetRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.street
        );
        if (streetRefiner) {
            this.onInputChange(streetRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.states;
        const stateRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.states
        );
        if (stateRefiner) {
            this.onInputChange(stateRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.counties;
        const countyRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.counties
        );
        if (countyRefiner) {
            this.onInputChange(countyRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.city;
        const cityRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.city
        );
        if (cityRefiner) {
            this.onInputChange(cityRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.zipCodes;
        const zipRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.zipCodes
        );
        if (zipRefiner) {
            this.onInputChange(zipRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.chains;
        const chainsRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.chains
        );
        if (chainsRefiner) {
            this.onInputChange(chainsRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }

    //public methods
    compareCountyOptions(a: CountyViewmodel, b: CountyViewmodel): boolean {
        return (
            a?.county?.name === b?.county?.name
            && a?.county?.stateId === b?.county.stateId
        )
    }

    compareRefinerOptions(a: Refiner, b: Refiner): boolean {
        return (
            a?.dataPropertyName === b?.dataPropertyName &&
            a?.location === b?.location &&
            a?.value === b?.value &&
            a?.dataValue === b?.dataValue
        );
    }

    countyChange(): void {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.counties;
        refiner.value = this.viewmodel.selectedCounties
            .map((vm) => vm.county.name)
            .join(", ");
        refiner.dataPropertyName = "county";
        refiner.dataValue = this.viewmodel.selectedCounties
            .map((vm) => vm.county.id)
            .join(valueSeparator);

        this._refinerService.checkAndUpdateRefiner(refiner, true);
    }

    openedStateChange(): void {
        if (this.stateSelect && !this.stateSelect.panelOpen) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.states;
            refiner.value = this.viewmodel.selectedStates
                .map((vm) => vm.state.shortCode)
                .join(", ");
            refiner.dataPropertyName = "state";
            refiner.dataValue = this.viewmodel.selectedStates
                .map((vm) => vm.state.id)
                .join(valueSeparator);

            this._refinerService.checkAndUpdateRefiner(refiner, true);

            const remainingSelectedCounties = this.viewmodel.adjustCountySelectOptions();

            if (remainingSelectedCounties?.length > 0 ) {
                const refinerUpdate = new Refiner();
                refinerUpdate.location = RefinerLocation.counties;
                refinerUpdate.value = remainingSelectedCounties.map((rsc) => rsc.county.name).join(", ");
                this._refinerService.updateRefinerValue(refinerUpdate)

            } else {
                this._refinerService.removeRefinerByLocation(RefinerLocation.counties);
            }
        }
        this.viewmodel.dropdownSettings.disabled = !this.viewmodel.selectedStates?.length;
        this.viewmodel.dropdownSettings = Object.assign({}, this.viewmodel.dropdownSettings);

    }

    private addStringValueRefiner(keyEvent: KeyboardEvent, location: RefinerLocation, value: string): void {
        if (keyEvent) {
            this._refinerService.onInputChange(location, value);
        }

        if (value) {
            const refiner = new Refiner();
            refiner.location = location;
            refiner.value = value;
            refiner.dataValue = this._refinerService.buildStringDataValue(value);
            this._refinerService.checkAndUpdateRefiner(refiner, true, false);
        } else {
            this._refinerService.removeRefinerByLocation(location, true, false);
        }
    }
}
