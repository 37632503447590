import {
    AfterContentChecked,
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChildren,
    ViewContainerRef
} from "@angular/core";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { FilterLocation } from "src/app/enums/filter-location";
import { RefinerServiceBase } from "../../refiner-service-base";
import { FilterBaseComponent } from "../filter-base/filter-base.component";

@Component({
    selector: "app-filter-container",
    templateUrl: "./filter-container.component.html",
    styleUrls: ["./filter-container.component.scss"]
})
export class FilterContainerComponent implements AfterContentChecked {
    //view children
    @ViewChildren("containers", { read: ViewContainerRef })
    containers: QueryList<ViewContainerRef>;

    //inputs
    private _filters: FilterAndParams[] = [];
    @Input()
    get filters(): FilterAndParams[] {
        return this._filters;
    }
    set filters(value: FilterAndParams[]) {
        this._filters = value;
    }

    @Input()
    expandPanelsOnInit = false;

    @Input()
    filterLocation: FilterLocation;

    @Input()
    refinerService: RefinerServiceBase;

    @Input()
    isSearchDisabled = false;

    //outputs
    @Output() reset = new EventEmitter();
    @Output() search = new EventEmitter();
    @Output() filtersIntialized = new EventEmitter();

    //public vars
    componentRefs: ComponentRef<FilterBaseComponent>[] = [];
    filtersCreated = false;
    panelOpenState = true;

    constructor(private resolver: ComponentFactoryResolver) {}

    ngAfterContentChecked(): void {
        this.createComponents();
    }

    //events
    onReset(): void {
        this.reset.emit();
    }

    onSearch(): void {
        this.search.emit();
    }

    //public methods
    createComponents() {
        if (!this.filtersCreated && this.containers && this.filters?.length > 0 ) {
            const containers = this.containers.toArray();
            if (containers.length > 0) {
                for (let i = 0; i < containers?.length; i++) {
                    const factory: ComponentFactory<FilterBaseComponent> = this.resolver.resolveComponentFactory<FilterBaseComponent>(
                        this.filters[i].filter
                    );
                    const componentRef = containers[i].createComponent(factory);
                    componentRef.instance.refinerService = this.refinerService;
                    componentRef.instance.filterLocation = this.filterLocation;
                    componentRef.instance.zrtFilterService = this.filters[i].zrtFilterService;
                    this.componentRefs.push(componentRef);
                    this.filtersCreated = true;
                }
            }
            this.waitForFiltersToIntialize();
        }
    }

    waitForFiltersToIntialize(): void {
        const isInitialized = !this.componentRefs.some((cr) => !cr.instance.isInitialized)
        if (isInitialized) {
            this.filtersIntialized.emit();
        } else {
            setTimeout(() => {
               this.waitForFiltersToIntialize()
            }, 100);
        }
    }
}
