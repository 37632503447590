import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { RefinerLocation, EntityObjectNames, EntityPropertyNames, valueSeparator } from "shield.shared";
import { tap, map, debounceTime } from "rxjs/operators";

export abstract class RefinerServiceBase {
    //protected vars
    protected _refinerInputChange: Refiner;
    protected _refinerInputChangeSubject: Subject<Refiner> = new Subject();
    protected _refiners: Refiner[] = [];
    protected _refinerSubject: BehaviorSubject<Refiner[]> = new BehaviorSubject(
        this._refiners
    );
    protected _stagedRefiners = new BehaviorSubject<Refiner[]>(this._refiners);

    public areDefaultsSet: boolean = false;

    //public vars
    //What are they
    get refiners(): Refiner[] {
        return this._refiners;
    }

    //When do they change
    refiners$: Observable<Refiner[]> = this._refinerSubject.asObservable();
    stagedRefiners$: Observable<(Refiner & {shouldPreventRemoval: boolean})[]> = this._stagedRefiners.asObservable().pipe(
        debounceTime(100),
        map(refiners => refiners.map(r => ({...r, shouldPreventRemoval: this.shouldPreventRemoval(r)})))
    );
    refinerInputChange$: Observable<Refiner> = this._refinerInputChangeSubject.asObservable();

    //events
    onInputChange(location: RefinerLocation, value: string, dataValue?: string, shouldSearchWhenCleared = true): void {
        const refiner = new Refiner();
        refiner.location = location;
        refiner.value = value;
        refiner.dataValue = dataValue ?? value;
        refiner.shouldSearchWhenCleared = shouldSearchWhenCleared;
        this._refinerInputChangeSubject.next(refiner);
    }

    //public methods
    addRefiner(location: RefinerLocation, value: string, dataPropertyName: string, dataValue?: string, dataObjectName?: EntityObjectNames, dtoPropertyName?: EntityPropertyNames): void {
        const refiner = new Refiner();
        refiner.location = location;
        refiner.value = value;
        refiner.dataPropertyName = dataPropertyName;
        refiner.dataValue = dataValue;
        refiner.dtoObjectName = dataObjectName;
        refiner.dtoPropertyName = dtoPropertyName;
        this.addRefiners(refiner);
    }

    addRefiners(...refiners: Refiner[]): void {
        for (const refiner of refiners) {
            if (!this.doesRefinerExist(refiner)) {
                if (refiner?.location && refiner?.value) {
                    if (refiner.location === RefinerLocation.zrt || refiner.location === RefinerLocation.zrtByArea || refiner.location === RefinerLocation.zrtByEmployee) {
                        this.removeRefinerByLocation(RefinerLocation.zrt, false);
                        this.removeRefinerByLocation(RefinerLocation.zrtByArea, false);
                        this.removeRefinerByLocation(RefinerLocation.zrtByEmployee, false);
                    } else {
                        this.removeRefinerByLocation(refiner.location, false);
                    }                    
                    this._refiners.push(refiner);
                    this._stagedRefiners.next(this._refiners);
                    this.onInputChange(refiner.location, refiner.value, refiner.dataValue);
                }
            }
        }
    }

    buildStringDataValue(value: string): string {
        return value ? value.split(",").map(v => v.trim()).join(valueSeparator) : "";
    }

    checkAndUpdateRefiner(refiner: Refiner, isDataValue?: boolean, shouldSearch?: boolean): void {
        let hasValue = false;
        if(isDataValue) {
            if(refiner.dataValue && refiner.dataValue.length > 0) {
                hasValue = true;
            }
        }
        else {
            if(refiner.value && refiner.value.length > 0) {
                hasValue = true;
            }
        }
        if (!this.doesRefinerExist(refiner)) {
            if (this.doesRefinerLocationExist(refiner.location)) {
                if(!hasValue){
                    this.removeRefinerByLocation(refiner.location, true, shouldSearch);
                }
            }
            if(hasValue) {
                this.addRefiners(refiner);
            }
        }
    }

    resetRefiners(): void {
        this.areDefaultsSet = false;
        this.clearRefiners();
    }

    clearRefiners(): void {
        this._refiners = this._refiners.filter((refiner) => {
            return this.shouldPreventRemoval(refiner)
        });
        this._refinerSubject.next(this._refiners);
        this._stagedRefiners.next(this._refiners);
    }

    doesRefinerExist(refiner: Refiner): boolean {

        const rtn =
            this._refiners.filter(
                (myRefiner) =>
                    myRefiner.location === refiner.location &&
                    myRefiner.value === refiner.value &&
                    myRefiner.dataValue === refiner.dataValue &&
                    myRefiner.dataPropertyName === refiner.dataPropertyName &&
                    myRefiner.dtoObjectName === refiner.dtoObjectName
            ).length > 0


        return rtn;
    }

    doesRefinerLocationExist(refinerLocation: RefinerLocation): boolean {
        return this.refiners.findIndex((refiner) => refinerLocation === refiner.location) !== -1;
    }

    getRefinerByLocation(loc: RefinerLocation): Refiner | undefined {
        return this.refiners.find((refiner) => refiner.location === loc);
    }

    removeRefiner(refiner: Refiner): void {
        if (refiner && !this.shouldPreventRemoval(refiner)) {
            this.removeRefinerByLocation(refiner.location);
        }
    }

    replaceRefiners(refiners: Refiner[]): void {
        const refinersToRemove = this._refiners.filter((rtr) => !refiners.map((r) => r.location).includes(rtr.location));
        for (const removed of refinersToRemove) {
            const shouldSearch = false;
            this.removeRefinerByLocation(removed.location, false, shouldSearch);
        }
        this.addRefiners(...refiners);
    }

    removeRefinerByLocation(
        refinerLocation: RefinerLocation,
        shouldEmit: boolean = true,
        shouldSearch: boolean = true,
        shouldEmptyEmit: boolean = false
    ): void {
        if (refinerLocation) {
            const index = this._refiners.findIndex(
                (myRefiner) => myRefiner.location === refinerLocation
            );
            if (index !== -1) {
                this._refiners.splice(index, 1);
                this._stagedRefiners.next(this._refiners);
                if (shouldEmit) {
                    this.onInputChange(refinerLocation, "", "", shouldSearch);
                }
            } else if (shouldEmptyEmit) {
                this.onInputChange(refinerLocation, "", "", shouldSearch);
            }
        }
    }

    updateRefinerValue(refiner: Refiner): void {
        if (refiner?.value) {
            const found = this.refiners.find((myRefiner) => myRefiner.location === refiner.location);

            if (found) {
                this.onInputChange(found.location, refiner.value, refiner.dataValue);
            }
        }
    }

    shouldPreventRemoval(refiner: Refiner): boolean {
        return false;
    }
}
