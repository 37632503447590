import { Injectable } from "@angular/core";
import { ContactOfflineService } from "../offline-services/contact-offline.service";
import { Contact } from "../../entity-models/contact.entity";
import { ContactOnlineService } from "../online-services/contact-online.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { SnackbarService } from "../snackbar.service";
import { GenericResponseDto, SharedHelper } from "shield.shared";
import { DatabaseService } from "../database.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class ContactDelineationService extends DelineationContext<Contact, string> {

    constructor(private contactOnlineService: ContactOnlineService,
        private contactOfflineService: ContactOfflineService,
        snackbarService: SnackbarService,
        protected datasourceDelineationService: DatasourceDelineationService,
        protected dbService: DatabaseService){
            super(dbService, datasourceDelineationService, snackbarService);
        }

    async getById(id: string): Promise<GenericResponseDto<Contact | undefined>> {
        const offline = (key: string) => {
            return this.contactOfflineService.getById(key);
        }
        const online = (key: string) => {
            return this.contactOnlineService.getById(key);
        }
        const unProcessedDelegate = async (key: string, hasOfflineAccess: boolean) => this.getUnprocessedById(key, hasOfflineAccess);
        const response = await this.datasourceDelineationService.makeCall<string, Contact>(id, offline, online,
            (onlineContact: Contact, notSyncedOfflineContact: Contact) => this.compareContact(onlineContact, notSyncedOfflineContact),
            unProcessedDelegate);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getByCustomerId(id: string): Promise<GenericResponseDto<Contact[] | undefined>> {
        const offline = (key: string) => {
            return this.contactOfflineService.getByCustomerId(key);
        }
        const online = (key: string) => {
            return this.contactOnlineService.getByCustomerId(key);
        }

        const response = await this.datasourceDelineationService.makeCall<string, Contact[]>(
            id, offline, online, this.compareContactArray,
            (key: string, hasOfflineAccess: boolean) => this.getUnprocessedContactsByCustomerId(key, hasOfflineAccess));

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async save(contact: Contact): Promise<GenericResponseDto<Contact | undefined>> {
        const offline = (key: Contact) => {
            return this.contactOfflineService.save(key);
        }
        const online = async (key: Contact) => {
            return await this.contactOfflineService.save(key);;
        }
        const response = await this.datasourceDelineationService.makeCall<Contact, Contact>(contact, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    private async getUnprocessedById(key: string, hasOfflineAccess: boolean): Promise<Contact> {
        const unprocessed = await this.contactOfflineService.getUnprocessedById(key);

        void this.cleanUpContacts(hasOfflineAccess);
        return unprocessed;
    }

    private async getUnprocessedContactsByCustomerId(key: string, hasOfflineAccess: boolean): Promise<Contact[]> {
        const unprocessed = await this.contactOfflineService.getUnprocessedByCustomerId(key);

        void this.cleanUpContacts(hasOfflineAccess);
        return unprocessed;
    }

    private async cleanUpContacts(hasOfflineAccess: boolean): Promise<void> {
        if (hasOfflineAccess) return;

        const processed = await this.dbService.contacts.where("hasServerProcessed").equals(1).toArray();

        if (processed.length) {
            this.dbService.contacts.bulkDelete(processed.map(v => v.id));
        }
    }

    private async compareContact(
        onlineContact: Contact,
        notSyncedOfflineContact: Contact
    ) : Promise<Contact> {
        if (!onlineContact) {
            return notSyncedOfflineContact;
        }
        const results = await this.compareContactArray(!!onlineContact ? [onlineContact] : null
            , !!notSyncedOfflineContact ? [notSyncedOfflineContact] : null);
        return results[0];
    }

    private async compareContactArray(
        onlineContacts: Contact[],
        notSyncedOfflineContacts: Contact[]
    ): Promise<Contact[]> {
        const rtn = new Array<Contact>();
        const offlineContactMaps = new Map(notSyncedOfflineContacts?.map((entry) => [entry.id, entry]));

        for (const onlineContact of (onlineContacts ?? [])) {
            const offlineContact = offlineContactMaps?.get(onlineContact.id);
            if (offlineContact) {
                offlineContactMaps.delete(offlineContact.id);
                if (
                    (onlineContact.modifiedUtcDateTime?.getTime() ?? onlineContact.createdUtcDateTime?.getTime()) >=
                    (offlineContact.modifiedUtcDateTime?.getTime() ?? offlineContact.createdUtcDateTime?.getTime())
                ) {
                    rtn.push(onlineContact);
                } else {
                    rtn.push(offlineContact);
                }
            } else {
                rtn.push(onlineContact);
            }
        }

        // What is remaining in the offlineContactMaps are entries that don't exist in the online version
        // Unprocessed entries are placed at the top
        for (const offlineContactMap of offlineContactMaps.entries()) {
            rtn.push(offlineContactMap[1]);
        }

        return rtn;
    }
}
