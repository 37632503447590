import { ElementRef } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn } from "@angular/forms";
import * as moment from "moment";
import { Moment } from "moment";
import { BehaviorSubject, fromEvent, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { RefinerLocation, valueSeparator } from "shield.shared";
import { Helper } from "src/app/helpers/helper";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { ProjectStateService } from "src/app/services/project-state-service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { ProjectMetricsSummaryOptions } from "../project-enums/project-metrics-summary-options.enum";
import { ProjectMetricsGridViewModel } from "./project-metrics-grid.viewmodel";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { ProjectMetricsRefinerService } from "./project-metrics-refiner.service";
import { ProjectMetricsZrtFilterService } from "./project-metrics-zrt-filter.service";
import { ZrtTreeViewComponent } from "src/app/shared/zrt-tree-view/zrt-tree-view.component";

export class ProjectMetricsViewModel {
    projectDelineationService: ProjectDelineationService;
    dateForm: FormGroup;
    dateFormat: string = MY_DATE_FORMATS.display.dateInput;
    employeeDelineationService: EmployeeDelineationService;
    endDateDefault: moment.Moment;
    endDateInput: ElementRef;
    formBuilder: FormBuilder;
    gridData = new Array<ProjectMetricsGridViewModel>();
    initialized = false;
    pleaseWaitService: PleaseWaitService;
    projectSubscription: Subscription;
    projectMetricsRefinerService: ProjectMetricsRefinerService;
    searchInput: ElementRef;
    searchText: string;
    searchValueSubscription: Subscription;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    snackbar: SnackbarService;
    startDateDefault: moment.Moment;
    startDateInput: ElementRef;
    stateService: ProjectStateService;
    summaryOptions = ProjectMetricsSummaryOptions;
    summaryInput = ProjectMetricsSummaryOptions.zrt;
    total: ProjectMetricsGridViewModel;
    zrtFilterService: ProjectMetricsZrtFilterService;
    zrtSelectionSubscription: Subscription;

    constructor(
        employeeDelineationService: EmployeeDelineationService,
        formBuilder: FormBuilder,
        projectDelineationService: ProjectDelineationService,
        pleaseWaitService: PleaseWaitService,
        projectMetricsRefinerService: ProjectMetricsRefinerService,
        stateService: ProjectStateService,
        snackbar: SnackbarService,
        zrtFilterService: ProjectMetricsZrtFilterService
    ) {
        this.employeeDelineationService = employeeDelineationService;
        this.formBuilder = formBuilder;
        this.dateForm = this.formBuilder.group({
            endDate: ["", [this.lessThanStartDate()]],
            startDate: ["", [this.greaterThanEndDate()]]
        });
        this.projectDelineationService = projectDelineationService;
        this.pleaseWaitService = pleaseWaitService;
        this.projectMetricsRefinerService = projectMetricsRefinerService;
        this.stateService = stateService;
        this.snackbar = snackbar;
        this.zrtFilterService = zrtFilterService;
    }

    initialize(
        startDateInput: ElementRef,
        endDateInput: ElementRef,
        zrtTree: ZrtTreeViewComponent
    ): void {
        this.startDateInput = startDateInput;
        this.endDateInput = endDateInput;

        if (zrtTree) {
            zrtTree.zrtFilterService = this.zrtFilterService;
        }

        if (
            !this.projectSubscription ||
            this.projectSubscription.closed
        ) {
            this.projectSubscription = this.stateService.observableProject.subscribe(
                async (project) => {
                    if (project && !this.initialized) {
                        this.initialized = true;
                        this.setDefaultDates(project.startDate, project.endDate);
                        await this.setZrtFilterData((project.projectEmployees ?? []).map(pe => pe.employeeId));
                        await this.getMetrics();
                    }
                }
            );
        }

        if (
            !this.zrtSelectionSubscription ||
            this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription = this.zrtFilterService.observableSelectedZrts.subscribe((selectedZrts) => {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.zrtByEmployee;
                refiner.value = selectedZrts
                    .map((vm) => vm.id)
                    .join(valueSeparator);
                this.projectMetricsRefinerService.checkAndUpdateRefiner(refiner);

                void this.getMetrics();
            });
        }
    }

    onAddEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.endDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, this.dateFormat) &&
            !this.dateForm.controls.endDate.errors &&
            this.projectMetricsRefinerService.getRefinerByLocation(RefinerLocation.endDate)
                ?.value !== date
        ) {
            this.projectMetricsRefinerService.onInputChange(
                RefinerLocation.endDate,
                this.endDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.endDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.projectMetricsRefinerService.addRefiner(
                    RefinerLocation.endDate,
                    this.dateForm.controls.endDate.value?.format(
                        this.dateFormat
                    ),
                    "endDate"
                );
            }
        } else {
            if (event?.type === "blur") {
                if (event?.type === "blur") {
                    this.dateForm.controls.endDate.setValue(this.endDateDefault);
                    this.projectMetricsRefinerService.onInputChange(
                        RefinerLocation.endDate,
                        this.endDateInput.nativeElement.value
                    );
                }
            }
        }
    }    

    blockNonDateCharacters(event: any)
    {
        return(event.charCode > 46 && event.charCode < 58);
    }

    onAddStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.startDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, this.dateFormat) &&
            !this.dateForm.controls.startDate.errors &&
            this.projectMetricsRefinerService.getRefinerByLocation(RefinerLocation.startDate)
                ?.value !== date
        ) {
            this.projectMetricsRefinerService.onInputChange(
                RefinerLocation.startDate,
                this.startDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.startDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.projectMetricsRefinerService.addRefiner(
                    RefinerLocation.startDate,
                    this.dateForm.controls.startDate.value?.format(
                        this.dateFormat
                    ),
                    "startDate"
                );
            }
        } else {
            if (event?.type === "blur") {
                this.dateForm.controls.startDate.setValue(this.startDateDefault);
                this.projectMetricsRefinerService.onInputChange(
                    RefinerLocation.startDate,
                    this.startDateInput.nativeElement.value
                );
            }
        }
    }

    async getMetrics(): Promise<void> {
        if (!this.initialized) return;

        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        if (!this.dateForm.controls.endDate.errors && !this.dateForm.controls.endDate.errors) {
            const active = this.projectMetricsRefinerService.refiners.slice();
            const response = await this.projectDelineationService.getMetrics(
                this.stateService.project.id,
                active,
                this.summaryInput
            );
            if (response && !response.isError) {
                this.gridData = (response.values as ProjectMetricsGridViewModel[])
                    .sort((a, b) => a.name.localeCompare(b.name));
                this.setTotal();
            } else {
                if (response?.isError) {
                    this.snackbar.showError(response.message);
                }
            }
            this.setTotal();
        }
        this.shouldWait$.next(true);
    }

    unsubscribe(): void {
        if (this.projectSubscription && !this.projectSubscription.closed) {
            this.projectSubscription.unsubscribe();
        }
        if (this.zrtSelectionSubscription && !this.zrtSelectionSubscription.closed) {
            this.zrtSelectionSubscription.unsubscribe();
        }
        this.zrtFilterService.selectedZrts = [];
    }

    // private
    private greaterThanEndDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;
            const momentStart: Moment = control.value as Moment;
            if (momentStart) {
                const startDate = momentStart
                    .startOf("day")
                    .valueOf();
                if (this.dateForm.controls.endDate.value) {
                    const endDate = (this.dateForm.controls.endDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (startDate > endDate) {
                        forbidden = true;
                    }
                }
            }
            return forbidden ? { dateGreaterThanEndDate: true } : null;
        };
    }

    private lessThanStartDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;
            const momentEnd: Moment = control.value as Moment;
            if (momentEnd) {
                const endDate = (control.value as Moment)
                    .startOf("day")
                    .valueOf();
                if (this.dateForm.controls.startDate.value) {
                    const startDate = (this.dateForm.controls.startDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (endDate < startDate) {
                        forbidden = true;
                    }
                }
            }
            return forbidden ? { dateLessThanStartDate: true } : null;
        };
    }

    private setDefaultDates(start: Date, end: Date): void {
        this.startDateDefault = start ? moment(start) : null;
        this.endDateDefault = end ? moment(end) : null;

        this.dateForm.controls.startDate.setValue(this.startDateDefault);
        this.dateForm.controls.endDate.setValue(this.endDateDefault);

        this.onAddStartDateRefiner();
        this.onAddEndDateRefiner();
    }

    private setTotal(): void {
        let total = new ProjectMetricsGridViewModel();

        total.name = "Total";
        total.assigned = 0;
        total.calledOn = 0;
        total.notCalled = 0;
        total.storesWithOrders = 0;
        total.cashCount = 0;
        total.cashAmount = 0;
        total.cashTotalSticks = 0;
        total.orderCount = 0;
        total.orderAmount = 0;
        total.orderTotalSticks = 0;
        total.gratisAmount = 0;
        total.gratisPercentage = 0;
        total.totalCalls = 0;
        total.callsWithWholesaler = 0;
        total.callsWithoutWholesaler = 0;

        for (const item of this.gridData) {
            if (item.assigned) total.assigned += item.assigned;
            if (item.calledOn) total.calledOn += item.calledOn;
            if (item.notCalled) total.notCalled += item.notCalled;
            if (item.storesWithOrders) total.storesWithOrders += item.storesWithOrders;
            if (item.cashCount) total.cashCount += item.cashCount;
            if (item.cashAmount) total.cashAmount += item.cashAmount;
            if (item.cashTotalSticks) total.cashTotalSticks += item.cashTotalSticks;
            if (item.orderCount) total.orderCount += item.orderCount;
            if (item.orderAmount) total.orderAmount += item.orderAmount;
            if (item.orderTotalSticks) total.orderTotalSticks += item.orderTotalSticks;
            if (item.gratisAmount) total.gratisAmount += item.gratisAmount;
            if (item.gratisPercentage) total.gratisPercentage += item.gratisPercentage;
            if (item.totalCalls) total.totalCalls += item.totalCalls;
            if (item.callsWithWholesaler) total.callsWithWholesaler += item.callsWithWholesaler;
            if (item.callsWithoutWholesaler) total.callsWithoutWholesaler += item.callsWithoutWholesaler;
        }
        const denominator = total.orderAmount + total.cashAmount;
        total.gratisPercentage = total.gratisAmount && denominator ? (total.gratisAmount / denominator) : 0;

        this.total = total;
    }

    private async setZrtFilterData(employeeIds: string[]): Promise<void> {
        this.zrtFilterService.byArea = false;
        const zrtsResponse = await this.employeeDelineationService.getEmployeeZrtsByIds(employeeIds);
        if (zrtsResponse && zrtsResponse?.values) {
            this.zrtFilterService.employeeZrts = zrtsResponse.values;
        }
    }
}
