import { AgmCoreModule } from "@agm/core";
import { AgmMarkerClustererModule } from "@agm/markerclusterer";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatCarouselModule } from "@ngmodule/material-carousel";
import { AgmDirectionModule } from "agm-direction";
import { ProjectModule } from "src/app/details/project/project.module";
import { CallHistoryZrtFilterService } from "../details/call-history/call-history-zrt.filter.service";
import { SharedModule } from "../shared/shared.module";
import { WholesaleProductsModule } from "../wholesale-products/wholesale-products.module";
import { CallValidationService } from "./account-services/call-validation.service";
import { CustomerStateService } from "./account-services/customer-state.service";
import { AccountListZrtFilterService } from "./accounts-list/account-list-zrt-filter.service";
import { AccountsListRefinerService } from "./accounts-list/accounts-list-refiner.service";
import { AccountsListRoutingModeComponent } from "./accounts-list/accounts-list-routing-mode/accounts-list-routing-mode.component";
import { AccountsListComponent } from "./accounts-list/accounts-list.component";
import { AccountsComponent } from "./accounts.component";
import { AccountsRoutingModule } from "./accounts.routing.module";
import { AddNewBusinessButtonComponent } from "./add-new-business-button/add-new-business-button.component";
import { CallMasterComponent } from "./call-master/call-master.component";
import { CallService } from "./call-master/call-services/call.service";
import { ChainHqCallComponent } from './call-master/chain-hq-call/chain-hq-call.component';
import { ChainHqApplicationService } from "./call-master/chain-hq-call/chain-hq-services/chain-hq-call-application.service";
import { StepperCallApplicationService } from "./call-master/stepper-call/stepper-call-services/stepper-call-application.service";
import { StepperCallModule } from "./call-master/stepper-call/stepper-call.module";
import { WholesaleCallComponent } from "./call-master/wholesale-call/wholesale-call.component";
import { WholesaleCallApplicationService } from "./call-master/wholesale-call/wholesale-services/wholesale-call-application.service";
import { ChainHqInfoComponent } from "./info-master/chain-hq-info/chain-hq-info.component";
import { InfoMasterComponent } from "./info-master/info-master.component";
import { RetailInfoComponent } from "./info-master/retail-info/retail-info.component";
import { WholesaleAccountEditComponent } from "./info-master/wholesale-account-edit/wholesale-account-edit.component";
import { WholesaleAccountInfoComponent } from "./info-master/wholesale-info/wholesale-account-info.component";
import { PictureComponent } from "./picture/picture.component";
import { ChainHqProductsComponent } from "./products-master/chain-hq-products/chain-hq-products.component";
import { ProductsMasterComponent } from "./products-master/products-master.component";
import { WholesaleProductsComponent } from "./products-master/wholesale-account-products/wholesalse-account-products.component";
import { ChainHqProfileComponent } from "./profile-master/chain-hq-profile/chain-hq-profile.component";
import { CustomerCallHistoryBaseComponent } from './profile-master/customer-call-history-base/customer-call-history-base.component';
import { CustomerCallHistoryComponent } from './profile-master/customer-call-history/customer-call-history.component';
import { CustomerDetailedCallHistoryComponent } from './profile-master/customer-detailed-call-history/customer-detailed-call-history.component';
import { ProfileMasterComponent } from "./profile-master/profile-master.component";
import { RelatedContactsComponent } from "./profile-master/related-contacts/related-contacts.component";
import { RelatedProjectsComponent } from "./profile-master/related-projects/related-projects.component";
import { ViewRetailProjectComponent } from "./profile-master/related-projects/view-retail-project/view-retail-project.component";
import { LastOrderComponent } from "./profile-master/retail-profile/last-order/last-order.component";
import { EditWholesalersComponent } from "./profile-master/retail-profile/related-wholesalers/edit-wholesalers/edit-wholesalers.component";
import { RelatedWholesalersComponent } from './profile-master/retail-profile/related-wholesalers/related-wholesalers.component';
import { NewAgreementComponent } from "./profile-master/retail-profile/retail-agreements/new-agreement/new-agreement.component";
import { PaymentStatusComponent } from "./profile-master/retail-profile/retail-agreements/payment-status/payment-status.component";
import { RetailAgreementsComponent } from "./profile-master/retail-profile/retail-agreements/retail-agreements.component";
import { ViewAgreementComponent } from "./profile-master/retail-profile/retail-agreements/view-agreement/view-agreement.component";
import { RetailProfileComponent } from "./profile-master/retail-profile/retail-profile.component";
import { WholesaleProfileComponent } from "./profile-master/wholesale-profile/wholesale-profile.component";
import { VolumeMasterModule } from "./volume-master/volume-master.module";
import { DashboardMasterModule } from "./dashboard-master/dashboard-master.module";
@NgModule({
    declarations: [
        PictureComponent,
        WholesaleProfileComponent,
        RetailProfileComponent,
        WholesaleAccountInfoComponent,
        WholesaleAccountEditComponent,
        WholesaleProductsComponent,
        ChainHqProductsComponent,
        ChainHqProfileComponent,
        ChainHqInfoComponent,
        RetailInfoComponent,
        ProfileMasterComponent,
        InfoMasterComponent,
        CallMasterComponent,
        ProductsMasterComponent,
        WholesaleCallComponent,
        CustomerDetailedCallHistoryComponent,
        ChainHqCallComponent,
        CustomerCallHistoryComponent,
        AddNewBusinessButtonComponent,
        RelatedWholesalersComponent,
        LastOrderComponent,
        RetailAgreementsComponent,
        NewAgreementComponent,
        ViewAgreementComponent,
        PaymentStatusComponent,
        RelatedProjectsComponent,
        EditWholesalersComponent,
        RelatedContactsComponent,
        ViewRetailProjectComponent,
        CustomerCallHistoryBaseComponent,
        AccountsListComponent,
        AccountsListRoutingModeComponent,
        AccountsComponent
    ],
    imports: [
        CommonModule,
        AccountsRoutingModule,
        StepperCallModule,
        VolumeMasterModule,
        DashboardMasterModule,
        MatCarouselModule,
        SharedModule,
        FontAwesomeModule,
        BrowserAnimationsModule,
        MatSlideToggleModule,
        ProjectModule,
        MatIconModule,
        AgmCoreModule,
        AgmMarkerClustererModule,
        AgmDirectionModule,
        NgbModule,
        WholesaleProductsModule,
    ],
    providers: [
        CallValidationService,
        CustomerStateService,
        CallService,
        StepperCallApplicationService,
        WholesaleCallApplicationService,
        ChainHqApplicationService,
        AccountsListRefinerService,
        AccountListZrtFilterService,
        CallHistoryZrtFilterService,
        { provide: MAT_DIALOG_DATA, useValue: {} }
    ]
})
export class AccountsModule { }
