import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    GenericVersionResponseDto, RouteDto, RouteStopDto,
    RouteSyncCommand, SharedHelper, SyncCommandTypes,
    SystemInformationKeys, VersionResponseDto
} from "shield.shared";
import { RouteStop } from "src/app/entity-models/route-stop.entity";
import { Route } from "src/app/entity-models/route.entity";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { RouteConverterService } from "src/app/services/converter-services/route-converter.service";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { SyncLevel } from "../sync-enums/sync-level.enum";

@Injectable()
export class RouteDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.None;
    offlineRepSyncType = SyncLevel.Required;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.route;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing routes...");

        await this.pullData();

        this.log("Done syncing routes...");
    }

    private async pullData(): Promise<void> {
        const lastVersion = this.lastSyncVersion;

        if (!(await this.checkVersion(lastVersion))) {
            this.log("Route data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Route data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.routePullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;

        try {
            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;
                const response = await this.http
                    .get<GenericVersionResponseDto<RouteDto[]>>(`/api/routes${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                this.log(
                    `Downloaded ${thisBatchSize} routes, saving to IndexedDB...`
                );

                const routes = response.values.map((r) => {
                    const result = RouteConverterService.routeDtoToRoute(r);
                    result.hasServerProcessed = 1;
                    return result;
                });
                if (routes.length > 0) {
                    await this.dbService.routes.bulkPut(routes);
                }
                

                this.lastSyncVersion = response.maxVersion;
            } while (thisBatchSize > 0);

            this.log("Done saving route data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing routes", e);
        }
    }

    async pushData(): Promise<void> {
        await this.pushRouteData();
    }

    private pushRouteData(): Promise<void> {
        return this.syncQueue.process<RouteSyncCommand>(
            SyncCommandTypes.route,
            async (message) => {
                let route = await this.dbService.routes.get(message.payload.id);

                if (!route) return;

                let dto = RouteConverterService.routeToRouteDto(route);

                if (dto) {
                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }

    private get lastSyncVersion(): string {
        const value = localStorage.getItem(this.syncVersionKey);
        return value === "null" ? null : value;
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/routes/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }
}
