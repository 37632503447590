<ng-container *ngIf="viewmodel">
    <div class="row">
        <div class="col-12">
            <app-page-header #header [name]="viewmodel?.headerName"
                             [entryCount]="viewmodel?.total ? viewmodel.total : 0" [buttons]="viewmodel?.headerButtons"
                             [refinerService]="viewmodel?.refinerService"
                             [subAvailableColumns]="viewmodel?.availableColumns"
                             (onClickButton)="viewmodel?.onButtonClickEvent($event)"
                             (onButtonAction)="viewmodel?.onButtonAction($event)"
                             (headerExpansionChanged)="viewmodel?.headerExpansionChanged()"
                             (onColumnVisabilityMenuCloseEvent)="viewmodel?.setColumnVisibilityCommunicator($event)">
            </app-page-header>
        </div>
    </div>
    <div class="d-flex p-2 justify-content-center align-items-center">
        <div>
            <button class="btn btn-secondary mr-2" data-toggle="tooltip" data-placement="bottom" title="View Filters"
                    (click)="viewmodel?.showFilters()">
                <span class="white-space-nowrap">
                    <span class="material-icons">filter_alt</span>
                    Filters •
                    {{ viewmodel?.refinerService.refiners.length }}
                </span>
            </button>
        </div>
        <div *ngIf="viewmodel?.shouldDisplayZrtSearch" class="flex-grow-1 text-center text-muted">
            <div class="mat-form-field">
                <app-zrt-tree-view #zrtTree [displayName]="'ZRT/Name'"></app-zrt-tree-view>
            </div>
        </div>
        <div class="flex-grow-1">
            <mat-form-field appearance="outline" color="accent" [formGroup]="viewmodel.dateForm">
                <mat-label>Start on or After</mat-label>
                <input #startDateInput matInput formControlName="startDate" [max]="viewmodel?.startMaxDate"
                       [matDatepicker]="afterPicker" (keyup)="viewmodel?.onAddStartDateRefiner($event)"
                       (blur)="viewmodel?.onAddStartDateRefiner($event)"
                       (dateChange)="viewmodel?.onAddStartDateRefiner()"/>
                <mat-datepicker touchUi #afterPicker></mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="afterPicker"></mat-datepicker-toggle>
            </mat-form-field>
            <mat-error *ngIf="
                    viewmodel?.dateForm
                        .get('startDate')
                        .hasError('dateGreaterThanEndDate')
                ">
                Start date can not be greater than End date.
            </mat-error>
        </div>
        <div class="flex-grow-1">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Status</mat-label>
                <mat-select #statusSelect
                            [(value)]="viewmodel.selectedStatuses"
                            (openedChange)="viewmodel.onOpenedStatusChange()"
                            (selectionChange)="viewmodel.onSelectionStatusChange()"
                            multiple>
                    <mat-option *ngFor="let status of viewmodel.statuses" [value]="status">
                        {{ status.description }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="d-flex">
            <button appThrottleClick mat-raised-button (throttledClick)="viewmodel?.search()"
                    [disabled]="viewmodel.isSearchButtonDisabled"
                    class="btn btn-primary mr-2">SEARCH
            </button>
            <button mat-raised-button (click)="viewmodel?.reset()" class="btn btn-secondary">RESET</button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-accordion>
                <mat-expansion-panel #balanceExpansion
                                     [expanded]="viewmodel.isTmOrRm"
                                     (afterExpand)="viewmodel.isGapSet = false"
                                     (afterCollapse)="viewmodel.isGapSet = false"
                                     hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <fa-icon *ngIf="balanceExpansion.expanded" class="icon fa-lg icon-toggle pull-right"
                                     [icon]="viewmodel.faCaretDown">
                            </fa-icon>
                            <fa-icon *ngIf="!balanceExpansion.expanded" class="icon fa-lg icon-toggle pull-right"
                                     [icon]="viewmodel.faCaretUp">
                            </fa-icon>
                            <h2>Current Balance</h2>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="current-balance-body">
                        <table class="table table-sm">
                            <thead>
                            <tr>
                                <th>ZRT</th>
                                <th>Name</th>
                                <th>Pending Carry Forward</th>
                                <th>Carry Forward</th>
                                <th>Invoices</th>
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let balance of viewmodel.currentBalances">
                                <td>{{ balance.zrt }}</td>
                                <td>{{ balance.fullName }}</td>
                                <td>{{ balance.pendingCarryForwardAmount | currency}}</td>
                                <td>{{ balance.carryForwardAmount | currency }}</td>
                                <td>{{ balance.invoicesAmount | currency }}</td>
                                <td>{{ balance.totalAmount | currency }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</ng-container>
<mat-sidenav-container *ngIf="viewmodel" class="sidenav-container d-flex flex-column flex-grow-1" autosize>
    <mat-sidenav #drawer [mode]="viewmodel?.drawerMode" [fixedInViewport]="true"
                 [fixedTopGap]="viewmodel?.topGapDistance" [fixedBottomGap]="0" class="sidenav">
        <app-filter-container [refinerService]="viewmodel?.refinerService" [filters]="viewmodel?.filters"
                              [isSearchDisabled]="viewmodel?.isSearchButtonDisabled"
                              [filterLocation]="viewmodel.filterLocation"
                              (search)="viewmodel?.search()" (reset)="viewmodel?.reset()">
        </app-filter-container>
    </mat-sidenav>
    <mat-sidenav-content #sideNavContent>
        <div class="sidenav-content d-flex flex-column flex-grow-1">
            <ng-container>
                <div class="table-responsive d-flex flex-column flex-grow-1">
                    <app-grid #grid [(dataSource)]="viewmodel.dataSource" [columnDefinitions]="viewmodel?.columnDef"
                                [detailTemplate]="detailTemplate" [searchRefiners]="viewmodel?.activeRefiners"
                                [selectedColumnVisability]="viewmodel?.selectedColumnVisibilityComunicator"
                                [isFixedLayout]="viewmodel?.isFixedLayout" [height]="viewmodel?.gridHeight"
                                [isEnabledExpandedDetails]="viewmodel?.isEnabledExpandedDetails"
                                [itemsRendedInViewPort]="viewmodel?.itemsRendedInViewPort"
                                [detailHeight]="viewmodel?.detailHeight" [sortFunction]="viewmodel.sortFunction"
                                (getDataBatch)="viewmodel?.getGratisBatch($event)"
                                class="d-flex flex-grow-1">
                    </app-grid>
                </div>
            </ng-container>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #detailTemplate let-data="data">
    <div class="row detail-content">
        <div class="col"></div>
    </div>
    <div class="row detail-content" *ngFor="let row of data">
        <div class="col h-center"></div>
    </div>
</ng-template>
