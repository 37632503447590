/* eslint-disable no-bitwise */
// eslint-disable-next-line no-shadow
export enum ValidationStops {
    none = 1 << 0,

    signatureHardStop = 1 << 1,

    callClosingHardStop = 1 << 2,

    callClosingSoftStop = 1 << 4,

    retailReceiptHardStop = 1 << 8,

    wholesaleReceiptHardStop = 1 << 16
}
