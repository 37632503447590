import { County } from "src/app/entity-models/county.entity";
import { State } from "src/app/entity-models/state.entity";

export class CountyViewmodel {
    id: string;
    displayValue: string;
    groupBy: string;
    county: County;
    state: State;

    constructor(county: County, state: State){
        this.county = county;
        this.state = state;
        this.id = county.id;
        this.displayValue = county.name;
        this.groupBy = state.name;
    }
}
