export type TaxRateLocalityType = "County" | "State-County" | "City" | "State";

export class TaxRate {
    id: string; //UUID
    stateId: string;
    locality: string;
    taxTypeId: number;
    taxSubmittedToId: number;
    taxDivisionId: number;
    taxCalculationMethodId: number;
    taxRate: number;
    isPrepaid: boolean;
    isActive: boolean;
    createdUserId: string;
    createdUtcDateTime: Date;
    createdUserZrt: string;
    createdUserFullName: string
    modifiedUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    rowversion: string;
}
