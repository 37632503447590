import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
//Icons
import {
    faEye,
    faCamera,
    faArrowDown,
    faPen,
    faTrash,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { OverlayService } from "src/app/services/overlay.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { AccountProfileBaseComponent } from "../account-profile-base/account-profile-base.component";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { WholesaleProfileViewmodel } from "./wholesale-account-profile.viewmodel";
import { CustomerDetailedCallHistoryComponent } from "../customer-detailed-call-history/customer-detailed-call-history.component";
import { AppStateService } from "src/app/services/app-state.service";
import { ContactDelineationService } from "src/app/services/delineation-services/contact-delineation.service";
import { CustomerContractDelineationService } from "src/app/services/delineation-services/customer-contract-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { ContractTemplateDelineationService } from "src/app/services/delineation-services/contract-template-delineation.service";
import { ContractPaymentDelineationService } from "src/app/services/delineation-services/contract-payment-delineation.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { Router } from "@angular/router";
import { ProjectStateService } from "src/app/services/project-state-service";
import { ProjectApplicationService } from "src/app/details/project/project-services/project-application.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { WholesalerGroupMemberDelineationService } from "src/app/services/delineation-services/wholesaler-group-member-delineation.service";
import { WholesalerGroupDelineationService } from "src/app/services/delineation-services/wholesaler-group-delineation.service";
import { SyncService } from "src/app/services/sync.service";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";

@Component({
    selector: "app-wholesale-profile",
    templateUrl: "./wholesale-profile.component.html",
    styleUrls: ["./wholesale-profile.component.scss"]
})
export class WholesaleProfileComponent
    extends AccountProfileBaseComponent
    implements OnInit, OnDestroy {
    faEye: IconDefinition = faEye;
    faCamera: IconDefinition = faCamera;
    faArrowDown: IconDefinition = faArrowDown;
    faPen: IconDefinition = faPen;
    faTrash: IconDefinition = faTrash;
    wholesalerCustomerSubscription: Subscription;
    employeeSubscription: Subscription;
    dateFormat = MY_DATE_FORMATS.display.customDateInput;

    wholesalerViewmodel: WholesaleProfileViewmodel = new WholesaleProfileViewmodel(this.wholesalerGroupDelineationService);

    @ViewChild('history') history: CustomerDetailedCallHistoryComponent;

    public constructor(
        appStateService: AppStateService,
        overlayService: OverlayService,
        contactDelineationService: ContactDelineationService,
        customerContractDelineationService: CustomerContractDelineationService,
        customerDelineationSevice: CustomerDelineationService,
        customerStateService: CustomerStateService,
        contractTemplateDelineationService: ContractTemplateDelineationService,
        contractPaymentDelineationService: ContractPaymentDelineationService,
        projectDelineationService: ProjectDelineationService,
        router: Router,
        projectStateService: ProjectStateService,
        projectApplicationService: ProjectApplicationService,
        wholesalerGroupMemberDelineationService: WholesalerGroupMemberDelineationService,
        px3RankService: Px3DelineationService,
        private wholesalerGroupDelineationService: WholesalerGroupDelineationService,
        snackbarService: SnackbarService,
        syncService: SyncService,
    ) {
        super(appStateService
            , overlayService
            , contactDelineationService
            , customerContractDelineationService
            , contractPaymentDelineationService
            , customerDelineationSevice
            , customerStateService
            , contractTemplateDelineationService
            , projectDelineationService
            , router
            , projectStateService
            , projectApplicationService
            , wholesalerGroupMemberDelineationService
            , snackbarService
            , px3RankService
            , syncService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.currentEmployee && this.customer) {
            this.wholesalerViewmodel.buildViewmodelFromDomainModel(
                this.customer,
                this.currentEmployee
            );
        }

        if (
            !this.wholesalerCustomerSubscription ||
            this.wholesalerCustomerSubscription.closed
        ) {
            this.wholesalerCustomerSubscription = this.customerStateService.observableCustomer
                .pipe(
                    map(async (customer) => {
                        this.customer = customer;
                        if (customer && this.currentEmployee) {
                            this.wholesalerViewmodel.buildViewmodelFromDomainModel(
                                customer,
                                this.currentEmployee
                            );
                        }
                    })
                )
                .subscribe();
        }
    }

    ngOnDestroy(): void {
        if (
            this.employeeSubscription &&
            !this.employeeSubscription.closed
        ) {
            this.employeeSubscription.unsubscribe();
        }

        if (
            this.wholesalerCustomerSubscription &&
            !this.wholesalerCustomerSubscription.closed
        ) {
            this.wholesalerCustomerSubscription.unsubscribe();
        }
    }

}
