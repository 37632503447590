import { Injectable } from "@angular/core";
import {
    AccountFilterDto,
    FilterRequestV2Dto,
    FilterSortDto,
    GenericDropDownDto,
    GenericResponseDto,
    ProfileProjectParamsDto,
    ProjectAssignmentCountParamsDto,
    ProjectByIdParamsDto,
    ProjectBatchParamsDto,
    ProjectCustomerAndEmployeeParamsDto,
    ProjectEmployeeAssignmentParamsDto,
    ProjectListColumns,
    ProjectMetricParamsDto,
    ProjectMetricProductDto,
    ProjectMetricWholesalerDto,
    ProjectMetricZrtDto,
    ProjectStoreAssignmentParamsDto,
    StagingProjectCheckDto,
    StringArrayDto,
    ProjectAssignmentSummaryOptions,
    ProjectAssignmentCountFilterDto,
    CustomerTypeEnum,
    GenericLookup
} from "shield.shared";
import { ProjectCustomerColumns } from "shield.shared/dist/filter/filter-sort-columns/project-customer-columns";
import { ProjectAssignmentsSummaryViewModel } from "src/app/details/project/project-configuration/project-assignments/project-assignments-summary.viewmodel";
import { ProjectBasicCustomerEmployee } from "src/app/entity-models/project-basic-customer-employee.entity";
import { ProjectBasicCustomer } from "src/app/entity-models/project-basic-customer.entity";
import { ProjectCustomer } from "src/app/entity-models/project-customer.entity";
import { Project } from "src/app/entity-models/project.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { DatabaseService } from "../database.service";
import { AccountListFilterMapService } from "../filter-map-services/account-list-filter-map.service";
import { ProjectAssignmentCountFilterMapService } from "../filter-map-services/project-assignment-count-filter-map.service";
import { ProjectListFilterMapService } from "../filter-map-services/project-list-filter-map.service";
import { ProjectOfflineService } from "../offline-services/project-offline-service";
import { ProjectOnlineService } from "../online-services/project-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class ProjectDelineationService extends DelineationContext<Project, string> {
    constructor(
        private projectOfflineService: ProjectOfflineService,
        private projectOnlineService: ProjectOnlineService,
        snackbarService: SnackbarService,
        protected datasourceDelineationService: DatasourceDelineationService,
        protected dbservice: DatabaseService
    ) {
        super(dbservice, datasourceDelineationService, snackbarService);
    }

    // Use discardProject() for normal project removal...
    async deleteProject(id: string): Promise<GenericResponseDto<Project>> {
        const offline = async (key: string) => {
            return await this.projectOfflineService.deleteProject(key);
        };
        const online = (key: string) => {
            return this.projectOnlineService.deleteProject(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            string,
            Project
        >(id, offline, online, null, null, true);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async discardProject(id: string): Promise<GenericResponseDto<Project>> {
        const offline = async (key: string) => {
            return await this.projectOfflineService.discardProject(key);
        };
        const online = (key: string) => {
            return this.projectOnlineService.discardProject(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            string,
            Project
        >(id, offline, online, null, null, true);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async upsertProject(
        project: Project,
        stagingDone?: boolean
    ): Promise<GenericResponseDto<Project>> {
        const offline = async (params: { key: Project, flag: boolean }) => {
            return await this.projectOfflineService.upsertProject(params.key);
        };
        const online = (params: { key: Project, flag: boolean }) => {
            return this.projectOnlineService.upsertProject(params.key, params.flag);
        };
        const response = await this.datasourceDelineationService.makeCall<
        { key: Project, flag: boolean },
            Project
        >({ key: project, flag: stagingDone }, offline, online, null, null, true);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getProjectById(id: string, isStaging?: boolean): Promise<GenericResponseDto<Project>> {
        const params = new ProjectByIdParamsDto();
        params.id = id;
        params.isStaging = isStaging;

        const offline = (key: ProjectByIdParamsDto) => {
            return this.projectOfflineService.getById(key);
        };
        const online = (key: ProjectByIdParamsDto) => {
            return this.projectOnlineService.getById(key);
        };
        const response = await this.datasourceDelineationService.makeCall<ProjectByIdParamsDto, Project>(
            params,
            offline,
            online, null, null, true
        );

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getProjectBatch(
        id: string,
        refiners: Refiner[],
        pageSize: number | null,
        startIndex: number,
        filterSorts: FilterSortDto<ProjectListColumns>[]
    ): Promise<GenericResponseDto<Project[]>> {
        const key = new ProjectBatchParamsDto();
        key.filterRequestDto = new FilterRequestV2Dto();
        key.filterRequestDto.id = id;
        key.filterRequestDto.filters = ProjectListFilterMapService.mapFilterData(
            refiners
        );
        key.filterRequestDto.pageSize = pageSize;
        key.filterRequestDto.startIndex = startIndex;
        key.filterRequestDto.filterSorts = filterSorts;

        const offline = (key: ProjectBatchParamsDto) => {
            return this.projectOfflineService.getProjectBatch(key);
        };
        const online = (key: ProjectBatchParamsDto) => {
            return this.projectOnlineService.getProjectBatch(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            ProjectBatchParamsDto,
            Project[]
        >(key, offline, online, null, null, true);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getCustomersForProjectAssignmentBatch(
        id: string,
        refiners: Refiner[],
        pageSize: number | null,
        startIndex: number,
        filterSorts: FilterSortDto<ProjectCustomerColumns>[]
    ): Promise<GenericResponseDto<ProjectBasicCustomer[]>> {
        const filterRequestDto = new FilterRequestV2Dto<
            AccountFilterDto,
            ProjectCustomerColumns
        >();
        filterRequestDto.id = id;
        filterRequestDto.filters = AccountListFilterMapService.mapFilterData(
            refiners
        );
        filterRequestDto.startIndex = startIndex;
        filterRequestDto.pageSize = pageSize;
        filterRequestDto.filterSorts = filterSorts;

        //We are using FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns> here as the filters are the same
        const offline = (
            key: FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>
        ) => {
            return this.projectOfflineService.getCustomersForProjectBatch(key);
        };
        const online = (
            key: FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>
        ) => {
            return this.projectOnlineService.getCustomersForProjectBatch(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>,
            ProjectBasicCustomer[]
        >(filterRequestDto, offline, online, null, null, true);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getCustomersForProjectEmployeeAssignmentBatch(
        id: string,
        refiners: Refiner[],
        pageSize: number | null,
        startIndex: number,
        filterSorts: FilterSortDto<ProjectCustomerColumns>[]
    ): Promise<GenericResponseDto<ProjectBasicCustomerEmployee[]>> {
        const filterRequestDto = new FilterRequestV2Dto<
            AccountFilterDto,
            ProjectCustomerColumns
        >();
        filterRequestDto.id = id;
        filterRequestDto.filters = AccountListFilterMapService.mapFilterData(
            refiners
        );
        filterRequestDto.startIndex = startIndex;
        filterRequestDto.pageSize = pageSize;
        filterRequestDto.filterSorts = filterSorts;

        //We are using FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns> here as the filters are the same
        const offline = (
            key: FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>
        ) => {
            return this.projectOfflineService.getCustomersForProjectEmployeeBatch(key);
        };
        const online = (
            key: FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>
        ) => {
            return this.projectOnlineService.getCustomersForProjectEmployeeBatch(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>,
            ProjectBasicCustomerEmployee[]
        >(filterRequestDto, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getDropDown(): Promise<
        GenericResponseDto<GenericDropDownDto[] | undefined>
    > {
        const offline = () => {
            return this.projectOfflineService.getDropDown();
        };
        const online = () => {
            return this.projectOnlineService.getDropDown();
        };
        const response = await this.datasourceDelineationService.makeCall<
            undefined,
            GenericDropDownDto[]
        >(undefined, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getProjectCustomersByProjectId(
        id: string
    ): Promise<GenericResponseDto<ProjectCustomer[]>> {
        const offline = (key: string) => {
            return this.projectOfflineService.getProjectCustomersByProjectId(
                key
            );
        };
        const online = (key: string) => {
            return this.projectOnlineService.getProjectCustomersByProjectId(
                key
            );
        };
        const response = await this.datasourceDelineationService.makeCall<
            string,
            ProjectCustomer[]
        >(id, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getMetrics(
        projectId: string,
        refiners: Refiner[],
        summaryOption: string
    ): Promise<
        GenericResponseDto<
            | ProjectMetricZrtDto[]
            | ProjectMetricWholesalerDto[]
            | ProjectMetricProductDto[]
        >
    > {
        const params = new ProjectMetricParamsDto();
        params.projectId = projectId;
        params.refiners = refiners.map((r) => Refiner.refinerToRefinerDto(r));
        params.summaryOption = summaryOption;

        const offline = (key: ProjectMetricParamsDto) => {
            return this.projectOfflineService.getMetrics(key);
        };
        const online = (key: ProjectMetricParamsDto) => {
            return this.projectOnlineService.getMetrics(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            ProjectMetricParamsDto,
            | ProjectMetricZrtDto[]
            | ProjectMetricWholesalerDto[]
            | ProjectMetricProductDto[]
        >(params, offline, online, null, null, true);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async setProjectStoreAsAssigned(
        projectId: string,
        customerId: string,
        refiners: Refiner[]
    ): Promise<GenericResponseDto<ProjectCustomer | undefined>> {
        const params = new ProjectStoreAssignmentParamsDto();
        params.filterRequestV2Dto = new FilterRequestV2Dto<
            AccountFilterDto,
            ProjectCustomerColumns
        >();
        params.projectId = projectId;
        params.customerId = customerId;
        params.filterRequestV2Dto.pageSize = 800;
        params.filterRequestV2Dto.filters = AccountListFilterMapService.mapFilterData(
            refiners
        );

        const offline = async (key: ProjectStoreAssignmentParamsDto) => {
            return await this.projectOfflineService.setProjectStoreAsAssigned(
                key
            );
        };
        const online = (key: ProjectStoreAssignmentParamsDto) => {
            return this.projectOnlineService.setProjectStoreAsAssigned(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            ProjectStoreAssignmentParamsDto,
            ProjectCustomer | undefined
        >(params, offline, online, null, null, true);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async setProjectStoreAsUnassigned(
        projectId: string,
        customerId: string,
        refiners: Refiner[]
    ): Promise<GenericResponseDto<ProjectCustomer>> {
        const params = new ProjectStoreAssignmentParamsDto();
        params.filterRequestV2Dto = new FilterRequestV2Dto<
            AccountFilterDto,
            ProjectCustomerColumns
        >();
        params.projectId = projectId;
        params.customerId = customerId;
        params.filterRequestV2Dto.filters = AccountListFilterMapService.mapFilterData(
            refiners
        );
        params.filterRequestV2Dto.pageSize = 800;

        const offline = (key: ProjectStoreAssignmentParamsDto) => {
            return this.projectOfflineService.setProjectStoreAsUnassigned(key);
        };
        const online = (key: ProjectStoreAssignmentParamsDto) => {
            return this.projectOnlineService.setProjectStoreAsUnassigned(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            ProjectStoreAssignmentParamsDto,
            ProjectCustomer
        >(params, offline, online, null, null, true);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async setProjectStoresAsAssigned(
        projectId: string,
        refiners: Refiner[]
    ): Promise<GenericResponseDto<Project>> {
        const params = new ProjectStoreAssignmentParamsDto();
        params.filterRequestV2Dto = new FilterRequestV2Dto<
            AccountFilterDto,
            ProjectCustomerColumns
        >();
        params.projectId = projectId;
        params.filterRequestV2Dto.filters = AccountListFilterMapService.mapFilterData(
            refiners
        );
        params.filterRequestV2Dto.pageSize = 800;

        const offline = (key: ProjectStoreAssignmentParamsDto) => {
            return this.projectOfflineService.setProjectStoresAsAssigned(key);
        };
        const online = (key: ProjectStoreAssignmentParamsDto) => {
            return this.projectOnlineService.setProjectStoresAsAssigned(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            ProjectStoreAssignmentParamsDto,
            Project
        >(params, offline, online, null, null, true);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async setProjectStoresAsUnassigned(
        projectId: string,
        refiners: Refiner[]
    ): Promise<GenericResponseDto<Project>> {
        const params = new ProjectStoreAssignmentParamsDto();
        params.filterRequestV2Dto = new FilterRequestV2Dto<
            AccountFilterDto,
            ProjectCustomerColumns
        >();
        params.projectId = projectId;
        params.filterRequestV2Dto.filters = AccountListFilterMapService.mapFilterData(
            refiners
        );
        params.filterRequestV2Dto.pageSize = 800;

        const offline = (key: ProjectStoreAssignmentParamsDto) => {
            return this.projectOfflineService.setProjectStoresAsUnassigned(key);
        };
        const online = (key: ProjectStoreAssignmentParamsDto) => {
            return this.projectOnlineService.setProjectStoresAsUnassigned(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            ProjectStoreAssignmentParamsDto,
            Project
        >(params, offline, online, null, null, true);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getProfileProjects(
        projectStatusIds: number[],
        customerType: GenericLookup<CustomerTypeEnum>,
        employeeId: string,
        customerId: string
    ): Promise<GenericResponseDto<Project[] | undefined>> {
        const params = new ProfileProjectParamsDto();
        params.projectStatusIds = projectStatusIds;
        params.employeeId = employeeId;
        params.customerId = customerId;
        params.customerType = customerType;

        const offline = (key: ProfileProjectParamsDto) => {
            return this.projectOfflineService.getProfileProjects(key);
        };
        const online = (key: ProfileProjectParamsDto) => {
            return this.projectOnlineService.getProfileProjects(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            ProfileProjectParamsDto,
            Project[]
        >(params, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getStartedProjectsByCustomerAndEmployeeIds(
        customerId: string,
        employeeId: string
    ): Promise<GenericResponseDto<Project[]>> {
        const params = new ProjectCustomerAndEmployeeParamsDto();
        params.customerId = customerId;
        params.employeeId = employeeId;

        const offline = (params: ProjectCustomerAndEmployeeParamsDto) => {
            return this.projectOfflineService.getStartedProjectsByCustomerAndEmployeeIds(
                params
            );
        };
        const online = (params: ProjectCustomerAndEmployeeParamsDto) => {
            return this.projectOfflineService.getStartedProjectsByCustomerAndEmployeeIds(
                params
            );
        };
        const response = await this.datasourceDelineationService.makeCall<
            ProjectCustomerAndEmployeeParamsDto,
            Project[]
        >(params, offline, online, null, null, true);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async setProjectStoreForAssignment(
        refiners: Refiner[],
        checked: boolean,
        projectId: string,
        customerId?: string,
    ): Promise<GenericResponseDto<string[]>> {
        const params = new ProjectStoreAssignmentParamsDto();
        params.filterRequestV2Dto = new FilterRequestV2Dto<
            AccountFilterDto,
            ProjectCustomerColumns
        >();
        params.projectId = projectId;
        params.customerId = customerId;
        params.filterRequestV2Dto.filters = AccountListFilterMapService.mapFilterData(
            refiners
        );
        if (customerId) {
            params.filterRequestV2Dto.filters = AccountListFilterMapService.mapCustomerIdToFilter(
                params.filterRequestV2Dto.filters,
                customerId
            );
        }
        params.filterRequestV2Dto.filters.isSelecting = checked;

        const offline = (key: ProjectStoreAssignmentParamsDto) => {
            return this.projectOfflineService.setProjectStoreForAssignment(key);
        };
        const online = (key: ProjectStoreAssignmentParamsDto) => {
            return this.projectOnlineService.setProjectStoreForAssignment(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            ProjectStoreAssignmentParamsDto,
            string[]
        >(params, offline, online, null, null, true);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async assignEmployeesToStores(
        projectId: string,
        employeeIds: string[]
    ): Promise<GenericResponseDto<ProjectCustomer[]>> {
        const params = new ProjectEmployeeAssignmentParamsDto();
        params.projectId = projectId;
        params.employeeIdArray = new StringArrayDto();
        params.employeeIdArray.values = employeeIds;

        const offline = (key: ProjectEmployeeAssignmentParamsDto) => {
            return this.projectOfflineService.assignEmployeesToStores(key);
        };
        const online = (key: ProjectEmployeeAssignmentParamsDto) => {
            return this.projectOnlineService.assignEmployeesToStores(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            ProjectEmployeeAssignmentParamsDto,
            ProjectCustomer[]
        >(params, offline, online, null, null, true);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async unassignEmployeesFromStores(
        projectId: string,
        employeeIds: string[]
    ): Promise<GenericResponseDto<ProjectCustomer[]>> {
        const params = new ProjectEmployeeAssignmentParamsDto();
        params.projectId = projectId;
        params.employeeIdArray = new StringArrayDto();
        params.employeeIdArray.values = employeeIds;

        const offline = (key: ProjectEmployeeAssignmentParamsDto) => {
            return this.projectOfflineService.unassignEmployeesFromStores(key);
        };
        const online = (key: ProjectEmployeeAssignmentParamsDto) => {
            return this.projectOnlineService.unassignEmployeesFromStores(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            ProjectEmployeeAssignmentParamsDto,
            ProjectCustomer[]
        >(params, offline, online, null, null, true);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async getAssignmentCounts(
        projectId: string,
        filterOption: ProjectAssignmentSummaryOptions
    ): Promise<GenericResponseDto<ProjectAssignmentsSummaryViewModel[]>> {
        const params = new ProjectAssignmentCountParamsDto();
        params.projectId = projectId;
        params.filterRequestDto = new FilterRequestV2Dto<
            ProjectAssignmentCountFilterDto,
            undefined
        >();
        params.filterRequestDto.filters = ProjectAssignmentCountFilterMapService.mapFilterData(filterOption);
        params.filterRequestDto.pageSize = 10000;


        const offline = (key: ProjectAssignmentCountParamsDto) => {
            return this.projectOfflineService.getAssignmentCounts(key);
        };
        const online = (key: ProjectAssignmentCountParamsDto) => {
            return this.projectOnlineService.getAssignmentCounts(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            ProjectAssignmentCountParamsDto,
            ProjectAssignmentsSummaryViewModel[]
        >(params, offline, online, null, null, true);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }

    async checkStagingProjectStatus(projectId?: string): Promise<GenericResponseDto<StagingProjectCheckDto>> {
        const offline = (key: string) => {
            return this.projectOfflineService.checkStagingProjectStatus(key);
        };
        const online = (key: string) => {
            return this.projectOnlineService.checkStagingProjectStatus(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            string,
            StagingProjectCheckDto
        >(projectId, offline, online, null, null, true);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }
}
