import { DayTimeEntryWorkWithDto } from "shield.shared";
import { DayTimeEntryWorkWith } from "src/app/entity-models/day-time-entry-work-with.entity";

export class DayTimeEntryWorkWithConverterService {
    static dayTimeEntryWorkWithToDayTimeEntryWorkWithDto(
        entity: DayTimeEntryWorkWith
    ): DayTimeEntryWorkWithDto {
        const rtn = new DayTimeEntryWorkWithDto();

        rtn.id = entity.id;
        rtn.person = entity.person;
        rtn.start = entity.start.toISOString();
        rtn.end = entity.end.toISOString();

        return rtn;
    }

    static dayTimeEntryWorkWithDtoToDayTimeEntryWorkWith(
        dto: DayTimeEntryWorkWithDto
    ): DayTimeEntryWorkWith {
        const rtn = new DayTimeEntryWorkWith();

        rtn.id = dto.id;
        rtn.person = dto.person;
        rtn.start = new Date(dto.start);
        rtn.end = new Date(dto.end);

        return rtn;
    }
}
