import { TimeEntryTypeDto } from "shield.shared";
import { TimeEntryType } from "src/app/entity-models/time-entry-type.entity";

export class TimeEntryTypeConverterService {

    static timeEntryTypeToTimeEntryTypeDto(
        entity: TimeEntryType
    ): TimeEntryTypeDto {

        const dto = new TimeEntryTypeDto();

        dto.id = entity.id;
        dto.isDefault = entity.isDefault;
        dto.isFutureCompliant = entity.isFutureCompliant;
        dto.isOnlyApplicationControlled = entity.isOnlyApplicationControlled;
        dto.name = entity.name;
        dto.rowversion = entity.rowversion;

        return dto;
    }

    static timeEntryTypeDtoToTimeEntryType(
        dto: TimeEntryTypeDto
    ): TimeEntryType {

        const entity = new TimeEntryType();

        entity.id = dto.id;
        entity.isDefault = dto.isDefault;
        entity.isFutureCompliant = dto.isFutureCompliant;
        entity.isOnlyApplicationControlled = dto.isOnlyApplicationControlled;
        entity.name = dto.name;
        entity.rowversion = dto.rowversion;

        return entity;
    }
}
