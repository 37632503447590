import { HttpClient } from "@angular/common/http";
import Dexie from "dexie";
import { GenericVersionResponseDto, SyncCommandTypes, VersionResponseDto } from "shield.shared";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { DataSyncHandlerBase } from "../data-sync-handler-base";

const DEFAULT_BATCH_SIZE = 1000;

/**
 * This class is meant to be used as a base to consolidate methods that are common
 * amongst sync handlers. DataSyncHandlerBase already exists as a base sync handler, 
 * but this class was added to gradually consolidate duplicate sync handler code rather than
 * modifying the base, and then all the handlers at once.
 * 
 * Eventually, this class should be merged with DataSyncHandlerBase.
 */
export abstract class SyncHandler<E, D> extends DataSyncHandlerBase {

    isRunSuccessfull = false;
    private maxBatchSize: number;
    constructor(
        protected syncName: string,
        protected syncCommandType: SyncCommandTypes,
        public syncVersionKey: SyncVersionKeyNames,
        protected apiBase: string,
        protected table: Dexie.Table<E, string>,
        protected http: HttpClient,
        maxBatchSize?: number,
    ) {
        super();
        this.maxBatchSize = maxBatchSize ?? DEFAULT_BATCH_SIZE;
    }

    protected async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>(`${this.apiBase}/version`)
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    protected abstract entityToDto(entity: E): D;

    protected abstract dtoToEntity(dto: D): E;


    protected async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log(`${this.syncName} are up to date`);
            this.isRunSuccessfull = true;
            return;
        }

        this.log(`${this.syncName} are out of date, syncing...`);

        try {
            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${this.maxBatchSize}${versionQuery}`;

                const response = await this.http
                    .get<GenericVersionResponseDto<D[]>>(`${this.apiBase}${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                this.log(
                    `Downloaded ${thisBatchSize} ${this.syncName}, saving to IndexedDB...`
                );

                const entities = response.values.map((c) =>
                    this.dtoToEntity(c)
                );

                await this.table.bulkPut(entities);
                this.log(`  Saved  ${entities.length} ${this.syncName}.`);

                this.lastSyncVersion = response.maxVersion;
            } while (thisBatchSize > 0);

            this.log(`Done saving ${this.syncName}.`);
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error(`Error syncing ${this.syncName}`, e);
        }
    }

    protected get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    protected set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    async execute(): Promise<void> {
        this.log(`Syncing ${this.syncName}...`);

        await this.pullData();

        this.log(`Done syncing ${this.syncName}...`);
    }

    public abstract pushData(): Promise<void>;

}