import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericResponseDto } from "shield.shared";
import { TaxRate } from "src/app/entity-models/tax-rate.entity";
import { DatabaseService } from "../database.service";
import { TaxRateOfflineService } from "../offline-services/tax-rate-offline.service";
import { TaxRateOnlineSerivce } from "../online-services/tax-rate-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class TaxRateDelineationService extends DelineationContext<TaxRate, string> {

    constructor(private taxRateOfflineService: TaxRateOfflineService
        , private taxRateOnlineService: TaxRateOnlineSerivce
        , snackbarService: SnackbarService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService) {
            super(dbService, datasourceDelineationService, snackbarService);
         }

    async getAll(): Promise<GenericResponseDto<TaxRate[]>> {

        const offline = () => {
            return this.taxRateOfflineService.getAll();
        }
        const online = () => {
            return this.taxRateOnlineService.getAll();
        }
        const response = await this.datasourceDelineationService.makeCall<undefined, TaxRate[]>(undefined, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }
}
