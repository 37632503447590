import { ChainHeadquarterProduct } from "./chain-headquarter-product.entity";
import { Customer } from "./customer.entity";

export class ChainHeadquarter {
    id: string; //UUID
    customerId: string;
    groupId: string;
    numberOfStores: number;
    type: string;
    ultimateCustomerId: string; //UUID of Ultimate Customer StoreId
    ultimateCustomer: Customer;
    ultimateAssignedEmployeeId: string;
    ultimateAssignedEmployeeName: string;
    immediateCustomerId: string; //UUID of Immediate Customer StoreId
    immediateCustomer: Customer;
    immediateAssignedEmployeeId: string;
    immediateAssignedEmployeeName: string;
    ownerCustomerId: string; //UUID of Owner Customer StoreId
    ownerCustomer: Customer;
    ownerAssignedEmployeeId: string;
    ownerAssignedEmployeeName: string;
    chainHeadquarterProducts: ChainHeadquarterProduct[] = [];
}
