import {
    Component,
    OnInit,
    Type,
    ViewChild
} from "@angular/core";
import {
    MatSelect,
    MatSelectChange
} from "@angular/material/select";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterService } from "src/app/services/filter.service";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import {
    CustomerTypeEnum,
    GenericLookup,
    RefinerLocation,
    valueSeparator
} from "shield.shared";
import { ProjectCustomerAttributesViewmodel } from "./project-customer-attributes.viewmodel";
import { BaseFilterMapService } from "src/app/services/filter-map-services/base-filter-map.service";
import { Px3Rank } from "src/app/entity-models/px3-rank.entity";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";

@Component({
    selector: "app-project-customer-attributes-filter",
    templateUrl: "./project-customer-attributes-filter.component.html",
    styleUrls: ["./project-customer-attributes-filter.component.scss"]
})
export class ProjectCustomerAttributesFilterComponent extends FilterBaseComponent implements OnInit {
    //view childs
    @ViewChild("storeTypesSelect") storeTypesSelect: MatSelect;
    @ViewChild("msaSelect") msaSelect: MatSelect;
    @ViewChild("phoneSelect") phoneSelect: MatSelect;
    @ViewChild("activeSelect") activeSelect: MatSelect;
    @ViewChild("volumeSelect") volumeSelect: MatSelect;
    @ViewChild("px3RankSelect") px3RankSelect: MatSelect;
    @ViewChild("callableSelect") callableSelect: MatSelect;

    //public vars
    contentComponent: Type<ProjectCustomerAttributesFilterComponent>;
    icon = "bookmark";
    name = "Attributes";
    viewmodel = new ProjectCustomerAttributesViewmodel(this.filterService);
    previousSelectedCallable: Array<string>;

    isPx3RankInitialized = false;
    constructor(private filterService: FilterService, private px3RankService: Px3DelineationService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        await this.viewmodel.initializeStoreTypeDropDown();
        this.previousSelectedCallable = [...this.viewmodel.selectedCallable];
        this.setInitialized();
        this.refinerService.refiners.forEach((refiner) => this.onInputChange(refiner));
    }

    //events
    onOpenedStoreTypesChange(): void {
        this.viewmodel.isAllCustomerTypePresent = this.viewmodel.selectedCustomerType.includes(
            this.viewmodel.allCustomerType
        );
        if (this.viewmodel.isAllCustomerTypePresent) {
            this._refinerService.removeRefinerByLocation(
                RefinerLocation.storeTypes,
                true,
                false
            );
        } else {
            if (!this.storeTypesSelect.panelOpen) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.storeTypes;
                refiner.value = this.viewmodel.selectedCustomerType
                    .map((vm) => vm.name)
                    .join(", ");
                refiner.dataPropertyName = "name";
                refiner.dataValue = this.viewmodel.selectedCustomerType
                    .map((vm) => vm.name)
                    .join(valueSeparator);

                this._refinerService.checkAndUpdateRefiner(refiner);
            }
        }
    }

    onSelectionStoreTypesChange(): void {
        const {isAllPresent, selected} = FilterBaseComponent.onMultiSelectSelectionChange(this.viewmodel.isAllCustomerTypePresent, this.viewmodel.selectedCustomerType, this.viewmodel.allCustomerType);
        this.viewmodel.isAllCustomerTypePresent = isAllPresent;
        this.viewmodel.selectedCustomerType = selected;
    }

    async onOpenedSelectedPx3Change(): Promise<void> {
        this.viewmodel.isAllPx3RankPresent = this.viewmodel.selectedPx3Ranks.includes(
            this.viewmodel.allPx3Rank
        );
        if (this.viewmodel.isAllPx3RankPresent) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.px3Rank,
                true,
                false
            );
        } else {
            if (!this.px3RankSelect.panelOpen) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.px3Rank;
                refiner.value = this.viewmodel.selectedPx3Ranks
                    .map((vm) => vm.name)
                    .join(", ");
                refiner.dataPropertyName = "px3RankId";
                refiner.dataValue = this.viewmodel.selectedPx3Ranks
                    .map((vm) => vm.name === 'Not Ranked' ? 'Not Ranked' : vm.id.id)
                    .join(valueSeparator);

                this.refinerService.checkAndUpdateRefiner(refiner);
            }
        }
    }

    onSelectionPx3RanksChange(): void {
        const {isAllPresent, selected} = FilterBaseComponent.onMultiSelectSelectionChange(this.viewmodel.isAllPx3RankPresent, this.viewmodel.selectedPx3Ranks, this.viewmodel.allPx3Rank);
        this.viewmodel.isAllPx3RankPresent = isAllPresent;
        this.viewmodel.selectedPx3Ranks = selected;
    }

    onOpenedSelectedMsaChange(): void {
        if (!this.msaSelect.panelOpen) {
            if (this.viewmodel.selectedMsa === "All") {
                this._refinerService.removeRefinerByLocation(
                    RefinerLocation.msa,
                    true,
                    false
                );
            } else {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.msa;
                refiner.value = this.viewmodel.selectedMsa;
                refiner.dataPropertyName = "msa";
                refiner.dataValue =
                    this.viewmodel.selectedMsa === this.viewmodel.yes
                        ? BaseFilterMapService.yes
                        : "0";

                this._refinerService.checkAndUpdateRefiner(refiner, true);
            }
        }
    }

    onOpenedSelectedPhoneChange(): void {
        if (!this.phoneSelect.panelOpen) {
            if (this.viewmodel.selectedPhone === "All") {
                this._refinerService.removeRefinerByLocation(
                    RefinerLocation.phone,
                    true,
                    false
                );
            } else {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.phone;
                refiner.value = this.viewmodel.selectedPhone;
                refiner.dataPropertyName = "phone";
                refiner.dataValue =
                    this.viewmodel.selectedPhone === this.viewmodel.yes
                        ? BaseFilterMapService.yes
                        : "0";

                this._refinerService.checkAndUpdateRefiner(refiner, true);
            }
        }
    }

    onOpenedSelectedActiveChange(): void {
        if (!this.activeSelect.panelOpen) {
            if (this.viewmodel.selectedActive === "All") {
                this._refinerService.removeRefinerByLocation(
                    RefinerLocation.isActive,
                    true,
                    false
                );
            } else {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.isActive;
                refiner.value = this.viewmodel.selectedActive;
                refiner.dataPropertyName = "active";
                refiner.dataValue =
                    this.viewmodel.selectedActive === this.viewmodel.yes
                        ? BaseFilterMapService.yes
                        : "0";

                this._refinerService.checkAndUpdateRefiner(refiner, true);
            }
        }
    }

    onOpenedSelectedVolumeChange(): void {
        if (!this.volumeSelect.panelOpen) {
            if (this.viewmodel.selectedVolume === "All") {
                this._refinerService.removeRefinerByLocation(
                    RefinerLocation.volume,
                    true,
                    false
                );
            } else {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.volume;
                refiner.value = this.viewmodel.selectedVolume;
                refiner.dataPropertyName = "volume";
                refiner.dataValue =
                    this.viewmodel.selectedVolume === this.viewmodel.yes
                        ? BaseFilterMapService.yes
                        : "0";

                this._refinerService.checkAndUpdateRefiner(refiner, true);
            }
        }
    }

    async initializePx3RankDropDown(): Promise<void> {
        this.viewmodel.allPx3Rank.name = "All";
        this.viewmodel.notRankedPx3Rank.name = "Not Ranked";
        this.viewmodel.px3Ranks.push(this.viewmodel.allPx3Rank);
        this.viewmodel.px3Ranks.push(this.viewmodel.notRankedPx3Rank);
        const px3Ranks = (await this.px3RankService.getAll());
        for (let rank of px3Ranks){
            this.viewmodel.px3Ranks.push({
                id: rank,
                name: rank.rank,
                description: rank.rank,
            });
        }
        const px3RankRefiner = this.refinerService.getRefinerByLocation(RefinerLocation.px3Rank);
        if (px3RankRefiner) {
            this.onInputChange(px3RankRefiner);
        }
        else {
            this.viewmodel.selectedPx3Ranks.push(this.viewmodel.allPx3Rank);
        }
    }

    onOpenedSelectedCallableChange(): void {
        if (!this.callableSelect.panelOpen) {
            if (this.viewmodel.selectedCallable.includes(this.viewmodel.all)) {
                this.refinerService.removeRefinerByLocation(RefinerLocation.callable, true, false);
            }
            else {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.callable;
                refiner.value = this.viewmodel.selectedCallable.join(", ");
                refiner.dataPropertyName = "callable";
                refiner.dataValue = this.viewmodel.selectedCallable.join(valueSeparator);

                this.refinerService.checkAndUpdateRefiner(refiner, true);
            }
        }
    }

    onCallableSelectionChange(event: MatSelectChange): void {
        const myEvent = event.value as Array<string>; // Come on Google, Strongly type your properties!
        if (this.previousSelectedCallable.includes(this.viewmodel.all) && !!myEvent.find((e) => e !== this.viewmodel.all)) {
            this.viewmodel.selectedCallable = this.viewmodel.selectedCallable.filter((e) => e !== this.viewmodel.all);
        } else if (
            (!this.previousSelectedCallable.includes(this.viewmodel.all) && !!myEvent.find((e) => e === this.viewmodel.all))
            || (!!myEvent.find((e) => e === this.viewmodel.yes)
                && !!myEvent.find((e) => e === this.viewmodel.no)
                && !!myEvent.find((e) => e === this.viewmodel.overridden))) {
            this.viewmodel.selectedCallable = [this.viewmodel.all];
        } else if (!this.viewmodel.selectedCallable?.length) {
            this.viewmodel.selectedCallable = [this.viewmodel.all]
        }
        this.previousSelectedCallable = [...this.viewmodel.selectedCallable];
    }

    async onInputChange(refiner: Refiner): Promise<void> {
        if (!this.isPx3RankInitialized) {
            this.isPx3RankInitialized = true;
            await this.initializePx3RankDropDown();
        }

        if (refiner && this.isInitialized) {
            switch (refiner.location) {
                case RefinerLocation.storeTypes:
                    const selectedCustomerTypes = refiner.value?.split(", ");
                    const rtnSelectedCustomerTypes = new Array<GenericLookup<CustomerTypeEnum>>();

                    if (selectedCustomerTypes) {
                        for (const type of selectedCustomerTypes) {
                            const found = this.viewmodel.customerTypes.find(
                                (st) => st.name === type.trim()
                            );
                            if (found) {
                                rtnSelectedCustomerTypes.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedCustomerType = rtnSelectedCustomerTypes?.length
                        ? rtnSelectedCustomerTypes
                        : [this.viewmodel.allCustomerType];
                    break;
                case RefinerLocation.msa:
                    if (refiner.value !== this.viewmodel.selectedMsa) {
                        this.viewmodel.selectedMsa = !!refiner.value ? refiner.value : this.viewmodel.all;
                    }
                    break;
                case RefinerLocation.phone:
                    if (refiner.value !== this.viewmodel.selectedPhone) {
                        this.viewmodel.selectedPhone = !!refiner.value ? refiner.value : this.viewmodel.all;
                    }
                    break;
                case RefinerLocation.isActive:
                    if (refiner.value !== this.viewmodel.selectedActive) {
                        this.viewmodel.selectedActive = !!refiner.value ? refiner.value : this.viewmodel.all;
                    }
                    break;
                case RefinerLocation.volume:
                    if (refiner.value !== this.viewmodel.selectedVolume) {
                        this.viewmodel.selectedVolume = !!refiner.value ? refiner.value : this.viewmodel.all;
                    }
                    break;
                    case RefinerLocation.px3Rank:
                        const selectedPx3Ranks = refiner.value?.split(", ");
                        const rtnSelectedPx3Ranks = new Array<
                            GenericLookup<Px3Rank>
                        >();

                        if (selectedPx3Ranks) {
                            for (const rank of selectedPx3Ranks) {
                                const found = this.viewmodel.px3Ranks.find(
                                    (rnk) => rnk.name === rank.trim()
                                );
                                if (found) {
                                    rtnSelectedPx3Ranks.push(found);
                                }
                            }
                        }
                        this.viewmodel.selectedPx3Ranks = rtnSelectedPx3Ranks?.length
                            ? rtnSelectedPx3Ranks
                            : [this.viewmodel.allPx3Rank];
                        break;
                case RefinerLocation.callable:
                    if (refiner.value !== this.viewmodel.selectedCallable.join(", ")) {
                        this.viewmodel.selectedCallable = !!refiner.value?.length ? refiner.value.split(", ") : [this.viewmodel.all];
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.storeTypes;
        const storeTypesRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.storeTypes
        );
        if (storeTypesRefiner) {
            this.onInputChange(storeTypesRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.msa;
        const msaRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.msa
        );
        if (msaRefiner) {
            this.onInputChange(msaRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.phone;
        const phoneRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.phone
        );
        if (phoneRefiner) {
            this.onInputChange(phoneRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.isActive;
        const activeRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.isActive
        );
        if (activeRefiner) {
            this.onInputChange(activeRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.volume;
        const volumeRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.volume
        );
        if (volumeRefiner) {
            this.onInputChange(volumeRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.callable;
        const callableRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.callable
        );
        if (callableRefiner) {
            this.onInputChange(callableRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }
}
