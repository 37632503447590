import { OrderDto } from "./order.dto";

export class OrderExtractionDto {
    id: string;
    wholesalerId: string;
    dateExtracted: string;
    createdUserFullName: string;
    createdUserId: string;
    createdUserZrt: string | null;
    createdUtcDateTime: string;

    orders: OrderDto[];
}
