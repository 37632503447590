import { TimeEntryTypeDto } from "./time-entry-type.dto";

export class TimeEntryDto {
    id: string;
    sourceId: string;
    start: string;
    end: string;
    type: TimeEntryTypeDto;
    name: string;
    comments: string;
    employeeId: string;
    createdDate: string;
    createdBy: string;
    updatedDate: string;
    updatedBy: string;
    isDeleted: boolean;
    rowversion: string;
}
