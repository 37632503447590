<div
    class="distribution-modal d-flex flex-column"
    cdkDrag
    cdkDragBoundary=".cdk-overlay-container"
>
    <div cdkDragHandle>
        <div class="modal-header">
            Add Products
            <mat-icon class="close-icon" appThrottleClick (throttledClick)="close()">close</mat-icon>
        </div>
    </div>
    <div class="modal-body flex-grow-1 d-flex flex-column">
        <app-distribution-grid
            [mode]="mode"
            [isOrderMode]="isOrderMode"
            #gridComponent
        ></app-distribution-grid>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-white" (click)="close()">
            Close
        </button>
    </div>
</div>
