import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import SignaturePad from 'signature_pad';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';

import { PaymentStatusViewModel } from './payment-status.viewmodel';

@Component({
    selector: 'app-payment-status',
    templateUrl: './payment-status.component.html',
    styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent implements OnInit, OnDestroy, AfterViewChecked {

    viewmodel: PaymentStatusViewModel;
    viewSet = false;

    @ViewChild("agreement") agreement: ElementRef<any>;

    constructor(public injectedData: SwisherOverlayRef<
            PaymentStatusViewModel,
            PaymentStatusComponent
        >) { }

    ngOnInit(): void {
        this.viewmodel = this.injectedData.data;
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }

    ngAfterViewChecked(): void {
        if (!this.viewSet) {
            this.viewSet = true;

            setTimeout(() => {
                const canvas: HTMLCanvasElement = document.getElementById(
                    "signature-canvas"
                ) as HTMLCanvasElement;

                if (canvas) {
                    this.viewmodel.signaturePad = new SignaturePad(canvas, {
                        dotSize: 2,
                        penColor: "rgb(1,1,1)",
                        onBegin: () => {
                            this.viewmodel.onBeginSignature();
                        },
                    });

                    if (this.viewmodel.payment.customerContactSignature) {
                        this.viewmodel.signaturePad.fromDataURL(
                            this.viewmodel.payment.customerContactSignature.image
                        );
                    }
                }

                if (this.agreement) {
                    this.viewmodel.agreement = this.agreement;
                }
            }, 0);
        }
    }
}
