import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { Customer } from "src/app/entity-models/customer.entity";
import { ProjectCustomer } from "src/app/entity-models/project-customer.entity";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { ProjectSummaryStoresGridViewmodel } from "./project-summary-stores.grid.viewmodel";

export class ProjectSummaryStoresViewmodel {
    checkingStores = true;
    projectCustomers = new Array<ProjectCustomer>();

    grid: GridComponent;
    gridData = new Array<GridData>();
    isFixedLayout = true;

    columnDef: ColumnDef[] = [
        {
            headerName: "Name",
            dataPropertyName: "name",
            isAvailable: true,
            isSelected: true
        },
        {
            headerName: "Address",
            dataPropertyName: "address",
            isAvailable: true,
            isSelected: true
        },
        {
            headerName: "City",
            dataPropertyName: "city",
            isAvailable: true,
            isSelected: true
        },
        {
            headerName: "State",
            dataPropertyName: "state",
            isAvailable: true,
            isSelected: true,
        }
    ];

    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    selectedColumnVisibilityComunicator = this.columnDef.map((cd) => cd.isAvailable && cd.headerName);

    customerDelineationService: CustomerDelineationService;

    constructor(customerDelineationService: CustomerDelineationService){
        this.customerDelineationService = customerDelineationService;
    }

    initialize(grid: GridComponent): void {
        this.grid = grid;
    }

    async buildProjectCustomers(projectCustomers: ProjectCustomer[]): Promise<void> {
        this.projectCustomers = projectCustomers.slice();
        let customers = new Array<Customer>();
        const ids = projectCustomers.map((pc) => pc.customerId);
        for (let i = 0; i < ids.length; i += 1000) {
            const chunk = ids.slice(i, i + 1000)
            const customersResponse = await this.customerDelineationService.getByIds(chunk);
            if (!customersResponse) { return; }
            customers.push(...customersResponse.values);
        }
        customers.sort((a, b) => a.name > b.name ? 1 : a.name > b.name ? -1 : 0);

        let gridData = new Array<GridData>();

        for (const customer of customers) {
            if (customer) {
                const gridItem: GridData = {
                    data: new ProjectSummaryStoresGridViewmodel(customer),
                    detailArrayName: null,
                    isExpanded: false,
                    index: null
                };
                gridData.push(gridItem);
            }
        }
        this.gridData = gridData;

        this.dataSource = new TableVirtualScrollDataSource(
            this.gridData
        );
    }
}
