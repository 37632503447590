<ng-container *ngIf="projectApplicationService.selectedIndex == currentStep">
    <div class="row">
        <div class="col-lg-2 col-md-12 col-sm-12">
        </div>
        <div class="col-lg-1 col-md-12 col-sm-12">
            <h1 class="m-teal">
                Products
            </h1>
        </div>
        <div class="col-lg-2 col-md-12 col-sm-12 v-center">
            <mat-slide-toggle [(ngModel)]="viewmodel.isWholesalerbased" (change)="viewmodel.basedOnWholesalerChange()">
                {{viewmodel.isWholesalerbased ? viewmodel.wholesalerBasedText + ':' : viewmodel.nonWholesalerBasedText +
                ':' }}
            </mat-slide-toggle>
        </div>
        <div *ngIf="viewmodel.isWholesalerbased" class="col-lg-3 col-md-12 col-sm-12">
            <angular2-multiselect [data]="viewmodel.wholesalers" [(ngModel)]="viewmodel.selectedWholesaler"
                [settings]="viewmodel.wholeSalerDropdownSettings" (onDeSelect)="viewmodel.onWholesalerSelectChange()"
                (onSelect)="viewmodel.onWholesalerSelectChange()"
                (onDeSelectAll)="viewmodel.onWholesalerSelectChange()">
            </angular2-multiselect>
        </div>
        <div *ngIf="!viewmodel.isWholesalerbased" class="col-lg-3 col-md-12 col-sm-12">

        </div>
        <div class="col-lg col-md-12 col-sm-12">
            <h2 class="m-teal">
                Products Selected For Project: {{viewmodel?.assignedProducts?.length ? viewmodel.assignedProducts.length
                : 0}}
            </h2>
        </div>
        <div class="col-lg-1 col-md-12 col-sm-12 v-center">
            <button appThrottleClick *ngIf="viewmodel.shouldShowOrderDates" mat-raised-button class="sw-red-btn actionBtn p-1"
                (throttledClick)="viewmodel.openOrderDates()">Order Dates -
                {{viewmodel.projectStateService.project?.projectOrderDates?.length
                ? viewmodel.projectStateService.project?.projectOrderDates?.length : 0}}</button>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-12">
            <div class="row">
                <div class="col">
                    <b><u>Categories</u></b>
                </div>
            </div>
            <div class="row workable-view-height">
                <div class="col">
                    <mat-radio-group aria-labelledby="radio-group-label" class="radio-group"
                                     (change)="viewmodel.categoryChanged($event)" [(ngModel)]="viewmodel.selectedCategory">
                        <mat-radio-button color="warn" class="radio-button" *ngFor="let category of viewmodel.categories"
                                          [value]="category">
                            {{category}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-10 col-sm-12">
            <div class="row header-container">
                <div class="search-text">
                    <div class="v-center">
                        Select All
                    </div>
                    <div class="w-100 mt-1 mb-2">
                        <mat-form-field appearance="outline" color="accent" class="w-100">
                            <mat-label>Search</mat-label>
                            <input #unAssignedSearch type="text" id="unassigned-search-text"
                                [(ngModel)]="viewmodel.unAssignedSearchText" matInput />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row header-container">
                <div class="col-1 padding-left">
                    <mat-checkbox color="warn" class="margin-top"
                        (change)="viewmodel.selectAllUnassignedChanged($event)"
                        [(ngModel)]="viewmodel.allUnassignedchecked"></mat-checkbox>
                </div>
                <div (click)="viewmodel.unAssignedSort('description')" class="col large-font">
                    <b>Description</b>
                    <fa-icon *ngIf="viewmodel.unAssignedFilteredProductsSortDto.name === 'description'
                        && viewmodel.unAssignedFilteredProductsSortDto.direction === viewmodel.directions.ascending"
                        [icon]="faArrowUp" class="m-1"></fa-icon>
                    <fa-icon *ngIf="viewmodel.unAssignedFilteredProductsSortDto.name === 'description'
                        && viewmodel.unAssignedFilteredProductsSortDto.direction === viewmodel.directions.descending"
                        [icon]="faArrowDown" class="m-1"></fa-icon>
                </div>
                <div (click)="viewmodel.unAssignedSort('uin')" *ngIf="viewmodel.isWholesalerbased"
                    class="col large-font display-padding">
                    <b>UIN</b>
                    <fa-icon *ngIf="viewmodel.unAssignedFilteredProductsSortDto.name === 'uin'
                        && viewmodel.unAssignedFilteredProductsSortDto.direction === viewmodel.directions.ascending"
                        [icon]="faArrowUp" class="m-1"></fa-icon>
                    <fa-icon *ngIf="viewmodel.unAssignedFilteredProductsSortDto.name === 'uin'
                        && viewmodel.unAssignedFilteredProductsSortDto.direction === viewmodel.directions.descending"
                        [icon]="faArrowDown" class="m-1"></fa-icon>
                </div>
                <div (click)="viewmodel.unAssignedSort('dateAvailable')" *ngIf="viewmodel.isWholesalerbased"
                    class="col large-font display-padding">
                    <b>Date Available</b>
                    <fa-icon *ngIf="viewmodel.unAssignedFilteredProductsSortDto.name === 'dateAvailable'
                        && viewmodel.unAssignedFilteredProductsSortDto.direction === viewmodel.directions.ascending"
                        [icon]="faArrowUp" class="m-1"></fa-icon>
                    <fa-icon *ngIf="viewmodel.unAssignedFilteredProductsSortDto.name === 'dateAvailable'
                        && viewmodel.unAssignedFilteredProductsSortDto.direction === viewmodel.directions.descending"
                        [icon]="faArrowDown" class="m-1"></fa-icon>
                </div>
                <div (click)="viewmodel.unAssignedSort('upc')" *ngIf="!viewmodel.isWholesalerbased"
                    class="col large-font display-padding">
                    <b>Display UPC</b>
                    <fa-icon *ngIf="viewmodel.unAssignedFilteredProductsSortDto.name === 'upc'
                        && viewmodel.unAssignedFilteredProductsSortDto.direction === viewmodel.directions.ascending"
                        [icon]="faArrowUp" class="m-1"></fa-icon>
                    <fa-icon *ngIf="viewmodel.unAssignedFilteredProductsSortDto.name === 'upc'
                        && viewmodel.unAssignedFilteredProductsSortDto.direction === viewmodel.directions.descending"
                        [icon]="faArrowDown" class="m-1"></fa-icon>
                </div>
            </div>
            <cdk-virtual-scroll-viewport #unassignedViewport itemSize="72" class="viewport drop-list" cdkDropList
                cdkDropListSortingDisabled unassignedViewport #unAssignedProductList="cdkDropList"
                id="unAssignedProducts" [cdkDropListData]="viewmodel.unAssignedFilteredProducts"
                [cdkDropListConnectedTo]="[assignedProductList]" (cdkDropListDropped)="viewmodel.drop($event)">

                <div [id]="item.product.id + ',' + item.wholesalerId" class="drop-box viewport-item" [class.odd]="odd"
                    *cdkVirtualFor="let item of viewmodel.unAssignedFilteredProducts; let odd = odd" cdkDrag>
                    <div [id]="item.wholsalerId" class="row data-row">
                        <div class="col-1">
                            <mat-checkbox color="warn" class="margin-top" [(ngModel)]="item.checked"></mat-checkbox>
                        </div>

                        <div class="col">
                            {{item.description}}
                        </div>
                        <div *ngIf="viewmodel.isWholesalerbased" class="col">
                            {{item.uin}}
                        </div>
                        <div *ngIf="viewmodel.isWholesalerbased" class="col">
                            {{item.dateAvailableFormatted}}
                        </div>
                        <div *ngIf="!viewmodel.isWholesalerbased" class="col">
                            {{item.upc}}
                        </div>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
        <div class="col-lg-2 col-md-12 col-sm-12 assignment-arrows-col">
            <div class="row v-center assignment-arrows">
                <button mat-raised-button class="btn-white mb-3" (click)="viewmodel.addClicked()">
                    <img class="center-fit" [src]="viewmodel.arrowRightimageLocation" alt="Add to project." />
                </button>
            </div>
            <div class="row v-center assignment-arrows">
                <button *ngIf="viewmodel.assignedFilteredProducts?.length > 0" mat-raised-button
                    class="btn-white mt-3" (click)="viewmodel.removeClicked()">
                    <img class="center-fit" [src]="viewmodel.arrowLeftimageLocation" alt="Add to project." />
                </button>
            </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="row header-container">
                <div class="search-text">
                    <div class="v-center">
                        Select All
                    </div>
                    <div class="w-100 mt-1 mb-2">
                        <mat-form-field appearance="outline" color="accent" class="w-100">
                            <mat-label>Search</mat-label>
                            <input #assignedSearch type="text" id="assigned-search-text"
                                [(ngModel)]="viewmodel.assignedSearchText" matInput />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row header-container">
                <div class="col-1 padding-left">
                    <mat-checkbox color="warn" class="margin-top" (change)="viewmodel.selectAllAssignedChanged($event)"
                        [(ngModel)]="viewmodel.allAssignedchecked"></mat-checkbox>
                </div>
                <div (click)="viewmodel.assignedSort('description')" class="col large-font">
                    <b>Description</b>
                    <fa-icon *ngIf="viewmodel.assignedFilteredProductsSortDto.name === 'description'
                        && viewmodel.assignedFilteredProductsSortDto.direction === viewmodel.directions.ascending"
                        [icon]="faArrowUp" class="m-1"></fa-icon>
                    <fa-icon *ngIf="viewmodel.assignedFilteredProductsSortDto.name === 'description'
                        && viewmodel.assignedFilteredProductsSortDto.direction === viewmodel.directions.descending"
                        [icon]="faArrowDown" class="m-1"></fa-icon>
                </div>
                <div (click)="viewmodel.assignedSort('uin')" *ngIf="viewmodel.isWholesalerbased"
                    class="col large-font display-padding">
                    <b>UIN</b>
                    <fa-icon *ngIf="viewmodel.assignedFilteredProductsSortDto.name === 'uin'
                        && viewmodel.assignedFilteredProductsSortDto.direction === viewmodel.directions.ascending"
                        [icon]="faArrowUp" class="m-1"></fa-icon>
                    <fa-icon *ngIf="viewmodel.assignedFilteredProductsSortDto.name === 'uin'
                        && viewmodel.assignedFilteredProductsSortDto.direction === viewmodel.directions.descending"
                        [icon]="faArrowDown" class="m-1"></fa-icon>
                </div>
                <div (click)="viewmodel.assignedSort('dateAvailable')" *ngIf="viewmodel.isWholesalerbased"
                    class="col large-font display-padding">
                    <b>Date Available</b>
                    <fa-icon *ngIf="viewmodel.assignedFilteredProductsSortDto.name === 'dateAvailable'
                        && viewmodel.assignedFilteredProductsSortDto.direction === viewmodel.directions.ascending"
                        [icon]="faArrowUp" class="m-1"></fa-icon>
                    <fa-icon *ngIf="viewmodel.assignedFilteredProductsSortDto.name === 'dateAvailable'
                        && viewmodel.assignedFilteredProductsSortDto.direction === viewmodel.directions.descending"
                        [icon]="faArrowDown" class="m-1"></fa-icon>
                </div>
                <div (click)="viewmodel.assignedSort('wholesalerName')" *ngIf="viewmodel.isWholesalerbased"
                    class="col large-font display-padding">
                    <b>Wholesaler</b>
                    <fa-icon *ngIf="viewmodel.assignedFilteredProductsSortDto.name === 'wholesalerName'
                        && viewmodel.assignedFilteredProductsSortDto.direction === viewmodel.directions.ascending"
                        [icon]="faArrowUp" class="m-1"></fa-icon>
                    <fa-icon *ngIf="viewmodel.assignedFilteredProductsSortDto.name === 'wholesalerName'
                        && viewmodel.assignedFilteredProductsSortDto.direction === viewmodel.directions.descending"
                        [icon]="faArrowDown" class="m-1"></fa-icon>
                </div>
                <div (click)="viewmodel.assignedSort('upc')" *ngIf="!viewmodel.isWholesalerbased"
                    class="col large-font display-padding">
                    <b>Display UPC</b>
                    <fa-icon *ngIf="viewmodel.assignedFilteredProductsSortDto.name === 'upc'
                        && viewmodel.assignedFilteredProductsSortDto.direction === viewmodel.directions.ascending"
                        [icon]="faArrowUp" class="m-1"></fa-icon>
                    <fa-icon *ngIf="viewmodel.assignedFilteredProductsSortDto.name === 'upc'
                        && viewmodel.assignedFilteredProductsSortDto.direction === viewmodel.directions.descending"
                        [icon]="faArrowDown" class="m-1"></fa-icon>
                </div>
            </div>
            <cdk-virtual-scroll-viewport #assignedViewport itemSize="100" class="viewport drop-list" cdkDropList
                cdkDropListSortingDisabled #assignedProductList="cdkDropList" id="assignedProducts"
                [cdkDropListData]="viewmodel.assignedFilteredProducts"
                [cdkDropListConnectedTo]="[unAssignedProductList]" (cdkDropListDropped)="viewmodel.drop($event)">
                <div [id]="item.product.id + ',' + item.wholesalerId" class="drop-box viewport-item" [class.odd]="odd"
                    *cdkVirtualFor="let item of viewmodel.assignedFilteredProducts; let odd = odd; let i = index"
                    cdkDrag>
                    <div class="row data-row">
                        <div class="col-1">
                            <mat-checkbox color="warn" class="margin-top" (change)="viewmodel.setSelectAllAssigned()"
                                [(ngModel)]="item.checked"></mat-checkbox>
                        </div>
                        <div class="col">
                            {{item.description}}
                        </div>
                        <div *ngIf="viewmodel.isWholesalerbased" class="col">
                            {{item.uin}}
                        </div>
                        <div *ngIf="viewmodel.isWholesalerbased" class="col">
                            {{item.dateAvailableFormatted}}
                        </div>
                        <div *ngIf="viewmodel.isWholesalerbased" class="col">
                            {{item.wholesalerName}}
                        </div>
                        <div *ngIf="!viewmodel.isWholesalerbased" class="col">
                            {{item.upc}}
                        </div>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</ng-container>
