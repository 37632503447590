import { ActivatedRoute, ChildActivationEnd, NavigationEnd, Params, Router } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import { buffer, filter, map, take } from "rxjs/operators";
import { Employee } from "src/app/entity-models/employee.entity";
import { AppStateService } from "src/app/services/app-state.service";
import { ProjectTabs } from "./project-enums/project-tabs";
import { ProjectStateService } from "../../services/project-state-service";
import { OverlayService } from "src/app/services/overlay.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { ProjectApplicationService } from "./project-services/project-application.service";
import { newSequentialId, ProjectStatuses, ProjectStatusLookup, VisibleProjectStatuses, ProjectTypeLookup, ProjectTypes, ProjectValidation, StagingProjectStatus } from "shield.shared";
import { ProjectValidationSummaryViewmodel } from "./project-validation-summary/project-validation-summary.viewmodel";
import { ProjectValidationSummaryComponent } from "./project-validation-summary/project-validation-summary.component";

import { PleaseWaitService } from "src/app/services/please-wait.service";
import { ProjectConverterService } from "src/app/services/converter-services/project-converter.service";
import { NewProjectViewmodel } from "../new-project-modal/new-project.viewmodel";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { NewProjectModalComponent } from "../new-project-modal/new-project-modal.component";
import { Project } from "src/app/entity-models/project.entity";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { SyncService } from "src/app/services/sync.service";

export class ProjectViewmodel {

    //private vars
    private appStateService: AppStateService;
    private childRouteParamsSubscription: Subscription;
    private childrenParamsSubscription: Subscription;
    private childUrlSubscription: Subscription;
    private employee: Employee;
    private employeeSubscription: Subscription;
    private projectSubscription: Subscription;
    private activatedRoute: ActivatedRoute;
    private router: Router;
    private routerEventSubscription: Subscription;
    private projectApplicationService: ProjectApplicationService;
    private snackbarService: SnackbarService;
    private pleaseWaitService: PleaseWaitService;
    private shouldWait$ = new BehaviorSubject<boolean>(true);
    private projectDelineationService: ProjectDelineationService;
    private indexSubscription: Subscription;

    //readonly vars
    readonly projectTabSummary = ProjectTabs.ProjectSummary;
    readonly projectTabMetrics = ProjectTabs.ProjectMetrics;
    readonly projectTabConfiguration = ProjectTabs.ProjectConfiguration;

    //public vars
    projectStateService: ProjectStateService;
    projectStatus: string;
    projectTab: ProjectTabs;
    overlayService: OverlayService;
    newProjectOverlayRef: SwisherOverlayRef<
        NewProjectViewmodel,
        NewProjectModalComponent
    >;

    constructor(projectStateService: ProjectStateService,
        projectApplicationService: ProjectApplicationService,
        router: Router,
        appStateService: AppStateService,
        activatedRoute: ActivatedRoute,
        overlayService: OverlayService,
        snackbarService: SnackbarService,
        pleaseWaitService: PleaseWaitService,
        projectDelineationService: ProjectDelineationService,
        private syncService: SyncService,
        ) {

        this.projectTab = this.projectTabConfiguration;
        this.projectStateService = projectStateService;
        this.projectApplicationService = projectApplicationService;
        this.router = router;
        this.appStateService = appStateService;
        this.activatedRoute = activatedRoute;
        this.overlayService = overlayService;
        this.snackbarService = snackbarService;
        this.pleaseWaitService = pleaseWaitService;
        this.projectDelineationService = projectDelineationService;
    }

    initialize(): void {
        const routeEndEvent$ = this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd)
        );

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.employeeSubscription = this.appStateService.currentEmployee.subscribe(
                (employee) => {
                    if (employee && this.employee?.id !== employee.id) {
                        this.employee = employee;
                    }
                }
            );
        }

        if (!this.projectSubscription || this.projectSubscription.closed) {
            this.projectSubscription = this.projectStateService.observableProject.subscribe(
                (project) => {
                    if (project) {
                        const projectStatus = VisibleProjectStatuses.find(s => s === project.projectStatusId) || ProjectStatuses.Editable;

                        this.projectStatus = ProjectStatusLookup.find((ps) => ps.id === projectStatus)?.description;
                        if (this.projectTab === ProjectTabs.ProjectConfiguration) {
                            this.projectDelineationService.upsertProject(project);
                        }
                    }
                }
            );
        }

        if (
            !this.routerEventSubscription ||
            this.routerEventSubscription.closed
        ) {
            this.routerEventSubscription = this.router.events
                .pipe(
                    filter(
                        (e) =>
                            e instanceof ChildActivationEnd &&
                            e.snapshot.component === this.activatedRoute.component
                    ),
                    buffer(routeEndEvent$),
                    map(
                        ([ev]) =>
                            (ev as ChildActivationEnd)?.snapshot.firstChild.data
                    )
                )
                .subscribe(() => {
                    if (
                        this.childRouteParamsSubscription &&
                        !this.childRouteParamsSubscription.closed
                    ) {
                        this.childRouteParamsSubscription.unsubscribe();
                    }
                    this.childRouteParamsSubscription = this.activatedRoute.children[0]?.params.subscribe(
                        () => {
                            if (this.router.url.indexOf(ProjectTabs.ProjectSummary) > 0) {
                                this.setTab(this.projectTabSummary);
                            }
                            else if (this.router.url.indexOf(ProjectTabs.ProjectMetrics) > 0) {
                                this.setTab(this.projectTabMetrics);
                            }
                            else {
                                this.setTab(this.projectTabConfiguration);
                            }
                        }
                    );
                });
        }

        if (
            !this.childUrlSubscription ||
            this.childUrlSubscription.closed
        ) {
            if (this.activatedRoute.firstChild) {
                this.childUrlSubscription = this.activatedRoute.firstChild.url.subscribe(
                    (url) => {
                        switch (url[0].path) {
                            case ProjectTabs.ProjectSummary:
                                this.setTab(this.projectTabSummary);
                                break;
                            case ProjectTabs.ProjectMetrics:
                                this.setTab(this.projectTabMetrics);
                                break;
                            default:
                                this.setTab(this.projectTabConfiguration);
                                break;
                        }
                    }
                );
            }
        }

        if (
            !this.childrenParamsSubscription ||
            this.childrenParamsSubscription.closed
        ) {
            this.childrenParamsSubscription = this.activatedRoute.params.subscribe(
                async (params) => {
                    const projectId = params.projectId as string;
                    const wholesalerId = params.wholesalerId as string;
                    const chainHqCode = params.chainHqCode as string;
                    const retailId = params.retailId as string;

                    const isStaging = this.projectTab === ProjectTabs.ProjectConfiguration;

                    if (isStaging) {
                        const stagingResponse = await this.projectDelineationService.checkStagingProjectStatus(projectId);

                        if (projectId && stagingResponse?.values?.status === StagingProjectStatus.OK) {
                            await this.projectStateService.loadAndNotify(projectId, isStaging);
                        }
                    } else {
                        if (projectId) {
                            await this.projectStateService.loadAndNotify(projectId);
                        }
                    }

                    if (this.projectStateService.project?.id && !this.activatedRoute?.firstChild) {

                        if (wholesalerId) {
                            void this.router.navigate(["/details/project/", this.projectStateService.project.id, "configuration", "wholesaler", wholesalerId]);
                        } else if (chainHqCode) {
                            void this.router.navigate(["/details/project/", this.projectStateService.project.id, "configuration", "chain-hq", chainHqCode]);
                        } else if (retailId) {
                            void this.router.navigate(["/details/project/", this.projectStateService.project.id, "configuration", "retail", retailId]);
                        } else {
                            void this.router.navigate(["/details/project/", this.projectStateService.project.id, "configuration"]);
                        }
                    } else if (!this.projectStateService.project?.id) {
                        this.projectApplicationService.setCanDeactivate(true);
                        await this.router.navigate(["/details/project-list/"]);
                    } else {
                        this.projectApplicationService.isInProfile = false;
                        this.projectStateService.notify();
                    }
                }
            );
        }

        if (!this.indexSubscription || this.indexSubscription.closed) { // save project to staging table on index toggle
            this.indexSubscription = this.projectApplicationService.observableSelectedIndex.subscribe(async (index) => {
                if (!index) return;

                this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

                if (this.projectStateService.project?.id) {
                    await this.projectDelineationService.upsertProject(this.projectStateService.project);
                }
                this.shouldWait$.next(true);
            });
        }
    }

    openNewProjectModal(): void {
        const data = new NewProjectViewmodel();
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.newProjectOverlayRef.close(data);
        }

        this.newProjectOverlayRef = this.overlayService.open(NewProjectModalComponent, data, true);

        this.newProjectOverlayRef.afterClosed$.subscribe(async (ref) => {
            if (ref?.data?.isConfirmed) {
                const model = ref.data;
                const project = new Project();
                project.id = newSequentialId();
                project.projectTypeId = model.selectedProjectType;
                project.projectStatusId = ProjectStatuses.Staging;
                project.name = "";
                project.areStoresVisibleToAll = false;

                const response = await this.projectDelineationService.upsertProject(project);

                if (response.isError) {
                    this.snackbarService.showError(response.message);
                    return;
                }


                void this.router.navigate([`/details/project/${project.id}`]);
            }
        })

    }

    async cancel(): Promise<void> {
        if (this.projectTab === ProjectTabs.ProjectConfiguration) {
            return await this.projectApplicationService.cancel();
        }
        void this.router.navigate(["/details/project-list"]);

    }

    getSaveText(): string {
        if (this.projectStateService.project
            && this.projectStateService.project.projectStatusId !== ProjectStatuses.Staging
        ) {
            return "Save Changes";
        }
        return "Save";
    }

    setTab(tab: ProjectTabs): void {
        this.projectTab = tab;
    }

    async validate(): Promise<void> {
        const beginingStatusId = this.projectStateService.project.projectStatusId;
        const results = ProjectValidation.runValidation(ProjectConverterService.projectToProjectDto(this.projectStateService.project));

        if (!results.isInvalid && results.validationErrors?.length === 0) {
            this.projectStateService.project = ProjectConverterService.projectDtoToProject(results.projectDto);
            await this.save();

        } else {
            //Open Validation Summary
            const data = new ProjectValidationSummaryViewmodel();
            data.projectValidationDto = results;
            data.oldStatusId = beginingStatusId;
            data.headerLeftText = "Validation Summary"
            data.buttonLeftFunction = () => ref.close(data);
            data.buttonRightFunction = () => {
                data.isConfirmed = true;
                ref.close(data);
            }
            data.initialize();

            const ref = this.overlayService.open(ProjectValidationSummaryComponent, data, true);

            ref.afterClosed$.subscribe(async (response) => {
                if (response?.data?.isConfirmed) {
                    this.projectStateService.project = ProjectConverterService.projectDtoToProject(data.projectValidationDto.projectDto);
                    await this.save();
                }
            })
        }
    }

    async save(): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        const result = await this.projectStateService.saveProjectAndNotify();
        if (result && !result.isError) {
            this.snackbarService.showInfo("Save successful!");
            this.syncService.forceOutboundSync();

            this.projectApplicationService.isSaving = true; // stop the canDeactivate from triggering
            await this.router.navigate(["/details/project-list/"]);
            this.projectApplicationService.isSaving = false;
        }
        this.shouldWait$.next(false);
    }

    unsubscribe(): void {
        if (this.employeeSubscription && !this.employeeSubscription.closed) {
            this.employeeSubscription.unsubscribe();
        }
        if (this.routerEventSubscription && !this.routerEventSubscription.closed) {
            this.routerEventSubscription.unsubscribe();
        }
        if (this.childRouteParamsSubscription && !this.childRouteParamsSubscription.closed) {
            this.childRouteParamsSubscription.unsubscribe();
        }
        if (this.childrenParamsSubscription && !this.childrenParamsSubscription.closed) {
            this.childrenParamsSubscription.unsubscribe();
        }
        if (
            this.childUrlSubscription && !this.childUrlSubscription.closed) {
            this.childUrlSubscription.unsubscribe();
        }
        if (
            this.indexSubscription && !this.indexSubscription.closed) {
            this.indexSubscription.unsubscribe();
        }
    }
}
