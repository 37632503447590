import { GenericLookup } from "../generic";

export enum EmployeeRoleType {
    ShieldAdmin = 0,
    TM = 1,
    RM = 2,
    ZM = 3,
    CS = 4,
    CorpAdmin = 5,
    NAM = 6,
    ZCAM = 7,
    SPM = 8,
    ShieldSupport = 9
}

export const EmployeeRoleTypeLookup: GenericLookup<EmployeeRoleType>[] = [
    { id: EmployeeRoleType.ShieldAdmin, name: "ShieldAdmin", description: "Shield Administrator" },
    { id: EmployeeRoleType.TM, name: "TM", description: "Territory Manager" },
    { id: EmployeeRoleType.RM, name: "RM", description: "Regional Manager" },
    { id: EmployeeRoleType.ZM, name: "ZM", description: "Zone Manager" },
    { id: EmployeeRoleType.CS, name: "CS", description: "Customer Service" },
    { id: EmployeeRoleType.CorpAdmin, name: "CorpAdmin", description: "Corporate Administrator" },
    { id: EmployeeRoleType.NAM, name: "NAM", description: "National Account Manager" },
    { id: EmployeeRoleType.ZCAM, name: "ZCAM", description: "Zone Chain Account Manager" },
    { id: EmployeeRoleType.SPM, name: "SPM", description: "Special Project Manager" },
    { id: EmployeeRoleType.ShieldSupport, name: "ShieldSupport", description: "Shield Support" }
];
