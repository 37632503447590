import { Injectable } from "@angular/core";
import { GenericResponseDto } from "shield.shared";
import { Call } from "src/app/accounts/call-master/call-services/call.service";
import { Receipt } from "src/app/entity-models/receipt";
import { DatabaseService } from "../database.service";
import { ReceiptOfflineService } from "../offline-services/receipt-offline.service";
import { ReceiptOnlineService } from "../online-services/receipt-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class ReceiptDelineationService extends DelineationContext<Receipt, string> {

    constructor(private receiptOfflineService: ReceiptOfflineService
        , private receiptOnlineServie: ReceiptOnlineService
        , snackbarService: SnackbarService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService) {
            super(dbService, datasourceDelineationService, snackbarService);
         }

    async getCallReceiptsByCall(call: Call): Promise<GenericResponseDto<Receipt[]>> {

        if (!call) { return null;};

        const offline = (key: Call) => {
            return this.receiptOfflineService.getCallReceiptsByCall(key);
        }
        const online = async (key: Call) => {
            let rtn = new GenericResponseDto<Receipt[]>();
            const receipts = await this.receiptOfflineService.getCallReceiptsByCall(key);
            if (receipts?.length) {
                rtn.values = receipts;
            } else {
                rtn = await this.receiptOnlineServie.getCallReceiptsByCall(key);
            }
            return rtn;
        }
        const response = await this.datasourceDelineationService.makeCall<Call, Receipt[]>(call, offline, online);

        return response;
    }

    async getLocalReceiptByCall(call: Call): Promise<GenericResponseDto<Receipt[]>> {

        if (!call) { return null;};

        const offline = (key: Call) => {
            return this.receiptOfflineService.getLocalReceiptByCall(key);
        }
        const online = async (key: Call) => {
            const values = await this.receiptOfflineService.getLocalReceiptByCall(key);
            const response = new GenericResponseDto<Receipt[]>();
            response.id = key.id;
            response.values = values;
            return response;
        }
        const response = await this.datasourceDelineationService.makeCall<Call, Receipt[]>(call, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }
}
