import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CallTypes, GenericResponseDto, ReceiptDto } from "shield.shared";
import { Receipt } from "src/app/entity-models/receipt";
import { Call } from "../../accounts/call-master/call-services/call.service";
import { ReceiptConverterService } from "../converter-services/receipt-converter.service";

@Injectable()
export class ReceiptOnlineService {

    constructor(
        private http: HttpClient
    ){}

    async getCallReceiptsByCall(call: Call): Promise<GenericResponseDto<Receipt[]>> {

        switch (call.callType) {
            case CallTypes.retail:
            case CallTypes.rmWholesale:
                const receiptPromises: Promise<GenericResponseDto<ReceiptDto>>[] = [];

                call.callReceipts ??= [];
                for (const callReceipt of call.callReceipts) {

                        receiptPromises.push(
                            this.http
                                .get<GenericResponseDto<ReceiptDto>>(
                                    `/api/calls/${call.id}/receiptId/${callReceipt.id}`
                                )
                                .toPromise()
                        );
                }
                        const result = await Promise.all(receiptPromises);
                        const values = result.filter((r) => !!r.values).map((r) => r.values);

                        const rtn = new GenericResponseDto<Receipt[]>();
                        if (result) {
                            rtn.count = values?.length ?? 0;
                            rtn.id = call.id;
                            rtn.isError = result.some((r) => r.isError);

                            const messages = result.filter((r) => r.isError).map((r) => r.message);
                            if (messages) {
                                rtn.message = messages.join(",");
                            }

                            rtn.values = new Array<Receipt>();
                            for (const r of values) {
                                rtn.values.push(await ReceiptConverterService.receiptDtoToReceipt(r));
                            }
                        }

                        return rtn;
                    break;
            default:
                return null;
                break;
        }
    }
}
