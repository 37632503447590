import { Injectable } from "@angular/core";
import { Subsidiary } from "shield.shared";
import { CallReceipt } from "src/app/entity-models/call-receipt";
import { RetailReceiptComponent } from "../receipts/retail-receipt/retail-receipt.component";

const RECEIPT_EXTENSION_SWISHER = "00";
const RECEIPT_EXTENSION_EAS = "01";
@Injectable()
export class RetailReceiptService {

    buildRetailCallReceipt(
        receiptId: string,
        subsidiary: Subsidiary,
        retailReceipt: RetailReceiptComponent,
    ): CallReceipt {
        if (subsidiary === Subsidiary.Swisher && retailReceipt.swisherRetailFormat) {
            return retailReceipt.swisherRetailFormat.buildRetailCallReceipt(
                receiptId,
                RECEIPT_EXTENSION_SWISHER,
            );
        } else if (subsidiary === Subsidiary.EAS && retailReceipt.easRetailFormat) {
            return retailReceipt.easRetailFormat.buildRetailCallReceipt(
                receiptId,
                RECEIPT_EXTENSION_EAS,
            );
        } else {
            throw new Error("Invalid subsidiary");
        }
    }
}
