<ng-container *ngIf="currentRoute">
    <div role="main">
        <div class="main-container 2-column">
            <div class="d-flex">
                <div class="worksheet-area">
                    <div class="worksheet-header">
                        <div class="card client-list">
                            <div class="card-header banner-header d-flex flex-column pb-0">
                                <div class="d-flex justify-content-between align-items-center flex-wrap">
                                    <div class="mb-3">
                                        <div class="client-list-toggle">
                                            Route Date: {{currentRoute.date | date: dateWeekFormat}}
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <small>
                                            Total Stores: {{showNearbyStores ? totalRouteStops.length + nearbyStores.length : totalRouteStops.length}}
                                        </small>
                                    </div>
                                    <div class="mb-3">
                                        <ng-container *ngIf="currentRoute.date >= today">
                                            <a (click)="editCurrentRoute()" class="btn btn-sm btn-white"
                                            data-toggle="tooltip" data-placement="bottom" title="Edit Route">
                                                <fa-icon [icon]="faPen"></fa-icon>
                                            </a>
                                            <a (click)="deleteRoute()" class="btn btn-sm btn-white"
                                            data-toggle="tooltip" data-placement="bottom" title="Delete Route">
                                                <fa-icon [icon]="faTrash"></fa-icon>
                                            </a>
                                        </ng-container>
                                        <a href="" class="btn btn-sm btn-white" data-toggle="tooltip"
                                            data-placement="bottom" title="Print">
                                            <fa-icon [icon]="faPrint"></fa-icon>
                                        </a>
                                        <a href="" class="btn btn-sm btn-white" data-toggle="tooltip"
                                            data-placement="bottom" title="Export">
                                            <fa-icon [icon]="faCloudDownloadAlt"></fa-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body smaller-top-padding">
                                <div class="d-flex justify-content-between align-items-center flex-wrap mt-3">
                                    <div class="pr-3 mb-3">
                                        <button class="btn btn-secondary mr-1" (click)="toggleShowNearbyStore()">
                                            <span class="white-space-nowrap">
                                                {{showNearbyStores? "Hide" : "Show"}} Nearby Stores
                                            </span>
                                        </button>
                                    </div>
                                    <div class="pr-3 mb-3">
                                        <button class="btn btn-secondary mr-1"
                                                [disabled]="!isSnapshotAvailable"
                                                [attr.title]="snapshotTooltip"
                                                routerLink="../px3-snapshot">
                                            <span class="white-space-nowrap">
                                                <b>Opportunity Snapshot</b>
                                            </span>
                                        </button>
                                    </div>
                                    <div class="d-flex flex-column justify-content-start pr-3 mb-3">
                                        <div>
                                            ZRT/Name: {{currentRoute.zrt ? currentRoute.zrt + " - " : ""}}
                                            {{currentRoute.employeeName}}
                                        </div>
                                        <div>
                                            Route Name: {{currentRoute.name}}
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column justify-content-start  pr-3 mb-3">
                                        <div>
                                            Stores Routed: {{currentRouteStops.length}}
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column justify-content-start  pr-3 mb-3">
                                        <div>
                                            Routed Stores Completed: {{currentRoute.completedRoutedStops}}
                                        </div>
                                        <div>
                                            Total Stores Completed: {{currentRoute.completedStops}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-4 table-viewport">
                                <table class="table table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                Last Call
                                                <div class="header-underline"></div>
                                            </th>
                                            <th scope="col">
                                                Status
                                                <div class="header-underline"></div>
                                            </th>
                                            <th scope="col">
                                                Avail
                                                <div class="header-underline"></div>
                                            </th>
                                            <th scope="col">
                                                Name
                                                <div class="header-underline"></div>
                                            </th>
                                            <th scope="col">
                                                Address
                                                <div class="header-underline"></div>
                                            </th>
                                            <th scope="col">
                                                City
                                                <div class="header-underline"></div>
                                            </th>
                                            <th scope="col">
                                                County
                                                <div class="header-underline"></div>
                                            </th>
                                            <th scope="col">
                                                State
                                                <div class="header-underline"></div>
                                            </th>
                                            <th scope="col">
                                                Zip
                                                <div class="header-underline"></div>
                                            </th>
                                            <th scope="col">
                                                Type
                                                <div class="header-underline"></div>
                                            </th>
                                            <th scope="col">
                                                Ind Vol
                                                <div class="header-underline"></div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let stop of totalRouteStops">
                                            <td [ngClass]="{
                                                routed: stop.inRoute
                                            }" class="text-align-center">
                                                {{stop.lastCall ? stop.lastCall : '-'}}
                                            </td>
                                            <td class="text-align-center">
                                                {{stop.isCompleted ? 'Completed' : ''}}

                                            </td>
                                            <td class="text-align-center">
                                                {{stop.avail ? stop.avail : '-'}}
                                            </td>
                                            <td [ngClass]="{
                                                px3Rank: stop.px3Rank
                                            }" class="text-align-center">
                                                <a (click)="navigateTo(stop.customerId)">{{stop.name}}</a>
                                            </td>
                                            <td class="text-align-center">
                                                {{stop.address}}
                                            </td>
                                            <td class="text-align-center">
                                                {{stop.city}}
                                            </td>
                                            <td class="text-align-center">
                                                {{stop.county}}
                                            </td>
                                            <td class="text-align-center">
                                                {{stop.state}}
                                            </td>
                                            <td class="text-align-center">
                                                {{stop.zip}}
                                            </td>
                                            <td class="text-align-center">
                                                {{stop.customerType?.name}}
                                            </td>
                                            <td class="text-align-center">
                                                {{stop.indVolume | number}}
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="showNearbyStores">
                                            <tr *ngFor="let stop of nearbyStores">
                                                <td [ngClass]="{
                                                    routed: stop.inRoute
                                                }" class="text-align-center">
                                                    {{stop.lastCall ? stop.lastCall : '-'}}
                                                </td>
                                                <td class="text-align-center">
                                                    {{stop.isCompleted ? 'Completed' : ''}}

                                                </td>
                                                <td class="text-align-center">
                                                    {{stop.avail ? stop.avail : '-'}}
                                                </td>
                                                <td [ngClass]="{
                                                    px3Rank: stop.px3Rank
                                                }" class="text-align-center">
                                                    <a (click)="navigateTo(stop.customerId)">{{stop.name}}</a>
                                                </td>
                                                <td class="text-align-center">
                                                    {{stop.address}}
                                                </td>
                                                <td class="text-align-center">
                                                    {{stop.city}}
                                                </td>
                                                <td class="text-align-center">
                                                    {{stop.county}}
                                                </td>
                                                <td class="text-align-center">
                                                    {{stop.state}}
                                                </td>
                                                <td class="text-align-center">
                                                    {{stop.zip}}
                                                </td>
                                                <td class="text-align-center">
                                                    {{stop.customerType?.name}}
                                                </td>
                                                <td class="text-align-center">
                                                    {{stop.indVolume | number}}
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
