<ng-container *ngIf="viewmodel">
<ng-container>
    <div class="row">
        <div class="col-12">
            <app-page-header #header [name]="viewmodel.headerName"
                [entryCount]="viewmodel.total ? viewmodel.total : 0" [buttons]="viewmodel.headerButtons"
                [refinerService]="viewmodel.refinerService" [subAvailableColumns]="viewmodel.availableColumns"
                (onClickButton)="viewmodel.onButtonClickEvent($event)"
                (onButtonAction)="viewmodel.onButtonAction($event)"
                (headerExpansionChanged)="viewmodel.headerExpansionChanged()">
            </app-page-header>
        </div>
    </div>
    <div class="d-flex p-2 justify-content-center align-items-center">
        <div>
            <button class="btn btn-secondary mr-2" data-toggle="tooltip" data-placement="bottom" title="View Filters"
                (click)="viewmodel.showFilters()">
                <span class="white-space-nowrap">
                    <span class="material-icons">filter_alt</span>
                    Filters •
                    {{ viewmodel.refinerService.refiners.length }}
                </span>
            </button>
        </div>
        <div class="flex-grow-1">
            <mat-form-field appearance="outline" class="flex-grow-1 mat-input-search" color="accent">
                <mat-label>Wholesaler Group ID / Name</mat-label>
                <input
                    type="text"
                    (keyup)="viewmodel.onAddGroupRefiner($event)"
                    (blur)="viewmodel.onAddGroupRefiner()"
                    [(ngModel)]="viewmodel.groupInput"
                    matInput
                />
            </mat-form-field>
        </div>
        <div class="flex-grow-1">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Order Method</mat-label>
                <mat-select #methodSelect [(ngModel)]="viewmodel.methodInput" (openedChange)="viewmodel.onOpenedMethodChange()" multiple>
                    <mat-option *ngFor="let option of viewmodel.methodOptions" [value]="option">
                        {{ option }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="d-flex">
            <button appThrottleClick mat-raised-button (throttledClick)="viewmodel.search()" [disabled]="viewmodel.isSearchButtonDisabled" class="btn btn-primary mr-2">SEARCH</button>
            <button mat-raised-button (click)="viewmodel.reset()" class="btn btn-secondary">RESET</button>
        </div>
    </div>
</ng-container>
<mat-sidenav-container class="sidenav-container d-flex flex-column flex-grow-1" autosize>
    <mat-sidenav #drawer [mode]="viewmodel.drawerMode" [fixedInViewport]="true"
        [fixedTopGap]="viewmodel.topGapDistance" [fixedBottomGap]="0" class="sidenav">
        <app-filter-container [refinerService]="viewmodel.refinerService" [filters]="viewmodel.filters"
            [isSearchDisabled]="viewmodel.isSearchButtonDisabled" [filterLocation]="viewmodel.filterLocation"
            [expandPanelsOnInit]="viewmodel.expandPanelsOnInit" (search)="viewmodel.search()" (reset)="viewmodel.reset()">
        </app-filter-container>
    </mat-sidenav>
    <mat-sidenav-content #sideNavContent class="d-flex flex-column flex-grow-1">
        <div class="sidenav-content d-flex flex-column flex-grow-1">
            <ng-container>
                <div class="table-responsive d-flex flex-column flex-grow-1">
                    <app-grid #grid [(dataSource)]="viewmodel.dataSource" [columnDefinitions]="viewmodel.columnDef"
                        [detailTemplate]="detailTemplate" [searchRefiners]="viewmodel.activeRefiners"
                        [isFixedLayout]="viewmodel.isFixedLayout" [height]="viewmodel.gridHeight"
                        [isEnabledExpandedDetails]="viewmodel.isEnabledExpandedDetails"
                        [itemsRendedInViewPort]="viewmodel.itemsRendedInViewPort"
                        [detailHeight]="viewmodel.detailHeight" [sortFunction]="viewmodel.sortFunction"
                        (getDataBatch)="viewmodel.getBatch($event)"
                        class="d-flex flex-column flex-grow-1">
                    </app-grid>
                </div>
            </ng-container>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #detailTemplate let-data="data">
    <table *ngIf="data.groupMembers.length > 0" class="table-sm table-borderless">
        <thead class="detail-table-head">
            <tr>
                <th class="detail-table-col h-center"><u>Name</u></th>
                <th class="detail-table-col h-center"><u>Number</u></th>
                <th class="detail-table-col h-center"><u>Address</u></th>
                <th class="detail-table-col h-center"><u>City</u></th>
                <th class="detail-table-col h-center"><u>State</u></th>
                <th class="detail-table-col h-center"><u>Zip</u></th>
                <th class="detail-table-col h-center"><u># of Products</u></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of data.groupMembers">
                <td class="detail-table-col h-left">{{row.name}}</td>
                <td class="detail-table-col h-left">{{row.customerNumber}}</td>
                <td class="detail-table-col h-left">{{row.address}}</td>
                <td class="detail-table-col h-left">{{row.city}}</td>
                <td class="detail-table-col h-left">{{row.state}}</td>
                <td class="detail-table-col h-left">{{row.zip}}</td>
                <td class="detail-table-col h-right" appThrottleClick (throttledClick)="viewmodel.onOpenLineItemProductList(row.id, row.name, data.groupId, row.wholesalerId)">
                    <a class="edit-column">
                        {{row.numberOfProducts}}
                        <icon-marker iconName="{{viewmodel?.isAdmin ? 'edit' : 'visibility'}}"></icon-marker>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <ng-container *ngIf="data.groupMembers.length == 0" class="text-center"><em>No wholesaler group members found.</em></ng-container>
</ng-template>
</ng-container>
