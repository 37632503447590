import { Injectable } from "@angular/core";
import { FilterRequestV2Dto, FilterSortDto, GenericResponseDto, RouteBatchParamsDto, RouteListColumns } from "shield.shared";
import { Employee } from "src/app/entity-models/employee.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { Route } from "src/app/entity-models/route.entity";
import { RouteOfflineService } from "../offline-services/route-offline.service";
import { RouteOnlineService } from "../online-services/route-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { RouteListFilterMapService } from "../filter-map-services/route-list-filter-map.service";
import { DatabaseService } from "../database.service";
import { DelineationContext } from "./delineation-context.service";
import { Call } from "src/app/accounts/call-master/call-services/call.service";

@Injectable()
export class RouteDelineationService extends DelineationContext<Route, string> {

    constructor(
        private routeOfflineService: RouteOfflineService
        , private routeOnlineService: RouteOnlineService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService
        , snackbarService: SnackbarService) {
            super(dbService, datasourceDelineationService, snackbarService);
         }

    async getById(id: string): Promise<GenericResponseDto<Route> | undefined> {
        const offline = (key: string) => {
            return this.routeOfflineService.getById(key);
        }
        const online = (key: string) => {
            return this.routeOnlineService.getById(key);
        }
        const compareDelegate = (onlineRoute: Route, notSyncedOfflineRoute: Route) => this.compareRoute(onlineRoute, notSyncedOfflineRoute);
        const unProcessedDelegate = async (key: string, hasOfflineAccess: boolean) => this.getUnprocessedRoutesById(key, hasOfflineAccess);
        const response = await this.datasourceDelineationService.makeCall<string, Route>(id, offline, online, compareDelegate, unProcessedDelegate);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async updateRouteByCall(call: Call): Promise<GenericResponseDto<void> | undefined> {
        const offline = (key: Call) => {
            return this.routeOfflineService.updateRouteByCall(key);
        }

        const online = (key: Call) => {
            return this.routeOfflineService.updateRouteByCall(key);
        }

        const response = await this.datasourceDelineationService.makeCall<Call,void>(call,offline,online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getFutureByEmployee(employee: Employee): Promise<GenericResponseDto<Route[]> | undefined> {
        const offline = (key: string) => {
            return this.routeOfflineService.getFutureByEmployeeId(key);
        }
        const online = (key: string) => {
            return this.routeOnlineService.getFutureByEmployeeId(key);
        }
        const compareDelegate = this.compareRouteArray;
        const unProcessedDelegate = (key: string, hasOfflineAccess: boolean) => this.getUnprocessedFutureByEmployee(key, hasOfflineAccess);
        const response = await this.datasourceDelineationService.makeCall<string, Route[]>(employee.id, offline, online, compareDelegate, unProcessedDelegate);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async editRoute(route: Route): Promise<undefined> {
        const offline = (key: Route) => {
            return this.routeOfflineService.editRoute();
        }
        const online = (key: Route) => {
            return this.routeOnlineService.editRoute(key);
        }
        await this.datasourceDelineationService.makeCall<Route, undefined>(route, offline, online);
        return;
    }

    async getBatch(id: string
        , zrt: string
        , refiners: Refiner[]
        , pageSize: number
        , startIndex: number
        , filterSorts: FilterSortDto<RouteListColumns>[]
    ): Promise<GenericResponseDto<Route[]> | undefined> {
        const key = new RouteBatchParamsDto();
        key.filterRequestDto = new FilterRequestV2Dto();
        key.filterRequestDto.id = id;
        key.filterRequestDto.filters = RouteListFilterMapService.mapFilterData(refiners);
        key.filterRequestDto.pageSize = pageSize;
        key.filterRequestDto.startIndex = startIndex;
        key.filterRequestDto.filterSorts = filterSorts;
        key.employeeId = id;
        key.employeeZrt = zrt;

        const offline = (key: RouteBatchParamsDto) => {
            return this.routeOfflineService.getBatch(key);
        }
        const online = (key: RouteBatchParamsDto) => {
            return this.routeOnlineService.getBatch(key);
        }
        const compareDelegate = this.compareRouteArray;
        const unProcessedDelegate = (key: RouteBatchParamsDto, hasOfflineAccess: boolean) => this.getUnprocessedRoutesByBatch(key, hasOfflineAccess);
        const response = await this.datasourceDelineationService.makeCall<RouteBatchParamsDto, Route[]>(key, offline, online, compareDelegate, unProcessedDelegate);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async deleteRoute(routeId: string): Promise<GenericResponseDto<undefined>> {
        const offline = (key: string) => {
            return this.routeOfflineService.delete(key);
        }
        const online = (key: string) => {
            return this.routeOnlineService.delete(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, undefined>(routeId, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async offlineOnlineDeleteRoute(routeId: string): Promise<GenericResponseDto<undefined>> {
        const deleteResponse = await this.deleteRoute(routeId);
        await this.dbService.routes.delete(routeId);
        return deleteResponse;
    }

    async saveRoute(route: Route): Promise<GenericResponseDto<Route | undefined>> {
        const offline = (key:  Route) => {
            return this.routeOfflineService.saveRoute(key);
        }
        const online = (key:  Route) => {
            return this.routeOnlineService.saveRoute(key);
        }
        const response = await this.datasourceDelineationService.makeCall<Route, Route>(route, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    private async getUnprocessedRoutesById(key: string, hasOfflineAccess: boolean): Promise<Route> {
        const unprocessed = await this.routeOfflineService.getUnprocessedById(key);

        void this.cleanUpRoutes(hasOfflineAccess);
        return unprocessed;
    }

    private async getUnprocessedFutureByEmployee(key: string, hasOfflineAccess: boolean): Promise<Route[]> {
        const unprocessed = await this.routeOfflineService.getUnprocessedFutureByEmployeeId(key);

        void this.cleanUpRoutes(hasOfflineAccess);
        return unprocessed;
    }

    private async getUnprocessedRoutesByBatch(key: RouteBatchParamsDto, hasOfflineAccess: boolean): Promise<Route[]> {
        const unprocessed = await this.routeOfflineService.getUnprocessedBatch(key);

        void this.cleanUpRoutes(hasOfflineAccess);
        return unprocessed;
    }

    private async cleanUpRoutes(hasOfflineAccess: boolean): Promise<void> {
        if (hasOfflineAccess) return;

        const processed = await this.dbService.routes.where("hasServerProcessed").equals(1).toArray();

        if (processed.length) {
            this.dbService.routes.bulkDelete(processed.map(v => v.id));
        }
    }

    private async compareRoute(
        onlineRoute: Route,
        notSyncedOfflineRoute: Route
    ) : Promise<Route> {
        if (!onlineRoute) {
            return notSyncedOfflineRoute;
        }
        const results = await this.compareRouteArray(!!onlineRoute ? [onlineRoute] : null
            , !!notSyncedOfflineRoute ? [notSyncedOfflineRoute] : null);
        return results[0];
    }

    private async compareRouteArray(
        onlineRoutes: Route[],
        notSyncedOfflineRoutes: Route[]
    ): Promise<Route[]> {
        const rtn = new Array<Route>();
        const offlineRouteMaps = new Map(notSyncedOfflineRoutes?.map((entry) => [entry.id, entry]));

        for (const onlineRoute of (onlineRoutes ?? [])) {
            const offlineRoute = offlineRouteMaps?.get(onlineRoute.id);
            if (offlineRoute) {
                offlineRouteMaps.delete(offlineRoute.id);
                if (
                    (onlineRoute.modifiedUtcDateTime?.getTime() ?? onlineRoute.createdUtcDateTime?.getTime()) >=
                    (offlineRoute.modifiedUtcDateTime?.getTime() ?? offlineRoute.createdUtcDateTime?.getTime())
                ) {
                    rtn.push(onlineRoute);
                } else {
                    rtn.push(offlineRoute);
                }
            } else {
                rtn.push(onlineRoute);
            }
        }

        // What is remaining in the offlineRouteMaps are entries that don't exist in the online version
        // Unprocessed entries will appear at the top
        for (const offlineRouteMap of offlineRouteMaps.entries()) {
            rtn.push(offlineRouteMap[1]);
        }
        return rtn;
    }
}
