<div
            style="display: flex; flex-direction: column;"
            [attr.class]="chartWidth"
        >
            <h2
                class="text-teal border-bottom border-teal"
                style="margin-left: 3rem;"
            >
                {{ chartTitle }}
            </h2>
            <div *ngIf="!hasData" class="no-data-tashare">No Data</div>
            <svg *ngIf="hasData" [attr.viewBox]="viewbox">
                <g *ngFor="let series of positionedData" [attr.x]="0">
                    <!-- Share  -->
                    <g [attr.transform]="gTransform">
                        <rect
                            [attr.x]="series.shareX"
                            y="20"
                            width="20"
                            [attr.height]="maxBar"
                            style="fill:#bababa"
                        />
                        <rect
                            [attr.x]="series.shareX"
                            y="20"
                            width="20"
                            [attr.height]="series.share * heightFactor"
                            class="share-bar"
                        >
                            <animate
                                attributeName="height"
                                from="0"
                                [attr.to]="series.share * heightFactor"
                                dur="0.5s"
                                fill="freeze"
                            />
                        </rect>
                    </g>
                    <text
                        [attr.x]="series.shareValueLabelX"
                        [attr.y]="maxBar - 5"
                        style="fill:#FFFFFF"
                        font-size="6"
                        font-weight="bold"
                    >
                        {{ series.share }}%
                    </text>
                    <text
                        [attr.x]="series.shareTextX - 3"
                        [attr.y]="maxBar + 10"
                        style="fill:#000000"
                        font-size="6"
                        font-weight="bold"
                    >
                        Share
                    </text>
                    <!-- TA Share  -->

                    <g [attr.transform]="gTransform">
                        <rect
                            [attr.x]="series.taShareX"
                            y="20"
                            width="20"
                            [attr.height]="maxBar"
                            style="fill:#bababa"
                        />
                        <rect
                            [attr.x]="series.taShareX"
                            y="20"
                            width="20"
                            [attr.height]="series.taShare * heightFactor"
                            class="share-bar"
                        >
                            <animate
                                attributeName="height"
                                from="0"
                                [attr.to]="series.taShare * heightFactor"
                                dur="0.5s"
                                fill="freeze"
                            />
                        </rect>
                    </g>
                    <text
                        [attr.x]="series.taShareValueLabelX"
                        [attr.y]="maxBar - 5"
                        style="fill:#FFFFFF"
                        font-size="6"
                        font-weight="bold"
                    >
                        {{ series.taShare }}%
                    </text>
                    <text
                        [attr.x]="series.taShareTextX - 8"
                        [attr.y]="maxBar + 10"
                        style="fill:#000000"
                        font-size="6"
                        font-weight="bold"
                    >
                        TA Share
                    </text>
                </g>
            </svg>
            <div style="display: flex; flex-direction: row;">
                <div *ngFor="let series of data" class="legendContainer">
                    <div class="legendItem">
                        <span
                            class="legendColor"
                            style="background-color: #232323"
                        ></span>
                        {{ series.swisherLabel }}
                    </div>
                    <div class="legendItem">
                        <span
                            class="legendColor"
                            style="background-color: #bababa"
                        ></span>
                        {{ series.genericLabel }}
                    </div>
                </div>
            </div>
        </div>