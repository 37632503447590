<div *ngIf="!filters?.length">
    "No Filters Configured"
</div>
<div *ngIf="filters?.length > 0">
    <div class="d-flex justify-content-center w-100 p-3">
        <button mat-raised-button class="w-50 mr-1 btn btn-secondary"
            (click)="onReset()">RESET</button>
        <button appThrottleClick mat-raised-button class="w-50 ml-1 btn btn-primary"
            (throttledClick)="onSearch()"
            [disabled]="isSearchDisabled">SEARCH</button>
    </div>
    <mat-accordion>
        <mat-expansion-panel *ngFor="let filter of filters; index as i"
            [expanded]="i === 0 ? expandPanelsOnInit : false"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>{{this.componentRefs[i]?.instance?.icon}}</mat-icon>
                    {{this.componentRefs[i]?.instance?.name}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="m-0 p-0 mb-4" />
            <ng-container #containers>
            </ng-container>
        </mat-expansion-panel>
    </mat-accordion>
</div>
