import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Contact } from "src/app/entity-models/contact.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { Helper } from "src/app/helpers/helper";
import { CustomerConverterService } from "src/app/services/converter-services/customer-converter.service";
import { ContactDelineationService } from "src/app/services/delineation-services/contact-delineation.service";
@Injectable()
export class CustomerStateService {
    constructor(private contactDelineationService: ContactDelineationService) {}
    private _customer: Customer = null;
    private _customerSubject: BehaviorSubject<Customer> = new BehaviorSubject(
        this._customer
    );

    ignoreCustomerSubscription = false;

    get customer(): Customer {
        return this._customer;
    }
    set customer(value: Customer) {
        this._customer = value;
        this._customerSubject.next(value);
    }
    observableCustomer: Observable<Customer> = this._customerSubject.asObservable();

    async updateCustomerByContact(): Promise<void> {
        if (!this.customer) return;
        
        const initialState = !!this.customer.phone || this.customer.hasPhone;

        let contacts: Contact[] = [];

        if (!this.customer.phone) {
            const response = await this.contactDelineationService.getByCustomerId(
                this.customer.id
            );

            if (!response) { return; }

            contacts = response.values;
        }

        this.customer.hasPhone =
            !!this.customer.phone ||
            !!contacts.find((c) => !!c.phone || c.alternatePhone);

        this.customer.availability = CustomerConverterService.getCustomerAvailability(contacts);

        return;
    }

    isTaxAvailable(): boolean {
        const state = this.customer.businessAddress.state;
        return state && (Helper.equalsIgnoreCase(state, "AL") || Helper.equalsIgnoreCase(state, "KY"))
    }
}
