import { ContractTemplateDto } from "./contract-template.dto";
import { CustomerContractPaymentDto } from "./customer-contract-payment.dto";

export class CustomerContractDto {
    id: string;
    customerId: string;
    number: string;
    level: number;
    isNew: boolean;
    signatureDate: string;
    endDate: string;
    customerNumber: string | null;
    customerName: string | null;
    retailIdentificationNumber: string;
    address1: string;
    address2: string | null;
    city: string;
    state: string;
    zipCode: string;
    px3RankId?: string | null;
    lastPaymentStatusId: number;
    totalPayment: number;
    customerContactId: string;
    customerContactName: string | null;
    createdUtcDateTime: string;
    createdUserId: string;
    createdUserZrt: string | null;
    createdUserFullName: string;
    modifiedUtcDateTime: string;
    modifiedUserId: string;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string;
    customerContractPayments: CustomerContractPaymentDto[];
    contractTemplate: ContractTemplateDto;
    signatureBlob: string;
}
