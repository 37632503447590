import { OverlayRef } from "@angular/cdk/overlay";
import { TemplateRef, Type } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { OverlayCloseEvent } from "./overlay-close-event";
import { BasicDialogViewModel } from "../dialogs/basic-dialog/basic-dialog.viewmodel";

export class SwisherOverlayRef<R extends unknown, T> {
    afterClosed$ = new Subject<OverlayCloseEvent<R>>();
    private _blocking: boolean = false;
    blockingSubject = new BehaviorSubject(
        this._blocking
    );
    observableBlocking: Observable<boolean> = this.blockingSubject.asObservable();
    get blocking(): boolean {
        return this._blocking;
    }
    set blocking(value: boolean) {
        this._blocking = value;
        this.blockingSubject.next(this._blocking);
    }

    constructor(
        public overlay: OverlayRef,
        public content: string | TemplateRef<T> | Type<T>,
        public data: R,
    ) {
        overlay.backdropClick().subscribe(() => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (!this.blocking) {
                this._close("backdropClick", null);
            }
        });
    }

    close(closeData?: R, force?: boolean): void {
        this._close("close", closeData, force);
    }

    private _close(type: "backdropClick" | "close", closeData: R, force?: boolean) {
        if (force || !(this.data instanceof BasicDialogViewModel) || this.data.shouldClose()) {
            this.overlay.dispose();
            this.afterClosed$.next({
                type,
                data: closeData
            });

            this.afterClosed$.complete();
        }
    }
}
