import { Injectable } from "@angular/core";
import { RefinerLocation, valueSeparator, ZoneRecapFilterDto } from "shield.shared";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { ZrtFilterMapService } from "./zrt-filter-map.service";

@Injectable()
export class ZoneRecapFilterMapService {
    static mapFilterData(refiners: Refiner[]): ZoneRecapFilterDto {

        if (!refiners) return;
        const filterDto = new ZoneRecapFilterDto();

        for (const refiner of refiners) {
            if (!refiner.dataValue) {
                refiner.dataValue = refiner.value;
            }

            switch (refiner.location) {
                case RefinerLocation.startDate:
                    filterDto.callOnOrAfter = refiner.dataValue;
                    break;
                case RefinerLocation.endDate:
                    filterDto.callOnOrBefore = refiner.dataValue;
                    break;
                case RefinerLocation.zrtByEmployee:
                    filterDto.zrts = refiner.dataValue.split(valueSeparator).filter(v => !!v);
                    break;
                default:
                    break;
            }
        }
        return filterDto;
    }
}
