import { ProductUpc } from "./product-upc.entity";

export class Product {
    id: string;
    itemNumber: string;
    description: string;
    longDescription: string;
    subsidiary: string;
    brand: string;
    division: string;
    shapeCut: string;
    blend: string;
    sellable: boolean;
    returnable: boolean;
    webOrderable: boolean;
    comboProduct: boolean;
    offerSupport: boolean;
    productCatalog: boolean;
    shield: boolean;
    isDeactivated: boolean;
    isDeleted: boolean;
    rowversion: string;
    //Must be a number to be indexable
    isDropDownSelectable: number;
    upcs: ProductUpc[];
    // these are flattened for read-optimization / indexing
    upcNumbers: string[];
    returnableUpc: ProductUpc;
    lowestSellableUpc: ProductUpc;
}
