import { ResponseCountKey } from "../enum";
import { ResponseCount } from "../response-count.dto";

export class GenericResponseDto<T> {
    id: string;
    count: number;
    values: T;
    isError: boolean;
    message: string;
    affected?: number;
    counts: ResponseCount[];

    constructor() {
        this.id = "";
        this.count = 0;
        this.values = null;
        this.isError = false;
        this.message = "";
        this.affected = undefined;
        this.counts = [(new ResponseCount())];
    }

    getCount (
        key?: ResponseCountKey
    ): number | undefined {
        const index = this.counts.findIndex((v) => key ? v.key === key : v.key === ResponseCountKey.default);
        if (index >= 0) {
            return this.counts[index].count;
        }
        else {
            return undefined;
        }
    }

    setCount (
        key: ResponseCountKey,
        count: number | undefined
    ): void {
        const index = this.counts.findIndex((v) => v.key === key);
        if (index >= 0) {
            this.counts[index].count = count;
        }
        else {
            const rck = new ResponseCount();
            rck.key = key;
            rck.count = count;
            this.counts.push(rck);
        }
    }
}
