<ng-container *ngIf="viewmodel" class="d-flex flex-column flex-grow-1">
    <div class="row">
        <div class="col-12">
            <app-page-header #header [name]="viewmodel?.headerName"
                [entryCount]="dataViewmodel.total ? dataViewmodel.total : 0" [buttons]="viewmodel.headerButtons"
                [refinerService]="viewmodel.refinerService" [subAvailableColumns]="viewmodel.availableColumns"
                (onClickButton)="viewmodel.onButtonClickEvent($event)"
                (onButtonAction)="viewmodel.onButtonAction($event)"
                (headerExpansionChanged)="viewmodel.headerExpansionChanged()"
                (onColumnVisabilityMenuCloseEvent)="viewmodel.setColumnVisibilityCommunicator($event)">
            </app-page-header>
        </div>
    </div>
    <div class="d-flex p-2 justify-content-center align-items-center border-bottom">
        <div>
            <button class="btn btn-secondary mr-2" data-toggle="tooltip" data-placement="bottom" title="View Filters"
                (click)="viewmodel.showFilters()">
                <span class="white-space-nowrap">
                    <span class="material-icons">filter_alt</span>
                    Filters •
                    {{ viewmodel.refinerService?.refiners.length }}
                </span>
            </button>
            <button *ngIf="!viewmodel.routingMode" class="btn btn-secondary mr-2"
                (click)="viewmodel.toggleMap()">
                <span class="white-space-nowrap">
                    <span class="material-icons mr-1">map</span>
                    {{ viewmodel.showMap == true ? "Hide Map" : "Show Map" }}
                </span>
            </button>
        </div>
        <div class="flex-grow-1">
            <mat-form-field appearance="outline" class="flex-grow-1 mat-input-search" color="accent">
                <mat-label>Store Name / Customer ID</mat-label>
                <input
                    type="text"
                    (keyup)="viewmodel.onAddAccountRefiner($event)"
                    [(ngModel)]="viewmodel.accountInput"
                    matInput
                />
            </mat-form-field>
        </div>
        <div class="flex-grow-1">
            <mat-form-field appearance="outline" class="flex-grow-1 mat-input-search" color="accent">
                <mat-label>City</mat-label>
                <input
                    type="text"
                    (keyup)="viewmodel.onAddCityRefiner($event)"
                    [(ngModel)]="viewmodel.cityInput"
                    matInput
                />
            </mat-form-field>
        </div>
        <div class="d-flex">
            <button appThrottleClick mat-raised-button (throttledClick)="viewmodel.search()" [disabled]="viewmodel.isSearchButtonDisabled"
                class="btn btn-primary mr-2">SEARCH</button>
            <button mat-raised-button (click)="viewmodel.reset()" class="btn btn-secondary">RESET</button>
        </div>
    </div>
</ng-container>
<mat-sidenav-container *ngIf="viewmodel" class="sidenav-container d-flex flex-grow-1" autosize>
    <mat-sidenav #drawer [mode]="viewmodel.drawerMode" [fixedInViewport]="true"
        [fixedTopGap]="viewmodel.topGapDistance" [fixedBottomGap]="0" class="sidenav">
        <app-filter-container [refinerService]="viewmodel.refinerService" [filters]="viewmodel.filters"
            [isSearchDisabled]="viewmodel.isSearchButtonDisabled" [filterLocation]="viewmodel.filterLocation"
            (search)="viewmodel.search()" (reset)="viewmodel.reset()" (filtersIntialized)="filtersIntialized()">
        </app-filter-container>
    </mat-sidenav>
    <mat-sidenav-content #sideNavContent class="flex-grow-1 overflow-hidden">
        <div class="sidenav-content">
            <div *ngIf="viewmodel?.showMap" class="map-area mb-2">
                <!-- <mat-spinner class="map-spinner" [diameter]="60" *ngIf="dataViewmodel.mapLoading"></mat-spinner> -->
                <agm-map style="width: 100%; height: 300px" (mapClick)="closeInfoWindow()"
                        [latitude]="viewmodel.mapCenter.lat"
                        [longitude]="viewmodel.mapCenter.lng"
                        [zoom]="viewmodel.mapZoom"
                        [styles]="viewmodel.mapStyles"
                        (boundsChange)="viewmodel.setMapBounds($event)"
                        (zoomChange)="viewmodel.setMapOnZoom()"
                        (tilesLoaded)="viewmodel.setVisibleMapMarkers()">
                    <agm-marker-cluster imagePath="images/m" *ngIf="!dataViewmodel.mapRebuilding"
                                        [maxZoom]="viewmodel.mapMarkerClusterOptions.maxZoom">
                        <agm-marker *ngFor="let marker of dataViewmodel.customerMarkers"
                                    [visible]="dataViewmodel.customerMarkersVisibleIds.includes(marker.customerId)"
                                    [latitude]="marker.address.latitude" [longitude]="marker.address.longitude"
                                    [title]="marker.title"
                                    [iconUrl]="marker.icon"
                                    (markerClick)="openInfoWindow(infoWindow, $event, marker)">
                        </agm-marker>
                        <agm-info-window #infoWindow>
                            <div *ngIf="viewmodel.infoMarker">
                                <div class="info-window-header">
                                    <ng-container *ngIf="viewmodel.infoMarker.icon">
                                        <img [src]="viewmodel.infoMarker.icon.url"
                                             height="20" width="20"/>
                                    </ng-container>
                                    <strong>{{viewmodel.infoMarker.title}}</strong>
                                    <ng-container
                                        *ngIf="viewmodel.infoMarker.customerNumber">
                                        ({{viewmodel.infoMarker.customerNumber}})
                                    </ng-container>
                                </div>
                                <div class="info-window-row"><strong>
                                    {{viewmodel.infoMarker.addressText}},
                                    {{viewmodel.infoMarker.cityStateZip}}
                                </strong></div>
                                <div class="info-window-row"><strong>Store Type: </strong>
                                    {{viewmodel.infoMarker?.customerType?.name}}</div>
                                <div class="info-window-row"><strong>Availability: </strong>
                                    {{viewmodel.infoMarker.availability}}</div>
                                <div class="info-window-row"><strong>Px3 Rank: </strong>
                                    {{viewmodel.infoMarker.px3Rank}}</div>
                                <div class="info-window-row"><strong>Active: </strong>
                                    {{viewmodel.infoMarker.active}}</div>
                                <div class="info-window-row"><strong>Last Call: </strong>
                                    {{viewmodel.infoMarker.lastCall}}</div>
                            </div>
                        </agm-info-window>
                    </agm-marker-cluster>
                </agm-map>
            </div>
            <ng-container>
                <div class="row flex-column flex-grow-1">
                    <div class="col-12 d-flex flex-column flex-basis-0">
                        <app-accounts-list-routing-mode
                            [accountsVm]="viewmodel"
                            [futureRoutes]="dataViewmodel.futureRoutes"
                            [routingMode]="viewmodel.routingMode"
                            (routingModeTouched)="viewmodel.onRouteTouch(false)"
                            (routingModeUntouched)="viewmodel.onRouteTouch(true)"
                            (routingModeToggled)="viewmodel.onToggleRoutingMode(false)"
                            (mapViewToggled)="viewmodel.routingModeMapView = $event">
                        </app-accounts-list-routing-mode>
                    </div>
                    <div [ngClass]="viewmodel.routingModeMapView ? 'hidden' : 'visible'"
                         class="col-12 table-responsive d-flex flex-grow-1 flex-basis-0">
                        <app-grid #grid [(dataSource)]="dataViewmodel.dataSource"
                                  [columnDefinitions]="viewmodel.columnDef"
                                  [searchRefiners]="dataViewmodel.activeRefiners"
                                  [searchRefinersGreaterThans]="dataViewmodel.refinerGreaterThans"
                                  [searchRefinersLessThans]="dataViewmodel.refinerLessThans"
                                  [searchRefinersMultiselects]="dataViewmodel.refinerMultiselects"
                                  [selectedColumnVisability]="viewmodel.selectedColumnVisibilityComunicator"
                                  [isFixedLayout]="viewmodel.isFixedLayout" [height]="viewmodel.gridHeight"
                                  [isEnabledExpandedDetails]="viewmodel.isEnabledExpandedDetails"
                                  [itemsRendedInViewPort]="viewmodel.itemsRendedInViewPort"
                                  [detailHeight]="viewmodel.detailHeight"
                                  [sortFunction]="viewmodel.sortFunction"
                                  (rowSelectionChange)="viewmodel.setCustomerOnRoute($event)"
                                  [total]="dataViewmodel.total"
                                  (getDataBatch)="viewmodel.onGetBatch($event, viewmodel.refinerService.refiners)"
                                  class="d-flex flex-grow-1">
                        </app-grid>
                    </div>
                </div>
            </ng-container>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
