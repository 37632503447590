import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    IconDefinition,
    faPen,
    faPrint,
    faCloudDownloadAlt,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";
import { formatDate } from "@angular/common";
import { BehaviorSubject, Subscription } from "rxjs";
import { Address } from "src/app/entity-models/address.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { RouteStop } from "src/app/entity-models/route-stop.entity";
import { Route } from "src/app/entity-models/route.entity";
import { DatabaseService } from "src/app/services/database.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { RouteManagementService } from "../route-management.service";
import { RouteStopViewmodel } from "../routeStopViewmodel";
import { RouteViewmodel } from "../routeViewmodel";
import { RouteDelineationService } from "src/app/services/delineation-services/route-delineation.service";
import { Employee } from "src/app/entity-models/employee.entity";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { Helper } from "src/app/helpers/helper";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { CallHistoryEntry } from "src/app/entity-models/call-history-entry.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { newSequentialId, RefinerLocation } from "shield.shared";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";
import { DialogService } from "src/app/services/dialog.service";
import { filter, switchMap } from "rxjs/operators";

@Component({
    selector: "app-route-details",
    templateUrl: "./route-details.component.html",
    styleUrls: ["./route-details.component.scss"],
    providers: [PleaseWaitService]
})
export class RouteDetailsComponent implements OnInit, OnDestroy {
    faPen: IconDefinition = faPen;
    faPrint: IconDefinition = faPrint;
    faCloudDownloadAlt: IconDefinition = faCloudDownloadAlt;
    faTrash = faTrash;

    urlSubscription: Subscription;
    shouldWait$ = new BehaviorSubject<boolean>(true);

    today = new Date();

    currentRoute: RouteViewmodel;
    currentRouteStops = new Array<RouteStopViewmodel>();
    totalRouteStops = new Array<RouteStopViewmodel>();
    nearbyStores = new Array<RouteStopViewmodel>();
    routeId: string;
    routeEmployee: Employee;
    showNearbyStores = false;
    dateFormat = MY_DATE_FORMATS.display.customDateInput;
    dateWeekFormat = MY_DATE_FORMATS.display.customWeekDateInput;
    routeCalls: CallHistoryEntry[];

    shouldEditCurrentRoute = false;

    constructor(
        private pleaseWaitService: PleaseWaitService,
        private customerDelineationService: CustomerDelineationService,
        private dbService: DatabaseService,
        private employeeDelineationService: EmployeeDelineationService,
        private routeService: RouteManagementService,
        private routeDelineationService: RouteDelineationService,
        private callDelineationService: CallDelineationService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dialogService: DialogService,
    ) { }

    ngOnInit(): void {
        this.routeId = null;
        this.shouldEditCurrentRoute = false;
        this.today.setHours(0, 0, 0, 0);

        if (
            !this.urlSubscription ||
            this.urlSubscription.closed
        ) {
            this.urlSubscription = this.activatedRoute.url.subscribe(
                (url) => {
                    this.routeId = url[2].path as string;
                    this.updateRouteOnRedirect();
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (
            this.urlSubscription &&
            !this.urlSubscription.closed
        ) {
            this.urlSubscription.unsubscribe();
        }

        if (!this.shouldEditCurrentRoute &&
            this.routeService.observableRoute
        ) {
            this.routeService.route = null;
        }
    }

    get isCurrentOrFutureRoute(): boolean {
        return this.currentRoute?.date >= this.today;
    }

    get isUserOnline(): boolean {
        return this.employeeDelineationService.getOnlineState();
    }

    get isSnapshotAvailable(): boolean {
        return this.isCurrentOrFutureRoute && this.isUserOnline;
    }

    get snapshotTooltip(): string {
        if (!this.isCurrentOrFutureRoute) {
            return "Opportunity Snapshot is only available for current or future date routes";
        }

        if (!this.isUserOnline) {
            return "Opportunity Snapshot is only available when you are online";
        }

        return null;
    }

    formatAddress(address: Address): string {
        let cityStateZip;
        let addressText;
        if (address?.city && address?.state && address?.zip) {
            cityStateZip = `${address.city}, ${address.state} ${address.zip}`;
        }
        if (address?.address1) {
            addressText = `${address.address1} ${address.address2 ?? ""}`.trim();
        }
        if (cityStateZip && addressText) return `${addressText}, ${cityStateZip}`;
        if (cityStateZip) return `${cityStateZip}`;
        if (addressText) return `${addressText}`;
    }

    async buildRouteViewmodels(route: Route, routeCustomers: Customer[]): Promise<void> {

        this.currentRoute = await this.routeToRouteVm(route);

        route.stops.sort((a, b) => a.sequence - b.sequence);

        this.currentRouteStops = route.stops
            .filter((stop) => stop.customerId)
            .map((stop) =>
                this.routeStopToRouteStopVm(
                    routeCustomers.find((c) => c.id === stop.customerId),
                    stop
                )
            );
        this.totalRouteStops = this.currentRouteStops.slice();

        const foundCustomerIds = new Array<string>();
        for (const call of this.routeCalls) {
            if (!foundCustomerIds.includes(call.customerId)) {
                foundCustomerIds.push(call.customerId);
                const stop = this.currentRouteStops.find((s) => s.customerId === call.customerId);
                if (stop) {
                    this.currentRoute.completedStops++;
                    this.currentRoute.completedRoutedStops++;
                } else {
                    const nonRouteCustomerResponse = await this.customerDelineationService.getById(call.customerId);
                    if (!nonRouteCustomerResponse) return;

                    let nonRouteStop = this.routeStopToRouteStopVm(nonRouteCustomerResponse.values);
                    nonRouteStop.isCompleted = true;
                    this.totalRouteStops.push(nonRouteStop);
                    this.currentRoute.completedStops++;
                }
            }
        }

        const zrts = [...new Set(this.currentRouteStops.filter(v => !!v.zrt).map((rs) => rs.zrt))];
        if (!zrts.length) return;

        const allStoresInZrt = await this.dbService.customers
            .where("zrt")
            .anyOf(zrts)
            .toArray();
        this.currentRouteStops.forEach((rs) => {
            for (const store of allStoresInZrt) {
                if (
                    !this.totalRouteStops.map((rs) => rs.customerId).includes(store.id) &&
                    (store.businessAddress?.latitude || store.dbaAddress?.latitude) &&
                    (store.businessAddress?.longitude || store.dbaAddress?.longitude)
                ) {
                    const src = { lat: rs.lat, lng: rs.lng };
                    const dst = {
                        lat: store.dbaAddress?.latitude ?? store.businessAddress?.latitude,
                        lng: store.dbaAddress?.longitude ?? store.businessAddress?.longitude
                    };
                    const distance = Helper.getDistanceinMiles(src, dst);
                    if (distance < 1) {
                        this.nearbyStores.push(this.routeStopToRouteStopVm(store, null, distance));
                    }
                }
            }
        });
        this.nearbyStores = this.nearbyStores
            .filter((v, i, a) => a.map((ns) => ns.customerId).indexOf(v.customerId) === i)
            .sort((a, b) => a.distance > b.distance ? 1 : -1);
    }

    editCurrentRoute(): void {
        this.shouldEditCurrentRoute = true;
        void this.router.navigate(["/accounts", "customers"]);
    }

    async deleteRoute() {
        const stopCount = parseInt(this.currentRoute.totalStops);
        this.dialogService.showConfirmDialog(
            `This route contains ${stopCount}
            stop${stopCount === 1 ? "" : "s"}. Are you sure you wish to delete this route?`
        ).pipe(
            filter(confirm => confirm), 
            switchMap(_ => 
                this.pleaseWaitService.withSpinnerShowing(async () => {
                    await this.routeDelineationService.offlineOnlineDeleteRoute(this.currentRoute.id);
                })
            )
        ).subscribe(() => this.router.navigate(["/my-day", "route-management"]));        
    }

    navigateTo(customerId: string): void {
        void this.router.navigate(["/accounts", customerId, "profile"], { queryParams: { routeId: this.routeId } });
    }

    async routeToRouteVm(route: Route): Promise<RouteViewmodel> {
        const stopCount = route.stops.length;
        const firstStop = route.stops[0];
        const lastStop = route.stops[route.stops.length - 1];

        const vm: RouteViewmodel = {
            id: route.id,
            name: route.name,
            description: route.description,
            date: route.date,
            dateFormatted: moment(route.date).format(this.dateFormat),
            zrt: this.routeEmployee.zrt,
            employeeName: this.routeEmployee.fullName,
            startAddress: this.formatAddress(firstStop.address),
            endAddress: this.formatAddress(lastStop.address),
            totalStops: `${stopCount}`,
            completedStops: 0,
            completedRoutedStops: 0,
            completedNonRoutedStops: 0
        };

        return vm;
    }

    routeStopToRouteStopVm(
        customer: Customer,
        stop?: RouteStop,
        distance?: number,
        px3Rank?: string,
    ): RouteStopViewmodel {
        return {
            customerId: customer?.id,
            avail: customer?.availability,
            indVolume: customer?.industryVolume ?? 0,
            lastCall: customer?.lastCall ? formatDate(
                customer.lastCall,
                this.dateFormat,
                "en-US"
            ) : "",
            msaStore: customer ? (customer.isMsa ? "Y" : "N") : "",
            px3Rank: px3Rank,
            zrt: customer?.zrt,
            customerType: customer?.customerType,
            name: stop?.label ?? customer?.name,
            shareVolume: customer?.shieldVolume ?? 0,
            sharePercentage: customer?.industryVolume ?
                customer?.shieldVolume / customer?.industryVolume : 0,
            address: stop?.address.address1 ?
                `${stop.address.address1} ${stop?.address.address2 ?? ""}`.trim() : (
                    customer.businessAddress.address1 ?
                        `${customer.businessAddress.address1} ${customer.businessAddress.address2 ?? ""}`.trim() :
                        ""
                ),
            city: stop?.address.city ?? customer.businessAddress.city,
            state: stop?.address.state ?? customer.businessAddress.state,
            county: stop?.address.county ?? customer.businessAddress.county,
            zip: stop?.address.zip ?? customer.businessAddress.zip,
            inRoute: stop ? true : false,
            lat: customer?.dbaAddress?.latitude ?? customer?.businessAddress?.latitude,
            lng: customer?.dbaAddress?.longitude ?? customer?.businessAddress?.longitude,
            distance: distance ?? 0,
            isCompleted: stop?.isCompleted
        };
    }

    setRouteObservable(route: Route): void {
        if (route) {
            if ((this.routeService && !this.routeService.route?.id) ||
                this.routeService?.route?.id !== route.id
            ) {
                this.routeService.route = route;
            }
        }
    }

    toggleShowNearbyStore(): void {
        this.showNearbyStores = !this.showNearbyStores;
    }

    async updateRouteOnRedirect(): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        const routeResponse = await this.routeDelineationService.getById(this.routeId);
        if (!routeResponse || !routeResponse.values) {
            this.shouldWait$.next(false);
            return;
        }

        const employeeResponse = await this.employeeDelineationService.getById(routeResponse.values.employeeId);
        if (!employeeResponse) {
            this.shouldWait$.next(false);
            return;
        }

        this.routeEmployee = employeeResponse.values;
        this.routeCalls = await this.getRouteCalls(routeResponse.values);

        const customerIds = routeResponse.values.stops
            .map((s) => s.customerId)
            .filter((cid) => !!cid);
        const customersResponse = await this.customerDelineationService.getByIds(customerIds);
        if (!customersResponse) {
            this.shouldWait$.next(false);
            return;
        }

        this.buildRouteViewmodels(routeResponse.values, customersResponse.values);
        this.setRouteObservable(routeResponse.values);

        this.shouldWait$.next(false);
    }

    private async getRouteCalls(route: Route): Promise<CallHistoryEntry[]> {
        const dateValue = moment(route.date).format(MY_DATE_FORMATS.display.dateInput);
        const callRefiners = new Array<Refiner>();

        const employeeRefiner = new Refiner();
        employeeRefiner.location = RefinerLocation.zrtByEmployee;
        employeeRefiner.dataValue = employeeRefiner.value = route.employeeId;
        callRefiners.push(employeeRefiner);

        const startRefiner = new Refiner();
        startRefiner.location = RefinerLocation.callOnOrAfterDate;
        startRefiner.dataValue = startRefiner.value = dateValue;
        callRefiners.push(startRefiner);

        const endRefiner = new Refiner();
        endRefiner.location = RefinerLocation.callOnOrBeforeDate;
        endRefiner.dataValue = endRefiner.value = dateValue;
        callRefiners.push(endRefiner);

        const response = await this.callDelineationService.getCallHistoryBatch(
            newSequentialId(),
            route.employeeZrt,
            callRefiners,
            10000,
            0,
            null
        );

        return response?.values ?? new Array<CallHistoryEntry>();
    }
}
