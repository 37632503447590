<div #myFilter>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Reset Days</mat-label>
            <mat-select #resetSelect
                [(value)]="viewmodel.selectedResetDays"
                (openedChange)="onOpenedSelectedResetDayChange()">
                <mat-option [value]="viewmodel.all"> All
                </mat-option>
                <mat-option [value]="viewmodel.yes"> Yes </mat-option>
                <mat-option [value]="viewmodel.no"> No </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label>Work With</mat-label>
            <input #workWithInput type="text"
                (blur)="onAddWorkWithRefiner()"
                (keyup)="onAddWorkWithRefiner($event)"
                [(ngModel)]="viewmodel.workWithInput" matInput />
        </mat-form-field>
    </div>
</div>
