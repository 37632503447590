<div #myFilter>
    <div class="mt-3 mb-3 w-100">
        <angular2-multiselect
            [data]="viewmodel.wholesalers"
            [(ngModel)]="viewmodel.selectedWholesalers"
            [settings]="viewmodel.wholesalersDropdownSettings"
            (onDeSelectAll)="closedWholesalerSelect()"
            (onClose)="closedWholesalerSelect()"
            (onOpen)="openedWholesalerSelect()">
        </angular2-multiselect>
    </div>
    <div class="mt-3 mb-3 w-100">
        <angular2-multiselect
            [data]="viewmodel.chains"
            [(ngModel)]="viewmodel.selectedChains"
            [settings]="viewmodel.chainsDropdownSettings"
            (onDeSelectAll)="closedChainSelect()"
            (onClose)="closedChainSelect()"
            (onOpen)="openedChainSelect()">
        </angular2-multiselect>
    </div>
</div>
