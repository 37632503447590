<div class="order-dates-modal" cdkDrag cdkDragBoundary=".cdk-overlay-container">
    <div cdkDragHandle>
        <div class="modal-header">
            Order Date Options
            <mat-icon class="close-icon" (click)="close()">close</mat-icon>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">

        </div>
        <div class="row">
            <div class="col center margin">
                <u>
                    Wholesaler
                </u>
            </div>
            <div class="col center margin">
                <u>
                    Project
                </u>
            </div>
            <div *ngIf="manager" class="col center margin">
                <u>
                    Manager
                </u>
            </div>
        </div>
        <div class="row">
            <div class="col center margin">
                <angular2-multiselect
                    [data]="wholesalerDropDown"
                    [(ngModel)]="selectedWholesaler"
                    [settings]="wholesalerDropdownSettings"
                    (onDeSelect)="wholesalerDeselect($event)"
                    (onDeSelectAll)="wholesalerDeselect($event)"
                    (onClose)="closedWholesalerSelect()">
                </angular2-multiselect>
            </div>
            <div class="col center margin">
                <angular2-multiselect
                    [data]="projectDropDown"
                    [(ngModel)]="selectedProject"
                    [settings]="projectsDropdownSettings"
                    (onDeSelect)="projectDeselect($event)"
                    (onDeSelectAll)="projectDeselect($event)"
                    (onClose)="projectSelectChange($event)"
                    (onOpen)="openedProjectSelect()">
                </angular2-multiselect>
            </div>
            <div *ngIf="manager" class="col center margin">
                {{manager ? (manager.zrt ? ('ZRT' +  manager.zrt + ' ') : "" + (manager.fullName ? manager.fullName : '' )) : ''}} </div>
        </div>
        <div class="row">
            <div class="col-5"></div>
            <div class="col-6 center">
                <b>Quantity</b>
            </div>
            <div class="col"></div>
        </div>
        <div class="row">
            <div class="col-5">
                <b>Product</b>
            </div>
            <div class="col-6 gridHeaders margin-right">
                <div class="col"
                    *ngFor="let callOrderDate of orderProduct.orderDates;">{{callOrderDate.orderDate | date: dateFormat}}</div>
            </div>
            <div class="col"></div>
        </div>
        <div class="row">
            <div class="col-5">
                {{ orderProduct.product.description }}
            </div>
            <div class="col-6">
                <div class="container">
                    <div class="row">
                        <div class="col"
                            *ngFor="let callOrderDate of orderProduct.orderDates;">
                            <input
                                class="gridInput"
                                type="number"
                                placeholder="0"
                                [(ngModel)]="callOrderDate.quantity"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col"></div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn sw-red-btn btn-sm" [disabled]="isOkDisabled()" (click)="close(true)">OK</button>
    </div>
</div>
