import { GenericLookup, ProjectTypeLookup, ProjectTypes } from "shield.shared";
import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";

export class NewProjectViewmodel extends BasicDialogViewModel {
    selectedProjectType: ProjectTypes;
    projectTypes: GenericLookup<ProjectTypes>[];

    constructor() {
        super();
        this.buttonRightText = "Create Project";
        this.buttonRightDisabledFunction = () => false;

        this.buttonLeftText = "Cancel";
        this.buttonLeftDisabledFunction = () => false;

        this.headerLeftText = "New Project";
        this.isConfirmed = false;
        this.showFooter = true;

        this.projectTypes = ProjectTypeLookup;
        this.selectedProjectType = ProjectTypes.RetailEngagement;

    }
}
