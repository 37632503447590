<div class="card-body p-0">
    <div class="card-view-port">
        <div *ngIf="slides && slides.length > 0" class="row">
            <div class="col-3 chevron-container padding-left-picture-type">
                <div *ngIf="pictureType" class="picture-info-container large-font">
                    Picture Type: {{ pictureType }}
                </div>
                <span class="chevron material-icons" (click)="carousel.previous()">chevron_left</span>
            </div>
            <div class="col carousel-container">
                <mat-carousel #carousel (change)="setPictureType($event)" [autoplay]="false" color="primary"
                    [proportion]="proportion" maxWidth="1400px" [slides]="slides.length" [loop]="true"
                    [hideArrows]="true" [hideIndicators]="false" [useKeyboard]="true" [useMouseWheel]="true"
                    orientation="ltr" (click)="openPictureInModal(pictureString, pictureType)">
                    <mat-carousel-slide   #matCarouselSlide *ngFor="
                                let slide of slides;
                                let i = index
                            "[image]="slide.image" overlayColor="#00000040" [hideOverlay]="true">
                    </mat-carousel-slide>
                </mat-carousel>
            </div>
            <div class="col-3 chevron-container padding-right-picture-type">
                <div *ngIf="pictureTags" class="picture-info-container">
                    Picture Tags: {{ pictureTags }}
                </div>
                <span class="chevron material-icons" (click)="carousel.next()">chevron_right</span>
            </div>
        </div>
    </div>
</div>
