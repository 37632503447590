import { Customer } from "src/app/entity-models/customer.entity";

export class ProjectSummaryStoresGridViewmodel {

    id: string;
    name: string;
    address: string;
    city: string;
    state: string;
    customer: Customer;
    constructor(customer: Customer){

        this.id = customer.id;
        this.customer = customer;
        this.name = customer.name;
        this.address = customer?.businessAddress?.address1;
        this.city = customer?.businessAddress?.city;
        this.state = customer?.businessAddress?.state;
    }
}
