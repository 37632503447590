import { Component, ElementRef, OnInit, Type, ViewChild } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterService } from "src/app/services/filter.service";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { GenericLookup, ProjectStatuses, ProjectTypes, RefinerLocation, valueSeparator } from "shield.shared";
import { ProjectAttributesViewmodel } from "./project-attributes.viewmodel";

@Component({
    selector: "app-project-attributes-filter",
    templateUrl: "./project-attributes-filter.component.html",
    styleUrls: ["./project-attributes-filter.component.scss"]
})
export class ProjectAttributesFilterComponent extends FilterBaseComponent implements OnInit {
    //view childs
    @ViewChild("projectStatusesSelect") projectStatusesSelect: MatSelect;
    @ViewChild("projectTypesSelect") projectTypesSelect: MatSelect;

    //public vars
    contentComponent: Type<ProjectAttributesFilterComponent>;
    icon = "bookmark";
    name = "Attributes";
    viewmodel = new ProjectAttributesViewmodel(this.filterService);

    constructor(private filterService: FilterService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        await this.viewmodel.initializeDropDowns();
        this.setInitialized();
    }

    //events
    onOpenedProjectStatusesChange(): void {
        this.viewmodel.isAllProjectStatusPresent = this.viewmodel.selectedProjectStatuses.includes(
            this.viewmodel.allProjectStatus
        );
        if (this.viewmodel.isAllProjectStatusPresent) {
            this._refinerService.removeRefinerByLocation(RefinerLocation.projectStatus, true, false);
        }
        else {
            if (this.projectStatusesSelect && !this.projectStatusesSelect.panelOpen) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.projectStatus;
                refiner.value = this.viewmodel.selectedProjectStatuses
                    .map((vm) => vm.description)
                    .join(", ");
                refiner.dataPropertyName = "description";
                refiner.dataValue = this.viewmodel.selectedProjectStatuses
                    .map((vm) => vm.id)
                    .join(valueSeparator);

                this._refinerService.checkAndUpdateRefiner(refiner);
            }
        }
    }

    onSelectionProjectStatusesChange(): void {
        if (
            this.viewmodel.isAllProjectStatusPresent &&
            this.viewmodel.selectedProjectStatuses.length > 1
        ) {
            const index = this.viewmodel.selectedProjectStatuses.findIndex(
                (pt) => pt === this.viewmodel.allProjectStatus
            );
            if (index !== -1) {
                this.viewmodel.selectedProjectStatuses.splice(index, 1);
                this.viewmodel.isAllProjectStatusPresent = false;
            }
        } else if (
            !this.viewmodel.isAllProjectStatusPresent &&
            this.viewmodel.selectedProjectStatuses.includes(
                this.viewmodel.allProjectStatus
            )
        ) {
            this.viewmodel.selectedProjectStatuses = [
                this.viewmodel.allProjectStatus
            ];
            this.viewmodel.isAllProjectStatusPresent = true;
        }
    }

    onOpenedProjectTypesChange(): void {
        this.viewmodel.isAllProjectTypePresent = this.viewmodel.selectedProjectTypes.includes(
            this.viewmodel.allProjectType
        );
        if (this.viewmodel.isAllProjectTypePresent) {
            this._refinerService.removeRefinerByLocation(RefinerLocation.projectType, true, false);
        }
        else {
            if (this.projectTypesSelect && !this.projectTypesSelect.panelOpen) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.projectType;
                refiner.value = this.viewmodel.selectedProjectTypes
                    .map((vm) => vm.description)
                    .join(", ");
                refiner.dataPropertyName = "description";
                refiner.dataValue = this.viewmodel.selectedProjectTypes
                    .map((vm) => vm.id)
                    .join(valueSeparator);

                this._refinerService.checkAndUpdateRefiner(refiner);
            }
        }
    }

    onSelectionProjectTypesChange(): void {
        if (
            this.viewmodel.isAllProjectTypePresent &&
            this.viewmodel.selectedProjectTypes.length > 1
        ) {
            const index = this.viewmodel.selectedProjectTypes.findIndex(
                (pt) => pt === this.viewmodel.allProjectType
            );
            if (index !== -1) {
                this.viewmodel.selectedProjectTypes.splice(index, 1);
                this.viewmodel.isAllProjectTypePresent = false;
            }
        } else if (
            !this.viewmodel.isAllProjectTypePresent &&
            this.viewmodel.selectedProjectTypes.includes(
                this.viewmodel.allProjectType
            )
        ) {
            this.viewmodel.selectedProjectTypes = [
                this.viewmodel.allProjectType
            ];
            this.viewmodel.isAllProjectTypePresent = true;
        }
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.projectStatus:
                        const selectedProjectStatuses = refiner.value?.split(", ");
                        const rtnSelectedProjectStatuses = new Array<GenericLookup<ProjectStatuses>>();

                        if (selectedProjectStatuses) {
                            for (const status of selectedProjectStatuses) {
                                const found = this.viewmodel.projectStatuses.find(
                                    (ps) => ps.description === status.trim()
                                );
                                if (found) {
                                    rtnSelectedProjectStatuses.push(found);
                                }
                            }
                        }
                        this.viewmodel.selectedProjectStatuses = rtnSelectedProjectStatuses?.length ? rtnSelectedProjectStatuses : [this.viewmodel.allProjectStatus];
                    break;
                case RefinerLocation.projectType:
                        const selectedProjectTypes = refiner.value?.split(", ");
                        const rtnSelectedProjectTypes = new Array<GenericLookup<ProjectTypes>>();

                        if (selectedProjectTypes) {
                            for (const type of selectedProjectTypes) {
                                const found = this.viewmodel.projectTypes.find(
                                    (pt) => pt.description === type.trim()
                                );
                                if (found) {
                                    rtnSelectedProjectTypes.push(found);
                                }
                            }
                        }
                        this.viewmodel.selectedProjectTypes = rtnSelectedProjectTypes?.length ? rtnSelectedProjectTypes : [this.viewmodel.allProjectType];
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.projectType;
        const projectTypesRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.projectType
        );
        if (projectTypesRefiner) {
            this.onInputChange(projectTypesRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.projectStatus;
        const projectStatusesRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.projectStatus
        );
        if (projectStatusesRefiner) {
            this.onInputChange(projectStatusesRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }
}
