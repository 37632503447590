import { Subscription } from "rxjs";
import { ProjectFilterService } from "src/app/details/project-list/project-filter.service";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { Customer } from "src/app/entity-models/customer.entity";

export class ProjectActivitiesViewmodel {
    projectFilterService: ProjectFilterService;

    wholesalersSubscription: Subscription;
    wholesalers = new Array<Customer>();
    selectedWholesalers = new Array<Customer>();
    selectedWholesalersHold = new Array<Customer>();
    isWholesalersStale: boolean;
    selectedWholesalerIds = new Array<string>();
    wholesalersDropdownSettings = {
        text: "Wholesalers",
        singleSelection: false,
        unSelectAllText: "Deselect All",
        filterUnSelectAllText: "Deselect all filtered results",
        enableCheckAll: false,
        enableFilterSelectAll: true,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "displayName",
        searchBy: ["displayName"],
        classes: "multi-select-container c-btn"
    }

    chainsSubscription: Subscription;
    chains = new Array<AccountOwnership>();
    selectedChains = new Array<AccountOwnership>();
    selectedChainsHold = new Array<AccountOwnership>();
    isChainsStale: boolean;
    chainsDropdownSettings = {
        text: "Chains",
        singleSelection: false,
        unSelectAllText: "Deselect All",
        filterUnSelectAllText: "Deselect all filtered results",
        enableCheckAll: false,
        enableFilterSelectAll: true,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "name",
        searchBy: ["name"],
        classes: "multi-select-container c-btn"
    }

    constructor(projectFilterService: ProjectFilterService) {
        this.projectFilterService = projectFilterService;
    }
}
