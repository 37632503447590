import { Component, OnInit } from '@angular/core';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';
import { ProjectValidationSummaryViewmodel } from './project-validation-summary.viewmodel';

@Component({
    selector: 'app-project-validation-summary',
    templateUrl: './project-validation-summary.component.html',
    styleUrls: ['./project-validation-summary.component.scss']
})
export class ProjectValidationSummaryComponent implements OnInit {

    viewmodel = this.injectedData.data;

    constructor(public injectedData: SwisherOverlayRef<
        ProjectValidationSummaryViewmodel,
        ProjectValidationSummaryComponent>) { }

    ngOnInit(): void {
    }

}
