export enum ResponseCountKey {
    default = "Default",
    affected = "Affected",
    added = "Added",
    updated = "Updated",
    deleted = "Deleted",
    customer = "Customer",
    assigned = "Assigned",
    unassigned = "Unassigned",
}
