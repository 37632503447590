import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CallTypes, LicenseTypes, newSequentialId, Subsidiary } from 'shield.shared';
import { CustomerStateService } from 'src/app/accounts/account-services/customer-state.service';
import { CallService } from 'src/app/accounts/call-master/call-services/call.service';
import { CallReceipt } from 'src/app/entity-models/call-receipt';
import { CallReceiptLicense } from 'src/app/entity-models/call-receipt-license.entity';
import { CustomerLicense } from 'src/app/entity-models/customer-license.entity';
import { Customer } from 'src/app/entity-models/customer.entity';
import { Employee } from 'src/app/entity-models/employee.entity';
import { RetailCall } from 'src/app/entity-models/retail-call.entity';
import { RetailStepperStep } from 'src/app/enums/retail-stepper-step';
import { SubsidiaryTypes } from 'src/app/enums/subsidiary-types';
import { AppStateService } from 'src/app/services/app-state.service';
import { MY_DATE_FORMATS } from 'src/app/shared/constants/date-formats';
import { CallCashProductViewModel } from '../../../../call-viewmodels/call-cash-product.viewmodel';
import { ReceiptAddressViewModel } from '../../receipt-address.viewmodel';
import { Contact } from 'src/app/entity-models/contact.entity';
import { Helper } from 'src/app/helpers/helper';
import { CallGratisProductViewModel } from 'src/app/accounts/call-master/call-viewmodels/call-gratis-product.viewmodel';
import { CallProductInViewModel } from 'src/app/accounts/call-master/call-viewmodels/call-product-in.viewmodel';
import { CallProductOutViewModel } from 'src/app/accounts/call-master/call-viewmodels/call-product-out.viewmodel';
import { StateLicenseDelineationService } from 'src/app/services/delineation-services/state-license-delineation.service';
import { StateDelineationService } from 'src/app/services/delineation-services/state-delineation.service';
import { RetailTaxableReceiptViewmodel } from '../../../stepper-call-models/retail-taxable-receipt.viewmodel';
import { StepperCallApplicationService } from '../../../stepper-call-services/stepper-call-application.service';

@Component({
    selector: 'app-retail-format-base',
    templateUrl: './retail-format-base.component.html',
    styleUrls: ['./retail-format-base.component.scss']
})
export class RetailFormatBaseComponent implements OnInit, OnDestroy {

    @Input() isRetailDraft = false;
    @Input() isRetailFinal = false;
    @Input() customerReceiptAddress: ReceiptAddressViewModel;
    @Input() signature: string;
    @Input() signatureRetailWidth: number;
    @Input() signatureRetailHeight: number;

    private _selectedContact: Contact;
    @Input()
    get selectedContact(): Contact {
        return this._selectedContact;
    }
    set selectedContact(value: Contact) {
        this._selectedContact = value;

        if (value) {
            this.contactDisplayName = (value.lastName ? value.lastName + ", " : "")
                + (value.firstName ? value.firstName : "");
        }
    }

    dateTimeFormat = MY_DATE_FORMATS.display.customDateTimeInput;
    selectedIndexSubscription: Subscription;
    employeeSubscription: Subscription;
    timeNow: Date;
    employee: Employee;
    retailStateOptLicense: CustomerLicense;
    retailStateVaporLicense: CustomerLicense;
    swisherlicense: string;
    swisherReceiptNumber: string;
    easReceiptNumber: string;
    swisherCashRetailViewmodels = new Array<CallCashProductViewModel>();
    swisherGratisRetailViewmodels = new Array<CallGratisProductViewModel>();
    swisherExchangeInRetailViewmodels = new Array<CallProductInViewModel>();
    swisherExchangeOutRetailViewmodels = new Array<CallProductOutViewModel>();
    easCashRetailViewmodels = new Array<CallCashProductViewModel>();
    easGratisRetailViewmodels = new Array<CallGratisProductViewModel>();
    easExchangeInRetailViewmodels = new Array<CallProductInViewModel>();
    easExchangeOutRetailViewmodels = new Array<CallProductOutViewModel>();
    cashSalesAreBuiltSubscription: Subscription;
    gratisSalesAreBuiltSubscription: Subscription;
    exchangeInSalesAreBuiltSubscription: Subscription;
    exchangeOutSalesAreBuiltSubscription: Subscription;
    customerSubscription: Subscription;
    easlicense: string;
    stateName: string;
    customer: Customer;
    swisherTaxableCashTotal: number = 0;
    swisherTaxableGratisTotal: number = 0;
    swisherTaxableExchangeInTotal: number = 0;
    swisherTaxableExchangeOutTotal: number = 0;
    easTaxableCashTotal: number = 0;
    easTaxableGratisTotal: number = 0;
    easTaxableExchangeInTotal: number = 0;
    easTaxableExchangeOutTotal: number = 0;
    swisherRetailTaxableReceiptViewmodel = new Array<RetailTaxableReceiptViewmodel>();
    easRetailTaxableReceiptViewmodel = new Array<RetailTaxableReceiptViewmodel>();
    swisherStateTaxTotal = 0;
    swisherCountyTaxTotal = 0;
    swisherCityTaxTotal = 0;
    swisherTaxTotal = 0;
    easStateTaxTotal = 0;
    easCountyTaxTotal = 0;
    easCityTaxTotal = 0;
    easTaxTotal = 0;
    signatureDisplayName: string;
    salesRepDisplayName: string;
    contactDisplayName: string;
    readonly swisherAddress = "P.O. Box 2230, Jacksonville, FL 32203";
    cashSwisherTotalTax = 0;

    printLogo: string;

    constructor(public stepperCallApplicationService: StepperCallApplicationService,
        private callService: CallService,
        private appStateService: AppStateService,
        private customerStateService: CustomerStateService,
        private stateLicenseDelineationService: StateLicenseDelineationService,
        private stateDelineationService: StateDelineationService
        ) { }

    ngOnInit(): void {
        if (
            !this.selectedIndexSubscription ||
            this.selectedIndexSubscription.closed
        ) {

            this.selectedIndexSubscription = this.stepperCallApplicationService.observableSelectedIndex.subscribe(
                async (selectedIndex) => {
                    if (selectedIndex === RetailStepperStep.receipts) {
                        this.timeNow = new Date();
                        if (!(this.callService.call as RetailCall).receiptBase) {
                            (this.callService.call as RetailCall).receiptBase = Math.floor(
                                new Date().getTime() / 1000.0
                            );
                            await this.callService.saveCallAndNotify();
                        }
                        this.groupRetailReceipts();
                    }
                }
            );

            this.employeeSubscription = this.appStateService.currentEmployee.subscribe(
                (employee) => {
                    this.employee = employee;
                    if (this.employee) {

                        this.signatureDisplayName = (employee.lastName ? employee.lastName + ", " : "")
                        + (employee.firstName ? employee.firstName : "");

                        this.salesRepDisplayName = (employee.zrt ? "ZRT " + employee.zrt + ", " : "")
                            + this.signatureDisplayName;

                        this.groupRetailReceipts();
                    }
                }
            );
        }

        if (!this.customerSubscription || this.customerSubscription.closed) {
            this.customerSubscription = this.customerStateService.observableCustomer.subscribe(
                async (customer) => {
                    this.customer = customer;
                    if (customer) {
                        this.setLicenses();
                    }

                }
            );
        }

        if (
            !this.cashSalesAreBuiltSubscription ||
            this.cashSalesAreBuiltSubscription.closed
        ) {
            this.cashSalesAreBuiltSubscription = this.stepperCallApplicationService.observableCashBuilt.subscribe(
                async (value) => {
                    if (value) {
                        await this.groupCashSales();
                        this.groupTaxes();
                    }
                }
            );
        }
        if (
            !this.gratisSalesAreBuiltSubscription ||
            this.gratisSalesAreBuiltSubscription.closed
        ) {
            this.gratisSalesAreBuiltSubscription = this.stepperCallApplicationService.observableGratisBuilt.subscribe(
                async (value) => {
                    if (value) {
                        await this.groupGratisSales();
                        this.groupTaxes();
                    }
                }
            );
        }
        if (
            !this.exchangeInSalesAreBuiltSubscription ||
            this.exchangeInSalesAreBuiltSubscription.closed
        ) {
            this.exchangeInSalesAreBuiltSubscription = this.stepperCallApplicationService.observableExchangeInBuilt.subscribe(
                async (value) => {
                    if (value) {
                        await this.groupExchangeInSales();
                        this.groupTaxes();
                    }
                }
            );
        }
        if (
            !this.exchangeOutSalesAreBuiltSubscription ||
            this.exchangeOutSalesAreBuiltSubscription.closed
        ) {
            this.exchangeOutSalesAreBuiltSubscription = this.stepperCallApplicationService.observableExchangeOutBuilt.subscribe(
                async (value) => {
                    if (value) {
                        await this.groupExchangeOutSales();
                        this.groupTaxes();
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (
            this.selectedIndexSubscription &&
            !this.selectedIndexSubscription.closed
        ) {
            this.selectedIndexSubscription.unsubscribe();
        }

        if (this.employeeSubscription && !this.employeeSubscription.closed) {
            this.employeeSubscription.unsubscribe();
        }

        if (
            this.cashSalesAreBuiltSubscription &&
            !this.cashSalesAreBuiltSubscription.closed
        ) {
            this.cashSalesAreBuiltSubscription.unsubscribe();
        }
        if (
            this.gratisSalesAreBuiltSubscription &&
            !this.gratisSalesAreBuiltSubscription.closed
        ) {
            this.gratisSalesAreBuiltSubscription.unsubscribe();
        }
        if (
            this.exchangeInSalesAreBuiltSubscription &&
            !this.exchangeInSalesAreBuiltSubscription.closed
        ) {
            this.exchangeInSalesAreBuiltSubscription.unsubscribe();
        }
        if (
            this.exchangeOutSalesAreBuiltSubscription &&
            !this.exchangeOutSalesAreBuiltSubscription.closed
        ) {
            this.exchangeOutSalesAreBuiltSubscription.unsubscribe();
        }
    }

    groupRetailReceipts(): void {
        if (
            this.employee
            && (this.callService.call?.callType === CallTypes.retail
                || this.callService.call.callType === CallTypes.rmWholesale)
            && this.callService.call.receiptBase
        ) {
            this.groupCashSales();
            this.groupGratisSales();
            this.groupExchangeInSales();
            this.groupExchangeOutSales();
            this.groupTaxes();
            this.stepperCallApplicationService.calculateTotals();

            if (!this.swisherReceiptNumber) {
                this.swisherReceiptNumber =
                    (this.employee.zrt ??= "") +
                    this.callService.call.receiptBase.toString() +
                    "00";
            }

            if (!this.easReceiptNumber) {
                this.easReceiptNumber =
                    (this.employee.zrt ??= "") +
                    this.callService.call.receiptBase.toString() +
                    "01";
            }
        }
    }

    buildRetailCallReceipt(receiptId: string, receiptExtention: string): CallReceipt {
        const rtn = new CallReceipt();
        rtn.id = receiptId;
        rtn.callId = this.callService.call.id;
        rtn.receiptNumber = this.employee.zrt + (this.callService.call as RetailCall).receiptBase.toString();
        rtn.receiptNumberExtention = receiptExtention;
        rtn.isOriginal = true;
        rtn.callReceiptLicenses = this.buildRetailCallReceiptLicense(rtn.id);

        return rtn;
    }

    private buildRetailCallReceiptLicense(callReceiptId: string): CallReceiptLicense[] {
        const rtn = new Array<CallReceiptLicense>();

        if (this.retailStateOptLicense) {
            const callReceiptLicense = new CallReceiptLicense();
            callReceiptLicense.id = newSequentialId();
            callReceiptLicense.callReceiptId = callReceiptId;
            callReceiptLicense.licenseNumber = this.retailStateOptLicense.licenseNumber;
            callReceiptLicense.licenseTypeId = LicenseTypes.RetailStateOTPLicense;

            rtn.push(callReceiptLicense);
        }

        if (this.retailStateOptLicense) {
            const callReceiptLicense = new CallReceiptLicense();
            callReceiptLicense.id = newSequentialId();
            callReceiptLicense.callReceiptId = callReceiptId;
            callReceiptLicense.licenseNumber = this.retailStateOptLicense.licenseNumber;
            callReceiptLicense.licenseTypeId = LicenseTypes.RetailStateVaporLicense;

            rtn.push(callReceiptLicense);
        }
        return rtn;
    }

    groupCashSales(): void {
        if (
            this.employee
            && (this.callService.call?.callType === CallTypes.retail
                || this.callService.call.callType === CallTypes.rmWholesale)
            && this.callService.call.receiptBase
        ) {
            this.swisherCashRetailViewmodels = this.stepperCallApplicationService.callCashProductViewModels
                .filter((cp) => Helper.equalsIgnoreCase(cp.product?.subsidiary, SubsidiaryTypes.swisher)
                    || Helper.equalsIgnoreCase(cp.product?.subsidiary, SubsidiaryTypes.rogueholdingsLlc));

            if (this.swisherCashRetailViewmodels.length) {
                const taxableViewmodels = this.swisherCashRetailViewmodels.filter((scrvm) => scrvm.stateTaxAmount || scrvm.countyTaxAmount || scrvm.cityTaxAmount);
                this.swisherTaxableCashTotal = taxableViewmodels.reduce((acc, record) => (acc ?? 0)
                    + (record.stateTaxAmount ?? 0)
                    + (record.countyTaxAmount ?? 0)
                    + (record.cityTaxAmount ?? 0), 0);
            }

            this.easCashRetailViewmodels = this.stepperCallApplicationService.callCashProductViewModels
                .filter((cp) => Helper.equalsIgnoreCase(cp.product?.subsidiary, SubsidiaryTypes.eas));

            if (this.easCashRetailViewmodels.length) {
                const taxableViewmodels = this.easCashRetailViewmodels.filter((ecrvm) => ecrvm.stateTaxAmount || ecrvm.countyTaxAmount || ecrvm.cityTaxAmount);
                this.easTaxableCashTotal = taxableViewmodels.reduce((acc, record) => (acc ?? 0)
                    + (!record.statePrepaid ? record.stateTaxAmount ?? 0 : 0)
                    + (record.countyTaxAmount ?? 0)
                    + (record.cityTaxAmount ?? 0), 0);
            }

            this.stepperCallApplicationService.calculateTotals();
        }
    }

    groupGratisSales(): void {
        if (
            this.employee
            && (this.callService.call?.callType === CallTypes.retail
                || this.callService.call.callType === CallTypes.rmWholesale)
            && this.callService.call.receiptBase
        ) {
            this.swisherGratisRetailViewmodels = this.stepperCallApplicationService.callGratisProductViewModels.filter((cp) => cp.product?.subsidiary === SubsidiaryTypes.swisher
                || cp.product?.subsidiary === SubsidiaryTypes.rogueholdingsLlc);

            if (this.swisherGratisRetailViewmodels.length) {
                const taxableViewmodels = this.swisherGratisRetailViewmodels.filter((sgrvm) => sgrvm.stateTaxAmount || sgrvm.countyTaxAmount || sgrvm.cityTaxAmount);
                this.swisherTaxableGratisTotal = taxableViewmodels.reduce((acc, record) => (acc ?? 0)
                    + (!record.statePrepaid ? record.stateTaxAmount ?? 0 : 0)
                    + (record.countyTaxAmount ?? 0)
                    + (record.cityTaxAmount ?? 0), 0);
            }

            this.easGratisRetailViewmodels = this.stepperCallApplicationService.callGratisProductViewModels
                .filter((cp) => cp.product?.subsidiary === SubsidiaryTypes.eas);

            if (this.easGratisRetailViewmodels.length) {
                const taxableViewmodels = this.easGratisRetailViewmodels.filter((egrvm) => egrvm.stateTaxAmount || egrvm.countyTaxAmount || egrvm.cityTaxAmount);
                this.easTaxableGratisTotal = taxableViewmodels.reduce((acc, record) => (acc ?? 0)
                    + (!record.statePrepaid ? record.stateTaxAmount ?? 0 : 0)
                    + (record.countyTaxAmount ?? 0)
                    + (record.cityTaxAmount ?? 0), 0);
            }

            this.stepperCallApplicationService.calculateTotals();
        }
    }

    groupExchangeOutSales(): void {
        if (
            this.employee
            && (this.callService.call?.callType === CallTypes.retail
                || this.callService.call.callType === CallTypes.rmWholesale)
            && this.callService.call.receiptBase
        ) {
            this.swisherExchangeOutRetailViewmodels = this.stepperCallApplicationService.callProductOutViewModels.filter((cp) => cp.product?.subsidiary === SubsidiaryTypes.swisher
                || cp.product?.subsidiary === SubsidiaryTypes.rogueholdingsLlc);

            if (this.swisherExchangeOutRetailViewmodels.length) {
                const taxableViewmodels = this.swisherExchangeOutRetailViewmodels.filter((seorvm) => seorvm.stateTaxAmount || seorvm.countyTaxAmount || seorvm.cityTaxAmount);
                this.swisherTaxableExchangeOutTotal = taxableViewmodels.reduce((acc, record) => (acc ?? 0)
                    + (!record.statePrepaid ? record.stateTaxAmount ?? 0 : 0)
                    + (record.countyTaxAmount ?? 0)
                    + (record.cityTaxAmount ?? 0), 0);
            }

            this.easExchangeOutRetailViewmodels = this.stepperCallApplicationService.callProductOutViewModels
                .filter((cp) => cp.product?.subsidiary === SubsidiaryTypes.eas);

            if (this.easExchangeOutRetailViewmodels.length) {
                const taxableViewmodels = this.easExchangeOutRetailViewmodels.filter((eeorvm) => eeorvm.stateTaxAmount || eeorvm.countyTaxAmount || eeorvm.cityTaxAmount);
                this.easTaxableExchangeOutTotal = taxableViewmodels.reduce((acc, record) => (acc ?? 0)
                    + (!record.statePrepaid ? record.stateTaxAmount ?? 0 : 0)
                    + (record.countyTaxAmount ?? 0)
                    + (record.cityTaxAmount ?? 0), 0);
            }

            this.stepperCallApplicationService.calculateTotals();
        }
    }

    groupExchangeInSales(): void {
        if (
            this.employee
            && (this.callService.call?.callType === CallTypes.retail
                || this.callService.call.callType === CallTypes.rmWholesale)
            && this.callService.call.receiptBase
        ) {
            this.swisherExchangeInRetailViewmodels = this.stepperCallApplicationService.callProductInViewModels.filter((cp) => cp.product?.subsidiary === SubsidiaryTypes.swisher
                || cp.product?.subsidiary === SubsidiaryTypes.rogueholdingsLlc);

            if (this.swisherExchangeInRetailViewmodels.length) {
                const taxableViewmodels = this.swisherExchangeInRetailViewmodels.filter((seirvm) => seirvm.stateTaxAmount || seirvm.countyTaxAmount || seirvm.cityTaxAmount);
                this.swisherTaxableExchangeInTotal = taxableViewmodels.reduce((acc, record) => (acc ?? 0)
                    + (!record.statePrepaid ? record.stateTaxAmount ?? 0 : 0)
                    + (record.countyTaxAmount ?? 0)
                    + (record.cityTaxAmount ?? 0), 0);
            }

            this.easExchangeInRetailViewmodels = this.stepperCallApplicationService.callProductInViewModels
                .filter((cp) => cp.product?.subsidiary === SubsidiaryTypes.eas);

            if (this.swisherExchangeInRetailViewmodels.length) {
                const taxableViewmodels = this.swisherExchangeInRetailViewmodels.filter((eeirvm) => eeirvm.stateTaxAmount || eeirvm.countyTaxAmount || eeirvm.cityTaxAmount);
                this.easTaxableExchangeInTotal = taxableViewmodels.reduce((acc, record) => (acc ?? 0)
                    + (!record.statePrepaid ? record.stateTaxAmount ?? 0 : 0)
                    + (record.countyTaxAmount ?? 0)
                    + (record.cityTaxAmount ?? 0), 0);
            }

            this.stepperCallApplicationService.calculateTotals();
        }
    }

    groupTaxes(): void {

        const swisherViewmodels = new Array<RetailTaxableReceiptViewmodel>();
        const easViewmodels = new Array<RetailTaxableReceiptViewmodel>();

        for (const item of this.swisherCashRetailViewmodels ?? []) {
            const vm = new RetailTaxableReceiptViewmodel();
            vm.productDescription = item.product?.description;
            vm.stateTax = item.stateTaxAmount ?? 0;
            vm.countyTax = item.countyTaxAmount ?? 0;
            vm.cityTax = item.cityTaxAmount ?? 0;
            vm.total = (vm.stateTax ?? 0) + (vm.countyTax ?? 0) + (vm.cityTax ?? 0);

            swisherViewmodels.push(vm);
        }

        for (const item of this.easCashRetailViewmodels ?? []) {
            const vm = new RetailTaxableReceiptViewmodel();
            vm.productDescription = item.product?.description;
            vm.stateTax = item.stateTaxAmount ?? 0;
            vm.countyTax = item.countyTaxAmount ?? 0;
            vm.cityTax = item.cityTaxAmount ?? 0;
            vm.total = (vm.stateTax ?? 0) + (vm.countyTax ?? 0) + (vm.cityTax ?? 0);

            easViewmodels.push(vm);
        }

        for (const item of this.swisherGratisRetailViewmodels ?? []) {
            const vm = new RetailTaxableReceiptViewmodel();
            vm.productDescription = item.product?.description;
            vm.stateTax = item.stateTaxAmount ?? 0;
            vm.countyTax = item.countyTaxAmount ?? 0;
            vm.cityTax = item.cityTaxAmount ?? 0;
            vm.total = (vm.stateTax ?? 0) + (vm.countyTax ?? 0) + (vm.cityTax ?? 0);

            swisherViewmodels.push(vm);
        }

        for (const item of this.easGratisRetailViewmodels ?? []) {
            const vm = new RetailTaxableReceiptViewmodel();
            vm.productDescription = item.product?.description;
            vm.stateTax = item.stateTaxAmount ?? 0;
            vm.countyTax = item.countyTaxAmount ?? 0;
            vm.cityTax = item.cityTaxAmount ?? 0;
            vm.total = (vm.stateTax ?? 0) + (vm.countyTax ?? 0) + (vm.cityTax ?? 0);

            easViewmodels.push(vm);
        }

        for (const item of this.swisherExchangeOutRetailViewmodels ?? []) {
            const vm = new RetailTaxableReceiptViewmodel();
            vm.productDescription = item.product?.description;
            vm.stateTax = item.stateTaxAmount ?? 0;
            vm.countyTax = item.countyTaxAmount ?? 0;
            vm.cityTax = item.cityTaxAmount ?? 0;
            vm.total = (vm.stateTax ?? 0) + (vm.countyTax ?? 0) + (vm.cityTax ?? 0);

            swisherViewmodels.push(vm);
        }

        for (const item of this.easExchangeOutRetailViewmodels ?? []) {
            const vm = new RetailTaxableReceiptViewmodel();
            vm.productDescription = item.product?.description;
            vm.stateTax = item.stateTaxAmount ?? 0;
            vm.countyTax = item.countyTaxAmount ?? 0;
            vm.cityTax = item.cityTaxAmount ?? 0;
            vm.total = (vm.stateTax ?? 0) + (vm.countyTax ?? 0) + (vm.cityTax ?? 0);

            easViewmodels.push(vm);
        }

        for (const item of (this.swisherExchangeInRetailViewmodels ?? [])
        .concat((this.easExchangeInRetailViewmodels ?? []))) {
            const vm = new RetailTaxableReceiptViewmodel();
            vm.productDescription = item.product?.description;
            vm.stateTax = item.stateTaxAmount ?? 0;
            vm.countyTax = item.countyTaxAmount ?? 0;
            vm.cityTax = item.cityTaxAmount ?? 0;
            vm.total = vm.stateTax + vm.countyTax + vm.cityTax;
            vm.total = (vm.stateTax ?? 0) + (vm.countyTax ?? 0) + (vm.cityTax ?? 0);

            swisherViewmodels.push(vm);
        }

        this.swisherRetailTaxableReceiptViewmodel = swisherViewmodels;
        this.easRetailTaxableReceiptViewmodel = easViewmodels;

        this.swisherStateTaxTotal = this.swisherRetailTaxableReceiptViewmodel.reduce((acc, record) => (acc ?? 0) + (record.stateTax ?? 0), 0);
        this.swisherCountyTaxTotal = this.swisherRetailTaxableReceiptViewmodel.reduce((acc, record) => (acc ?? 0) + (record.countyTax ?? 0), 0);
        this.swisherCityTaxTotal = this.swisherRetailTaxableReceiptViewmodel.reduce((acc, record) => (acc ?? 0) + (record.cityTax ?? 0), 0);
        this.swisherTaxTotal = this.swisherStateTaxTotal + this.swisherCountyTaxTotal + this.swisherCityTaxTotal;

        this.easStateTaxTotal = this.easRetailTaxableReceiptViewmodel.reduce((acc, record) => (acc ?? 0) + (record.stateTax ?? 0), 0);
        this.easCountyTaxTotal = this.easRetailTaxableReceiptViewmodel.reduce((acc, record) => (acc ?? 0) + (record.countyTax ?? 0), 0);
        this.easCityTaxTotal = this.swisherRetailTaxableReceiptViewmodel.reduce((acc, record) => (acc ?? 0) + (record.cityTax ?? 0), 0);
        this.easTaxTotal = this.easStateTaxTotal + this.easCountyTaxTotal + this.easCityTaxTotal;

    }

    async setLicenses(): Promise<void> {
        const customerLicenses = this.customer.customerLicenses ??= [];
        this.retailStateOptLicense = customerLicenses.find((l) => l.licenseTypeId === LicenseTypes.RetailStateOTPLicense && l.isActive);
        this.retailStateVaporLicense = customerLicenses.find((l) => l.licenseTypeId === LicenseTypes.RetailStateVaporLicense && l.isActive);

        const stateResponse = await this.stateDelineationService.getByShortCode(this.customer.businessAddress.state);
        if (!stateResponse) { return; }
        const state = stateResponse.values;

        const licensesResponse = await this.stateLicenseDelineationService.getByShortCode(state.shortCode);
        if (!licensesResponse) { return; }
        const licenses = licensesResponse.values;

        this.stateName = state.name;
        this.swisherlicense = licenses?.find((l) => l.subsidiaryId === Subsidiary.Swisher)?.number ?? "";
        this.easlicense = licenses?.find((l) => l.subsidiaryId === Subsidiary.EAS)?.number ?? "";
    }

    async getPrintLogo(url: string): Promise<void> {
        const imageData = await fetch(url);
        const blob = await imageData.blob();

        const reader = new FileReader();
        reader.onloadend = () => {
            this.printLogo = reader.result as string;
            return;
        }
        reader.readAsDataURL(blob);
    }
}
