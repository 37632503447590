<div #myFilter>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Store Types</mat-label>
            <mat-select #storeTypesSelect
                [(value)]="viewmodel.selectedCustomerTypes"
                (openedChange)="onOpenedStoreTypesChange()"
                (selectionChange)="onSelectionStoreTypesChange()"
                multiple>
                <mat-option *ngFor="let type of viewmodel.customerTypes" [value]="type">
                    {{ type.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>MSA</mat-label>
            <mat-select #msaSelect
                [(value)]="viewmodel.selectedMsa"
                (openedChange)="onOpenedSelectedMsaChange()">
                <mat-option [value]="viewmodel.all"> All
                </mat-option>
                <mat-option [value]="viewmodel.yes"> Yes </mat-option>
                <mat-option [value]="viewmodel.no"> No </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Px3 Ranks</mat-label>
            <mat-select #px3RankSelect
                [(value)]="viewmodel.selectedPx3Ranks"
                (openedChange)="onOpenedSelectedPx3Change()"
                (selectionChange)="onSelectionPx3RanksChange()"
                multiple>
                <mat-option *ngFor="let rank of viewmodel.px3Ranks" [value]="rank">
                    {{ rank.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
