import { Injectable } from "@angular/core";
import { GenericResponseDto, WholesalerGroupEntryDto, WholesalerGroupsListBatchParamsDto } from "shield.shared";
import { WholesalerGroupEntry } from "src/app/entity-models/wholesaler-group-entry.entity";
import { DatabaseService } from "../database.service";
import { id } from "@swimlane/ngx-charts";

@Injectable()
export class WholesalerGroupOfflineService {

    constructor(private dbService: DatabaseService) { }

    private readonly notAvailableInOfflineModeError = "Transactions are not available in offline mode.";

    async getBatch(params: WholesalerGroupsListBatchParamsDto): Promise<WholesalerGroupEntry[]> {

        throw Error(this.notAvailableInOfflineModeError);
    }

    async getEntryByWholesalerId(wholesalerId: string)
        : Promise<GenericResponseDto<WholesalerGroupEntryDto | null>>
    {
        const groupMember = await this.dbService.wholesalerGroupMembers
            .where("wholesalerId")
            .equals(wholesalerId)
            .first();

        if (!groupMember) {
            return null;
        }

        const customer = await this.dbService.customers.get(wholesalerId);

        const group = await this.dbService.wholesalerGroups.get(groupMember.wholesalerGroupId);

        const groupEntry = {
            id: group.id,
            groupId: group.externalSyncId,
            groupName: group.name,
            method: group.orderType,
            numberOfMembers: 0,
            numberOfProducts: 0,
            groupMembers: [{
                id: groupMember.id,
                wholesalerId: wholesalerId,
                name: customer.name,
                customerNumber: customer.customerNumber,
                address: customer.businessAddress.address1,
                city: customer.businessAddress.city,
                state: customer.businessAddress.state,
                zip: customer.businessAddress.zip,
                numberOfProducts: 0,
            }],
        };

        const returnDto = new GenericResponseDto<WholesalerGroupEntryDto | null>();
        returnDto.values = groupEntry;
        return returnDto;


    }

}
