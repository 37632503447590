<div class="wrapper d-flex flex-grow-1">
    <mat-card class="d-flex flex-grow-1 p-0">
        <app-stepper #stepper class="d-flex flex-grow-1">
            <cdk-step label="Before Picture" class="d-flex flex-column flex-grow-1">
                <app-before-picture class="d-flex flex-column flex-grow-1"></app-before-picture>
            </cdk-step>
            <cdk-step label="Activities &amp; Surveys" class="d-flex flex-column flex-grow-1">
                <app-activities-surveys
                    [isReadOnly]="false"
                    [isPanel]="false"
                    [call]="callService.call"
                    class="d-flex flex-grow-1"></app-activities-surveys>
            </cdk-step>
            <cdk-step label="In Distro" class="d-flex flex-column flex-grow-1">
                <app-in-distro class="d-flex flex-column flex-grow-1"></app-in-distro>
            </cdk-step>
            <cdk-step label="Sales/Gratis" class="d-flex flex-column flex-grow-1">
                <app-sales-gratis class="d-flex flex-column flex-grow-1"></app-sales-gratis>
            </cdk-step>
            <cdk-step label="Exchange" class="d-flex flex-column flex-grow-1">
                <app-exchange class="d-flex flex-column flex-grow-1"></app-exchange>
            </cdk-step>
            <cdk-step label="Receipts" class="d-flex flex-column flex-grow-1">
                <app-receipts #receipts class="d-flex flex-column flex-grow-1"></app-receipts>
            </cdk-step>
            <cdk-step label="After Pictures" class="d-flex flex-column flex-grow-1">
                <app-after-pictures class="d-flex flex-column flex-grow-1"></app-after-pictures>
            </cdk-step>
            <cdk-step label="Closing Notes" class="d-flex flex-column flex-grow-1">
                <app-closing-notes #closingNotes (autoPrintRetailReceipts)="autoPrintRetailReceipts()"
                                   (autoPrintWholesaleReceipts)="autoPrintWholesaleReceipts()"
                                   class="d-flex flex-column flex-grow-1"></app-closing-notes>
            </cdk-step>
        </app-stepper>
    </mat-card>
</div>
