<div class="inner-card">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th scope="col">{{ viewmodel.text }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let response of viewmodel.responses">
                <td class="text-left">
                    {{ response.text }}
                </td>
            </tr>
        </tbody>
    </table>
</div>
