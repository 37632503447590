import { Injectable } from "@angular/core";
import { GenericResponseDto } from "shield.shared";
import { StateLicense } from "src/app/entity-models/state-license.entity";
import { DatabaseService } from "../database.service";
import { StateLicenseOfflineService } from "../offline-services/state-license-offline.service";
import { StateLicenseOnlineService } from "../online-services/state-license-onlne.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class StateLicenseDelineationService extends DelineationContext<StateLicense, string> {

    constructor(private stateLicenseOfflineService: StateLicenseOfflineService
        , private stateLicenseOnlineService: StateLicenseOnlineService
        , snackbarService: SnackbarService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService){
            super(dbService, datasourceDelineationService, snackbarService);
        }

    async getByShortCode(shortCode: string): Promise<GenericResponseDto<StateLicense[]>> {

        const offline = (key: string) => {
            return this.stateLicenseOfflineService.getByShortCode(key);
        }
        const online = (key: string) => {
            return this.stateLicenseOnlineService.getByShortCode(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, StateLicense[]>(shortCode, offline, online);

        if (response) {
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
        }
        return response;
    }
}
