<div #originalEasRetailReceipt *ngIf="easCashRetailViewmodels?.length > 0
    || easGratisRetailViewmodels?.length > 0
    || easExchangeOutRetailViewmodels?.length > 0
    || easExchangeInRetailViewmodels?.length > 0">
    <div class="row">
        <div class="col center image-margin-top">
            <img *ngIf="printLogo" [src]="printLogo" alt="Eas Print Logo" />
        </div>
    </div>
    <div class="row">
        <div class="col center"><b>E-Alternative Solutions fulfillment for E-Alternative Solutions</b></div>
    </div>
    <div class="row">
        <div class="col center">
            {{swisherAddress}}
        </div>
    </div>
    <div class="row">
        <div class="col center">{{ timeNow | date: dateTimeFormat }}</div>
        <div *ngIf="employee" class="col center">
            {{salesRepDisplayName}}
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="center">EAS State License # {{ easlicense }}</div>
        </div>
    </div>
    <hr />
    <div>
        <div class="row">
            <div class="col center">
                {{ customerReceiptAddress.addressName }} - Receipt #{{easReceiptNumber}}
            </div>
        </div>
        <div *ngIf="retailStateVaporLicense" class="row">
            <div class="col center">
                Retail State Vapor License # {{ retailStateVaporLicense?.licenseNumber }}
            </div>
        </div>
        <div class="row">
            <div class="col center">Retail State OTP License # {{retailStateOptLicense?.licenseNumber}}</div>
        </div>
        <div class="row">
            <div class="col center">
                {{ customerReceiptAddress.addressFormatted }}
            </div>
        </div>
        <div *ngIf="customer.businessAddress?.county" class="row">
            <div class="col center">{{ customer.businessAddress?.county }}&nbsp;County</div>
        </div>
        <hr />
        <div *ngIf="easCashRetailViewmodels?.length > 0">
            <div class="row margin-right">
                <div class="col-5">
                    <b>Cash Sales Item</b>
                </div>
                <div class="col-1">
                    <b>Qty</b>
                </div>
                <div class="col-2">
                    <b>Price</b>
                </div>
                <div class="col-2">
                    <b>Discount</b>
                </div>
                <div class="col-2">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let cashProduct of easCashRetailViewmodels">
                <div class="col-5">
                    {{ cashProduct.product?.description }}
                </div>
                <div class="col-1">
                    {{ cashProduct.quantity }}
                </div>
                <div class="col-2">
                    {{ cashProduct.price | currency }}
                </div>
                <div class="col-2">
                    {{ cashProduct.discount | currency }}
                </div>
                <div class="col-2">
                    {{ cashProduct.total | currency }}
                </div>
            </div>
            <div class="row margin-right">
                <div class="col-5">
                    Taxes
                </div>
                <div class="col-1">
                    1
                </div>
                <div class="col-2">
                    {{ easTaxableCashTotal | precisionCurrency }}
                </div>
                <div class="col-2">
                    {{ 0 | currency }}
                </div>
                <div class="col-2">
                    {{ easTaxableCashTotal | precisionCurrency }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col total-display">
                    <b>Total: {{ stepperCallApplicationService.easCashDueNowTotal | currency }}</b>
                </div>
            </div>
            <div class="row">
                <div class="col larger total-display">
                    <b><u>Cash Due Now: {{ stepperCallApplicationService.easCashDueNowTotal | currency }}</u></b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="easGratisRetailViewmodels?.length > 0">
            <div class="row margin-right">
                <div class="col-5">
                    <b>Promotional Goods</b>
                </div>
                <div class="col-2">
                    <b>Qty</b>
                </div>
                <div class="col-2">
                    <b>Price</b>
                </div>
                <div class="col-3">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let gratisProduct of easGratisRetailViewmodels">
                <div class="col-5">
                    {{ gratisProduct.product?.description }}
                </div>
                <div class="col-2">
                    {{ gratisProduct.quantity }}
                </div>
                <div class="col-2">
                    {{ gratisProduct.value | currency }}
                </div>
                <div class="col-3">
                    {{ gratisProduct.total | currency }}
                </div>
            </div>
            <div class="row margin-right">
                <div class="col-5">
                    Taxes
                </div>
                <div class="col-2">
                    1
                </div>
                <div class="col-2">
                    {{ easTaxableGratisTotal | precisionCurrency }}
                </div>
                <div class="col-3">
                    {{ easTaxableGratisTotal | precisionCurrency }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col total-display">
                    <b>Promotional Goods Total:
                        {{ stepperCallApplicationService.easGratisDueNowTotal | currency }}</b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="easExchangeOutRetailViewmodels?.length > 0">
            <div class="row margin-right">
                <div class="col-5">
                    <b>Exchange Out Item</b>
                </div>
                <div class="col-2">
                    <b>Qty</b>
                </div>
                <div class="col-2">
                    <b>Price</b>
                </div>
                <div class="col-3">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let outProduct of easExchangeOutRetailViewmodels">
                <div class="col-5">
                    {{ outProduct.product?.description }}
                </div>
                <div class="col-2">
                    {{ outProduct.quantity }}
                </div>
                <div class="col-2">
                    {{ outProduct.price | currency }}
                </div>
                <div class="col-3">
                    {{ outProduct.total | currency }}
                </div>
            </div>
            <div class="row margin-right">
                <div class="col-5">
                    Taxes
                </div>
                <div class="col-2">
                    1
                </div>
                <div class="col-2">
                    {{ easTaxableExchangeOutTotal * -1 | precisionCurrency }}
                </div>
                <div class="col-3">
                    {{ easTaxableExchangeOutTotal * -1 | precisionCurrency }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col total-display">
                    <b>Exchange Out Total:
                        {{ stepperCallApplicationService.easExchangeOutTotal | currency }}</b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="easExchangeInRetailViewmodels?.length > 0">
            <div class="row margin-right">
                <div class="col-5">
                    <b>Exchange In Item</b>
                </div>
                <div class="col-2">
                    <b>Qty</b>
                </div>
                <div class="col-2">
                    <b>Price</b>
                </div>
                <div class="col-3">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let inProduct of easExchangeInRetailViewmodels">
                <div class="col-5">
                    {{ inProduct.product?.description }}
                </div>
                <div class="col-2">
                    {{ inProduct.quantity }}
                </div>
                <div class="col-2">
                    {{ inProduct.price | currency }}
                </div>
                <div class="col-3">
                    {{ inProduct.total | currency }}
                </div>
            </div>
            <div class="row margin-right">
                <div class="col-5">
                    Taxes
                </div>
                <div class="col-2">
                    1
                </div>
                <div class="col-2">
                    {{ easTaxableExchangeInTotal | precisionCurrency }}
                </div>
                <div class="col-3">
                    {{ easTaxableExchangeInTotal | precisionCurrency }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col total-display">
                    <b>Exchange In Total: {{ stepperCallApplicationService.easExchangeInTotal
                        |
                        currency }}</b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="easRetailTaxableReceiptViewmodel?.length > 0">
            <div class="row margin-right">
                <div class="col-4">
                    <b>Taxes Paid</b>
                </div>
                <div class="col-2">
                    <b>State</b>
                </div>
                <div class="col-2">
                    <b>County</b>
                </div>
                <div class="col-2">
                    <b>City</b>
                </div>
                <div class="col-2">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let tax of easRetailTaxableReceiptViewmodel">
                <div class="col-4">
                    {{ tax.productDescription }}
                </div>
                <div class="col-2">
                    {{ tax.stateTax | precisionCurrency }}
                </div>
                <div class="col-2">
                    {{ tax.countyTax | precisionCurrency }}
                </div>
                <div class="col-2">
                    {{ tax.cityTax | precisionCurrency }}
                </div>
                <div class="col-2">
                    {{ tax.total | precisionCurrency }}
                </div>
            </div>
            <hr />
            <div class="row margin-right">
                <div class="col-4">
                    <b>Total Tax</b>
                </div>
                <div class="col-2">
                    <b>{{easStateTaxTotal | precisionCurrency}}</b>
                </div>
                <div class="col-2">
                    <b>{{ easCountyTaxTotal | precisionCurrency }}</b>
                </div>
                <div class="col-2">
                    <b>{{ easCityTaxTotal | precisionCurrency }}</b>
                </div>
                <div class="col-2">
                    <b>{{ easTaxTotal | currency }}</b>
                </div>
            </div>
            <br />
        </div>
    </div>
    <div class="row">
        <div class="col">
            Salesrep: ({{signatureDisplayName}})
        </div>
    </div>
    <div class="row">
        <div class="col larger">
            <b>Customer Representative:</b>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col">
            <img *ngIf="signature" [src]="signature" alt="Signature" [width]="signatureRetailWidth"
                [height]="signatureRetailHeight" />
            <hr class="no-top-margin" />
        </div>
    </div>
    <div class="row">
        <div class="col">Signature ({{contactDisplayName}})</div>
        <br />
    </div>
    <div class="row">
        <div class="col center">
            All {{ stateName }} state cigarette and/or tobacco product taxes
        </div>
    </div>
    <div class="row">
        <div class="col center">
            are included in the total amount of this invoice
        </div>
    </div>
    <div class="row">
        <div class="col center">
            Falsification of this form is grounds for immediate dismissal
        </div>
    </div>
    <div class="row">
        <div class="col center">Receipt #{{swisherReceiptNumber}}</div>
    </div>
    <hr />
</div>
