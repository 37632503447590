import { Product } from "src/app/entity-models/product.entity";
export class AddProductViewModel {
    product: Product;
    inGroup: boolean;
    selected: boolean;

    constructor(product: Product) {
        this.product = product;
    }
}
