import { Subscription } from "rxjs";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { FilterSaveDialogComponent } from "src/app/dialogs/filter-save-dialog/filter-save-dialog.component";
import { FilterSaveViewmodel } from "src/app/dialogs/filter-save-dialog/filter-save.viewmodel";
import { Employee } from "src/app/entity-models/employee.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { UserFilter } from "src/app/entity-models/user-filter.entity";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { SpecialCoverageInformationDialogComponent } from "./special-coverage-information-dialog/special-coverage-information-dialog.component";
import { SpecialCoverageInformationDialogViewModel } from "./special-coverage-information-dialog/special-coverage-information-dialog.viewmodel";

export class MySearchesViewmodel {

    employee: Employee;
    isDefaultFilterSelection = true;
    myListLoading: boolean = false;
    myListSelected: boolean = false;
    newEntriesSubscription: Subscription;
    originalRefinerList: Refiner[] = [];
    selectedFilter = "";
    specialCoverageSelected: boolean = false;
    userFilter: UserFilter;

    readonly headerNames = ["Retail Number", "Customer Number"];

    confirmationOverlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;
    filterOverLayRef: SwisherOverlayRef<
        FilterSaveViewmodel,
        FilterSaveDialogComponent
    >;
    specialCoverageInfoOverlayRef: SwisherOverlayRef<
        SpecialCoverageInformationDialogViewModel,
        SpecialCoverageInformationDialogComponent
    >;
}
