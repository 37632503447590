import { Product } from "src/app/entity-models/product.entity";
import { AddProductViewModel } from "./add-product.viewmodel";
import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";

export class AddProductsViewmodel extends BasicDialogViewModel {
    headerLeftText = "Add Products";
    buttonLeftText = "Close";
    buttonLeftFunction: () => void;
    buttonLeftDisabledFunction: () => boolean;
    width: "73vw";
    height = "74vh";
    blocking = true;
    hideItemNumber = false;
    hidePacksize = false;

    products: Map<string, AddProductViewModel> = new Map<
        string,
        AddProductViewModel
    >();

    constructor(public addOnly: boolean = false) {
        super();
    }


    result: AddProductViewModel[] = [];

    buildViewmodelProductsFromProductDomainModel(
        products: Map<string, Product>,
        selectedProductIds: string[]
    ): void {
        products.forEach((product) => {
            this.products.set(
                product.id,
                new AddProductViewModel(product)
            );
        }
        );
        for (const productId of selectedProductIds) {
            if (this.products.has(productId)) {
                const product = this.products.get(productId);
                if (!this.addOnly) {
                    product.selected = true;
                }
                product.inGroup = true;
            }
        }
    }
}
