export class WholesalerGroup {
    id: string;
    name: string;
    externalSyncId: string;
    customerId: string;
    orderGroupName: string | null;
    orderType: string | null;
    createdUtcDateTime: Date;
    createdUserId: string;
    createdUserZrt: string;
    createdUserFullName: string;
    modifiedUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    rowversion: string;
}
