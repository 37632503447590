import { FilterSortDto } from "./filter-sort.dto";

export class FilterRequestV2Dto<T, K> {
    id: string;
    filters: T;
    pageSize: number | undefined;
    startIndex: number;
    filterSorts: FilterSortDto<K>[];

    validate() {
        if (!this.pageSize) {
            this.pageSize = undefined;
        }

        if (!this.startIndex) {
            this.startIndex = 0;
        }

        if (!this.filterSorts) {
            this.filterSorts = [];
        }
    }

}
