import { ElementRef } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { Day } from "src/app/enums/day";
import { FilterService } from "src/app/services/filter.service";
export class CustomerDateInformationViewmodel {
    filterService: FilterService;
    formBuilder: FormBuilder;

    callDateForm: FormGroup;

    endDateDefault: moment.Moment = moment();
    endDateInput: ElementRef;
    endMaxDate: moment.Moment = moment();
    endMinDate: moment.Moment = moment().subtract(3, "years");

    startDateDefault: moment.Moment = moment().subtract(7, "days");
    startDateInput: ElementRef;
    startMaxDate: moment.Moment = moment();

    availability = new Array<string>();
    selectedAvailability = new Array<string>();
    isAllAvailabilityPresent: boolean;

    constructor(
        filterService: FilterService,
        formBuilder: FormBuilder
    ) {
        this.filterService = filterService;
        this.formBuilder = formBuilder;
        this.callDateForm = this.formBuilder.group({
            callEndDate: ["", null],
            callStartDate: ["", null]
        });

        this.initializeAvailabilityDropDown();
    }

    async initializeAvailabilityDropDown(): Promise<void> {
        this.availability.push("None");
        this.availability.push("All");
        this.availability.push(Day.sunday);
        this.availability.push(Day.monday);
        this.availability.push(Day.tuesday);
        this.availability.push(Day.wednesday);
        this.availability.push(Day.thursday);
        this.availability.push(Day.friday);
        this.availability.push(Day.saturday);
    }
}
