<div class="card card--projects m-0">
    <div
        class="card-header"
    >
        Projects
    </div>
    <ng-container *ngIf="!projects?.length">
        <div class="d-flex justify-content-center align-items-center card-body p-0">
                <div class="customer-overview-tiles-empty-state">
                    <div class="empty-state-header">
                        <div class="icon">
                            <span class="material-icons"
                            >assignment_turned_in</span
                            >
                        </div>
                        <h6>There are currently no active projects.</h6>
                        <button appThrottleClick *ngIf="isOnline && isNotTm" class="btn btn-white btn-sm" (throttledClick)="onOpenNewProject.emit()">
                            Add Project
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="card-body p-0" *ngIf="projects?.length">
            <div class="card-view-port">
                <table class="table table-striped table-hover mb-0">
                    <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Start</th>
                        <th scope="col">End</th>
                        <th scope="col">View</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let project of projects">
                        <td>{{ project.project?.name }}</td>
                        <td>{{ project.startDate }}</td>
                        <td>{{ project.endDate }}</td>
                        <td>
                            <div class="d-flex">
                                <a class="single-icon-on-white mr-1" appThrottleClick (throttledClick)="onOpenViewProject.emit(project.project)">
                                    <span class="material-icons">visibility</span>
                                </a>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
    </div>
    <ng-container *ngIf="isOnline && projects?.length && isNotTm">
        <hr/>
        <div>
            <button appThrottleClick
                class="btn sw-red-btn btn-sm bottom-margin card-bottom"
                (throttledClick)="onOpenNewProject.emit()"
            >
                Add New
            </button>
        </div>
    </ng-container>
</div>
