import { Component, Input } from "@angular/core";
import { faEye, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { Customer } from "src/app/entity-models/customer.entity";
import { OverlayService } from "src/app/services/overlay.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { CallHistoryAccountProfileViewModel } from "../account-profile.viewmodels/call-history-account-profile.viewModel";
import { CustomerCallHistoryBaseComponent } from "../customer-call-history-base/customer-call-history-base.component";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { SystemInformationDelineationService } from "src/app/services/delineation-services/system-information-delineation.service";
import { PingService } from "src/app/services/ping.service";

@Component({
    selector: "app-customer-call-history",
    templateUrl: "./customer-call-history.component.html",
    styleUrls: ["./customer-call-history.component.scss"]
})
export class CustomerCallHistoryComponent extends CustomerCallHistoryBaseComponent {
    faEye: IconDefinition = faEye;

    @Input() openClosingNotes: (vm: CallHistoryAccountProfileViewModel) => void;

    @Input()
    get customer(): Customer {
        return this._customer;
    }
    set customer(value: Customer) {
        if (value?.id !== this._customer?.id) {
            if (value) {

                this._customer = value;
                this.callHistorySearch();
            }
        }
    }

    dateFormat = MY_DATE_FORMATS.display.customDateInput;

    callHistoryAccountProfileViewModels: CallHistoryAccountProfileViewModel[];

    constructor(
        overlayService: OverlayService,
        systemInformationDelineationService: SystemInformationDelineationService,
        callDelineationService: CallDelineationService,
        pingService: PingService
    ) {
        super(overlayService,
            systemInformationDelineationService,
            callDelineationService,
            pingService
            )
    }

    async callHistorySearch(): Promise<void> {
        if (this.customer) {
            const calls = await this.callHistoryBaseSearch();

            const viewModels: CallHistoryAccountProfileViewModel[] = [];
            calls.forEach((call) => {
                const viewModel: CallHistoryAccountProfileViewModel = new CallHistoryAccountProfileViewModel();
                viewModel.buildViewModleFromModel(call);
                viewModels.push(viewModel);
            });

            this.callHistoryAccountProfileViewModels = viewModels;
        }
    }
}
