import { GenericLookup } from "../generic";

export enum ContractPaymentTypes {
    NA = 0,
    Normal = 1,
    Bonus = 2,
}

export const ContractPaymentTypeLookup: GenericLookup<ContractPaymentTypes>[] = [
    { id: ContractPaymentTypes.NA, name: "NA", description: "Not Applicable" },
    { id: ContractPaymentTypes.Normal, name: "Normal", description: "Normal" },
    { id: ContractPaymentTypes.Bonus, name: "Bonus", description: "Bonus" }
  ];
