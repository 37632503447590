import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { Project } from "src/app/entity-models/project.entity";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { PingService } from "src/app/services/ping.service";
import { RelatedProjectViewmodel } from "./related-projects.viewmodel";
import { ViewRetailProjectComponent } from "./view-retail-project/view-retail-project.component";
import { ViewRetailProjectViewModel } from "./view-retail-project/view-retail-project.viewmodel";

@Component({
    selector: "app-related-projects",
    templateUrl: "./related-projects.component.html",
    styleUrls: ["./related-projects.component.scss"]
})
export class RelatedProjectsComponent implements OnInit, OnDestroy {
    private _projects: RelatedProjectViewmodel[];
    projectOverlayRef: SwisherOverlayRef<
        ViewRetailProjectViewModel,
        ViewRetailProjectComponent
    >;

    @Input()
    get projects(): RelatedProjectViewmodel[] {
        return this._projects;
    }
    set projects(value: RelatedProjectViewmodel[]) {
        this._projects = value;
    }

    @Input() isRetail: boolean;
    @Input() isChainHq: boolean;
    @Input() isNotTm: boolean;

    isOnlineSubscription: Subscription;
    isOnline = false;

    @Output() onOpenNewProject = new EventEmitter();
    @Output() onOpenViewProject = new EventEmitter<Project>();

    constructor(public pingService: PingService) {}

    ngOnInit(): void {
        if (!this.isOnlineSubscription || this.isOnlineSubscription.closed) {
            this.isOnlineSubscription = this.pingService.online.subscribe((isOnline) => {
                this.isOnline = isOnline;
            })
        }
    }

    ngOnDestroy(): void {
        if (this.isOnlineSubscription && !this.isOnlineSubscription.closed) {
            this.isOnlineSubscription.unsubscribe();
        }
    }

}
