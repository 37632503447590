import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import {
    FilterRequestV2Dto,
    FilterSortDto,
    GenericDropDownDto,
    GenericResponseDto,
    HasWholesalserProductMappingsParamsDto,
    newSequentialId,
    ProductDistributionBatchParamsDto,
    ProductDistributionColumns,
    SharedHelper
} from "shield.shared";
import { ProductViewmodel } from "src/app/accounts/call-master/stepper-call/distribution-grid/product.viewmodel";
import { Customer } from "src/app/entity-models/customer.entity";
import { ProductDistributionEntry } from "src/app/entity-models/product-distribution-entry.entity";
import { Product } from "src/app/entity-models/product.entity";
import { ProjectProduct } from "src/app/entity-models/project-product.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { WholesalerGroupProductCatalogItem } from "src/app/entity-models/wholesaler-group-product-catalog-item.entity";
import { DatabaseService } from "../database.service";
import { ProductDistributionFilterMapService } from "../filter-map-services/product-distribution-filter-map.service";
import { ProductOfflineService } from "../offline-services/product-offline.service";
import { ProductOnlineService } from "../online-services/product-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";
import * as moment from "moment";
@Injectable()
export class ProductDelineationService extends DelineationContext<Product, string> {
    constructor(
        private productOfflineService: ProductOfflineService,
        private productOnlineService: ProductOnlineService,
        snackbarService: SnackbarService,
        protected datasourceDelineationService: DatasourceDelineationService,
        protected dbService: DatabaseService
    ) {
        super(dbService, datasourceDelineationService, snackbarService);
        void this.getAll();
    }

    //AllProducts
    private _allProducts = new Map<string, Product>();
    private _allProductsSubject: BehaviorSubject<
        Map<string, Product>
    > = new BehaviorSubject(this._allProducts);
    get allProducts(): Map<string, Product> {
        return this._allProducts;
    }
    observableAllProducts: Observable<
        Map<string, Product>
    > = this._allProductsSubject.asObservable();

    //Active Products
    private _activeProducts = new Map<string, Product>();
    private _activeProductsSubject: BehaviorSubject<
        Map<string, Product>
    > = new BehaviorSubject(this._activeProducts);
    get activeProducts(): Map<string, Product> {
        return this._activeProducts;
    }
    observableActiveProducts: Observable<
        Map<string, Product>
    > = this._activeProductsSubject.asObservable();

    async getAll(): Promise<GenericResponseDto<Product[] | undefined>> {
        const offline = () => {
            return this.productOfflineService.getAll();
        };
        const online = () => {
            return this.productOnlineService.getAll();
        };
        const response = await this.datasourceDelineationService.makeCall<
            undefined,
            Product[]
        >(undefined, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        this._allProducts = new Map(
            response.values.filter((p) => !p.isDeleted).map((p) => [p.id, p])
        );
        this._allProductsSubject.next(this._allProducts);

        this._activeProducts = new Map(
            response.values
                .filter((p) => !p.isDeactivated && !p.isDeleted)
                .map((p) => [p.id, p])
        );
        this._activeProductsSubject.next(this._activeProducts);

        return response;
    }

    async getDropDown(): Promise<
        GenericResponseDto<GenericDropDownDto[] | undefined>
    > {
        const offline = () => {
            return this.productOfflineService.getDropDown();
        };
        const online = () => {
            return this.productOnlineService.getDropDown();
        };
        const response = await this.datasourceDelineationService.makeCall<
            undefined,
            GenericDropDownDto[]
        >(undefined, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getProductByProductId(id: string): Promise<Product> {
        if ((this.allProducts?.size ?? 0) === 0) {
            await this.getAll();
        }
        return this.allProducts.get(id);
    }

    async getByIds(ids: string[]): Promise<GenericResponseDto<Product[]>> {
        const offline = (keys: string[]) => {
            return this.productOfflineService.getByIds(keys);
        };
        const online = (keys: string[]) => {
            return this.productOnlineService.getByIds(keys);
        };
        const response = await this.datasourceDelineationService.makeCall<
            string[],
            Product[]
        >(ids, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getProductViewmodelsForWholesalerProjectProducts(
        projectProducts: ProjectProduct[]
    ): Promise<GenericResponseDto<ProductViewmodel[]>> {
        let viewmodelArray = new Array<ProductViewmodel>();

        projectProducts = projectProducts.filter((pp) => !!pp.wholesalerId);

        const projectProductMap = new Map(
            projectProducts.map((pp) => [
                pp.productId,
                pp
            ]));
        const productCatalogItems = await this.dbService.wholesalerProductCatalogItems
            .where("wholesalerId")
            .anyOf([... new Set(projectProducts.map((pp) => pp.wholesalerId))])
            .and((pci) => projectProductMap.has(pci.productId) && !pci.isDeactivated)
            .toArray();
        if (productCatalogItems?.length) {
            const groupCatalogItems = await this.dbService.wholesalerGroupProductCatalogItems
                .where("id")
                .anyOf([... new Set(productCatalogItems.map((item) => item.wholesalerGroupProductCatalogItemId))])
                .and(gci => !gci.isDeactivated)
                .toArray();
            const groupCatalogItemsMap = new Map(
                groupCatalogItems.map((item) => [item.id, item])
            );
            const productCatalogItemsMap = new Map(productCatalogItems.map(i => [i.wholesalerId + i.productId, i]));

            const productResponse = await this.getByIds([...new Set(projectProducts.map((pp) => pp.productId))]);
            if (productResponse) {
                const productMap = new Map(productResponse.values.map((product) => [product.id, product]));
                for (const projectProduct of projectProducts) {
                    const product = productMap.get(projectProduct.productId);
                    if (!product) {
                        continue;
                    }
                    const vm = Object.assign(
                        {},
                        product
                    ) as ProductViewmodel;
                    const groupCatalogItem = groupCatalogItemsMap.get(projectProduct.wholesalerGroupProductId);
                    const productCatalogItem = productCatalogItemsMap.get(projectProduct.wholesalerId + projectProduct.productId);
                    if (groupCatalogItem) {
                        vm.uin = groupCatalogItem.productUIN;
                        vm.wholesalerGroupProductId = groupCatalogItem.id;
                        const dateAvailable: Date = productCatalogItem.dateAvailable ?? groupCatalogItem.dateAvailable;
                        if (dateAvailable && moment(dateAvailable).isAfter(moment())) {
                            vm.dateAvailable = dateAvailable;
                        }
                    }
                    vm.wholesalerId = projectProduct.wholesalerId;
                    viewmodelArray.push(vm);
                }
            }
        }

        const rtn = new GenericResponseDto<ProductViewmodel[]>();
        rtn.id = newSequentialId();
        rtn.values = viewmodelArray;

        return rtn;
    }

    async getWholesalerGroupProductCatalogItemsByIds(
        ids: string[]
    ): Promise<GenericResponseDto<WholesalerGroupProductCatalogItem[]>> {
        const offline = (keys: string[]) => {
            return this.productOfflineService.getWholesalerGroupProductCatalogItemsByIds(
                keys
            );
        };
        const online = (keys: string[]) => {
            return this.productOnlineService.getWholesalerGroupProductCatalogItemsByIds(
                keys
            );
        };
        const response = await this.datasourceDelineationService.makeCall<
            string[],
            WholesalerGroupProductCatalogItem[]
        >(ids, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getWholesalerProducts(
        wholesaler: Customer
    ): Promise<GenericResponseDto<ProductViewmodel[]>> {
        let wholesalerProducts = new Array<ProductViewmodel>();

        const productCatalogItems = await this.dbService.wholesalerProductCatalogItems
            .where("wholesalerId")
            .equals(wholesaler.id)
            .and(pci => !pci.isDeactivated)
            .toArray();

        if (productCatalogItems?.length) {
            const groupCatalogItems = await this.dbService.wholesalerGroupProductCatalogItems
                .where("id")
                .anyOf([... new Set(productCatalogItems.map((item) => item.wholesalerGroupProductCatalogItemId))])
                .and((item) => !item.isDeactivated)
                .toArray();
            const groupCatalogItemsMap = new Map(
                groupCatalogItems.map((wgpci) => [
                    wgpci.id,
                    wgpci
                ])
            );

            const productsIds = [...new Set(productCatalogItems.map((item) => item.productId))];
            const productsResponse = await this.getByIds(productsIds);
            if (productsResponse) {
                const products = productsResponse.values.filter(
                    (v) => !v.isDeactivated && !v.isDeleted
                );
                const productMap = new Map(products.map((p) => [p.id, p]));
                if (products) {
                    for (const productCatalogItem of productCatalogItems) {
                        const groupCatalogItem = groupCatalogItemsMap.get(
                            productCatalogItem.wholesalerGroupProductCatalogItemId
                        );
                        const product = productMap.get(productCatalogItem.productId);
                        if (product && groupCatalogItem) {
                            const vm = product as ProductViewmodel;
                            const wholesalerGroupProductCatalogItem = groupCatalogItemsMap.get(
                                productCatalogItem.wholesalerGroupProductCatalogItemId
                            );
                            if (wholesalerGroupProductCatalogItem) {
                                vm.uin = wholesalerGroupProductCatalogItem.productUIN;
                                vm.wholesalerGroupProductId =
                                    wholesalerGroupProductCatalogItem.id;
                                    if (wholesalerGroupProductCatalogItem.dateAvailable > new Date()) {
                                        vm.dateAvailable = wholesalerGroupProductCatalogItem.dateAvailable;
                                        vm.dateAvailableFormatted = moment(wholesalerGroupProductCatalogItem.dateAvailable).format('MM/DD/yyyy');
                                    }
                            }
                            vm.wholesalerId = wholesaler.id;
                            vm.wholesaler = wholesaler;
                            const dateAvailable = productCatalogItem.dateAvailable ?? groupCatalogItem.dateAvailable;
                            if (dateAvailable && moment(dateAvailable).isAfter(moment())) {
                                vm.dateAvailable = dateAvailable;
                            }
                            wholesalerProducts.push(vm);
                        }
                    }
                }
            }
        }
        const rtn = new GenericResponseDto<ProductViewmodel[]>();
        rtn.id = newSequentialId();
        rtn.values = wholesalerProducts;

        return rtn;
    }

    async hasProductWholesalerMappings(
        customer: Customer,
        product: Product
    ): Promise<GenericResponseDto<boolean>> {
        let rtn = new GenericResponseDto<boolean>();
        rtn.values = false;

        const params = new HasWholesalserProductMappingsParamsDto();
        params.wholesalerIds = customer.customerWholesalers.map(
            (cw) => cw.wholesalerId
        );
        params.productId = product.id;

        if (params.wholesalerIds?.length) {
            const offline = (keys: HasWholesalserProductMappingsParamsDto) => {
                return this.productOfflineService.hasProductWholesalerMappings(
                    keys
                );
            };
            const online = (keys: HasWholesalserProductMappingsParamsDto) => {
                return this.productOnlineService.hasProductWholesalerMappings(
                    keys
                );
            };

            const response = await this.datasourceDelineationService.makeCall<
                HasWholesalserProductMappingsParamsDto,
                boolean
            >(params, offline, online);
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
            rtn = response;
        }

        return rtn;
    }

    async getWholesalerMappedProduct(
        wholesalerIds: string[]
    ): Promise<GenericResponseDto<string[]>> {
        let rtn = new GenericResponseDto<string[]>();

        if (wholesalerIds?.length) {
            const offline = (keys: string[]) => {
                return this.productOfflineService.getWholesalerMappedProduct(
                    keys
                );
            };
            const online = (keys: string[]) => {
                return this.productOfflineService.getWholesalerMappedProduct(
                    keys
                );
            };

            const response = await this.datasourceDelineationService.makeCall<
                string[],
                string[]
            >(wholesalerIds, offline, online);
            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }
            rtn = response;
        }

        return rtn;
    }

    async getDistributionBatch(
        id: string,
        refiners: Refiner[],
        pageSize: number,
        startIndex: number,
        filterSorts: FilterSortDto<ProductDistributionColumns>[]
    ): Promise<GenericResponseDto<ProductDistributionEntry[]>> {
        const key = new ProductDistributionBatchParamsDto();
        key.filterRequestDto = new FilterRequestV2Dto();
        key.filterRequestDto.id = id;
        key.filterRequestDto.filters = ProductDistributionFilterMapService.mapFilterData(
            refiners
        );
        key.filterRequestDto.pageSize = pageSize;
        key.filterRequestDto.startIndex = startIndex;
        key.filterRequestDto.filterSorts = filterSorts;

        const offline = (key: ProductDistributionBatchParamsDto) => {
            return this.productOfflineService.getDistributionBatch(key);
        };
        const online = (key: ProductDistributionBatchParamsDto) => {
            return this.productOnlineService.getDistributionBatch(key);
        };
        const response = await this.datasourceDelineationService.makeCall<
            ProductDistributionBatchParamsDto,
            ProductDistributionEntry[]
        >(key, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }
}
