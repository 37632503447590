import { ZipReassignmentCustomerTypeDto } from "./zip-reassignment-customer-type.dto";


export class ZipReassignmentDto {
    id: string;
    subsidiaryId: number;
    newZrt: string;
    zip: string;
    county: string | null;
    state: string | null;
    originalZrt: string;
    scheduledDateTime: string;
    scheduledCustomerCount: number;
    completedDateTime: string | null;
    completedCustomerCount: number | null;
    createdUtcDateTime: string | null;
    createdUserId: string | null;
    createdUserZrt: string | null;
    createdUserFullName: string | null;
    modifiedUtcDateTime: string | null;
    modifiedUserId: string | null;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string | null;

    zipReassignmentCustomerTypes: ZipReassignmentCustomerTypeDto[] | null;
}
