import { Component, OnInit, HostListener } from "@angular/core";
import { Employee } from "src/app/entity-models/employee.entity";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ShareOptionsDialogViewmodel } from "./share-options-dialog.viewmodel";
import { ContactComponent } from "src/app/accounts/contact/contact.component";
import { ContactViewModel } from "src/app/accounts/contact/contact.viewmodel";
import { OverlayService } from "src/app/services/overlay.service";
import { ContactDelineationService } from "src/app/services/delineation-services/contact-delineation.service";
import { BehaviorSubject } from "rxjs";
import { Contact } from "src/app/entity-models/contact.entity";

@Component({
    selector: "app-share-options-dialog",
    templateUrl: "./share-options-dialog.component.html",
    styleUrls: ["./share-options-dialog.component.css"]
})
export class ShareOptionsDialogComponent
    extends ShareOptionsDialogViewmodel
implements OnInit {
    
    contactOptions: Contact[] = [];
    contactOverlayRef: SwisherOverlayRef<ContactViewModel, ContactComponent>;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    selectedContact: Contact;
    showCustomerContact: boolean = false;

    constructor(
        private ref: SwisherOverlayRef<ShareOptionsDialogViewmodel, ShareOptionsDialogComponent>,
        private overlayService: OverlayService,
        private contactDelineationService: ContactDelineationService
    ) {
        super();

        ref.overlay.backdropClick().subscribe(() => {
            this.close();
        });
    }

    ngOnInit(): void {
        this.comments = this.ref.data.comments;
        this.confirmButtonText = this.ref.data.confirmButtonText;
        this.shareEmployees = this.ref.data.shareEmployees;
        this.customerId = this.ref.data.customerId;
        this.showCustomerContact = this.customerId !== null && this.customerId !== undefined;
        
        if (this.showCustomerContact) {
            this.setContactOptions();
        }
    }

    saveShare(): void {
        this.ref.data.comments = this.comments;
        this.ref.data.promise = this.ref.data.onSaveShare(this);
        this.ref.close(this.ref.data);
    }

    close(): void {
        this.ref.data.onClose(this);
        this.ref.close(this.ref.data);
    }

    @HostListener('window:keyup', ['$event'])
        onKeyUp(event: KeyboardEvent): void {
            if (event.key === 'Enter' && this.ref) {
                this.saveShare()
            }
        }

    onAddContact(): void {
        const data: ContactViewModel = new ContactViewModel();
        data.customerId = this.customerId;
        data.headerLeftText = "Send Original Receipt - Add Contact";

        this.contactOverlayRef = this.overlayService.open(
            ContactComponent,
            data,
            true
        );

        this.contactOverlayRef.afterClosed$.subscribe((rtnData) => {
            if (rtnData) {
                void this.setContactOptions();
            }
        });
    }

    async setContactOptions(): Promise<void> {
        const response = await this.contactDelineationService.getByCustomerId(
            this.customerId
        );

        if (!response) {
            this.shouldWait$.next(false);
            return;
        }

        this.contactOptions = response.values?.filter((c) => !c.isDeleted);
    }

    async onSelectedContact(): Promise<void> {
        this.shareCustomerContact = this.selectedContact;
    }

    compareContactOptions(a: Contact, b: Contact): boolean {
        return a?.id === b?.id;
    }
}
