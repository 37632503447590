export class WholesalerGroupEntryMemberDto {
    id: string;
    wholesalerId: string;
    name: string;
    customerNumber: string;
    address: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    numberOfProducts: number;
}
