import { FilterRequestV2Dto, GratisListColumns, GratisListFilterDto, RefinerGroupDto } from "../filter";


export class GratisBatchParamsDto {

    filterRequestDto: FilterRequestV2Dto<GratisListFilterDto, GratisListColumns>;
    refinerGroups: RefinerGroupDto[];
    zrt: string;
}

export class GratisRejectApprovalParamsDto {

    gratisId: string;
    reason: string;
}
