<div #myFilter>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Project Type</mat-label>
            <mat-select #projectTypesSelect
                [(value)]="viewmodel.selectedProjectTypes"
                (openedChange)="onOpenedProjectTypesChange()"
                (selectionChange)="onSelectionProjectTypesChange()"
                multiple>
                <mat-option *ngFor="let type of viewmodel.projectTypes" [value]="type">
                    {{ type.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Project Status</mat-label>
            <mat-select #projectStatusesSelect
                [(value)]="viewmodel.selectedProjectStatuses"
                (openedChange)="onOpenedProjectStatusesChange()"
                (selectionChange)="onSelectionProjectStatusesChange()"
                multiple>
                <mat-option *ngFor="let status of viewmodel.projectStatuses" [value]="status">
                    {{ status.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
