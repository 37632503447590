import { Injectable } from "@angular/core";
import {
    DayTimeEntrySyncCommand,
    newSequentialId,
    TimeEntryParamsDto,
    TimeEntrySyncCommand } from "shield.shared";
import { Call } from "src/app/accounts/call-master/call-services/call.service";
import { Customer } from "src/app/entity-models/customer.entity";
import { DayTimeEntry } from "src/app/entity-models/day-time-entry.entity";
import { TimeEntryType } from "src/app/entity-models/time-entry-type.entity";
import { TimeEntry } from "src/app/entity-models/time-entry.entity";
import { DexieTableNames } from "src/app/enums/dexie-table-names";
import { DataSyncQueueService } from "src/app/sync/data-sync-queue.service";
import { DatabaseService } from "../database.service";
import { DatasourceDelineationService } from "../delineation-services/datasource-delineation.service";
import { DelineationContext } from "../delineation-services/delineation-context.service";

@Injectable()
export class TimeEntryOfflineService extends DelineationContext<TimeEntry, string> {

    constructor(dbService: DatabaseService, datasourceDelineationService: DatasourceDelineationService, private dataSyncQueueService: DataSyncQueueService) {
        super(dbService, datasourceDelineationService)
    }

    async getById(id: string): Promise<TimeEntry> {
        return await this.dbService.timeEntries
            .where("id")
            .equals(id)
            .first();
    }

    async getUnprocessedById(id: string): Promise<TimeEntry> {
        return await this.dbService.timeEntries
            .where("id")
            .equals(id)
            .and(v => !v.hasServerProcessed)
            .first();
    }

    async getByEmployeeIdAndDate(params: TimeEntryParamsDto): Promise<TimeEntry[]> {
        const startDate = new Date();
        startDate.setFullYear(params.year);
        startDate.setMonth(params.month);
        startDate.setDate(1);
        startDate.setHours(0,0,0,0);

        const endDate = new Date();
        endDate.setFullYear(params.year);
        endDate.setMonth(params.month + 1);
        endDate.setDate(1)
        endDate.setHours(0,0,0,0);

        const timeEntries = await this.dbService.timeEntries
            .where("employeeId")
            .equals(params.employeeId)
            .toArray();
        return timeEntries.filter(v => v.start.getTime() >= startDate.getTime() && v.start.getTime() < endDate.getTime());
    }

    async getUnprocessedByEmployeeIdAndDate(params: TimeEntryParamsDto): Promise<TimeEntry[]> {
        const startDate = new Date();
        startDate.setFullYear(params.year);
        startDate.setMonth(params.month);
        startDate.setDate(1);
        startDate.setHours(0,0,0,0);

        const endDate = new Date();
        endDate.setFullYear(params.year);
        endDate.setMonth(params.month + 1);
        endDate.setDate(1)
        endDate.setHours(0,0,0,0);

        const timeEntries = await this.dbService.timeEntries
            .where("employeeId")
            .equals(params.employeeId)
            .filter(v => !v.hasServerProcessed)
            .toArray();
        return timeEntries.filter(v => v.start.getTime() >= startDate.getTime() && v.end.getTime() < endDate.getTime());
    }

    async getDayTimeEntriesByEmployeeIdAndDate(params: TimeEntryParamsDto): Promise<DayTimeEntry[]> {
        const startDate = new Date();
        startDate.setFullYear(params.year);
        startDate.setMonth(params.month);
        startDate.setDate(1);
        startDate.setHours(0,0,0,0);

        const endDate = new Date();
        endDate.setFullYear(params.year);
        endDate.setMonth(params.month + 1);
        endDate.setDate(1)
        endDate.setHours(0,0,0,0);

        return await this.dbService.dayTimeEntries
            .where("[employeeId+date]").between([params.employeeId, startDate], [params.employeeId, endDate])
            .toArray();
    }

    async getTimeEntryTypes(): Promise<TimeEntryType[]> {
        return await this.dbService.timeEntryTypes
            .orderBy("name")
            .toArray();
    }

    async upsert(entry: TimeEntry): Promise<undefined> {
        if (entry) {
            entry.hasServerProcessed = 0;
            await this.dbService.transaction(
                "rw",
                this.dbService.timeEntries,
                this.dbService.syncQueue,
                async () => {
                    await this.dbService.timeEntries.put(entry);
                    await this.dataSyncQueueService.enqueue(
                        new TimeEntrySyncCommand(entry.id)
                    );
                }
            );
        }
        return;
    }

    async upsertArray(entries: TimeEntry[]): Promise<undefined> {
        if (entries) {
            entries.forEach((entry) => entry.hasServerProcessed = 0);
            await this.dbService.transaction(
                "rw",
                this.dbService.timeEntries,
                this.dbService.syncQueue,
                async () => {
                    await this.dbService.timeEntries.bulkPut(entries);
                    for (const entry of entries) {
                        await this.dataSyncQueueService.enqueue(
                            new TimeEntrySyncCommand(entry.id)
                        );
                    }
                }
            );
        }
        return;
    }

    async upsertDayTimeEntry(entry: DayTimeEntry): Promise<undefined> {
        if (entry) {
            await this.dbService.transaction(
                "rw",
                this.dbService.dayTimeEntries,
                this.dbService.syncQueue,
                async () => {
                    await this.dbService.dayTimeEntries.put(entry);
                    await this.dataSyncQueueService.enqueue(
                        new DayTimeEntrySyncCommand(entry.id)
                    );
                }
            );
        }
        return;
    }

    async saveCallTimeEntry(params: CallTimeEntryParams): Promise<undefined> {
        const log: TimeEntry = new TimeEntry();
        log.id = newSequentialId();
        log.sourceId = params.call.id;
        log.start = new Date(params.call.startTime);
        log.start.setSeconds(0, 0);
        const startOnStop =
            log.start.getFullYear() === params.call.stopTime.getFullYear() &&
            log.start.getMonth() === params.call.stopTime.getMonth() &&
            log.start.getDate() === params.call.stopTime.getDate();

        log.end = new Date(params.call.stopTime);
        log.end.setSeconds(0, 0);
        log.end.setTime(log.end.getTime() + 1000 * 60); //round up to the next minute.
        if (!startOnStop) {
            log.end = log.start;
        }
        log.type = params.type;
        log.name = params.customer.name;
        log.comments = params.call.closingNotes;
        log.createdDate = new Date();
        log.createdBy = params.call.createdUserId;
        log.updatedDate = log.createdDate;
        log.updatedBy = log.createdBy;
        log.employeeId = params.call.createdUserId;
        log.hasServerProcessed = 0;

        await this.persist(log, DexieTableNames.timeEntries);
        await this.dataSyncQueueService.enqueue(
            new TimeEntrySyncCommand(log.id)
        );

        return;
    }
}

export class CallTimeEntryParams {
    call: Call;
    customer: Customer;
    type: TimeEntryType;
}
