import { Injectable } from "@angular/core";
import { DailySummaryBatchParamsDto } from "shield.shared";
import { DailySummaryEntry } from "src/app/entity-models/daily-summary-entry.entity";

@Injectable()
export class DailySummaryEntryOfflineService {

    async getDailySummaryBatch(key: DailySummaryBatchParamsDto): Promise<DailySummaryEntry[]> {
        throw Error("Cannot get daily summary report offline.");
    }
}
