import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    AccountOwnershipDto,
    AccountOwnershipSyncCommand,
    GenericVersionResponseDto,
    SharedHelper,
    SyncCommandTypes,
    SystemInformationKeys,
    VersionResponseDto,
} from "shield.shared";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { AccountOwnershipConverterService } from "src/app/services/converter-services/account-ownership-converter.service";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { SyncLevel } from "../sync-enums/sync-level.enum";

@Injectable()
export class AccountOwnershipSyncDataHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.Initial;
    offlineRepSyncType = SyncLevel.Initial;

    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.accountownership;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing account ownership...");

        await this.pullData();

        this.log("Done syncing account ownership...");
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/account-ownership/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("Account ownership data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Account ownership data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.accountOwnershipPullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;
        try {
            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;

                const response = await this.http
                    .get<GenericVersionResponseDto<AccountOwnershipDto[]>>(`/api/account-ownership${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                this.log(
                    `Downloaded ${thisBatchSize} account ownerships, saving to IndexedDB...`
                );

                const aos = response.values.map((v) => {
                    const rtn = AccountOwnershipConverterService.accountOwnershipDtoToAccountOwnership(v);
                    rtn.hasServerProcessed = 1;
                    return rtn;
                });

                await this.dbService.accountOwnerships.bulkPut(aos);
                this.log(`  Saved  ${aos.length} account ownerships.`);

                this.lastSyncVersion = response.maxVersion;

            } while (thisBatchSize > 0);

            this.log("Done saving account ownerships data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing account ownerships", e);
        }
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<AccountOwnershipSyncCommand>(
            SyncCommandTypes.accountOwnership,
            async (message) => {
                let accountOwnership = await this.dbService.accountOwnerships.get(
                    message.payload.id
                );

                if (accountOwnership) {
                    let dto = AccountOwnershipConverterService.accountOwnershipToAccountOwnershipDto(accountOwnership);
                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }

}
