import { GenericLookup } from "../generic";

export enum GratisStatuses {

    NotSubmitted = 0,
    Submitted = 1,
    AwaitingRegionalManager = 2,
    AwaitingZoneManager = 3,
    AwaitingCustomerService = 4,
    Complete = 5,
    NotComplete = 6,
    Rejected = 7,
    Deleted = 8
}

export const GratisStatusLookup: GenericLookup<GratisStatuses>[] = [

    { id: GratisStatuses.NotSubmitted, name: "NotSubmitted", description: "Not Submitted" },
    { id: GratisStatuses.Submitted, name: "Submitted", description: "Submitted" },
    { id: GratisStatuses.AwaitingRegionalManager, name: "AwaitingRegionalManager", description: "Awaiting Regional Manager" },
    { id: GratisStatuses.AwaitingZoneManager, name: "AwaitingZoneManager", description: "Awaiting Zone Manager" },
    { id: GratisStatuses.AwaitingCustomerService, name: "AwaitingCustomerService", description: "Awaiting Customer Service" },
    { id: GratisStatuses.Complete, name: "Complete", description: "Complete" },
    { id: GratisStatuses.NotComplete, name: "NotComplete", description: "Not Complete" },
    { id: GratisStatuses.Rejected, name: "Rejected", description: "Rejected" },
    { id: GratisStatuses.Deleted, name: "Deleted", description: "Deleted" }
];
