<div #wholesaleDraftReceipt>
    <app-swisher-wholesaler-format-draft
        [relayedCustomer]="customer"
        [wholesalers]="wholesalers"
        [relayedEmployee]="employee"
        (swisherWholesalerFormatDraftBuilt)="swisherWholesalerFormatDraftBuilt()">
    </app-swisher-wholesaler-format-draft>
    <app-eas-wholesaler-format-draft *ngIf="isEasEmployee"
        [relayedCustomer]="customer"
        [wholesalers]="wholesalers"
        [relayedEmployee]="employee"
        (easWholesalerFormatDraftBuilt)="easWholesalerFormatDraftBuilt()">
    </app-eas-wholesaler-format-draft>
    <app-eas-wholesaler-swisher-rep-format-draft *ngIf="!isEasEmployee"
        [relayedCustomer]="customer"
        [wholesalers]="wholesalers"
        [relayedEmployee]="employee"
        (easWholesalerFormatDraftBuilt)="easWholesalerFormatDraftBuilt()">
    </app-eas-wholesaler-swisher-rep-format-draft>
</div>
