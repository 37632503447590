import {
    AfterContentChecked,
    Component,
    Type,
    ViewChild
} from "@angular/core";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import {
    GenericDropDownDto,
    RefinerLocation,
    valueSeparator
} from "shield.shared";
import { CustomerActivitiesViewmodel } from "./customer-activities.viewmodel";
import { Customer } from "src/app/entity-models/customer.entity";
import { ActivitiesFilterService } from "../../../services/activities-filter.service";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { AngularMultiSelect } from "angular2-multiselect-dropdown";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: "app-customer-activities-filter",
    templateUrl: "./customer-activities-filter.component.html",
    styleUrls: ["./customer-activities-filter.component.scss"]
})
export class CustomerActivitiesFilterComponent extends FilterBaseComponent implements AfterContentChecked {
    //view childs
    @ViewChild("chainSelect") chainSelect: AngularMultiSelect;
    @ViewChild("projectSelect") projectSelect: AngularMultiSelect;
    @ViewChild("wholesalerSelect") wholesalerSelect: AngularMultiSelect;
    @ViewChild("productSelect") productSelect: AngularMultiSelect;

    //public vars
    contentComponent: Type<CustomerActivitiesFilterComponent>;
    icon = "check_circle";
    name = "Activities";
    viewmodel = new CustomerActivitiesViewmodel(this.activitiesFilterService);
    isProjectsInitialized = false;
    isChainsInitialized = false;
    isWholesalersInitialized = false;
    isProductsInitialized = false;

    constructor(private activitiesFilterService: ActivitiesFilterService) {
        super();
    }


    ngAfterContentChecked(): void {
        if (!this.isInitialized && this.chainSelect && this.projectSelect && this.wholesalerSelect && this.productSelect) {

            if (
                !this.viewmodel.chainsSubscription ||
                this.viewmodel.chainsSubscription.closed
            ) {
                this.viewmodel.chainsSubscription = this.activitiesFilterService.observableChains.pipe(untilDestroyed(this)).subscribe(
                    (chains) => {
                        if (chains && this.activitiesFilterService.chainsLoaded) {
                            this.chainSelect.settings.noDataLabel = "No chains found.";
                            let partialChains = chains.map((chain) => {
                                chain.commonOperatingName ??= chain.name;
                                chain.searchKey = `${chain.commonOperatingName} ${chain.name}`;
                                return chain;
                            });
                            this.isChainsInitialized = true;

                            partialChains = [...new Map(partialChains.map((c) => [c.ownerCode, c])).values()];
                            this.viewmodel.chains = partialChains;

                            const selected = new Array<AccountOwnership>();
                            for (const chain of this.viewmodel.selectedChains) {
                                if (partialChains.map((c) => c.ownerCode).includes(chain.ownerCode)) {
                                    selected.push(chain);
                                }
                            }
                            this.viewmodel.selectedChains = selected;
                            if (!this.isInitialized
                                && this.isProjectsInitialized
                                && this.isChainsInitialized
                                && this.isWholesalersInitialized
                                && this.isProductsInitialized) {
                                this.setInitialized();
                            }
                        }
                    }
                );
            }
            if (
                !this.viewmodel.projectsSubscription ||
                this.viewmodel.projectsSubscription.closed
            ) {
                this.viewmodel.projectsSubscription = this.activitiesFilterService.observableProjects.pipe(untilDestroyed(this)).subscribe(
                    (projects) => {
                        if (projects && this.activitiesFilterService.projectsLoaded) {
                            this.projectSelect.settings.noDataLabel = "No projects found.";
                            this.viewmodel.projects = projects;
                            this.isProjectsInitialized = true;

                            const selected = new Array<GenericDropDownDto>();
                            for (const project of this.viewmodel.selectedProjects) {
                                if (projects.map(p => p.id).includes(project.id)) {
                                    selected.push(project);
                                }
                            }
                            this.viewmodel.selectedProjects = selected;
                            if (!this.isInitialized
                                && this.isProjectsInitialized
                                && this.isChainsInitialized
                                && this.isWholesalersInitialized
                                && this.isProductsInitialized) {
                                this.setInitialized();
                            }
                        }
                    }
                );
            }
            if (
                !this.viewmodel.wholesalersSubscription ||
                this.viewmodel.wholesalersSubscription.closed
            ) {
                this.viewmodel.wholesalersSubscription = this.activitiesFilterService.observableWholesalers.pipe(untilDestroyed(this)).subscribe(
                    (wholesalers) => {
                        if (wholesalers && this.activitiesFilterService.wholesalersLoaded) {
                            this.wholesalerSelect.settings.noDataLabel = "No wholesalers found.";
                            this.viewmodel.wholesalers = wholesalers;
                            this.isWholesalersInitialized = true;

                            const selected = new Array<Customer>();
                            for (const wholesaler of this.viewmodel.selectedWholesalers) {
                                if (wholesalers.map((w) => w.id).includes(wholesaler.id)) {
                                    selected.push(wholesalers.find(w => w.id === wholesaler.id));
                                }
                            }
                            this.viewmodel.selectedWholesalers = selected;
                            if (!this.isInitialized
                                && this.isProjectsInitialized
                                && this.isChainsInitialized
                                && this.isWholesalersInitialized
                                && this.isProductsInitialized) {
                                this.setInitialized();
                            }
                        }
                    }
                );
            }

            if (
                !this.viewmodel.productsSubscription ||
                this.viewmodel.productsSubscription.closed
            ) {
                this.viewmodel.productsSubscription = this.activitiesFilterService.observableProducts.pipe(untilDestroyed(this)).subscribe(
                    (products) => {
                        if (products && this.activitiesFilterService.productsLoaded) {
                            this.productSelect.settings.noDataLabel = "No products found.";
                            this.viewmodel.products = products;
                            this.isProductsInitialized = true;

                            const selected = new Array<GenericDropDownDto>();
                            for (const product of this.viewmodel.selectedProducts) {
                                if (products.map((w) => w.id).includes(product.id)) {
                                    selected.push(products.find(p => p.id === product.id));
                                }
                            }
                            this.viewmodel.selectedProducts = selected;
                            if (!this.isInitialized
                                && this.isProjectsInitialized
                                && this.isChainsInitialized
                                && this.isWholesalersInitialized
                                && this.isProductsInitialized) {
                                this.setInitialized();
                            }
                        }
                    }
                );
            }
        }
    }

    //events
    openedProjectSelect() {
        this.viewmodel.isProjectsStale = true;
        this.viewmodel.selectedProjectsHold = this.viewmodel.selectedProjects;
    }

    closedProjectSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.projects;
        refiner.value = this.viewmodel.selectedProjects?.length < 2
            ? this.viewmodel.selectedProjects
                .map((project) => project.name)
                .join(", ")
            : this.viewmodel.selectedProjects?.length + " Selected";
        refiner.dataPropertyName = "name"; // TODO: Determine property for local filter.
        refiner.dataValue = this.viewmodel.selectedProjects
            .map((project) => project.id)
            .join(valueSeparator);

        if (this.viewmodel.selectedProjectsHold === this.viewmodel.selectedProjects) {
            this.viewmodel.isProjectsStale = false;
        }

        this._refinerService.checkAndUpdateRefiner(refiner);
    }

    openedWholesalerSelect() {
        this.viewmodel.isWholesalersStale = true;
        this.viewmodel.selectedWholesalersHold = this.viewmodel.selectedWholesalers;
    }

    closedWholesalerSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.wholesalers;
        refiner.value = this.viewmodel.selectedWholesalers?.length < 2
            ? this.viewmodel.selectedWholesalers
                .map((wholesaler) => wholesaler.name)
                .join(", ")
            : this.viewmodel.selectedWholesalers?.length + " Selected";
        refiner.dataPropertyName = "customerWholesalers";
        refiner.dataValue = this.viewmodel.selectedWholesalers
            .map((wholesaler) => wholesaler.id)
            .join(valueSeparator);

        if (this.viewmodel.selectedWholesalersHold === this.viewmodel.selectedWholesalers) {
            this.viewmodel.isWholesalersStale = false;
        }

        this._refinerService.checkAndUpdateRefiner(refiner);
    }

    openedChainSelect() {
        this.viewmodel.isChainsStale = true;
        this.viewmodel.selectedChainsHold = this.viewmodel.selectedChains;
    }

    closedChainSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.chains;
        refiner.value = this.viewmodel.selectedChains?.length < 2
            ? this.viewmodel.selectedChains
                .map((chain) => chain.name)
                .join(", ")
            : this.viewmodel.selectedChains?.length + " Selected";
        refiner.dataPropertyName = "ownerCode";
        refiner.dataValue = this.activitiesFilterService.getChainDataValues(this.viewmodel.selectedChains, true);

        if (this.viewmodel.selectedChainsHold === this.viewmodel.selectedChains) {
            this.viewmodel.isChainsStale = false;
        }

        this._refinerService.checkAndUpdateRefiner(refiner);
    }

    openedProductSelect() {
        this.viewmodel.isProductsStale = true;
        this.viewmodel.selectedProductsHold = this.viewmodel.selectedProducts;
    }

    closedProductSelect() {
        const refiner = new Refiner();
        refiner.location = RefinerLocation.products;
        refiner.value = this.viewmodel.selectedProducts?.length < 2
            ? this.viewmodel.selectedProducts
                .map((product) => product.displayValue)
                .join(", ")
            : this.viewmodel.selectedProducts?.length + " Selected";
        refiner.value = this.viewmodel.selectedProducts?.length
            ? refiner.value.concat(this.viewmodel.notInDist ? " (Not In Dist)" : " (In Dist)")
            : "";
        refiner.dataPropertyName = "id";
        refiner.dataValue = this.viewmodel.selectedProducts
            .map((product) => product.id)
            .join(valueSeparator);

        if (this.viewmodel.selectedProductsHold === this.viewmodel.selectedProducts) {
            this.viewmodel.isProductsStale = false;
        }

        this._refinerService.checkAndUpdateRefiner(refiner);
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.chains:
                    const selectedChainCodes = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedChains = new Array<AccountOwnership>();

                    if (selectedChainCodes) {
                        for (const code of selectedChainCodes) {
                            const found = this.viewmodel.chains.find(
                                (c) => c.ownerCode?.trim() === code.trim()
                            );
                            if (found) {
                                rtnSelectedChains.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedChains = rtnSelectedChains;
                    break;
                case RefinerLocation.projects:
                    const selectedProjectIds = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedProjects = new Array<GenericDropDownDto>();

                    if (selectedProjectIds) {
                        for (const projectId of selectedProjectIds) {
                            const found = this.viewmodel.projects.find(
                                (p) => p.id === projectId.trim()
                            );
                            if (found) {
                                rtnSelectedProjects.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedProjects = rtnSelectedProjects;
                    break;
                case RefinerLocation.wholesalers:
                    const selectedWholesalerIds = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedWholesalers = new Array<Customer>();

                    if (selectedWholesalerIds) {
                        for (const wholesalerId of selectedWholesalerIds) {
                            const found = this.viewmodel.wholesalers.find(
                                (w) => w.id === wholesalerId.trim()
                            );
                            if (found) {
                                rtnSelectedWholesalers.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedWholesalers = rtnSelectedWholesalers;
                    break;
                case RefinerLocation.products:
                    const selectedProductIds = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedProducts = new Array<GenericDropDownDto>();

                    if (selectedProductIds) {
                        for (const productId of selectedProductIds) {
                            const found = this.viewmodel.products.find(
                                (p) => p.id === productId.trim()
                            );
                            if (found) {
                                rtnSelectedProducts.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedProducts = rtnSelectedProducts;
                    if (refiner.value?.includes("(In Dist)")) {
                        this.viewmodel.notInDist = false;
                    } else {
                        this.viewmodel.notInDist = true;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.chains;
        const chainsRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.chains
        );
        if (chainsRefiner) {
            this.onInputChange(chainsRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.projects;
        const projectsRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.projects
        );
        if (projectsRefiner) {
            this.onInputChange(projectsRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.wholesalers;
        const wholesalersRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.wholesalers
        );
        if (wholesalersRefiner) {
            this.onInputChange(wholesalersRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.products;
        const productsRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.products
        );
        if (productsRefiner) {
            this.onInputChange(productsRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }
}
