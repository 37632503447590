import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { CallService } from 'src/app/accounts/call-master/call-services/call.service';
import { StateDelineationService } from 'src/app/services/delineation-services/state-delineation.service';
import { StateLicenseDelineationService } from 'src/app/services/delineation-services/state-license-delineation.service';
import { StepperCallApplicationService } from '../../../stepper-call-services/stepper-call-application.service';
import { WholesalerFormatBaseComponent } from '../wholesaler-format-base/wholesaler-format-base.component';

@Component({
    selector: 'app-eas-wholesaler-format',
    templateUrl: './eas-wholesaler-format.component.html',
    styleUrls: ['./eas-wholesaler-format.component.scss']
})
export class EasWholesalerFormatComponent extends WholesalerFormatBaseComponent {

    @ViewChildren("originalEasWholesaleReceipt") originalEasWholesaleReceipt: QueryList<ElementRef>;

    imageLocation = "/assets/img/easPrintLogo.jpg";

    constructor(callService: CallService,
        stepperCallApplicationService: StepperCallApplicationService,
        stateLicenseDelineationService: StateLicenseDelineationService,
        stateDelineationService: StateDelineationService) {
        super(callService,
            stepperCallApplicationService,
            stateLicenseDelineationService,
            stateDelineationService);

        const basePath = window.location.origin;
        this.imageLocation = basePath + this.imageLocation;

        this.getPrintLogo(this.imageLocation);
    }
}
