import { Injectable } from "@angular/core";
import { Survey } from "src/app/entity-models/survey.entity";
import { DatabaseService } from "../database.service";

@Injectable()
export class SurveyOfflineService {

    constructor(private dbService: DatabaseService){}

    async getById(id: string): Promise<Survey> {

        return await this.dbService.surveys.where("id")
            .equals(id).first();
    }

    async getByIds(ids: string[]): Promise<Survey[]> {

        return await this.dbService.surveys.bulkGet(ids);
    }
}
