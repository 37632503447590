export const MY_DATE_FORMATS = {
    parse: {
        dateInput: "MM/DD/YYYY"
    },
    display: {
        dateInput: "M/D/YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY",
        customDateInput: "M/d/yy",
        customDateTimeInput: "M/d/yy hh:mm a",
        customMomentDateTime: "M/D/yy hh:mm a",
        customTime: "hh:mm a",
        customWeekDateInput: "E - M/d/YY",
        monthDayYearInput: "MMMM D, YYYY"
    }
};
