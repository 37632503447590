import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Customer } from "src/app/entity-models/customer.entity";
import { OrderDelineationService } from "src/app/services/delineation-services/order-delineation.service";

@Injectable()
export class OrderExtractionWholesalerService {
    private _selectedWholesaler: Customer = null;
    selectedWholesalerSubject: BehaviorSubject<Customer> = new BehaviorSubject(
        this._selectedWholesaler
    );
    get selectedWholesaler(): Customer {
        return this._selectedWholesaler;
    }
    set selectedWholesaler(value: Customer) {
        this._selectedWholesaler = value;
        this.selectedWholesalerSubject.next(value);
    }
    observableSelectedWholesaler: Observable<Customer> = this.selectedWholesalerSubject.asObservable();

    private _wholesalers: Customer[] = [];
    wholesalersSubject: BehaviorSubject<Customer[]> = new BehaviorSubject(
        this._wholesalers
    );
    get wholesalers(): Customer[] {
        return this._wholesalers;
    }
    set wholesalers(value: Customer[]) {
        this._wholesalers = value;
        this.wholesalersSubject.next(value);
    }
    observableWholesalers: Observable<Customer[]> = this.wholesalersSubject.asObservable();

    constructor(private orderDelineationService: OrderDelineationService) {}

    async getWholesalers(): Promise<Customer[]> {
        const wholesalers = await this.orderDelineationService.getWholesalersWithOrders();
        this._wholesalers = wholesalers.values;
        this.wholesalersSubject.next(this._wholesalers);

        return this._wholesalers;
    }
}
