import { Product } from "./product.entity";
import { WholesalerProductCatalogItems } from "./wholesaler-product-catalog-items.entity";

export class WholesalerGroupProductCatalogItem {
    id: string;
    wholesalerGroupId: string;
    product: Product;
    productId: string;
    productUIN: string;
    department: string | null; //FOR FUTURE USE?
    dateAvailable?: Date;
    wholesalerCount: number;
    isDeactivated: boolean;
    isDeactivatedDate: Date | null;
    createdUtcDateTime: Date;
    createdUserId: string;
    createdUserZrt: string;
    createdUserFullName: string;
    modifiedUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    rowversion: string;
    //This must be a number so it can be indexed by IndexedDB.
    isAvailable: number;
    wholesalerItems: WholesalerProductCatalogItems[];
}
