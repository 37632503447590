<div class="card card-project-panel m-0 d-flex flex-column flex-grow-1">
    <div class="card-header">Stores</div>
    <div class="card-body un-scrollable p-0 d-flex flex-column flex-grow-1">
        <ng-container>
            <div class="row d-flex flex-column flex-grow-1">
                <div *ngIf="viewmodel.checkingStores || viewmodel.projectCustomers?.length !== 0"
                    class="col-12 table-responsive d-flex flex-column flex-grow-1">
                    <app-grid #grid
                        [(dataSource)]="viewmodel.dataSource"
                        [columnDefinitions]="viewmodel?.columnDef"
                        [selectedColumnVisability]="viewmodel?.selectedColumnVisibilityComunicator"
                        [isFixedLayout]="false" [height]="'30vh'"
                        [isEnabledExpandedDetails]="false"
                        [itemsRendedInViewPort]="6"
                        [detailHeight]="48"
                        class="d-flex flex-column flex-grow-1">
                    </app-grid>
                </div>
                <div *ngIf="!viewmodel.projectCustomers?.length" class="margin-auto">
                    <b class="missing-data-warning">Project will not be visible to field until stores have been added.</b>
                </div>
            </div>
        </ng-container>
    </div>
</div>
