import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    Px3IncentivePeriodDto,
    Px3IncentivePeriodSyncCommand,
    SyncCommandTypes
} from "shield.shared";
import { Px3IncentivePeriod } from "src/app/entity-models/px3-incentive-period.entity";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { SyncLevel } from "../sync-enums/sync-level.enum";
import { SyncHandler } from "./sync-handler";
@Injectable()
export class Px3IncentivePeriodSyncHandler
    extends SyncHandler<Px3IncentivePeriod, Px3IncentivePeriodDto>
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.Initial;
    offlineRepSyncType = SyncLevel.Initial;

    constructor(
        dbService: DatabaseService,
        http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super(
            "Px3 Incentive Periods",
            SyncCommandTypes.px3IncentivePeriod,
            SyncVersionKeyNames.px3IncentivePeriod,
            '/api/px3-incentive-periods',
            dbService.px3IncentivePeriods,
            http
        );
    }

    protected entityToDto(entity: Px3IncentivePeriod): Px3IncentivePeriodDto {
        return {
            id: entity.id,
            startDate: entity.startDate.toUTCString(),
            endDate: entity.endDate.toUTCString(),
        };
    }

    protected dtoToEntity(dto: Px3IncentivePeriodDto): Px3IncentivePeriod {
        return new Px3IncentivePeriod(dto.id, new Date(dto.startDate), new Date(dto.endDate));
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<Px3IncentivePeriodSyncCommand>(
            this.syncCommandType,
            async (message) => {
                let result = await this.table.get(
                    message.payload.id
                );

                let dto = this.entityToDto(result);
                if (dto) {
                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }
}
