import { RefinerLocation } from "shield.shared";

export class WholesalerGroupProductsViewmodel {
    readonly refinerLocations: RefinerLocation[] = [
        RefinerLocation.uin,
        RefinerLocation.productNumber,
        RefinerLocation.productDescription,
        RefinerLocation.productPrice
    ];
    descriptionInput: string;
    itemNumberInput: string;
    priceInput: number;
    priceOptions = { align: "left" };
    uinInput: string;

    constructor() {}
}
