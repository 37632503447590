<div class="d-flex flex-column flex-grow-1">
    <app-nav-menu *ngIf="isAuthenticated && initialSyncComplete"></app-nav-menu>
    <div role="main" class="d-flex flex-row flex-grow-1" style="height: 0; overflow-x: hidden; overflow-y: auto">
        <div class="main-container 2-column d-flex flex-column flex-grow-1">
            <div *ngIf="pingInitalized && isAuthenticated && initialSyncComplete" class="w-100 d-flex flex-column flex-grow-1">
                <router-outlet></router-outlet>
            </div>
            <div *ngIf="isAuthenticated && !initialSyncComplete" class="w-100 d-flex flex-column flex-grow-1">
                <app-sync-gate></app-sync-gate>
            </div>
        </div>
    </div>
</div>
