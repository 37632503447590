<div class="card card--call-history m-0">
    <div class="card-header">
        Call History
    </div>
    <ng-container *ngIf="!callHistoryAccountProfileViewModels?.length">
        <div class="d-flex justify-content-center align-items-center card-body p-0">
            <div class="customer-overview-tiles-empty-state">
                <div class="empty-state-header">
                    <div class="icon">
                        <span class="material-icons"
                        >phone_in_talk</span
                        >
                    </div>
                    <h6>No calls have been placed for this customer.</h6>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="card-body p-0" *ngIf="callHistoryAccountProfileViewModels?.length">
        <div class="card-view-port-scroll">
            <table class="table table-striped table-hover mb-0">
                <thead>
                    <tr>
                        <th scope="col">Comment</th>
                        <th scope="col">Call Date</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let vm of callHistoryAccountProfileViewModels">
                        <td>
                            {{vm.call.closingNotes}}
                        </td>
                        <td>{{vm.call.startTime | date: dateFormat}}</td>
                        <td class="text-right">
                            <a
                                appThrottleClick
                                (throttledClick)="openClosingNotes(vm)"
                                class="single-icon-on-white"
                            ><span
                                class="flaticon-eye-variant-with-enlarged-pupil"
                            ></span
                            ></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
