import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { CallService } from "../../call-services/call.service";
import { Picture } from "src/app/entity-models/picture.entity";
import { RetailStepperStep } from "src/app/enums/retail-stepper-step";
import { OverlayService } from "src/app/services/overlay.service";
import { MatCarouselComponent } from "@ngmodule/material-carousel";
import { PictureType } from "src/app/entity-models/call-picture.entity";
import { CallValidationService } from "../../../account-services/call-validation.service";
import { StepCamComponent } from "../../step-cam/step-cam.component";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { PictureDelineationService } from "src/app/services/delineation-services/picture-delineation.service";
import { StepperCallApplicationService } from "../stepper-call-services/stepper-call-application.service";
import { CallTypes } from "shield.shared";
import { RmWholesaleStepperStep } from "src/app/enums/rm-wholesale-stepper-step";
import { ValidationError } from "src/app/accounts/account-models/validationError";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";

@Component({
    selector: "app-after-pictures",
    templateUrl: "./after-pictures.component.html",
    styleUrls: ["./after-pictures.component.css"]
})
export class AfterPicturesComponent implements OnInit, OnDestroy {
    @ViewChild("carousel") carousel: MatCarouselComponent;
    //Private vars
    private observablepictureSubscription: Subscription;
    private observableSelectedIndexSubscription: Subscription;

    //Public vars
    afterPicture: Picture = null;
    slides: Picture[] = [];
    beforeSlide: Picture[] = [];
    afterImg = "";
    pictureType: PictureType = "After";
    proportion = 75;
    readonly stepperStepBefore: RetailStepperStep = RetailStepperStep.before;
    readonly stepperStepAfter: RetailStepperStep = RetailStepperStep.after;
    selectedIndex: RetailStepperStep | RmWholesaleStepperStep;
    validationErrorMessages: string[] = [];

    public constructor(
        private callService: CallService,
        private stepperCallApplicationService: StepperCallApplicationService,
        private overlayService: OverlayService,
        private pictureDelineationService: PictureDelineationService,
        private callValidationService: CallValidationService
    ) {}

    public ngOnInit(): void {
        if (
            !this.observablepictureSubscription ||
            this.observablepictureSubscription.closed
        ) {
            this.observablepictureSubscription = this.callService.observablePictures.subscribe(
                (pic) => {
                    if (pic
                        && ((this.callService.call?.callType === CallTypes.retail && this.selectedIndex === RetailStepperStep.after)
                            || this.callService.call?.callType === CallTypes.rmWholesale && this.selectedIndex === RmWholesaleStepperStep.after)) {
                        void this.setImage(pic);
                    }
                }
            );
        }

        if (
            !this.observableSelectedIndexSubscription ||
            this.observableSelectedIndexSubscription.closed
        ) {
            this.observableSelectedIndexSubscription = this.stepperCallApplicationService.observableSelectedIndex.subscribe(
                (selectedIndex) => {
                    this.selectedIndex = selectedIndex;
                    if ((this.callService.call?.callType === CallTypes.retail && this.selectedIndex === RetailStepperStep.after)
                        || (this.callService.call?.callType === CallTypes.rmWholesale && this.selectedIndex === RmWholesaleStepperStep.after)) {
                        void this.setImage();
                        this.runValidation();
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (
            this.observablepictureSubscription &&
            !this.observablepictureSubscription.closed
        ) {
            this.observablepictureSubscription.unsubscribe();
        }

        if (
            this.observableSelectedIndexSubscription &&
            !this.observableSelectedIndexSubscription.closed
        ) {
            this.observableSelectedIndexSubscription.unsubscribe();
        }
    }

    //events
    public onOpenModal(step: RetailStepperStep | RmWholesaleStepperStep): void {
        this.overlayService.open(StepCamComponent, step);
    }

    runValidation(): void {
        const skipFarthestIndex = true;
        let callValidation: ValidationError[];
        if (this.callService.call?.callType === CallTypes.retail) {

            callValidation = this.callValidationService.isCallStepValid(
                RetailStepperStep.after,
                skipFarthestIndex
            );
        } else if (this.callService.call?.callType === CallTypes.rmWholesale) {
            callValidation = this.callValidationService.isCallStepValid(
                RmWholesaleStepperStep.after,
                skipFarthestIndex
            );
        }


        this.validationErrorMessages = callValidation.map(cv => cv.message);
    }

    async setImage(newPictures?: Picture[]): Promise<void> {
        const call = this.callService.call as RetailCall | RmWholesaleCall;
        if ((this.stepperCallApplicationService.selectedIndex === RetailStepperStep.after
                && call?.callType === CallTypes.retail)
            || (this.stepperCallApplicationService.selectedIndex === RmWholesaleStepperStep.after
                && call?.callType === CallTypes.rmWholesale)
        ) {
            //get all other pictures
            const picsResponse = await this.pictureDelineationService.getLocalPicturesByIds(
                call.callPictures
                    ?.filter(
                        (picture) =>
                            picture.type !== "Before" &&
                            picture.type !== "After"
                    )
                    .map((pic) => pic.id)
            );

            if (!picsResponse) { return; }

            this.slides = picsResponse.values;

            // Slide Picture array input for the Before mat-carousel
            const beforeSlideResponse = await this.pictureDelineationService.getLocalPicturesByIds(
                call?.callPictures
                    ?.filter((picture) => picture.type === "Before")
                    .map((pic) => pic.id)
            );

            if (!beforeSlideResponse) { return; }

            this.beforeSlide = beforeSlideResponse.values;

            const afterPicResponse = await this.pictureDelineationService.getLocalPicturesByIds(
                call?.callPictures
                    ?.filter((picture) => picture.type === "After")
                    .map((pic) => pic.id)
            );

            if (!afterPicResponse) { return; }

            if (afterPicResponse.values?.length) {
                this.afterPicture = afterPicResponse.values[0];
            }

            // if we have an after picture make it index 0
            // this way the after pictures index alway matches
            // mat-carousel-slide for no picture
            if (this.afterPicture) {
                this.slides.unshift(this.afterPicture);
            }

            // If I am taking a picture from this view
            // and it is not the after picture
            // I don't want to be directed to the first index
            let scrollPosition = 0;
            if (newPictures?.length) {
                scrollPosition = this.slides.findIndex(
                    (slide) =>
                        slide.id === newPictures[newPictures.length - 1].id
                );

                // add mat-carousel-slide for no picture
                if (!this.afterPicture) scrollPosition++;
            }

            // Wait one cycle to allow any unshifting to happen
            setTimeout(() => {
                this.carousel.slideTo(scrollPosition > 0 ? scrollPosition : 0);
            }, 0);
        }
    }

    setPictureType(index: number): void {
        if (index) {
            const picture: Picture = this.slides[
                // the slide index is not the same as the
                // mat-carousel if we don't have the after picture
                // The mat-carousel is counting the mat-carousel-slide for no picture as index 0
                index - (!this.afterPicture ? 1 : 0)
            ];
            if (picture) {
                const callPicture = (this.callService.call as RetailCall | RmWholesaleCall).callPictures?.find(
                    (p) => p.id === picture.id
                );
                this.pictureType = callPicture.type;
            }
        } else {
            this.pictureType = "After";
        }
    }
}
