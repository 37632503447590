import { WholesalerViewmodel } from "../shared/viewmodels/wholesaler.viewmodel";
import { CallOrderDate } from "./call-order-date.entity";
import { Project } from "./project.entity";

export class CallOrderProduct {
    id: string; //UUID
    productId: string; //UUID
    quantity: number;
    units: number;
    wholesalerCustomerId: string;
    uin: string;
    upc: string;
    storeCount: number;
    selectedProject: Project | undefined;
    callOrderDates: CallOrderDate[];
}
