import { LicenseTypes } from "shield.shared";
import { Customer } from "src/app/entity-models/customer.entity";

export class retailProfileViewmodel {
    retailStateOTPLicense: string;
    retailStateVaporLicense: string;
    stateTaxId: string;
    federalTaxId: string;

    buildRetailProfileViewmodel(customer: Customer): void {

        if (customer) {
            const customerLicenses = customer.customerLicenses;
            if (customerLicenses) {
                const federalTaxLicense = customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.FederalTaxId && cl.isActive);
                const stateTaxLicense = customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.StateTaxId && cl.isActive);
                const retailStateOTPLicense = customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.RetailStateOTPLicense && cl.isActive);
                const retailStateVaporLicense = customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.RetailStateVaporLicense && cl.isActive);

                this.federalTaxId = federalTaxLicense?.licenseNumber;
                this.stateTaxId = stateTaxLicense?.licenseNumber;
                this.retailStateOTPLicense = retailStateOTPLicense?.licenseNumber;
                this.retailStateVaporLicense = retailStateVaporLicense?.licenseNumber;
            }
        }
    }
}
