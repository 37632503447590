import { GratisAssignedTransactionLineItemDto } from "./gratis-assigned-transaction-line-item.dto";

export class GratisAssignedTransactionDto {
    id: string;
    transactionId: string | null;
    callId: string | null;
    callDate: string;
    callClosingNotes: string | null;
    manualGratisId: string | null;
    customerId: string | null;
    externalSyncId: string | null;
    description: string | null;
    customerName: string | null;
    customerAddress1: string | null;
    customerAddress2: string | null;
    customerCity: string | null;
    customerState: string | null;
    customerZip: string | null;
    customerCounty: string | null;
    receiptNumber: string | null;
    salesTotal: number | null;
    netTotal: number | null;
    gratisTotal: number;
    gratisPercentage: number | null;
    carryForwardGratisId: string | null;
    carryForwardGratisNumber: string | null;
    contractPaymentGratisId: string | null;
    createdUtcDateTime: string | null;
    createdUserId: string;
    createdUserZrt: string | null;
    createdUserFullName: string;
    modifiedUtcDateTime: string | null;
    modifiedUserId: string;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string;

    gratisAssignedTransactionLineItems: GratisAssignedTransactionLineItemDto[];
}
