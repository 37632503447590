<form [formGroup]="injectedData.data.rejectionFormGroup">
    <mat-form-field appearance="outline" color="accent" class="w-100">
        <mat-label>Rejection Reason</mat-label>
        <textarea matInput type="text" formControlName="rejectionReasonControl"
            placeholder="Please enter the rejection reason." maxlength="200" required></textarea>
        <mat-hint align="end">{{
            injectedData.data.rejectionReason
            ?.length || 0
            }}/200</mat-hint>
    </mat-form-field>
</form>
