export class ZrtAssignment {
    zrt: string;
    zone: string | null;
    region: string | null;
    territory: string | null;
    subsidiaryId: number;
    employeeId: string | null;
    isDeleted: boolean;
    createdUtcDateTime: Date | null;
    createdUserId: string | null;
    createdUserZrt: string | null;
    createdUserFullName: string | null;
    modifiedUtcDateTime: Date | null;
    modifiedUserId: string | null;
    modifiedUserZrt: string | null;
    modifiedUserFullName: string | null;
    rowversion: string;
}
