<div
    class="basic-modal"
    [style]="{
        height: 'auto',
        width: 'auto'
    }"
    cdkDrag
    cdkDragBoundary=".cdk-overlay-container"
>
    <div cdkDragHandle>
        <div class="modal-header">
            Finish and Download Route and Share Options
            <mat-icon class="close-icon" (click)="close()">close</mat-icon>
        </div>
    </div>
    <div class="modal-body">
        <div class="d-flex justify-content-start align-items-center mb-2">
          <fa-icon [icon]="faInfoCircle" class="text-m-gray mr-1"></fa-icon>
          <p class="text-m-gray mb-0">All fields are optional.</p>
        </div>

        <div>
            <mat-form-field color="accent" appearance="outline" class="w-100">
              <mat-label>Route Name</mat-label>
              <input matInput placeholder="Route Name" [(ngModel)]="routeName" maxlength="50" autocomplete="off">
            </mat-form-field>

            <mat-form-field color="accent" appearance="outline" class="w-100">
              <mat-label>Route Description</mat-label>
              <input matInput placeholder="Route Description" [(ngModel)]="routeDescription" maxlength="100" autocomplete="off">
            </mat-form-field>

            <div class="d-flex flex-column align-items-start mt-3">
                <h3 class="mb-2">Sharing Options</h3>
                <mat-checkbox
                [(ngModel)]="textRoute"
                >
                Text <ng-container *ngIf="isEdit">Updated </ng-container>Route
                </mat-checkbox>

                <mat-checkbox
                [(ngModel)]="emailRoute"
                >
                Email <ng-container *ngIf="isEdit">Updated </ng-container>Route
                </mat-checkbox>

                <mat-checkbox
                [(ngModel)]="supervisorTextRoute"
                >
                <ng-container *ngIf="isEdit">Share Updated Route via Text</ng-container>
                <ng-container *ngIf="!isEdit">Share Text Route</ng-container>
                </mat-checkbox>

                <mat-checkbox
                [(ngModel)]="supervisorEmailRoute"
                >
                <ng-container *ngIf="isEdit">Share Updated Route via Email</ng-container>
                <ng-container *ngIf="!isEdit">Share Email Route</ng-container>
                </mat-checkbox>
            </div>

            <app-employee-dropdown #employeeDropdown *ngIf="supervisorEmailRoute || supervisorTextRoute"
                (selectionChange)="setSupervisor($event)">
            </app-employee-dropdown>

            <mat-form-field *ngIf="supervisorEmailRoute || supervisorTextRoute" color="accent" appearance="outline" class="w-100">
                <mat-label>Message</mat-label>
                <input matInput [(ngModel)]="supervisorTextMessage">
            </mat-form-field>

        </div>
    </div>
    <div class="modal-footer">
        <button mat-raised-button
            class="btn-white actionBtn"
            (click)="close()"
        >
            Cancel
        </button>
        <button mat-raised-button
            class="sw-red-btn actionBtn"
            [disabled]="(supervisorEmailRoute || supervisorTextRoute) ? !supervisorEmployeeId : false"
            (click)="saveClick()"
        >
            Save and Download
        </button>
    </div>
</div>
