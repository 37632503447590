import { GratisProductRequestedDetailDto } from "shield.shared";

import { GratisProductRequestedDetail } from "src/app/entity-models/gratis-product-requested-detail.entity";

export class GratisProductRequestedDetailConverterService {
    static dtoToEntity(
        dto: GratisProductRequestedDetailDto
    ): GratisProductRequestedDetail {
        const entity = new GratisProductRequestedDetail();

        entity.id = dto.id;
        entity.costPerUnit = dto.costPerUnit;
        entity.productId = dto.productId;
        entity.quantity = dto.quantity;
        entity.unitOfMeasure = dto.unitOfMeasure;

        return entity;
    }

    static entityToDto(
        entity: GratisProductRequestedDetail
    ): GratisProductRequestedDetailDto {
        const dto = new GratisProductRequestedDetailDto();

        dto.id = entity.id;
        dto.costPerUnit = entity.costPerUnit;
        dto.productId = entity.productId;
        dto.quantity = entity.quantity;
        dto.unitOfMeasure = entity.unitOfMeasure;

        return dto;
    }
}
