<ng-container *ngIf="viewmodel">
    <div class="row">
        <div class="col-12">
            <app-page-header #header [name]="viewmodel.headerName"
                [entryCount]="viewmodel.searchCount ? viewmodel.searchCount : 0"
                [buttons]="viewmodel.headerButtons" [refinerService]="viewmodel.refinerService"
                [subAvailableColumns]="viewmodel.availableColumns"
                (onClickButton)="viewmodel.onButtonClickEvent($event)"
                (onButtonAction)="viewmodel.onButtonAction($event)"
                (headerExpansionChanged)="viewmodel.headerExpansionChanged()">
            </app-page-header>
        </div>
    </div>
    <div class="d-flex p-2 justify-content-center align-items-center">
        <div>
            <button class="btn btn-secondary mr-2" data-toggle="tooltip" data-placement="bottom" title="View Filters"
                (click)="viewmodel.showFilters()" [disabled]="viewmodel.isSearchDisabled">
                <span class="white-space-nowrap">
                    <span class="material-icons">filter_alt</span>
                    Filters •
                    {{ viewmodel.refinerService.refiners.length }}
                </span>
            </button>
        </div>
        <div class="flex-grow-1">
            <mat-form-field appearance="outline" color="accent">
                <mat-label *ngIf="!viewmodel.selectedWholesaler">Select a wholesaler...</mat-label>
                <mat-label *ngIf="viewmodel.selectedWholesaler">Wholesaler</mat-label>
                <mat-select #wholesalerDropdown
                        (openedChange)="viewmodel.onWholesalerSearchOpenChange()"
                        [(value)]="viewmodel.selectedWholesaler"
                        (valueChange)="viewmodel.onWholesalerSelectionChange()">
                    <mat-form-field appearance="outline" color="accent" class="wholesaler-search-wrapper">
                        <span class="wholesaler-search-icon" matPrefix><mat-icon>search</mat-icon></span>
                        <input #wholesalerSearch matInput class="wholesaler-search"
                            [(ngModel)]="viewmodel.wholesalerSearchValue"
                            type="text"
                            maxLength="50"
                            (keydown)="viewmodel.onWholesalerKeyDown($event)"
                            placeholder="Search"/>
                    </mat-form-field>
                    <mat-option *ngFor="let option of viewmodel.wholesalersData" [value]="option">
                        {{ option.displayName }}
                        <span *ngIf="option.hasPendingOrders">&#42;</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex-grow-1 d-flex align-items-center mr-2">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Extraction</mat-label>
                <mat-select
                    [(value)]="viewmodel.selectedExtraction"
                    [disabled]="viewmodel.isSelectedExtractionDisabled"
                    (selectionChange)="viewmodel.onExtractionSelectionChange()">
                    <mat-option *ngFor="let option of viewmodel.extractionOptions" [value]="option">
                        {{ option.extractionName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button appThrottleClick mat-raised-button
                class="btn"
                [class.btn-success]="viewmodel.selectedExtraction?.id == viewmodel.newExtraction"
                [class.btn-secondary]="viewmodel.selectedExtraction?.id != viewmodel.newExtraction"
                [disabled]="viewmodel.isExtractDisabled"
                (throttledClick)="viewmodel.extract(viewmodel.selectedExtraction)"
            >
            EXTRACT
            </button>
        </div>
        <div class="d-flex">
            <button appThrottleClick mat-raised-button (throttledClick)="viewmodel.reset()" class="btn btn-secondary mr-2">RESET</button>
            <button appThrottleClick mat-raised-button
            (throttledClick)="viewmodel.search()"
            class="btn btn-primary"
            [disabled]="viewmodel.isSearchDisabled">
                SEARCH
            </button>
        </div>
    </div>
</ng-container>
<mat-sidenav-container class="sidenav-container d-flex flex-column flex-grow-1" autosize>
        <mat-sidenav #drawer [mode]="viewmodel.drawerMode" [fixedInViewport]="true" [fixedTopGap]="viewmodel.topGapDistance"
            [fixedBottomGap]="0" class="sidenav">
            <app-filter-container
                [refinerService]="viewmodel.refinerService"
                [filters]="viewmodel.filters"
                [isSearchDisabled]="viewmodel.isSearchDisabled"
                [expandPanelsOnInit]="viewmodel.expandPanelsOnInit"
                (search)="viewmodel.search()"
                (reset)="viewmodel.reset()">
            </app-filter-container>
        </mat-sidenav>
    <mat-sidenav-content #sideNavContent>
        <div class="sidenav-content d-flex flex-column flex-grow-1">
            <ng-container>
                <div class="table-responsive d-flex flex-column flex-grow-1">
                    <app-grid #grid [(dataSource)]="viewmodel.dataSource"
                        [columnDefinitions]="viewmodel.columnDef"
                        [searchRefiners]="viewmodel.activeRefiners"
                        [searchRefinersLessThans]="viewmodel.refinerLessThans"
                        [showExpanderToggle]="viewmodel.showExpanderToggle"
                        [isFixedLayout]="viewmodel.isFixedLayout"
                        [height]="viewmodel.gridHeight"
                        [isEnabledExpandedDetails]="viewmodel.isEnabledExpandedDetails"
                        [itemsRendedInViewPort]="viewmodel.itemsRendedInViewPort"
                        [detailHeight]="viewmodel.detailHeight"
                        class="d-flex flex-column flex-grow-1">
                    </app-grid>
                </div>
            </ng-container>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
