import { Injectable } from "@angular/core";
import { Picture } from "src/app/entity-models/picture.entity";
import { DatabaseService } from "src/app/services/database.service";
import { CallTypes, PictureParamDto } from "shield.shared";
import { Call } from "src/app/accounts/call-master/call-services/call.service";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { WholesaleCall } from "src/app/entity-models/wholesale-call.entity";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";


@Injectable()
export class PictureOfflineService {
    constructor(
        private dbService: DatabaseService
    ) {}

    async getLocalPicturesByIds(ids: string[]): Promise<Picture[]> {
        return  await this.dbService.pictures.where("id").anyOf(ids).toArray();
    }

    async getCallPicturesByCall(call: Call): Promise<Picture[]> {

        if (!call) { return null; }

        if (call.callType === CallTypes.retail || call.callType === CallTypes.wholesale || CallTypes.rmWholesale) {

            return await this.dbService.pictures
                .where("id")
                .anyOf((call as RetailCall | WholesaleCall | RmWholesaleCall).callPictures.map((p) => p.id))
                .toArray();

        }

        return null;
    }

    async getPicture(params: PictureParamDto): Promise<Picture> {
        if (!params) { return null; }

        return await this.dbService.pictures
            .where("id")
            .equals(params.pictureId)
            .first()
    }
}
