import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { WebcamImage, WebcamInitError, WebcamUtil } from "ngx-webcam";
import { Observable, Subject, Subscription } from "rxjs";

//Models
import { RetailStepperStep } from "../../../enums/retail-stepper-step";

import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import {
    PictureType,
    CallPicture
} from "src/app/entity-models/call-picture.entity";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { WholesaleCallApplicationService } from "../wholesale-call/wholesale-services/wholesale-call-application.service";
import { Helper } from "src/app/helpers/helper";
import { CustomerGenericTypes } from "src/app/enums/customer-generic-types";
import { CallPictureDeliveryTypeViewModel } from "../call-viewmodels/call-picture-deliverytype.viewmodel";
import { CustomerConverterService } from "src/app/services/converter-services/customer-converter.service";
import { StepperCallApplicationService } from "../stepper-call/stepper-call-services/stepper-call-application.service";
import { CallService } from "../call-services/call.service";
import { CallTypes } from "shield.shared";
import { MatSlider } from "@angular/material/slider";

@Component({
    selector: "app-step-cam",
    templateUrl: "./step-cam.component.html",
    styleUrls: ["./step-cam.component.scss"]
})
export class StepCamComponent implements OnInit, OnDestroy {
    //View Children
    @ViewChild("modalCanvas") modalCanvas: ElementRef;
    @ViewChild('focusSlider') focusSlider: MatSlider;
    @ViewChild('confirmButton') confirmButton!: ElementRef;

    focusMin: number;
    focusMax: number;
    focusStep: number;
    focusValue: number;
    brightnessMin: number;
    brightnessMax: number;
    brightnessStep: number;
    brightnessValue: number;
    mediastream: any

    //Outputs
    @Output() moveStepper = new EventEmitter();

    //Private vars
    private readonly after: PictureType = "After";
    private capturedImage: string;
    cameraHeight: number;
    cameraWidth: number;
    // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
    private nextWebcam: Subject<boolean | string> = new Subject<
        boolean | string
    >();
    private tags: string[] = [];
    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();
    private readonly before: PictureType = "Before";

    //Public vars
    get nextWebcamObservable(): Observable<boolean | string> {
        return this.nextWebcam.asObservable();
    }

    get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    allowCameraSwitch = true;
    deviceId: string;
    errors: WebcamInitError[] = [];
    group: FormGroup;

    multipleWebcamsAvailable = false;
    //You can not Assign a string as a default value for a select unles it is an object property that matches the ngModel
    pictureTypeOptions: CallPictureDeliveryTypeViewModel[] = [
        { value: this.before, viewValue: this.before },
        { value: this.after, viewValue: this.after },
        { value: "Displays", viewValue: "Displays" },
        { value: "Signage", viewValue: "Signage" },
        { value: "Other", viewValue: "Other" }
    ];
    selectedPictureValue: PictureType;

    // toggle webcam on/off
    showWebcam = true;
    tagsString: string = null;
    useFrontCamera = true;
    videoOptions: MediaTrackConstraints = {
        facingMode: { ideal: "environment" }
    };
    // latest snapshot
    webcamImage: WebcamImage = null;

    tagLimitRegEx = new RegExp("^([^,][*,]?[^,]*){0,10}$");

    tagFormControl = new FormControl(this.tagsString, {
        validators: [Validators.pattern(this.tagLimitRegEx)]
    });

    tagStringSubscription: Subscription;

    @HostListener("window:resize", ["$event"])
    onResize(event?: Event): void {
        const win = event ? (event.target as Window) : window;
        this.cameraWidth = win.innerWidth * 0.437;
        this.cameraHeight = this.cameraWidth * 0.749;
    }

    public constructor(
        public overlayRef: SwisherOverlayRef<null, StepCamComponent>,
        private stepperCallApplicationService: StepperCallApplicationService,
        private wholesaleCallApplicationService: WholesaleCallApplicationService,
        private customerStateService: CustomerStateService,
        private callService: CallService,

        private ref: SwisherOverlayRef<RetailStepperStep, StepCamComponent>,
    ) {
        this.onResize();
    }

    ngOnInit(): void {
        this.overlayRef.blocking = true;

        this.group = new FormGroup({ select: new FormControl("", Validators.required)});

        if (!this.tagStringSubscription || this.tagStringSubscription.closed) {
            this.tagStringSubscription = this.tagFormControl.valueChanges.subscribe(
                () => {
                    if (this.tagFormControl.valid) {
                        this.tagsString = this.tagFormControl.value as string;
                    }
                }
            );
        }

        this.selectedPictureValue = null;

        switch (this.ref?.data) {
            case RetailStepperStep.before:
                this.selectedPictureValue = this.before;
                break;

            case RetailStepperStep.after:
                this.selectedPictureValue = this.after;
                break;

            default:
                break;
        }

        this.group.controls.select.setValue(this.selectedPictureValue);
        this.group.markAsUntouched();

        WebcamUtil.getAvailableVideoInputs()
            .then((mediaDevices: MediaDeviceInfo[]) => {
                this.multipleWebcamsAvailable =
                    mediaDevices && mediaDevices.length > 1;
            });
    }

    ngOnDestroy(): void {
        if (this.tagStringSubscription && !this.tagStringSubscription.closed) {
            this.tagStringSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.confirmButton.nativeElement.focus();
        }, 0);
        // navigator.mediaDevices
        // .getUserMedia({ video: true })
        // .then(stream => {this.gotMedia(stream)})
        // .catch(err => console.error("getUserMedia() failed: ", err));
    }

    gotMedia(mediastream: any) {
        this.mediastream = mediastream
        const track = mediastream.getVideoTracks()[0];
        const capabilities = track.getCapabilities();
        this.focusMin = capabilities.focusDistance.min;
        this.focusMax = capabilities.focusDistance.max;
        this.focusStep = capabilities.focusDistance.step;
        this.focusValue = track.getSettings().focusDistance;

        this.brightnessMin = capabilities.brightness.min;
        this.brightnessMax = capabilities.brightness.max;
        this.brightnessStep = capabilities.brightness.step;
        this.brightnessValue = (this.brightnessMin + this.brightnessMax)/2

        track.applyConstraints({
            advanced: [{
              focusMode: "manual",
              focusDistance: this.focusValue,
              whiteBalanceMode: "manual",
              brightness: this.brightnessValue
            }]
          });
      }

      onBrightnessSliderInput(event: any) {
        const track = this.mediastream.getVideoTracks()[0];
        track.applyConstraints({
          advanced: [{
            whiteBalanceMode: "manual",
            brightness: event.value
          }]
        });
      }

      onFocusSliderInput(event: any) {
        const track = this.mediastream.getVideoTracks()[0];
        track.applyConstraints({
          advanced: [{
            focusMode: "manual",
            focusDistance: event.value
          }]
        });
      }
    //Events
    public onCameraWasSwitched(deviceId: string): void {
        console.log("active device: " + deviceId);
        this.deviceId = deviceId;
    }

    public onHandleInitError(error: WebcamInitError): void {
        this.errors.push(error);
    }

    public onHandleImage(webcamImage: WebcamImage): void {
        this.webcamImage = webcamImage;

        setTimeout(() => {
            let zip: string = this.customerStateService.customer.businessAddress
                .zip;
            if (zip && zip.length > 5) {
                zip = zip.substring(0, 4);
            }
            const address = CustomerConverterService.getCustomerFlatBusinessAddress(
                this.customerStateService.customer
            );
            const datetime: string = new Date().toLocaleString();

            const textLine1: string = this.customerStateService.customer.name.concat(
                ", ",
                address
            );

            const textLine2: string = datetime;

            this.addTextToImage(this.webcamImage.imageAsDataUrl, textLine1, textLine2);
        }, 50);
    }

    public async onSave(): Promise<void> {
        if (this.tagsString) {
            const splitTaggs: string[] = this.tagsString.split(",", 10);

            splitTaggs.forEach((tag) => {
                this.tags.push(tag.trim());
            });
        }

        const callPicture: CallPicture = new CallPicture();
        callPicture.type = this.selectedPictureValue;
        callPicture.tags = this.tags;

        const type = Helper.getCustomerGenericType(
            this.customerStateService.customer
        );

        switch (type) {
            case CustomerGenericTypes.retail:
                await this.stepperCallApplicationService.addPicture(
                    callPicture,
                    this.capturedImage
                );
                break;
            case CustomerGenericTypes.wholesale:
                if (this.callService.call?.callType === CallTypes.wholesale) {
                    await this.wholesaleCallApplicationService.addPicture(
                        callPicture,
                        this.capturedImage
                    );
                } else if (this.callService.call?.callType === CallTypes.rmWholesale) {
                    await this.stepperCallApplicationService.addPicture(
                        callPicture,
                        this.capturedImage
                    );
                }

                break;
            default:
                break;
        }
        this.restCamera(false);
        this.overlayRef.close();
    }

    public onShowNextWebcam(directionOrDeviceId: boolean | string): void {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    }

    public onToggleWebcam(): void {
        this.webcamImage = null;
    }

    public onTriggerSnapshot(): void {
        if (this.webcamImage) {
            this.webcamImage = null;
        }
        this.trigger.next();
    }

    //Private methods
    private addTextToImage(image: string, textLine1: string, textLine2: string): void {
        const canvas = this.modalCanvas.nativeElement as HTMLCanvasElement;
        const context = canvas.getContext("2d");
        const cameraWidth = this.cameraWidth;
        const cameraHeight = this.cameraHeight;

        // Draw Image function
        const img = new Image();
        img.src = image;
        img.onload = () => {
            context.drawImage(img, 0, 0, cameraWidth, cameraHeight);
            context.lineWidth = 2;

            const bannerHieght = 40;

            context.beginPath();
            context.rect(
                0,
                cameraHeight - bannerHieght,
                cameraWidth,
                bannerHieght
            );
            context.fillStyle = "black";
            context.fill();

            context.fillStyle = "white";
            context.font = "22px sans-serif";
            context.fillText(textLine1, 5, cameraHeight - 3);

            //Testing Two Lines
            context.fillText(textLine2, 5, cameraHeight - 25);

            this.capturedImage = canvas.toDataURL();
        };
    }

    public restCamera(shouldClearImage: boolean = true): void {
        this.tagsString = null;
        this.tags = [];

        if (shouldClearImage) {
            this.webcamImage = null;
        }
    }
}
