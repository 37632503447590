import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { GenericResponseDto, ProjectStatuses, SharedHelper } from "shield.shared";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { Product } from "src/app/entity-models/product.entity";
import { ProjectActivitySurvey } from "src/app/entity-models/project-activity-survey.entity";
import { ProjectCustomer } from "src/app/entity-models/project-customer.entity";
import { ProjectEmployee } from "src/app/entity-models/project-employee.entity";
import { ProjectOrderDate } from "src/app/entity-models/project-order-date.entity";
import { ProjectProduct } from "src/app/entity-models/project-product.entity";
import { Project } from "src/app/entity-models/project.entity";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { OverlayService } from "src/app/services/overlay.service";
import { ProjectStateService } from "src/app/services/project-state-service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";

@Injectable()
export class ProjectApplicationService {

    productsMap: Map<string, Product>
    products: Product[];

    isCanceling = false;
    isSaving = false;

    confirmationOverlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;
    cancelConfirmed = false;

    // selectedIndex
    protected _selectedIndex = 0;
    selectedIndexSubject: BehaviorSubject<number> = new BehaviorSubject(
        this._selectedIndex
    );

    get selectedIndex(): number {
        return this._selectedIndex;
    }

    set selectedIndex(value: number) {
        this._selectedIndex = value;

        this.selectedIndexSubject.next(value);
    }
    observableSelectedIndex: Observable<number> = this.selectedIndexSubject.asObservable();

    private canDeactivateProjectSubject: Subject<boolean> = new Subject();
    observableCanDeactivateProject: Observable<boolean> = this.canDeactivateProjectSubject.asObservable();

    isInProfile = false;

    constructor(private projectStateService: ProjectStateService,
        private projectDelineationService: ProjectDelineationService,
        private router: Router,
        private overlayService: OverlayService,
        private productDelineationService: ProductDelineationService) {
            this.initialize();
         };

    async initialize(): Promise<void> {
        const productsResponse = await this.productDelineationService.getAll();
        if (!productsResponse) { return; }

        if (!productsResponse.values) {
            this.products = new Array<Product>();
            this.productsMap = new Map<string, Product>();
        } else {
            this.products = productsResponse.values;
            this.productsMap = new Map(this.products.map((p) => [p.id, p]));
        }
    }

    async cancelProject(): Promise<void> {
        this.isCanceling = true;

        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation - Project Cancelation";
            data.message =
                "Canceling a Project cannot be undone. Are you sure?";
            data.buttonLeftText = "No";
            data.buttonLeftFunction = () => {
                this.confirmationOverlayRef.close(data);
            };

            data.buttonRightText = "Yes";
            data.buttonRightFunction = () => {
                data.isConfirmed = true;
                this.confirmationOverlayRef.close(data);
            };

            this.confirmationOverlayRef = this.overlayService.open(
                ConfirmationDialogComponent,
                data
            );

            this.confirmationOverlayRef.afterClosed$.subscribe(async (closeResponse) => {
                if (closeResponse?.data?.isConfirmed) {
                    if (this.projectStateService.project.projectStatusId == ProjectStatuses.Staging) {
                        await this.projectDelineationService.discardProject(this.projectStateService.project.id);
                    } else {
                        this.projectStateService.project.projectStatusId = ProjectStatuses.Canceled;
                        await this.projectStateService.saveProjectAndNotify();
                    }

                    this.clearProjectServices();
                    this.setCanDeactivate(true);
                    await this.router.navigate(["/details/project-list"]);
                }
                this.isCanceling = false;
            })


    }

    async cancel(navigationUrl?: string): Promise<void>{
        if (!this.projectStateService.project) {
            this.clearProjectServices();
            this.setCanDeactivate(true);
            void this.router.navigate(navigationUrl ? [navigationUrl] : ["/details/project-list"]);
            return;
        }

        const isEqual = this.isProjectEqual(this.projectStateService.lastSavedProject);
        if (!isEqual) {
            const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
            data.header = "Confirmation - Unsaved Changes";
            data.message =
                "Your project has unsaved changes. What would you like to do?";

            data.buttonLeftText = "Cancel";
            data.buttonLeftFunction = () => {
                this.confirmationOverlayRef.close();
                this.setCanDeactivate(false);
            };

            data.buttonMiddleText = "Discard Changes";
            data.buttonMiddleFunction = async () => {
                this.confirmationOverlayRef.close();

                await this.discardProject(this.projectStateService.project.id);
                this.clearProjectServices();

                this.setCanDeactivate(true);
                await this.router.navigate(navigationUrl ? [navigationUrl] : ["/details/project-list"]);
            };


            data.buttonRightText = "Save Changes";
            data.buttonRightFunction = async () => {
                this.confirmationOverlayRef.close();
                await this.projectStateService.saveProjectAndNotify();
                this.clearProjectServices();

                this.setCanDeactivate(true);
                await this.router.navigate(navigationUrl ? [navigationUrl] : ["/details/project-list"]);
            };

            this.confirmationOverlayRef = this.overlayService.open(
                ConfirmationDialogComponent,
                data
            );

            this.confirmationOverlayRef.afterClosed$.subscribe(() => {
                return !this.isCanceling;
            });

        } else {
            await this.discardProject(this.projectStateService.project.id);
            this.clearProjectServices();

            this.setCanDeactivate(true);
            await this.router.navigate(navigationUrl ? [navigationUrl] : ["/details/project-list"]);

        }
    }

    clearProjectServices(): void {
        this.selectedIndex = 0;
        this.projectStateService.project = null;
    }

    isProjectEqual(savedProject: Project): boolean {

        const p1 = this.projectStateService.project;
        if (!p1) {
            return true;
        }

        let p2 = savedProject;
        if (!p2) {
            p2 = new Project();
            p2.name = "";
            p2.areStoresVisibleToAll = false;
        }

        return p1.name == p2.name
            && (p1.startDate ? p1.startDate.getTime() : null) == (p2.startDate ? p2.startDate?.getTime() : null)
            && (p1.visibleDate ? p1.visibleDate.getTime() : null) == (p2.visibleDate ? p2.visibleDate?.getTime() : null)
            && (p1.endDate ? p1.endDate.getTime() : null) == (p2.endDate ? p2.endDate?.getTime() : null)
            && p1.mission == p2.mission
            && p1.shareSiteLink == p2.shareSiteLink
            && p1.wholesalerGroupId == p2.wholesalerGroupId
            && p1.areStoresVisibleToAll == p2.areStoresVisibleToAll
            && this.isActivitiesAndSurveysEqual(p1.projectActivitySurveys, p2.projectActivitySurveys)
            && this.areProjectCustomersEqual(p1.projectCustomers, p2.projectCustomers)
            && this.areProjectEmployeesEqual(p1.projectEmployees, p2.projectEmployees)
            && this.areProjectOrderDatesEqaul(p1.projectOrderDates, p2.projectOrderDates)
            && this.areProjectProductsEqual(p1.projectProducts, p2.projectProducts);

    }

    areProjectProductsEqual(a: ProjectProduct[], b: ProjectProduct[]): boolean {
        let rtn = true;

        if ((!a && b)
            || (a && !b)
            || (a.length !== b.length)) {
            return false;
        }
        if (!a && !b) {
            return true;
        }

        for (let i = 0; (i < a.length) && rtn === true; i++) {
            const id = a[i].id;

            const found = b.find((obj) => obj.id === id);
            if (found) {
                if (a[i].productId != found.productId || a[i].projectId !== found.projectId) {
                    rtn = false;
                }
            } else {
                rtn = false;
            }
        }

        return rtn;
    }

    areProjectOrderDatesEqaul(a: ProjectOrderDate[], b: ProjectOrderDate[]): boolean {
        let rtn = true;

        if ((!a && b)
            || (a && !b)
            || (a.length !== b.length)) {
            return false;
        }
        if (!a && !b) {
            return true;
        }

        for (let i = 0; (i < a.length) && rtn === true; i++) {
            const id = a[i].id;

            const found = b.find((obj) => obj.id === id);
            if (found) {
                if (a[i].dateIndex != found.dateIndex
                    || !SharedHelper.areDatesEqual(a[i].orderDate, found.orderDate)
                    || a[i].projectId !== found.projectId) {
                    rtn = false;
                }
            } else {
                rtn = false;
            }
        }

        return rtn;
    }

    areProjectEmployeesEqual(a: ProjectEmployee[], b: ProjectEmployee[]): boolean {
        let rtn = true;

        if ((!a && b)
            || (a && !b)
            || (a.length !== b.length)) {
            return false;
        }
        if (!a && !b) {
            return true;
        }

        for (let i = 0; (i < a.length) && rtn === true; i++) {
            const id = a[i].id;

            const found = b.find((obj) => obj.id === id);
            if (found) {
                if (a[i].employeeId != found.employeeId || a[i].projectId !== found.projectId) {
                    rtn = false;
                }
            } else {
                rtn = false;
            }
        }

        return rtn;
    }

    areProjectCustomersEqual(a: ProjectCustomer[], b: ProjectCustomer[]): boolean {
        let rtn = true;

        if ((!a && b)
            || (a && !b)
            || (a.length !== b.length)) {
            return false;
        }
        if (!a && !b) {
            return true;
        }

        for (let i = 0; (i < a.length) && rtn === true; i++) {
            const id = a[i].id;

            const found = b.find((obj) => obj.id === id);
            if (found) {
                if (a[i].customerId != found.customerId
                    || a[i].projectId !== found.projectId
                    || a[i].employeeIds.filter(v => !found.employeeIds.includes(v)).length
                    || found.employeeIds.filter(v => !a[i].employeeIds.includes(v)).length
                ) {
                    rtn = false;
                }
            } else {
                rtn = false;
            }
        }

        return rtn;
    }

    async discardProject(projectId: string): Promise<GenericResponseDto<Project>> {
        return await this.projectDelineationService.discardProject(projectId);
    }

    isActivitiesAndSurveysEqual(a: ProjectActivitySurvey[], b: ProjectActivitySurvey[]): boolean {
        let rtn = true;

        if ((!a && b)
            || (a && !b)
            || (a.length !== b.length)) {
            return false;
        }
        if (!a && !b) {
            return true;
        }

        for (let i = 0; (i < a.length) && rtn === true; i++) {
            const id = a[i].id;

            const found = b.find((obj) => obj.id === id);
            if (found) {
                if (a[i].activitySurveyId != found.activitySurveyId || a[i].projectId != found.projectId) {
                    rtn = false;
                }
            } else {
                rtn = false;
            }
        }

        return rtn;
    }

    setCanDeactivate(value: boolean): void {
        this.canDeactivateProjectSubject.next(value);
    }
}
