import { Injectable } from '@angular/core';
import { Customer } from 'src/app/entity-models/customer.entity';
import { LogService } from 'src/app/services/log.service';
import { Call } from './call.service';

export enum ReceiptRenderType {
    Print,
    Email
}


export enum ReceiptRenderLocation {
    CallHistory,
    CallSave,
    Invoice
}

const RECEIPT_RENDER_EVENT_NAME = "ReceiptRender";

@Injectable({
    providedIn: 'root',
})
export class CallEventLoggerService {

    constructor(
        private logService: LogService,
    ) { }


    logReceiptRender(type: ReceiptRenderType, location: ReceiptRenderLocation, call: Call, callCustomer: Customer) {
        this.logService.logEvent(RECEIPT_RENDER_EVENT_NAME, {
            callId: call.id,
            customerNumber: callCustomer.customerNumber,
            callDate: call.startTime.toISOString(),
            location: ReceiptRenderLocation[location],
            type: ReceiptRenderType[type],
        })
    }
}
