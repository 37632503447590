import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';
import { RejectionReasonViewmodel } from './rejection-reason.viewmodel';

@Component({
    selector: 'app-rejection-reason',
    templateUrl: './rejection-reason.component.html',
    styleUrls: ['./rejection-reason.component.scss']
})
export class RejectionReasonComponent implements OnInit, OnDestroy {


    constructor(public injectedData: SwisherOverlayRef<
        RejectionReasonViewmodel,
        RejectionReasonComponent
    >) {}

    @HostListener('window:keydown', ['$event'])
        onKeyUp(event: KeyboardEvent): void {
            if (event.key === 'Enter' && this.injectedData) {
                this.injectedData.data.buttonRightFunction()
            }
        }

    ngOnInit(): void {


        if (!this.injectedData.data.rejectionReasonSubscription || this.injectedData.data.rejectionReasonSubscription.closed) {
            this.injectedData.data.rejectionReasonSubscription = this.injectedData.data.rejectionFormGroup.controls["rejectionReasonControl"].valueChanges.subscribe((value) => {
                this.injectedData.data.rejectionReason = value;
            });
        }
    }

    ngOnDestroy(): void {
        if (this.injectedData.data.rejectionReasonSubscription && !this.injectedData.data.rejectionReasonSubscription.closed) {
            this.injectedData.data.rejectionReasonSubscription.unsubscribe();
        }
    }

}
