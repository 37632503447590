import * as uuid from "uuid";
export function newSequentialId(): string {
    // translated from c# code here:
    //   https://docs.microsoft.com/en-us/archive/blogs/dbrowne/how-to-generate-sequential-guids-for-sql-server-in-net

    const s = uuid.parse(uuid.v1());

    const t = new Uint8Array(16);


    t[3] = s[0];
    t[2] = s[1];
    t[1] = s[2];
    t[0] = s[3];

    t[5] = s[4];
    t[4] = s[5];

    t[7] = s[6];
    t[6] = s[7];

    t[8] = s[8];
    t[9] = s[9];

    t[10] = s[10];
    t[11] = s[11];
    t[12] = s[12];
    t[13] = s[13];
    t[14] = s[14];
    t[15] = s[15];

    //HACK.DMC - it sure would be nice if we could use uuid.stringify() here, but it does some RFC validation that fails...
    //  (it expects the 14th char of the string to be [1-5] and it won't necessarily be due to the byte shuffling that makes it SQL sequential)
    const newId = [...t].map((b) => b.toString(16).padStart(2, "0")).join("");
    const formattedId =
        newId.slice(0, 8) +
        "-" +
        newId.slice(8, 12) +
        "-" +
        newId.slice(12, 16) +
        "-" +
        newId.slice(16, 20) +
        "-" +
        newId.slice(20, 32);

    return formattedId.toUpperCase();
}
