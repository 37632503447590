import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
    apiVersion, GenericVersionResponseDto, KeyValueDto,
    parameterKeys, SharedHelper, SurveyDto,
    SurveySyncCommand, SyncCommandTypes, SystemInformationKeys,
    VersionResponseDto
} from "shield.shared";
import { DatabaseService } from "../../services/database.service";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { SurveyConverterService } from "src/app/services/converter-services/survey-converter.service";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { SyncLevel } from "../sync-enums/sync-level.enum";
@Injectable()
export class SurveyDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    apiVersion = apiVersion;
    onlineRepSyncType = SyncLevel.None;
    offlineRepSyncType = SyncLevel.Required;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.survey;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing surveys...");

        await this.pullData();

        this.log("Done syncing surveys.");
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/surveys/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("Survey data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Survey data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.surveyPullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;

        try {
            let thisBatchSize: number;
            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;

                this.log(`Get survey [` + query + `]`);
                const response = await this.http
                    .get<GenericVersionResponseDto<SurveyDto[]>>(`/api/surveys${query}`)
                    .toPromise();

                thisBatchSize = response.values.length;

                this.log(`  Downloaded ${thisBatchSize}, saving to IndexedDB...`);

                const ss = response.values.map((v) =>
                    SurveyConverterService.surveyDtoToSurvey(v)
                );

                await this.dbService.surveys.bulkPut(ss);
                this.log(`  Saved ${ss.length} surveys.`);

                this.lastSyncVersion = response.maxVersion;
            } while (thisBatchSize > 0);

            this.log("Done saving survey data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing survey", e);
        }
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<SurveySyncCommand>(
            SyncCommandTypes.survey,
            async (message) => {
                let s = await this.dbService.surveys.get(
                    message.payload.id
                );

                let dto = SurveyConverterService.surveyToSurveyDto(s);

                if (dto) {
                    const params = new Array<KeyValueDto>();
                    const param = new KeyValueDto();
                    param.key = parameterKeys.apiVersion;
                    param.value = this.apiVersion.toString();
                    params.push(param);

                    await this.syncQueue.pushCommand(dto, message);
                }
            }
        );
    }
}
