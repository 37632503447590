import { Injectable } from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject, Observable } from "rxjs";
import {
    CallTypes,
    CustomerTypeEnum,
    GenericResponseDto,
    NotificationRequestDto,
    SurveyQuestionTypes,
    newSequentialId
} from "shield.shared";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { CountDownComponent } from "src/app/dialogs/count-down/count-down.component";
import { CountDownViewmodel } from "src/app/dialogs/count-down/count-down.viewmodel";
import { CallCashProduct } from "src/app/entity-models/call-cash-product.entity";
import { CallExchangeInProduct } from "src/app/entity-models/call-exchange-in-product.entity";
import { CallExchangeOutProduct } from "src/app/entity-models/call-exchange-out-product.entity";
import { CallGratisProduct } from "src/app/entity-models/call-gratis-product.entity";
import { CallOrderDate } from "src/app/entity-models/call-order-date.entity";
import { CallOrderProduct } from "src/app/entity-models/call-order-product.entity";
import { CallPicture } from "src/app/entity-models/call-picture.entity";
import { CallSurveyAnswer } from "src/app/entity-models/call-survey-answer.entity";
import { ChainHqCall } from "src/app/entity-models/chain-hq-call.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { Picture } from "src/app/entity-models/picture.entity";
import { Product } from "src/app/entity-models/product.entity";
import { Project } from "src/app/entity-models/project.entity";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";
import { Survey } from "src/app/entity-models/survey.entity";
import { WholesaleCall } from "src/app/entity-models/wholesale-call.entity";
import { DexieTableNames } from "src/app/enums/dexie-table-names";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { CustomerConverterService } from "src/app/services/converter-services/customer-converter.service";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { ContactDelineationService } from "src/app/services/delineation-services/contact-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { NotificationDelineationService } from "src/app/services/delineation-services/notification-delineation.service";
import { PictureDelineationService } from "src/app/services/delineation-services/picture-delineation.service";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";
import { RouteDelineationService } from "src/app/services/delineation-services/route-delineation.service";
import { TimeEntryDelineationService } from "src/app/services/delineation-services/time-entry-delineation.service";
import { WholesalerGroupProductCatalogItemDelineationService } from "src/app/services/delineation-services/wholesaler-group-product-catalog-item-delineation.service";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { SyncService } from "src/app/services/sync.service";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { ActivitySurveyResponseViewModel } from "../../account.viewmodels/activity-survey-response.viewmodel";
import { ActivitySurveyViewModel } from "../../account.viewmodels/activity-survey.viewmodel";
import { ProductViewmodel } from "../stepper-call/distribution-grid/product.viewmodel";
import { WholsaleCallViewmodel } from "../wholesale-call/wholesale-call.viewmodel";

@Injectable()
export class CallService {
    startingCall = false;
    blockingCall = false;
    routeId: string;

    constructor(
        private routeDelineationService: RouteDelineationService,
        private customerDelineationService: CustomerDelineationService,
        private customerStateService: CustomerStateService,
        private contactDelineationService: ContactDelineationService,
        private syncService: SyncService,
        private notificationDelineationService: NotificationDelineationService,
        private callDelineationService: CallDelineationService,
        private pictureDelineationService: PictureDelineationService,
        private timeEntryDelineationService: TimeEntryDelineationService,
        private px3Service: Px3DelineationService,
        private catalogItemService: WholesalerGroupProductCatalogItemDelineationService,
        private pleaseWaitService: PleaseWaitService,
    ) { }

    //Properties and Observables

    //pictures
    private _pictures: Picture[] = [];
    picturesSubject: BehaviorSubject<Picture[]> = new BehaviorSubject(
        this._pictures
    );
    get pictures(): Picture[] {
        return this._pictures;
    }
    set pictures(value: Picture[]) {
        this._pictures = value;
        this.picturesSubject.next(value);
    }
    observablePictures: Observable<
        Picture[]
    > = this.picturesSubject.asObservable();

    //Call
    private _call: Call = null;
    callSubject: BehaviorSubject<Call> = new BehaviorSubject(this._call);
    observableCall: Observable<Call> = this.callSubject.asObservable();

    get call(): Call {
        return this._call;
    }
    set call(value: Call) {
        this._call = value;
        this.callSubject.next(value);
    }

    //isFinalRetailReceiptPrinted
    set isFinalRetailReceiptPrinted(value: boolean) {
        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            this.call.isFinalRetailReceiptPrinted = value;
            void this.saveCallAndNotify();
        }
    }
    get isFinalRetailReceiptPrinted(): boolean {
        let rtn = false;

        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            rtn = this.call?.isFinalRetailReceiptPrinted;
        }

        return rtn;
    }

    //isFinalWholesaleReceiptPrinted
    set isFinalWholesaleReceiptPrinted(value: boolean) {
        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            this.call.isFinalWholesaleReceiptPrinted = value;
            void this.saveCallAndNotify();
        }
    }
    get isFinalWholesaleReceiptPrinted(): boolean {
        let rtn = false;

        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            rtn = this.call?.isFinalWholesaleReceiptPrinted;
        }

        return rtn;
    }

    //isEmailFinalRetailReceipt
    set isEmailFinalRetailReceipt(value: boolean) {
        if (
            this.call &&
            (this.call?.callType === CallTypes.retail ||
                this.call?.callType === CallTypes.rmWholesale)
        ) {
            this.call.isEmailFinalRetailReceipt = value;
            void this.saveCallAndNotify();
        }
    }
    get isEmailFinalRetailReceipt(): boolean {
        let rtn = false;

        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            rtn = this.call?.isEmailFinalRetailReceipt;
        }

        return rtn;
    }

    //isEmailFinalWholesaleReceipt
    set isEmailFinalWholesaleReceipt(value: boolean) {
        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            this.call.isEmailFinalWholesaleReceipt = value;
            void this.saveCallAndNotify();
        }
    }
    get isEmailFinalWholesaleReceipt(): boolean {
        let rtn = false;

        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            rtn = this.call?.isEmailFinalWholesaleReceipt;
        }

        return rtn;
    }

    //Public methods
    async addAndRemoveProductsFromDist(
        addProductIds: string[],
        removeProductIds: string[]
    ): Promise<void> {
        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            if (!this.call.inDistProductIds) {
                this.call.inDistProductIds = [];
            }

            this.call.evaluatedProducts ??= [];

            this.call.productsOos ??= [];

            this.call.evaluatedProducts = [
                ...new Set(
                    this.call.evaluatedProducts.concat(
                        addProductIds,
                        removeProductIds
                    )
                )
            ]; //distinct the array

            if (removeProductIds && removeProductIds.length > 0) {
                this.call.inDistProductIds = this.call.inDistProductIds.filter(
                    (element) => !removeProductIds.includes(element)
                );
            }

            if (addProductIds && addProductIds.length > 0) {
                this.call.inDistProductIds = [
                    ...new Set(this.call.inDistProductIds.concat(addProductIds))
                ];
                this.call.productsOos = this.call.productsOos.filter(
                    (element) =>
                        (this.call?.callType === CallTypes.retail ||
                            this.call?.callType === CallTypes.rmWholesale) &&
                        !this.call.inDistProductIds.includes(element)
                );
            }

            await this.saveCallAndNotify();
        }
    }

    async addProductCash(product: Product): Promise<void> {
        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            this.isFinalRetailReceiptPrinted = false;
            this.call.productsCash ??= [];

            if (product && product.id) {
                if (!this.call.productsCash.includes(product.id)) {
                    this.call.productsCash.push(product.id);

                    this.call.cashProducts ??= [];

                    let callCashProduct = this.call.cashProducts.find(
                        (cashProduct) => cashProduct.productId === product?.id
                    );
                    if (!callCashProduct) {
                        callCashProduct = new CallCashProduct();
                        callCashProduct.id = newSequentialId();
                        callCashProduct.productId = product.id;
                        callCashProduct.quantity = 1;
                        callCashProduct.units =
                            product.lowestSellableUpc?.noOfEaches ?? 1;
                        callCashProduct.discount = 0;
                        callCashProduct.price = 0;
                        callCashProduct.upc = product.lowestSellableUpc?.upc;
                        this.call.cashProducts.push(callCashProduct);
                    }
                }
            }

            await this.saveCallAndNotify();
        }
    }

    async addProductCos(productId: string): Promise<void> {
        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            this.call.productsCos ??= [];

            if (productId) {
                if (!this.call.productsCos.includes(productId)) {
                    this.call.productsCos.push(productId);
                }
            }

            await this.saveCallAndNotify();
        }
    }

    async addProductInDist(productId?: string): Promise<void> {
        if (productId) {
            await this.addAndRemoveProductsFromDist([productId], []);
        }
    }

    async addProductIntro(productId: string): Promise<void> {
        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            this.call.productsIntro ??= [];

            if (productId) {
                if (!this.call.productsIntro.includes(productId)) {
                    this.call.productsIntro.push(productId);
                }
            }

            await this.saveCallAndNotify();
        }
    }

    async addProductOos(productId: string): Promise<void> {
        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            this.call.productsOos ??= [];

            if (productId) {
                if (!this.call.productsOos.includes(productId)) {
                    this.call.productsOos.push(productId);
                }
            }

            await this.saveCallAndNotify();
        }
    }

    async addProductOrder(
        product: Product,
        wholesaler: Customer,
        selectedProject?: Project,
        dateAvailable?: Date,
    ): Promise<void> {
        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.chainHq ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            this.isFinalWholesaleReceiptPrinted = false;
            this.call.productsOrder ??= [];

            if (product && product.id) {
                const productIdAndWholesalerId =
                    product.id + (wholesaler?.id ?? "");
                if (
                    !this.call.productsOrder.includes(productIdAndWholesalerId)
                ) {
                    this.call.productsOrder.push(productIdAndWholesalerId);

                    this.call.orderProducts ??= [];

                    let callOrderProduct = this.call.orderProducts.find(
                        (orderProduct) =>
                            orderProduct.productId === product.id &&
                            orderProduct.wholesalerCustomerId ==
                            (wholesaler?.id ?? null)
                    );

                    if (!callOrderProduct) {
                        const orderDates = this.buildOrderDates(dateAvailable, selectedProject);

                        callOrderProduct = new CallOrderProduct();
                        callOrderProduct.id = newSequentialId();
                        callOrderProduct.productId = product.id;
                        callOrderProduct.quantity = 1;
                        callOrderProduct.units =
                            product.lowestSellableUpc?.noOfEaches ?? 1;
                        callOrderProduct.uin = (product as ProductViewmodel)?.uin;
                        callOrderProduct.upc = product.lowestSellableUpc?.upc;
                        callOrderProduct.storeCount = this.customerStateService.customer.storeCount;
                        callOrderProduct.wholesalerCustomerId = wholesaler?.id;
                        callOrderProduct.selectedProject = selectedProject;
                        callOrderProduct.callOrderDates = orderDates;
                        this.call.orderProducts.push(callOrderProduct);
                    }
                }
            }

            await this.saveCallAndNotify();
        }
    }

    buildOrderDates(
        dateAvailable: Date | undefined,
        selectedProject: Project | undefined,
    ): CallOrderDate[] {
        const orderDates: CallOrderDate[] = [];
        const earliestOrderDate = dateAvailable ?? new Date();
        const projectOrderDates = selectedProject?.projectOrderDates ?? [];
        projectOrderDates.sort((a, b) => a.orderDate.getTime() - b.orderDate.getTime());
        if (!selectedProject || projectOrderDates.length === 0) {
            orderDates.push({
                orderDate: earliestOrderDate,
                quantity: 1
            });
        } else {
            //If there is no order date 1 in the project, add an order date
            //for the earliest order date. Otherwise, add all project dates and update to be
            //the earliest order date, or later.
            if (!projectOrderDates.some(d => d.dateIndex === 1)) {
                orderDates.push({
                    orderDate: earliestOrderDate,
                    quantity: 1
                });
            }
            projectOrderDates.forEach((d, i) => {
                const newDate = {
                    orderDate: moment(d.orderDate).isBefore(earliestOrderDate) ?
                        earliestOrderDate :
                        d.orderDate,
                    quantity: i === 0 ? 1 : 0
                };
                if (!orderDates.some(
                    d => d.orderDate.getTime() === newDate.orderDate.getTime()
                )) {
                    orderDates.push(newDate);
                }
            });
        }
        return orderDates;
    }

    async addProductGratis(product: Product): Promise<void> {
        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            this.isFinalRetailReceiptPrinted = false;
            if (product && product.id) {
                this.call.gratisProducts ??= [];

                let callGratisProduct = this.call.gratisProducts.find(
                    (gratisProduct) => gratisProduct.productId === product.id
                );
                if (!callGratisProduct) {
                    callGratisProduct = new CallGratisProduct();
                    callGratisProduct.id = newSequentialId();
                    callGratisProduct.productId = product.id;
                    callGratisProduct.quantity = 1;
                    callGratisProduct.units =
                        product.returnableUpc?.noOfEaches ?? 1;
                    callGratisProduct.value = 0;
                    callGratisProduct.upc = product.returnableUpc?.upc;
                    this.call.gratisProducts.push(callGratisProduct);
                }
            }

            await this.saveCallAndNotify();
        }
    }

    async addProductIn(product: Product): Promise<void> {
        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            this.isFinalRetailReceiptPrinted = false;
            if (product && product.id) {
                this.call.exchangeInProducts ??= [];

                let callProductIn = this.call.exchangeInProducts.find(
                    (exchangeInproduct) =>
                        exchangeInproduct.productId === product.id
                );
                if (!callProductIn) {
                    callProductIn = new CallExchangeInProduct();
                    callProductIn.id = newSequentialId();
                    callProductIn.productId = product.id;
                    callProductIn.quantity = 1;
                    callProductIn.units =
                        product.returnableUpc?.noOfEaches ?? 1;
                    callProductIn.price = 0;
                    callProductIn.wholesalePrice = 0;
                    callProductIn.upc = product.returnableUpc?.upc;
                    this.call.exchangeInProducts.push(callProductIn);
                }
            }

            await this.saveCallAndNotify();
        }
    }

    async addProductOut(product: Product): Promise<void> {
        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            this.isFinalRetailReceiptPrinted = false;
            if (product && product.id) {
                this.call.exchangeOutProducts ??= [];

                let callProductOut = this.call.exchangeOutProducts.find(
                    (productOut) => productOut.productId === product.id
                );
                if (!callProductOut) {
                    callProductOut = new CallExchangeOutProduct();
                    callProductOut.id = newSequentialId();
                    callProductOut.productId = product.id;
                    callProductOut.quantity = 1;
                    callProductOut.units =
                        product.returnableUpc?.noOfEaches ?? 1;
                    callProductOut.price = 0;
                    callProductOut.wholesalePrice = 0;
                    callProductOut.upc = product.returnableUpc?.upc;
                    this.call.exchangeOutProducts.push(callProductOut);
                }
            }

            await this.saveCallAndNotify();
        }
    }

    async blockCall(
        employeeId: string,
        modalRef: SwisherOverlayRef<CountDownViewmodel, CountDownComponent>,
        overlayService: OverlayService,
    ): Promise<boolean> {
        let rtn = false;
        const lastCompletedCallResponse = await this.callDelineationService.getLastCompletedCallByEmployeeId(
            employeeId
        );
        if (lastCompletedCallResponse?.values) {
            const lastCompletedCall = lastCompletedCallResponse.values;
            const minutes = 1;
            const ms = 1000 * 60 * minutes;
            const now = new Date();
            const stopTime = new Date(Math.ceil(lastCompletedCall.stopTime.getTime() / ms));

            if (now.getTime() < stopTime.getTime() * ms) { // round up to the minute to match the time log
                const data = new CountDownViewmodel();
                data.message = "You can begin your next call within ";
                data.secondMessage = " seconds.";
                data.seconds = Math.ceil(60 - now.getSeconds());
                data.buttonLeftText = "Cancel";
                data.buttonLeftFunction = () => modalRef.close();
                data.blocking = false;
                data.headerLeftText = "Call Countdown";
                modalRef = overlayService.open(CountDownComponent, data, true);
                rtn = true;
            }
        }
        return rtn;
    }

    buildCallActivitySurveyViewModelFromSurvey(
        survey: Survey,
        answers?: CallSurveyAnswer[]
    ): ActivitySurveyViewModel {
        return {
            ...survey,
            surveyResponses: (survey?.surveyQuestions ?? []).map((q) => {
                const answer = answers?.find(
                    (a) => a.surveyQuestionId === q.id
                );
                return new ActivitySurveyResponseViewModel(q, answer);
            })
        };
    }

    buildBasicCurrentCallModal(
        customerName: string
    ): ConfirmationDialogViewmodel {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "Current call for " +
            customerName +
            " must be canceled or completed before beginning another call. Would you like to cancel that call or navigate to it?";
        data.buttonLeftText = `Cancel previous call`;
        data.buttonRightText = "Navigate to previous call";
        data.blocking = true;
        return data;
    }

    async shareCall(
        employeeId: string,
        employeeName: string,
        shareIds: string[],
        callComments: string
    ): Promise<void> {
        for (const shareId of shareIds) {
            const request = new NotificationRequestDto();
            request.sendEmail = true;
            request.id = newSequentialId();
            request.employeeId = employeeId;
            request.recipientEmployeeId = shareId;
            request.subject = `Please Review Call Notes ${this.customerStateService.customer.customerNumber} ${this.customerStateService.customer.name}`;
            request.message = this.formatCallMessage(
                employeeName,
                callComments
            );

            await this.notificationDelineationService.send(request);
        }
    }

    async completeCall(): Promise<boolean> {
        if (this.call.stopTime) {
            return false
        } else {
            await this.pleaseWaitService.withSpinnerShowing(async () => {
                this.call.stopTime = new Date();
                if (
                    this.call.startTime.getFullYear() !==
                    this.call.stopTime.getFullYear() ||
                    this.call.startTime.getMonth() !== this.call.stopTime.getMonth() ||
                    this.call.startTime.getDate() !== this.call.stopTime.getDate()
                ) {
                    this.call.stopTime = new Date(this.call.startTime);
                    this.call.stopTime.setHours(23, 59, 59);
                }

                const contactResponse = await this.contactDelineationService.getByCustomerId(
                    this.customerStateService.customer.id
                );

                if (contactResponse) {
                    const contacts = contactResponse.values;

                    this.customerStateService.customer.availability = CustomerConverterService.getCustomerAvailability(
                        contacts
                    );
                }

                this.customerStateService.customer.lastCall = this.call.stopTime;
                this.call.px3RankId = this.customerStateService.customer.px3RankId;
                this.call.px3Rank = this.call.px3RankId
                    ? (await this.px3Service.getById(this.call.px3RankId)).rank
                    : null;

                await this.callDelineationService.save(this.call);

                await this.customerDelineationService.upsertCustomer(
                    this.customerStateService.customer
                );

                await this.timeEntryDelineationService.saveCallTimeEntry(
                    this.call,
                    this.customerStateService.customer
                );
                await this.routeDelineationService.updateRouteByCall(this.call);
                // fire off the outbound sync so the user doesn't have to wait for the outbound timer
                this.syncService.forceOutboundSync();
            });
            return true
        }
    }

    async deleteCall(): Promise<void> {
        if (!this.call) return;

        await this.callDelineationService.delete(
            this.call,
            DexieTableNames.calls
        );
        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.wholesale ||
            this.call?.callType === CallTypes.rmWholesale
        )
            await this.pictureDelineationService.bulkDelete(
                this.call.callPictures,
                DexieTableNames.callPictures
            );
    }

    async getLocalCallsByCustomerId(
        id: string
    ): Promise<GenericResponseDto<Call[]>> {
        return await this.callDelineationService.getLocalCallsByCustomerId(id);
    }

    async getLastCallOfTypeByCustomerId(
        id: string,
        callType: CallTypes
    ): Promise<GenericResponseDto<Call>> {
        return await this.callDelineationService.getLastCallOfTypeByCustomerId(id, callType);
    }

    async getOpenCall(currentEmployee: Employee): Promise<Call | undefined> {
        const response = await this.callDelineationService.getOpenCallByEmployeeId(
            currentEmployee.id
        );

        return response ? response.values : undefined;
    }

    async getLastCompletedCallByEmployeeId(currentEmployee: Employee): Promise<Call | undefined> {
        const response = await this.callDelineationService.getLastCompletedCallByEmployeeId(
            currentEmployee.id
        );

        return response ? response.values : undefined;
    }

    async removeProductFromCash(productId: string): Promise<void> {
        if (
            productId &&
            (this.call?.callType === CallTypes.retail ||
                this.call?.callType === CallTypes.rmWholesale) &&
            this.call.productsCash?.includes(productId)
        ) {
            this.isFinalRetailReceiptPrinted = false;
            const index: number = this.call.productsCash.indexOf(productId);
            if (index !== -1) {
                this.call.productsCash.splice(index, 1);
            }

            if (this.call.cashProducts && this.call.cashProducts.length > 0) {
                let cashIndex: number = this.call.cashProducts.findIndex(
                    (cashProduct) => cashProduct.productId === productId
                );

                while (cashIndex !== -1) {
                    this.call.cashProducts.splice(cashIndex, 1);
                    cashIndex = this.call.cashProducts.findIndex(
                        (cashProduct) => cashProduct.productId === productId
                    );
                }
            }

            await this.saveCallAndNotify();
        }
    }

    async removeAProductFromGratisById(productId: string): Promise<void> {
        if (
            productId &&
            (this.call?.callType === CallTypes.retail ||
                this.call?.callType === CallTypes.rmWholesale)
        ) {
            if (
                this.call.gratisProducts &&
                this.call.gratisProducts.length > 0
            ) {
                this.isFinalRetailReceiptPrinted = false;
                const gratisIndex: number = this.call.gratisProducts.findIndex(
                    (gratisProduct) => gratisProduct.productId === productId
                );

                if (gratisIndex !== -1) {
                    // called from sales/Gratis. Here we are only removing 1 item based on its Id
                    this.call.gratisProducts.splice(gratisIndex, 1);
                }
            }

            await this.saveCallAndNotify();
        }
    }
    async removeAllProductFromGratisByProductId(
        productId: string
    ): Promise<void> {
        if (
            productId &&
            (this.call?.callType === CallTypes.retail ||
                this.call?.callType === CallTypes.rmWholesale)
        ) {
            if (
                this.call.gratisProducts &&
                this.call.gratisProducts.length > 0
            ) {
                this.isFinalRetailReceiptPrinted = false;
                let gratisIndex: number = this.call.gratisProducts.findIndex(
                    (gratisProduct) => gratisProduct.productId === productId
                );

                while (gratisIndex !== -1) {
                    // Called from the distribution modal. If a product is unchecked, remove all instances of the product
                    this.call.gratisProducts.splice(gratisIndex, 1);
                    gratisIndex = this.call.gratisProducts.findIndex(
                        (gratisProduct) => gratisProduct.productId === productId
                    );
                }
            }

            await this.saveCallAndNotify();
        }
    }

    async removeAProductFromProductInById(productId: string): Promise<void> {
        if (
            productId &&
            (this.call?.callType === CallTypes.retail ||
                this.call?.callType === CallTypes.rmWholesale)
        ) {
            if (
                this.call.exchangeInProducts &&
                this.call.exchangeInProducts.length > 0
            ) {
                this.isFinalRetailReceiptPrinted = false;
                const productInIndex: number = this.call.exchangeInProducts.findIndex(
                    (product) => product.productId === productId
                );

                if (productInIndex !== -1) {
                    // called from sales/Gratis. Here we are only removing 1 item based on its Id
                    this.call.exchangeInProducts.splice(productInIndex, 1);
                }
            }

            await this.saveCallAndNotify();
        }
    }

    async removeAProductFromProductOutById(productId: string): Promise<void> {
        if (
            productId &&
            (this.call?.callType === CallTypes.retail ||
                this.call?.callType === CallTypes.rmWholesale)
        ) {
            if (
                this.call.exchangeOutProducts &&
                this.call.exchangeOutProducts.length > 0
            ) {
                this.isFinalRetailReceiptPrinted = false;
                const productOutIndex: number = this.call.exchangeOutProducts.findIndex(
                    (productOut) => productOut.productId == productId
                );

                if (productOutIndex !== -1) {
                    this.call.exchangeOutProducts.splice(productOutIndex, 1);
                }
            }

            await this.saveCallAndNotify();
        }
    }

    async removeAllProductFromProductInByProductId(
        productId: string
    ): Promise<void> {
        if (
            productId &&
            (this.call?.callType === CallTypes.retail ||
                this.call?.callType === CallTypes.rmWholesale)
        ) {
            if (
                this.call.exchangeInProducts &&
                this.call.exchangeInProducts.length > 0
            ) {
                this.isFinalRetailReceiptPrinted = false;
                let productInIndex: number = this.call.exchangeInProducts.findIndex(
                    (product) => product.productId === productId
                );

                while (productInIndex !== -1) {
                    // Called from the distribution modal. If a product is unchecked, remove all instances of the product
                    this.call.exchangeInProducts.splice(productInIndex, 1);
                    productInIndex = this.call.exchangeInProducts.findIndex(
                        (product) => product.productId === productId
                    );
                }
            }

            await this.saveCallAndNotify();
        }
    }

    async removeAllProductFromProductOutByProductId(
        productId: string
    ): Promise<void> {
        if (
            productId &&
            (this.call?.callType === CallTypes.retail ||
                this.call?.callType === CallTypes.rmWholesale)
        ) {
            if (
                this.call.exchangeOutProducts &&
                this.call.exchangeOutProducts.length > 0
            ) {
                this.isFinalRetailReceiptPrinted = false;
                let productOutIndex: number = this.call.exchangeOutProducts.findIndex(
                    (productOut) => productOut.productId === productId
                );

                while (productOutIndex !== -1) {
                    // Called from the distribution modal. If a product is unchecked, remove all instances of the product
                    this.call.exchangeOutProducts.splice(productOutIndex, 1);
                    productOutIndex = this.call.exchangeOutProducts.findIndex(
                        (productOut) => productOut.productId === productId
                    );
                }
            }

            await this.saveCallAndNotify();
        }
    }

    async removeProductFromCos(productId: string): Promise<void> {
        if (
            productId &&
            (this.call?.callType === CallTypes.retail ||
                this.call?.callType === CallTypes.rmWholesale) &&
            this.call.productsCos &&
            this.call.productsCos.includes(productId)
        ) {
            const index: number = this.call.productsCos.indexOf(productId);
            if (index !== -1) {
                this.call.productsCos.splice(index, 1);
                await this.saveCallAndNotify();
            }
        }
    }

    async removeProductFromDist(productId: string): Promise<void> {
        if (productId) {
            const addProductsInDist: string[] = [];
            const removeProductFromDist: string[] = [productId];
            await this.addAndRemoveProductsFromDist(
                addProductsInDist,
                removeProductFromDist
            );
        }
    }

    async removeProductFromIntro(productId: string): Promise<void> {
        if (
            productId &&
            (this.call?.callType === CallTypes.retail ||
                this.call?.callType === CallTypes.rmWholesale) &&
            this.call.productsIntro &&
            this.call.productsIntro.includes(productId)
        ) {
            const index: number = this.call.productsIntro.indexOf(productId);
            if (index !== -1) {
                this.call.productsIntro.splice(index, 1);
                await this.saveCallAndNotify();
            }
        }
    }
    async removeProductFromOos(productId: string): Promise<void> {
        if (
            productId &&
            (this.call?.callType === CallTypes.retail ||
                this.call?.callType === CallTypes.rmWholesale) &&
            this.call.productsOos &&
            this.call.productsOos.includes(productId)
        ) {
            const index: number = this.call.productsOos.indexOf(productId);
            if (index !== -1) {
                this.call.productsOos.splice(index, 1);
                await this.saveCallAndNotify();
            }
        }
    }

    async removeProductFromOrder(
        productId: string,
        wholesalerId: string
    ): Promise<void> {
        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.chainHq ||
            this.call?.callType === CallTypes.rmWholesale
        ) {
            if (
                productId &&
                this.call.productsOrder &&
                this.call.productsOrder.includes(
                    productId + (wholesalerId ?? "")
                )
            ) {
                const productAndWholesalerId = productId + (wholesalerId ?? "");
                this.isFinalWholesaleReceiptPrinted = false;
                const index: number = this.call.productsOrder.indexOf(
                    productAndWholesalerId
                );
                if (index !== -1) {
                    this.call.productsOrder.splice(index, 1);
                }

                if (
                    this.call.orderProducts &&
                    this.call.orderProducts.length > 0
                ) {
                    let orderIndex: number = this.call.orderProducts.findIndex(
                        (orderProduct) =>
                            orderProduct.productId === productId &&
                            (orderProduct.wholesalerCustomerId ?? "") ===
                            (wholesalerId ?? "")
                    );

                    while (orderIndex !== -1) {
                        this.call.orderProducts.splice(orderIndex, 1);
                        orderIndex = this.call.orderProducts.findIndex(
                            (orderProduct) =>
                                orderProduct.productId === productId &&
                                (orderProduct.wholesalerCustomerId ?? "") ===
                                (wholesalerId ?? "")
                        );
                    }
                }
            }

            await this.saveCallAndNotify();
        }
    }

    async saveCallAndNotify(): Promise<void> {
        if (this.call) {
            this.setActivityAndSurveyStatus();
        }
        await this.saveCall();

        //Notify after everything is updated and saved
        this.callSubject.next(this.call);
    }

    async saveCallEntry(call: WholsaleCallViewmodel): Promise<void> {
        if (this.call?.callType === CallTypes.wholesale) {
            this.call.isReturns = call.isReturns;
            this.call.isPurchase = call.isPurchase;
            this.call.isSwisherDay = call.isSwisherDay;
            this.call.isTradeShow = call.isTradeShow;

            this.call.purchaseInvoice = call.purchaseInvoice;
            this.call.purchaseAmount = call.purchaseAmount;
            this.call.creditMemo = call.creditMemo;
            this.call.creditAmount = call.creditAmount;
            this.call.billThrough = call.billThrough;

            await this.saveCallAndNotify();
        }
    }

    async saveClosingNotes(closingNotes: string): Promise<void> {
        if (this.call) {
            this.call.closingNotes = closingNotes;
            await this.saveCallAndNotify();
        }
    }

    setActivityAndSurveyStatus(): void {
        const answers = new Array<CallSurveyAnswer>();
        for (const survey of this.call.surveys) {
            for (const response of survey.surveyResponses) {
                if (
                    (response.surveyQuestionType.id !==
                        SurveyQuestionTypes.CheckBox &&
                        response.answer?.answer) ||
                    (response.surveyQuestionType.id ===
                        SurveyQuestionTypes.CheckBox &&
                        response.answer?.answer === "true")
                ) {
                    answers.push(response.answer);
                }
            }
        }
        this.call.surveyAnswers = answers;
    }


    /**
     * Returns the appropriate call type for a given customer and employee.
     * @param customer - The customer.
     * @param employee - The employee.
     * @returns The appropriate call type for the given customer and employee, or undefined if no call type is found.
     */
    getCallTypeForCustomerAndEmployee(customer: Customer, employee: Employee): CallTypes | undefined {
        const customerType = customer.customerType.id;
        const isTM = employee.searchableZrt?.length === 4;
        if (customerType === CustomerTypeEnum.ChainRetail || customerType === CustomerTypeEnum.IndependentRetail) {
            return CallTypes.retail;
        }
        if (customerType === CustomerTypeEnum.ChainHQ) {
            return CallTypes.chainHq;
        }
        if (customerType === CustomerTypeEnum.DirectWholesaler && isTM) {
            return CallTypes.wholesale;
        }
        if ((customerType === CustomerTypeEnum.DirectWholesaler && !isTM) || customerType === CustomerTypeEnum.IndirectWholesaler) {
            return CallTypes.rmWholesale;
        }
    }

    //Private methods
    private formatCallMessage(employee: string, comment: string): string {
        let rtn = "";

        const createdLine = `Call Made By: ${employee}`;
        const commentLine = "Comment: " + (comment ?? "None");
        const customerLine =
            `Customer ID: <a href="${window.location.origin}/accounts/${this.customerStateService.customer.id}/profile">`
            + `${this.customerStateService.customer.customerNumber}</a>`;
        const storeNameLine =
            "Store Name: " + this.customerStateService.customer.name;
        const storeAddressLine =
            "Store Address: " +
            Helper.formatAddress(
                this.customerStateService.customer.businessAddress
            );
        const closingNotesLine =
            "Closing Notes: " + (this.call.closingNotes ?? "None");

        rtn = `<p>${createdLine}</p>
               <p>${commentLine}</p>
               <br/>
               <p>${customerLine}</p>
               <p>${storeNameLine}</p>
               <p>${storeAddressLine}</p>
               <br/>
               <p>${closingNotesLine}</p>`;

        return rtn;
    }

    private async saveCall(): Promise<void> {
        this.callDelineationService.persist(this.call, DexieTableNames.calls);
    }

    replacePicture(
        oldPicture: CallPicture,
        newPicture: CallPicture,
        image: string
    ): void {
        if (
            this.call?.callType === CallTypes.retail ||
            this.call?.callType === CallTypes.rmWholesale ||
            this.call?.callType === CallTypes.wholesale
        ) {
            const pictureIndex = this.pictures.findIndex(
                (p) => p.id === oldPicture.id
            );
            if (pictureIndex > -1) {
                const picture = this.pictures[pictureIndex];
                picture.id = newPicture.id;
                picture.image = image;
                const callPictureIndex = this.call.callPictures.findIndex(
                    (p) => p.id === oldPicture.id
                );
                this.call.callPictures[callPictureIndex] = newPicture;
            } else {
                this.call.callPictures.push(newPicture);
            }
        }
    }

    async savePicturesAndNotify(): Promise<void> {
        await this.pictureDelineationService.persistAll(
            this.pictures,
            DexieTableNames.callPictures
        );
        await this.saveCallAndNotify();

        // Notify after everything is updated and saved
        this.picturesSubject.next(this.pictures);
        this.callSubject.next(this.call);
    }
}

export type Call = RetailCall | WholesaleCall | RmWholesaleCall | ChainHqCall;
