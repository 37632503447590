<app-grid #grid [(dataSource)]="viewmodel.dataSource" [columnDefinitions]="viewmodel?.columnDef"
    [searchRefiners]="viewmodel?.activeRefiners"
    [isFixedLayout]="false" [height]="viewmodel.gridHeight"
    [width]="viewmodel.gridWidth"
    [isEnabledExpandedDetails]="viewmodel.isEnabledExpandedDetails"
    [itemsRendedInViewPort]="viewmodel?.itemsRendedInViewPort"
    [isSortable]="viewmodel.isSortable"
    [isSelectable]="true"
    (headerSelectionChanged)="viewmodel.onHeaderSelectionChanged($event)"
    (rowSelectionChange)="onRowSelectionChange()">
</app-grid>
