import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { GenericDropDownDto, valueSeparator } from "shield.shared";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { Customer } from "src/app/entity-models/customer.entity";

@Injectable()
export class ActivitiesFilterService {
    chainsLoaded = false;
    projectsLoaded = false;
    productGroupsLoaded = false;
    productsLoaded = false;
    wholesalersLoaded = false;

    // Chains
    private _chains: AccountOwnership[] = [];
    chainsSubject: BehaviorSubject<AccountOwnership[]> = new BehaviorSubject(
        this._chains
    );
    get chains(): AccountOwnership[] {
        return this._chains;
    }
    set chains(value: AccountOwnership[]) {
        this._chains = value;
        this.chainsLoaded = true;
        this.chainsSubject.next(value);
    }
    observableChains: Observable<AccountOwnership[]> = this.chainsSubject.asObservable();

    getChainDataValues(chains: AccountOwnership[], ownerCode?: boolean): string {
        let rtn = "";

        for (const chain of chains) {
            const childValues = this.getChainDataValues(chain.children, ownerCode);
            rtn = rtn.concat(`${childValues ? childValues + valueSeparator : ""}${ownerCode ? chain.ownerCode : chain.id}${valueSeparator}`);
        }

        rtn = rtn.slice(0, rtn.length - 1);

        return rtn;
    }

    // Projects
    private _projects: GenericDropDownDto[] = [];
    projectsSubject: BehaviorSubject<GenericDropDownDto[]> = new BehaviorSubject(
        this._projects
    );
    get projects(): GenericDropDownDto[] {
        return this._projects;
    }
    set projects(value: GenericDropDownDto[]) {
        this._projects = value;
        this.projectsLoaded = true;
        this.projectsSubject.next(value);
    }
    observableProjects: Observable<GenericDropDownDto[]> = this.projectsSubject.asObservable();

    // Wholesalers
    private _wholesalers: Customer[] = [];
    wholesalersSubject: BehaviorSubject<Customer[]> = new BehaviorSubject(
        this._wholesalers
    );
    get wholesalers(): Customer[] {
        return this._wholesalers;
    }
    set wholesalers(value: Customer[]) {
        this._wholesalers = value;
        this.wholesalersLoaded = true;
        this.wholesalersSubject.next(value);
    }
    observableWholesalers: Observable<Customer[]> = this.wholesalersSubject.asObservable();

    // Products
    private _products: GenericDropDownDto[] = [];
    productsSubject: BehaviorSubject<GenericDropDownDto[]> = new BehaviorSubject(
        this._products
    );
    get products(): GenericDropDownDto[] {
        return this._products;
    }
    set products(value: GenericDropDownDto[]) {
        this._products = value;
        this.productsLoaded = true;
        this.productsSubject.next(value);
    }
    observableProducts: Observable<GenericDropDownDto[]> = this.productsSubject.asObservable();

    // Products
    private _productGroups: GenericDropDownDto[] = [];
    productGroupsSubject: BehaviorSubject<GenericDropDownDto[]> = new BehaviorSubject(
        this._productGroups
    );
    get productGroups(): GenericDropDownDto[] {
        return this._productGroups;
    }
    set productGroups(value: GenericDropDownDto[]) {
        this._productGroups = value;
        this.productGroupsLoaded = true;
        this.productGroupsSubject.next(value);
    }
    observableProductGroups: Observable<GenericDropDownDto[]> = this.productGroupsSubject.asObservable();
}
