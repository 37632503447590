// eslint-disable-next-line no-shadow
export enum RmWholesaleStepperStep {
    inDistribution = 0,
    salesGratis = 1,
    exchange = 2,
    receipts = 3,
    activitySurveys = 4,
    after = 5,
    closingNotes = 6
}
