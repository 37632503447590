<ng-container *ngIf="viewmodel">
<ng-container>
    <div class="row">
        <div class="col-12">
            <app-page-header #header [name]="viewmodel.headerName"
                [entryCount]="viewmodel.total ? viewmodel.total : 0" [buttons]="viewmodel.headerButtons"
                [refinerService]="viewmodel.refinerService" [subAvailableColumns]="viewmodel.availableColumns"
                (onClickButton)="viewmodel.onButtonClickEvent($event)"
                (onButtonAction)="viewmodel.onButtonAction($event)"
                (headerExpansionChanged)="viewmodel.headerExpansionChanged()">
            </app-page-header>
        </div>
    </div>
    <div class="d-flex p-2 justify-content-center align-items-center">
        <div>
            <button class="btn btn-secondary mr-2" data-toggle="tooltip" data-placement="bottom" title="View Filters"
                (click)="viewmodel.showFilters()">
                <span class="white-space-nowrap">
                    <span class="material-icons">filter_alt</span>
                    Filters •
                    {{ viewmodel.refinerService.refiners.length }}
                </span>
            </button>
        </div>
        <div class="flex-grow-1">
            <mat-form-field appearance="outline" color="accent" [formGroup]="viewmodel.dateForm">
                <mat-label>Call on or After</mat-label>
                <input #startDateInput matInput formControlName="startDate" [max]="viewmodel.startMaxDate"
                    [matDatepicker]="afterPicker" (keyup)="viewmodel.onAddStartDateRefiner($event)"
                    (dateChange)="viewmodel.onAddStartDateRefiner()"
                    (blur)="viewmodel.onAddStartDateRefiner($event)" />
                <mat-datepicker touchUi #afterPicker></mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="afterPicker"></mat-datepicker-toggle>
            </mat-form-field>
            <mat-error *ngIf="
                    viewmodel.dateForm.invalid
                ">
                Date format invalid.
            </mat-error>
            <mat-error *ngIf="
                    viewmodel.dateForm
                        .get('startDate')
                        .hasError('dateGreaterThanEndDate')
                ">
                Start date can not be greater than End date.
            </mat-error>
        </div>
        <div class="flex-grow-1">
            <mat-form-field appearance="outline" color="accent" [formGroup]="viewmodel.dateForm">
                <mat-label>Call on or Before</mat-label>
                <input #endDateInput matInput formControlName="endDate" [min]="viewmodel.endMinDate"
                    [max]="viewmodel.endMaxDate" [matDatepicker]="beforePicker"
                    (dateChange)="viewmodel.onAddEndDateRefiner()"
                    (keyup)="viewmodel.onAddEndDateRefiner($event)" (blur)="viewmodel.onAddEndDateRefiner($event)" />
                <mat-datepicker touchUi #beforePicker></mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="beforePicker"></mat-datepicker-toggle>
            </mat-form-field>
            <mat-error *ngIf="
                    viewmodel.dateForm.invalid
                ">
                Date format invalid.
            </mat-error>
            <mat-error *ngIf="
                    viewmodel.dateForm
                        .get('endDate')
                        .hasError('dateLessThanStartDate')
                ">
                End date can not be less than Start date.
            </mat-error>
        </div>
        <div class="d-flex ml-1 mr-4">
            <mat-checkbox color="warn"
                    [indeterminate]="false"
                    [(ngModel)]="viewmodel.lastCall"
                    (ngModelChange)="viewmodel.onLastCallCheckboxChange()">
                    Last Call on Account
                </mat-checkbox>
        </div>
        <div class="d-flex">
            <button appThrottleClick mat-raised-button (throttledClick)="viewmodel.search()" [disabled]="viewmodel.isSearchButtonDisabled" class="btn btn-primary mr-2">SEARCH</button>
            <button mat-raised-button (click)="viewmodel.reset()" class="btn btn-secondary">RESET</button>
        </div>
    </div>
</ng-container>
    <mat-sidenav-container class="sidenav-container flex-grow-1" autosize>
        <mat-sidenav #drawer [mode]="viewmodel.drawerMode" [fixedInViewport]="true"
            [fixedTopGap]="viewmodel.topGapDistance" [fixedBottomGap]="0" class="sidenav">
            <app-filter-container [refinerService]="viewmodel.refinerService" [filters]="viewmodel.filters"
                [filterLocation]="viewmodel.filterLocation" [isSearchDisabled]="viewmodel.isSearchButtonDisabled"
                (search)="viewmodel.search()" (reset)="viewmodel.reset()">
            </app-filter-container>
        </mat-sidenav>
        <mat-sidenav-content #sideNavContent class="overflow-hidden">
            <div class="sidenav-content">
                <ng-container>
                    <div class="d-flex flex-grow-1">
                        <div class="table-responsive">
                            <div class="pictures-viewport">
                                <table class="table table-bordered table-hover" #grid mat-table recycleRows [dataSource]="viewmodel.dataSource"
                                    [fixedLayout]="true" matSort>
                                    <ng-container [matColumnDef]="viewmodel.columns.pictures">
                                        <th mat-header-cell
                                            *matHeaderCellDef
                                            class="picture-column grid-cell">
                                            <b class="header-underline table-header">Pictures
                                            </b>
                                        </th>
                                        <td mat-cell class="grid-cell" *matCellDef="let element">
                                            <div class="row">
                                                <div class="col-2 chevron-container padding-left-picture-type">
                                                    <span class="chevron material-icons" (click)="carousel.previous()">chevron_left</span>
                                                </div>
                                                <div class="col carousel-container">
                                                    <mat-carousel #carousel (change)="setPictureType($event, element)" [autoplay]="false" color="primary"
                                                        [proportion]="75" maxWidth="1400px" [slides]="element.data.pictures.length" [loop]="true"
                                                        [hideArrows]="true" [hideIndicators]="false" [useKeyboard]="true" [useMouseWheel]="true"
                                                        orientation="ltr" >
                                                        <!-- <mat-carousel-slide *ngIf="element.data['hasBeforePicture']">
                                                            <img (click)="openPictureInModal(element.data['beforePicture'], element.data)" [src]="element.data['beforePicture']?.url" onError="this.src='assets/img/missing-picture.png'"
                                                                style="height: 100% !important; width: 100%;"/>
                                                        </mat-carousel-slide> -->
                                                        <!-- <mat-carousel-slide *ngIf="!element.data['beforePicture']?.url && !element.data['hasBeforePicture']"
                                                            [image]="'assets/img/no-before-call-picture.png'"
                                                            overlayColor="#00000040"
                                                            [hideOverlay]="true">
                                                        </mat-carousel-slide> -->
                                                        <!-- <mat-carousel-slide  *ngIf="element.data['hasAfterPicture']">
                                                            <img (click)="openPictureInModal(element.data['afterPicture'], element.data)" [src]="element.data['afterPicture']?.url" onerror="this.src='assets/img/missing-picture.png'"
                                                                style="height: 100% !important; width: 100%;"/>
                                                        </mat-carousel-slide> -->
                                                        <!-- <mat-carousel-slide *ngIf="!element.data['afterPicture']?.url && !element.data['hasAfterPicture']"
                                                            [image]="'assets/img/no-after-call-picture.png'"
                                                            overlayColor="#00000040"
                                                            [hideOverlay]="true">
                                                        </mat-carousel-slide> -->
                                                        <mat-carousel-slide *ngFor="let picture of element.data['pictures']">
                                                            <img [src]="picture?.url" (click)="openPictureInModal(picture, element.data)" onerror="this.src='assets/img/missing-picture.png'"
                                                                style="height: 100% !important; width: 100%;"/>
                                                        </mat-carousel-slide>
                                                    </mat-carousel>
                                                    <div *ngIf="!typeArr[element.index]" class="d-flex p-2 justify-content-between align-items-baseline">
                                                        <h2>{{viewmodel.gridData[element.index].data.pictures[0].type}}</h2>
                                                        <p>Tags: {{viewmodel.gridData[element.index].data.pictures[0].tags || 'N/A'}}</p>
                                                    </div>
                                                    <div *ngIf="typeArr[element.index]" class="d-flex p-2 justify-content-between align-items-baseline">
                                                        <h2>{{typeArr[element.index]}}</h2>
                                                        <p>Tags: {{tagArr[element.index]}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-3 chevron-container padding-right-picture-type">
                                                    <span class="chevron material-icons" (click)="carousel.next()">chevron_right</span>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="viewmodel.columns.storeInformation">
                                        <th mat-header-cell matRipple
                                            *matHeaderCellDef mat-sort-header
                                            class="store-column grid-cell"
                                                (click)="viewmodel.onSort(viewmodel.columns.storeInformation)">
                                            <b class="header-underline">Store Information
                                            </b>
                                        </th>
                                        <td mat-cell class="grid-cell" *matCellDef="let element">
                                            <h3>{{ element.data["customerNumber"] }}</h3>
                                            <b>Name: </b><a class="text-teal"
                                                (click)="viewmodel.routeToStoreProfile(element.index)">{{ element.data["customerName"] }}</a><br/>
                                            <b>Address:</b> {{ element.data["address"] }}<br/>
                                            <b>City:</b> {{ element.data["city"] }}<br/>
                                            <b>State:</b> {{ element.data["state"] }}<br/>
                                            <b>ZRT:</b> {{ element.data["zrt"] }}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="viewmodel.columns.lastCall">
                                        <th mat-header-cell matRipple
                                            *matHeaderCellDef mat-sort-header
                                            class="call-column grid-cell"
                                            (click)="viewmodel.onSort(viewmodel.columns.lastCall)">
                                            <b class="header-underline">Last Call
                                            </b>
                                        </th>
                                        <td mat-cell class="grid-cell" *matCellDef="let element">
                                            <b>Call Date:</b> {{ element.data["callDate"] }}<br/>
                                            <b>Call Type:</b> {{ element.data["callType"] }}<br/>
                                            <b>Called By:</b> {{ element.data["createdByName"] }}<br/>
                                            <b>Start Time:</b> {{ element.data["startFormatted"] }}<br/>
                                            <b>Stop Time:</b> {{ element.data["endFormatted"] }}<br/>
                                            <b>Duration:</b> {{ element.data["duration"] }}<br/>
                                            <b>Call Comments:</b> {{ element.data["closingNotes"] ? element.data["closingNotes"] : 'N/A' }}
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="viewmodel.displayedColumns;sticky: true"></tr>
                                    <tr mat-row class="element-row" *matRowDef="let row; columns: viewmodel.displayedColumns;"></tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>
