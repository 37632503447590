import { Injectable } from "@angular/core";
import { ReceiptSettings } from "src/app/entity-models/receipt-settings.entity";
import { DatabaseService } from "../database.service";

@Injectable()
export class ReceiptSettingsOfflineService {

    constructor(private dbService: DatabaseService){}

    async getReceiptSettingsByEmployeeId(id: string): Promise<ReceiptSettings> {

        return await this.dbService.receiptSettings.where("employeeId")
            .equals(id)
            .first();
    }

}
