import {
    AfterContentChecked,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import { FormBuilder } from "@angular/forms";

import { MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import { OverlayService } from "src/app/services/overlay.service";

import { GridComponent } from "src/app/shared/grid/grid.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { RouteManagementViewmodel } from "./route-management.viewmodel";
import { AppStateService } from "src/app/services/app-state.service";
import { Router } from "@angular/router";
import { RouteManagementRefinerService } from "./route-management-refiner.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { RouteManagementService } from "./route-management.service";
import { RouteDelineationService } from "src/app/services/delineation-services/route-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { PingService } from "src/app/services/ping.service";
import { RouteManagementZrtFilterService } from "./route-management-zrt-filter.service";
import { ZrtTreeViewComponent } from "src/app/shared/zrt-tree-view/zrt-tree-view.component";


@Component({
    selector: "app-route-management",
    templateUrl: "./route-management.component.html",
    styleUrls: ["./route-management.component.scss"],
    providers: [PleaseWaitService]
})
export class RouteManagementComponent
    implements OnInit, AfterContentChecked, OnDestroy {
    @HostBinding("class") class = "worksheet-static d-flex flex-column flex-grow-1";
    // ViewChilds
    @ViewChild("drawer") drawer: MatSidenav;
    @ViewChild("grid") grid: GridComponent;
    @ViewChild("sideNavContent") sideNavContent: MatSidenavContent;
    @ViewChild("header") header: PageHeaderComponent;
    @ViewChild("startDateInput") startDateInput: ElementRef;
    @ViewChild("endDateInput") endDateInput: ElementRef;
    @ViewChild("zrtTree") zrtTree: ZrtTreeViewComponent;

    // Public vars
    viewmodel: RouteManagementViewmodel;
    isViewmodelReady = false;
    screenHeight: number;

    constructor(
        private pleaseWaitService: PleaseWaitService,
        private overlayService: OverlayService,
        private appStateService: AppStateService,
        private pingService: PingService,
        private callDelineationService: CallDelineationService,
        private customerDelineationService: CustomerDelineationService,
        private refinerService: RouteManagementRefinerService,
        private router: Router,
        private routeDelineationService: RouteDelineationService,
        private routeManagementService: RouteManagementService,
        private formBuilder: FormBuilder,
        private employeeDelineationService: EmployeeDelineationService,
        private filterService: FilterService,
        private zrtFilterService: RouteManagementZrtFilterService
    ) {}

    async ngOnInit(): Promise<void> {
        this.viewmodel = new RouteManagementViewmodel(
            this.pleaseWaitService,
            this.overlayService,
            this.appStateService,
            this.pingService,
            this.callDelineationService,
            this.customerDelineationService,
            this.refinerService,
            this.formBuilder,
            this.routeDelineationService,
            this.routeManagementService,
            this.router,
            this.employeeDelineationService,
            this.filterService,
            this.zrtFilterService
        );
    }

    ngAfterContentChecked(): void {
        if (this.isViewmodelReady && !this.viewmodel.isGapSet) {
            this.viewmodel.calculateGap();
        }

        if (!this.isViewmodelReady
            && this.grid
            && this.header
            && this.sideNavContent
            && this.drawer
            && this.startDateInput
            && this.endDateInput
            && this.zrtTree
            ) {
            this.isViewmodelReady = true;
            this.getScreenSize();
            void this.viewmodel.initialize(
                this.grid,
                this.header,
                this.sideNavContent,
                this.drawer,
                this.startDateInput,
                this.endDateInput,
                this.zrtTree
            );
        }
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?: any): void {
        if (this.viewmodel) {
            this.viewmodel.screenHeight = window.innerHeight;
        }
    }
}
