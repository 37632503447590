export class TaxCalculatorRecordViewModel {
    id: string;
    category: string;
    quantityExtention: number;
    quantity: number;
    weight: number;
    price: number;
    stateRate = 0;
    stateTax = 0;
    countyRate = 0;
    countyTax = 0;
    cityTax = 0;
    cityRate = 0;
    totalTax = 0;
    stateCalculationMethod: string;
    cityCalculationMethod: string;
    countyCalculationMethod: string;
    statePrepaid: boolean;
    cityPrepaid: boolean;
    countyPrepaid: boolean;
    noOfPacks?: number;
    productId: string;
}
