export class RegisteredUserDto {
    id: string;
    userId: string;
    employeeId: string;
    otherPhone: string;
    lastSeenUtcDateTime: string | undefined;
    lastSyncAttemptUtcDateTime: string | undefined;
    lastCompletedSyncCycleUtcDateTime: string | undefined;
    createdUtcDateTime: string;
    createdUserId: string;
    createdUserZrt: string;
    createdUserFullName: string;
    modifiedUtcDateTime: string;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    rowversion: string;
}
