import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from "@angular/core";

@Component({
    selector: "shield-share-tashare-chart",
    templateUrl: "./share-tashare-chart.component.html",
    styleUrls: ["./share-tashare-chart.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareTashareChartComponent implements OnInit, OnChanges {
    @Input() public chartTitle: string = "";
    @Input() public variant: ShareTAShareChartVariant = "Normal";
    @Input() public data: ShareTAShareData[];
    @Input() public hasData = false;

    public viewbox: string = "0 0 100 100";
    public gTransform: string = "scale(1,-1) translate(0,-100)";
    public maxBar: number = 100;
    public heightFactor: number = 1.0;
    public chartWidth = "chart";
    public positionedData: PositionedShareTAShareData[] = [];

    constructor(private changeDetection: ChangeDetectorRef) { }

    ngOnChanges(_changes: SimpleChanges): void {
        this.processData();
    }

    ngOnInit(): void {
        this.processData();
    }

    processData(): void {
        this.heightFactor = determineHeightFactor(this.variant);
        this.maxBar = 100 * this.heightFactor;
        this.viewbox = calculateVewBox(
            this.data?.length ?? 0,
            this.heightFactor
        );
        const [_x, _y, _width, height] = this.viewbox.split(" ").map((v) => +v);
        this.gTransform = `scale(1,-1) translate(0,-${height})`;
        this.positionedData = setPositions(this.data, this.viewbox);
        this.chartWidth = "chart";
        if (this.data.length === 2) {
            this.chartWidth += " wide";
        }
        if (this.variant === "Tall") {
            this.chartWidth += " tall";
        }
        this.changeDetection.markForCheck();
    }
}

const determineHeightFactor = (variant: ShareTAShareChartVariant) => {
    switch (variant) {
        case "Tall":
            return 2.6;
        case "Normal":
        default:
            return 1.0;
    }
};

const calculateVewBox = (
    seriesLength: number,
    heightFactor: number
): string => {
    if (seriesLength === 0) return "0 0 100 100";
    const numberOfSeries = seriesLength;
    const labelHeight = 20;
    const height = heightFactor * 100 + labelHeight;
    const width = Math.max(numberOfSeries * 70, 100);
    return `0 0 ${width} ${height}`;
};

const setPositions = (
    series: ShareTAShareData[],
    viewBox: string
): ShareTAShareData[] => {
    if (!series) return [];
    const [_x, _y, width, _height] = viewBox.split(" ").map((val) => +val);
    const length = series.length;
    if (length > 2) throw new Error("Maximum of 2 Series per chart");
    if (length === 0) return [];
    if (length === 1) return setSingleSeries(series, width);
    return setDoubleSeries(series, width);
};

const applyPadding = (share: number) => {
    if (share < 10) return 6;
    if (share === 100) return 2;
    return 5;
};

const setSingleSeries = (
    series: ShareTAShareData[],
    width: number
): PositionedShareTAShareData[] => {
    const _series = series[0];
    const shareX = 10;
    const shareValueLabelX = shareX + applyPadding(_series.share);
    const shareTextX = shareX + 4;
    const taShareX = width - 35;
    const taShareTextX = taShareX + 4;
    const taShareValueLabelX = taShareX + applyPadding(_series.taShare);

    return [
        {
            ..._series,
            shareX,
            shareTextX,
            shareValueLabelX,
            taShareX,
            taShareTextX,
            taShareValueLabelX
        }
    ];
};

const setDoubleSeries = (
    series: ShareTAShareData[],
    width: number
): PositionedShareTAShareData[] => {
    const [first, second] = series;

    const firstShareX = 10;
    const firstShareTextX = firstShareX + 4;
    const firstShareValueLabelX = firstShareX + applyPadding(first.share);
    const firstTaShareX = 35;
    const firstTaShareTextX = firstTaShareX + 4;
    const firstTaShareValueLabelX = firstTaShareX + applyPadding(first.taShare);

    const secondShareX = width - 25 - 35;
    const secondShareTextX = secondShareX + 4;
    const secondShareValueLabelX = secondShareX + applyPadding(second.share);
    const secondTaShareX = width - 35;
    const secondTaShareTextX = secondTaShareX + 4;
    const secondTaShareValueLabelX =
        secondTaShareX + applyPadding(second.taShare);
    return [
        {
            ...first,
            shareX: firstShareX || 0,
            shareTextX: firstShareTextX || 0,
            shareValueLabelX: firstShareValueLabelX || 0,
            taShareX: firstTaShareX || 0,
            taShareTextX: firstTaShareTextX || 0,
            taShareValueLabelX: firstTaShareValueLabelX || 0
        },
        {
            ...second,
            shareX: secondShareX || 0,
            shareTextX: secondShareTextX || 0,
            shareValueLabelX: secondShareValueLabelX || 0,
            taShareX: secondTaShareX || 0,
            taShareTextX: secondTaShareTextX || 0,
            taShareValueLabelX: secondTaShareValueLabelX || 0
        }
    ];
};

export type ShareTAShareChartVariant = "Normal" | "Tall";

export type ShareTAShareData = {
    share: number;
    taShare: number;
    swisherLabel: string;
    genericLabel: string;
};

type PositionedShareTAShareData = ShareTAShareData & {
    shareX?: number;
    shareValueLabelX?: number;
    shareTextX?: number;
    taShareX?: number;
    taShareTextX?: number;
    taShareValueLabelX?: number;
};
