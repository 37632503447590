<div #myFilter>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-receipt">Receipt Number
            </mat-label>
            <input #receiptInput id="receipt-input" type="text" (keyup)="onAddReceiptRefiner($event)"
                [(ngModel)]="viewmodel.receiptInput" matInput />
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Status</mat-label>
            <mat-select #statusSelect
                [(value)]="viewmodel.selectedStatus"
                (openedChange)="onOpenedStatusChange()">
                <mat-option [value]="'All'"> All
                </mat-option>
                <mat-option [value]="'Reimbursed'"> Reimbursed </mat-option>
                <mat-option [value]="'Not Reimbursed'"> Not Reimbursed </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Transaction Type</mat-label>
            <mat-select #typeSelect
                [(value)]="viewmodel.selectedTypes"
                (openedChange)="onOpenedTypeChange()"
                (selectionChange)="onSelectionTypeChange()"
                multiple>
                <mat-option *ngFor="let type of viewmodel.types" [value]="type">
                    {{ type.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-3 mb-3 w-100">
        <angular2-multiselect
            [data]="viewmodel.products"
            [(ngModel)]="viewmodel.selectedProducts"
            [settings]="viewmodel.productsDropdownSettings"
            (onDeSelectAll)="onClosedProductsSelect()"
            (onClose)="onClosedProductsSelect()"
            (onOpen)="onOpenedProductsSelect()">
        </angular2-multiselect>
    </div>
    <div class="mt-3 mb-3">
        <mat-form-field appearance="outline" class="w-100" color="accent">
            <mat-label id="filter-uin">UIN
            </mat-label>
            <input #uinInput id="uin-input" type="text" (keyup)="onAddUinRefiner($event)"
                [(ngModel)]="viewmodel.uinInput" matInput />
        </mat-form-field>
    </div>
</div>
