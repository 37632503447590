import { MapsAPILoader } from "@agm/core";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class GeocoderService {
    private geocoder: google.maps.Geocoder;

    constructor(mapsApiLoader: MapsAPILoader) {
        void mapsApiLoader.load().then(() => {
            this.geocoder = new google.maps.Geocoder();
        });
    }

    async geocode(
        request: google.maps.GeocoderRequest
    ): Promise<google.maps.GeocoderResult[]> {
        return new Promise((resolve, reject) => {
            this.geocoder.geocode(request, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    resolve(results);
                } else {
                    reject(`Google geocoder error: ${status}`);
                }
            });
        });
    }
}
