import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { RetailStepperStep } from "src/app/enums/retail-stepper-step";
import { SubsidiaryTypes } from "src/app/enums/subsidiary-types";
import { Helper } from "src/app/helpers/helper";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { EasWholesalerFormatDraftComponent } from "../receipts/wholesaler-receipt/eas-wholesaler-format-draft/eas-wholesaler-format-draft.component";
import { EasWholesalerFormatComponent } from "../receipts/wholesaler-receipt/eas-wholesaler-format/eas-wholesaler-format.component";
import { EasWholesalerSwisherRepFormatDraftComponent } from "../receipts/wholesaler-receipt/eas-wholesaler-swisher-rep-format-draft/eas-wholesaler-swisher-rep-format-draft.component";
import { SwisherWholesalerFormatDraftComponent } from "../receipts/wholesaler-receipt/swisher-wholesaler-format-draft/swisher-wholesaler-format-draft.component";
import { SwisherWholesalerFormatComponent } from "../receipts/wholesaler-receipt/swisher-wholesaler-format/swisher-wholesaler-format.component";
import { WholesaleReceiptViewmodel } from "../receipts/wholesaler-receipt/wholesale-receipt.viewmodel";
import { StepperCallApplicationService } from "./stepper-call-application.service";

@Injectable()
export class WholesalerReceiptService {

    isWholesaleFinal = false;
    shouldWait$ = new BehaviorSubject<boolean>(false);

    constructor(private retailCallApplicationService: StepperCallApplicationService,
        private pleaseWaitService: PleaseWaitService) {

    }
    async buildViewmodels(customer: Customer,
        wholesalers: Customer[],
        employee: Employee,
        easWholesaleFormat?: EasWholesalerFormatComponent
            | EasWholesalerFormatDraftComponent
            | EasWholesalerSwisherRepFormatDraftComponent,
        swisherWholesaleFormat?: SwisherWholesalerFormatComponent
            | SwisherWholesalerFormatDraftComponent): Promise<void> {

        if (this.retailCallApplicationService.areOrdersBuilt) {
            if (
                !this.isWholesaleFinal &&
                customer &&
                employee
            ) {

                let receiptNumberExtention = 2;

                if (swisherWholesaleFormat) {
                    swisherWholesaleFormat.swisherViewmodels = new Array<WholesaleReceiptViewmodel>();
                }

                if (easWholesaleFormat) {
                    easWholesaleFormat.easViewmodels = new Array<WholesaleReceiptViewmodel>();
                }

                for (const wholesaler of wholesalers) {

                    if (wholesaler) {
                        const swisherOrderProducts = this.retailCallApplicationService.callOrderProductViewModels.filter(
                            (op) => {
                                return op.wholesaler?.length &&
                                op.wholesaler[0]?.id === wholesaler.id &&
                                (Helper.equalsIgnoreCase(op.product.subsidiary, SubsidiaryTypes.swisher)
                                    || Helper.equalsIgnoreCase(op.product.subsidiary, SubsidiaryTypes.rogueholdingsLlc))
                            });
                        const easOrderProducts = this.retailCallApplicationService.callOrderProductViewModels.filter(
                            (op) => {
                                return op.wholesaler?.length &&
                                op.wholesaler[0]?.id === wholesaler.id &&
                                Helper.equalsIgnoreCase(op.product.subsidiary, SubsidiaryTypes.eas)
                            });
                        if (swisherOrderProducts?.length > 0) {
                            if (swisherWholesaleFormat) {

                                await swisherWholesaleFormat.buildSwisherViewmodels(wholesaler,
                                    customer,
                                    employee,
                                    swisherOrderProducts,
                                    receiptNumberExtention,
                                    easOrderProducts?.length ? true : false); // needed to apply page breaks if needed
                                receiptNumberExtention++;
                            }
                        }
                        if (easOrderProducts?.length > 0) {
                            if (easWholesaleFormat) {

                                await easWholesaleFormat.buildEasViewmodels(wholesaler,
                                    customer,
                                    employee,
                                    easOrderProducts,
                                    receiptNumberExtention);
                                receiptNumberExtention++;
                            }
                        }

                    }

                }

                this.shouldWait$.next(false);
            }
        }
    }
}
