import { Product } from "src/app/entity-models/product.entity";

export class CallProductInViewModel {
    id: string; //UUID
    product: Product;
    quantity: number;
    units: number;
    price: number;
    wholesalePrice: number;
    oldQuantity: number;
    oldUnits: number;
    oldRetailPrice: number;
    oldWholesalePrice: number;
    upc: string;
    total: number;
    totalWithTax: number;

    stateTaxAmount: number;
    countyTaxAmount: number;
    cityTaxAmount: number;
    statePrepaid: boolean;
    cityPrepaid: boolean;
    countyPrepaid: boolean;
    get isPristine(): boolean {
        return (
            this.quantity === 1 &&
            (this.units === this.product.returnableUpc?.noOfEaches ?? 1) &&
            this.price === 0 &&
            this.wholesalePrice === 0
        );
    }

    copyTo(
        productIn: CallProductInViewModel
    ): CallProductInViewModel {
        productIn.oldQuantity = productIn.quantity;
        productIn.oldUnits = productIn.units;
        productIn.oldRetailPrice = productIn.price;
        productIn.oldWholesalePrice = productIn.wholesalePrice;
        productIn.quantity = this.quantity;
        productIn.units = this.units;
        productIn.price = this.price;
        productIn.wholesalePrice = this.wholesalePrice;
        return productIn;
    }
}
