import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    CustomerPx3RankDto,
    CustomerPx3RankSyncCommand,
    SyncCommandTypes
} from "shield.shared";
import { CustomerPx3Rank } from "src/app/entity-models/customer-px3-rank.entity";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { SyncLevel } from "../sync-enums/sync-level.enum";
import { SyncHandler } from "./sync-handler";
import { Px3IncentivePeriod } from "src/app/entity-models/px3-incentive-period.entity";
@Injectable()
export class CustomerPx3RankSyncHandler
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.Initial;
    offlineRepSyncType = SyncLevel.Initial;
    syncCommandType = SyncCommandTypes.customerPx3Rank;

    constructor(
        private dbService: DatabaseService,
        private syncQueue: DataSyncQueueService,
        // private px3Service: Px3DelineationService
    ) {
    }
    syncVersionKey = SyncVersionKeyNames.customerPx3Rank;
    isRunSuccessfull = true;

    execute(): Promise<void> {
        //Intentionally Blank
        return;
    }

    protected entityToDto(entity: CustomerPx3Rank): CustomerPx3RankDto {
        return {...entity};
    }

    protected dtoToEntity(dto: CustomerPx3RankDto): CustomerPx3Rank {
        return {...dto};
    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<CustomerPx3RankSyncCommand>(
            this.syncCommandType,
            async (message) => {
                let customer = await this.dbService.customers.get(message.payload.id);
                if (!customer) {
                    return;
                }
                let curIncentivePeriod = await this.dbService.px3IncentivePeriods
                    .filter(i => i.startDate < new Date() && i.endDate > new Date())
                    .first();
                await this.syncQueue.pushCommand({customerId: customer.id, px3RankId: customer.px3RankId, px3IncentivePeriodId: curIncentivePeriod.id} as Partial<CustomerPx3RankDto>, message);
            }
        );
    }
}
