export class ProjectMetricZrtDto {
    id: string;
    name: string;
    assigned: number;
    calledOn: number;
    notCalled: number;
    storesWithOrders: number;
    cashCount: number;
    cashAmount: number;
    cashTotalSticks: number;
    orderCount: number;
    orderAmount: number;
    orderTotalSticks: number;
    gratisAmount: number;
    gratisPercentage: number;
}
