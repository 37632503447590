export class ProductUpcDto {
    id: string;
    productId: string;
    upc: string;
    height: number;
    width: number;
    depth: number;
    weight: number;
    noOfEaches: number;
    noOfPacks: number;
    listPrice: number | null;
    uom: string;
    lowestSellableUnit: boolean;
    returnableUnit: boolean;
}
