export class RegisteredUser {
    id: string;
    userId: string;
    employeeId: string;
    otherPhone: string;
    lastSeenUtcDateTime: Date | undefined;
    lastSyncAttemptUtcDateTime: Date | undefined;
    lastCompletedSyncCycleUtcDateTime: Date | undefined;
    zrtOverride: string;
    createdUtcDateTime: Date;
    createdUserId: string;
    createdUserZrt: string;
    createdUserFullName: string;
    modifiedUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    hasServerProcessed: number;
    rowversion: string;
}
