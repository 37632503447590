export enum RouteListColumns {
    zrt = "ZRT",
    routeName = "Route Name",
    routeDate = "Route Date",
    description = "Description",
    startAddress = "Start Address",
    endAddress = "End Address",
    stopsInRoute = "Stops In Route",
    completedStopsInRoute = "Completed Stops In Route"
}
