<h2 class="text-left">Assignees Selected: {{ viewmodel.selectedEmployees?.length }}</h2>
<div class="inner-card">
    <mat-form-field appearance="outline" color="accent" class="w-100 search">
        <span class="search-icon" matPrefix><mat-icon>search</mat-icon></span>
        <input matInput maxlength="50" placeholder="Search" [(ngModel)]="viewmodel.searchText"
            (keyup)="viewmodel.filter()" (blur)="viewmodel.filter()">
    </mat-form-field>
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th class="summary-head" scope="col">
                    <mat-checkbox [indeterminate]="viewmodel.someChecked()" color="warn"
                        [checked]="viewmodel.allChecked()"
                        (change)="viewmodel.markSelected()">
                    </mat-checkbox>
                </th>
                <th class="summary-head" scope="col">Territory</th>
                <th class="summary-head" scope="col">Name</th>
                <th class="summary-head" scope="col">Store Count</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let employee of viewmodel.employeesFiltered">
                <td>
                    <mat-checkbox [indeterminate]="false" color="warn"
                        [ngModel]="viewmodel.selectedEmployees?.includes(employee)"
                        (change)="viewmodel.markSelected(employee)">
                    </mat-checkbox>
                </td>
                <td class="text-left">
                    Territory {{ employee.zrt }}
                </td>
                <td class="text-left">
                    {{ employee.fullName }}
                </td>
                <td>
                    {{ viewmodel.getCount(employee.id) }}
                </td>
            </tr>
        </tbody>
    </table>
</div>
