import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
} from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";

import { RetailStepperStep } from "src/app/enums/retail-stepper-step";
import { Customer } from "src/app/entity-models/customer.entity";
import { Contact } from "src/app/entity-models/contact.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { CallService } from "../../../call-services/call.service";
import { CallTypes, Subsidiary } from "shield.shared";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { ReceiptAddressViewModel } from "../receipt-address.viewmodel";
import { SwisherWholesalerFormatComponent } from "./swisher-wholesaler-format/swisher-wholesaler-format.component";
import { EasWholesalerFormatComponent } from "./eas-wholesaler-format/eas-wholesaler-format.component";
import { EasWholesalerSwisherRepFormatComponent } from "./eas-wholesaler-swisher-rep-format/eas-wholesaler-swisher-rep-format.component";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { StepperCallApplicationService } from "../../stepper-call-services/stepper-call-application.service";
import { WholesalerReceiptService } from "../../stepper-call-services/wholsaler-receipt.service";
import { WholesalerFormatDraftComponent } from "./wholesaler-format-draft/wholesaler-format-draft.component";

@Component({
    selector: "app-wholesale-receipt",
    templateUrl: "./wholesale-receipt.component.html",
    styleUrls: ["./wholesale-receipt.component.scss"]
})
export class WholesaleReceiptComponent implements OnInit, OnDestroy {
    @ViewChild("swisherWholesaleFormat") swisherWholesaleFormat: SwisherWholesalerFormatComponent;
    @ViewChild("easWholesaleFormat") easWholesaleFormat: EasWholesalerFormatComponent | EasWholesalerSwisherRepFormatComponent;
    @ViewChild("wholesaleDraft") wholesaleDraft: WholesalerFormatDraftComponent;

    //Inputs
    private _employee: Employee;
    @Input()
    get employee(): Employee {
        return this._employee;
    }
    set employee(value: Employee) {
        this._employee = value;
        if (value) {
            if (value.subsidiaryId === Subsidiary.EAS) {
                this.isEasEmployee = true;
            } else {
                this.isEasEmployee = false;
            }
            void this.buildViewmodels();
        }
    }

    @Input()
    swisherlicense: string;

    @Input()
    easlicense: string;

    private _customer: Customer;
    @Input()
    get customer(): Customer {
        return this._customer;
    }
    set customer(value: Customer) {
        this._customer = value;
        if (value) {
            void this.buildViewmodels();
        }
    }

    selectedWholesaler: Customer;

    @Input() customerReceiptAddress: ReceiptAddressViewModel

    @Input()
    selectedContact: Contact;

    @Input()
    get signature(): string {
        return this._signature;
    }
    set signature(value: string) {
        if (value) {
            this._signature = value;
        }
    }

    @Output() wholesaleDraftCapture = new EventEmitter();

    addressOptions: Customer[] = [];
    signatureRetailWidth = 400;
    signatureRetailHeight = 100;
    shouldWait$ = new BehaviorSubject<boolean>(true);

    isBuilding = false;
    isWholesaleDraft = false;
    isWholesaleFinal = false;
    callSubscription: Subscription;
    ordersAreBuiltSubscription: Subscription;
    isEasEmployee = false;
    wholesalers: Customer[];

    originalEasWholesaleReceipt: QueryList<ElementRef>
    originalSwisherWholesaleReceipt: QueryList<ElementRef>

    get hasReceipts(): boolean {
        return (
            (this.stepperCallApplicationService.callOrderProductViewModels ??= [])
                .length > 0
        );
    }

    private _signature: string;

    constructor(
        public stepperCallApplicationService: StepperCallApplicationService,
        public callService: CallService,
        private wholesalerReceiptService: WholesalerReceiptService,
        private customerDelineationService: CustomerDelineationService
    ) { }

    async ngOnInit(): Promise<void> {
        if (
            !this.ordersAreBuiltSubscription ||
            this.ordersAreBuiltSubscription.closed
        ) {
            this.ordersAreBuiltSubscription = this.stepperCallApplicationService.observableOrdersAreBuilt.subscribe(
                async (value) => {
                    if (value) {
                        await this.buildViewmodels();
                    }
                }
            );
        }


        if (!this.callSubscription || this.callSubscription.closed) {
            this.callSubscription = this.callService.observableCall.subscribe(
                async (call: RetailCall) => {
                    if (
                        call &&
                        this.callService.call.callType === CallTypes.retail &&
                        this.stepperCallApplicationService.selectedIndex ===
                        RetailStepperStep.receipts
                    ) {
                        await this.buildViewmodels();
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (
            this.ordersAreBuiltSubscription &&
            !this.ordersAreBuiltSubscription.closed
        ) {
            this.ordersAreBuiltSubscription.unsubscribe();
        }
        if (this.callSubscription && !this.callSubscription.closed) {
            this.callSubscription.unsubscribe();
        }
    }

    captureWholesaleDraft(): void {
        this.wholesaleDraftCapture.emit();
    }

    async buildViewmodels(): Promise<void> {
        if (!this.isBuilding && this.employee && this.customer && !this.isWholesaleFinal) {
            this.isBuilding = true;
            const wholesalerIds = (this.callService
                .call as RetailCall).orderProducts?.map(
                    (orderProduct) => orderProduct.wholesalerCustomerId
                )?.filter((v, i, a) => a.indexOf(v) === i);

            const wholesalerResponse = await this.customerDelineationService.getByIds(
                wholesalerIds
            );
            if (!wholesalerResponse) {
                this.shouldWait$.next(false);
                return;
            }
            const wholesalers = wholesalerResponse.values.sort((a, b) => a.name > b.name ? 1 : -1);

            if (wholesalers?.length > 0) {
                this.addressOptions = wholesalers;

                this.selectedWholesaler = this.addressOptions[0];
            }

            this.wholesalers = wholesalers;

            await this.wholesalerReceiptService.buildViewmodels(this.customer,
                wholesalers,
                this.employee,
                this.easWholesaleFormat,
                this.swisherWholesaleFormat);

                this.isBuilding = false;
        }
    }
}
