import {
    AfterViewChecked, Component, ElementRef, HostBinding,
    HostListener, OnDestroy, OnInit,
    ViewChild
} from "@angular/core";

import { MatSidenav, MatSidenavContent } from "@angular/material/sidenav";

import { OrderExtractionRefinerService } from "./order-extraction-refiner.service";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { OrderExtractionViewmodel } from "./order-extraction.viewmodel";
import { Router } from "@angular/router";
import { OrderExtractionWholesalerService } from "./order-extraction-wholesaler.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { OrderDelineationService } from "src/app/services/delineation-services/order-delineation.service";
import { MatSelect } from "@angular/material/select";

@Component({
    selector: "app-order-extraction",
    templateUrl: "./order-extraction.component.html",
    styleUrls: ["./order-extraction.component.scss"],
    providers: [PleaseWaitService]
})
export class OrderExtractionComponent
    implements OnInit, AfterViewChecked, OnDestroy {
    @HostBinding("class") class = "worksheet-static d-flex flex-column flex-grow-1";
    // ViewChilds
    @ViewChild("drawer") drawer: MatSidenav;
    @ViewChild("grid") grid: GridComponent;
    @ViewChild("sideNavContent") sideNavContent: MatSidenavContent;
    @ViewChild("header") header: PageHeaderComponent;
    @ViewChild("wholesalerSearch") wholesalerSearch: ElementRef;
    @ViewChild("wholesalerDropdown") wholesalerDropdown: MatSelect;

    // Public vars
    viewmodel: OrderExtractionViewmodel;
    isViewmodelReady = false;
    screenHeight: number;

    constructor(
        private refinerService: OrderExtractionRefinerService,
        private orderDelineationService: OrderDelineationService,
        private wholesalerService: OrderExtractionWholesalerService,
        private pleaseWaitService: PleaseWaitService,
        private router: Router
    ) { }

    async ngOnInit(): Promise<void> {
        this.viewmodel = new OrderExtractionViewmodel(
            this.refinerService,
            this.orderDelineationService,
            this.wholesalerService,
            this.pleaseWaitService,
            this.router
        );
    }

    ngAfterViewChecked(): void {
        if (this.isViewmodelReady && !this.viewmodel.isGapSet) {
            this.viewmodel.calculateGap();
        }

        if (!this.isViewmodelReady
            && this.grid
            && this.header
            && this.sideNavContent
            && this.drawer
            && this.wholesalerSearch
            && this.wholesalerDropdown
        ) {
            this.isViewmodelReady = true;
            this.getScreenSize();
            void this.viewmodel.initialize(
                this.grid,
                this.header,
                this.sideNavContent,
                this.drawer,
                this.wholesalerSearch,
                this.wholesalerDropdown
            );
        }
    }

    ngOnDestroy(): void {
        if (
            this.viewmodel.wholesalerSubscription &&
            !this.viewmodel.wholesalerSubscription.closed
        ) {
            this.viewmodel.wholesalerSubscription.unsubscribe();
        }

        if (
            this.viewmodel.selectedWholesalerSubscription &&
            !this.viewmodel.selectedWholesalerSubscription.closed
        ) {
            this.viewmodel.selectedWholesalerSubscription.unsubscribe();
        }

        if (
            this.viewmodel.refinerInputChangeSubscription &&
            !this.viewmodel.refinerInputChangeSubscription.closed
        ) {
            this.viewmodel.refinerInputChangeSubscription.unsubscribe();
        }

        if (
            this.viewmodel.wholesalerSearchSubscription &&
            !this.viewmodel.wholesalerSearchSubscription.closed
        ) {
            this.viewmodel.wholesalerSearchSubscription.unsubscribe();
        }
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?: any): void {
        if (this.viewmodel) {
            this.viewmodel.screenHeight = window.innerHeight;
        }
    }
}
