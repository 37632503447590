<div #originalSwisherRetailReceipt id="retail-draft-receipt">
    <app-swisher-retail-format-draft *ngIf="!isTaxState" [customerReceiptAddress]="customerReceiptAddress">
    </app-swisher-retail-format-draft>
    <app-swisher-retail-narrow-tax-format-draft *ngIf="isTaxState && selectedFormat === selectedFormats.narrow;"
        [customerReceiptAddress]="customerReceiptAddress">
    </app-swisher-retail-narrow-tax-format-draft>
    <app-swisher-retail-wide-tax-format-draft *ngIf="isTaxState && selectedFormat === selectedFormats.wide;"
        [customerReceiptAddress]="customerReceiptAddress">
    </app-swisher-retail-wide-tax-format-draft>
    <app-eas-retail-wide-tax-format-draft *ngIf="isEasEmployee && isTaxState && selectedFormat === selectedFormats.wide;"
        [customerReceiptAddress]="customerReceiptAddress">
    </app-eas-retail-wide-tax-format-draft>
    <app-eas-retail-wide-tax-format-swisher-rep-draft *ngIf="!isEasEmployee && isTaxState && selectedFormat === selectedFormats.wide;"
        [customerReceiptAddress]="customerReceiptAddress">
    </app-eas-retail-wide-tax-format-swisher-rep-draft>
    <app-eas-retail-format-draft *ngIf="isEasEmployee && !isTaxState" [customerReceiptAddress]="customerReceiptAddress">
    </app-eas-retail-format-draft>
    <app-eas-retail-format-swisher-rep-draft *ngIf="!isEasEmployee && !isTaxState" [customerReceiptAddress]="customerReceiptAddress">
    </app-eas-retail-format-swisher-rep-draft>
    <app-eas-retail-narrow-tax-format-draft *ngIf="isEasEmployee && isTaxState && selectedFormat === selectedFormats.narrow;"
        [customerReceiptAddress]="customerReceiptAddress">
    </app-eas-retail-narrow-tax-format-draft>
    <app-eas-retail-narrow-tax-format-swisher-rep-draft *ngIf="!isEasEmployee && isTaxState && selectedFormat === selectedFormats.narrow;"
        [customerReceiptAddress]="customerReceiptAddress">
    </app-eas-retail-narrow-tax-format-swisher-rep-draft>
</div>
