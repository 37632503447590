import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { NamedStringDto } from "shield.shared";
import { Product } from "src/app/entity-models/product.entity";


export class GratisRequestDetailViewmodel {
    id: string;
    gratisId: string;
    product: Product;
    quantity: number;
    unitOfMeasure: NamedStringDto;
    availableUnitOfMeasures: NamedStringDto[];
    costPerUnit: number;
    oldCostperUnit: number;
    costAmount: number;
    cbuSubscription: Subscription;
    formGroup = new FormGroup({});

    unsubscribe(): void {
        if (this.cbuSubscription) {
            this.cbuSubscription.unsubscribe();
        }
    }

}
