import {
    AfterContentChecked,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import { FormBuilder } from "@angular/forms";

import { MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import { OverlayService } from "src/app/services/overlay.service";

import { GridComponent } from "src/app/shared/grid/grid.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { CallHistoryViewmodel } from "./call-history.viewmodel";
import { AppStateService } from "src/app/services/app-state.service";
import { Router } from "@angular/router";
import { CallHistoryRefinerService } from "./call-history-refiner.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { PingService } from "src/app/services/ping.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { CallHistoryZrtFilterService } from "./call-history-zrt.filter.service";
import { ActivitiesFilterService } from "src/app/services/activities-filter.service";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";
import { XLSXService } from "src/app/services/xlsx.service";



@Component({
    selector: "app-call-history",
    templateUrl: "./call-history.component.html",
    styleUrls: ["./call-history.component.scss"],
    providers: [PleaseWaitService]
})
export class CallHistoryComponent
    implements OnInit, AfterContentChecked, OnDestroy {
    @HostBinding("class") class = "worksheet-static d-flex flex-column flex-grow-1";
    // ViewChilds
    @ViewChild("drawer") drawer: MatSidenav;
    @ViewChild("grid") grid: GridComponent;
    @ViewChild("sideNavContent") sideNavContent: MatSidenavContent;
    @ViewChild("header") header: PageHeaderComponent;
    @ViewChild("startDateInput") startDateInput: ElementRef;
    @ViewChild("endDateInput") endDateInput: ElementRef;

    // Public vars
    viewmodel: CallHistoryViewmodel;
    isViewmodelReady = false;
    screenHeight: number;

    constructor(
        private pleaseWaitService: PleaseWaitService,
        private overlayService: OverlayService,
        private appStateService: AppStateService,
        private refinerService: CallHistoryRefinerService,
        private router: Router,
        private formBuilder: FormBuilder,
        private callDelineationService: CallDelineationService,
        private customerDelineationService: CustomerDelineationService,
        private pingService: PingService,
        private snackbar: SnackbarService,
        private employeeDelineationService: EmployeeDelineationService,
        private filterService: FilterService,
        private zrtFilterService: CallHistoryZrtFilterService,
        private activitiesFilterService: ActivitiesFilterService,
        private projectDelineationService: ProjectDelineationService,
        private accountOwnershipDelineationService: AccountOwnershipDelineationService,
        private px3RankService: Px3DelineationService,
        private xlsxService: XLSXService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.viewmodel = new CallHistoryViewmodel(
            this.pleaseWaitService,
            this.overlayService,
            this.appStateService,
            this.refinerService,
            this.formBuilder,
            this.router,
            this.callDelineationService,
            this.customerDelineationService,
            this.pingService,
            this.snackbar,
            this.employeeDelineationService,
            this.filterService,
            this.zrtFilterService,
            this.activitiesFilterService,
            this.projectDelineationService,
            this.accountOwnershipDelineationService,
            this.px3RankService,
            this.xlsxService,
        );
    }

    ngAfterContentChecked(): void {
        if (this.isViewmodelReady && !this.viewmodel.isGapSet) {
            this.viewmodel.calculateGap();
        }

        if (!this.isViewmodelReady
            && this.grid
            && this.header
            && this.sideNavContent
            && this.drawer
            && this.startDateInput
            && this.endDateInput
            ) {
            this.isViewmodelReady = true;
            this.getScreenSize();
            void this.viewmodel.initialize(
                this.grid,
                this.header,
                this.sideNavContent,
                this.drawer,
                this.startDateInput,
                this.endDateInput
            );
        }
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?: any): void {
        if (this.viewmodel) {
            this.viewmodel.screenHeight = window.innerHeight;
        }
    }
}
