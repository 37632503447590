<div class="container-fluid">
    <div class="row">
        
        <div style="width: 300px;" class="col-md-2 d-flex justify-content-start align-items-center">
            <button mat-raised-button
                class="actionBtn"
                [ngClass]="webcamImage ? 'btn-white' : 'sw-red-btn'"
                ngbAutofocus
                (click)="onTriggerSnapshot()"
            >
                {{ webcamImage ? "Retake" : "TAKE" }}
             </button>
        </div>        
        
        <div class="col-md-8">
            <div class="cam-modal" cdkDrag cdkDragBoundary=".cdk-overlay-container">
                <div cdkDragHandle>
                    <div class="modal-header">
                        Call Cam
                    </div>
                </div>
                <form [formGroup]="group" #pictureTypeForm="ngForm">
                    <div class="d-flex control-container">
                        <div class="flex-grow-1">
                            <mat-form-field appearance="outline" color="accent" class="container w-100">
                                <mat-label>Picture Type</mat-label>
                                <mat-select
                                    formControlName="select"
                                    [value]="selectedPictureValue"
                                    (selectionChange)="selectedPictureValue = $event.value"
                                    required
                                >
                                    <mat-option
                                        *ngFor="let option of pictureTypeOptions"
                                        [value]="option.value"
                                    >
                                        {{ option.viewValue }}
                                    </mat-option>
                                    <mat-option>Clear</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error class="validation-error"
                                *ngIf="group.controls.select.hasError('required')"
                                >Please select a picture type.
                            </mat-error>
                        </div>
                        <div class="flex-grow-1">
                            <mat-form-field appearance="outline" color="accent" class="container w-100">
                                <mat-label>Tags (Optional)</mat-label>
                                <input
                                    matInput
                                    [value]="tagsString"
                                    [formControl]="tagFormControl"
                                    placeholder="Up to 10 tags, comma separated"
                                    maxlength="250"
                                />
                                <mat-hint align="end"
                                    >{{ tagFormControl.value?.length || 0 }}/250
                                </mat-hint>
                            </mat-form-field>
                            <mat-error class="validation-error"
                                *ngIf="tagFormControl.hasError('pattern')"
                                >10 tag limit. Commas must have values in between them.
                            </mat-error>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div #confirmButton (keyup.space)="onTriggerSnapshot()" (keyup.enter)="onTriggerSnapshot()" tabindex="0" style="text-align: center">
                            <div>
                                <div
                                    *ngIf="!webcamImage"
                                    class="wrapper"
                                    (window:resize)="onResize($event)"
                                >
                                    <webcam
                                        [width]="cameraWidth"
                                        [height]="cameraHeight"
                                        [trigger]="triggerObservable"
                                        (imageCapture)="onHandleImage($event)"
                                        *ngIf="showWebcam"
                                        [allowCameraSwitch]="allowCameraSwitch"
                                        [switchCamera]="nextWebcamObservable"
                                        [videoOptions]="videoOptions"
                                        [imageQuality]="1"
                                        (cameraSwitched)="onCameraWasSwitched($event)"
                                        (initError)="onHandleInitError($event)"
                                    >
                                    </webcam>
                                </div>
                                <div class="snapshot" *ngIf="webcamImage" class="wrapper">
                                    <canvas
                                        #modalCanvas
                                        id="modal-canvas"
                                        style="align-items: center"
                                        [width]="cameraWidth"
                                        [height]="cameraHeight"
                                    ></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" style="justify-content: center">
                        <section>
                            <!-- <div class="slider-container">
                                <span class="slider-label">Focus:</span>
                                <mat-slider *ngIf="!webcamImage"
                                    #focusSlider 
                                    color="accent"
                                    [min]="focusMin" 
                                    [max]="focusMax" 
                                    [step]="focusStep" 
                                    [value]="focusValue" 
                                    (input)="onFocusSliderInput($event)">
                                </mat-slider>
                                <span class="slider-label">Brightness:</span>
                                <mat-slider 
                                    #brightnessSlider 
                                    [min]="brightnessMin" 
                                    [max]="brightnessMax" 
                                    [step]="brightnessStep" 
                                    [value]="brightnessValue" 
                                    (input)="onBrightnessSliderInput($event)">
                                </mat-slider>

                            </div> -->
                            <button mat-raised-button
                                class="actionBtn btn-white"
                                (click)="restCamera(); overlayRef.close()"
                            >
                                Cancel
                            </button>
                            <button mat-raised-button
                                class="actionBtn"
                                [ngClass]="webcamImage ? 'btn-white' : 'sw-red-btn'"
                                ngbAutofocus
                                (click)="onTriggerSnapshot()"
                            >
                                {{ webcamImage ? "Retake" : "TAKE" }}
                            </button>
                            <button *ngIf="webcamImage"
                                appThrottleClick
                                mat-raised-button
                                class="actionBtn sw-red-btn"
                                (throttledClick)="onSave()"
                                [disabled]="
                                    group.controls.select.hasError('required') ||
                                    tagFormControl.hasError('pattern')
                                "
                            >
                                Save
                            </button>
                        </section>
                        <h4 *ngIf="errors.length > 0">Messages:</h4>
                        <ul *ngFor="let error of errors">
                            <li>{{ error | json }}</li>
                        </ul>
                    </div>
                </form>
            </div>
        </div>

        <div style="width: 300px;" class="col-md-2 d-flex justify-content-end align-items-center">
            <button mat-raised-button
                class="actionBtn"
                [ngClass]="webcamImage ? 'btn-white' : 'sw-red-btn'"
                ngbAutofocus
                (click)="onTriggerSnapshot()"
            >
                {{ webcamImage ? "Retake" : "TAKE" }}
             </button>
        </div>
    </div>
</div>
