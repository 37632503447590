import { WholesalerGroupEntryMemberDto } from "./wholesaler-group-entry-member.dto";

export class WholesalerGroupEntryDto {
    id: string;
    groupId: string;
    groupName: string;
    numberOfMembers: number;
    method: string;
    numberOfProducts: number;
    groupMembers: WholesalerGroupEntryMemberDto[];
}
