import { Input } from "@angular/core";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { ProjectActivitySurvey } from "src/app/entity-models/project-activity-survey.entity";
import { Project } from "src/app/entity-models/project.entity";
import { Survey } from "src/app/entity-models/survey.entity";
import { SurveyDelineationService } from "src/app/services/delineation-services/survey-delineation.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { ProjectSummaryActivitySurveyGridViewmodel } from "./project-summary-activitiy-survey-grid.viewmodel";

export class ProjectSummaryActivitiesSurveysViewmodel {

    checkingProjectProperties = true;
    projectActivitiesSurveys = new Array<ProjectActivitySurvey>();

    grid: GridComponent;
    gridData = new Array<GridData>();
    isFixedLayout = true;

    columnDef: ColumnDef[] = [
        {
            headerName: "Response",
            dataPropertyName: "type",
            isAvailable: true,
            isSelected: true
        },
        {
            headerName: "Activity/Survey",
            dataPropertyName: "text",
            isAvailable: true,
            isSelected: true
        },
        {
            headerName: "Required",
            dataPropertyName: "isRequired",
            isAvailable: true,
            isSelected: true
        }
    ];

    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    selectedColumnVisibilityComunicator = this.columnDef.map((cd) => cd.isAvailable && cd.headerName);

    surveyDelineationService: SurveyDelineationService;
    snackbarService: SnackbarService;

    constructor(surveyDelineationService: SurveyDelineationService
        , snackbarService: SnackbarService){

        this.surveyDelineationService = surveyDelineationService;
        this.snackbarService = snackbarService;
    }

    initialize(grid: GridComponent): void {
        this.grid = grid;
    }

    async buildProjectActivitiesSurveys(projectActivitySurveys: ProjectActivitySurvey[]): Promise<void> {

        const activitySurveyIds = projectActivitySurveys.map((pas) => pas.activitySurveyId);
        const response = await this.surveyDelineationService.getByIds(activitySurveyIds);
        if (!response || !response.values) { return; }

        const activitiesSurveys = response.values;
        this.projectActivitiesSurveys = projectActivitySurveys.slice();

        let gridData = new Array<GridData>();

        for (const group of activitiesSurveys) {
            if (group) {
                for (const question of group.surveyQuestions) {
                    const gridItem: GridData = {
                        data: new ProjectSummaryActivitySurveyGridViewmodel(question),
                        detailArrayName: null,
                        isExpanded: false,
                        index: null
                    };
                    gridData.push(gridItem);
                }
            }
        }
        this.gridData = gridData;

        this.dataSource = new TableVirtualScrollDataSource(
            this.gridData
        );
    }
}
