import { CustomerContractPayment } from "src/app/entity-models/customer-contract-payment.entity";

export class ViewAgreementLineItemViewModel {
    status: string;
    paymentDate: string;
    paymentAmount: number;
    rep: string;
    contractPayment: CustomerContractPayment;
    isEditable: boolean = true;
}
