import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
    apiVersion, CustomerDto,
    VersionResponseDto, GenericVersionResponseDto,
    CallTypes, SystemInformationKeys, SharedHelper,
    KeyValueDto, CustomerSyncCommand, SyncCommandTypes,
    CustomerPx3DataDTO,
    GenericRequestDto,
    newSequentialId,
    GenericResponseDto,
    Px3IncentivePeriodDto
} from "shield.shared";
import { Customer } from "src/app/entity-models/customer.entity";
import { DatabaseService } from "../../services/database.service";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncQueueService } from "../data-sync-queue.service";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { CallDataSyncHandler } from "./call-data-sync-handler";
import { Call } from "src/app/accounts/call-master/call-services/call.service";
import { Employee } from "src/app/entity-models/employee.entity";
import { Subscription } from "rxjs";
import { CallConverterService } from "src/app/services/converter-services/call-converter.service";
import { CustomerConverterService } from "src/app/services/converter-services/customer-converter.service";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { Picture } from "src/app/entity-models/picture.entity";
import { PictureConverterService } from "src/app/services/converter-services/picture-conveter.service";
import { Receipt } from "src/app/entity-models/receipt";
import { ReceiptConverterService } from "src/app/services/converter-services/receipt-converter.service";
import { CustomerAssignmentType } from "../sync-enums/customer-assignment-type.enum";
import { SyncLevel } from "../sync-enums/sync-level.enum";
import { Helper } from "src/app/helpers/helper";
@Injectable()
export class CustomerDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    apiVersion = apiVersion;
    employee: Employee;
    onlineRepSyncType = SyncLevel.None;
    offlineRepSyncType = SyncLevel.Required;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.customer;

    private subscriptionAuthToken: Subscription;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing customers...");

        await this.pullData();
        await this.pullCallsMadeAndPx3Rank();

        this.log("Done syncing customers...");
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            const version = await this.http
                .get<VersionResponseDto>("/api/customers/version")
                .toPromise();
            return version.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("Customer data is up to date");
            this.isRunSuccessfull = true;
            return;
        }

        this.log("Customer data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.customerPullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 1000;

        try {
            let thisBatchSize: number;
            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;

                this.log(`Get customers - [` + query + `]`);
                const response = await this.http
                    .get<GenericVersionResponseDto<CustomerDto[]>>(`/api/customers${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length;

                this.log(`  Downloaded ${thisBatchSize}, saving to IndexedDB...`);

                let customers = response.values.map((c) =>
                    CustomerConverterService.customerDtoToCustomer(c, CustomerAssignmentType.Zrt)
                );

                const existingAssignedCustomers = await this.dbService.customers
                    .bulkGet(customers.map(v => v.id));

                const projectCustomers = existingAssignedCustomers.filter(v => v && v.isProjectAssignment);
                const specialCustomers = existingAssignedCustomers.filter(v => v && v.isSpecialAssignment);

                customers = customers.map(v => {
                    if (projectCustomers.find(x => x.id == v.id)) {
                        v.isProjectAssignment = 1;
                    }
                    if (specialCustomers.find(x => x.id == v.id)) {
                        v.isSpecialAssignment = 1;
                    }
                    return v;
                });

                const customersMap = new Map(customers.map((c) => [c.id, c]));

                const deletedCustomerIds = new Array<string>();
                const savedCustomers = new Array<Customer>();

                // remove calls not on the customer, deleted calls will be removed when removing the deleted customers
                let savedCalls = new Array<Call>();
                let callsToRemove = new Array<Call>();

                for (const value of response.values) {
                    if (value.isDeleted) {
                        deletedCustomerIds.push(value.id);
                        continue;
                    } else {
                        const customer = customersMap.get(value.id);
                        customer.hasServerProcessed = 1;
                        savedCustomers.push(customer);
                    }
                    const existingCalls = await this.dbService.calls.where("id").anyOf((value.calls ?? []).map((call) => call.id)).toArray();
                    const existingCallIds = existingCalls.map((ec) => ec.id);
                    let oldestReturnedCall = SharedHelper.getLastMonthsDate();

                    const customerCalls = new Array<Call>();
                    for (const call of value.calls) {
                        const rtn = CallConverterService.callDtoToCall(call);
                        if (rtn.stopTime.getTime() < oldestReturnedCall.getTime()) {
                            oldestReturnedCall = rtn.stopTime;
                        }
                        rtn.hasServerProcessed = 1;
                        customerCalls.push(rtn);
                    }

                    const customerCallsMap = new Map(customerCalls.map((cc) => [cc.id, [cc]]));
                    const staleCalls = oldestReturnedCall ? await this.dbService.calls
                        .where("customerId").equals(value.id)
                        .and((c) => !!c.stopTime && c.stopTime.getTime() < oldestReturnedCall.getTime())
                        .toArray() : [];
                    callsToRemove = callsToRemove.concat(staleCalls.filter((dc) => !customerCallsMap.has(dc.id)));

                    if (customerCalls?.length) {
                        savedCalls = [...savedCalls,  ...customerCalls];
                    }
                }

                if (callsToRemove?.length) {
                    await this.deletePicturesByCalls(callsToRemove);
                    await this.deleteReceiptsByCalls(callsToRemove);
                    await this.removeCalls(callsToRemove);
                }

                if (deletedCustomerIds?.length) {
                    await this.removeDeletedCustomers(deletedCustomerIds)
                }

                if (savedCustomers?.length) {
                    await this.dbService.customers.bulkPut(savedCustomers);
                    this.log(`  Saved ${savedCustomers.length} customers.`);
                }

                if (savedCalls?.length) {
                    await this.dbService.calls.bulkPut(savedCalls);
                    this.log(`  Saved ${savedCalls.length} calls.`);
                }

                this.lastSyncVersion = response.maxVersion;
            } while (thisBatchSize > 0);

            this.log("Done saving customer data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing customers", e);
        }
    }

    private async pullCallsMadeAndPx3Rank(): Promise<void> {
        this.log('Updating Calls Made and Px3 Rank');
        //The Current Incentive Period ID that was set at the end of the previous sync.
        const curIncentivePeriodId = window.localStorage.getItem('sync_current_px3_incentive_period_id');
        if (curIncentivePeriodId == null) {
            this.log('No current incentive period found, skipping calls made and px3 rank update');
            return;
        }
        const serverCurrentIncentivePeriod = await this.http.get<GenericResponseDto<Px3IncentivePeriodDto>>("/api/px3-incentive-periods/incentive-period").toPromise();
        if (curIncentivePeriodId === serverCurrentIncentivePeriod.values.id) {
            this.log('Incentive Period matches server Incentive Period. Skipping Calls Made and Px3 Rank pull.');
            return;
        }
        console.time('Calls Made Px3')
        const customerIDs = await this.dbService.customers.toCollection().primaryKeys();
        const idBatches = Helper.chunkArray(customerIDs, 1000);
        const infoMap: Map<string, CustomerPx3DataDTO> = new Map();
        await Promise.all(idBatches.map(async (idBatch) => {
            const request = new GenericRequestDto<string[]>();
            request.id = newSequentialId();
            request.values = idBatch;
            const response = await this.http
                .post<GenericResponseDto<CustomerPx3DataDTO[]>>(
                    "/api/customers/calls-made-and-px3-rank",
                    request
                )
                .toPromise();

            for (const info of response.values) {
                infoMap.set(info.customerId, info);
            }
        }));
        const customers = new Map((await this.dbService.customers.bulkGet(Array.from(infoMap.keys()))).map(customer => [customer.id, customer]));
        const empty = '';
        await Promise.all(Array.from(infoMap.values()).map(info => {
            const customer = customers.get(info.customerId);
            if (info && (info.callsMade !== customer.callsMade || info.px3RankId !== customer.px3RankId)) {
                customer.callsMade = info.callsMade;
                customer.px3RankId = info.px3RankId;
                return this.dbService.customers.put(customer);
            }
            return Promise.resolve(empty);
        }));
        console.timeEnd('Calls Made Px3')
        this.log('Finished Calls Made and Px3 Rank')
    }

    private async removeDeletedCustomers(deletedCustomerIds: string[]): Promise<void> {

        //Not needed per Rahul 1/25/2022
        //[9:27 AM] Rahul Ravindran
        //I was reviewing your list. I think we can keep AccountOwnerships table.
        //It is possible for TMs to add a new Chain Retail store and might want to set its parent to a Chain HQ.
        //Technically, we can still remove it but the effort to handle all edge cases is not worth it.

        // const accountOwnerships = await this.dbService.accountOwners.where("customerId").anyOf(deletedCustomerIds).toArray();
        // if (accountOwnerships?.length) {
        //     await this.dbService.accountOwners.bulkDelete(accountOwnerships.map((ao) => ao.id));
        // }

        const contacts = await this.dbService.contacts.where("customerId").anyOf(deletedCustomerIds).toArray();
        if (contacts?.length) {
            await this.dbService.contacts.bulkDelete(contacts.map((c) => c.id));
        }


        const customerContracts = await this.dbService.customerContracts.where("customerId").anyOf(deletedCustomerIds).toArray();
        if (customerContracts?.length) {
            await this.dbService.customerContracts.bulkDelete(customerContracts.map((cc) => cc.id));
        }

        // review, I am not a fan of this. It is very expensisve and I wonder if this is not a one way sync responsabiltiy of the server\whoever is marking the
        // record as deleted
        // const projects = await this.dbService.projects.filter((p) => p.projectCustomers.some((pc) => deletedCustomerIds.includes(pc.customerId))
        //     || p.projectCustomerEmployeeAssignments.some((pcea) => deletedCustomerIds.includes(pcea.customerId)));

        const wholesalerGroupMembers = await this.dbService.wholesalerGroupMembers.where("wholesalerId").equals(deletedCustomerIds).toArray();
        if (wholesalerGroupMembers?.length) {
            await this.dbService.wholesalerGroupMembers.bulkDelete(wholesalerGroupMembers.map((wgm) => wgm.id));
        }

        for (const id of deletedCustomerIds) {
            const calls = await this.dbService.calls.where("customerId").equals(id).toArray();
            await this.deletePicturesByCalls(calls);
            await this.removeCalls(calls);
        }

        if (deletedCustomerIds?.length) {
            await this.dbService.customers.bulkDelete(deletedCustomerIds);
            this.log(`  Deleted ${deletedCustomerIds.length} customers.`);
        }
    }

    private async deletePicturesByCalls(calls: Call[]): Promise<void> {
        for (const call of calls) {
            if (call.callType === CallTypes.retail
                || call.callType === CallTypes.rmWholesale
                || call.callType === CallTypes.wholesale) {

                const pictureIds = (call.callPictures ?? []).map((callPicture) => callPicture.id);
                if (pictureIds.length) {
                    await this.dbService.pictures.bulkDelete(pictureIds);
                }
            }
        }
    }

    private async deleteReceiptsByCalls(calls: Call[]): Promise<void> {
        for (const call of calls) {
            if (call.callType === CallTypes.retail
                || call.callType === CallTypes.rmWholesale) {

                const receiptIds = (call.callReceipts ?? []).map((callReceipt) => callReceipt.id);
                if (receiptIds.length) {
                    await this.dbService.receipts.bulkDelete(receiptIds);
                }
            }
        }
    }

    private async removeCalls(calls: Call[]): Promise<void> {

        let recieptIds = new Array<string>();
        let pictureIds = new Array<string>();

        for (const call of calls) {

            switch (call.callType) {
                case CallTypes.retail: {
                    const callReceiptIds = (call.callReceipts ?? []).map((cr) => cr.id);
                    if (callReceiptIds?.length) {
                        recieptIds = [...recieptIds, ...callReceiptIds];
                    }

                    const callPictureIds = (call.callPictures ?? []).map((cp) => cp.id);
                    if (callPictureIds?.length) {
                        pictureIds = [...pictureIds, ...callPictureIds];
                    }
                    break;
                }
                case CallTypes.wholesale: {
                    const callPictureIds = (call.callPictures ?? []).map((cp) => cp.id);
                    if (callPictureIds?.length) {
                        pictureIds = [...pictureIds, ...callPictureIds];
                    }
                    break;
                }
                default:
                    break;
            }
        }

        if (recieptIds.length) {
            await this.dbService.receipts.bulkDelete(recieptIds);
        }

        if (pictureIds.length) {
            await this.dbService.pictures.bulkDelete(pictureIds);
        }

        if (calls.length) {
            await this.dbService.calls.bulkDelete(calls.map((c) => c.id));
            this.log(`  Deleted ${calls.length} calls.`);
        }

    }

    async pushData(): Promise<void> {
        return this.syncQueue.process<CustomerSyncCommand>(
            SyncCommandTypes.customer,
            async (message) => {
                let customer = await this.dbService.customers.get(
                    message.payload.id
                );

                //Customer has been removed
                if (!customer) return;

                let dto = CustomerConverterService.customerToCustomerDto(customer);

                if (dto) {
                    const params = new Array<KeyValueDto>();
                    const param = new KeyValueDto();
                    param.key = "apiVersion";
                    param.value = this.apiVersion.toString();
                    params.push(param);

                    this.syncQueue.pushCommand(dto, message, params);
                }

                const callDataSyncHandler = new CallDataSyncHandler(this.dbService, this.http, this.syncQueue);
                callDataSyncHandler.pushCallPictures();
                callDataSyncHandler.pushCallReceipts();
            }
        );
    }
}
