import { GratisStatuses } from "../lookup";
import { ZrtFilterDto } from "./zrt-filter.dto";

export class GratisListFilterDto {

    /*
     * My Searches
     */
    // Usage: Get only accounts with given ids
    ids?: string[];

    /*
    * Header and Requested information section
    */

    //Usage: Contains ZRT properties
    zrtFilterDto?: ZrtFilterDto;

    //Usage: Get data based on gratis status
    statuses?: GratisStatuses[];

    /*
    * Header Section And Date Information
    */

    //Usage: Get data based on gratis start date
    startOnOrAfterDate?: string;

    /*
    * My Searches
    */

    // Usage: Get data by gratis id's
    gratisIds?: string[];

    /*
    * Requested Information
    */

    //Usage: Get data matching the purpose
    purpose?: string;

    //Usage: Get data matching the days waiting
    daysWaiting?: number;


    /*
    * Date Infromation
    */

    //Usage: Get data based on gratis end date
    endOnOrBeforeDate?: string;
}
