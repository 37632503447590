import { Injectable } from "@angular/core";
import { FilterRequestV2Dto, GenericResponseDto, StringArrayDto, ZoneRecapFilterDto, ZoneRecapParamsDto, ZoneRecapProductDto } from "shield.shared";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { ZoneRecap } from "src/app/entity-models/zone-recap.entity";
import { ZoneRecapFilterMapService } from "../filter-map-services/zone-recap-filter-map.service";
import { ZoneRecapOfflineService } from "../offline-services/zone-recap-offline.service";
import { ZoneRecapOnlineService } from "../online-services/zone-recap-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";

@Injectable()
export class ZoneRecapDelineationService {

    constructor(
        private zoneRecapOfflineService: ZoneRecapOfflineService,
        private zoneRecapOnlineService: ZoneRecapOnlineService,
        private snackbarService: SnackbarService,
        protected datasourceDelineationService: DatasourceDelineationService,
    ) {}

    async getRecap(
        zone: string,
        id: string,
        pageSize: number,
        startIndex: number,
        refiners: Array<Refiner>
    ): Promise<GenericResponseDto<ZoneRecap>> {
        const params = new ZoneRecapParamsDto();
        params.zone = zone;
        const filterRequestDto = new FilterRequestV2Dto<ZoneRecapFilterDto, undefined>();
        filterRequestDto.id = id;
        filterRequestDto.pageSize = pageSize;
        filterRequestDto.startIndex = startIndex;
        filterRequestDto.filters = ZoneRecapFilterMapService.mapFilterData(refiners);
        filterRequestDto.filterSorts = null;
        params.filterRequestDto = filterRequestDto;

        const offline = (key: ZoneRecapParamsDto) => {
            return this.zoneRecapOfflineService.offlineError(
                key
            );
        };
        const online = (key: ZoneRecapParamsDto) => {
            return this.zoneRecapOnlineService.getRecap(
                key
            );
        };
        const response = await this.datasourceDelineationService.makeCall<ZoneRecapParamsDto, ZoneRecap>(params, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async upsertProducts(
        zone: string,
        productIds: string[]
    ): Promise<GenericResponseDto<ZoneRecapProductDto>> {
        const params = new ZoneRecapParamsDto();
        params.zone = zone;
        const productIdArrayDto = new StringArrayDto();
        productIdArrayDto.values = productIds;
        params.productIdArrayDto = productIdArrayDto;

        const offline = (key: ZoneRecapParamsDto) => {
            return this.zoneRecapOfflineService.offlineError(
                key
            );
        };
        const online = (key: ZoneRecapParamsDto) => {
            return this.zoneRecapOnlineService.upsertProducts(
                key
            );
        };
        const response = await this.datasourceDelineationService.makeCall<ZoneRecapParamsDto, ZoneRecapProductDto>(params, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }
}
