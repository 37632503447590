export class AddressDto {
    id: string;
    name: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    county: string | null;
    countyFipsCode: string | null;
    state: string | null;
    zip: string | null;
    country: string | null;
    latitude: number | null;
    longitude: number | null;
    dateCreated: string | null;
    lastUpdated: string | null;
}
