import { ElementRef } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidatorFn
} from "@angular/forms";
import {
    MatDrawerMode,
    MatSidenav,
    MatSidenavContent
} from "@angular/material/sidenav";
import * as moment from "moment";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { ColumnSelector } from "src/app/shared/viewmodels/column-selector.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import {
    CallHistoryColumns,
    FilterSortDto,
    GenericDropDownDto,
    RefinerLocation,
    SharedHelper,
    SortDirection,
    Subsidiary} from "shield.shared";
import { Moment } from "moment";
import { BehaviorSubject, Subscription } from "rxjs";
import { map, skip, skipWhile } from "rxjs/operators";
import { SnackbarService } from "src/app/services/snackbar.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { AppStateService } from "src/app/services/app-state.service";
import { CallPicturesRefinerService } from "./call-pictures-refiner.service";
import { Employee } from "src/app/entity-models/employee.entity";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { CallTableRowViewModel } from "../../accounts/account.viewmodels/call-table-row.viewmodel";
import { Router } from "@angular/router";
import { FilterLocation } from "src/app/enums/filter-location";
import { MatSort, MatSortHeader } from "@angular/material/sort";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { ActivitiesFilterService } from "src/app/services/activities-filter.service";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { Helper } from "src/app/helpers/helper";
import { CallPicturesZrtFilterService } from "./call-pictures-zrt-filter.service";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { MyListCommands } from "src/app/enums/my-list-commands";
import { OverlayService } from "src/app/services/overlay.service";
import { BaseFilterMapService } from "src/app/services/filter-map-services/base-filter-map.service";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";

export class CallPicturesViewmodel {
    //private vars
    private formBuilder: FormBuilder;

    //public vars
    accountInput: string;
    accountOwnershipDelineationService: AccountOwnershipDelineationService;
    activeRefiners: Refiner[] = [];
    activitiesFilterService: ActivitiesFilterService;
    appStateService: AppStateService;
    availableColumns: ColumnSelector[];
    baseGridYOffset = 0;
    callDelineationService: CallDelineationService;
    columns = CallHistoryColumns;
    cityInput: string;
    columnDef: ColumnDef[];
    columnsToDisplay: string[];
    confirmationOverlayRef: SwisherOverlayRef<ConfirmationDialogViewmodel, ConfirmationDialogComponent>;
    currentRefinerMap: Map<RefinerLocation, string> = new Map();
    dataSource: MatTableDataSource<GridData> = new MatTableDataSource<GridData>();
    dateForm: FormGroup;
    dateFormat: string = MY_DATE_FORMATS.display.dateInput;
    detailHeight = 48;
    displayedColumns: string[] = [];
    drawer: MatSidenav;
    drawerMode: MatDrawerMode = "side";
    endDateDefault: moment.Moment = moment();
    endDateInput: ElementRef;
    endMaxDate: moment.Moment = moment().add(3, "years");
    endMinDate: moment.Moment = moment().subtract(3, "years");
    employee: Employee;
    employeeDelineationService: EmployeeDelineationService;
    employeeSubscription: Subscription;
    filters: FilterAndParams[] = [];
    filterDataLoaded = false;
    filterLocation = FilterLocation.callPictures;
    filterService: FilterService;
    filterSorts: FilterSortDto<CallHistoryColumns>[] = [];
    grid: MatTable<GridData>;
    gridData = new Array<GridData>();
    gridDataChangeSubscription: Subscription;
    gridHeight = "75vh";
    gridhieghtOffsetDeduction = 0;
    header: PageHeaderComponent;
    headerButtons: HeaderButtonComponent[] = new Array<HeaderButtonComponent>();
    headerName = "Call Pictures";
    inBufferScrollPlay = true;
    isGapSet = false;
    isSearchButtonDisabled = false;
    itemsRendedInViewPort = 2;
    itemSize = 48;
    lastCall = false;
    myListSubscription: Subscription;
    overlayService: OverlayService;
    pageIndex = 0;
    pageSize = 50;
    pleaseWaitService: PleaseWaitService;
    previousRefinerMap: Map<RefinerLocation, string> = new Map();
    projectDelineationService: ProjectDelineationService;
    receiptInput: string;
    refinerInputChangeSubscription: Subscription;
    refinerService: CallPicturesRefinerService;
    refinerServiceSubscription: Subscription;
    router: Router;
    screenHeight: number = 0;
    shouldResetGrid = false;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    sideNavContent: MatSidenavContent;
    snackbar: SnackbarService;
    sort: MatSort;
    startDateDefault: moment.Moment = moment().subtract(7, "days");
    startDateInput: ElementRef;
    startMaxDate: moment.Moment = moment().add(3, "years");
    startMinDate: moment.Moment = moment().subtract(3, "years");
    streetInput: string;
    topGapDistance = 0;
    total?: number;
    zipInput: string;
    zrtFilterService: CallPicturesZrtFilterService;
    zrtSelectionSubscription: Subscription;

    constructor(
        refinerService: CallPicturesRefinerService,
        formBuilder: FormBuilder,
        callDelineationService: CallDelineationService,
        snackbar: SnackbarService,
        pleaseWaitService: PleaseWaitService,
        appStateService: AppStateService,
        router: Router,
        employeeDelineationService: EmployeeDelineationService,
        filterService: FilterService,
        zrtFilterService: CallPicturesZrtFilterService,
        accountOwnershipDelineationService: AccountOwnershipDelineationService,
        activitiesFilterService: ActivitiesFilterService,
        projectDelineationService: ProjectDelineationService,
        overlayService: OverlayService,
        private px3RankService: Px3DelineationService
    ) {
        this.refinerService = refinerService;
        this.callDelineationService = callDelineationService;
        this.snackbar = snackbar;
        this.pleaseWaitService = pleaseWaitService;
        this.appStateService = appStateService;
        this.router = router;
        this.employeeDelineationService = employeeDelineationService;
        this.filterService = filterService;
        this.zrtFilterService = zrtFilterService;
        this.accountOwnershipDelineationService = accountOwnershipDelineationService;
        this.activitiesFilterService = activitiesFilterService;
        this.projectDelineationService = projectDelineationService;
        this.overlayService = overlayService;

        this.formBuilder = formBuilder;
        this.dateForm = this.formBuilder.group({
            endDate: ["", [this.lessThanStartDate()]],
            startDate: ["", [this.greaterThanEndDate()]]
        });
    }

    //events
    onButtonAction(value: any): void {
        switch (value) {
            default:
                break;
        }
    }

    onButtonClickEvent(value: HeaderButtonComponent) {
        if (value.onClick) {
            value.onClick();
        }
    }

    onAddAccountRefiner(event?: KeyboardEvent): void {
        if (this.accountInput
            && this.refinerService.getRefinerByLocation(RefinerLocation.account)?.value !== this.accountInput) {
            this.refinerService.onInputChange(
                RefinerLocation.account,
                this.accountInput
            );
        }

        if (this.accountInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.account,
                this.accountInput,
                "name"
            );
        }

        if (!this.accountInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.account,
                true,
                false,
                true
            )
        }
    }

    onAddStreetRefiner(event?: KeyboardEvent): void {
        if (this.streetInput
            && this.refinerService.getRefinerByLocation(RefinerLocation.street)?.value !== this.streetInput) {
            this.refinerService.onInputChange(
                RefinerLocation.street,
                this.streetInput
            );
        }

        if (this.streetInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.street,
                this.streetInput,
                "address"
            );
        }

        if (!this.streetInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.street,
                true,
                false
            )
        }
    }

    onAddCityRefiner(event?: KeyboardEvent): void {
        if (this.cityInput
            && this.refinerService.getRefinerByLocation(RefinerLocation.city)?.value !== this.cityInput) {
            this.refinerService.onInputChange(
                RefinerLocation.city,
                this.cityInput
            );
        }

        if (this.cityInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.city,
                this.cityInput,
                "city"
            );
        }

        if (!this.cityInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.city,
                true,
                false,
                true
            )
        }
    }

    onAddZipRefiner(event?: KeyboardEvent): void {
        if (this.zipInput
            && this.refinerService.getRefinerByLocation(RefinerLocation.zipCodes)?.value !== this.zipInput) {
            this.refinerService.onInputChange(
                RefinerLocation.zipCodes,
                this.zipInput
            );
        }

        if (this.zipInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.zipCodes,
                this.zipInput,
                "zip"
            );
        }

        if (!this.zipInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.zipCodes,
                true,
                false
            )
        }
    }

    onAddEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.endDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, this.dateFormat) &&
            !this.dateForm.controls.endDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.callOnOrBeforeDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.callOnOrBeforeDate,
                this.endDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.endDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.callOnOrBeforeDate,
                    this.dateForm.controls.endDate.value?.format(
                        this.dateFormat
                    ),
                    "callOnOrBeforeDate"
                );
            }
        } else {
            if (!date && event?.type === "blur") {
                this.refinerService.removeRefinerByLocation(
                    RefinerLocation.callOnOrBeforeDate,
                    true,
                    false
                );
                this.dateForm.controls.endDate.setValue(null);
            }
        }
    }

    onAddStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.startDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, this.dateFormat) &&
            !this.dateForm.controls.startDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.callOnOrAfterDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.callOnOrAfterDate,
                this.startDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.startDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.callOnOrAfterDate,
                    this.dateForm.controls.startDate.value?.format(
                        this.dateFormat
                    ),
                    "callOnOrAfterDate"
                );
            }
        } else {
            if (!date && event?.type === "blur") {
                this.refinerService.removeRefinerByLocation(
                    RefinerLocation.callOnOrAfterDate,
                    true,
                    false
                );
                this.dateForm.controls.startDate.setValue(null);
            }
        }
    }

    onLastCallCheckboxChange(): void {
        this.shouldResetGrid = true;

        if (!this.lastCall) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.lastCallOnAccount,
                true,
                false
            );
            return;
        }
        const refiner = new Refiner();
        refiner.location = RefinerLocation.lastCallOnAccount;
        refiner.dataValue = refiner.value = BaseFilterMapService.yes;

        this.refinerService.checkAndUpdateRefiner(refiner);
    }

    onRefinersChange() {
        this.setRefinerInput();

        if (this.header) {
            if (this.refinerService.refiners.length === 0) {
                this.header.expanded = false;
            } else {
                this.header.expanded = true;
            }
        }

        // Search on removal
        const shouldAddInputs = false;
        this.search(shouldAddInputs);
    }

    onScrolledIndexChanged(index: number): void {
        if (!this.total || this.gridData.length < this.total) {
            if (!this.inBufferScrollPlay) {
                if (
                    Math.ceil(index) + this.itemsRendedInViewPort >=
                    this.dataSource.filteredData.length /
                            this.itemSize
                ) {
                    if (index) {
                        this.inBufferScrollPlay = true;
                    }
                    this.getCallBatch(index);
                }
            }
        }
    }

    onSort(column: string): void {
        const filterSorts = new Array<FilterSortDto<CallHistoryColumns>>();
        this.sort.sortables.forEach((item) => {
            let sortDirection: SortDirection;
            switch ((item as MatSortHeader)._sort.direction) {
                case "asc":
                    sortDirection = SortDirection.ascending;
                    break;
                case "desc":
                    sortDirection = SortDirection.descending;
                    break;
                default:
                    sortDirection = SortDirection.none;
                    break;
            }
            const sortDto = new FilterSortDto<CallHistoryColumns>();
            sortDto.direction = sortDirection;
            const headerName = this.columnDef.find(
                (cd) => cd.headerName === item.id
            )?.headerName;
            if (
                headerName === column &&
                sortDirection != SortDirection.none
            ) {
                sortDto.column = headerName as CallHistoryColumns;
                filterSorts.push(sortDto);
            }
        });

        this.filterSorts = filterSorts;
        this.getCallBatch(0, true);
    }

    //public methods
    calculateGap(): void {
        if (this.sideNavContent) {
            setTimeout(() => {
                this.topGapDistance =
                    window.pageYOffset +
                        this.sideNavContent
                            .getElementRef()
                            ?.nativeElement?.getBoundingClientRect()?.top ?? 0;
                this.gridhieghtOffsetDeduction =
                    window.pageYOffset +
                        this.sideNavContent
                            .getElementRef()
                            ?.nativeElement?.getBoundingClientRect()?.bottom ??
                    0;
                if (
                    this.baseGridYOffset === 0 &&
                    this.gridhieghtOffsetDeduction !== 0
                ) {
                    this.baseGridYOffset = this.gridhieghtOffsetDeduction;
                }
                this.gridHeight =
                    75 * (this.screenHeight / 100) +
                    (this.baseGridYOffset - this.gridhieghtOffsetDeduction) +
                    "px";
            }, 0);
            this.isGapSet = true;
        }
    }

    checkSearchError(): void {
        if (this.dateForm) {
            if (
                this.dateForm
                    .get("endDate")
                    .hasError("dateLessThanStartDate") ||
                this.dateForm
                    .get("startDate")
                    .hasError("dateGreaterThanEndDate")
            ) {
                this.isSearchButtonDisabled = true;
            } else {
                this.isSearchButtonDisabled = false;
            }
        }
    }

    reset(): void {
        this.refinerService.resetRefiners();
    }

    async getCallBatch(
        index: number,
        force?: boolean
    ): Promise<void> {
        if (this.refinerService.areDefaultsSet) {
            this.inBufferScrollPlay = false;

            const areRefinersTheSame = this.resolvePageIndex(index);
            if (
                this.employee && (
                    this.total != this.gridData?.length ||
                    !areRefinersTheSame ||
                    force ||
                    this.shouldResetGrid
                )
            ) {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
                setTimeout(async () => {
                    const zrtsToAdd = this.zrtFilterService.selectedZrts.map(v => v.zrt);
                    this.activeRefiners = this.refinerService.refiners.slice();

                    const results = await this.callDelineationService.getCallHistoryBatch(
                        this.employee.id,
                        this.employee.searchableZrt,
                        this.activeRefiners,
                        this.pageSize,
                        this.pageIndex * this.pageSize,
                        this.filterSorts,
                        zrtsToAdd,
                        this.lastCall,
                        true
                    );
                    if (!results) {
                        this.shouldResetGrid = true;
                        this.shouldWait$.next(true);
                        return;
                    }

                    this.total = results.getCount();
                    this.isGapSet = false;

                    let gridData: GridData[] = this.gridData.slice();
                    if (this.pageIndex === 0) {
                        gridData = new Array<GridData>();
                    }
                    const largestIndex = gridData.length;
                    const px3Ranks = new Map((await this.px3RankService.getAll()).map((pr) => [pr.id, pr.rank]));
                    for (const call of results?.values ?? []) {
                        const gridItem: GridData = {
                            data: new CallTableRowViewModel(call, px3Ranks.get(call.px3RankId)),
                            detailArrayName: "",
                            isExpanded: false,
                            index: largestIndex + results.values.indexOf(call)
                        };
                        gridData.push(gridItem);
                    }
                    this.gridData = gridData;

                    this.dataSource = new MatTableDataSource(
                        this.gridData
                    );
                    this.shouldResetGrid = false;
                    this.shouldWait$.next(true);
                }, 0);
            }
        }
    }

    greaterThanEndDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            const momentStart: Moment = control.value as Moment;

            if (momentStart) {
                const startDate = momentStart.startOf("day").valueOf();
                this.endMinDate = momentStart;

                if (this.dateForm.controls.endDate.value) {
                    const endDate = (this.dateForm.controls.endDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (startDate > endDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.endMinDate = null;
            }

            this.checkSearchError();

            return forbidden ? { dateGreaterThanEndDate: true } : null;
        };
    }

    headerExpansionChanged(): void {
        this.isGapSet = false;
    }

    async initialize(
        grid: MatTable<GridData>,
        header: PageHeaderComponent,
        sideNavContent: MatSidenavContent,
        drawer: MatSidenav,
        sort: MatSort,
        startDateInput: ElementRef,
        endDateInput: ElementRef
    ): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(
            this.shouldWait$
        );

        this.grid = grid;
        this.header = header;
        this.sideNavContent = sideNavContent;
        this.drawer = drawer;
        this.sort = sort;
        this.startDateInput = startDateInput;
        this.endDateInput = endDateInput;

        this.displayedColumns = [
            CallHistoryColumns.pictures,
            CallHistoryColumns.storeInformation,
            CallHistoryColumns.lastCall
        ];

        this.columnDef = [
            {
                headerName: CallHistoryColumns.pictures,
                dataPropertyName: "pictures",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: CallHistoryColumns.storeInformation,
                dataPropertyName: "storeInfo",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: CallHistoryColumns.lastCall,
                dataPropertyName: "lastCall",
                isAvailable: true,
                isSelected: true
            }
        ];

        if (this.refinerService.areDefaultsSet) {
            this.populateInputsFromRefiners();
        }

        if (
            !this.refinerServiceSubscription ||
            this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription = this.refinerService.refiners$.pipe(
                skipWhile(() => !this.filterDataLoaded && !this.refinerService.areDefaultsSet)
            ).subscribe(
                () => {
                    this.onRefinersChange();
                }
            );
        }

        if (
            !this.refinerInputChangeSubscription ||
            this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription = this.refinerService.refinerInputChange$.subscribe(
                (refiner) => {
                    if (refiner) {
                        this.setInputFromRefiner(refiner);

                        if (refiner.shouldSearchWhenCleared && !refiner.value) {
                            this.getCallBatch(0);
                        }
                    }

                    if (this.header) {
                        if (this.refinerService.refiners.length === 0) {
                            this.header.expanded = false;
                        } else {
                            this.header.expanded = true;
                        }
                    }
                    this.isGapSet = false;
                }
            );
        }

        if(!this.employeeSubscription || this.employeeSubscription.closed) {
            this.employeeSubscription = this.appStateService.currentEmployee.subscribe(
                async (employee) => {
                    if (employee) {
                        this.employee = employee;
                        if (employee) {
                            await this.setFilterData();
                            void this.getCallBatch(0);
                        }
                    }
                }
            );
        }

        if (
            !this.zrtSelectionSubscription ||
            this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription = this.zrtFilterService.observableSelectedZrtsHandleRefiners(this.refinerService).subscribe();
        }

        if (!this.myListSubscription ||
            this.myListSubscription.closed
        ) {
            this.myListSubscription = this.filterService.myListCommandObservable.pipe(skip(1)).subscribe(async (command) => {
                switch (command) {
                    case MyListCommands.toggleOn:
                    case MyListCommands.toggleOff:
                        this.getCallBatch(0, true);
                        break;
                    case MyListCommands.push:
                        this.getMyListIds();
                        break;
                    default:
                        break;

                }
            });
        }
    }

    lessThanStartDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            if (control.value) {
                const endDate = (control.value as Moment)
                    .startOf("day")
                    .valueOf();
                this.startMaxDate = control.value;

                if (this.dateForm.controls.startDate.value) {
                    const startDate = (this.dateForm.controls.startDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (endDate < startDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.startMaxDate = moment();
            }

            this.checkSearchError();

            return forbidden ? { dateLessThanStartDate: true } : null;
        };
    }

    populateInputsFromRefiners(): void {
        for (const refiner of this.refinerService.refiners) {
            this.setInputFromRefiner(refiner);
        }
    }

    resolvePageIndex(index: number): boolean {
        this.currentRefinerMap = new Map<RefinerLocation, string>();
        for (const refiner of this.refinerService.refiners ?? []) {
            this.currentRefinerMap.set(refiner.location, refiner.dataValue ?? refiner.value);
        }

        const rtn = SharedHelper.compareMaps(
            this.currentRefinerMap,
            this.previousRefinerMap
        );

        if (rtn && this.total != this.gridData?.length && index !== 0) {
            this.pageIndex++;
        } else {
            this.previousRefinerMap = new Map(this.currentRefinerMap);
            this.pageIndex = 0;
        }
        return rtn;
    }

    routeToStoreProfile(index: number) {
        const customerId = this.gridData[index].data.customerId;
        void this.router.navigate(["/accounts", customerId, "profile"]);
    }

    search(shouldAddInputs: boolean = true) {
        if (shouldAddInputs) {
            this.onAddAccountRefiner();
            this.onAddStreetRefiner();
            this.onAddCityRefiner();
            this.onAddZipRefiner();
            this.onAddStartDateRefiner();
            this.onAddEndDateRefiner();
        }
        this.activeRefiners = this.refinerService.refiners.slice();

        this.getCallBatch(this.pageIndex);
    }

    async setActivitiesFilter(): Promise<void> {
        const projectResponse = await this.projectDelineationService.getDropDown();
        if (!projectResponse) { return; }

        const projects = projectResponse.values;
        this.activitiesFilterService.projects = projects ?? new Array<GenericDropDownDto>();

        const chainsResponse = await this.accountOwnershipDelineationService.getAllInDropDown();
        if (chainsResponse?.values?.length) {
            this.activitiesFilterService.chains = chainsResponse.values.sort((a, b) => a.name.localeCompare(b.name));
        } else {
            this.activitiesFilterService.chains = new Array<AccountOwnership>();
        }
    }

    setDefaultFilter(): void {
        if (!(this.zrtFilterService.employeeZrts?.length) || !this.employee) {
            return;
        }

        if (!this.refinerService.areDefaultsSet) {
            this.dateForm.controls.startDate.setValue(this.startDateDefault);
            this.onAddStartDateRefiner();

            this.dateForm.controls.endDate.setValue(this.endDateDefault);
            this.onAddEndDateRefiner();

            const refiner = this.zrtFilterService.createDefaultZrtRefiner(this.employee);
            const isCorporateUser = Helper.isEmployeeCustomerServiceOrAdmin(this.employee);
            if (refiner) {
                this.refinerService.checkAndUpdateRefiner(refiner, true);
                if (isCorporateUser) {
                    this.zrtFilterService.defaultZrtSelection = this.zrtFilterService.selectedZrts;
                }
            }
            this.refinerService.isCorporateUser = isCorporateUser;

            this.refinerService.areDefaultsSet = true;
        }

        this.filterDataLoaded = true;
    }

    async setFilterData(): Promise<void> {
        if (Helper.isEmployeeAllowedToRemoveZrtFilter(this.employee)) {
            this.zrtFilterService.areas = await this.filterService.getAreas();
            const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts();
            if (zrtsResponse && zrtsResponse?.values) {
                this.zrtFilterService.employeeZrts = zrtsResponse.values;
            }
        } else {
            const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts(this.employee.searchableZrt ?? undefined);
            if (zrtsResponse && zrtsResponse?.values) {
                this.zrtFilterService.employeeZrts = zrtsResponse.values;
            }
        }

        void this.setActivitiesFilter();

        this.setDefaultFilter();
    }

    setRefinerInput(): void {
        if (!this.filterDataLoaded) return;

        this.setDefaultFilter();

        if (!this.refinerService.refiners.filter(v =>
                v.location == RefinerLocation.zrtByArea ||
                v.location == RefinerLocation.zrtByEmployee
            ).length
        ) {
            this.zrtFilterService.selectedZrts = [];
        }

        this.dateForm.controls.startDate.setValue(null);
        this.dateForm.controls.endDate.setValue(null);

        this.lastCall = false;

        this.accountInput = null;
        this.streetInput = null;
        this.cityInput = null;
        this.zipInput = null;

        this.populateInputsFromRefiners();
    }

    showFilters(): void {
        this.drawer.toggle();
        this.isGapSet = false;
    }

    shouldHideArrows(pictures: any[]): boolean {
        return !pictures?.length;
    }

    updateDisplayCaptions(row: CallTableRowViewModel, carouselIndex: number): void {
        carouselIndex--;
        if (carouselIndex == -1 || !row.otherPictures?.length) {
            row.otherPictureTags = row.afterPicture?.tags
                ? Helper.valueSeparatedStringToFormattedString(row.afterPicture.tags)
                : "N/A";
            row.otherPictureCaption = "After";
            return;
        }
        const otherPicture = row.otherPictures[carouselIndex];
        row.otherPictureTags = otherPicture?.tags
            ? Helper.valueSeparatedStringToFormattedString(otherPicture.tags)
            : "N/A";
        row.otherPictureCaption = otherPicture?.type ?? "Other";
    }

    unsubscribe(): void {
        if (
            this.refinerServiceSubscription && !this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription.unsubscribe();
        }
        if (
            this.refinerInputChangeSubscription && !this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription.unsubscribe();
        }
        if (
            this.gridDataChangeSubscription && !this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription.unsubscribe();
        }
        if (
            this.employeeSubscription && !this.employeeSubscription.closed
        ) {
            this.employeeSubscription.unsubscribe();
        }
        if (
            this.zrtSelectionSubscription && !this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription.unsubscribe();
        }
        if (
            this.myListSubscription && !this.myListSubscription.closed
        ) {
            this.myListSubscription.unsubscribe();
        }
    }

    private getMyListIds(): void {
        setTimeout(async () => {
            if (this.total > 10000) {
                this.snackbar.showWarning("Total entry count is too high. Cannot push more than 10,000 records at once.");
                this.filterService.myListEntries$.next(undefined);
            } else {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                    this.shouldWait$
                );
                const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                data.header = "Confirmation";
                data.message =
                    "Would you like to push all entries to My List?";
                data.buttonLeftText = "Cancel";
                data.buttonLeftFunction = () => {
                    this.confirmationOverlayRef.close(data);
                };
                data.buttonRightText = "Yes";
                data.buttonRightFunction = () => {
                    data.isConfirmed = true;
                    this.confirmationOverlayRef.close(data);
                };

                this.confirmationOverlayRef = this.overlayService.open(
                    ConfirmationDialogComponent,
                    data
                );

                this.confirmationOverlayRef.afterClosed$
                    .pipe(
                        map(async (event) => {
                            if (event && event.data && event.data.isConfirmed) {
                                this.filterService.myListEntries$.next(await this.getIdsForMyList());
                                this.shouldWait$.next(true);
                            } else {
                                this.filterService.myListEntries$.next(undefined);
                                this.shouldWait$.next(true);
                            }
                        })
                    )
                    .subscribe();
            }
        }, 0);
    }

    private async getIdsForMyList(): Promise<string[]> {
        let rtn = this.dataSource.filteredData.map((gd) => gd.data.id);

        if (this.callDelineationService.getOnlineState() && rtn.length < this.total) {
            const gridIdResponse = await this.callDelineationService.getCallHistoryBatch(
                this.employee.id,
                this.employee.zrt,
                this.activeRefiners,
                10000,
                this.pageIndex * this.pageSize,
                this.filterSorts,
                this.zrtFilterService.selectedZrts.map(v => v.zrt),
                this.lastCall,
                true
            );
            if (gridIdResponse?.values) {
                const gridIds = gridIdResponse.values.map(v => v.id);
                rtn = rtn.concat(gridIds.filter((v) => !rtn.includes(v)));
            }
        }

        return rtn;
    }

    private setInputFromRefiner(refiner: Refiner): void {
        switch (refiner.location) {
            case RefinerLocation.zrtByArea:
            case RefinerLocation.zrtByEmployee:
                this.zrtFilterService.applyRefiner(refiner);
                break;
            case RefinerLocation.account:
                this.accountInput = refiner.value;
                break;
            case RefinerLocation.street:
                this.streetInput = refiner.value;
                break;
            case RefinerLocation.city:
                this.cityInput = refiner.value;
                break;
            case RefinerLocation.zipCodes:
                this.zipInput = refiner.value;
                break;
            case RefinerLocation.callOnOrAfterDate:
                const startDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.startDate.setValue(
                    startDate
                );
                break;
            case RefinerLocation.callOnOrBeforeDate:
                const endDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.endDate.setValue(
                    endDate
                );
                break;
            case RefinerLocation.lastCallOnAccount:
                if (!refiner.value) {
                    this.lastCall = false;
                    this.onLastCallCheckboxChange();
                }
                break;
            default:
                break;
        }
    }
}
