import { CallOrderProduct } from "src/app/entity-models/call-order-product.entity";
import { ChainHqCall } from "src/app/entity-models/chain-hq-call.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { ActivitySurveyViewModel } from "../../account.viewmodels/activity-survey.viewmodel";
import { CallOrderProductViewModel } from "../call-viewmodels/call-order-product.viewmodel";

export class ChainHqCallViewmodel {
    chainHqCall: ChainHqCall;
    surveys: ActivitySurveyViewModel[] = [];
    closingNotes: string;
    callComments: string;
    shareEmployees: Employee[] = [];
    chainHqCallOrderProductViewModels: CallOrderProductViewModel[] = [];

    //modal props
    headerLeftText: string;
    headerRightText: string;
    width = "95vw";
    height = "95vh";

    //https://cuppalabs.github.io/components/multiselectDropdown/
    wholeSalerDropdownSettings = {
        singleSelection: true,
        showCheckbox: false,
        text: "Select Wholesaler",
        enableCheckAll: false,
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "displayValue",
        searchBy: ["displayValue"],
        classes: "multi-select-container c-btn pure-checkbox"
    }

    buildViewModelFromDomainModel(chq: ChainHqCall): void {
        if (chq) {
            this.chainHqCall = chq;
            this.surveys = chq.surveys?.slice();
            this.closingNotes = chq.closingNotes;
        }
    }

    buildDomainModelFromViewModel(vms: CallOrderProductViewModel[]): ChainHqCall {
        const rtn = this.chainHqCall;
        rtn.surveys = this.surveys;
        rtn.closingNotes = this.closingNotes;
        const orders: CallOrderProduct[] = [];

        for (const order of vms) {
            const myOrder = new CallOrderProduct();
            myOrder.id = order.id;
            myOrder.productId = order.product.id;
            myOrder.quantity = order.quantity;
            myOrder.units = order.units;
            myOrder.wholesalerCustomerId = order.wholesaler?.length ? order.wholesaler[0].id : null;
            myOrder.uin = order.uin;
            myOrder.upc = order.upc;
            myOrder.callOrderDates = order.orderDates;
            myOrder.storeCount = order.storeCount;
            orders.push(myOrder);
        }
        rtn.orderProducts = orders;

        return rtn;
    }
}
