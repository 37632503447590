import {
    Component,
    OnInit,
    Type,
    ViewChild
} from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterService } from "src/app/services/filter.service";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import {
    CustomerTypeEnum,
    GenericLookup,
    RefinerLocation,
    valueSeparator
} from "shield.shared";
import { CallHistoryAttributesViewmodel } from "./call-history-attributes.viewmodel";
import { BaseFilterMapService } from "src/app/services/filter-map-services/base-filter-map.service";
import { Px3Rank } from "src/app/entity-models/px3-rank.entity";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";

@Component({
    selector: "app-call-history-attributes-filter",
    templateUrl: "./call-history-attributes-filter.component.html",
    styleUrls: ["./call-history-attributes-filter.component.scss"]
})
export class CallHistoryAttributesFilterComponent extends FilterBaseComponent implements OnInit {
    //view childs
    @ViewChild("callTypesSelect") callTypesSelect: MatSelect;
    @ViewChild("picturesSelect") picturesSelect: MatSelect;
    @ViewChild("storeTypesSelect") customerTypesSelect: MatSelect;
    @ViewChild("px3RankSelect") px3RankSelect: MatSelect;

    //public vars
    contentComponent: Type<CallHistoryAttributesFilterComponent>;
    icon = "bookmark";
    name = "Attributes";
    viewmodel = new CallHistoryAttributesViewmodel(this.filterService, this.px3RankService);

    constructor(private filterService: FilterService, private px3RankService: Px3DelineationService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        await this.viewmodel.initializeTypeDropDowns();
        await this.viewmodel.initializePx3RankDropDown();
        this.setInitialized();
    }

    //events
    onOpenedCustomerTypesChange(): void {
        this.viewmodel.isAllCustomerTypePresent = this.viewmodel.selectedCutomerTypes.includes(
            this.viewmodel.allCustomerType
        );
        if (this.viewmodel.isAllCustomerTypePresent) {
            this._refinerService.removeRefinerByLocation(RefinerLocation.storeTypes, true, false);
        }
        else {
            if (!this.customerTypesSelect.panelOpen) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.storeTypes;
                refiner.value = this.viewmodel.selectedCutomerTypes
                .map((vm) => vm.name)
                .join(", ");
                refiner.dataValue = this.viewmodel.selectedCutomerTypes
                    .map((vm) => vm.name)
                    .join(valueSeparator);
                refiner.dataPropertyName = "name";

                this._refinerService.checkAndUpdateRefiner(refiner);
            }
        }
    }

    onSelectionCustomerTypesChange(): void {
        const {isAllPresent, selected} = FilterBaseComponent.onMultiSelectSelectionChange(this.viewmodel.isAllCustomerTypePresent, this.viewmodel.selectedCutomerTypes, this.viewmodel.allCustomerType);
        this.viewmodel.isAllCallTypePresent = isAllPresent;
        this.viewmodel.selectedCutomerTypes = selected;
    }

    onOpenedCallTypesChange(): void {
        this.viewmodel.isAllCallTypePresent = this.viewmodel.selectedCallTypes.includes(
            this.viewmodel.allCallType
        );
        if (this.viewmodel.isAllCallTypePresent) {
            this._refinerService.removeRefinerByLocation(RefinerLocation.callType, true, false);
        }
        else {
            if (!this.callTypesSelect?.panelOpen) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.callType;
                refiner.value = this.viewmodel.selectedCallTypes
                    .join(", ");
                refiner.dataValue = this.viewmodel.selectedCallTypes
                    .join(valueSeparator);
                refiner.dataPropertyName = "callType";

                this._refinerService.checkAndUpdateRefiner(refiner);
            }
        }
    }

    onSelectionCallTypesChange(): void {
        if (
            this.viewmodel.isAllCallTypePresent &&
            this.viewmodel.selectedCallTypes.length > 1
        ) {
            const index = this.viewmodel.selectedCallTypes.findIndex(
                (ct) => ct === this.viewmodel.allCallType
            );
            if (index !== -1) {
                this.viewmodel.selectedCallTypes.splice(index, 1);
                this.viewmodel.isAllCallTypePresent = false;
            }
        } else if (
            !this.viewmodel.isAllCallTypePresent &&
            this.viewmodel.selectedCallTypes.includes(
                this.viewmodel.allCallType
            )
        ) {
            this.viewmodel.selectedCallTypes = [
                this.viewmodel.allCallType
            ];
            this.viewmodel.isAllCallTypePresent = true;
        }
    }

    onOpenedSelectedPicturesChange(): void {
        if (!this.picturesSelect.panelOpen) {
            if (this.viewmodel.selectedPictures === "All") {
                this._refinerService.removeRefinerByLocation(RefinerLocation.hasPictures, true, false);
            }
            else {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.hasPictures;
                refiner.value = this.viewmodel.selectedPictures;
                refiner.dataPropertyName = "hasPictures";
                refiner.dataValue = refiner.value === this.viewmodel.yes ? BaseFilterMapService.yes : "0";

                this._refinerService.checkAndUpdateRefiner(refiner, true);
            }
        }
    }

    async onOpenedSelectedPx3Change(): Promise<void> {
        this.viewmodel.isAllPx3RankPresent = this.viewmodel.selectedPx3Ranks.includes(
            this.viewmodel.allPx3Rank
        );
        if (this.viewmodel.isAllPx3RankPresent) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.px3Rank,
                true,
                false
            );
        } else {
            if (!this.px3RankSelect.panelOpen) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.px3Rank;
                refiner.value = this.viewmodel.selectedPx3Ranks
                    .map((vm) => vm.name)
                    .join(", ");
                refiner.dataPropertyName = "px3RankId";
                refiner.dataValue = this.viewmodel.selectedPx3Ranks
                    .map((vm) => vm.name === 'Not Ranked' ? 'Not Ranked' : vm.id.id)
                    .join(valueSeparator);

                this.refinerService.checkAndUpdateRefiner(refiner);
            }
        }
    }

    onSelectionPx3RanksChange(): void {
        const {isAllPresent, selected} = FilterBaseComponent.onMultiSelectSelectionChange(this.viewmodel.isAllPx3RankPresent, this.viewmodel.selectedPx3Ranks, this.viewmodel.allPx3Rank);
        this.viewmodel.isAllPx3RankPresent = isAllPresent;
        this.viewmodel.selectedPx3Ranks = selected;
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {

                case RefinerLocation.storeTypes:
                    const selectedCustomerTypes = refiner.value?.split(", ");
                    const rtnSelectedCustomerTypes = new Array<GenericLookup<CustomerTypeEnum>>();

                    if (selectedCustomerTypes) {
                        for (const type of selectedCustomerTypes) {
                            const found = this.viewmodel.customerTypes.find(
                                (ct) => ct.name === type.trim()
                            );
                            if (found) {
                                rtnSelectedCustomerTypes.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedCutomerTypes = rtnSelectedCustomerTypes?.length
                        ? rtnSelectedCustomerTypes
                        : [this.viewmodel.allCustomerType];
                    break;
                case RefinerLocation.callType:
                    const selectedCallTypes = refiner.value?.split(", ");
                    const rtnSelectedCallTypes = new Array<string>();

                    if (selectedCallTypes) {
                        for (const type of selectedCallTypes) {
                            const found = this.viewmodel.callTypes.find(
                                (st) => st === type.trim()
                            );
                            if (found) {
                                rtnSelectedCallTypes.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedCallTypes = rtnSelectedCallTypes?.length ? rtnSelectedCallTypes : [this.viewmodel.allCallType];
                    break;;
                case RefinerLocation.hasPictures:
                    if (refiner.value !== this.viewmodel.selectedPictures) {
                        this.viewmodel.selectedPictures = !!refiner.value ? refiner.value : this.viewmodel.all;
                    }
                break;
                case RefinerLocation.px3Rank:
                    const selectedPx3Ranks = refiner.value?.split(", ");
                    const rtnSelectedPx3Ranks = new Array<
                        GenericLookup<Px3Rank>
                    >();

                    if (selectedPx3Ranks) {
                        for (const rank of selectedPx3Ranks) {
                            const found = this.viewmodel.px3Ranks.find(
                                (rnk) => rnk.name === rank.trim()
                            );
                            if (found) {
                                rtnSelectedPx3Ranks.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedPx3Ranks = rtnSelectedPx3Ranks?.length
                        ? rtnSelectedPx3Ranks
                        : [this.viewmodel.allPx3Rank];
                break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.storeTypes;
        const customerTypesRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.storeTypes
        );
        if (customerTypesRefiner) {
            this.onInputChange(customerTypesRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.callType;
        const callTypesRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.callType
        );
        if (callTypesRefiner) {
            this.onInputChange(callTypesRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.hasPictures;
        const picturesRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.hasPictures
        );
        if (picturesRefiner) {
            this.onInputChange(picturesRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }
}
