import { Injectable } from "@angular/core";
import { RefinerLocation } from "shield.shared";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { RefinerServiceBase } from "src/app/shared/refiner-service-base";

@Injectable()
export class AccountsListRefinerService extends RefinerServiceBase {

    constructor(private customerDelineationService: CustomerDelineationService) {
        super();
    }

    private readonly onlineRefinerLocations = [RefinerLocation.zrt, RefinerLocation.zrtByArea, RefinerLocation.zrtByEmployee];
    routingModeEnabled = false;

    shouldPreventRemoval(refiner: Refiner): boolean {
        return (
            this.onlineRefinerLocations.includes(refiner.location) &&
            (!this.customerDelineationService.getOnlineState() || this.routingModeEnabled)
        );
    }

    shouldResetDefaults(): boolean {
        const locations = (this.refiners ?? []).map(v => v.location);
        return !locations.some(v => this.onlineRefinerLocations.includes(v));
    }
}
