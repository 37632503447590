import {
    Component,
    OnInit,
    Type,
    ViewChild
} from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { RefinerLocation } from "shield.shared";
import { DailySummaryAttributesViewmodel } from "./daily-summary-attributes.viewmodel";
import { BaseFilterMapService } from "src/app/services/filter-map-services/base-filter-map.service";

@Component({
    selector: "app-daily-summary-attributes-filter",
    templateUrl: "./daily-summary-attributes-filter.component.html",
    styleUrls: ["./daily-summary-attributes-filter.component.scss"]
})
export class DailySummaryAttributesFilterComponent extends FilterBaseComponent implements OnInit {
    //view childs
    @ViewChild("resetSelect") resetSelect: MatSelect;

    //public vars
    contentComponent: Type<DailySummaryAttributesFilterComponent>;
    icon = "bookmark";
    name = "Attributes";
    viewmodel = new DailySummaryAttributesViewmodel();

    constructor() {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.setInitialized();
    }

    //events
    onOpenedSelectedResetDayChange(): void {
        if (!this.resetSelect.panelOpen) {
            if (this.viewmodel.selectedResetDays === "All") {
                this._refinerService.removeRefinerByLocation(RefinerLocation.resetDays, true, false);
            }
            else {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.resetDays;
                refiner.value = this.viewmodel.selectedResetDays;
                refiner.dataPropertyName = "reset";
                refiner.dataValue = this.viewmodel.selectedResetDays === this.viewmodel.yes ? BaseFilterMapService.yes : "0";

                this._refinerService.checkAndUpdateRefiner(refiner, true);
            }
        }
    }

    onAddWorkWithRefiner(event?: KeyboardEvent) {
        this._refinerService.onInputChange(
            RefinerLocation.workWithPerson,
            this.viewmodel.workWithInput
        );
        if (
            this.viewmodel.workWithInput &&
            (!event || this.inputSubmitKeys.includes(event.key))
        ) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.workWithPerson;
            refiner.value = this.viewmodel.workWithInput;
            refiner.dataValue = this._refinerService.buildStringDataValue(this.viewmodel.workWithInput);
            refiner.dataPropertyName = "workWith";
            this._refinerService.addRefiners(refiner);
        }
    }


    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.resetDays:
                    if (refiner.value !== this.viewmodel.selectedResetDays) {
                        this.viewmodel.selectedResetDays = !!refiner.value ? refiner.value : this.viewmodel.all;
                    }
                    break;
                case RefinerLocation.workWithPerson:
                    if (refiner.value !== this.viewmodel.workWithInput) {
                        this.viewmodel.workWithInput = refiner.value;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.resetDays;
        const resetDaysRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.resetDays
        );
        if (resetDaysRefiner) {
            this.onInputChange(resetDaysRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.workWithPerson;
        const workWithRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.workWithPerson
        );
        if (workWithRefiner) {
            this.onInputChange(workWithRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }
}
