import { GenericLookup } from "../generic";

export enum TaxTypes {
    State = 1,
    City = 2,
    County = 3
}

export const TaxTypeLookup: GenericLookup<TaxTypes>[] = [
    { id: TaxTypes.State, name: "State", description: "State" },
    { id: TaxTypes.City, name: "City", description: "City" },
    { id: TaxTypes.County, name: "County", description: "County" }
];
