export enum ProjectCustomerColumns {

    assigned = "Assigned",
    lastCall = "Last Call",
    storeName = "Store Name",
    address = "Address",
    city = "City",
    county = "County",
    state = "State",
    zip = "Zip",
    customerType = "Store Type",
    indVol = "Industry Volume",
    ssVol = "SS Volume",
    zrt = "Zrt",
    ssPercent = "SS %",
    msa = "MSA",
    phone = "Phone",
    chain = "Chain",
    territory = "Territory",
    assignments = "Assignments"
}
