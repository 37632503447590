import { ProjectBasicCustomer } from "src/app/entity-models/project-basic-customer.entity";

export class ProjectAssignmentGridViewmodel {
    isSelected: boolean;
    storeName: string;
    address: string;
    city: string;
    county: string;
    state: string;
    zip: string;
    zrt: string;
    chain: string;
    customerId: string;
    employeeIds: string[];
    displayAssignments: string;

    constructor(pbc: ProjectBasicCustomer, assignments?: string){
        this.customerId = pbc.customerId;
        this.isSelected = this.isSelected;
        this.storeName = pbc.name ?? "";
        this.address = pbc.address1 ?? "";
        this.city = pbc.city ?? "";
        this.county = pbc.county ?? "";
        this.state = pbc.state ?? "";
        this.zip = pbc.zip ?? "";
        this.zrt = pbc.zrt ?? "";
        this.chain = pbc.chain ?? "";
        this.displayAssignments = assignments ?? "";
    }
}
