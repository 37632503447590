import { CreateModifyBaseDto } from "../create-modify-base.dto";
import { ProductDto } from "./product.dto";
import { WholesalerProductCatalogItemDto } from "./wholesaler-product-catalog-item.dto";

export class WholesalerGroupProductCatalogItemDto extends CreateModifyBaseDto {
    id: string;
    wholesalerGroupId: string;
    product: ProductDto | undefined;
    productId: string;
    productUIN: string;
    department: string | null; //FOR FUTURE USE?
    dateAvailable?: string;
    wholesalerCount: string;
    isDeactivated: boolean;
    isDeactivatedDate: string | null;
    rowversion: string;
    wholesalerItems: WholesalerProductCatalogItemDto[];
}
