import { Injectable } from "@angular/core";
import { AccountsListRefinerService } from "src/app/accounts/accounts-list/accounts-list-refiner.service";
import { ProjectStoresRefinerService } from "src/app/details/project/project-configuration/project-stores/project-stores-refiner.service";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { RefinerServiceBase } from "../../refiner-service-base";

@Injectable()
export class MySearchesParityService {

    constructor(
        private accountsListRefinerService: AccountsListRefinerService,
        private projectStoresRefinerService: ProjectStoresRefinerService
    ){}

    checkParityOnRefinerAdd(addedRefiner: Refiner) {
        const refinerServices: RefinerServiceBase[] = [
            this.accountsListRefinerService,
            this.projectStoresRefinerService
        ];

        for (const service of refinerServices) {
            const found = service.refiners.find(v => v.location === addedRefiner.location);

            if (found) {
                service.addRefiners(addedRefiner);
            }
        }
    }
}
