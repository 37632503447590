import { Injectable } from "@angular/core";
import { CallBatchParamsDto, CallHistoryColumns, CallHistoryFilterDto, CallHistoryParamsDto, CallSyncCommand, CallTypes, FilterSortDto, SharedHelper, SortDirection } from "shield.shared";
import { Call } from "src/app/accounts/call-master/call-services/call.service";
import { CallHistoryEntry } from "src/app/entity-models/call-history-entry.entity";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { DataSyncQueueService } from "src/app/sync/data-sync-queue.service";
import { DatabaseService } from "../database.service";
import { Px3DelineationService } from "../delineation-services/px3-delineation.service";
import { OfflineServicesHelper } from "./offline-services-helper";
import Dexie, { Collection, Table } from "dexie";

@Injectable()
export class CallOfflineService {

    constructor(
        private dbService: DatabaseService,
        private dataSyncQueueService: DataSyncQueueService,
        private px3RankService: Px3DelineationService
    ) { }

    async getCallCountsForCustomerIds(
        customerIds: string[],
        startDate: Date,
        endDate: Date
    ) : Promise<Map<string, number>> {
        const result = new Map<string, number>();
        await Promise.all(customerIds.map(async (id) => {
            result.set(id, await this.dbService.calls.where('[customerId+stopTime]').between([id,startDate], [id,endDate]).count());
        }));
        return result;
    }

    private async applyCallHistoryFilterViaIndexes<T>(table: Table<T, string>, filter: CallHistoryFilterDto) {
        const collections: Collection<T, string>[] = [];
        if (filter.zrtFilterDto?.zrts) {
            const customerIds = await this.dbService.customers.where("zrt").anyOf(filter.zrtFilterDto.zrts).primaryKeys();
            collections.push(table.where("customerId").anyOf(customerIds));
        }
        if (filter.zrtFilterDto?.employeeIds) {
            collections.push(table.where("createdUserId").anyOf(filter.zrtFilterDto.employeeIds));
        }
        if (filter.businessAddressFilterDto?.counties) {
            collections.push(table.where("county").anyOf(filter.businessAddressFilterDto.counties));
        }
        if (filter.businessAddressFilterDto?.states) {
            collections.push(table.where("state").anyOf(filter.businessAddressFilterDto.states));
        }
        if (filter.callOnOrBeforeDate && filter.callOnOrAfterDate) {
            collections.push(table.where("stopTime").between(new Date(new Date(filter.callOnOrAfterDate).setHours(0,0,0,0)), new Date(new Date(filter.callOnOrBeforeDate).setHours(23,59,59,0))));
        } else if (filter.callOnOrAfterDate) {
            collections.push(table.where("stopTime").aboveOrEqual(new Date(new Date(filter.callOnOrAfterDate).setHours(0,0,0,0))));
        } else if (filter.callOnOrBeforeDate) {
            collections.push(table.where("stopTime").belowOrEqual(new Date(new Date(filter.callOnOrBeforeDate).setHours(0,0,0,0))));
        }
        if (filter.callTypes) {
            collections.push(table.where("callType").anyOf(filter.callTypes));
        }
        if (filter.hasPictures) {
            collections.push(table.where("hasPictures").equals(filter.hasPictures ? 1 : 0));
        }
        if (filter.px3Rank && filter.px3Rank.length > 0) {
            collections.push(table.where("px3RankId").anyOf(filter.px3Rank));
        }
        return collections;
    }

    private applyCallHistoryFilter(calls: CallHistoryEntry[], filter: CallHistoryFilterDto): CallHistoryEntry[] {
        if (!filter) return calls;
        if (filter.zrtFilterDto?.zrts) {
            calls = calls.filter((c) => filter.zrtFilterDto.zrts.includes(c.zrt));
        }
        if (filter.zrtFilterDto?.employeeIds) {
            calls = calls.filter((c) => filter.zrtFilterDto.employeeIds.includes(c.createdById));
        }
        if (filter.account) {
            calls = calls.filter(
                (c) => SharedHelper.searchStringArray(c.customerName, filter.account) ||
                       SharedHelper.searchStringArray(c.customerNumber, filter.account) ||
                       SharedHelper.searchStringArray(c.customerId, filter.account)
            );
        }

        if (filter.businessAddressFilterDto?.street) {
            calls = calls.filter((c) => SharedHelper.searchStringArray(c.address, filter.businessAddressFilterDto.street));
        }
        if (filter.businessAddressFilterDto?.city) {
            calls = calls.filter((c) => SharedHelper.searchStringArray(c.city, filter.businessAddressFilterDto.city));
        }
        if (filter.businessAddressFilterDto?.counties) {
            calls = calls.filter((c) => filter.businessAddressFilterDto.counties.includes(c.county));
        }
        if (filter.businessAddressFilterDto?.states) {
            calls = calls.filter((c) => filter.businessAddressFilterDto.states.includes(c.state));
        }
        if (filter.businessAddressFilterDto?.zipCode) {
            calls = calls.filter((c) => SharedHelper.searchString(c.zip, filter.businessAddressFilterDto.zipCode));
        }
        if (filter.callOnOrAfterDate) {
            calls = calls.filter((c) => c.stopTime && c.stopTime.getTime() >= new Date(filter.callOnOrAfterDate).setHours(0,0,0,0));
        }
        if (filter.callOnOrBeforeDate) {
            calls = calls.filter((c) => c.stopTime && c.stopTime.getTime() <= new Date(filter.callOnOrBeforeDate).setHours(23,59,59,0));
        }
        if (filter.customerTypeIds?.length) {
            calls = calls.filter((c) => filter.customerTypeIds.includes(c.customerType .id));
        }
        if (filter.callTypes) {
            calls = calls.filter((c) => filter.callTypes.includes(c.callType));
        }
        if (filter.px3Rank !== undefined && filter.px3Rank.length > 0) {
            calls = calls.filter((c) => {
                if (!c.px3RankId) {
                    return filter.px3Rank.includes('Not Ranked');
                }
                return filter.px3Rank.includes(c.px3RankId);
            });
        }
        if (filter.hasPictures !== undefined) {
            calls = calls.filter((c) => c.hasPictures == filter.hasPictures);
        }

        return calls;
    }

    async getCallById(id: string): Promise<Call> {
        let ret =  await this.dbService.calls
            .where("id")
            .equals(id)
            .first();
        if (!ret) {
            throw new Error('BYPASS');
        }
        return ret
    }

    async getCallByIds(ids: string[]): Promise<Call[]> {
        let ret =  await this.dbService.calls
            .where("id")
            .anyOf(ids)
            .toArray();
        if (!ret || ret?.length == 0) {
            throw new Error('BYPASS');
        }
        return ret
    }

    async getCallsByCustomerId(callBatchParams: CallBatchParamsDto): Promise<Call[]> {

        let ret = await this.dbService.calls
            .where("customerId")
            .equals(callBatchParams.customerId)
            .and((record) => record.stopTime !== undefined).toArray();
        if (!ret || ret?.length == 0) {
            throw new Error('BYPASS');
        }
        return ret
    }

    async getUnprocessedCallsByCustomerId(callBatchParams: CallBatchParamsDto): Promise<Call[]> {
        return await this.dbService.calls
            .where("customerId")
            .equals(callBatchParams.customerId)
            .and((record) => !record.hasServerProcessed && record.stopTime !== undefined)
            .toArray();
    }

    async getLocalCallsByCustomerId(id: string): Promise<Call[] | undefined> {
        return await this.dbService.calls
            .where("customerId")
            .equals(id)
            .and((call) => call.stopTime == null)
            .reverse()
            .sortBy("createdUtcDateTime");
    }

    async getOpenCallByEmployeeId(id: string): Promise<Call | undefined> {

        const calls = await this.dbService.calls
            .where("createdUserId")
            .equals(id)
            .and((call) => !call.stopTime)
            .toArray();

        if (calls && calls.length > 0) {
            calls.sort((a, b) =>
                a.startTime.getTime() > b.startTime.getTime() ? 1 : -1
            );
        }

        return calls?.length > 0 ? calls[0] : undefined;
    }

    async getCallHistory(callHistoryParams: CallHistoryParamsDto): Promise<CallHistoryEntry[]> {
        if (callHistoryParams.getPictures) {
            throw Error("Cannot get call pictures in offline mode.");
        }
        let calls = await OfflineServicesHelper.multiPartFilterWithSort(
            this.dbService.calls, "stopTime",
            async (table) => [
                [table.where("createdUserId").equals(callHistoryParams.employeeId), table.where("createdUserZrt").startsWith(callHistoryParams.employeeZrt)],
                ...(await this.applyCallHistoryFilterViaIndexes(this.dbService.calls, callHistoryParams.filterRequestDto.filters)),
                table.where("stopTime").above(Dexie.minKey)
            ]
        );
        if (callHistoryParams.lastCall) {
            calls = calls.filter((c, i, a) => a.map(v => v.customerId).indexOf(c.customerId) === i);
        }
        const customers = await this.dbService.customers
            .where("id")
            .anyOf(calls.map((call) => call.customerId))
            .toArray();
        const entries = calls.map((c) => new CallHistoryEntry(c, customers.find((cu) => cu.id === c.customerId)));
        if (!entries) {
            throw new Error('BYPASS')
        }
        const filteredEntries = this.applyCallHistoryFilter(entries, callHistoryParams.filterRequestDto.filters);
        return this.sortCallHistoryEntries(filteredEntries, callHistoryParams.filterRequestDto.filterSorts);
    }

    private sortCallHistoryEntries(calls: CallHistoryEntry[], sorts: FilterSortDto<CallHistoryColumns>[]): CallHistoryEntry[] {
        let sortedCalls = calls;
        if (sorts !== null) {
            for (const sort of sorts) {
                switch(sort.column){
                    case CallHistoryColumns.createdBy: {
                        sortedCalls = sortedCalls.sort((a, b) => a.createdByName.localeCompare(b.createdByName));
                    } break;
                    case CallHistoryColumns.start: {
                        sortedCalls = sortedCalls.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
                    } break;
                    case CallHistoryColumns.end: {
                        sortedCalls = sortedCalls.sort((a, b) => a.stopTime.getTime() - b.stopTime.getTime());
                    } break;
                    case CallHistoryColumns.duration: {
                        sortedCalls = sortedCalls.sort((a, b) => a.durationMins - b.durationMins);
                    } break;
                    case CallHistoryColumns.type: {
                        sortedCalls = sortedCalls.sort((a, b) => a.callType.localeCompare(b.callType));
                    } break;
                    case CallHistoryColumns.comments: {
                        sortedCalls = sortedCalls.sort((a, b) => a.closingNotes.localeCompare(b.closingNotes));
                    } break;
                    case CallHistoryColumns.customerId: {
                        sortedCalls = sortedCalls.sort((a, b) => a.customerId.localeCompare(b.customerId));
                    } break;
                    case CallHistoryColumns.name: {
                        sortedCalls = sortedCalls.sort((a, b) => a.customerName.localeCompare(b.customerName));
                    } break;
                    case CallHistoryColumns.address: {
                        sortedCalls = sortedCalls.sort((a, b) => a.address.localeCompare(b.address));
                    } break;
                    case CallHistoryColumns.city: {
                        sortedCalls = sortedCalls.sort((a, b) => a.city.localeCompare(b.city));
                    } break;
                    case CallHistoryColumns.state: {
                        sortedCalls = sortedCalls.sort((a, b) => a.state.localeCompare(b.state));
                    } break;
                    case CallHistoryColumns.zip: {
                        sortedCalls = sortedCalls.sort((a, b) => a.zip.localeCompare(b.zip));
                    } break;
                    default: break;
                }
                if (sort.direction === SortDirection.descending) {
                    sortedCalls = sortedCalls.reverse();
                }
            }
        }
        return sortedCalls;
    }

    async getUnprocessedCallHistory(callHistoryParams: CallHistoryParamsDto): Promise<CallHistoryEntry[]> {
        let calls = await this.dbService.calls
            .where("hasServerProcessed")
            .equals(0)
            .filter(
                (record) =>
                    (record.createdUserId === callHistoryParams.employeeId ||
                        record.createdUserZrt.startsWith(
                            callHistoryParams.employeeZrt
                        )) &&
                    record.stopTime !== undefined
            )
            .toArray();
        if (callHistoryParams.lastCall) {
            calls = calls.filter((c, i, a) => a.map(v => v.customerId).indexOf(c.customerId) === i);
        }
        const customers = await this.dbService.customers
            .where("id")
            .anyOf(calls.map((call) => call.customerId))
            .toArray();
        const entries = calls.map((c) => new CallHistoryEntry(c, customers.find((cu) => cu.id === c.customerId)));

        return this.applyCallHistoryFilter(entries, callHistoryParams.filterRequestDto.filters);
    }

    async save(call: Call): Promise<undefined> {
        call.hasServerProcessed = 0;
        this.dbService.calls.put(call);
        await this.dataSyncQueueService.enqueue(
            new CallSyncCommand(call.id)
        );

        return;
    }

    async getLastCallOfTypeByCustomerId(id: string, callType: CallTypes): Promise<Call | undefined> {
        
        let ret = await this.dbService.calls
            .where("[customerId+callType+stopTime]").between([id, callType, Dexie.minKey], [id, callType, Dexie.maxKey])
            .reverse()
            .first();
        
        if (!ret) {
            throw new Error('BYPASS');
        }
        return ret
    }

    async getLastCallWithOrdersByCustomerId(id: string, callType: CallTypes): Promise<Call | undefined> {

        let ret = await this.dbService.calls
            .where("[customerId+callType+stopTime]").between([id, callType, Dexie.minKey], [id, callType, Dexie.maxKey])
            .reverse()
            .filter((record: RetailCall) => record.orderProducts?.length > 0)
            .first();

        if (!ret) {
            throw new Error('BYPASS');
        }
        return ret
    }

    async getLastCompletedCallByEmployeeId(id: string): Promise<Call | undefined> {

        const result = await this.dbService.calls
            .where("[createdUserId+stopTime]").between([id, Dexie.minKey], [id, Dexie.maxKey])
            .reverse()
            .first();

        if (!result) {
            throw new Error('BYPASS');
        }
        return result;
    }
}
