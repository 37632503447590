import { Component, OnInit } from '@angular/core';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';

import { ViewProjectActivityDialogViewModel } from './view-project-activity-dialog.viewmodel';

@Component({
    selector: 'app-view-project-activity-dialog',
    templateUrl: './view-project-activity-dialog.component.html',
    styleUrls: ['./view-project-activity-dialog.component.scss']
})
export class ViewProjectActivityDialogComponent implements OnInit {

    viewmodel: ViewProjectActivityDialogViewModel;

    constructor(public injectedData: SwisherOverlayRef<
            ViewProjectActivityDialogViewModel,
            ViewProjectActivityDialogComponent
        >) { }

    ngOnInit(): void {
        this.viewmodel = this.injectedData.data;
    }
}
