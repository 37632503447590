import { Availability } from "./availability.entity";

export class Contact {
    id: string; //uuid
    customerId: string; // customer UUID
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    alternatePhone: string;
    note: string;
    availability: Availability;
    isDeleted: boolean;
    createdUtcDateTime: Date;
    createdUserId: string;
    createdUserZrt: string;
    createdUserFullName: string;
    modifiedUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
    clientLastTouchedUtcDateTime: Date;
    hasServerProcessed: number;
    rowversion: string;
}
