<form [formGroup]="viewmodel?.agreementFormGroup">
    <div class="row">
        <div class="col-12 w-100">
            <ng-container *ngIf="viewmodel.template?.contractTemplateType?.id === viewmodel.templateTypes.RetailIncentiveAgreement">
                <app-retail-incentive-agreement [contract]="viewmodel.template" [endDate]="viewmodel.contractDateFormatted"></app-retail-incentive-agreement>
            </ng-container>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-4 h-center">
            <mat-form-field appearance="outline" color="accent" class="w-100">
                <mat-label>Level</mat-label>
                <mat-select formControlName="levelControl" required>
                    <mat-option [value]="1">Level 1</mat-option>
                    <mat-option [value]="2">Level 2</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4 h-center">
            <mat-form-field appearance="outline" color="accent" class="w-100">
                <mat-label>End Date</mat-label>
                <input #dateInput matInput formControlName="dateControl" [matDatepicker]="datePicker" [min]="viewmodel.minDate" required/>
                <mat-datepicker touchUi #datePicker></mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            </mat-form-field>
        </div>
        <div class="col-4 h-center">
            <mat-form-field appearance="outline" color="accent" class="w-100">
                <mat-label>Contact</mat-label>
                <mat-select formControlName="contactControl" required>
                    <mat-option *ngFor="let contact of viewmodel.contacts" [value]="contact" >
                        {{ contact.firstName }} {{ contact.lastName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-12 text-left">
            <p>Customer Representative Signature</p>
        </div>
    </div>
    <div class="row">
        <div class="col-2 h-center">
            <button mat-raised-button
            class="actionBtn btn-white"
            (click)="viewmodel.onClearSignature()">Clear</button>
        </div>
        <div class="col-10 h-center">
            <canvas
                #canvas
                id="signature-canvas"
                class="border"
                [ngClass]="{
                    'signature-required' : viewmodel.isSignatureInvalid()
                }"
                [width]="viewmodel.canvasWidth"
                [height]="viewmodel.canvasHeight"
            ></canvas>
        </div>
    </div>
</form>
