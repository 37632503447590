import { InjectionToken } from "@angular/core";
import { SyncVersionKeyNames } from "../enums/sync-version-key-names";
import { SyncLevel } from "./sync-enums/sync-level.enum";


export const dataSyncHandlerInterfaceToken = new InjectionToken<DataSyncHandlerInterface>(
    "DataSyncHandlerInterface"
);

export interface DataSyncHandlerInterface {

    onlineRepSyncType: SyncLevel; //ZM, CorpAdmin and others
    offlineRepSyncType: SyncLevel; //TM and RM only
    syncVersionKey?: SyncVersionKeyNames;
    isRunSuccessfull: boolean;

    execute(): Promise<void>;
    pushData(): Promise<void>;
}

