import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Contact } from 'src/app/entity-models/contact.entity';
import { Customer } from 'src/app/entity-models/customer.entity';
import { Employee } from 'src/app/entity-models/employee.entity';
import { ReceiptAddressViewModel } from '../../receipt-address.viewmodel';

@Component({
  selector: 'app-wholesaler-format-draft',
  templateUrl: './wholesaler-format-draft.component.html',
  styleUrls: ['./wholesaler-format-draft.component.scss']
})
export class WholesalerFormatDraftComponent {

    @Input() customerReceiptAddress: ReceiptAddressViewModel;
    @Input() signature: string;
    @Input() signatureRetailWidth: number;
    @Input() signatureRetailHeight: number;
    @Input() selectedContact: Contact;
    @Input() isWholesaleDraft = false;
    @Input() isWholesaleFinal = false;
    @Input() selectedWholesaler: Customer;
    @Input() isEasEmployee = false;
    @Input() wholesalers: Customer[];
    @Input() customer: Customer;
    @Input() employee: Employee;

    @Output() captureReady = new EventEmitter();

    @ViewChild("wholesaleDraftReceipt") wholesaleDraftReceipt: ElementRef;

    isEasWholesalerFormatDraftBuilt = false;
    isSwisherWholesalerFormatDraftBuild = false;

    easWholesalerFormatDraftBuilt(): void {
        this.isEasWholesalerFormatDraftBuilt = true;
        this.isCaptureReady();
    }

    swisherWholesalerFormatDraftBuilt(): void {
        this.isSwisherWholesalerFormatDraftBuild = true;
        this.isCaptureReady();
    }

    isCaptureReady(): void {
        if (this.isEasWholesalerFormatDraftBuilt && this.isSwisherWholesalerFormatDraftBuild) {
            this.captureReady.emit();
            this.isEasWholesalerFormatDraftBuilt = false;
            this.isSwisherWholesalerFormatDraftBuild = false;
        }
    }
}
