import { Injectable } from "@angular/core";
import { CallTypes } from "shield.shared";
import { Call } from "src/app/accounts/call-master/call-services/call.service";
import { Receipt } from "src/app/entity-models/receipt";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";
import { DatabaseService } from "../database.service";

@Injectable()
export class ReceiptOfflineService {

    constructor(private dbService: DatabaseService){}

    async getLocalReceiptByCall(call: Call): Promise<Receipt[]> {

        let rtn = new Array<Receipt>();
        if (call.callType === CallTypes.retail || call.callType === CallTypes.rmWholesale) {

            const recieptIds = call.callReceipts?.map((cr) => cr.id);

            if (recieptIds) {
                rtn = await this.dbService.receipts.bulkGet(recieptIds);
            }
        }

        return rtn;
    }

    async getCallReceiptsByCall(call: Call): Promise<Receipt[]> {

        return await this.dbService.receipts
            .where("id")
            .anyOf((call as RetailCall | RmWholesaleCall).callReceipts.map((cr) => cr.id))
            .toArray()
    }
}
