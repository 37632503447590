<div #myFilter>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Store Types</mat-label>
            <mat-select #storeTypesSelect
                [(value)]="viewmodel.selectedCutomerTypes"
                (openedChange)="onOpenedCustomerTypesChange()"
                (selectionChange)="onSelectionCustomerTypesChange()"
                multiple>
                <mat-option *ngFor="let type of viewmodel.customerTypes" [value]="type">
                    {{ type.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Call Types</mat-label>
            <mat-select #callTypesSelect
                [(value)]="viewmodel.selectedCallTypes"
                (openedChange)="onOpenedCallTypesChange()"
                (selectionChange)="onSelectionCallTypesChange()"
                multiple>
                <mat-option *ngFor="let type of viewmodel.callTypes" [value]="type">
                    {{ type }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Pictures</mat-label>
            <mat-select #picturesSelect
                [(value)]="viewmodel.selectedPictures"
                (openedChange)="onOpenedSelectedPicturesChange()">
                <mat-option [value]="viewmodel.all"> All
                </mat-option>
                <mat-option [value]="viewmodel.yes"> Has Pictures </mat-option>
                <mat-option [value]="viewmodel.no"> No Pictures </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Px3 Ranks</mat-label>
            <mat-select #px3RankSelect
                [(value)]="viewmodel.selectedPx3Ranks"
                (openedChange)="onOpenedSelectedPx3Change()"
                (selectionChange)="onSelectionPx3RanksChange()"
                multiple>
                <mat-option *ngFor="let rank of viewmodel.px3Ranks" [value]="rank">
                    {{ rank.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
