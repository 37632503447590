import { Component, OnInit } from "@angular/core";
import { HeaderButtonComponent } from "../header-button/header-button.component";
import {
    faCloudDownloadAlt,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { ButtonActions } from "../../../enums/button-actions.enum";

@Component({
    selector: "app-excel-export-button",
    templateUrl: "./excel-export-button.component.html",
    styleUrls: ["./excel-export-button.component.css"]
})
export class ExcelExportButtonComponent
    implements HeaderButtonComponent {
    icon: IconDefinition = faCloudDownloadAlt;
    title = "Export to Excel";
    buttonAction = ButtonActions.exportToExcel;
    isDisabled: boolean;
}
