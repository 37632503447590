import { ElementRef } from "@angular/core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faPrint } from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import * as moment from "moment";
import { RetailIncentiveRetailerViewModel } from "src/app/contracts/retail-incentive-agreement/retail-incentive-retailer.viewmodel";
import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";
import { ShareOptionsDialogComponent } from "src/app/dialogs/share-options-dialog/share-options-dialog.component";
import { ShareOptionsDialogViewmodel } from "src/app/dialogs/share-options-dialog/share-options-dialog.viewmodel";
import { CustomerContract } from "src/app/entity-models/customer-contract.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ContactDelineationService } from "src/app/services/delineation-services/contact-delineation.service";
import { CustomerContractDelineationService } from "src/app/services/delineation-services/customer-contract-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { OverlayService } from "src/app/services/overlay.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";

export class ViewContractViewModel extends BasicDialogViewModel {
    private customerDelineationService: CustomerDelineationService;
    private customerContractDelineationService: CustomerContractDelineationService;
    private overlayService: OverlayService;
    private contactDelineationService: ContactDelineationService;

    customerContract: CustomerContract;
    retailer: RetailIncentiveRetailerViewModel;
    endDate = "";
    agreement: ElementRef;

    faEnvelope: IconDefinition = faEnvelope;
    faPrint: IconDefinition = faPrint;

    shareEmployees: Employee[];
    shareComments: string;
    private shareOverlayRef: SwisherOverlayRef<
        ShareOptionsDialogViewmodel,
        ShareOptionsDialogComponent
    >;

    constructor(
        customerContract: CustomerContract,
        customerApiService: CustomerDelineationService,
        customerContractDelineationService: CustomerContractDelineationService,
        overlayService: OverlayService,
        contactDelineationService: ContactDelineationService
    ) {
        super();
        this.customerDelineationService = customerApiService;
        this.customerContractDelineationService = customerContractDelineationService;
        this.overlayService = overlayService;
        this.contactDelineationService = contactDelineationService;

        this.customerContract = customerContract;
        this.endDate = moment(customerContract.endDate).format(MY_DATE_FORMATS.display.dateInput);

        void this.buildRetailerViewModel();

        this.headerLeftText = `${customerContract.contractTemplate.contractTemplateType.description} - ${customerContract.customerName} - ${customerContract.signatureDate}`;
        this.buttonLeftDisabledFunction = () => false;
        this.buttonLeftText = "Close";
        this.width = "50vw";
    }

    async buildRetailerViewModel(): Promise<void> {

        const signature = await this.customerContractDelineationService.getContractSignature(this.customerContract.id);
        const response  = await this.contactDelineationService.getByCustomerId(this.customerContract.customerId);
        if (!response) { return; }

        const contacts = response.values;

        const retailer = contacts?.find((c) => c.id === this.customerContract.customerContactId);
        this.retailer = {...this.customerContract,
            signatureDate: moment(this.customerContract.createdUtcDateTime).format(MY_DATE_FORMATS.display.monthDayYearInput),
            swisherName: Helper.formatDisplayName(this.customerContract),
            customerContactName: retailer ? `${retailer.firstName} ${retailer.lastName}` : "",
            customerContactSignature: signature ? signature.image : null
        }
    }

    async generateContractPdfDataUrl(htmlElement: HTMLElement): Promise<string> {
        let rtn = "";
        const pdf = new jsPDF('p', 'mm', 'a4', true);

        await pdf.html(htmlElement, {
            margin: [5, 5, 12, 12],
            html2canvas: { logging: false, scale: 0.2 }
        }).then(() => {
            rtn = pdf.output("dataurlstring");
        });

        return rtn;
    }

    async email(): Promise<void> {
        const data: ShareOptionsDialogViewmodel = new ShareOptionsDialogViewmodel();
        data.shareEmployees = this.shareEmployees;
        data.confirmButtonText = "Share Contract";
        data.comments = this.shareComments;
        data.onClose = (closeData: ShareOptionsDialogViewmodel) => {
            this.shareComments = closeData.comments;
            this.shareEmployees = closeData.shareEmployees;
        }
        data.onSaveShare = async (
            saveData: ShareOptionsDialogViewmodel
        ): Promise<void> => {
            const ids = saveData.shareEmployees.map((e) => e.id);
            let image = await this.generateContractPdfDataUrl(
                this.agreement?.nativeElement
            );
            if (image) {
                image = image.replace(/^[^,]+,/, '');
                image = image.replace(/\s/g, '')
                for (const employeeId of ids) {
                    await this.customerContractDelineationService.emailContract(employeeId, image, this.shareComments);
                }
            }
        }
        this.shareOverlayRef = this.overlayService.open(
            ShareOptionsDialogComponent,
            data
        );
    }

    async print(): Promise<void> {
        const image = await this.generateContractPdfDataUrl(
            this.agreement?.nativeElement
        );
        if (image) {
            Helper.addIFrameImage(document, [image]);
        }
    }
}
