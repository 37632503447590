import { Injectable } from "@angular/core";
import { GenericResponseDto, VolumeParamsDto } from "shield.shared";
import { MsaDistributorVolumeResponse } from "src/app/entity-models/msa-distributor-volume-response.entity";
import { MsaDistributorVolume } from "src/app/entity-models/msa-distributor-volume.entity";
import { DatabaseService } from "../database.service";
import { VolumeOfflineService } from "../offline-services/volume-offline.service";
import { VolumeOnlineService } from "../online-services/volume-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class VolumeDelineationService extends DelineationContext<MsaDistributorVolume, string>{

    constructor(private volumeOfflineService: VolumeOfflineService,
        private volumeOnlineService: VolumeOnlineService,
        snackbarService: SnackbarService,
        protected datasourceDelineationService: DatasourceDelineationService,
        protected dbService: DatabaseService) {
            super(dbService, datasourceDelineationService, snackbarService);
        }

    async loadForCustomer(id: string): Promise<GenericResponseDto<MsaDistributorVolumeResponse>> {
        const offline = (key: string) => {
            return this.volumeOfflineService.getByCustomerId(key);
        }
        const online = (key: string) => {
            return this.volumeOnlineService.getByCustomerId(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, MsaDistributorVolumeResponse>(id, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async loadForCustomerByAvgArea(id: string, distance: number): Promise<GenericResponseDto<MsaDistributorVolumeResponse>> {
        const key = new VolumeParamsDto();
        key.customerId = id;
        key.distance = distance;
        const offline = (key: VolumeParamsDto) => {
            return this.volumeOfflineService.getAvgByCustomerAndDistance(key);
        }
        const online = (key: VolumeParamsDto) => {
            return this.volumeOnlineService.getAvgByCustomerAndDistance(key);
        }
        const response = await this.datasourceDelineationService.makeCall<VolumeParamsDto, MsaDistributorVolumeResponse>(key, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }
}
