import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EmployeeDto, GenericRequestDto, GenericResponseDto, newSequentialId, RegisteredUserDto, ResponseCountKey, SearchZrtDropDownDto } from "shield.shared";
import { RegisteredUser } from "src/app/entity-models/registered-user-entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { RegisteredUserConverterService } from "../converter-services/registered-user-converter.service";

@Injectable()
export class RegisteredUserOnlineService {
    constructor(private http: HttpClient) { }

    async getByEmployeeId(id: string): Promise<GenericResponseDto<RegisteredUser>> {
        if (!id) {
            return;
        }

        const result = await this.http
            .get<GenericResponseDto<RegisteredUserDto>>(`/api/registered-users/employee-id/${id}`)
            .toPromise();

        return ConverterHelper.dtoToEntity<RegisteredUserDto, RegisteredUser>(result, (r) => RegisteredUserConverterService.registeredUserDtoToRegisteredUser(r));
    }

    async autosaveRegisteredUser(registeredUser: RegisteredUser): Promise<GenericResponseDto<RegisteredUser>> {
        if (!registeredUser) {
            return;
        }

        const dto = RegisteredUserConverterService.registeredUserToRegisteredUserDto(registeredUser);
        const request = new GenericRequestDto<RegisteredUserDto>();
        request.id = registeredUser.id;
        request.values = dto;

        const result = await this.http
            .post<GenericResponseDto<RegisteredUserDto>>("/api/registered-users/save", request)
            .toPromise();

        return ConverterHelper.dtoToEntity<RegisteredUserDto, RegisteredUser>(result, (r) => RegisteredUserConverterService.registeredUserDtoToRegisteredUser(r));
    }
}
