import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { BehaviorSubject, Subscription } from "rxjs";
import { newSequentialId, SurveyQuestionTypes } from "shield.shared";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { ProjectActivitySurvey } from "src/app/entity-models/project-activity-survey.entity";
import { SurveyQuestion } from "src/app/entity-models/survey-question.entity";
import { Survey } from "src/app/entity-models/survey.entity";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { ProjectStateService } from "src/app/services/project-state-service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { ProjectApplicationService } from "../../project-services/project-application.service";
import { AddProjectActivityDialogComponent } from "./add-project-activity-dialog/add-project-activity-dialog.component";
import { AddProjectActivityDialogViewModel } from "./add-project-activity-dialog/add-project-activity-dialog.viewmodel";
import { ViewProjectActivityDialogComponent } from "./view-project-activity-dialog/view-project-activity-dialog.component";
import { ViewProjectActivityDialogViewModel } from "./view-project-activity-dialog/view-project-activity-dialog.viewmodel";
import { SurveyDelineationService } from "src/app/services/delineation-services/survey-delineation.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";

export class ProjectActivitiesAndSurveysViewmodel {
    activityOverlayRef: SwisherOverlayRef<AddProjectActivityDialogViewModel,AddProjectActivityDialogComponent>;
    confirmationOverlayRef: SwisherOverlayRef<ConfirmationDialogViewmodel, ConfirmationDialogComponent>;
    dropdownOverlayRef: SwisherOverlayRef<ViewProjectActivityDialogViewModel, ViewProjectActivityDialogComponent>;
    dateFormat: string = MY_DATE_FORMATS.display.customDateInput;
    faEye: IconDefinition = faEye;
    initialized = false;
    overlayService: OverlayService;
    pleaseWaitService: PleaseWaitService;
    projectDelineationService: ProjectDelineationService;
    projectApplicationService: ProjectApplicationService;
    projectStateService: ProjectStateService;
    projectSubscription: Subscription;
    questionTypes = SurveyQuestionTypes;
    screenHeight: number = 0;
    selectedActivities: Survey[];
    shouldExpand = true;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    snackbarService: SnackbarService;
    surveyDelineationService: SurveyDelineationService;

    constructor(
        pleaseWaitService: PleaseWaitService,
        projectDelineationService: ProjectDelineationService,
        projectApplicationService: ProjectApplicationService,
        projectStateService: ProjectStateService,
        snackbarService: SnackbarService,
        overlayService: OverlayService,
        surveyDelineationService: SurveyDelineationService
    ) {

        this.pleaseWaitService = pleaseWaitService;
        this.projectDelineationService = projectDelineationService;
        this.projectApplicationService = projectApplicationService;
        this.projectStateService = projectStateService;
        this.snackbarService = snackbarService;
        this.overlayService = overlayService;
        this.surveyDelineationService = surveyDelineationService;
    }

    //public methods
    unsubscribe(): void {
        if (this.projectSubscription && !this.projectSubscription.closed) {
            this.projectSubscription.unsubscribe();
        }
    }

    initialize(): void {
        if (
            !this.projectSubscription ||
            this.projectSubscription.closed
        ) {
            this.projectSubscription = this.projectStateService.observableProject.subscribe(
                async (project) => {
                    if (project && !this.initialized) {
                        this.initialized = true;
                        await this.getProjectSurveys();
                    }
                }
            );
        }
    }

    async getAvailableSurveys(): Promise<Survey[]> {
        let rtn = new Array<Survey>();
        const id = this.projectStateService.project.id;
        const response = await this.surveyDelineationService.getAvailibleBatch(id);
        if (!response) {
            this.shouldWait$.next(false);
            return;
        }

        const results = response.values;
        if (results && results[0]) {
            const active = this.projectStateService.project.projectActivitySurveys.map((as) => as.activitySurveyId);
            rtn = results.filter((as) => !active.includes(as.id));
        }
        return rtn;
    }

    async getProjectSurveys(): Promise<void> {
        this.shouldWait$.next(true);
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        const ids = this.projectStateService.project.projectActivitySurveys.map((as) => as.activitySurveyId);
        if (ids.length) {
            const results = await this.surveyDelineationService.getByIds(ids);
            if (results) {

                this.selectedActivities = results.values;;
            }
        } else {
            this.selectedActivities = new Array<Survey>();
        }
        this.projectStateService.notify();
        this.shouldWait$.next(false);
    }

    async onOpenAddActivityDialog(): Promise<void> {
        const activities = await this.getAvailableSurveys();

        const data = new AddProjectActivityDialogViewModel(activities);
        data.buttonLeftFunction = () => this.activityOverlayRef.close(data);
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.activityOverlayRef.close(data);
        }
        data.width = "50vw";
        this.activityOverlayRef = this.overlayService.open(
            AddProjectActivityDialogComponent,
            data,
            true
        );
        this.activityOverlayRef.afterClosed$.subscribe(async (ref) => {
            if (ref && ref.data && ref.data.isConfirmed) {
                for (const vm of ref.data.activityVms) {
                    if (vm.isSelected && !this.projectStateService.project.projectActivitySurveys.find((as) => as.id === vm.id)) {
                        const activitySurvey = new ProjectActivitySurvey();
                        activitySurvey.id = newSequentialId();
                        activitySurvey.projectId = this.projectStateService.project.id;
                        activitySurvey.activitySurveyId = vm.id;
                        this.projectStateService.project.projectActivitySurveys.push(activitySurvey);
                    }
                }
                void this.getProjectSurveys();
            }
        });
    }

    async onRemoveActivity(activity: Survey): Promise<void> {
        this.shouldExpand = false;

        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation - Remove Activity Group";
        data.message =
            `Would you like to remove ${activity.title} from the project?`;
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            this.confirmationOverlayRef.close();
        };

        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.confirmationOverlayRef.close(data);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe((event) => {
            if (event && event.data && event.data.isConfirmed) {
                this.projectStateService.project.projectActivitySurveys
                    = this.projectStateService.project.projectActivitySurveys
                        .filter((as) => as.activitySurveyId !== activity.id);
                void this.getProjectSurveys();
            }
        });
    }

    async onViewDropDownOptions(question: SurveyQuestion): Promise<void> {
        const data = new ViewProjectActivityDialogViewModel();
        data.responses = question.surveyQuestionOptions;
        data.text = question.text;
        data.width = "30vw";
        data.buttonLeftFunction = () => this.dropdownOverlayRef.close();

        this.dropdownOverlayRef = this.overlayService.open(
            ViewProjectActivityDialogComponent,
            data,
            true
        );
    }
}
