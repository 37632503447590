import { NotificationRequestDto } from "../dto";
import { FilterRequestV2Dto, OrderListColumns, OrderListFilterDto, RefinerGroupDto } from "../filter";
import * as moment from "moment";

export class OrderBatchParamsDto {

    filterRequestDto: FilterRequestV2Dto<OrderListFilterDto, OrderListColumns>;
    refinerGroups: RefinerGroupDto[];
    zrt: string;
}

export class EmailOrderParamsDto {

    request: NotificationRequestDto;
    callId: string;
    receiptId: string;
}

export class OrdersByWholesalersParamsDto {

    wholesalerId: string;
    filterRequestDto: FilterRequestV2Dto<OrderListFilterDto, OrderListColumns>;
}

export class ChangeOrderDateParamsDto {

    id: string;
    orderDate: moment.Moment
}

export class ChangeUinParamsDto {

    wholesalerId: string;
    oldUin: string;
    newUin: string;
}

export class MoveOrderProductsParamsDto {

    lineItemIds: string[];
    orderDate: moment.Moment;
}
