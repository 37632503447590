import { Injectable } from "@angular/core";
import { Product } from "src/app/entity-models/product.entity";
import { CallTypes } from "shield.shared";
import { CallService } from "./call.service";
import { DistributionActionItems } from "../stepper-call/stepper-call-enums/distribution-action-items";
import { DistributionProperties } from "../stepper-call/stepper-call-enums/distribution-properties";
import { DistributionAtttributes } from "../stepper-call/stepper-call-models/distribution-attributes.model";
import { CallDistributionProductViewModel } from "../call-viewmodels/call-distribution-product.viewmodel";
import { ProductViewmodel } from "../stepper-call/distribution-grid/product.viewmodel";

@Injectable()
export class CallDistributionService {

    callProducts: CallDistributionProductViewModel[] = [];

    constructor(private callService: CallService) {}
    //public methods
    buildDistributionAttributeSet(
        isInDist?: boolean,
        isIntro?: boolean,
        isOos?: boolean,
        isCos?: boolean
    ): DistributionAtttributes {
        const rtn = new DistributionAtttributes();

        rtn.isIndist = !!isInDist;
        rtn.isIntro = !!isIntro;
        rtn.isOos = !!isOos;
        rtn.isCos = !!isCos;

        return rtn;
    }

    async updateDistributionViewModel(
        viewmodel: CallDistributionProductViewModel,
        updatedProperty?: DistributionProperties,
        actionItem?: DistributionActionItems
    ): Promise<CallDistributionProductViewModel> {
        if (viewmodel) {
            const oldSet: DistributionAtttributes = this.buildDistributionAttributeSetFromViewModel(
                viewmodel
            );

            if (actionItem && actionItem !== DistributionActionItems.exchangeOut) {
                oldSet.isIndist = true;
            }

            const newSet = this.updateDistributionSet(oldSet, updatedProperty);

            let updatedvm: CallDistributionProductViewModel = new CallDistributionProductViewModel();

            if (updatedProperty) {
                updatedvm = this.buildViewModelFromSet(newSet);
                updatedvm.excludeUpdating = viewmodel.excludeUpdating;
            } else {
                updatedvm = viewmodel;
            }

            updatedvm.isSelect = viewmodel.isSelect;
            updatedvm.wholesaler = viewmodel.wholesaler;
            updatedvm.selectedProject = viewmodel.selectedProject;

            updatedvm.dateAvailable = viewmodel.dateAvailable;
            updatedvm.isCash =
                actionItem === DistributionActionItems.cash &&
                updatedvm.excludeUpdating !== "isCash"
                    ? !viewmodel.isCash
                    : viewmodel.isCash;
            updatedvm.isOrder =
                actionItem === DistributionActionItems.order &&
                updatedvm.excludeUpdating !== "isOrder"
                    ? !viewmodel.isOrder
                    : viewmodel.isOrder;
            updatedvm.product = viewmodel.product;

            await this.updateCall(
                newSet,
                updatedProperty,
                actionItem,
                updatedvm
            );
            viewmodel = updatedvm;
        }

        return viewmodel;
    }

    updateDistributionSet(
        set: DistributionAtttributes,
        updatedProperty: DistributionProperties
    ): DistributionAtttributes {
        const rtn = new DistributionAtttributes();

        switch (updatedProperty) {
            case DistributionProperties.inDist:
                switch (set.isIndist) {
                    case true:
                        rtn.isIndist = true;
                        rtn.isIntro = set.isIntro;
                        rtn.isOos = false;
                        rtn.isCos = set.isCos;
                        break;
                    case false:
                        rtn.isIndist = false;
                        rtn.isIntro = false;
                        rtn.isOos = set.isOos;
                        rtn.isCos = false;
                        break;
                }
                break;
            case DistributionProperties.intro:
                switch (set.isIntro) {
                    case true:
                        rtn.isIndist = true;
                        rtn.isIntro = true;
                        rtn.isOos = false;
                        rtn.isCos = false;
                        break;
                    case false:
                        rtn.isIndist = set.isIndist;
                        rtn.isIntro = set.isIntro;
                        rtn.isOos = set.isOos;
                        rtn.isCos = set.isCos;
                        break;
                }
                break;
            case DistributionProperties.oos:
                switch (set.isOos) {
                    case true:
                        rtn.isIndist = false;
                        rtn.isIntro = false;
                        rtn.isOos = true;
                        rtn.isCos = false;
                        break;
                    case false:
                        rtn.isIndist = set.isIndist;
                        rtn.isIntro = set.isIntro;
                        rtn.isOos = false;
                        rtn.isCos = set.isCos;
                        break;
                }
                break;
            case DistributionProperties.cos:
                switch (set.isCos) {
                    case true:
                        rtn.isIndist = true;
                        rtn.isIntro = false;
                        rtn.isOos = false;
                        rtn.isCos = true;
                        break;
                    case false:
                        rtn.isIndist = set.isIndist;
                        rtn.isIntro = set.isIntro;
                        rtn.isOos = set.isOos;
                        rtn.isCos = false;
                        break;
                }
                break;
            default:
                rtn.isIndist = set.isIndist;
                rtn.isIntro = set.isIntro;
                rtn.isCos = set.isCos;
                rtn.isOos = set.isOos;
        }

        return rtn;
    }

    async updateCall(
        newSet: DistributionAtttributes,
        updatedProperty?: DistributionProperties,
        actionItem?: DistributionActionItems,
        viewmodel?: CallDistributionProductViewModel
    ): Promise<void> {
        if (newSet.isIndist) {
            await this.callService.addProductInDist(viewmodel.product.id);
        } else {
            await this.callService.removeProductFromDist(viewmodel.product.id);
            if (actionItem !== DistributionActionItems.exchangeOut) {
                this.removeProductFromViewModel(viewmodel);
            }
        }

        if (newSet.isIntro) {
            await this.callService.addProductIntro(viewmodel.product.id);
        } else {
            await this.callService.removeProductFromIntro(viewmodel.product.id);
        }

        if (updatedProperty === DistributionProperties.oos && newSet.isOos) {
            await this.callService.addProductOos(viewmodel.product.id);
            this.removeProductFromViewModel(viewmodel);
        } else {
            await this.callService.removeProductFromOos(viewmodel.product.id);
        }

        if (updatedProperty === DistributionProperties.cos && newSet.isCos) {
            await this.callService.addProductCos(viewmodel.product.id);
        } else {
            await this.callService.removeProductFromCos(viewmodel.product.id);
        }

        switch (actionItem) {
            case DistributionActionItems.cash:
                if (viewmodel.isCash) {
                    await this.callService.addProductCash(viewmodel.product);
                } else {
                    await this.callService.removeProductFromCash(
                        viewmodel.product.id
                    );
                }
                break;

            case DistributionActionItems.order:
                if (viewmodel.isOrder) {
                    await this.callService.addProductOrder(
                        viewmodel.product,
                        viewmodel.wholesaler,
                        viewmodel.selectedProject,
                        viewmodel.dateAvailable,
                    );
                } else {
                    await this.callService.removeProductFromOrder(
                        viewmodel.product.id, viewmodel.wholesaler?.id
                    );
                }
                break;
            case DistributionActionItems.gratis:
                if (viewmodel.isSelect) {
                    await this.callService.addProductGratis(viewmodel.product);
                } else {
                    await this.callService.removeAProductFromGratisById(
                        viewmodel.product.id
                    );
                }
                break;
            case DistributionActionItems.exchangeOut:
                if (viewmodel.isSelect) {
                    await this.callService.addProductOut(viewmodel.product);
                } else {
                    await this.callService.removeAProductFromProductOutById(
                        viewmodel.product.id
                    );
                }
                break;
            case DistributionActionItems.exchangeIn:
                if (viewmodel.isSelect) {
                    await this.callService.addProductIn(viewmodel.product);
                } else {
                    await this.callService.removeAProductFromProductInById(
                        viewmodel.product.id
                    );
                }
                break;
        }
    }

    buildDistributionAttributeSetFromViewModel(
        viewmodel: CallDistributionProductViewModel
    ): DistributionAtttributes {
        const rtn = new DistributionAtttributes();

        rtn.isIndist = viewmodel.isInDist;
        rtn.isIntro = viewmodel.isIntro;
        rtn.isOos = viewmodel.isOos;
        rtn.isCos = viewmodel.isCos;

        return rtn;
    }

    buildViewModelFromSet(
        set: DistributionAtttributes
    ): CallDistributionProductViewModel {
        const rtn = new CallDistributionProductViewModel();

        rtn.isInDist = set.isIndist;
        rtn.isIntro = set.isIntro;
        rtn.isOos = set.isOos;
        rtn.isCos = set.isCos;

        return rtn;
    }

    async addOrRemoveFromDist(
        product: Product
    ): Promise<CallDistributionProductViewModel> {
        if (product && (this.callService.call?.callType === CallTypes.retail
            || this.callService.call?.callType === CallTypes.rmWholesale)) {

            this.callService.call.inDistProductIds ??= [];
            this.callService.call.productsIntro ??= [];
            this.callService.call.productsOos ??= [];
            this.callService.call.productsCos ??= [];

            const set = new DistributionAtttributes();
            set.isIndist =
                this.callService.call.inDistProductIds.findIndex(
                    (id) => id === product.id
                ) !== -1
                    ? true
                    : false;
            set.isIntro =
                this.callService.call.productsIntro.findIndex(
                    (id) => id === product.id
                ) !== -1
                    ? true
                    : false;
            set.isOos =
                this.callService.call.productsOos.findIndex(
                    (id) => id === product.id
                ) !== -1
                    ? true
                    : false;
            set.isCos =
                this.callService.call.productsCos.findIndex(
                    (id) => id === product.id
                ) !== -1
                    ? true
                    : false;

            const viewmodel = this.buildViewModelFromSet(set);
            viewmodel.product = product as ProductViewmodel;

            // Toggles between adding to / removing from in dist.
            viewmodel.isInDist = !viewmodel.isInDist;

            return await this.updateDistributionViewModel(
                viewmodel,
                DistributionProperties.inDist,
                null
            );
        }
    }

    removeProductFromViewModel(viewmodel: CallDistributionProductViewModel): void {
        if (viewmodel.isCash === true) {
            viewmodel.isCash = false;
            viewmodel.isSelect = false;
            this.callService.removeProductFromCash(viewmodel.product.id);
        }

        if (viewmodel.isOrder === true) {
            viewmodel.isOrder = false;
            viewmodel.isSelect = false;
            this.callService.removeProductFromOrder(viewmodel.product.id, viewmodel.wholesaler?.id);
        }

        if (viewmodel.isSelect === true) {
            viewmodel.isSelect = false;
            viewmodel.isGratis = false;
            this.callService.removeAProductFromGratisById(
                viewmodel.product.id
            );
            viewmodel.isExchangeIn = false;
            this.callService.removeAProductFromProductInById(
                viewmodel.product.id
            );
        }
    }
}
