import { GenericLookup } from "../generic";
import { AccountOwnershipHierarchyLevel } from "../lookup";

export class AccountOwnershipDto {
    id: string;
    businessOwnerCode: string;
	ownerCode: string;
    customerId: string | null;
	parentAccountOwnershipId: string | null;
	parentOwnerCode: string | null;
	hierarchyLevel: GenericLookup<AccountOwnershipHierarchyLevel>;
	name: string;
    assignedEmployeeId: string | null;
	commonOperatingName: string | null;
	familyCode: string | null;
    storeCount: number | null;
	isDeactivated: boolean | null;
    rowversion: string;
    createdUtcDateTime: string | null;
    createdUserFullName: string | null;
    modifiedUtcDateTime: string | null;
    modifiedUserFullName: string | null;
}
