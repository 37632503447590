export class OrderLineItemDto {
    id: string;
    orderId: string;
    productId: string;
    productDescription: string;
    quantity: number;
    orderLineItemStatusId: number;
    uin: string;
    upc: string;
    movedToNewOrderId: string | null;
    createdUserId: string;
    createdUtcDateTime: string;
    createdUserZrt: string;
    createdUserFullName: string
    modifiedUtcDateTime: string;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;
}
