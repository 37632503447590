import { Injectable } from "@angular/core";
import { ContractTemplate } from "../../entity-models/contract-template.entity";
import { ContractTemplateOnlineService } from "../online-services/contract-template-online.service";
import { ContractTemplateOfflineService } from "../offline-services/contract-template-offline.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { SnackbarService } from "../snackbar.service";
import { ContractTemplateTypeIdAndZoneParamDto, GenericResponseDto } from "shield.shared";
import { DelineationContext } from "./delineation-context.service";
import { DatabaseService } from "../database.service";


@Injectable()
export class ContractTemplateDelineationService extends DelineationContext<ContractTemplate, string> {

    constructor(private contractTemplateService: ContractTemplateOfflineService,
        private contractTemplateApiService: ContractTemplateOnlineService,
        snackbarService: SnackbarService,
        protected datasourceDelineationService: DatasourceDelineationService,
        protected dbService: DatabaseService
        ){
            super(dbService, datasourceDelineationService, snackbarService);
        }

    async getByTypeIdAndZone(params: ContractTemplateTypeIdAndZoneParamDto): Promise<GenericResponseDto<ContractTemplate[] | undefined>> {
        const offline = (key: ContractTemplateTypeIdAndZoneParamDto) => {
            return this.contractTemplateService.getByTypeIdAndZone(key.typeId, key.zone);
        }
        const online = (key: ContractTemplateTypeIdAndZoneParamDto) => {
            return this.contractTemplateApiService.getByTypeIdAndZone(key);
        }
        const response = await this.datasourceDelineationService.makeCall<ContractTemplateTypeIdAndZoneParamDto, ContractTemplate[]>(params, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }
        return response;
    }

    async save(contractTemplate: ContractTemplate): Promise<ContractTemplate | void> {
        throw new Error ("Not implemented");
    }
}
