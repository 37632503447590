import { DailySummaryEntryDto, TimeEntryDto } from "shield.shared";
import { DailySummaryEntry } from "src/app/entity-models/daily-summary-entry.entity";
import { TimeEntry } from "src/app/entity-models/time-entry.entity";
import { Helper } from "src/app/helpers/helper";

export class TimeEntryConverterService {

    static timeEntryToTimeEntryDto(entity: TimeEntry): TimeEntryDto {
        const rtn = new TimeEntryDto();

        rtn.id = entity.id;
        rtn.sourceId = entity.sourceId;
        rtn.start = entity.start.toISOString();
        rtn.end = entity.end.toISOString();
        rtn.type = entity.type;
        rtn.name = entity.name;
        rtn.comments = entity.comments;
        rtn.employeeId = entity.employeeId;
        rtn.createdDate = entity.createdDate.toISOString();
        rtn.createdBy = entity.createdBy;
        rtn.updatedDate = entity.updatedDate.toISOString();
        rtn.updatedBy = entity.updatedBy;
        rtn.isDeleted = entity.isDeleted;
        rtn.rowversion = entity.rowversion ?? null;

        return rtn;
    }

    static timeEntryDtoToTimeEntry(dto: TimeEntryDto): TimeEntry {
        const rtn = new TimeEntry();

        rtn.id = dto.id;
        rtn.sourceId = dto.sourceId;
        rtn.start = dto.start ? new Date(dto.start) : null;
        rtn.end = dto.end ? new Date(dto.end) : null;
        rtn.type = dto.type;
        rtn.name = dto.name;
        rtn.comments = dto.comments;
        rtn.employeeId = dto.employeeId;
        rtn.createdDate = dto.createdDate ? new Date(dto.createdDate) : null;
        rtn.createdBy = dto.createdBy;
        rtn.updatedDate = dto.updatedDate ? new Date(dto.updatedDate) : null;
        rtn.updatedBy = dto.updatedBy;
        rtn.isDeleted = dto.isDeleted;
        rtn.rowversion = dto.rowversion;

        return rtn;
    }
}
