import {
    GratisApprovalDto,
    GratisAssignedTransactionDto,
    GratisDto,
    GratisProductRequestedDetailDto,
    GratisProductShippedDetailDto
} from "shield.shared";

import { GratisApproval } from "src/app/entity-models/gratis-approval.entity";
import { GratisAssignedTransaction } from "src/app/entity-models/gratis-assigned-transactions.entity";
import { GratisProductRequestedDetail } from "src/app/entity-models/gratis-product-requested-detail.entity";
import { Gratis } from "src/app/entity-models/gratis.entity";
import { State } from "src/app/entity-models/state.entity";
import { GratisApprovalConverterService } from "./gratis-approval-converter.service";
import { GratisAssignedTransactionConverterService } from "./gratis-assigned-transaction-converter.service";
import { GratisProductRequestedDetailConverterService } from "./gratis-product-requested-detail-converter.service";
import { GratisProductShippedDetailConverterService } from "./gratis-product-shipped-detail-converter.service";

export class GratisConverterService {
    static defaultNeededByDate: Date;

    constructor() {
        const date = new Date();
        date.setDate(date.getDate() + 7);
        date.setHours(0, 0, 0, 0);
        GratisConverterService.defaultNeededByDate = new Date(date);
    }
    static gratisDtoToGratis(dto: GratisDto, shipToState: State): Gratis {
        const entity = new Gratis();

        entity.id = dto.id;
        entity.employeeId = dto.employeeId;
        entity.gratisNumber = dto.gratisNumber;
        entity.gratisStatusId = dto.gratisStatusId;
        entity.purpose = dto.purpose;
        entity.daysWaiting = dto.daysWaiting;
        entity.neededByDate = dto.neededByDate
            ? new Date(dto.neededByDate)
            : GratisConverterService.defaultNeededByDate;
        entity.startDate = dto.startDate ? new Date(dto.startDate) : null;
        entity.endDate = dto.endDate ? new Date(dto.endDate) : null;
        entity.availableAmount = dto.availableAmount;
        entity.requestedAmount = dto.requestedAmount;
        entity.reimbursedAmount = dto.reimbursedAmount;
        entity.manualGratisAmount = dto.manualGratisAmount;
        entity.carryForwardAmount = dto.carryForwardAmount;
        entity.employeeApprovalStatusId = dto.employeeApprovalStatusId;
        entity.employeeApprovalStatusDate = dto.employeeApprovalStatusDate
            ? new Date(dto.employeeApprovalStatusDate)
            : null;
        entity.regionalEmployeeApprovalEmployeeId =
            dto.regionalEmployeeApprovalEmployeeId;
        entity.regionalEmployeeApprovalStatusId =
            dto.regionalEmployeeApprovalStatusId;
        entity.regionalEmployeeApprovalStatusDate = dto.regionalEmployeeApprovalStatusDate
            ? new Date(dto.regionalEmployeeApprovalStatusDate)
            : null;
        entity.regionalEmployeeApprovalComment =
            dto.regionalEmployeeApprovalComment;
        entity.zoneEmployeeApprovalEmployeeId =
            dto.zoneEmployeeApprovalEmployeeId;
        entity.zoneEmployeeApprovalStatusId = dto.zoneEmployeeApprovalStatusId;
        entity.zoneEmployeeApprovalStatusDate = dto.zoneEmployeeApprovalStatusDate
            ? new Date(dto.zoneEmployeeApprovalStatusDate)
            : null;
        entity.zoneEmployeeApprovalComment = dto.zoneEmployeeApprovalComment;
        entity.customerServiceEmployeeApprovalEmployeeId =
            dto.customerServiceEmployeeApprovalEmployeeId;
        entity.customerServiceEmployeeApprovalStatusId =
            dto.customerServiceEmployeeApprovalStatusId;
        entity.customerServiceEmployeeApprovalStatusDate = dto.customerServiceEmployeeApprovalStatusDate
            ? new Date(dto.customerServiceEmployeeApprovalStatusDate)
            : null;
        entity.customerServiceEmployeeApprovalComment =
            dto.customerServiceEmployeeApprovalComment;
        entity.orderDate = dto.orderDate ? new Date(dto.orderDate) : null;
        entity.orderBy = dto.orderBy;
        entity.orderReferenceNumber = dto.orderReferenceNumber;
        entity.shipToAddress1 = dto.shipToAddress1;
        entity.shipToAddress2 = dto.shipToAddress2;
        entity.shipToCity = dto.shipToCity;
        entity.shipToState = dto.shipToState;
        entity.shipToZip = dto.shipToZip;
        entity.createdUtcDateTime = dto.createdUtcDateTime
            ? new Date(dto.createdUtcDateTime)
            : null;
        entity.createdUserId = dto.createdUserId;
        entity.createdUserZrt = dto.createdUserZrt;
        entity.createdUserFullName = dto.createdUserFullName;
        entity.modifiedUtcDateTime = dto.modifiedUtcDateTime
            ? new Date(dto.modifiedUtcDateTime)
            : null;
        entity.modifiedUserId = dto.modifiedUserId;
        entity.modifiedUserZrt = dto.modifiedUserZrt;
        entity.modifiedUserFullName = dto.modifiedUserFullName;
        entity.gratisApprovals = (
            dto.gratisApprovals ?? new Array<GratisApprovalDto>()
        ).map((dto) => GratisApprovalConverterService.dtoToEntity(dto, dto.id));
        entity.gratisProductRequestedDetails = (
            dto.gratisProductRequestedDetails ??
            new Array<GratisProductRequestedDetailDto>()
        ).map((dto) =>
            GratisProductRequestedDetailConverterService.dtoToEntity(dto)
        );
        entity.gratisProductShippedDetails = (
            dto.gratisProductShippedDetails ??
            new Array<GratisProductShippedDetailDto>()
        ).map((dto) =>
            GratisProductShippedDetailConverterService.dtoToEntity(dto)
        );
        entity.gratisAssignedTransactions = (
            dto.gratisAssignedTransactions ??
            new Array<GratisAssignedTransactionDto>()
        ).map((dto) =>
            GratisAssignedTransactionConverterService.dtoToEntity(dto)
        );

        return entity;
    }

    static gratisToGratisDto(entity: Gratis): GratisDto {
        const dto = new GratisDto();

        dto.id = entity.id;
        dto.employeeId = entity.employeeId;
        dto.gratisNumber = entity.gratisNumber;
        dto.gratisStatusId = entity.gratisStatusId;
        dto.purpose = entity.purpose;
        dto.daysWaiting = entity.daysWaiting;
        dto.neededByDate = entity.neededByDate
            ? entity.neededByDate.toISOString()
            : GratisConverterService.defaultNeededByDate.toDateString();
        dto.startDate = entity.startDate
            ? entity.startDate.toISOString()
            : null;
        dto.endDate = entity.endDate ? entity.endDate.toISOString() : null;
        dto.availableAmount = entity.availableAmount;
        dto.requestedAmount = entity.requestedAmount;
        dto.reimbursedAmount = entity.reimbursedAmount;
        dto.manualGratisAmount = entity.manualGratisAmount;
        dto.carryForwardAmount = entity.carryForwardAmount;
        dto.employeeApprovalStatusId = entity.employeeApprovalStatusId;
        dto.employeeApprovalStatusDate = entity.employeeApprovalStatusDate
            ? entity.employeeApprovalStatusDate.toISOString()
            : null;
        dto.regionalEmployeeApprovalEmployeeId =
            entity.regionalEmployeeApprovalEmployeeId;
        dto.regionalEmployeeApprovalStatusId =
            entity.regionalEmployeeApprovalStatusId;
        dto.regionalEmployeeApprovalStatusDate = entity.regionalEmployeeApprovalStatusDate
            ? entity.regionalEmployeeApprovalStatusDate.toISOString()
            : null;
        dto.regionalEmployeeApprovalComment =
            entity.regionalEmployeeApprovalComment;
        dto.zoneEmployeeApprovalEmployeeId =
            entity.zoneEmployeeApprovalEmployeeId;
        dto.zoneEmployeeApprovalStatusId = entity.zoneEmployeeApprovalStatusId;
        dto.zoneEmployeeApprovalStatusDate = entity.zoneEmployeeApprovalStatusDate
            ? entity.zoneEmployeeApprovalStatusDate.toISOString()
            : null;
        dto.zoneEmployeeApprovalComment = entity.zoneEmployeeApprovalComment;
        dto.customerServiceEmployeeApprovalEmployeeId =
            entity.customerServiceEmployeeApprovalEmployeeId;
        dto.customerServiceEmployeeApprovalStatusId =
            entity.customerServiceEmployeeApprovalStatusId;
        dto.customerServiceEmployeeApprovalStatusDate = entity.customerServiceEmployeeApprovalStatusDate
            ? entity.customerServiceEmployeeApprovalStatusDate.toISOString()
            : null;
        dto.customerServiceEmployeeApprovalComment =
            entity.customerServiceEmployeeApprovalComment;
        dto.orderDate = entity.orderDate
            ? entity.orderDate.toISOString()
            : null;
        dto.orderBy = entity.orderBy;
        dto.orderReferenceNumber = entity.orderReferenceNumber;
        dto.shipToAddress1 = entity.shipToAddress1;
        dto.shipToAddress2 = entity.shipToAddress2;
        dto.shipToCity = entity.shipToCity;
        dto.shipToState = entity.shipToState;
        dto.shipToZip = entity.shipToZip;
        dto.createdUtcDateTime = entity.createdUtcDateTime
            ? entity.createdUtcDateTime.toISOString()
            : new Date().toISOString();
        dto.createdUserId = entity.createdUserId;
        dto.createdUserZrt = entity.createdUserZrt;
        dto.createdUserFullName = entity.createdUserFullName;
        dto.modifiedUtcDateTime = entity.modifiedUtcDateTime
            ? entity.modifiedUtcDateTime.toISOString()
            : new Date().toISOString();
        dto.modifiedUserId = entity.modifiedUserId;
        dto.modifiedUserZrt = entity.modifiedUserZrt;
        dto.modifiedUserFullName = entity.modifiedUserFullName;
        dto.gratisApprovals = (
            entity.gratisApprovals ?? new Array<GratisApproval>()
        ).map((entity) => GratisApprovalConverterService.entityToDto(entity));
        dto.gratisProductRequestedDetails = (
            entity.gratisProductRequestedDetails ??
            new Array<GratisProductRequestedDetail>()
        ).map((entity) =>
            GratisProductRequestedDetailConverterService.entityToDto(entity)
        );
        dto.gratisProductShippedDetails = (entity.gratisProductShippedDetails ?? [])
            .map((entity) => GratisProductShippedDetailConverterService.entityToDto(entity));
        dto.gratisAssignedTransactions = (
            entity.gratisAssignedTransactions ??
            new Array<GratisAssignedTransaction>()
        ).map((entity) =>
            GratisAssignedTransactionConverterService.entityToDto(entity)
        );

        return dto;
    }
}
