import { CallReceiptLicenseDto } from "./call-receipt-license.dto";

export class CallReceiptDto {
    id: string;
    callId: string;
    receiptNumber: string;
    receiptNumberExtention: string;
    blobPath: string;
    wholesalerId?: string | null;
    isOriginal: boolean;

    saleUtcDateTime: string | null;
    saleUserId: string | null;
    saleUserZrt: string | null;
    saleUserFullName: string | null;
	address1: string | null;
	address2: string | null;
	city: string | null;
	county: string | null;
	state: string | null;
	zip: string | null;
	country: string | null;
    createdUtcDateTime: string | null;
    createdUserId: string | null;
    createdUserZrt: string | null;
    createdUserFullName: string | null;

    callReceiptLicenses: CallReceiptLicenseDto[];
}
