import { AddressFilterDto, RefinerLocation, valueSeparator } from "shield.shared";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { BaseFilterMapService } from "./base-filter-map.service";

export class AddressFilterMapService extends BaseFilterMapService {
    static mapFilterData(refiners: Refiner[]): AddressFilterDto {
        if (!refiners) return;
        const filterDto = new AddressFilterDto();

        for (const refiner of refiners) {
            if (!refiner.dataValue) {
                refiner.dataValue = refiner.value;
            }
            switch (refiner.location) {
                case RefinerLocation.street:
                    filterDto.street = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.city:
                    filterDto.city = refiner.dataValue.split(valueSeparator);
                    break;
                case RefinerLocation.states:
                    filterDto.stateIds = refiner.dataValue.split(valueSeparator);
                    filterDto.states = refiner.value.split(", ");
                    break;
                case RefinerLocation.counties:
                    filterDto.countyIds = refiner.dataValue.split(valueSeparator);
                    filterDto.counties = refiner.value.split(", ");
                    break;
                case RefinerLocation.zipCodes:
                    filterDto.zipCode = refiner.dataValue;
                    break;
                default:
                    break;
            }
        }

        return filterDto;
    }
}
