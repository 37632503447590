export class Country {
    id: string;
    name: string;
    alpha2Code: string;
    alpha3Code: string;
    numericCode: number;
    iSO31662Codes: string;
    independent: boolean;
    rowversion: string;
}
