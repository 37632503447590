import { FilterRequestV2Dto, RetailContractsFilterDto, RetailContractsColumns } from "../filter";

export class EmailContractParamsDto {
    employeeId: string;
    image: string;
    comment?: string;
}

export class RetailContractBatchParamsDto {
    employeeId: string;
    filterRequestDto: FilterRequestV2Dto<RetailContractsFilterDto, RetailContractsColumns>;
}
